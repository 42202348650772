import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ConfirmIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.8531 1.81273C16.05 2.00648 16.05 2.32523 15.8531 2.51898L5.35313 13.019C5.15938 13.2159 4.84063 13.2159 4.64688 13.019L0.146437 8.51899C-0.0488125 8.32524 -0.0488125 8.00648 0.146437 7.81273C0.341563 7.61586 0.658125 7.61586 0.853438 7.81273L5 11.9596L15.1469 1.81273C15.3406 1.61711 15.6594 1.61711 15.8531 1.81273Z" fill="#08AAB4"/>
            </svg>
        </SvgIcon>
    );
}
