const mainPageMenuClick = function(label) {
    return {
        category: 'mainPage',
        action: 'menuClick',
        label,
    };
};

const mainPageActions = function(label) {
    return {
        category: 'mainPage',
        action: 'actions',
        label,
    };
};

const mainPageLinks = function(label) {
    return {
        category: 'mainPage',
        action: 'links',
        label,
    };
};

const onBoarding = function(label) {
    return {
        category: 'mainPage',
        action: 'onboarding',
        label,
    };
};

const manageGroupsPage = function(label) {
    return {
        category: 'manageGroupsPage',
        action: 'actions',
        label,
    };
};

const manageCollectionsPage = function(label) {
    return {
        category: 'manageCollectionsPage',
        action: 'actions',
        label,
    };
};

const joinGroupsPage = function(label) {
    return {
        category: 'joinGroupsPage',
        action: 'actions',
        label,
    };
};

const editGroupPageActions = function(label) {
    return {
        category: 'joinGroupsPage',
        action: 'actions',
        label,
    };
};

const shareBoxActions = function(label) {
    return {
        category: 'shareBoxActions',
        action: 'actions',
        label,
    };
};

const joinBoxActions = function(label) {
    return {
        category: 'joinBoxActions',
        action: 'actions',
        label,
    };
};

const lessonPageActions = function(label) {
    return {
        category: 'lessonPage',
        action: 'actions',
        label,
    };
};

const lessonPageToolSelection = function(label) {
    return {
        category: 'lessonPage',
        action: 'toolSelection',
        label,
    };
};

const lessonPageMenuActions = function(label) {
    return {
        category: 'lessonPage',
        action: 'menuAction',
        label,
    };
};

const individualSessionBox = function(label) {
    return {
        category: 'individualSessionBox',
        action: 'action',
        label,
    };
};

export const GA_EVENT_TREE = {
    mainPage: {
        menuClick: {
            profile: mainPageMenuClick('profile'),
            home: mainPageMenuClick('home'),
            myGroups: mainPageMenuClick('myGroups'),
            joinGroup: mainPageMenuClick('joinGroup'),
            // tutorial: mainPageMenuClick('tutorial'),
            logout: mainPageMenuClick('logout'),
            contact: mainPageMenuClick('contact'),
        },
        actions: {
            viewLatest: mainPageActions('viewLatest'),
            enterLesson: mainPageActions('enterLesson'),
            seeMoreStart: mainPageActions('seeMoreStart'),
            seeMoreJoin: mainPageActions('seeMoreJoin'),
            quickCreate: mainPageActions('quickCreate'),
            nameGroup: mainPageActions('nameGroup'),
            backQuickStart: mainPageActions('backQuickStart'),
            finishQuickStart: mainPageActions('finishQuickStart'),
        },
        links: {
            facebook: mainPageLinks('facebook'),
            youtube: mainPageLinks('youtube'),
            ro: mainPageLinks('ro'),
            fr: mainPageLinks('fr'),
            en: mainPageLinks('en'),
            googleLogin: mainPageLinks('googleLogin'),
            linkWhiteboard: mainPageLinks('linkWhiteboardLogin'),
            facebookLogin: mainPageLinks('facebookLogin'),
            microsoftLogin: mainPageLinks('microsoftLogin'),
            tutorial: mainPageLinks('tutorial'),
        },
        onboarding: {
            loginGoogle: onBoarding('loginGoogle'),
            loginFacebook: onBoarding('loginFacebook'),
            startFree: onBoarding('startFree'),
            startTrial: onBoarding('startTrial'),
            startBasic: onBoarding('startBasic'),
            startPremium: onBoarding('startPremium'),
            wantOffer: onBoarding('wantOffer'),
            checkRon: onBoarding('checkRon'),
            checkEur: onBoarding('checkEur'),
            checkAnnual: onBoarding('checkAnnual'),
            checkMonthly: onBoarding('checkMonthly'),
            viewSubscriptions: onBoarding('viewSubscriptions'),
        },
    },
    manageGroups: {
        actions: {
            load: manageGroupsPage('load'),
            start: manageGroupsPage('start'),
            edit: manageGroupsPage('edit'),
            delete: manageGroupsPage('delete'),
            create: manageGroupsPage('create'),
        },
    },
    manageCollections: {
        actions: {
            load: manageCollectionsPage('load'),
            edit: manageCollectionsPage('edit'),
            move: manageCollectionsPage('move'),
            delete: manageCollectionsPage('delete'),
            create: manageCollectionsPage('create'),
        },
    },
    joinGroups: {
        actions: {
            load: joinGroupsPage('load'),
            join: manageGroupsPage('join'),
        },
    },
    editGroup: {
        actions: {
            load: editGroupPageActions('load'),
            share: editGroupPageActions('share'),
            start: editGroupPageActions('start'),
            updateMeta: editGroupPageActions('updateMeta'),
            removeUser: editGroupPageActions('removeUser'),
        },
    },
    shareBoxActions: {
        actions: {
            copyLink: shareBoxActions('copyLink'),
            mailLink: shareBoxActions('mailLink'),
            fbLink: shareBoxActions('fbLink'),
            whatsAppLink: shareBoxActions('whatsAppLink'),
            viberLink: shareBoxActions('viberLink'),
        },
    },
    joinBoxActions: {
        actions: {
            accept: joinBoxActions('accept'),
            reject: joinBoxActions('reject'),
            close: joinBoxActions('close'),
        },
    },
    lessonPage: {
        actions: {
            start: lessonPageActions('start'),
            leave: lessonPageActions('leave'),
            join: lessonPageActions('join'),
            startCall: lessonPageActions('startCall'),
            joinCall: lessonPageActions('joinCall'),
            endCall: lessonPageActions('endCall'),
            leaveCall: lessonPageActions('leaveCall'),
            raiseHand: lessonPageActions('raiseHand'),
            disableAudio: lessonPageActions('disableAudio'),
            disableVideo: lessonPageActions('disableVideo'),
            enableAudio: lessonPageActions('enableAudio'),
            enableVideo: lessonPageActions('enableVideo'),
            hideParticipants: lessonPageActions('hideParticipants'),
            showParticipantsGrid: lessonPageActions('showParticipantsGrid'),
            showParticipantsBottom: lessonPageActions('showParticipantsBottom'),
        },
        toolSelection: {
            zoomIn: lessonPageToolSelection('zoomIn'),
            selector: lessonPageToolSelection('selector'),
            pointer: lessonPageToolSelection('pointer'),
            pen: lessonPageToolSelection('pen'),
            line: lessonPageToolSelection('line'),
            rectangle: lessonPageToolSelection('rectangle'),
            ellipse: lessonPageToolSelection('ellipse'),
            mathConverter: lessonPageToolSelection('mathConverter'),
            brush: lessonPageToolSelection('brush'),
            eraser: lessonPageToolSelection('eraser'),
            text: lessonPageToolSelection('text'),
            toolWeight: lessonPageToolSelection('toolWeight'),
            textWeight: lessonPageToolSelection('textWeight'),
            eraserWeight: lessonPageToolSelection('eraserWeight'),
            color: lessonPageToolSelection('color'),
            clear: lessonPageToolSelection('clear'),
            clearAllBoards: lessonPageToolSelection('clearAllBoards'),
            lineageNone: lessonPageToolSelection('lineageNone'),
            lineageMath: lessonPageToolSelection('lineageMath'),
            lineageType1: lessonPageToolSelection('lineageType1'),
            lineageType2: lessonPageToolSelection('lineageType2'),
            lineageDictando: lessonPageToolSelection('lineageDictando'),
            lineageMusic: lessonPageToolSelection('lineageMusic'),
            lineageMM: lessonPageToolSelection('lineageMM'),
            imagePaste: lessonPageToolSelection('imagePaste'),
            imagePasteFromClipboard: lessonPageToolSelection('imagePasteFromClipboard'),
            imageInsert: lessonPageToolSelection('imageInsert'),
            imageUpload: lessonPageToolSelection('imageUpload'),
            undoButton: lessonPageToolSelection('undoButton'),
            undoSelection: lessonPageToolSelection('undoSelection'),
            nextBoard: lessonPageToolSelection('nextBoard'),
            previousBoard: lessonPageToolSelection('previousBoard'),
            removeBoard: lessonPageToolSelection('removeBoard'),
            openBoards: lessonPageToolSelection('openBoards'),
            changeText: lessonPageToolSelection('changeText'),
            changeImage: lessonPageToolSelection('changeImage'),
            downloadImage: lessonPageToolSelection('downloadImage'),
            downloadPDF: lessonPageToolSelection('downloadPDF'),
            selectRuler: lessonPageToolSelection('selectRuler'),
            selectCompass: lessonPageToolSelection('selectCompass'),
            selectProtractor: lessonPageToolSelection('selectProtractor'),
        },
        menuActions: {
            leaveSession: lessonPageMenuActions('leaveSession'),
            share: lessonPageMenuActions('share'),
            requests: lessonPageMenuActions('requests'),
            startIndividualBoards: lessonPageMenuActions('startIndividualBoards'),
            stopIndividualBoards: lessonPageMenuActions('stopIndividualBoards'),
            pauseIndividualBoards: lessonPageMenuActions('pauseIndividualBoards'),
            resumeIndividualBoards: lessonPageMenuActions('resumeIndividualBoards'),
            contact: lessonPageMenuActions('contact'),
            language: lessonPageMenuActions('language'),
            seeIndividualSessions: lessonPageMenuActions('seeIndividualSessions'),
            load_collection_in_main: manageCollectionsPage('load_collection_in_main'),
            load_shared_collection_in_main: manageCollectionsPage('load_shared_collection_in_main'),
            load_collection_in_individual: manageCollectionsPage('load_collection_in_individual'),
            load_shared_collection_in_individual: manageCollectionsPage('load_shared_collection_in_individual'),
        },
    },
    individualSessionBox: {
        actions: {
            seeIndividualSession: individualSessionBox('seeIndividualSession'),
            downloadEntireIndividualSessionPDF: individualSessionBox('downloadEntireIndividualSessionPDF'),
            removeEntireIndividualSession: individualSessionBox('removeEntireIndividualSession'),
            downloadSingleImage: individualSessionBox('downloadSingleImage'),
            downloadSinglePDF: individualSessionBox('downloadSinglePDF'),
            removeSingleBoard: individualSessionBox('removeSingleBoard'),
            loadImage: individualSessionBox('loadImage'),
        },
    },
};
