import React, { useState } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import store from '../../redux/store';
import { userJoinRequest } from '../../redux/actions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { DialogTitle, IconButton, makeStyles } from '@material-ui/core';
import RequestsTable from '../../pages/EditClass/RequestsTable';
import { Close } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

const JoinRequestDialog = (props) => {
    const classes = useStyles();
    const [reload, setReload] = useState(false);

    const onReload = () => {
        setReload(!reload);
    };

    const onClose = () => {
        store.dispatch(userJoinRequest(null));
    };

    return (
        <Dialog open={!!props.appState.userJoinRequest} onClose={onClose} fullWidth maxWidth="lg">
            <DialogTitle disableTypography id="alert-dialog-title">
                <Typography variant="h4">
                    <FormattedMessage id="share.joinDescription" />
                </Typography>
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {props.appState.userJoinRequest ? (
                    <RequestsTable
                        classId={props.appState.userJoinRequest.classId}
                        onReload={onReload}
                        requestId={props.appState.userJoinRequest.userId}
                    />
                ) : null}
            </DialogContent>
            <DialogActions style={{ marginBottom: '0.5rem', marginRight: '1rem' }}>
                <Button onClick={onClose} size="large" variant="contained" color="default">
                    <FormattedMessage id="close" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    const { appState } = state;
    return { appState };
};

export default injectIntl(connect(mapStateToProps)(JoinRequestDialog));
