import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Grid, Typography } from '@material-ui/core';
import GenericState from '../../../MyComponents/GenericLoadingErrorState/GenericState';
import userApi from '../../../Api/userApi';
import { Link, useHistory } from 'react-router-dom';
import store from '../../../redux/store';
import { updateUserSubscription } from '../../../redux/actions';
import { PROFILE_LINK } from '../../../common/urlLinks';

const CancelStage = () => {
    const [isStarting, setIsStarting] = useState(false);

    let history = useHistory();

    const cancel = () => {
        setIsStarting(true);
        userApi
            .cancelSubscription()
            .then(() => {
                return userApi.getExtendedProfile();
            })
            .then((res) => {
                store.dispatch(updateUserSubscription(res.data.subscription));
                history.push(PROFILE_LINK);
            })
            .catch((err) => {
                console.error('Unable to cancel the subscription', err);
                history.push(PROFILE_LINK);
            });
    };

    return (
        <Grid container spacing={10}>
            <Grid item xs={12} sm={12} style={{ textAlign: 'center', marginTop: '5rem' }}>
                <Typography variant="h2">
                    <FormattedMessage id={'onboarding.cancel.message'} />
                </Typography>
            </Grid>
            {isStarting ? (
                <Grid item xs={12} sm={12} style={{ textAlign: 'center', marginTop: '5rem' }}>
                    <GenericState isLoading={true} inline={true} />
                </Grid>
            ) : (
                <>
                    <Grid item xs={12} sm={6} style={{ textAlign: 'center', marginTop: '5rem' }}>
                        <Button variant={'contained'} onClick={cancel} color={'secondary'} size={'large'}>
                            <FormattedMessage id={'onboarding.cancel.yes'} />
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ textAlign: 'center', marginTop: '5rem' }}>
                        <Link to={PROFILE_LINK}>
                            <Button variant={'contained'} color={'primary'} size={'large'}>
                                <FormattedMessage id={'onboarding.cancel.no'} />
                            </Button>
                        </Link>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default CancelStage;
