import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Chip, IconButton, List, ListItem, ListItemText } from '@material-ui/core';

import CustomizedExpansionPanels from '../ExpansionPanel/CustomizedExpansionPanels';
import store from '../../redux/store';
import { userToggleDraw } from '../../redux/actions';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import Avatar from '@material-ui/core/Avatar';
import AccountCircle from '@material-ui/icons/AccountCircle';
import HandRaisedIcon from '../icons/HandRaisedIcon';
import Fab from '@material-ui/core/Fab';
import PencilIcon from '../icons/PencilIcon';
import { Mic, MicOff } from '@material-ui/icons';
import MicNoneIcon from '@material-ui/icons/MicNone';
import socketUtils from '../../pages/LessonPage/socketUtils';
import { BOARD_TYPE } from '../../constants';

const ParticipantsList = (props) => {
    const toggleDrawState = (user) => {
        if (window.updateUserDrawingCallback) {
            window.updateUserDrawingCallback(user.email, !user.enableDraw);
        }
        store.dispatch(userToggleDraw(user.email));
    };

    const toggleAudio = (user) => {
        if (user.isMuted) {
            return;
        }
        socketUtils.updateUserMic(user.email, !user.isMuted);
    };

    const buildUserPanel = (users) => {
        if (users.length === 0) {
            return null;
        }
        let sortedArr = [...users];
        try {
            sortedArr.sort((a, b) => {
                return a.name.localeCompare(b.name);
            });
        } catch (ex) {
            console.error('Unable to compare', sortedArr);
        }

        return (
            <List style={{ width: '100%' }}>
                {sortedArr.map((user) => (
                    <ListItem key={user.email} disableGutters disabled={!user.online}>
                        <div style={{ width: '100%' }}>
                            <div className="u-fx u-fx-align-center">
                                <div>
                                    {user.avatar ? (
                                        <Avatar style={{ border: '1px solid #08AAB4' }} src={user.avatar} />
                                    ) : (
                                        <AccountCircle />
                                    )}
                                </div>
                                <ListItemText className="u-ml-half">{user.name}</ListItemText>
                                <div className="u-ml-auto">
                                    {props.lessonState.isAdmin ? (
                                        user.raisedHand ? (
                                            <HandRaisedIcon />
                                        ) : null
                                    ) : user.isMuted === undefined || user.isMuted === null ? (
                                        <MicNoneIcon style={{ verticalAlign: 'middle' }} color="disabled" />
                                    ) : user.isMuted ? (
                                        <MicOff color="disabled" style={{ verticalAlign: 'middle' }} />
                                    ) : (
                                        <Mic style={{ verticalAlign: 'middle', color: '#4da34d' }} />
                                    )}
                                </div>
                            </div>
                            {props.lessonState.isAdmin ? (
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                                    <Fab
                                        size="small"
                                        color={user.enableDraw ? 'primary' : 'default'}
                                        onClick={() => toggleDrawState(user)}
                                        style={{ boxShadow: 'none' }}
                                        disabled={props.lessonState.boardType === BOARD_TYPE.MULTI_BOARD}
                                    >
                                        <PencilIcon />
                                    </Fab>
                                    {user.isMuted === undefined || user.isMuted === null ? (
                                        <IconButton
                                            size="small"
                                            color="default"
                                            style={{
                                                boxShadow: 'none',
                                            }}
                                            disabled
                                        >
                                            <MicOff style={{ verticalAlign: 'middle' }} />
                                        </IconButton>
                                    ) : (
                                        <IconButton
                                            size="small"
                                            // color={!user.isMuted ? 'secondary' : 'default'}
                                            onClick={() => toggleAudio(user)}
                                            style={{
                                                boxShadow: 'none',
                                            }}
                                            disabled={!user.online || user.isMuted}
                                        >
                                            {user.isMuted ? (
                                                <MicOff style={{ verticalAlign: 'middle' }} />
                                            ) : (
                                                <Mic style={{ verticalAlign: 'middle' }} />
                                            )}
                                        </IconButton>
                                    )}
                                </div>
                            ) : null}
                        </div>
                    </ListItem>
                ))}
            </List>
        );
    };

    let panels = [];

    const userData = buildUserPanel(props.lessonState.members);
    let activeUserCount = 0;

    if (props.lessonState.members) {
        activeUserCount = props.lessonState.members.filter((el) => el.online).length;
    }

    if (userData) {
        panels.push({
            name: 'UserActions',
            title: props.intl.formatMessage({ id: 'menu.userActions' }),
            expanded: 'UserActions',
            content: userData,
            more:
                props.lessonState.isAdmin && activeUserCount > 0 ? (
                    <Chip variant="outlined" label={activeUserCount} />
                ) : null,
            icon: (
                <ListItemIcon>
                    <PeopleAltIcon color="primary" />
                </ListItemIcon>
            ),
        });
    }

    return <CustomizedExpansionPanels panels={panels} expanded="UserActions" />;
};

const mapStateToProps = (state) => {
    const { profile, lessonState } = state;
    return { profile, lessonState };
};

export default injectIntl(connect(mapStateToProps)(ParticipantsList));
