import React from 'react';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getSubscriptionData, SUBSCRIPTION_PLANS, TRIAL_DAYS } from '../../shared/Capabilities';
import { Card, CardActions, CardContent, Grid, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { ONBOARDING_STEPS } from '../../shared/OnboardingOptions';

const useStyles = makeStyles({
    root: {
        minWidth: 250,
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: '1rem',
        paddingBottom: '1rem'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

const printDate = function (intl, expirationDate) {
    if (!expirationDate) {
        return intl.formatMessage({ id: `subscriptions.subscription.expiresNever` });
    }
    let exp = new Date(expirationDate);
    if (exp < new Date()) {
        return intl.formatMessage({ id: `subscriptions.subscription.expiresExpired` });
    }

    return exp.toLocaleDateString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
};

const CurrentSubscriptionShort = ({ intl, profile, subscriptions }) => {
    const classes = useStyles();
    if (!profile || !subscriptions || !profile.subscription) {
        return null;
    }
    let subscriptionData = getSubscriptionData(subscriptions, profile.subscription);

    let isCancelable = false;
    let isUpgradable = false;
    if (
        subscriptionData.subscriptionName === SUBSCRIPTION_PLANS.BASIC ||
        subscriptionData.subscriptionName === SUBSCRIPTION_PLANS.PREMIUM
    ) {
        if (!profile.subscription.canceledAt) {
            isCancelable = true;
        }
    }
    if (
        !isCancelable &&
        subscriptionData.subscriptionName !== SUBSCRIPTION_PLANS.SPECIAL &&
        subscriptionData.subscriptionName !== SUBSCRIPTION_PLANS.PREMIUM
    ) {
        isUpgradable = true;
    }
    if (
        !isUpgradable &&
        profile.subscription.canceledAt &&
        subscriptionData.subscriptionName !== SUBSCRIPTION_PLANS.SPECIAL
    ) {
        isUpgradable = true;
    }

    return (
        <>
            <Card className={classes.root} elevation={0}>
                {/* <CardContent style={{ display: 'contents' }}> */}
                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                <FormattedMessage id="subscriptions.subscription.currentSubscription" />
                            </Typography>
                            <Typography variant="h4" component="h2">
                                {subscriptionData.subscriptionName}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                <FormattedMessage id="subscriptions.subscription.expires" />
                            </Typography>
                            <Typography variant="h4" component="h2">
                                {subscriptionData.subscriptionName === SUBSCRIPTION_PLANS.FREE ||
                                !profile.subscription.endDate
                                    ? intl.formatMessage({ id: `subscriptions.subscription.expiresNever` })
                                    : printDate(intl, profile.subscription.endDate)}
                            </Typography>
                        </Grid>
                    </Grid>
                {/* </CardContent> */}
                {/* <CardActions> */}
                    <Grid container spacing={4} style={{display: 'flex', alignItems: 'center'}}>
                        {/* <div style={{ width: '100%', display: 'inline-flex', gap: '1rem' }}> */}
                        <Grid item xs={12} sm={6}>
                            <div style={{ width: '100%' }}>
                                {isCancelable && (
                                    <Link to={`/onboarding?step=${ONBOARDING_STEPS.CANCEL}`}>
                                        <Button variant={'contained'}>
                                            <FormattedMessage id={'subscriptions.subscription.cancel'} />
                                        </Button>
                                    </Link>
                                )}
                                {isUpgradable && (
                                    <Link to={`/subscriptions`}>
                                        <Button color="primary" variant={'contained'}>
                                            <FormattedMessage id="subscriptions.subscription.upgrade" />
                                        </Button>
                                    </Link>
                                )}
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div style={{ width: '100%', textAlign: 'end' }}>
                                {subscriptionData.subscriptionName === SUBSCRIPTION_PLANS.FREE &&
                                profile?.subscription?.trialStarted === false ? (
                                    <Link
                                        to={`/onboarding?step=${ONBOARDING_STEPS.START}&subscription=${
                                            SUBSCRIPTION_PLANS.TRIAL
                                        }&billAnnual=${true}&billEur=${false}`}
                                    >
                                        <Button color="primary" variant={'contained'} style={{ marginRight: '0.5rem' }}>
                                            <FormattedMessage
                                                id="subscriptions.subscription.startTrial"
                                                values={{ days: TRIAL_DAYS }}
                                            />
                                        </Button>
                                    </Link>
                                ) : null}
                            </div>
                        </Grid>
                        {/* </div> */}
                    </Grid>
                {/* </CardActions> */}
            </Card>
        </>
    );
};

const mapStateToProps = (state) => {
    const { profile, mainAppState } = state;
    return { profile, subscriptions: mainAppState.subscriptions };
};

export default injectIntl(connect(mapStateToProps)(CurrentSubscriptionShort));
