import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { FOR_SCHOOLS_LINK, FOR_TUTORING_LINK, TUTORIAL_LINK } from '../../common/urlLinks';
import { FindMoreButton } from '../Home/HomeFeatures/FindMoreButton';
import { transitionToTop } from '../../common/utils';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../constants';

const useStyles = makeStyles(() => ({
    contentBlock: {
        borderRadius: '1rem 1rem 1rem 1rem',
        background: '#FFF',
        boxShadow: '0rem 0rem 2.5rem rgba(210, 104, 40, 0.04)',
        padding: '0.3rem',
        position: 'relative',
        textAlign: 'end',
        height: '7rem',
    },
    moreBlock: {
        padding: '1rem',
    },
    font: {
        color: COLORS.fontDefault,
        fontWeight: '700',
        textAlign: 'end',
        paddingRight: '0.5rem',
        position: 'absolute',
        right: '0px',
        bottom: '0px',
        zIndex: '1',
    },
    container: {
        position: 'relative',
    },
    image: {
        position: 'absolute',
        height: '80%',
        left: '0px',
        top: '0px',
    },
}));

const blocks = [
    {
        img: '/static/asset/img/schools/orice_smartboard.png',
        text: 'welcome.text.exploreSchools',
        link: FOR_SCHOOLS_LINK,
    },
    {
        img: '/static/asset/img/features/formule_matematice.png',
        text: 'welcome.text.exploreTutoring',
        link: FOR_TUTORING_LINK,
    },
    {
        img: '/static/asset/img/tutoring/elevi_diferite_nivele.png',
        text: 'welcome.text.exploreIndividualBoards',
        link: '/features?feature=individualboards',
    },
    {
        img: '/static/asset/img/features/librarie.png',
        text: 'welcome.text.exploreLibrary',
        link: '/features?feature=library',
    },
    {
        img: '/static/asset/img/features/lipiti_informatii.png',
        text: 'welcome.text.exploreCompose',
        link: '/features/?feature=paste',
    },
    {
        img: '/static/asset/img/tutoring/conferinta_audio_video.png',
        text: 'welcome.text.keepInTouch',
        link: '/features?feature=audiovideo',
    },
];

const MainActivityBlock = () => {
    const classes = useStyles();
    return (
        <Grid container>
            <Grid item xs={12} sm={12}>
                <Typography variant="h4">
                    <FormattedMessage id={'welcome.text.exploreTutorial'} />
                </Typography>
                <Link to={TUTORIAL_LINK} onClick={transitionToTop}>
                    <FindMoreButton />
                </Link>
            </Grid>
            <Grid item xs={12} sm={12} />
            {blocks.map((el, index) => {
                return (
                    <Grid item xs={12} sm={6} md={4} className={classes.moreBlock} key={`main-block-${index}`}>
                        <div className={classes.contentBlock}>
                            <Typography variant="h4" className={classes.font}>
                                <FormattedMessage id={el.text} />
                            </Typography>

                            <Link to={el.link} onClick={transitionToTop}>
                                <FindMoreButton />
                            </Link>
                            <img src={el.img} alt="VBoard" className={classes.image} />
                        </div>
                    </Grid>
                );
            })}
            {/*<Grid item xs={12} sm={6} md={4} className={classes.moreBlock}>*/}
            {/*    <div className={classes.contentBlock}>*/}
            {/*        <img*/}
            {/*            src={`/static/asset/img/schools/orice_smartboard.png`}*/}
            {/*            alt="VBoard"*/}
            {/*            className={classes.image}*/}
            {/*        />*/}
            {/*        <Typography variant="h4" className={classes.font}>*/}
            {/*            <FormattedMessage id={'welcome.text.exploreSchools'} />*/}
            {/*        </Typography>*/}

            {/*        <Link to={FOR_SCHOOLS_LINK} onClick={transitionToTop}>*/}
            {/*            <FindMoreButton />*/}
            {/*        </Link>*/}
            {/*    </div>*/}
            {/*</Grid>*/}
            {/*<Grid xs={12} sm={6} md={4} className={classes.moreBlock}>*/}
            {/*    <div className={classes.contentBlock}>*/}
            {/*        <img*/}
            {/*            src={`/static/asset/img/schools/orice_smartboard.png`}*/}
            {/*            alt="VBoard"*/}
            {/*            className={classes.image}*/}
            {/*        />*/}
            {/*        <Typography variant="h4" className={classes.font}>*/}
            {/*            <FormattedMessage id={'welcome.text.exploreTutoring'} />*/}
            {/*        </Typography>*/}

            {/*        <Link to={FOR_TUTORING_LINK} onClick={transitionToTop}>*/}
            {/*            <FindMoreButton />*/}
            {/*        </Link>*/}
            {/*    </div>*/}
            {/*</Grid>*/}
            {/*<Grid xs={12} sm={6} md={4} className={classes.moreBlock}>*/}
            {/*    <div className={classes.contentBlock}>*/}
            {/*        <Typography variant="h4" align={'center'} className={classes.font}>*/}
            {/*            <FormattedMessage id={'welcome.text.exploreLibrary'} />*/}
            {/*        </Typography>*/}
            {/*        <Link to={'/features?feature=library'} onClick={transitionToTop}>*/}
            {/*            <FindMoreButton fullWidth />*/}
            {/*        </Link>*/}
            {/*    </div>*/}
            {/*</Grid>*/}
            {/*<Grid xs={12} sm={6} md={4} className={classes.moreBlock}>*/}
            {/*    <div className={classes.contentBlock}>*/}
            {/*        <Typography variant="h4" align={'center'} className={classes.font}>*/}
            {/*            <FormattedMessage id={'welcome.text.exploreIndividualBoards'} />*/}
            {/*        </Typography>*/}
            {/*        <Link to={'/features?feature=individualboards'} onClick={transitionToTop}>*/}
            {/*            <FindMoreButton fullWidth />*/}
            {/*        </Link>*/}
            {/*    </div>*/}
            {/*</Grid>*/}
            {/*<Grid xs={12} sm={6} md={4} className={classes.moreBlock}>*/}
            {/*    <div className={classes.contentBlock}>*/}
            {/*        <Typography variant="h4" align={'center'} className={classes.font}>*/}
            {/*            <FormattedMessage id={'welcome.text.exploreCompose'} />*/}
            {/*        </Typography>*/}
            {/*        <Link to={'/features/?feature=paste'} onClick={transitionToTop}>*/}
            {/*            <FindMoreButton fullWidth />*/}
            {/*        </Link>*/}
            {/*    </div>*/}
            {/*</Grid>*/}
            {/*<Grid xs={12} sm={6} md={4} className={classes.moreBlock}>*/}
            {/*    <div className={classes.contentBlock}>*/}
            {/*        <Typography variant="h4" align={'center'} className={classes.font}>*/}
            {/*            <FormattedMessage id={'welcome.text.keepInTouch'} />*/}
            {/*        </Typography>*/}
            {/*        <Link to={'/features?feature=audiovideo'} onClick={transitionToTop}>*/}
            {/*            <FindMoreButton fullWidth />*/}
            {/*        </Link>*/}
            {/*    </div>*/}
            {/*</Grid>*/}
        </Grid>
    );
};

export default MainActivityBlock;
