import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function LatexEdit(props) {
    return (
        <SvgIcon {...props}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 150 150"
                preserveAspectRatio="xMidYMid meet"
                version="1.0"
            >
                <path
                    d="M 131.371094 2.128906 C 130.015625 0.773438 128.128906 0 126.242188 0 C 124.304688 0 122.46875 0.773438 121.113281 2.128906 L 65.613281 57.582031 C 64.355469 58.839844 63.628906 60.53125 63.53125 62.324219 L 62.566406 79.789062 C 62.46875 81.824219 63.242188 83.855469 64.695312 85.304688 C 66.046875 86.660156 67.886719 87.433594 69.824219 87.433594 L 70.210938 87.433594 L 87.675781 86.46875 C 89.417969 86.371094 91.113281 85.597656 92.371094 84.339844 L 147.871094 28.933594 C 149.226562 27.53125 150 25.695312 150 23.804688 C 150 21.871094 149.226562 20.03125 147.871094 18.628906 Z M 131.371094 2.128906 "
                    fillOpacity="1"
                    fillRule="nonzero"
                />
                <path
                    d="M 24.503906 150 L 104.996094 150 C 118.511719 150 129.5 139.007812 129.5 125.496094 L 129.5 75.875 C 129.5 71.867188 126.25 68.617188 122.242188 68.617188 C 118.238281 68.617188 114.984375 71.867188 114.984375 75.875 L 114.984375 125.496094 C 114.984375 131 110.507812 135.484375 104.996094 135.484375 L 24.503906 135.484375 C 18.996094 135.484375 14.515625 131 14.515625 125.496094 L 14.515625 45.003906 C 14.515625 39.496094 18.996094 35.015625 24.503906 35.015625 L 74.125 35.015625 C 78.132812 35.015625 81.382812 31.765625 81.382812 27.757812 C 81.382812 23.746094 78.132812 20.5 74.125 20.5 L 24.503906 20.5 C 10.992188 20.5 0 31.492188 0 45.003906 L 0 125.496094 C 0 139.007812 10.992188 150 24.503906 150 Z M 24.503906 150 "
                    fillOpacity="1"
                    fillRule="nonzero"
                />
            </svg>
        </SvgIcon>
    );
}
