import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function SingleBoardIconRedesign(props) {
    return (
        <SvgIcon {...props} style={{ ...props.style, verticalAlign: 'bottom' }}>
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="24" height="24" rx="4" fill="#08AAB4" fillOpacity="0.08"/>
                <g clipPath="url(#clip0_920_871)">
                    <path d="M7.5 6.75C6.67266 6.75 6 7.42266 6 8.25V15H7.5V8.25H16.5V15H18V8.25C18 7.42266 17.3273 6.75 16.5 6.75H7.5ZM10.5 15V15.75H6C5.58516 15.75 5.25 16.0852 5.25 16.5C5.25 16.9148 5.58516 17.25 6 17.25H18C18.4148 17.25 18.75 16.9148 18.75 16.5C18.75 16.0852 18.4148 15.75 18 15.75H15V15C15 14.5852 14.6648 14.25 14.25 14.25H11.25C10.8352 14.25 10.5 14.5852 10.5 15Z" fill="#08AAB4"/>
                </g>
                <defs>
                    <clipPath id="clip0_920_871">
                        <rect width="13.5" height="12" fill="white" transform="translate(5.25 6)"/>
                    </clipPath>
                </defs>
            </svg>

        </SvgIcon>
    );
}
