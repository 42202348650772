import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Grid, Typography } from '@material-ui/core';
import GenericState from '../../MyComponents/GenericLoadingErrorState/GenericState';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { parseQuery } from '../../common/utils';
import userApi from '../../Api/userApi';
import store from '../../redux/store';
import { showError, updateUserPreferences } from '../../redux/actions';
import { PROFILE_LINK } from '../../common/urlLinks';

const VerifyEmailPage = ({ state, profile }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [validated, setIsValidate] = useState(false);

    let location = useLocation();
    let history = useHistory();

    let validatingTextId = '';
    if (isLoading) {
        validatingTextId = 'onboarding.verifyEmail.verifying';
    } else if (validated) {
        validatingTextId = 'onboarding.verifyEmail.update';
    } else {
        validatingTextId = 'onboarding.verifyEmail.invalid';
    }

    useEffect(() => {
        let queryObj = parseQuery(location.search);
        if (!queryObj.id || !queryObj.email) {
            setIsLoading(false);
            setIsValidate(false);
        } else {
            setIsLoading(true);
            userApi
                .verifyEmail({ id: queryObj.id, email: queryObj.email })
                .then((res) => {
                    if (res.data === true) {
                        setIsValidate(true);
                        return userApi.getExtendedProfile();
                    } else {
                        setIsValidate(false);
                        return Promise.resolve();
                    }
                })
                .then((response) => {
                    store.dispatch(updateUserPreferences(response.data.preferences));
                    setIsLoading(false);
                    let redirectState = window.localStorage.getItem('tempCheckoutSession');
                    if (redirectState) {
                        let redirectObj = JSON.parse(redirectState);
                        if (redirectObj) {
                            history.push(
                                `/onboarding?step=${redirectObj.step}&subscription=${redirectObj.subscription}&billAnnual=${redirectObj.billAnnual}&billEur=${redirectObj.billEur}`
                            );
                        }
                    }
                })
                .catch((err) => {
                    console.error('Unable to validate email', err);
                    setIsLoading(false);
                    setIsValidate(false);
                    store.dispatch(showError('GENERIC_ERROR'));
                });
        }
    }, [location]);

    return (
        <Grid container spacing={10}>
            <Grid item xs={12} sm={12} style={{ textAlign: 'center', marginTop: '5rem' }}>
                <Typography variant="h2">
                    <FormattedMessage id={validatingTextId} />
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} style={{ textAlign: 'center', marginTop: '5rem' }}>
                {isLoading ? (
                    <GenericState isLoading={true} inline={true} />
                ) : (
                    <Link to={PROFILE_LINK}>
                        <Button variant={'contained'} color={'primary'}>
                            <FormattedMessage id={'profile'} />
                        </Button>
                    </Link>
                )}
            </Grid>
        </Grid>
    );
};

export default injectIntl(VerifyEmailPage);
