import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function MobileMenuIcon(props) {
    return (
        <SvgIcon {...props} style={{ verticalAlign: 'bottom' }}>
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 3.375C0 2.75391 0.503906 2.25 1.125 2.25H3.375C3.99609 2.25 4.5 2.75391 4.5 3.375V5.625C4.5 6.24844 3.99609 6.75 3.375 6.75H3V11.25H6V10.875C6 10.2516 6.50156 9.75 7.125 9.75H9.375C9.99844 9.75 10.5 10.2516 10.5 10.875V13.125C10.5 13.7484 9.99844 14.25 9.375 14.25H7.125C6.50156 14.25 6 13.7484 6 13.125V12.75H3V18C3 18.4125 3.33562 18.75 3.75 18.75H6V18.375C6 17.7516 6.50156 17.25 7.125 17.25H9.375C9.99844 17.25 10.5 17.7516 10.5 18.375V20.625C10.5 21.2484 9.99844 21.75 9.375 21.75H7.125C6.50156 21.75 6 21.2484 6 20.625V20.25H3.75C2.50734 20.25 1.5 19.2422 1.5 18V6.75H1.125C0.503906 6.75 0 6.24844 0 5.625V3.375ZM3 5.25V3.75H1.5V5.25H3ZM9 20.25V18.75H7.5V20.25H9ZM7.5 11.25V12.75H9V11.25H7.5ZM7.5 4.5C7.5 4.08562 7.8375 3.75 8.25 3.75H23.25C23.6625 3.75 24 4.08562 24 4.5C24 4.9125 23.6625 5.25 23.25 5.25H8.25C7.8375 5.25 7.5 4.9125 7.5 4.5ZM13.5 12C13.5 11.5875 13.8375 11.25 14.25 11.25H23.25C23.6625 11.25 24 11.5875 24 12C24 12.4125 23.6625 12.75 23.25 12.75H14.25C13.8375 12.75 13.5 12.4125 13.5 12ZM13.5 19.5C13.5 19.0875 13.8375 18.75 14.25 18.75H23.25C23.6625 18.75 24 19.0875 24 19.5C24 19.9125 23.6625 20.25 23.25 20.25H14.25C13.8375 20.25 13.5 19.9125 13.5 19.5Z" fill="#08AAB4"/>
            </svg>
        </SvgIcon>
    );
}