import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { YOUTUBE_CLIPS } from '../constants';
import SectionHeader from '../SectionHeader';
import SectionChapter from '../SectionChapter';

const GroupManagement = ({ language, refs }) => {
    const groupsCreateSet = [
        {
            id: 'tutorial.manageGroups.createGroup.explanation1',
            img: [`/static/asset/img/tutorial/withText/${language.toUpperCase()}/firstPage_createGroup.jpeg`],
        },
        {
            id: 'tutorial.manageGroups.createGroup.explanation2',
            img: [`/static/asset/img/tutorial/withText/${language.toUpperCase()}/myGroups_createGroup.jpeg`],
        },
    ];

    const myGroupsSet = [
        {
            id: 'tutorial.lessonStart.myGroups.description',
        },
        {
            id: 'tutorial.lessonStart.myGroups.explanation',
            children: (
                <ul>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.lessonStart.myGroups.explanation.1"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.lessonStart.myGroups.explanation.2"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.lessonStart.myGroups.explanation.3"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>
                </ul>
            ),
            img: [`/static/asset/img/tutorial/withText/${language.toUpperCase()}/myGroups_startLesson.jpeg`],
        },
    ];

    const groupsEditSet = [
        {
            id: 'tutorial.manageGroups.edit.description',
        },
        {
            id: 'tutorial.manageGroups.edit.explanation1',
            children: (
                <ul>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.manageGroups.edit.list.term1"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.manageGroups.edit.list.term2"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.manageGroups.edit.list.term5"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.manageGroups.edit.list.term3"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.manageGroups.edit.list.term3.explanation"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>
                </ul>
            ),
            img: [`/static/asset/img/tutorial/withText/${language.toUpperCase()}/editGroup_pageDescription.jpeg`],
        },
        {
            id: 'tutorial.manageGroups.edit.list.term4',
            children: (
                <ul>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.manageGroups.edit.list.term4.explanation"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                        <ul>
                            <li>
                                <Typography>
                                    <FormattedMessage
                                        id="tutorial.manageGroups.edit.list.term4.explanation.term1"
                                        values={{
                                            b: (chunks) => <b>{chunks}</b>,
                                        }}
                                    />
                                </Typography>
                            </li>
                            <li>
                                <Typography>
                                    <FormattedMessage
                                        id="tutorial.manageGroups.edit.list.term4.explanation.term3"
                                        values={{
                                            b: (chunks) => <b>{chunks}</b>,
                                        }}
                                    />
                                </Typography>
                            </li>
                            <li>
                                <Typography>
                                    <FormattedMessage
                                        id="tutorial.manageGroups.edit.list.term4.explanation.term5"
                                        values={{
                                            b: (chunks) => <b>{chunks}</b>,
                                        }}
                                    />
                                </Typography>
                            </li>
                        </ul>
                    </li>
                </ul>
            ),
            img: [
                `/static/asset/img/tutorial/withText/${language.toUpperCase()}/editGroup_members2.jpeg`,
                `/static/asset/img/tutorial/withText/${language.toUpperCase()}/editGroup_members1.jpeg`,
            ],
        },
    ];
    return (
        <>
            <SectionHeader
                refs={refs}
                title={'tutorial.manageGroups.title'}
                description={'tutorial.manageGroups.description'}
                id={'section-manageGroups'}
                youtubeSrc={YOUTUBE_CLIPS.GROUPS[language.toUpperCase()]}
                imageTop={false}
            />
            <SectionChapter
                refs={refs}
                id={'section-manageGroups-create'}
                title={'tutorial.manageGroups.createGroup'}
                explanations={groupsCreateSet}
                imageTop={false}
            />
            <SectionChapter
                refs={refs}
                id={'section-myGroups'}
                title={'tutorial.lessonStart.myGroups'}
                explanations={myGroupsSet}
                imageTop={false}
            />

            <SectionChapter
                refs={refs}
                id={'section-manageGroups-edit'}
                title={'tutorial.manageGroups.edit'}
                explanations={groupsEditSet}
                imageTop={false}
            />
        </>
    );
};

export default GroupManagement;
