import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { featureLinks } from '../../../common/features';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { StartGroupButton } from '../../../MyComponents/StartGroupButton/StartGroupButton';
import { FindMoreButton } from '../../Home/HomeFeatures/FindMoreButton';
import { COLORS2 } from '../../../constants';
import Divider from '@material-ui/core/Divider';
import { tutorialLinks } from '../../../common/urlLinks';
import { ArrowForwardIos } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    gridWrap: {
        height: '100%',
        background: 'linear-gradient(180deg, rgba(251, 248, 245, 0) 0%, #FFFFFF 93.57%)',
        borderRadius: '0rem 0rem 2rem 2rem',
        paddingTop: '10rem',
        paddingBottom: '2rem',
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        minHeight: '13rem',
        background: 'transparent',
    },
    lines1: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '2rem',
    },
    lines2: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '-1rem',
        marginBottom: '-1rem',
    },
    lines3: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: '2.25rem',
    },
    columnFlexCenter: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        paddingTop: '3rem',
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
        padding: '1rem',
    },
    findOutMoreText: {
        color: COLORS2.virtualAmber,
        fontStyle: 'normal',
        fontWeight: '700',
        // borderBottom: '0.125rem solid #D26828',
    },
    imgContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    sectionTitle: {
        padding: '2rem',
    },
    sectionStep: {
        paddingLeft: '10%',
        textAlign: 'left',
    },
    stepStart: {
        fontWeight: '500',
        fontSize: '2.0rem',
    },
    subHeading: { fontWeight: '700', fontSize: '2.5rem' },
    sectionContent: {
        textAlign: 'center',
    },
    stepContent: {
        paddingLeft: '10%',
        display: 'flex',
        alignItems: 'center',
    },
}));

export const ForTutoringFeaturesDesktop = ({ handleOpen }) => {
    const classes = useStyles();

    const transitionToTop = () => {
        setTimeout(() => {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }, 200);
    };

    return (
        <>
            <Grid container className={classes.gridWrap}>
                <Grid item xs={12}>
                    <Typography variant="h2" align="center">
                        <FormattedMessage
                            id={'redesign.forTutoring.title'}
                            values={{
                                b: (chunks) => (
                                    <b>
                                        <br></br>
                                        {chunks}
                                    </b>
                                ),
                            }}
                        />
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h4" align="center">
                        <FormattedMessage id={'redesign.forTutoring.subtitle3'} />
                    </Typography>
                </Grid>

                <Grid item xs={12} className={classes.columnFlexCenter}>
                    <Grid item xs={12} className={classes.flexCenter}>
                        <Grid item xs={12} className={classes.imgContainer}>
                            <img src={`/static/asset/img/tutoring/elevi_diferite_nivele.png`} alt="VBoard" width="70%" />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h3" align="left">
                                <FormattedMessage
                                    id="redesign.page.forTutoring.feature.individualBoards.tag.v2"
                                    values={{
                                        b: (chunks) => <b>{chunks}</b>,
                                    }}
                                />
                            </Typography>
                            <Typography
                                variant="h6"
                                align="left"
                                style={{ color: COLORS2.virtualText, paddingBottom: '2rem', paddingTop: '1rem' }}
                            >
                                <FormattedMessage
                                    id="redesign.page.home.forTutoring.individualBoards.description.v2"
                                    values={{
                                        a: (chunks) => (
                                            <a href={featureLinks.individualBoards} className={classes.findOutMoreText}>
                                                {chunks}
                                            </a>
                                        ),
                                        b: (chunks) => (
                                            <a href={featureLinks.availableLibrary} className={classes.findOutMoreText}>
                                                {chunks}
                                            </a>
                                        ),
                                        c: (chunks) => (
                                            <a href={featureLinks.paste} className={classes.findOutMoreText}>
                                                {chunks}
                                            </a>
                                        ),
                                    }}
                                />
                            </Typography>
                            <Link to={tutorialLinks.individualBoards}>
                                <FindMoreButton />
                            </Link>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className={classes.flexCenter}>
                        <Grid item xs={12}>
                            <Typography variant="h3" align="left">
                                <FormattedMessage
                                    id="redesign.page.home.forTutoring.saveActivity.tag.v2"
                                    values={{
                                        b: (chunks) => (
                                            <b>
                                                <br></br>
                                                {chunks}
                                            </b>
                                        ),
                                    }}
                                />
                            </Typography>
                            <Typography
                                variant="h6"
                                align="left"
                                style={{ color: COLORS2.virtualText, paddingBottom: '2rem', paddingTop: '1rem' }}
                            >
                                <FormattedMessage
                                    id="redesign.page.home.forTutoring.saveActivity.description.v2"
                                    values={{
                                        a: (chunks) => (
                                            <a href={featureLinks.pdfDownload} className={classes.findOutMoreText}>
                                                {chunks}
                                            </a>
                                        ),
                                    }}
                                />
                            </Typography>
                            <Link to={tutorialLinks.saveIndividualBoards}>
                                <FindMoreButton />
                            </Link>
                        </Grid>
                        <Grid item xs={12} className={classes.imgContainer}>
                            <img src={`/static/asset/img/tutoring/salvare_activitate_sesiune.png`} alt="VBoard" width="70%" />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className={classes.flexCenter}>
                        <Grid item xs={12} className={classes.imgContainer}>
                            <img src={`/static/asset/img/tutoring/conferinta_audio_video.png`} alt="VBoard" width="70%" />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h3" align="left">
                                <FormattedMessage
                                    id="redesign.page.home.forTutoring.audioVideo.tag.v2"
                                    values={{
                                        b: (chunks) => (
                                            <b>
                                                <br></br>
                                                {chunks}
                                            </b>
                                        ),
                                    }}
                                />
                            </Typography>
                            <Typography
                                variant="h6"
                                align="left"
                                style={{ color: COLORS2.virtualText, paddingBottom: '2rem', paddingTop: '1rem' }}
                            >
                                <FormattedMessage
                                    id="redesign.page.home.forTutoring.audioVideo.description.v2"
                                    values={{
                                        a: (chunks) => (
                                            <a href={featureLinks.audioVideo} className={classes.findOutMoreText}>
                                                {chunks}
                                            </a>
                                        ),
                                    }}
                                />
                            </Typography>
                            <Link to={tutorialLinks.audioVideo}>
                                <FindMoreButton />
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    item
                    xs={12}
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: '2rem', paddingTop: '3rem' }}
                    onClick={transitionToTop}
                >
                    <StartGroupButton
                        handleOpen={() => {
                            handleOpen(false);
                        }}
                    />
                </Grid>

                <Grid item className={classes.sectionTitle} xs={12}>
                    <Typography variant={'h2'} className={classes.subHeading}>
                        <FormattedMessage id={'redesign.forTutoring.howTo'} />
                    </Typography>
                </Grid>
                <Divider />
                <Grid item xs={12} className={classes.sectionContent}>
                    <div className={classes.sectionStep}>
                        <Typography variant={'h3'} className={classes.stepStart}>
                            <FormattedMessage id={'redesign.forTutoring.step'} values={{ step: 1 }} />
                        </Typography>

                        <Typography variant={'h4'} className={classes.stepContent}>
                            <ArrowForwardIos style={{ marginRight: '1rem' }} />
                            <FormattedMessage id={'redesign.forTutoring.createGroup.v2'} />
                        </Typography>
                    </div>
                    <Link to={tutorialLinks.groups}>
                        <FindMoreButton />
                    </Link>
                </Grid>

                <Divider />
                <Grid item xs={12} className={classes.sectionContent}>
                    <div className={classes.sectionStep}>
                        <Typography variant={'h3'} className={classes.stepStart}>
                            <FormattedMessage id={'redesign.forTutoring.step'} values={{ step: 2 }} />
                        </Typography>
                        <Typography variant={'h4'} className={classes.stepContent}>
                            <ArrowForwardIos style={{ marginRight: '1rem' }} />
                            <FormattedMessage id={'redesign.forTutoring.startConference.v2'} />
                        </Typography>
                    </div>
                    <Link to={tutorialLinks.audioVideo}>
                        <FindMoreButton />
                    </Link>
                </Grid>

                <Divider />
                <Grid item xs={12} className={classes.sectionContent}>
                    <div className={classes.sectionStep}>
                        <Typography variant={'h3'} className={classes.stepStart}>
                            <FormattedMessage id={'redesign.forTutoring.step'} values={{ step: 3 }} />
                        </Typography>
                        <Typography variant={'h4'} className={classes.stepContent}>
                            <ArrowForwardIos style={{ marginRight: '1rem' }} />
                            <FormattedMessage id={'redesign.forTutoring.startIndividual.v2'} />
                        </Typography>
                    </div>
                    <Link to={tutorialLinks.individualBoards}>
                        <FindMoreButton />
                    </Link>
                </Grid>
                <Divider />
                <Grid item xs={12} className={classes.sectionContent}>
                    <div className={classes.sectionStep}>
                        <Typography variant={'h3'} className={classes.stepStart}>
                            <FormattedMessage id={'redesign.forTutoring.step'} values={{ step: 4 }} />
                        </Typography>
                        <Typography variant={'h4'} className={classes.stepContent}>
                            <ArrowForwardIos style={{ marginRight: '1rem' }} />
                            <FormattedMessage id={'redesign.forTutoring.loadFromLib.v2'} />
                        </Typography>
                    </div>
                    <Link to={tutorialLinks.library}>
                        <FindMoreButton />
                    </Link>
                </Grid>
                <Divider />
                <Grid item xs={12} className={classes.sectionContent} style={{ paddingBottom: '2rem' }}>
                    <div className={classes.sectionStep}>
                        <Typography variant={'h3'} className={classes.stepStart}>
                            <FormattedMessage id={'redesign.forTutoring.step'} values={{ step: 5 }} />
                        </Typography>
                        <Typography variant={'h4'} className={classes.stepContent}>
                            <ArrowForwardIos style={{ marginRight: '1rem' }} />
                            <FormattedMessage id={'redesign.forTutoring.download.v2'} />
                        </Typography>
                    </div>
                    <Link to={featureLinks.pdfDownload}>
                        <FindMoreButton />
                    </Link>
                </Grid>
            </Grid>
        </>
    );
};
