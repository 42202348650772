import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import {
    BOARD_TYPE,
    COLORS2,
    LIBRARY_MANAGER_STATE,
    TOOL_BRUSH,
    TOOL_ERASER,
    TOOL_MATH_CONVERTER,
    TOOL_PEN,
    TOOL_POINTER,
    TOOL_SELECT,
    TOOL_TEXT,
} from '../../../constants';
import Fab from '@material-ui/core/Fab';
import {
    changeEraserWeight,
    changeTextWeight,
    changeTool,
    hideSpinner,
    loadLessonPages,
    pickLessonPage,
    showError,
    showInfo,
    showSpinner,
    upgradeSubscriptionDialog,
} from '../../../redux/actions';
import { Input, ListItemText, Paper, Tooltip, Typography } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import ImageIcon from '@material-ui/icons/Image';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import store from '../../../redux/store';
import ZoomBox from './ZoomBox';
import EraserIcon from '../../icons/EraserIcon';
import StyledMenu from '../StyledMenu';
import UndoIcon from '@material-ui/icons/Undo';

import ToolBox from './ToolBox';
import ToolWeightColor from './ToolWeightColor';
import GridIcon from '../../icons/GridIcon';
import ToolNavLesson from './ToolNavLesson';
import { DeleteForever } from '@material-ui/icons';
import SaveIcon from '@material-ui/icons/Save';
import VBToolTip from '../../Tooltip/VBToolTip';
import ImageCropper from './ImageCropper';
import { jsPDF } from 'jspdf';
import boardManager from '../../../pages/LessonPage/boardManager';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import TitleIcon from '@material-ui/icons/Title';
import CursorIcon from '../../icons/CursorIcon';
import {
    LINEAGE_DICATANDO,
    LINEAGE_MATH,
    LINEAGE_MUSIC,
    LINEAGE_NONE,
    LINEAGE_TYPE_1,
    LINEAGE_TYPE_2,
    LINEAGE_MM,
} from '../../../pages/LessonPage/drawConstants';
import drawUtils from '../../../pages/LessonPage/drawUtils';
import BrushIcon from '@material-ui/icons/Brush';
import WandIcon from '../../icons/WandIcon';
import { GA_EVENT_TREE } from '../../../gaConstants';
import BoardCollectionDialog from './BoardCollectionDialog';
import { CAPABILITIES, hasBooleanPermission } from '../../../shared/Capabilities';
import OpenFolder from '../../icons/OpenFolder';
import PasteIcon from '../../icons/PasteIcon';
import ClearSingleIcon from '../../icons/ClearSingleIcon';
import ClearMultipleIcon from '../../icons/ClearMultipleIcon';
import cloudBoardManager from '../../../pages/LessonPage/cloudBoardManager';
import SaveAllToLib from '../../icons/SaveAllToLib';
import SaveSingleToLib from '../../icons/SaveSingleToLib';
import MathConvertIcon from '../../icons/MathConvertIcon';
import IconButton from '@material-ui/core/IconButton';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import LatexEditDialog from '../LatexEditDialog';
import RulerProtractor from '../../icons/RulerProtractor';
import RulerIcon from '../../icons/RulerIcon';
import ProtractorIcon from '../../icons/ProtractorIcon';
import RulerProtractorCompass from '../../icons/RulerProtractorCompass';
import CompassIcon from '../../icons/CompassIcon';
import GoogleIcon from '../../icons/GoogleIcon';
import GoogleSearchDialog from './GoogleSearchDialog';

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    toolBorder: {
        borderColor: 'rgba(0,0,0,0.3)',
        borderWidth: 'thin',
        borderTopStyle: 'solid',
        borderBottomStyle: 'solid',
    },
    toolBorderDown: {
        borderColor: 'rgba(0,0,0,0.3)',
        borderWidth: 'thin',
        borderBottomStyle: 'solid',
    },
    toolBorderTop: {
        borderColor: 'rgba(0,0,0,0.3)',
        borderWidth: 'thin',
        borderTopStyle: 'solid',
    },
    menuText: {
        fontWeight: 400,
        fontSize: '1rem',
        lineHeight: '1.75rem',
        letterSpacing: '-0.01rem',
        color: COLORS2.darkGreen,
    },
}));

const ToolPickerFloat = (props) => {
    const classes = useStyles();

    const [selectorAvailable, setSelectorAvailable] = useState(false);
    const [anchorImage, setAnchorImage] = React.useState(null);
    const [anchorGeometryTool, setAnchorGeometryTool] = React.useState(null);
    const [anchorClear, setAnchorClear] = React.useState(null);
    const [anchorSaveToLib, setAnchorSaveToLib] = React.useState(null);
    const [anchorGrid, setAnchorGrid] = React.useState(null);
    const [dialogClear, setDialogClear] = React.useState(0);
    const [dialogFileOpen, setDialogFileOpen] = React.useState(false);
    const [dialogCollectionSave, setDialogCollectionSave] = React.useState(null);
    const [dialogFileName, setDialogFileName] = React.useState('');
    // const [dialogInputText, setDialogInputText] = React.useState('');
    // const [dialogTextOpen, setDialogTextOpen] = React.useState(false);
    const [dialogImgUploadOpen, setDialogImgUploadOpen] = React.useState(false);
    const [dialogGoogleSearchOpen, setDialogGoogleSearchOpen] = React.useState(false);

    window.checkIfStickyElementsToolPicker = () => {
        // let lessonId = lessonList.getLessonId(
        //     props.profile.email,
        //     props.lessonState.classId,
        //     props.lessonState.currentLessonPage
        // );
        //
        // let elements = lessonList.getStickyLessonForEmail(props.profile.email, props.lessonState.classId, lessonId)
        //     .stickyElements;
        if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
            let board = boardManager.getCurrentMainBoard();
            if (!board) return;
            let elements = board.meta.stickyElements;
            if (elements && elements.length > 0) {
                if (boardManager.isAdmin) {
                    setSelectorAvailable(true);
                } else {
                    let shouldShow = false;
                    elements.forEach((el) => {
                        if (el.creator !== boardManager.adminEmail) {
                            shouldShow = true;
                        }
                    });
                    setSelectorAvailable(shouldShow);
                }
            } else {
                if (selectorAvailable) {
                    store.dispatch(changeTool(TOOL_PEN));
                    if (window.toolSelectionCallback) window.toolSelectionCallback(TOOL_PEN);
                }
                setSelectorAvailable(false);
            }
        } else {
            if (
                !boardManager ||
                !boardManager.currentBoardActive ||
                !boardManager.lessonState ||
                !boardManager.lessonState.individualBoards ||
                !boardManager.lessonState.individualBoards[boardManager.currentBoardActive]
            ) {
                return;
            }
            let userState = boardManager.lessonState.individualBoards[boardManager.currentBoardActive];
            if (boardManager.currentBoardActive && userState && userState.pages[userState.currentPageNo]) {
                let elements = userState.pages[userState.currentPageNo].meta.stickyElements;
                if (elements && elements.length > 0) {
                    setSelectorAvailable(true);
                } else {
                    if (selectorAvailable) {
                        store.dispatch(changeTool(TOOL_PEN));
                        if (window.toolSelectionCallback) window.toolSelectionCallback(TOOL_PEN);
                    }
                    setSelectorAvailable(false);
                }
            }
        }
    };

    const selectEraser = (weight) => {
        store.dispatch(changeTool(TOOL_ERASER));
        window.logAppActivity(GA_EVENT_TREE.lessonPage.toolSelection.eraser);
        if (weight) {
            store.dispatch(changeEraserWeight(weight));
            handleClose();
        }

        if (window.toolSelectionCallback) window.toolSelectionCallback(TOOL_ERASER);
    };

    let selectBrush = () => {
        window.logAppActivity(GA_EVENT_TREE.lessonPage.toolSelection.brush);
        store.dispatch(changeTool(TOOL_BRUSH));
        if (window.toolSelectionCallback) window.toolSelectionCallback(TOOL_BRUSH);
    };

    let selectSelector = () => {
        if (props.drawing.tool === TOOL_SELECT) {
            store.dispatch(changeTool(TOOL_PEN));
            if (window.toolSelectionCallback) window.toolSelectionCallback(TOOL_PEN);
        } else {
            window.logAppActivity(GA_EVENT_TREE.lessonPage.toolSelection.selector);
            store.dispatch(changeTool(TOOL_SELECT));
            if (window.switchToStickySelection) window.switchToStickySelection(true);
            if (window.toolSelectionCallback) window.toolSelectionCallback(TOOL_SELECT);
        }

        handleClose();
    };

    let selectPointer = () => {
        if (props.drawing.tool === TOOL_POINTER) {
            store.dispatch(changeTool(TOOL_PEN));
            if (window.toolSelectionCallback) window.toolSelectionCallback(TOOL_PEN);
        } else {
            window.logAppActivity(GA_EVENT_TREE.lessonPage.toolSelection.pointer);
            store.dispatch(changeTool(TOOL_POINTER));
            if (window.switchToStickySelection) window.switchToStickySelection(true);
            if (window.toolSelectionCallback) window.toolSelectionCallback(TOOL_POINTER);
        }

        handleClose();
    };

    const selectText = (weight) => {
        if (props.drawing.tool === TOOL_TEXT) {
            store.dispatch(changeTool(TOOL_PEN));
            if (window.toolSelectionCallback) window.toolSelectionCallback(TOOL_PEN);
        } else {
            window.logAppActivity(GA_EVENT_TREE.lessonPage.toolSelection.text);
            store.dispatch(changeTool(TOOL_TEXT));
            if (weight) {
                store.dispatch(changeTextWeight(weight));
                handleClose();
            }
            if (window.switchToTextSelection) window.switchToTextSelection(true);
            if (window.toolSelectionCallback) window.toolSelectionCallback(TOOL_TEXT);
        }
    };
    const checkClipboard = () => {
        if (navigator?.clipboard) {
            try {
                navigator.permissions
                    .query({ name: 'clipboard-read' })
                    .then((result) => {
                        // If permission to read the clipboard is granted or if the user will
                        // be prompted to allow it, we proceed.

                        if (result.state === 'granted' || result.state === 'prompt') {
                            navigator.clipboard
                                .read()
                                .then((data) => {
                                    for (let i = 0; i < data.length; i++) {
                                        if (!data[i].types.includes('image/png')) {
                                            store.dispatch(showError('NO_IMAGE_IN_CLIPBOARD'));
                                        } else {
                                            data[i]
                                                .getType('image/png')
                                                .then(drawUtils.imageDataFromBlob)
                                                .then((elem) => {
                                                    if (window.handleCroppedImage) {
                                                        window.handleCroppedImage(elem.data, elem.width, elem.height);
                                                        window.logAppActivity(
                                                            GA_EVENT_TREE.lessonPage.toolSelection
                                                                .imagePasteFromClipboard,
                                                        );
                                                    }
                                                })
                                                .catch((err) => {
                                                    console.error('Unable to paste', err);
                                                });
                                        }
                                    }
                                })
                                .catch((err) => {
                                    console.error('Unable to read', err);
                                    store.dispatch(showError('NO_IMAGE_IN_CLIPBOARD'));
                                });
                        } else {
                            store.dispatch(showError('NO_ACCESS_TO_CLIPBOARD'));
                        }
                    })
                    .catch(() => {
                        store.dispatch(showError('NO_ACCESS_TO_CLIPBOARD'));
                    });
            } catch (e) {
                store.dispatch(showError('NO_ACCESS_TO_CLIPBOARD'));
            }
        }
        handleClose();
    };

    const searchGoogle = () => {
        setDialogGoogleSearchOpen(true);
    };

    // const writeText = () => {
    //     handleClose();
    //     if (window.toolSelectionCallback) window.toolSelectionCallback(dialogInputText);
    // };

    useEffect(() => {
        store.dispatch(changeTool(TOOL_PEN));
        if (window.toolSelectionCallback) window.toolSelectionCallback(TOOL_PEN);
    }, [props.lessonState.allowDrawing]);

    const generatePdf = async () => {
        let canDownload = hasBooleanPermission(
            props.subscriptions,
            props.profile.subscription,
            CAPABILITIES.PDF_DOWNLOAD,
        );

        store.dispatch(showSpinner());

        if (boardManager.lessonState.boardType === BOARD_TYPE.MULTI_BOARD && !boardManager.isAdmin) {
            // allowing download
            const doc = new jsPDF({
                orientation: 'landscape',
                unit: 'mm',
                format: [297, 210],
                compress: true,
            });

            // console.log('==== orig mb state', boardManager.originalMultiBoardState);
            let lessonData = boardManager.currentBoards[boardManager.currentBoardActive];
            if (lessonData && lessonData.pages && lessonData.pages.length > 0) {
                for (let i = 0; i < lessonData.pages.length; i++) {
                    let data;
                    if (i === 0) {
                        if (i === lessonData.currentPageNo) {
                            data = await drawUtils.buildComposedImageForMultiBoard(
                                lessonData.pages[i].meta.gridType,
                                boardManager.originalMultiBoardState.originalDownload,
                                boardManager.originalMultiBoardState.originalSticky,
                                lessonData.pages[i].meta.stickyElements,
                                null,
                                null,
                                'multiBoardChildCanvas-' + boardManager.adminEmail,
                            );
                        } else {
                            data = await drawUtils.buildComposedImageForMultiBoard(
                                lessonData.pages[i].meta.gridType,
                                boardManager.originalMultiBoardState.originalDownload,
                                boardManager.originalMultiBoardState.originalSticky,
                                lessonData.pages[i].meta.stickyElements,
                                null,
                                lessonData.pages[i].download,
                            );
                        }
                    } else {
                        if (i === lessonData.currentPageNo) {
                            data = await drawUtils.buildComposedImageForMultiBoard(
                                lessonData.pages[i].meta.gridType,
                                null,
                                null,
                                lessonData.pages[i].meta.stickyElements,
                                null,
                                null,
                                'multiBoardChildCanvas-' + boardManager.adminEmail,
                            );
                        } else {
                            data = await drawUtils.buildComposedImageForMultiBoard(
                                lessonData.pages[i].meta.gridType,
                                null,
                                null,
                                lessonData.pages[i].meta.stickyElements,
                                null,
                                lessonData.pages[i].download,
                            );
                        }
                    }

                    if (data) {
                        doc.addImage(data, 'png', 10, 35, 280, 140, '', 'FAST');
                    }
                    if (i < lessonData.pages.length - 1) {
                        doc.addPage('a4', 'landscape');
                    }
                }
                window.logAppActivity(GA_EVENT_TREE.lessonPage.toolSelection.downloadPDF, lessonData.pages.length);
            }
            let name = 'download';
            if (
                props.lessonState &&
                props.lessonState.lessonInfo &&
                props.lessonState.lessonInfo.classData &&
                props.lessonState.lessonInfo.classData.name
            ) {
                name = props.lessonState.lessonInfo.classData.name;
            }
            name = name + ' - ' + new Date().toLocaleString();
            doc.save(name);
            handleClose();
            store.dispatch(hideSpinner());
            return;
        } else {
            if (!canDownload.value) {
                store.dispatch(
                    upgradeSubscriptionDialog({
                        key: 'subscription.upsell.pdf.unavailable',
                        subscriptions: canDownload.availableSubscriptions,
                    }),
                );
                handleClose();
                store.dispatch(hideSpinner());
                return;
            }
        }

        const doc = new jsPDF({
            orientation: 'landscape',
            unit: 'mm',
            format: [297, 210],
            compress: true,
        });

        let mainPages = boardManager.lessonState.mainBoardPages;
        if (mainPages && mainPages.length > 0) {
            for (let i = 0; i < mainPages.length; i++) {
                let data = await drawUtils.buildComposedImageForMainPage(
                    props.profile.email,
                    props.lessonState.classId,
                    mainPages[i].id,
                    true,
                );

                if (data) {
                    doc.addImage(data, 'png', 10, 35, 280, 140, '', 'FAST');
                    // doc.addImage(data, 'png', 10, 35, 280, 140);
                }
                if (i < mainPages.length - 1) {
                    doc.addPage('a4', 'landscape');
                }
            }
            window.logAppActivity(GA_EVENT_TREE.lessonPage.toolSelection.downloadPDF, mainPages.length);
        }
        let name = 'download';
        if (
            props.lessonState &&
            props.lessonState.lessonInfo &&
            props.lessonState.lessonInfo.classData &&
            props.lessonState.lessonInfo.classData.name
        ) {
            name = props.lessonState.lessonInfo.classData.name;
        }
        name = name + ' - ' + new Date().toLocaleString();
        doc.save(name);
        store.dispatch(hideSpinner());
        handleClose();
    };

    const selectImage = () => {
        // if (window.triggerUploadCallback) window.triggerUploadCallback();
        window.logAppActivity(GA_EVENT_TREE.lessonPage.toolSelection.imageInsert);
        setDialogImgUploadOpen(true);
        // handleClose();
    };

    const selectRuler = () => {
        if (window.showRuler) window.showRuler();
        window.logAppActivity(GA_EVENT_TREE.lessonPage.toolSelection.selectRuler);
        // setDialogImgUploadOpen(true);
        handleClose();
    };

    const selectCompass = () => {
        if (window.showCompass) window.showCompass();
        window.logAppActivity(GA_EVENT_TREE.lessonPage.toolSelection.selectCompass);
        // setDialogImgUploadOpen(true);
        handleClose();
    };

    const selectProtractor = () => {
        if (window.showProtractor) window.showProtractor();
        window.logAppActivity(GA_EVENT_TREE.lessonPage.toolSelection.selectProtractor);
        handleClose();
    };

    const clearImage = () => {
        window.logAppActivity(GA_EVENT_TREE.lessonPage.toolSelection.clear);
        if (window.triggerClearImageCallback) window.triggerClearImageCallback();
        handleClose();
    };
    const clearAllBoards = async () => {
        window.logAppActivity(GA_EVENT_TREE.lessonPage.toolSelection.clearAllBoards);
        handleClose();
        if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD && boardManager.isAdmin) {
            if (boardManager.lessonState.mainBoardPages.length <= 1) {
                clearImage();
            } else {
                store.dispatch(
                    showSpinner({
                        messageKey: 'tool.dialog.removingBoards',
                        showProgress: true,
                        progressValue: 0,
                    }),
                );

                if (window.triggerClearImageCallback) {
                    await window.triggerClearImageCallback();
                }

                let n = boardManager.lessonState.mainBoardPages.length;
                let i = 0;

                while (boardManager.lessonState.mainBoardPages.length > 0) {
                    let pages = await cloudBoardManager.removePage(
                        boardManager.email,
                        boardManager.classId,
                        boardManager.lessonState.mainBoardPages[0].id,
                    );
                    boardManager.lessonState.mainBoardPages = pages;
                    let pageIds = pages.map((el) => el.id);
                    store.dispatch(loadLessonPages(pageIds));
                    boardManager.currentLessonPage = pageIds.length - 1;

                    store.dispatch(
                        showSpinner({
                            messageKey: 'tool.dialog.removingBoards',
                            showProgress: true,
                            progressValue: ((i + 1) * 100) / n,
                        }),
                    );
                    i++;
                }

                let pages = await cloudBoardManager.newPage(boardManager.email, boardManager.classId);

                boardManager.lessonState.mainBoardPages = pages;
                let pageIds = pages.map((el) => el.id);
                store.dispatch(loadLessonPages(pageIds));
                boardManager.currentLessonPage = pageIds.length - 1;
                boardManager.lessonState.mainBoardId = pageIds[0];

                if (window.switchPage) {
                    await window.switchPage(0);
                }
                store.dispatch(hideSpinner());

                // let pages = await cloudBoardManager.removePage(
                //     boardManager.email,
                //     boardManager.classId,
                //     boardManager.mainBoardPages[pageNo].id
                // );
                // boardManager.mainBoardPages = pages;
                // let pageIds = pages.map((el) => el.id);
                // store.dispatch(loadLessonPages(pageIds));
            }
        }

        // if (window.triggerClearImageCallback) window.triggerClearImageCallback();
    };

    const clearImageDialog = (value) => {
        setDialogClear(value);
    };

    const saveImage = async () => {
        if (window.triggerSaveImageCallback) {
            if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
                // TODO there is a mixup between lineage and current lineage somewhere
                let board = boardManager.getCurrentMainBoard();
                if (!board) {
                    console.error('no board found');
                    return;
                }
                let data = await drawUtils.buildComposedImageForStudentMainPage(
                    drawUtils.getCanvas().toDataURL('png', 1.0),
                    board.meta.stickyElements,
                    board.meta.gridType,
                );
                window.triggerSaveImageCallback(dialogFileName, data);
                window.logAppActivity(GA_EVENT_TREE.lessonPage.toolSelection.downloadImage);
            } else {
                console.log('=== no single');
            }
        } else {
            console.log('=== no trigger');
        }
        handleClose();
    };

    const buildNone = () => {
        // if (window.triggerClearImageCallback) window.triggerClearImageCallback();
        window.logAppActivity(GA_EVENT_TREE.lessonPage.toolSelection.lineageNone);
        props.switchLineage(LINEAGE_NONE);
        handleClose();
    };

    const buildTip1 = () => {
        // if (window.buildTip1) window.buildTip1();
        window.logAppActivity(GA_EVENT_TREE.lessonPage.toolSelection.lineageType1);
        props.switchLineage(LINEAGE_TYPE_1);
        handleClose();
    };

    const buildTip2 = () => {
        // if (window.buildTip2) window.buildTip2();
        window.logAppActivity(GA_EVENT_TREE.lessonPage.toolSelection.lineageType2);
        props.switchLineage(LINEAGE_TYPE_2);
        handleClose();
    };

    const buildDictando = () => {
        // if (window.buildDictando) window.buildDictando();
        window.logAppActivity(GA_EVENT_TREE.lessonPage.toolSelection.lineageDictando);
        props.switchLineage(LINEAGE_DICATANDO);
        handleClose();
    };

    const buildMath = () => {
        // if (window.buildMath) window.buildMath();
        window.logAppActivity(GA_EVENT_TREE.lessonPage.toolSelection.lineageMath);
        props.switchLineage(LINEAGE_MATH);
        handleClose();
    };

    const buildMusic = () => {
        // if (window.buildMusic) window.buildMusic();
        window.logAppActivity(GA_EVENT_TREE.lessonPage.toolSelection.lineageMusic);
        props.switchLineage(LINEAGE_MUSIC);
        handleClose();
    };

    const buildMM = () => {
        // if (window.buildMusic) window.buildMusic();
        window.logAppActivity(GA_EVENT_TREE.lessonPage.toolSelection.lineageMM);
        props.switchLineage(LINEAGE_MM);
        handleClose();
    };

    let imageUpload = (
        <ListItemIcon>
            <AddPhotoAlternateIcon color='primary' />
        </ListItemIcon>
    );

    let imagePdf = (
        <ListItemIcon>
            <PictureAsPdfIcon color='primary' />
        </ListItemIcon>
    );

    let imageSave = (
        <ListItemIcon>
            <SaveAltIcon color='primary' />
        </ListItemIcon>
    );
    let imagePaste = (
        <ListItemIcon>
            <PasteIcon color='primary' />
        </ListItemIcon>
    );
    let googleImage = (
        <ListItemIcon>
            <GoogleIcon color='primary' />
        </ListItemIcon>
    );
    if (!boardManager.lessonState) return null;

    let downloadImageMenuItem = (
        <MenuItem dense disableGutters disabled={boardManager.lessonState.boardType === BOARD_TYPE.MULTI_BOARD}>
            <ListItem
                button
                onClick={() => {
                    setDialogFileOpen(true);
                    let fileName = `${props.lessonState.lessonInfo.classData.name} - ${new Date().toLocaleString()}`;
                    if (
                        boardManager.lessonState.boardType === BOARD_TYPE.MULTI_BOARD &&
                        boardManager.currentBoardActive &&
                        boardManager.currentBoards[boardManager.currentBoardActive].name
                    ) {
                        fileName = `${props.lessonState.lessonInfo.classData.name} - ${
                            boardManager.currentBoards[boardManager.currentBoardActive].name
                        } - ${new Date().toLocaleString()}`;
                    }
                    setDialogFileName(fileName);
                }}
            >
                {imageSave}
                <ListItemText className={classes.menuText}>
                    <FormattedMessage id={'tool.SAVE_IMAGE'} />
                </ListItemText>
            </ListItem>
        </MenuItem>
    );

    let downloadPDFMenuItem = null;
    if (
        (boardManager.lessonState.boardType !== BOARD_TYPE.MULTI_BOARD && boardManager.isAdmin) ||
        (boardManager.lessonState.boardType === BOARD_TYPE.MULTI_BOARD && !boardManager.isAdmin)
    ) {
        downloadPDFMenuItem = (
            <MenuItem dense disableGutters>
                <ListItem button onClick={generatePdf}>
                    {imagePdf}
                    <ListItemText className={classes.menuText}>
                        <FormattedMessage id={'tool.DOWNLOAD_PDF'} />{' '}
                    </ListItemText>
                </ListItem>
            </MenuItem>
        );
    }

    let imageMenu = (
        <List>
            <MenuItem dense disableGutters>
                <ListItem button onClick={selectImage}>
                    {imageUpload}
                    <ListItemText className={classes.menuText}>
                        <FormattedMessage id={'tool.UPLOAD_IMAGE'} />
                    </ListItemText>
                </ListItem>
            </MenuItem>
            {downloadImageMenuItem}
            {downloadPDFMenuItem}
            {navigator.clipboard ? (
                <MenuItem dense disableGutters>
                    <ListItem button onClick={checkClipboard}>
                        {imagePaste}
                        <ListItemText className={classes.menuText}>
                            <FormattedMessage id={'tool.PASTE_IMAGE'} />{' '}
                        </ListItemText>
                    </ListItem>
                </MenuItem>
            ) : null}
            <MenuItem dense disableGutters>
                <ListItem button onClick={searchGoogle}>
                    {googleImage}
                    <ListItemText className={classes.menuText}>
                        <FormattedMessage id={'generic.label.googleSearch'} />
                    </ListItemText>
                </ListItem>
            </MenuItem>
        </List>
    );

    let geometryToolMenu = (
        <List>
            <MenuItem dense disableGutters>
                <ListItem button onClick={selectRuler}>
                    <ListItemIcon>
                        <RulerIcon color='primary' />
                    </ListItemIcon>
                    <ListItemText className={classes.menuText}>
                        <FormattedMessage id={'tooltip.lessonPage.ruler'} />
                    </ListItemText>
                </ListItem>
            </MenuItem>
            <MenuItem dense disableGutters>
                <ListItem button onClick={selectProtractor}>
                    <ListItemIcon>
                        <ProtractorIcon color='primary' />
                    </ListItemIcon>
                    <ListItemText className={classes.menuText}>
                        <FormattedMessage id={'tooltip.lessonPage.protractor'} />
                    </ListItemText>
                </ListItem>
            </MenuItem>
            <MenuItem dense disableGutters>
                <ListItem button onClick={selectCompass}>
                    <ListItemIcon>
                        <CompassIcon color='primary' />
                    </ListItemIcon>
                    <ListItemText className={classes.menuText}>
                        <FormattedMessage id={'tooltip.lessonPage.compass'} />
                    </ListItemText>
                </ListItem>
            </MenuItem>
        </List>
    );

    let gridMenu = (
        <List>
            <MenuItem dense disableGutters>
                <ListItem button onClick={buildNone}>
                    <ListItemText className={classes.menuText}>
                        <FormattedMessage id='grid.none' />
                    </ListItemText>
                </ListItem>
            </MenuItem>
            <MenuItem dense disableGutters>
                <ListItem button onClick={buildTip1}>
                    <ListItemText className={classes.menuText}>
                        <FormattedMessage id='grid.type1' />
                    </ListItemText>
                </ListItem>
            </MenuItem>
            <MenuItem dense disableGutters>
                <ListItem button onClick={buildTip2}>
                    <ListItemText className={classes.menuText}>
                        <FormattedMessage id='grid.type2' />
                    </ListItemText>
                </ListItem>
            </MenuItem>
            <MenuItem dense disableGutters>
                <ListItem button onClick={buildDictando}>
                    <ListItemText className={classes.menuText}>
                        <FormattedMessage id='grid.dictando' />
                    </ListItemText>
                </ListItem>
            </MenuItem>
            <MenuItem dense disableGutters>
                <ListItem button onClick={buildMath}>
                    <ListItemText className={classes.menuText}>
                        <FormattedMessage id='grid.math' />
                    </ListItemText>
                </ListItem>
            </MenuItem>
            <MenuItem dense disableGutters>
                <ListItem button onClick={buildMusic}>
                    <ListItemText className={classes.menuText}>
                        <FormattedMessage id='grid.music' />
                    </ListItemText>
                </ListItem>
            </MenuItem>
            <MenuItem dense disableGutters>
                <ListItem button onClick={buildMM}>
                    <ListItemText className={classes.menuText}>
                        <FormattedMessage id='grid.mm' />
                    </ListItemText>
                </ListItem>
            </MenuItem>
        </List>
    );

    let clearMenu = (
        <List>
            <MenuItem dense disableGutters>
                <ListItem
                    button
                    onClick={() => {
                        clearImageDialog(1);
                    }}
                >
                    <ListItemIcon>
                        <ClearSingleIcon color='primary' />
                    </ListItemIcon>
                    <ListItemText className={classes.menuText}>
                        <FormattedMessage id={'tool.CLEAR_CURRENT_WHITEBOARD'} />
                    </ListItemText>
                </ListItem>
            </MenuItem>
            <MenuItem dense disableGutters>
                <ListItem
                    button
                    onClick={() => {
                        clearImageDialog(2);
                    }}
                    disabled={
                        !(boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD && props.lessonState.isAdmin)
                    }
                >
                    <ListItemIcon>
                        <ClearMultipleIcon color='primary' />
                    </ListItemIcon>
                    <ListItemText className={classes.menuText}>
                        <FormattedMessage id={'tool.REMOVE_ALL_WHITEBOARDS'} />
                    </ListItemText>
                </ListItem>
            </MenuItem>
        </List>
    );

    let addToLibMenu = (
        <List>
            <MenuItem dense disableGutters>
                <ListItem
                    button
                    onClick={() => {
                        setDialogCollectionSave(LIBRARY_MANAGER_STATE.SAVE);
                    }}
                >
                    <ListItemIcon>
                        <SaveSingleToLib color='primary' />
                    </ListItemIcon>
                    <ListItemText className={classes.menuText}>
                        <FormattedMessage id={'tool.ADD_CURRENT_TO_LIB'} />
                    </ListItemText>
                </ListItem>
            </MenuItem>
            <MenuItem dense disableGutters>
                <ListItem
                    button
                    onClick={() => {
                        setDialogCollectionSave(LIBRARY_MANAGER_STATE.SAVE_SET);
                    }}
                >
                    <ListItemIcon>
                        <SaveAllToLib color='primary' />
                    </ListItemIcon>
                    <ListItemText className={classes.menuText}>
                        <FormattedMessage id={'tool.ADD_ALL_TO_LIB'} />
                    </ListItemText>
                </ListItem>
            </MenuItem>
            {downloadImageMenuItem}
            {downloadPDFMenuItem}
        </List>
    );

    // const [videoBox, setVideoBox] = React.useState(true);
    // const [micOn, setMicOn] = React.useState(!callUtils.isMuted());

    const handleClick = (event, item) => {
        if (!event) {
            handleClose();
            return;
        }
        switch (item) {
            case 'IMAGE':
                setAnchorImage(event.currentTarget);
                break;
            case 'GRID':
                setAnchorGrid(event.currentTarget);
                break;
            case 'SAVE_TO_LIB':
                setAnchorSaveToLib(event.currentTarget);
                break;
            case 'CLEAR':
                setAnchorClear(event.currentTarget);
                break;
            case 'GEOMETRY-TOOL':
                setAnchorGeometryTool(event.currentTarget);
                break;
            default:
                handleClose();
        }
    };

    const handleClose = () => {
        setAnchorImage(null);
        setAnchorClear(null);
        setAnchorSaveToLib(null);
        setAnchorGrid(null);
        setDialogFileOpen(false);
        // setDialogTextOpen(false);
        setDialogImgUploadOpen(false);
        setDialogGoogleSearchOpen(false);
        setAnchorGeometryTool(false);
        setDialogClear(0);
    };

    const updateText = (e) => {
        setDialogFileName(e.target.value);
    };
    let selectMathConverter = () => {
        if (props.drawing.tool === TOOL_MATH_CONVERTER) {
            store.dispatch(changeTool(TOOL_SELECT));
            handleClose();
            if (window.convertToMath) {
                window.convertToMath();
            }
        } else {
            window.logAppActivity(GA_EVENT_TREE.lessonPage.toolSelection.mathConverter);
            store.dispatch(showInfo('START_MATH'));
            store.dispatch(changeTool(TOOL_MATH_CONVERTER));
            if (window.toolSelectionCallback) window.toolSelectionCallback(TOOL_MATH_CONVERTER);
            handleClose();
        }
    };

    // const updateInputText = (e) => {
    //     setDialogInputText(e.target.value);
    // };
    let availableHeight = window.innerHeight * 0.95;

    let allowDrawing = props.lessonState.allowDrawing || props.lessonState.boardType === BOARD_TYPE.MULTI_BOARD;

    return (
        <Paper
            style={{
                display: 'inline-block',
                verticalAlign: 'top',
                overflow: 'scroll',
                maxHeight: availableHeight + 'px',
                margin: '0 5px',
                padding: '0 5px',
                width: '100%',
                maxWidth: '140px',
                overflowX: 'hidden',
            }}
            id='toolpickerContainer'
        >
            <ZoomBox />
            {props.drawing.tool === TOOL_MATH_CONVERTER ? (
                <div style={{ paddingTop: '1rem', textAlign: 'center' }}>
                    <Button
                        onClick={(e) => {
                            e.preventDefault();
                            selectMathConverter();
                        }}
                        color={'primary'}
                        fullWidth
                        variant={'contained'}
                    >
                        <FormattedMessage id='tooltip.lessonPage.mathConvert.button' />
                    </Button>
                    <Fab
                        size='small'
                        color={'default'}
                        className={classes.margin}
                        onClick={() => {
                            if (window.undoStrokes) {
                                window.undoStrokes();
                            }
                        }}
                        style={{ display: 'inline-block' }}
                    >
                        <VBToolTip content={<FormattedMessage id='tooltip.lessonPage.undo' />}>
                            <UndoIcon style={{ verticalAlign: 'middle' }} />
                        </VBToolTip>
                    </Fab>
                    <div style={{ textAlign: 'center', paddingTop: '2rem' }}>
                        <Tooltip title={props.intl.formatMessage({ id: 'tooltip.lessonPage.mathConvert.helper' })}>
                            <IconButton>
                                <HelpOutlineIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            ) : (
                <>
                    <div className={classes.toolBorderDown}>
                        <Fab
                            size='small'
                            color={props.drawing.tool === TOOL_SELECT ? 'primary' : 'default'}
                            className={classes.margin}
                            onClick={selectSelector}
                            style={{ display: 'inline-block' }}
                            disabled={!selectorAvailable || !allowDrawing}
                        >
                            <VBToolTip content={<FormattedMessage id='tooltip.lessonPage.selector' />}>
                                <CursorIcon style={{ verticalAlign: 'middle' }} />
                            </VBToolTip>
                        </Fab>
                        <Fab
                            size='small'
                            color={props.drawing.tool === TOOL_POINTER ? 'primary' : 'default'}
                            className={classes.margin}
                            onClick={selectPointer}
                            style={{ display: 'inline-block' }}
                            disabled={!allowDrawing}
                        >
                            <VBToolTip content={<FormattedMessage id='tooltip.lessonPage.pointer' />}>
                                <WandIcon style={{ verticalAlign: 'middle' }} />
                            </VBToolTip>
                        </Fab>
                    </div>

                    <ToolBox />
                    <div>
                        <Fab
                            size='small'
                            color={props.drawing.tool === TOOL_BRUSH ? 'primary' : 'default'}
                            className={classes.margin}
                            onClick={selectBrush}
                            style={{ display: 'inline-block' }}
                            disabled={!allowDrawing}
                        >
                            <VBToolTip content={<FormattedMessage id='tooltip.lessonPage.brush' />}>
                                <BrushIcon style={{ verticalAlign: 'middle' }} />
                            </VBToolTip>
                        </Fab>
                        <Fab
                            size='small'
                            color={props.drawing.tool === TOOL_ERASER ? 'primary' : 'default'}
                            aria-label='eraser'
                            className={classes.margin}
                            onClick={(e) => {
                                e.preventDefault();
                                selectEraser();
                            }}
                            aria-controls='eraser-menu'
                            aria-haspopup='true'
                            style={{ display: 'inline-block' }}
                            disabled={!allowDrawing}
                        >
                            <VBToolTip content={<FormattedMessage id='tooltip.lessonPage.eraser' />}>
                                <EraserIcon style={{ verticalAlign: 'middle' }} />
                            </VBToolTip>
                        </Fab>
                    </div>
                    <div>
                        <Fab
                            size='small'
                            color={props.drawing.tool === TOOL_TEXT ? 'primary' : 'default'}
                            aria-label='text'
                            className={classes.margin}
                            onClick={(e) => {
                                e.preventDefault();
                                selectText();
                            }}
                            aria-controls='text-menu'
                            aria-haspopup='true'
                            style={{ display: 'inline-block' }}
                            disabled={!allowDrawing}
                        >
                            <VBToolTip content={<FormattedMessage id='tooltip.lessonPage.text' />}>
                                <TitleIcon style={{ verticalAlign: 'middle' }} />
                            </VBToolTip>
                        </Fab>

                        {props.lessonState.isAdmin && boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD ? (
                            <Fab
                                size='small'
                                color={props.drawing.tool === TOOL_MATH_CONVERTER ? 'secondary' : 'default'}
                                aria-label='text'
                                className={classes.margin}
                                onClick={(e) => {
                                    e.preventDefault();
                                    selectMathConverter();
                                }}
                                aria-controls='text-menu'
                                aria-haspopup='true'
                                style={{ display: 'inline-block' }}
                                disabled={!allowDrawing}
                            >
                                <VBToolTip content={<FormattedMessage id='tooltip.lessonPage.mathConvert' />}>
                                    <MathConvertIcon style={{ verticalAlign: 'middle' }} />
                                </VBToolTip>
                            </Fab>
                        ) : null}
                    </div>

                    {boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD ? (
                        <div className={classes.toolBorderTop}>
                            <Fab
                                size='small'
                                color='default'
                                aria-label='math'
                                className={classes.margin}
                                onClick={(e) => handleClick(e, 'GEOMETRY-TOOL')}
                                aria-controls='geometry-tool-menu'
                                aria-haspopup='true'
                                style={{ display: 'inline-block' }}
                                disabled={!allowDrawing}
                            >
                                <VBToolTip content={<FormattedMessage id='tooltip.lessonPage.geometryTools' />}>
                                    <RulerProtractorCompass style={{ verticalAlign: 'middle' }} />
                                </VBToolTip>
                            </Fab>
                            <StyledMenu
                                id='geometry-tools-menu'
                                anchorEl={anchorGeometryTool}
                                keepMounted
                                open={Boolean(anchorGeometryTool)}
                                onClose={handleClose}
                            >
                                {geometryToolMenu}
                            </StyledMenu>
                        </div>
                    ) : null}

                    <ToolWeightColor style={{ verticalAlign: 'bottom' }} />
                    <div>
                        <Fab
                            size='small'
                            color='default'
                            aria-label='image'
                            className={classes.margin}
                            onClick={(e) => handleClick(e, 'IMAGE')}
                            aria-controls='image-menu'
                            aria-haspopup='true'
                            style={{ display: 'inline-block', background: 'rgba(255,205,52,0.3)' }}
                            disabled={!allowDrawing}
                        >
                            <VBToolTip content={<FormattedMessage id='tooltip.lessonPage.imageOps' />}>
                                <ImageIcon style={{ verticalAlign: 'middle' }} />
                            </VBToolTip>
                        </Fab>
                        <StyledMenu
                            id='image-menu'
                            anchorEl={anchorImage}
                            keepMounted
                            open={Boolean(anchorImage)}
                            onClose={handleClose}
                        >
                            {imageMenu}
                        </StyledMenu>
                        {(props.lessonState.isAdmin &&
                            boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) ||
                        (!props.lessonState.isAdmin &&
                            boardManager.lessonState.boardType === BOARD_TYPE.MULTI_BOARD) ? (
                            <>
                                <Fab
                                    size='small'
                                    color='default'
                                    aria-label='grid'
                                    className={classes.margin}
                                    onClick={(e) => handleClick(e, 'GRID')}
                                    aria-controls='grid-menu'
                                    aria-haspopup='true'
                                    style={{ display: 'inline-block' }}
                                    disabled={!allowDrawing}
                                >
                                    <VBToolTip content={<FormattedMessage id='tooltip.lessonPage.grid' />}>
                                        <GridIcon style={{ verticalAlign: 'middle' }} />
                                    </VBToolTip>
                                </Fab>
                                <StyledMenu
                                    id='grid-menu'
                                    anchorEl={anchorGrid}
                                    keepMounted
                                    open={Boolean(anchorGrid)}
                                    onClose={handleClose}
                                >
                                    {gridMenu}
                                </StyledMenu>
                            </>
                        ) : null}
                    </div>
                    {props.lessonState.isAdmin ? (
                        <div className={classes.toolBorderTop}>
                            <Fab
                                size='small'
                                color='default'
                                className={classes.margin}
                                onClick={(e) => {
                                    // setDialogCollectionSave(LIBRARY_MANAGER_STATE.SAVE);
                                    handleClick(e, 'SAVE_TO_LIB');
                                }}
                                style={{ display: 'inline-block', background: 'rgba(255,205,52,0.3)' }}
                                disabled={boardManager.lessonState.boardType === BOARD_TYPE.MULTI_BOARD}
                            >
                                <VBToolTip content={<FormattedMessage id='tooltip.lessonPage.save' />}>
                                    <SaveIcon style={{ verticalAlign: 'middle' }} />
                                </VBToolTip>
                            </Fab>
                            <StyledMenu
                                id='lib-save-menu'
                                anchorEl={anchorSaveToLib}
                                keepMounted
                                open={Boolean(anchorSaveToLib)}
                                onClose={handleClose}
                            >
                                {addToLibMenu}
                            </StyledMenu>

                            <Fab
                                size='small'
                                color='default'
                                className={classes.margin}
                                onClick={() => {
                                    setDialogCollectionSave(LIBRARY_MANAGER_STATE.LOAD);
                                }}
                                style={{ display: 'inline-block', background: 'rgba(255,205,52,0.3)' }}
                            >
                                <VBToolTip content={<FormattedMessage id='collections.loadCollection.title' />}>
                                    <OpenFolder style={{ verticalAlign: 'middle' }} />
                                </VBToolTip>
                            </Fab>
                        </div>
                    ) : null}
                    <div className={classes.toolBorder}>
                        <Fab
                            size='small'
                            color={'default'}
                            className={classes.margin}
                            onClick={(e) => handleClick(e, 'CLEAR')}
                            style={{ display: 'inline-block' }}
                            disabled={
                                !allowDrawing ||
                                (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD &&
                                    !boardManager.isAdmin)
                            }
                        >
                            <VBToolTip content={<FormattedMessage id='tooltip.lessonPage.clear' />}>
                                <DeleteForever style={{ verticalAlign: 'middle' }} color='secondary' />
                            </VBToolTip>
                        </Fab>
                        <StyledMenu
                            id='lib-clear-menu'
                            anchorEl={anchorClear}
                            keepMounted
                            open={Boolean(anchorClear)}
                            onClose={handleClose}
                        >
                            {clearMenu}
                        </StyledMenu>

                        {boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD && props.lessonState.isAdmin ? (
                            <Fab
                                size='small'
                                className={classes.margin}
                                onClick={() => {
                                    window.logAppActivity(GA_EVENT_TREE.lessonPage.toolSelection.undoButton);
                                    store.dispatch(pickLessonPage('undoBoard'));
                                }}
                                style={{ display: 'inline-block' }}
                                disabled={!allowDrawing}
                            >
                                <VBToolTip content={<FormattedMessage id='tooltip.lessonPage.undo' />}>
                                    <UndoIcon style={{ verticalAlign: 'middle' }} />
                                </VBToolTip>
                            </Fab>
                        ) : null}
                    </div>

                    <ToolNavLesson />
                    <div style={{ height: '15px' }} />
                </>
            )}

            <Dialog open={dialogFileOpen} onClose={handleClose}>
                <DialogContent>
                    <Input type={'text'} value={dialogFileName} onChange={updateText} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={saveImage} color='primary'>
                        <FormattedMessage id='ok' />
                    </Button>
                    <Button onClick={handleClose} color='primary'>
                        <FormattedMessage id='close' />
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={!!dialogClear} onClose={handleClose}>
                <DialogContent>
                    <Typography variant='h4'>
                        {dialogClear === 1 ? (
                            <FormattedMessage id='tool.dialog.areYouSureDelete' />
                        ) : (
                            <FormattedMessage id='tool.dialog.areYouSureDeleteAll' />
                        )}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            if (dialogClear === 1) {
                                clearImage();
                            } else {
                                clearAllBoards();
                            }
                        }}
                        color='primary'
                    >
                        <FormattedMessage id='ok' />
                    </Button>
                    <Button onClick={handleClose} color='primary'>
                        <FormattedMessage id='close' />
                    </Button>
                </DialogActions>
            </Dialog>
            {dialogCollectionSave ? (
                <BoardCollectionDialog
                    close={() => {
                        setDialogCollectionSave(null);
                        handleClose();
                    }}
                    state={dialogCollectionSave}
                />
            ) : null}

            <ImageCropper dialogImgUploadOpen={dialogImgUploadOpen} handleClose={handleClose} />
            <GoogleSearchDialog open={dialogGoogleSearchOpen} close={handleClose} />
            <LatexEditDialog />
        </Paper>
    );
};

const mapStateToProps = (state) => {
    const { drawing, lessonState, profile, mainAppState } = state;
    return { drawing, lessonState, profile, subscriptions: mainAppState.subscriptions };
};

export default injectIntl(connect(mapStateToProps)(ToolPickerFloat));
