import {makeStyles} from "@material-ui/core";
import { COLORS2 } from '../../constants';

const styles = makeStyles({
    drawerPaper:{
        height: "calc(100vh - 48px)",
        width: "272px",
        backgroundColor: 'transparent',
        borderRight: 'none',
        margin: "24px 0px",
        borderRadius: "0px 32px 32px 0px"
    },
    entireSideMenu: {
        minHeight: '100%',
        background: '#FFFFFF',
        boxShadow: '0rem 0rem 2.5rem rgba(210, 104, 40, 0.04)',
        borderRadius: '0rem 1rem 1rem 0rem',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    list:{
        display: 'flex',
        alignItems: 'center'
    },
    listItemText: {
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '0.875rem',
        lineHeight: '1.063rem',
        letterSpacing: '0.16rem',
        textTransform: 'uppercase',
        color: COLORS2.darkGreen,
    },
    listItem: {
        width: '100%',
        height: '3.5rem',

        '&:hover': {
            width: '100%',
            height: '3.5rem',
            background: COLORS2.virtualBackground,
            borderRadius: '0.5rem',
        },
    },
    icon:{
        width: '2rem',
        height: '2rem'
    },
    divider:{
        background: '#D26828', opacity: '0.1'
    },
    footerWrapper: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
        overflow:"auto"
    }
});

export default styles;