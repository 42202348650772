import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function TutoringIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
                <path d="M95.798 69.883c-.953 1.078-1.945 2.156-3.008 3.211-1.055 1.063-2.139 2.055-3.209 3.016l4.047 4.055c.021.016.045.016.068.031.047.055.078.105.125.16 1.146 1.148 3.465.684 5.193-1.039 1.713-1.719 2.178-4.035 1.039-5.184-.055-.055-.117-.078-.18-.125-.008-.016-.008-.055-.031-.07L95.798 69.883zM104.169 79.047c.039 1.094-.582 2.496-1.768 3.676-1.17 1.176-2.572 1.789-3.674 1.758 1.441 1.922 6.209 8.238 8.705 10.727 2.938 2.945 17.998 14.266 19.859 12.395 1.867-1.867-9.449-16.918-12.395-19.867C112.409 85.254 106.091 80.48 104.169 79.047zM71.312 38.445H50.421c-2.76 7.512-2.9 15.734-.428 23.316h21.318V38.445zM36.716 40.219V21.152H20.71v21.199H.501v15.566H20.71v21.145h16.006V60.848C34.321 54.188 34.306 46.879 36.716 40.219z" />
                <path
                    d="M68.776,20.191c-16.752,0-30.34,13.578-30.34,30.336c0,16.754,13.588,30.34,30.34,30.34
		c16.758,0,30.338-13.586,30.338-30.34C99.114,33.77,85.534,20.191,68.776,20.191z M68.776,74.297
		c-13.123,0-23.758-10.645-23.758-23.77c0-13.121,10.635-23.758,23.758-23.758s23.766,10.637,23.766,23.758
		C92.542,63.652,81.899,74.297,68.776,74.297z"
                />
            </svg>
        </SvgIcon>
    );
}
