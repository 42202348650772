import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { HomeFeaturesDesktop } from '../HomeFeatures/HomeFeaturesDesktop';
import DesktopLanding from '../../../MyComponents/Landing/DesktopLanding';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import { StartGroupButton } from '../../../MyComponents/StartGroupButton/StartGroupButton';
import { DesktopLoginButtons } from '../HomeModals/DesktopLoginButtons';
import { VIDEO_URLS } from '../../../constants';

const useStyles = makeStyles(() => ({
    desktopContainer: {
        position: 'relative',
        marginBottom: '-10rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    bottomGreenPaper: {
        background: 'rgba(8, 170, 180, 0.16)',
        borderRadius: '0rem 0rem 2rem 2rem',
        height: '2rem',
        width: '90%',
    },
}));

const HomeLandingDesktop = ({ handleOpen, open, handleLogin, language }) => {
    const classes = useStyles();

    const setCreateGroupPath = () => {
        window.localStorage.setItem('pathBeforeLogin', '/creategroup');
    };

    return (
        <>
            <DesktopLanding videoUrl={VIDEO_URLS['main'][language.toUpperCase()]} needsBackground={true}>
                <div style={{ zIndex: '1', paddingBottom: '1rem' }}>
                    <Typography variant="h1">
                        <FormattedMessage id={'redesign.page.home.vBoard.mainName.v2'} />
                        <FormattedMessage
                            id={'redesign.page.home.vBoard.tagline.v2'}
                            values={{
                                b: (chunks) => (
                                    <b>
                                        <br></br>
                                        {chunks}
                                        <br></br>
                                    </b>
                                ),
                            }}
                        />
                    </Typography>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'bottom',
                            paddingTop: '1rem',
                        }}
                    >
                        <StartGroupButton
                            handleOpen={() => {
                                handleOpen(false);
                                setCreateGroupPath();
                            }}
                        />
                        {!open ? <DesktopLoginButtons handleLogin={handleLogin} /> : <div style={{ width: '18rem' }} />}
                    </div>
                </div>
            </DesktopLanding>
            <Grid item xs={12} className={classes.desktopContainer}>
                <HomeFeaturesDesktop
                    handleOpen={() => {
                        handleOpen(false);
                        setCreateGroupPath();
                    }}
                />
                <Grid container className={classes.bottomGreenPaper} />
            </Grid>
        </>
    );
};

export default HomeLandingDesktop;
