import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Grid, Paper, TextField, Typography } from '@material-ui/core';
import GenericState from '../../../MyComponents/GenericLoadingErrorState/GenericState';
import validator from 'validator';
import userApi from '../../../Api/userApi';
import store from '../../../redux/store';
import { showError, updateUserPreferences } from '../../../redux/actions';

const ValidateEmail = ({ profile, validateCallback }) => {
    const [isStarting, setIsStarting] = useState(false);
    const [sentEmail, setSentEmail] = useState(false);
    const [email, setEmail] = useState('');
    let interval = useRef({ func: null });

    useEffect(() => {
        return function () {
            if (interval.current.func) {
                clearInterval(interval.current.func);
            }
        };
    }, []);

    useEffect(() => {
        if (profile?.preferences?.email) {
            if (profile?.preferences?.emailIsValid && validateCallback) {
                validateCallback();
            } else {
                if (validator.isEmail(profile.preferences.email)) {
                    setEmail(profile.preferences.email);
                }
            }
        }
    }, [profile, validateCallback]);

    const handleChange = (event) => {
        setEmail(event.target.value);
    };

    const updateEmail = () => {
        setIsStarting(true);
        userApi
            .updateEmail({
                email,
            })
            .then((res) => {
                setSentEmail(true);
                interval.current.func = setInterval(() => {
                    userApi.getExtendedProfile().then((response) => {
                        if (response.data.preferences.email === email && response.data.preferences.emailIsValid) {
                            store.dispatch(updateUserPreferences(response.data.preferences));
                            if (interval.current.func) {
                                clearInterval(interval.current.func);
                                interval.current.func = null;
                            }
                        }
                    });
                }, 1000);
                window.localStorage.setItem('pathBeforeLogin', window.location.pathname + window.location.search);
            })
            .catch((err) => {
                store.dispatch(showError('GENERIC_ERROR'));
                console.error('Unable to update email', err);
            });
    };

    let titleTextId = '';
    let buttonTextId = '';
    if (sentEmail) {
        titleTextId = 'profile.validEmail.emailSent';
    } else if (profile?.preferences?.emailIsValid) {
        titleTextId = 'profile.validEmail.emailValidated.description';
    } else {
        titleTextId = 'profile.validEmail.emailNotValidated.description';
    }

    if (profile?.preferences?.emailIsValid) {
        buttonTextId = 'profile.validEmail.activate';
    } else {
        buttonTextId = 'profile.validEmail.change';
    }

    let actionComponent = null;
    if (isStarting && !sentEmail) {
        actionComponent = <GenericState isLoading={true} inline={true} />;
    }
    if (!isStarting) {
        actionComponent = (
            <Button
                variant={'contained'}
                color={'primary'}
                size={'large'}
                disabled={!validator.isEmail(email)}
                onClick={updateEmail}
            >
                <FormattedMessage id={buttonTextId} />
            </Button>
        );
    }
    if (isStarting && sentEmail) {
        actionComponent = (
            <Typography variant="h5">
                <FormattedMessage id={titleTextId} />
            </Typography>
        );
    }

    return (
        <Grid container spacing={10}>
            <Grid item xs={12}>
                <Paper elevation={0} style={{ background: 'rgba(255, 205, 52, 0.06)', padding: '2rem' }}>
                    <Grid container spacing={2} justify={'center'}>
                        <Grid item xs={12}>
                            <div
                                style={{
                                    width: '100%',
                                    marginTop: '0.5rem',
                                    textAlign: 'center',
                                }}
                            >
                                <Typography variant="h2">
                                    <FormattedMessage id="onboarding.verifyEmail.updateTitle" />
                                </Typography>
                                <Typography variant="h5">
                                    <FormattedMessage id={titleTextId} />
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={6} style={{ textAlign: 'center' }}>
                <TextField
                    variant={'outlined'}
                    color={'primary'}
                    disabled={isStarting || sentEmail}
                    value={email}
                    fullWidth
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12} sm={6} style={{ textAlign: 'center' }}>
                {actionComponent}
            </Grid>
        </Grid>
    );
};

export default ValidateEmail;
