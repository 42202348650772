import React from 'react';
import { useHistory } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import TutorialSideMenuIcon from '../../MyComponents/icons/TutorialSideMenuIcon';
import Typography from '@material-ui/core/Typography';
import { COLORS2 } from '../../constants';

const useStyles = makeStyles({
    list: {
        cursor: 'pointer',
        width: '100%',
        height: '3.5rem',

        '&:hover': {
            width: '100%',
            height: '3.5rem',
            background: COLORS2.virtualBackground,
            borderRadius: '8px',
        }
    }
});

const TutorialMenuLink = ({ closeDrawer }) => {
    const history = useHistory();
    const classes = useStyles();

    const handleRedirect = (event) => {
        event.preventDefault();

        history.push({
            pathname: '/tutorial',
            state: {
                sectionId: 'section-tutorialStart',
            },
        });
        if (closeDrawer) {
            closeDrawer();
        }
    };

    return (
        <ListItem className={classes.list}>
            <ListItemIcon onClick={handleRedirect}>
                <TutorialSideMenuIcon />
            </ListItemIcon>
            <ListItemText onClick={handleRedirect} >
                <Typography
                    style={{
                        fontStyle: 'normal',
                        fontWeight: '600',
                        fontSize: '0.875rem',
                        lineHeight: '1.063rem',
                        letterSpacing: '0.16rem',
                        textTransform: 'uppercase',
                        color: COLORS2.darkGreen,
                    }}
                >
                    <FormattedMessage id={'tutorial'}/>
                </Typography>
            </ListItemText>
        </ListItem>
    );
};

export default TutorialMenuLink;
