import React, { useEffect, useState } from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import { COLORS2 } from '../../constants';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import userApi from '../../Api/userApi.js';
import SpinnerContentRenderer from '../../MyComponents/Drawer/SpinnerContentRenderer.js';
import WhiteboardLink from '../../MyComponents/icons/WhiteboardLink.js';
import PhoneQR from '../../MyComponents/icons/PhoneQR.js';

const useStyles = makeStyles(() => ({
    menuNav: {
        fontWeight: 600,
        fontSize: '0.875rem',
        lineHeight: '1.063rem',
        textAlign: 'right',
        letterSpacing: '0.16rem',
        color: COLORS2.findMore,
    },
    menuNavActive: {
        fontWeight: 700,
        fontSize: '0.875rem',
        lineHeight: '1.063rem',
        textAlign: 'right',
        letterSpacing: '0.16rem',
        color: COLORS2.findMore,
        borderBottom: '0.125rem solid #D26828',
    },
    desktopContainer: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '-10rem',
        background: 'linear-gradient(180deg, rgba(251, 248, 245, 0) 0%, #FFFFFF 93.57%)',
        borderRadius: '0rem 0rem 2rem 2rem',
    },
    bottomGreenPaper: {
        background: 'rgba(8, 170, 180, 0.16)',
        borderRadius: '0rem 0rem 2rem 2rem',
        height: '2rem',
        width: '90%',
        top: '10rem',
        position: 'relative',
        marginLeft: '5%',
    },
    gridWrap: {
        height: '100%',
        background: 'linear-gradient(180deg, rgba(251, 248, 245, 0) 0%, #FFFFFF 93.57%)',
        borderRadius: '0rem 0rem 2rem 2rem',
        paddingBottom: '2rem',
        paddingLeft: '3rem',
        paddingRight: '3rem',
    },
}));

const ApproveLinkWhiteboard = (props) => {
    const classes = useStyles();

    const [loading, setLoading] = useState(true);


    useEffect(() => {
        if (props.match.params.id) {
            userApi.linkWhiteboard(props.match.params.id).then(() => {
                setLoading(false);
            });
        }
    }, []);

    return (
        <Container>
            <div className={classes.desktopContainer}>
                <Container className={classes.gridWrap}>
                    <Grid container>
                        <Grid style={{ width: '100%' }}>
                            <div
                                style={{
                                    display: 'inline-block',
                                    width: '100%',
                                    textAlign: 'center',
                                    height: '15rem',
                                    position: 'relative',
                                    paddingTop: '5rem',
                                }}
                            >
                                <div>
                                    <PhoneQR style={{ width: '5.5rem', height: '5.5rem' }} color={'primary'} />
                                    <WhiteboardLink style={{ width: '5.5rem', height: '5.5rem' }} />
                                </div>
                                {loading ? <Typography variant='h4'>
                                    <FormattedMessage
                                        id={'page.linkwhiteboard.allowlink.loading'}
                                    />
                                </Typography> : <><Typography variant='h4'>
                                    <FormattedMessage
                                        id={'page.linkwhiteboard.allowlink.done1'}
                                    />
                                </Typography>
                                    <Typography variant='h5'>
                                        <FormattedMessage
                                            id={'page.linkwhiteboard.allowlink.done2'}
                                        />
                                    </Typography></>}
                                {loading ? <SpinnerContentRenderer /> : null}
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </Container>
    );
};

export default ApproveLinkWhiteboard;
