import Grid from '@material-ui/core/Grid';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import { ForTutoringFeaturesTablet } from './TutoringFeatures/ForTutoringFeaturesTablet';
import TabletLanding from '../../MyComponents/Landing/TabletLanding';
import { VIDEO_URLS } from '../../constants';
import { StartGroupButton } from '../../MyComponents/StartGroupButton/StartGroupButton';

const useStyles = makeStyles(() => ({
    desktopContainer: {
        position: 'relative',
        marginBottom: '-10rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    bottomGreenPaper: {
        background: 'rgba(8, 170, 180, 0.16)',
        borderRadius: '0rem 0rem 2rem 2rem',
        height: '2rem',
        width: '90%',
    },
    loginContainer: {
        background: 'transparent',
        padding: '3rem 1rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

const ForTutoringTablet = ({ handleOpen, language }) => {
    const classes = useStyles();

    const setCreateGroupPath = () => {
        window.localStorage.setItem('pathBeforeLogin', '/creategroup');
    };

    let needsBackground = false;

    return (
        <>
            <TabletLanding videoUrl={VIDEO_URLS['tutoring'][language.toUpperCase()]} needsBackground={needsBackground}>
                <Grid
                    item
                    xs={12}
                    style={{ zIndex: 1, textAlign: 'center', paddingTop: needsBackground ? '2rem' : '0rem' }}
                >
                    <Typography variant="h1">
                        <FormattedMessage
                            id={'redesign.page.home.forTutoring.tagline.v2'}
                            values={{
                                b: (chunks) => (
                                    <b>
                                        {chunks}
                                        <br></br>
                                    </b>
                                ),
                            }}
                        />
                    </Typography>
                </Grid>
                <Grid item xs={12} md={12} className={classes.loginContainer}>
                    <StartGroupButton
                        handleOpen={() => {
                            handleOpen(false);
                            setCreateGroupPath();
                        }}
                    />
                </Grid>
            </TabletLanding>
            <div className={classes.desktopContainer}>
                <ForTutoringFeaturesTablet
                    handleOpen={() => {
                        handleOpen(false);
                        setCreateGroupPath();
                    }}
                />
                <div className={classes.bottomGreenPaper} />
            </div>
        </>
    );
};

export default ForTutoringTablet;
