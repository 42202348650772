import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function CreateDirectoryIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 22.5H21C22.6547 22.5 24 21.1547 24 19.5V7.5C24 5.84531 22.6547 4.5 21 4.5H13.9922C13.1953 4.5 12.4312 4.18594 11.8687 3.62344L10.6266 2.37656C10.0641 1.81406 9.3 1.5 8.50312 1.5H3C1.34531 1.5 0 2.84531 0 4.5V19.5C0 21.1547 1.34531 22.5 3 22.5Z" fill="#08AAB4"/>
                <path d="M15.5 12.5H12.5V9.47188C12.5 9.225 12.275 9 12 9C11.725 9 11.5 9.225 11.5 9.47188V12.5H8.5C8.225 12.5 8 12.725 8 13C8 13.2761 8.22363 13.4719 8.5 13.4719H11.5V16.4719C11.5 16.775 11.725 17 12 17C12.2761 17 12.5 16.7762 12.5 16.5281V13.4719H15.5C15.775 13.4719 16 13.275 16 13C16 12.725 15.775 12.5 15.5 12.5Z" fill="white"/>
            </svg>

        </SvgIcon>
    );
}
