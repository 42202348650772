import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function VBoardFooterIcon(props) {
    return (
        <SvgIcon {...props} style={{ verticalAlign: 'bottom' }}>
            <svg viewBox="0 0 123 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.66" clipPath="url(#clip0_57_566)">
                    <path opacity="0.6" d="M17.909 29.8011C18.0862 29.8011 18.2619 29.8319 18.4319 29.8925L28.939 33.3956C29.8818 22.2859 29.8823 22.2798 29.8833 22.2706C29.9053 22.0499 29.9745 21.8452 30.0876 21.6579L16.765 1.61328C14.2358 2.57128 11.808 3.87615 10.2486 4.90445C8.64527 5.96097 6.40793 7.7492 4.49219 9.7632L17.842 29.8078C17.8645 29.8068 17.8865 29.8011 17.909 29.8011Z" fill="white"/>
                    <path opacity="0.4" d="M33.7248 20.3573C34.4607 20.3573 35.2212 20.4384 35.9852 20.5974C37.2767 20.8658 38.4914 21.3492 39.4039 21.9587C39.4608 21.9967 39.5099 22.046 39.5478 22.104L39.9959 22.7869V22.7864C39.9959 22.7864 26.3435 1.97263 26.2739 1.8659C25.5912 0.826831 24.3719 0.298828 22.6498 0.298828C20.8825 0.298828 18.7891 0.845303 16.7617 1.61396L30.0884 21.6581C30.579 20.8432 31.8941 20.3573 33.7248 20.3573Z" fill="white"/>
                    <path opacity="0.8" d="M35.527 43.4603C37.1627 42.3823 39.0093 41.8789 40.8298 41.8999L40.8933 24.307C40.8933 24.2065 40.8641 24.1074 40.8088 24.0233L39.548 22.1021C39.5101 22.0447 39.4615 21.9954 39.4041 21.9569C38.4916 21.3473 37.2774 20.8645 35.9854 20.5956C35.2213 20.436 34.4604 20.3555 33.725 20.3555C31.4728 20.3555 30.001 21.0913 29.8838 22.2689C29.8833 22.2781 29.8822 22.2838 28.9395 33.3939L18.4323 29.8908C18.2623 29.8303 18.0867 29.7995 17.9095 29.7995C16.2641 29.7995 15.011 32.2881 14.4984 34.7542C14.2009 36.1863 14.142 37.5117 14.332 38.4866C14.3448 38.5523 14.3699 38.6144 14.4068 38.6698L15.667 40.591C15.7223 40.6761 15.8033 40.7428 15.8965 40.7824C15.9784 40.8167 20.9457 42.9472 26.3575 45.2676L32.0079 47.69C32.7131 46.0203 33.9001 44.5328 35.527 43.4603Z" fill="white"/>
                    <path d="M68.2025 40.4328C67.6444 39.4856 66.9192 38.7128 66.0466 38.1351C65.6697 37.8862 65.2836 37.6666 64.8908 37.4793C65.5427 36.9533 66.1193 36.3237 66.614 35.5961C67.4349 34.3852 67.8507 32.9884 67.8507 31.4455C67.8507 29.7229 67.3427 28.1754 66.3401 26.8464C65.3594 25.5461 63.9844 24.5147 62.253 23.781C60.5539 23.0621 58.5455 22.6973 56.283 22.6973C54.4042 22.6973 52.5273 22.7896 50.7033 22.9718C48.8736 23.1555 47.1478 23.4203 45.5736 23.7589L44.9668 23.8892V52.1176L45.5706 52.25C46.4652 52.4471 47.578 52.64 48.8797 52.8247C50.1686 53.0064 51.5477 53.149 52.979 53.248C54.4119 53.3466 55.7622 53.3963 56.9923 53.3963C59.3428 53.3963 61.4424 52.9792 63.2337 52.1566C65.0404 51.3254 66.4742 50.196 67.4938 48.7982C68.5328 47.3738 69.0603 45.7801 69.0603 44.0621C69.0603 42.6213 68.772 41.4006 68.2025 40.4328ZM52.2984 41.0383C53.1122 40.9726 54.4953 40.8777 55.6409 40.8777C57.2929 40.8777 59.1277 40.9577 60.2093 41.5519C60.9887 41.9809 61.4362 42.7742 61.4362 43.7286C61.4362 44.5932 61.0245 45.2664 60.1775 45.7878C59.3182 46.3158 58.2147 46.5621 56.7055 46.5621H56.6441C55.1165 46.5559 53.317 46.4138 52.299 46.2639V41.0383H52.2984ZM59.6014 34.3262C58.929 34.8978 57.9673 35.1877 56.7419 35.1877H52.2979V29.8333C53.4865 29.6403 54.8236 29.5428 56.2825 29.5428C57.5249 29.5428 58.5905 29.8076 59.4498 30.3289C60.2072 30.7892 60.56 31.3967 60.56 32.2398C60.5606 33.0957 60.2472 33.7781 59.6014 34.3262Z" fill="white"/>
                    <path d="M77.3379 33.8642L77.489 34.5359H77.5391L77.681 33.8894L80.3367 24.959H82.9171L78.7702 37.1795H76.2579L72.1191 24.959H74.6996L77.3379 33.8642Z" fill="white"/>
                    <path d="M86.4843 25.9239H84.0391V24.0859H86.4843V25.9239ZM86.4843 37.1798H84.0391V28.098H86.4843V37.1798Z" fill="white"/>
                    <path d="M93.1655 30.2058L92.2693 30.1801C91.9052 30.1801 91.6072 30.2556 91.3722 30.4069C91.1381 30.5578 90.9589 30.7707 90.836 31.0448V37.1802H88.3984V28.0989H90.6691L90.7781 29.4418C90.9901 28.9666 91.2651 28.5961 91.6031 28.3298C91.9401 28.064 92.3303 27.9316 92.7712 27.9316C92.8941 27.9316 93.0073 27.9414 93.1102 27.9609C93.2131 27.9809 93.3181 28.0045 93.4241 28.0322L93.1655 30.2058Z" fill="white"/>
                    <path d="M98.1856 25.875V28.0989H99.7188V29.8199H98.1856V34.4452C98.1856 34.7977 98.2598 35.0496 98.4078 35.2005C98.5558 35.3513 98.7499 35.4268 98.9901 35.4268C99.1181 35.4268 99.2302 35.4196 99.325 35.4057C99.4202 35.3924 99.5288 35.3683 99.6522 35.3344L99.8535 37.1052C99.6077 37.1893 99.3685 37.2519 99.1376 37.294C98.9056 37.3361 98.6526 37.3571 98.3797 37.3571C97.5357 37.3571 96.8864 37.1252 96.4311 36.6608C95.9759 36.1965 95.749 35.4601 95.749 34.4529V29.8199H94.4258V28.0989H95.749V25.875H98.1856Z" fill="white"/>
                    <path d="M106.78 35.8366C106.501 36.3235 106.147 36.6986 105.717 36.9608C105.287 37.224 104.787 37.3559 104.217 37.3559C103.262 37.3559 102.515 37.0311 101.976 36.3825C101.437 35.7339 101.168 34.7262 101.168 33.3608V28.0977H103.606V33.3772C103.606 34.172 103.714 34.7159 103.929 35.0099C104.144 35.3039 104.477 35.4507 104.93 35.4507C105.32 35.4507 105.655 35.3876 105.935 35.2619C106.214 35.1356 106.442 34.9499 106.621 34.7036V28.0977H109.068V37.1794H107.04L106.78 35.8366Z" fill="white"/>
                    <path d="M116.381 37.1807C116.291 36.9739 116.214 36.7558 116.15 36.5264C116.086 36.2971 116.04 36.0621 116.012 35.8209C115.761 36.2632 115.429 36.6296 115.015 36.921C114.602 37.2115 114.111 37.3577 113.541 37.3577C112.591 37.3577 111.861 37.1124 111.346 36.6229C110.832 36.1334 110.576 35.4663 110.576 34.6212C110.576 33.732 110.918 33.0423 111.602 32.5523C112.286 32.0628 113.284 31.818 114.597 31.818H115.971V31.0961C115.971 30.6594 115.856 30.3213 115.627 30.0806C115.399 29.8399 115.058 29.7194 114.605 29.7194C114.208 29.7194 113.903 29.8158 113.688 30.0088C113.473 30.2017 113.366 30.4665 113.366 30.802H111.011L110.995 30.7518C110.956 29.9852 111.29 29.3237 111.996 28.7665C112.702 28.2098 113.625 27.9316 114.764 27.9316C115.853 27.9316 116.735 28.2056 117.406 28.7542C118.079 29.3027 118.417 30.0888 118.417 31.113V34.8649C118.417 35.2903 118.449 35.69 118.513 36.0651C118.577 36.4402 118.679 36.8122 118.819 37.1817H116.381V37.1807ZM114.144 35.5443C114.569 35.5443 114.948 35.4381 115.283 35.2252C115.618 35.0127 115.848 34.7664 115.971 34.4868V33.2024H114.597C114.077 33.2024 113.685 33.3317 113.42 33.5888C113.155 33.8464 113.022 34.1625 113.022 34.5376C113.022 34.8454 113.121 35.0902 113.319 35.2719C113.518 35.453 113.793 35.5443 114.144 35.5443Z" fill="white"/>
                    <path d="M122.694 37.1798H120.248V24.0859H122.694V37.1798Z" fill="white"/>
                    <path d="M75.7969 51.8026V39.582H79.7917C81.2271 39.582 82.3471 39.8632 83.1516 40.4261C83.9555 40.9885 84.3575 41.8233 84.3575 42.9312C84.3575 43.491 84.2116 43.9918 83.9212 44.4341C83.6309 44.8764 83.2064 45.2089 82.6482 45.4327C83.4071 45.5722 83.9806 45.9027 84.3657 46.423C84.7508 46.9438 84.9439 47.5508 84.9439 48.2446C84.9439 49.414 84.5588 50.2996 83.7876 50.901C83.0174 51.5029 81.9251 51.8036 80.5127 51.8036H75.7969V51.8026ZM78.2426 44.702H79.8757C80.5286 44.702 81.032 44.5675 81.3838 44.2992C81.7351 44.0308 81.9108 43.6388 81.9108 43.1236C81.9108 42.5587 81.7336 42.142 81.3787 41.8731C81.0248 41.6048 80.4953 41.4703 79.7912 41.4703H78.2421V44.702H78.2426ZM78.2426 46.3973V49.9225H80.5133C81.1605 49.9225 81.6547 49.7814 81.9958 49.4986C82.3358 49.2159 82.5068 48.7982 82.5068 48.244C82.5068 47.6514 82.3389 47.1947 82.004 46.8761C81.6691 46.5569 81.1636 46.3973 80.4887 46.3973H78.2426Z" fill="white"/>
                    <path d="M85.8462 47.1789C85.8462 45.8243 86.2231 44.7149 86.9774 43.8513C87.7307 42.9862 88.7749 42.5547 90.1099 42.5547C91.4501 42.5547 92.4993 42.9857 93.2593 43.8472C94.0182 44.7093 94.3987 45.8197 94.3987 47.1795V47.3555C94.3987 48.7209 94.0197 49.8333 93.2634 50.6923C92.506 51.5513 91.4608 51.9807 90.1268 51.9807C88.7867 51.9807 87.7374 51.5513 86.981 50.6923C86.2236 49.8333 85.8457 48.7209 85.8457 47.3555V47.1789H85.8462ZM88.2838 47.3549C88.2838 48.1831 88.4287 48.8476 88.7201 49.3489C89.0099 49.8497 89.479 50.1002 90.1273 50.1002C90.7572 50.1002 91.2196 49.8482 91.5136 49.3443C91.8065 48.8409 91.9524 48.178 91.9524 47.3549V47.1789C91.9524 46.3733 91.8044 45.7155 91.5084 45.206C91.2124 44.697 90.7464 44.4425 90.1104 44.4425C89.4795 44.4425 89.0171 44.6985 88.7242 45.2106C88.4313 45.7222 88.2843 46.3785 88.2843 47.1789V47.3549H88.2838Z" fill="white"/>
                    <path d="M101.252 51.8037C101.162 51.5969 101.085 51.3788 101.021 51.1495C100.957 50.9201 100.911 50.6851 100.883 50.4439C100.632 50.8863 100.3 51.2526 99.8861 51.5441C99.4728 51.8345 98.9817 51.9807 98.4118 51.9807C97.4624 51.9807 96.7316 51.7355 96.2175 51.246C95.7033 50.7564 95.4473 50.0894 95.4473 49.2443C95.4473 48.355 95.7893 47.6654 96.4735 47.1754C97.1566 46.6858 98.1547 46.4411 99.4682 46.4411H100.842V45.7191C100.842 45.2824 100.727 44.9443 100.499 44.7036C100.27 44.463 99.9286 44.3424 99.4764 44.3424C99.0796 44.3424 98.7743 44.4389 98.5593 44.6318C98.3442 44.8247 98.2372 45.0895 98.2372 45.4251H95.8825L95.8656 45.3748C95.8267 44.6082 96.1606 43.9468 96.8668 43.3895C97.573 42.8328 98.4963 42.5547 99.6352 42.5547C100.724 42.5547 101.606 42.8287 102.278 43.3772C102.95 43.9258 103.288 44.7119 103.288 45.736V49.488C103.288 49.9134 103.32 50.3131 103.384 50.6882C103.448 51.0633 103.55 51.4353 103.69 51.8047H101.252V51.8037ZM99.015 50.1674C99.4401 50.1674 99.819 50.0612 100.154 49.8482C100.489 49.6358 100.719 49.3895 100.842 49.1098V47.8255H99.4682C98.9479 47.8255 98.5562 47.9548 98.2909 48.2119C98.0257 48.4694 97.893 48.7855 97.893 49.1606C97.893 49.4685 97.9919 49.7133 98.19 49.8949C98.3892 50.076 98.6642 50.1674 99.015 50.1674Z" fill="white"/>
                    <path d="M110.143 44.8288L109.247 44.8032C108.884 44.8032 108.585 44.8786 108.351 45.03C108.116 45.1808 107.937 45.3938 107.815 45.6678V51.8032H105.377V42.722H107.647L107.756 44.0648C107.968 43.5897 108.243 43.2192 108.581 42.9529C108.919 42.6871 109.308 42.5547 109.75 42.5547C109.872 42.5547 109.985 42.5644 110.089 42.5839C110.192 42.6039 110.297 42.6276 110.403 42.6553L110.143 44.8288Z" fill="white"/>
                    <path d="M110.848 47.3378C110.848 45.9051 111.157 44.7496 111.775 43.8711C112.392 42.9926 113.261 42.5539 114.384 42.5539C114.836 42.5539 115.243 42.6514 115.607 42.8474C115.97 43.0434 116.289 43.3231 116.562 43.6869V39.5742H119.016V51.8029H116.93L116.72 50.6864C116.436 51.1061 116.098 51.4263 115.707 51.6475C115.316 51.8686 114.869 51.9789 114.367 51.9789C113.256 51.9789 112.392 51.5705 111.774 50.7531C111.157 49.9362 110.848 48.8566 110.848 47.5138V47.3378H110.848ZM113.286 47.5143C113.286 48.3091 113.42 48.9341 113.688 49.3897C113.956 49.8464 114.391 50.0742 114.995 50.0742C115.347 50.0742 115.654 50.0024 115.916 49.8598C116.179 49.7176 116.394 49.5088 116.561 49.2343V45.3653C116.394 45.0795 116.18 44.8578 115.92 44.6977C115.661 44.5382 115.358 44.4586 115.012 44.4586C114.414 44.4586 113.977 44.7244 113.701 45.256C113.424 45.7876 113.286 46.4814 113.286 47.3378V47.5143Z" fill="white"/>
                    <path d="M40.8387 41.9011C39.0151 41.8781 37.166 42.3825 35.5278 43.4616C33.8875 44.5427 32.6928 46.0462 31.9902 47.7338C35.8842 49.4035 39.2845 50.8608 40.408 51.3416C40.4618 51.3616 40.5796 51.3888 40.681 51.3216C40.7773 51.2584 40.7988 51.1563 40.8044 51.0963L40.811 49.8284L40.8387 41.9011Z" fill="white"/>
                    <path d="M14.3327 38.4886C14.3456 38.5543 14.3706 38.6164 14.4075 38.6718L14.6508 39.0433C12.9578 36.4659 1.17195 18.5246 1.1192 18.4445C-0.410211 16.1144 1.69977 12.699 4.49241 9.76367L17.8387 29.803C16.2286 29.8738 15.0038 32.3255 14.4992 34.7562C14.2017 36.1883 14.1428 37.5137 14.3327 38.4886Z" fill="white"/>
                    <path d="M17.9016 29.8015C17.9045 29.8015 17.9074 29.8014 17.9102 29.8014C17.982 29.8014 18.0535 29.8065 18.1245 29.8165C18.0531 29.8064 17.9812 29.8013 17.909 29.8013C17.9066 29.8013 17.9041 29.8013 17.9016 29.8015Z" fill="white"/>
                </g>
                <defs>
                    <clipPath id="clip0_57_566">
                        <rect width="123" height="54" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        </SvgIcon>
    );
}
