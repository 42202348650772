import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function FranceIcon(props) {
    return (
        <SvgIcon {...props} style={{ verticalAlign: 'bottom' }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 216.504 128.088">
                <path fill="#fff" fillRule="evenodd" d="M216.252 127.836V.252h-216v127.584h216z" clipRule="evenodd" />
                <path
                    fill="#27569f"
                    fillRule="evenodd"
                    d="M71.748 127.836V.252H.252v127.584h71.496z"
                    clipRule="evenodd"
                />
                <path
                    fill="#cc2136"
                    fillRule="evenodd"
                    d="M216.252 127.836V.252h-71.496v127.584h71.496z"
                    clipRule="evenodd"
                />
                <path
                    fill="none"
                    stroke="#000"
                    strokeMiterlimit="2.613"
                    strokeWidth=".504"
                    d="M216.252 127.836V.252h-216v127.584h216z"
                />
            </svg>
        </SvgIcon>
    );
}
