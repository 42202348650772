import { Button, Checkbox, FormControlLabel, FormGroup, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import UnsubscribeIcon from '../../MyComponents/icons/UnsubscribeIcon';
import React, { useState } from 'react';
import userApi from '../../Api/userApi';
import { Link, useParams } from 'react-router-dom';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import { jwtDecode } from "jwt-decode";

export const Unsubscribe = (props) => {
    const [marketing, setMarketing] = useState(false);
    const [specialOffers, setSpecialOffers] = useState(false);
    const [open, setOpen] = useState(false);

    let { token } = useParams();
    if (!token) {
        if (props.match.params.token) {
            token = props.match.params.token;
        }
    }

    token = token.split('&')[0];

    let decoded = { name: 'N/A' };
    if (token) {
        decoded = jwtDecode(token);
    }

    if (decoded && decoded.exp < Math.floor(Date.now() / 1000)) {
        console.log('token expired');
    }

    const handleChangeMarketing = (event) => {
        setMarketing(event.target.checked);
    };

    const handleChangeOffers = (event) => {
        setSpecialOffers(event.target.checked);
    };

    const handleConfirm = () => {
        if (marketing || specialOffers) {
            setOpen(true);
        }
        userApi
            .unsubscribe({ token, marketing, specialOffers })
            .then(() => {
                console.log('Unsubscribe request sent successfully');
            })
            .catch((error) => {
                console.error('Error in unsubscribe request:', error);
            });
    };
    if (!decoded) {
        return (
            <>
                <Typography variant="h2" style={{ textAlign: 'center' }}>
                    <FormattedMessage id="genericError" />
                </Typography>
                <Link to={'/'}>
                    <Button
                        startIcon={<HomeOutlinedIcon />}
                        color="primary"
                        variant={'contained'}
                        size="large"
                        style={{ marginTop: '3rem' }}
                    >
                        <FormattedMessage id="unsubscribe.message.goHome" />
                    </Button>
                </Link>
            </>
        );
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingTop: '4rem',
                padding: '3rem',
            }}
        >
            {open ? (
                <>
                    <Typography variant="h2" style={{ textAlign: 'center' }}>
                        <FormattedMessage id="unsubscribe.message.success" />
                    </Typography>
                    <Link to={'/'}>
                        <Button
                            startIcon={<HomeOutlinedIcon />}
                            color="primary"
                            variant={'contained'}
                            size="large"
                            style={{ marginTop: '3rem' }}
                        >
                            <FormattedMessage id="unsubscribe.message.goHome" />
                        </Button>
                    </Link>
                </>
            ) : (
                <>
                    <Typography variant="h2" style={{ textAlign: 'center' }}>
                        <FormattedMessage id="unsubscribe.hello.name" values={{ name: decoded.name }} />
                    </Typography>
                    <div
                        style={{
                            padding: '1rem',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginTop: '2rem',
                        }}
                    >
                        <UnsubscribeIcon style={{ height: '5rem', width: '100%' }} />
                        <Typography variant="h3" style={{ textAlign: 'center' }}>
                            <FormattedMessage id="unsubscribe.message.sorry" />
                        </Typography>
                        <Typography variant="h3" style={{ textAlign: 'center' }}>
                            <FormattedMessage id="unsubscribe.message.confirm" />
                        </Typography>
                        <FormGroup style={{ marginTop: '3rem' }}>
                            <FormControlLabel
                                control={<Checkbox />}
                                label={<FormattedMessage id="unsubscribe.checkbox.allowMarketing" />}
                                onChange={handleChangeMarketing}
                                checked={marketing}
                            />
                            <FormControlLabel
                                control={<Checkbox />}
                                label={<FormattedMessage id="unsubscribe.checkbox.allowSpecialOffers" />}
                                onChange={handleChangeOffers}
                                checked={specialOffers}
                            />
                        </FormGroup>
                        <Button
                            onClick={handleConfirm}
                            color="primary"
                            variant={'contained'}
                            size="large"
                            style={{ marginTop: '3rem' }}
                        >
                            <FormattedMessage id="consent.dialog.button.confirm" />
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
};
