import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import store from '../../redux/store';
import { showMultiBoardLessonState } from '../../redux/actions';
import classApi from '../../Api/classApi';
import GenericState from '../../MyComponents/GenericLoadingErrorState/GenericState';
import ResponsiveTable from '../../MyComponents/ResponsiveTable/ResponsiveTable';
import GenericDeletionConfirmation from '../../MyComponents/GenericDeletionConfirmation/GenericDeletionConfirmation';
import { connect } from 'react-redux';
import { canIncreaseLimitedData, CAPABILITIES, getIntPermission } from '../../shared/Capabilities';
import { Link } from 'react-router-dom';
import { Container, makeStyles } from '@material-ui/core';
import DeleteIcon from '../../MyComponents/icons/DeleteIcon';
import ViewActivityIcon from '../../MyComponents/icons/ViewActivityIcon';
import { determineDefaultLanguage } from '../../common/utils';
import { COLORS2 } from '../../constants';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: theme.spacing(2),
        '& > *': {
            margin: theme.spacing(2),
            width: '20ch',
        },
    },
    toolbar: theme.mixins.toolbar,

    buttonGroupRequests: {
        border: '0.063rem solid #F9EAE1',
        borderRadius: '0.25rem',
        height: '2rem',
        marginLeft: '1rem',
    },
    flexEnd: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    nameSpan: {
        display: 'inline-flex',
        alignItems: 'center',
        paddingLeft: '0.625rem',
    },
    spanDate1: {
        fontWeight: 400,
        fontSize: '1rem',
        color: COLORS2.virtualText,
    },
    spanDate2: {
        fontWeight: 500,
        fontSize: '1rem',
        color: COLORS2.darkGreen,
    },
    spanHour1: {
        color: COLORS2.virtualAmber,
        fontWeight: 500,
        fontSize: '1rem',
        background: COLORS2.virtualBackground,
        borderRadius: '0.25rem',
    },
    spanHour2: {
        color: COLORS2.virtualAmber,
        fontWeight: 500,
        fontSize: '1rem',
        background: COLORS2.virtualBackground,
        borderRadius: '0.25rem',
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        position: 'absolute',
        left: '0.6rem',
        bottom: '0.6rem',
    },
    customViewButton: {
        boxSizing: 'border-box',
        minWidth: '2.5rem',
        height: '2.5rem',
        background: 'rgba(8, 170, 180, 0.08)',
        borderRadius: '0.5rem',
    },
    emptyStateH6: {
        fontSize: '1rem',
        fontWeight: '400',
        margin: '0.2rem 0rem 0.2rem 0rem',
    },
}));

const MultiBoardTable = ({ intl, lessonId, profile, displayTitle = true, subscriptions, isOwner = true }) => {
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [modalItem, setModalItem] = useState(false);
    const [canCreate, setCanCreate] = React.useState(null);

    useEffect(() => {
        if (subscriptions && profile) {
            canIncreaseLimitedData(
                subscriptions,
                profile.subscription,
                CAPABILITIES.GROUP_COUNT,
                Promise.resolve(data.length)
            ).then((res) => {
                setCanCreate(res);
            });
        } else {
            setCanCreate({ value: true });
        }
    }, [subscriptions, profile, data]);

    let deleteRow = function (multiBoardId) {
        setModalItem(multiBoardId);
    };

    let viewRow = function (row) {
        store.dispatch(
            showMultiBoardLessonState({ id: row.id, date: +row.ts, lessonId, email: profile.email, isOwner })
        );
    };

    let localesOptions = 'ro-RO';
    let currentLanguage = determineDefaultLanguage();
    if (currentLanguage === 'en') {
        localesOptions = 'en-EN';
    } else if (currentLanguage === 'fr') {
        localesOptions = 'fr-FR';
    } else if (currentLanguage === 'ro') {
        localesOptions = 'ro-RO';
    }

    const composeDateCellDayDesktop = (row) => {
        return (
            <span className={classes.spanDate1}>
                {new Date(row.createdAt).toLocaleString(localesOptions, {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                })}
            </span>
        );
    };

    const composeDateCellHour = (row) => {
        return (
            <span className={classes.spanHour1}>
                {new Date(row.createdAt).toLocaleString(localesOptions, {
                    hour: 'numeric',
                    minute: 'numeric',
                })}
            </span>
        );
    };

    const composeCountCell = (row) => {
        return row.userCount;
    };

    useEffect(() => {
        classApi
            .getMultiBoards(lessonId)
            .then((res) => {
                setData(res.data.multiBoards);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
                setError(true);
            });
    }, []);

    if (loading || error) {
        return <GenericState isLoading={loading} isError={error} />;
    }
    const deleteMultiBoard = () => {
        setLoading(true);
        setModalItem(null);
        classApi
            .deleteMultiBoards(lessonId, modalItem)
            .catch((err) => {
                console.error('unable to delete entire multiboard', lessonId, modalItem, err);
            })
            .then(() => {
                classApi
                    .getMultiBoards(lessonId)
                    .then((res) => {
                        setData(res.data.multiBoards);
                        setLoading(false);
                    })
                    .catch((error) => {
                        console.error(error);
                        setLoading(false);
                        setError(true);
                    });
            });
    };

    const desktopMeta = [];
    desktopMeta.push({
        header: 'multiBoard.dateLabel',
        build: composeDateCellDayDesktop,
    });
    desktopMeta.push({
        header: 'redesign.headerEmpty',
        build: composeDateCellHour,
    });
    if (isOwner) {
        desktopMeta.push({
            header: 'multiBoard.participantCountLabel',
            build: composeCountCell,
        });
    }
    desktopMeta.push({
        header: 'redesign.headerEmpty',
        align: 'end',
        build: function (row) {
            return (
                <Button
                    variant="contained"
                    color="default"
                    size="large"
                    onClick={() => {
                        viewRow(row);
                    }}
                >
                    <FormattedMessage id={'redesign.generic.label.view'} />
                </Button>
            );
        },
    });

    if (isOwner) {
        desktopMeta.push({
            header: 'redesign.headerEmpty',
            align: 'end',
            build: function (row) {
                return (
                    <Button
                        color="secondary"
                        startIcon={<DeleteIcon />}
                        onClick={() => {
                            deleteRow(row.id);
                        }}
                    />
                );
            },
        });
    }

    const mobileMeta = [
        {
            header: 'redesign.updateClass.activity',
            build: function (row) {
                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            viewRow(row);
                        }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Button
                                startIcon={<ViewActivityIcon className={classes.icon} />}
                                className={classes.customViewButton}
                            />
                            <div style={{ marginLeft: '1rem' }}>
                                <span className={classes.spanDate2}>
                                    {new Date(row.createdAt).toLocaleString(localesOptions, {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                    })}
                                </span>
                                <div>{isOwner ? row.userCount : null}</div>
                            </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <span className={classes.spanHour2}>
                                {new Date(row.createdAt).toLocaleString(localesOptions, {
                                    hour: 'numeric',
                                    minute: 'numeric',
                                })}
                            </span>
                            {isOwner ? (
                                <Button
                                    startIcon={<DeleteIcon />}
                                    onClick={(event) => {
                                        deleteRow(row.id);
                                        event.stopPropagation();
                                    }}
                                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                                />
                            ) : null}
                        </div>
                    </div>
                );
            },
        },
    ];

    let limitSection = 0;
    if (subscriptions && profile && profile.subscription) {
        limitSection = getIntPermission(subscriptions, profile.subscription, CAPABILITIES.INDIVIDUAL_SESSION_COUNT)
            .value;
    }

    data.forEach((el, index) => {
        if (isOwner) {
            el.enabled = index < limitSection;
        } else {
            el.enabled = true;
        }
    });

    return (
        <>
            {displayTitle ? (
                <Grid item xs={12} sm={12}>
                    <Typography variant="h5" gutterBottom>
                        <FormattedMessage id={'menu.pastLessons'} />
                    </Typography>
                </Grid>
            ) : null}
            {canCreate && canCreate.value === false && isOwner ? (
                <div style={{ padding: '1rem' }}>
                    <Typography variant={'h6'} color={'secondary'}>
                        <FormattedMessage id={'subscription.upsell.multiBoard.exceeded'} />
                    </Typography>
                    {!window.isTeams ? (
                        <Link to={'/subscriptions'}>
                            <Button variant={'contained'} color={'primary'}>
                                <FormattedMessage id="subscriptions.subscription.findOutMore" />
                            </Button>
                        </Link>
                    ) : null}
                </div>
            ) : null}
            <Grid item xs={12} sm={12}>
                <ResponsiveTable
                    data={data}
                    metaDesktop={desktopMeta}
                    metaMobile={mobileMeta}
                    metaTablet={mobileMeta}
                    styled={false}
                    editStyle={false}
                />
                {data.length === 0 ? (
                    <Container style={{ marginLeft: '-1.3rem' }}>
                        <Grid
                            container
                            spacing={2}
                            justify={'flex-start'}
                            style={{ marginTop: '1rem', marginBottom: '1rem' }}
                        >
                            <Grid item>
                                <Typography variant={'h6'} className={classes.emptyStateH6}>
                                    <FormattedMessage id={'multiBoard.noBoards'} />
                                </Typography>
                            </Grid>
                        </Grid>
                    </Container>
                ) : null}
                <GenericDeletionConfirmation
                    open={!!modalItem}
                    deleteFunction={deleteMultiBoard}
                    onClose={() => {
                        setModalItem(null);
                    }}
                    type={intl
                        .formatMessage({
                            id: 'redesign.updateClass.activity',
                        })
                        .toLowerCase()}
                />
            </Grid>
        </>
    );
};

const mapStateToProps = (state) => {
    const { profile, mainAppState } = state;
    return {
        profile,
        subscriptions: mainAppState.subscriptions,
    };
};

export default injectIntl(connect(mapStateToProps)(MultiBoardTable));
