import { FormControl, makeStyles, MenuItem, Select, Typography } from '@material-ui/core';
import RomaniaIcon from '../../MyComponents/icons/Romania';
import UKIcon from '../../MyComponents/icons/UK';
import FranceIcon from '../../MyComponents/icons/France';
import SelectArrowIcon from '../../MyComponents/icons/SelectArrowIcon';
import React from 'react';
import { COLORS2 } from '../../constants';

const useStyles = makeStyles((theme) => ({
    languageLink: {
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        padding: theme.spacing(1),
        borderRadius: '2.188rem',
    },
    img: {
        marginRight: '0.5rem',
        display: 'inline',
    },
    selectLanguageItem: {
        textTransform: 'uppercase',
        fontWeight: '600',
        color: COLORS2.darkGreen,
        fontStyle: 'normal',
        letterSpacing: '0.1rem',
        fontSize: '0.875rem',
        lineHeight: '1.063rem',
        textAlign: 'right',
    },
}));

export const LanguageMenu = ({ handleRo, handleEn, handleFr, language }) => {
    const classes = useStyles();
    let l = window.location;

    return (
        <FormControl style={{ paddingLeft: '2rem' }}>
            <Select defaultValue={language} disableUnderline={true} IconComponent={SelectArrowIcon}>
                <MenuItem value={'ro'}>
                    <a href={`${l.protocol}//${l.host}/ro`} onClick={handleRo} className={classes.languageLink}>
                        <RomaniaIcon className={classes.img} />
                        <Typography align="right" className={classes.selectLanguageItem}>
                            RO
                        </Typography>
                    </a>
                </MenuItem>
                <MenuItem value={'en'}>
                    <a href={`${l.protocol}//${l.host}/en`} onClick={handleEn} className={classes.languageLink}>
                        <UKIcon className={classes.img} />
                        <Typography align="right" className={classes.selectLanguageItem}>
                            EN
                        </Typography>
                    </a>
                </MenuItem>
                <MenuItem value={'fr'}>
                    <a href={`${l.protocol}//${l.host}/fr`} onClick={handleFr} className={classes.languageLink}>
                        <FranceIcon className={classes.img} />
                        <Typography align="right" className={classes.selectLanguageItem}>
                            FR
                        </Typography>
                    </a>
                </MenuItem>
            </Select>
        </FormControl>
    );
};
