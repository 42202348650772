import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, CircularProgress, Container, Grid, Paper, Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { a11yProps, TabPanel } from '../generic/TabPanel';
import { connect } from 'react-redux';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { jsPDF } from 'jspdf';
import GenericState from '../../MyComponents/GenericLoadingErrorState/GenericState';
import drawUtils from '../../pages/LessonPage/drawUtils';
import { GA_EVENT_TREE } from '../../gaConstants';
import PageNavigator from './PageNavigator';
import store from '../../redux/store';
import { upgradeSubscriptionDialog } from '../../redux/actions';
import { CAPABILITIES, hasBooleanPermission } from '../../shared/Capabilities';
import classApi from '../../Api/classApi';
import { makeStyles } from '@material-ui/core/styles';
import { determineDefaultLanguage } from '../utils';
import Hidden from '@material-ui/core/Hidden';
import { COLORS2 } from '../../constants';

let callAddFont = function() {
    this.addFont('/Roboto-Regular.ttf', 'Roboto', 'normal');
};
jsPDF.API.events.push(['addFonts', callAddFont]);


const useStyles = makeStyles(() => ({
    button: {
        background: COLORS2.virtualGreen,
        boxShadow: '0rem 0rem 0rem 0.5rem rgba(8, 170, 180, 0.08)',
        borderRadius: '1rem',
        fontSize: '1rem',
        fontWeight: '700',
    },
}));

const MultiBoardBox = ({ appState, lessonState, profile, subscriptions }) => {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [tabs, setTabs] = useState(null);
    const navigatorRef = useRef(null);
    const [lessonId, setLessonId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [canDownloadServer, setCanDownloadServer] = useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        let tmpLessonId = null;

        if (lessonState.classId) {
            tmpLessonId = lessonState.classId;
        } else if (lessonState.lessonInfo && lessonState.lessonInfo.id) {
            tmpLessonId = lessonState.lessonInfo.id;
        } else if (lessonState.lessonInfo && lessonState.lessonInfo.classData && lessonState.lessonInfo.classData.id) {
            tmpLessonId = lessonState.lessonInfo.classData.id;
        } else if (appState.multiBoardLessonState && appState.multiBoardLessonState.lessonId) {
            tmpLessonId = appState.multiBoardLessonState.lessonId;
        }
        setLessonId(tmpLessonId);
    }, [lessonState.classId, lessonState.lessonInfo, appState.multiBoardLessonState]);

    useEffect(() => {
        if (lessonId && appState.multiBoardLessonState.id) {
            classApi
                .getMultiBoardUserPages(lessonId, appState.multiBoardLessonState.id)
                .then((res) => {
                    let tempTabs = {};
                    res.data.pages.forEach((el) => {
                        if (!tempTabs[el.email]) {
                            tempTabs[el.email] = {
                                id: el.email,
                                email: el.email,
                                name: el.name,
                                originalUrl: res.data.original.download,
                                originalSticky: res.data.original.meta.stickyElements,
                                userState: {
                                    pages: [
                                        {
                                            meta: el.meta,
                                            id: el.id,
                                            download: el.download,
                                        },
                                    ],
                                },
                            };
                        } else {
                            tempTabs[el.email].userState.pages.push({
                                meta: el.meta,
                                id: el.id,
                                download: el.download,
                            });
                        }
                    });
                    let tempTabArr = Object.values(tempTabs).sort((a, b) => {
                        return a?.userState?.name?.toLowerCase() > b?.userState?.name?.toLowerCase();
                    });
                    // console.log(tempTabArr);
                    setTabs(tempTabArr);
                    setLoading(false);
                    setCanDownloadServer(res.data.canDownload);
                })
                .catch((error) => {
                    console.error(error);
                    setLoading(false);
                    setError(true);
                });
        }
    }, [lessonId]);

    // const handleUserRemoval = (targetEmail) => {
    //     window.logAppActivity(GA_EVENT_TREE.individualSessionBox.actions.removeSingleBoard);
    //     if (tabs.length > 1) {
    //         setValue(0);
    //         removeUserMultiBoardEntry(profile.email, lessonId, appState.multiBoardLessonState.id, targetEmail);
    //     } else {
    //         removeUserMultiBoard(profile.email, lessonId, appState.multiBoardLessonState.id);
    //     }
    // };

    // const loadMultiBoard = useCallback(async () => {
    //     if (dataItems.current.isLoading) return;
    //     dataItems.current.isLoading = true;
    //     // let boardData = await buildMultiBoardLessonWithURLs(profile.email, lessonId, appState.multiBoardLessonState.id);
    //     let boardInfoFromLesson = lessonList.getLessonForEmail(profile.email, lessonId);
    //     let userKeys = Object.keys(boardInfoFromLesson[appState.multiBoardLessonState.id].users);
    //     let tmpUsers = boardData.users;
    //     let tabs = [];
    //     for (let i = 0; i < userKeys.length; i++) {
    //         let key = userKeys[i];
    //         if (tmpUsers[key] && tmpUsers[key].pages && tmpUsers[key].pages.length > 0) {
    //             // let userDrawingIds = tmpUsers[key].pages.map(el => el.id);
    //             let data = {
    //                 originalUrl: boardData.originalDownload,
    //                 originalSticky: boardData.originalSticky,
    //                 userState: tmpUsers[key],
    //                 currentPage: null,
    //             };
    //             tabs.push({
    //                 id: key,
    //                 name: tmpUsers[key].name,
    //                 originalDownload: boardData.originalDownload,
    //                 originalSticky: boardData.originalSticky,
    //                 userDrawingData: data,
    //             });
    //             // for(let i=0; i< userDrawingIds.length; i++) {
    //             //     if (i===0) {
    //             //         dataItems.current[userDrawingIds[i]] = await drawUtils.buildComplexComposedImage(
    //             //             tmpUsers[key].pages[i].gridType,
    //             //             boardData.originalDownload,
    //             //             boardData.originalSticky,
    //             //             tmpUsers[key].pages[i].stickyElements,
    //             //             null,
    //             //             tmpUsers[key].pages[i].id);
    //             //         //
    //             //         // dataItems.current[userDrawingIds[i]] = await drawUtils.buildComposedImageForMultiBoard(
    //             //         //     boardData.originalLineage,
    //             //         //     boardData.originalDownload,
    //             //         //     boardData.originalSticky,
    //             //         //     boardData.users[key] && boardData.users[key].pages && boardData.users[key].pages[0]
    //             //         //         ? boardData.users[key].pages[0].stickyElements
    //             //         //         : [],
    //             //         //     tmpUsers[key].pages[0].id
    //             //         // );
    //             //     } else {
    //             //         dataItems.current[userDrawingIds[i]] = await drawUtils.buildComplexComposedImage(
    //             //             tmpUsers[key].pages[i].gridType,
    //             //             null,
    //             //             null,
    //             //             tmpUsers[key].pages[i].stickyElements,
    //             //             null,
    //             //             tmpUsers[key].pages[i].id);
    //             //     }
    //             // }
    //         }
    //     }
    //     dataItems.current.isLoading = false;
    //     window.logAppActivity(GA_EVENT_TREE.individualSessionBox.actions.seeIndividualSession, tabs.length);
    //     setTabs(tabs);
    // }, [appState.multiBoardLessonState.id, appState.multiBoardLessonState.users]);

    // useEffect(() => {
    //     loadMultiBoard();
    // }, [loadMultiBoard]);

    if (!lessonId) {
        return null;
    }
    if (loading || error) {
        return <GenericState isLoading={loading} isError={error} inline />;
    }

    let localesOptions = 'ro-RO';
    let currentLanguage = determineDefaultLanguage();
    if (currentLanguage === 'en') {
        localesOptions = 'en-EN';
    } else if (currentLanguage === 'fr') {
        localesOptions = 'fr-FR';
    } else if (currentLanguage === 'ro') {
        localesOptions = 'ro-RO';
    }

    let data = appState.multiBoardLessonState;

    let dateStr = new Date(data.date).toLocaleString(localesOptions, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
    let hourStr = new Date(data.date).toLocaleString(localesOptions, {
        hour: 'numeric',
        minute: 'numeric',
    });

    let lessonName = lessonState?.lessonInfo?.name
        ? lessonState.lessonInfo.name
        : lessonState?.lessonInfo?.classData?.name;
    if (!lessonName) {
        lessonName = profile.name;
    }

    if (!tabs) {
        return (
            <Container>
                <Grid container spacing={2} justify={'center'}>
                    <Grid item>
                        <GenericState isLoading={true} />
                    </Grid>
                </Grid>
            </Container>
        );
    }

    if (tabs.length === 0 || !lessonId) {
        return (
            <Container>
                <Grid container spacing={2} justify={'center'}>
                    <Grid item>
                        <FormattedMessage id={'multiBoard.noBoards'} />
                    </Grid>
                </Grid>
            </Container>
        );
    }

    const downloadPdf = async () => {
        let canDownload = hasBooleanPermission(subscriptions, profile.subscription, CAPABILITIES.PDF_DOWNLOAD);

        if (!canDownload.value && !canDownloadServer) {
            store.dispatch(
                upgradeSubscriptionDialog({
                    key: 'subscription.upsell.pdf.unavailable',
                    subscriptions: canDownload.availableSubscriptions,
                }),
            );
            return;
        }

        if (tabs.length > 0) {
            window.logAppActivity(
                GA_EVENT_TREE.individualSessionBox.actions.downloadEntireIndividualSessionPDF,
                tabs.length,
            );
            const doc = new jsPDF({
                orientation: 'landscape',
                unit: 'mm',
                format: [297, 210],
                compress: 'true',
            });
            doc.setFont('Roboto');

            for (let i = 0; i < tabs.length; i++) {
                if (tabs[i] && tabs[i].userState.pages) {
                    let pages = tabs[i].userState.pages;
                    if (pages.length > 0) {
                        for (let j = 0; j < pages.length; j++) {
                            let data = null;
                            if (j === 0) {
                                data = await drawUtils.buildComplexComposedImage(
                                    pages[j].meta.gridType,
                                    tabs[i].originalUrl,
                                    tabs[i].originalSticky,
                                    pages[j].meta.stickyElements,
                                    pages[j].download,
                                    pages[j].id,
                                );
                            } else {
                                data = await drawUtils.buildComplexComposedImage(
                                    pages[j].meta.gridType,
                                    null,
                                    null,
                                    pages[j].meta.stickyElements,
                                    pages[j].download,
                                    pages[j].id,
                                );
                            }
                            if (data) {
                                doc.text(tabs[i].name, 30, 15);
                                doc.text(`${j + 1} / ${pages.length}`, 280, 15);
                                doc.addImage(data, 'png', 10, 35, 280, 140, '', 'FAST');
                                if (i < tabs.length - 1 || j < pages.length - 1) {
                                    doc.addPage('a4', 'landscape');
                                }
                            }
                        }
                    }
                }
            }
            let name = lessonName + '-individual' + dateStr + hourStr + '.pdf';
            doc.save(name);
        }
    };

    const downloadSinglePdf = async (el) => {
        let canDownload = hasBooleanPermission(subscriptions, profile.subscription, CAPABILITIES.PDF_DOWNLOAD);

        if (!canDownload.value && !canDownloadServer) {
            store.dispatch(
                upgradeSubscriptionDialog({
                    key: 'subscription.upsell.pdf.unavailable',
                    subscriptions: canDownload.availableSubscriptions,
                }),
            );
            return;
        }

        if (!el.id) {
            return;
        }
        window.logAppActivity(GA_EVENT_TREE.individualSessionBox.actions.downloadSinglePDF);
        const doc = new jsPDF({
            orientation: 'landscape',
            unit: 'mm',
            format: [297, 210],
            compress: true,
        });

        let elements = navigatorRef.current.getCurrentComposedImages();
        if (elements && elements.length > 1) {
            doc.text(el.name, 30, 15);
            doc.text(`1 / ${elements.length}`, 280, 15);
            doc.addImage(elements[0], 'png', 10, 35, 280, 140, '', 'FAST');
            for (let i = 1; i < elements.length; i++) {
                doc.addPage('a4', 'landscape');
                doc.text(`${i + 1} / ${elements.length}`, 280, 15);
                doc.addImage(elements[i], 'png', 10, 35, 280, 140, '', 'FAST');
            }
        } else {
            doc.text(el.name, 30, 15);
            if (elements && elements[0]) {
                doc.addImage(elements[0], 'png', 10, 35, 280, 140, '', 'FAST');
            }
        }
        let name = lessonName + '-individual-' + el.name + '-' + dateStr + hourStr + '.pdf';
        doc.save(name);
    };

    if (!tabs || tabs.length === 0) {
        return (
            <Container>
                <div style={{ padding: '60px 150px' }}>
                    <CircularProgress />
                </div>
            </Container>
        );
    }

    let sortedArr = [...tabs];
    try {
        sortedArr.sort((a, b) => {
            return a.name.localeCompare(b.name);
        });
    } catch (ex) {
        console.error('Unable to compare', sortedArr);
    }

    return (
        <>
            <AppBar position='static' color='default' elevation={0}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor='primary'
                    textColor='primary'
                    variant='scrollable'
                    scrollButtons='auto'
                    aria-label='scrollable auto tabs example'
                >
                    {sortedArr.map((el, index) => (
                        <Tab label={el.name} {...a11yProps(index)} key={`tabForMultiLesson-${el.id}`} />
                    ))}
                </Tabs>
            </AppBar>
            {sortedArr.map((el, index) => {
                return (
                    <TabPanel value={value} index={index} key={`tabContentForMultiBoardSession${el.id}`}>
                        <Grid container spacing={2}>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                            >
                                <Button
                                    size='large'
                                    color='primary'
                                    variant='contained'
                                    onClick={downloadPdf}
                                    className={classes.button}
                                    startIcon={<PictureAsPdfIcon />}
                                >
                                    <FormattedMessage id={'multiBoardBox.downloadAllPdf'} />
                                </Button>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Typography variant='h4'>
                                    {lessonName} - {dateStr} {hourStr}
                                </Typography>
                            </Grid>

                            <Hidden only={['xs', 'sm', 'md']}>
                                <Grid item xs={12} sm={3} style={{ textAlign: 'end' }}>
                                    <Button
                                        size='large'
                                        variant='contained'
                                        color='default'
                                        onClick={() => {
                                            document.getElementById(
                                                'downloadImage',
                                            ).href = navigatorRef.current.getCurrentComposedImage();
                                            document.getElementById(
                                                'downloadImage',
                                            ).download = `${lessonName}-${el.name}-${dateStr}`;
                                            document.getElementById('downloadImage').click();
                                        }}
                                    >
                                        <FormattedMessage id={'generic.label.downloadImage'} />
                                    </Button>
                                </Grid>
                            </Hidden>
                            <Hidden only={['lg', 'xl']}>
                                <Grid item xs={12} sm={3} style={{ textAlign: 'center' }}>
                                    <Button
                                        size='large'
                                        variant='contained'
                                        color='default'
                                        onClick={() => {
                                            document.getElementById(
                                                'downloadImage',
                                            ).href = navigatorRef.current.getCurrentComposedImage();
                                            document.getElementById(
                                                'downloadImage',
                                            ).download = `${lessonName}-${el.name}-${dateStr}`;
                                            document.getElementById('downloadImage').click();
                                        }}
                                    >
                                        <FormattedMessage id={'generic.label.downloadImage'} />
                                    </Button>
                                </Grid>
                            </Hidden>
                            <Hidden only={['xs', 'sm', 'md']}>
                                <Grid item xs={12} sm={3} style={{ textAlign: 'start' }}>
                                    <Button
                                        size='large'
                                        variant='contained'
                                        color='default'
                                        onClick={() => {
                                            downloadSinglePdf(el);
                                        }}
                                    >
                                        <FormattedMessage id={'multiBoardBox.downloadSinglePdf'} />
                                    </Button>
                                </Grid>
                            </Hidden>
                            <Hidden only={['lg', 'xl']}>
                                <Grid item xs={12} sm={3} style={{ textAlign: 'center' }}>
                                    <Button
                                        size='large'
                                        variant='contained'
                                        color='default'
                                        onClick={() => {
                                            downloadSinglePdf(el);
                                        }}
                                    >
                                        <FormattedMessage id={'multiBoardBox.downloadSinglePdf'} />
                                    </Button>
                                </Grid>
                            </Hidden>
                            <a style={{ display: 'none' }} id='downloadImage' download='myCanvas.png' />
                            {/*{lessonState.isStarted ? (*/}
                            {/*    <Grid item xs={12} sm={3} style={{ textAlign: 'center' }}>*/}
                            {/*        <Button*/}
                            {/*            variant="outlined"*/}
                            {/*            color="primary"*/}
                            {/*            size="small"*/}
                            {/*            onClick={() => {*/}
                            {/*                if (window.loadMultiBoardInLesson) {*/}
                            {/*                    window.logAppActivity(*/}
                            {/*                        GA_EVENT_TREE.individualSessionBox.actions.loadImage*/}
                            {/*                    );*/}
                            {/*                    window.loadMultiBoardInLesson(*/}
                            {/*                        lessonId,*/}
                            {/*                        appState.multiBoardLessonState.id,*/}
                            {/*                        el.id,*/}
                            {/*                        el.originalDownload,*/}
                            {/*                        navigatorRef.current.getCurrentItem()*/}
                            {/*                    );*/}
                            {/*                    store.dispatch(showMultiBoardLessonState(null));*/}
                            {/*                }*/}
                            {/*                // console.log(navigatorRef.current.getCurrentItem());*/}
                            {/*            }}*/}
                            {/*        >*/}
                            {/*            <FormattedMessage id={'generic.label.loadImage'} />*/}
                            {/*        </Button>*/}
                            {/*    </Grid>*/}
                            {/*) : null}*/}
                            {/*<Grid item xs={12} sm={3} style={{ textAlign: 'center' }}>*/}
                            {/*    <Button*/}
                            {/*        variant="outlined"*/}
                            {/*        size="small"*/}
                            {/*        color="secondary"*/}
                            {/*        onClick={() => handleUserRemoval(el.id)}*/}
                            {/*    >*/}
                            {/*        <FormattedMessage id={'generic.label.delete'} />*/}
                            {/*    </Button>*/}
                            {/*</Grid>*/}
                            <Grid item xs={12}>
                                <Paper variant='outlined' className='u-p-full'>
                                    <PageNavigator data={el} ref={navigatorRef} />
                                </Paper>
                            </Grid>
                        </Grid>
                    </TabPanel>
                );
            })}
        </>
    );
};

const mapStateToProps = (state) => {
    const { appState, lessonState, profile, mainAppState } = state;
    return { appState, lessonState, profile, subscriptions: mainAppState.subscriptions };
};

export default injectIntl(connect(mapStateToProps)(MultiBoardBox));
