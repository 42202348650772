import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function QRCodeIcon(props) {
    return (
        <SvgIcon {...props} style={{ ...props.style }}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                zoomAndPan="magnify"
                viewBox="0 0 150 150"
                preserveAspectRatio="xMidYMid meet"
                version="1.0"
            >
                <path
                    d="M 95.707031 12.878906 L 95.707031 54.296875 L 137.121094 54.296875 L 137.121094 12.878906 Z M 130.21875 26.683594 L 130.21875 33.585938 L 123.316406 33.585938 L 123.316406 40.492188 L 130.21875 40.492188 L 130.21875 47.394531 L 102.605469 47.394531 L 102.605469 33.585938 L 109.507812 33.585938 L 109.507812 26.683594 L 102.605469 26.683594 L 102.605469 19.78125 L 109.507812 19.78125 L 109.507812 26.683594 L 116.414062 26.683594 L 116.414062 19.78125 L 130.21875 19.78125 Z M 130.21875 26.683594 "
                    fillOpacity="1"
                    fillRule="nonzero"
                />
                <path
                    d="M 116.414062 26.683594 L 123.316406 26.683594 L 123.316406 33.585938 L 116.414062 33.585938 Z M 116.414062 26.683594 "
                    fillOpacity="1"
                    fillRule="nonzero"
                />
                <path
                    d="M 81.902344 40.492188 L 81.902344 47.386719 L 88.804688 47.386719 L 88.804688 19.78125 L 68.097656 19.78125 L 68.097656 26.683594 L 75 26.683594 L 75 33.585938 L 81.902344 33.585938 Z M 81.902344 40.492188 "
                    fillOpacity="1"
                    fillRule="nonzero"
                />
                <path
                    d="M 109.507812 33.585938 L 116.414062 33.585938 L 116.414062 40.492188 L 109.507812 40.492188 Z M 109.507812 33.585938 "
                    fillOpacity="1"
                    fillRule="nonzero"
                />
                <path
                    d="M 54.292969 40.492188 L 54.292969 12.878906 L 12.878906 12.878906 L 12.878906 54.296875 L 54.292969 54.296875 Z M 47.386719 19.78125 L 47.386719 33.585938 L 40.484375 33.585938 L 40.484375 19.78125 Z M 19.78125 19.78125 L 26.683594 19.78125 L 26.683594 26.683594 L 19.78125 26.683594 Z M 33.585938 47.386719 L 33.585938 40.484375 L 26.683594 40.484375 L 26.683594 47.386719 L 19.78125 47.386719 L 19.78125 40.484375 L 26.683594 40.484375 L 26.683594 33.582031 L 33.585938 33.582031 L 33.585938 40.484375 L 47.394531 40.484375 L 47.394531 47.386719 Z M 33.585938 47.386719 "
                    fillOpacity="1"
                    fillRule="nonzero"
                />
                <path
                    d="M 68.097656 40.492188 L 75 40.492188 L 75 33.585938 L 61.195312 33.585938 L 61.195312 47.386719 L 68.097656 47.386719 Z M 68.097656 40.492188 "
                    fillOpacity="1"
                    fillRule="nonzero"
                />
                <path
                    d="M 54.292969 61.195312 L 54.292969 95.707031 L 95.703125 95.707031 L 95.703125 54.292969 L 54.292969 54.292969 Z M 81.902344 61.195312 L 81.902344 68.097656 L 88.804688 68.097656 L 88.804688 88.804688 L 68.097656 88.804688 L 68.097656 75 L 75 75 L 75 68.097656 L 61.195312 68.097656 L 61.195312 61.195312 Z M 81.902344 61.195312 "
                    fillOpacity="1"
                    fillRule="nonzero"
                />
                <path
                    d="M 47.386719 68.097656 L 47.386719 61.195312 L 26.683594 61.195312 L 26.683594 68.097656 L 19.78125 68.097656 L 19.78125 88.804688 L 26.683594 88.804688 L 26.683594 75 L 33.585938 75 L 33.585938 68.097656 Z M 47.386719 68.097656 "
                    fillOpacity="1"
                    fillRule="nonzero"
                />
                <path
                    d="M 109.507812 61.195312 L 130.21875 61.195312 L 130.21875 68.097656 L 109.507812 68.097656 Z M 109.507812 61.195312 "
                    fillOpacity="1"
                    fillRule="nonzero"
                />
                <path
                    d="M 102.613281 68.097656 L 109.515625 68.097656 L 109.515625 75 L 102.613281 75 Z M 102.613281 68.097656 "
                    fillOpacity="1"
                    fillRule="nonzero"
                />
                <path
                    d="M 33.585938 75 L 40.492188 75 L 40.492188 81.902344 L 33.585938 81.902344 Z M 33.585938 75 "
                    fillOpacity="1"
                    fillRule="nonzero"
                />
                <path
                    d="M 75 75 L 81.902344 75 L 81.902344 81.902344 L 75 81.902344 Z M 75 75 "
                    fillOpacity="1"
                    fillRule="nonzero"
                />
                <path
                    d="M 116.414062 75 L 116.414062 81.902344 L 102.613281 81.902344 L 102.613281 88.804688 L 130.21875 88.804688 L 130.21875 81.902344 L 123.316406 81.902344 L 123.316406 75 Z M 116.414062 75 "
                    fillOpacity="1"
                    fillRule="nonzero"
                />
                <path
                    d="M 40.492188 81.902344 L 47.394531 81.902344 L 47.394531 88.804688 L 40.492188 88.804688 Z M 40.492188 81.902344 "
                    fillOpacity="1"
                    fillRule="nonzero"
                />
                <path
                    d="M 12.878906 102.613281 L 12.878906 137.125 L 54.292969 137.125 L 54.292969 95.707031 L 12.878906 95.707031 Z M 40.492188 130.21875 L 33.585938 130.21875 L 33.585938 123.316406 L 26.683594 123.316406 L 26.683594 130.21875 L 19.78125 130.21875 L 19.78125 123.316406 L 26.683594 123.316406 L 26.683594 116.414062 L 33.585938 116.414062 L 33.585938 123.316406 L 40.492188 123.316406 Z M 40.492188 102.613281 L 40.492188 109.515625 L 47.394531 109.515625 L 47.394531 116.417969 L 40.492188 116.417969 L 40.492188 109.515625 L 33.585938 109.515625 L 33.585938 102.613281 Z M 26.683594 102.613281 L 26.683594 109.515625 L 19.78125 109.515625 L 19.78125 102.613281 Z M 26.683594 102.613281 "
                    fillOpacity="1"
                    fillRule="nonzero"
                />
                <path
                    d="M 81.902344 102.613281 L 68.097656 102.613281 L 68.097656 109.507812 L 61.195312 109.507812 L 61.195312 130.21875 L 68.097656 130.21875 L 68.097656 116.414062 L 75 116.414062 L 75 130.21875 L 81.902344 130.21875 L 81.902344 116.414062 L 88.804688 116.414062 L 88.804688 109.507812 L 81.902344 109.507812 Z M 81.902344 102.613281 "
                    fillOpacity="1"
                    fillRule="nonzero"
                />
                <path
                    d="M 95.707031 109.507812 L 95.707031 137.121094 L 137.121094 137.121094 L 137.121094 95.703125 L 95.707031 95.703125 Z M 109.507812 102.613281 L 109.507812 116.417969 L 130.21875 116.417969 L 130.21875 130.222656 L 102.605469 130.222656 L 102.605469 102.613281 Z M 109.507812 102.613281 "
                    fillOpacity="1"
                    fillRule="nonzero"
                />
                <path
                    d="M 150.003906 150.003906 L -0.00390625 150.003906 L -0.00390625 -0.00390625 L 150.003906 -0.00390625 Z M 2.90625 147.09375 L 147.09375 147.09375 L 147.09375 2.90625 L 2.90625 2.90625 Z M 2.90625 147.09375 "
                    fillOpacity="1"
                    fillRule="nonzero"
                />
            </svg>
        </SvgIcon>
    );
}
