import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { random } from 'lodash';

const useStyles = makeStyles(() => ({
    table: {
        // minWidth: 650,
    },
    disableRow: {
        background: 'rgba(210, 104, 40, 0.5)',
    },
    enableRow: {},
    centerMobileCell: {
        display: 'flex',
        flexDirection: 'column',
    },
}));

const MobileTable = ({ data, meta, editStyle }) => {
    const classes = useStyles();

    return (
        <>
            <Paper
                style={{
                    width: '100',
                    boxShadow: editStyle ? '0rem 0.5rem 1.5rem rgba(210, 104, 40, 0.08)' : 'none',
                    borderRadius: editStyle ? '1rem' : '0rem',
                }}
            >
                {
                    <span>
                        {meta.map((entry, index) => {
                            return (
                                <div key={`${random(10000)}-${index}`}>
                                    {meta[index].title ? <> {meta[index].title()} </> : <div />}
                                </div>
                            );
                        })}
                    </span>
                }
                {data.map((row) => (
                    <Grid
                        container
                        key={row.id}
                        className={row.enabled !== false ? classes.enableRow : classes.disableRow}
                        direction={'row'}
                        style={{
                            padding: '0.5rem',
                            borderTop: editStyle ? '0.063rem solid #D268281A' : '0rem',
                            display: 'flex',
                        }}
                    >
                        {meta.map((entry, index) => {
                            return (
                                <Grid
                                    item
                                    xs={12}
                                    key={'mobileRow-' + row.id + '-' + index}
                                    className={classes.centerMobileCell}
                                >
                                    {meta[index].header ? (
                                        <div style={{ width: '50%' }}>
                                            <Typography style={{ textTransform: 'uppercase', fontWeight: 400, fontSize: '0.625rem', color: '#6D6D6'}}>
                                                <FormattedMessage id={meta[index].header} />
                                            </Typography>
                                        </div>
                                    ) : null}
                                    <div style={{ width: '100%' }}>{meta[index].build(row)}</div>
                                </Grid>
                            );
                        })}
                    </Grid>
                ))}
            </Paper>
        </>
    );
};

export default MobileTable;
