import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { Avatar, Button, Grid } from '@material-ui/core';
import managementApi from '../../Api/managementApi';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { getSubscriptionData } from '../../shared/Capabilities';
import { connect } from 'react-redux';

const UpdateUser = ({ subscriptions, user, close }) => {
    const [serverUser, setServerUser] = React.useState(null);
    const [selectedDate, setSelectedDate] = React.useState(new Date());

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    useEffect(() => {
        if (user) {
            managementApi.findUserById(user.id).then((res) => {
                setServerUser(res.data);
                setSelectedDate(new Date(res.data?.subscription?.endDate));
            });
        }
    }, [user]);

    let subscriptionData = null;
    if (serverUser && subscriptions) {
        subscriptionData = getSubscriptionData(subscriptions, serverUser.subscription);
    }

    return (
        <Grid container direction="row" spacing={2} style={{ paddingTop: '20px' }}>
            <Grid item xs={3} style={{ border: 'solid' }}>
                <Button onClick={close} variant={'contained'}>
                    Close
                </Button>
            </Grid>
            <Grid item xs={3} style={{ border: 'solid' }}>
                <Avatar src={user.avatar} />
            </Grid>
            <Grid item xs={3} style={{ border: 'solid' }}>
                <Typography>{user.email}</Typography>
            </Grid>
            <Grid item xs={3} style={{ border: 'solid' }}>
                <Typography>
                    {user.first_name} {user.last_name}
                </Typography>
            </Grid>
            {serverUser ? (
                <>
                    <Grid item xs={4} style={{ border: 'solid' }}>
                        <Typography>Groups: {serverUser.groupCount}</Typography>
                    </Grid>
                    <Grid item xs={4} style={{ border: 'solid' }}>
                        <Typography>Folders : {serverUser.collectionFolderCount}</Typography>
                    </Grid>
                    <Grid item xs={4} style={{ border: 'solid' }}>
                        <Typography>Library: {serverUser.libraryCount}</Typography>
                    </Grid>
                </>
            ) : null}

            {serverUser && serverUser.prefs && serverUser.subscription ? (
                <>
                    <Grid item xs={6} style={{ border: 'solid' }}>
                        <Typography>Customer Stripe ID: {serverUser.prefs.customerStripeId}</Typography>
                    </Grid>
                    <Grid item xs={6} style={{ border: 'solid' }}>
                        <Typography>
                            Subscription Stripe ID: {serverUser.subscription.stripeCustomerSubscriptionId}
                        </Typography>
                    </Grid>
                    <Grid item xs={3} style={{ border: 'solid' }}>
                        <Typography>Email valid: {serverUser.prefs.emailIsValid ? 'true' : 'false'}</Typography>
                    </Grid>
                    <Grid item xs={3} style={{ border: 'solid' }}>
                        <Typography>{subscriptionData ? subscriptionData.subscriptionName : ''}</Typography>
                    </Grid>
                    <Grid item xs={3} style={{ border: 'solid' }}>
                        <Typography>
                            End date:
                            {serverUser.subscription.endDate
                                ? new Date(serverUser.subscription.endDate).toLocaleString()
                                : 'never'}
                        </Typography>
                    </Grid>
                    <Grid item xs={3} style={{ border: 'solid' }}>
                        <Typography>
                            Canceled :
                            {serverUser.subscription.canceledAt
                                ? new Date(serverUser.subscription.canceledAt).toLocaleString()
                                : 'NO'}
                        </Typography>
                    </Grid>
                    <Grid item xs={4} style={{ border: 'solid' }}>
                        <Button
                            variant={'contained'}
                            onClick={() => {
                                managementApi.makeUserSpecial(user.id);
                            }}
                        >
                            Make Special
                        </Button>
                    </Grid>
                    <Grid item xs={4} style={{ border: 'solid' }}>
                        <Button
                            variant={'contained'}
                            onClick={() => {
                                managementApi.makeUserFree(user.id);
                            }}
                        >
                            Make Free
                        </Button>
                    </Grid>
                    <Grid item xs={4} style={{ border: 'solid' }}>
                        <Button
                            variant={'contained'}
                            onClick={() => {
                                managementApi.makeUserBasic(user.id);
                            }}
                        >
                            Make Basic
                        </Button>
                    </Grid>
                    <Grid item xs={4} style={{ border: 'solid' }}>
                        <Button
                            variant={'contained'}
                            onClick={() => {
                                managementApi.makeUserPremium(user.id);
                            }}
                        >
                            Make Premium
                        </Button>
                    </Grid>
                    <Grid item xs={4} style={{ border: 'solid' }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Date picker inline"
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                        <Button
                            variant={'contained'}
                            onClick={() => {
                                managementApi.updateUserEndDate(user.id, selectedDate);
                            }}
                        >
                            Update Expiration
                        </Button>
                    </Grid>
                </>
            ) : null}
        </Grid>
    );
};

const mapStateToProps = (state) => {
    const { mainAppState } = state;
    return { subscriptions: mainAppState.subscriptions };
};

export default connect(mapStateToProps)(UpdateUser);
