import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function HandRaisedIcon(props) {
    return (
        <SvgIcon {...props} style={{ ...props.style, verticalAlign: 'bottom' }}>
            <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 50 50" viewBox="0 0 50 50">
                <path
                    fill="#FBC792"
                    d="M42.3663788,8.9597511v20.3999996c0,1.1998997-0.7700005,2.2299995-1.8499985,2.6100006v5.709898
				H21.4263783c-2.539999-4.3998985-6.1399994-8.1099987-10.5299997-10.7499981l-3.7399998-2.2399998
				c1.8199997-3.0300007,5.75-4.0100002,8.7700005-2.1899014l4.3399992,2.6000004V6.7597508
				c0-1.52,1.2299995-2.7600002,2.7600002-2.7600002c0.7600002,0,1.4500008,0.3099003,1.9500008,0.8099003
				s0.8099995,1.1900997,0.8099995,1.9500999v-2c0-1.5200002,1.2399998-2.7600002,2.7600002-2.7600002
				c1.5300007,0,2.7700005,1.2399999,2.7700005,2.7600002v1.5c0-1.52,1.2299995-2.7600002,2.7599983-2.7600002
				c1.5200005,0,2.7600021,1.2400002,2.7600021,2.7600002v2.7000003c0-1.5201006,1.2399979-2.7600002,2.7700005-2.7600002
				c0.7599983,0,1.4499969,0.3099999,1.9499969,0.7999997C42.0563774,7.5097508,42.3663788,8.1997509,42.3663788,8.9597511z"
                />
                <path
                    d="M18.8401623,33.91325c0.9505005,1.1902008,1.8232994,2.4443016,2.5886993,3.7700005h19.0907993V33.91325H18.8401623z"
                    opacity=".05"
                />
                <path
                    fill="#414141"
                    d="M24.6353798,40.8397522c-1.1000004,0-2,0.8999977-2,2c0,1.1098976,0.8999996,2,2,2
			c1.1099987,0,2-0.8901024,2-2C26.6353798,41.7397499,25.7453785,40.8397522,24.6353798,40.8397522z"
                />
                <path
                    fill="#414141"
                    d="M41.5159607,36.8294525v-4.2277031c1.1299019-0.6688995,1.8506012-1.8958988,1.8506012-3.2420979
			V8.9597511c0-2.0365005-1.6397018-3.7598004-3.7598-3.7598004c-0.6581993,0-1.2783012,0.1689-1.8172989,0.4663
			c-0.285202-1.7925-1.8418007-3.1665001-3.7129021-3.1665001c-0.7861824,0-1.5165977,0.2422001-2.1201,0.6562002
			c-0.6044998-1.2734001-1.9062996-2.1562002-3.4101982-2.1562002c-1.6504002,0-3.0557003,1.0693002-3.5605011,2.5518
			c-0.5849991-0.3599-1.2579002-0.5518-1.9589996-0.5518c-2.0732994,0-3.7607994,1.6870003-3.7607994,3.7603002v16.5750999
			c-0.1004009-0.0601997-3.0072994-1.8037987-2.8232002-1.6932983c-0.0028992-0.0018005-0.0063839-0.0016003-0.0093002-0.0033016
			c-3.5528002-2.1330986-8.0739994-0.8934994-10.1343002,2.5365009c-0.1366997,0.2275009-0.1766996,0.5-0.1122999,0.7572994
			c0.0645165,0.2573013,0.2285166,0.4790001,0.4561,0.6152l3.7372999,2.2392998
			c3.7782001,2.2714005,7.0046997,5.4238014,9.415699,9.1608009c-1.1522999,0.4976997-1.9615822,1.6432991-1.9615822,2.9763985
			v5.8330002c0,1.7881012,1.4540825,3.2432022,3.2421837,3.2432022h19.5243969c1.7881012,0,3.2422028-1.455101,3.2422028-3.2432022
			v-5.8330002C43.8431625,38.4558525,42.8566628,37.2272491,41.5159607,36.8294525z M11.4095621,26.0720501l-2.8017998-1.6786995
			c1.7181005-1.9451008,4.5874996-2.3712006,6.8046999-1.0356998c0.1005001,0.0603008,7.9354,4.7602005,7.4414005,4.4638996
			c0.4723988,0.2833996,1.0873985,0.1315994,1.3720989-0.3428001c0.2842007-0.4736004,0.1308002-1.0879002-0.3428001-1.3720989
			L21.2659626,24.53615V6.7600508c0-0.9707999,0.7901001-1.7603002,1.7607994-1.7603002
			c0.467701,0,0.9081993,0.1835999,1.2422009,0.5170999c0.3338985,0.3340001,0.5174999,0.7754002,0.5174999,1.2432003
			c0,0.0016999,0.0009995,0.0030999,0.0009995,0.0047998v15.7544003c0,0.5522995,0.4473,1,1,1s1-0.4477005,1-1
			c0-0.1109009-0.0000839-1.6184998-0.0009995-17.759201c0-0.9708002,0.7890987-1.7603002,1.7598-1.7603002
			c0.9765987,0,1.7704983,0.7895,1.7704983,1.7603002c-0.0081997,24.8403015-0.0050983,15.6402998-0.0057983,17.759201
			c0,0.5522995,0.4471989,1,1,1c0.5527,0,1-0.4477005,1-1V6.2890506c0.0002975-0.0102,0.0057983-0.0186996,0.0057983-0.0289998
			c0-0.9707999,0.7891006-1.7603002,1.7598-1.7603002s1.7597008,0.7895002,1.7597008,1.7603002
			c-0.0001984,1.5176997,0.0008011-5.4151001-0.0018845,14.7279997c0,0.5522003,0.4472847,1,1,1c0.5526848,0,1-0.4477997,1-1
			V8.969451c0-0.0035,0.0018845-0.0063,0.0018845-0.0096998c0-0.9703002,0.7939987-1.7598004,1.7705002-1.7598004
			c1.0068016,0,1.7598,0.8148999,1.7598,1.7598004v20.3999004c0,0.7484989-0.4756012,1.4183998-1.1826019,1.6669998
			c-0.4003983,0.1406002-0.6679993,0.5184994-0.6679993,0.9433002v4.7099991H21.9974613
			C19.3753796,32.3264503,15.7249622,28.6662502,11.4095621,26.0720501z M41.8431625,45.7570496
			c0,0.6856003-0.5576019,1.2432022-1.2422028,1.2432022H21.0765629c-0.6846008,0-1.2421837-0.5576019-1.2421837-1.2432022
			v-5.8330002c0-0.684597,0.5575829-1.2421989,1.2421837-1.2421989h19.5243969c0.6846008,0,1.2422028,0.5576019,1.2422028,1.2421989
			V45.7570496z"
                />
            </svg>
        </SvgIcon>
    );
}
