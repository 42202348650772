import React, { useImperativeHandle, useRef, useState } from 'react';
import { COLORS } from '../../constants';
import drawUtils from '../../pages/LessonPage/drawUtils';
import { DeleteForever } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import MoveIcon from '../icons/MoveIcon';
import RotateIconNew from '../icons/RotateIconNew';

const useStyles = makeStyles(() => ({
    moveButton: {
        position: 'absolute',
        color: COLORS.primaryD2,
        bottom: '32px',
        width: '32px',
        height: '32px',
        left: '40px',
        borderRadius: '50%',
        backgroundColor: COLORS.primaryD2 + 33,
    },
    deleteButton: {
        position: 'absolute',
        color: COLORS.secondaryD2,
        right: '50%',
        bottom: '50%',
        width: '24px',
        height: '24px',
        borderRadius: '50%',
        backgroundColor: COLORS.secondaryD2 + 33,
    },
    rotateButton: {
        position: 'absolute',
        color: COLORS.primaryD2,
        bottom: '32px',
        width: '32px',
        height: '32px',
        right: '40px',
        borderRadius: '50%',
        backgroundColor: COLORS.primaryD2 + 33,
    },
    hiddenActionButton: { width: '32px', height: '32px', backgroundColor: 'none' },
}));

const ProtractorComponent = React.forwardRef((props, ref) => {
    let [visible, setVisible] = useState(false);
    let [startPoint, setStartPoint] = useState({ x: 0, y: 0 });
    let [position, setPosition] = useState({
        x: drawUtils.CANVAS_ACTUAL_WIDTH / 3,
        y: drawUtils.CANVAS_ACTUAL_HEIGHT / 3,
    });
    let [rotation, setRotation] = useState(0);
    let [canHandle, setCanHandle] = useState(false);
    const classes = useStyles();

    let refUpdateDebouncer = useRef({
        position: {
            x: drawUtils.CANVAS_ACTUAL_WIDTH / 3,
            y: drawUtils.CANVAS_ACTUAL_HEIGHT / 3,
        },
        debounceFunc: null,
        startPoint: { x: 0, y: 0 },
        rotation: 0,
        visible: false,
        moveX: 0,
        moveY: 0,
    });

    useImperativeHandle(ref, () => ({
        showElement(el) {
            let newState = !visible;

            if (el !== undefined && el !== null) {
                newState = el;
                setVisible(el);
                if (refUpdateDebouncer && refUpdateDebouncer.current) {
                    refUpdateDebouncer.current.visible = el;
                }
            } else {
                setVisible(!visible);
                if (refUpdateDebouncer && refUpdateDebouncer.current) {
                    refUpdateDebouncer.current.visible = !visible;
                }
            }
            setTimeout(() => {
                updatePositions(true);
            }, 200);
        },
        updateScale(startPoint) {
            setStartPoint({ x: startPoint.x, y: startPoint.y });
            refUpdateDebouncer.current.startPoint = { x: startPoint.x, y: startPoint.y };
            setTimeout(updatePositions, 200);
        },
        getState() {
            return {
                visible: refUpdateDebouncer.current.visible,
                rotation: refUpdateDebouncer.current.rotation,
                position: refUpdateDebouncer.current.position,
            };
        },
        setAllowWriting(canWrite) {
            setCanHandle(canWrite);
            setTimeout(() => {
                updatePositions();
            }, 100);
        },

        updateState(data) {
            if (data) {
                setVisible(data.visible);
                refUpdateDebouncer.current.visible = data.visible;
                if (data.position) {
                    setPosition(data.position);
                    refUpdateDebouncer.current.position = data.position;
                }
                if (data.rotation) {
                    setRotation(data.rotation);
                    refUpdateDebouncer.current.rotation = data.rotation;
                }
            }
        },
    }));

    const updatePositions = (shouldCallProps = false) => {
        if (props.updateState) {
            let state = {
                visible: refUpdateDebouncer.current.visible,
                rotation: refUpdateDebouncer.current.rotation,
                position: refUpdateDebouncer.current.position,
            };
            if (shouldCallProps) {
                props.updateState(state);
            }
        }
    };

    window.moveProtractor = (event, data) => {
        let pos = {
            x: data.x + refUpdateDebouncer.current.moveX,
            y: data.y + refUpdateDebouncer.current.moveY,
        };
        if (pos.x === position.x && pos.y === position.y) return;
        if (!refUpdateDebouncer.current.debounceFunc) {
            refUpdateDebouncer.current.debounceFunc = setTimeout(() => {
                if (refUpdateDebouncer?.current?.position) {
                    setPosition(
                        drawUtils.scalePoint(
                            startPoint,
                            refUpdateDebouncer.current.position.x,
                            refUpdateDebouncer.current.position.y
                        )
                    );
                }
                refUpdateDebouncer.current.debounceFunc = null;
            }, 30);
        } else {
            refUpdateDebouncer.current.position = pos;
        }
    };

    window.stopProtractor = (event, data) => {
        let position = {
            x: data.x + refUpdateDebouncer.current.moveX,
            y: data.y + refUpdateDebouncer.current.moveY,
        };

        if (refUpdateDebouncer.current.debounceFunc) {
            clearTimeout(refUpdateDebouncer.current.debounceFunc);
            refUpdateDebouncer.current.debounceFunc = null;
        }
        let pos = drawUtils.scalePoint(startPoint, position.x, position.y);
        setPosition(pos);
        refUpdateDebouncer.current.position = pos;
        if (props.updateState) {
            let state = {
                visible: refUpdateDebouncer.current.visible,
                rotation: refUpdateDebouncer.current.rotation,
                position: refUpdateDebouncer.current.position,
            };
            props.updateState(state);
        }
    };

    window.rotateProtractor = (event, data) => {
        if (!refUpdateDebouncer.current.debounceFunc) {
            refUpdateDebouncer.current.debounceFunc = setTimeout(() => {
                setRotation(refUpdateDebouncer.current.rotation);
                refUpdateDebouncer.current.debounceFunc = null;
            }, 30);
        } else {
            let el = document.getElementById('protractor-mid-point')?.getBoundingClientRect();
            let rect = drawUtils.getCurrentCanvasRect();

            let x1, x2, y1, y2;
            x1 = data.x + rect.x + refUpdateDebouncer.current.moveX;
            x2 = el.x;
            y1 = data.y + rect.y + refUpdateDebouncer.current.moveY;
            y2 = el.y;
            refUpdateDebouncer.current.rotation = (Math.atan2(y2 - y1, x2 - x1) * 180) / Math.PI + 180;
        }
    };

    window.stopProtractorRotate = (event, data) => {
        if (refUpdateDebouncer.current.debounceFunc) {
            clearTimeout(refUpdateDebouncer.current.debounceFunc);
            refUpdateDebouncer.current.debounceFunc = null;
        }
        let el = document.getElementById('protractor-mid-point')?.getBoundingClientRect();
        let rect = drawUtils.getCurrentCanvasRect();

        let x1, x2, y1, y2;
        x1 = data.x + rect.x + refUpdateDebouncer.current.moveX;
        x2 = el.x;
        y1 = data.y + rect.y + refUpdateDebouncer.current.moveY;
        y2 = el.y;
        refUpdateDebouncer.current.rotation = (Math.atan2(y2 - y1, x2 - x1) * 180) / Math.PI + 180;

        setRotation(refUpdateDebouncer.current.rotation);
        if (props.updateState) {
            let state = {
                visible: refUpdateDebouncer.current.visible,
                rotation: refUpdateDebouncer.current.rotation,
                position: refUpdateDebouncer.current.position,
            };
            props.updateState(state);
        }

        updatePositions();
    };

    window.handleStartMoveProtractor = (event, data) => {
        let el = document.getElementById('protractor-unrolled-start-point')?.getBoundingClientRect();
        let rect = drawUtils.getCurrentCanvasRect();
        let w = 0;
        let h = 0;
        if (el && rect) {
            let xx = data.x + rect.left;
            let yy = data.y + rect.top;
            w = el.x - xx;
            h = el.y - yy;
        }
        refUpdateDebouncer.current.moveX = w;
        refUpdateDebouncer.current.moveY = h;
    };

    window.handleStartRotateProtractor = (event, data) => {
        let el = document.getElementById('protractor-end-point')?.getBoundingClientRect();
        let rect = drawUtils.getCurrentCanvasRect();
        let w = 0;
        let h = 0;
        if (el && rect) {
            let xx = data.x + rect.left;
            let yy = data.y + rect.top;
            w = el.x - xx;
            h = el.y - yy;
        }
        refUpdateDebouncer.current.moveX = w;
        refUpdateDebouncer.current.moveY = h;
    };

    window.hideProtractor = () => {
        setVisible(false);
        refUpdateDebouncer.current.visible = false;
        if (props.updateState) {
            let state = {
                visible: refUpdateDebouncer.current.visible,
                rotation: refUpdateDebouncer.current.rotation,
                position: refUpdateDebouncer.current.position,
            };
            props.updateState(state);
        }
    };

    let t = 'rotate(' + rotation + 'deg)';
    let t1 = 'rotate(' + 360 - rotation + 'deg)';
    if (!position) {
        return null;
    }

    let rswhWH = drawUtils.reverseScalePoint(
        { x: 0, y: 0 },
        drawUtils.CANVAS_ACTUAL_WIDTH / 3,
        drawUtils.CANVAS_ACTUAL_HEIGHT / 3
    );

    if (!rswhWH) return null;

    // if (!reverseScaledWHPoints) {
    //     return;
    // }

    let reverseScaledPoint = drawUtils.reverseScalePoint(startPoint, position.x, position.y);
    if (!reverseScaledPoint) {
        return null;
    }
    let px = reverseScaledPoint.x;
    let py = reverseScaledPoint.y;

    return (
        <div>
            <div
                id="protractor-unrolled-start-point"
                style={{
                    transform: t1,
                    width: rswhWH.x + 'px',
                    height: 'auto',
                    position: 'absolute',
                    left: px + 'px',
                    top: py + 'px',
                    transformOrigin: '50% 100%',
                    display: visible ? 'block' : 'none',
                }}
            />
            <div
                style={{
                    transform: t,
                    width: rswhWH.x + 'px',
                    height: 'auto',
                    position: 'absolute',
                    left: px + 'px',
                    top: py + 'px',
                    transformOrigin: '50% 100%',
                    display: visible ? 'table' : 'none',
                    borderRadius: '50% 50% 0 0/100% 100% 0 0',
                    backgroundImage: 'linear-gradient(#ffff0000, #11474B33)',
                }}
            >
                <img src={'/ProtractorDetail1.svg'} style={{ display: 'table-row' }} alt={'Protractor'} />

                <div
                    id="protractor-start-point"
                    style={{
                        position: 'absolute',
                        left: '0px',
                        top: '0px',
                    }}
                />
                <div
                    id="protractor-end-point"
                    style={{
                        position: 'absolute',
                        right: '0px',
                        bottom: '0px',
                    }}
                />
                <div
                    id="protractor-mid-point"
                    style={{
                        position: 'absolute',
                        left: '50%',
                        bottom: '0px',
                    }}
                />
                <RotateIconNew
                    className={classes.rotateButton}
                    id="protractor-rotate-icon-position"
                    style={{ visibility: canHandle ? 'visible' : 'hidden' }}
                />
                <DeleteForever
                    className={classes.deleteButton}
                    id="protractor-delete-icon-position"
                    onClick={() => {
                        setVisible(false);
                    }}
                    onTouchStart={() => {
                        setVisible(false);
                    }}
                    style={{ visibility: canHandle ? 'visible' : 'hidden' }}
                />
                <MoveIcon
                    className={classes.moveButton}
                    id="protractor-move-icon-position"
                    style={{ visibility: canHandle ? 'visible' : 'hidden' }}
                />
            </div>
        </div>
    );
});

export default ProtractorComponent;
