import {
    START_LESSON,
    JOIN_LESSON,
    END_LESSON,
    USER_JOIN,
    USER_LEAVE,
    USER_TOGGLE_DRAW,
    LESSON_INFO,
    RAISE_HAND,
    USER_RAISE_HAND,
    MUTE,
    USER_MUTE,
    CREATE_LESSON_PAGE,
    SWITCH_LESSON_PAGE,
    LOAD_LESSON_PAGES,
    ALLOW_DRAWING,
    CHANGE_CALL_STATE,
    UPDATE_SOCKET_ID,
    SWITCH_BOARD_TYPE,
    TOGGLE_VIDEO,
    TOGGLE_USERS_VIDEO,
    OFFLINE_USERS,
    UPDATE_LESSON_OPEN_JOIN_REQUESTS,
    UPDATE_LATEX_TEXT,
    USER_CAN_DRAW,
} from '../actionTypes';
import { CALL_STATE, BOARD_TYPE } from '../../constants';

const initialState = {
    isStarted: false,
    isAdmin: false,
    classId: null,
    members: [],
    lessonInfo: null,
    raisedHand: false,
    isMuted: false,
    isVideoEnabled: true,
    isUsersVidEnabled: 1,
    callState: CALL_STATE.CALL_DISABLED,
    lessonPages: [],
    currentLessonPage: 0,
    allowDrawing: false,
    mySocketId: null,
    boardType: BOARD_TYPE.SINGLE_BOARD,
    openJoinRequests: [],
    dialogEditLatex: null,
};

const lessonState = function (state = initialState, action) {
    switch (action.type) {
        case START_LESSON: {
            return {
                ...state,
                isStarted: true,
                isAdmin: true,
                classId: action.payload.classId,
                isUsersVidEnabled: 1,
                isVideoEnabled: true,
                isMuted: false,
                members: action.payload.members ? action.payload.members : [],
            };
        }
        case JOIN_LESSON: {
            return {
                ...state,
                isStarted: true,
                isAdmin: false,
                classId: action.payload,
                members: action.payload.members ? action.payload.members : [],
            };
        }
        case END_LESSON: {
            return {
                ...state,
                isStarted: false,
                isAdmin: false,
                classId: null,
                members: [],
            };
        }
        case USER_LEAVE: {
            let newMembers = [];
            for (let i = 0; i < state.members.length; i++) {
                if (state.members[i].email !== action.payload) {
                    newMembers.push(state.members[i]);
                } else {
                    newMembers.push(Object.assign({}, state.members[i], { online: false }));
                }
            }
            return {
                ...state,
                members: newMembers,
            };
        }
        case USER_TOGGLE_DRAW: {
            let newMembers = [];
            for (let i = 0; i < state.members.length; i++) {
                if (state.members[i].email !== action.payload) {
                    newMembers.push(state.members[i]);
                } else {
                    state.members[i].enableDraw = !state.members[i].enableDraw;
                    newMembers.push(state.members[i]);
                }
            }
            return {
                ...state,
                members: newMembers,
            };
        }
        case USER_CAN_DRAW: {
            let newMembers = [];
            for (let i = 0; i < state.members.length; i++) {
                if (state.members[i].email !== action.payload.email) {
                    newMembers.push(state.members[i]);
                } else {
                    state.members[i].enableDraw = action.payload.canDraw;
                    newMembers.push(state.members[i]);
                }
            }
            return {
                ...state,
                members: newMembers,
            };
        }
        case USER_RAISE_HAND: {
            let newMembers = [];
            for (let i = 0; i < state.members.length; i++) {
                if (state.members[i].email !== action.payload.email) {
                    newMembers.push(state.members[i]);
                } else {
                    state.members[i].raisedHand = action.payload.raisedHand;
                    newMembers.push(state.members[i]);
                }
            }
            return {
                ...state,
                members: newMembers,
            };
        }
        case USER_MUTE: {
            let newMembers = [];
            for (let i = 0; i < state.members.length; i++) {
                if (state.members[i].email !== action.payload.email) {
                    newMembers.push(state.members[i]);
                } else {
                    state.members[i].isMuted = action.payload.isMuted;
                    newMembers.push(state.members[i]);
                }
            }
            return {
                ...state,
                members: newMembers,
            };
        }

        case USER_JOIN: {
            let newMembers = [...state.members];
            let replaced = false;
            for (let i = 0; i < newMembers.length; i++) {
                if (newMembers[i].email === action.payload.email) {
                    newMembers[i] = action.payload;
                    replaced = true;
                    break;
                }
            }
            if (!replaced) {
                newMembers.push(Object.assign({}, action.payload));
            }
            // console.log('==== new members', newMembers);

            return {
                ...state,
                members: newMembers,
            };
        }

        case OFFLINE_USERS: {
            let newMembers = [...state.members];
            action.payload.forEach((el) => {
                let found = false;
                for (let i = 0; i < newMembers.length; i++) {
                    if (newMembers[i].email === el.email) {
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    newMembers.push(Object.assign({}, el, { online: false }));
                }
            });
            return {
                ...state,
                members: newMembers,
            };
        }
        case LESSON_INFO: {
            return {
                ...state,
                lessonInfo: action.payload,
            };
        }
        case RAISE_HAND: {
            return {
                ...state,
                raisedHand: !state.raisedHand,
            };
        }
        case MUTE: {
            if (action.payload !== undefined && action.payload !== null) {
                return {
                    ...state,
                    isMuted: action.payload,
                };
            } else {
                return {
                    ...state,
                    isMuted: !state.isMuted,
                };
            }
        }
        case TOGGLE_VIDEO: {
            if (action.payload !== undefined && action.payload !== null) {
                return {
                    ...state,
                    isVideoEnabled: action.payload,
                };
            } else {
                return {
                    ...state,
                    isVideoEnabled: !state.isVideoEnabled,
                };
            }
        }
        case TOGGLE_USERS_VIDEO: {
            if (action.payload !== undefined && action.payload !== null) {
                return {
                    ...state,
                    isUsersVidEnabled: action.payload,
                };
            } else {
                return {
                    ...state,
                    isUsersVidEnabled: 0,
                };
            }
        }

        case CHANGE_CALL_STATE: {
            return {
                ...state,
                callState: action.payload,
            };
        }
        case ALLOW_DRAWING: {
            return {
                ...state,
                allowDrawing: action.payload,
            };
        }
        case CREATE_LESSON_PAGE: {
            let newLessonPages = [...state.lessonPages];
            newLessonPages.push(action.payload);
            return {
                ...state,
                lessonPages: newLessonPages,
                currentLessonPage: newLessonPages.length - 1,
            };
        }
        case LOAD_LESSON_PAGES: {
            let newLessonPages = [...action.payload];
            return {
                ...state,
                lessonPages: newLessonPages,
                currentLessonPage: newLessonPages.length - 1,
            };
        }
        case SWITCH_LESSON_PAGE: {
            return {
                ...state,
                currentLessonPage: action.payload,
            };
        }
        case UPDATE_SOCKET_ID: {
            return {
                ...state,
                mySocketId: action.payload,
            };
        }
        case SWITCH_BOARD_TYPE: {
            return {
                ...state,
                boardType: action.payload,
            };
        }
        case UPDATE_LESSON_OPEN_JOIN_REQUESTS: {
            return {
                ...state,
                openJoinRequests: action.payload,
            };
        }
        case UPDATE_LATEX_TEXT: {
            return {
                ...state,
                dialogEditLatex: action.payload,
            };
        }
        default:
            return state;
    }
};

export default lessonState;
