import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { COLORS2 } from '../../constants';

const useStyles = makeStyles(() => ({
    buttonGroup: {
        backgroundColor: COLORS2.darkGreen,
        borderRadius: '4.75rem',
        padding: '0.25rem',
    },
}));

export const CustomButtonGroupMonthly = ({ billAnnual, handleChange}) => {

    const classes = useStyles();

    return(
        <ToggleButtonGroup className={classes.buttonGroup} onChange={handleChange}>
            <ToggleButton
                style={{
                    color: billAnnual ? COLORS2.virtualBackground : COLORS2.darkGreen,
                    backgroundColor: billAnnual ? COLORS2.darkGreen : COLORS2.virtualBackground,
                    width: '6.375rem',
                    height: '2.5rem',
                    borderRadius: '4.75rem',
                    border: 'none',
                    marginRight: '0.1rem',
                }}
                checked={billAnnual}
                value="monthly"
            >
                <FormattedMessage id="subscriptions.subscription.monthly" />
            </ToggleButton>
            <ToggleButton
                style={{
                    color: billAnnual ? COLORS2.darkGreen : COLORS2.virtualBackground,
                    backgroundColor: billAnnual ? COLORS2.virtualBackground : COLORS2.darkGreen,
                    width: '6.375rem',
                    height: '2.5rem',
                    borderRadius: '4.75rem',
                    border: 'none',
                }}
                checked={billAnnual}
                value="annual"
            >
                <FormattedMessage id="subscriptions.subscription.yearly" />
            </ToggleButton>
        </ToggleButtonGroup>
    );
};