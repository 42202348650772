import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function WandIcon(props) {
    return (
        <SvgIcon {...props} style={{ verticalAlign: 'bottom' }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
                <path d="M58.21,51.14l-36.4-36.4a5,5,0,0,0-7.07,7.07l36.4,36.4a5,5,0,0,0,7.07-7.07ZM50.8,46.56,46.56,50.8,18.27,22.52l4.24-4.25ZM16.15,16.15a3,3,0,0,1,4.24,0l.71.71L16.86,21.1l-.71-.71A3,3,0,0,1,16.15,16.15ZM56.79,56.79a3,3,0,0,1-4.24,0L48,52.21,52.21,48l4.58,4.58a3,3,0,0,1,0,4.24Zm-44.43-43L7.67,9.08,9.08,7.67l4.7,4.69Zm5.14-1.94h-2V4.18h2Zm-6.3,7.86,1.42,1.41L8.37,25.34,7,23.93Zm9.9-7.07L19.69,11.2,23.93,7l1.41,1.41ZM11.62,17.5H4.18v-2h7.44Z" />
            </svg>
        </SvgIcon>
    );
}
