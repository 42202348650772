import { Link } from 'react-router-dom';
import { SPECIAL_QUOTA_LINK } from '../../common/urlLinks';
import Button from '@material-ui/core/Button';
import { makeStyles, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { transitionToTop } from '../../common/utils';

const useStyles = makeStyles(() => ({
    writeUs: {
        width: '23.188rem',
        height: '4.5rem',
    },
    buttonText: {
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '1rem',
        lineHeight: '1.188rem',
        letterSpacing: '0.16em',
        textTransform: 'uppercase',
        color: '#FFFFFF',
    },
}));

export default function RequestOfferButton() {
    const classes = useStyles();
    return (
        <Link to={SPECIAL_QUOTA_LINK}>
            <Button className={classes.writeUs} onClick={transitionToTop} color="primary" variant="contained">
                <Typography className={classes.buttonText}>
                    <FormattedMessage id="specialQuota.buttonText" />
                </Typography>
            </Button>
        </Link>
    );
}
