import React from 'react';
import { Container } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import ForTutoringDesktop from './ForTutoringDesktop';
import ForTutoringTablet from './ForTutoringTablet';
import ForTutoringMobile from './ForTutoringMobile';

const ForTutoring = ({ open, handleOpen, handleLogin, language }) => {

    return (
        <Container maxWidth="lg">
            <Hidden only={['xs', 'sm']}>
                <ForTutoringDesktop handleOpen={handleOpen} open={open} handleLogin={handleLogin} language={language}/>
            </Hidden>
            <Hidden only={['xs', 'md', 'lg', 'xl']}>
                <ForTutoringTablet handleOpen={handleOpen} language={language}/>
            </Hidden>
            <Hidden only={['sm', 'md', 'lg', 'xl']}>
                <ForTutoringMobile handleOpen={handleOpen} open={open} handleLogin={handleLogin} language={language}/>
            </Hidden>
        </Container>
    );
};

export default ForTutoring;
