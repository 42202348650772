import {
    Button,
    Checkbox,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { FormattedMessage, injectIntl } from 'react-intl';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import store from '../../redux/store';
import { showConsent, updateUserConsent } from '../../redux/actions';
import DialogActions from '@material-ui/core/DialogActions';
import userApi from '../../Api/userApi';
import { termsHTML } from '../../pages/Compliancy/terms-20220504';
import ArrowLeftIcon from '../icons/ArrowLeftIcon';
import { policyHTML } from '../../pages/Compliancy/policy-20220402';
import { COLORS2, POLICY_VERSION, TERMS_VERSION } from '../../constants';

const ConsentDialog = (props) => {
    const [termsAndConditions, setTermsAndConditions] = useState(false);
    const [marketing, setMarketing] = useState(false);
    const [specialOffers, setSpecialOffers] = useState(false);
    const [seeTerms, setSeeTerms] = useState(false);
    const [seePolicy, setSeePolicy] = useState(false);

    const handleChangeTerms = (event) => {
        setTermsAndConditions(event.target.checked);
    };

    const handleChangeMarketing = (event) => {
        setMarketing(event.target.checked);
    };

    const handleChangeOffers = (event) => {
        setSpecialOffers(event.target.checked);
    };

    const handleClose = () => {
        if (termsAndConditions === true) store.dispatch(showConsent(false));
    };

    const handleSeeTerms = () => {
        setSeeTerms(true);
    };

    const handleSeePolicy = () => {
        setSeePolicy(true);
    };

    const handleBack = () => {
        setSeeTerms(false);
        setSeePolicy(false);
    };

    const updateConsent = () => {
        userApi
            .updateConsent({
                acceptedTermsAndConditions: termsAndConditions,
                allowMarketing: marketing,
                allowSpecialOffers: specialOffers,
                terms: JSON.stringify(TERMS_VERSION),
                policy: JSON.stringify(POLICY_VERSION),
            })
            .then((res) => {
                store.dispatch(updateUserConsent(res.data));
                handleClose();
            })
            .catch((err) => {
                console.error('unable to update consent', err);
            });
    };

    return (
        <Dialog
            open={props.profile.openConsent}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{ borderRadius: '2rem' }}
        >
            {!seeTerms && !seePolicy ? (
                <>
                    <DialogTitle id="alert-dialog-title">
                        <FormattedMessage id="consent.dialog.title" />
                    </DialogTitle>
                    <DialogContent>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox />}
                                label={<FormattedMessage id="consent.dialog.checkbox.allowMarketing" />}
                                onChange={handleChangeMarketing}
                            />
                            <FormControlLabel
                                control={<Checkbox />}
                                label={<FormattedMessage id="consent.dialog.checkbox.allowSpecialOffers" />}
                                onChange={handleChangeOffers}
                            />
                            <FormControl required={true}>
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label={
                                        <>
                                            <span>
                                                <FormattedMessage id="consent.dialog.checkbox.termsAndConditions1" />
                                            </span>
                                            <span onClick={handleSeeTerms} style={{ color: COLORS2.virtualGreen }}>
                                                <FormattedMessage id="consent.dialog.checkbox.termsAndConditions2" />
                                            </span>
                                            <span>
                                                <FormattedMessage id="consent.dialog.checkbox.termsAndConditions3" />
                                            </span>
                                            <span onClick={handleSeePolicy} style={{ color: COLORS2.virtualGreen }}>
                                                <FormattedMessage id="consent.dialog.checkbox.termsAndConditions4" />
                                            </span>
                                        </>
                                    }
                                    onChange={handleChangeTerms}
                                />
                            </FormControl>
                        </FormGroup>
                        <FormHelperText>
                            <FormattedMessage id="consent.dialog.helperText" />
                        </FormHelperText>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'center', paddingBottom: '1rem' }}>
                        {termsAndConditions ? (
                            <Button color="primary" variant={'contained'} onClick={updateConsent}>
                                <FormattedMessage id="consent.dialog.button.confirm" />
                            </Button>
                        ) : (
                            <Button
                                color="primary"
                                variant={'contained'}
                                disabled={!termsAndConditions}
                                onClick={updateConsent}
                            >
                                <FormattedMessage id="consent.dialog.button.confirm" />
                            </Button>
                        )}
                    </DialogActions>
                </>
            ) : seePolicy ? (
                <div style={{ padding: '2rem' }}>
                    <ArrowLeftIcon onClick={handleBack} />
                    <div dangerouslySetInnerHTML={{ __html: policyHTML }} />
                </div>
            ) : (
                <div style={{ padding: '2rem' }}>
                    <ArrowLeftIcon onClick={handleBack} />
                    <div dangerouslySetInnerHTML={{ __html: termsHTML }} />
                </div>
            )}
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    const { profile } = state;
    return { profile };
};

export default injectIntl(connect(mapStateToProps)(ConsentDialog));
