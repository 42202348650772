import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Drawer from '../MyComponents/Drawer/Drawer';
import LessonDrawer from '../MyComponents/Drawer/LessonDrawer';
import ShareDialog from '../MyComponents/Drawer/ShareDialog';
import JoinRequestDialog from '../MyComponents/Drawer/JoinRequestDialog';
import LessonPagePickerDialog from '../MyComponents/Drawer/LessonPagePickerDialog';
import RequestFullScreen from '../MyComponents/Drawer/RequestFullScren';
import MultiBoardDialog from '../MyComponents/Drawer/MultiBoardDialog';
import JoinCallDialog from '../MyComponents/Drawer/JoinCallDialog';
import StaticPageNavigatorDialog from '../MyComponents/Drawer/StaticPageNavigatorDialog';
import UpgradeSubscriptionDialog from '../MyComponents/Drawer/UpgradeSubscriptionDialog';
import Footer from '../MyComponents/Footer/Footer';
import { COLORS2 } from '../constants';
import ConsentDialog from '../MyComponents/ConsentDialog/ConsentDialog';

const wrapperStyle = {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    overflow: 'hidden',
};

export default function AuthenticatedApp({ language, updateLanguage }) {
    return (
        <>
            <Switch>
                <Route path="/lesson/:classId">
                    <div style={wrapperStyle}>
                        <LessonDrawer updateLanguage={updateLanguage} />
                    </div>
                </Route>
                <Route path="*">
                    <div style={{ ...wrapperStyle, backgroundColor: COLORS2.virtualBackground }}>
                        <Drawer updateLanguage={updateLanguage} language={language} />
                        <Footer />
                    </div>
                </Route>
            </Switch>
            <JoinRequestDialog />
            <ShareDialog />
            <UpgradeSubscriptionDialog />
            <JoinCallDialog />
            <MultiBoardDialog />
            {/* <NewFeatureDialog /> */}
            <LessonPagePickerDialog />
            <RequestFullScreen />
            <StaticPageNavigatorDialog />
            <ConsentDialog />
        </>
    );
}
