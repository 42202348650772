import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage, injectIntl } from 'react-intl';
import classApi from '../../Api/classApi';
import { useHistory } from 'react-router-dom';
import { Checkbox, Container, Grid, Typography } from '@material-ui/core';
import GenericState from '../../MyComponents/GenericLoadingErrorState/GenericState';
import { GA_EVENT_TREE } from '../../gaConstants';
import { connect } from 'react-redux';
import ResponsiveTable from '../../MyComponents/ResponsiveTable/ResponsiveTable';
import GenericDeletionConfirmation from '../../MyComponents/GenericDeletionConfirmation/GenericDeletionConfirmation';
import CreateClassAction from './CreateClassAction';
import { determineDefaultLanguage } from '../../common/utils';
import { buildDesktopMeta } from './ManageClassDesktopMeta';
import { buildMobileMeta } from './ManageClassMobileMeta';
import { buildTabletMeta } from './ManageClassTabletMeta';
import { FindMoreAboutClasses } from './FindMoreAboutClasses';
import Hidden from '@material-ui/core/Hidden';
import { COLORS2 } from '../../constants';
import loadClassesHook from '../../MyComponents/Hooks/loadClassesHook';

const useStyles = makeStyles((theme) => ({
    table: {
        // minWidth: 650,
    },
    container: { width: '100vw' },
    button: {
        position: 'absolute',
        width: '19.5rem',
        height: '4.5rem',
        left: '67rem',
        top: '30.375rem',
        marginLeft: 'auto',
        background: COLORS2.virtualGreen,
        boxShadow: '0rem 0rem 0rem 0.5rem rgba(8, 170, 180, 0.08)',
        borderRadius: '1rem',
    },
    buttonAlone: { marginLeft: '0rem' },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'absolute',
        width: 450,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    disableRow: {
        background: 'rgba(210, 104, 40, 0.5)',
    },
    enableRow: {},
    centerMobileCell: { display: 'flex', alignItems: 'center' },
    redesignedTableButton: {
        width: 'fit-content',
        height: '3rem',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '1rem 1rem 0.9rem',
        gap: '0.7rem',
    },
    withoutHover: {
        '&:hover': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
        },
    },
    rowActionTextMine: {
        width: 'fit-content',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '0.875rem',
        lineHeight: '1.063rem',
        textAlign: 'center',
        letterSpacing: '0.1em',
        textTransform: 'uppercase',
        color: COLORS2.darkGreen,
        flex: 'none',
        order: '0',
        flexGrow: '0',
    },
    rowActionTextNotMine: {
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '0.875rem',
        lineHeight: '1.063rem',
        textAlign: 'center',
        letterSpacing: '0.1em',
        textTransform: 'uppercase',
        color: COLORS2.virtualGreen,
        flex: 'none',
        order: '0',
        flexGrow: '0',
    },
    findMoreText: {
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '1rem',
        lineHeight: '1rem',
        letterSpacing: '-0.01em',
        textTransform: 'none',
    },
    activeGroupsText: {
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '1.5rem',
        letterSpacing: '-0.01em',
        textTransform: 'none',
        paddingRight: '1rem',
        margin: '0rem',
        color: COLORS2.darkGreen,
    },
    classGeneric: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '3rem 0rem',
        width: '100%',
    },
    checkBoxText: {
        color: COLORS2.darkCyan,
        fontWeight: 400,
    },
}));

const ManageClass = ({ allowedGroups, profile }) => {
    let history = useHistory();
    const classes = useStyles();
    const { dataAll, isLoading, isError, loadClasses, setIsLoading } = loadClassesHook(allowedGroups);
    // const [data, setData] = React.useState([]);
    // const [otherClasses, setOtherClasses] = React.useState([]);
    const [modalItem, setModalItem] = React.useState(null);
    // const [canCreate, setCanCreate] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [checked, setChecked] = React.useState(false);

    // useEffect(() => {
    //     if (subscriptions && profile && allowedGroups) {
    //         canIncreaseLimitedData(
    //             subscriptions,
    //             profile.subscription,
    //             CAPABILITIES.GROUP_COUNT,
    //             Promise.resolve(data.length)
    //         ).then((res) => {
    //             setCanCreate(res);
    //         });
    //     } else {
    //         setCanCreate({ value: true });
    //     }
    // }, [subscriptions, profile, data]);

    // const sortDateFunction = (a, b) => {
    //     let dateA = a.lastaccess ? new Date(a.lastaccess).getTime() : new Date(a.updatedAt).getTime();
    //     let dateB = b.lastaccess ? new Date(b.lastaccess).getTime() : new Date(b.updatedAt).getTime();
    //     return dateA > dateB ? -1 : 1;
    // };

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const hideModal = () => {
        setModalItem(null);
    };

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    // useEffect(() => {
    //     loadClasses();
    // }, []);

    // const loadClasses = () => {
    //     setIsLoading(true);
    //     userApi
    //         .getAllowedGroups()
    //         .then((response) => {
    //             store.dispatch(updateAllowedGroups(response.data.groupIds));
    //         })
    //         .catch((err) => {
    //             console.error('unable to get allowed groups', err);
    //         });
    //     classApi
    //         .getMyClasses()
    //         .then((res) => {
    //             setData(res.data);
    //             window.logAppActivity(GA_EVENT_TREE.manageGroups.actions.load, res.data.length);
    //             setIsLoading(false);
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //             setIsLoading(false);
    //             setIsError(true);
    //         });
    //     classApi
    //         .getClasses()
    //         .then((res) => {
    //             res.data.forEach((el) => {
    //                 el.enabled = true;
    //             });
    //             setOtherClasses(res.data);
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // };

    let lessonStart = function (classId) {
        window.logAppActivity(GA_EVENT_TREE.manageGroups.actions.start);
        history.push('/lesson/' + classId);
    };

    let deleteClass = function () {
        window.logAppActivity(GA_EVENT_TREE.manageGroups.actions.delete);
        setIsLoading(true);
        hideModal();
        classApi
            .deleteClass(modalItem.id)
            .then(() => {
                loadClasses();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    // const createGroup = () => {
    //     window.logAppActivity(GA_EVENT_TREE.manageGroups.actions.create);
    //     if (canCreate?.value) {
    //         history.push('/createGroup');
    //     } else {
    //         store.dispatch(
    //             upgradeSubscriptionDialog({
    //                 key: 'subscription.upsell.groups.exceeded',
    //                 subscriptions: canCreate.availableSubscriptions,
    //             })
    //         );
    //     }
    // };

    // data.forEach((el) => {
    //     el.enabled = !allowedGroups || (allowedGroups && allowedGroups.includes(el.id));
    // });
    //
    // data.forEach((el) => {
    //     el.isMine = true;
    // });
    //
    // otherClasses.forEach((el) => {
    //     el.isMine = false;
    // });
    //
    // dataAll = dataAll.concat(data, otherClasses).sort(sortDateFunction);
    //
    // if (checked === true) {
    //     dataAll = data.sort(sortDateFunction);
    // }
    let myDataAll = dataAll;
    if (checked === true) {
        myDataAll = dataAll.filter((el) => el.isMine);
    }

    let localesOptions = 'ro-RO';
    let currentLanguage = determineDefaultLanguage();
    if (currentLanguage === 'en') {
        localesOptions = 'en-EN';
    } else if (currentLanguage === 'fr') {
        localesOptions = 'fr-FR';
    } else if (currentLanguage === 'ro') {
        localesOptions = 'ro-RO';
    }

    return (
        <>
            <CssBaseline />
            <Container>
                <div style={{ paddingTop: '2rem' }} />
                {myDataAll.length > 0 ? (
                    <Grid container>
                        <CreateClassAction />
                        <div className={classes.classGeneric}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                <Typography variant="h5" className={classes.activeGroupsText}>
                                    <Hidden only={['xs', 'sm']}>
                                        <FormattedMessage id="redesign.menu.manageClass" />
                                    </Hidden>
                                    <Hidden only={['md','lg','xl']}>
                                        <FormattedMessage id="page.home.feature.groups.tag" />
                                    </Hidden>
                                </Typography>
                                <Hidden only={['xs']}>
                                    <FindMoreAboutClasses isMobile={false}/>
                                </Hidden>
                                <Hidden only={['sm','md','lg','xl']}>
                                    <FindMoreAboutClasses isMobile={true}/>
                                </Hidden>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                <Hidden only={['xs']}>
                                    <Checkbox color="default" checked={checked} onChange={handleChange} />
                                    <Typography className={classes.checkBoxText}>
                                        <FormattedMessage id="redesign.menu.manageClass.onlyMyGroupsDesktopCheckbox" />
                                    </Typography>
                                </Hidden>
                                <Hidden only={['sm','md', 'lg', 'xl']}>
                                    <Typography className={classes.checkBoxText}>
                                        <FormattedMessage id="redesign.menu.manageClass.onlyMyGroupsMobileCheckbox" />
                                    </Typography>
                                    <Checkbox color="default" checked={checked} onChange={handleChange} />
                                </Hidden>
                            </div>
                        </div>
                    </Grid>
                ) : (
                    <Grid container>
                        <div className={classes.classGeneric}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                <Typography variant="h5" className={classes.activeGroupsText}>
                                    <FormattedMessage id="page.home.feature.groups.tag" />
                                </Typography>
                                <Hidden only={['xs', 'sm']}>
                                    <FindMoreAboutClasses />
                                </Hidden>
                            </div>
                        </div>
                        <CreateClassAction />
                    </Grid>
                )}

                {isLoading ? <GenericState isLoading={true} inline={true} /> : null}
                {isError ? <GenericState isError={true} inline={true} /> : null}

                {!isError && !isLoading && myDataAll.length > 0 ? (
                    <ResponsiveTable
                        data={myDataAll}
                        metaDesktop={buildDesktopMeta(
                            classes,
                            lessonStart,
                            profile,
                            profile.email,
                            localesOptions,
                            setModalItem
                        )}
                        metaMobile={buildMobileMeta(profile, profile.email, lessonStart, localesOptions, setModalItem)}
                        metaTablet={buildTabletMeta(profile, profile.email, lessonStart, localesOptions, setModalItem)}
                        editStyle={true}
                    />
                ) : null}

                <GenericDeletionConfirmation
                    open={!!modalItem}
                    deleteFunction={deleteClass}
                    onClose={hideModal}
                    value={modalItem ? modalItem.name : ''}
                /> 
            </Container>
        </>
    );
};

const mapStateToProps = (state) => {
    const { profile, mainAppState } = state;
    return {
        allowedGroups: profile.allowedGroups,
        profile,
        subscriptions: mainAppState.subscriptions,
    };
};
export default injectIntl(connect(mapStateToProps)(ManageClass));
