import React from 'react';
import { Container } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import ForSchoolsDesktop from './ForSchoolsDesktop';
import ForSchoolsTablet from './ForSchoolsTablet';
import ForSchoolsMobile from './ForSchoolsMobile';

const ForSchools = ({ open, handleOpen, handleLogin, language }) => {

    return (
        <Container maxWidth="lg">
            <Hidden only={['xs', 'sm']}>
                <ForSchoolsDesktop handleOpen={handleOpen} open={open} handleLogin={handleLogin} language={language}/>
            </Hidden>
            <Hidden only={['xs', 'md', 'lg', 'xl']}>
                <ForSchoolsTablet handleOpen={handleOpen} language={language}/>
            </Hidden>
            <Hidden only={['sm', 'md', 'lg', 'xl']}>
                <ForSchoolsMobile handleOpen={handleOpen} open={open} handleLogin={handleLogin} language={language}/>
            </Hidden>
        </Container>
    );
};

export default ForSchools;
