import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function BoardSet(props) {
    return (
        <SvgIcon {...props}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                zoomAndPan="magnify"
                viewBox="0 0 150 150"
                preserveAspectRatio="xMidYMid meet"
                version="1.0"
            >
                <defs>
                    <clipPath>
                        <path d="M 0 53 L 130 53 L 130 141.921875 L 0 141.921875 Z M 0 53 " clipRule="nonzero" />
                    </clipPath>
                    <clipPath>
                        <path d="M 20 12 L 149.875 12 L 149.875 121 L 20 121 Z M 20 12 " clipRule="nonzero" />
                    </clipPath>
                    <clipPath>
                        <path
                            d="M 53.847656 68 L 113.847656 68 L 113.847656 127.878906 L 53.847656 127.878906 Z M 53.847656 68 "
                            clipRule="nonzero"
                        />
                    </clipPath>
                </defs>
                <g>
                    <path
                        d="M 0.207031 136.484375 C 0.207031 138.273438 0.207031 141.652344 5.367188 141.652344 L 124.070312 141.652344 C 129.230469 141.652344 129.230469 138.273438 129.230469 136.484375 L 129.230469 53.832031 L 0.207031 53.832031 Z M 0.207031 136.484375 "
                        fillOpacity="0.2"
                        fillRule="nonzero"
                    />
                </g>
                <path
                    d="M 134.394531 22.835938 L 15.691406 22.835938 C 10.527344 22.835938 10.527344 26.214844 10.527344 28.003906 L 134.394531 28.003906 L 134.394531 131.320312 C 136.179688 131.320312 139.554688 131.320312 139.554688 126.152344 L 139.554688 28.003906 C 139.554688 22.835938 139.554688 22.835938 134.394531 22.835938 Z M 134.394531 22.835938 "
                    fillOpacity="1"
                    fillRule="nonzero"
                />
                <g>
                    <path
                        d="M 144.714844 12.503906 L 26.011719 12.503906 C 20.851562 12.503906 20.851562 15.882812 20.851562 17.671875 L 144.714844 17.671875 L 144.714844 120.988281 C 146.5 120.988281 149.875 120.988281 149.875 115.820312 L 149.875 17.671875 C 149.875 12.503906 149.875 12.503906 144.714844 12.503906 Z M 144.714844 12.503906 "
                        fillOpacity="1"
                        fillRule="nonzero"
                    />
                </g>
                <path
                    d="M 124.070312 33.167969 L 5.367188 33.167969 C 0.207031 33.167969 0.207031 36.546875 0.207031 38.335938 L 0.207031 48.664062 L 129.230469 48.664062 L 129.230469 38.335938 C 129.230469 33.167969 129.230469 33.167969 124.070312 33.167969 Z M 16.335938 43.5 C 14.550781 43.5 13.109375 42.058594 13.109375 40.269531 C 13.109375 38.484375 14.550781 37.042969 16.335938 37.042969 C 18.121094 37.042969 19.558594 38.484375 19.558594 40.269531 C 19.558594 42.058594 18.121094 43.5 16.335938 43.5 Z M 29.238281 43.5 C 27.453125 43.5 26.011719 42.058594 26.011719 40.269531 C 26.011719 38.484375 27.453125 37.042969 29.238281 37.042969 C 31.023438 37.042969 32.460938 38.484375 32.460938 40.269531 C 32.460938 42.058594 31.023438 43.5 29.238281 43.5 Z M 42.140625 43.5 C 40.355469 43.5 38.914062 42.058594 38.914062 40.269531 C 38.914062 38.484375 40.355469 37.042969 42.140625 37.042969 C 43.925781 37.042969 45.367188 38.484375 45.367188 40.269531 C 45.367188 42.058594 43.925781 43.5 42.140625 43.5 Z M 42.140625 43.5 "
                    fillOpacity="1"
                    fillRule="nonzero"
                />
                <g>
                    <path
                        fill="#d36823"
                        d="M 53.847656 115.445312 L 53.847656 127.878906 L 66.28125 127.878906 L 102.960938 91.199219 L 90.527344 78.765625 Z M 112.539062 81.617188 C 113.832031 80.324219 113.832031 78.21875 112.539062 76.925781 L 104.800781 69.1875 C 103.507812 67.894531 101.402344 67.894531 100.109375 69.1875 L 94.042969 75.253906 L 106.472656 87.683594 Z M 112.539062 81.617188 "
                        fillOpacity="1"
                        fillRule="nonzero"
                    />
                </g>
            </svg>
        </SvgIcon>
    );
}
