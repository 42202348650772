import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { COLORS2 } from '../../constants';

const ExpansionPanel = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(Accordion);

const ExpansionPanelSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        paddingLeft: '16px',
        paddingRight: '16px',
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(AccordionSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(AccordionDetails);

const useStyles = makeStyles(() => ({
    customTitle: {
        fontSize: '0.875rem',
        color: COLORS2.darkGreen,
        textTransform: 'uppercase',
        letterSpacing: '0.1rem',
    },
    container: {
        alignItems: 'center',
        flexWrap: 'nowrap',
    },
}));

export default function CustomizedExpansionPanels(props) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(props.expanded);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return props.panels.map((panel) => {
        return (
            <ExpansionPanel
                square
                expanded={expanded === panel.name}
                onChange={handleChange(panel.name)}
                key={panel.name}
            >
                <ExpansionPanelSummary aria-controls={panel.name + '-content'} id={panel.name + '-header'}>
                    <Grid container className={classes.container}>
                        <Grid item xs={12} sm={2}>
                            {panel.icon}
                        </Grid>
                        {panel.more ? (
                            <>
                                <Grid item xs={12} sm={7}>
                                    <Typography className={classes.customTitle}>{panel.title}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    {panel.more}
                                </Grid>
                            </>
                        ) : (
                            <Grid item xs={12} sm={9}>
                                <Typography className={classes.customTitle}>{panel.title}</Typography>
                            </Grid>
                        )}

                        <Grid item xs={12} sm={1}>
                            {expanded === panel.name ? <ExpandLess /> : <ExpandMore />}
                        </Grid>
                    </Grid>
                </ExpansionPanelSummary>

                <ExpansionPanelDetails>{panel.content}</ExpansionPanelDetails>
            </ExpansionPanel>
        );
    });
}
