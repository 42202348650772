import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import SectionChapter from '../../SectionChapter';

const UpdateBoards = ({ language, refs }) => {
    let boardMenu = [
        {
            id: 'tutorial.manageLibrary.savingToLibrary.description',
            children: (
                <ul>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.manageLibrary.savingToLibrary.explanation1"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.manageLibrary.savingToLibrary.explanation2"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.manageLibrary.savingToLibrary.explanation3"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>
                </ul>
            ),
            img: [`/static/asset/img/tutorial/withText/${language.toUpperCase()}/SaveToLib.png`],
        },
    ];
    return (
        <SectionChapter
            refs={refs}
            id={'section-manageLibrary-savingToLibrary'}
            title={'tutorial.manageLibrary.savingToLibrary.title'}
            explanations={boardMenu}
            imageTop={false}
        />
    );
};

export default UpdateBoards;
