import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function GridIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 80 80" viewBox="0 0 80 80">
                <path
                    d="M72,7H8C7.4472656,7,7,7.4477539,7,8v64c0,0.5522461,0.4472656,1,1,1h64c0.5527344,0,1-0.4477539,1-1V8
	C73,7.4477539,72.5527344,7,72,7z M22.5527344,71H9V57.4472656h13.5527344V71z M22.5527344,55.4472656H9V41h13.5527344V55.4472656z
	 M22.5527344,39H9V24.5522461h13.5527344V39z M22.5527344,22.5522461H9V9h13.5527344V22.5522461z M39,71H24.5527344V57.4472656H39
	V71z M39,55.4472656H24.5527344V41H39V55.4472656z M39,39H24.5527344V24.5522461H39V39z M39,22.5522461H24.5527344V9H39V22.5522461z
	 M55.4472656,71H41V57.4472656h14.4472656V71z M55.4472656,55.4472656H41V41h14.4472656V55.4472656z M55.4472656,39H41V24.5522461
	h14.4472656V39z M55.4472656,22.5522461H41V9h14.4472656V22.5522461z M71,71H57.4472656V57.4472656H71V71z M71,55.4472656
	H57.4472656V41H71V55.4472656z M71,39H57.4472656V24.5522461H71V39z M71,22.5522461H57.4472656V9H71V22.5522461z"
                />
            </svg>
        </SvgIcon>
    );
}
