import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Divider, MenuItem, MenuList, Modal, Slide, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import VerticalEllipsisIcon from '../icons/VerticalEllipsisIcon';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import StartLessonIcon from '../icons/StartLessonIcon';
import { GA_EVENT_TREE } from '../../gaConstants';
import { useHistory } from 'react-router-dom';
import store from '../../redux/store';
import { showMultiBoardLessonState } from '../../redux/actions';
import IndividualBoardActivityIcon from '../icons/IndividualBoardActivityIcon';
import LeaveGroupIcon from '../icons/LeaveGroupIcon';
import OrangeGroupIcon from '../icons/OrangeGroupIcon';
import Avatar from '@material-ui/core/Avatar';
import { COLORS2 } from '../../constants';

const useStyles = makeStyles(() => ({
    modalStyle: {
        background: 'linear-gradient(180deg, rgba(2, 35, 37, 0) 0%, #022325 95.56%)',
        transitionTimingFunction: 'ease-out',
        //transitionDelay: '100ms',
    },
    boxModalStyle: {
        position: 'absolute',
        bottom: '0',
        background: '#FFFFFF',
        boxShadow: '1.5rem -1.5rem 3rem rgba(0, 0, 0, 0.08)',
        borderRadius: '2rem 2rem 0rem 0rem',
    },
    menuText: {
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '1rem',
        letterSpacing: '-0.01rem',
        textTransform: 'none',
        color: COLORS2.darkGreen,
    },
    withoutHover: {
        '&:hover': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
        },
    },
    alertMenuText: {
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '1rem',
        letterSpacing: '-0.01rem',
        textTransform: 'none',
        color: COLORS2.virtualRed,
    },
}));

export const MobilePopUpGuestGroups = ({ row, email, localesOptions, isMobilePopUp }) => {
    let history = useHistory();
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    let lessonStart = function (classId) {
        window.logAppActivity(GA_EVENT_TREE.manageGroups.actions.start);
        history.push('/lesson/' + classId);
    };

    let name = '';
    if (row.first_name) {
        name += row.first_name + ' ';
    }
    if (row.last_name) {
        name += row.last_name;
    }

    return (
        <>
            <Button onClick={handleOpen}>
                <VerticalEllipsisIcon
                    style={{
                        filter:
                            'invert(46%) sepia(95%) saturate(601%) hue-rotate(140deg) brightness(93%) contrast(94%)',
                    }}
                />
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                className={classes.modalStyle}
                BackdropProps={{ style: { backgroundColor: 'transparent' } }}
            >
                <Slide direction="up" in={open} timeout={{ enter: 500, exit: 400 }} easing={'ease-out'}>
                    <Box
                        className={classes.boxModalStyle}
                        style={{ width: isMobilePopUp ? '100%' : '75%', margin: isMobilePopUp ? '0rem' : '0rem 6rem' }}
                    >
                        <div style={{ display: 'flex', padding: '1.5rem', alignItems: 'center' }}>
                            <span
                                style={{
                                    boxSizing: 'border-box',
                                    width: '3rem',
                                    height: '3rem',
                                    border: '0.063rem solid #FBF0E9',
                                    borderRadius: '1rem',
                                    textAlign: 'center',
                                }}
                            >
                                <OrangeGroupIcon style={{ top: '2.25rem', left: '2.275rem', position: 'absolute' }} />
                            </span>
                            <div style={{ paddingLeft: '1rem' }}>
                                <Typography
                                    style={{
                                        fontStyle: 'normal',
                                        fontWeight: '700',
                                        fontSize: '1.125rem',
                                        letterSpacing: '-0.02rem',
                                        textTransform: 'none',
                                        color: COLORS2.darkGreen,
                                    }}
                                >
                                    {row.name}
                                </Typography>
                            </div>
                        </div>
                        <div style={{ background: 'rgba(210, 104, 40, 0.04)', width: '100%' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    padding: '2rem 2rem 0rem 5rem',
                                }}
                            >
                                <div>
                                    <Typography
                                        style={{
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            fontSize: '0.625rem',
                                            lineHeight: '1rem',
                                            letterSpacing: '0.08rem',
                                            textTransform: 'uppercase',
                                            color: COLORS2.virtualText,
                                        }}
                                    >
                                        <FormattedMessage id="redesign.generic.label.initiatedBy" />
                                    </Typography>
                                </div>
                                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                                    <Avatar url={row.avatar} style={{ width: '1rem', height: '1rem' }} />
                                    <span
                                        style={{
                                            paddingLeft: '0.375rem',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            fontSize: '0.625rem',
                                            lineHeight: '1rem',
                                            letterSpacing: '-0.01rem',
                                            textTransform: 'none',
                                            textAlign: 'right',
                                            color: '#5F646B',
                                        }}
                                    >
                                        {name}
                                    </span>
                                </div>
                            </div>
                            <Divider
                                style={{
                                    backgroundColor: 'rgba(210, 104, 40, 0.1)',
                                    height: '0.063rem',
                                    margin: '1rem 0rem 1rem 5rem',
                                }}
                            />
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    padding: '0rem 2rem 0rem 5rem',
                                }}
                            >
                                <div>
                                    <Typography
                                        style={{
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            fontSize: '0.625rem',
                                            lineHeight: '1rem',
                                            letterSpacing: '0.08rem',
                                            textTransform: 'uppercase',
                                            color: COLORS2.virtualText,
                                        }}
                                    >
                                        <FormattedMessage id="redesign.generic.label.createdAtMobile" />
                                    </Typography>
                                </div>
                                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                                    <span
                                        style={{
                                            paddingLeft: '0.375rem',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            fontSize: '0.625rem',
                                            lineHeight: '1rem',
                                            letterSpacing: '-0.01rem',
                                            textTransform: 'none',
                                            textAlign: 'right',
                                            color: '#5F646B',
                                        }}
                                    >
                                        {new Date(row.createdAt).toLocaleDateString(localesOptions, {
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric',
                                        })}
                                    </span>
                                </div>
                            </div>
                            <Divider
                                style={{
                                    backgroundColor: 'rgba(210, 104, 40, 0.1)',
                                    height: '0.063rem',
                                    margin: '1rem 0rem 1rem 5rem',
                                }}
                            />
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    padding: '0rem 2rem 2rem 5rem',
                                }}
                            >
                                <div>
                                    <Typography
                                        style={{
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            fontSize: '0.625rem',
                                            lineHeight: '1rem',
                                            letterSpacing: '0.08rem',
                                            textTransform: 'uppercase',
                                            color: COLORS2.virtualText,
                                        }}
                                    >
                                        <FormattedMessage id="redesign.generic.label.lastAccessedMobile" />
                                    </Typography>
                                </div>
                                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                                    <span
                                        style={{
                                            paddingLeft: '0.375rem',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            fontSize: '0.625rem',
                                            lineHeight: '1rem',
                                            letterSpacing: '-0.01rem',
                                            textTransform: 'none',
                                            textAlign: 'right',
                                            color: '#5F646B',
                                        }}
                                    >
                                        {new Date(row.lastaccess).toLocaleDateString(localesOptions, {
                                            month: 'long',
                                            day: 'numeric',
                                            hour: 'numeric',
                                            minute: 'numeric',
                                        })}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <MenuList autoFocusItem={open} style={{ padding: '1.5rem' }}>
                            <MenuItem
                                onClick={() => {
                                    lessonStart(row.classId);
                                }}
                            >
                                <Button startIcon={<StartLessonIcon />} className={classes.withoutHover}>
                                    <Typography className={classes.menuText}>
                                        <FormattedMessage id={'joinRequest.joinLesson'} />
                                    </Typography>
                                </Button>
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    store.dispatch(
                                        showMultiBoardLessonState({
                                            id: null,
                                            lessonId: row.classId,
                                            email: email,
                                            isOwner: false,
                                        })
                                    );
                                }}
                            >
                                <Button startIcon={<IndividualBoardActivityIcon />} className={classes.withoutHover}>
                                    <Typography className={classes.menuText}>
                                        <FormattedMessage id={'redesign.generic.label.viewActivity'} />
                                    </Typography>
                                </Button>
                            </MenuItem>
                            {/*<MenuItem>*/}
                            {/*    <Button*/}
                            {/*        startIcon={<LeaveGroupIcon />}*/}
                            {/*        className={classes.withoutHover}*/}
                            {/*    >*/}
                            {/*        <Typography className={classes.alertMenuText}>*/}
                            {/*            <FormattedMessage id={'redesign.generic.label.leaveGroup'} />*/}
                            {/*        </Typography>*/}
                            {/*    </Button>*/}
                            {/*</MenuItem>*/}
                        </MenuList>
                    </Box>
                </Slide>
            </Modal>
        </>
    );
};
