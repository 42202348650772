import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import CreateIcon from '@material-ui/icons/Create';
import LineIcon from '../../../../MyComponents/icons/LineIcon';
import RectangleIcon from '../../../../MyComponents/icons/RectangleIcon';
import EllipsisIcon from '../../../../MyComponents/icons/EllipsisIcon';
import BrushIcon from '@material-ui/icons/Brush';
import EraserIcon from '../../../../MyComponents/icons/EraserIcon';
import TitleIcon from '@material-ui/icons/Title';
import { DeleteForever } from '@material-ui/icons';
import SectionChapter from '../../SectionChapter';

const InstrumentManagement = ({ language, classes, refs }) => {
    let instruments = [
        {
            id: 'tutorial.manageLesson.tools.explanation1',
            children: (
                <ul>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.tools.line1"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                            <CreateIcon className={classes.sampleFab} />
                        </Typography>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.tools.line1.explanation"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.tools.line2"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                            <LineIcon className={classes.sampleFab} />
                        </Typography>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.tools.line2.explanation"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.tools.square"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                            <RectangleIcon className={classes.sampleFab} />
                        </Typography>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.tools.square.explanation"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.tools.elipsis"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                            <EllipsisIcon className={classes.sampleFab} />
                        </Typography>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.tools.elipsis.explanation"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.tools.brush"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                            <BrushIcon className={classes.sampleFab} />
                        </Typography>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.tools.brush.explanation"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>

                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.tools.eraser"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                            <EraserIcon className={classes.sampleFab} />
                        </Typography>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.tools.eraser.explanation"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>

                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.tools.text"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                            <TitleIcon className={classes.sampleFab} />
                        </Typography>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.tools.text.explanation"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                    li: (chunks) => (
                                        <span>
                                            {chunks}
                                            <br></br>
                                            <br></br>
                                        </span>
                                    ),
                                }}
                            />
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.tools.cleanBoard"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                            <DeleteForever className={classes.sampleFab} />
                        </Typography>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.tools.cleanBoard.explanation"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>
                </ul>
            ),
            img: [`/static/asset/img/tutorial/withText/${language.toUpperCase()}/Tools.jpg`],
        },
        {
            id: 'tutorial.tools.lineWeight',
            children: (
                <ul>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.tools.lineWeight.explanation"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>
                </ul>
            ),
            img: [
                `/static/asset/img/tutorial/withText/${language.toUpperCase()}/SizeLine.jpg`,
                `/static/asset/img/tutorial/withText/${language.toUpperCase()}/SizeEraser.jpg`,
                `/static/asset/img/tutorial/withText/${language.toUpperCase()}/SizeText.jpg`,
            ],
        },
        {
            id: 'tutorial.tools.lineColor',
            children: (
                <ul>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.tools.lineColor.explanation"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>
                </ul>
            ),
            img: [`/static/asset/img/tutorial/withText/${language.toUpperCase()}/Color.jpg`],
        },
    ];
    return (
        <SectionChapter
            refs={refs}
            id={'section-manageLesson-tools'}
            title={'tutorial.manageLesson.tools.title'}
            explanations={instruments}
            imageTop={false}
        />
    );
};

export default InstrumentManagement;
