import React from 'react';
import Divider from '@material-ui/core/Divider';
import { Container, Grid, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import Sticky from 'react-stickynode';
import styles from './styles';
import TutorialLibrary from '../../Sections/TutorialLibrary';
import GroupManagement from '../../Sections/GroupManagement';
import LessonManagement from '../../Sections/LessonManagement';
import IndividualBoardManagement from '../../Sections/IndividualBoardManagement';
import MobileManagement from '../../Sections/MobileManagement';
import TabletSummaryMenu from '../../../../MyComponents/SummaryMenu/TabletSummaryMenu/TabletSummaryMenu';
import { sections } from '../../constants';
import { UPDATE_TUTORIAL_ACTIVE_SECTION } from '../../../../redux/actionTypes';
import { useDispatch } from 'react-redux';

const TutorialTabletPublic = ({ language, refs }) => {
    const classes = styles();
    const dispatch = useDispatch();

    // TODO check nesting here - grid container and item logic makes no sense

    return (
        <>
            <Grid item xs={10} sm={10} md={10} xl={10}>
                <Typography variant="h1" className={classes.tutorialTabletTitle}>
                    <FormattedMessage id="tutorial" />
                </Typography>
            </Grid>
            <Container
                id="section-tutorialStart"
                maxWidth="xl"
                className={classes.tutorialRoot}
                ref={(el) => {
                    if (el && el.id) {
                        refs.current[el.id.toLowerCase()] = el;
                    }
                }}
            >
                <Grid container direction="row">
                    <Grid item xs={12} sm={12} md={12} xl={12}>
                        <Sticky enabled={true}>
                            <TabletSummaryMenu
                                sections={sections}
                                path={'/tutorial'}
                                updateFunction={(id) => {
                                    dispatch({ type: UPDATE_TUTORIAL_ACTIVE_SECTION, payload: { sectionId: id } });
                                }}
                                selectorFunc={(state) => state.tutorial.sectionId}
                            />
                        </Sticky>
                        <GroupManagement language={language} classes={classes} refs={refs} />
                        <Divider variant="middle" />
                        <LessonManagement language={language} classes={classes} refs={refs} />
                        <Divider variant="middle" />
                        <IndividualBoardManagement language={language} classes={classes} refs={refs} />
                        <Divider variant="middle" />
                        <TutorialLibrary language={language} classes={classes} refs={refs} />
                        <Divider variant="middle" />
                        <MobileManagement language={language} classes={classes} refs={refs} />
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default TutorialTabletPublic;
