import React from 'react';
import Hidden from '@material-ui/core/Hidden';
import HomeLandingDesktop from './HomeLandingDesktop';
import HomeLandingMobile from './HomeLandingMobile';
import Container from '@material-ui/core/Container';
import HomeLandingTablet from './HomeLandingTablet';

const HomeLanding = ({ handleOpen, open, handleLogin, language }) => {
    return (
        <Container maxWidth="lg">
            <Hidden only={['xs', 'sm']}>
                <HomeLandingDesktop handleOpen={handleOpen} open={open} handleLogin={handleLogin} language={language} />
            </Hidden>
            <Hidden only={['xs', 'md', 'lg', 'xl']}>
                <HomeLandingTablet handleOpen={handleOpen} language={language} />
            </Hidden>
            <Hidden only={['sm', 'md', 'lg', 'xl']}>
                <HomeLandingMobile handleOpen={handleOpen} language={language} />
            </Hidden>
        </Container>
    );
};

export default HomeLanding;
