import React, { useImperativeHandle, useRef, useState } from 'react';
import { Rnd } from 'react-rnd';
import drawUtils from './drawUtils';
import { Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { FormattedMessage } from 'react-intl';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { LINEAGE_NONE } from './drawConstants';
import TextBoxForSticky from '../../TextBox/TextBoxForSticky';
import boardManager from './boardManager';
import { BOARD_TYPE, TOOL_BRUSH, TOOL_CIRCLE, TOOL_LINE, TOOL_PEN, TOOL_RECTANGLE } from '../../constants';
import { DeleteForever } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { addLatexToWindowCache, getLatexFromWindowCache } from '../../common/utils';
import convert from 'react-from-dom';
import { updateLatexText } from '../../redux/actions';
import store from '../../redux/store';
import LatexEdit from '../../MyComponents/icons/LatexEdit';
import DraggerControl from './DraggerControl';

const imagePasteBox = {
    border: 'solid',
    width: '100%',
    height: '100%',
    position: 'relative',
    borderWidth: '1px',
};

const imagePasteBoxInactive = {
    width: '100%',
    height: '100%',
    position: 'relative',
};

// const canvasContainer = {
//     zIndex: '-1000',
//     position: 'absolute',
// };

const imagePasteImg = {
    width: '100%',
    height: '100%',
    position: 'relative',
};

const imagePasteImgSelected = {
    width: '100%',
    height: '100%',
    position: 'relative',
    opacity: '0.9',
    zIndex: '-1',
};

// const buttonComponent = {
//     position: 'absolute',
//     right: '-24px',
//     top: '-24px',
//     cursor: 'pointer',
// };

const moverActive = {
    cursor: 'move',
    zIndex: 0,
};

// const moverInActive = {
//     cursor: 'not-allowed',
//     zIndex: -1,
// };

const lineageStyle = {
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: '#fff',
    zIndex: '-1000',
    top: '0px',
    left: '0px',
};

const useStyles = makeStyles(() => ({
    buttonRemoveComponent: {
        position: 'absolute',
        cursor: 'pointer',
        right: '-18px',
        top: '-18px',
        width: '0.7em',
        height: '0.7em',
        transition: 'transform 0.3s',
        '&:hover': {
            // right: '-24px',
            // top: '-24px',
            transform: 'scale(1.8)',
        },
    },
    buttonGroup: {
        position: 'absolute',
        cursor: 'pointer',
        right: '-24px',
        top: '-24px',
    },
    buttonGroupComponent: {
        width: '0.7em',
        height: '0.7em',
        transition: 'transform 0.3s',
        marginLeft: '0.5rem',
        '&:hover': {
            // right: '-24px',
            // top: '-24px',
            transform: 'scale(1.8)',
        },
    },
}));

const StickyImage = ({
                         reverseScaledPoint,
                         reverseScaledWHPoints,
                         el,
                         isSelectable,
                         setDialogClear,
                         updateStickyImagePosition,
                         startPoint,
                     }) => {
    const classes = useStyles();
    if (isSelectable) {
        return (
            <Rnd
                key={'stickyImage-' + el.id}
                position={{
                    x: reverseScaledPoint.x,
                    y: reverseScaledPoint.y,
                }}
                size={{
                    width: reverseScaledWHPoints.x - reverseScaledPoint.x,
                    height: reverseScaledWHPoints.y - reverseScaledPoint.y,
                }}
                bounds='parent'
                dragAxis='both'
                onDragStop={(e, d) => {
                    let scaledPoint = drawUtils.scalePoint(startPoint, d.x, d.y);
                    updateStickyImagePosition(el.id, scaledPoint.x, scaledPoint.y);
                    // this.setState({ x: d.x, y: d.y });
                }}
                onResizeStop={(e, direction, ref, delta, position) => {
                    let scalePointSize = drawUtils.scalePoint({ x: 0, y: 0 }, ref.offsetWidth, ref.offsetHeight);
                    let scaledPoint = drawUtils.scalePoint(startPoint, position.x, position.y);
                    updateStickyImagePosition(el.id, scaledPoint.x, scaledPoint.y, scalePointSize.x, scalePointSize.y);
                }}
                style={moverActive}
                onTouchStart={(e) => {
                    e.stopPropagation();
                }}
                onPointerDown={(e) => {
                    e.stopPropagation();
                }}
            >
                <div style={imagePasteBox}>
                    <img src={el.url} alt='' style={imagePasteImgSelected} />
                    {/*<TextareaAutosize value={'ana are mere'} />*/}
                    <DeleteForever
                        color='secondary'
                        className={classes.buttonRemoveComponent}
                        onClick={() => {
                            setDialogClear(el.id);
                        }}
                        onTouchStart={() => {
                            setDialogClear(el.id);
                        }}
                    />
                </div>
            </Rnd>
        );
    } else {
        let style = { ...imagePasteBoxInactive };
        style.position = 'absolute';
        style.top = reverseScaledPoint.y + 'px';
        style.left = reverseScaledPoint.x + 'px';
        style.width = reverseScaledWHPoints.x - reverseScaledPoint.x;
        style.height = reverseScaledWHPoints.y - reverseScaledPoint.y;
        style.zIndex = '-1000';
        return (
            <div style={style}>
                <img src={el.url} alt='' style={imagePasteImg} />
            </div>
        );
    }
};

const StickyLatex = ({
                         reverseScaledPoint,
                         reverseScaledWHPoints,
                         el,
                         isSelectable,
                         setDialogClear,
                         updateLatexCallback,
                         updateStickyImagePosition,
                         startPoint,
                     }) => {
    const classes = useStyles();
    let ltx = getLatexFromWindowCache(el.id);
    if (!ltx) {
        ltx = window.latexToImg(el.latex);
        addLatexToWindowCache(el.id, ltx);
    }

    if (isSelectable) {
        return (
            <Rnd
                key={'stickyLatex-' + el.id}
                position={{
                    x: reverseScaledPoint.x,
                    y: reverseScaledPoint.y,
                }}
                size={{
                    width: reverseScaledWHPoints.x - reverseScaledPoint.x,
                    height: reverseScaledWHPoints.y - reverseScaledPoint.y,
                }}
                bounds='parent'
                dragAxis='both'
                onDragStop={(e, d) => {
                    let scaledPoint = drawUtils.scalePoint(startPoint, d.x, d.y);
                    updateStickyImagePosition(el.id, scaledPoint.x, scaledPoint.y);
                    // this.setState({ x: d.x, y: d.y });
                }}
                onResizeStop={(e, direction, ref, delta, position) => {
                    let scalePointSize = drawUtils.scalePoint({ x: 0, y: 0 }, ref.offsetWidth, ref.offsetHeight);
                    let scaledPoint = drawUtils.scalePoint(startPoint, position.x, position.y);
                    updateStickyImagePosition(el.id, scaledPoint.x, scaledPoint.y, scalePointSize.x, scalePointSize.y);
                }}
                style={moverActive}
                onTouchStart={(e) => {
                    e.stopPropagation();
                }}
                onPointerDown={(e) => {
                    e.stopPropagation();
                }}
            >
                <div style={{ ...imagePasteBox, color: el.color }}>
                    {convert(ltx)}
                    <div className={classes.buttonGroup}>
                        <LatexEdit
                            color='primary'
                            className={classes.buttonGroupComponent}
                            onClick={() => {
                                store.dispatch(updateLatexText({ el, callback: updateLatexCallback }));
                            }}
                            onTouchStart={() => {
                                store.dispatch(updateLatexText({ el, callback: updateLatexCallback }));
                            }}
                        />
                        <DeleteForever
                            color='secondary'
                            className={classes.buttonGroupComponent}
                            onClick={() => {
                                setDialogClear(el.id);
                            }}
                            onTouchStart={() => {
                                setDialogClear(el.id);
                            }}
                        />
                    </div>
                </div>
            </Rnd>
        );
    } else {
        let style = { ...imagePasteBoxInactive };
        style.position = 'absolute';
        style.top = reverseScaledPoint.y + 'px';
        style.left = reverseScaledPoint.x + 'px';
        style.width = reverseScaledWHPoints.x - reverseScaledPoint.x;
        style.height = reverseScaledWHPoints.y - reverseScaledPoint.y;
        style.zIndex = '-1000';
        style.color = el.color;
        return <div style={style}>{convert(ltx)}</div>;
    }
};

const StickyElements = React.forwardRef((props, ref) => {
    let [stickyComponents, setStickyComponents] = useState([]);
    let [fixedStickyComponents, setFixedStickyComponents] = useState([]);
    let [startPoint, setStartPoint] = useState({ x: 0, y: 0 });
    let [dialogClear, setDialogClear] = useState(null);
    let [isSelectable, setIsSelectable] = useState(false);
    let [isTextSelectable, setIsTextSelectable] = useState(false);
    let [lineage, setLineage] = useState(LINEAGE_NONE);
    let [fixedWritingUrl, setFixedWritingUrl] = useState(null);
    let [geometryElements, setGeometryElements] = useState([]);

    const handleClose = () => {
        setDialogClear(null);
    };

    const removeItem = () => {
        props.removeStickyElement(dialogClear);
        setDialogClear(null);
    };

    const lineageData = useRef({});
    const pointerData = useRef({ cleanerCallback: null, canvasRef: React.createRef() });
    const auxDrawingData = useRef({ auxDrawingDataCanvasRef: React.createRef() });

    const updateLatexCallback = (id, latex) => {
        if (props.updateLatexElement) {
            props.updateLatexElement(id, latex);
        }
    };

    useImperativeHandle(ref, () => ({
        addStickyElement(el) {
            setStickyComponents([...stickyComponents, el]);
        },
        shallowSyncPageElements(elements) {
            if (!elements) {
                setStickyComponents([]);
            } else {
                setStickyComponents([...elements]);
            }
        },
        setFixedStickyElements(elements) {
            if (elements) {
                setFixedStickyComponents([...elements]);
            } else {
                setFixedStickyComponents([]);
            }
        },
        setFixedWritingUrl(url) {
            // console.log('=== setting fixed writing', url);
            setFixedWritingUrl(url);
        },
        updateStartPoint(newStartPoint) {
            setStartPoint(newStartPoint);
        },
        setSelectable(value) {
            setIsSelectable(value);
        },
        setIsTextSelectable(value) {
            setIsTextSelectable(value);
        },
        setLineage(lineage) {
            // console.log('=== setting lineage', lineage);
            // console.trace();
            if (lineage !== LINEAGE_NONE && !lineageData.current[lineage]) {
                lineageData.current[lineage] = drawUtils.buildLineageImage(lineage);
            }
            setLineage(lineage);
        },
        drawPointerEvent() {
            if (pointerData.current) {
                if (pointerData.current.cleanerCallback) {
                    clearTimeout(pointerData.current.cleanerCallback);
                }
                pointerData.current.cleanerCallback = setTimeout(() => {
                    if (pointerData.current && pointerData.current.canvasRef && pointerData.current.canvasRef.current) {
                        pointerData.current.cleanerCallback = null;
                        pointerData.current.canvasRef.current
                            .getContext('2d')
                            .clearRect(0, 0, drawUtils.CANVAS_ACTUAL_WIDTH, drawUtils.CANVAS_ACTUAL_HEIGHT);
                    }
                }, 2000);
            }
        },
        drawShape(tool, x1, y1, x2, y2, weight, color) {
            if (auxDrawingData.current) {
                if (
                    auxDrawingData.current &&
                    auxDrawingData.current.auxDrawingDataCanvasRef &&
                    auxDrawingData.current.auxDrawingDataCanvasRef.current
                ) {
                    let ctx = auxDrawingData.current.auxDrawingDataCanvasRef.current.getContext('2d');
                    ctx.clearRect(0, 0, drawUtils.CANVAS_ACTUAL_WIDTH, drawUtils.CANVAS_ACTUAL_HEIGHT);
                    if (!tool) {
                        return;
                    }
                    if (x1 === x2 && y1 === y2) return;
                    let width = drawUtils.computeLineWidth(weight);
                    ctx.lineWidth = width;
                    ctx.strokeStyle = color;

                    if (tool === TOOL_LINE || tool === TOOL_PEN) {
                        ctx.lineCap = 'round';
                        ctx.lineJoin = 'round';
                        ctx.beginPath();
                        ctx.moveTo(x1, y1);
                        ctx.lineTo(x2, y2);
                        ctx.stroke();
                        ctx.closePath();
                    } else if (tool === TOOL_RECTANGLE) {
                        ctx.beginPath();
                        ctx.lineCap = 'square';
                        ctx.lineJoin = 'miter';
                        ctx.miterLimit = 10;
                        ctx.lineWidth = width;
                        ctx.rect(x1 > x2 ? x2 : x1, y1 > y2 ? y2 : y1, Math.abs(x2 - x1), Math.abs(y2 - y1));
                        ctx.stroke();
                        ctx.closePath();
                    } else if (tool === TOOL_CIRCLE) {
                        ctx.beginPath();
                        ctx.lineCap = 'square';
                        ctx.lineJoin = 'miter';
                        ctx.miterLimit = 10;
                        ctx.ellipse(
                            Math.min(x1, x2) + Math.abs((x1 - x2) / 2),
                            Math.min(y1, y2) + Math.abs((y1 - y2) / 2),
                            Math.abs((x2 - x1) / 2),
                            Math.abs((y2 - y1) / 2),
                            0,
                            0,
                            360,
                        );
                        ctx.stroke();
                        ctx.closePath();
                    }
                }
            }
        },
        drawArc(x, y, archWidth, direction, start, end, weight, color) {
            if (auxDrawingData.current) {
                if (
                    auxDrawingData.current &&
                    auxDrawingData.current.auxDrawingDataCanvasRef &&
                    auxDrawingData.current.auxDrawingDataCanvasRef.current
                ) {
                    let ctx = auxDrawingData.current.auxDrawingDataCanvasRef.current.getContext('2d');
                    ctx.clearRect(0, 0, drawUtils.CANVAS_ACTUAL_WIDTH, drawUtils.CANVAS_ACTUAL_HEIGHT);
                    let width = drawUtils.computeLineWidth(weight);
                    ctx.lineWidth = width;
                    ctx.strokeStyle = color;
                    ctx.beginPath();
                    if (start === end) {
                        ctx.arc(x, y, archWidth, 0, 2 * Math.PI, direction === -1);
                    } else {
                        ctx.arc(x, y, archWidth, (start * Math.PI) / 180, (end * Math.PI) / 180, direction === -1);
                    }
                    ctx.stroke();
                }
            }
        },
        clearAuxDrawing() {
            if (auxDrawingData.current) {
                if (
                    auxDrawingData.current &&
                    auxDrawingData.current.auxDrawingDataCanvasRef &&
                    auxDrawingData.current.auxDrawingDataCanvasRef.current
                ) {
                    let ctx = auxDrawingData.current.auxDrawingDataCanvasRef.current.getContext('2d');
                    ctx.clearRect(0, 0, drawUtils.CANVAS_ACTUAL_WIDTH, drawUtils.CANVAS_ACTUAL_HEIGHT);
                    ctx.beginPath();
                    ctx.stroke();
                }
            }
        },
        getPointerContext() {
            if (pointerData.current && pointerData.current.canvasRef && pointerData.current.canvasRef.current) {
                return pointerData.current.canvasRef.current.getContext('2d');
            }
            return null;
        },
        updateGeometryState(geometry) {
            let geometryEls = [];
            if (geometry.compass && geometry.compass.visible) {
                let ell = document.getElementById('compass-move-icon-position')?.getBoundingClientRect();
                let elSP = document.getElementById('compass-start-point')?.getBoundingClientRect();
                if (!ell || !elSP) {
                    return;
                }
                let adjY = elSP.top - ell.top;
                let adjX = elSP.left - ell.left;
                geometryEls.push(
                    <DraggerControl
                        startPoint={startPoint}
                        el={{ id: 'mainCompassMover' }}
                        point={geometry.compass.position}
                        key={`stickyEls-compass-main-move`}
                        // adjustLeft={-16}
                        adjustLeft={-1 * adjX}
                        // adjustTop={-32}
                        adjustTop={-1 * adjY}
                        icon={
                            <div
                                id='compass-move-icon-position-drag'
                                style={{
                                    position: 'absolute',
                                    width: '100%',
                                    height: '100%',
                                }}
                            />
                        }
                        onDrag={window.compassMove}
                        onStart={window.compassStartMove}
                        onStop={window.compassStopMove}
                    />,
                );
                let el = document.getElementById('compass-expand-icon-position')?.getBoundingClientRect();
                let reverseScaledPoint = drawUtils.scalePoint(startPoint, el.x, el.y);
                geometryEls.push(
                    <DraggerControl
                        startPoint={startPoint}
                        el={{ id: 'mainCompassExpander' }}
                        point={{ x: reverseScaledPoint.x, y: reverseScaledPoint.y }}
                        key={`stickyEls-compass-main-expand`}
                        adjustLeft={-32}
                        adjustTop={-48}
                        icon={
                            <div
                                id='compass-expand-icon-position-drag'
                                style={{
                                    position: 'absolute',
                                    width: '100%',
                                    height: '100%',
                                }}
                            />
                        }
                        onDrag={window.expandCompass}
                        onStart={window.handleStartCompassExpand}
                        onStop={window.stopCompassExpand}
                    />,
                );
                el = document.getElementById('compass-rotate-icon-position')?.getBoundingClientRect();
                reverseScaledPoint = drawUtils.scalePoint(startPoint, el.x, el.y);
                geometryEls.push(
                    <DraggerControl
                        startPoint={startPoint}
                        el={{ id: 'mainCompassRotator' }}
                        point={{ x: reverseScaledPoint.x, y: reverseScaledPoint.y }}
                        key={`stickyEls-compass-main-rotate`}
                        adjustLeft={-32}
                        adjustTop={-48}
                        icon={
                            <div
                                id='compass-rotate-icon-position-drag'
                                style={{
                                    position: 'absolute',
                                    width: '100%',
                                    height: '100%',
                                }}
                            />
                        }
                        onDrag={window.rotateCompass}
                        onStart={window.handleStartCompassRotate}
                        onStop={window.stopCompassRotate}
                    />,
                );
                let deleteEl = document.getElementById('compass-delete-icon-position').getBoundingClientRect();
                let rect = drawUtils.getCurrentCanvasRect();

                geometryEls.push(
                    <div
                        color={'secondary'}
                        key={'main-compass-delete-div'}
                        onClick={(e) => {
                            if (window.hideCompass) {
                                window.hideCompass();
                            }
                            e.stopPropagation();
                        }}
                        onTouchStart={(e) => {
                            if (window.hideCompass) {
                                window.hideCompass();
                            }
                            e.stopPropagation();
                        }}
                        style={{
                            top: deleteEl.top - rect.top + 'px',
                            left: deleteEl.left - rect.left + 'px',
                            width: '32px',
                            height: '32px',
                            position: 'absolute',
                            cursor: 'auto',
                        }}
                    />,
                );
            }

            if (geometry.ruler && geometry.ruler.visible) {
                let ell = document.getElementById('ruler-move-icon-position')?.getBoundingClientRect();
                let elSP = document.getElementById('ruler-start-point')?.getBoundingClientRect();
                if (!ell || !elSP) return;
                let adjY = elSP.top - ell.top;
                let adjX = elSP.left - ell.left;
                geometryEls.push(
                    <DraggerControl
                        startPoint={startPoint}
                        el={{ id: 'mainRulerMover' }}
                        point={geometry.ruler.position}
                        key={`stickyEls-ruler-main-move`}
                        // adjustLeft={-16}
                        adjustLeft={-1 * adjX}
                        // adjustTop={-32}
                        adjustTop={-1 * adjY}
                        icon={
                            <div
                                id='ruler-move-icon-position-drag'
                                style={{
                                    position: 'absolute',
                                    width: '100%',
                                    height: '100%',
                                }}
                            />
                        }
                        onDrag={window.moveRuler}
                        onStart={window.handleStartRulerMove}
                        onStop={window.stopRuler}
                    />,
                );

                let el = document.getElementById('ruler-rotate-icon-position')?.getBoundingClientRect();
                let reverseScaledPoint = drawUtils.scalePoint(startPoint, el.x, el.y);
                geometryEls.push(
                    <DraggerControl
                        startPoint={startPoint}
                        el={{ id: 'mainRulerRotator' }}
                        point={{ x: reverseScaledPoint.x, y: reverseScaledPoint.y }}
                        key={`stickyEls-ruler-main-rotate`}
                        adjustLeft={-32}
                        adjustTop={-48}
                        icon={
                            <div
                                id='compass-rotate-icon-position-drag'
                                style={{
                                    position: 'absolute',
                                    width: '100%',
                                    height: '100%',
                                }}
                            />
                        }
                        onDrag={window.rotateRuler}
                        onStart={window.handleStartRulerRotate}
                        onStop={window.stopRulerRotate}
                    />,
                );
                let deleteEl = document.getElementById('ruler-delete-icon-position').getBoundingClientRect();
                let rect = drawUtils.getCurrentCanvasRect();

                geometryEls.push(
                    <div
                        color={'secondary'}
                        key={'main-ruler-delete-div'}
                        onClick={(e) => {
                            if (window.hideRuler) {
                                window.hideRuler();
                            }
                            e.stopPropagation();
                        }}
                        onTouchStart={(e) => {
                            if (window.hideRuler) {
                                window.hideRuler();
                            }
                            e.stopPropagation();
                        }}
                        style={{
                            top: deleteEl.top - rect.top + 'px',
                            left: deleteEl.left - rect.left + 'px',
                            width: '32px',
                            height: '32px',
                            position: 'absolute',
                            cursor: 'auto',
                        }}
                    />,
                );
            }

            if (geometry.protractor && geometry.protractor.visible) {
                let ell = document.getElementById('protractor-move-icon-position')?.getBoundingClientRect();
                if (!ell) return;
                let reverseScaledPoint = drawUtils.scalePoint(startPoint, ell.x, ell.y);
                geometryEls.push(
                    <DraggerControl
                        startPoint={startPoint}
                        el={{ id: 'mainProtractorMover' }}
                        point={{ x: reverseScaledPoint.x, y: reverseScaledPoint.y }}
                        key={`stickyEls-protractor-main-move`}
                        adjustLeft={-32}
                        adjustTop={-48}
                        icon={
                            <div
                                id='protractor-move-icon-position-drag'
                                style={{
                                    position: 'absolute',
                                    width: '100%',
                                    height: '100%',
                                }}
                            />
                        }
                        onDrag={window.moveProtractor}
                        onStart={window.handleStartMoveProtractor}
                        onStop={window.stopProtractor}
                    />,
                );

                let el = document.getElementById('protractor-rotate-icon-position')?.getBoundingClientRect();
                reverseScaledPoint = drawUtils.scalePoint(startPoint, el.x, el.y);
                geometryEls.push(
                    <DraggerControl
                        startPoint={startPoint}
                        el={{ id: 'mainProtractorRotator' }}
                        point={{ x: reverseScaledPoint.x, y: reverseScaledPoint.y }}
                        key={`stickyEls-protractor-main-rotate`}
                        adjustLeft={-32}
                        adjustTop={-48}
                        icon={
                            <div
                                id='compass-protractor-icon-position-drag'
                                style={{
                                    position: 'absolute',
                                    width: '100%',
                                    height: '100%',
                                }}
                            />
                        }
                        onDrag={window.rotateProtractor}
                        onStart={window.handleStartRotateProtractor}
                        onStop={window.stopProtractorRotate}
                    />,
                );
                let deleteEl = document.getElementById('protractor-delete-icon-position').getBoundingClientRect();
                let rect = drawUtils.getCurrentCanvasRect();

                geometryEls.push(
                    <div
                        color={'secondary'}
                        key={'main-protractor-delete-div'}
                        onClick={(e) => {
                            if (window.hideProtractor) {
                                window.hideProtractor();
                            }
                            e.stopPropagation();
                        }}
                        onTouchStart={(e) => {
                            if (window.hideProtractor) {
                                window.hideProtractor();
                            }
                            e.stopPropagation();
                        }}
                        style={{
                            top: deleteEl.top - rect.top + 'px',
                            left: deleteEl.left - rect.left + 'px',
                            width: '32px',
                            height: '32px',
                            position: 'absolute',
                            cursor: 'auto',
                        }}
                    />,
                );
            }
            geometryEls.filter((g) => !g);
            setGeometryElements(geometryEls);
        },
    }));

    let fixedImageItems = fixedStickyComponents.map((el) => {
        if (el.stickyType === 'image') {
            let reverseScaledPoint = drawUtils.reverseScalePoint(startPoint, el.x, el.y);
            let reverseScaledWHPoints = drawUtils.reverseScalePoint(startPoint, el.x + el.width, el.y + el.height);

            if (!reverseScaledPoint) {
                reverseScaledPoint = drawUtils.reverseScalePoint(startPoint, el.x, el.y, props.email);
            }
            if (!reverseScaledWHPoints) {
                reverseScaledWHPoints = drawUtils.reverseScalePoint(
                    startPoint,
                    el.x + el.width,
                    el.y + el.height,
                    props.email,
                );
            }
            if (!reverseScaledPoint || !reverseScaledWHPoints) {
                // console.log('==== unable to see ', props.email);
                return null;
            }

            return (
                <StickyImage
                    key={'stickyImageItems-' + el.id}
                    reverseScaledPoint={reverseScaledPoint}
                    reverseScaledWHPoints={reverseScaledWHPoints}
                    el={el}
                    isSelectable={false}
                    setDialogClear={setDialogClear}
                    startPoint={startPoint}
                />
            );
        }
        return null;
    });

    let fixedLatexItems = fixedStickyComponents.map((el) => {
        if (el.stickyType === 'latex') {
            let reverseScaledPoint = drawUtils.reverseScalePoint(startPoint, el.x, el.y);
            let reverseScaledWHPoints = drawUtils.reverseScalePoint(startPoint, el.x + el.width, el.y + el.height);

            if (!reverseScaledPoint) {
                reverseScaledPoint = drawUtils.reverseScalePoint(startPoint, el.x, el.y, props.email);
            }
            if (!reverseScaledWHPoints) {
                reverseScaledWHPoints = drawUtils.reverseScalePoint(
                    startPoint,
                    el.x + el.width,
                    el.y + el.height,
                    props.email,
                );
            }
            if (!reverseScaledPoint || !reverseScaledWHPoints) {
                // console.log('==== unable to see ', props.email);
                return null;
            }

            return (
                <StickyLatex
                    key={'stickyLatexItems-' + el.id}
                    reverseScaledPoint={reverseScaledPoint}
                    reverseScaledWHPoints={reverseScaledWHPoints}
                    el={el}
                    isSelectable={false}
                    setDialogClear={setDialogClear}
                    startPoint={startPoint}
                    updateLatexCallback={updateLatexCallback}
                />
            );
        }
        return null;
    });

    let latexItems = stickyComponents.map((el) => {
        if (el.stickyType === 'latex') {
            let reverseScaledPoint = drawUtils.reverseScalePoint(startPoint, el.x, el.y);
            let reverseScaledWHPoints = drawUtils.reverseScalePoint(startPoint, el.x + el.width, el.y + el.height);

            if (!reverseScaledPoint) {
                reverseScaledPoint = drawUtils.reverseScalePoint(startPoint, el.x, el.y, props.email);
            }
            if (!reverseScaledWHPoints) {
                reverseScaledWHPoints = drawUtils.reverseScalePoint(
                    startPoint,
                    el.x + el.width,
                    el.y + el.height,
                    props.email,
                );
            }
            if (!reverseScaledPoint || !reverseScaledWHPoints) {
                // console.log('==== unable to see ', props.email);
                return null;
            }

            return (
                <StickyLatex
                    key={'latexItems-' + el.id}
                    reverseScaledPoint={reverseScaledPoint}
                    reverseScaledWHPoints={reverseScaledWHPoints}
                    el={el}
                    setDialogClear={setDialogClear}
                    startPoint={startPoint}
                    isSelectable={
                        (isSelectable && boardManager.lessonState.boardType === BOARD_TYPE.MULTI_BOARD) ||
                        (isSelectable && boardManager.isAdmin) ||
                        (isSelectable && boardManager.adminEmail !== el.creator)
                    }
                    updateStickyImagePosition={props.updateStickyImagePosition}
                    updateLatexCallback={updateLatexCallback}
                />
            );
        }
        return null;
    });

    let imageItems = stickyComponents.map((el) => {
        if (el.stickyType === 'image') {
            let reverseScaledPoint = drawUtils.reverseScalePoint(startPoint, el.x, el.y);
            let reverseScaledWHPoints = drawUtils.reverseScalePoint(startPoint, el.x + el.width, el.y + el.height);

            if (!reverseScaledPoint) {
                reverseScaledPoint = drawUtils.reverseScalePoint(startPoint, el.x, el.y, props.email);
            }
            if (!reverseScaledWHPoints) {
                reverseScaledWHPoints = drawUtils.reverseScalePoint(
                    startPoint,
                    el.x + el.width,
                    el.y + el.height,
                    props.email,
                );
            }
            if (!reverseScaledPoint || !reverseScaledWHPoints) {
                // console.log('==== unable to see ', props.email);
                return null;
            }

            return (
                <StickyImage
                    key={'stickyImageItems-' + el.id}
                    reverseScaledPoint={reverseScaledPoint}
                    reverseScaledWHPoints={reverseScaledWHPoints}
                    el={el}
                    isSelectable={
                        (isSelectable && boardManager.lessonState.boardType === BOARD_TYPE.MULTI_BOARD) ||
                        (isSelectable && boardManager.isAdmin) ||
                        (isSelectable && boardManager.adminEmail !== el.creator)
                    }
                    setDialogClear={setDialogClear}
                    updateStickyImagePosition={props.updateStickyImagePosition}
                    startPoint={startPoint}
                />
            );
        }
        return null;
    });

    let fixedTextItems = fixedStickyComponents.map((el) => {
        if (el.stickyType === 'text') {
            let reverseScaledPoint = drawUtils.reverseScalePoint(startPoint, el.x, el.y);
            let reverseScaledWHPoints = drawUtils.reverseScalePoint({ x: 0, y: 0 }, el.width, el.height);

            if (!reverseScaledPoint) {
                reverseScaledPoint = drawUtils.reverseScalePoint(startPoint, el.x, el.y, props.email);
            }
            if (!reverseScaledWHPoints) {
                reverseScaledWHPoints = drawUtils.reverseScalePoint({ x: 0, y: 0 }, el.width, el.height, props.email);
            }
            if (!reverseScaledPoint || !reverseScaledWHPoints) {
                // console.log('==== unable to see ', props.email);
                return null;
            }

            return (
                <TextBoxForSticky
                    key={'stickyTextItems-' + el.id}
                    id={el.id}
                    x={reverseScaledPoint.x}
                    y={reverseScaledPoint.y}
                    width={reverseScaledWHPoints.x}
                    height={reverseScaledWHPoints.y}
                    text={el.text}
                    weight={el.weight}
                    color={el.color}
                    isSelectable={false}
                    startPoint={startPoint}
                    setDialogClear={setDialogClear}
                    email={props.email}
                    setWriting={props.setWriting}
                    removeElement={props.removeStickyElement}
                />
            );
        }
        return null;
    });

    let textItems = stickyComponents.map((el) => {
        if (el.stickyType === 'text') {
            let reverseScaledPoint = drawUtils.reverseScalePoint(startPoint, el.x, el.y);

            let reverseScaledWHPoints = drawUtils.reverseScalePoint({ x: 0, y: 0 }, el.width, el.height);

            if (!reverseScaledPoint) {
                reverseScaledPoint = drawUtils.reverseScalePoint(startPoint, el.x, el.y, props.email);
            }
            if (!reverseScaledWHPoints) {
                reverseScaledWHPoints = drawUtils.reverseScalePoint({ x: 0, y: 0 }, el.width, el.height, props.email);
            }

            if (!reverseScaledPoint || !reverseScaledWHPoints) {
                // console.log('==== unable to see ', props.email);
                return null;
            }

            return (
                <TextBoxForSticky
                    key={'stickyTextItems-' + el.id}
                    id={el.id}
                    x={reverseScaledPoint.x}
                    y={reverseScaledPoint.y}
                    width={reverseScaledWHPoints.x}
                    height={reverseScaledWHPoints.y}
                    text={el.text}
                    weight={el.weight}
                    color={el.color}
                    isSelectable={
                        ((isSelectable || isTextSelectable) &&
                            boardManager.lessonState.boardType === BOARD_TYPE.MULTI_BOARD) ||
                        ((isSelectable || isTextSelectable) && boardManager.isAdmin) ||
                        ((isSelectable || isTextSelectable) && boardManager.adminEmail !== el.creator)
                    }
                    updateStickyTextInfo={props.updateStickyTextInfo}
                    startPoint={startPoint}
                    setDialogClear={setDialogClear}
                    email={props.email}
                    setWriting={props.setWriting}
                    removeElement={props.removeStickyElement}
                />
            );
        }
        return null;
    });

    // console.log('=== geometry elements', geometryElements);
    let items = [
        ...fixedImageItems,
        ...imageItems,
        ...fixedTextItems,
        ...textItems,
        ...fixedLatexItems,
        ...latexItems,
        ...geometryElements,
    ];

    let lineageElement;
    if (lineage !== LINEAGE_NONE && lineageData.current[lineage]) {
        let reverseScaledPoint = drawUtils.reverseScalePoint(startPoint, 0, 0);
        let reverseScaledWHPoints = drawUtils.reverseScalePoint(
            startPoint,
            drawUtils.CANVAS_ACTUAL_WIDTH,
            drawUtils.CANVAS_ACTUAL_HEIGHT,
        );

        if (!reverseScaledPoint) {
            reverseScaledPoint = drawUtils.reverseScalePoint(startPoint, 0, 0, props.email);
        }
        if (!reverseScaledWHPoints) {
            reverseScaledWHPoints = drawUtils.reverseScalePoint(
                startPoint,
                drawUtils.CANVAS_ACTUAL_WIDTH,
                drawUtils.CANVAS_ACTUAL_HEIGHT,
                props.email,
            );
        }
        if (!reverseScaledPoint || !reverseScaledWHPoints) {
            lineageElement = <div style={lineageStyle} />;
        } else {
            let myStyle = { ...imagePasteBoxInactive };
            myStyle.position = 'absolute';
            myStyle.top = reverseScaledPoint.y + 'px';
            myStyle.left = reverseScaledPoint.x + 'px';
            myStyle.width = reverseScaledWHPoints.x - reverseScaledPoint.x + 'px';
            myStyle.height = reverseScaledWHPoints.y - reverseScaledPoint.y + 'px';
            myStyle.backgroundColor = '#fff';
            myStyle.zIndex = '-1000';
            lineageElement = (
                <div style={myStyle} key={'lineage'}>
                    <img src={lineageData.current[lineage]} alt='' style={imagePasteImg} />
                </div>
            );
        }
    } else {
        lineageElement = <div style={lineageStyle} />;
    }
    let fixedWriting = null;
    // console.log('=== got fixed writing', fixedWritingUrl);
    if (fixedWritingUrl) {
        let reverseScaledPoint = drawUtils.reverseScalePoint(startPoint, 0, 0);
        let reverseScaledWHPoints = drawUtils.reverseScalePoint(
            startPoint,
            drawUtils.CANVAS_ACTUAL_WIDTH,
            drawUtils.CANVAS_ACTUAL_HEIGHT,
        );
        if (!reverseScaledPoint) {
            reverseScaledPoint = drawUtils.reverseScalePoint(startPoint, 0, 0, props.email);
        }
        if (!reverseScaledWHPoints) {
            reverseScaledWHPoints = drawUtils.reverseScalePoint(
                startPoint,
                drawUtils.CANVAS_ACTUAL_WIDTH,
                drawUtils.CANVAS_ACTUAL_HEIGHT,
                props.email,
            );
        }
        if (!reverseScaledPoint || !reverseScaledWHPoints) {
            fixedWriting = null;
        } else {
            let myStyle = { ...imagePasteBoxInactive };
            myStyle.position = 'absolute';
            myStyle.top = reverseScaledPoint.y + 'px';
            myStyle.left = reverseScaledPoint.x + 'px';
            myStyle.width = reverseScaledWHPoints.x - reverseScaledPoint.x + 'px';
            myStyle.height = reverseScaledWHPoints.y - reverseScaledPoint.y + 'px';
            myStyle.zIndex = '-1000';
            fixedWriting = (
                <div style={myStyle} key={'originalDrawing'}>
                    <img src={fixedWritingUrl} alt='' style={imagePasteImg} />
                </div>
            );
        }
    }

    let pointerElement;
    if (pointerData.current && pointerData.current.canvasRef) {
        let reverseScaledPoint = drawUtils.reverseScalePoint(startPoint, 0, 0);
        let reverseScaledWHPoints = drawUtils.reverseScalePoint(
            startPoint,
            drawUtils.CANVAS_ACTUAL_WIDTH,
            drawUtils.CANVAS_ACTUAL_HEIGHT,
        );

        if (!reverseScaledPoint) {
            reverseScaledPoint = drawUtils.reverseScalePoint(startPoint, 0, 0, props.email);
        }
        if (!reverseScaledWHPoints) {
            reverseScaledWHPoints = drawUtils.reverseScalePoint(
                startPoint,
                drawUtils.CANVAS_ACTUAL_WIDTH,
                drawUtils.CANVAS_ACTUAL_HEIGHT,
                props.email,
            );
        }
        if (!reverseScaledPoint || !reverseScaledWHPoints) {
            pointerElement = null;
        } else {
            let myStyle = { ...imagePasteBoxInactive };
            myStyle.position = 'absolute';
            myStyle.top = reverseScaledPoint.y + 'px';
            myStyle.left = reverseScaledPoint.x + 'px';
            myStyle.width = reverseScaledWHPoints.x - reverseScaledPoint.x + 'px';
            myStyle.height = reverseScaledWHPoints.y - reverseScaledPoint.y + 'px';
            myStyle.zIndex = '-1';
            pointerElement = (
                <div style={myStyle} key={'pointer'}>
                    <canvas
                        width={drawUtils.CANVAS_ACTUAL_WIDTH}
                        height={drawUtils.CANVAS_ACTUAL_HEIGHT}
                        style={imagePasteImg}
                        ref={pointerData.current.canvasRef}
                    />
                </div>
            );
        }
    }

    let auxDrawing;
    if (auxDrawingData.current && auxDrawingData.current.auxDrawingDataCanvasRef) {
        let reverseScaledPoint = drawUtils.reverseScalePoint(startPoint, 0, 0);
        let reverseScaledWHPoints = drawUtils.reverseScalePoint(
            startPoint,
            drawUtils.CANVAS_ACTUAL_WIDTH,
            drawUtils.CANVAS_ACTUAL_HEIGHT,
        );

        if (!reverseScaledPoint) {
            reverseScaledPoint = drawUtils.reverseScalePoint(startPoint, 0, 0, props.email);
        }
        if (!reverseScaledWHPoints) {
            reverseScaledWHPoints = drawUtils.reverseScalePoint(
                startPoint,
                drawUtils.CANVAS_ACTUAL_WIDTH,
                drawUtils.CANVAS_ACTUAL_HEIGHT,
                props.email,
            );
        }
        if (!reverseScaledPoint || !reverseScaledWHPoints) {
            auxDrawing = null;
        } else {
            let myStyle = { ...imagePasteBoxInactive };
            myStyle.position = 'absolute';
            myStyle.top = reverseScaledPoint.y + 'px';
            myStyle.left = reverseScaledPoint.x + 'px';
            myStyle.width = reverseScaledWHPoints.x - reverseScaledPoint.x + 'px';
            myStyle.height = reverseScaledWHPoints.y - reverseScaledPoint.y + 'px';
            myStyle.zIndex = '-1';
            auxDrawing = (
                <div style={myStyle} key={'auxDrawer'}>
                    <canvas
                        width={drawUtils.CANVAS_ACTUAL_WIDTH}
                        height={drawUtils.CANVAS_ACTUAL_HEIGHT}
                        style={imagePasteImg}
                        ref={auxDrawingData.current.auxDrawingDataCanvasRef}
                    />
                </div>
            );
        }
    }

    return (
        <>
            {lineageElement}
            {items}
            {fixedWriting}
            {auxDrawing}
            {pointerElement}
            <Dialog open={!!dialogClear} onClose={handleClose}>
                <DialogContent>
                    <Typography variant='h4'>
                        <FormattedMessage id='sticky.dialog.areYouSureDelete' />
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={removeItem} onTouchStart={removeItem} color='primary'>
                        <FormattedMessage id='ok' />
                    </Button>
                    <Button onClick={handleClose} onTouchStart={handleClose} color='primary'>
                        <FormattedMessage id='close' />
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
});

export default StickyElements;
