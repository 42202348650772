import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import { FindMoreButton } from '../Home/HomeFeatures/FindMoreButton';

const useStyles = makeStyles(() => ({
    sectionImage: { textAlign: 'center', padding: '2rem' },
    image: {
        maxWidth: '300px',
        width: '80%',
        borderRadius: '16px',
    },

    imageVSP: {
        maxWidth: '400px',
        width: '80%',
    },

    title: {
        fontWeight: '700',
        textAlign: 'left',
        paddingTop: '2rem',
        paddingBottom: '1rem',
    },

    video: {
        border: '3px solid #065156',
        borderRadius: '2rem',
    },
}));

const AboutMedia = () => {
    const classes = useStyles();

    return (
        <Grid container spacing={3} alignItems={'center'} alignContent={'center'} justify={'center'}>
            <Grid item xs={12}>
                <Typography variant='h2' className={classes.title}>
                    <FormattedMessage id={'aboutUs.media.button'} />
                </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
                <Typography variant='h3'>
                    <FormattedMessage id={'aboutUs.media.m11.place'} />
                </Typography>
                <Typography variant='h6'>
                    <FormattedMessage id={'aboutUs.media.m11.description'} />
                </Typography>
                <br />
                <Typography variant='h6'>
                    <b>
                        <i>
                            <FormattedMessage id={'aboutUs.media.m11.description1'} />
                        </i>
                    </b>
                </Typography>
                <br />
                <Typography variant='h6'>
                    <b>
                        <i>
                            <FormattedMessage id={'aboutUs.media.m11.description2'} />
                        </i>
                    </b>
                </Typography>
                <br />
                <Typography variant='h6'>
                    <b>
                        <i>
                            <FormattedMessage id={'aboutUs.media.m11.description3'} />
                        </i>
                    </b>
                </Typography>
                <br />
                <Typography variant='h6'>
                    <b>
                        <i>
                            <FormattedMessage id={'aboutUs.media.m11.description4'} />
                        </i>
                    </b>
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.sectionImage}>
                <a
                    href={'https://newsroom.orange.ro/comunicate/comunitatile-din-delta-dunarii-vor-avea-acces-la-tehnologia-5g-orange-prin-intermediul-unui-proiect-cu-finantare-europeana/'}
                    target={'_blank'}
                >
                    <FindMoreButton textId={'aboutUs.media.m11.description.orange'} />
                </a>
                <br />
                <a
                    href={'https://www.zf.ro/business-hi-tech/orange-romania-merge-cu-reteaua-5g-si-in-delta-dunarii-printr-un-22530320'}
                    target={'_blank'}
                >
                    <FindMoreButton textId={'aboutUs.media.m11.description.zf'} />
                </a>
                <br />
                <a
                    href={'https://hotnews.ro/comunitatile-din-delta-dunarii-vor-avea-acces-la-tehnologia-5g-orange-prin-intermediul-unui-proiect-cu-finantare-europeana-1824522'}
                    target={'_blank'}
                >
                    <FindMoreButton textId={'aboutUs.media.m11.description.hotnews'} />
                </a>
                <br />
                <a
                    href={'https://stirileprotv.ro/s/p-peste-20-000-de-locuitori-din-delta-dunarii-vor-beneficia-de-acces-la-tehnologie-prin-proiectul-5g-connect-danube-delta.html'}
                    target={'_blank'}
                >
                    <FindMoreButton textId={'aboutUs.media.m11.description.protv'} />
                </a>
            </Grid>
            <Divider variant={'middle'} style={{ width: '100%', marginTop: '2rem', marginBottom: '2rem' }} />

            <Grid item xs={12} sm={6}>
                <Typography variant='h3'>
                    <FormattedMessage id={'aboutUs.media.m3.place'} />
                </Typography>
                <br />
                <Typography variant='h6'>
                    <FormattedMessage id={'aboutUs.media.m3.description'} />
                </Typography>
                <br />
                <Typography variant='h6'>
                    <b>
                        <i>
                            <FormattedMessage id={'aboutUs.media.m3.description1'} />
                        </i>
                    </b>
                </Typography>
                <br />
                <Typography variant='h6'>
                    <b>
                        <i>
                            <FormattedMessage id={'aboutUs.media.m3.description2'} />
                        </i>
                    </b>
                </Typography>
                <br />
                <a
                    href={
                        'https://www.edupedu.ro/video-profesorul-controleaza-interactivitatea-cu-elevul-ghideaza-clasa-spune-marius-mazilu-fondatorul-vboard-aplicatia-nativa-pe-tablele-interactive-nu-ne-permitem-ca-procesul-educational-sa-ias/'
                    }
                    target={'_blank'}
                >
                    <FindMoreButton textId={'aboutUs.media.m3.description.edupedu'} />
                </a>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.sectionImage}>
                <iframe
                    height='314'
                    width='100%'
                    src='https://www.youtube.com/embed/aOFHyo-vIAI?si=ackqptKtCGrptWRx'
                    title='EduPedu'
                    frameBorder='0'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                    allowFullScreen={true}
                    className={classes.video}
                />
            </Grid>

            <Divider variant={'middle'} style={{ width: '100%', marginTop: '2rem', marginBottom: '2rem' }} />

            <Grid item xs={12} sm={6}>
                <Typography variant='h3'>
                    <FormattedMessage id={'aboutUs.media.m4.place'} />
                </Typography>
                <br />
                <Typography variant='h6'>
                    <FormattedMessage id={'aboutUs.media.m4.description'} />
                </Typography>
                <br />
                <a
                    href={
                        'https://www.techexpo.ro/spealers-editiei-2024-tech-stage'
                    }
                    target={'_blank'}
                >
                    <FindMoreButton textId={'aboutUs.media.m4.description.speakers'} />
                </a>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.sectionImage}>
                <iframe
                    height='314'
                    width='100%'
                    src='https://www.youtube.com/embed/BkihbnkeSKU?si=5pIL_GUjWC5x_S3J'
                    title='Tech Expo 2024'
                    frameBorder='0'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                    allowFullScreen={true}
                    className={classes.video}
                />
            </Grid>

            <Divider variant={'middle'} style={{ width: '100%', marginTop: '2rem', marginBottom: '2rem' }} />

            <Grid item xs={12} sm={6}>
                <Typography variant='h3'>
                    <FormattedMessage id={'aboutUs.media.m5.place'} />
                </Typography>
                <br />
                <Typography variant='h6'>
                    <FormattedMessage id={'aboutUs.media.m5.description'} />
                </Typography>
                <br />
                <Typography variant='h6'>
                    <b>
                        <i>
                            <FormattedMessage id={'aboutUs.media.m5.description1'} />
                        </i>
                    </b>
                    <FormattedMessage id={'aboutUs.media.m5.description1.1'} />
                </Typography>
                <Typography variant='h6'>
                    <b>
                        <i>
                            <FormattedMessage id={'aboutUs.media.m5.description2'} />
                        </i>
                    </b>
                    <FormattedMessage id={'aboutUs.media.m5.description2.1'} />
                </Typography>
                <br />
                <a
                    href={
                        'https://www.protv.ro/emisiuni/imperiul-leilor/articol/95513-imperiul-leilor-21-noiembrie-2023-marius-mazilu-foloseste-tehnologia-pentru-a-revolutiona-sistemul-educational-ce-au-spus-leii-despre-afacerea-lui'
                    }
                    target={'_blank'}
                >
                    <FindMoreButton textId={'aboutUs.media.m5.description.protv'} />
                </a>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.sectionImage}>
                <iframe
                    height='314'
                    width='100%'
                    src='https://www.youtube.com/embed/aVzIWa-l5cw?si=pmaIs53ZIrnqUM5j'
                    title='YouTube video player'
                    frameBorder='0'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                    allowFullScreen={true}
                    className={classes.video}
                />
            </Grid>
            <Divider variant={'middle'} style={{ width: '100%', marginTop: '2rem', marginBottom: '2rem' }} />

            <Grid item xs={12} sm={6}>
                <Typography variant='h3'>
                    <FormattedMessage id={'aboutUs.media.m6.place'} />
                </Typography>
                <Typography variant='h6'>
                    <FormattedMessage id={'aboutUs.media.m6.description'} />
                </Typography>
                <br />
                <Typography variant='h6'>
                    <b>
                        <i>
                            <FormattedMessage id={'aboutUs.media.m6.description1'} />
                        </i>
                    </b>
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.sectionImage}>
                <iframe
                    src='https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2F100040195164162%2Fvideos%2F793068531958004%2F&show_text=false&width=560&t=0'
                    height='314'
                    width='100%'
                    scrolling='no'
                    frameBorder='0'
                    allowFullScreen={true}
                    allow='autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share'
                    className={classes.video}
                />
            </Grid>
            <Divider variant={'middle'} style={{ width: '100%', marginTop: '2rem', marginBottom: '2rem' }} />

            <Grid item xs={12} sm={6}>
                <Typography variant='h3'>
                    <FormattedMessage id={'aboutUs.media.m7.place'} />
                </Typography>
                <Typography variant='h6'>
                    <FormattedMessage id={'aboutUs.media.m7.description'} />
                </Typography>
                <br />
                <Typography variant='h6'>
                    <b>
                        <i>
                            <FormattedMessage id={'aboutUs.media.m7.description1'} />
                        </i>
                    </b>
                </Typography>
                <br />

                <a
                    href={'https://start-up.ro/vboard-romanii-care-vor-sa-puna-o-tabla-virtuala-in-orice-clasa/'}
                    target={'_blank'}
                >
                    <FindMoreButton textId={'aboutUs.media.m7.description.start-up'} />
                </a>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.sectionImage}>
                <iframe
                    height='314'
                    width='100%'
                    src='https://www.youtube.com/embed/1hEMGGFI-UU?si=q7dKtcWs85cN0fw9'
                    title='YouTube video player'
                    frameBorder='0'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                    allowFullScreen
                    className={classes.video}
                />
            </Grid>
            <Divider variant={'middle'} style={{ width: '100%', marginTop: '2rem', marginBottom: '2rem' }} />

            <Grid item xs={12} sm={6}>
                <Typography variant='h3'>
                    <FormattedMessage id={'aboutUs.media.m8.place'} />
                </Typography>
                <Typography variant='h6'>
                    <FormattedMessage id={'aboutUs.media.m8.description'} />
                </Typography>
                <br />
                <Typography variant='h6'>
                    <b>
                        <i>
                            <FormattedMessage id={'aboutUs.media.m8.description1.express'} />
                        </i>
                    </b>
                </Typography>
                <br />
                <Typography variant='h6'>
                    <b>
                        <i>
                            <FormattedMessage id={'aboutUs.media.m8.description1.zf'} />
                        </i>
                    </b>
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.sectionImage}>
                <a
                    href={
                        'https://expressdebanat.ro/povestea-timisoreanului-marius-mazilu-care-ofera-o-solutie-impotriva-celei-mai-mari-probleme-create-de-covid-19/'
                    }
                    target={'_blank'}
                >
                    <img src={'https://expressdebanat.ro/wp-content/uploads/2021/07/logo-site-3.png'} />
                </a>
                <br />
                <a
                    href={
                        'https://www.zf.ro/zf-it-generation/zf-it-generation-start-up-pitch-marius-mazilu-fondator-vboard-20289489'
                    }
                    target={'_blank'}
                >
                    <img src={'https://www.zf.ro/images/Logo%20+%20Slogan.png'} width={'80%'} />
                </a>
            </Grid>
            <Divider variant={'middle'} style={{ width: '100%', marginTop: '2rem', marginBottom: '2rem' }} />

            <Grid item xs={12} sm={6}>
                <Typography variant='h3'>
                    <FormattedMessage id={'aboutUs.media.m9.place'} />
                </Typography>
                <Typography variant='h6'>
                    <FormattedMessage id={'aboutUs.media.m9.description'} />
                </Typography>
                <br />
                <Typography variant='h6'>
                    <b>
                        <i>
                            <FormattedMessage id={'aboutUs.media.m9.description1'} />
                        </i>
                    </b>
                </Typography>
                <br />
                <Typography variant='h6'>
                    <b>
                        <i>
                            <FormattedMessage id={'aboutUs.media.m9.description2'} />
                        </i>
                    </b>
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.sectionImage}>
                <a
                    href={'https://www.facebook.com/watch/live/?ref=watch_permalink&v=329145398984969&t=14836'}
                    target={'_blank'}
                >
                    <FindMoreButton textId={'aboutUs.media.m9.description.scaleUp'} />
                </a>
                <br />
                <a
                    href={'https://www.facebook.com/watch/live/?ref=watch_permalink&v=329145398984969&t=13108'}
                    target={'_blank'}
                >
                    <FindMoreButton textId={'aboutUs.media.m9.description.orange'} />
                </a>
                <br />
                <a
                    href={'https://www.facebook.com/watch/live/?ref=watch_permalink&v=329145398984969&t=4087'}
                    target={'_blank'}
                >
                    <FindMoreButton textId={'aboutUs.media.m9.description.pitch'} />
                </a>
            </Grid>
            <Divider variant={'middle'} style={{ width: '100%', marginTop: '2rem', marginBottom: '2rem' }} />
            <Grid item xs={12} sm={6}>
                <Typography variant='h3'>
                    <FormattedMessage id={'aboutUs.media.m10.place'} />
                </Typography>
                <Typography variant='h6'>
                    <FormattedMessage id={'aboutUs.media.m10.description'} />
                </Typography>
                <br />
                <Typography variant='h6'>
                    <b>
                        <i>
                            <FormattedMessage id={'aboutUs.media.m10.description1'} />
                        </i>
                    </b>
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.sectionImage}>
                <iframe
                    src='https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FTVRTimisoara%2Fvideos%2F1053505232153653%2F&show_text=false&width=560&t=236'
                    height='314'
                    width='100%'
                    scrolling='no'
                    frameBorder='0'
                    allowFullScreen={true}
                    allow='autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share'
                    className={classes.video}
                />
            </Grid>
        </Grid>
    );
};

export default AboutMedia;
