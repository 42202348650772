import {
    CHANGE_COLOR,
    CHANGE_TOOL,
    CHANGE_TOOL_WEIGHT,
    CHANGE_ERASER_WEIGHT,
    CHANGE_TEXT_WEIGHT,
} from '../actionTypes';
import { TOOL_PEN } from '../../constants';

const initialState = {
    color: '#000000',
    tool: TOOL_PEN,
    toolWeight: 1,
    textWeight: 1,
    eraserWeight: 5,
};

const drawing = function (state = initialState, action) {
    if (!action.payload) return state;
    switch (action.type) {
        case CHANGE_COLOR: {
            return {
                ...state,
                color: action.payload,
            };
        }
        case CHANGE_TOOL: {
            return {
                ...state,
                tool: action.payload,
            };
        }
        case CHANGE_TOOL_WEIGHT: {
            return {
                ...state,
                toolWeight: action.payload,
            };
        }
        case CHANGE_TEXT_WEIGHT: {
            return {
                ...state,
                textWeight: action.payload,
            };
        }
        case CHANGE_ERASER_WEIGHT: {
            return {
                ...state,
                eraserWeight: action.payload,
            };
        }
        default:
            return state;
    }
};

export default drawing;
