import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

import NotFound from '../pages/NotFound/NotFound';
import Contact from '../pages/Compliancy/Contact';
import PrivacyPolicy from '../pages/Compliancy/PrivacyPolicy';
import TermsOfUse from '../pages/Compliancy/TermsOfUse';
import Header from '../pages/Home/Header';
import Subscriptions from '../pages/Subscriptions/Subscriptions';
import ForTutoring from '../pages/ForTutoring/ForTutoring';
import Features from '../pages/Features/Features';
import Onboarding from '../pages/Onboarding/Onboarding';
import { CONTACT_US_LINK, LINK_WHITEBOARD, PROFILE_LINK, SPECIAL_QUOTA_LINK } from '../common/urlLinks';
import SpecialQuota from '../pages/Compliancy/SpecialQuota';
import HomeLanding from '../pages/Home/HomeLanding/HomeLanding';
import { COLORS2 } from '../constants';
import SubscriptionsFooter from '../MyComponents/Footer/SubscriptionsFooter';
import Footer from '../MyComponents/Footer/Footer';
import Tutorial from '../pages/Tutorial/Tutorial';
import { ResponsiveLoginModal } from '../pages/Home/HomeModals/ResponsiveLoginModal';
import ForSchools from '../pages/ForSchools/ForSchools';
import SignUpConsent from '../pages/Compliancy/SignUpConsent';
import { Unsubscribe } from '../pages/Unsubscribe/Unsubscribe';
import About from '../pages/About/About';
import Snackbar from '@material-ui/core/Snackbar';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Alert } from '@material-ui/lab';
import store from '../redux/store.js';
import { hideNotification } from '../redux/actions.js';
import LinkWhiteboard from '../pages/LinkWhiteboard/LinkWhiteboard';

const wrapperStyle = {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    overflow: 'hidden',
    backgroundColor: COLORS2.dawnBackground,
};


function UnauthenticatedApp({ language, updateLanguage, appState, intl }) {
    const [open, setOpen] = React.useState(false);
    const [openFromHeader, setOpenFromHeader] = React.useState(true);
    let history = useHistory();
    const handleOpen = (fromHeader = true) => {
        setOpen(true);
        setOpenFromHeader(fromHeader);
    };
    const handleClose = () => {
        setOpen(false);
        window.localStorage.removeItem('pathBeforeLogin');
    };

    const handleLogin = (target = '') => {
        if (target === 'linkWhiteboard') {
            history.push(LINK_WHITEBOARD);
            return;
        }
        if (window.location.host === 'localhost:3000') {
            window.location.assign('http://localhost:3000/login' + target);
        } else if (window.location.host === '192.168.2.112:3000' + target) {
            window.location.assign('http://192.168.2.112:3000/login' + target);
        } else {
            if (target === '/microsoft' && window.isTeams) {
                window.microsoftTeams.getContext(() => {
                    window.microsoftTeams.authentication.authenticate({
                        successCallback: () => {
                            window.location.reload();
                        },
                        failureCallback: () => {
                        },
                        url: window.location.protocol + '//' + window.location.host + '/login/microsoft-teams',
                    });
                });
            } else {
                window.location.assign(window.location.protocol + '//' + window.location.host + '/login' + target);
            }
        }
    };

    const handleCloseNotif = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        store.dispatch(hideNotification());
    };

    let notifMessage = '';
    if (appState.notificationMessage) {
        if (typeof appState.notificationMessage === 'string') {
            notifMessage = intl.formatMessage({
                id: 'notification.code.' + appState.notificationMessage,
            });
        } else {
            notifMessage = intl.formatMessage(
                {
                    id: 'notification.code.' + appState.notificationMessage.messageKey,
                },
                appState.notificationMessage.data,
            );
        }
    }
    return (
        <div style={wrapperStyle}>
            {appState.isNotificationOpen ? (
                <Snackbar
                    open={appState.isNotificationOpen}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Alert onClose={handleCloseNotif} severity={appState.notificationType}>
                        {notifMessage}
                    </Alert>
                </Snackbar>
            ) : null}

            <Grid container direction='column' spacing={2}>
                <Header updateLanguage={updateLanguage} handleOpen={handleOpen} language={language} />
                <Switch>
                    <Route exact={true} path={['/', '/en', '/fr', '/ro']}>
                        <HomeLanding
                            handleOpen={handleOpen}
                            open={open}
                            handleLogin={handleLogin}
                            language={language}
                        />
                        <Footer language={language} handleOpen={handleOpen} />
                    </Route>
                    <Route exact={true} path={CONTACT_US_LINK}>
                        <Contact />
                        <Footer language={language} handleOpen={handleOpen} />
                    </Route>
                    <Route exact={true} path={SPECIAL_QUOTA_LINK}>
                        <SpecialQuota />
                        <Footer language={language} handleOpen={handleOpen} />
                    </Route>

                    <Route exact={true} path='/privacypolicy'>
                        <PrivacyPolicy />
                        <Footer language={language} handleOpen={handleOpen} />
                    </Route>
                    <Route exact={true} path='/terms'>
                        <TermsOfUse />
                        <Footer language={language} handleOpen={handleOpen} />
                    </Route>
                    <Route exact={true} path={['/ro/tutorial', '/fr/tutorial', '/en/tutorial', '/tutorial']}>
                        <Tutorial language={language} />
                        <Footer language={language} handleOpen={handleOpen} />
                    </Route>
                    <Route
                        exact={true}
                        path={['/subscriptions', '/ro/subscriptions', '/fr/subscriptions', '/en/subscriptions']}
                    >
                        <Subscriptions
                            language={language}
                            handleOpen={handleOpen}
                            open={open}
                            handleLogin={handleLogin}
                        />
                        <SubscriptionsFooter
                            language={language}
                            open={open}
                            handleLogin={handleLogin}
                            handleOpen={handleOpen}
                        />
                    </Route>
                    <Route path={['/features', '/ro/features', '/fr/features', '/en/features']}>
                        <Features />
                        <Footer handleOpen={handleOpen} />
                    </Route>
                    <Route exact={true} path={['/ro/tutoring', '/fr/tutoring', '/en/tutoring', '/tutoring']}>
                        <ForTutoring
                            language={language}
                            open={open}
                            handleOpen={handleOpen}
                            handleLogin={handleLogin}
                        />
                        <Footer language={language} handleOpen={handleOpen} />
                    </Route>
                    <Route
                        exact={false}
                        path={[
                            '/ro/about/:aboutValue',
                            '/fr/about/:aboutValue',
                            '/en/about/:aboutValue',
                            '/about/:aboutValue',
                            '/ro/about',
                            '/fr/about',
                            '/en/about',
                            '/about',
                        ]}
                    >
                        <About />
                        <Footer language={language} handleOpen={handleOpen} />
                    </Route>
                    <Route exact={true} path={['/ro/schools', '/fr/schools', '/en/schools', '/schools']}>
                        <ForSchools language={language} open={open} handleOpen={handleOpen} handleLogin={handleLogin} />
                        <Footer language={language} handleOpen={handleOpen} />
                    </Route>

                    <Route exact={true} path='/onboarding'>
                        <Onboarding
                            handleLogin={handleLogin}
                            open={open}
                            handleOpen={handleOpen}
                            setOpen={setOpen}
                            handleClose={handleClose}
                            language={language}
                        />
                        <SubscriptionsFooter open={open} handleOpen={handleOpen} />
                    </Route>
                    <Route exact={true} path={LINK_WHITEBOARD}>
                        <LinkWhiteboard />
                        <Footer language={language} handleOpen={handleOpen} />
                    </Route>

                    <Route path='/signup_consent' component={SignUpConsent} />
                    <Route path='/unsubscribe/:token' component={Unsubscribe} />

                    <Route exact={true} path='*'>
                        <NotFound />
                    </Route>
                </Switch>
                <ResponsiveLoginModal
                    open={open}
                    handleClose={handleClose}
                    handleLogin={handleLogin}
                    fromHeader={openFromHeader}
                />
            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => {
    const { appState } = state;
    return { appState };
};

export default injectIntl(connect(mapStateToProps)(UnauthenticatedApp));