import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function DescriptionListIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg viewBox="0 0 20 16" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 2C5 0.896875 5.89687 0 7 0H18C19.1031 0 20 0.896875 20 2V11C20 12.1031 19.1031 13 18 13H10.525C10.1562 12.2031 9.59062 11.5156 8.8875 11H12V10C12 9.44687 12.4469 9 13 9H15C15.5531 9 16 9.44687 16 10V11H18V2H7V3.53437C6.4125 3.19375 5.72813 3 5 3V2ZM5 10C3.34375 10 2 8.65625 2 7C2 5.34375 3.34375 4 5 4C6.65625 4 8 5.34375 8 7C8 8.65625 6.65625 10 5 10ZM4.16563 11H5.83125C8.13437 11 10 12.8656 10 15.1656C10 15.625 9.62812 16 9.16562 16H0.834375C0.371875 16 0 15.6281 0 15.1656C0 12.8656 1.86563 11 4.16563 11Z" />
            </svg>
        </SvgIcon>
    );
}
