import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function BottomList(props) {
    return (
        <SvgIcon {...props} style={{ ...props.style, verticalAlign: 'bottom' }}>
            <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 32 32"
                enableBackground="new 0 0 32 32"
                // opacity="1"
                // fillOpacity="0"
                // strokeWidth="1"
                // strokeOpacity="1"
                // stroke="#000000"
                fill="currentColor"
            >
                <path d="M9.43 23.19C9.82 23.19 10.14 23.51 10.14 23.9C10.14 24.96 10.14 27.42 10.14 28.48C10.14 28.87 9.82 29.19 9.43 29.19C7.97 29.19 4.31 29.19 2.85 29.19C2.46 29.19 2.14 28.87 2.14 28.48C2.14 27.42 2.14 24.96 2.14 23.9C2.14 23.51 2.46 23.19 2.85 23.19C4.31 23.19 7.97 23.19 9.43 23.19Z" />
                <path d="M26.79 2.1C27.19 2.1 27.5 2.42 27.5 2.81C27.5 5.94 27.5 14.6 27.5 17.72C27.5 18.11 27.19 18.43 26.79 18.43C22.42 18.43 10.01 18.43 5.64 18.43C5.24 18.43 4.93 18.11 4.93 17.72C4.93 14.6 4.93 5.94 4.93 2.81C4.93 2.42 5.24 2.1 5.64 2.1C10.01 2.1 22.42 2.1 26.79 2.1Z" />
                <path d="M19.94 23.19C20.33 23.19 20.65 23.51 20.65 23.9C20.65 24.96 20.65 27.42 20.65 28.48C20.65 28.87 20.33 29.19 19.94 29.19C18.48 29.19 14.82 29.19 13.36 29.19C12.97 29.19 12.65 28.87 12.65 28.48C12.65 27.42 12.65 24.96 12.65 23.9C12.65 23.51 12.97 23.19 13.36 23.19C14.82 23.19 18.48 23.19 19.94 23.19Z" />
                <path
                    d="M30.28 23.19C30.67 23.19 30.99 23.51 30.99 23.9C30.99 24.96 30.99 27.42 30.99 28.48C30.99 28.87 30.67 29.19 30.28 29.19C28.82 29.19 25.16 29.19 23.7 29.19C23.3 29.19 22.99 28.87 22.99 28.48C22.99 27.42 22.99 24.96 22.99 23.9C22.99 23.51 23.3 23.19 23.7 23.19C25.16 23.19 28.82 23.19 30.28 23.19Z"
                    fillOpacity="0.75"
                />
            </svg>
        </SvgIcon>
    );
}
