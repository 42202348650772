import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { Grid, LinearProgress, Link } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import { hideSpinner } from '../../redux/actions';
import store from '../../redux/store';
import MigrationDialog from '../Migration/MigrationDialog';

let styleWithInfo = {
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    borderRadius: '4px',
    background: 'white',
    width: '80%',
    textAlign: 'center',
};
let styleWithoutInfo = {
    textAlign: 'center',
};

const SpinnerContentRenderer = ({ content }) => {
    if (!content) {
        return (
            <>
                <CircularProgress color="primary" style={{ width: '10rem', height: '10rem' }} />
            </>
        );
    }
    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={
                content.messageKey || content.descriptionKey || content.showProgress || content.showHome
                    ? styleWithInfo
                    : styleWithoutInfo
            }
        >
            <Grid item xs={12} sm={12}>
                {content.messageKey ? (
                    <Typography variant="h2">
                        <FormattedMessage id={content.messageKey} />
                    </Typography>
                ) : null}
            </Grid>
            <Grid item xs={12} sm={12}>
                {content.descriptionKey ? (
                    <Typography variant="h4">
                        <FormattedMessage id={content.descriptionKey} />
                    </Typography>
                ) : null}
            </Grid>
            <Grid item xs={12} sm={12}>
                {content.showSpinner ? <CircularProgress color="primary" /> : null}
            </Grid>
            <Grid item xs={12} sm={12}>
                {content.showProgress ? (
                    <LinearProgress variant="determinate" value={content.progressValue} style={{ margin: '1rem' }} />
                ) : null}
            </Grid>
            <Grid item xs={12} sm={12}>
                {content.showHome ? (
                    <Link
                        to="/"
                        component={RouterLink}
                        onClick={() => {
                            store.dispatch(hideSpinner());
                        }}
                        color={'primary'}
                    >
                        <Typography variant="h4" color={'primary'}>
                            <FormattedMessage id="redesign.goBackToGroups" />
                        </Typography>
                    </Link>
                ) : null}
            </Grid>
            <Grid item xs={12} sm={12}>
                {content.showCloudMigration ? <MigrationDialog data={content.showCloudMigration} /> : null}
            </Grid>
        </Grid>
    );
};

export default SpinnerContentRenderer;
