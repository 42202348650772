import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function CreateDirectoryOutlinedIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 3H8.5L7.08437 1.58594C6.70937 1.21062 6.20312 1 5.67188 1H2C0.895625 1 0 1.89531 0 3V13C0 14.1047 0.895625 15 2 15H14C15.1044 15 16 14.1047 16 13V5C16 3.89687 15.1031 3 14 3ZM15 13C15 13.5513 14.5513 14 14 14H2C1.44875 14 1 13.5513 1 13V3C1 2.44875 1.44875 2 2 2H5.67188C5.93897 2 6.19 2.104 6.37875 2.29297L8.08438 4H14C14.5513 4 15 4.44875 15 5V13ZM10.5 8.5H8.5V6.47188C8.5 6.225 8.275 6 8 6C7.725 6 7.5 6.225 7.5 6.47188V8.5H5.5C5.225 8.5 5 8.725 5 9C5 9.27606 5.22363 9.47188 5.5 9.47188H7.5V11.4719C7.5 11.775 7.725 12 8 12C8.27612 12 8.5 11.7762 8.5 11.5281V9.47188H10.5C10.775 9.47188 11 9.275 11 9C11 8.725 10.775 8.5 10.5 8.5Z" fill="#08AAB4"/>
            </svg>
        </SvgIcon>
    );
}
