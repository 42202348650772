import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function EmailIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                viewBox="0 0 16 16"
                xmlSpace="preserve"
            >
                <path fill="#ECEFF1" d="M2 2h12v12H2z" />
                <path fill="#CFD8DC" d="M8 9.262L14 14V4.646z" />
                <path
                    d="M14.5 2H14L8 6.738 2 2h-.5A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14H2V4.646l6 4.615 6-4.616V14h.5a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2z"
                    fill="#F44336"
                />
            </svg>{' '}
        </SvgIcon>
    );
}
