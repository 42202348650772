import React, { useEffect, useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { FormattedMessage, injectIntl } from 'react-intl';
import classApi from '../../Api/classApi';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import {
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    TextField,
    Typography,
} from '@material-ui/core';
import { AddCircleOutline, Close, Delete, KeyboardArrowRight } from '@material-ui/icons';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import GenericState from '../../MyComponents/GenericLoadingErrorState/GenericState';
import { GA_EVENT_TREE } from '../../gaConstants';
import { connect } from 'react-redux';
import userApi from '../../Api/userApi';
import store from '../../redux/store';
import { showError, updateAllowedGroups, upgradeSubscriptionDialog } from '../../redux/actions';
import { canIncreaseLimitedData, CAPABILITIES } from '../../shared/Capabilities';

const useStyles = makeStyles((theme) => ({
    table: {
        // minWidth: 650,
    },
    container: { width: '100vw' },
    button: { marginLeft: 'auto' },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'absolute',
        width: 450,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    disableRow: {
        background: 'rgba(210, 104, 40, 0.5)',
    },
    enableRow: {},
}));

const TeamsConfig = ({ intl, allowedGroups, subscriptions, profile }) => {
    let history = useHistory();
    const classes = useStyles();
    const [data, setData] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [isError, setIsError] = React.useState(false);
    const [modalItem, setModalItem] = React.useState(null);
    const [canCreate, setCanCreate] = React.useState(null);
    const [selectedId, setSelectedId] = React.useState(null);
    const [name, setName] = useState('');

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleKeyPress = (event) => {
        if (event.keyCode === 13) {
            if (name.trim().length > 0) {
                createGroup();
            }
        }
    };

    useEffect(() => {
        if (subscriptions && profile && allowedGroups) {
            canIncreaseLimitedData(
                subscriptions,
                profile.subscription,
                CAPABILITIES.GROUP_COUNT,
                Promise.resolve(data.length)
            ).then((res) => {
                setCanCreate(res);
            });
        } else {
            setCanCreate({ value: true });
        }
    }, [subscriptions, profile, data]);

    const showModal = (item) => {
        setModalItem(item);
    };

    const hideModal = () => {
        setModalItem(null);
    };

    useEffect(() => {
        loadClasses();
    }, []);

    const loadClasses = () => {
        setIsLoading(true);
        userApi
            .getAllowedGroups()
            .then((response) => {
                store.dispatch(updateAllowedGroups(response.data.groupIds));
            })
            .catch((err) => {
                console.error('unable to get allowed groups', err);
            });
        classApi
            .getMyClasses()
            .then((res) => {
                setData(res.data);
                window.logAppActivity(GA_EVENT_TREE.manageGroups.actions.load, res.data.length);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
                setIsError(true);
            });
    };

    let lessonStart = function (classId) {
        window.logAppActivity(GA_EVENT_TREE.manageGroups.actions.start);
        history.push('/lesson/' + classId);
    };

    let editClass = function (classId) {
        window.logAppActivity(GA_EVENT_TREE.manageGroups.actions.edit);
        history.push('/class/' + classId);
    };

    let deleteClass = function () {
        window.logAppActivity(GA_EVENT_TREE.manageGroups.actions.delete);
        setIsLoading(true);
        hideModal();
        classApi
            .deleteClass(modalItem.id)
            .then(() => {
                loadClasses();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const createGroup = () => {
        if (name.trim().length > 0) {
            classApi
                .createGroup({ name, description: '' })
                .then(loadClasses)
                .catch((err) => {
                    if (err.response.status === 401) return;
                    if (err.response.status === 403 && err.response.data?.availableSubscriptions) {
                        store.dispatch(
                            upgradeSubscriptionDialog({
                                key: 'subscription.upsell.groups.exceeded',
                                subscriptions: err.response.data?.availableSubscriptions,
                            })
                        );
                        history.replace('/manageClasses');
                    } else {
                        store.dispatch(showError('GENERIC_ERROR'));
                        console.error(err);
                    }
                });
        }
    };

    if (isLoading) {
        return <GenericState isLoading={true} />;
    }

    if (isError) {
        return <GenericState isError={true} />;
    }

    return (
        <>
            <CssBaseline />
            <Container>
                <div style={{ paddingTop: '3rem' }} />
                {data.length === 0 ? (
                    <div className="u-fx u-fx-align-center u-mb-full">
                        <>
                            <TextField
                                variant="outlined"
                                label={intl.formatMessage({ id: 'createGroup.className' })}
                                value={name}
                                onChange={handleNameChange}
                                onKeyUp={handleKeyPress}
                                required
                                style={{ flexGrow: '1', maxWidth: '300px' }}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={createGroup}
                                className={classes.button}
                                startIcon={<AddCircleOutline />}
                                size="large"
                            >
                                <FormattedMessage id="createGroup.submit" />
                            </Button>
                        </>
                    </div>
                ) : (
                    <>
                        <Typography variant="h2">
                            <FormattedMessage id="menu.manageClass" />
                        </Typography>
                        <TableContainer component={Paper} style={{ width: '100%', marginBottom: '1.5rem' }}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <FormattedMessage id={'generic.label.createdAt'} />
                                        </TableCell>
                                        <TableCell>
                                            <FormattedMessage id={'generic.label.name'} />
                                        </TableCell>
                                        <TableCell align="right">
                                            <FormattedMessage id={'generic.label.select'} />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((row) => (
                                        <TableRow
                                            key={row.id}
                                            className={
                                                !allowedGroups || (allowedGroups && allowedGroups.includes(row.id))
                                                    ? classes.enableRow
                                                    : classes.disableRow
                                            }
                                        >
                                            <TableCell component="th" scope="row">
                                                <span>{new Date(row.createdAt).toLocaleString()}</span>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <span>{row.name}</span>
                                                {row.requests != '0' ? (
                                                    <span style={{ marginLeft: '10px' }}>
                                                        <Chip
                                                            avatar={<Avatar>{row.requests}</Avatar>}
                                                            label={intl.formatMessage({
                                                                id: 'generic.label.requests',
                                                            })}
                                                            clickable
                                                            onClick={() => {
                                                                editClass(row.id);
                                                            }}
                                                            color="primary"
                                                        />
                                                    </span>
                                                ) : null}
                                            </TableCell>
                                            <TableCell align="right">
                                                <Button
                                                    variant="contained"
                                                    color={selectedId === row.id ? 'primary' : 'default'}
                                                    onClick={() => {
                                                        setSelectedId(row.id);
                                                        window.microsoftTeams.settings.setValidityState(true);
                                                        window.setTeamsLesson(row.id, row.name);
                                                    }}
                                                    startIcon={<KeyboardArrowRight />}
                                                >
                                                    <FormattedMessage id={'generic.label.select'} />
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                )}

                <Dialog
                    open={!!modalItem}
                    onClose={hideModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    // disableBackdropClick
                    // disableEscapeKeyDown
                    fullWidth
                >
                    <DialogTitle id="alert-dialog-title">
                        <Typography>
                            <FormattedMessage id="updateClass.deleteConfirmationTitle" />
                        </Typography>
                        <IconButton aria-label="close" className={classes.closeButton} onClick={hideModal}>
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <FormattedMessage id={'updateClass.deleteConfirmationMessage'} />{' '}
                            {modalItem ? modalItem.name : ''} ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={hideModal} size="large" variant="contained" color="default">
                            <FormattedMessage id="close" />
                        </Button>
                        <Button
                            onClick={deleteClass}
                            color="secondary"
                            autoFocus
                            variant="contained"
                            startIcon={<Delete />}
                        >
                            <FormattedMessage id="generic.label.delete" />
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </>
    );
};

const mapStateToProps = (state) => {
    const { profile, mainAppState } = state;
    return {
        allowedGroups: profile.allowedGroups,
        profile,
        subscriptions: mainAppState.subscriptions,
        pricing: mainAppState.pricing,
    };
};
export default injectIntl(connect(mapStateToProps)(TeamsConfig));
