import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function GridList(props) {
    return (
        <SvgIcon {...props} style={{ verticalAlign: 'bottom' }}>
            <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 32 32"
                enableBackground="new 0 0 32 32"
                // opacity="1"
                // fillOpacity="0"
                // strokeWidth="1"
                // strokeOpacity="1"
                // stroke="#000000"
                fill="currentColor"
            >
                <path d="M9.43 23.19C9.82 23.19 10.14 23.51 10.14 23.9C10.14 24.96 10.14 27.42 10.14 28.48C10.14 28.87 9.82 29.19 9.43 29.19C7.97 29.19 4.31 29.19 2.85 29.19C2.46 29.19 2.14 28.87 2.14 28.48C2.14 27.42 2.14 24.96 2.14 23.9C2.14 23.51 2.46 23.19 2.85 23.19C4.31 23.19 7.97 23.19 9.43 23.19Z" />
                <path
                    d="M26.79 2.1C27.19 2.1 27.5 2.42 27.5 2.81C27.5 5.94 27.5 14.6 27.5 17.72C27.5 18.11 27.19 18.43 26.79 18.43C22.42 18.43 10.01 18.43 5.64 18.43C5.24 18.43 4.93 18.11 4.93 17.72C4.93 14.6 4.93 5.94 4.93 2.81C4.93 2.42 5.24 2.1 5.64 2.1C10.01 2.1 22.42 2.1 26.79 2.1Z"
                    fillOpacity="0.75"
                />
                <path d="M19.94 23.19C20.33 23.19 20.65 23.51 20.65 23.9C20.65 24.96 20.65 27.42 20.65 28.48C20.65 28.87 20.33 29.19 19.94 29.19C18.48 29.19 14.82 29.19 13.36 29.19C12.97 29.19 12.65 28.87 12.65 28.48C12.65 27.42 12.65 24.96 12.65 23.9C12.65 23.51 12.97 23.19 13.36 23.19C14.82 23.19 18.48 23.19 19.94 23.19Z" />
                <path d="M30.28 23.19C30.67 23.19 30.99 23.51 30.99 23.9C30.99 24.96 30.99 27.42 30.99 28.48C30.99 28.87 30.67 29.19 30.28 29.19C28.82 29.19 25.16 29.19 23.7 29.19C23.3 29.19 22.99 28.87 22.99 28.48C22.99 27.42 22.99 24.96 22.99 23.9C22.99 23.51 23.3 23.19 23.7 23.19C25.16 23.19 28.82 23.19 30.28 23.19Z" />
                <path d="M9.43 10C9.82 10 10.14 10.32 10.14 10.71C10.14 11.77 10.14 14.23 10.14 15.29C10.14 15.68 9.82 16 9.43 16C7.97 16 4.31 16 2.85 16C2.46 16 2.14 15.68 2.14 15.29C2.14 14.23 2.14 11.77 2.14 10.71C2.14 10.32 2.46 10 2.85 10C4.31 10 7.97 10 9.43 10Z" />
                <path d="M19.94 10C20.33 10 20.65 10.32 20.65 10.71C20.65 11.77 20.65 14.23 20.65 15.29C20.65 15.68 20.33 16 19.94 16C18.48 16 14.82 16 13.36 16C12.97 16 12.65 15.68 12.65 15.29C12.65 14.23 12.65 11.77 12.65 10.71C12.65 10.32 12.97 10 13.36 10C14.82 10 18.48 10 19.94 10Z" />
                <path d="M30.28 10C30.67 10 30.99 10.32 30.99 10.71C30.99 11.77 30.99 14.23 30.99 15.29C30.99 15.68 30.67 16 30.28 16C28.82 16 25.16 16 23.7 16C23.3 16 22.99 15.68 22.99 15.29C22.99 14.23 22.99 11.77 22.99 10.71C22.99 10.32 23.3 10 23.7 10C25.16 10 28.82 10 30.28 10Z" />
            </svg>
        </SvgIcon>
    );
}
