import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function RemoveIcon(props) {
    return (
        <SvgIcon {...props} style={{ verticalAlign: 'bottom' }}>
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.58984 0.992969C7.22266 0.360156 8.08047 0.00390625 8.97578 0.00390625H15.0227C15.918 0.00390625 16.7758 0.360156 17.4086 0.992969L23.0055 6.58984C23.6383 7.22266 23.9945 8.08047 23.9945 8.97578V15.0227C23.9945 15.918 23.6383 16.7758 23.0055 17.4086L17.4086 23.0055C16.7758 23.6383 15.918 23.9945 15.0227 23.9945H8.97578C8.08047 23.9945 7.22266 23.6383 6.58984 23.0055L0.992969 17.4086C0.360156 16.7758 0.00390625 15.918 0.00390625 15.0227V8.97578C0.00390625 8.08047 0.360156 7.22266 0.992969 6.58984L6.58984 0.992969ZM8.62422 10.8742C8.00078 10.8742 7.49922 11.3758 7.49922 11.9992C7.49922 12.6227 8.00078 13.1242 8.62422 13.1242H15.3742C15.9977 13.1242 16.4992 12.6227 16.4992 11.9992C16.4992 11.3758 15.9977 10.8742 15.3742 10.8742H8.62422Z" fill="#F9A237"/>
            </svg>

        </SvgIcon>
    );
}
