import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function RaiseHandIcon(props) {
    return (
        <SvgIcon {...props} style={{ verticalAlign: 'bottom' }}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                enableBackground="new 0 0 90.305 204.057"
                viewBox="0 0 90.305 204.057"
            >
                <path
                    d="M5.175,42.591c0.146,1.017,0.55,1.98,1.174,2.796l21.904,28.626v34.335v16.203v69.131c0,5.729,4.645,10.375,10.378,10.375
		c5.727,0,10.373-4.646,10.373-10.375v-53.509h4.113v53.509c0,5.729,4.646,10.375,10.377,10.375c5.729,0,10.375-4.646,10.375-10.375
		v-69.131v-16.203V83.914c2.391,7.2,4.706,20.884,4.409,46.857c-0.038,3.313,2.618,6.03,5.931,6.068c0.023,0,0.046,0,0.07,0
		c3.281,0,5.96-2.642,5.998-5.932c0.386-33.789-3.398-54.231-11.57-62.498c-2.733-2.764-6.044-4.293-9.608-4.483
		c-0.397-0.078-0.808-0.121-1.23-0.121H35.554L16.832,39.34L11.94,5.152c-0.47-3.28-3.504-5.564-6.79-5.089
		c-3.28,0.469-5.559,3.509-5.089,6.79L5.175,42.591z"
                />
                <circle cx="51.062" cy="39.806" r="20.5" />
            </svg>
        </SvgIcon>
    );
}
