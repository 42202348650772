import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../constants';

const useStyles = makeStyles((theme) => ({
    ruler: {
        position: 'relative',
        width: '100%',
        borderTop: '1px solid #555',
        height: '64px',
        backgroundImage: `linear-gradient(${COLORS.primaryD1 + '66'}, #ffff0000);`,
    },
    cm: {
        position: 'absolute',
        borderLeft: '1px solid #555',
        height: '16px',
        width: '10%',
        '&:after': {
            position: 'absolute',
            bottom: '-15px',
            font: '11px/1 sans-serif',
        },
    },
    mm: {
        position: 'absolute',
        borderLeft: '1px solid #555',
        height: '10px',
        width: '5px',
        '&:nth-of-type(5)': {
            height: '14px',
        },
    },
}));

export default function RulerDIV() {
    const classes = useStyles();
    let cms = [];
    for (let i = 0; i < 10; i++) {
        cms.push(
            <div className={classes.cm} style={{ left: (i * 100) / 10 + '%' }} key={`ruler-cm-${i}`}>
                <span style={{ position: 'absolute', top: '14px' }}>{i}</span>
                <div className={classes.mm} style={{ left: '10%' }}></div>
                <div className={classes.mm} style={{ left: '20%' }}></div>
                <div className={classes.mm} style={{ left: '30%' }}></div>
                <div className={classes.mm} style={{ left: '40%' }}></div>
                <div className={classes.mm} style={{ left: '50%' }}></div>
                <div className={classes.mm} style={{ left: '60%' }}></div>
                <div className={classes.mm} style={{ left: '70%' }}></div>
                <div className={classes.mm} style={{ left: '80%' }}></div>
                <div className={classes.mm} style={{ left: '90%' }}></div>
            </div>
        );
    }
    return (
        <div className={classes.ruler}>
            {cms}
            <div className={classes.cm} style={{ right: '0px', borderRight: '1px solid #555' }}>
                {/*<span style={{ position: 'absolute', top: '14px', right: '-10px' }}>15</span>*/}
            </div>
        </div>
    );
}
