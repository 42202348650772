export const sections = [
    // {
    //     id: 'section-lessonStart',
    //     nameId: 'tutorial.lessonStart.title',
    //     childrenVisible: true,
    //     children: [
    //         {
    //             id: 'section-firstPage',
    //             nameId: 'tutorial.lessonStart.firstPage',
    //         },
    //         {
    //             id: 'section-myGroups',
    //             nameId: 'tutorial.lessonStart.myGroups',
    //         },
    //         {
    //             id: 'section-enterGroup',
    //             nameId: 'tutorial.lessonStart.enterGroup',
    //         },
    //     ],
    // },
    // {
    //     id: 'section-shareLesson',
    //     nameId: 'tutorial.shareLesson.title',
    //     childrenVisible: true,
    //     children: [
    //         {
    //             id: 'section-shareLesson-firstPage',
    //             nameId: 'tutorial.lessonStart.firstPage',
    //         },
    //         {
    //             id: 'section-shareLesson-myGroups',
    //             nameId: 'tutorial.lessonStart.myGroups',
    //         },
    //         {
    //             id: 'section-shareLesson-virtualBoard',
    //             nameId: 'tutorial.shareLesson.virtualBoard',
    //         },
    //     ],
    // },
    {
        id: 'section-manageGroups',
        nameId: 'tutorial.manageGroups.title',
        childrenVisible: true,
        children: [
            {
                id: 'section-manageGroups-create',
                nameId: 'tutorial.manageGroups.createGroup',
            },
            {
                id: 'section-myGroups',
                nameId: 'tutorial.manageGroups.page',
            },
            {
                id: 'section-manageGroups-edit',
                nameId: 'tutorial.manageGroups.edit',
            },
        ],
    },
    // {
    //     id: 'section-invites',
    //     nameId: 'tutorial.invites.title',
    // },
    {
        id: 'section-manageLesson',
        nameId: 'tutorial.manageLesson.title',
        childrenVisible: true,
        children: [
            {
                id: 'section-manageLesson-audio',
                nameId: 'tutorial.manageLesson.audio.title',
            },
            {
                id: 'section-manageLesson-mainMenu',
                nameId: 'tutorial.manageLesson.mainMenu.title',
            },
            {
                id: 'section-manageLesson-tools',
                nameId: 'tutorial.manageLesson.tools.title',
            },
            {
                id: 'section-manageLesson-geometry',
                nameId: 'redesign.page.tutorial.manageLesson.geometry.title',
            },
            {
                id: 'section-manageLesson-mathConvertorManagement',
                nameId: 'redesign.page.tutorial.manageLesson.mathConvertorManagement.title',
            },
            {
                id: 'section-manageLesson-imageMenu',
                nameId: 'tutorial.manageLesson.imageMenu.title',
            },
            {
                id: 'section-manageLesson-rulingMenu',
                nameId: 'tutorial.manageLesson.rulingMenu.title',
            },
            {
                id: 'section-manageLesson-boardMenu',
                nameId: 'tutorial.manageLesson.boardMenu.title',
            },
            {
                id: 'section-manageLesson-zoom',
                nameId: 'tutorial.manageLesson.zoom.title',
            },
        ],
    },
    {
        id: 'section-manageSplitBoardLesson',
        nameId: 'tutorial.manageSplitBoardLesson.title',
        childrenVisible: true,
        children: [
            {
                id: 'section-manageSplitBoardLesson-start',
                nameId: 'tutorial.manageSplitBoardLesson.start.title',
            },
            {
                id: 'section-manageSplitBoardLesson-mainDisplay',
                nameId: 'tutorial.manageSplitBoardLesson.mainDisplay.title',
            },
            {
                id: 'section-manageSplitBoardLesson-singleBoard',
                nameId: 'tutorial.manageSplitBoardLesson.singleBoard.title',
            },
            {
                id: 'section-manageSplitBoardLesson-close',
                nameId: 'tutorial.manageSplitBoardLesson.close.title',
            },
            {
                id: 'section-manageSplitBoardLesson-reviewPast',
                nameId: 'tutorial.manageSplitBoardLesson.reviewPast.title',
            },
        ],
    },
    {
        id: 'section-manageLibrary',
        nameId: 'tutorial.manageLibrary.title',
        childrenVisible: true,
        children: [
            {
                id: 'section-manageLibrary-addToLibrary',
                nameId: 'tutorial.manageLibrary.addToLibrary.title',
            },
            {
                id: 'section-manageLibrary-savingToLibrary',
                nameId: 'tutorial.manageLibrary.savingToLibrary.title',
            },
            {
                id: 'section-manageLibrary-openFromLibrary',
                nameId: 'tutorial.manageLibrary.openFromLibrary.title',
            },
            {
                id: 'section-manageLibrary-manageLibrary',
                nameId: 'tutorial.manageLibrary.manageLibrary.title',
            },
            {
                id: 'section-shareLibrary-shareLibrary',
                nameId: 'tutorial.shareLibrary.shareLibrary.title',
            },
        ],
    },

    {
        id: 'section-optimization',
        nameId: 'tutorial.optimization.title',
        childrenVisible: false,
        children: [
            {
                id: 'section-optimization-description',
            },
        ],
    },
];

export const YOUTUBE_CLIPS = {
    GROUPS: {
        EN: 'https://www.youtube.com/embed/aekmNisSJoc',
        FR: 'https://www.youtube.com/embed/O-Sc7XOCYAI',
        RO: 'https://www.youtube.com/embed/MKRV4i4QvaA',
    },
    TUTORING: {
        EN: 'https://www.youtube.com/embed/zSGWi8qTul0',
        FR: 'https://www.youtube.com/embed/3J772gGwR20',
        RO: 'https://www.youtube.com/embed/_J-uypaFgQo',
    },
    ZOOM: {
        EN: 'https://www.youtube.com/embed/BJgGxyGLmbU',
        FR: 'https://www.youtube.com/embed/phitfX6oe0k',
        RO: 'https://www.youtube.com/embed/KSY5igkf9lo',
    },
    LIBRARY: {
        EN: 'https://www.youtube.com/embed/mKZfcpc7bJM',
        FR: 'https://www.youtube.com/embed/kCz7GvINKYg',
        RO: 'https://www.youtube.com/embed/wXuNTrX7rHA',
    },
    AUDIO_VIDEO: {
        EN: 'https://www.youtube.com/embed/9ig2GlE6CRI',
        FR: 'https://www.youtube.com/embed/NJx6PJB-z-E',
        RO: 'https://www.youtube.com/embed/IgOzVry-fAM',
    },
}; 
