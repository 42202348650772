import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import store from '../../redux/store';
import { shareLesson } from '../../redux/actions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import ShareBox from '../../common/components/ShareBox';
import { DialogTitle, IconButton, makeStyles, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

const ShareDialog = (props) => {
    const classes = useStyles();
    const close = () => {
        store.dispatch(shareLesson(null));
    };

    return (
        <Dialog open={!!props.appState.sharedLesson} onClose={close}>
            <DialogTitle disableTypography id="alert-dialog-title">
                <Typography variant="h4">
                    <FormattedMessage id="generic.label.shareLesson" />
                </Typography>
                <IconButton aria-label="close" className={classes.closeButton} onClick={close}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent style={{ overflow: 'auto' }}>
                {props.appState.sharedLesson ? <ShareBox lessonId={props.appState.sharedLesson.id} /> : null}
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center', marginBottom: '0.5rem' }}>
                <Button onClick={close} size="large" variant="contained" color="default">
                    <FormattedMessage id="close" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    const { appState } = state;
    return { appState };
};

export default injectIntl(connect(mapStateToProps)(ShareDialog));
