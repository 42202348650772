import apiUtil from './apiUtil';

let joinRequestApi = {};

joinRequestApi.joinClass = function (classId) {
    let myData = {
        url: '/api/private/join/' + classId,
        method: 'GET',
    };
    return apiUtil.runRequest(myData);
};

joinRequestApi.cancelJoinClass = function (classId) {
    let myData = {
        url: '/api/private/cancel/' + classId,
        method: 'GET',
    };
    return apiUtil.runRequest(myData);
};

joinRequestApi.getJoinRequestsForClass = function (classId, statusCode) {
    let myData = {
        url: '/api/private/joinrequestsforclass/' + classId + '/' + statusCode,
        method: 'GET',
    };
    return apiUtil.runRequest(myData);
};

joinRequestApi.acceptJoinRequest = function (requestId) {
    let myData = {
        url: '/api/private/joinrequest/targetop/accept/' + requestId,
        method: 'GET',
    };
    return apiUtil.runRequest(myData);
};

joinRequestApi.rejectJoinRequest = function (requestId) {
    let myData = {
        url: '/api/private/joinrequest/targetop/reject/' + requestId,
        method: 'GET',
    };
    return apiUtil.runRequest(myData);
};

joinRequestApi.blockJoinRequest = function (requestId) {
    let myData = {
        url: '/api/private/joinrequest/targetop/block/' + requestId,
        method: 'GET',
    };
    return apiUtil.runRequest(myData);
};

joinRequestApi.hideTargetJoinRequest = function (requestId) {
    let myData = {
        url: '/api/private/joinrequest/targetop/hide/' + requestId,
        method: 'GET',
    };
    return apiUtil.runRequest(myData);
};

joinRequestApi.getCurrentRequestCount = function () {
    let myData = {
        url: '/api/private/joinrequeststome',
        method: 'GET',
    };
    return apiUtil.runRequest(myData);
};

export default joinRequestApi;
