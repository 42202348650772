import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Typography, makeStyles, Divider } from '@material-ui/core';

import YouTubeIcon from '../../MyComponents/icons/YouTubeIcon';
import FacebookMainPageIcon from '../../MyComponents/icons/FacebookMainPageIcon';
import { CONTACT_US_LINK, SPECIAL_QUOTA_LINK } from '../../common/urlLinks';
import Grid from '@material-ui/core/Grid';
import { COLORS2 } from '../../constants';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { ONBOARDING_STEPS } from '../../shared/OnboardingOptions';
import { SUBSCRIPTION_PLANS } from '../../common/utils';
import Hidden from '@material-ui/core/Hidden';
import VBIcon from '../icons/VBIcon';
import FacebookForSubscriptionsIcon from '../icons/FacebookForSubcriptionsIcon';
import YouTubeForSubscriptionsIcon from '../icons/YoutubeForSubscrtipitonsIcon';

function Copyright() {
    return (
        <Typography variant='body2' color='textSecondary'>
            {`© ${new Date().getFullYear()} `}
            <Link color='inherit' to='/'>
                Vboard
            </Link>
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    footerLoggedOut: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        marginTop: 'auto',
        marginBottom: '3rem',
    },
    footerLoggedIn: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        padding: theme.spacing(2, 1),
        marginTop: 'auto',
        backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
    },
    lastLinkLoggedOut: {
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '0.875rem',
        lineHeight: '1rem',
        letterSpacing: '0.14em',
        textTransform: 'uppercase',
        paddingLeft: '1rem',
        paddingRight: '1rem',
    },
    lastLinkLoggedIn: {
        marginLeft: '0.5rem',
        paddingLeft: '0.5rem',
        marginRight: '0.5rem',
        paddingRight: '0.5rem',
        borderRight: `0.063rem solid ${theme.palette.text.secondary}`,
    },
    footerLogo: {
        width: '7.688rem',
        height: '3.375rem',
    },
    helpers: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        height: '1rem',
        marginTop: '0.325rem',
    },
    imgFacebookIcon: {
        width: '1.5rem',
        height: '1.5rem',
        marginTop: '0.725rem',
        marginRight: '0.5rem',
    },
    imgYoutubeIcon: {
        width: '2.125',
        height: '2.2rem',
    },
    socials: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        height: '3rem',
    },
    writeUs: {
        background: COLORS2.virtualBackground,
        boxShadow: '0rem 0rem 0rem 0.5rem rgba(8, 170, 180, 0.08)',
        borderRadius: '1rem',
        width: '20rem',
        height: '4.5rem',
    },
    tryPremium: {
        background: COLORS2.virtualGreen,
        boxShadow: '0rem 0rem 0rem 0.5rem rgba(8, 170, 180, 0.08)',
        borderRadius: '1rem',
        width: '20rem',
        height: '4.5rem',
        position: 'initial',
    },
    customDivider: {
        '&.MuiDivider-root': {
            backgroundColor: 'rgba(210, 104, 40, 0.2)',
            marginRight: '4.5rem',
            marginLeft: '4.5rem',
        },
    },
}));

function SubscriptionsFooter(props) {
    const classes = useStyles();

    if (window.isTeams) {
        return null;
    }

    const transitionToTop = () => {
        setTimeout(() => {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }, 200);
    };

    const setOnboardingPath = (path) => {
        window.localStorage.setItem('pathBeforeLogin', path);
    };

    return (
        <div>
            {props.profile.loggedIn ? (
                <footer className={classes.footerLoggedIn}>
                    <Copyright />
                    <div className='u-fx u-fx-align-center'>
                        <Typography variant='body2' className={classes.lastLinkLoggedIn} onClick={transitionToTop}>
                            <Link to={CONTACT_US_LINK}>
                                <FormattedMessage id='contactus' />
                            </Link>
                        </Typography>
                        <Typography variant='body2' className={classes.lastLinkLoggedIn} onClick={transitionToTop}>
                            <Link to='/privacypolicy'>
                                <FormattedMessage id='privacy' />
                            </Link>
                        </Typography>
                        <Typography variant='body2' className={classes.lastLinkLoggedIn} onClick={transitionToTop}>
                            <Link to='/terms'>
                                <FormattedMessage id='terms' />
                            </Link>
                        </Typography>
                        <a
                            href='https://www.facebook.com/Virtual-Board-104068774768862'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            <FacebookMainPageIcon className={classes.img} />
                        </a>
                        <a
                            className='u-ml-half'
                            href='https://www.youtube.com/c/VirtualBoard_vboard'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            <YouTubeIcon className={classes.img} />
                        </a>
                    </div>
                </footer>
            ) : (
                <footer className={classes.footerLoggedOut}>
                    <Hidden only={['xs', 'sm']}>
                        <Grid container style={{ paddingTop: '14rem', paddingRight: '1rem', paddingLeft: '1rem' }}>
                            <Grid item xs={12}>
                                <Typography
                                    variant='h2'
                                    style={{
                                        textAlign: 'center',
                                        color: COLORS2.darkGreen,
                                    }}
                                >
                                    <FormattedMessage
                                        id='redesign.page.home.footer.notDecided.tag1'
                                        values={{
                                            b: (chunks) => <b>{chunks}</b>,
                                        }}
                                    />
                                </Typography>
                                <Typography
                                    variant='h4'
                                    style={{
                                        textAlign: 'center',
                                        color: COLORS2.darkGreen,
                                        fontWeight: '500',
                                        margin: '0rem',
                                    }}
                                >
                                    <FormattedMessage id='redesign.page.home.subscriptionsFooter.notDecided.tag2' />
                                </Typography>
                                <Typography
                                    variant='h4'
                                    style={{
                                        textAlign: 'center',
                                        color: COLORS2.darkGreen,
                                        fontWeight: '500',
                                        margin: '0rem',
                                    }}
                                >
                                    <FormattedMessage id='redesign.page.home.subscriptionsFooter.notDecided.tag3' />
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    paddingTop: '3.5rem',
                                }}
                            >
                                <Link to={SPECIAL_QUOTA_LINK}>
                                    <Button className={classes.writeUs} onClick={transitionToTop}>
                                        <Typography
                                            style={{
                                                fontStyle: 'normal',
                                                fontWeight: '700',
                                                fontSize: '0.875rem',
                                                lineHeight: '1.063rem',
                                                letterSpacing: '0.16rem',
                                                textTransform: 'uppercase',
                                                color: COLORS2.virtualGreen,
                                                textAlign: 'center',
                                            }}
                                        >
                                            <FormattedMessage id='specialQuota.buttonText' />
                                        </Typography>
                                    </Button>
                                </Link>
                                <Typography
                                    style={{
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        fontSize: '0.875rem',
                                        lineHeight: '1rem',
                                        letterSpacing: '0.16rem',
                                        textTransform: 'uppercase',
                                        color: COLORS2.orTextFooter,
                                        textAlign: 'center',
                                        padding: '0rem 2rem',
                                    }}
                                >
                                    <FormattedMessage id='redesign.page.home.footer.or' />
                                </Typography>
                                <Button
                                    className={classes.tryPremium}
                                    onClick={() => {
                                        transitionToTop();
                                        props.handleOpen();
                                        setOnboardingPath(
                                            `/onboarding?step=${ONBOARDING_STEPS.START}&subscription=${SUBSCRIPTION_PLANS.TRIAL}`,
                                        );
                                    }}
                                >
                                    <Typography
                                        style={{
                                            fontStyle: 'normal',
                                            fontWeight: '700',
                                            fontSize: '0.875rem',
                                            lineHeight: '1.063rem',
                                            letterSpacing: '0.16rem',
                                            textTransform: 'uppercase',
                                            color: COLORS2.third,
                                            textAlign: 'center',
                                        }}
                                    >
                                        <FormattedMessage id='redesign.page.home.footer.tryPremium' />
                                    </Typography>
                                </Button>
                            </Grid>
                            <Grid item xs={12} style={{ paddingTop: '10rem' }}>
                                <Divider className={classes.customDivider} variant='middle' />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    paddingTop: '4rem',
                                }}
                            >
                                <Grid item xs={6} sm={2} style={{ paddingLeft: '2rem' }}>
                                    <VBIcon className={classes.footerLogo} />
                                </Grid>
                                <Grid
                                    item
                                    xs={8}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <div className={classes.helpers}>
                                        <Typography className={classes.lastLinkLoggedOut} onClick={transitionToTop}>
                                            <Link
                                                to={CONTACT_US_LINK}
                                                style={{
                                                    color: COLORS2.darkGreen,
                                                }}
                                            >
                                                <FormattedMessage id='contactus' />
                                            </Link>
                                        </Typography>
                                        <Typography className={classes.lastLinkLoggedOut} onClick={transitionToTop}>
                                            <Link
                                                to='/terms'
                                                style={{
                                                    color: COLORS2.darkGreen,
                                                }}
                                            >
                                                <FormattedMessage id='terms' />
                                            </Link>
                                        </Typography>
                                        <Typography className={classes.lastLinkLoggedOut} onClick={transitionToTop}>
                                            <Link
                                                to='/privacypolicy'
                                                style={{
                                                    color: COLORS2.darkGreen,
                                                }}
                                            >
                                                <FormattedMessage id='privacy' />
                                            </Link>
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    sm={2}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <div className={classes.socials}>
                                        <a
                                            href='https://www.facebook.com/Virtual-Board-104068774768862'
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            <FacebookForSubscriptionsIcon className={classes.imgFacebookIcon} />
                                        </a>
                                        <a
                                            className='u-ml-half'
                                            href='https://www.youtube.com/c/VirtualBoard_vboard'
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            style={{ paddingTop: '0.5rem' }}
                                        >
                                            <YouTubeForSubscriptionsIcon className={classes.imgYoutubeIcon} />
                                        </a>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Hidden>
                    <Hidden only={['md', 'lg', 'xl']}>
                        <Grid container style={{ paddingTop: '7rem', paddingRight: '1rem', paddingLeft: '1rem' }}>
                            <Grid item xs={12}>
                                <Typography
                                    variant='h2'
                                    style={{
                                        textAlign: 'center',
                                        color: COLORS2.darkGreen,
                                    }}
                                >
                                    <FormattedMessage
                                        id='redesign.page.home.footer.notDecided.tag1'
                                        values={{
                                            b: (chunks) => <b>{chunks}</b>,
                                        }}
                                    />
                                </Typography>
                                <Typography
                                    variant='h4'
                                    style={{
                                        textAlign: 'center',
                                        color: COLORS2.darkGreen,
                                        fontWeight: '500',
                                        margin: '0rem',
                                    }}
                                >
                                    <FormattedMessage id='redesign.page.home.subscriptionsFooter.notDecided.tag2' />
                                </Typography>
                                <Typography
                                    variant='h4'
                                    style={{
                                        textAlign: 'center',
                                        color: COLORS2.darkGreen,
                                        fontWeight: '500',
                                        margin: '0rem',
                                    }}
                                >
                                    <FormattedMessage id='redesign.page.home.subscriptionsFooter.notDecided.tag3' />
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    paddingTop: '3.5rem',
                                    paddingBottom: '4rem',
                                    flexDirection: 'column',
                                }}
                            >
                                <Link to={SPECIAL_QUOTA_LINK}>
                                    <Button className={classes.writeUs} onClick={transitionToTop}>
                                        <Typography
                                            style={{
                                                fontStyle: 'normal',
                                                fontWeight: '700',
                                                fontSize: '0.875rem',
                                                lineHeight: '1.063rem',
                                                letterSpacing: '0.16rem',
                                                textTransform: 'uppercase',
                                                color: COLORS2.virtualGreen,
                                                textAlign: 'center',
                                            }}
                                        >
                                            <FormattedMessage id='specialQuota.buttonText' />
                                        </Typography>
                                    </Button>
                                </Link>
                                <Typography
                                    style={{
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        fontSize: '0.875rem',
                                        lineHeight: '1rem',
                                        letterSpacing: '0.16rem',
                                        textTransform: 'uppercase',
                                        color: 'rgba(255,255,255,0.5)',
                                        textAlign: 'center',
                                        padding: '1.25rem 0rem',
                                    }}
                                >
                                    <FormattedMessage id='redesign.page.home.footer.or' />
                                </Typography>
                                <Button
                                    className={classes.tryPremium}
                                    onClick={() => {
                                        transitionToTop();
                                        props.handleOpen();
                                        setOnboardingPath(
                                            `/onboarding?step=${ONBOARDING_STEPS.START}&subscription=${SUBSCRIPTION_PLANS.TRIAL}`,
                                        );
                                    }}
                                >
                                    <Typography
                                        style={{
                                            fontStyle: 'normal',
                                            fontWeight: '700',
                                            fontSize: '0.875rem',
                                            lineHeight: '1.063rem',
                                            letterSpacing: '0.16rem',
                                            textTransform: 'uppercase',
                                            color: COLORS2.third,
                                            textAlign: 'center',
                                        }}
                                    >
                                        <FormattedMessage id='redesign.page.home.footer.tryPremium' />
                                    </Typography>
                                </Button>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    backgroundColor: COLORS2.virtualBackground,
                                }}
                            >
                                <Grid item xs={6} sm={2} style={{ paddingLeft: '2rem' }}>
                                    <VBIcon className={classes.footerLogo} />
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    sm={2}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <div className={classes.socials}>
                                        <a
                                            href='https://www.facebook.com/Virtual-Board-104068774768862'
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            <FacebookForSubscriptionsIcon className={classes.imgFacebookIcon} />
                                        </a>
                                        <a
                                            className='u-ml-half'
                                            href='https://www.youtube.com/c/VirtualBoard_vboard'
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            style={{ paddingTop: '0.5rem' }}
                                        >
                                            <YouTubeForSubscriptionsIcon className={classes.imgYoutubeIcon} />
                                        </a>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Hidden>
                </footer>
            )}
        </div>
    );
}

const mapStateToProps = (state) => {
    const { profile } = state;
    return { profile };
};

export default connect(mapStateToProps)(SubscriptionsFooter);
