import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';

import drawUtils from '../../../pages/LessonPage/drawUtils';
import VBToolTip from '../../Tooltip/VBToolTip';
import { GA_EVENT_TREE } from '../../../gaConstants';

const MAX_ZOOM = 9;

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    toolBorder: {
        borderColor: 'rgba(0,0,0,0.3)',
        borderWidth: 'thin',
        borderBottomStyle: 'solid',
        paddingTop: '0.5rem',
        paddingBottom: '0.5rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    zoomBtn: { display: 'inline-block' },
}));

export default function ZoomBox() {
    const classes = useStyles();

    const [zoomValue, setZoomValue] = React.useState(window.zoomState.zoomLevel);

    const updateZoom = () => {
        setZoomValue(Math.round(window.zoomState.zoomLevel));
    };

    window.forceZoomBox = updateZoom;

    const zoomIn = () => {
        if (zoomValue < MAX_ZOOM) {
            setZoomValue(zoomValue + 1);
            window.zoomState.zoomDisabled = false;
            window.zoomState.zoomLevel = zoomValue + 1;
            drawUtils.triggerWindowCallbacks();
            window.logAppActivity(GA_EVENT_TREE.lessonPage.toolSelection.zoomIn, zoomValue + 1);
        }
    };

    const zoomOut = () => {
        if (zoomValue > 0) {
            setZoomValue(zoomValue - 1);
            window.zoomState.zoomLevel = zoomValue - 1;
            if (zoomValue - 1 === 0) {
                window.zoomState.zoomDisabled = true;
            }
            drawUtils.triggerWindowCallbacks();
        }
    };

    let zoomInEnabled = zoomValue < MAX_ZOOM;
    let zoomOutEnabled = zoomValue > 0;

    let zoomInColor = zoomInEnabled ? 'action' : 'disabled';
    let zoomOutColor = zoomOutEnabled ? 'action' : 'disabled';
    let sizeText = '';
    if (zoomValue === 0) {
        sizeText = ' ';
    } else {
        sizeText = zoomValue + 1 + 'x';
    }

    return (
        <div className={classes.toolBorder}>
            <Fab size="small" className={classes.zoomBtn} onClick={zoomIn} disabled={!zoomInEnabled}>
                <VBToolTip content={<FormattedMessage id="tooltip.lessonPage.zoomIn" />}>
                    <ZoomInIcon color={zoomInColor} style={{ verticalAlign: 'middle' }} />
                </VBToolTip>
            </Fab>
            <span>{zoomValue === 0 ? '100%' : sizeText}</span>
            <Fab size="small" className={classes.zoomBtn} disabled={!zoomOutEnabled} onClick={zoomOut}>
                <VBToolTip content={<FormattedMessage id="tooltip.lessonPage.zoomOut" />}>
                    <ZoomOutIcon color={zoomOutColor} style={{ verticalAlign: 'middle' }} />
                </VBToolTip>
            </Fab>
        </div>
    );
}
