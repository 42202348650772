import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { Container } from '@material-ui/core';
import { policyHTML } from './policy-20250402.js';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(3),
        // width: '100%',
    },
    container: {
        margin: theme.spacing(3),
        // width: '100%',
    },
}));

const PrivacyPolicy = () => {
    const classes = useStyles();

    return (
        <Container fixed>
            <Paper elevation={3} className={classes.container}>
                <Container fixed>
                    <div dangerouslySetInnerHTML={{ __html: policyHTML }} />
                </Container>
            </Paper>
        </Container>
    );
};

export default injectIntl(PrivacyPolicy);
