import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Avatar, Button, Fab, Grid, TextField } from '@material-ui/core';
import managementApi from '../../Api/managementApi';
import EditIcon from '@material-ui/icons/Edit';
import UpdateUser from './UpdateUser';

const FindUser = () => {
    const [user, setUser] = React.useState(null);
    const [users, setUsers] = React.useState([]);
    const [userEmail, setUserEmail] = React.useState('');
    const [userName, setUserName] = React.useState('');

    if (user) {
        return (
            <UpdateUser
                user={user}
                close={() => {
                    setUser(null);
                }}
            />
        );
    }

    return (
        <Grid container direction="row" spacing={2} style={{ paddingTop: '20px' }}>
            <Grid item xs={4}>
                <TextField
                    variant={'outlined'}
                    value={userName}
                    onChange={(event) => {
                        setUserName(event.target.value);
                    }}
                    label="Name"
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    variant={'outlined'}
                    value={userEmail}
                    onChange={(event) => {
                        setUserEmail(event.target.value);
                    }}
                    label="Email"
                />
            </Grid>
            <Grid item xs={4}>
                <Button
                    variant={'outlined'}
                    onClick={() => {
                        managementApi.findUser(userEmail, userName).then((res) => {
                            setUsers(res.data);
                        });
                    }}
                >
                    Search
                </Button>
            </Grid>
            {users.map((el) => {
                return (
                    <Grid item xs={12} key={el.id}>
                        <Grid container>
                            <Grid item xs={3}>
                                <Avatar src={el.avatar} />
                            </Grid>
                            <Grid item xs={3}>
                                <Typography>
                                    {el.first_name} {el.last_name}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography>{el.email}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Fab
                                    onClick={() => {
                                        setUser(el);
                                    }}
                                >
                                    <EditIcon />
                                </Fab>
                            </Grid>
                        </Grid>
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default FindUser;
