import React from 'react';
import { Drawer } from '@material-ui/core';
import styles from './styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import VBIcon from '../icons/VBIcon';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import Hidden from '@material-ui/core/Hidden';
import CloseSideMenuIcon from '../icons/CloseSideMenuIcon';
import Divider from '@material-ui/core/Divider';
import SideMenu from '../Drawer/SideMenu';
import TutorialMenuLink from '../../pages/Tutorial/TutorialMenuLink';
import { Route, useHistory } from 'react-router-dom';
import FeaturesIcon from '../icons/FeaturesIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PricesSideMenuIcon from '../icons/PricesSideMenuIcon';
import { handleLogout } from '../../common/utils';
import LogOutIcon from '../icons/LogOutIcon';
import LanguageSelector from '../Drawer/LanguageSelector';
import { GA_EVENT_TREE } from '../../gaConstants';
import { ABOUT_US_LINK, CONTACT_US_LINK, FEATURES_LINK, HOME_LINK, SUBSCRIPTIONS_LINK } from '../../common/urlLinks';
import { COLORS2 } from '../../constants';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import InfoIcon from '@material-ui/icons/Info';

const SideNavigation = ({ open, handleDrawerToggle, variant }) => {
    const history = useHistory();
    const classes = styles();

    let props;
    const handleHome = (closeFunction) => {
        window.logAppActivity(GA_EVENT_TREE.mainPage.menuClick.home);
        history.push(HOME_LINK);
        closeFunction();
    };

    const handleFeatures = (closeFunction) => {
        //window.logAppActivity(GA_EVENT_TREE.mainPage.menuClick.home);
        history.push(FEATURES_LINK);
        closeFunction();
    };

    const handleSubscriptions = (closeFunction) => {
        //window.logAppActivity(GA_EVENT_TREE.mainPage.menuClick.subscriptions);
        history.push(SUBSCRIPTIONS_LINK);
        closeFunction();
    };

    const handleAbout = (closeFunction) => {
        //window.logAppActivity(GA_EVENT_TREE.mainPage.menuClick.subscriptions);
        history.push(ABOUT_US_LINK);
        closeFunction();
    };

    const handleContactUs = (closeFunction) => {
        //window.logAppActivity(GA_EVENT_TREE.mainPage.menuClick.subscriptions);
        history.push(CONTACT_US_LINK);
        closeFunction();
    };

    return (
        <Drawer
            variant={variant}
            open={open}
            onClose={handleDrawerToggle}
            classes={{
                paper: classes.drawerPaper,
            }}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
        >
            <div id='side-menu' className={classes.entireSideMenu}>
                <div className={classes.toolbar}>
                    <List className={classes.list}>
                        <ListItem
                            button
                            onClick={() => {
                                handleHome(handleDrawerToggle);
                            }}
                        >
                            <ListItemIcon>
                                <VBIcon className={classes.icon} />
                            </ListItemIcon>
                            <Typography className={classes.listItemText}>
                                <FormattedMessage id='home' />
                            </Typography>
                        </ListItem>
                        <Hidden only={['lg', 'xl']}>
                            <CloseSideMenuIcon onClick={handleDrawerToggle} />
                        </Hidden>
                    </List>
                </div>
                <Divider className={classes.divider} />
                <div className={classes.footerWrapper}>
                    <div>
                        <SideMenu closeDrawer={handleDrawerToggle} />
                        <TutorialMenuLink closeDrawer={handleDrawerToggle} />
                    </div>
                    <Route
                        render={({ location }) => {
                            return location.pathname.indexOf('/lesson') === -1 ? (
                                <>
                                    <div>
                                        <List>
                                            <ListItem
                                                button
                                                className={classes.listItem}
                                                onClick={() => {
                                                    handleFeatures(handleDrawerToggle);
                                                }}
                                            >
                                                <ListItemIcon>
                                                    <FeaturesIcon />
                                                </ListItemIcon>
                                                <ListItemText>
                                                    <Typography className={classes.listItemText}>
                                                        <FormattedMessage id={'topMenu.features.name'} />
                                                    </Typography>
                                                </ListItemText>
                                            </ListItem>
                                        </List>
                                        <List>
                                            <ListItem
                                                button
                                                onClick={() => {
                                                    handleSubscriptions(handleDrawerToggle);
                                                }}
                                                className={classes.listItem}
                                            >
                                                <ListItemIcon>
                                                    <PricesSideMenuIcon />
                                                </ListItemIcon>
                                                <ListItemText>
                                                    <Typography className={classes.listItemText}>
                                                        <FormattedMessage id={'topMenu.subscriptions.name'} />
                                                    </Typography>
                                                </ListItemText>
                                            </ListItem>
                                        </List>
                                        <List>
                                            <ListItem
                                                button
                                                onClick={() => {
                                                    handleAbout(handleDrawerToggle);
                                                }}
                                                className={classes.listItem}
                                            >
                                                <ListItemIcon>
                                                    <InfoIcon color={'primary'} />
                                                </ListItemIcon>
                                                <ListItemText>
                                                    <Typography className={classes.listItemText}>
                                                        <FormattedMessage id={'aboutUs'} />
                                                    </Typography>
                                                </ListItemText>
                                            </ListItem>
                                        </List>

                                        <List>
                                            <ListItem
                                                button
                                                onClick={() => {
                                                    handleContactUs(handleDrawerToggle);
                                                }}
                                                className={classes.listItem}
                                            >
                                                <ListItemIcon>
                                                    <ContactSupportIcon style={{ color: COLORS2.virtualGreen }} />
                                                </ListItemIcon>
                                                <ListItemText>
                                                    <Typography className={classes.listItemText}>
                                                        <FormattedMessage id={'contactus'} />
                                                    </Typography>
                                                </ListItemText>
                                            </ListItem>
                                        </List>
                                        <Divider className={classes.divider} />
                                        <List>
                                            <ListItem button onClick={handleLogout} className={classes.listItem}>
                                                <ListItemIcon>
                                                    <LogOutIcon />
                                                </ListItemIcon>
                                                <ListItemText>
                                                    <Typography className={classes.listItemText}>
                                                        <FormattedMessage id={'logout'} />
                                                    </Typography>
                                                </ListItemText>
                                            </ListItem>
                                        </List>
                                    </div>
                                </>
                            ) : (
                                <LanguageSelector
                                    updateLanguage={props?.updateLanguage}
                                    closeDrawer={handleDrawerToggle}
                                />
                            );
                        }}
                    />
                </div>
            </div>
        </Drawer>
    );
};

export default SideNavigation;
