import React, { useState } from 'react';
import { Grid, InputBase } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import StartGroupIcon from '../../MyComponents/icons/StartGroupIcon';
import { FormattedMessage, injectIntl } from 'react-intl';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import classApi from '../../Api/classApi';
import store from '../../redux/store';
import { showError, upgradeSubscriptionDialog } from '../../redux/actions';
import { useHistory } from 'react-router-dom';
import { COLORS2 } from '../../constants';

const useStyles = makeStyles(() => ({
    button: {
        whiteSpace: 'nowrap',
        margin: '1rem',
        minWidth: 'auto',
    },
    buttonMobile: {
        borderRadius: '0rem 1rem 1rem 0rem',
        height: '4rem',
        margin: '1rem',
        boxShadow: 'none',
    },
    container: {
        width: '100%',
        height: '5rem',
        background: '#FFFFFF',
        boxShadow: '0rem 2.5rem 10rem rgba(210, 104, 40, 0.08)',
        borderRadius: '2rem',
    },
    baseSm: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        marginLeft: '1rem',
    },
    iconPosition: {
        width: '1.875rem',
        height: '1.375rem',
        marginTop: '0.563rem',
        marginLeft: '0.563rem',
    },
    baseStyle: {
        fontWeight: 400,
        color: COLORS2.taxBreak,
        fontSize: '1.375rem',
    },
    inputSm: {
        fontWeight: '400',
        color: COLORS2.darkCyan,
        fontSize: '1rem',
    },
    addIcon: {
        fontSize: '3rem',
        margin: '1rem',
        color: COLORS2.pattensBlue,
    },
    iconSm: {
        fontSize: '2rem',
        fontWeight: '400',
        marginRight: '0.5rem',
    },
    containerSm: {
        width: '100%',
        height: '4rem',
        background: '#FFFFFF',
        boxShadow: '0rem 2.5rem 10rem rgba(210, 104, 40, 0.08)',
        borderRadius: '1rem',
    },
}));

const CreateClassAction = ({ intl }) => {
    const classes = useStyles();
    const [name, setName] = useState('');
    let history = useHistory();

    const handleChange = (event) => {
        setName(event.target.value);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            createGroup();
        }
    };

    const createGroup = () => {
        if (!name || name.trim() === '') return;
        classApi
            .createGroup({ name: name.trim() })
            .then((res) => {
                history.push('/lesson/' + res.data.id);
            })
            .catch((err) => {
                if (err.response.status === 401) return;
                if (err.response.status === 403 && err.response.data?.availableSubscriptions) {
                    store.dispatch(
                        upgradeSubscriptionDialog({
                            key: 'subscription.upsell.groups.exceeded',
                            subscriptions: err.response.data?.availableSubscriptions,
                        })
                    );
                    history.replace('/manageClasses');
                } else {
                    store.dispatch(showError('GENERIC_ERROR'));
                    console.error(err);
                }
            });
    };

    return (
        <>
            <Hidden xsDown>
                <Grid item xs={12} className={classes.container}>
                    <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center' }}>
                        <AddIcon className={classes.addIcon} />
                        <InputBase
                            placeholder={intl.formatMessage({ id: 'redesign.CreateGroup.label' })}
                            value={name}
                            onChange={handleChange}
                            onKeyPress={handleKeyPress}
                            type="text"
                            fullWidth
                            style={{ fontWeight: 400, color: '#496365', fontSize: '1.375rem' }}
                            endAdornment={
                                name || name.trim() !== '' ? (
                                    <Button
                                        size="large"
                                        color="primary"
                                        variant="contained"
                                        onClick={createGroup}
                                        className={classes.button}
                                        endIcon={<StartGroupIcon className={classes.iconPosition} />}
                                    >
                                        <FormattedMessage id="redesign.CreateGroup.submit" />
                                    </Button>
                                ) : null
                            }
                        />
                    </div>
                </Grid>
            </Hidden>
            <Hidden smUp>
                <Grid item xs={12} className={classes.containerSm}>
                    <div className={classes.baseSm}>
                        <InputBase
                            placeholder={intl.formatMessage({ id: 'redesign.createGroup.mobile' })}
                            type="text"
                            value={name}
                            onChange={handleChange}
                            onKeyPress={handleKeyPress}
                            fullWidth
                            className={classes.inputSm}
                            endAdornment={
                                <Button
                                    size="large"
                                    color="primary"
                                    variant="contained"
                                    onClick={createGroup}
                                    className={classes.buttonMobile}
                                    endIcon={<AddIcon className={classes.iconSm} />}
                                />
                            }
                        />
                    </div>
                </Grid>
            </Hidden>
        </>
    );
};

export default injectIntl(CreateClassAction);
