import React from 'react';
import Slider from '@material-ui/core/Slider';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';

const STEP_FACTOR = 25;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

export default function VerticalSlider(props) {
    const classes = useStyles();

    const [value, setValue] = React.useState(100);

    const handleChange = (event, newValue) => {
        if (value !== newValue) {
            setValue(newValue);
            props.updateValue(newValue);
        }
    };

    window.forceVertSlider = setValue;

    const stepUp = () => {
        if (value + STEP_FACTOR > 100) {
            handleChange(null, 100);
        } else {
            handleChange(null, value + STEP_FACTOR);
        }
    };

    const stepDown = () => {
        if (value - STEP_FACTOR < 0) {
            handleChange(null, 0);
        } else {
            handleChange(null, value - STEP_FACTOR);
        }
    };

    return (
        <div className={clsx(classes.root)}>
            <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                size="small"
                className={clsx(classes.root)}
                onClick={stepUp}
            >
                <ExpandLessIcon />
            </IconButton>
            <div id="verticalSliderHeight" style={{ display: 'inline-block' }}>
                <Slider
                    value={value}
                    onChange={handleChange}
                    aria-labelledby="continuous-slider"
                    orientation="vertical"
                    track="inverted"
                />
            </div>
            <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                size="small"
                className={clsx(classes.root)}
                onClick={stepDown}
            >
                <ExpandMoreIcon />
            </IconButton>
        </div>
    );
}
