import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { debounce } from 'lodash';
import { Typography, Paper } from '@material-ui/core';
import { getSummaryActiveItem } from '../summaryUtils';
import { COLORS2 } from '../../../constants';

const nodeStyle = {
    cursor: 'pointer',
    paddingTop: '1rem',
    alignItems: 'center',
    position: 'relative',
};
const selectedNodeStyle = {
    fontWeight: '700',
};

const selectedCursorStyle = {
    height: '2rem',
    position: 'absolute',
    width: '4px',
    backgroundColor: COLORS2.darkGreen,
    borderRadius: '32px 0px 0px 32px',
    left: '0px',
    transform: 'matrix(-1, 0, 0, 1, 0, 0)',
};

const DesktopSummaryMenu = ({ sections, path, updateFunction, selectorFunc }) => {
    const intl = useIntl();
    const history = useHistory();
    const sectionId = useSelector(selectorFunc);

    useEffect(() => {
        const scrollToMenuItem = () => {
            let item = getSummaryActiveItem(sections);
            if (item && item.id) {
                updateFunction(item.id);
            }
        };

        window.addEventListener('scroll', debounce(scrollToMenuItem, 100));
        return () => {
            window.removeEventListener('scroll', scrollToMenuItem);
        };
    }, []);

    const handleSummaryClick = (event, sectionId) => {
        event.preventDefault();
        history.push({
            pathname: path,
            state: {
                sectionId,
            },
        });
        updateFunction(sectionId);
    };

    const renderItem = (node) => {
        let style = nodeStyle;
        let selected = false;
        if (sectionId === node.id) {
            style = { ...style, ...selectedNodeStyle };
            selected = true;
        }
        return (
            <div
                key={node.id}
                onClick={(event) => {
                    handleSummaryClick(event, node.id);
                }}
                style={{ ...style, ...{ paddingLeft: '3.5rem' } }}
            >
                {selected ? <div style={selectedCursorStyle} /> : null}
                {node.nameId && (
                    <Typography variant="h6" style={selected ? selectedNodeStyle : {}}>
                        {intl.formatMessage({ id: node.nameId })}
                    </Typography>
                )}
                {Array.isArray(node.children) ? node.children.map((child) => renderChildItem(child)) : null}
            </div>
        );
    };

    const renderChildItem = (node) => {
        let style = nodeStyle;
        let selected = false;
        if (sectionId === node.id) {
            style = { ...style, ...selectedNodeStyle, display: 'flex' };
            selected = true;
        }

        return (
            <div
                key={node.id}
                style={style}
                onClick={(event) => {
                    handleSummaryClick(event, node.id);
                    event.preventDefault();
                    event.stopPropagation();
                }}
            >
                {selected ? <div style={{ ...selectedCursorStyle, left: '-3.5rem' }} /> : null}
                {node.nameId && (
                    <Typography variant="body2" style={selected ? selectedNodeStyle : {}}>
                        {intl.formatMessage({ id: node.nameId })}
                    </Typography>
                )}
            </div>
        );
    };

    return (
        <Paper
            elevation={3}
            style={{
                paddingTop: '1rem',
                paddingBottom: '1rem',
                backgroundColor: '#FFFFFF',
                boxShadow: '0px 40px 160px rgba(210, 104, 40, 0.08)',
                borderRadius: '32px',
            }}
        >
            <div style={{ overflow: 'auto', maxHeight: '92vh' }}>{sections.map((section) => renderItem(section))}</div>
        </Paper>
    );
};

export default DesktopSummaryMenu;
