import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function LibrarySideMenuIcon(props) {
    return (
        <SvgIcon {...props} style={{ verticalAlign: 'bottom' }}>
            <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_46_242)">
                    <path d="M0 1.00035C0 0.447221 0.446875 0.000345698 1 0.000345698H3C3.55312 0.000345698 4 0.447221 4 1.00035V3.00035H0V1.00035ZM0 4.00035H4V12.0003H0V4.00035ZM0 13.0003H4V15.0003C4 15.5535 3.55312 16.0003 3 16.0003H1C0.446875 16.0003 0 15.5535 0 15.0003V13.0003ZM5 1.00035C5 0.447221 5.44688 0.000345698 6 0.000345698H8C8.55313 0.000345698 9 0.447221 9 1.00035V3.00035H5V1.00035ZM5 4.00035H9V12.0003H5V4.00035ZM5 13.0003H9V15.0003C9 15.5535 8.55313 16.0003 8 16.0003H6C5.44688 16.0003 5 15.5535 5 15.0003V13.0003ZM11.3625 12.3785L10 7.2691V4.46285L13.1375 3.62222L15.2 11.3535L11.3625 12.3785ZM12.8813 2.6566L10 3.42535V0.344096L11.1531 0.0347207C11.6812 -0.109029 12.2281 0.206596 12.3687 0.740971L12.8813 2.6566ZM11.6187 13.3441L15.4563 12.316L15.9656 14.2253C16.1063 14.7566 15.7937 15.3035 15.2625 15.4472L13.3438 15.9628C12.8156 16.1066 12.2688 15.791 12.1281 15.2566L11.6187 13.3441Z" fill="#08AAB4"/>
                </g>
                <defs>
                    <clipPath id="clip0_46_242">
                        <rect width="16" height="16" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        </SvgIcon>
    );
}