import { v4 as uuidv4 } from 'uuid';
import drawUtils from './drawUtils';
import { LINEAGE_NONE } from './drawConstants';
import store from '../../redux/store';
import { showWarn } from '../../redux/actions';
import boardManager, { DEFAULT_BOARD } from './boardManager';
import { STICKY_ELEMENTS_TYPE } from '../../constants';
import {
    addToWindowCache,
    getStickyImage,
    // getUrlFromWindowCache
} from '../../common/utils';

const UNDO_ITEM_COUNT = 15;

let lessonList = {};

const storage = window.localStorage;

lessonList.saveLesson = function (email, lessonId, pageNo, gridType = LINEAGE_NONE, skip = false) {
    if (skip) {
        return Promise.resolve();
    }
    let lesson = lessonList.getLessonForEmail(email, lessonId);
    if (pageNo < lesson.pages.length) {
        let id = lesson.pages[pageNo];
        try {
            // console.log('save regular page');
            if (!drawUtils.getCanvas()) {
                return Promise.resolve(true);
            }
            // console.log('saving old ', id, gridType);
            storage.setItem(id + '-type', gridType);
            let data = drawUtils.getCanvas().toDataURL('image/png', 1);
            lessonList.saveStageForUndo(id, data);
            boardManager.mainBoard.isCurrentPageDirty = false;
            return updateItem(id, data);
        } catch (ex) {
            console.error('no more storage - unable to save your lessons', ex);
            store.dispatch(showWarn('LOCAL_STORAGE_FULL'));
            return Promise.resolve(false);
        }
    } else {
        try {
            let newID = uuidv4();
            if (!drawUtils.getCanvas()) {
                return true;
            }
            localStorage.setItem(newID + '-type', gridType);
            lesson.pages.push(newID);
            updateLessonForEmail(email, lessonId, lesson);
            let data = drawUtils.getCanvas().toDataURL('image/png', 1);
            // console.log('saving new ', newID, gridType);
            lessonList.saveStageForUndo(newID, data);
            return updateItem(newID, data);
        } catch (ex) {
            console.error('no more storage - unable to save your lessons', ex);
            store.dispatch(showWarn('LOCAL_STORAGE_FULL'));
            return Promise.resolve(false);
        }
    }
};

lessonList.buildImageElement = function (newID, x, y, width, height, creator, url) {
    return {
        stickyType: STICKY_ELEMENTS_TYPE.IMAGE,
        id: newID,
        x,
        y,
        width,
        height,
        creator,
        url,
    };
};

lessonList.buildTextElement = function (text, x, y, width, height, weight, color, creator) {
    let newID = uuidv4();
    return {
        stickyType: STICKY_ELEMENTS_TYPE.TEXT,
        id: newID,
        x,
        y,
        width,
        height,
        text,
        weight,
        color,
        creator,
    };
};

lessonList.buildLatexElement = function (latex, x, y, width, height, weight, color, creator) {
    let newID = uuidv4();
    return {
        stickyType: STICKY_ELEMENTS_TYPE.LATEX,
        id: newID,
        x,
        y,
        width,
        height,
        latex,
        weight,
        color,
        creator,
    };
};

lessonList.addStickyImage = function (email, lessonId, boardId, newImageElement) {
    let boardElements = lessonList.getStickyLessonForEmail(email, lessonId, boardId);
    boardElements.stickyElements.push(newImageElement);
    lessonList.saveStickyLessonForEmail(email, lessonId, boardId, boardElements);
    addToWindowCache(email, lessonId, boardId, newImageElement.id, newImageElement.url);
    return newImageElement;
};

lessonList.addStickyText = function (email, lessonId, boardId, el) {
    let boardElements = lessonList.getStickyLessonForEmail(email, lessonId, boardId);
    boardElements.stickyElements.push(el);
    lessonList.saveStickyLessonForEmail(email, lessonId, boardId, boardElements);
};

// lessonList.addStickyTextToMultiBoard = function (email, lessonId, boardId, el) {
//     let boardElements = lessonList.getStickyLessonForEmail(email, lessonId, boardId);
//     boardElements.stickyElements.push(el);
//     lessonList.saveStickyLessonForEmail(email, lessonId, boardId, boardElements);
// };

// lessonList.getStickyImage = async function (email, lessonId, boardId, id) {
//     let url = getUrlFromWindowCache(email, lessonId, boardId, id);
//     if (!url) {
//         let res = await storageApi.getStickyImage(lessonId, boardId, id);
//         if (res.data.download) {
//             url = res.data.download;
//             addToWindowCache(email, lessonId, boardId, id, url);
//         }
//     }
//     return url;
// };

lessonList.removeStickyElement = (email, lessonId, boardId, id) => {
    let boardElements = lessonList.getStickyLessonForEmail(email, lessonId, boardId);
    if (!boardElements.stickyElements) {
        return;
    }
    boardElements.stickyElements.forEach((el, index) => {
        if (el.id === id) {
            // console.log('=== should be removing', boardId, id, boardElements.stickyElements);
            if (boardElements.stickyElements[index].stickyType === 'image') {
                // storageApi.removeStickyImage(lessonId, boardId, boardElements.stickyElements[index].id).catch((el) => {
                //     console.log('==== error removing the image');
                // });
                // removeItem(boardElements.stickyElements[index].id + '-stickyImage');
                boardElements.stickyElements.splice(index, 1);
                lessonList.saveStickyLessonForEmail(email, lessonId, boardId, boardElements);
            } else {
                boardElements.stickyElements.splice(index, 1);
                lessonList.saveStickyLessonForEmail(email, lessonId, boardId, boardElements);
            }
        }
    });
};

lessonList.removeStickyElements = function (email, lessonId, boardId) {
    // console.log('==== removin sticky elements', email, lessonId, boardId);
    let boardElements = lessonList.getStickyLessonForEmail(email, lessonId, boardId);
    for (let i = 0; i < boardElements.stickyElements.length; i++) {
        if (boardElements.stickyElements[i].stickyType === STICKY_ELEMENTS_TYPE.IMAGE) {
            // console.log('=== should be removing', boardElements.stickyElements[i]);
            // storageApi.removeStickyImage(lessonId, boardId, boardElements.stickyElements[i].id).catch((err) => {
            //     console.log('unable to remove sticky elements');
            // });
            // removeItem(boardElements.stickyElements[i].id + '-stickyImage');
        }
    }
    localStorage.removeItem(generateStickyElementsId(email, lessonId, boardId));
};

lessonList.getUndoKeys = function (id) {
    let undoListKey = `${id}-undoList`;
    let undoListStr = localStorage.getItem(undoListKey);
    let undoList = [];
    if (undoListStr) {
        undoList = JSON.parse(undoListStr);
    }
    return undoList.map((el) => `${id}-undoItem-${el}`);
};

lessonList.saveStageForUndo = function (id, data) {
    try {
        let undoTS = new Date().getTime();
        let undoListKey = `${id}-undoList`;
        let undoListStr = localStorage.getItem(undoListKey);
        let undoList = [];
        if (undoListStr) {
            undoList = JSON.parse(undoListStr);
        }
        undoList.push(undoTS);

        updateItem(`${id}-undoItem-${undoTS}`, data)
            .then(() => {
                localStorage.setItem(undoListKey, JSON.stringify(undoList));
                if (undoList.length > UNDO_ITEM_COUNT) {
                    removeItem(`${id}-undoItem-${undoList[0]}`).then(() => {
                        undoList = undoList.slice(1);
                        localStorage.setItem(undoListKey, JSON.stringify(undoList));
                    });
                }
            })
            .catch(() => {
                console.log('unable to save the undo portion');
            });
    } catch (e) {
        console.log('unable to save the undo portion');
    }
};

lessonList.getLessonId = function (email, lessonId, pageNo) {
    let lesson = lessonList.getLessonForEmail(email, lessonId);
    if (pageNo < lesson.pages.length) {
        return lesson.pages[pageNo];
    } else {
        return null;
    }
};

lessonList.removeLessonPage = function (email, lessonId, pageNo) {
    let lesson = lessonList.getLessonForEmail(email, lessonId);
    if (pageNo < lesson.pages.length) {
        removeItem(lesson.pages[pageNo]);
        lessonList.removeStickyElements(email, lessonId, lesson.pages[pageNo]);
        lesson.pages.splice(pageNo, 1);
        updateLessonForEmail(email, lessonId, lesson);
    }
};

lessonList.getLessonForEmail = function (email, lessonId) {
    let emailObjStr = storage.getItem(email);
    let emailObj;

    if (!emailObjStr) {
        emailObj = {};
        emailObj[lessonId] = {
            pages: [],
        };
        localStorage.setItem(email, JSON.stringify(emailObj));
    } else {
        emailObj = JSON.parse(emailObjStr);
        if (!emailObj) {
            emailObj = {};
        }
        if (!emailObj[lessonId]) {
            emailObj[lessonId] = {
                pages: [],
            };
        }
        localStorage.setItem(email, JSON.stringify(emailObj));
    }
    return emailObj[lessonId];
};

function generateStickyElementsId(email, lessonId, boardId) {
    return `${email}_${lessonId}_${boardId}_stickyElements`;
}

lessonList.getStickyElementsWithUrls = function (email, lessonId, boardId) {
    let session = lessonList.getStickyLessonForEmail(email, lessonId, boardId);
    if (!session) {
        return [];
    }
    let stickyElements = session.stickyElements;
    for (let i = 0; i < stickyElements.length; i++) {
        if (stickyElements[i].stickyType === STICKY_ELEMENTS_TYPE.IMAGE) {
            stickyElements[i].url = getStickyImage(email, lessonId, boardId, stickyElements[i].id);
        }
    }
    return stickyElements;
};

lessonList.getStickyLessonForEmail = function (email, lessonId, boardId) {
    if (!email || !lessonId || !boardId) {
        return null;
    }
    let stickyItemId = generateStickyElementsId(email, lessonId, boardId);
    let objStr = storage.getItem(stickyItemId);
    let emailObj;
    if (!objStr) {
        emailObj = { stickyElements: [] };
        localStorage.setItem(stickyItemId, JSON.stringify(emailObj));
    } else {
        emailObj = JSON.parse(objStr);
    }
    return emailObj;
};

lessonList.saveStickyLessonForEmail = function (email, lessonId, boardId, stickyElements) {
    localStorage.setItem(generateStickyElementsId(email, lessonId, boardId), JSON.stringify(stickyElements));
};

const updateLessonForEmail = function (email, lessonId, lesson) {
    let emailObjStr = storage.getItem(email);
    let emailObj = JSON.parse(emailObjStr);
    emailObj[lessonId] = lesson;
    // console.log('=== about to save', email, lesson);
    localStorage.setItem(email, JSON.stringify(emailObj));
};

lessonList.cleanLessons = function (lessonIdsInDB, email) {
    let lessonsInStore = JSON.parse(localStorage.getItem(email));
    if (lessonsInStore) {
        let lessonIdsInStore = Object.keys(lessonsInStore);
        lessonIdsInStore.forEach((id) => {
            if (!lessonIdsInDB.includes(id)) {
                lessonList.cleanLesson(id, email, lessonsInStore);
            }
            lessonList.trimMultiBoardSessions(email, id);
        });
    }
};

lessonList.trimMultiBoardSessions = function (email, lessonId) {
    let lesson = lessonList.getLessonForEmail(email, lessonId);
    if (lesson) {
        Object.keys(lesson).forEach((key) => {
            if (!lessonList.isSpecialAttribute(key)) {
                if (Object.keys(lesson[key].users).length === 0) {
                    delete lesson[key];
                }
            }
        });
        updateLessonForEmail(email, lessonId, lesson);
    }
};

lessonList.isSpecialAttribute = function (key) {
    if (key === 'pages' || key === 'stickyElements') {
        return true;
    }
    return false;
};

lessonList.cleanLesson = function (lessonId, email, lessonsInStore) {
    let currentStore = lessonsInStore;
    if (!currentStore) {
        currentStore = JSON.parse(localStorage.getItem(email));
    }
    // console.log('==== cleaning store', currentStore);
    if (currentStore && currentStore[lessonId] && currentStore[lessonId].pages) {
        currentStore[lessonId].pages.forEach((id, index) => {
            localStorage.removeItem(id + '-type');
            removeItem(id);
            lessonList.removeStickyElements(email, lessonId, id);
        });
        Object.keys(currentStore[lessonId]).forEach((key) => {
            if (!lessonList.isSpecialAttribute(key)) {
                if (currentStore[lessonId][key]) {
                    if (currentStore[lessonId][key].users) {
                        Object.keys(currentStore[lessonId][key].users).forEach((targetEmail) => {
                            if (
                                currentStore[lessonId][key].users[targetEmail] &&
                                currentStore[lessonId][key].users[targetEmail].pages &&
                                currentStore[lessonId][key].users[targetEmail].pages.length > 0
                            ) {
                                removeItem(currentStore[lessonId][key].users[targetEmail].pages[0].id);
                            }
                        });
                    }
                    lessonList.removeMultiBoard(email, lessonId, key);
                }
            }
        });
        delete currentStore[lessonId];
        localStorage.setItem(email, JSON.stringify(currentStore));
    }
};

lessonList.startMultiBoardLesson = function (email, lessonId, multiBoardId, imageId, stickyElements) {
    // console.log('==== should start multi lesson', email, lessonId, multiBoardId);
    let lesson = lessonList.getLessonForEmail(email, lessonId);
    lesson[multiBoardId] = {
        users: {},
        date: new Date().getTime(),
        imageId,
        gridType: storage.getItem(imageId + '-type'),
    };
    updateLessonForEmail(email, lessonId, lesson);
    let multiBoardLessonData = {
        originalStickyElements: stickyElements,
        users: {},
    };
    localStorage.setItem(
        buildMultiBoardLessonDataName(email, lessonId, multiBoardId),
        JSON.stringify(multiBoardLessonData)
    );
};

function buildMultiBoardLessonDataName(email, lessonId, multiBoardId) {
    return `${email}_${lessonId}_${multiBoardId}_multiBoardData`;
}

lessonList.getMultiBoardElements = (email, lessonId, multiBoardId) => {
    let dataName = buildMultiBoardLessonDataName(email, lessonId, multiBoardId);
    let multiBoardDataStr = localStorage.getItem(dataName);
    let multiBoardData;
    if (!multiBoardDataStr) {
        multiBoardData = {
            originalSticky: [],
            users: {},
        };
        lessonList.updateMultiBoardElements(email, lessonId, multiBoardId, multiBoardData);
        return multiBoardData;
    } else {
        multiBoardData = JSON.parse(multiBoardDataStr);
        return multiBoardData;
    }
};

lessonList.getMultiBoardDataForUser = (email, lessonId, multiBoardId, userEmail) => {
    let multiBoardData = lessonList.getMultiBoardElements(email, lessonId, multiBoardId);
    if (!multiBoardData.users[userEmail]) {
        // console.log('=== building multiuser for =', userEmail, boardManager.originalMultiBoardState.originalLineage);
        multiBoardData.users[userEmail] = {
            pages: [
                {
                    stickyElements: [],
                    id: uuidv4(),
                    gridType: boardManager.lessonState.originalMultiBoardState.originalLineage,
                },
            ],
        };
        lessonList.updateMultiBoardElements(email, lessonId, multiBoardId, multiBoardData);
    }
    return multiBoardData;
};

lessonList.updateMultiBoardElements = (email, lessonId, multiBoardId, data) => {
    localStorage.setItem(buildMultiBoardLessonDataName(email, lessonId, multiBoardId), JSON.stringify(data));
};

lessonList.addMultiBoardStickyElement = (email, lessonId, multiBoardId, userEmail, el, pageNo = 0) => {
    let multiBoardData = lessonList.getMultiBoardDataForUser(email, lessonId, multiBoardId, userEmail);
    if (!multiBoardData.users[userEmail].pages) {
        multiBoardData.users[userEmail].pages = [];
    }
    if (multiBoardData.users[userEmail].pages.length < pageNo + 1) {
        return;
    }

    if (!multiBoardData.users[userEmail].pages[pageNo].stickyElements) {
        multiBoardData.users[userEmail].pages[pageNo].stickyElements = [];
    }
    multiBoardData.users[userEmail].pages[pageNo].stickyElements.push(el);
    lessonList.updateMultiBoardElements(email, lessonId, multiBoardId, multiBoardData);
};

lessonList.updateMultiBoardStickyElement = (email, lessonId, multiBoardId, userEmail, el, pageNo = 0) => {
    let multiBoardData = lessonList.getMultiBoardDataForUser(email, lessonId, multiBoardId, userEmail);
    let idx = multiBoardData.users[userEmail].pages[pageNo].stickyElements.findIndex((item) => item.id === el.id);
    if (idx >= 0) {
        multiBoardData.users[userEmail].pages[pageNo].stickyElements[idx] = el;
        lessonList.updateMultiBoardElements(email, lessonId, multiBoardId, multiBoardData);
    }
    return multiBoardData.users[userEmail].pages[pageNo].stickyElements;
};

lessonList.removeMultiBoardStickyElement = (email, lessonId, multiBoardId, userEmail, id, pageNo = 0) => {
    let multiBoardData = lessonList.getMultiBoardDataForUser(email, lessonId, multiBoardId, userEmail);
    let idx = multiBoardData.users[userEmail].pages[pageNo].stickyElements.findIndex((item) => item.id === id);
    if (idx >= 0) {
        multiBoardData.users[userEmail].pages[pageNo].stickyElements.splice(idx, 1);
        lessonList.updateMultiBoardElements(email, lessonId, multiBoardId, multiBoardData);
    }
    return multiBoardData.users[userEmail].pages[pageNo].stickyElements;
};

lessonList.getMultiBoardElementsForUser = (email, lessonId, multiBoardId, userEmail) => {
    return lessonList.getMultiBoardDataForUser(email, lessonId, multiBoardId, userEmail).users[userEmail].pages;
};

lessonList.updateMultiBoardLessonForUser = async function (email, lessonId, multiBoardId, userInfo, pageNo = 0) {
    // console.log('updating multiboard page:', userInfo.email, pageNo);
    let lesson = lessonList.getLessonForEmail(email, lessonId);
    if (lesson && lesson[multiBoardId] && lesson[multiBoardId].users) {
        if (!lesson[multiBoardId].users[userInfo.email]) {
            lesson[multiBoardId].users[userInfo.email] = {
                name: userInfo.name,
            };
        }
        updateLessonForEmail(email, lessonId, lesson);
    } else {
        return false;
    }
    let multiBoardData = lessonList.getMultiBoardElements(email, lessonId, multiBoardId);
    if (!multiBoardData.users) {
        multiBoardData.users = {};
    }
    if (!multiBoardData.users[userInfo.email]) {
        multiBoardData.users[userInfo.email] = { pages: [] };
    }

    if (
        multiBoardData.users[userInfo.email].pages.length > 0 &&
        multiBoardData.users[userInfo.email].pages.length > pageNo
    ) {
        try {
            updateLessonForEmail(email, lessonId, lesson);
            lessonList.updateMultiBoardElements(email, lessonId, multiBoardId, multiBoardData);
            await updateItem(
                multiBoardData.users[userInfo.email].pages[pageNo].id,
                boardManager.getUserCanvasData(userInfo.email)
            );
            lesson = lessonList.getLessonForEmail(email, lessonId);
        } catch (ex) {
            store.dispatch(showWarn('LOCAL_STORAGE_FULL'));
            return false;
        }
        let newItem = { ...multiBoardData.users[userInfo.email].pages[pageNo] };
        newItem.gridType = boardManager.getLineageForTarget(userInfo.email, pageNo);
        // console.log('==== updating grid type for with', userInfo.email, newItem.gridType);
        multiBoardData.users[userInfo.email].pages[pageNo] = newItem;
        if (!boardManager.currentBoards[userInfo.email]) {
            // console.log('==== should be loading user in updateMultiBoardLessonForUser');
            boardManager.currentBoards[userInfo.email] = { ...DEFAULT_BOARD };
            boardManager.currentBoards[userInfo.email].currentLineage = lesson[multiBoardId].gridType;
        }
    } else {
        // console.log('=== building multiuser2 for =', userInfo.email);
        let newID = uuidv4();
        if (!multiBoardData.users[userInfo.email].pages) {
            multiBoardData.users[userInfo.email].pages = [];
        }
        multiBoardData.users[userInfo.email].pages.push({
            id: newID,
            gridType: lesson[multiBoardId].gridType,
            stickyElements: [],
        });
        try {
            updateLessonForEmail(email, lessonId, lesson);
            lessonList.updateMultiBoardElements(email, lessonId, multiBoardId, multiBoardData);
            await updateItem(newID, boardManager.getUserCanvasData(userInfo.email));
            lesson = lessonList.getLessonForEmail(email, lessonId);
        } catch (ex) {
            store.dispatch(showWarn('LOCAL_STORAGE_FULL'));
            return false;
        }

        if (!boardManager.currentBoards[userInfo.email]) {
            // console.log('==== should be loading user in updateMultiBoardLessonForUser 2');
            boardManager.currentBoards[userInfo.email] = { ...DEFAULT_BOARD };
            boardManager.currentBoards[userInfo.email].currentLineage = lesson[multiBoardId].gridType;
        }
    }
    lessonList.updateMultiBoardElements(email, lessonId, multiBoardId, multiBoardData);
    return true;
};

lessonList.updateMultiBoardLessonLineageForUser = function (
    email,
    lessonId,
    multiBoardId,
    targetEmail,
    pageNo = 0,
    lineage
) {
    let multiBoardData = lessonList.getMultiBoardElements(email, lessonId, multiBoardId);
    if (!multiBoardData.users) {
        return;
    }
    if (!multiBoardData.users[targetEmail]) {
        return;
    }

    if (multiBoardData.users[targetEmail].pages.length > 0 && multiBoardData.users[targetEmail].pages.length > pageNo) {
        multiBoardData.users[targetEmail].pages[pageNo].gridType = lineage;
        lessonList.updateMultiBoardElements(email, lessonId, multiBoardId, multiBoardData);
    }
};

lessonList.getMultiBoardLessonDataForUser = async function (email, lessonId, multiBoardId, targetEmail, pageNo = 0) {
    let lessonPages = lessonList.getMultiBoardElementsForUser(email, lessonId, multiBoardId, targetEmail);
    if (lessonPages && lessonPages.length > 0 && pageNo < lessonPages.length) {
        let data = await getItem(lessonPages[pageNo].id);
        let gridType = lessonPages[pageNo].gridType;
        if (data) {
            return {
                data,
                gridType,
                id: lessonPages[pageNo].id,
            };
        }
    }
    return null;
};

lessonList.getMultiBoardLessonDataForAdmin = async function (email, lessonId, multiBoardId) {
    let lesson = lessonList.getLessonForEmail(email, lessonId);
    if (lesson && lesson[multiBoardId] && lesson[multiBoardId].imageId) {
        let data = await getItem(lesson[multiBoardId].imageId);
        let gridType = lesson[multiBoardId].gridType;
        if (data) {
            return {
                data,
                gridType,
            };
        }
    }
    return null;
};

lessonList.getMultiBoardLesson = function (email, lessonId, multiBoardId) {
    let lesson = lessonList.getLessonForEmail(email, lessonId);
    if (lesson && lesson[multiBoardId]) {
        return lesson[multiBoardId];
    }
    return null;
};

lessonList.removeMultiBoardLessonForUser = function (email, lessonId, multiBoardId, targetEmail) {
    let lesson = lessonList.getLessonForEmail(email, lessonId);
    if (lesson && lesson[multiBoardId] && lesson[multiBoardId].users && lesson[multiBoardId].users[targetEmail]) {
        delete lesson[multiBoardId].users[targetEmail];
        updateLessonForEmail(email, lessonId, lesson);
    }
};

lessonList.removeMultiBoard = function (email, lessonId, multiBoardId) {
    // console.log('==== removing multiBoard', email, lessonId, multiBoardId);

    let multiBoardElements = lessonList.getMultiBoardElements(email, lessonId, multiBoardId);

    if (multiBoardElements && multiBoardElements.users) {
        Object.keys(multiBoardElements.users).forEach((targetEmail) => {
            if (multiBoardElements.users[targetEmail].pages) {
                multiBoardElements.users[targetEmail].pages.forEach((page) => {
                    if (page.stickyElements) {
                        page.stickyElements.forEach((el) => {
                            if (el.stickyType === STICKY_ELEMENTS_TYPE.IMAGE) {
                                // storageApi
                                //     .deleteMultiBoardStickyImage(lessonId, multiBoardId, el.id, targetEmail)
                                //     .catch((err) => {
                                //         console.error('unable to delete');
                                //     });
                            }
                        });
                    }
                    removeItem(page.id);
                });
            }
            delete multiBoardElements.users[targetEmail];
            lessonList.updateMultiBoardElements(email, lessonId, multiBoardId, multiBoardElements);
        });
    }

    let lesson = lessonList.getLessonForEmail(email, lessonId);
    if (lesson && lesson[multiBoardId]) {
        delete lesson[multiBoardId];
        updateLessonForEmail(email, lessonId, lesson);
    }
    localStorage.removeItem(buildMultiBoardLessonDataName(email, lessonId, multiBoardId));
};

lessonList.migrateAllDataToForage = async (email) => {
    let lessonsInStore = JSON.parse(localStorage.getItem(email));
    if (lessonsInStore) {
        let lessonIdsInStore = Object.keys(lessonsInStore);
        for (let lessonIdIndex = 0; lessonIdIndex < lessonIdsInStore.length; lessonIdIndex++) {
            const lessonId = lessonIdsInStore[lessonIdIndex];
            const lessonInStoreElement = lessonsInStore[lessonId];
            if (lessonInStoreElement) {
                const multiBoardIds = Object.keys(lessonInStoreElement);
                for (let multiBoardIdIndex = 0; multiBoardIdIndex < multiBoardIds.length; multiBoardIdIndex++) {
                    const multiBoardId = multiBoardIds[multiBoardIdIndex];
                    if (lessonList.isSpecialAttribute(multiBoardId)) {
                        // console.log('should migrate pages for lesson ', lessonId);
                        if (lessonInStoreElement.pages && lessonInStoreElement.pages.length > 0) {
                            for (let i = 0; i < lessonInStoreElement.pages.length; i++) {
                                // console.log('moving ', lessonInStoreElement.pages[i]);
                                await moveIndex(lessonInStoreElement.pages[i]);
                            }
                        }
                    } else {
                        const multiLesson = lessonInStoreElement[multiBoardId];
                        if (multiLesson) {
                            // console.log('should migrate multiBoard lesson', multiLesson);
                            if (multiLesson.imageId) {
                                // console.log('moving ', multiLesson.imageId);
                                await moveIndex(multiLesson.imageId);
                            }
                            if (multiLesson.users) {
                                const usersKeys = Object.keys(multiLesson.users);
                                for (let i = 0; i < usersKeys.length; i++) {
                                    // console.log('trying for ', usersKeys[i]);
                                    if (
                                        multiLesson.users &&
                                        multiLesson.users[usersKeys[i]] &&
                                        multiLesson.users[usersKeys[i]].pages &&
                                        multiLesson.users[usersKeys[i]].pages.length > 0
                                    ) {
                                        // console.log('moving ', multiLesson.users[usersKeys[i]].pages[0].id);
                                        await moveIndex(multiLesson.users[usersKeys[i]].pages[0].id);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return Promise.resolve();
};

lessonList.migrateMultiBoardSessionToMultipleClientBoards = (email) => {
    let lessonsInStore = JSON.parse(localStorage.getItem(email));
    if (lessonsInStore) {
        let lessonIdsInStore = Object.keys(lessonsInStore);
        for (let lessonIdIndex = 0; lessonIdIndex < lessonIdsInStore.length; lessonIdIndex++) {
            const lessonId = lessonIdsInStore[lessonIdIndex];
            const lessonInStoreElement = lessonsInStore[lessonId];
            if (lessonInStoreElement) {
                const multiBoardIds = Object.keys(lessonInStoreElement);
                for (let multiBoardIdIndex = 0; multiBoardIdIndex < multiBoardIds.length; multiBoardIdIndex++) {
                    const multiBoardId = multiBoardIds[multiBoardIdIndex];
                    if (!lessonList.isSpecialAttribute(multiBoardId)) {
                        const multiLesson = lessonInStoreElement[multiBoardId];
                        if (multiLesson && multiLesson.users) {
                            const usersKeys = Object.keys(multiLesson.users);
                            let multiBoardState = localStorage.getItem(
                                buildMultiBoardLessonDataName(email, lessonId, multiBoardId)
                            );
                            if (!multiBoardState) {
                                continue;
                            }
                            multiBoardState = JSON.parse(multiBoardState);
                            for (let i = 0; i < usersKeys.length; i++) {
                                console.log('trying for ', usersKeys[i], multiLesson.users[usersKeys[i]]);
                                // continue;
                                if (
                                    multiLesson.users &&
                                    multiLesson.users[usersKeys[i]] &&
                                    multiLesson.users[usersKeys[i]].pages
                                ) {
                                    // should move multiLesson.users[usersKeys[i]].pages;
                                    if (!multiBoardState.users) {
                                        multiBoardState.users = {};
                                    }
                                    if (!multiBoardState.users[usersKeys[i]]) {
                                        multiBoardState.users[usersKeys[i]] = {
                                            pages: multiLesson.users[usersKeys[i]].pages,
                                        };
                                    } else {
                                        multiBoardState.users[usersKeys[i]].pages =
                                            multiLesson.users[usersKeys[i]].pages;
                                    }
                                    if (multiBoardState.users[usersKeys[i]].stickyElements) {
                                        multiBoardState.users[usersKeys[i]].pages[0].stickyElements =
                                            multiBoardState.users[usersKeys[i]].stickyElements;
                                        delete multiBoardState.users[usersKeys[i]].stickyElements;
                                    } else {
                                        multiBoardState.users[usersKeys[i]].pages[0].stickyElements = [];
                                    }
                                    multiBoardState.users[usersKeys[i]].pages[0].gridType = multiBoardState.gridType;
                                    delete multiLesson.users[usersKeys[i]].pages;
                                }
                            }
                            localStorage.setItem(
                                buildMultiBoardLessonDataName(email, lessonId, multiBoardId),
                                JSON.stringify(multiBoardState)
                            );
                            // console.log('=== migrated multiboardstate', multiBoardState, multiLesson.users);
                        }
                    }
                }
            }
        }
    }
    localStorage.setItem(email, JSON.stringify(lessonsInStore));
};

async function moveIndex(index) {
    let data = localStorage.getItem(index);
    if (data) {
        // console.log('removing', index);
        await window.localforage.setItem(index, data);
        localStorage.removeItem(index);
        // console.log('removed', index);
    } else {
        console.log('item not found');
    }
}

export async function getItem(index) {
    let data;
    data = await window.localforage.getItem(index);
    if (!data) {
        data = localStorage.getItem(index);
    }
    return data;
}

export async function updateItem(index, data) {
    await window.localforage.setItem(index, data);
}

export async function removeItem(index) {
    try {
        await window.localforage.removeItem(index);
        localStorage.removeItem(index);
        localStorage.removeItem(index + '-type');
        let undoKeys = lessonList.getUndoKeys(index);
        for (let i = 0; i < undoKeys.length; i++) {
            await window.localforage.removeItem(undoKeys[i]);
        }
        localStorage.removeItem(`${index}-undoList`);
    } catch (ex) {
        console.error('Error removing page from localstorage', index);
    }
}

lessonList.removeItem = async (index) => {
    return removeItem(index);
};

export default lessonList;
