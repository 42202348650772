import React, { useEffect, useState } from 'react';
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { GA_EVENT_TREE } from '../../gaConstants';
import { COLORS2 } from '../../constants';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import CreateClassAction from '../ManageClass/CreateClassAction';
import ResponsiveTable from '../../MyComponents/ResponsiveTable/ResponsiveTable';
import { buildDesktopMeta } from '../ManageClass/ManageClassDesktopMeta';
import { buildMobileMeta } from '../ManageClass/ManageClassMobileMeta';
import { buildTabletMeta } from '../ManageClass/ManageClassTabletMeta';
import { determineDefaultLanguage } from '../../common/utils';
import loadClassesHook from '../../MyComponents/Hooks/loadClassesHook';
import { FormattedMessage } from 'react-intl';
import collectionsApi from '../../Api/collectionsApi';
import { SeeAllGroupsButton } from './HomeFeatures/SeeAllGroupsButton';
import { FindMoreButton } from './HomeFeatures/FindMoreButton';
import MainActivityBlock from '../Welcome/MainActivityBlock';

const useStyles = makeStyles({
    wrap: {
        minHeight: '27vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgb(12, 169, 180, 0.1)',
    },
    welcomeMessageBox: {
        textAlign: 'left',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-start',
    },
    welcomeText: {
        fontSize: '1.25rem',
        fontStyle: 'normal',
        fontWeight: '400',
        letterSpacing: '-0.01em',
        margin: '0.5rem 0rem 0.5rem 0rem',
        color: COLORS2.darkGreen,
    },
    welcomeTextBold: {
        fontSize: '1.25rem',
        fontStyle: 'normal',
        letterSpacing: '-0.01em',
        color: COLORS2.darkGreen,
        fontWeight: 700,
        margin: '0.5rem 0rem 0.5rem 0.25rem',
    },
    welcomeMessageWebBox: {
        textAlign: 'left',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-start',
        marginTop: '-3.75rem',
        marginLeft: '5rem',
    },
});

const Home = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const [boardCount, setBoardCount] = useState();
    const { dataAll, isLoading, isError, setIsError } = loadClassesHook(props.allowedGroups);
    const modifiedDataAll = dataAll.slice(0, 3);

    const loadCollections = async () => {
        try {
            // setIsLoading(true);
            let boards = await Promise.all([collectionsApi.getBoardsCount()]);
            if (boards && boards.length > 0 && boards[0].data) {
                setBoardCount(boards[0].data.count);
            }
        } catch (err) {
            console.log(err);
            // setIsLoading(false);
            setIsError(true);
        }
    };

    let lessonStart = function(classId) {
        window.logAppActivity(GA_EVENT_TREE.manageGroups.actions.start);
        history.push('/lesson/' + classId);
    };

    let localesOptions = 'ro-RO';
    let currentLanguage = determineDefaultLanguage();
    if (currentLanguage === 'en') {
        localesOptions = 'en-EN';
    } else if (currentLanguage === 'fr') {
        localesOptions = 'fr-FR';
    } else if (currentLanguage === 'ro') {
        localesOptions = 'ro-RO';
    }

    useEffect(() => {
        loadCollections();
    }, []);

    return (
        <>
            {isLoading ? null : (
                <Grid item xs={12} sm={12}>
                    <Typography variant='h2'>
                        <FormattedMessage
                            id={'redesign.home.loggedIn.welcomeProfileGivenName'}
                            values={{ givenName: props.profile.extended.first_name }}
                        />
                    </Typography>
                </Grid>
            )}
            <Grid item xs={12} sm={12}>
                <CreateClassAction />
            </Grid>
            {boardCount && boardCount > 0 ? (
                <Grid
                    item
                    xs={12}
                    sm={12}
                    style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                    {boardCount == 1 ? (
                        <Typography variant='h4'>
                            <FormattedMessage id={'redesign.home.loggedIn.oneBoardsCountMessage'} />
                        </Typography>
                    ) : (
                        <Typography variant='h4'>
                            <FormattedMessage
                                id={'redesign.home.loggedIn.moreBoardsCountMessage'}
                                values={{ boardsCount: boardCount }}
                            />
                        </Typography>
                    )}
                    <Link to={`/library`}>
                        <Button color='primary' variant={'contained'}>
                            <FormattedMessage id='redesign.home.loggedIn.boardsCountRedirectButton' />
                        </Button>
                    </Link>
                </Grid>
            ) : (
                <Grid
                    item
                    xs={12}
                    sm={12}
                    style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                    <Typography variant='h4'>
                        <FormattedMessage
                            id={'redesign.home.loggedIn.noBoardsCountMessage'}
                            values={{ boardsCount: boardCount }}
                        />
                    </Typography>
                    <Link to={`/tutorial?feature=manageLibrary`}>
                        <FindMoreButton />
                    </Link>
                </Grid>
            )}
            {dataAll && dataAll.length > 0 ? (
                <Grid item xs={12} sm={12}>
                    <ResponsiveTable
                        data={modifiedDataAll}
                        metaDesktop={buildDesktopMeta(
                            classes,
                            lessonStart,
                            props.profile,
                            props.profile.email,
                            localesOptions,
                            () => {
                            },
                            true,
                        )}
                        metaMobile={buildMobileMeta(
                            props.profile,
                            props.profile.email,
                            lessonStart,
                            localesOptions,
                            () => {
                            },
                            true,
                        )}
                        metaTablet={buildTabletMeta(
                            props.profile,
                            props.profile.email,
                            lessonStart,
                            localesOptions,
                            () => {
                            },
                            true,
                        )}
                        editStyle={true}
                    />
                    {dataAll.length > 3 ? (
                        <Link to='/manageClasses'>
                            <SeeAllGroupsButton />
                        </Link>
                    ) : null}
                </Grid>
            ) : null}
            <MainActivityBlock />
        </>
    );
};

const mapStateToProps = (state) => {
    const { profile, mainAppState } = state;
    return { allowedGroups: profile.allowedGroups, profile, subscriptions: mainAppState.subscriptions };
};

export default connect(mapStateToProps)(Home);
