import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import LanguageSelector from './LanguageSelector';
import { Route, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import SideMenu from './SideMenu';
import Backdrop from '@material-ui/core/Backdrop';
import store from '../../redux/store';
import { hideNotification } from '../../redux/actions';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { FormattedMessage, injectIntl } from 'react-intl';
import ParticipantsList from './ParticipantsList';

import LessonPage from '../../pages/LessonPage/LessonPage';
import Fab from '@material-ui/core/Fab';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Avatar from '@material-ui/core/Avatar';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Typography } from '@material-ui/core';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import MultiBoardList from './MultiBoardList';
import { BOARD_TYPE, COLORS2 } from '../../constants';
import SpinnerContentRenderer from './SpinnerContentRenderer';
import { handleLogout } from '../../common/utils';
import { CONTACT_US_LINK, PROFILE_LINK } from '../../common/urlLinks';
import VBIcon from '../icons/VBIcon';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        // [theme.breakpoints.up('lg')]: {
        //     width: drawerWidth,
        //     flexShrink: 0,
        // },
    },
    menuButton: {
        position: 'fixed',
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        height: 'calc(100vh - 4rem)',
        width: '17rem',
        borderRight: 'none',
        margin: '1.5rem 0rem',
        borderRadius: '0px 32px 32px 0px',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(0),
        overscrollBehaviorY: 'contain',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    logoImage: {
        height: '2.2rem',
        width: '2.2rem',
        marginRight: '0.5rem',
    },
    logoContainer: {
        margin: '1rem 0rem 0rem 0.5rem',
        display: 'flex',
        alignItems: 'center',
    },
    split: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
        overflow: 'auto',
    },
    entireSideMenu: {
        minHeight: '100%',
        borderRadius: '0rem 1rem 1rem 0rem',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    divider:{
        background: '#D26828',
        opacity: '0.1',
    },
}));

const LessonDrawer = (props) => {
    const { container } = props;
    let history = useHistory();
    const classes = useStyles();

    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleContact = (closeFunction) => {
        history.push(CONTACT_US_LINK);
        closeFunction();
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        store.dispatch(hideNotification());
    };

    const handleProfile = (closeFunction) => {
        history.push(PROFILE_LINK);
        closeFunction();
    };

    const buildDrawer = (closeFunction) => {
        return (
            <div className={classes.entireSideMenu}>
                <div className={classes.toolbar}>
                    <div className={classes.logoContainer}>
                        <VBIcon style={{ width: '2rem', height: '2rem' }} />
                        {props.lessonState.lessonInfo && props.lessonState.lessonInfo.classData ? (
                            <Typography
                                variant="subtitle1"
                                display="inline"
                                align="center"
                                style={{ width: '100%', marginRight: '1rem' }}
                            >
                                {props.lessonState.lessonInfo.classData.name}
                            </Typography>
                        ) : null}
                    </div>
                </div>
                <Divider className={classes.divider}/>
                <div className={classes.split}>
                    <div>
                        <SideMenu closeDrawer={closeFunction} />
                        {props.lessonState.isAdmin && props.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD ? (
                            <MultiBoardList />
                        ) : null}
                        <ParticipantsList />
                    </div>
                    <div>
                        <List>
                            <ListItem
                                button
                                onClick={() => {
                                    handleContact(closeFunction);
                                }}
                            >
                                <ListItemIcon>
                                    <ContactSupportIcon style={{ color: COLORS2.virtualGreen }} />
                                </ListItemIcon>
                                <Typography variant="subtitle1">
                                    <FormattedMessage id="contactus" />
                                </Typography>
                            </ListItem>
                        </List>
                        <Divider className={classes.divider}/>
                        <LanguageSelector updateLanguage={props.updateLanguage} closeDrawer={closeFunction} />
                        <Route
                            render={({ location }) => {
                                return location.pathname.indexOf('/lesson') === -1 ? (
                                    <>
                                        <Divider className={classes.divider}/>
                                        <List>
                                            <ListItem
                                                button
                                                onClick={() => {
                                                    handleProfile(closeFunction);
                                                }}
                                            >
                                                <ListItemIcon>
                                                    <AccountCircleIcon color="primary" />
                                                </ListItemIcon>
                                                <ListItemText primary={props.intl.formatMessage({ id: 'profile' })} />
                                                {props.profile.avatar ? (
                                                    <Avatar src={props.profile.avatar} />
                                                ) : (
                                                    <AccountCircle />
                                                )}
                                            </ListItem>
                                        </List>
                                        <Divider className={classes.divider}/>
                                        <List>
                                            <ListItem button onClick={handleLogout}>
                                                <ListItemIcon>
                                                    <ExitToAppIcon color="primary" />
                                                </ListItemIcon>
                                                <ListItemText primary={props.intl.formatMessage({ id: 'logout' })} />
                                            </ListItem>
                                        </List>
                                        <Divider className={classes.divider}/>
                                        <List>
                                            <ListItem button>
                                                <a href="https://forms.gle/zTDQM7AjrkLrbwqU6">
                                                    <ListItemText primary={'Contact'} />
                                                </a>
                                            </ListItem>
                                        </List>
                                    </>
                                ) : null;
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    };

    let notifMessage = '';
    if (props.appState.notificationMessage) {
        if (typeof props.appState.notificationMessage === 'string') {
            notifMessage = props.intl.formatMessage({
                id: 'notification.code.' + props.appState.notificationMessage,
            });
        } else {
            notifMessage = props.intl.formatMessage(
                {
                    id: 'notification.code.' + props.appState.notificationMessage.messageKey,
                },
                props.appState.notificationMessage.data
            );
        }
    }
    return (
        <div className={classes.root}>
            <CssBaseline />
            <nav className={classes.drawer}>
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    {buildDrawer(handleDrawerToggle)}
                </Drawer>
            </nav>
            <main className={classes.content}>
                <div className={classes.menuButton}>
                    <Fab
                        size="small"
                        aria-label="menu"
                        onClick={handleDrawerToggle}
                        aria-controls="pen-menu"
                        aria-haspopup="true"
                    >
                        <MenuIcon />
                    </Fab>
                </div>

                <LessonPage />

                <Backdrop className={classes.backdrop} open={props.appState.loading}>
                    <SpinnerContentRenderer content={props.appState.loadingData} />
                </Backdrop>
                {props.appState.isNotificationOpen ? (
                    <Snackbar
                        open={props.appState.isNotificationOpen}
                        autoHideDuration={6000}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Alert onClose={handleClose} severity={props.appState.notificationType}>
                            {notifMessage}
                        </Alert>
                    </Snackbar>
                ) : null}
            </main>
        </div>
    );
};

const mapStateToProps = (state) => {
    const { profile, appState, lessonState } = state;
    return { profile, appState, lessonState };
};

export default injectIntl(connect(mapStateToProps)(LessonDrawer));
