import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import SectionChapter from '../../Tutorial/SectionChapter';
import SectionHeader from '../../Tutorial/SectionHeader';
import { tutorialLinks } from '../../../common/urlLinks';
import { FindMoreButton } from '../../Home/HomeFeatures/FindMoreButton';

const PasteFeature = ({ refs }) => {
    const paste = [
        {
            children: (
                <>
                    <ul>
                        <li>
                            <Typography variant="h6">
                                <FormattedMessage id="page.home.feature.paste.description.1" />
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="h6">
                                <FormattedMessage id="page.home.feature.paste.description.2" />
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="h6">
                                <FormattedMessage id="page.home.feature.paste.description.3" />
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="h6">
                                <FormattedMessage id="page.home.feature.paste.description.4" />
                            </Typography>
                        </li>
                    </ul>
                    <a href={tutorialLinks.imageMenu}>
                        <FindMoreButton />
                    </a>
                </>
            ),
        },
    ];
    return (
        <>
            <SectionHeader
                refs={refs}
                title={'page.home.feature.paste.tag'}
                id={'section-paste'}
                img={`/static/asset/img/features/lipiti_informatii.png`}
            />
            <SectionChapter
                refs={refs}
                id={'section-paste-section'}
                title={'page.home.feature.paste.description'}
                explanations={paste}
            />
        </>
    );
};

export default PasteFeature;
