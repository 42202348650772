import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { COLORS2 } from '../../constants';

const useStyles = makeStyles(() => ({
    table: {
        // minWidth: 650,
    },
    disableRow: {
        background: 'rgba(210, 104, 40, 0.5)',
    },
    enableRow: {},
    centerMobileCell: { display: 'flex', alignItems: 'center', paddingBottom: '0.5rem' },
    tableBegin: {
        textAlign: 'start',
        borderColor: 'rgba(210, 104, 40, 0.1)',
    },
    tableEnd: {
        textAlign: 'end',
        borderColor: 'rgba(210, 104, 40, 0.1)',
    },
    tableMiddle: {
        textAlign: 'left',
        borderColor: 'rgba(210, 104, 40, 0.1)',
    },
}));

const DesktopTable = ({ data, meta, styled, editStyle }) => {
    const classes = useStyles();

    return (
        <TableContainer component={Paper} style={{ width: '100%', marginBottom: '1.5rem', boxShadow: editStyle ? '0rem 2.5rem 10rem rgba(210, 104, 40, 0.08)' : 'none', borderRadius: editStyle ? '2rem' : '0rem', padding: editStyle ? '1rem 1rem 0rem 1rem' : '0rem' }}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {meta.map((el, index) => {
                            let cls = classes.tableBegin;
                            if(el.align === 'start'){
                                cls = classes.tableBegin;
                            }
                            if(el.align === 'middle'){
                                cls = classes.tableMiddle;
                            }
                            if(el.align === 'end'){
                                cls = classes.tableEnd;
                            }
                            return (
                                <TableCell
                                    key={`desktopTableHead-${index}`}
                                    className={ cls }
                                >
                                    <Typography
                                        style={{
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            fontSize: '0.625rem',
                                            lineHeight: '1rem',
                                            letterSpacing: '0.20rem',
                                            textTransform: 'uppercase',
                                            color: COLORS2.virtualText,
                                        }}
                                    >
                                        <FormattedMessage id={meta[index].header} />
                                    </Typography>
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row) => {
                        if (styled) {
                            return (
                                <TableRow
                                    key={row.id}
                                    className={row.enabled !== false ? classes.enableRow : classes.disableRow}
                                >
                                    {meta.map((el, index) => {
                                        let cls = classes.tableBegin;
                                        if(el.align === 'start'){
                                            cls = classes.tableBegin;
                                        }
                                        if(el.align === 'middle'){
                                            cls = classes.tableMiddle;
                                        }
                                        if(el.align === 'end'){
                                            cls = classes.tableEnd;
                                        }
                                        return (
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                key={`desktopRow-${row.id}-${index}`}
                                                className={cls}
                                            >
                                                {meta[index].build(row)}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        } else {
                            return (
                                <TableRow key={row.id} className={row.enabled ? classes.enableRow : classes.disableRow}>
                                    {meta.map((el, index) => {
                                        let cls = classes.tableBegin;
                                        if(el.align === 'start'){
                                            cls = classes.tableBegin;
                                        }
                                        if(el.align === 'middle'){
                                            cls = classes.tableMiddle;
                                        }
                                        if(el.align === 'end'){
                                            cls = classes.tableEnd;
                                        }
                                        return (
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                key={`desktopRow-${row.id}-${index}`}
                                                className={cls}
                                            >
                                                {meta[index].build(row)}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        }
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default DesktopTable;
