import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function StartLessonIcon(props) {
    return (
        <SvgIcon {...props} style={{ verticalAlign: 'bottom' }}>
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.2797 6.97031C12.9867 6.67734 12.5119 6.67734 12.2194 6.97031C11.9269 7.26328 11.9264 7.73813 12.2194 8.03063L15.4406 11.25H6.75C6.3375 11.25 6 11.5875 6 12C6 12.4125 6.33544 12.75 6.75 12.75H15.4406L12.2208 15.9698C11.9278 16.2628 11.9278 16.7377 12.2208 17.0302C12.5138 17.3227 12.9886 17.3231 13.2811 17.0302L17.7811 12.5302C17.925 12.3844 18 12.1922 18 12C18 11.8078 17.9268 11.6162 17.7803 11.4698L13.2797 6.97031ZM12 0C5.37188 0 0 5.37188 0 12C0 18.6281 5.37188 24 12 24C18.6281 24 24 18.6281 24 12C24 5.37188 18.6281 0 12 0ZM12 22.5C6.21094 22.5 1.5 17.7891 1.5 12C1.5 6.21094 6.21094 1.5 12 1.5C17.7891 1.5 22.5 6.21094 22.5 12C22.5 17.7891 17.7891 22.5 12 22.5Z" fill="#08AAB4"/>
            </svg>
        </SvgIcon>
    );
}
