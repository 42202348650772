import React, { useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Container, IconButton, InputAdornment, TextField } from '@material-ui/core';
import GenericState from '../../MyComponents/GenericLoadingErrorState/GenericState';
import analyticsApi from '../../Api/analyticsApi';
import PaginatedTable from '../../MyComponents/PaginatedTable/PaginatedTable';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import Avatar from '@material-ui/core/Avatar';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
    toolbar: theme.mixins.toolbar,
}));

const loginColumns = [
    { id: 'email', label: 'Email' },
    { id: 'logincount', label: 'Login count' },
    { id: 'authtype', label: 'Login type' },
    {
        id: 'latestlogin',
        label: 'Latest login',
        render: function (val) {
            let d = new Date(val);
            return d.toLocaleString();
        },
    },
];
const samsungColumns = [{ id: 'SN', label: 'SN' }];
const lessonsWithUserCountColumns = [
    { id: 'name', label: 'Name' },
    { id: 'usercount', label: 'User count' },
    {
        id: 'starttime',
        label: 'Start time',
        render: function (val) {
            let d = new Date(val);
            return d.toLocaleString();
        },
    },
    {
        id: 'duration',
        label: 'Duration',
        render: function (val) {
            let s = '';
            if (val) {
                if (val.hours) {
                    s += val.hours + ' h ';
                }
                if (val.minutes) {
                    s += val.minutes + ' min ';
                }
                if (val.seconds) {
                    s += val.seconds + ' sec ';
                }
            }
            return s;
        },
    },
];

const lessonsByAdminsColumns = [
    {
        id: 'avatar',
        label: 'User',
        render: function (val) {
            if (val) {
                return <Avatar src={val} />;
            }
            return <AccountCircle />;
        },
    },
    { id: 'email', label: 'Email' },
    { id: 'first_name', label: 'First Name' },
    { id: 'last_name', label: 'Last Name' },
    { id: 'name', label: 'Name' },
    {
        id: 'starttime',
        label: 'Start time',
        render: function (val) {
            let d = new Date(val);
            return d.toLocaleString();
        },
    },
    {
        id: 'duration',
        label: 'Duration',
        render: function (val, o) {
            let s = '';
            if (val) {
                let totalDiff = 0;

                if (val.hours) {
                    s += val.hours + ' h ';
                    totalDiff += val.hours * 60 * 60 * 1000;
                }
                if (val.minutes) {
                    s += val.minutes + ' min ';
                    totalDiff += val.minutes * 60 * 1000;
                }
                if (val.seconds) {
                    s += val.seconds + ' sec ';
                    totalDiff += val.seconds * 1000;
                }
                let current = new Date(o.starttime).getTime();

                let dateEnded = current + totalDiff;

                let now = new Date().getTime();

                if ((now - dateEnded) / 1000 < 60) {
                    s += ' LIVE';
                }
            }
            return s;
        },
    },
];
const Analytics = () => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = React.useState(true);
    const [isError] = React.useState(false);
    const [rows, setRows] = React.useState([]);
    const [count, setCount] = React.useState(0);

    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('latestlogin');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [type, setType] = React.useState('logins');

    const [columns, setColumns] = React.useState(loginColumns);
    const [sn, setSn] = React.useState('');

    const handleAnalyticsType = (event, newType) => {
        setType(newType);
        if (newType === 'logins') {
            setColumns([...loginColumns]);
        }
        if (newType === 'lessonsWithUserCount') {
            setColumns([...lessonsWithUserCountColumns]);
        }
        if (newType === 'lessonsByAdmins') {
            setColumns([...lessonsByAdminsColumns]);
        }
        if (newType === 'samsung') {
            setColumns([...samsungColumns]);
        }
        setPage(0);
    };

    useEffect(() => {
        setIsLoading(true);
        if (type === 'logins') {
            analyticsApi.getLogins(rowsPerPage, page, orderBy, order).then((res) => {
                setIsLoading(false);
                setRows(res.data.logins);
                setCount(res.data.count);
            });
        }
        if (type === 'lessonsWithUserCount') {
            analyticsApi.getLessonsWithUserCount(rowsPerPage, page, orderBy, order).then((res) => {
                setIsLoading(false);
                setRows(res.data.lessons);
                setCount(res.data.count);
            });
        }
        if (type === 'lessonsByAdmins') {
            analyticsApi.getLessonsByAdmins(rowsPerPage, page, orderBy, order).then((res) => {
                setIsLoading(false);
                setRows(res.data.lessons);
                setCount(res.data.count);
            });
        }
        if (type === 'samsung') {
            analyticsApi.getSamsungAccounts(rowsPerPage, page, orderBy, order).then((res) => {
                setIsLoading(false);
                setRows(res.data.samsungDevices);
                setCount(res.data.count);
            });
        }
    }, [order, orderBy, page, rowsPerPage, type]);

    if (isLoading) {
        return <GenericState isLoading={true} />;
    }
    if (isError) {
        return <GenericState isError={true} />;
    }

    const setOrderFlags = (order, orderBy) => {
        setOrder(order);
        setOrderBy(orderBy);
    };
    const updateText = (e) => {
        setSn(e.target.value);
    };

    return (
        <React.Fragment>
            <CssBaseline />
            <div className={classes.toolbar} />
            <Container>
                <ToggleButtonGroup value={type} exclusive onChange={handleAnalyticsType}>
                    <ToggleButton value="logins">
                        <Typography variant={'h6'}>Logins</Typography>
                    </ToggleButton>
                    <ToggleButton value="lessonsWithUserCount">
                        <Typography variant={'h6'}>Lessons with user count</Typography>
                    </ToggleButton>
                    <ToggleButton value="lessonsByAdmins">
                        <Typography variant={'h6'}>Lessons by admins</Typography>
                    </ToggleButton>
                    <ToggleButton value="samsung">
                        <Typography variant={'h6'}>Samsung Devices</Typography>
                    </ToggleButton>
                </ToggleButtonGroup>

                {type === 'samsung' ? (
                    <Container>
                        <TextField
                            value={sn}
                            label={'SN'}
                            onChange={updateText}
                            variant="outlined"
                            size={'large'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => {
                                                analyticsApi.addSamsungSN({ sn }).then(() => {
                                                    setSn('');
                                                    handleAnalyticsType(null, 'logins');
                                                });
                                            }}
                                        >
                                            <AddIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Container>
                ) : null}
                <PaginatedTable
                    headCells={columns}
                    rows={rows}
                    rowCount={count}
                    order={order}
                    orderBy={orderBy}
                    setOrder={setOrderFlags}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                />
            </Container>
        </React.Fragment>
    );
};

export default Analytics;
