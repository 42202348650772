import { makeStyles } from "@material-ui/core";
import { COLORS, COLORS2 } from '../../../../constants';

const styles = makeStyles({
    tutorialRoot: {
        '& hr': {
            width: '100%',
            margin: '1rem 0 5rem 0',
        },

        '& img': {
            width: '100%',
            border: '1px solid lightgray',
        },
        '& b': {
            padding: '0px',
        },
        backgroundColor: '#FFF',
        marginTop: '2.5rem',
        borderRadius: '16px',
        // paddingTop: '2rem',
        // paddingLeft: '0px',
        // paddingRight: '0px',
        margin: '0'
    },

    sampleFab: {
        '& span': {
            padding: '0px',
        },

        '& svg': {
            padding: '0px',
            verticalAlign: 'middle',
        },
        verticalAlign: 'middle',
        width: '36px',
        height: '36px',
        // padding: '4px',
        color: COLORS.primary,
        padding: '0px',
        marginLeft: '1rem',
    },
    tutorialTabletTitle : {
        fontWeight: '500',
        fontSize: '1.5rem',
        fontStyle: 'normal',
        margin: '2.5rem 0rem 0.5rem 2rem',
        lineHeight: '2rem',
        color: COLORS2.darkGreen,
        width: '5rem',
        letterSpacing: '-0.01em',
    }
});

export default styles;