import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function SharedFolderIcon(props) {
    return (
        <SvgIcon {...props} style={{ verticalAlign: 'bottom', fontSize: '1.8rem' }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                    fill="#fcd09b"
                    d="M20 6h-8l-1.41-1.41C10.21 4.21 9.7 4 9.17 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-5 3c1.1 0 2 .9 2 2s-.9 2-2 2s-2-.9-2-2s.9-2 2-2zm4 8h-8v-1c0-1.33 2.67-2 4-2s4 .67 4 2v1z"
                />
            </svg>
        </SvgIcon>
    );
}
