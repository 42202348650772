import React from 'react';
import Slider from '@material-ui/core/Slider';
import Grid from '@material-ui/core/Grid';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import IconButton from '@material-ui/core/IconButton';

const STEP_FACTOR = 25;

export default function HorizontalSlider(props) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        if (value !== newValue) {
            setValue(newValue);
            props.updateValue(newValue);
        }
    };

    window.forceHorizSlider = setValue;

    const stepUp = () => {
        if (value + STEP_FACTOR > 100) {
            handleChange(null, 100);
        } else {
            handleChange(null, value + STEP_FACTOR);
        }
    };

    const stepDown = () => {
        if (value - STEP_FACTOR < 0) {
            handleChange(null, 0);
        } else {
            handleChange(null, value - STEP_FACTOR);
        }
    };

    return (
        <Grid container spacing={0} alignItems="center">
            <Grid item>
                <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color="inherit"
                    size="small"
                    onClick={stepDown}
                >
                    <ChevronLeftIcon />
                </IconButton>
            </Grid>
            <Grid item xs>
                <Slider value={value} onChange={handleChange} aria-labelledby="continuous-slider" />
            </Grid>
            <Grid item>
                <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color="inherit"
                    size="small"
                    onClick={stepUp}
                >
                    <ChevronRightIcon />
                </IconButton>
            </Grid>
        </Grid>
    );
}
