import React from 'react';
import Divider from '@material-ui/core/Divider';
import { Container, Grid, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import Sticky from 'react-stickynode';
import TutorialLibrary from '../../Sections/TutorialLibrary';
import GroupManagement from '../../Sections/GroupManagement';
import LessonManagement from '../../Sections/LessonManagement';
import IndividualBoardManagement from '../../Sections/IndividualBoardManagement';
import MobileManagement from '../../Sections/MobileManagement';
import styles from './styles';
import DesktopSummaryMenu from '../../../../MyComponents/SummaryMenu/DesktopSummaryMenu/DesktopSummaryMenu';
import { sections } from '../../constants';
import { UPDATE_TUTORIAL_ACTIVE_SECTION } from '../../../../redux/actionTypes';
import { useDispatch } from 'react-redux';

const TutorialDesktopPublic = ({ language, refs }) => {
    const classes = styles();
    const dispatch = useDispatch();

    return (
        <>
            <Grid container style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                <Grid item xs={10} sm={10} md={10} xl={10} style={{ paddingRight: '3rem' }}>
                    <Typography variant="h1">
                        <FormattedMessage id="redesign.page.tutorial.title1" />
                    </Typography>
                    <Typography variant="h1">
                        <FormattedMessage
                            id="redesign.page.tutorial.title2"
                            values={{
                                b: (chunks) => (
                                    <b>
                                        {chunks}
                                        <br></br>
                                    </b>
                                ),
                            }}
                        />
                    </Typography>
                </Grid>
                <Grid item xs={2} sm={2} md={2} xl={2} style={{ paddingRight: '3rem' }} />
            </Grid>
            <Divider variant="middle" />

            <Container
                id="section-tutorialStart"
                maxWidth="xl"
                className={classes.tutorialRoot}
                ref={(el) => {
                    if (el && el.id) {
                        refs.current[el.id.toLowerCase()] = el;
                    }
                }}
            >
                <Grid container>
                    <Grid item xs={8} sm={8} md={8} xl={8} style={{ paddingRight: '3rem' }}>
                        <GroupManagement language={language} classes={classes} refs={refs} />
                        <Divider variant="middle" />
                        <LessonManagement language={language} classes={classes} refs={refs} />
                        <Divider variant="middle" />
                        <IndividualBoardManagement language={language} classes={classes} refs={refs} />
                        <Divider variant="middle" />
                        <TutorialLibrary language={language} classes={classes} refs={refs} />
                        <Divider variant="middle" />
                        <MobileManagement language={language} classes={classes} refs={refs} />
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} xl={4}>
                        <Sticky enabled={true} top={8}>
                            <DesktopSummaryMenu
                                sections={sections}
                                path={'/tutorial'}
                                updateFunction={(id) => {
                                    dispatch({ type: UPDATE_TUTORIAL_ACTIVE_SECTION, payload: { sectionId: id } });
                                }}
                                selectorFunc={(state) => state.tutorial.sectionId}
                            />
                        </Sticky>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default TutorialDesktopPublic;
