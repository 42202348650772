import { combineReducers } from 'redux';
import profile from './profile';
import drawing from './drawing';
import appState from './appState';
import lessonState from './lessonState';
import tutorial from './tutorial';
import mainAppState from './mainAppState';
import userMessages from './userMessages';
import features from './features';

export default combineReducers({
    profile,
    drawing,
    appState,
    lessonState,
    tutorial,
    features,
    mainAppState,
    userMessages,
});
