import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import StartGroupIcon from '../icons/StartGroupIcon';
import Button from '@material-ui/core/Button';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    startGroup: {
        width: '23.188rem',
        height: '4.5rem',
    },
}));

export const StartGroupButton = ({ handleOpen }) => {
    const classes = useStyles();

    return (
        <Button className={classes.startGroup} onClick={handleOpen} size="large" color="primary" variant="contained">
            <Typography
                style={{
                    fontStyle: 'normal',
                    fontWeight: '700',
                    fontSize: '1rem',
                    lineHeight: '1.188rem',
                    letterSpacing: '0.16em',
                    textTransform: 'uppercase',
                    color: '#FFFFFF',
                }}
            >
                <FormattedMessage id="redesign.page.home.startGroup" />
            </Typography>
            <StartGroupIcon
                style={{
                    width: '1.875rem',
                    height: '1.375rem',
                    marginTop: '0.563rem',
                    marginLeft: '0.563rem',
                    left: '19.438rem',
                    top: '6.25rem',
                }}
            />
        </Button>
    );
};
