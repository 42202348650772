import React, { useEffect, useRef } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Chip, Divider, Grid, Paper, TextField, Typography } from '@material-ui/core';

import GenericState from '../../../MyComponents/GenericLoadingErrorState/GenericState';
import collectionsApi from '../../../Api/collectionsApi';
import { LIBRARY_MANAGER_STATE } from '../../../constants';
import { Autocomplete } from '@material-ui/lab';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { difference } from 'lodash';
import { AddBoxOutlined } from '@material-ui/icons';
import drawUtils from '../../LessonPage/drawUtils';
import SlideShow from '../../../MyComponents/SlideShow/SlideShow';
import GreenFolderIcon from '../../../MyComponents/icons/GreenFolderIcon';
import { makeStyles } from '@material-ui/core/styles';
import SingleBoardIconRedesign from '../../../MyComponents/icons/SingleBoardIconRedesign';
import BoardSet from '../../../MyComponents/icons/BoardSet';

const filter = createFilterOptions();

const useStyles = makeStyles(() => ({
    customDivider: {
        '&.MuiDivider-root': {
            backgroundColor: 'rgba(210, 104, 40, 0.1)',
            margin: '0.5rem 2rem 1.5rem 2rem',
        },
    },
    customPaperSlideShow: {
        borderRadius: '1rem',
        boxShadow: 'none',
        padding: '0rem !important',
        minHeight: '150px',
    },
}));

const DesktopCollectionEditor = ({
                                     selectedNode,
                                     intl,
                                     tags,
                                     attachTag,
                                     createAndAttachTag,
                                     removeTag,
                                     create = false,
                                     state = LIBRARY_MANAGER_STATE.MANAGE,
                                 }) => {
    const classes = useStyles();
    const [name, setName] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [boards, setBoards] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isError, setIsError] = React.useState(false);
    const dataSets = useRef({
        boardsToRender: {},
    });

    useEffect(() => {
        if (selectedNode && !create) {
            loadCollection(selectedNode);
        }
        if (create) {
            setName('');
            setDescription('');
        }
    }, [selectedNode, create]);

    const loadCollection = (selectedNode) => {
        if (selectedNode.isCollection) {
            if (selectedNode.isUserCollection) {
                setIsLoading(true);
                collectionsApi
                    .getSharedCollection(selectedNode.id)
                    .then((res) => {
                        setName(res.data.name ? res.data.name : '');
                        setDescription(res.data.description ? res.data.description : '');
                        if (res.data.boards && res.data.boards.length > 0) {
                            let promises = [];
                            for (let i = 0; i < res.data.boards.length; i++) {
                                let b = res.data.boards[i];
                                if (b.meta && !dataSets.current.boardsToRender[b.id]) {
                                    promises.push(
                                        drawUtils
                                            .buildComplexComposedImage(
                                                b.meta.gridType,
                                                null,
                                                null,
                                                b.meta.stickyElements,
                                                b.mainUrl,
                                                null,
                                            )
                                            .then((data) => {
                                                if (data) {
                                                    dataSets.current.boardsToRender[b.id] = data;
                                                } else {
                                                    dataSets.current.boardsToRender[b.id] = null;
                                                }
                                            }),
                                    );
                                }
                            }
                            return Promise.all(promises).then(() => {
                                setBoards(res.data.boards);
                                setIsLoading(false);
                            });
                        } else {
                            setBoards([]);
                            setIsLoading(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        setIsError(true);
                        setIsLoading(false);
                    });
            } else {
                setIsLoading(true);
                collectionsApi
                    .getCollection(selectedNode.id)
                    .then((res) => {
                        setName(res.data.name ? res.data.name : '');
                        setDescription(res.data.description ? res.data.description : '');
                        if (res.data.boards && res.data.boards.length > 0) {
                            let promises = [];
                            for (let i = 0; i < res.data.boards.length; i++) {
                                let b = res.data.boards[i];
                                if (b.meta && !dataSets.current.boardsToRender[b.id]) {
                                    promises.push(
                                        drawUtils
                                            .buildComplexComposedImage(
                                                b.meta.gridType,
                                                null,
                                                null,
                                                b.meta.stickyElements,
                                                b.mainUrl,
                                                null,
                                            )
                                            .then((data) => {
                                                if (data) {
                                                    dataSets.current.boardsToRender[b.id] = data;
                                                } else {
                                                    dataSets.current.boardsToRender[b.id] = null;
                                                }
                                            }),
                                    );
                                }
                            }
                            return Promise.all(promises).then(() => {
                                setBoards(res.data.boards);
                                setIsLoading(false);
                            });
                        } else {
                            setBoards([]);
                            setIsLoading(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        setIsError(true);
                        setIsLoading(false);
                    });
            }
        } else if (selectedNode.isUserBoard) {
            setIsLoading(true);
            collectionsApi
                .getSharedBoard(selectedNode.id)
                .then((res) => {
                    setName(res.data.name ? res.data.name : '');
                    setDescription(res.data.description ? res.data.description : '');
                    if (!dataSets.current.boardsToRender[selectedNode.id]) {
                        drawUtils
                            .buildComplexComposedImage(
                                res.data.meta.gridType,
                                null,
                                null,
                                res.data.meta.stickyElements,
                                res.data.mainUrl,
                                null,
                            )
                            .then((data) => {
                                if (data) {
                                    dataSets.current.boardsToRender[selectedNode.id] = data;
                                } else {
                                    dataSets.current.boardsToRender[selectedNode.id] = null;
                                }
                                setBoards([res.data]);
                                setIsLoading(false);
                            });
                    } else {
                        setBoards([res.data]);
                        setIsLoading(false);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setIsError(true);
                    setIsLoading(false);
                });
        } else {
            setIsLoading(true);
            collectionsApi
                .getBoard(selectedNode.id)
                .then((res) => {
                    setName(res.data.name ? res.data.name : '');
                    setDescription(res.data.description ? res.data.description : '');
                    if (!dataSets.current.boardsToRender[selectedNode.id]) {
                        drawUtils
                            .buildComplexComposedImage(
                                res.data.meta.gridType,
                                null,
                                null,
                                res.data.meta.stickyElements,
                                res.data.mainUrl,
                                null,
                            )
                            .then((data) => {
                                if (data) {
                                    dataSets.current.boardsToRender[selectedNode.id] = data;
                                } else {
                                    dataSets.current.boardsToRender[selectedNode.id] = null;
                                }
                                setBoards([res.data]);
                                setIsLoading(false);
                            });
                    } else {
                        setBoards([res.data]);
                        setIsLoading(false);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setIsError(true);
                    setIsLoading(false);
                });
        }
    };

    const tagsChanged = (newTags) => {
        if (tags && selectedNode && selectedNode.tags) {
            let newTagIds = newTags.map((el) => el.id);
            if (newTagIds.length > selectedNode.tags.length) {
                let diff = difference(newTagIds, selectedNode.tags);
                diff.forEach((el) => {
                    if (el !== -1) {
                        attachTag(selectedNode, { id: el });
                    }
                });
                let newlyCreatedTags = newTags.filter((el) => el.id === -1);
                newlyCreatedTags.forEach((el) => {
                    createAndAttachTag(el.inputValue, selectedNode);
                });
            } else if (newTagIds.length < selectedNode.tags.length) {
                let diff = difference(selectedNode.tags, newTagIds);
                diff.forEach((el) => removeTag(selectedNode, { id: el }));
            }
        }
    };

    if (isLoading) {
        return <>
            <div>
                <img
                    src={`/static/asset/img/FolderBackground.png`}
                    alt='folderBackground2'
                    style={{ maxWidth: '100%', maxHeight: '100%' }}
                />
            </div>

            <GenericState isLoading={true} inline forceHeight={'10rem'} /></>;
    }
    if (isError) {
        return <GenericState isError={true} inline />;
    }
    if (!selectedNode && !create) {
        return null;
    }

    let setTags = [];
    if (tags && selectedNode && selectedNode.tags) {
        setTags = tags.filter((tag) => selectedNode.tags.includes(tag.id));
    }

    let slideElements = [];
    if (boards.length > 0) {
        boards.forEach((el) => {
            slideElements.push({ img: dataSets.current.boardsToRender[el.id] });
        });
    }

    const buildPresentation = (name, selectedNode, create) => {
        if (name) {
            return (
                <>
                    <Grid
                        item
                        xs={12}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '0.5rem 2rem',
                            marginTop: '-4rem',
                        }}
                    >
                        {selectedNode && selectedNode.isCollection && !selectedNode.isBoardSet ? (
                            <GreenFolderIcon />
                        ) : null}
                        {selectedNode && !selectedNode.isCollection && !selectedNode.isBoardSet ? (
                            <SingleBoardIconRedesign />
                        ) : null}
                        {selectedNode && selectedNode.isBoardSet ? <BoardSet /> : null}
                        {selectedNode && selectedNode.isUserBoard ? (
                            <Typography variant='h5' style={{ marginLeft: '1rem' }}>
                                {selectedNode.name}
                            </Typography>
                        ) : (
                            <Typography variant='h5' style={{ marginLeft: '1rem' }}>
                                {name}
                            </Typography>
                        )}
                    </Grid>
                    {selectedNode && selectedNode.isCollection && !create ? (
                        <Divider className={classes.customDivider} variant='middle' />
                    ) : null}
                </>
            );
        } else if (selectedNode && selectedNode.isUserCollection) {
            if (selectedNode.name) {
                return (
                    <Grid
                        item
                        xs={12}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '0.5rem 2rem',
                            marginTop: '-4rem',
                        }}
                    >
                        {selectedNode && selectedNode.isUserCollection && !selectedNode.isBoardSet ? (
                            <GreenFolderIcon />
                        ) : null}
                        {selectedNode && !selectedNode.isUserCollection && !selectedNode.isBoardSet ? (
                            <SingleBoardIconRedesign />
                        ) : null}
                        {selectedNode && selectedNode.isBoardSet ? <BoardSet /> : null}
                        <Typography variant='h5' style={{ marginLeft: '1rem' }}>
                            {selectedNode.name}
                        </Typography>
                    </Grid>
                );
            } else {
                return (
                    <Grid
                        item
                        xs={12}
                        style={{
                            padding: '0.5rem 2rem',
                            marginTop: '-4rem',
                            marginBottom: create ? '2rem' : '0rem',
                        }}
                    >
                        {!create ? (
                            <Typography variant='h5'>
                                <FormattedMessage id='redesign.collections.noNameFolder' />
                            </Typography>
                        ) : null}
                    </Grid>
                );
            }
        } else {
            return (
                <Grid
                    item
                    xs={12}
                    style={{
                        padding: '0.5rem 2rem',
                        marginTop: '-4rem',
                        marginBottom: create ? '2rem' : '0rem',
                    }}
                >
                    {!create ? (
                        <Typography variant='h5'>
                            <FormattedMessage id='redesign.collections.noNameFolder' />
                        </Typography>
                    ) : null}
                </Grid>
            );
        }
    };

    return (
        <>
            {state === LIBRARY_MANAGER_STATE.MANAGE || (state === LIBRARY_MANAGER_STATE.SAVE && create) ? (
                <>
                    <div>
                        <img
                            src={`/static/asset/img/FolderBackground.png`}
                            alt='folderBackground2'
                            style={{ maxWidth: '100%', maxHeight: '100%' }}
                        />
                    </div>

                    {buildPresentation(name, selectedNode, create)}

                    {boards.length > 0 && !create ? (
                        isLoading ? (
                            <div style={{ height: '20rem' }} />
                        ) : (
                            <Grid item>
                                <Paper className={classes.customPaperSlideShow}>
                                    <SlideShow slideElements={slideElements} enableZoom={true} />
                                </Paper>
                            </Grid>
                        )
                    ) : null}
                    {selectedNode && !selectedNode.isUserCollection && !selectedNode.isUserBoard ? (
                        (selectedNode && selectedNode.isCollection) || create ? null : (
                            <Grid
                                item
                                xs={12}
                                className='u-fx u-fx-align-center u-fx-wrap'
                                style={{ padding: '0.5rem 2rem' }}
                            >
                                <Autocomplete
                                    fullWidth
                                    multiple
                                    id='selected-tags-outlined'
                                    size='small'
                                    options={tags}
                                    getOptionLabel={(option) => {
                                        if (option.inputValue) {
                                            return (
                                                <div style={{ width: '100%', display: 'inline-flex' }}>
                                                    <AddBoxOutlined />
                                                    <FormattedMessage id={'collections.createTag'} /> :{' '}
                                                    {option.inputValue}
                                                </div>
                                            );
                                        }
                                        return option.name;
                                    }}
                                    filterSelectedOptions
                                    value={setTags}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);
                                        if (params.inputValue !== '') {
                                            filtered.push({
                                                inputValue: params.inputValue,
                                                id: -1,
                                            });
                                        }
                                        return filtered;
                                    }}
                                    onChange={(event, v, reason) => tagsChanged(v, reason)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant='outlined'
                                            label={intl.formatMessage({ id: 'collections.filterTags' })}
                                            placeholder={intl.formatMessage({ id: 'collections.tags' })}
                                        />
                                    )}
                                />
                            </Grid>
                        )
                    ) : null}
                    {/*{selectedNode && selectedNode.isCollection && !selectedNode.isBoardSet ? (*/}
                    {/*    isLoading ? (*/}
                    {/*        <div style={{ height: '20rem' }} />*/}
                    {/*    ) : (*/}
                    {/*    )*/}
                    {/*) : null}*/}
                    {create ? (
                        <Grid item xs={12} style={{ marginLeft: '2rem' }}>
                            <Typography variant='h4'>
                                <FormattedMessage id='collections.createCollection.button' />
                            </Typography>
                        </Grid>
                    ) : null}
                </>
            ) : (
                <Grid item xs={12} style={{ padding: '0rem 0rem' }}>
                    <div>
                        <img
                            src={`/static/asset/img/FolderBackground.png`}
                            alt='folderBackground2'
                            style={{ maxWidth: '100%', maxHeight: '100%' }}
                        />
                    </div>

                    <Grid item>
                        {setTags.map((tag) => (
                            <Chip key={tag.id} variant='outlined' label={tag.name} />
                        ))}
                    </Grid>
                    {selectedNode ? (
                        <Typography variant='h4'>{selectedNode.name}</Typography>
                    ) : (
                        <Typography variant='h4'>{name}</Typography>
                    )}
                    <Typography variant='body2'>{description ? description : ''}</Typography>
                </Grid>
            )}
            {state === LIBRARY_MANAGER_STATE.LOAD ? (
                <>
                    {boards.length > 0 ? (
                        <Grid item>
                            <Paper className={classes.customPaperSlideShow}>
                                <SlideShow slideElements={slideElements} enableZoom={true} />
                            </Paper>
                        </Grid>
                    ) : null}
                </>
            ) : null}
        </>
    );
};

export default injectIntl(DesktopCollectionEditor);
