import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import { Button, LinearProgress, Paper } from '@material-ui/core';

export default function MigrationDialog({ data, close }) {
    useEffect(() => {
        if (data && data.groups && data.groups > 0 && data.groups === data.groupsComplete) {
            setTimeout(close, 1000);
        }
    }, [data, close]);

    if (!data.groups) {
        return null;
    }

    return (
        <Paper style={{ padding: '1rem', margin: '2rem' }}>
            <Typography variant="h3">
                <FormattedMessage id={'notification.migrating.messageKey'} />
            </Typography>
            {data.groups > 0 && data.groups !== data.groupsComplete ? (
                <img src={'/static/asset/img/migration.gif'} style={{ padding: '2rem', width: '80%' }} />
            ) : null}
            {data.groups > 0 && data.groups === data.groupsComplete ? (
                <div style={{ width: '70%' }}>
                    <Typography variant="h4">
                        <FormattedMessage id={'notification.migrating.complete'} />
                    </Typography>
                    <img src={'/static/asset/img/allDone.gif'} style={{ padding: '2rem', width: '50%' }} />
                </div>
            ) : null}
            {data.groups > 0 ? (
                <LinearProgress
                    variant="determinate"
                    value={(data.groupsComplete * 100) / data.groups}
                    style={{ height: '1rem', borderRadius: '90px', margin: '2rem' }}
                />
            ) : null}
            {data.uploads > 0 ? (
                <LinearProgress
                    variant="determinate"
                    value={(data.uploadsComplete * 100) / data.uploads}
                    style={{ height: '1rem', borderRadius: '90px', margin: '2rem' }}
                />
            ) : null}
            {data.groups > 0 && data.groups === data.groupsComplete ? (
                <div style={{ width: '100%' }}>
                    <Typography variant="h4">
                        <FormattedMessage id={'notification.migrating.backToWork'} />
                    </Typography>
                    <Button variant="contained" color="primary" onClick={close}>
                        <FormattedMessage id={'close'} />
                    </Button>
                </div>
            ) : null}
        </Paper>
    );
}
