import React from 'react';
import { Button, Container, Grid, makeStyles } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import AboutHome from './AboutHome';
import AboutMedia from './AboutMedia';
import AboutPartners from './AboutPartners';
import AboutTeam from './AboutTeam';
import {
    ABOUT_US_HOME_LINK,
    ABOUT_US_MEDIA_LINK,
    ABOUT_US_PARTNERS_LINK,
    ABOUT_US_TEAM_LINK,
} from '../../common/urlLinks';
import Typography from '@material-ui/core/Typography';
import { COLORS2 } from '../../constants';
import Hidden from '@material-ui/core/Hidden';

const aboutKey = {
    HOME: 'home',
    MEDIA: 'media',
    PARTNERS: 'partners',
    TEAM: 'team',
};

const useStyles = makeStyles(() => ({
    menuNav: {
        fontWeight: 600,
        fontSize: '0.875rem',
        lineHeight: '1.063rem',
        textAlign: 'right',
        letterSpacing: '0.16rem',
        color: COLORS2.findMore,
    },
    menuNavActive: {
        fontWeight: 700,
        fontSize: '0.875rem',
        lineHeight: '1.063rem',
        textAlign: 'right',
        letterSpacing: '0.16rem',
        color: COLORS2.findMore,
        borderBottom: '0.125rem solid #D26828',
    },
    desktopContainer: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '-10rem',
        background: 'linear-gradient(180deg, rgba(251, 248, 245, 0) 0%, #FFFFFF 93.57%)',
        borderRadius: '0rem 0rem 2rem 2rem',
    },
    bottomGreenPaper: {
        background: 'rgba(8, 170, 180, 0.16)',
        borderRadius: '0rem 0rem 2rem 2rem',
        height: '2rem',
        width: '90%',
        top: '10rem',
        position: 'relative',
        marginLeft: '5%',
    },
    gridWrap: {
        height: '100%',
        background: 'linear-gradient(180deg, rgba(251, 248, 245, 0) 0%, #FFFFFF 93.57%)',
        borderRadius: '0rem 0rem 2rem 2rem',
        paddingBottom: '2rem',
        paddingLeft: '3rem',
        paddingRight: '3rem',
    },
}));

const About = ({ match }) => {
    let componentToRender;
    const classes = useStyles();
    let active = aboutKey.HOME;
    switch (match.params.aboutValue) {
        case aboutKey.HOME: {
            componentToRender = <AboutHome />;
            break;
        }
        case aboutKey.MEDIA: {
            componentToRender = <AboutMedia />;
            active = aboutKey.MEDIA;
            break;
        }
        case aboutKey.PARTNERS: {
            componentToRender = <AboutPartners />;
            active = aboutKey.PARTNERS;
            break;
        }
        case aboutKey.TEAM: {
            componentToRender = <AboutTeam />;
            active = aboutKey.TEAM;
            break;
        }
        default:
            componentToRender = <AboutHome />;
    }

    return (
        <Container>
            <Hidden only={['xs', 'sm']}>
                <Grid
                    container
                    justify='center'
                    alignItems='center'
                    style={{ textAlign: 'center', paddingTop: '3rem' }}
                >
                    <Grid item xs={6} sm={3}>
                        <Link to={ABOUT_US_HOME_LINK}>
                            <Button variant={'text'}>
                                <Typography
                                    className={active === aboutKey.HOME ? classes.menuNavActive : classes.menuNav}
                                >
                                    <FormattedMessage id={'aboutUs.ourMission.button'} />
                                </Typography>
                            </Button>
                        </Link>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Link to={ABOUT_US_TEAM_LINK}>
                            <Button variant={'text'}>
                                <Typography
                                    className={active === aboutKey.TEAM ? classes.menuNavActive : classes.menuNav}
                                >
                                    <FormattedMessage id={'aboutUs.team.button'} />
                                </Typography>
                            </Button>
                        </Link>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Link to={ABOUT_US_PARTNERS_LINK}>
                            <Button variant={'text'}>
                                <Typography
                                    className={active === aboutKey.PARTNERS ? classes.menuNavActive : classes.menuNav}
                                >
                                    <FormattedMessage id={'aboutUs.partners.button'} />
                                </Typography>
                            </Button>
                        </Link>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Link to={ABOUT_US_MEDIA_LINK}>
                            <Button variant={'text'}>
                                <Typography
                                    className={active === aboutKey.MEDIA ? classes.menuNavActive : classes.menuNav}
                                >
                                    <FormattedMessage id={'aboutUs.media.button'} />
                                </Typography>
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
            </Hidden>
            <div className={classes.desktopContainer}>
                <Container className={classes.gridWrap}>{componentToRender}</Container>
            </div>
            <div className={classes.bottomGreenPaper} />
        </Container>
    );
};

export default withRouter(About);
