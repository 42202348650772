import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import classApi from '../../Api/classApi';
import GenericState from '../../MyComponents/GenericLoadingErrorState/GenericState';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import { GA_EVENT_TREE } from '../../gaConstants';
import DeleteIcon from '../../MyComponents/icons/DeleteIcon';
import ResponsiveTable from '../../MyComponents/ResponsiveTable/ResponsiveTable';
import GenericDeletionConfirmation from '../../MyComponents/GenericDeletionConfirmation/GenericDeletionConfirmation';
import store from '../../redux/store';
import { shareLesson } from '../../redux/actions';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

const MembersTable = (props) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [isError, setIsError] = React.useState(false);
    const [modalItem, setModalItem] = useState(null);
    const [modal, setModal] = useState(false);
    const [modalText, setModalText] = useState('');

    const [data, setData] = React.useState([]);

    const hideModal = () => {
        setModal(false);
    };
    const openModal = () => {
        setModal(true);
    };

    // let deleteRow = function (memberId, memberName) {
    //     setModalItem(memberId);
    //     setModalText(memberName);
    // };

    useEffect(() => {
        classApi
            .getClassMembers(props.classId)
            .then((res) => {
                setData(res.data);
                setIsLoading(false);
                window.logAppActivity(GA_EVENT_TREE.editGroup.actions.load, res.data.length);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
                setIsError(true);
            });
    }, [props.classId, props.reload]);

    let deleteMember = function (memberId, memberName) {
        //setIsLoading(true);
        //setModalItem(null);
        //setModalText('');
        window.logAppActivity(GA_EVENT_TREE.editGroup.actions.removeUser);
        hideModal();
        setModalItem(memberId);
        setModalText(memberName);
        classApi
            .removeClassMember(props.classId, modalItem)
            .then(() => {
                return classApi.getClassMembers(props.classId);
            })
            .then((res) => {
                setData(res.data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
                setIsError(true);
            });
    };

    if (isLoading) {
        return <GenericState isLoading={true} />;
    }
    if (isError) {
        return <GenericState isError={true} />;
    }

    function composeNameCell(row) {
        let name = row.first_name + ' ' + row.last_name;
        if (
            (row.new_first_name && row.new_first_name.trim !== '') ||
            (row.new_last_name && row.new_last_name.trim !== '')
        ) {
            name = row.new_first_name + ' ' + row.new_last_name;
        }
        if (row.avatar) {
            return (
                <div className="u-fx u-fx-align-center">
                    <span>
                        <Avatar src={row.avatar} />
                    </span>
                    <div className="u-fx u-fx-col" style={{ paddingLeft: '10px' }}>
                        <Typography variant="body1">{name}</Typography>
                    </div>
                </div>
            );
        } else {
            return <Typography variant="body1">{name}</Typography>;
        }
    }

    const desktopMeta = [
        {
            header: 'redesign.generic.label.name.member',
            build: composeNameCell,
        },
        {
            header: 'redesign.generic.label.email',
            build: function (row) {
                return <Typography variant="body2">{row.email}</Typography>;
            },
        },
        {
            header: 'redesign.headerEmpty',
            align: 'end',
            build: function (row) {
                let name = row.first_name + ' ' + row.last_name;
                return (
                    <Button
                        color="secondary"
                        onClick={() => {
                            setModalItem(row.id);
                            setModalText(name);
                            openModal();
                        }}
                        startIcon={<DeleteIcon />}
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                    />
                );
            },
        },
    ];

    const mobileMeta = [
        {
            header: 'redesign.generic.label.member',
            build: function (row) {
                let name = row.first_name + ' ' + row.last_name;
                if (
                    (row.new_first_name && row.new_first_name.trim !== '') ||
                    (row.new_last_name && row.new_last_name.trim !== '')
                ) {
                    name = row.new_first_name + ' ' + row.new_last_name;
                }
                return (
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div className="u-fx u-fx-align-center">
                            <span>
                                <Avatar src={row.avatar} />
                            </span>
                            <div className="u-fx u-fx-col" style={{ paddingLeft: '10px' }}>
                                <Typography variant="body1">{name}</Typography>
                                <Typography variant="body2">{row.email}</Typography>
                            </div>
                        </div>
                        <Button
                            onClick={() => {
                                setModalItem(row.id);
                                setModalText(name);
                                openModal();
                            }}
                            startIcon={<DeleteIcon />}
                            style={{ display: 'flex', justifyContent: 'flex-end' }}
                        />
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <Grid item xs={12} sm={12}>
                <Typography variant="h5" gutterBottom>
                    <FormattedMessage id="updateClass.classMembers" />
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
                {data && data.length > 0 ? (
                    <ResponsiveTable
                        metaDesktop={desktopMeta}
                        metaMobile={mobileMeta}
                        metaTablet={mobileMeta}
                        data={data}
                        styled={true}
                        editStyle={false}
                    />
                ) : (
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        style={{ margin: '2rem 2rem 2rem 0rem' }}
                        startIcon={<PersonAddIcon />}
                        onClick={() => {
                            store.dispatch(shareLesson({ id: props.classId }));
                        }}
                    >
                        <FormattedMessage id={'updateClass.addMember'} />
                    </Button>
                )}
            </Grid>
            <GenericDeletionConfirmation
                open={modal}
                deleteFunction={deleteMember}
                onClose={hideModal}
                type={''}
                value={modalText}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    const { profile } = state;
    return { profile };
};

export default injectIntl(connect(mapStateToProps)(MembersTable));
