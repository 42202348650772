import React, { useEffect, useState } from 'react';
import { Button, Container, Grid, Paper } from '@material-ui/core';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import {
    getSubscriptionStateAction,
    SUBSCRIPTION_STATE_ACTION,
    SUBSCRIPTION_PLANS,
    TRIAL_DAYS,
} from '../../shared/Capabilities.js';

import Typography from '@material-ui/core/Typography';
import { featureList, replaceValues } from '../../common/features';
import { cloneDeep } from 'lodash';
import { Link } from 'react-router-dom';
import { SPECIAL_QUOTA_LINK } from '../../common/urlLinks';
import { GA_EVENT_TREE } from '../../gaConstants';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS2, VIDEO_URLS } from '../../constants';
import SubscriptionFeaturesSingle from './SubscriptionFeaturesSingle';
import { ButtonGroupCurrency } from '../../MyComponents/ButtonGroupSubscriptions/ButtonGroupCurrency';
import { ButtonGroupMonthly } from '../../MyComponents/ButtonGroupSubscriptions/ButtonGroupMonthly';
import MobileLanding from '../../MyComponents/Landing/MobileLanding';
import TabletLanding from '../../MyComponents/Landing/TabletLanding';
import Hidden from '@material-ui/core/Hidden';
import { CustomButtonGroupMonthly } from '../../MyComponents/ButtonGroupSubscriptions/CustomButtonGroupMonthly';
import { CustomButtonGroupCurr } from '../../MyComponents/ButtonGroupSubscriptions/CustomButtonGroupCurr';
import { StartGroupButton } from '../../MyComponents/StartGroupButton/StartGroupButton';
import { ONBOARDING_STEPS } from '../../shared/OnboardingOptions';

const SUBSCRIPTIONS_AVAILABLE = [SUBSCRIPTION_PLANS.FREE, SUBSCRIPTION_PLANS.BASIC, SUBSCRIPTION_PLANS.PREMIUM];

const useStyles = makeStyles((theme) => ({
    wrap: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: COLORS2.dawnBackground,
    },
    divContainer: {
        display: 'flex',
        position: 'absolute',
        backgroundColor: 'white',
        borderRadius: '2rem',
        padding: '2rem',
        width: '45rem',
        height: '62rem',
        right: '20%',
    },
    buttonGroup: {
        backgroundColor: 'rgba(255,255,255,0.9)',
        borderRadius: '4.75rem',
        padding: '0.25rem',
    },
    subscriptionsLanding: {
        display: 'flex',
        position: 'absolute',
        backgroundColor: 'white',
        borderRadius: '2rem',
        padding: '2rem',
        width: '45rem',
        height: '30rem',
        left: '25%',
    },
    startButtonContainer: {
        background: 'transparent',
        paddingBottom: '1rem',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '1.5rem',
    },
    buttonGroupContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '1rem',
    },
    paper: {
        background: COLORS2.darkGreen,
        padding: '2rem',
        paddingTop: '15rem',
    },
    paperLoggedIn: {
        background: COLORS2.dawnBackground,
        paddingLeft: '2rem',
        paddingRight: '2rem',
        paddingTop: '9rem',
    },
    widthCenter: {
        width: '100%',
        textAlign: 'center',
    },
    switchesContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    gridContainer: {
        marginTop: '-7.5rem',
        backgroundColor: COLORS2.darkGreen,
    },
    gridContainerLoggedIn: {
        marginTop: '-7.5rem',
        backgroundColor: COLORS2.dawnBackground,
    },
    description: {
        width: '100%',
        marginTop: '0.5rem',
        textAlign: 'center',
    },
    customH5: {
        color: 'white',
        fontWeight: 500,
    },
    customH5LoggedIn: {
        color: COLORS2.darkGreen,
        fontWeight: 500,
    },
    customH2: {
        fontSize: '3rem',
        lineHeight: '3.5rem',
        color: 'white',
        fontWeight: 700,
        marginBottom: '1.5rem',
    },
    mobileH1: {
        fontSize: '3.25rem',
        lineHeight: '3.75rem',
    },
    loginContainer: {
        background: 'transparent',
        padding: '3rem 1rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    autoMargin: {
        [theme.breakpoints.down('xs')]: {
            marginLeft: '0rem',
            marginRight: '0rem',
        },
    },
    topButtons: {
        border: '1px solid rgba(6, 81, 86, 0.24)',
        borderRadius: '1rem',
        height: '4.5rem',
        backgroundColor: 'rgba(6, 81, 86, 0.08)',
        boxShadow: 'none',
        color: COLORS2.darkGreen,
        width: '19rem',
    },
}));

const SubscriptionsMobile = ({ profile, subscriptions, pricing, language, handleOpen }) => {
    const classes = useStyles();
    let needsBackground = true;
    const [billAnnual, setBillAnnual] = useState(true);
    const [billEur, setBillEur] = useState(language !== 'ro');

    useEffect(() => {
        setBillEur(language !== 'ro');
    }, [language]);

    if (!profile || !subscriptions || !profile.subscription) {
        return null;
    }

    const handleChange = () => {
        setBillAnnual(!billAnnual);
        if (billAnnual) {
            window.logAppActivity(GA_EVENT_TREE.mainPage.onboarding.checkAnnual);
        } else {
            window.logAppActivity(GA_EVENT_TREE.mainPage.onboarding.checkMonthly);
        }
    };

    const handleCurrencySelect = () => {
        setBillEur(!billEur);
        if (billEur) {
            window.logAppActivity(GA_EVENT_TREE.mainPage.onboarding.checkEur);
        } else {
            window.logAppActivity(GA_EVENT_TREE.mainPage.onboarding.checkRon);
        }
    };

    let subscriptionsToRender = [];
    let availableSubs = [];
    SUBSCRIPTIONS_AVAILABLE.forEach((subscriptionName) => {
        if (window.isTeams && subscriptionName === SUBSCRIPTION_PLANS.PREMIUM) {
            return;
        }
        let subscription = subscriptions[subscriptionName];
        if (subscription) {
            let myFeatureList = cloneDeep(featureList);
            replaceValues(subscription, myFeatureList);
            subscriptionsToRender.push(myFeatureList);
            availableSubs.push(subscriptionName);
        }
    });

    let canUpgrade = true;
    if (!profile.subscription.canceledAt) {
        subscriptionsToRender.forEach((el, index) => {
            if (
                getSubscriptionStateAction(profile, subscriptions, SUBSCRIPTIONS_AVAILABLE[index]) ===
                SUBSCRIPTION_STATE_ACTION.CANCEL
            ) {
                canUpgrade = false;
            }
        });
    }

    const setCreateGroupPath = () => {
        window.localStorage.setItem('pathBeforeLogin', '/creategroup');
    };

    let canStartTrial = profile?.subscription?.trialStarted === false;

    if (!profile || !profile.loggedIn) {
        canStartTrial = true;
    }

    if (window.isTeams) {
        return (
            <Grid
                container
                direction="row"
                justify="center"
                style={{ paddingTop: '2rem', paddingBottom: '2rem' }}
                spacing={2}
            >
                <Grid item xs={12}>
                    <Paper elevation={0} style={{ background: 'rgba(255, 205, 52, 0.06)', padding: '2rem' }}>
                        <Grid container spacing={2} justify={'center'}>
                            <Grid item xs={12}>
                                <div style={{ width: '100%', marginTop: '0.5rem', textAlign: 'center' }}>
                                    <Typography variant="h2">
                                        <FormattedMessage id="topMenu.teams.subscriptions.name" />
                                    </Typography>
                                    <Typography variant="h5">
                                        <FormattedMessage id="subscriptions.teams.subscription.description1" />
                                    </Typography>
                                    <Typography variant="h5">
                                        <FormattedMessage id="subscriptions.teams.subscription.description2" />
                                    </Typography>
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={3} />
                            <Grid item xs={12} sm={3}>
                                <div style={{ padding: '0.5rem' }}></div>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <div style={{ padding: '0.5rem' }}>
                                    <Link to={SPECIAL_QUOTA_LINK}>
                                        <Button variant={'contained'} color={'secondary'} size={'large'} fullWidth>
                                            <FormattedMessage id={'subscriptions.subscription.requestSpecialQuote'} />
                                        </Button>
                                    </Link>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={3} />
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        );
    }

    return (
        <>
            {!profile.loggedIn ? (
                <Container maxWidth="lg" className={classes.autoMargin}>
                    <Hidden only={['xs', 'md', 'lg', 'xl']}>
                        <TabletLanding
                            videoUrl={VIDEO_URLS['pricing'][language.toUpperCase()]}
                            needsBackground={needsBackground}
                        >
                            <Grid item xs={12} md={7} style={{ zIndex: 1 }}>
                                <Grid item xs={12} md={12} style={{ textAlign: 'center', paddingTop: '2rem' }}>
                                    <Typography variant="h1">
                                        <FormattedMessage
                                            id={'redesign.subscriptions.name'}
                                            values={{
                                                b: (chunks) => (
                                                    <b>
                                                        {chunks}
                                                        <br />
                                                    </b>
                                                ),
                                            }}
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={12} className={classes.loginContainer}>
                                    <StartGroupButton
                                        handleOpen={() => {
                                            handleOpen(false);
                                            setCreateGroupPath();
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </TabletLanding>
                    </Hidden>
                    <Hidden only={['sm', 'md', 'lg', 'xl']}>
                        <MobileLanding
                            videoUrl={VIDEO_URLS['pricing'][language.toUpperCase()]}
                            needsBackground={needsBackground}
                        >
                            <Grid item xs={12} md={7} style={{ zIndex: 1 }}>
                                <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    style={{ textAlign: 'center', paddingTop: needsBackground ? '2rem' : '0rem' }}
                                >
                                    <Typography variant="h1" className={classes.mobileH1}>
                                        <FormattedMessage
                                            id={'redesign.subscriptions.name'}
                                            values={{
                                                b: (chunks) => <b>{chunks}</b>,
                                            }}
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={12} className={classes.loginContainer}>
                                    <StartGroupButton
                                        handleOpen={() => {
                                            handleOpen(false);
                                            setCreateGroupPath();
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </MobileLanding>
                    </Hidden>
                </Container>
            ) : null}
            <Grid
                container
                direction="row"
                justify="center"
                className={profile.loggedIn ? classes.gridContainerLoggedIn : classes.gridContainer}
                spacing={2}
            >
                <Grid item xs={12}>
                    <Paper elevation={0} className={profile.loggedIn ? classes.paperLoggedIn : classes.paper}>
                        <Grid container spacing={2} justify={'center'}>
                            <Grid item xs={12}>
                                <div className={classes.description}>
                                    <Typography
                                        variant="h5"
                                        className={profile.loggedIn ? classes.customH5LoggedIn : classes.customH5}
                                    >
                                        <FormattedMessage id="redesign.subscriptions.subscription.description1" />
                                    </Typography>
                                    <Typography
                                        variant="h5"
                                        className={profile.loggedIn ? classes.customH5LoggedIn : classes.customH5}
                                    >
                                        <FormattedMessage id="redesign.subscriptions.subscription.description2" />
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={3} />
                        </Grid>
                    </Paper>
                </Grid>

                {profile.loggedIn ? (
                    <Grid
                        item
                        xs={12}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginBottom: '2rem',
                        }}
                    >
                        {canStartTrial ? (
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                style={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}
                            >
                                <Link
                                    to={`/onboarding?step=${ONBOARDING_STEPS.START}&subscription=${SUBSCRIPTION_PLANS.TRIAL}&billAnnual=${billAnnual}&billEur=${billEur}`}
                                >
                                    <Button className={classes.topButtons}>
                                        <FormattedMessage
                                            id={'subscriptions.subscription.startTrial'}
                                            values={{ days: TRIAL_DAYS }}
                                        />
                                    </Button>
                                </Link>
                            </Grid>
                        ) : null}
                        <Grid item xs={12} sm={6}>
                            <Link to={SPECIAL_QUOTA_LINK}>
                                <Button className={classes.topButtons}>
                                    <FormattedMessage id={'subscriptions.subscription.requestSpecialQuote'} />
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>
                ) : null}

                <Grid container className={classes.switchesContainer}>
                    <Grid item xs={12} sm={6} className={classes.widthCenter}>
                        <div className={classes.buttonGroupContainer}>
                            {profile.loggedIn ? (
                                <CustomButtonGroupMonthly billAnnual={billAnnual} handleChange={handleChange} />
                            ) : (
                                <ButtonGroupMonthly billAnnual={billAnnual} handleChange={handleChange} />
                            )}
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.widthCenter}>
                        <div className={classes.buttonGroupContainer}>
                            {profile.loggedIn ? (
                                <CustomButtonGroupCurr billEur={billEur} handleCurrencySelect={handleCurrencySelect} />
                            ) : (
                                <ButtonGroupCurrency billEur={billEur} handleCurrencySelect={handleCurrencySelect} />
                            )}
                        </div>
                    </Grid>
                </Grid>
                {subscriptionsToRender.map((el, index) => {
                    let isActive =
                        getSubscriptionStateAction(profile, subscriptions, SUBSCRIPTIONS_AVAILABLE[index]) ===
                            SUBSCRIPTION_STATE_ACTION.ACTIVE ||
                        getSubscriptionStateAction(profile, subscriptions, SUBSCRIPTIONS_AVAILABLE[index]) ===
                            SUBSCRIPTION_STATE_ACTION.CANCEL ||
                        (!profile.loggedIn && SUBSCRIPTIONS_AVAILABLE[index] === SUBSCRIPTION_PLANS.BASIC);
                    return (
                        <Grid item xs={12} key={`subscriptionType-${availableSubs[index]}`}>
                            <Paper
                                style={{
                                    background: profile.loggedIn
                                        ? isActive
                                            ? 'rgba(250, 250, 250, 1)'
                                            : 'rgba(6, 81, 86, 0.9)'
                                        : isActive
                                        ? 'rgba(250, 250, 250, 1)'
                                        : 'rgba(250, 250, 250, 0.08)',
                                    boxShadow:
                                        '0rem 0rem 0rem 0.5rem rgba(8, 170, 180, 0.16), 0rem 2rem 6rem rgba(2, 35, 37, 0.32)',
                                    borderRadius: '2rem',
                                    margin: '1rem 2rem',
                                }}
                                elevation={0}
                            >
                                <SubscriptionFeaturesSingle
                                    featureList={el}
                                    subscriptionType={availableSubs[index]}
                                    pricing={pricing}
                                    billAnnual={billAnnual}
                                    billEur={billEur}
                                    subscriptionState={getSubscriptionStateAction(
                                        profile,
                                        subscriptions,
                                        SUBSCRIPTIONS_AVAILABLE[index]
                                    )}
                                    canUpgrade={canUpgrade}
                                    isActive={isActive}
                                    isDesktop={false}
                                    handleOpen={handleOpen}
                                />
                            </Paper>
                        </Grid>
                    );
                })}
            </Grid>
        </>
    );
};

const mapStateToProps = (state) => {
    const { profile, mainAppState } = state;
    return { profile, subscriptions: mainAppState.subscriptions, pricing: mainAppState.pricing };
};

export default injectIntl(connect(mapStateToProps)(SubscriptionsMobile));
