export const PROFILE_LINK = '/profile';
export const CREATE_GROUP_LINK = '/creategroup';
export const MANAGE_CLASSES_LINK = '/manageclasses';
export const LIBRARY_LINK = '/library';
// export const JOIN_CLASS_LINK = '/joinclass';
export const CONTACT_US_LINK = '/contactus';
export const SPECIAL_QUOTA_LINK = '/specialquota';
export const LINK_WHITEBOARD = '/linkWhiteboard';
export const ALLOW_WHITEBOARD = '/allowWhiteboard';
export const PRIVACY_POLICY_LINK = '/privacypolicy';
export const TERMS_OF_USE = '/terms';
export const TUTORIAL_LINK = '/tutorial';
export const FOR_TUTORING_LINK = '/tutoring';
export const USAGE_FOR_TUTORING_LINK = '/usageTutoring';
export const FOR_SCHOOLS_LINK = '/schools';
export const USAGE_FOR_SCHOOLS_LINK = '/usageSchools';
export const SUBSCRIPTIONS_LINK = '/subscriptions';
export const ABOUT_US_LINK = '/about';
export const ABOUT_US_HOME_LINK = '/about/home';
export const ABOUT_US_MEDIA_LINK = '/about/media';
export const ABOUT_US_TEAM_LINK = '/about/team';
export const ABOUT_US_PARTNERS_LINK = '/about/partners';
export const FEATURES_LINK = '/features';
export const STRIPE_PAYMENT_LINK = '/stripe-payment';
export const ONBOARDING_LINK = '/onboarding';
export const VERIFY_EMAIL_LINK = '/verifyemail';
export const MESSAGES_LINK = '/messages';
export const HOME_LINK = '/';
export const EN_PREFIX = '/en';
export const RO_PREFIX = '/ro';
export const FR_PREFIX = '/fr';

export const tutorialLinks = {
    lesson: `${TUTORIAL_LINK}?feature=manageLesson`,
    groups: `${TUTORIAL_LINK}?feature=manageGroups`,
    audioVideo: `${TUTORIAL_LINK}?feature=manageLesson-audio`,
    imageMenu: `${TUTORIAL_LINK}?feature=manageLesson-imageMenu`,
    rulingMenu: `${TUTORIAL_LINK}?feature=manageLesson-rulingMenu`,
    geometry: `${TUTORIAL_LINK}?feature=manageLesson-geometry`,
    mathConvertor: `${TUTORIAL_LINK}?feature=manageLesson-mathConvertorManagement`,
    library: `${TUTORIAL_LINK}?feature=manageLibrary`,
    individualBoards: `${TUTORIAL_LINK}?feature=manageSplitBoardLesson`,
    saveIndividualBoards: `${TUTORIAL_LINK}?feature=manageSplitBoardLesson-reviewPast`,
    generalTools: `${TUTORIAL_LINK}?feature=manageLesson-tools`,
    phoneOptimization: `${TUTORIAL_LINK}?feature=optimization`,
    splitBoardLesson: `${TUTORIAL_LINK}?feature=manageSplitBoardLesson`,
};
