import React from 'react';
import { injectIntl } from 'react-intl';
import { SUBSCRIPTION_PLANS } from '../../shared/Capabilities';
import TrialStage from './Stages/TrialStage';
import ValidateEmail from './Stages/ValidateEmail';
import PaidStage from './Stages/PaidStage';
import SpecialStage from './Stages/SpecialStage';

const StartStage = ({ state, profile, language }) => {
    if (state.subscription === SUBSCRIPTION_PLANS.TRIAL) {
        return <TrialStage profile={profile} />;
    }

    if (state.subscription === SUBSCRIPTION_PLANS.BASIC || state.subscription === SUBSCRIPTION_PLANS.PREMIUM) {
        if (!profile?.preferences?.emailIsValid) {
            window.localStorage.setItem('tempCheckoutSession', JSON.stringify(state));
            return <ValidateEmail profile={profile} />;
        } else {
            return <PaidStage state={state} />;
        }
    }

    if (state.subscription === SUBSCRIPTION_PLANS.FREE) {
        return null;
    }

    if (!profile.loggedIn) {
        return <SpecialStage state={state} language={language} isLoggedIn={profile.loggedIn} />;
    } else {
        if (!profile?.preferences?.emailIsValid) {
            window.localStorage.setItem('tempCheckoutSession', JSON.stringify(state));
            return <ValidateEmail profile={profile} />;
        } else {
            return <SpecialStage state={state} language={language} isLoggedIn={profile.loggedIn} />;
        }
    }
};

export default injectIntl(StartStage);
