import Button from '@material-ui/core/Button';
import { GA_EVENT_TREE } from '../../../../gaConstants';
import React from 'react';
import FacebookIcon from '../../../../MyComponents/icons/FacebookIcon';

export const FacebookLoginButton = ({ handleLogin, classes }) => {
    return (
        <div style={{ paddingLeft: '1.5rem' }}>
            {!window.isTeams ? (
                <Button
                    size="large"
                    variant="contained"
                    className={classes.loginButtonSmall}
                    onClick={() => {
                        window.logAppActivity(GA_EVENT_TREE.mainPage.links.facebookLogin);
                        handleLogin('/facebook');
                    }}
                    startIcon={<FacebookIcon className={classes.customIcon} />}
                />
            ) : null}
        </div>
    );
};
