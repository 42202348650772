import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { debounce } from 'lodash';
import { Typography, Paper, Box } from '@material-ui/core';
import styles from './styles';
import { getSummaryActiveItem } from '../summaryUtils';
import MobileMenuScrollIcon from '../../icons/MobileMenuScrollIcon';
import MobileMenuIcon from '../../icons/MobileMenuIcon';
import { COLORS2 } from '../../../constants';

const nodeStyle = {
    cursor: 'pointer',
    paddingTop: '1rem',
    alignItems: 'center',
    position: 'relative',
};
const selectedNodeStyle = {
    fontWeight: '700',
};

const selectedCursorStyle = {
    height: '2rem',
    position: 'absolute',
    width: '4px',
    backgroundColor: COLORS2.darkGreen,
    borderRadius: '32px 0px 0px 32px',
    left: '0px',
    transform: 'matrix(-1, 0, 0, 1, 0, 0)',
};

const MobileSummaryMenu = ({ sections, path, updateFunction, selectorFunc }) => {
    const [expanded, setExpanded] = useState(false);
    const [selectedMenuItem, setSelectedMenuItem] = useState();
    const intl = useIntl();
    const history = useHistory();
    const sectionId = useSelector(selectorFunc);
    const classes = styles();

    useEffect(() => {
        const scrollToMenuItem = () => {
            let item = getSummaryActiveItem(sections);
            if (item && item) {
                updateFunction(item.id);
                setSelectedMenuItem(item.nameId);
            }
        };

        window.addEventListener('scroll', debounce(scrollToMenuItem, 100));
        return () => {
            window.removeEventListener('scroll', scrollToMenuItem);
        };
    }, []);

    const handleSummaryClick = (event, sectionId) => {
        event.preventDefault();
        history.push({
            pathname: path,
            state: {
                sectionId,
            },
        });
        updateFunction(sectionId);
    };

    const renderItem = (node) => {
        let style = nodeStyle;
        let selected = false;
        if (sectionId === node.id) {
            style = { ...style, ...selectedNodeStyle };
            selected = true;
        }

        return (
            <div
                key={node.id}
                onClick={(event) => {
                    setExpanded(false);
                    handleSummaryClick(event, node.id);
                }}
                style={{ ...style, ...{ paddingLeft: '2rem' } }}
            >
                {selected ? <div style={selectedCursorStyle} /> : null}
                {node.nameId && (
                    <Typography variant="h6" style={selected ? selectedNodeStyle : {}}>
                        {intl.formatMessage({ id: node.nameId })}
                    </Typography>
                )}
                {Array.isArray(node.children) ? node.children.map((child) => renderChildItem(child)) : null}
            </div>
        );
    };

    const renderChildItem = (node) => {
        let style = nodeStyle;
        let selected = false;
        if (sectionId === node.id) {
            style = { ...style, ...selectedNodeStyle, display: 'flex' };
            selected = true;
        }

        return (
            <div
                key={node.id}
                style={style}
                onClick={(event) => {
                    handleSummaryClick(event, node.id);
                    setExpanded(false);
                    event.preventDefault();
                    event.stopPropagation();
                }}
            >
                {selected ? <div style={{ ...selectedCursorStyle, left: '-2rem' }} /> : null}
                {node.nameId && (
                    <Typography variant="body2" style={selected ? selectedNodeStyle : {}}>
                        {intl.formatMessage({ id: node.nameId })}
                    </Typography>
                )}
            </div>
        );
    };

    return (
        <>
            <Paper elevation={3} className={classes.simpleMenuSelection}>
                <div
                    onClick={() => {
                        setExpanded(!expanded);
                    }}
                    className={classes.simpleTutorialMenuExpander}
                >
                    {expanded ? (
                        ''
                    ) : (
                        <div className={classes.containerSimpleMenuItems}>
                            <MobileMenuScrollIcon className={classes.mobileMenuScrollIcon} />
                            <div className={classes.menuItemSelected}>
                                {selectedMenuItem ? <FormattedMessage id={selectedMenuItem} /> : null}
                            </div>
                            <div className={classes.summary}>
                                <FormattedMessage id="redesign.content" />
                            </div>
                            <div className={classes.mobileMenuIconContainer}>
                                <MobileMenuIcon className={classes.mobileMenuIcon} />
                            </div>
                        </div>
                    )}
                </div>
                {expanded ? (
                    <div style={{ overflow: 'auto', maxHeight: '92vh' }}>
                        {sections.map((section) => renderItem(section))}
                    </div>
                ) : null}
            </Paper>
            <Box className={classes.orangeRectangleBreak} />
        </>
    );
};

export default MobileSummaryMenu;
