import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
// import PWAPrompt from 'react-ios-pwa-prompt';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import AuthenticatedApp from './AuthenticatedApp';
import UnauthenticatedApp from './UnauthenticatedApp';
import { COLORS2 } from '../constants';
import userApi from '../Api/userApi';
import store from '../redux/store';
import { migrateLesson } from '../common/migrationUtil';
import { showError } from '../redux/actions';
import MigrationDialog from '../MyComponents/Migration/MigrationDialog';

const theme = createMuiTheme({
    props: {
        MuiButton: {},
    },
    overrides: {
        MuiButton: {
            root: {
                // borderRadius: '16rem',
                padding: '1rem 1rem 0.938rem',
                width: 'fit-content',
                fontStyle: 'normal',
                fontWeight: '600',
                fontSize: '0.875rem',
                lineHeight: '1.063rem',
                textAlign: 'center',
                letterSpacing: '0.1em',
                textTransform: 'uppercase',
                color: COLORS2.virtualGreen,
                // flex: 'none',
                // order: '0',
                // flexGrow: '0',
            },
            contained: {
                boxSizing: 'borderBox',
                border: '0.063rem solid rgba(6, 81, 86, 0.24)',
                borderRadius: '1rem',
                backgroundColor: 'none',
                boxShadow: 'none',
                '&:hover': {
                    boxShadow: 'none',
                },
                color: COLORS2.darkGreen,
            },
            text: {
                '&:hover': {
                    boxShadow: 'none',
                    background: 'rgba(8, 170, 180, 0.04)',
                    borderRadius: '16px',
                },
            },
            outlined: {
                border: '1px solid rgba(6, 81, 86, 0.24)',
                borderRadius: '16px',
                '&:hover': {
                    boxShadow: 'none',
                    background: 'rgba(8, 170, 180, 0.04)',
                },
            },
            sizeLarge: {
                height: '3rem',
            },
            containedSizeLarge: {
                '&:disabled': {
                    border: '1px solid #DAE0E5',
                    borderRadius: '1rem',
                    background: 'rgba(255,255,255,1)',
                },
            },
            containedPrimary: {
                //boxShadow: '0rem 0rem 0rem 0.5rem rgb(8 170 180 / 8%)',
                backgroundColor: COLORS2.virtualGreen,
                border: 'none',
                '&:hover': {
                    backgroundColor: '#008A94',
                    boxShadow: '0px 0px 0px 8px rgba(8, 170, 180, 0.08), 0px 32px 32px -16px rgba(6, 81, 86, 0.16);',
                },
                color: '#FFF',
                boxShadow: '0rem 0rem 0rem 0.5rem rgba(8, 170, 180, 0.08)',
            },
            containedSizeSmall: {
                '&:hover': {
                    backgroundColor: '#008A94',
                    boxShadow: 'none',
                },
                boxShadow: 'none',
            },
            containedSecondary: {
                border: '0.063rem solid rgba(243, 83, 37, 0.24)',
                color: COLORS2.virtualRed,
                backgroundColor: 'transparent',
            },
            outlinedPrimary: {
                color: COLORS2.darkGreen,
                border: '1px solid rgba(6, 81, 86, 0.24)',
            },
        },
        // MuiTextField: {
        //     root: {
        //         '&:focus-within': {
        //             boxShadow: '0 0 0 2px #739dd333',
        //         },
        //     },
        // },
        MuiFab: {
            root: {
                boxShadow:
                    '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 1px 0px rgba(0,0,0,0.12);',
            },
        },
        MuiTypography: {
            root: {
                fontFamily: [
                    'Gilroy',
                    'TT Norms',
                    'Roboto',
                    '-apple-system',
                    'BlinkMacSystemFont',
                    '"Segoe UI"',
                    '"Helvetica Neue"',
                    'Arial',
                    'sans-serif',
                    '"Apple Color Emoji"',
                    '"Segoe UI Emoji"',
                    '"Segoe UI Symbol"',
                ].join(','),
            },
            h1: {
                fontSize: '5rem',
                lineHeight: '5.5rem',
                fontStyle: 'normal',
                color: COLORS2.darkGreen,
                fontWeight: '300',
                letterSpacing: '-0.04rem',
                margin: '0.5rem 0rem 0.5rem 0rem',
                b: {
                    color: 'red',
                },
            },
            h2: {
                fontSize: '3.5rem',
                lineHeight: '4rem',
                fontStyle: 'normal',
                color: COLORS2.darkGreen,
                fontWeight: '300',
                letterSpacing: '-0.04em',
                margin: '0.5rem 0rem 0.5rem 0rem',
            },
            h3: {
                fontSize: '2.5rem',
                lineHeight: '3rem',
                fontWeight: '300',
                color: COLORS2.darkGreen,
                fontStyle: 'normal',
                letterSpacing: '-0.01em',
            },
            h4: {
                fontSize: '1.5rem',
                lineHeight: '2rem',
                fontStyle: 'normal',
                fontWeight: '400',
                letterSpacing: '-0.01em',
                margin: '0.5rem 0rem 0.5rem 0rem',
                color: COLORS2.darkGreen,
            },
            h5: {
                fontSize: '1.375rem',
                margin: '0.3rem 0rem 0.3rem 0rem',
                fontWeight: '500',
                color: COLORS2.darkGreen,
            },
            h6: {
                fontSize: '1rem',
                lineHeight: '1.625rem',
                fontStyle: 'normal',
                fontWeight: '500',
                letterSpacing: '-0.01em',
                margin: '0.2rem 0rem 0.2rem 0rem',
                color: COLORS2.darkGreen,
            },
            body1: {
                fontSize: '1rem',
                fontWeight: '500',
                lineHeight: '1.625rem',
                color: COLORS2.virtualText,
            },
            subtitle1: {
                fontStyle: 'normal',
                fontWeight: '600',
                fontSize: '0.875rem',
                lineHeight: '1.063rem',
                letterSpacing: '0.1rem',
                textTransform: 'uppercase',
                color: COLORS2.darkGreen,
            },
            subtitle2: {
                fontSize: '2rem',
                lineHeight: '2.5rem',
                fontWeight: '600',
                color: COLORS2.darkGreen,
            },
        },
    },
    typography: {
        fontFamily: [
            'Gilroy',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'TT Norms',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            // main: '#26a69a',
            main: '#08AAB4',
            contrastText: '#fff',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            light: '#0066ff',
            main: '#D26828',
            // dark: will be calculated from palette.secondary.main,
            contrastText: '#fff',
        },
        default: {
            //light: '#ffa726',
            main: '#08AAB4',
            //dark: '#ef6c00',
            contrastText: '#fff',
        },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
    },
});

const migrateTablesToStorage = async (updateGroups, updateUploads, close) => {
    try {
        let profile = store.getState().profile;
        let key = 'pages-migrated-to-cloud-' + profile.email;
        if (window.localStorage.getItem(key) === 'true') {
            // console.log('==== migrated');
            close();
            return Promise.resolve();
        } else {
            let boards = window.localStorage.getItem(profile.email);
            if (!boards) {
                close();
                return Promise.resolve();
            }
            let parsedBoards = JSON.parse(boards);
            if (!parsedBoards) {
                close();
                return Promise.resolve();
            }
            let lessonsToMigrate = Object.keys(parsedBoards);
            if (lessonsToMigrate.length === 0) {
                close();
                return;
            }
            updateGroups(lessonsToMigrate.length, 0);
            for (let i = 0; i < lessonsToMigrate.length; i++) {
                try {
                    updateUploads(0, 0);
                    await migrateLesson(profile.email, lessonsToMigrate[i], updateUploads);
                    updateGroups(lessonsToMigrate.length, i + 1);
                } catch (e) {
                    console.error('Unable to migrate class', profile.email, lessonsToMigrate[i], e);
                    store.dispatch(showError('GENERIC_ERROR'));
                    updateGroups(lessonsToMigrate.length, lessonsToMigrate.length);
                    return;
                }
            }
            console.log('=== should delete');
            window.localStorage.removeItem(profile.email);
            window.localStorage.setItem(key, 'true');
            // console.log('=== completed migration');
        }
    } catch (e) {
        console.error('Unable to migrate', store?.getState()?.profile, e);
        store.dispatch(showError('GENERIC_ERROR'));
    }
};
function Main({ profile, language, updateLanguage }) {
    const [migrationsComplete, setMigrationsComplete] = useState(false);
    const [migrationsData, setMigrationsData] = useState({
        uploads: 0,
        uploadsComplete: 0,
        groups: 0,
        groupsComplete: 0,
    });
    const updateGroups = (t, c) => {
        setMigrationsData((prev) => {
            let newVal = { ...prev };
            newVal.groups = t;
            newVal.groupsComplete = c;
            return newVal;
        });
    };

    const updateUploads = (t, c) => {
        setMigrationsData((prev) => {
            let newVal = { ...prev };
            newVal.uploads = t;
            newVal.uploadsComplete = c;
            return newVal;
        });
    };

    const close = () => {
        setMigrationsComplete(true);
    };

    useEffect(() => {
        migrateTablesToStorage(updateGroups, updateUploads, close);
    }, []);

    return (
        <ThemeProvider theme={theme}>
            {/*<PWAPrompt*/}
            {/*    timesToShow={5}*/}
            {/*    copyTitle={intl.formatMessage({ id: 'addToHomeScreen' })}*/}
            {/*    copyBody={intl.formatMessage({ id: 'addToHomeScreenDetail' })}*/}
            {/*    copyShareButtonLabel={intl.formatMessage({ id: 'pressAddButton' })}*/}
            {/*    copyAddHomeButtonLabel={intl.formatMessage({ id: 'pressShareButton' })}*/}
            {/*/>*/}
            {profile.loggedIn ? (
                <>
                    {migrationsComplete ? (
                        <AuthenticatedApp
                            language={language}
                            updateLanguage={(lang) => {
                                updateLanguage(lang);
                                userApi.updateLanguage(lang).catch((err) => {
                                    console.error(err);
                                });
                            }}
                        />
                    ) : (
                        <MigrationDialog data={migrationsData} close={close} />
                    )}
                </>
            ) : (
                <UnauthenticatedApp language={language} updateLanguage={updateLanguage} />
            )}
        </ThemeProvider>
    );
}

const mapStateToProps = (state) => {
    const { profile } = state;
    return { profile };
};

export default injectIntl(connect(mapStateToProps)(Main));
