import React, { useEffect, useRef } from 'react';
import { Button, Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { getSubscriptionData, SUBSCRIPTION_ORDER } from '../../shared/Capabilities.js';

import Typography from '@material-ui/core/Typography';
import { Link, useHistory, useLocation } from 'react-router-dom';
import userApi from '../../Api/userApi';
import store from '../../redux/store';
import { updateUserPreferences } from '../../redux/actions';
import { parseQuery } from '../../common/utils';
import { PROFILE_LINK } from '../../common/urlLinks';

const StripePayment = ({ profile, subscriptions }) => {
    const location = useLocation();
    const history = useHistory();

    let interval = useRef({ func: null });
    let params = parseQuery(location.search);

    useEffect(() => {
        return function () {
            if (interval.current.func) {
                clearInterval(interval.current.func);
            }
        };
    }, []);

    useEffect(() => {
        if (subscriptions && profile?.subscription && params.state === 'success') {
            let s = getSubscriptionData(subscriptions, profile.subscription);
            if (SUBSCRIPTION_ORDER[s.subscriptionName] > 0) {
                if (interval.current.func) {
                    clearInterval(interval.current.func);
                    interval.current.func = null;
                }
                history.push(PROFILE_LINK);
            } else {
                if (!interval.current.func) {
                    updateProfile();
                }
            }
        }
    }, [profile, location, subscriptions, history, params]);

    const updateProfile = () => {
        interval.current.func = setInterval(() => {
            userApi.getExtendedProfile().then((response) => {
                store.dispatch(updateUserPreferences(response.data.preferences));
            });
        }, 2000);
    };
    let msgId;
    let showLinkToSubscriptions = false;
    if (params.state === 'success') {
        msgId = 'subscriptions.subscription.paymentSuccess';
    } else if (params.state === 'canceled') {
        msgId = 'subscriptions.subscription.paymentCanceled';
        showLinkToSubscriptions = true;
    } else {
        msgId = 'subscriptions.subscription.paymentError';
        showLinkToSubscriptions = true;
    }

    return (
        <Grid container spacing={2} justify={'center'} style={{ padding: '2rem' }}>
            <Grid item xs={12}>
                <div style={{ width: '100%', marginTop: '0.5rem' }}>
                    <Typography variant="h3">
                        <FormattedMessage id={msgId} />
                    </Typography>
                </div>
            </Grid>
            {showLinkToSubscriptions ? (
                <Grid item xs={12}>
                    <div style={{ width: '100%', marginTop: '0.5rem' }}>
                        <Link to={'/subscriptions'}>
                            <Button variant={'contained'} color={'primary'}>
                                <FormattedMessage id={'topMenu.subscriptions.name'} />
                            </Button>
                        </Link>
                    </div>
                </Grid>
            ) : null}
        </Grid>
    );
};

const mapStateToProps = (state) => {
    const { profile, mainAppState } = state;
    return { profile, subscriptions: mainAppState.subscriptions };
};

export default connect(mapStateToProps)(StripePayment);
