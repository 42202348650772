import { Grid, Typography } from '@material-ui/core';
import { FormattedMessage, injectIntl } from 'react-intl';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';

const useStyles = makeStyles((theme) => ({
    sectionContainer: {
        paddingBottom: theme.spacing(2),
    },
    sectionContent: {
        '& *': {
            paddingBottom: theme.spacing(0.5),
        },
        paddingBottom: theme.spacing(1),
    },
    sectionImage: {
        padding: theme.spacing(2),
    },
    sectionSubheader: {
        paddingBottom: theme.spacing(1),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        backgroundColor: 'rgba(2, 35, 37, 0.85)',
    },
}));

const SectionChapter = ({ refs, title, id, explanations, intl }) => {
    const classes = useStyles();
    let objArray = [];
    const [selectedValue, setSelectedValue] = React.useState('');
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = (value) => {
        setSelectedValue(value);
        setOpen(true);
    };

    const handleClose = () => {
        setSelectedValue('');
        setOpen(false);
    };

    if (explanations) {
        explanations.forEach((ex, ii) => {
            objArray.push(
                <Grid item xs={12} sm={12} key={`tutorial-chapter-ex-${id}-${ex.id}-${ii}`}>
                    <section className={classes.sectionContent}>
                        {ex.id && (
                            <Typography>
                                <FormattedMessage
                                    id={ex.id}
                                    values={{
                                        b: (chunks) => <b>{chunks}</b>,
                                    }}
                                />
                            </Typography>
                        )}
                        {ex.children}
                    </section>
                </Grid>
            );
            if (ex.img) {
                ex.img.forEach((im, index) => {
                    objArray.push(
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            key={`tutorial-chapter-img-${id}-${ex.id}-${index}-${ii}`}
                            className={classes.sectionImage}
                        >
                            <img
                                src={im}
                                alt={intl.formatMessage({ id: title })}
                                onClick={() => {
                                    handleClickOpen(im);
                                }}
                            />
                        </Grid>
                    );
                });
            }
        });
    }
    return (
        <Grid
            container
            id={id}
            ref={(el) => {
                if (el && el.id && refs) {
                    refs.current[el.id.toLowerCase()] = el;
                }
            }}
            className={classes.sectionContainer}
        >
            <Grid item xs={12} sm={12}>
                <Typography variant="h3" className={classes.sectionSubheader}>
                    <FormattedMessage id={title} />
                </Typography>
            </Grid>
            {objArray}
            <Dialog onClose={handleClose} open={open} maxWidth={'lg'} style={{ cursor: 'pointer' }}>
                <img src={selectedValue} alt={intl.formatMessage({ id: title })} />
            </Dialog>
        </Grid>
    );
};

export default injectIntl(SectionChapter);
