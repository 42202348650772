import drawUtils from './drawUtils';
import {
    BOARD_TYPE,
    TOOL_BRUSH,
    TOOL_CIRCLE,
    TOOL_ERASER,
    TOOL_LINE,
    TOOL_MATH_CONVERTER,
    TOOL_PEN,
    TOOL_POINTER,
    TOOL_RECTANGLE,
    TOOL_TEXT,
} from '../../constants';
import boardManager from './boardManager';

// const DELAY_BEFORE_CONSIDER_DRAW = 8;

const DELAY_BEFORE_TOUCH_DECISION = 100;
const ERASER_OVERLAY_MOVE_DEBOUNCE = 10;

// const ZOOM_DEBOUNCE_INTERVAL = 10;

let moveUtils = {};

let touchDecisionDebounce = null;
let decisionDone = false;
let snapToRuler = false;
let startTouchArray = [];
let moveTouchArray = [];
let touchDrawMode = false;
let initialZoom = 0;
let initialHorizVal = 0;
let initialVertVal = 0;
// let zoomDebouncer = null;
let currentZoomEvent = null;
let eraserDebouncer = null;
let eraserCurrentEvent = {
    x: 0,
    y: 0,
};
let isSomeTouchDevice = false;

let inMouseOrPen = false;

moveUtils.zoomFunc = (lessonComponent) => {
    // if (lessonComponent.zoomctx) {
    // console.log('zoom called', window.zoomState.zoomLevel);

    // if (drawUtils.isZoomed()) {
    // console.log(
    //     'zoom func',
    //     window.zoomState.zoomLevel + 1,
    //     lessonComponent.startPoint,
    //     lessonComponent.lastHorizValue
    // );
    // let sourceCanvas = drawUtils.getCanvas();
    // let targetCanvas = drawUtils.getZoomedCanvas();
    // let w = targetCanvas.width;
    // targetCanvas.width = 0;
    // targetCanvas.width = w;
    // let targetContext = targetCanvas.getContext('2d');
    // targetContext.imageSmoothingEnabled = true;
    // targetContext.imageSmoothingQuality = 'high';
    // targetContext.drawImage(
    //     sourceCanvas,
    //     lessonComponent.startPoint.x,
    //     lessonComponent.startPoint.y,
    //     targetCanvas.width / (window.zoomState.zoomLevel + 1),
    //     targetCanvas.height / (window.zoomState.zoomLevel + 1),
    //     0,
    //     0,
    //     targetCanvas.width,
    //     targetCanvas.height
    // );
    let sourceCanvas = boardManager.getCurrentCanvas();
    if (!sourceCanvas) return;
    //
    // height: '100%',
    //     width: '100%',
    //     transform: 'translate3d(0px, 0px, 0px)',

    // console.log('window.zoomState.zoomLevel', window.zoomState.zoomLevel, lessonComponent.lastHorizValue);

    // sourceCanvas.style.height = 100 + 50 * window.zoomState.zoomLevel + '%';
    sourceCanvas.style.width = 100 + 50 * window.zoomState.zoomLevel + '%';
    sourceCanvas.style.height = 100 + 50 * window.zoomState.zoomLevel + '%';
    let rect = sourceCanvas.getBoundingClientRect();
    let rectContainer = boardManager.getCurrentCanvasContainer().getBoundingClientRect();
    let moveLeft = (-1 * (Math.abs(rect.width - rectContainer.width) * lessonComponent.lastHorizValue)) / 100;
    let moveTop = (-1 * (Math.abs(rect.height - rectContainer.height) * (100 - lessonComponent.lastVertValue))) / 100;

    if (window.zoomState.zoomLevel === 0) {
        moveTop = 0;
        moveLeft = 0;
    }

    // console.log('rect:', `translate3d(${moveLeft}px, 0px, 0px)`);
    sourceCanvas.style.transform = `translate3d(${moveLeft}px, ${moveTop}px, 0px)`;

    //TODO handle the multiboard scenario
    let svgContainer = boardManager.getCurrentWritingSvgContainer();
    if (svgContainer) {
        svgContainer.style.width = 100 + 50 * window.zoomState.zoomLevel + '%';
        // svgContainer.style.height = 100 + 50 * window.zoomState.zoomLevel + '%';
        svgContainer.style.transform = `translate3d(${moveLeft}px, ${moveTop}px, 0px)`;
    }
    // }
    // }
};

moveUtils.moveZoomer = (event, lessonComponent) => {
    let drawing = lessonComponent.props.drawing;

    // console.log('=== in move zoomer', decisionDone, touchDrawMode);
    // if (decisionDone && !touchDrawMode) {
    //     return;
    // }
    let rect = drawUtils.getCurrentCanvasRect();

    let eventPoint = {
        x: event.clientX - rect.left,
        y: event.clientY - rect.top,
    };
    if (snapToRuler) {
        eventPoint = lessonComponent.rulerElement.current.getOverlapPoint(eventPoint.x, eventPoint.y, true);
    }

    if (
        drawUtils.isOverCurrentCanvas(event, rect) &&
        lessonComponent.isDrawing &&
        drawing.tool !== TOOL_TEXT &&
        drawing.tool !== TOOL_RECTANGLE &&
        drawing.tool !== TOOL_LINE &&
        drawing.tool !== TOOL_CIRCLE
    ) {
        if (lessonComponent.isDrawing === true) {
            // console.log("no bounce", event.clientX, event.clientY);
            // if (new Date().getTime() - lessonComponent.eventTriggerTime > DELAY_BEFORE_CONSIDER_DRAW) {
            //     lessonComponent.eventTriggerTime = new Date().getTime();
            // } else {
            //     return;
            // }
            // console.log("bounce", event.clientX, event.clientY);

            // let scaledDown = drawUtils.scaleDown(
            //     lessonComponent.startPoint,
            //     lessonComponent.x,
            //     lessonComponent.y,
            //     event.clientX - rect.left,
            //     event.clientY - rect.top
            // );

            const dx = lessonComponent.x - eventPoint.x;
            const dy = lessonComponent.y - eventPoint.y;
            const distance = Math.sqrt(dx * dx + dy * dy);
            if (distance < 2) return;


            let scaledDown1 = drawUtils.scalePoint(lessonComponent.startPoint, lessonComponent.x, lessonComponent.y);
            let scaledDown2 = drawUtils.scalePoint(lessonComponent.startPoint, eventPoint.x, eventPoint.y);

            lessonComponent.drawLine(scaledDown1.x, scaledDown1.y, scaledDown2.x, scaledDown2.y);
            lessonComponent.x = eventPoint.x;
            lessonComponent.y = eventPoint.y;
        }
        // moveUtils.zoomFunc(lessonComponent);
    }

    // if (drawing.tool === TOOL_TEXT) {
    //     if (drawUtils.isOverCurrentCanvas(event)) {
    //         let inputText = document.getElementById('inputText');
    //         inputText.style.top = event.clientY - inputText.getBoundingClientRect().height + 'px';
    //         inputText.style.left = event.clientX + 'px';
    //         if (inputText.style.display !== 'block') {
    //             inputText.style.display = 'block';
    //         }
    //     } else {
    //         let inputText = document.getElementById('inputText');
    //         if (inputText.style.display !== 'none') {
    //             inputText.style.display = 'none';
    //         }
    //     }
    // }

    if (drawing.tool === TOOL_ERASER || drawing.tool === TOOL_BRUSH) {
        if (drawUtils.isOverCurrentCanvas(event)) {
            eraserCurrentEvent.x = event.clientX;
            eraserCurrentEvent.y = event.clientY;
            if (!eraserDebouncer) {
                eraserDebouncer = setTimeout(() => {
                    let eraserOverlay = boardManager.getCurrentEraserOverlay();
                    let container = boardManager.getCurrentCanvasContainer();
                    if (container) {
                        // let width = drawUtils.computeEraserWidthForDisplay(drawing.eraserWeight);
                        // let bound = container.getBoundingClientRect();
                        // eraserOverlay.style.top = bound.top + eraserCurrentEvent.y - width / 2 + 'px';
                        // eraserOverlay.style.left = bound.left + eraserCurrentEvent.x - width / 2 + 'px';

                        eraserOverlay.style.top =
                            eraserCurrentEvent.y - eraserOverlay.getBoundingClientRect().height / 2 + 'px';
                        eraserOverlay.style.left =
                            eraserCurrentEvent.x - eraserOverlay.getBoundingClientRect().width / 2 + 'px';
                        if (eraserOverlay.style.display !== 'block') {
                            eraserOverlay.style.display = 'block';
                            setTimeout(() => {
                                eraserOverlay.style.top =
                                    eraserCurrentEvent.y - eraserOverlay.getBoundingClientRect().height / 2 + 'px';
                                eraserOverlay.style.left =
                                    eraserCurrentEvent.x - eraserOverlay.getBoundingClientRect().width / 2 + 'px';
                            }, 0);

                        }
                    }
                    eraserDebouncer = null;
                }, ERASER_OVERLAY_MOVE_DEBOUNCE);
            }
        } else {
            let eraserOverlay = boardManager.getCurrentEraserOverlay();
            if (eraserOverlay.style.display !== 'none') {
                eraserOverlay.style.display = 'none';
            }
        }
    }

    if (
        drawing.tool === TOOL_RECTANGLE ||
        drawing.tool === TOOL_CIRCLE ||
        drawing.tool === TOOL_LINE ||
        (drawing.tool === TOOL_PEN && snapToRuler)
    ) {
        if (drawUtils.isOverCurrentCanvas(event) && lessonComponent.shapePoints.length > 0) {
            moveUtils.moveShape(eventPoint.x, eventPoint.y, lessonComponent, drawing.tool);
        }
    }
};

moveUtils.windowMouseDown = (event, lessonComponent) => {
    // console.log('mouse down', drawUtils.isOverCurrentCanvas(event));
    decisionDone = true;
    if (drawUtils.isOverCurrentCanvas(event)) {
        let drawing = lessonComponent.props.drawing;
        let rect = drawUtils.getCurrentCanvasRect();
        lessonComponent.x = event.clientX - rect.left;
        lessonComponent.y = event.clientY - rect.top;
        // console.log('started over canvas1', lessonComponent.x, lessonComponent.y);

        let scaledDown = drawUtils.scalePoint(lessonComponent.startPoint, lessonComponent.x, lessonComponent.y);

        // lessonComponent.x = scaledDown.x;
        // lessonComponent.y = scaledDown.y;
        // console.log('started over canvas', scaledDown, event.clientX, event.clientY, rect);

        // let scaledDown = drawUtils.scaleDown(
        //     lessonComponent.startPoint,
        //     lessonComponent.x,
        //     lessonComponent.y,
        //     lessonComponent.x,
        //     lessonComponent.y
        // );
        // lessonComponent.x = scaledDown.x2;
        // lessonComponent.y = scaledDown.y2;

        lessonComponent.eventTriggerTime = new Date().getTime();

        let lineOverlapPoint = null;
        if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
            lineOverlapPoint = lessonComponent.rulerElement?.current?.getOverlapPoint(
                lessonComponent.x,
                lessonComponent.y,
            );
        }

        if (lineOverlapPoint && drawing.tool !== TOOL_CIRCLE) {
            lessonComponent.x = lineOverlapPoint.x;
            lessonComponent.y = lineOverlapPoint.y;
            snapToRuler = true;
        }

        if (drawing.tool === TOOL_TEXT) {
            // this.ctx.fillStyle = this.props.drawing.color;
            // this.ctx.font = drawUtils.computeFontPixel(drawing.toolWeight) + 'px Arial';
            // this.ctx.fillText(this.textValue, this.x , this.y);
            // lessonComponent.drawLine(lessonComponent.x, lessonComponent.y, lessonComponent.x, lessonComponent.y);
            // lessonComponent.showTextBox();
            // moveUtils.zoomFunc(lessonComponent);
            // lessonComponent.handleTextStart(scaledDown);
            let multiBoardCloseButton = document.getElementById('multiBoardCloseButton');
            if (multiBoardCloseButton) {
                let r = multiBoardCloseButton.getBoundingClientRect();
                if (
                    event.clientX > r.left &&
                    event.clientX < r.right &&
                    event.clientY > r.top &&
                    event.clientY < r.bottom
                ) {
                    // lessonComponent.handleTextCommit();
                    // lessonComponent.handleTextClose();
                    event.preventDefault();
                    return;
                }
            }

            lessonComponent.handleTextStart(event.clientX - rect.left, event.clientY - rect.top, scaledDown);
        } else if (drawing.tool === TOOL_RECTANGLE) {
            moveUtils.startShape(
                event.clientX - rect.left,
                event.clientY - rect.top,
                lessonComponent,
                document.getElementById('rectangle'),
            );
        } else if (drawing.tool === TOOL_CIRCLE) {
            moveUtils.startShape(
                event.clientX - rect.left,
                event.clientY - rect.top,
                lessonComponent,
                document.getElementById('circle'),
            );
        } else if (drawing.tool === TOOL_LINE) {
            moveUtils.startShape(
                event.clientX - rect.left,
                event.clientY - rect.top,
                lessonComponent,
                document.getElementById('line'),
            );
        } else if (drawing.tool === TOOL_PEN && snapToRuler) {
            moveUtils.startShape(
                event.clientX - rect.left,
                event.clientY - rect.top,
                lessonComponent,
                document.getElementById('line'),
            );
        } else {
            lessonComponent.isDrawing = true;
        }
    }
};

moveUtils.handleTouchEnd = (event, lessonComponent) => {
    if (
        !window.enablePinchToZoom &&
        event.changedTouches &&
        lessonComponent.targetTouchId !== undefined &&
        lessonComponent.targetTouchId !== null &&
        event.changedTouches.length > 0 &&
        lessonComponent.targetTouchId !== event.changedTouches[0].identifier
    ) {
        // console.log('=== ignoring touch end event', lessonComponent.targetTouchId, event);
        return;
    }

    // console.log('=== touch end event', lessonComponent.targetTouchId, event);

    let sentUp = false;
    if (!decisionDone && touchDecisionDebounce) {
        clearTimeout(touchDecisionDebounce);
        startTouchArray.forEach((el) => {
            moveUtils.handleTouchStart(el, lessonComponent);
        });
        if (!inMouseOrPen) {
            moveTouchArray.forEach((el) => {
                moveUtils.handleTouchMove(el, lessonComponent, true);
            });
        }
        touchDrawMode = true;
        touchDecisionDebounce = null;
        startTouchArray = [];
        moveTouchArray = [];
        moveUtils.windowMouseUp(event, lessonComponent);
        sentUp = true;
    }
    if (decisionDone && !touchDrawMode) {
        return;
    }
    startTouchArray = [];
    moveTouchArray = [];

    if (!sentUp) {
        moveUtils.windowMouseUp(event, lessonComponent);
    }
};

moveUtils.windowMouseUp = (event, lessonComponent) => {
    // console.log('mouse up event');
    let drawing = lessonComponent.props.drawing;
    // if (decisionDone && !touchDrawMode) {
    //     return;
    // }

    let rect = drawUtils.getCurrentCanvasRect();

    let eventPoint;

    if (event.clientX && event.clientY) {
        eventPoint = {
            x: event.clientX - rect.left,
            y: event.clientY - rect.top,
        };
    } else if (event.changedTouches) {
        let touches = event.changedTouches;
        let myX, myY;
        myX = touches[0].pageX;
        myY = touches[0].pageY;
        eventPoint = {
            x: myX - rect.left,
            y: myY - rect.top,
        };
    } else {
        lessonComponent.isDrawing = false;
        return;
    }

    if (snapToRuler) {
        eventPoint = lessonComponent.rulerElement.current.getOverlapPoint(eventPoint.x, eventPoint.y, true);
    }

    lessonComponent.targetTouchId = null;
    if (drawing.tool === TOOL_TEXT) {
        // let rect = drawUtils.getCurrentCanvasRect();
        // let scaledDown = drawUtils.scalePoint(
        //     lessonComponent.startPoint,
        //     event.clientX - rect.left,
        //     event.clientY - rect.top
        // );
        return;
    }
    moveUtils.hideEraser(lessonComponent);
    if (drawing.tool === TOOL_RECTANGLE) {
        moveUtils.commitShape(eventPoint.x, eventPoint.y, lessonComponent, document.getElementById('rectangle'));
    }
    if (drawing.tool === TOOL_CIRCLE) {
        moveUtils.commitShape(eventPoint.x, eventPoint.y, lessonComponent, document.getElementById('circle'));
    }
    if (drawing.tool === TOOL_LINE || (drawing.tool === TOOL_PEN && snapToRuler)) {
        moveUtils.commitShape(eventPoint.x, eventPoint.y, lessonComponent, document.getElementById('line'));
        lessonComponent.flushStrokes();
    }
    snapToRuler = false;

    if (
        lessonComponent.isDrawing &&
        (drawing.tool === TOOL_PEN || drawing.tool === TOOL_POINTER || drawing.tool === TOOL_MATH_CONVERTER || drawing.tool === TOOL_BRUSH)
    ) {
        if (lessonComponent.isDrawing === true) {
            let scaledDown1 = drawUtils.scalePoint(lessonComponent.startPoint, lessonComponent.x, lessonComponent.y);
            let scaledDown2 = drawUtils.scalePoint(lessonComponent.startPoint, eventPoint.x, eventPoint.y);
            if (!scaledDown1 || !scaledDown2) {
                lessonComponent.isDrawing = false;
                return;
            }

            if (lessonComponent.localPointArray.length === 0) {
                if (Math.abs(scaledDown2.x - scaledDown1.x) > 2 || Math.abs(scaledDown2.y - scaledDown1.y) > 2) {
                    lessonComponent.isDrawing = false;
                    return;
                }
            }

            lessonComponent.drawLine(scaledDown1.x, scaledDown1.y, scaledDown2.x, scaledDown2.y, true);
            lessonComponent.x = eventPoint.x;
            lessonComponent.y = eventPoint.y;
            lessonComponent.flushStrokes();
        }
    }

    lessonComponent.isDrawing = false;
};

moveUtils.handleMouseLeave = (event, lessonComponent) => {
    let drawing = lessonComponent.props.drawing;
    if (
        drawing.tool === TOOL_RECTANGLE &&
        !drawUtils.isOverCurrentCanvas(event) &&
        !drawUtils.isInElement(event, document.getElementById('rectangle'))
    ) {
        moveUtils.cancelShape(lessonComponent, document.getElementById('rectangle'));
    }
    if (
        drawing.tool === TOOL_CIRCLE &&
        !drawUtils.isOverCurrentCanvas(event) &&
        !drawUtils.isInElement(event, document.getElementById('circle'))
    ) {
        moveUtils.cancelShape(lessonComponent, document.getElementById('circle'));
    }
    if (
        drawing.tool === TOOL_LINE &&
        !drawUtils.isOverCurrentCanvas(event) &&
        !drawUtils.isInElement(event, document.getElementById('line'))
    ) {
        moveUtils.cancelShape(lessonComponent, document.getElementById('line'));
    }

    if (
        lessonComponent.isDrawing &&
        (drawing.tool === TOOL_PEN || drawing.tool === TOOL_POINTER || drawing.tool === TOOL_MATH_CONVERTER || drawing.tool === TOOL_BRUSH)
    ) {
        let rect = drawUtils.getCurrentCanvasRect();
        let cX, cY;
        if (event.clientX && event.clientY) {
            cX = event.clientX;
            cY = event.clientY;
        } else if (event.changedTouches) {
            let touches = event.changedTouches;
            cX = touches[0].pageX;
            cY = touches[0].pageY;
        } else {
            lessonComponent.isDrawing = false;
            lessonComponent.x = rect.left;
            lessonComponent.y = rect.top;
            return;
        }

        let scaledDown1 = drawUtils.scalePoint(lessonComponent.startPoint, lessonComponent.x, lessonComponent.y);
        let scaledDown2 = drawUtils.scalePoint(lessonComponent.startPoint, cX - rect.left, cY - rect.top);

        lessonComponent.drawLine(scaledDown1.x, scaledDown1.y, scaledDown2.x, scaledDown2.y, true);
        lessonComponent.x = cX - rect.left;
        lessonComponent.y = cY - rect.top;
        lessonComponent.flushStrokes();
    }

    lessonComponent.isDrawing = false;
};

moveUtils.reactToZoomChange = (lessonComponent, callZoom = true) => {
    // lessonComponent.rect = lessonComponent.canvas.getBoundingClientRect();
    // lessonComponent.zoomCanvas.width = lessonComponent.canvas.width;
    // lessonComponent.zoomCanvas.height = lessonComponent.canvas.height;
    // lessonComponent.zoomCanvas.style.position = "absolute";
    // lessonComponent.zoomCanvas.style.top = lessonComponent.rect.top + "px";
    // lessonComponent.zoomCanvas.style.left = lessonComponent.rect.left + "px";

    if (drawUtils.isZoomed()) {
        // drawUtils.getZoomedCanvas().width = drawUtils.getCanvas().width;
        // drawUtils.getZoomedCanvas().height = drawUtils.getCanvas().height;
        drawUtils.showSliders();
        // document.getElementById('horizontalSlider').style.visibility = "visible";
        // document.getElementById('verticalSlider').style.visibility = "visible";
        // drawUtils.getCanvas().style.display = 'none';
        // drawUtils.getZoomedCanvas().style.display = 'inline-block';
    } else {
        drawUtils.hideSliders();
        // document.getElementById('horizontalSlider').style.visibility = "hidden";
        // document.getElementById('verticalSlider').style.visibility = "hidden";
        // drawUtils.getCanvas().style.display = 'inline-block';
        // drawUtils.getZoomedCanvas().style.display = 'none';
    }
    // if (callZoom) {
    moveUtils.zoomFunc(lessonComponent);
    // }
};

moveUtils.handleTouchStartWrapper = (e, lessonComponent) => {
    isSomeTouchDevice = true;
    e.preventDefault();
    e.stopPropagation();
    decisionDone = false;
    if (!window.enablePinchToZoom) {
        decisionDone = true;
        moveTouchArray = [];
        startTouchArray = [];
        touchDrawMode = true;
        moveUtils.handleTouchStart(e, lessonComponent);
        return;
    }

    if (!e.targetTouches) {
        moveUtils.handleTouchStart(e, lessonComponent);
        return;
    }

    if (decisionDone) {
        if (touchDrawMode) {
            moveUtils.handleTouchStart(e, lessonComponent);
        } else {
            // handle zooming here
            // console.log('=== should be zooming in start', startTouchArray, e);
        }
    } else {
        if (touchDecisionDebounce) {
            startTouchArray.push(moveUtils.cloneTouchEvent(e));
        } else {
            startTouchArray = [];
            moveTouchArray = [];
            startTouchArray.push(moveUtils.cloneTouchEvent(e));
            moveUtils.startDecisionProcess(lessonComponent);
        }
    }
};

moveUtils.startDecisionProcess = (lessonComponent) => {
    decisionDone = false;
    initialZoom = window.zoomState.zoomLevel;
    initialHorizVal = lessonComponent.lastHorizValue;
    initialVertVal = lessonComponent.lastVertValue;
    touchDecisionDebounce = setTimeout(() => {
        // console.log('debounce triggered', startTouchArray, moveTouchArray);
        if (inMouseOrPen) {
            decisionDone = true;
            touchDecisionDebounce = null;
            moveTouchArray = [];
            startTouchArray = [];
            return;
        }
        if (
            startTouchArray[startTouchArray.length - 1] &&
            startTouchArray[startTouchArray.length - 1].targetTouches.length > 1
        ) {
            // need to start zooming
            // console.log('=== should zoom', startTouchArray);
            touchDrawMode = false;
        } else {
            startTouchArray.forEach((el) => {
                moveUtils.handleTouchStart(el, lessonComponent);
            });
            if (!inMouseOrPen) {
                moveTouchArray.forEach((el) => {
                    moveUtils.handleTouchMove(el, lessonComponent, true);
                });
            }
            touchDrawMode = true;
            startTouchArray = [];
        }
        decisionDone = true;
        touchDecisionDebounce = null;
        moveTouchArray = [];
    }, DELAY_BEFORE_TOUCH_DECISION);
};

moveUtils.handleZoomDebounce = (e, lessonComponent) => {
    // if (zoomDebouncer) {
    currentZoomEvent = e.targetTouches;
    // } else {
    //     currentZoomEvent = e.targetTouches;
    //     zoomDebouncer = setTimeout(() =>
    //     {
    // handle zooming here
    // console.log('==== debouncing', startTouchArray, currentZoomEvent);
    // zoomDebouncer = null;
    if (!startTouchArray[startTouchArray.length - 1]) return;
    if (!currentZoomEvent) return;
    let lastTouches = startTouchArray[startTouchArray.length - 1].targetTouches;
    if (lastTouches[0] && lastTouches[1] && currentZoomEvent[0] && currentZoomEvent[1]) {
        let increase = drawUtils.computePinchZoom(
            lastTouches[0],
            lastTouches[1],
            currentZoomEvent[0],
            currentZoomEvent[1],
        );
        if (increase === null) return;
        let newZoom;
        // if (Math.abs(increase.percentage) > 20) {
        newZoom = initialZoom + (4 * increase.percentage * (initialZoom + 1)) / 100;
        // }
        if (newZoom > 9) {
            newZoom = 9;
        } else if (newZoom < 0) {
            newZoom = 0;
        }
        // console.log('new zoom', initialZoom, increase);
        window.zoomState.zoomLevel = newZoom;
        if (!drawUtils.getCanvasContainer()) return;
        let targetCanvas = drawUtils.getCanvasContainer().getBoundingClientRect();
        let w = targetCanvas.width;
        let y = targetCanvas.height;
        if (w === 0) {
            let targetCanvas = drawUtils.getCanvas();
            w = targetCanvas.width;
            y = targetCanvas.height;
        }
        let moveScalar = newZoom;
        if (moveScalar < 1) {
            moveScalar = 1;
        }

        // let xZoomCurrentPerc = (((increase.initialMid.x + increase.currentMid.x) / 2) * 100) / w;
        // let oldPercAdjusted = (initialHorizVal * (newZoom + 1)) / (initialZoom + 1);
        // if (initialZoom !== newZoom && w * moveScalar > 0.2) {
        //     xZoomMove = (((increase.initialMid.x + increase.currentMid.x) / 2) * 100) / (w * moveScalar);
        // }
        // lessonComponent.lastHorizValue = initialHorizVal + Math.round((xMove * 100) / w);
        // lessonComponent.lastHorizValue = initialHorizVal + Math.round((xMove * 100) / w);

        let xMove = (increase.initialMid.x - increase.currentMid.x) / moveScalar;
        let midX = (increase.initialMid.x + increase.currentMid.x) / 2;
        let upgradedX = (drawUtils.CANVAS_ACTUAL_WIDTH * midX) / w;
        let perComputeX = (upgradedX * 100) / drawUtils.CANVAS_ACTUAL_WIDTH;

        if (initialHorizVal === 0 && initialZoom === 0) {
            lessonComponent.lastHorizValue = perComputeX;
        } else {
            lessonComponent.lastHorizValue = initialHorizVal + Math.round((xMove * 100) / w);
        }

        // console.log(' === xmove comp', lessonComponent.lastHorizValue, midX, upgradedX, perCompute);
        if (lessonComponent.lastHorizValue > 100) {
            lessonComponent.lastHorizValue = 100;
        } else if (lessonComponent.lastHorizValue < 0) {
            lessonComponent.lastHorizValue = 0;
        }

        let yMove = (increase.initialMid.y - increase.currentMid.y) / moveScalar;
        let midY = (increase.initialMid.y + increase.currentMid.y) / 2;
        let upgradedY = (drawUtils.CANVAS_ACTUAL_HEIGHT * midY) / y;
        let perComputeY = (upgradedY * 100) / drawUtils.CANVAS_ACTUAL_HEIGHT;

        if (initialZoom === 0) {
            lessonComponent.lastVertValue = 100 - perComputeY;
        } else {
            lessonComponent.lastVertValue = initialVertVal - Math.round((yMove * 100) / y);
        }

        // let yMove = (increase.currentMid.y - increase.initialMid.y) / moveScalar;
        // let yZoomMove = 0;
        // if (initialZoom !== newZoom && y * moveScalar > 0.2) {
        //     yZoomMove = (((increase.initialMid.y + increase.currentMid.y) / 2) * 100) / (y * moveScalar);
        // }
        // yZoomMove = (((increase.initialMid.y + increase.currentMid.y) / 2) * 100) / y;
        //
        // lessonComponent.lastVertValue = (initialVertVal * (newZoom + 1)) / (initialZoom + 1);
        // lessonComponent.lastVertValue = initialVertVal + Math.round((yMove * 100) / y);
        // console.log(' === ymove', lessonComponent.lastVertValue, yMove, initialVertVal, y);
        if (lessonComponent.lastVertValue > 100) {
            lessonComponent.lastVertValue = 100;
        } else if (lessonComponent.lastVertValue < 0) {
            lessonComponent.lastVertValue = 0;
        }

        if (newZoom === 0) {
            lessonComponent.lastVertValue = 100;
            lessonComponent.lastHorizValue = 0;
        }

        drawUtils.triggerWindowCallbacks();
        window.forceZoomBox();
        window.forceHorizSlider(lessonComponent.lastHorizValue);
        window.forceVertSlider(lessonComponent.lastVertValue);
        // console.log('data to trigger', newZoom, lessonComponent.lastHorizValue, lessonComponent.lastVertValue);
        currentZoomEvent = null;
    }
    // }
    //     , ZOOM_DEBOUNCE_INTERVAL);
    // }
};

moveUtils.handleTouchMoveWrapper = (e, lessonComponent) => {
    e.preventDefault();
    e.stopPropagation();
    if (!e.targetTouches) {
        moveUtils.handleTouchMove(e, lessonComponent);
        return;
    }
    if (!window.enablePinchToZoom) {
        decisionDone = true;
        moveTouchArray = [];
        startTouchArray = [];
        touchDrawMode = true;
    }

    if (decisionDone) {
        if (!inMouseOrPen) {
            if (touchDrawMode) {
                moveUtils.handleTouchMove(e, lessonComponent);
            } else {
                moveUtils.handleZoomDebounce(e, lessonComponent);
            }
        }
    } else {
        if (!inMouseOrPen) {
            if (touchDecisionDebounce) {
                moveTouchArray.push(moveUtils.cloneTouchEvent(e));
            } else {
                moveTouchArray = [];
                moveTouchArray.push(moveUtils.cloneTouchEvent(e));
                moveUtils.startDecisionProcess(lessonComponent);
            }
        }
    }
};

moveUtils.cloneTouchEvent = (e) => {
    let shallowE = {};
    if (e.touches) {
        shallowE.touches = [...e.touches];
    }
    if (e.targetTouches) {
        shallowE.targetTouches = [...e.targetTouches];
    }
    if (e.changedTouches) {
        shallowE.changedTouches = [e.changedTouches];
    }
    return shallowE;
};

moveUtils.handleTouchStart = (e, lessonComponent) => {
    lessonComponent.eventTriggerTime = new Date().getTime();

    if (
        e.targetTouches &&
        (lessonComponent.targetTouchId === null || lessonComponent.targetTouchId === undefined) &&
        e.targetTouches[0]
    ) {
        lessonComponent.targetTouchId = e.targetTouches[0].identifier;
    } else {
        console.log('skipping on start');
        return;
    }

    let touches = e.touches;
    let xZ = 0,
        yZ = 0;
    let scaledDown;
    let rect = drawUtils.getCurrentCanvasRect();
    let drawing = lessonComponent.props.drawing;
    let pX;
    let pY;
    if (touches && touches[0] && touches[0][0]) {
        pX = touches[0][0].pageX;
        pY = touches[0][0].pageY;
    } else if (touches && touches[0]) {
        pX = touches[0].pageX;
        pY = touches[0].pageY;
    } else {
        return;
    }

    let lineOverlapPoint = lessonComponent.rulerElement?.current?.getOverlapPoint(pX - rect.left, pY - rect.top);
    if (lineOverlapPoint && drawing.tool !== TOOL_CIRCLE) {
        snapToRuler = true;
    }

    let currentTouch;
    let id = null;
    for (let i = 0; i < touches.length; i++) {
        // console.log('continuing on start', touches[i][0], touches[i].identifier, lessonComponent.targetTouchId);

        if (touches[i].identifier !== undefined) {
            id = touches[i].identifier;
            currentTouch = touches[i];
        } else if (touches[i] && touches[i][0]) {
            id = touches[i][0].identifier;
            currentTouch = touches[i][0];
        }
        if (id !== lessonComponent.targetTouchId) continue;

        // lessonComponent.ongoingTouches.push(drawUtils.copyTouch(touches[i]));
        if (snapToRuler) {
            lineOverlapPoint = lessonComponent.rulerElement?.current?.getOverlapPoint(
                currentTouch.pageX - rect.left,
                currentTouch.pageY - rect.top,
                true,
            );
            lessonComponent.x = lineOverlapPoint.x;
            lessonComponent.y = lineOverlapPoint.y;
            xZ = lineOverlapPoint.x;
            yZ = lineOverlapPoint.y;
        } else {
            lessonComponent.x = currentTouch.pageX - rect.left;
            lessonComponent.y = currentTouch.pageY - rect.top;
            xZ = currentTouch.pageX - rect.left;
            yZ = currentTouch.pageY - rect.top;
        }
    }
    moveUtils.hideEraser(lessonComponent);

    if (drawing.tool === TOOL_TEXT) {
        scaledDown = drawUtils.scalePoint(lessonComponent.startPoint, lessonComponent.x, lessonComponent.y);
        // console.log('scaling down', scaledDown);
        let multiBoardCloseButton = document.getElementById('multiBoardCloseButton');
        if (multiBoardCloseButton) {
            let r = multiBoardCloseButton.getBoundingClientRect();
            if (
                currentTouch.pageX > r.left &&
                currentTouch.pageX < r.right &&
                currentTouch.pageY > r.top &&
                currentTouch.pageY < r.bottom
            ) {
                // lessonComponent.handleTextCommit();
                // lessonComponent.handleTextClose();
                return;
            }
        }

        lessonComponent.handleTextStart(xZ, yZ, scaledDown);
    }
    if (drawing.tool === TOOL_RECTANGLE) {
        moveUtils.startShape(xZ, yZ, lessonComponent, document.getElementById('rectangle'));
    }
    if (drawing.tool === TOOL_CIRCLE) {
        moveUtils.startShape(xZ, yZ, lessonComponent, document.getElementById('circle'));
    }
    if (drawing.tool === TOOL_LINE) {
        moveUtils.startShape(xZ, yZ, lessonComponent, document.getElementById('line'));
    }
    if (drawing.tool === TOOL_PEN && snapToRuler) {
        moveUtils.startShape(xZ, yZ, lessonComponent, document.getElementById('line'));
        lessonComponent.isDrawing = false;
        return;
    }
    if (drawing.tool === TOOL_PEN || drawing.tool === TOOL_POINTER || drawing.tool === TOOL_MATH_CONVERTER || drawing.tool === TOOL_BRUSH) {
        lessonComponent.isDrawing = true;
    }
};

moveUtils.handleTouchMove = (e, lessonComponent, shallow = false) => {
    let drawing = lessonComponent.props.drawing;

    // if (!shallow) {
    //     if (new Date().getTime() - lessonComponent.eventTriggerTime > DELAY_BEFORE_CONSIDER_DRAW) {
    //         lessonComponent.eventTriggerTime = new Date().getTime();
    //     } else {
    //         console.log('skipping move logic');
    //         return;
    //     }
    // }
    if (!e.touches) {
        return;
    }

    let touches = e.touches;
    let myX, myY;
    if (drawing.tool === TOOL_TEXT) {
        return;
    }

    let id = null;
    let rect = drawUtils.getCurrentCanvasRect();

    for (let i = 0; i < touches.length; i++) {
        let currentTouch;

        if (touches[i].identifier !== undefined) {
            id = touches[i].identifier;
            currentTouch = touches[i];
        } else if (touches[i] && touches[i][0]) {
            id = touches[i][0].identifier;
            currentTouch = touches[i][0];
        }

        if (
            id !== lessonComponent.targetTouchId ||
            lessonComponent.targetTouchId === undefined ||
            lessonComponent.targetTouchId === null
        ) {
            continue;
        }

        // let idx = lessonComponent.ongoingTouchIndexById(touches[i].identifier);
        // console.log('figure out ', idx, touches[i].identifier);

        // if (idx >= 0) {
        if (snapToRuler) {
            let lineOverlapPoint = lessonComponent.rulerElement?.current?.getOverlapPoint(
                currentTouch.pageX - rect.left,
                currentTouch.pageY - rect.top,
                true,
            );
            myX = lineOverlapPoint.x;
            myY = lineOverlapPoint.y;
        } else {
            myX = currentTouch.pageX - rect.left;
            myY = currentTouch.pageY - rect.top;
        }
        if (drawing.tool === TOOL_RECTANGLE) {
            continue;
        }
        if (drawing.tool === TOOL_CIRCLE) {
            continue;
        }
        if (drawing.tool === TOOL_LINE) {
            continue;
        }
        if (drawing.tool === TOOL_PEN && snapToRuler) {
            continue;
        }

        if (drawing.tool === TOOL_ERASER || drawing.tool === TOOL_BRUSH) {
            // console.log('should show eraser');
            eraserCurrentEvent.x = myX;
            eraserCurrentEvent.y = myY;
            if (!eraserDebouncer) {
                eraserDebouncer = setTimeout(() => {
                    let eraserOverlay = boardManager.getCurrentEraserOverlay();
                    let container = boardManager.getCurrentCanvasContainer();
                    if (container) {
                        let width = drawUtils.computeEraserWidthForDisplay(drawing.eraserWeight);
                        let bound = container.getBoundingClientRect();
                        eraserOverlay.style.top = bound.top + eraserCurrentEvent.y - width / 2 + 'px';
                        eraserOverlay.style.left = bound.left + eraserCurrentEvent.x - width / 2 + 'px';
                        if (eraserOverlay.style.display !== 'block') {
                            console.log('===here 2');
                            eraserOverlay.style.display = 'block';
                        }
                    }
                    eraserDebouncer = null;
                }, ERASER_OVERLAY_MOVE_DEBOUNCE);
            }
        }

        let scaledDown1 = drawUtils.scalePoint(lessonComponent.startPoint, lessonComponent.x, lessonComponent.y);
        let scaledDown2 = drawUtils.scalePoint(lessonComponent.startPoint, myX, myY);

        if (scaledDown1 && scaledDown2) {
            lessonComponent.drawLine(scaledDown1.x, scaledDown1.y, scaledDown2.x, scaledDown2.y);
        }
        lessonComponent.x = myX;
        lessonComponent.y = myY;

        // lessonComponent.ongoingTouches.splice(idx, 1, drawUtils.copyTouch(touches[i])); // swap in the new touch record
        // moveUtils.zoomFunc(lessonComponent);
        // } else {
        //     console.log("can't figure out which touch to continue");
        // }
    }

    // moveUtils.hideEraser(lessonComponent);
    // let rect = drawUtils.getCurrentCanvasRect();

    if (
        drawing.tool === TOOL_RECTANGLE ||
        drawing.tool === TOOL_CIRCLE ||
        drawing.tool === TOOL_LINE ||
        (drawing.tool === TOOL_PEN && snapToRuler)
    ) {
        if (lessonComponent.shapePoints.length > 0) {
            moveUtils.moveShape(myX, myY, lessonComponent, drawing.tool);
        }
    }
};

moveUtils.hideEraser = function(lessonComponent) {
    let drawing = lessonComponent.props.drawing;
    let inputEraser = boardManager.getCurrentEraserOverlay();

    if ((drawing.tool === TOOL_ERASER || drawing.tool === TOOL_BRUSH) && inputEraser.style.display !== 'none') {
        inputEraser.style.display = 'none';
    }
};

moveUtils.startShape = function(x, y, lessonComponent, element) {
    let drawing = lessonComponent.props.drawing;
    let rect = drawUtils.getCurrentCanvasRect();
    lessonComponent.shapePoints = [
        {
            x: lessonComponent.x + rect.left,
            y: lessonComponent.y + rect.top,
            xZ: x + rect.left,
            yZ: y + rect.top,
        },
    ];

    // check if point is already created
    // =======

    let scaledDown1 = drawUtils.scalePoint(
        lessonComponent.startPoint,
        lessonComponent.shapePoints[0].x - rect.left,
        lessonComponent.shapePoints[0].y - rect.top,
    );
    let updatedPoints = boardManager.getFromLinePoints(scaledDown1.x, scaledDown1.y);
    if (updatedPoints.x !== scaledDown1.x || updatedPoints.y !== scaledDown1.y) {
        let reversedUpdated = drawUtils.reverseScalePoint(lessonComponent.startPoint, updatedPoints.x, updatedPoints.y);
        lessonComponent.x = reversedUpdated.x;
        lessonComponent.y = reversedUpdated.y;
        reversedUpdated = {
            x: reversedUpdated.x + rect.left,
            y: reversedUpdated.y + rect.top,
        };

        lessonComponent.shapePoints = [
            {
                x: reversedUpdated.x,
                y: reversedUpdated.y,
                xZ: reversedUpdated.x,
                yZ: reversedUpdated.y,
            },
        ];
    }
};

moveUtils.commitShape = function(clientX, clientY, lessonComponent, element) {
    if (lessonComponent.shapePoints.length === 0) {
        moveUtils.cancelShape(lessonComponent, element);
        return;
    }

    let rect = drawUtils.getCurrentCanvasRect();
    let scaledDown1 = drawUtils.scalePoint(
        lessonComponent.startPoint,
        lessonComponent.shapePoints[0].x - rect.left,
        lessonComponent.shapePoints[0].y - rect.top,
    );
    let scaledDown2 = drawUtils.scalePoint(lessonComponent.startPoint, clientX, clientY);

    let updatedPoints = boardManager.getFromLinePoints(scaledDown2.x, scaledDown2.y);
    if (updatedPoints.x !== scaledDown2.x || scaledDown2.y !== scaledDown2.y) {
        scaledDown2.x = updatedPoints.x;
        scaledDown2.y = updatedPoints.y;
    }

    lessonComponent.drawLine(scaledDown1.x, scaledDown1.y, scaledDown2.x, scaledDown2.y, true);

    lessonComponent.x = 0;
    lessonComponent.y = 0;
    lessonComponent.isDrawing = false;

    // moveUtils.zoomFunc(lessonComponent);

    moveUtils.cancelShape(lessonComponent, element);
};

moveUtils.moveShape = function(point2X, point2Y, lessonComponent, tool) {
    let scalePoint1 = drawUtils.scalePoint(lessonComponent.startPoint, lessonComponent.x, lessonComponent.y);
    let scalePoint2 = drawUtils.scalePoint(lessonComponent.startPoint, point2X, point2Y);
    boardManager.drawShape(
        tool,
        scalePoint1.x,
        scalePoint1.y,
        scalePoint2.x,
        scalePoint2.y,
        lessonComponent.props.drawing.toolWeight,
        lessonComponent.props.drawing.color,
    );
};

moveUtils.moveArc = function(x, y, width, direction, start, end, lessonComponent) {
    boardManager.drawArc(
        x,
        y,
        width,
        direction,
        start,
        end,
        lessonComponent.props.drawing.toolWeight,
        lessonComponent.props.drawing.color,
    );
};

moveUtils.cancelShape = function(lessonComponent, element) {
    boardManager.drawShape(null);
    lessonComponent.shapePoints = [];
    if (!element) return;
    if (element.style.display !== 'none') {
        element.style.display = 'none';
    }
    element.style.top = '0px';
    element.style.height = '0px';
    element.style.left = '0px';
    element.style.width = '0px';
};

moveUtils.getIsTouchEnabled = () => {
    return isSomeTouchDevice;
};

moveUtils.setMouseOrPen = (val) => {
    inMouseOrPen = val;
};

export default moveUtils;
