import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { Button, FormControlLabel, Grid, Switch, TextField } from '@material-ui/core';
import managementApi from '../../Api/managementApi';

const AddEditSubscription = ({ subscription, pricing, handleClose }) => {
    const [stripeId, setStripeId] = React.useState('');
    const [groupCount, setGroupCount] = React.useState(0);
    const [collectionCount, setCollectionCount] = React.useState(0);
    const [multipleIndividual, setMultipleIndividual] = React.useState(false);
    const [pdf, setPdf] = React.useState(false);
    const [audioVideo, setAudioVideo] = React.useState(false);

    useEffect(() => {
        setStripeId(subscription.subscriptionStripeId);
        setGroupCount(subscription.groupCount);
        setCollectionCount(subscription.collectionCount);
        setMultipleIndividual(subscription.multipleIndividualBoards);
        setPdf(subscription.pdfDownload);
        setAudioVideo(subscription.audioVideo);
    }, [subscription]);

    const updateSubscription = () => {
        managementApi
            .updateSubscriptionTypes(subscription.id, {
                subscriptionStripeId: stripeId,
                groupCount: groupCount,
                collectionCount: collectionCount,
                multipleIndividualBoards: multipleIndividual,
                pdfDownload: pdf,
                audioVideo: audioVideo,
            })
            .then(() => {
                handleClose();
            })
            .catch(alert);
    };

    return (
        <>
            <Grid container direction="column" spacing={2} style={{ paddingTop: '20px' }}>
                <Grid item>
                    <Typography variant="h2">{subscription.subscriptionName}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h4">Payment</Typography>
                    {pricing[subscription.subscriptionName] ? (
                        pricing[subscription.subscriptionName].map((el, index) => (
                            <Typography variant={'h4'} key={'price-' + subscription.subscriptionName + '-' + index}>
                                {el.unit_amount / 100} {el.currency} / {el.interval}
                            </Typography>
                        ))
                    ) : (
                        <Typography variant="h4">Free</Typography>
                    )}
                </Grid>
                <Grid item>
                    <TextField
                        label="Stripe Subscription"
                        variant="outlined"
                        value={stripeId}
                        onChange={(e) => {
                            setStripeId(e.target.value);
                        }}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        label="Group Count"
                        variant="outlined"
                        value={groupCount}
                        onChange={(e) => {
                            setGroupCount(e.target.value);
                        }}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        label="Collection Count"
                        variant="outlined"
                        value={collectionCount}
                        onChange={(e) => {
                            setCollectionCount(e.target.value);
                        }}
                    />
                </Grid>
                <Grid item>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={multipleIndividual}
                                onChange={(e) => {
                                    setMultipleIndividual(e.target.checked);
                                }}
                            />
                        }
                        label="Multiple individual boards"
                    />
                </Grid>

                <Grid item>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={pdf}
                                onChange={(e) => {
                                    setPdf(e.target.checked);
                                }}
                            />
                        }
                        label="PDF"
                    />
                </Grid>
                <Grid item>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={audioVideo}
                                onChange={(e) => {
                                    setAudioVideo(e.target.checked);
                                }}
                            />
                        }
                        label="Audio/Video"
                    />
                </Grid>
                <Grid item>
                    <Button color={'primary'} onClick={updateSubscription} variant={'outlined'}>
                        Update
                    </Button>

                    <Button color={'secondary'} onClick={handleClose} variant={'outlined'}>
                        Close
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default AddEditSubscription;
