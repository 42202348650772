import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function FacebookForSubscriptionsIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_83_13993)">
                    <path d="M16 32.002C24.8366 32.002 32 24.8385 32 16.002C32 7.1654 24.8366 0.00195312 16 0.00195312C7.16344 0.00195312 0 7.1654 0 16.002C0 24.8385 7.16344 32.002 16 32.002Z" fill="#1977F3"/>
                    <path d="M22.2289 20.6277L22.9377 16.0017H18.5006V13.0001C18.5006 11.7356 19.1194 10.5003 21.1084 10.5003H23.1267V6.56275C23.1267 6.56275 21.2952 6.25 19.5447 6.25C15.8906 6.25 13.5011 8.46403 13.5011 12.4758V16.0017H9.4375V20.6277H13.5011V31.8082C14.3156 31.9364 15.1503 32.0017 16.0009 32.0017C16.8514 32.0017 17.6861 31.9342 18.5006 31.8082V20.6277H22.2289Z" fill="white"/>
                </g>
                <defs>
                    <clipPath id="clip0_83_13993">
                        <rect width="32" height="32" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        </SvgIcon>
    );
}
