import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { USER_MESSAGE_TYPES, USER_MESSAGES, USER_MESSAGES_REVERTED } from '../../shared/UserMessaging';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import { AccordionActions } from '@material-ui/core';
import DeleteIcon from '../../MyComponents/icons/DeleteIcon';
import MessageIcon from '../../MyComponents/icons/MessageIcon';
import { Link } from 'react-router-dom';
import { COLORS2 } from '../../constants';
import { determineDefaultLanguage } from '../../common/utils';
import Hidden from '@material-ui/core/Hidden';

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummaryUnread = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .08)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionSummaryRead = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .01)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

const useStyles = makeStyles(() => ({
    customDelete: {
        fontWeight: 400,
        fontSize: '1rem',
        lineHeight: '1rem',
        letterSpacing: '-0.01rem',
        textTransform: 'none',
        color: COLORS2.virtualRed,
    },
    customUnread: {
        fontWeight: 400,
        fontSize: '1rem',
        lineHeight: '1rem',
        letterSpacing: '-0.01rem',
        textTransform: 'none',
        color: COLORS2.virtualGreen,
    },
    spanDate: {
        fontWeight: 500,
        fontSize: '1rem',
        color: COLORS2.darkGreen,
    },
    spanHour: {
        color: COLORS2.virtualAmber,
        fontWeight: 500,
        fontSize: '1rem',
        background: COLORS2.virtualBackground,
        borderRadius: '0.25rem',
        marginRight: '1rem',
        textAlign: 'center',
    },
}));

function MessageAccordions({ messages, handleDelete, handleReadMark, intl }) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(null);

    const handleChange = (panel) => (event, newExpanded) => {
        let newVal = newExpanded ? panel : false;
        setExpanded(newVal);
        if (newVal && handleReadMark) {
            handleReadMark(newVal, true);
        }
    };

    const makeUnread = (id) => {
        setExpanded(false);
        if (handleReadMark) {
            handleReadMark(id, false);
        }
    };

    let localesOptions = 'ro-RO';
    let currentLanguage = determineDefaultLanguage();
    if (currentLanguage === 'en') {
        localesOptions = 'en-EN';
    } else if (currentLanguage === 'fr') {
        localesOptions = 'fr-FR';
    } else if (currentLanguage === 'ro') {
        localesOptions = 'ro-RO';
    }

    const formatDate = (row) => {
        if (row.createdAt) {
            let d = new Date(row.createdAt);
            return (
                <>
                    <Hidden only={['xs']}>
                        <span className={classes.spanHour}>
                            {d.toLocaleString(localesOptions, {
                                hour: 'numeric',
                                minute: 'numeric',
                            })}
                        </span>
                        <span className={classes.spanDate}>
                            {d.toLocaleString(localesOptions, {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                            })}
                        </span>
                    </Hidden>
                    <Hidden only={['sm','md','lg','xl']}>
                        <div className={classes.spanHour}>
                            {d.toLocaleString(localesOptions, {
                                hour: 'numeric',
                                minute: 'numeric',
                            })}
                        </div>
                        <div className={classes.spanDate}>
                            {d.toLocaleString(localesOptions, {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                            })}
                        </div>
                    </Hidden>
                </>
            );
        } else {
            return '';
        }
    };

    const formatTitle = (row) => {
        if (row.messageType === USER_MESSAGE_TYPES.PREDEFINED_TYPE) {
            return intl.formatMessage({
                id: `messages.USER_MESSAGES.${USER_MESSAGES_REVERTED[row.messageCode]}.title`,
            });
        }
        return '';
    };

    const formatBody = (row) => {
        if (row.messageType === USER_MESSAGE_TYPES.PREDEFINED_TYPE) {
            let values = {
                date: '',
                subscriptionName: 'N/A',
                hostedUrl: '#',
                hostedPdf: '#',
            };
            if (row?.meta?.endDate) {
                values.date = new Date(row.meta.endDate).toLocaleDateString(undefined, {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                });
            }
            if (row?.meta?.subscriptionName) {
                values.subscriptionName = row.meta.subscriptionName;
            }
            if (row?.meta?.hostedUrl) {
                values.hostedUrl = row.meta.hostedUrl;
            }
            if (row?.meta?.hostedPdf) {
                values.hostedPdf = row.meta.hostedPdf;
            }

            switch (row.messageCode) {
                case USER_MESSAGES.UPGRADED_TO_BONUS_TRIAL: {
                    return (
                        <div>
                            <Typography variant="h4">
                                <FormattedMessage id="messages.USER_MESSAGES.UPGRADED_TO_BONUS_TRIAL.b1" />
                            </Typography>
                            <Typography variant="h5">
                                <FormattedMessage
                                    id="messages.USER_MESSAGES.UPGRADED_TO_BONUS_TRIAL.b2"
                                    values={values}
                                />
                            </Typography>
                        </div>
                    );
                }
                case USER_MESSAGES.STARTED_FREE: {
                    return (
                        <div>
                            <Typography variant="h4">
                                <FormattedMessage id="messages.USER_MESSAGES.STARTED_FREE.b1" />
                            </Typography>
                            <Typography variant="h5">
                                <FormattedMessage id="messages.USER_MESSAGES.STARTED_FREE.b2" />
                            </Typography>
                            <Link to={'/subscriptions'}>
                                <Button variant={'outlined'} color="primary" size={'large'}>
                                    <FormattedMessage id="topMenu.subscriptions.name" />
                                </Button>
                            </Link>
                        </div>
                    );
                }
                case USER_MESSAGES.UPDATED_SUBSCRIPTION: {
                    return (
                        <div>
                            <Typography variant="h4">
                                <FormattedMessage id="messages.USER_MESSAGES.UPDATED_SUBSCRIPTION.b1" values={values} />
                            </Typography>
                            <Typography variant="h5">
                                <FormattedMessage id="messages.USER_MESSAGES.UPDATED_SUBSCRIPTION.b2" values={values} />
                            </Typography>
                        </div>
                    );
                }
                case USER_MESSAGES.CANCELED_SUBSCRIPTION: {
                    return (
                        <div>
                            <Typography variant="h4">
                                <FormattedMessage
                                    id="messages.USER_MESSAGES.CANCELED_SUBSCRIPTION.b1"
                                    values={values}
                                />
                            </Typography>
                            <Typography variant="h5">
                                <FormattedMessage
                                    id="messages.USER_MESSAGES.CANCELED_SUBSCRIPTION.b2"
                                    values={values}
                                />
                            </Typography>
                        </div>
                    );
                }
                case USER_MESSAGES.INVOICE_GENERATED: {
                    return (
                        <div>
                            <Typography variant="h4">
                                <FormattedMessage id="messages.USER_MESSAGES.INVOICE_GENERATED.b1" />
                            </Typography>
                            <a target="_blank" href={values.hostedUrl} rel="noreferrer">
                                <Button variant={'outlined'} color="primary" size={'large'}>
                                    <FormattedMessage id="messages.USER_MESSAGES.INVOICE_GENERATED.b2" />
                                </Button>
                            </a>
                        </div>
                    );
                }
                case USER_MESSAGES.PAYMENT_FAILED: {
                    return (
                        <div>
                            <Typography variant="h4">
                                <FormattedMessage id="messages.USER_MESSAGES.PAYMENT_FAILED.b1" />
                            </Typography>
                            <a target="_blank" href={values.hostedUrl} rel="noreferrer">
                                <Button variant={'outlined'} color="primary" size={'large'}>
                                    <FormattedMessage id="messages.USER_MESSAGES.PAYMENT_FAILED.b2" />
                                </Button>
                            </a>
                        </div>
                    );
                }

                default:
                    return <Typography>N/A</Typography>;
            }
        }
        return '';
    };

    return (
        <div>
            {messages.map((el) => {
                return (
                    <Accordion
                        square
                        expanded={expanded === el.id}
                        onChange={handleChange(el.id)}
                        key={`message-accordion-${el.id}`}
                    >
                        {el.isRead ? (
                            <AccordionSummaryRead>
                                <span
                                    style={{ fontWeight: '300', marginRight: '2rem', fontSize: '1rem', height: '100%' }}
                                >
                                    {formatDate(el)}
                                </span>
                                <span style={{ fontWeight: '600', fontSize: '1rem', height: '100%' }}>
                                    {formatTitle(el)}
                                </span>
                            </AccordionSummaryRead>
                        ) : (
                            <AccordionSummaryUnread>
                                <span
                                    style={{ fontWeight: '300', marginRight: '2rem', fontSize: '1rem', height: '100%' }}
                                >
                                    {formatDate(el)}
                                </span>
                                <span style={{ fontWeight: '600', fontSize: '1rem', height: '100%' }}>
                                    {formatTitle(el)}
                                </span>
                            </AccordionSummaryUnread>
                        )}
                        <AccordionDetails>{formatBody(el)}</AccordionDetails>
                        <AccordionActions>
                            <Button
                                size="small"
                                startIcon={<MessageIcon />}
                                onClick={() => makeUnread(el.id)}
                                className={classes.customUnread}
                            >
                                <FormattedMessage id="messages.markUnread" />
                            </Button>
                            <Button
                                size="small"
                                startIcon={<DeleteIcon />}
                                onClick={() => handleDelete(el.id)}
                                className={classes.customDelete}
                            >
                                <FormattedMessage id="messages.delete" />
                            </Button>
                        </AccordionActions>
                    </Accordion>
                );
            })}
        </div>
    );
}

export default injectIntl(MessageAccordions);
