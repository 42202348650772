import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function SelectedOrangeFolderIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 21.5H21C22.6547 21.5 24 20.1547 24 18.5V6.5C24 4.84531 22.6547 3.5 21 3.5H13.9922C13.1953 3.5 12.4312 3.18594 11.8687 2.62344L10.6266 1.37656C10.0641 0.814063 9.3 0.5 8.50312 0.5H3C1.34531 0.5 0 1.84531 0 3.5V18.5C0 20.1547 1.34531 21.5 3 21.5Z" fill="#F9A237"/>
            </svg>
        </SvgIcon>
    );
}
