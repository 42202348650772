import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Rnd } from 'react-rnd';
import TextareaAutosize from 'react-autosize-textarea';
import drawUtils from '../pages/LessonPage/drawUtils';
import { DeleteForever, MoreVert } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import StyledMenu from '../MyComponents/Drawer/StyledMenu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { FormattedMessage } from 'react-intl';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import TextSizeSelectorMenu from '../MyComponents/Drawer/Tools/TextSizeSelectorMenu';
import { TOOL_TEXT } from '../constants';
import { CirclePicker } from 'react-color';

const imagePasteBox = {
    position: 'relative',
};

// const editableStyle = {
//     width: '100%',
//     height: '100%',
//     resize: 'none',
//     borderColor: 'rgba(0,0,0,0.3)',
//     fontFamily: 'Arial',
//     borderStyle: 'solid',
//     borderWidth: '1px',
//     '&:hover': {
//         borderColor: 'rgba(100,0,0,0.3)',
//     },
// };

const displayStyle = {
    width: '100%',
    height: '100%',
    resize: 'none',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'default',
    fontFamily: 'Arial',
};
const moverActive = {
    border: 'none',
    cursor: 'move',
    zIndex: 0,
    height: 'auto',
};

const moverInActive = {
    border: 'none',
    cursor: 'not-allowed',
    zIndex: -1,
};

const useStyles = makeStyles((theme) => ({
    editableStyle: {
        width: '100%',
        height: '100%',
        resize: 'none',
        borderColor: 'rgba(0,0,0,0.3)',
        borderRadius: '4px',
        fontFamily: 'Arial',
        borderStyle: 'solid',
        borderWidth: '1px',
        cursor: 'move',
        marginTop: '-1px',
        marginLeft: '-1px',
        backgroundColor: 'transparent',

        '&:hover': {
            borderColor: 'rgba(87,78,51,0.8)',
        },
        '&:focus-visible': {
            boxShadow: '0 0 0 2px #07767D33',
            borderColor: '#07767D',
            borderRadius: '4px',
            outline: 'none',
            cursor: 'text',
        },
    },
    buttonRemoveComponent: {
        position: 'absolute',
        cursor: 'pointer',
        right: '-18px',
        top: '-18px',
        width: '0.7em',
        height: '0.7em',
        transition: 'transform 0.3s',
        '&:hover': {
            // right: '-24px',
            // top: '-24px',
            transform: 'scale(1.8)',
        },
    },
    menuText: {
        fontWeight: 400,
        fontSize: '0.875rem',
        lineHeight: '1.43rem',
        letterSpacing: '-0.01rem',
        paddingTop: '4px',
        paddingBottom: '4px',
    },
    margin: {
        margin: theme.spacing(1),
    },
    sketchPicker: { padding: '1rem' },
}));

const MAIN_MENU = 'MAIN_MENU';
const SIZE_MENU = 'SIZE_MENU';
const COLOR_MENU = 'COLOR_MENU';
const TEXT_FLUSH_INTERVAL = 2000;
const CLICK_DECISION_INTERVAL = 200;

const TexBoxForSticky = React.forwardRef((props, ref) => {
    const classes = useStyles();

    let [toolWeight, setWeight] = useState(props.weight);
    let [color, setColor] = useState(props.color);
    let [text, setText] = useState(props.text);
    let [isEditing, setIsEditing] = useState(false);
    const [menuState, setMenuState] = useState(null);
    const [anchorMenu, setAnchorMenu] = React.useState(null);
    let textAreaRef = useRef(React.createRef());
    let inEditMode = useRef({ editMode: false, editInterval: null });
    let flushWriting = useRef({ flushedValue: text, flushFunction: null, textToFlush: text });
    let dragStartTime = useRef(0);

    useImperativeHandle(ref, () => ({
        changeColor(color) {
            console.log('changing color', color);
        },
    }));

    useEffect(() => {
        setText(props.text);
        if (flushWriting.current.flushFunction) {
            clearTimeout(flushWriting.current.flushFunction);
        }
        flushWriting.current.flushFunction = null;
        flushWriting.current.flushedValue = props.text;
        flushWriting.current.textToFlush = props.text;
    }, [props.text]);

    useEffect(() => {
        setWeight(props.weight);
    }, [props.weight]);

    useEffect(() => {
        setColor(props.color);
    }, [props.color]);

    const textChange = (e) => {
        let newTxt = e.currentTarget.value;
        setText(newTxt);
        flushWriting.current.textToFlush = newTxt;

        if (!flushWriting.current.flushFunction) {
            flushWriting.current.flushFunction = setTimeout(() => {
                if (flushWriting.current.flushedValue !== flushWriting.current.textToFlush) {
                    props.updateStickyTextInfo(props.id, flushWriting.current.textToFlush);
                    flushWriting.current.flushedValue = flushWriting.current.textToFlush;
                }
                flushWriting.current.flushFunction = null;
            }, TEXT_FLUSH_INTERVAL);
        }
    };

    const handleClick = (event) => {
        if (!event) {
            handleClose();
            return;
        }
        setAnchorMenu(event.currentTarget);
        setMenuState(MAIN_MENU);
    };
    const handleClose = () => {
        setAnchorMenu(null);
        setMenuState(null);
    };

    let decremental = (window.zoomState.zoomLevel * 50 + 100) / 100;
    let canvasRect = drawUtils.getCurrentCanvasRect(props.email);
    let scaleFactor = canvasRect.width / drawUtils.CANVAS_ACTUAL_WIDTH;
    let fontSize = scaleFactor * decremental * drawUtils.computeFontPixel(toolWeight) + 'px';

    let myStyle = {
        fontSize,
        color: color,
    };

    const focusEdit = () => {
        if (textAreaRef.current && textAreaRef.current.focus && props.isSelectable) {
            if (isEditing) return;
            if (dragStartTime.current && new Date().getTime() - dragStartTime.current > CLICK_DECISION_INTERVAL) return;
            setIsEditing(true);
            setTimeout(() => {
                if (textAreaRef.current) {
                    textAreaRef.current.focus();
                    textAreaRef.current.click();
                }
            }, 500);
        }
    };

    if (props.isSelectable) {
        myStyle = { ...myStyle };
        let menu = (
            <List>
                <ListItem
                    button
                    onClick={() => {
                        props.setDialogClear(props.id);
                    }}
                    className={classes.menuText}
                >
                    <ListItemIcon>
                        <DeleteForever color="secondary" />
                    </ListItemIcon>
                    <FormattedMessage id="tooltip.lessonPage.remove" />
                </ListItem>
                <ListItem
                    button
                    onClick={() => {
                        setMenuState(SIZE_MENU);
                    }}
                    className={classes.menuText}
                >
                    <ListItemIcon>
                        <TextFieldsIcon style={{ verticalAlign: 'middle' }} color={'primary'} />
                    </ListItemIcon>
                    <FormattedMessage id="tooltip.lessonPage.size" />
                </ListItem>
                <ListItem
                    button
                    onClick={() => {
                        setMenuState(COLOR_MENU);
                    }}
                    className={classes.menuText}
                >
                    <ListItemIcon>
                        <span
                            style={{
                                borderRadius: '50%',
                                display: 'inline-block',
                                border: '1px solid white',
                                background: color,
                                width: '24px',
                                height: '24px',
                            }}
                        />
                    </ListItemIcon>
                    <FormattedMessage id="tooltip.lessonPage.color" />
                </ListItem>
            </List>
        );
        if (menuState === SIZE_MENU) {
            menu = (
                <TextSizeSelectorMenu
                    textWeight={toolWeight}
                    tool={TOOL_TEXT}
                    selectText={(size) => {
                        props.updateStickyTextInfo(props.id, null, null, null, null, null, null, size);
                        handleClose();
                    }}
                />
            );
        }
        if (menuState === COLOR_MENU) {
            menu = (
                <div className={classes.sketchPicker}>
                    <CirclePicker
                        disableAlpha
                        color={color}
                        colors={[
                            '#000000',
                            '#f44336',
                            '#e91e63',
                            '#9c27b0',
                            '#673ab7',
                            '#3f51b5',
                            '#2196f3',
                            '#03a9f4',
                            '#009688',
                            '#4caf50',
                            '#8bc34a',
                            '#cddc39',
                            '#ffeb3b',
                            '#ffc107',
                            '#ff9800',
                            '#ff5722',
                            '#795548',
                            '#607d8b',
                        ]}
                        onChangeComplete={(c) => {
                            props.updateStickyTextInfo(props.id, null, null, null, null, null, c.hex, null);
                            handleClose();
                        }}
                    />
                </div>
            );
        }
        return (
            <Rnd
                position={{ x: props.x, y: props.y }}
                size={{ width: props.width }}
                bounds="parent"
                onDragStop={(e, d) => {
                    let scaledPoint = drawUtils.scalePoint(props.startPoint, d.x, d.y);
                    props.updateStickyTextInfo(props.id, text, scaledPoint.x, scaledPoint.y);
                    dragStartTime.current = null;
                }}
                onDragStart={() => {
                    dragStartTime.current = new Date().getTime();
                }}
                onResizeStop={(e, direction, ref, delta, position) => {
                    let scalePointSize = drawUtils.scalePoint({ x: 0, y: 0 }, ref.offsetWidth, ref.offsetHeight);
                    let scaledPoint = drawUtils.scalePoint(props.startPoint, position.x, position.y);
                    props.updateStickyTextInfo(
                        props.id,
                        text,
                        scaledPoint.x,
                        scaledPoint.y,
                        scalePointSize.x,
                        scalePointSize.y
                    );
                }}
                enableResizing={{
                    top: false,
                    right: true,
                    bottom: false,
                    left: false,
                    topRight: false,
                    bottomRight: false,
                    bottomLeft: false,
                    topLeft: false,
                }}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
                style={props.isSelectable ? moverActive : moverInActive}
                onTouchStart={(e) => {
                    e.stopPropagation();
                }}
                onPointerDown={(e) => {
                    e.stopPropagation();
                }}
                disableDragging={isEditing}
            >
                <div style={imagePasteBox}>
                    {props.isSelectable ? (
                        // <Fab style={buttonRemoveComponent} size={'small'} onClick={cancelUpdateRemoteData}>
                        <>
                            <MoreVert
                                color="primary"
                                onClick={(e) => {
                                    // props.setDialogClear(props.id);
                                    handleClick(e);
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                onTouchStart={(e) => {
                                    // props.setDialogClear(props.id);
                                    handleClick(e);
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                className={classes.buttonRemoveComponent}
                            />
                            <StyledMenu
                                id={`tex-box-edit-${props.id}`}
                                anchorEl={anchorMenu}
                                keepMounted
                                open={Boolean(anchorMenu)}
                                onClose={handleClose}
                            >
                                {menu}
                            </StyledMenu>
                        </>
                    ) : // </Fab>
                    null}
                    <TextareaAutosize
                        value={text}
                        onChange={textChange}
                        className={classes.editableStyle}
                        style={myStyle}
                        ref={textAreaRef}
                        id={`text-area-sticky-${props.id}`}
                        onBlur={() => {
                            if (flushWriting.current.flushedValue !== flushWriting.current.textToFlush) {
                                if (flushWriting.current.textToFlush.length === 0) {
                                    props.removeElement(props.id);
                                } else {
                                    props.updateStickyTextInfo(props.id, flushWriting.current.textToFlush);
                                }

                                flushWriting.current.flushedValue = flushWriting.current.textToFlush;
                            } else {
                                if (flushWriting.current.textToFlush.length === 0) {
                                    props.removeElement(props.id);
                                }
                            }
                            if (flushWriting.current.flushFunction) {
                                clearTimeout(flushWriting.current.flushFunction);
                                flushWriting.current.flushFunction = null;
                            }
                            if (props.setWriting) {
                                props.setWriting(false);
                            }
                            inEditMode.current.editMode = false;
                            setIsEditing(false);
                        }}
                        onTouchEnd={() => {
                            focusEdit();
                        }}
                        onPointerUp={() => {
                            focusEdit();
                        }}
                    />
                </div>
            </Rnd>
        );
    } else {
        myStyle = { ...myStyle, ...displayStyle };

        let divStyle = { ...imagePasteBox };

        divStyle.position = 'absolute';
        divStyle.top = props.y + 'px';
        divStyle.left = props.x + 'px';
        divStyle.width = props.width + 'px';
        divStyle.height = props.height + 'px';
        divStyle.zIndex = '-1000';

        return (
            <div style={divStyle}>
                <TextareaAutosize value={text} style={myStyle} ref={textAreaRef} />
            </div>
        );
    }
});

export default TexBoxForSticky;
