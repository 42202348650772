import DesktopLanding from '../../MyComponents/Landing/DesktopLanding';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import { ForSchoolsFeaturesDesktop } from './SchoolsFeatures/ForSchoolsFeaturesDesktop';
import { VIDEO_URLS } from '../../constants';
import RequestOfferButton from '../../MyComponents/RequestOfferButton/RequestOfferButton';

const useStyles = makeStyles(() => ({
    desktopContainer: {
        position: 'relative',
        marginBottom: '-10rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    bottomGreenPaper: {
        background: 'rgba(8, 170, 180, 0.16)',
        borderRadius: '0rem 0rem 2rem 2rem',
        height: '2rem',
        width: '90%',
    },
}));

const ForSchoolsDesktop = ({ handleOpen, language }) => {
    const classes = useStyles();

    const setCreateGroupPath = () => {
        window.localStorage.setItem('pathBeforeLogin', '/creategroup');
    };

    return (
        <>
            <DesktopLanding videoUrl={VIDEO_URLS['schools'][language.toUpperCase()]} needsBackground={false}>
                <div style={{ zIndex: '1', whiteSpace: 'nowrap', marginTop: '2rem' }}>
                    <Typography variant="h1">
                        <FormattedMessage
                            id={'redesign.home.forSchools.tagline'}
                            values={{
                                b: (chunks) => (
                                    <b>
                                        <br></br>
                                        {chunks}
                                        <br></br>
                                    </b>
                                ),
                            }}
                        />
                    </Typography>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'bottom',
                            paddingTop: '1rem',
                        }}
                    >
                        <RequestOfferButton />
                    </div>
                </div>
            </DesktopLanding>
            <div className={classes.desktopContainer}>
                <ForSchoolsFeaturesDesktop
                    handleOpen={() => {
                        handleOpen(false);
                        setCreateGroupPath();
                    }}
                />
                <div className={classes.bottomGreenPaper} />
            </div>
        </>
    );
};

export default ForSchoolsDesktop;
