import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(() => ({
    sectionImage: { textAlign: 'center', padding: '2rem' },
    image: {
        maxWidth: '300px',
        width: '80%',
        borderRadius: '16px',
    },

    title: {
        fontWeight: '700',
        textAlign: 'left',
        paddingTop: '2rem',
        paddingBottom: '1rem',
    },
}));

const AboutHome = () => {
    const classes = useStyles();

    return (
        <Grid container spacing={3} alignItems={'center'} alignContent={'center'} justify={'center'}>
            <Grid item xs={12}>
                <Typography variant='h2' className={classes.title}>
                    <FormattedMessage id={'aboutUs.ourMission.button'} />
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant='h3'>
                    <FormattedMessage id={'aboutUs.ourMission.start'} />
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Typography variant='h6'>
                    <FormattedMessage id={'aboutUs.ourMission.startDescription.1'} />
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.sectionImage}>
                <img src={'/static/asset/img/pandemic.webp'} className={classes.image} alt='COVID' />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Typography variant='h6'>
                    <FormattedMessage id={'aboutUs.ourMission.startDescription.2'} />
                </Typography>
                <Typography variant='h6'>
                    <FormattedMessage id={'aboutUs.ourMission.startDescription.3'} />
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.sectionImage}>
                <img src={'/static/asset/img/feedback.webp'} className={classes.image} alt='Feedback' />
            </Grid>
            <Divider variant={'middle'} style={{ width: '100%', marginTop: '2rem', marginBottom: '2rem' }} />

            <Grid item xs={12}>
                <Typography variant='h3'>
                    <FormattedMessage id={'aboutUs.ourMission.now'} />
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Typography variant='h6'>
                    <FormattedMessage id={'aboutUs.ourMission.nowDescription.1'} />
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.sectionImage}>
                <img src={'/static/asset/img/digital-education.webp'} className={classes.image} alt='Digital' />
            </Grid>

            <Grid item xs={12} sm={6}>
                <Typography variant='h6'>
                    <FormattedMessage id={'aboutUs.ourMission.nowDescription.2'} />
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.sectionImage}>
                <img src={'/static/asset/img/online-teach.webp'} className={classes.image} alt='Online' />
            </Grid>

            <Grid item xs={12} sm={6}>
                <Typography variant='h6'>
                    <FormattedMessage id={'aboutUs.ourMission.nowDescription.3'} />
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.sectionImage}>
                <img src={'/static/asset/img/stock-digital-board.webp'} className={classes.image} alt='Whiteboard' />
            </Grid>
        </Grid>
    );
};

export default AboutHome;
