import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function RotateIconNew(props) {
    return (
        <SvgIcon {...props}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                zoomAndPan="magnify"
                viewBox="0 0 112.5 112.499997"
                preserveAspectRatio="xMidYMid meet"
                version="1.0"
            >
                <g>
                    <path
                        d="M 15.347656 43.328125 C 15.347656 44.730469 16.488281 45.871094 17.894531 45.871094 L 39.9375 45.871094 C 40.964844 45.871094 41.890625 45.25 42.285156 44.300781 C 42.679688 43.351562 42.460938 42.253906 41.734375 41.53125 L 32.199219 31.996094 C 38.445312 25.789062 46.910156 22.132812 56.042969 22.132812 C 74.742188 22.132812 89.957031 37.34375 89.957031 56.042969 C 89.957031 57.914062 91.472656 59.433594 93.347656 59.433594 C 95.222656 59.433594 96.738281 57.914062 96.738281 56.042969 C 96.738281 33.605469 78.484375 15.347656 56.042969 15.347656 C 45.074219 15.347656 34.871094 19.703125 27.371094 27.167969 L 19.691406 19.488281 C 18.960938 18.757812 17.871094 18.539062 16.917969 18.933594 C 15.96875 19.328125 15.347656 20.257812 15.347656 21.285156 Z M 15.347656 43.328125 "
                        fillOpacity="1"
                        fillRule="nonzero"
                    />
                </g>
                <g>
                    <path
                        d="M 96.738281 68.761719 C 96.738281 67.355469 95.597656 66.21875 94.195312 66.21875 L 72.152344 66.21875 C 71.121094 66.21875 70.195312 66.835938 69.800781 67.789062 C 69.410156 68.738281 69.625 69.832031 70.355469 70.558594 L 79.886719 80.089844 C 73.640625 86.300781 65.175781 89.957031 56.042969 89.957031 C 37.34375 89.957031 22.132812 74.742188 22.132812 56.042969 C 22.132812 54.171875 20.617188 52.652344 18.742188 52.652344 C 16.867188 52.652344 15.347656 54.171875 15.347656 56.042969 C 15.347656 78.484375 33.605469 96.738281 56.042969 96.738281 C 67.015625 96.738281 77.21875 92.382812 84.714844 84.917969 L 92.398438 92.601562 C 92.886719 93.089844 93.535156 93.347656 94.195312 93.347656 C 94.523438 93.347656 94.851562 93.28125 95.167969 93.152344 C 96.117188 92.761719 96.738281 91.832031 96.738281 90.804688 Z M 96.738281 68.761719 "
                        fillOpacity="1"
                        fillRule="nonzero"
                    />
                </g>
            </svg>
        </SvgIcon>
    );
}
