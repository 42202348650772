import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function LineWidthIcon(props) {
    return (
        <SvgIcon {...props} style={{ verticalAlign: 'bottom' }}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 50 50"
                version="1.1"
            >
                <path d="M6 10v2h38v-2zm0 7v3h38v-3zm0 8v4h38v-4zm0 9v6h38v-6z" id="surface1" />
            </svg>
        </SvgIcon>
    );
}
