import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function LineIcon(props) {
    return (
        <SvgIcon {...props} style={{ ...props.style, verticalAlign: 'bottom' }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M4.707,20.707l16-16a1,1,0,1,0-1.414-1.414l-16,16a1,1,0,0,0,1.414,1.414Z" />
            </svg>
        </SvgIcon>
    );
}
