import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import userApi from './Api/userApi';
import { Provider } from 'react-redux';
import store from './redux/store';
import * as Sentry from '@sentry/react';
import {
    currentRequestCount,
    showConsent,
    showNewFeature,
    updateAllowedBoards,
    updateAllowedGroups,
    updateExtendedProfile,
    updateProfile,
    updateUserConsent,
    updateUserPreferences,
    updateUserSubscription,
} from './redux/actions';

import * as serviceWorker from './serviceWorker';
import joinRequestApi from './Api/joinRequestApi';
import lessonList from './pages/LessonPage/lessonList';
import localforage from 'localforage';
import { CONTACT_US_LINK, LINK_WHITEBOARD, SPECIAL_QUOTA_LINK } from './common/urlLinks';
import { parseQuery } from './common/utils';
import { LOGIN_TYPES } from './constants';

Sentry.init({
    dsn: 'https://a4af094f3e3deedb28b0a189c8ab843d@o4508913753718784.ingest.de.sentry.io/4508913755816016',
});

window.zoomState = {
    zoomLevel: 0,
    zoomDisabled: true,
    zoomCallbacks: {},
};

localforage.config({
    name: 'vboard',
    version: 1.0,
    storeName: 'boards', // Should be alphanumeric, with underscores.
    description: 'VBoard saved boards',
});

window.localforage = localforage.createInstance('vboard');

// window.enablePinchToZoom = false;
window.enablePinchToZoom = true;
if (window.screen && window.screen.width > 1200 && window.devicePixelRatio < 2.1) {
    window.enablePinchToZoom = false;
}

if (window.gtag) {
    // console.log('configuring ga');
    window.gtag('config', 'UA-163272572-1');
}

window.logEvent = (category, action, label, value) => {
    if (window.gtag) {
        if (!category || !action || !label) {
            return;
        }
        // console.log('======== should send', category, action, label, value);
        window.gtag('event', action, { event_category: category, event_label: label, value: value });
    }
};

window.logAppActivity = (event, value) => {
    window.logEvent(event.category, event.action, event.label, value);
};

function handleLogin(loginToken) {
    // console.log('have to handle login', window.isTeams, window.isLoggedIn);
    if (window.isTeams) {
        let divs = document.getElementsByClassName('osano-cm-window');
        if (divs && divs.length > 0) {
            divs.item(0).style.display = 'none';
        }
        let fbDivs = document.getElementById('fb-root');
        if (fbDivs) {
            fbDivs.style.display = 'none';
        }
        return;
    }
    let protocol = window.location.protocol;
    let host = window.location.host;

    if (host === 'localhost:3000') {
        host = 'localhost:4000';
    } else if (host === '192.168.2.112:3000') {
        host = '192.168.2.112:4000';
    }
    if (loginToken) {
        if (loginToken.type === LOGIN_TYPES.SESSIONS_US) {
            window.location.assign(protocol + '//' + host + '/sessions_us/callback/?token=' + loginToken.token);
        } else if (loginToken.type === LOGIN_TYPES.SAMSUNG) {
            window.location.assign(protocol + '//' + host + '/samsung/callback/?token=' + loginToken.token);
        } else if (loginToken.type === LOGIN_TYPES.TOP_DASKAL) {
            window.location.assign(protocol + '//' + host + '/topdaskal/callback/?token=' + loginToken.token);
        } else if (loginToken.type === LOGIN_TYPES.EQUALIFAI) {
            window.location.assign(protocol + '//' + host + '/equalifai/callback/?token=' + loginToken.token);
        } else if (loginToken.type === LOGIN_TYPES.DIGITALIADA) {
            window.location.assign(protocol + '//' + host + '/digitaliada/callback/?token=' + loginToken.token);
        }
        return;
    }
    if (window.location.pathname === '/login/google') {
        window.location.assign(protocol + '//' + host + '/login/google');
    } else if (window.location.pathname === '/login/facebook') {
        window.location.assign(protocol + '//' + host + '/login/facebook');
    } else if (window.location.pathname === '/login/microsoft') {
        window.location.assign(protocol + '//' + host + '/login/microsoft');
    } else if (window.location.pathname === '/login/microsoft-teams') {
        window.location.assign(protocol + '//' + host + '/login/microsoft-teams');
    } else {
        window.location.assign(protocol + '//' + host + '/login');
    }
}

function renderDom() {
    window.loadLatexAsync();
    ReactDOM.render(
        <Provider store={store}>
            <App />
        </Provider>,
        document.getElementById('root'),
    );
}

async function migrateTablesToStorage() {
    let profile = store.getState().profile;
    let key = 'pages-migrated-to-forage-for-' + profile.email;
    if (window.localStorage.getItem(key) === 'true') {
        // console.log('==== migrated');
        return Promise.resolve();
    } else {
        // console.log('=== not migrated');
        await lessonList.migrateAllDataToForage(profile.email);
        window.localStorage.setItem(key, 'true');
        // console.log('=== completed migration');
    }
}

function migrateMultiBoardSessionToMultipleClientBoards() {
    let profile = store.getState().profile;
    let key = 'migrate-multiboard-sessions-to-multiple-pages-' + profile.email;
    if (window.localStorage.getItem(key) === 'true') {
        // console.log('==== migrated migrate-multiboard-sessions-to-multiple-pages');
    } else {
        // console.log('=== not migrated');
        lessonList.migrateMultiBoardSessionToMultipleClientBoards(profile.email);
        // return;
        window.localStorage.setItem(key, 'true');
        // console.log('=== completed migration');
    }
}

if (window.location.pathname.startsWith('/login')) {
    // console.log('=== starts with login');
    handleLogin();
} else if (
    window.location.pathname === '/callback' ||
    window.location.pathname === '/google/callback' ||
    window.location.pathname === '/equalifai/callback' ||
    window.location.pathname === '/digitaliada/callback' ||
    window.location.pathname === '/sessions_us/callback' ||
    window.location.pathname === '/topdaskal/callback' ||
    window.location.pathname === '/samsung/callback' ||
    window.location.pathname === '/microsoft/callback' ||
    window.location.pathname === '/microsoft-teams/callback' ||
    window.location.pathname === '/facebook/callback'
) {
    userApi
        .login(window.location.pathname, window.location.search)
        .then(userApi.getProfile)
        .then((res) => {
            window.isLoggedIn = true;
            store.dispatch(updateProfile(res.data));
            return userApi.getExtendedProfile().then((response) => {
                store.dispatch(updateExtendedProfile(response.data.profile));
                if (response?.data?.preferences?.language) {
                    let l = response?.data?.preferences?.language.toLowerCase();
                    if (l === 'ro' || l === 'en' || l === 'fr') {
                        localStorage.setItem('language', l);
                    }
                }
                if (!response.data.consentOption) {
                    store.dispatch(showConsent(true));
                } else {
                    store.dispatch(updateUserConsent(response.data.consentOption));
                }
            });
        })
        .then(joinRequestApi.getCurrentRequestCount)
        .then((res) => {
            store.dispatch(currentRequestCount(res.data.count));
        })
        .then(() => {
            let existingPath = window.localStorage.getItem('pathBeforeLogin');
            if (existingPath) {
                window.localStorage.removeItem('pathBeforeLogin');
                window.location.replace(existingPath);
            } else {
                window.location.replace('/');
            }
        })
        // .then(renderDom)
        .catch((err) => {
            console.error('error on profile', err);
            // console.log('==== error on profile1', window.location.pathname);
            if (err.request && err.request.status === 400) {
                window.location.replace('/signup_consent');
            } else {
                handleLogin();
            }
        });
} else {
    userApi
        .getProfile()
        .then((res) => {
            window.isLoggedIn = true;
            store.dispatch(updateProfile(res.data));
            return userApi.getExtendedProfile().then((response) => {
                store.dispatch(updateExtendedProfile(response.data.profile));
                store.dispatch(updateUserPreferences(response.data.preferences));
                store.dispatch(updateUserSubscription(response.data.subscription));
                if (response?.data?.preferences?.language) {
                    let l = response?.data?.preferences?.language.toLowerCase();
                    if (l === 'ro' || l === 'en' || l === 'fr') {
                        localStorage.setItem('language', l);
                    }
                }
                if (!response.data.consentOption) {
                    store.dispatch(showConsent(true));
                } else {
                    store.dispatch(updateUserConsent(response.data.consentOption));
                }
                userApi
                    .getAllowedGroups()
                    .then((response) => {
                        store.dispatch(updateAllowedGroups(response.data.groupIds));
                    })
                    .catch((err) => {
                        console.error('unable to get allowed groups', err);
                    });
                userApi
                    .getAllowedBoards()
                    .then((response) => {
                        store.dispatch(updateAllowedBoards(response.data.boardIds));
                    })
                    .catch((err) => {
                        console.error('unable to get allowed boards', err);
                    });
            });
        })
        .then(joinRequestApi.getCurrentRequestCount)
        .then((res) => {
            store.dispatch(currentRequestCount(res.data.count));
            return migrateTablesToStorage();
        })
        .then(() => {
            let isOn = window.localStorage.getItem('vboard-beta-switch');
            window.isBetaEnabled = !!(isOn && isOn === 'true');
            let existingPath = window.localStorage.getItem('pathBeforeLogin');
            if (existingPath) {
                window.localStorage.removeItem('pathBeforeLogin');
                window.location.replace(existingPath);
                return;
            }
            let objectMoveFeature = localStorage.getItem('objectMoveFeature');
            if (!objectMoveFeature || objectMoveFeature === 'false') {
                store.dispatch(showNewFeature('objectMove'));
            } else {
                let objectMoveFeature = localStorage.getItem('pointerToolFeature');
                if (!objectMoveFeature || objectMoveFeature === 'false') {
                    store.dispatch(showNewFeature('pointerTool'));
                }
            }
            migrateMultiBoardSessionToMultipleClientBoards();
            if (window.isTeams) {
                window.hideOsano();
            }
            renderDom();
        })
        .catch((err) => {
            if (err.request) {
                if (err.request.status === 401) {
                    window.isLoggedIn = false;
                    // console.log('- login failed', window.isTeams, window.isLoggedIn);
                    if (
                        window.location.pathname === '/' ||
                        window.location.pathname.startsWith(CONTACT_US_LINK) ||
                        window.location.pathname.startsWith(SPECIAL_QUOTA_LINK) ||
                        window.location.pathname.startsWith('/privacypolicy') ||
                        window.location.pathname.startsWith('/terms') ||
                        window.location.pathname.startsWith('/tutorial') ||
                        window.location.pathname.startsWith('/ro/tutorial') ||
                        window.location.pathname.startsWith('/fr/tutorial') ||
                        window.location.pathname.startsWith('/en/tutorial') ||
                        window.location.pathname.startsWith('/about') ||
                        window.location.pathname.startsWith('/ro/about') ||
                        window.location.pathname.startsWith('/fr/about') ||
                        window.location.pathname.startsWith('/en/about') ||
                        window.location.pathname.startsWith('/tutoring') ||
                        window.location.pathname.startsWith('/ro/tutoring') ||
                        window.location.pathname.startsWith('/fr/tutoring') ||
                        window.location.pathname.startsWith('/en/tutoring') ||
                        window.location.pathname.startsWith('/schools') ||
                        window.location.pathname.startsWith('/ro/schools') ||
                        window.location.pathname.startsWith('/fr/schools') ||
                        window.location.pathname.startsWith('/en/schools') ||
                        window.location.pathname.startsWith('/subscriptions') ||
                        window.location.pathname.startsWith('/onboarding') ||
                        window.location.pathname.startsWith('/features') ||
                        window.location.pathname.startsWith(LINK_WHITEBOARD) ||
                        window.location.pathname.startsWith('/signup_consent') ||
                        window.location.pathname.startsWith('/unsubscribe') ||
                        window.location.pathname.startsWith('/ro') ||
                        window.location.pathname.startsWith('/it') ||
                        window.location.pathname.startsWith('/fr') ||
                        window.location.pathname.startsWith('/en')
                    ) {
                        if (window.isTeams) {
                            // console.log('is in teams - no action at invalid login');
                            window.hideOsano();
                            return;
                        }
                        renderDom();
                    } else {
                        window.localStorage.setItem(
                            'pathBeforeLogin',
                            window.location.pathname + window.location.search,
                        );
                        let q = parseQuery(window.location.search);
                        if (q.sessionsUsToken) {
                            handleLogin({ type: LOGIN_TYPES.SESSIONS_US, token: q.sessionsUsToken });
                        } else if (q.equalifaiToken) {
                            // console.log('==== error on location1', window.location.pathname);
                            handleLogin({ type: LOGIN_TYPES.EQUALIFAI, token: q.equalifaiToken });
                        } else if (q.digitaliadaToken) {
                            // console.log('==== error on location1', window.location.pathname);
                            handleLogin({ type: LOGIN_TYPES.DIGITALIADA, token: q.digitaliadaToken });
                        } else if (q.topDaskalToken) {
                            // console.log('==== error on location1', window.location.pathname);
                            handleLogin({ type: LOGIN_TYPES.TOP_DASKAL, token: q.topDaskalToken });
                        } else {
                            handleLogin();
                        }
                    }
                    return;
                }
            }
            // console.log('error on profile', err.request);
            // console.log(window.location);
            window.location.replace('/');
            renderDom();
            // handleLogin();
        });
}

let loginViaApi = (url) => {
    return userApi
        .loginApi(url)
        .then(userApi.getProfile)
        .then((res) => {
            window.isLoggedIn = true;
            store.dispatch(updateProfile(res.data));
            return userApi.getExtendedProfile().then((response) => {
                store.dispatch(updateExtendedProfile(response.data.profile));
                store.dispatch(updateUserPreferences(response.data.preferences));
                store.dispatch(updateUserSubscription(response.data.subscription));
                if (response?.data?.preferences?.language) {
                    let l = response?.data?.preferences?.language.toLowerCase();
                    if (l === 'ro' || l === 'en' || l === 'fr') {
                        localStorage.setItem('language', l);
                    }
                }
                if (!response.data.consentOption) {
                    store.dispatch(showConsent(true));
                } else {
                    store.dispatch(updateUserConsent(response.data.consentOption));
                }
                userApi
                    .getAllowedGroups()
                    .then((response) => {
                        store.dispatch(updateAllowedGroups(response.data.groupIds));
                    })
                    .catch((err) => {
                        console.error('unable to get allowed groups', err);
                    });
                userApi
                    .getAllowedBoards()
                    .then((response) => {
                        store.dispatch(updateAllowedBoards(response.data.boardIds));
                    })
                    .catch((err) => {
                        console.error('unable to get allowed boards', err);
                    });
            });
        })
        .then(joinRequestApi.getCurrentRequestCount)
        .then((res) => {
            store.dispatch(currentRequestCount(res.data.count));
            return migrateTablesToStorage();
        })
        .then(() => {
            let isOn = window.localStorage.getItem('vboard-beta-switch');
            window.isBetaEnabled = !!(isOn && isOn === 'true');
            let existingPath = window.localStorage.getItem('pathBeforeLogin');
            if (existingPath) {
                window.localStorage.removeItem('pathBeforeLogin');
                window.location.replace(existingPath);
                return;
            }
            let objectMoveFeature = localStorage.getItem('objectMoveFeature');
            if (!objectMoveFeature || objectMoveFeature === 'false') {
                store.dispatch(showNewFeature('objectMove'));
            } else {
                let objectMoveFeature = localStorage.getItem('pointerToolFeature');
                if (!objectMoveFeature || objectMoveFeature === 'false') {
                    store.dispatch(showNewFeature('pointerTool'));
                }
            }
            migrateMultiBoardSessionToMultipleClientBoards();
            if (window.isTeams) {
                window.hideOsano();
            }
            renderDom();
        })
        .catch((err) => {
            if (err.request) {
                if (err.request.status === 401) {
                    window.isLoggedIn = false;
                    // console.log('- login failed', window.isTeams, window.isLoggedIn);
                    if (
                        window.location.pathname === '/' ||
                        window.location.pathname.startsWith(CONTACT_US_LINK) ||
                        window.location.pathname.startsWith(SPECIAL_QUOTA_LINK) ||
                        window.location.pathname.startsWith('/privacypolicy') ||
                        window.location.pathname.startsWith('/terms') ||
                        window.location.pathname.startsWith('/tutorial') ||
                        window.location.pathname.startsWith('/ro/tutorial') ||
                        window.location.pathname.startsWith('/fr/tutorial') ||
                        window.location.pathname.startsWith('/en/tutorial') ||
                        window.location.pathname.startsWith('/about') ||
                        window.location.pathname.startsWith('/ro/about') ||
                        window.location.pathname.startsWith('/fr/about') ||
                        window.location.pathname.startsWith('/en/about') ||
                        window.location.pathname.startsWith('/subscriptions') ||
                        window.location.pathname.startsWith('/onboarding') ||
                        window.location.pathname.startsWith('/features') ||
                        window.location.pathname.startsWith('/signup_consent') ||
                        window.location.pathname.startsWith('/unsubscribe') ||
                        window.location.pathname.startsWith(LINK_WHITEBOARD) ||
                        window.location.pathname.startsWith('/ro') ||
                        window.location.pathname.startsWith('/it') ||
                        window.location.pathname.startsWith('/fr') ||
                        window.location.pathname.startsWith('/en')
                    ) {
                        if (window.isTeams) {
                            // console.log('is in teams - no action at invalid login');
                            window.hideOsano();
                            return;
                        }
                        renderDom();
                    } else {
                        window.localStorage.setItem(
                            'pathBeforeLogin',
                            window.location.pathname + window.location.search,
                        );
                        let q = parseQuery(window.location.search);
                        if (q.sessionsUsToken) {
                            handleLogin({ type: LOGIN_TYPES.SESSIONS_US, token: q.sessionsUsToken });
                        } else if (q.equalifaiToken) {
                            // console.log('==== error on location1', window.location.pathname);
                            handleLogin({ type: LOGIN_TYPES.EQUALIFAI, token: q.equalifaiToken });
                        } else if (q.digitaliadaToken) {
                            // console.log('==== error on location1', window.location.pathname);
                            handleLogin({ type: LOGIN_TYPES.DIGITALIADA, token: q.digitaliadaToken });
                        } else if (q.topDaskalToken) {
                            // console.log('==== error on location1', window.location.pathname);
                            handleLogin({ type: LOGIN_TYPES.TOP_DASKAL, token: q.topDaskalToken });
                        } else {
                            handleLogin();
                        }
                    }
                    return;
                }
            }
            // console.log('error on profile', err.request);
            // console.log(window.location);
            window.location.replace('/');
            renderDom();
            // handleLogin();
        });
};

const hideOsano = () => {
    let divs = document.getElementsByClassName('osano-cm-window');
    if (divs && divs.length > 0) {
        divs.item(0).style.display = 'none';
    }
    let fbDivs = document.getElementById('fb-root');
    if (fbDivs) {
        fbDivs.style.display = 'none';
    }
};

window.hideOsano = hideOsano;

window.loginViaApi = loginViaApi;

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
