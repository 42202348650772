import React, { useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { BOARD_TYPE, TOOL_PEN } from '../../../constants';
import { Badge, Fab, Hidden, IconButton } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import boardManager from '../../../pages/LessonPage/boardManager';
import { GA_EVENT_TREE } from '../../../gaConstants';
import MultiBoardIcon from '../../icons/MultiBoardIcon';
import store from '../../../redux/store';
import { changeTool, raiseHand, shareLesson, userJoinRequest } from '../../../redux/actions';
import HandRaisedIcon from '../../icons/HandRaisedIcon';
import socketUtils from '../../../pages/LessonPage/socketUtils';
import DraftsIcon from '@material-ui/icons/Drafts';
import { Pause, PlayArrow, Stop } from '@material-ui/icons';
import VBToolTip from '../../Tooltip/VBToolTip';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

const ActionButtons = (props) => {
    const raiseHandClick = () => {
        socketUtils.sendRaisedHandValue(!props.lessonState.raisedHand);
        store.dispatch(raiseHand());
    };

    if (!boardManager.isAdmin) {
        return (
            <div style={{ padding: '0.1rem 0.5rem 0.1rem 0.5rem', borderRight: '1px solid rgba(0, 0, 0, 0.1' }}>
                <Hidden mdUp implementation="css">
                    <Fab
                        size="small"
                        color={props.lessonState.raisedHand ? 'primary' : 'default'}
                        onClick={raiseHandClick}
                    >
                        <HandRaisedIcon />
                    </Fab>
                </Hidden>
                <Hidden only={['xs', 'sm']} implementation="css">
                    <Button
                        variant="contained"
                        color={props.lessonState.raisedHand ? 'primary' : 'default'}
                        size="small"
                        startIcon={<HandRaisedIcon />}
                        onClick={raiseHandClick}
                    >
                        <FormattedMessage id="menu.raiseHand" />
                    </Button>
                </Hidden>
            </div>
        );
    }

    const resetToPen = () => {
        store.dispatch(changeTool(TOOL_PEN));
        if (window.switchToStickySelection) {
            window.switchToStickySelection(false);
        }
        if (window.toolSelectionCallback) {
            window.toolSelectionCallback(TOOL_PEN);
        }
    };

    const switchToSplitBoards = () => {
        window.logAppActivity(GA_EVENT_TREE.lessonPage.menuActions.startIndividualBoards);
        boardManager.switchToNewMultiBoardForAdmin();
        resetToPen();
    };

    const resumeIndividualBoard = () => {
        window.logAppActivity(GA_EVENT_TREE.lessonPage.menuActions.resumeIndividualBoards);
        boardManager.resumeMultiBoardForAdmin();
        resetToPen();
    };

    const switchToSingleBoard = () => {
        window.logAppActivity(GA_EVENT_TREE.lessonPage.menuActions.stopIndividualBoards);
        boardManager.switchToSingleBoardForAdmin();
        resetToPen();
    };

    const pauseMultiBoard = () => {
        window.logAppActivity(GA_EVENT_TREE.lessonPage.menuActions.stopIndividualBoards);
        boardManager.switchToSingleBoardForAdmin(true);
        resetToPen();
    };
    const displayShareDialog = () => {
        window.logAppActivity(GA_EVENT_TREE.lessonPage.menuActions.share);
        store.dispatch(shareLesson({ id: window.location.pathname.split('/')[2] }));
    };
    const showJoinRequests = () => {
        window.logAppActivity(GA_EVENT_TREE.lessonPage.menuActions.requests);
        store.dispatch(userJoinRequest({ classId: window.location.pathname.split('/')[2], userId: null }));
    };

    let activeMemberCount = 0;
    if (props.lessonState && props.lessonState.members) {
        props.lessonState.members.forEach((member) => {
            if (member.online) {
                activeMemberCount++;
            }
        });
    }

    if (!boardManager.lessonState) return null;
    if (!props?.lessonState) return null;

    let multiBoardOption;
    if (props?.lessonState?.boardType === BOARD_TYPE.MULTI_BOARD) {
        multiBoardOption = (
            <>
                <Hidden mdUp implementation="css">
                    <VBToolTip content={<FormattedMessage id="menu.pauseBoard.tooltip" />}>
                        <IconButton size="small" color="primary" className="u-ml-half" onClick={pauseMultiBoard}>
                            <Pause />
                        </IconButton>
                    </VBToolTip>
                    <VBToolTip content={<FormattedMessage id="menu.stopBoard.tooltip" />}>
                        <IconButton size="small" color="primary" className="u-ml-half" onClick={switchToSingleBoard}>
                            <Stop />
                        </IconButton>
                    </VBToolTip>
                </Hidden>
                <Hidden only={['xs', 'sm']} implementation="css">
                    <VBToolTip content={<FormattedMessage id="menu.pauseBoard.tooltip" />}>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            startIcon={<Pause />}
                            onClick={pauseMultiBoard}
                            className="u-ml-half"
                        >
                            <FormattedMessage id="menu.pauseBoard" />
                        </Button>
                    </VBToolTip>
                    <VBToolTip content={<FormattedMessage id="menu.stopBoard.tooltip" />}>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            startIcon={<Stop />}
                            onClick={switchToSingleBoard}
                            className="u-ml-half"
                        >
                            <FormattedMessage id="menu.stopBoard" />
                        </Button>
                    </VBToolTip>
                </Hidden>
            </>
        );
    } else if (props?.lessonState?.boardType === BOARD_TYPE.SINGLE_BOARD && !boardManager.lessonState.pausedBoard) {
        multiBoardOption = (
            <>
                <Hidden mdUp implementation="css">
                    <VBToolTip
                        content={
                            activeMemberCount === 0 ? (
                                <FormattedMessage id="menu.splitBoard.tooltip.disabled" />
                            ) : (
                                <FormattedMessage id="menu.splitBoard.tooltip" />
                            )
                        }
                    >
                        <IconButton
                            size="small"
                            color="primary"
                            className="u-ml-half"
                            onClick={switchToSplitBoards}
                            disabled={activeMemberCount === 0}
                        >
                            <MultiBoardIcon />
                        </IconButton>
                    </VBToolTip>
                </Hidden>
                <Hidden only={['xs', 'sm']} implementation="css">
                    <VBToolTip
                        content={
                            activeMemberCount === 0 ? (
                                <FormattedMessage id="menu.splitBoard.tooltip.disabled" />
                            ) : (
                                <FormattedMessage id="menu.splitBoard.tooltip" />
                            )
                        }
                    >
                        <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            startIcon={<MultiBoardIcon />}
                            onClick={switchToSplitBoards}
                            className="u-ml-half"
                            disabled={activeMemberCount === 0}
                        >
                            <FormattedMessage id="menu.splitBoard" />
                        </Button>
                    </VBToolTip>
                </Hidden>
            </>
        );
    } else {
        multiBoardOption = (
            <>
                <Hidden mdUp implementation="css">
                    <VBToolTip content={<FormattedMessage id="menu.resumeBoard" />}>
                        <IconButton
                            size="small"
                            color="primary"
                            className="u-ml-half"
                            onClick={resumeIndividualBoard}
                            disabled={activeMemberCount === 0}
                        >
                            <PlayArrow />
                        </IconButton>
                    </VBToolTip>
                </Hidden>
                <Hidden only={['xs', 'sm']} implementation="css">
                    <VBToolTip content={<FormattedMessage id="menu.resumeBoard.tooltip" />}>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            startIcon={<PlayArrow />}
                            onClick={resumeIndividualBoard}
                            className="u-ml-half"
                            disabled={activeMemberCount === 0}
                        >
                            <FormattedMessage id="menu.resumeBoard" />
                        </Button>
                    </VBToolTip>
                </Hidden>
            </>
        );
    }

    return (
        <>
            {props?.lessonState?.openJoinRequests?.length > 0 ? (
                <div style={{ padding: '0.1rem 0.5rem 0.1rem 0.5rem', borderRight: '1px solid rgba(0, 0, 0, 0.1' }}>
                    <Badge badgeContent={props?.lessonState?.openJoinRequests?.length} color="secondary">
                        <IconButton size="small" color="primary" onClick={showJoinRequests}>
                            <DraftsIcon />
                        </IconButton>
                    </Badge>
                </div>
            ) : null}

            <div style={{ padding: '0.1rem 0.5rem 0.1rem 0.5rem', borderRight: '1px solid rgba(0, 0, 0, 0.1' }}>
                <Hidden mdUp implementation="css">
                    <VBToolTip content={<FormattedMessage id="menu.shareLesson.tooltip" />}>
                        <IconButton size="small" color="primary" onClick={displayShareDialog}>
                            <PersonAddIcon />
                        </IconButton>
                    </VBToolTip>
                </Hidden>
                <Hidden only={['xs', 'sm']} implementation="css">
                    <VBToolTip content={<FormattedMessage id="menu.shareLesson.tooltip" />}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<PersonAddIcon />}
                            onClick={displayShareDialog}
                        >
                            <FormattedMessage id="menu.shareLesson" />
                        </Button>
                    </VBToolTip>
                </Hidden>
            </div>
            {multiBoardOption}
        </>
    );
};

const mapStateToProps = (state) => {
    const { drawing, lessonState, profile } = state;
    return { drawing, lessonState, profile };
};

export default injectIntl(connect(mapStateToProps)(ActionButtons));
