import React from 'react';

import Hidden from '@material-ui/core/Hidden';
import DesktopTable from './DesktopTable';
import MobileTable from './MobileTable';

const ResponsiveTable = ({ data, metaDesktop, metaMobile, metaTablet, styled, groups, editStyle }) => {
    return (
        <>
            <Hidden only={['xs','sm','md']}>
                <DesktopTable data={data} meta={metaDesktop} styled={styled} editStyle={editStyle}/>
            </Hidden>
            <Hidden only={['xs','lg','xl']}>
                <MobileTable data={data} meta={metaTablet} styled={styled} groups={groups} editStyle={editStyle}/>
            </Hidden>
            <Hidden only={['sm','md','lg','xl']}>
                <MobileTable data={data} meta={metaMobile} styled={styled} groups={groups} editStyle={editStyle}/>
            </Hidden>
        </>
    );
};

export default ResponsiveTable;
