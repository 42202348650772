import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { YOUTUBE_CLIPS } from '../../constants';
import SectionChapter from '../../SectionChapter';

const ZoomManagement = ({ language, refs }) => {
    let boardMenu = [
        {
            children: (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <iframe
                        width="100%"
                        height="315"
                        src={YOUTUBE_CLIPS.ZOOM[language.toUpperCase()]}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Zoom"
                    />
                </div>
            ),
        },
        {
            id: 'tutorial.manageLesson.zoom.explanation',
            children: (
                <ul>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.zoom.buttons"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.zoom.buttons.explanation"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.boardMenu.glide"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.boardMenu.glide.explanation"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>
                </ul>
            ),
            img: [
                `/static/asset/img/tutorial/withText/${language.toUpperCase()}/lessonPage_zoom.jpeg`,
                `/static/asset/img/tutorial/withText/${language.toUpperCase()}/lessonPage_navigatePage.jpeg`,
            ],
        },
        {
            children: (
                <ul>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.boardMenu.fingers"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.boardMenu.fingers.explanation"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.boardMenu.fingers.navigation"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.boardMenu.fingers.navigation.explanation"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>
                </ul>
            ),
            img: [
                `/static/asset/img/tutorial/withText/${language.toUpperCase()}/lessonPage_mobileZoom.jpeg`,
                `/static/asset/img/tutorial/withText/${language.toUpperCase()}/lessonPage_mobileNavigation.jpeg`,
            ],
        },
    ];
    return (
        <SectionChapter
            refs={refs}
            id={'section-manageLesson-zoom'}
            title={'tutorial.manageLesson.zoom.title'}
            explanations={boardMenu}
            imageTop={false}
        />
    );
};

export default ZoomManagement;
