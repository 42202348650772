import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function OutlinedOrangeSelectedFolderIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="39" height="39" rx="7.5" stroke="#FBF0E9"/>
                <path d="M11 30.5H29C30.6547 30.5 32 29.1547 32 27.5V15.5C32 13.8453 30.6547 12.5 29 12.5H21.9922C21.1953 12.5 20.4312 12.1859 19.8687 11.6234L18.6266 10.3766C18.0641 9.81406 17.3 9.5 16.5031 9.5H11C9.34531 9.5 8 10.8453 8 12.5V27.5C8 29.1547 9.34531 30.5 11 30.5Z" fill="#F9A237"/>
            </svg>
        </SvgIcon>
    );
}
