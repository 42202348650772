import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import SectionChapter from '../../SectionChapter';

const ShareLibrary = ({ language, refs }) => {
    let boardMenu = [
        {
            id: 'tutorial.shareLibrary.shareLibrary.description',
            children: (
                <ul>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id='tutorial.shareLibrary.shareLibrary.explanation1'
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id='tutorial.shareLibrary.shareLibrary.explanation2'
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id='tutorial.shareLibrary.shareLibrary.explanation3'
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>
                </ul>
            ),
            img: [
                `/static/asset/img/tutorial/withText/${language.toUpperCase()}/ShareBoardPlus.png`,
                `/static/asset/img/tutorial/withText/${language.toUpperCase()}/shareLibraryEmail.png`,
            ],
        },
    ];
    return (
        <SectionChapter
            refs={refs}
            id={'section-shareLibrary-shareLibrary'}
            title={'tutorial.shareLibrary.shareLibrary.title'}
            explanations={boardMenu}
            imageTop={false}
        />
    );
};

export default ShareLibrary;
