import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import SectionChapter from '../../SectionChapter';

const CloseIndividualBoards = ({ language, refs }) => {
    let boardMenu = [
        {
            id: 'tutorial.manageSplitBoardLesson.close.description',
            children: (
                <ul>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.manageSplitBoardLesson.close.explanation1"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.manageSplitBoardLesson.close.explanation2"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>
                </ul>
            ),
            img: [`/static/asset/img/tutorial/withText/${language.toUpperCase()}/lessonPage_CloseMultiBoard.jpeg`],
        },
    ];
    return (
        <SectionChapter
            refs={refs}
            id={'section-manageSplitBoardLesson-close'}
            title={'tutorial.manageSplitBoardLesson.close.title'}
            explanations={boardMenu}
            imageTop={false}
        />
    );
};

export default CloseIndividualBoards;
