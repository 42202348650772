import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import store from '../../redux/store';
import { showMultiBoardLessonState } from '../../redux/actions';
import { ListItemIcon, ListItemText } from '@material-ui/core';
import CustomizedExpansionPanels from '../ExpansionPanel/CustomizedExpansionPanels';
import Chip from '@material-ui/core/Chip';
import QueuePlayNextIcon from '@material-ui/icons/QueuePlayNext';
import boardManager from '../../pages/LessonPage/boardManager';
import { BOARD_TYPE } from '../../constants';

const MultiBoardList = (props) => {
    let buildMultiBoardPanel = (items) => {
        if (!items || items.length === 0) return null;
        // console.log(items);
        let myItems = items.filter((el) => Object.keys(el.data.users).length > 0);
        if (boardManager.lessonState.boardType === BOARD_TYPE.MULTI_BOARD) {
            myItems = myItems.filter((el) => el.id !== boardManager.multiBoardId);
        }
        if (!myItems || myItems.length === 0) return null;
        myItems.reverse();
        return (
            <List>
                {myItems.map((item) => {
                    // console.log('=== muted state', user.isMuted);
                    return (
                        <ListItem
                            disableGutters
                            key={`multiBoardLessonKey-${item.id}`}
                            button
                            onClick={() => {
                                // console.log('=== trying to dispatch', item);
                                store.dispatch(showMultiBoardLessonState({ id: item.id, ...item.data }));
                            }}
                        >
                            <ListItemIcon>
                                <Chip label={Object.keys(item.data.users).length} />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ variant: 'body2' }}
                                primary={new Date(item.data.date).toLocaleString('en-US', {
                                    year: 'numeric',
                                    month: 'short',
                                    day: 'numeric',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                })}
                            />
                        </ListItem>
                    );
                })}
            </List>
        );
    };

    let panels = [];

    let lessonList = buildMultiBoardPanel(props.appState.multiBoardState);
    if (lessonList) {
        panels.push({
            name: 'MultiBoardActions',
            title: props.intl.formatMessage({ id: 'menu.pastLessons' }),
            expanded: 'MultiBoardActions',
            content: lessonList,
            icon: (
                <ListItemIcon>
                    <QueuePlayNextIcon color="primary" />
                </ListItemIcon>
            ),
        });
    } else {
        return null;
    }

    return (
        <div>
            <CustomizedExpansionPanels panels={panels} />
        </div>
    );
};

const mapStateToProps = (state) => {
    const { appState } = state;
    return { appState };
};

export default injectIntl(connect(mapStateToProps)(MultiBoardList));
