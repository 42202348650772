import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    slideshowContainer: { maxWidth: '1000px', position: 'relative', margin: 'auto' },
    mySlidesHide: { display: 'none', animationName: 'fade', animationDuration: '1.5s' },
    mySlidesShow: { display: 'block', animationName: 'fade', animationDuration: '1.5s' },
    fade: {},
    numberText: { color: '#1D2130', fontSize: '12px', padding: '8px 12px', position: 'absolute', top: 0 },
    text: {
        color: '#1D2130',
        fontSize: '15px',
        padding: '8px 12px',
        position: 'absolute',
        bottom: '8px',
        width: '100%',
        textAlign: 'center',
    },
    prev: {
        cursor: 'pointer',
        position: 'absolute',
        top: '50%',
        width: 'auto',
        padding: '16px',
        marginTop: '-22px',
        fontWeight: 'bold',
        fontSize: '18px',
        transition: '0.6s ease',
        borderRadius: '0 3px 3px 0',
        userSelect: 'none',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
        },
    },
    next: {
        cursor: 'pointer',
        position: 'absolute',
        top: '50%',
        width: 'auto',
        padding: '16px',
        marginTop: '-22px',
        fontWeight: 'bold',
        fontSize: '18px',
        transition: '0.6s ease',
        userSelect: 'none',
        right: '0',
        borderRadius: '3px 0 0 3px',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
        },
    },
}));

const SlideShow = ({ slideElements, enableZoom = false }) => {
    const [slideIndex, setSlideIndex] = React.useState(0);
    const [showZoomed, setShowZoomed] = React.useState(false);
    const classes = useStyles();

    const onNext = (event) => {
        if (slideIndex + 1 >= slideElements.length) {
            setSlideIndex(0);
        } else {
            setSlideIndex(slideIndex + 1);
        }
        event.preventDefault();
        event.stopPropagation();
    };

    const onPrev = (event) => {
        if (slideIndex - 1 < 0) {
            setSlideIndex(slideElements.length - 1);
        } else {
            setSlideIndex(slideIndex - 1);
        }
        event.preventDefault();
        event.stopPropagation();
    };

    useEffect(() => {
        setSlideIndex(0);
    }, [slideElements]);

    const zoomImage = () => {
        if (enableZoom) {
            setShowZoomed(true);
        }
    };

    return (
        <div style={{ display: 'flex' }}>
            <div className={classes.slideshowContainer}>
                {slideElements.map((el, index) => {
                    return (
                        <div
                            className={index === slideIndex ? classes.mySlidesShow : classes.mySlidesHide}
                            key={`slide-show-${index}`}
                        >
                            {slideElements.length > 1 ? (
                                <div className={classes.numberText}>
                                    {index + 1} / {slideElements.length}
                                </div>
                            ) : null}
                            <img src={el.img} style={{
                                width: '100%',
                                verticalAlign: 'middle',
                                cursor: enableZoom ? 'zoom-in' : 'auto',
                            }} onClick={zoomImage} alt='zoom' />
                            <div className={classes.text}>{el.text ? el.text : ''}</div>
                        </div>
                    );
                })}

                {slideElements.length > 1 ? (
                    <>
                        <a className={classes.prev} onClick={onPrev}>
                            ❮
                        </a>
                        <a className={classes.next} onClick={onNext}>
                            ❯
                        </a>
                    </>
                ) : null}
            </div>
            <br />
            <Dialog onClose={() => {
                setShowZoomed(false);
            }} open={showZoomed} fullWidth={true} maxWidth={false}>
                <img src={slideElements[slideIndex].img} style={{
                    width: '100%',
                    verticalAlign: 'middle',
                    cursor: enableZoom ? 'zoom-out' : 'auto',
                }} onClick={() => {
                    setShowZoomed(false);
                }} alt='back' />
            </Dialog>
        </div>
    );
};

export default SlideShow;
