import Button from '@material-ui/core/Button';
import { GA_EVENT_TREE } from '../../../../gaConstants';
import React from 'react';
import WhiteboardLink from '../../../../MyComponents/icons/WhiteboardLink.js';
import PhoneQR from '../../../../MyComponents/icons/PhoneQR.js';

export const ConnectAccountButton = ({ handleLogin, classes }) => {
    return (
        <div>
            {!window.isTeams ? (
                <>
                    <Button
                        size='large'
                        variant='contained'
                        className={classes.linkWhiteboard}
                        onClick={() => {
                            window.logAppActivity(GA_EVENT_TREE.mainPage.links.linkWhiteboard);
                            handleLogin('linkWhiteboard');
                        }}
                        // startIcon={<WhiteboardLink className={classes.customIcon} />}
                    >
                        <div style={{ display: 'inline-flex' }}>
                            <PhoneQR style={{ width: '3.5rem', height: '3.5rem' }} color={'primary'} />
                            <WhiteboardLink style={{ width: '3.5rem', height: '3.5rem' }} />
                        </div>
                    </Button>
                </>
            ) : null}
        </div>
    );
};
