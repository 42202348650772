import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import CallIcon from '@material-ui/icons/Call';
import BottomList from '../../../../MyComponents/icons/BottomList';
import MicIcon from '@material-ui/icons/Mic';
import VideocamIcon from '@material-ui/icons/Videocam';
import SectionChapter from '../../SectionChapter';
import { COLORS } from '../../../../constants';

const AudioVideoManagement = ({ language, classes, refs }) => {
    let audioVideo = [
        {
            id: 'tutorial.manageLesson.audio.explanation1',
            children: (
                <ul>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.manageLesson.audio.call"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                            <CallIcon style={{ color: COLORS.secondary }} className={classes.sampleFab} />
                        </Typography>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.manageLesson.audio.call.explanation"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.manageLesson.audio.showUsers"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />

                            <BottomList className={classes.sampleFab} style={{ color: COLORS.secondary }} />
                        </Typography>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.manageLesson.audio.showUsers.explanation"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.manageLesson.audio.microphone"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                            <MicIcon className={classes.sampleFab} />
                        </Typography>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.manageLesson.audio.microphone.explanation"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                            <VideocamIcon className={classes.sampleFab} />
                        </Typography>
                    </li>

                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.manageLesson.audio.enableVideo"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.manageLesson.audio.enableVideo.explanation"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>
                </ul>
            ),
            img: [
                `/static/asset/img/tutorial/withText/${language.toUpperCase()}/lessonPage_startCall.jpeg`,
                `/static/asset/img/tutorial/withText/${language.toUpperCase()}/lessonPage_microphone.jpg`,
                `/static/asset/img/tutorial/withText/${language.toUpperCase()}/ShowHideVideo.jpg`,
            ],
        },
    ];
    return (
        <SectionChapter
            refs={refs}
            id={'section-manageLesson-audio'}
            title={'tutorial.manageLesson.audio.title'}
            explanations={audioVideo}
            imageTop={false}
        />
    );
};

export default AudioVideoManagement;
