import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Typography,
} from '@material-ui/core';
import { Close, Delete } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

const GenericDeletionConfirmation = ({ open, deleteFunction, type = '', value = '', onClose }) => {
    const classes = useStyles();
    const [localOpen, setLocalOpen] = React.useState(false);
    useEffect(() => {
        setLocalOpen(open);
    }, [open]);

    const hideModal = () => {
        setLocalOpen(false);
        onClose();
    };

    return (
        <Dialog
            open={localOpen}
            onClose={hideModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">
                <Typography>
                    <FormattedMessage id="generic.deletion.deleteConfirmationTitle" />
                </Typography>
                <IconButton aria-label="close" className={classes.closeButton} onClick={hideModal}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <FormattedMessage
                        id={'generic.deletion.deleteConfirmationMessage'}
                        values={{ deletedItemType: type, deletedItemText: value }}
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={hideModal} size="large" variant="contained" color="default">
                    <FormattedMessage id="close" />
                </Button>
                <Button onClick={deleteFunction} color="secondary" autoFocus variant="contained" startIcon={<Delete />}>
                    <FormattedMessage id="generic.label.delete" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default GenericDeletionConfirmation;
