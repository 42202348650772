import React from 'react';
import { YOUTUBE_CLIPS } from '../constants';
import Divider from '@material-ui/core/Divider';
import SectionHeader from '../SectionHeader';
import StartIndividualBoard from './IndividualBoardSections/StartIndividualBoard';
import ShowIndividualBoards from './IndividualBoardSections/ShowIndividualBoards';
import ManageIndividualBoards from './IndividualBoardSections/ManageIndividualBoards';
import CloseIndividualBoards from './IndividualBoardSections/CloseIndividualBoards';
import IndividualBoardActivity from './IndividualBoardSections/IndividualBoardActivity';

const IndividualBoardManagement = ({ language, classes, refs }) => {
    return (
        <>
            <SectionHeader
                refs={refs}
                title={'tutorial.manageSplitBoardLesson.title'}
                description={'tutorial.manageSplitBoardLesson.description'}
                id={'section-manageSplitBoardLesson'}
                youtubeSrc={YOUTUBE_CLIPS.TUTORING[language.toUpperCase()]}
                imageTop={false}
            />
            <Divider variant="middle" />
            <StartIndividualBoard refs={refs} language={language} classes={classes} />
            <Divider variant="middle" />
            <ShowIndividualBoards refs={refs} language={language} classes={classes} />
            <Divider variant="middle" />
            <ManageIndividualBoards refs={refs} language={language} classes={classes} />
            <Divider variant="middle" />
            <CloseIndividualBoards refs={refs} language={language} classes={classes} />
            <Divider variant="middle" />
            <IndividualBoardActivity refs={refs} language={language} classes={classes} />
        </>
    );
};

export default IndividualBoardManagement;
