import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function TutorialSideMenuIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_46_244)">
                    <path d="M6.94063 0.559375C7.525 -0.025 8.475 -0.025 9.0625 0.559375L10.5 2H12.5C13.3281 2 14 2.67188 14 3.5V5.5L15.425 6.925C16.0094 7.50938 16.0094 8.45938 15.425 9.04688L14 10.4719V12.5C14 13.3281 13.3281 14 12.5 14H10.4719L9.0625 15.4094C8.47812 15.9938 7.52813 15.9938 6.94063 15.4094L5.52812 14H3.5C2.67188 14 2 13.3281 2 12.5V10.4719L0.575 9.04688C-0.00937501 8.4625 -0.00937501 7.5125 0.575 6.925L2 5.5V3.5C2 2.67188 2.67188 2 3.5 2H5.5L6.94063 0.559375ZM5.30625 5.16563L5.29375 5.20312C5.15625 5.59375 5.35938 6.02188 5.75 6.15938C6.14062 6.29688 6.56875 6.09375 6.70625 5.70312L6.71875 5.66563C6.75313 5.56563 6.85 5.5 6.95312 5.5H8.775C9.0375 5.5 9.24687 5.7125 9.24687 5.97188C9.24687 6.14062 9.15625 6.29688 9.00937 6.38125L7.625 7.175C7.39062 7.30938 7.24687 7.55625 7.24687 7.825V8.25C7.24687 8.66562 7.58125 9 7.99687 9C8.40625 9 8.74062 8.67188 8.74687 8.2625L9.75625 7.68437C10.3688 7.33125 10.7469 6.67813 10.7469 5.97188C10.7469 4.88125 9.8625 4 8.775 4H6.95625C6.21563 4 5.55625 4.46563 5.30625 5.16563ZM9 11C9 10.4469 8.55313 10 8 10C7.44688 10 7 10.4469 7 11C7 11.5531 7.44688 12 8 12C8.55313 12 9 11.5531 9 11Z" fill="#08AAB4"/>
                </g>
                <defs>
                    <clipPath id="clip0_46_244">
                        <rect width="16" height="16" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        </SvgIcon>
    );
}
