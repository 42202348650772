import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import RulerIcon from '../../../../MyComponents/icons/RulerIcon';
import ProtractorIcon from '../../../../MyComponents/icons/ProtractorIcon';
import SectionChapter from '../../SectionChapter';
import CompassIcon from '../../../../MyComponents/icons/CompassIcon';

const GeometryManagement = ({ language, classes, refs }) => {
    let instruments = [
        {
            id: 'redesign.page.tutorial.manageLesson.geometry.explanation1',
            children: (
                <ul>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="redesign.tutorial.geometry.line1"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                            <RulerIcon className={classes.sampleFab} />
                        </Typography>
                        <Typography>
                            <FormattedMessage
                                id="redesign.tutorial.geometry.line1.explanation"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                    li: (chunks) => (
                                        <span>
                                            {chunks}
                                            <br></br>
                                            <br></br>
                                        </span>
                                    ),
                                }}
                            />
                        </Typography>
                    </li>
                </ul>
            ),
            img: [
                `/static/asset/img/tutorial/withText/${language.toUpperCase()}/RulerRotate.jpg`,
                `/static/asset/img/tutorial/withText/${language.toUpperCase()}/RulerMove.jpg`,
                `/static/asset/img/tutorial/withText/${language.toUpperCase()}/RulerDelete.jpg`,
            ],
        },
        {
            children: (
                <ul>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="redesign.tutorial.geometry.line2"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                            <ProtractorIcon className={classes.sampleFab} />
                        </Typography>
                        <Typography>
                            <FormattedMessage
                                id="redesign.tutorial.geometry.line2.explanation"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                    li: (chunks) => (
                                        <span>
                                            {chunks}
                                            <br></br>
                                            <br></br>
                                        </span>
                                    ),
                                }}
                            />
                        </Typography>
                    </li>
                </ul>
            ),
            img: [
                `/static/asset/img/tutorial/withText/${language.toUpperCase()}/ProtractorRotate.jpg`,
                `/static/asset/img/tutorial/withText/${language.toUpperCase()}/ProtractorMove.jpg`,
                `/static/asset/img/tutorial/withText/${language.toUpperCase()}/ProtractorDelete.jpg`,
                `/static/asset/img/tutorial/withText/${language.toUpperCase()}/Geometry.jpg`,
            ],
        },
        {
            children: (
                <ul>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="redesign.tutorial.geometry.line3"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                            <CompassIcon className={classes.sampleFab} />
                        </Typography>
                        <Typography>
                            <FormattedMessage
                                id="redesign.tutorial.geometry.line3.explanation"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                    li: (chunks) => (
                                        <span>
                                            {chunks}
                                            <br></br>
                                            <br></br>
                                        </span>
                                    ),
                                }}
                            />
                        </Typography>
                    </li>
                </ul>
            ),
            img: [
                `/static/asset/img/tutorial/withText/${language.toUpperCase()}/MoveCompass.png`,
                `/static/asset/img/tutorial/withText/${language.toUpperCase()}/ResizeCompass.png`,
                `/static/asset/img/tutorial/withText/${language.toUpperCase()}/DrawCompass.png`,
            ],
        },
    ];
    return (
        <SectionChapter
            refs={refs}
            id={'section-manageLesson-geometry'}
            title={'redesign.page.tutorial.manageLesson.geometry.title'}
            explanations={instruments}
            imageTop={false}
        />
    );
};

export default GeometryManagement;
