export const SUBSCRIPTION_PLANS = {
    FREE: 'FREE',
    TRIAL: 'TRIAL',
    SPECIAL: 'SPECIAL',
    BASIC: 'BASIC',
    PREMIUM: 'PREMIUM',
};

export const CAPABILITIES = {
    AUDIO_VIDEO: 'audioVideo',
    MULTIPLE_INDIVIDUAL_BOARDS: 'multipleIndividualBoards',
    PDF_DOWNLOAD: 'pdfDownload',
    GROUP_COUNT: 'groupCount',
    LIBRARY_COUNT: 'collectionCount',
    INDIVIDUAL_SESSION_COUNT: 'savedMultiBoardSessionCount',
};

export const EXPIRATION_GRACE_DAYS = 7;
export const EXTENDED_TRIAL_DAYS = 30;
export const TRIAL_DAYS = 14;

export const SUBSCRIPTION_ORDER = {};
SUBSCRIPTION_ORDER[SUBSCRIPTION_PLANS.FREE] = 0;
SUBSCRIPTION_ORDER[SUBSCRIPTION_PLANS.TRIAL] = 0;
SUBSCRIPTION_ORDER[SUBSCRIPTION_PLANS.BASIC] = 1;
SUBSCRIPTION_ORDER[SUBSCRIPTION_PLANS.PREMIUM] = 2;

export const SUBSCRIPTION_STATE_ACTION = {
    START: 'START',
    NONE: 'NONE',
    ACTIVE: 'ACTIVE',
    CANCEL: 'CANCEL',
    UPGRADE: 'UPGRADE',
    CANCEL_OTHER: 'CANCEL_OTHER',
};

export const getSubscriptionStateAction = (profile, subscriptions, subscriptionName) => {
    if (!profile || !subscriptions || !profile.subscription) {
        return SUBSCRIPTION_STATE_ACTION.START;
    }
    if (!profile.loggedIn) {
        return SUBSCRIPTION_STATE_ACTION.START;
    }
    let subscriptionData = getSubscriptionData(subscriptions, profile.subscription);
    if (subscriptionData.subscriptionName === subscriptionName) {
        if (SUBSCRIPTION_ORDER[subscriptionName] > 0) {
            return SUBSCRIPTION_STATE_ACTION.CANCEL;
        } else {
            return SUBSCRIPTION_STATE_ACTION.ACTIVE;
        }
    } else {
        if (SUBSCRIPTION_ORDER[subscriptionName] > SUBSCRIPTION_ORDER[subscriptionData.subscriptionName]) {
            return SUBSCRIPTION_STATE_ACTION.UPGRADE;
        } else {
            return SUBSCRIPTION_STATE_ACTION.NONE;
        }
    }
};

export const getSubscriptionData = function (appSubscriptions, userSubscription, expiredCallback = null) {
    let capabilities = { ...appSubscriptions[SUBSCRIPTION_PLANS.FREE] };
    if (userSubscription) {
        Object.keys(appSubscriptions).forEach((key) => {
            let subscription = appSubscriptions[key];
            if (subscription.id === userSubscription.subscriptionId && subscription.active) {
                if (key === SUBSCRIPTION_PLANS.TRIAL) {
                    let expirationDate = new Date(userSubscription.endDate);
                    if (expirationDate > new Date()) {
                        capabilities = { ...subscription };
                    } else if (expiredCallback) {
                        expiredCallback();
                    }
                } else {
                    if (userSubscription.endDate) {
                        let expirationDate = new Date(userSubscription.endDate);
                        expirationDate.setDate(expirationDate.getDate() + EXPIRATION_GRACE_DAYS);
                        if (expirationDate > new Date()) {
                            capabilities = { ...subscription };
                        } else if (expiredCallback) {
                            expiredCallback();
                        }
                    } else {
                        capabilities = { ...subscription };
                    }
                }
            }
        });
    }
    delete capabilities.active;
    delete capabilities.id;
    delete capabilities.subscriptionStripeId;
    return capabilities;
};

export function validateAndAdd(key, availableSubscriptions, userSubscription) {
    if (key !== SUBSCRIPTION_PLANS.TRIAL) {
        if (key !== SUBSCRIPTION_PLANS.SPECIAL) {
            availableSubscriptions.push(key);
        }
    } else {
        if (!userSubscription.trialStarted) {
            availableSubscriptions.push(key);
        }
    }
}

export const hasBooleanPermission = function (appSubscriptions, userSubscription, permission) {
    let capabilities = getSubscriptionData(appSubscriptions, userSubscription);
    if (capabilities[permission]) {
        return {
            value: true,
        };
    }
    let availableSubscriptions = [];
    Object.keys(appSubscriptions).forEach((key) => {
        if (appSubscriptions[key][permission]) {
            validateAndAdd(key, availableSubscriptions, userSubscription);
        }
    });
    return {
        value: false,
        availableSubscriptions,
    };
};

export const getIntPermission = function (appSubscriptions, userSubscription, permission) {
    let capabilities = getSubscriptionData(appSubscriptions, userSubscription);
    if (capabilities[permission]) {
        return {
            value: capabilities[permission],
        };
    }
    return {
        value: 0,
    };
};

export const canIncreaseLimitedData = async function (appSubscriptions, userSubscription, permission, countAsyncFunc) {
    let capabilities = getSubscriptionData(appSubscriptions, userSubscription);
    if (capabilities[permission] === -1) {
        return {
            value: true,
        };
    }
    let currentCount = await countAsyncFunc;
    if (capabilities[permission] > currentCount) {
        return {
            value: true,
        };
    }
    let availableSubscriptions = [];
    Object.keys(appSubscriptions).forEach((key) => {
        if (appSubscriptions[key][permission] === -1 || appSubscriptions[key][permission] > currentCount) {
            validateAndAdd(key, availableSubscriptions, userSubscription);
        }
    });
    return {
        value: false,
        availableSubscriptions,
    };
};

export const canActOnData = async function (appSubscriptions, userSubscription, permission, asyncGetList, actorId) {
    let capabilities = getSubscriptionData(appSubscriptions, userSubscription);
    if (capabilities[permission] === -1) {
        return {
            value: true,
        };
    }
    let actionableList = await asyncGetList(capabilities[permission]);
    if (actionableList.includes(actorId)) {
        return {
            value: true,
        };
    }
    let availableSubscriptions = [];
    Object.keys(appSubscriptions).forEach((key) => {
        if (appSubscriptions[key][permission] === -1 || appSubscriptions[key][permission] > actionableList.length) {
            validateAndAdd(key, availableSubscriptions, userSubscription);
        }
    });

    return {
        value: false,
        availableSubscriptions: availableSubscriptions,
    };
};
