import { UPDATE_APP_SUBSCRIPTION } from '../actionTypes';

const initialState = {
    subscriptions: null,
    pricing: null,
};

const mainAppState = function (state = initialState, action) {
    switch (action.type) {
        case UPDATE_APP_SUBSCRIPTION: {
            const { subscriptions, pricing } = action.payload;
            return {
                ...state,
                subscriptions,
                pricing,
            };
        }
        default:
            return state;
    }
};
export default mainAppState;
