import React, { useEffect, useState } from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import { COLORS2 } from '../../constants';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import QRCode from 'qrcode';
import { v4 as uuidv4 } from 'uuid';
import { ALLOW_WHITEBOARD } from '../../common/urlLinks.js';
import userApi from '../../Api/userApi.js';
import PhoneQR from '../../MyComponents/icons/PhoneQR.js';

const useStyles = makeStyles(() => ({
    menuNav: {
        fontWeight: 600,
        fontSize: '0.875rem',
        lineHeight: '1.063rem',
        textAlign: 'right',
        letterSpacing: '0.16rem',
        color: COLORS2.findMore,
    },
    menuNavActive: {
        fontWeight: 700,
        fontSize: '0.875rem',
        lineHeight: '1.063rem',
        textAlign: 'right',
        letterSpacing: '0.16rem',
        color: COLORS2.findMore,
        borderBottom: '0.125rem solid #D26828',
    },
    desktopContainer: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '-10rem',
        background: 'linear-gradient(180deg, rgba(251, 248, 245, 0) 0%, #FFFFFF 93.57%)',
        borderRadius: '0rem 0rem 2rem 2rem',
    },
    bottomGreenPaper: {
        background: 'rgba(8, 170, 180, 0.16)',
        borderRadius: '0rem 0rem 2rem 2rem',
        height: '2rem',
        width: '90%',
        top: '10rem',
        position: 'relative',
        marginLeft: '5%',
    },
    gridWrap: {
        height: '100%',
        background: 'linear-gradient(180deg, rgba(251, 248, 245, 0) 0%, #FFFFFF 93.57%)',
        borderRadius: '0rem 0rem 2rem 2rem',
        paddingBottom: '2rem',
        paddingLeft: '3rem',
        paddingRight: '3rem',
        marginTop: '4rem',
    },
    homeImg: {
        display: 'flex',
        position: 'absolute',
        backgroundColor: '#FFFFFF',
        borderRadius: '2rem',
        padding: '2rem',
        width: '45rem',
        height: '30rem',
        left: '20%',
        zIndex: '0',
    },
}));

function showQRCode(link) {
    let canvas = document.getElementById('qr-code-whiteboard-link');
    if (canvas && link) {
        QRCode.toCanvas(canvas, link, function(error) {
            if (error) {
                console.error(error);
            }
        });
    }
}


const LinkWhiteboard = () => {
    const classes = useStyles();

    const [link, setLink] = useState(null);


    useEffect(() => {
        let uid = uuidv4();
        setLink(uid);
    }, []);

    useEffect(() => {
        let l = `${window.origin}${ALLOW_WHITEBOARD}/${link}`;
        if (!link) return;
        showQRCode(l);
        let interval = setInterval(() => {
            userApi.getLinkConnection(link)
                .then(() => {
                    window.location = window.location.origin;
                }).catch(() => {
            });
        }, 2000);
        return () => {
            clearInterval(interval);
        };
    }, [link]);

    return (
        <Container>
            <div className={classes.desktopContainer}>
                <Container className={classes.gridWrap}>
                    <Grid container>
                        <Grid item xs={12}>
                            <img alt='mainHomeWelcome' src={'/MainHomeWelcome.png'} className={classes.homeImg} />
                            <Typography variant='h1' style={{ position: 'absolute' }}>
                                <FormattedMessage
                                    id={'page.linkwhiteboard.connect.tag'}
                                    values={{
                                        b: (chunks) => (
                                            <b>
                                                <br></br>
                                                {chunks}
                                                <br></br>
                                            </b>
                                        ),
                                    }}
                                />
                            </Typography>
                        </Grid>
                        <Grid style={{ width: '100%' }}>
                            <div
                                style={{
                                    display: 'inline-block',
                                    width: '100%',
                                    textAlign: 'center',
                                    height: '12rem',
                                    position: 'relative',
                                }}
                            >
                            </div>
                        </Grid>
                        <Grid style={{ width: '100%' }}>
                            <div
                                style={{
                                    display: 'inline-block',
                                    width: '100%',
                                    textAlign: 'center',
                                    position: 'relative',
                                }}
                            >
                                <canvas id='qr-code-whiteboard-link' />
                            </div>
                        </Grid>

                        <Grid style={{ width: '100%' }}>
                            <div
                                style={{
                                    display: 'inline-block',
                                    width: '100%',
                                    textAlign: 'center',
                                    height: '13rem',
                                    position: 'relative',
                                    paddingTop: '2rem',
                                }}
                            >
                                <Typography variant='h3'>
                                    <FormattedMessage
                                        id={'page.linkwhiteboard.connect.step1'}
                                        values={{
                                            b: (chunks) => (
                                                <b>
                                                    {chunks}
                                                </b>
                                            ),
                                        }}
                                    />
                                    <PhoneQR style={{ width: '3.5rem', height: '3.5rem' }} color={'primary'} />
                                </Typography>
                                <br />
                                <Typography variant='h3'>
                                    <FormattedMessage
                                        id={'page.linkwhiteboard.connect.step2'}
                                        values={{
                                            b: (chunks) => (
                                                <b>
                                                    {chunks}
                                                </b>
                                            ),
                                        }}

                                    />
                                </Typography>
                            </div>
                        </Grid>

                    </Grid>
                </Container>
            </div>
            <div className={classes.bottomGreenPaper} />
        </Container>
    );
};

export default LinkWhiteboard;
