import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import QRCode from 'qrcode';
import Button from '@material-ui/core/Button';
import { Divider, IconButton } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { EmailShareButton, FacebookShareButton, ViberShareButton, WhatsappShareButton } from 'react-share';
import EmailIcon from '../../MyComponents/icons/EmailIcon';
import FacebookIcon from '../../MyComponents/icons/FacebookIcon';
import WhatsappIcon from '../../MyComponents/icons/WhatsappIcon';
import ViberIcon from '../../MyComponents/icons/ViberIcon';
import { makeStyles } from '@material-ui/core/styles';
import { GA_EVENT_TREE } from '../../gaConstants';
import { FileCopyOutlined } from '@material-ui/icons';
import QRCodeIcon from '../../MyComponents/icons/QRCodeIcon';

const useStyles = makeStyles((theme) => ({
    dividerSpacing: {
        margin: theme.spacing(3, 2),
    },
    smallVariant: {
        fontSize: '0.8rem',
        lineHeight: '1rem',
        letterSpacing: '0.1rem',
        textTransform: 'uppercase',
        color: 'white',
        fontWeight: 700,
    },
}));

const ShareBox = (props) => {
    const classes = useStyles();

    let [copied, setCopied] = useState(false);
    let [showQr, setShowQr] = useState(false);

    let link = '';
    if (props.lessonId) {
        link = window.location.protocol + '//' + window.location.host + '/lesson/' + props.lessonId;
    }

    useEffect(() => {
        if (window.gapi) {
            window.gapi.sharetoclassroom.go('google-class-share');
        }
    }, []);

    const showQRCode = () => {
        let canvas = document.getElementById('qr-code-lesson');
        QRCode.toCanvas(canvas, link, function (error) {
            setShowQr(!showQr);
            if (error) console.error(error);
        });
    };

    return (
        <>
            <div
                id="google-class-share"
                style={{
                    display: !showQr ? 'inline-block' : 'none',
                    width: '100%',
                    textAlign: 'center',
                    height: '5rem',
                    position: 'relative',
                }}
            >
                <div className="g-sharetoclassroom" data-size="48" data-url={link} />
                <div style={{ width: '100%', textAlign: 'center', bottom: '0px', position: 'absolute' }}>
                    <Typography>Google Classroom Share</Typography>
                </div>
            </div>
            <div
                style={{
                    display: showQr ? 'inline-block' : 'none',
                    width: '100%',
                    textAlign: 'center',
                    height: '5rem',
                    position: 'relative',
                }}
            >
                <canvas id="qr-code-lesson" />
            </div>

            <Divider className={classes.dividerSpacing} />
            <Typography>
                <FormattedMessage id="share.description" />
            </Typography>

            <Grid container alignItems="center">
                <Grid item xs={12} sm={8} style={{ display: 'inline-flex' }}>
                    <IconButton color={'primary'} onClick={showQRCode}>
                        <QRCodeIcon />
                    </IconButton>

                    <TextField id="outlined-basic" value={link} variant="outlined" disabled margin="dense" fullWidth />
                </Grid>
                <Grid
                    item
                    xs
                    className="u-fx u-fx-align-center"
                    style={{
                        justifyContent: 'end',
                        flexDirection: 'column-reverse',
                        marginTop: copied ? '1.5rem' : '0rem',
                    }}
                >
                    {copied ? (
                        <Typography>
                            <FormattedMessage id="generic.label.copied" />
                        </Typography>
                    ) : null}
                    <CopyToClipboard
                        text={link}
                        onCopy={() => {
                            window.logAppActivity(GA_EVENT_TREE.shareBoxActions.actions.copyLink);
                            setCopied(true);
                        }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            className="u-ml-full"
                            startIcon={<FileCopyOutlined />}
                        >
                            <Typography className={classes.smallVariant}>
                                <FormattedMessage id="generic.label.copyLink" />
                            </Typography>
                        </Button>
                    </CopyToClipboard>
                </Grid>
            </Grid>
            <Divider className={classes.dividerSpacing} />
            <Typography align="center" className="u-mb-full">
                <FormattedMessage id="share.alternative" />
            </Typography>
            <Grid container justify="space-evenly">
                <EmailShareButton url={link} subject={props.intl.formatMessage({ id: 'share.subject' })}>
                    <EmailIcon
                        style={{
                            fontSize: '50',
                        }}
                        onClick={() => {
                            window.logAppActivity(GA_EVENT_TREE.shareBoxActions.actions.mailLink);
                        }}
                    />
                </EmailShareButton>
                <FacebookShareButton url={link} quote={props.intl.formatMessage({ id: 'share.subject' })}>
                    <FacebookIcon
                        style={{
                            fontSize: '50',
                            color: '#385898',
                        }}
                        onClick={() => {
                            window.logAppActivity(GA_EVENT_TREE.shareBoxActions.actions.fbLink);
                        }}
                    />
                </FacebookShareButton>
                <WhatsappShareButton url={link} title={props.intl.formatMessage({ id: 'share.subject' })}>
                    <WhatsappIcon
                        style={{
                            fontSize: '50',
                            color: '#4DED69',
                        }}
                        onClick={() => {
                            window.logAppActivity(GA_EVENT_TREE.shareBoxActions.actions.whatsAppLink);
                        }}
                    />
                </WhatsappShareButton>
                <ViberShareButton url={link} title={props.intl.formatMessage({ id: 'share.subject' })}>
                    <ViberIcon
                        style={{
                            fontSize: '50',
                            color: '#594EE9',
                        }}
                        onClick={() => {
                            window.logAppActivity(GA_EVENT_TREE.shareBoxActions.actions.viberLink);
                        }}
                    />
                </ViberShareButton>
            </Grid>
        </>
    );
};

export default injectIntl(ShareBox);
