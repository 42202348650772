import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function VBIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 128 128"
                enableBackground="new 0 0 128 128"
                xmlSpace="preserve"
            >
                <path
                    fill="#D26828"
                    d="M26.068,89.819c-0.068-0.103-0.115-0.218-0.139-0.342c-0.354-1.814-0.245-4.281,0.31-6.946
	c0.956-4.591,3.293-9.223,6.362-9.223c0.33,0,0.658,0.056,0.975,0.169l19.597,6.521c1.758-20.679,1.759-20.69,1.761-20.708
	c0.218-2.193,2.964-3.562,7.165-3.562c1.372,0,2.791,0.151,4.215,0.447c2.41,0.5,4.675,1.399,6.377,2.534
	c0.106,0.071,0.197,0.163,0.268,0.27l0.836,1.271c0,0,0-0.001,0-0.001c0,0-25.464-38.743-25.594-38.94
	c-1.273-1.935-3.547-2.917-6.759-2.917c-7.406,0-17.89,5.127-23.128,8.572C10.964,31.798-3.526,44.856,1.284,52.169
	c0.098,0.148,22.081,33.544,25.238,38.342L26.068,89.819z"
                />
                <path
                    fill="#F9A237"
                    d="M32.601,73.309c0.33,0,0.658,0.056,0.975,0.169l19.597,6.521c1.758-20.679,1.759-20.69,1.761-20.708
	c0.041-0.41,0.17-0.792,0.381-1.141L30.466,20.839c-4.718,1.783-9.245,4.212-12.153,6.126c-2.99,1.966-7.164,5.295-10.737,9.044
	l24.899,37.311C32.518,73.317,32.558,73.309,32.601,73.309z"
                />
                <path
                    fill="#FFCD34"
                    d="M62.098,55.729c1.372,0,2.791,0.151,4.215,0.447c2.41,0.5,4.675,1.399,6.377,2.534
	c0.106,0.071,0.197,0.163,0.268,0.27l0.836,1.271c0,0,0-0.001,0-0.001c0,0-25.464-38.743-25.594-38.94
	c-1.273-1.935-3.547-2.917-6.759-2.917c-3.296,0-7.201,1.018-10.982,2.448L55.315,58.15C56.23,56.633,58.683,55.729,62.098,55.729z"
                />
                <path
                    fill="#08AAB4"
                    d="M65.459,98.734c3.051-2.005,6.494-2.943,9.891-2.904l0.117-32.747c0-0.188-0.054-0.371-0.156-0.528
	l-2.353-3.576c-0.07-0.107-0.161-0.199-0.268-0.27c-1.702-1.134-3.967-2.034-6.377-2.534c-1.425-0.296-2.843-0.447-4.215-0.447
	c-4.201,0-6.946,1.369-7.165,3.562c-0.001,0.017-0.002,0.028-1.761,20.708l-19.597-6.521c-0.317-0.113-0.645-0.169-0.975-0.169
	c-3.069,0-5.406,4.632-6.362,9.223c-0.555,2.665-0.665,5.132-0.31,6.946c0.024,0.124,0.071,0.239,0.139,0.342l2.351,3.576
	c0.104,0.158,0.254,0.283,0.428,0.356c0.152,0.065,9.418,4.029,19.511,8.35l10.539,4.509C60.21,103.501,62.424,100.73,65.459,98.734
	z"
                />
                <path
                    fill="#08AAB4"
                    d="M126.4,93.099c-1.041-1.763-2.394-3.201-4.021-4.277c-0.703-0.463-1.423-0.871-2.155-1.22
	c1.217-0.979,2.292-2.151,3.215-3.506c1.53-2.255,2.307-4.854,2.307-7.727c0-3.206-0.948-6.087-2.818-8.561
	c-1.829-2.42-4.393-4.339-7.623-5.705c-3.169-1.339-6.915-2.018-11.135-2.018c-3.505,0-7.005,0.171-10.406,0.511
	c-3.413,0.342-6.631,0.834-9.567,1.465l-1.133,0.243v52.544l1.126,0.246c1.669,0.367,3.745,0.726,6.172,1.07
	c2.405,0.338,4.978,0.603,7.646,0.787c2.673,0.185,5.191,0.276,7.485,0.276c4.384,0,8.301-0.776,11.642-2.308
	c3.37-1.547,6.044-3.648,7.946-6.251c1.938-2.651,2.921-5.617,2.921-8.816C128,97.171,127.462,94.899,126.4,93.099z M96.736,94.226
	c1.519-0.123,4.097-0.301,6.234-0.301c3.082,0,6.504,0.149,8.521,1.256c1.454,0.799,2.288,2.276,2.288,4.052
	c0,1.61-0.769,2.863-2.349,3.834c-1.603,0.982-3.66,1.439-6.475,1.439h-0.116c-2.848-0.011-6.204-0.275-8.104-0.554V94.226z
	 M110.358,81.732c-1.254,1.063-3.048,1.603-5.333,1.603h-8.289v-9.967c2.217-0.358,4.711-0.541,7.433-0.541
	c2.316,0,4.305,0.492,5.908,1.464c1.412,0.856,2.07,1.987,2.07,3.556C112.147,79.441,111.563,80.712,110.358,81.732z"
                />
                <path
                    fill="#D26828"
                    d="M75.364,95.83c-3.4-0.043-6.85,0.896-9.905,2.904c-3.06,2.013-5.288,4.811-6.599,7.952
	c7.264,3.108,13.604,5.82,15.7,6.716c0.101,0.037,0.319,0.088,0.509-0.037c0.18-0.117,0.221-0.308,0.23-0.42l0.012-2.359
	L75.364,95.83z"
                />
            </svg>
        </SvgIcon>
    );
}
