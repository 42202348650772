import React, { useState } from 'react';
import { Button, Collapse } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SubscriptionListRenderer from './SubscriptionListRenderer';

const ExpandableSubscriptionListRenderer = ({ featureList, highLightItem, isActive, isLight = true }) => {
    const [collapseState, setCollapseState] = useState(false);

    return (
        <>
            {!collapseState && highLightItem ? (
                <SubscriptionListRenderer
                    featureList={featureList}
                    highLightItem={highLightItem}
                    isActive={isActive}
                    isLight={isLight}
                />
            ) : null}
            <Collapse in={collapseState}>
                <SubscriptionListRenderer featureList={featureList} isActive={isActive} isLight={isLight} />
            </Collapse>
            <Button
                onClick={() => {
                    setCollapseState(!collapseState);
                }}
                endIcon={collapseState ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                fullWidth
            />
        </>
    );
};

export default ExpandableSubscriptionListRenderer;
