import React from 'react';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
    BOARD_TYPE,
    CALL_STATE,
    ELEMENT_OPERATIONS,
    MAX_MAIN_PAGES,
    MAX_MULTI_PAGES,
    STICKY_ELEMENTS_TYPE,
    TARGETED_REQUESTS,
    TOOL_ARC,
    TOOL_BRUSH,
    TOOL_CIRCLE,
    TOOL_ERASER,
    TOOL_IMAGE_PASTE,
    TOOL_LINE,
    TOOL_MATH_CONVERTER,
    TOOL_PEN,
    TOOL_POINTER,
    TOOL_RECTANGLE,
    TOOL_SELECT,
    TOOL_TEXT,
} from '../../constants';
import drawUtils from './drawUtils';
import moveUtils from './moveUtils';
import VerticalSlider from './VerticalSlider';
import HorizontalSlider from './HorizontalSlider';
import userApi from '../../Api/userApi';
import ScreenRotationIcon from '@material-ui/icons/ScreenRotation';
import store from '../../redux/store';
import {
    allowDrawing,
    changeCallState,
    changeTool,
    endLesson,
    hideSpinner,
    lessonInfo,
    loadLessonPages,
    mute,
    showError,
    showInfo,
    showSpinner,
    showStaticPageNavigator,
    switchLessonPage,
    updateLatexText,
    updateUserOpenJoinRequests,
    upgradeSubscriptionDialog,
    userJoinRequest,
} from '../../redux/actions';
import { withRouter } from 'react-router';
import { Paper } from '@material-ui/core';
import socketUtils from './socketUtils';
import audioCallUtils from './audioCallUtilsNoQueue';
import ToolPickerFloat from '../../MyComponents/Drawer/Tools/ToolPickerFloat';
import classApi from '../../Api/classApi';
import MicBox from '../../MyComponents/Drawer/Tools/MicBox';
import Typography from '@material-ui/core/Typography';
import lessonList, { getItem, updateItem } from './lessonList';
import { LINEAGE_NONE } from './drawConstants';
import ImageBox from '../../ImageBox/ImageBox';
import boardManager from './boardManager';
import MultiBoardComponent from './MultiBoardComponent';
import { addToWindowCache, getUrlFromWindowCache, hexToRgb, sleep } from '../../common/utils';
import StickyElements from './StickyElements';
import storageApi from '../../Api/storageApi';
import { v4 as uuidv4 } from 'uuid';
import { GA_EVENT_TREE } from '../../gaConstants';
import collectionsApi from '../../Api/collectionsApi';
import ActionButtons from '../../MyComponents/Drawer/Tools/ActionButtons';
import UsersButton from '../../MyComponents/Drawer/Tools/UsersButton';
import VBIcon from '../../MyComponents/icons/VBIcon';
import cloudBoardManager from './cloudBoardManager';
import { CAPABILITIES, hasBooleanPermission } from '../../shared/Capabilities';
import apiUtil from '../../Api/apiUtil';
import RulerComponent from '../../MyComponents/GeometryTools/RulerComponent';
import ProtractorComponent from '../../MyComponents/GeometryTools/ProtractorComponent';
import CompassComponent from '../../MyComponents/GeometryTools/CompassComponent';
// import * as iink from 'iink-js';

// const SKIP_FOR_SMOOTH_FACTOR = 0;

// const PAGE_SAVE_INTERVAL_SECS = 5;

// const LINEAGE_DEBOUNCE_INTERVAL_MS = 250;

// const ZOOM_DEBOUNCE_MS = 100;

// const SEND_IMAGE_DATA_DEBOUNCE_MS = 1000;

const MISSED_MESSAGE_RETRY_INTERVAL = 100;

const SEND_JOIN_IMAGE_DATA_DEBOUNCE_MS = 2000;

// const JOIN_IGNORE_BUFFER_MS = 3000;

const LINE_FLUSH_INTERVAL_MS = 1000;
const LOCAL_LINE_FLUSH_INTERVAL_MS = 50;

const SCROLL_INTO_VIEW_CHECK_INTERVAL = 1000;

const BEZIER_INTERVAL_DIVIDER = 2;

const LASER_POINTER_COLOR = '#D26828';

const noSelectStyle = {
    WebkitTouchCallout: 'none',
    WebkitUserSelect: 'none',
    KhtmlUserSelect: 'none',
    MozUserSelect: 'none',
    userSelect: 'none',
};

const multiBoardContainer = {
    display: 'none',
    flexDirection: 'row',
    flexWrap: 'wrap',
    verticalAlign: 'top',
    width: '100%',
    height: 'auto',
    position: 'relative',
    overflow: 'auto',
    // background: 'white',
    // justifyContent: 'center',
};

// const textStyle = {
//     margin: '1px',
//     position: 'absolute',
//     width: 'auto',
//     fontFamily: 'Arial',
//     display: 'none',
//     overflow: 'hidden',
// };

const canvasContainer = {
    display: 'inline-block',
    verticalAlign: 'top',
    width: '100%',
    height: 'auto',
    overflow: 'hidden',
    position: 'relative',
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
};

const singleLineStyle = {
    margin: '0px',
    position: 'absolute',
    border: 'none',
    width: '100px',
    height: '0px',
    display: 'none',
};

const rectangleStyle = {
    margin: '0px',
    position: 'absolute',
    width: '100px',
    height: '100px',
    border: 'solid',
    display: 'none',
};

const circleStyle = {
    margin: '0px',
    position: 'absolute',
    width: '100px',
    height: '100px',
    border: 'solid',
    display: 'none',
    borderRadius: '50%',
};

const localVideo = {
    // width: '100%',
    height: '100%',
    // border: 'solid',
    display: 'none',
    borderRadius: '1rem',
};

const remoteVideoList = {
    // top: '10%',
    // width: '100%',
    position: 'fixed',
    bottom: '36px',
    left: '48px',
    maxWidth: '75vw',
    // width: '70vw',
    height: '15vh',
    display: 'none',
    overflowX: 'scroll',
    overflowY: 'hidden',
};

// const remoteVideoContainer = {
//     position: 'absolute',
//     bottom: '10px',
//     right: '10px',
//     display: 'none',
// };

const eraserOverlay = {
    height: '0px',
    width: '0px',
    backgroundColor: '#bbb',
    borderRadius: '50%',
    display: 'none',
    position: 'absolute',
};

let missedMessagesCount = 0;

function setContainerCursor(container1, container2, cursor) {
    if (container1) {
        container1.style.cursor = cursor;
    }
    if (container2) {
        container2.style.cursor = cursor;
    }
}

class LessonPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isOnline: null };
        this.isDrawing = false;
        this.isWriting = false;
        this.ignoreBoardMoveAndClick = false;
        this.x = 0;
        this.y = 0;
        this.ongoingTouches = [];
        this.startPoint = { x: 0, y: 0 };
        this.shapePoints = [];
        this.classInfo = null;
        this.isInitialised = false;
        this.lastHorizValue = 0;
        this.lastVertValue = 100;
        // this.currentLiniage = LINEAGE_NONE;
        // this.lineageDebouncer = null;
        // this.zoomDebouncer = null;
        this.sendImageDataDebouncer = null;
        this.sendJoinImageDataDebouncer = null;
        this.joinImageEmails = [];
        this.drawLineDebouncer = null;
        this.localIntervalCount = 0;
        this.localPointArray = [];
        this.localMathConvertingArray = [];
        this.localCurrentDrawToFlush = null;
        this.flushLocalDrawIntervalFunc = null;
        this.intervalCount = 0;
        this.pointArray = [];
        this.currentDrawToFlush = null;
        this.flushPenIntervalFunc = null;
        this.allowDrawing = false;
        this.scrollIntoViewInterval = null;

        this.stickyComponent = React.createRef();
        this.singleImageBox = React.createRef();
        this.multiImageBox = React.createRef();
        this.textAreaBoxRef = React.createRef();
        this.multiTextAreaBoxRef = React.createRef();
        this.rulerElement = React.createRef();
        this.protractorElement = React.createRef();
        this.compassElement = React.createRef();

        this.lastComputedSvgInput = 0;
    }

    switchToStickySelection = (value = true) => {
        this.ignoreBoardMoveAndClick = value;
        if (!boardManager.lessonState) return;
        if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
            if (this.stickyComponent.current) {
                this.stickyComponent.current.setSelectable(value);
            }
        } else {
            if (
                boardManager.multiBoardRefs[boardManager.currentBoardActive] &&
                boardManager.multiBoardRefs[boardManager.currentBoardActive].current
            ) {
                boardManager.multiBoardRefs[boardManager.currentBoardActive].current.setSelectable(value);
            }
        }
    };

    switchToTextSelection = (value = true) => {
        // this.ignoreBoardMoveAndClick = value;
        if (!boardManager.lessonState) return;
        if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
            if (this.stickyComponent.current) {
                this.stickyComponent.current.setIsTextSelectable(value);
            }
        } else {
            if (
                boardManager.multiBoardRefs[boardManager.currentBoardActive] &&
                boardManager.multiBoardRefs[boardManager.currentBoardActive].current
            ) {
                boardManager.multiBoardRefs[boardManager.currentBoardActive].current.setIsTextSelectable(value);
            }
        }
    };
    debounceSendJoinImageData = (email) => {
        // console.log('debouncing --- ', email);

        if (!this.joinImageEmails.includes(email)) {
            this.joinImageEmails.push(email);
        }
        if (boardManager.isAdmin && boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
            // TODO check users are present before doing stuff

            if (!boardManager.mainBoard.isCurrentPageDirty && boardManager.getCurrentMainBoard()) {
                // console.log('=== should be sending join data', this.joinImageEmails);
                socketUtils.sendJoinImageData(
                    boardManager.lessonState.mainBoardId,
                    boardManager.lessonState.mainBoardPages,
                    this.joinImageEmails,
                );
                this.joinImageEmails = [];
                return;
            }
        }

        if (!this.sendJoinImageDataDebouncer) {
            this.sendJoinImageDataDebouncer = setTimeout(() => {
                if (!boardManager.getCurrentMainBoard()) return;
                if (!boardManager.mainBoard.isCurrentPageDirty) {
                    socketUtils.sendJoinImageData(
                        boardManager.lessonState.mainBoardId,
                        boardManager.lessonState.mainBoardPages,
                        this.joinImageEmails,
                    );
                    this.joinImageEmails = [];
                } else {
                    cloudBoardManager.saveCurrentMainPage().then(() => {
                        socketUtils.sendJoinImageData(
                            boardManager.lessonState.mainBoardId,
                            boardManager.lessonState.mainBoardPages,
                            this.joinImageEmails,
                        );
                        this.joinImageEmails = [];
                    });
                }
            }, SEND_JOIN_IMAGE_DATA_DEBOUNCE_MS);
        }
    };

    moveHoriz = (value) => {
        let rect = drawUtils.getCanvas();
        if (!rect) return;
        let decremental = (window.zoomState.zoomLevel * 50 + 100) / 100;
        let newValue = (parseInt(value) * (rect.width - rect.width / decremental)) / 100;
        this.lastHorizValue = value;
        moveUtils.zoomFunc(this);

        this.startPoint = {
            x: newValue,
            y: this.startPoint.y,
        };
        this.rulerElement.current?.updateScale(this.startPoint);
        this.protractorElement.current?.updateScale(this.startPoint);
        this.compassElement.current?.updateScale(this.startPoint);
        this.stickyComponent.current.updateGeometryState({
            compass: this.compassElement.current.getState(),
            protractor: this.protractorElement.current.getState(),
            ruler: this.rulerElement.current.getState(),
        });
        // this.handleTextInputMove();
        if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
            this.stickyComponent.current.updateStartPoint(this.startPoint);
            setTimeout(() => {
                if (this.stickyComponent.current) {
                    this.stickyComponent.current.updateGeometryState({
                        compass: this.compassElement.current.getState(),
                        protractor: this.protractorElement.current.getState(),
                        ruler: this.rulerElement.current.getState(),
                    });
                }
            }, 200);
        } else {
            if (!boardManager.currentBoardActive) return;
            if (boardManager.multiBoardRefs[boardManager.currentBoardActive].current) {
                boardManager.multiBoardRefs[boardManager.currentBoardActive].current.updateStartPoint(this.startPoint);
            } else {
                setTimeout(() => {
                    if (boardManager.multiBoardRefs[boardManager.currentBoardActive].current) {
                        boardManager.multiBoardRefs[boardManager.currentBoardActive].current.updateStartPoint(
                            this.startPoint,
                        );
                    }
                }, 200);
            }
        }
    };
    moveVert = (value) => {
        let rect = drawUtils.getCanvas();
        if (!rect) return;
        let decremental = (window.zoomState.zoomLevel * 50 + 100) / 100;
        let newValue = ((100 - parseInt(value)) * (rect.height - rect.height / decremental)) / 100;
        this.startPoint = {
            x: this.startPoint.x,
            y: newValue,
        };
        this.lastVertValue = value;
        moveUtils.zoomFunc(this);
        this.rulerElement?.current?.updateScale(this.startPoint);
        this.protractorElement?.current?.updateScale(this.startPoint);
        this.compassElement?.current?.updateScale(this.startPoint);
        this.stickyComponent.current.updateGeometryState({
            compass: this.compassElement.current.getState(),
            protractor: this.protractorElement.current.getState(),
            ruler: this.rulerElement.current.getState(),
        });
        // this.handleTextInputMove();
        if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
            this.stickyComponent.current.updateStartPoint(this.startPoint);
            setTimeout(() => {
                this.stickyComponent.current.updateGeometryState({
                    compass: this.compassElement.current.getState(),
                    protractor: this.protractorElement.current.getState(),
                    ruler: this.rulerElement.current.getState(),
                });
            }, 200);
        } else {
            if (boardManager.multiBoardRefs[boardManager.currentBoardActive].current) {
                boardManager.multiBoardRefs[boardManager.currentBoardActive].current.updateStartPoint(this.startPoint);
            }
        }
    };

    handlePointerLogic = (e, mouseHandler, touchHandler) => {
        switch (e.pointerType) {
            case 'mouse':
                mouseHandler(e);
                break;
            case 'pen':
                mouseHandler(e);
                break;
            case 'touch':
                if (touchHandler) {
                    touchHandler(e);
                }
                break;
            default:
                mouseHandler(e);
        }
    };

    moveZoomer = (event) => {
        // if (this.ignoreBoardMoveAndClick) {
        //     return;
        // }
        // if (moveUtils.getIsTouchEnabled()) {
        //     return;
        // }
        event.preventDefault();
        moveUtils.moveZoomer(event, this);
    };

    windowMouseDown = (event) => {
        moveUtils.setMouseOrPen(true);
        // if (moveUtils.getIsTouchEnabled()) {
        //     return;
        // }
        // console.log('==== triggered the mouse down');
        if (
            this.ignoreBoardMoveAndClick ||
            (this.isWriting && drawUtils.isOverElement(event, this.textAreaBoxRef.current.getElement())) ||
            (this.isWriting && drawUtils.isOverElement(event, this.multiTextAreaBoxRef.current.getElement()))
        ) {
            // console.log('=== got to the ignore short');
            return;
        }
        event.preventDefault();
        moveUtils.windowMouseDown(event, this);
    };

    windowMouseUp = (event) => {
        // if (moveUtils.getIsTouchEnabled()) {
        //     return;
        // }

        moveUtils.setMouseOrPen(false);
        if (
            this.ignoreBoardMoveAndClick ||
            (this.isWriting && drawUtils.isOverElement(event, this.textAreaBoxRef.current.getElement())) ||
            (this.isWriting && drawUtils.isOverElement(event, this.multiTextAreaBoxRef.current.getElement()))
        ) {
            return;
        }
        event.preventDefault();
        moveUtils.windowMouseUp(event, this);
    };

    handleTouchEnd = (event) => {
        event.preventDefault();
        if (
            this.ignoreBoardMoveAndClick ||
            (this.isWriting && drawUtils.isOverElement(event, this.textAreaBoxRef.current.getElement())) ||
            (this.isWriting && drawUtils.isOverElement(event, this.multiTextAreaBoxRef.current.getElement()))
        ) {
            return;
        }
        moveUtils.handleTouchEnd(event, this);
    };

    handleMouseLeave = (event) => {
        // if (moveUtils.getIsTouchEnabled()) {
        //     return;
        // }

        if (
            this.ignoreBoardMoveAndClick ||
            (this.isWriting && drawUtils.isOverElement(event, this.textAreaBoxRef.current.getElement())) ||
            (this.isWriting && drawUtils.isOverElement(event, this.multiTextAreaBoxRef.current.getElement()))
        ) {
            return;
        }
        event.preventDefault();
        moveUtils.handleMouseLeave(event, this);
    };

    reactToZoomChange = () => {
        moveUtils.reactToZoomChange(this, false);
        this.moveHoriz(this.lastHorizValue, false);
        this.moveVert(this.lastVertValue);
        if (this.isWriting) {
            this.handleTextClose();
        }
        if (this.props.drawing.tool === TOOL_ERASER || this.props.drawing.tool === TOOL_BRUSH) {
            this.showEraserOverlay();
        }
        let board = boardManager.getCurrentMainBoard();
        if (!board) return;

        board.meta.stickyElements = this.getCurrentStickyElements();
        this.shallowStickyElementSync();
        this.rulerElement?.current?.updateScale(this.startPoint);
        this.protractorElement?.current?.updateScale(this.startPoint);
        this.compassElement?.current?.updateScale(this.startPoint);
        this.stickyComponent.current.updateGeometryState({
            compass: this.compassElement.current.getState(),
            protractor: this.protractorElement.current.getState(),
            ruler: this.rulerElement.current.getState(),
        });

        setTimeout(() => {
            if (this.stickyComponent.current) {
                this.stickyComponent.current.updateGeometryState({
                    compass: this.compassElement.current.getState(),
                    protractor: this.protractorElement.current.getState(),
                    ruler: this.rulerElement.current.getState(),
                });
            }
        }, 200);
    };

    showTextBox = (value) => {
        let textDiv = document.getElementById('inputText');
        // textDiv.style.top = (this.rect.top + this.rect.height + 30) + "px";
        // textDiv.style.left = (this.rect.left) + "px";
        // document.getElementById("inputText").style.width = (this.rect.width / 3) + "px";
        // textDiv.style.height =
        //     (window.zoomState.zoomLevel + 1) * drawUtils.computeFontPixel(this.props.drawing.textWeight) + 'px';
        // textDiv.style.display = "block";
        let decremental = (window.zoomState.zoomLevel * 50 + 100) / 100;
        let scaleFactor = drawUtils.getCurrentCanvasRect().width / drawUtils.getCanvas().width;

        textDiv.style.fontSize =
            scaleFactor * decremental * drawUtils.computeFontPixel(this.props.drawing.textWeight) + 'px';
        textDiv.innerHTML = value;
    };

    showEraserOverlay = (weight) => {
        let eraserDiv = boardManager.getCurrentEraserOverlay();
        let width = drawUtils.computeEraserWidthForDisplay(weight ? weight : this.props.drawing.eraserWeight);

        // let scaleFactor = drawUtils.getCurrentCanvasRect().width / drawUtils.getCanvas().width;

        // eraserDiv.style.height = width * scaleFactor + 'px';
        // eraserDiv.style.width = width * scaleFactor + 'px';
        eraserDiv.style.height = width + 'px';
        eraserDiv.style.width = width + 'px';
        eraserDiv.style.display = 'block';

        // console.log('====== eraser width for display', width);
    };
    hideEraserOverlay = () => {
        let eraserDiv = boardManager.getCurrentEraserOverlay();
        if (eraserDiv) {
            eraserDiv.style.display = 'none';
        }
    };

    updateMainGeometry = (data) => {
        if (!boardManager.lessonState) return;
        if (this.protractorElement?.current) {
            this.protractorElement.current.updateState(data.protractor);
        }
        if (this.rulerElement?.current) {
            this.rulerElement.current.updateState(data.ruler);
        }
        if (this.compassElement?.current) {
            this.compassElement.current.updateState(data.compass);
        }
        if (
            boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD &&
            this.stickyComponent.current &&
            this.allowDrawing
        ) {
            this.stickyComponent.current.updateGeometryState({
                compass: data.compass,
                protractor: data.protractor,
                ruler: data.ruler,
            });
        }
    };

    setPointerForTool = (tool) => {
        let container1 = document.getElementById('canvasContainer');
        let container2 = document.getElementById('multiBoardContainer');
        switch (tool) {
            case TOOL_TEXT:
                setContainerCursor(container1, container2, 'text');
                break;

            case TOOL_PEN:
                setContainerCursor(container1, container2, 'url(/Pencil.svg) 0 24, auto');
                break;

            case TOOL_LINE:
                setContainerCursor(container1, container2, 'crosshair');
                break;

            case TOOL_CIRCLE:
                setContainerCursor(container1, container2, 'crosshair');
                break;

            case TOOL_RECTANGLE:
                setContainerCursor(container1, container2, 'crosshair');
                break;

            case TOOL_MATH_CONVERTER:
                setContainerCursor(container1, container2, 'url(/Pencil.svg) 0 24, auto');
                break;

            case TOOL_BRUSH:
                setContainerCursor(container1, container2, 'none');
                break;

            case TOOL_ERASER:
                setContainerCursor(container1, container2, 'none');
                break;
            case TOOL_POINTER:
                setContainerCursor(container1, container2, 'url(/Pointer.svg) 3 3, auto');
                break;

            default: {
                setContainerCursor(container1, container2, 'auto');
            }
        }
        if (!boardManager.lessonState) return;
        if (!this.allowDrawing && boardManager.getBoardType() === BOARD_TYPE.SINGLE_BOARD) {
            setContainerCursor(container1, container2, 'not-allowed');
        }

        if (
            boardManager.getBoardType() === BOARD_TYPE.MULTI_BOARD &&
            boardManager.isAdmin &&
            boardManager.currentBoardActive === null
        ) {
            setContainerCursor(container1, container2, 'auto');
        }
    };

    toolSelectionCallback = (tool, weight) => {
        //  need to wait for props to be updated before checking
        // console.log('handling selection callback', tool);

        // console.log('====', !this.allowDrawing && boardManager.getBoardType() === BOARD_TYPE.SINGLE_BOARD);
        this.setPointerForTool(tool);

        if (tool !== TOOL_TEXT) {
            this.handleTextClose();
        }
        if (tool !== TOOL_ERASER || tool !== TOOL_BRUSH) {
            this.hideEraserOverlay();
        }
        if (tool === TOOL_ERASER || tool === TOOL_BRUSH) {
            this.showEraserOverlay(weight);
        }
        if (tool !== TOOL_SELECT) {
            this.switchToStickySelection(false);
        }
        if (tool !== TOOL_TEXT) {
            this.switchToTextSelection(false);
        }
        // if (tool === TOOL_TEXT) {
        //     if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
        //         // this.textAreaBoxRef.current.updateWeightColor(this.props.drawing.textWeight, this.props.drawing.color);
        //     } else {
        //         // this.multiTextAreaBoxRef.current.updateWeightColor(
        //         //     this.props.drawing.textWeight,
        //         //     this.props.drawing.color
        //         // );
        //     }
        // }
        if (tool === TOOL_SELECT) {
            this.handleTextClose();
        }
    };

    socketStateHandling = (key) => {
        socketUtils.socketStateHandling(key, this.props.match.params.classId, this);
    };

    // updateStickyImagePosition = (id, x, y, w, h) => {
    //     lessonList.updateStickyImagePosition(
    //         boardManager.email,
    //         boardManager.classId,
    //         boardManager.mainBoard.boardId,
    //         id,
    //         x,
    //         y,
    //         w,
    //         h
    //     );
    //     this.getCurrentStickyElements(boardManager.mainBoard.boardId).then((elements) => {
    //         boardManager.mainBoard.stickyElements = elements;
    //         boardManager.sendElementOp(
    //             elements.find((el) => el.id === id),
    //             ELEMENT_OPERATIONS.UPDATE_STICKY_ELEMENT
    //         );
    //
    //         this.shallowStickyElementSync();
    //     });
    // };

    // updateStickyTextInfo = (id, text, x, y, w, h, color, weight) => {
    //     let pageNo = store.getState().lessonState.currentLessonPage;
    //     let lessonId = lessonList.getLessonId(this.props.profile.email, this.props.match.params.classId, pageNo);
    //     console.log('== update sticky text', x, y, w, h);
    //
    //     lessonList.updateStickyText(
    //         this.props.profile.email,
    //         this.props.match.params.classId,
    //         lessonId,
    //         id,
    //         text,
    //         x,
    //         y,
    //         w,
    //         h,
    //         weight,
    //         color
    //     );
    //     this.getCurrentStickyElements(boardManager.mainBoard.boardId).then((elements) => {
    //         boardManager.mainBoard.stickyElements = elements;
    //         boardManager.sendElementOp(
    //             elements.find((el) => el.id === id),
    //             ELEMENT_OPERATIONS.UPDATE_STICKY_ELEMENT
    //         );
    //         this.shallowStickyElementSync();
    //     });
    // };

    shallowStickyElementSync = () => {
        if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
            let board = boardManager.getCurrentMainBoard();
            if (!board || !board.meta.stickyElements) {
                // console.log('=== no sticky elements yo');
                window.checkIfStickyElementsToolPicker();
                return;
            }
            if (this.stickyComponent.current) {
                this.stickyComponent.current.shallowSyncPageElements(board.meta.stickyElements);
                this.stickyComponent.current.setLineage(board.meta.gridType);
                this.stickyComponent.current.updateGeometryState({
                    compass: this.compassElement.current.getState(),
                    protractor: this.protractorElement.current.getState(),
                    ruler: this.rulerElement.current.getState(),
                });
            }
        } else {
            boardManager.refreshStickyElements();
        }
        window.checkIfStickyElementsToolPicker();
    };

    // removeStickyElement = (id) => {
    //     // let pageNo = store.getState().lessonState.currentLessonPage;
    //     // let lessonId = lessonList.getLessonId(this.props.profile.email, this.props.match.params.classId, pageNo);
    //     // lessonList.removeStickyElement(this.props.profile.email, this.props.match.params.classId, lessonId, id);
    //     lessonList.removeStickyElement(boardManager.email, boardManager.classId, boardManager.mainBoard.boardId, id);
    //     this.getCurrentStickyElements(boardManager.mainBoard.boardId).then((elements) => {
    //         boardManager.mainBoard.stickyElements = elements;
    //         boardManager.sendElementOp({ id }, ELEMENT_OPERATIONS.UPDATE_STICKY_ELEMENT);
    //         this.shallowStickyElementSync();
    //         if (window.checkIfStickyElementsToolPicker) {
    //             window.checkIfStickyElementsToolPicker();
    //         }
    //     });
    // };

    initCanvasState = () => {
        // drawUtils.computeCanvas();
        drawUtils.clearCanvas();
        // drawUtils.resetSlidersAndCanvases();
        drawUtils.computeCanvas();
        drawUtils.computeSliders();
        // drawUtils.computeSliders();
        // drawUtils.computeCanvas();

        this.reactToZoomChange();
        this.isInitialised = true;
    };

    startPageFlow = () => {
        store.dispatch(showSpinner());

        // classApi.getClassPages(this.props.match.params.classId);
        let joinResponse;

        classApi
            .canIJoin(this.props.match.params.classId)
            .then((res) => {
                joinResponse = res;
                if (joinResponse.data && joinResponse.data.canIJoin) {
                    this.classInfo = joinResponse.data;

                    boardManager.classId = this.props.match.params.classId;
                    boardManager.email = this.props.profile.email;
                    boardManager.adminEmail = joinResponse.data.classData.adminEmail;

                    store.dispatch(lessonInfo(joinResponse.data));
                    // if (
                    //     (!this.classInfo.classMembers || this.classInfo.classMembers.length === 0) &&
                    //     joinResponse.data.owner
                    // ) {
                    //     store.dispatch(
                    //         shareLesson({ id: this.props.match.params.classId, name: this.classInfo.classData.name })
                    //     );
                    //     boardManager.isAdmin = true;
                    // }
                    if (
                        this.classInfo.classMembers &&
                        this.classInfo.joinRequestsData.length > 0 &&
                        this.classInfo.classMembers
                    ) {
                        store.dispatch(userJoinRequest({ classId: this.props.match.params.classId, userId: null }));
                        store.dispatch(updateUserOpenJoinRequests(this.classInfo.joinRequestsData));
                    }
                    if (joinResponse.data.owner) {
                        store.dispatch(hideSpinner());
                    } else {
                        store.dispatch(
                            showSpinner({
                                messageKey: 'lesson.teacherNotPresent.title',
                                descriptionKey: 'lesson.teacherNotPresent.description',
                                showHome: true,
                            }),
                        );
                    }
                    // if (window.isTeams && window.teamsContext && window.microsoftTeams) {
                    //     console.log('=== context', window.teamsContext);
                    //     window.microsoftTeams.conversations.openConversation({
                    //         subEntityId: '',
                    //         entityId: window.teamsContext.entityId,
                    //         channelId: window.teamsContext.channelId,
                    //         title: 'Marius Title',
                    //     });
                    //     window.microsoftTeams.shareDeepLink({
                    //         subEntityId: this.props.match.params.classId,
                    //         subEntityLabel: 'Started ' + this.classInfo.classData.name,
                    //         subEntityWebUrl: encodeURI(window.location.href),
                    //     });
                    // }
                    return Promise.resolve();
                } else {
                    console.log('======= not allowed');
                    this.props.history.push('/joinrequest/' + this.props.match.params.classId);
                    return Promise.reject('NOT_ALLOWED');
                }
            })
            .then(() => {
                // return callUtils.initLocalState(this);
                return audioCallUtils.init(this);
            })
            // .then(() => {
            //     console.log('==== in lesson state completion');
            // })
            .then(userApi.getSocketToken)
            .then((res) => {
                boardManager.initDefault();
                this.socketStateHandling(res.data);
                socketUtils.setAuthenticateCallback(() => {
                    this.startWithSocketOpened();
                });
            })
            .catch((err) => {
                if (err === 'NOT_ALLOWED') {
                    store.dispatch(hideSpinner());
                    return;
                }
                if (!err.response) {
                    console.error(err);
                    this.props.history.push('/');
                    store.dispatch(hideSpinner());
                    store.dispatch(showError('ERROR_CONNECTING_SOCKET'));
                    return;
                }
                if (err.response.status === 401) return;
                if (err.response.status === 403 && err.response.data?.availableSubscriptions) {
                    store.dispatch(
                        upgradeSubscriptionDialog({
                            key: 'subscription.upsell.groups.unavailable',
                            subscriptions: err.response.data?.availableSubscriptions,
                        }),
                    );
                    this.props.history.replace('/manageClasses');
                    return;
                }
                console.error(err);
                this.props.history.push('/');
                store.dispatch(hideSpinner());
                store.dispatch(showError('ERROR_CONNECTING_SOCKET'));
            });
    };

    convertToMath = async () => {
        if (this.localMathConvertingArray.length === 0) {
            store.dispatch(changeTool(TOOL_PEN));
            this.switchToStickySelection(false);
            this.toolSelectionCallback(TOOL_PEN);

            return;
        }
        // console.log('=== converting to math');
        let myObj = {
            configuration: {
                math: {
                    mimeTypes: ['application/svg+xml'],
                    solver: { enable: false },
                    margin: { bottom: 10, left: 15, right: 15, top: 10 },
                    eraser: { 'erase-precisely': false },
                },
                lang: 'en_US',
                export: {
                    'image-resolution': 300,
                    jiix: { 'bounding-box': false, strokes: false, text: { chars: false, words: true } },
                },
            },
            xDPI: 96,
            yDPI: 96,
            contentType: 'Math',
            theme:
                'ink {\\ncolor: #000000;\\n-myscript-pen-width: 1;\\n-myscript-pen-fill-style: none;\\n-myscript-pen-fill-color: #FFFFFF00;\\n}\\n.math {\\nfont-family: STIXGeneral;\\n}\\n.math-solved {\\nfont-family: STIXGeneral;\\ncolor: #A8A8A8FF;\\n}\\n.text {\\nfont-family: MyScriptInter;\\nfont-size: 10;\\n}\\n',
            strokeGroups: [
                {
                    penStyle: 'color: #000000;\\n-myscript-pen-width: 1;',
                    strokes: [],
                },
            ],
            height: 1440,
            width: 720,
            // height: 1920,
            // width: 960,
            conversionState: 'DIGITAL_EDIT',
        };
        let minX = drawUtils.CANVAS_ACTUAL_WIDTH,
            minY = drawUtils.CANVAS_ACTUAL_HEIGHT,
            maxX = 0,
            maxY = 0;

        this.localMathConvertingArray.forEach((el) => {
            let mylT = new Date().getTime();
            let myX = [];
            let myY = [];
            let myT = [];
            el.forEach((e, index) => {
                if (index === 0) {
                    myX.push(e.x1);
                    myY.push(e.y1);
                    myT.push(mylT);
                }
                myX.push(e.x2);
                myY.push(e.y2);
                myT.push(mylT);
                if (e.x2 < minX) {
                    minX = e.x2;
                }
                if (e.x2 > maxX) {
                    maxX = e.x2;
                }
                if (e.y2 < minY) {
                    minY = e.y2;
                }
                if (e.y2 > maxY) {
                    maxY = e.y2;
                }
            });
            myObj.strokeGroups[0].strokes.push({
                x: myX,
                y: myY,
                t: myT,
                pointerType: 'mouse',
            });
        });
        try {
            store.dispatch(showSpinner());
            let res = await apiUtil.runMyScript(myObj);
            // console.log('===== data', res.data);
            let myData = res.data;
            if (myData !== '') {
                if (parseInt(myData) == myData) {
                    // console.log('=== should convert to text');
                    await boardManager.addLocalStickyText(
                        { text: '' + myData },
                        { x: minX, y: minY },
                        { width: Math.max(maxX - minX, 20), height: Math.max(maxY - minY, 20) },
                        this.props.drawing.textWeight,
                        this.props.drawing.color,
                    );
                } else {
                    await boardManager.addLocalStickyLatex(
                        myData,
                        minX,
                        minY,
                        Math.max(maxX - minX, 20),
                        Math.max(maxY - minY, 20),
                        this.props.drawing.textWeight,
                        this.props.drawing.color,
                    );
                }
            }
            // MathJax.texReset();
            //
            // let newEl = document.createElement('div');
            // newEl.innerHTML = res.data;
            // console.log(newEl.children[0]);
            // window.MathJax.mathml2svg(newEl.children[0]);
        } catch (err) {
            console.error('Error Converting Math', err);
            store.dispatch(showError('GENERIC_ERROR'));
        }
        this.localMathConvertingArray = [];
        this.localPointArray = [];
        store.dispatch(changeTool(TOOL_SELECT));
        this.switchToStickySelection(true);
        this.toolSelectionCallback(TOOL_SELECT);
        store.dispatch(hideSpinner());
        this.clearWritingSvg();
    };

    undoStrokes = () => {
        if (this.props.drawing.tool === TOOL_MATH_CONVERTER) {
            if (this.localMathConvertingArray.length > 0) {
                this.localMathConvertingArray.pop();
                let pth = document.getElementById('main-writing-svg');
                if (pth) {
                    if (pth.childNodes.length > 1) {
                        pth.removeChild(pth.lastChild);
                    } else {
                        let newS = '';
                        pth.firstChild.setAttribute('d', newS);
                    }
                }
            }
        }
    };

    flushStrokes = () => {
        if (this.props.drawing.tool === TOOL_MATH_CONVERTER) {
            this.localMathConvertingArray.push([...this.localPointArray]);
            if (this.localIntervalCount > 0) {
                this.localCurrentDrawToFlush.points = this.localPointArray;
                this.localIntervalCount = 0;
                this.localPointArray = [];
                this.localCurrentDrawToFlush = null;
                this.updateWriteSVG(
                    {
                        tool: TOOL_PEN,
                        color: this.props.drawing.color,
                        width: drawUtils.computeLineWidth(this.props.drawing.toolWeight),
                        eraserWeight: this.props.drawing.eraserWeight,
                    },
                    this.localMathConvertingArray,
                );
            }
            return;
        }
        if (this.localIntervalCount > 0) {
            // console.log('localcount = ', this.localIntervalCount);
            this.localCurrentDrawToFlush.points = this.localPointArray;
            this.drawLineDebounced(this.localPointArray);
            // moveUtils.zoomFunc(this);
            this.localIntervalCount = 0;
            this.localPointArray = [];
            this.localCurrentDrawToFlush = null;
            if (this.props.drawing.tool === TOOL_BRUSH) {
                let width = drawUtils.computeEraserWidth(this.props.drawing.toolWeight);
                let color = this.props.drawing.color;
                let conv = hexToRgb(this.props.drawing.color);
                if (conv) {
                    color = `rgba(${conv.r}, ${conv.g}, ${conv.b}, 0.2)`;
                }
                this.updateWriteSVG({
                    tool: TOOL_PEN,
                    color: color,
                    width: width,
                    eraserWeight: this.props.drawing.eraserWeight,
                });
            } else {
                this.updateWriteSVG({
                    tool: TOOL_PEN,
                    color: this.props.drawing.color,
                    width: drawUtils.computeLineWidth(this.props.drawing.toolWeight),
                    eraserWeight: this.props.drawing.eraserWeight,
                });
            }

        }
    };

    allowMainGeometryHandling = (canWrite) => {
        this.rulerElement?.current?.setAllowWriting(canWrite);
        this.protractorElement?.current?.setAllowWriting(canWrite);
        this.compassElement?.current?.setAllowWriting(canWrite);
        if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
            if (this.allowDrawing) {
                setTimeout(() => {
                    if (this.stickyComponent.current) {
                        this.stickyComponent.current.updateGeometryState({
                            compass: this.compassElement.current.getState(),
                            protractor: this.protractorElement.current.getState(),
                            ruler: this.rulerElement.current.getState(),
                        });
                    }
                }, 100);
            } else {
                setTimeout(() => {
                    if (this.stickyComponent.current) {
                        this.stickyComponent.current.updateGeometryState({
                            compass: { ...this.compassElement.current.getState(), visible: false },
                            protractor: { ...this.protractorElement.current.getState(), visible: false },
                            ruler: { ...this.rulerElement.current.getState(), visible: false },
                        });
                    }
                }, 100);
            }
        }
    };

    displayMainGeometryHandling = (visible = true) => {
        this.rulerElement?.current?.showElement(visible, true);
        this.protractorElement?.current?.showElement(visible, true);
        this.compassElement?.current?.showElement(visible, true);
        setTimeout(() => {
            if (!this.protractorElement?.current) {
                return;
            }
            let geometry = {
                protractor: this.protractorElement.current.getState(),
                ruler: this.rulerElement.current.getState(),
                compass: this.compassElement.current.getState(),
            };
            if (
                boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD &&
                this.stickyComponent.current &&
                this.allowDrawing
            ) {
                this.stickyComponent.current.updateGeometryState(geometry);
            }

            socketUtils.updateMainGeometry(geometry);
        }, 200);
    };

    startWithSocketOpened = async () => {
        // console.log('==== starting with open socket', store.getState());
        try {
            await window.loadLatexAsync();
        } catch (ex) {
            console.error('Unable to load LaTeX', ex);
            store.dispatch(showError('GENERIC_ERROR'));
        }

        store.dispatch(mute(false));
        this.initCanvasState();
        this.flushPenIntervalFunc = setInterval(() => {
            if (this.intervalCount > 0) {
                // console.log('count = ', this.intervalCount);
                if (this.currentDrawToFlush) {
                    this.currentDrawToFlush.points = this.pointArray;
                    socketUtils.sendDraw(this.currentDrawToFlush);
                }
                this.intervalCount = 0;
                this.pointArray = [];
                this.currentDrawToFlush = null;
            }
        }, LINE_FLUSH_INTERVAL_MS);

        this.flushLocalDrawIntervalFunc = setInterval(() => {
            if (this.localIntervalCount > 0) {
                // console.log('localcount = ', this.localIntervalCount);
                this.localCurrentDrawToFlush.points = this.localPointArray;
                let drawing = this.props.drawing;
                if (drawing.tool === TOOL_PEN || drawing.tool === TOOL_MATH_CONVERTER || drawing.tool === TOOL_BRUSH) {


                    let color = drawing.color;
                    let width = drawUtils.computeLineWidth(drawing.toolWeight);

                    if (drawing.tool === TOOL_BRUSH) {
                        let conv = hexToRgb(this.props.drawing.color);
                        width = drawUtils.computeEraserWidth(drawing.eraserWeight);
                        if (conv) {
                            color = `rgba(${conv.r}, ${conv.g}, ${conv.b}, 0.2)`;
                        }
                    }

                    if (drawing.tool === TOOL_POINTER) {
                        color = LASER_POINTER_COLOR;
                    }

                    this.updateWriteSVG({
                        tool: drawing.tool,
                        color: color,
                        width: width,
                        eraserWeight: drawing.eraserWeight,
                    });
                    return;
                }
                this.drawLineDebounced(this.localPointArray);
                // moveUtils.zoomFunc(this);
                this.localIntervalCount = 0;
                this.localPointArray = [];
                this.localCurrentDrawToFlush = null;
            }
        }, LOCAL_LINE_FLUSH_INTERVAL_MS);
        // console.log('==== we should keep going !!', this.classInfo.owner, boardManager.lessonState.boardType);

        if (!this.classInfo.owner) {
            cloudBoardManager.addPagesToCache(
                boardManager.lessonState.mainBoardPages,
                boardManager.lessonState.adminEmail,
                this.props.match.params.classId,
            );
            this.allowDrawing = boardManager.lessonState.userStates[this.props.profile.email].enableDraw;
            store.dispatch(allowDrawing(this.allowDrawing));
            if (boardManager.lessonState.userStates[this.props.profile.email].isMuted) {
                audioCallUtils.mute();
            }
            if (boardManager.lessonState.boardType === BOARD_TYPE.MULTI_BOARD) {
                // console.log('==== we should keep going');
                if (
                    !boardManager.lessonState.individualBoards[boardManager.lessonState.adminEmail] ||
                    boardManager.lessonState.individualBoards[boardManager.lessonState.adminEmail].pages.length === 0
                ) {
                    // just joined a multiboard session and we do not have a board
                    await boardManager.switchToNewMultiBoardForUser();
                } else {
                    // console.log('==== switching to existing');
                    await boardManager.switchToExistingMultiBoardForUser();
                }
            } else {
                // let idx = boardManager.lessonState.mainBoardPages.findIndex(
                //     (el) => el.id === boardManager.lessonState.mainBoardId
                // );
                // if (idx < 0) idx = boardManager.lessonState.mainBoardPages.length - 1;
                // let loadSuccess = await this.loadLessonPage(idx);
                // if (!loadSuccess) {
                //     store.dispatch(showError('GENERIC_ERROR'));
                //     return;
                // }
                // store.dispatch(switchLessonPage(idx));
            }
            this.allowMainGeometryHandling(this.allowDrawing);
            window.logAppActivity(GA_EVENT_TREE.lessonPage.actions.join);
        } else {
            // INIT OPS HERE
            boardManager.isAdmin = true;
            // loadListingFromStorageInStore(this.props.profile.email, this.props.match.params.classId);
            // await getLessonSignedURLs(this.props.profile.email, this.props.match.params.classId);
            // let pages = await cloudBoardManager.getLessonPages(
            //     this.props.profile.email,
            //     this.props.match.params.classId
            // );
            cloudBoardManager.addPagesToCache(
                boardManager.lessonState.mainBoardPages,
                this.props.profile.email,
                this.props.match.params.classId,
            );
            // console.log('====', boardManager.lessonState);
            let pageIds = boardManager.lessonState.mainBoardPages.map((p) => p.id);
            store.dispatch(changeCallState(CALL_STATE.CAN_START_CALL));
            this.allowDrawing = true;
            store.dispatch(allowDrawing(this.allowDrawing));
            // boardManager.mainBoardPages = pages;

            // let lesson = lessonList.getLessonForEmail(this.props.profile.email, this.props.match.params.classId);
            // if (lesson.pages.length === 0) {
            // await lessonList.saveLesson(this.props.profile.email, this.props.match.params.classId, 0);
            // }
            // lesson = lessonList.getLessonForEmail(this.props.profile.email, this.props.match.params.classId);
            store.dispatch(loadLessonPages(pageIds));
            if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
                let idx = boardManager.getCurrentMainBoardIndex();
                if (idx < 0) idx = boardManager.lessonState.mainBoardPages.length - 1;
                let loadSuccess = await this.loadLessonPage(idx);
                if (!loadSuccess && boardManager.lessonState.mainBoardPages.length > 1) {
                    // only do this it it's the first/only page and it may not have been saved
                    // store.dispatch(showError('GENERIC_ERROR'));
                    return;
                }
                store.dispatch(switchLessonPage(idx));
                // boardManager.currentLessonPage = pageIds.length - 1;
            } else {
                await boardManager.switchToExistingMultiBoardForAdmin();
                // console.log('==== switching to multiboard admin', boardManager.delayedJoins);
                // if (boardManager.delayedJoins.length > 0) {
                //     let promises = [];
                //     for (let i = 0; i < boardManager.delayedJoins.length; i++) {
                //         promises.push(boardManager.sendMultiBoardJoinData(boardManager.delayedJoins[i]));
                //     }
                //     boardManager.delayedJoins = [];
                //     await Promise.all(promises);
                // }
            }
            window.logAppActivity(GA_EVENT_TREE.lessonPage.actions.start);
            this.allowMainGeometryHandling(this.allowDrawing);

            // this.sendImageData(true);
        }
        if (boardManager.lessonState.mainGeometry) {
            this.updateMainGeometry(boardManager.lessonState.mainGeometry);
        }
        // socketUtils.sendUserJoin({});
    };

    remoteMainPageChange = async (mainBoardId, mainBoardPages) => {
        try {
            store.dispatch(showSpinner());
            boardManager.lessonState.mainBoardPages = mainBoardPages;
            boardManager.lessonState.mainBoardId = mainBoardId;
            cloudBoardManager.addPagesToCache(mainBoardPages, boardManager.adminEmail, boardManager.classId);
            let idx = boardManager.getCurrentMainBoardIndex();
            let pageIds = mainBoardPages.map((p) => p.id);
            await this.asyncClearImage();
            store.dispatch(loadLessonPages(pageIds));
            store.dispatch(switchLessonPage(idx + 1));
            // boardManager.currentLessonPage = currentLessonNo + 1;
            // boardManager.setLineage(LINEAGE_NONE);
            // boardManager.mainBoard.boardId = boardManager.mainBoardPages[currentLessonNo + 1].id;
            await this.loadLessonPage(idx);
            // await cloudBoardManager.saveCurrentMainPage();
            store.dispatch(hideSpinner());
            return true;
        } catch (err) {
            console.error('Problem at creating new page', err);
        }
    };

    newPage = async () => {
        try {
            store.dispatch(showSpinner());
            let currentLessonNo = store.getState().lessonState.currentLessonPage;
            await cloudBoardManager.saveCurrentMainPage();
            let pages = await cloudBoardManager.newPage(boardManager.email, boardManager.classId);
            boardManager.lessonState.mainBoardPages = pages;
            boardManager.lessonState.mainBoardId = pages[pages.length - 1].id;
            let pageIds = pages.map((p) => p.id);
            await this.asyncClearImage();
            store.dispatch(loadLessonPages(pageIds));
            store.dispatch(switchLessonPage(currentLessonNo + 1));
            boardManager.currentLessonPage = currentLessonNo + 1;
            // boardManager.setLineage(LINEAGE_NONE);
            // boardManager.mainBoard.boardId = boardManager.mainBoardPages[currentLessonNo + 1].id;
            boardManager.mainBoard.isCurrentPageDirty = true;

            await cloudBoardManager.saveCurrentMainPage();
            await this.loadLessonPage(currentLessonNo + 1);
            if (boardManager.lessonState) {
                socketUtils.changeMainBoardState(
                    boardManager.lessonState.mainBoardId,
                    boardManager.lessonState.mainBoardPages,
                );
            }
            // await cloudBoardManager.saveCurrentMainPage();
            store.dispatch(hideSpinner());
            return true;
        } catch (err) {
            console.error('Problem at creating new page', err);
        }
    };

    switchPage = async (pageNo) => {
        try {
            store.dispatch(showSpinner());
            await cloudBoardManager.saveCurrentMainPage();
            let loadSuccess = await this.loadLessonPage(pageNo);
            if (!loadSuccess) {
                // store.dispatch(showError('GENERIC_ERROR'));
                // return;
            }
            if (boardManager.lessonState) {
                socketUtils.changeMainBoardState(
                    boardManager.lessonState.mainBoardId,
                    boardManager.lessonState.mainBoardPages,
                );
            }

            store.dispatch(switchLessonPage(pageNo));
            // boardManager.currentLessonPage = pageNo;
        } catch (err) {
            console.error('Problem at creating new page', err);
            store.dispatch(hideSpinner());
        }
    };

    removePage = async (pageNo) => {
        let currentLessonNo = store.getState().lessonState.currentLessonPage;
        // lessonList.removeLessonPage(this.props.profile.email, this.props.match.params.classId, pageNo);
        // let lesson = lessonList.getLessonForEmail(this.props.profile.email, this.props.match.params.classId);
        // store.dispatch(loadLessonPages(lesson.pages));
        store.dispatch(showSpinner());
        let pages = await cloudBoardManager.removePage(
            boardManager.email,
            boardManager.classId,
            boardManager.lessonState.mainBoardPages[pageNo].id,
        );
        boardManager.lessonState.mainBoardPages = pages;
        let pageIds = pages.map((el) => el.id);
        store.dispatch(loadLessonPages(pageIds));

        if (pageNo === currentLessonNo) {
            if (pages.length === 0) {
                this.clearImage();
                pages = await cloudBoardManager.newPage(boardManager.email, boardManager.classId);
                boardManager.lessonState.mainBoardPages = pages;
                store.dispatch(switchLessonPage(0));
                boardManager.currentLessonPage = 0;
                await this.loadLessonPage(0);
                // this.currentLiniage = LINEAGE_NONE;
            } else {
                store.dispatch(switchLessonPage(0));
                store.dispatch(switchLessonPage(pageIds.length - 1));
                boardManager.currentLessonPage = pageIds.length - 1;
                boardManager.lessonState.mainBoardPages = pages;
                let loadSuccess = await this.loadLessonPage(pageIds.length - 1);
                if (!loadSuccess) {
                    // store.dispatch(showError('GENERIC_ERROR'));
                    // return;
                }
            }
        }
        socketUtils.changeMainBoardState(boardManager.lessonState.mainBoardId, boardManager.lessonState.mainBoardPages);
        store.dispatch(hideSpinner());
    };

    saveCurrentLineage = (lineage, pageNo = null) => {
        let currentLessonNo = pageNo;
        if (!currentLessonNo) {
            currentLessonNo = store.getState().lessonState.currentLessonPage;
        }
        boardManager.lessonState.mainBoardPages[currentLessonNo].meta.gridType = lineage;
        // let lessonId = lessonList.getLessonId(
        //     this.props.profile.email,
        //     this.props.match.params.classId,
        //     currentLessonNo
        // );
        // window.localStorage.setItem(lessonId + '-type', lineage);
    };

    getCurrentStickyElements = () => {
        if (!boardManager.lessonState) return [];
        let idx = boardManager.getCurrentMainBoardIndex();
        return boardManager.lessonState.mainBoardPages[idx].meta.stickyElements;
    };

    loadLessonPage = async (pageNo) => {
        if (!this.stickyComponent.current) {
            return false;
        }
        let lessonId = boardManager.lessonState.mainBoardPages[pageNo].id;
        boardManager.lessonState.mainBoardId = lessonId;
        let stickyElements = boardManager.lessonState.mainBoardPages[pageNo].meta.stickyElements;
        this.stickyComponent.current.shallowSyncPageElements(stickyElements);
        let lessonLineage = boardManager.lessonState.mainBoardPages[pageNo].meta.gridType;
        this.stickyComponent.current.setLineage(lessonLineage);
        // if (lessonLineage && lessonLineage !== LINEAGE_NONE) {
        //     boardManager.setLineage(lessonLineage);
        // } else {
        //     boardManager.setLineage(LINEAGE_NONE);
        //     this.stickyComponent.current.setLineage(lessonLineage);
        // }

        let url = getUrlFromWindowCache(boardManager.adminEmail, boardManager.classId, lessonId, lessonId);
        if (lessonId && url) {
            let img = await drawUtils.buildImageFromUrlNoCache(url);
            if (img) {
                // console.log('=== got image');
                try {
                    boardManager.mainBoard.isBusy = true;
                    let ctx = drawUtils.getCanvas().getContext('2d');
                    ctx.globalCompositeOperation = 'source-over';
                    ctx.clearRect(0, 0, drawUtils.CANVAS_ACTUAL_WIDTH, drawUtils.CANVAS_ACTUAL_HEIGHT);
                    await sleep(200);
                    ctx.imageSmoothingEnabled = true;
                    ctx.imageSmoothingQuality = 'high';
                    ctx.drawImage(img, 0, 0, drawUtils.CANVAS_ACTUAL_WIDTH, drawUtils.CANVAS_ACTUAL_HEIGHT);
                    await sleep(200);
                    boardManager.mainBoard.isBusy = false;
                } catch (ex) {
                    console.error('Unable to load image', url, ex);
                    store.dispatch(hideSpinner(false));
                    return false;
                }
                // await sleep(200);
                // ctx.drawImage(img, 0, 0, drawUtils.CANVAS_ACTUAL_WIDTH, drawUtils.CANVAS_ACTUAL_HEIGHT);

                if (window.checkIfStickyElementsToolPicker) {
                    window.checkIfStickyElementsToolPicker();
                }
            } else {
                console.error('no image retrieved', url);
                store.dispatch(hideSpinner(false));
                return false;
                // store.dispatch(showError('GENERIC_ERROR'));
                // setTimeout(() => {
                //     window.location.reload();
                // }, 500);
            }
        } else {
            store.dispatch(hideSpinner(false));
            return false;
        }
        store.dispatch(hideSpinner(false));
        return true;
    };

    loadFullImage = (data, type) => {
        // let lessonLineage = lessonList.getLessonId(this.props.profile.email, this.props.match.params.classId, pageNo);
        this.clearImage();
        let img = new Image();
        let ctx = drawUtils.getCanvas().getContext('2d');
        ctx.globalCompositeOperation = 'source-over';
        img.onload = () => {
            ctx.imageSmoothingEnabled = true;
            ctx.imageSmoothingQuality = 'high';
            ctx.drawImage(
                img,
                0,
                0,
                drawUtils.CANVAS_ACTUAL_WIDTH,
                drawUtils.CANVAS_ACTUAL_HEIGHT,
                0,
                0,
                drawUtils.CANVAS_ACTUAL_WIDTH,
                drawUtils.CANVAS_ACTUAL_HEIGHT,
            );
            boardManager.mainBoard.isCurrentPageDirty = true;
            cloudBoardManager.saveCurrentMainPage().then(() => {
                this.sendImageData(true);
            });
        };
        img.src = data;
        if (type) {
            // TODO: figure out what to do here
            // boardManager.setLineage(type);
            // this.currentLiniage = lessonLineage;
            // if (type && type !== LINEAGE_NONE) {
            //     boardManager.setLineage(type);
            //     // this.currentLiniage = lessonLineage;
            //     boardManager.debounceCurrentLineage();
            //     // this.debounceLineage();
            // }
        } else {
            // boardManager.debounceCurrentLineage();
        }
    };

    // handleTextInputExpand = () => {
    //     let input = document.getElementById('textInput');
    //     let inputRect = input.getBoundingClientRect();
    //     let expanderRect = document.getElementById('textExpander').getBoundingClientRect();
    //     input.style.width = expanderRect.left - inputRect.left + 'px';
    //     input.style.height = expanderRect.top - inputRect.top + 'px';
    //     drawUtils.repositionTextInputMover();
    // };

    // handleTextInputMove = () => {
    //     if (this.isWriting) {
    //         let input = document.getElementById('textInputContainer');
    //         let inputRect = input.getBoundingClientRect();
    //         let moverRect = document.getElementById('textMover').getBoundingClientRect();
    //         input.style.top = 24 + moverRect.top + 'px';
    //         input.style.left = moverRect.left + 12 - inputRect.width / 2 + 'px';
    //         inputRect = input.getBoundingClientRect();
    //         drawUtils.repositionTextInputExpander();
    //         let rect = drawUtils.getCurrentCanvasRect();
    //         this.scaledDownCoords = drawUtils.scalePoint(
    //             this.startPoint,
    //             inputRect.left - rect.left,
    //             inputRect.top - rect.top
    //         );
    //     }
    // };

    handleImageUpdate = (image, x, y, width, height) => {
        // console.log('===== handling image update');
        if (image) {
            let ctx = drawUtils.getCanvas().getContext('2d');
            let scaledSize = drawUtils.scalePoint(this.startPoint, x, y);
            let scaledWidth = drawUtils.scalePoint(this.startPoint, x + width, y + height);
            ctx.globalCompositeOperation = 'source-over';

            ctx.imageSmoothingEnabled = true;
            ctx.imageSmoothingQuality = 'high';
            ctx.drawImage(
                image,
                scaledSize.x,
                scaledSize.y,
                Math.abs(scaledWidth.x - scaledSize.x),
                Math.abs(scaledWidth.y - scaledSize.y),
            );
            boardManager.setDirtyFlag();
            // this.isCurrentPageDirty = true;
            // moveUtils.zoomFunc(this);
            // boardManager.debounceCurrentLineage();
            // this.debounceLineage();
            socketUtils.sendDraw({
                tool: TOOL_IMAGE_PASTE,
                x1: scaledSize.x,
                y1: scaledSize.y,
                x2: scaledWidth.x,
                y2: scaledWidth.y,
                image: image.src,
            });
        }
        this.ignoreBoardMoveAndClick = false;
    };

    addStickyImage = async (image, x, y, width, height) => {
        this.ignoreBoardMoveAndClick = false;
        return boardManager.addLocalStickyImage(image, x, y, width, height);
    };

    showRuler = () => {
        if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
            this.rulerElement?.current?.showElement();
            this.rulerElement?.current?.updateScale(this.startPoint);
        }
    };

    showProtractor = () => {
        if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
            this.protractorElement?.current?.showElement();
            this.protractorElement?.current?.updateScale(this.startPoint);
        }
    };

    showCompass = () => {
        if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
            this.compassElement?.current?.showElement();
            this.compassElement?.current?.updateScale(this.startPoint);
            if (this.allowDrawing) {
                setTimeout(() => {
                    this.stickyComponent.current.updateGeometryState({
                        compass: this.compassElement.current.getState(),
                        protractor: this.protractorElement.current.getState(),
                        ruler: this.rulerElement.current.getState(),
                    });
                }, 100);
            }
        }
    };

    componentDidMount() {
        store.dispatch(allowDrawing(this.allowDrawing));
        store.dispatch(changeCallState(CALL_STATE.CALL_DISABLED));
        if (this.scrollIntoViewInterval) {
            clearInterval(this.scrollIntoViewInterval);
        }
        this.scrollIntoViewInterval = setInterval(() => {
            if (window.pageYOffset > 0) {
                if (boardManager.lessonState.boardType === BOARD_TYPE.MULTI_BOARD && !boardManager.currentBoardActive)
                    return;
                if (this.isWriting) return;
                document.body.scrollIntoView(true);
                // console.log('scrolled into view');
            }
        }, SCROLL_INTO_VIEW_CHECK_INTERVAL);
        let allGood = drawUtils.validateHorizontalPosition();
        if (allGood) {
            this.startPageFlow();
        }
        window.onresize = () => {
            drawUtils.handleResize(this);
        };

        // this.didImageLoad = false;

        this.startPoint = {
            x: 0,
            y: 0,
        };

        // this.isCurrentPageDirty = false;

        window.zoomState.zoomCallbacks['lessonPage'] = this.reactToZoomChange;
        window.toolSelectionCallback = this.toolSelectionCallback;
        window.updateUserDrawingCallback = this.updateUserDrawing;
        // window.triggerUploadCallback = this.loadImage;
        window.triggerClearImageCallback = this.clearImageHandler;
        window.triggerSaveImageCallback = this.saveImage;
        window.loadMultiBoardInLesson = this.loadMultiBoardInLesson;
        window.loadCollectionInLesson = this.loadCollectionInLesson;
        // window.buildTip1 = this.buildTip1;
        // window.buildTip2 = this.buildTip2;
        // window.buildDictando = this.buildDictando;
        // window.buildMath = this.buildMath;
        // window.buildMusic = this.buildMusic;
        window.newPage = this.newPage;
        window.switchPage = this.switchPage;
        window.removePage = this.removePage;
        window.handleCroppedImage = this.handleCroppedImage;
        window.switchToStickySelection = this.switchToStickySelection;
        window.switchToTextSelection = this.switchToTextSelection;
        window.addToCollection = this.addToCollection;
        window.addAllToCollection = this.addAllToCollection;
        window.updateToCollection = this.updateToCollection;
        window.convertToMath = this.convertToMath;
        window.undoStrokes = this.undoStrokes;
        // ===== TOOLS ===
        window.showRuler = this.showRuler;
        window.showProtractor = this.showProtractor;
        window.showCompass = this.showCompass;

        // window['handleTextInputExpand'] = this.handleTextInputExpand;
        // window['handleTextInputMove'] = this.handleTextInputMove;
        // window['handleImageUpdate'] = this.handleImageUpdate;
        window.loadFullImage = this.loadFullImage;
        boardManager.updateLessonComponent(this);
        window.hideOsano();

        document.onpaste = (evt) => {
            const dT = evt.clipboardData || window.clipboardData;
            if (dT.files && dT.files.length > 0) {
                this.handleTextClose();
                const file = dT.files[0];
                let reader = new FileReader();
                reader.onload = function(event) {
                    if (event && event.target && event.target.result) {
                        let img = new Image();
                        img.onload = () => {
                            if (window.handleCroppedImage) {
                                window.handleCroppedImage(event.target.result, img.width, img.height);
                                window.logAppActivity(GA_EVENT_TREE.lessonPage.toolSelection.imagePaste);
                            }
                        };
                        img.src = event.target.result;
                    }
                };
                reader.readAsDataURL(file);
            }
        };

        let canvas = document.getElementById('canvas');
        canvas.addEventListener('touchstart', this.handleTouchStart);
        canvas.addEventListener('touchend', this.handleTouchEnd);
        canvas.addEventListener('touchmove', this.handleTouchMove);
        canvas.addEventListener('touchcancel', this.handleMouseLeave);
        canvas.addEventListener('pointerdown', (e) => {
            this.handlePointerLogic(e, this.windowMouseDown);
        });
        canvas.addEventListener('pointermove', (e) => {
            this.handlePointerLogic(e, this.moveZoomer, (ev) => {
                if (this.targetTouchId !== null) {
                    let myEv = {
                        changedTouches: [
                            {
                                identifier: this.targetTouchId,
                                pageX: ev.pageX,
                                pageY: ev.pageY,
                            },
                        ],
                    };
                    ev.changedTouches = myEv.changedTouches;
                    this.handleTouchMove(ev);
                }
            });
        });
        canvas.addEventListener('pointerup', (e) => {
            this.handlePointerLogic(e, this.windowMouseUp);
        });
        canvas.addEventListener('pointerleave', (e) => {
            this.handlePointerLogic(e, this.handleMouseLeave);
        });
        canvas.addEventListener('pointercancel', (e) => {
            this.handlePointerLogic(e, this.handleMouseLeave);
        });
        window.addedListenersToItem = {};

        window.addEventListenersForIndividualBoard = this.addEventListenersForIndividualBoard;
    }

    addEventListenersForIndividualBoard = (elementId) => {
        if (window.addedListenersToItem[elementId] === true) {
            return;
        }
        // let multiCanvas = document.getElementById('multiBoardContainer');
        let multiCanvas = document.getElementById(elementId);
        if (!multiCanvas) {
            console.error('Canvas not found to attach listeners', elementId);
            return;
        }
        multiCanvas.addEventListener('touchstart', (e) => {
            if (boardManager.shouldHandleEventsForMulti()) {
                this.handleTouchStart(e);
            }
        });
        multiCanvas.addEventListener('touchend', (e) => {
            if (boardManager.shouldHandleEventsForMulti()) {
                this.handleTouchEnd(e);
            }
        });
        multiCanvas.addEventListener('touchmove', (e) => {
            if (boardManager.shouldHandleEventsForMulti()) {
                this.handleTouchMove(e);
            }
        });
        multiCanvas.addEventListener('touchcancel', (e) => {
            if (boardManager.shouldHandleEventsForMulti()) {
                this.handleMouseLeave(e);
            }
        });
        multiCanvas.addEventListener('pointerdown', (e) => {
            if (boardManager.shouldHandleEventsForMulti()) {
                this.handlePointerLogic(e, this.windowMouseDown);
            }
        });
        multiCanvas.addEventListener('pointermove', (e) => {
            if (boardManager.shouldHandleEventsForMulti()) {
                this.handlePointerLogic(e, this.moveZoomer, (ev) => {
                    if (this.targetTouchId !== null) {
                        let myEv = {
                            changedTouches: [
                                {
                                    identifier: this.targetTouchId,
                                    pageX: ev.pageX,
                                    pageY: ev.pageY,
                                },
                            ],
                        };
                        ev.changedTouches = myEv.changedTouches;
                        this.handleTouchMove(ev);
                    }
                });
            }
        });
        multiCanvas.addEventListener('pointerup', (e) => {
            if (boardManager.shouldHandleEventsForMulti()) {
                this.handlePointerLogic(e, this.windowMouseUp);
            }
        });
        multiCanvas.addEventListener('pointerleave', (e) => {
            if (boardManager.shouldHandleEventsForMulti()) {
                this.handlePointerLogic(e, this.handleMouseLeave);
            }
        });
        multiCanvas.addEventListener('pointercancel', (e) => {
            if (boardManager.shouldHandleEventsForMulti()) {
                this.handlePointerLogic(e, this.handleMouseLeave);
            }
        });

        window.addedListenersToItem[elementId] = true;
    };

    componentWillUnmount() {
        // if (this.pageSaveInterval) {
        //     clearInterval(this.pageSaveInterval);
        // }
        if (this.flushPenIntervalFunc) {
            clearInterval(this.flushPenIntervalFunc);
        }
        if (this.scrollIntoViewInterval) {
            clearInterval(this.scrollIntoViewInterval);
        }
        if (this.flushLocalDrawIntervalFunc) {
            clearInterval(this.flushLocalDrawIntervalFunc);
        }
        if (this.sendJoinImageDataDebouncer) {
            clearTimeout(this.sendJoinImageDataDebouncer);
        }
        // if (this.lineageDebouncer) {
        //     clearTimeout(this.lineageDebouncer);
        // }
        // if (this.zoomDebouncer) {
        //     clearTimeout(this.zoomDebouncer);
        // }
        if (this.sendImageDataDebouncer) {
            clearTimeout(this.sendImageDataDebouncer);
        }
        if (boardManager.isAdmin && boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
            cloudBoardManager.saveCurrentMainPage();
        }
        window.targetCanvasLoadCache = {};
        window.logAppActivity(GA_EVENT_TREE.lessonPage.actions.leave);

        store.dispatch(endLesson());
        store.dispatch(hideSpinner());
        socketUtils.socketClose();
        audioCallUtils.hangUp();
        boardManager.closeAndClean();
        window.onresize = null;
        // window['handleTextInputExpand'] = null;
        // window['handleTextInputMove'] = null;
        // window['handleImageUpdate'] = null;
        document.onpaste = () => {
        };
        if (!window.isTeams) {
            let divs = document.getElementsByClassName('osano-cm-window');
            if (divs && divs.length > 0) {
                divs.item(0).style.display = 'block';
            }
            let fbDivs = document.getElementById('fb-root');
            if (fbDivs) {
                fbDivs.style.display = 'block';
            }
        }
        store.dispatch(lessonInfo(null));
        store.dispatch(updateLatexText(null));
        window.zoomState.zoomLevel = 0;
    }

    sendImageData = (force = false) => {
        socketUtils.sendJoinImageData(
            boardManager.lessonState.mainBoardId,
            boardManager.lessonState.mainBoardPages,
            Object.keys(boardManager.lessonState.userStates),
        );
        // boardManager.sendImageData(force);
        // this.debounceSendImageData(force);
    };

    sendJoinImageData = (email) => {
        this.debounceSendJoinImageData(email);
    };

    updateUserDrawing = (email, drawingEnabled) => {
        socketUtils.updateUserDrawing(email, drawingEnabled, this);
    };

    ongoingTouchIndexById = (idToFind) => {
        for (let i = 0; i < this.ongoingTouches.length; i++) {
            let id = this.ongoingTouches[i].identifier;

            if (id == idToFind) {
                return i;
            }
        }
        return -1; // not found
    };

    handleTouchStart = (e) => {
        if (this.ignoreBoardMoveAndClick) {
            return;
        }
        moveUtils.handleTouchStartWrapper(e, this);
    };

    handleTouchMove = (e) => {
        if (this.ignoreBoardMoveAndClick) {
            return;
        }
        moveUtils.handleTouchMoveWrapper(e, this);
        e.preventDefault();
        e.stopPropagation();
    };

    getTextValue = () => {
        return document.getElementById('textInput').value;
    };

    setTextValue = (value) => {
        return (document.getElementById('textInput').value = value);
    };

    drawLine = (x1, y1, x2, y2, skipCheck = false) => {
        let drawing = this.props.drawing;

        if (
            drawing.tool === TOOL_PEN ||
            drawing.tool === TOOL_MATH_CONVERTER ||
            drawing.tool === TOOL_POINTER ||
            drawing.tool === TOOL_ERASER ||
            drawing.tool === TOOL_LINE ||
            drawing.tool === TOOL_BRUSH
        ) {
            if (x1 === x2 && y1 === y2 && !skipCheck) return;
            let points = this.localPointArray;
            if (points.length > 0 && points[points.length - 1].x1 === x1 && points[points.length - 1].y1 === y1) {
                return;
            }
            if (!this.localCurrentDrawToFlush) {
                this.localCurrentDrawToFlush = {
                    tool: drawing.tool,
                    color: drawing.tool === TOOL_POINTER ? LASER_POINTER_COLOR : this.props.drawing.color,
                    toolWeight: drawing.toolWeight,
                    eraserWeight: drawing.eraserWeight,
                };
            }
            this.localPointArray.push({ x1, y1, x2, y2 });
            this.localIntervalCount++;
            // if (drawing.tool === TOOL_PEN) {
            //     this.updateWriteSVG({
            //         tool: drawing.tool,
            //         color: drawing.tool === TOOL_POINTER ? LASER_POINTER_COLOR : this.props.drawing.color,
            //         toolWeight: drawing.toolWeight,
            //         eraserWeight: drawing.eraserWeight,
            //     });
            // }
            // if (drawing.tool === TOOL_MATH_CONVERTER) {
            //     this.updateWriteSVG({
            //         tool: drawing.tool,
            //         color: drawing.tool === TOOL_POINTER ? LASER_POINTER_COLOR : this.props.drawing.color,
            //         toolWeight: drawing.toolWeight,
            //         eraserWeight: drawing.eraserWeight,
            //     });
            // }
        } else {
            this.drawLineDebounced(x1, y1, x2, y2, skipCheck);
            // moveUtils.zoomFunc(this);
        }
    };

    setWriting = (val) => {
        this.isWriting = val;
    };

    clearWritingSvg = () => {
        let pth = document.getElementById('main-writing-svg');
        if (pth) {
            while (pth.firstChild && pth.childNodes.length > 1) {
                pth.removeChild(pth.lastChild);
            }
            let newS = '';
            pth.firstChild.setAttribute('d', newS);
        }
    };

    updateWriteSVG = (drawing, oldArray) => {
        if (this.lastComputedSvgInput === this.localPointArray.length) {
            return;
        }
        let pth = boardManager.getCurrentWritingSvgPath();
        if (pth) {
            if (oldArray && oldArray.length > 0) {
                this.clearWritingSvg();
            }
            this.lastComputedSvgInput = this.localPointArray.length;
            let newS = drawUtils.buildQuadraticPath(this.localPointArray);
            // let newS = drawUtils.buildSmoothSvgPathCurrent(this.localPointArray);
            // let newS = drawUtils.buildCatmullRomSplineSvgPath(this.localPointArray);
            pth.setAttribute('d', newS);
            pth.style.stroke = drawing.color;
            pth.style.strokeWidth = drawing.width + 'px';
            pth.style['stroke-linecap'] = 'round';
            pth.style['stroke-linejoin'] = 'round';
            pth.style['fill'] = 'none';
            if (oldArray && oldArray.length > 0) {
                let parent = pth.parentNode;
                oldArray.forEach((el) => {
                    let newPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                    let newS = drawUtils.buildQuadraticPath(el);
                    // let newS = drawUtils.buildSmoothSvgPathCurrent(el);
                    // let newS = drawUtils.buildCatmullRomSplineSvgPath(el);
                    newPath.setAttribute('d', newS);
                    newPath.style.stroke = drawing.color;
                    newPath.style.strokeWidth = drawing.width + 'px';
                    newPath.style['stroke-linecap'] = 'round';
                    newPath.style['stroke-linejoin'] = 'round';
                    newPath.style['fill'] = 'none';
                    parent.appendChild(newPath);
                });
            }
        }
    };

    drawArc = (x, y, arcWidth, direction, pointA, pointB) => {
        let drawing = this.props.drawing;
        let canvas = drawUtils.getCanvas();
        if (!canvas) {
            console.log('unable to draw line - no canvas');
            return;
        }
        let ctx = canvas.getContext('2d');
        ctx.globalCompositeOperation = 'source-over';
        if (!this.allowDrawing && boardManager.getBoardType() === BOARD_TYPE.SINGLE_BOARD) return;
        ctx.beginPath();
        let width = drawUtils.computeLineWidth(drawing.toolWeight);
        ctx.lineCap = 'butt';
        ctx.lineJoin = 'miter';
        ctx.miterLimit = 10;
        ctx.lineWidth = width;
        if (pointA === pointB) {
            ctx.arc(x, y, arcWidth, 0, 2 * Math.PI, direction === -1);
        } else {
            ctx.arc(x, y, arcWidth, (pointA * Math.PI) / 180, (pointB * Math.PI) / 180, direction === -1);
        }
        boardManager.pushLinePoints(x, y);
        ctx.strokeStyle = this.props.drawing.color;
        ctx.stroke();
        ctx.closePath();
        boardManager.setDirtyFlag();

        socketUtils.sendDraw({
            tool: TOOL_ARC,
            color: this.props.drawing.color,
            toolWeight: drawing.toolWeight,
            x,
            y,
            arcWidth,
            direction,
            pointA,
            pointB,
            width: canvas.width,
            height: canvas.height,
        });
        this.intervalCount = 0;
        this.pointArray = [];
        this.currentDrawToFlush = null;
    };

    drawLineDebounced = (x1, y1, x2, y2, skipCheck = false) => {
        let metaInfo = null;
        let drawing = this.props.drawing;
        let canvas = drawUtils.getCanvas();
        let svgPoints;
        if (!canvas) {
            console.log('unable to draw line - no canvas');
            return;
        }
        let ctx = canvas.getContext('2d');
        ctx.globalCompositeOperation = 'source-over';
        if (!this.allowDrawing && boardManager.getBoardType() === BOARD_TYPE.SINGLE_BOARD) return;
        if (drawing.tool === TOOL_PEN || drawing.tool === TOOL_LINE || drawing.tool === TOOL_POINTER || drawing.tool === TOOL_BRUSH) {
            if (x1 === x2 && y1 === y2 && !skipCheck) return;
            if (drawing.tool === TOOL_POINTER) {
                ctx = boardManager.getPointerContext();
                ctx.shadowBlur = 10;
                ctx.shadowColor = this.props.drawing.color;
                if (!ctx) {
                    console.log('bad juju on pointer draw');
                    return;
                }
            }
            this.intervalCount += this.localIntervalCount;
            ctx.beginPath();
            let width = drawUtils.computeLineWidth(drawing.toolWeight);
            ctx.lineCap = 'round';
            ctx.lineJoin = 'round';
            // ctx.shadowBlur = 1;
            // ctx.shadowColor = 'rgba(0,0,0,.5)';
            // ctx.miterLimit = 1;
            ctx.lineWidth = width;
            ctx.strokeStyle = this.props.drawing.color;
            ctx.strokeStyle = drawing.tool === TOOL_POINTER ? LASER_POINTER_COLOR : this.props.drawing.color;
            if (drawing.tool === TOOL_BRUSH) {
                let width = drawUtils.computeEraserWidth(drawing.eraserWeight);
                ctx.lineWidth = width;
                let conv = hexToRgb(drawing.color);
                if (conv) {
                    ctx.strokeStyle = `rgba(${conv.r}, ${conv.g}, ${conv.b}, 0.2)`;
                } else {
                    ctx.strokeStyle = drawing.color;
                }
            }
            let newS = drawUtils.buildQuadraticPath(this.localPointArray);
            // let newS = drawUtils.buildSmoothSvgPathCurrent(this.localPointArray);
            // let newS = drawUtils.buildCatmullRomSplineSvgPath(this.localPointArray);
            svgPoints = newS;
            let path1 = new Path2D(newS);
            ctx.stroke(path1);

            // if (this.localPointArray.length > 2) {
            //     ctx.moveTo(this.localPointArray[0].x1, this.localPointArray[0].y1);
            //     let cp1x, cp1y, cp2x, cp2y, x, y;
            //     if (this.localPointArray.length < BEZIER_INTERVAL_DIVIDER * 2) {
            //         let interval = Math.floor(this.localPointArray.length);
            //         x = this.localPointArray[this.localPointArray.length - 1].x2;
            //         y = this.localPointArray[this.localPointArray.length - 1].y2;
            //         cp1x = this.localPointArray[interval].x2;
            //         cp1y = this.localPointArray[interval].y2;
            //         cp2x = this.localPointArray[2 * interval].x2;
            //         cp2y = this.localPointArray[2 * interval].y2;
            //         ctx.bezierCurveTo(cp1x, cp1y, cp2x, cp2y, x, y);
            //     } else {
            //         let i;
            //         for (i = 0; i < Math.floor(this.localPointArray.length / BEZIER_INTERVAL_DIVIDER) - 2; i++) {
            //             x = this.localPointArray[(i + 1) * BEZIER_INTERVAL_DIVIDER].x2;
            //             y = this.localPointArray[(i + 1) * BEZIER_INTERVAL_DIVIDER].y2;
            //             cp1x = this.localPointArray[i * BEZIER_INTERVAL_DIVIDER + BEZIER_INTERVAL_DIVIDER / 3].x2;
            //             cp1y = this.localPointArray[i * BEZIER_INTERVAL_DIVIDER + BEZIER_INTERVAL_DIVIDER / 3].y2;
            //             cp2x = this.localPointArray[i * BEZIER_INTERVAL_DIVIDER + 2 * (BEZIER_INTERVAL_DIVIDER / 3)].x2;
            //             cp2y = this.localPointArray[i * BEZIER_INTERVAL_DIVIDER + 2 * (BEZIER_INTERVAL_DIVIDER / 3)].y2;
            //             ctx.bezierCurveTo(cp1x, cp1y, cp2x, cp2y, x, y);
            //         }
            //         let diff = this.localPointArray.length - BEZIER_INTERVAL_DIVIDER * (i + 1);
            //         // console.log(diff);
            //         if (diff !== 0) {
            //             let interval = Math.floor(diff / 3);
            //             x = this.localPointArray[this.localPointArray.length - 1].x2;
            //             y = this.localPointArray[this.localPointArray.length - 1].y2;
            //             cp1x = this.localPointArray[i * BEZIER_INTERVAL_DIVIDER + interval].x2;
            //             cp1y = this.localPointArray[i * BEZIER_INTERVAL_DIVIDER + interval].y2;
            //             cp2x = this.localPointArray[i * BEZIER_INTERVAL_DIVIDER + 2 * interval].x2;
            //             cp2y = this.localPointArray[i * BEZIER_INTERVAL_DIVIDER + 2 * interval].y2;
            //             ctx.bezierCurveTo(cp1x, cp1y, cp2x, cp2y, x, y);
            //         } else {
            //             ctx.lineTo(
            //                 this.localPointArray[this.localPointArray.length - 1].x2,
            //                 this.localPointArray[this.localPointArray.length - 1].y2
            //             );
            //         }
            //     }
            // } else {
            //     for (let i = 0; i < this.localPointArray.length; i++) {
            //         ctx.moveTo(this.localPointArray[i].x1, this.localPointArray[i].y1);
            //         ctx.lineTo(this.localPointArray[i].x2, this.localPointArray[i].y2);
            //         if (drawing.tool === TOOL_LINE) {
            //             boardManager.pushLinePoints(this.localPointArray[i].x1, this.localPointArray[i].y1);
            //             boardManager.pushLinePoints(this.localPointArray[i].x2, this.localPointArray[i].y2);
            //         }
            //     }
            // }
            if (drawing.tool === TOOL_LINE && this.localPointArray.length <= 2) {
                for (let i = 0; i < this.localPointArray.length; i++) {
                    boardManager.pushLinePoints(this.localPointArray[i].x1, this.localPointArray[i].y1);
                    boardManager.pushLinePoints(this.localPointArray[i].x2, this.localPointArray[i].y2);
                }
            }

            ctx.stroke();
            ctx.closePath();
            if (drawing.tool === TOOL_POINTER) {
                boardManager.triggerPointerDraw();
            }
        } else if (drawing.tool === TOOL_ERASER) {
            if (x1 === x2 && y1 === y2) return;
            ctx.globalCompositeOperation = 'destination-out';
            this.intervalCount += this.localIntervalCount;
            ctx.beginPath();
            let width = drawUtils.computeEraserWidth(drawing.eraserWeight);
            ctx.lineWidth = 1;
            ctx.lineCap = 'square';
            ctx.lineJoin = 'miter';
            ctx.miterLimit = 0;
            for (let i = 0; i < this.localPointArray.length; i++) {
                ctx.arc(this.localPointArray[i].x2, this.localPointArray[i].y2, width / 2, 0, 2 * Math.PI);
                boardManager.clearLinePoints(this.localPointArray[i].x2, this.localPointArray[i].y2, width);
            }

            ctx.strokeStyle = '#fff';
            ctx.fillStyle = '#fff';
            ctx.fill();
            ctx.stroke();
            ctx.closePath();
            // console.log('=== before eraser debounce', boardManager.getLineage());
            // if (boardManager.getLineage() !== LINEAGE_NONE) {
            //     boardManager.debounceCurrentLineage();
            //     // this.debounceLineage();
            // }
            // console.log('=== eraser ', x2, y2, width, drawing.eraserWeight);
            // } else if (drawing.tool === TOOL_BRUSH) {
            //     if (x1 === x2 && y1 === y2) return;
            //     ctx.globalCompositeOperation = 'source-over';
            //     this.intervalCount += this.localIntervalCount;
            //     let width = drawUtils.computeEraserWidth(drawing.eraserWeight);
            //     ctx.lineWidth = 0;
            //     ctx.lineCap = 'square';
            //     ctx.lineJoin = 'miter';
            //     ctx.miterLimit = 0;
            //     // if (this.localPointArray.length) {
            //     //     ctx.arc(this.localPointArray[0].x2, this.localPointArray[0].y2, width / 2, 0, 2 * Math.PI);
            //     // }
            //     let conv = hexToRgb(drawing.color);
            //     if (conv) {
            //         console.log('===', conv);
            //         ctx.strokeStyle = `rgba(${conv.r}, ${conv.g}, ${conv.b}, 0.3)`;
            //         ctx.fillStyle = `rgba(${conv.r}, ${conv.g}, ${conv.b}, 0.3)`;
            //     } else {
            //         ctx.strokeStyle = drawing.color;
            //         ctx.fillStyle = drawing.color;
            //     }
            //
            //     for (let i = 0; i < this.localPointArray.length; i++) {
            //         ctx.beginPath();
            //         ctx.arc(this.localPointArray[i].x2, this.localPointArray[i].y2, width / 2, 0, 2 * Math.PI);
            //         ctx.fill();
            //         ctx.stroke();
            //     }

            // ctx.closePath();
        } else if (drawing.tool === TOOL_TEXT) {
            // ctx.fillStyle = this.props.drawing.color;
            // ctx.font = drawUtils.computeFontPixel(drawing.textWeight) + 'px Arial';
            // drawUtils.writeTextToDom(
            //     ctx,
            //     this.getTextValue(),
            //     x1,
            //     y1,
            //     x2,
            //     drawUtils.computeFontPixel(drawing.textWeight)
            // );
            // ctx.fillText(this.getTextValue(), x1, y1);
        } else if (drawing.tool === TOOL_RECTANGLE) {
            ctx.beginPath();
            let width = drawUtils.computeLineWidth(drawing.toolWeight);
            ctx.lineCap = 'square';
            ctx.lineJoin = 'miter';
            ctx.miterLimit = 10;
            ctx.lineWidth = width;
            ctx.rect(x1 > x2 ? x2 : x1, y1 > y2 ? y2 : y1, Math.abs(x2 - x1), Math.abs(y2 - y1));
            boardManager.pushLinePoints(x1, y1);
            boardManager.pushLinePoints(x2, y1);
            boardManager.pushLinePoints(x1, y2);
            boardManager.pushLinePoints(x2, y2);
            ctx.strokeStyle = this.props.drawing.color;
            ctx.stroke();
            ctx.closePath();
        } else if (drawing.tool === TOOL_CIRCLE) {
            ctx.beginPath();
            let width = drawUtils.computeLineWidth(drawing.toolWeight);
            ctx.lineCap = 'square';
            ctx.lineJoin = 'miter';
            ctx.miterLimit = 10;
            ctx.lineWidth = width;
            ctx.ellipse(
                Math.min(x1, x2) + Math.abs((x1 - x2) / 2),
                Math.min(y1, y2) + Math.abs((y1 - y2) / 2),
                Math.abs((x2 - x1) / 2),
                Math.abs((y2 - y1) / 2),
                0,
                0,
                360,
            );
            ctx.strokeStyle = this.props.drawing.color;
            ctx.stroke();
            ctx.closePath();
        } else {
            return;
        }

        if (drawing.tool !== TOOL_POINTER) {
            boardManager.setDirtyFlag();
        }

        // this.isCurrentPageDirty = true;

        if (
            drawing.tool === TOOL_POINTER ||
            drawing.tool === TOOL_ERASER ||
            drawing.tool === TOOL_LINE
        ) {
            if (drawing.tool === TOOL_POINTER) {
                this.pointArray.push(this.localPointArray);
            } else {
                this.pointArray.push(...this.localPointArray);
            }
            this.currentDrawToFlush = {
                tool: drawing.tool,
                color: drawing.tool === TOOL_POINTER ? LASER_POINTER_COLOR : this.props.drawing.color,
                toolWeight: drawing.toolWeight,
                width: canvas.width,
                height: canvas.height,
                metaInfo,
                text: '',
                textWeight: drawing.textWeight,
                eraserWeight: drawing.eraserWeight,
            };
            return;
        }

        if (drawing.tool === TOOL_PEN) {
            x1 = null;
        }
        if (drawing.tool === TOOL_BRUSH) {
            x1 = null;
        }
        if (boardManager?.lessonState?.userStates &&
            Object.keys(boardManager?.lessonState?.userStates).length === 0 &&
            (drawing.tool === TOOL_PEN ||
                drawing.tool === TOOL_POINTER ||
                drawing.tool === TOOL_ERASER ||
                drawing.tool === TOOL_LINE ||
                drawing.tool === TOOL_RECTANGLE ||
                drawing.tool === TOOL_CIRCLE ||
                drawing.tool === TOOL_BRUSH
            )) {

        } else {
            socketUtils.sendDraw({
                tool: drawing.tool,
                color: this.props.drawing.color,
                toolWeight: drawing.toolWeight,
                svgPoints,
                x1,
                y1,
                x2,
                y2,
                width: canvas.width,
                height: canvas.height,
                metaInfo,
                text: '',
                textWeight: drawing.textWeight,
                eraserWeight: drawing.eraserWeight,
            });
        }

        this.intervalCount = 0;
        this.pointArray = [];
        this.currentDrawToFlush = null;
    };

    drawImageFromUrlInCanvas = async (url, canvas) => {
        if (url && canvas) {
            let ctx = canvas.getContext('2d');
            return drawUtils.buildImageFromUrlNoCache(url).then((img) => {
                let rect = canvas;
                if (img) {
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                    setTimeout(() => {
                        ctx.imageSmoothingEnabled = true;
                        ctx.imageSmoothingQuality = 'high';
                        ctx.drawImage(img, 0, 0, canvas.width, canvas.height, 0, 0, rect.width, rect.height);
                    }, 100);
                }
            });
        }
    };

    drawLineFromMessage = async (drawing, target) => {
        let canvas;
        if (target) {
            canvas = drawUtils.getCanvas(target);
        } else {
            canvas = drawUtils.getCanvas();
        }
        if (!canvas) {
            console.log('got message, but no canvas - will retry', target);
            missedMessagesCount++;
            if (missedMessagesCount > 100) {
                window.location.reload();
            }
            await sleep(MISSED_MESSAGE_RETRY_INTERVAL);
            this.drawLineFromMessage(drawing, target);
            return;
        }
        missedMessagesCount = 0;
        let ctx = canvas.getContext('2d');
        ctx.globalCompositeOperation = 'source-over';
        // console.log(drawing);

        if (drawing.tool === TOOL_PEN || drawing.tool === TOOL_LINE || drawing.tool === TOOL_POINTER || drawing.tool === TOOL_BRUSH) {
            // let x1 = drawUtils.computeX(drawing.x1, drawing.width);
            // let y1 = drawUtils.computeY(drawing.y1, drawing.height);
            // let x2 = drawUtils.computeX(drawing.x2, drawing.width);
            // let y2 = drawUtils.computeY(drawing.y2, drawing.height);

            // let x1 = drawing.x1;
            // let y1 = drawing.y1;
            // let x2 = drawing.x2;
            // let y2 = drawing.y2;

            if (drawing.tool === TOOL_POINTER) {
                ctx = boardManager.getPointerContext(target);
                ctx.shadowBlur = 10;
                ctx.shadowColor = drawing.tool === TOOL_POINTER ? LASER_POINTER_COLOR : drawing.color;

                if (!ctx) {
                    console.log('bad juju on pointer draw');
                    return;
                }
            }

            ctx.beginPath();
            let width = drawUtils.computeLineWidth(drawing.toolWeight);
            ctx.lineWidth = width;

            ctx.lineCap = 'round';
            ctx.lineJoin = 'round';
            ctx.lineWidth = width;
            ctx.strokeStyle = drawing.color;
            ctx.strokeStyle = drawing.tool === TOOL_POINTER ? LASER_POINTER_COLOR : drawing.color;
            if (drawing.tool === TOOL_PEN) {
                let path1 = new Path2D(drawing.svgPoints);
                ctx.stroke(path1);
            } else if (drawing.tool === TOOL_POINTER) {
                // console.log('=== points', drawing.points);
                // let newS = drawUtils.buildSmoothSvgPath(drawing.points[0]);
                // let path1 = new Path2D(newS);
                // ctx.stroke(path1);
                drawing.points.forEach((el) => {
                    if (el.length > 2) {
                        // let interval = Math.floor(el.length / 3);
                        // let cp1x, cp1y, cp2x, cp2y, x, y;
                        // ctx.moveTo(el[0].x1, el[0].y1);
                        // x = el[el.length - 1].x2;
                        // y = el[el.length - 1].y2;
                        // cp1x = el[interval].x2;
                        // cp1y = el[interval].y2;
                        // cp2x = el[2 * interval].x2;
                        // cp2y = el[2 * interval].y2;
                        // ctx.bezierCurveTo(cp1x, cp1y, cp2x, cp2y, x, y);

                        ctx.moveTo(el[0].x1, el[0].y1);
                        let cp1x, cp1y, cp2x, cp2y, x, y;
                        if (el.length < BEZIER_INTERVAL_DIVIDER * 2) {
                            let interval = Math.floor(el.length / 3);
                            x = el[el.length - 1].x2;
                            y = el[el.length - 1].y2;
                            cp1x = el[interval].x2;
                            cp1y = el[interval].y2;
                            cp2x = el[2 * interval].x2;
                            cp2y = el[2 * interval].y2;
                            ctx.bezierCurveTo(cp1x, cp1y, cp2x, cp2y, x, y);
                        } else {
                            let i;
                            for (i = 0; i < Math.floor(el.length / BEZIER_INTERVAL_DIVIDER) - 1; i++) {
                                x = el[(i + 1) * BEZIER_INTERVAL_DIVIDER].x2;
                                y = el[(i + 1) * BEZIER_INTERVAL_DIVIDER].y2;
                                cp1x = el[i * BEZIER_INTERVAL_DIVIDER + BEZIER_INTERVAL_DIVIDER / 2].x2;
                                cp1y = el[i * BEZIER_INTERVAL_DIVIDER + BEZIER_INTERVAL_DIVIDER / 2].y2;
                                cp2x = el[i * BEZIER_INTERVAL_DIVIDER + 2 * (BEZIER_INTERVAL_DIVIDER / 2)].x2;
                                cp2y = el[i * BEZIER_INTERVAL_DIVIDER + 2 * (BEZIER_INTERVAL_DIVIDER / 2)].y2;
                                ctx.bezierCurveTo(cp1x, cp1y, cp2x, cp2y, x, y);
                            }
                            let diff =
                                el.length - BEZIER_INTERVAL_DIVIDER * Math.floor(el.length / BEZIER_INTERVAL_DIVIDER);
                            if (diff !== 0) {
                                let interval = Math.floor(diff / 3);
                                x = el[el.length - 1].x2;
                                y = el[el.length - 1].y2;
                                cp1x = el[i * BEZIER_INTERVAL_DIVIDER + interval].x2;
                                cp1y = el[i * BEZIER_INTERVAL_DIVIDER + interval].y2;
                                cp2x = el[i * BEZIER_INTERVAL_DIVIDER + 2 * interval].x2;
                                cp2y = el[i * BEZIER_INTERVAL_DIVIDER + 2 * interval].y2;
                                ctx.bezierCurveTo(cp1x, cp1y, cp2x, cp2y, x, y);
                            } else {
                                ctx.lineTo(el[el.length - 1].x2, el[el.length - 1].y2);
                            }
                        }
                    } else {
                        for (let i = 0; i < el.length; i++) {
                            ctx.moveTo(el[i].x1, el[i].y1);
                            ctx.lineTo(el[i].x2, el[i].y2);
                        }
                    }
                });
            } else if (drawing.tool === TOOL_BRUSH) {
                let width = drawUtils.computeEraserWidth(drawing.eraserWeight);
                ctx.lineWidth = width;
                let conv = hexToRgb(drawing.color);
                if (conv) {
                    ctx.strokeStyle = `rgba(${conv.r}, ${conv.g}, ${conv.b}, 0.2)`;
                } else {
                    ctx.strokeStyle = drawing.color;
                }

                let path1 = new Path2D(drawing.svgPoints);
                ctx.stroke(path1);
            } else {
                for (let i = 0; i < drawing.points.length; i++) {
                    ctx.moveTo(drawing.points[i].x1, drawing.points[i].y1);
                    ctx.lineTo(drawing.points[i].x2, drawing.points[i].y2);
                    if (drawing.tool === TOOL_LINE) {
                        boardManager.pushLinePoints(drawing.points[i].x1, drawing.points[i].y1, target);
                        boardManager.pushLinePoints(drawing.points[i].x2, drawing.points[i].y2, target);
                    }
                }
            }

            ctx.stroke();
            ctx.closePath();
            if (drawing.tool === TOOL_POINTER) {
                boardManager.triggerPointerDraw(target);
            }
        } else if (drawing.tool === TOOL_ERASER) {
            // let x1 = drawUtils.computeX(drawing.x1, drawing.width);
            // let y1 = drawUtils.computeY(drawing.y1, drawing.height);
            // let x2 = drawUtils.computeX(drawing.x2, drawing.width);
            // let y2 = drawUtils.computeY(drawing.y2, drawing.height);

            for (let i = 0; i < drawing.points.length; i++) {
                let x2 = drawing.points[i].x2;
                let y2 = drawing.points[i].y2;

                ctx.globalCompositeOperation = 'destination-out';

                ctx.beginPath();
                let width = drawUtils.computeEraserWidth(drawing.eraserWeight);
                ctx.lineWidth = 1;
                ctx.lineCap = 'square';
                ctx.lineJoin = 'miter';
                ctx.miterLimit = 0;
                ctx.arc(x2, y2, width / 2, 0, 2 * Math.PI);
                boardManager.clearLinePoints(x2, y2, width, target);
                ctx.strokeStyle = '#fff';
                ctx.fillStyle = '#fff';
                ctx.fill();
                ctx.stroke();
                ctx.closePath();
            }
            // } else if (drawing.tool === TOOL_BRUSH) {
            //     for (let i = 0; i < drawing.points.length; i++) {
            //         let x2 = drawing.points[i].x2;
            //         let y2 = drawing.points[i].y2;
            //
            //         ctx.globalCompositeOperation = 'source-over';
            //
            //         ctx.beginPath();
            //         let width = drawUtils.computeEraserWidth(drawing.eraserWeight);
            //         ctx.lineWidth = 0;
            //         ctx.lineCap = 'square';
            //         ctx.lineJoin = 'miter';
            //         ctx.miterLimit = 0;
            //         ctx.arc(x2, y2, width / 2, 0, 2 * Math.PI);
            //         ctx.strokeStyle = drawing.color;
            //         ctx.fillStyle = drawing.color;
            //         ctx.fill();
            //         ctx.stroke();
            //         ctx.closePath();
            //     }
        } else if (drawing.tool === TOOL_TEXT) {
            // let x1 = drawUtils.computeX(drawing.x1, drawing.width);
            // let y1 = drawUtils.computeY(drawing.y1, drawing.height);
            // let x2 = drawUtils.computeX(drawing.x2, drawing.width);
            // let y2 = drawUtils.computeY(drawing.y2, drawing.height);

            let x1 = drawing.x1;
            let y1 = drawing.y1;

            ctx.fillStyle = drawing.color;
            ctx.font = drawUtils.computeFontPixel(drawing.textWeight) + 'px Arial';
            drawUtils.writeTextToDom(
                ctx,
                drawing.text,
                x1,
                y1,
                drawing.x2,
                drawUtils.computeFontPixel(drawing.textWeight),
            );
        } else if (drawing.tool === TOOL_RECTANGLE) {
            // let x1 = drawUtils.computeX(drawing.x1, drawing.width);
            // let y1 = drawUtils.computeY(drawing.y1, drawing.height);
            // let x2 = drawUtils.computeX(drawing.x2, drawing.width);
            // let y2 = drawUtils.computeY(drawing.y2, drawing.height);

            let x1 = drawing.x1;
            let y1 = drawing.y1;
            let x2 = drawing.x2;
            let y2 = drawing.y2;

            let width = drawUtils.computeLineWidth(drawing.toolWeight);
            ctx.beginPath();
            ctx.lineWidth = width;
            ctx.lineCap = 'square';
            ctx.lineJoin = 'miter';
            ctx.miterLimit = 10;
            ctx.lineWidth = width;
            ctx.rect(x1 > x2 ? x2 : x1, y1 > y2 ? y2 : y1, Math.abs(x2 - x1), Math.abs(y2 - y1));
            boardManager.pushLinePoints(x1, y1, target);
            boardManager.pushLinePoints(x2, y1, target);
            boardManager.pushLinePoints(x1, y2, target);
            boardManager.pushLinePoints(x2, y2, target);

            ctx.strokeStyle = drawing.color;
            ctx.stroke();
            ctx.closePath();
        } else if (drawing.tool === TOOL_ARC) {
            ctx.beginPath();
            let width = drawUtils.computeLineWidth(drawing.toolWeight);
            ctx.lineCap = 'butt';
            ctx.lineJoin = 'miter';
            ctx.miterLimit = 10;
            ctx.lineWidth = width;
            if (drawing.pointA === drawing.pointB) {
                ctx.arc(drawing.x, drawing.y, drawing.arcWidth, 0, 2 * Math.PI, drawing.direction === -1);
            } else {
                ctx.arc(
                    drawing.x,
                    drawing.y,
                    drawing.arcWidth,
                    (drawing.pointA * Math.PI) / 180,
                    (drawing.pointB * Math.PI) / 180,
                    drawing.direction === -1,
                );
            }
            boardManager.pushLinePoints(drawing.x, drawing.y);
            ctx.strokeStyle = drawing.color;
            ctx.stroke();
            ctx.closePath();
        } else if (drawing.tool === TOOL_CIRCLE) {
            // let x1 = drawUtils.computeX(drawing.x1, drawing.width);
            // let y1 = drawUtils.computeY(drawing.y1, drawing.height);
            // let x2 = drawUtils.computeX(drawing.x2, drawing.width);
            // let y2 = drawUtils.computeY(drawing.y2, drawing.height);

            let x1 = drawing.x1;
            let y1 = drawing.y1;
            let x2 = drawing.x2;
            let y2 = drawing.y2;

            let width = drawUtils.computeLineWidth(drawing.toolWeight);
            ctx.beginPath();
            ctx.lineWidth = width;
            ctx.lineCap = 'square';
            ctx.lineJoin = 'miter';
            ctx.miterLimit = 10;
            ctx.lineWidth = width;
            ctx.ellipse(
                Math.min(x1, x2) + Math.abs((x1 - x2) / 2),
                Math.min(y1, y2) + Math.abs((y1 - y2) / 2),
                Math.abs((x2 - x1) / 2),
                Math.abs((y2 - y1) / 2),
                0,
                0,
                360,
            );
            ctx.strokeStyle = drawing.color;
            ctx.stroke();
            ctx.closePath();
        } else if (drawing.tool === 'IMAGE_PUSH') {
            if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
                if (drawing.data) {
                    drawUtils.buildImageFromUrlNoCache(drawing.data).then((img) => {
                        let rect = canvas;
                        ctx.clearRect(0, 0, canvas.width, canvas.height);
                        if (img) {
                            setTimeout(() => {
                                ctx.imageSmoothingEnabled = true;
                                ctx.imageSmoothingQuality = 'high';
                                ctx.drawImage(img, 0, 0, drawing.width, drawing.height, 0, 0, rect.width, rect.height);
                            }, 100);
                        }
                    });
                    boardManager.mainBoard.boardId = drawing.boardId;
                    boardManager.clearLinePoints();
                }
                if (drawing.stickyElements && !boardManager.isAdmin) {
                    boardManager.mainBoard.stickyElements = drawing.stickyElements;
                    boardManager.mainBoard.boardId = drawing.boardId;
                    // console.log(
                    //     '=== should set sticky elements',
                    //     boardManager.mainBoard.stickyElements,
                    //     boardManager.mainBoard.boardId
                    // );
                    if (this.stickyComponent.current) {
                        this.stickyComponent.current.shallowSyncPageElements(drawing.stickyElements);
                    }
                    if (window.checkIfStickyElementsToolPicker) {
                        window.checkIfStickyElementsToolPicker();
                    }
                }
                if (drawing.lineage) {
                    boardManager.mainBoard.lineage = drawing.lineage;
                    if (this.stickyComponent.current) {
                        this.stickyComponent.current.setLineage(drawing.lineage);
                    }
                }
                store.dispatch(hideSpinner());
                this.allowMainGeometryHandling(this.allowDrawing);
            } else {
                // console.log('=== got image push', drawing);
                if (drawing.data) {
                    // console.log('== got draw data', drawing.data);
                    boardManager.lessonState.individualBoards[boardManager.adminEmail].isCurrentPageDirty = false;
                    drawUtils.buildImageFromUrlNoCache(drawing.data).then((img) => {
                        let rect = canvas;
                        if (img) {
                            // ctx.clearRect(0, 0, canvas.width, canvas.height);
                            setTimeout(() => {
                                ctx.imageSmoothingEnabled = true;
                                ctx.imageSmoothingQuality = 'high';
                                ctx.drawImage(img, 0, 0, drawing.width, drawing.height, 0, 0, rect.width, rect.height);
                            }, 100);
                        }
                        if (boardManager.isAdmin) {
                            boardManager.lessonState.individualBoards[target].boardId = drawing.boardId;
                        } else {
                            boardManager.lessonState.individualBoards[boardManager.adminEmail].boardId =
                                drawing.boardId;
                        }
                    });
                    boardManager.clearLinePoints(null, null, null, target);
                }
                if (drawing.stickyElements) {
                    if (boardManager.isAdmin) {
                        let tg = boardManager.lessonState.individualBoards[target];
                        tg.pages[tg.currentPageNo].meta.stickyElements = drawing.stickyElements;
                    } else {
                        let tg = boardManager.lessonState.individualBoards[boardManager.adminEmail];
                        tg.pages[tg.currentPageNo].meta.stickyElements = drawing.stickyElements;
                    }
                }
                if (drawing.multiBoardOriginalData) {
                    boardManager.originalMultiBoardState.originalDownload = drawing.multiBoardOriginalData.originalUrl;
                    boardManager.originalMultiBoardState.originalSticky = drawing.multiBoardOriginalData.originalSticky;
                    boardManager.originalMultiBoardState.originalLineage =
                        drawing.multiBoardOriginalData.originalLineage;
                }
                if (drawing.lineage) {
                    if (boardManager.isAdmin) {
                        let tg = boardManager.lessonState.individualBoards[target];
                        tg.pages[tg.currentPageNo].meta.gridType = drawing.lineage;
                    } else {
                        let tg = boardManager.lessonState.individualBoards[boardManager.adminEmail];
                        tg.pages[tg.currentPageNo].meta.gridType = drawing.lineage;
                    }
                }
                if (drawing.pages) {
                    if (!boardManager.isAdmin) {
                        boardManager.lessonState.individualBoards[boardManager.adminEmail].pages = drawing.pages;
                        boardManager.mainBoard.boardId = drawing.boardId;
                        boardManager.lessonState.individualBoards[boardManager.adminEmail].boardId = drawing.boardId;
                        let pageNo = 0;
                        boardManager.lessonState.individualBoards[boardManager.adminEmail].pages.forEach(
                            (page, index) => {
                                if (page.id === boardManager.mainBoard.boardId) {
                                    pageNo = index;
                                }
                            },
                        );
                        let b = boardManager.lessonState.individualBoards[boardManager.adminEmail];
                        b.currentPageNo = pageNo;
                        b.pages[pageNo].meta.gridType = drawing.pages[pageNo].meta.gridType;
                        if (drawing.pages[0].id !== drawing.boardId) {
                            boardManager.multiBoardRefs[boardManager.adminEmail].current.setFixedStickyElements([]);
                            boardManager.multiBoardRefs[boardManager.adminEmail].current.setFixedWritingUrl(null);
                        } else {
                            boardManager.multiBoardRefs[boardManager.adminEmail].current.setFixedStickyElements(
                                drawing.multiBoardOriginalData.originalSticky,
                            );
                            boardManager.multiBoardRefs[boardManager.adminEmail].current.setFixedWritingUrl(
                                drawing.multiBoardOriginalData.originalUrl,
                            );
                        }
                        store.dispatch(loadLessonPages(drawing.pages));
                        store.dispatch(switchLessonPage(pageNo));

                        // console.log('=== loading from image push');

                        let success = await boardManager.loadImageInTargetCanvas(
                            boardManager.adminEmail,
                            drawing.pages[pageNo].download,
                        );
                        if (!success) {
                            // ctx.clearRect(0, 0, canvas.width, canvas.height);
                            await sleep(200);
                        }
                    }
                }

                if (window.checkIfStickyElementsToolPicker) {
                    window.checkIfStickyElementsToolPicker();
                }
                if (window.updateBoardNavigation) {
                    window.updateBoardNavigation();
                }
                store.dispatch(hideSpinner());
            }

            // let img = new Image();
            // let rect = canvas;
            // ctx.clearRect(0, 0, canvas.width, canvas.height);
            //
            // img.onload = () => {
            //     // moveUtils.zoomFunc(this);
            //
            //     ctx.drawImage(img, 0, 0, drawing.width, drawing.height, 0, 0, rect.width, rect.height);
            //     if (drawing.lineage) {
            //         //TODO : handle this better
            //         // boardManager.setLineage(drawing.lineage, target);
            //         // this.currentLiniage = drawing.lineage;
            //         // if (drawing.lineage !== LINEAGE_NONE) {
            //         //     boardManager.debounceCurrentLineage(target);
            //         //     // this.debounceLineage();
            //         // }
            //         // moveUtils.zoomFunc(this);
            //     }
            // };
            // img.src = drawing.data;
            // console.log('========= should be handling image push');
        } else if (drawing.tool === 'ELEMENT_OP') {
            // console.log('element op', drawing);
            if (drawing.operation === ELEMENT_OPERATIONS.ADD_STICKY_ELEMENT) {
                boardManager.addStickyElementFromRemote(drawing.element, target);
            } else if (drawing.operation === ELEMENT_OPERATIONS.UPDATE_STICKY_ELEMENT) {
                boardManager.updateStickyElementFromRemote(drawing.element, target);
            } else if (drawing.operation === ELEMENT_OPERATIONS.REMOVE_STICKY_ELEMENT) {
                boardManager.removeStickyElementFromRemote(drawing.element.id, target);
            }
            if (window.checkIfStickyElementsToolPicker) {
                window.checkIfStickyElementsToolPicker();
            }
        } else if (drawing.tool === 'TOOL_IMAGE_PASTE') {
            let img = new Image();
            let x1 = drawing.x1;
            let y1 = drawing.y1;
            let x2 = drawing.x2;
            let y2 = drawing.y2;

            img.onload = () => {
                let ctx = canvas.getContext('2d');
                ctx.globalCompositeOperation = 'source-over';
                ctx.imageSmoothingEnabled = true;
                ctx.imageSmoothingQuality = 'high';
                ctx.drawImage(img, x1, y1, Math.abs(x2 - x1), Math.abs(y2 - y1));
                // boardManager.debounceCurrentLineage();
                // this.debounceLineage();
            };
            img.src = drawing.image;
            // console.log('========= should be handling image push');
        }
        if (drawing.tool !== 'ELEMENT_OP') {
            boardManager.setDirtyFlag(target);
        }
        // this.isCurrentPageDirty = true;
        // this.debounceZoom();
        // moveUtils.zoomFunc(this);
    };

    // loadImage = () => {
    //     if (!this.allowDrawing) return;
    //     document.getElementById('imageLoader').click();
    // };

    clearImageHandler = async () => {
        if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
            await this.asyncClearImage(true);
            boardManager.setLineage(LINEAGE_NONE);
            await boardManager.clearElements();
            boardManager.setLineage(LINEAGE_NONE);
            boardManager.clearLinePoints(null, null, null);
            // this.saveCurrentLineage(LINEAGE_NONE);
            this.stickyComponent.current.setLineage(LINEAGE_NONE);
            // this.currentLiniage = LINEAGE_NONE;a
            boardManager.mainBoard.isCurrentPageDirty = true;
            await cloudBoardManager.saveCurrentMainPage();
            socketUtils.changeMainBoardState(
                boardManager.lessonState.mainBoardId,
                boardManager.lessonState.mainBoardPages,
            );
        } else {
            if (boardManager.isAdmin) {
                boardManager.clearMultiBoardPageForAdmin(boardManager.currentBoardActive);
                socketUtils.sendTargetedRequest(
                    [boardManager.currentBoardActive],
                    TARGETED_REQUESTS.CLEAR_USER_MULTIBOARD_PAGE,
                    {},
                );
            } else {
                store.dispatch(showSpinner());
                boardManager.clearMultiBoardPageForUser();
                socketUtils.sendTargetedRequest(
                    [boardManager.adminEmail],
                    TARGETED_REQUESTS.CLEAR_USER_MULTIBOARD_PAGE,
                    {},
                );
            }
        }
    };

    clearImage = (force = false) => {
        if (!this.allowDrawing && !force) return;
        if (!drawUtils.getCanvas()) return;
        let ctx = drawUtils.getCanvas().getContext('2d');
        ctx.clearRect(0, 0, drawUtils.CANVAS_ACTUAL_WIDTH, drawUtils.CANVAS_ACTUAL_HEIGHT);
        // moveUtils.zoomFunc(this);
    };

    asyncClearImage = (force = false, target = null) => {
        if (!this.allowDrawing && !force) return;
        if (!drawUtils.getCanvas(target)) return;
        let ctx = drawUtils.getCanvas(target).getContext('2d');
        return drawUtils.asyncClearCanvas(ctx);
    };

    switchLineageFromRemote = (lineage) => {
        if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
            boardManager.setLineage(lineage);
            this.stickyComponent.current.setLineage(lineage);
        }
    };

    switchLineage = (lineage) => {
        if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
            // this.saveCurrentLineage(lineage);
            boardManager.setLineage(lineage);
            let board = boardManager.getCurrentMainBoard();
            if (!board) {
                console.error('board is not present', boardManager.lessonState.mainBoardId);
                return;
            }
            cloudBoardManager.updatePage(
                boardManager.email,
                boardManager.classId,
                boardManager.lessonState.mainBoardId,
                {
                    stickyElements: board.meta.stickyElements,
                    gridType: board.meta.gridType,
                },
            );
            this.stickyComponent.current.setLineage(lineage);
            if (boardManager.isAdmin) {
                socketUtils.sendLineageChange(lineage);
            }
            // this.sendImageData(true);
        } else {
            // store.dispatch(showSpinner());
            if (!boardManager.isAdmin) {
                let myBoard = boardManager.lessonState.individualBoards[boardManager.adminEmail];
                myBoard.pages[myBoard.currentPageNo].meta.gridType = lineage;
                boardManager.multiBoardRefs[boardManager.adminEmail].current.setLineage(lineage);
                cloudBoardManager.updateMultiBoardPage(
                    boardManager.adminEmail,
                    boardManager.classId,
                    boardManager.lessonState.multiBoardId.split('_')[0],
                    boardManager.email,
                    myBoard.pages[myBoard.currentPageNo].id,
                    myBoard.pages[myBoard.currentPageNo].meta,
                );
            }

            socketUtils.sendTargetedRequest(
                [boardManager.adminEmail],
                TARGETED_REQUESTS.SWITCH_USER_MULTIBOARD_LINEAGE,
                { lineage },
            );
        }
    };

    saveImage = (filename = 'Image', data) => {
        document.getElementById('downloadImage').href = data;
        document.getElementById('downloadImage').download = filename;
        document.getElementById('downloadImage').click();
    };

    addToCollection = async ({ name, collectionId, description }) => {
        // console.log('==== board state', boardManager.mainBoard);
        // return Promise.resolve();

        let board = boardManager.getCurrentMainBoard();
        if (!board) return;

        return collectionsApi
            .addBoardToCollection(
                boardManager.getCurrentCanvas().toDataURL('image/png', 1),
                boardManager.classId,
                new uuidv4(),
                {
                    stickyElements: board.meta.stickyElements,
                    gridType: board.meta.gridType,
                },
                boardManager.lessonState.mainBoardId,
                name,
                description,
                collectionId,
            )
            .catch((err) => {
                if (err.response.status === 401) return;
                if (err.response.status === 403 && err.response.data?.availableSubscriptions) {
                    store.dispatch(
                        upgradeSubscriptionDialog({
                            key: 'subscription.upsell.library.exceeded',
                            subscriptions: err.response.data?.availableSubscriptions,
                        }),
                    );
                } else {
                    store.dispatch(showError('GENERIC_ERROR'));
                    console.error(err);
                }
            });
    };

    addAllToCollection = async ({ name, collectionId, description }) => {
        // console.log('==== board state', boardManager.mainBoard);
        // return Promise.resolve();

        await cloudBoardManager.saveCurrentMainPage();
        await collectionsApi.saveBoardSet(boardManager.classId, name, description, collectionId).catch((err) => {
            if (err.response.status === 401) return;
            if (err.response.status === 403 && err.response.data?.availableSubscriptions) {
                store.dispatch(
                    upgradeSubscriptionDialog({
                        key: 'subscription.upsell.library.exceeded',
                        subscriptions: err.response.data?.availableSubscriptions,
                    }),
                );
            } else {
                store.dispatch(showError('GENERIC_ERROR'));
                console.error(err);
            }
        });
    };

    updateToCollection = async (id) => {
        let board = boardManager.getCurrentMainBoard();
        if (!board) return;
        await collectionsApi.updateBoardData(
            boardManager.getCurrentCanvas().toDataURL('image/png', 1),
            boardManager.classId,
            id,
            { stickyElements: board.meta.stickyElements, gridType: board.meta.gridType },
            boardManager.lessonState.mainBoardId,
        );
    };

    handleCroppedImage = async (data, width, height) => {
        if (!this.allowDrawing && boardManager.getBoardType() === BOARD_TYPE.SINGLE_BOARD) return;
        let newData = await drawUtils.imageResize(data);

        this.ignoreBoardMoveAndClick = true;

        let img = new Image();
        let rect = drawUtils.getCurrentCanvasRect();
        img.onload = () => {
            if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
                // console.log(this.singleImageBox.current);
                let newWH = drawUtils.computeWithHeightWithAspectRatio(
                    newData.w,
                    newData.h,
                    rect.width * 0.8,
                    rect.height * 0.8,
                );

                this.addStickyImage(img, 24, 24, newWH.width, newWH.height).then(() => {
                    store.dispatch(changeTool(TOOL_SELECT));
                    this.switchToStickySelection(true);
                    this.toolSelectionCallback(TOOL_SELECT);
                });

                // this.singleImageBox.current.addImage(img, newWH.width, newWH.height, rect.width, rect.height);
            } else {
                let newWH = drawUtils.computeWithHeightWithAspectRatio(
                    newData.w,
                    newData.h,
                    rect.width * 0.8,
                    rect.height * 0.8,
                );

                this.addStickyImage(img, 24, 24, newWH.width, newWH.height).then(() => {
                    store.dispatch(changeTool(TOOL_SELECT));
                    this.switchToStickySelection(true);
                    this.toolSelectionCallback(TOOL_SELECT);
                });

                // this.multiImageBox.current.addImage(
                //     img,
                //     Math.min(width, rect.width * 0.8),
                //     Math.min(height, rect.height * 0.8),
                //     rect.width,
                //     rect.height
                // );
            }
        };
        img.src = newData.data;
    };

    handleTextStart = async (x1, y1) => {
        let h =
            2 * (window.zoomState.zoomLevel + 1) * drawUtils.computeFontPixelForDisplay(this.props.drawing.toolWeight);
        let w = 200;

        let reverseScaledPoint = drawUtils.scalePoint(this.startPoint, x1, y1);
        let scaledWidth = drawUtils.scalePoint({ x: 0, y: 0 }, w, h);
        let wh = {
            width: scaledWidth.x,
            height: scaledWidth.y,
        };

        let el = await boardManager.addLocalStickyText(
            { text: '' },
            reverseScaledPoint,
            wh,
            this.props.drawing.textWeight,
            this.props.drawing.color,
        );
        await sleep(100);
        let textBox = document.getElementById(`text-area-sticky-${el.id}`);
        if (textBox) {
            textBox.focus();
            textBox.click();
        }
    };

    handleTextType = (event) => {
        if (this.isWriting) {
            if (event.keyCode === 27) {
                this.handleTextClose();
            }
        }
    };

    handleTextCommit = () => {
        let ref;
        if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
            ref = this.textAreaBoxRef.current;
        } else {
            ref = this.multiTextAreaBoxRef.current;
        }

        let textState = ref.getText();
        // console.log('=== commiting', textState);
        if (textState.text && textState.text.trim() !== '') {
            let reverseScaledPoint = drawUtils.scalePoint(this.startPoint, textState.xy.x, textState.xy.y);
            let scaledWidth = drawUtils.scalePoint({ x: 0, y: 0 }, textState.wh.width, textState.wh.height);
            let wh = {
                width: scaledWidth.x,
                height: scaledWidth.y,
            };

            boardManager.addLocalStickyText(
                textState,
                reverseScaledPoint,
                wh,
                this.props.drawing.textWeight,
                this.props.drawing.color,
            );
        }
        this.handleTextClose();

        // if (!this.getTextValue() || this.getTextValue().trim() === '') {
        //     return;
        // }
        // let height = drawUtils.computeFontPixel(this.props.drawing.textWeight);
        // let input = document.getElementById('textInput').getBoundingClientRect();
        // let rect = drawUtils.getCurrentCanvasRect();
        // let scaledSize = drawUtils.scalePoint(this.startPoint, input.right - rect.left, input.bottom - rect.top);
        // if (!this.scaledDownCoords) {
        //     return;
        // }
        //
        // this.drawLine(
        //     this.scaledDownCoords.x,
        //     this.scaledDownCoords.y + height,
        //     scaledSize.x - this.scaledDownCoords.x,
        //     this.scaledDownCoords.y - scaledSize.y,
        //     true
        // );
        // // moveUtils.zoomFunc(this);
    };

    handleTextClose = (e) => {
        // console.log('=== should be closing text');
        if (e && e.preventDefault) {
            e.preventDefault();
            e.stopPropagation();
        }
        if (this.textAreaBoxRef?.current) {
            if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD && this.textAreaBoxRef.current) {
                this.textAreaBoxRef.current.closeText();
            } else {
                this.multiTextAreaBoxRef.current.closeText();
            }
        }
        this.isWriting = false;

        // let inputContainer = document.getElementById('textInputContainer');
        // let input = document.getElementById('textInput');
        // inputContainer.style.display = 'none';
        // input.value = '';
        // // let inputDiv = document.getElementById('inputTextDiv');
        // // inputDiv.innerHTML = '';
        // this.isWriting = false;
        // window['updateTextInputExpand']({
        //     visible: false,
        //     axis: 'x',
        //     defaultPosition: { x: 0, y: 0 },
        //     bounds: {
        //         left: 0,
        //         top: 0,
        //         right: 0,
        //         bottom: 0,
        //     },
        // });
        // window['updateTextInputMove']({
        //     visible: false,
        //     axis: 'x',
        //     defaultPosition: { x: 0, y: 0 },
        //     bounds: {
        //         left: 0,
        //         top: 0,
        //         right: 0,
        //         bottom: 0,
        //     },
        // });
    };

    loadMultiBoardInLesson = async (classId, multiBoardId, target, originalDownload, pageNo) => {
        // console.log('==== trying to load for ', classId, multiBoardId, target);
        store.dispatch(showSpinner());

        let multiBoardData = lessonList.getMultiBoardElements(boardManager.email, classId, multiBoardId);
        if (
            !(
                multiBoardData &&
                multiBoardData.users &&
                multiBoardData.users[target] &&
                multiBoardData.users[target].pages &&
                multiBoardData.users[target].pages.length > pageNo
            )
        ) {
            store.dispatch(showError());
            store.dispatch(hideSpinner());
            return;
        }

        // let multiBoardElements = lessonList.getMultiBoardLesson(boardManager.email, classId, multiBoardId);

        // console.log('====== loading', classId, multiBoardId, target, originalDownload, pageNo, multiBoardData);
        // return;

        let newLessonState = {
            gridType: multiBoardData.users[target].pages[pageNo].gridType,
            stickyElements: [],
        };

        let elementsForCopy = {
            originalMultiBoard: [],
            userElements: [],
            userId: target,
        };

        if (pageNo === 0 && multiBoardData.originalStickyElements) {
            multiBoardData.originalStickyElements.forEach((element) => {
                if (element.stickyType === STICKY_ELEMENTS_TYPE.IMAGE) {
                    let newID = uuidv4();
                    let newEl = lessonList.buildImageElement(
                        newID,
                        element.x,
                        element.y,
                        element.width,
                        element.height,
                        element.creator,
                    );
                    newLessonState.stickyElements.push(newEl);
                    elementsForCopy.originalMultiBoard.push({ originalId: element.id, newId: newID });
                } else {
                    let newEl = lessonList.buildTextElement(
                        element.text,
                        element.x,
                        element.y,
                        element.width,
                        element.height,
                        element.weight,
                        element.color,
                        element.creator,
                    );
                    newLessonState.stickyElements.push(newEl);
                }
            });
        }

        multiBoardData.users[target].pages[pageNo].stickyElements.forEach((element) => {
            if (element.stickyType === STICKY_ELEMENTS_TYPE.IMAGE) {
                let newID = uuidv4();
                let newEl = lessonList.buildImageElement(
                    newID,
                    element.x,
                    element.y,
                    element.width,
                    element.height,
                    element.creator,
                );
                newLessonState.stickyElements.push(newEl);
                elementsForCopy.userElements.push({ originalId: element.id, newId: newID });
            } else {
                let newEl = lessonList.buildTextElement(
                    element.text,
                    element.x,
                    element.y,
                    element.width,
                    element.height,
                    element.weight,
                    element.color,
                    element.creator,
                );
                newLessonState.stickyElements.push(newEl);
            }
        });

        let currentLessonNo = store.getState().lessonState.currentLessonPage;
        await cloudBoardManager.saveCurrentMainPage();
        // await lessonList.saveLesson(
        //     boardManager.email,
        //     classId,
        //     currentLessonNo,
        //     boardManager.getLineage(),
        //     !boardManager.isCurrentPageDirty()
        // );
        await this.asyncClearImage();
        // await lessonList.saveLesson(
        //     this.props.profile.email,
        //     this.props.match.params.classId,
        //     currentLessonNo + 1,
        //     newLessonState.gridType
        // );
        let lesson = lessonList.getLessonForEmail(boardManager.email, classId);
        store.dispatch(loadLessonPages(lesson.pages));
        store.dispatch(switchLessonPage(currentLessonNo + 1));
        boardManager.currentLessonPage = currentLessonNo + 1;
        let lessonId = lessonList.getLessonId(
            this.props.profile.email,
            this.props.match.params.classId,
            boardManager.currentLessonPage,
        );
        boardManager.mainBoard.boardId = lessonId;

        let urlsData = await storageApi.loadMultiBoardSession(classId, multiBoardId, lessonId, elementsForCopy);
        urlsData.data.originalMultiBoard.forEach((element) => {
            addToWindowCache(boardManager.email, boardManager.classId, lessonId, element.newId, element.url);
            let elemIndex = newLessonState.stickyElements.findIndex((el) => el.id === element.newId);
            if (elemIndex >= 0) {
                newLessonState.stickyElements[elemIndex].url = element.url;
            }
        });
        urlsData.data.userElements.forEach((element) => {
            addToWindowCache(boardManager.email, boardManager.classId, lessonId, element.newId, element.url);
            let elemIndex = newLessonState.stickyElements.findIndex((el) => el.id === element.newId);
            if (elemIndex >= 0) {
                newLessonState.stickyElements[elemIndex].url = element.url;
            }
        });

        lessonList.saveStickyLessonForEmail(boardManager.email, boardManager.classId, lessonId, {
            stickyElements: newLessonState.stickyElements,
        });

        let currentData = await getItem(multiBoardData.users[target].pages[pageNo].id);
        let updatedDrawing;
        if (pageNo === 0) {
            updatedDrawing = drawUtils.buildComposedImageFromData(currentData, originalDownload);
        } else {
            updatedDrawing = drawUtils.buildComposedImageFromData(currentData);
        }

        await updateItem(lessonId, updatedDrawing);

        await this.loadLessonPage(boardManager.currentLessonPage);
        store.dispatch(hideSpinner());
    };

    loadCollectionInLesson = async (collectionIds, userCollectionIds) => {
        if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
            if (boardManager.lessonState.mainBoardPages.length + collectionIds.length > MAX_MAIN_PAGES) {
                store.dispatch(showInfo('LOADING_TOO_MANY_BOARDS', false, { count: MAX_MAIN_PAGES }));
                return;
            }
            store.dispatch(showSpinner());

            // let currentLessonNo = store.getState().lessonState.currentLessonPage;
            await cloudBoardManager.saveCurrentMainPage();
            // let mainList = boardManager.mainBoardPages;
            // currentLessonNo = mainList.length;

            for (let i = 0; i < collectionIds.length; i++) {
                if (collectionIds[i]) {
                    window.logAppActivity(GA_EVENT_TREE.lessonPage.menuActions.load_collection_in_main);
                    await collectionsApi.loadBoardInClass(this.props.match.params.classId, collectionIds[i]);
                }
            }

            for (let i = 0; i < userCollectionIds.length; i++) {
                if (userCollectionIds[i]) {
                    window.logAppActivity(GA_EVENT_TREE.lessonPage.menuActions.load_shared_collection_in_main);
                    await collectionsApi.loadSharedBoardInClass(this.props.match.params.classId, userCollectionIds[i]);
                }
            }

            let pages = await cloudBoardManager.getLessonPages(
                this.props.profile.email,
                this.props.match.params.classId,
            );
            let pageIds = pages.map((p) => p.id);
            boardManager.lessonState.mainBoardPages = pages;

            store.dispatch(loadLessonPages(pageIds));
            let loadSuccess = this.loadLessonPage(pages.length - 1);
            if (!loadSuccess) {
                store.dispatch(showError('GENERIC_ERROR'));
                return;
            }
            if (boardManager.lessonState) {
                socketUtils.changeMainBoardState(
                    boardManager.lessonState.mainBoardId,
                    boardManager.lessonState.mainBoardPages,
                );
            }
        } else {
            if (boardManager.currentBoardActive) {
                // console.log('=== loading', collectionIds, userCollectionIds);
                let s = store.getState();
                let canMultiple = hasBooleanPermission(
                    s.mainAppState.subscriptions,
                    s.profile.subscription,
                    CAPABILITIES.MULTIPLE_INDIVIDUAL_BOARDS,
                );

                if (!canMultiple.value) {
                    store.dispatch(
                        upgradeSubscriptionDialog({
                            key: 'subscription.upsell.multiBoards.unavailable',
                            subscriptions: canMultiple.availableSubscriptions,
                        }),
                    );
                    return;
                }

                if (
                    boardManager.lessonState.individualBoards[boardManager.currentBoardActive].pages.length +
                    collectionIds.length >
                    MAX_MULTI_PAGES
                ) {
                    store.dispatch(showInfo('LOADING_TOO_MANY_BOARDS', false, { count: MAX_MULTI_PAGES }));
                    return;
                }

                store.dispatch(showSpinner());
                for (let i = 0; i < collectionIds.length; i++) {
                    if (collectionIds[i]) {
                        window.logAppActivity(GA_EVENT_TREE.lessonPage.menuActions.load_collection_in_individual);
                        await collectionsApi.loadBoardInMultiBoardClass(
                            this.props.match.params.classId,
                            boardManager.lessonState.multiBoardId,
                            collectionIds[i],
                            { email: boardManager.currentBoardActive },
                        );
                    }
                }
                for (let i = 0; i < userCollectionIds.length; i++) {
                    if (userCollectionIds[i]) {
                        window.logAppActivity(GA_EVENT_TREE.lessonPage.menuActions.load_shared_collection_in_individual);
                        await collectionsApi.loadUserBoardInMultiBoardClass(
                            this.props.match.params.classId,
                            boardManager.lessonState.multiBoardId,
                            userCollectionIds[i],
                            { email: boardManager.currentBoardActive },
                        );
                    }
                }
                let data = await cloudBoardManager.getMultiBoardUserPages(
                    boardManager.currentBoardActive,
                    boardManager.classId,
                    boardManager.lessonState.multiBoardId.split('_')[0],
                    boardManager.email,
                );
                boardManager.lessonState.individualBoards[boardManager.currentBoardActive].pages = data.pages;
                socketUtils.sendTargetedRequest(
                    [boardManager.currentBoardActive],
                    TARGETED_REQUESTS.UPDATE_MULTIBOARD_SET,
                    {
                        currentPageNo:
                        boardManager.lessonState.individualBoards[boardManager.currentBoardActive].currentPageNo,
                        pages: boardManager.lessonState.individualBoards[boardManager.currentBoardActive].pages,
                    },
                );
                // socketUtils.sendJoinImageData(
                //     drawUtils.CANVAS_ACTUAL_WIDTH,
                //     drawUtils.CANVAS_ACTUAL_HEIGHT,
                //     boardManager.lessonState.individualBoards[boardManager.currentBoardActive].pages[
                //         boardManager.lessonState.individualBoards[boardManager.currentBoardActive].currentPageNo
                //     ].url,
                //     [boardManager.currentBoardActive],
                //     boardManager.originalMultiBoardState.originalLineage,
                //     boardManager.lessonState.individualBoards[boardManager.currentBoardActive].stickyElements,
                //     boardManager.lessonState.individualBoards[boardManager.currentBoardActive].boardId,
                //     {
                //         originalUrl: boardManager.originalMultiBoardState.originalDownload,
                //         originalSticky: boardManager.originalMultiBoardState.originalSticky,
                //         originalLineage: boardManager.originalMultiBoardState.originalLineage,
                //     },
                //     boardManager.lessonState.individualBoards[boardManager.currentBoardActive].pages
                // );
                if (window.updateBoardNavigation) {
                    window.updateBoardNavigation();
                }
                if (window.checkIfStickyElementsToolPicker) {
                    window.checkIfStickyElementsToolPicker();
                }
                if (window[`targetMultiBoardPageCount${boardManager.currentBoardActive}`]) {
                    window[`targetMultiBoardPageCount${boardManager.currentBoardActive}`]();
                }

                store.dispatch(showStaticPageNavigator(null));
                boardManager.refreshStickyElements();

                store.dispatch(hideSpinner());
            }
        }
    };

    render() {
        return (
            <>
                <div id='lessonPage' style={noSelectStyle}>
                    <canvas id='googleSearchCanvasId' style={{ display: 'none' }} />
                    <div
                        style={{
                            display: 'flex',
                            // justifyContent: 'space-between',
                            alignItems: 'center',
                            paddingLeft: '3rem',
                            paddingRight: '0.5rem',
                            paddingTop: '0.5rem',
                        }}
                    >
                        <Paper
                            className='u-fx u-fx-align-center'
                            style={{ padding: '0.1rem 0.5rem 0.1rem 0.5rem', marginLeft: '0.5rem' }}
                        >
                            <VBIcon style={{ height: '2.2rem', width: '2.2rem' }} />
                            {this.props.lessonState.lessonInfo && this.props.lessonState.lessonInfo.classData ? (
                                <Typography
                                    variant='h5'
                                    style={{
                                        margin: '0 0 0 0.5rem',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        maxWidth: '10rem',
                                    }}
                                >
                                    {this.props.lessonState.lessonInfo.classData.name}
                                </Typography>
                            ) : null}
                        </Paper>
                        <div id='horizontalSlider' style={{ flexGrow: '1' }}>
                            <HorizontalSlider updateValue={this.moveHoriz} />
                        </div>
                        <Paper className='u-fx u-fx-align-center'>
                            <ActionButtons />
                            <MicBox />
                        </Paper>
                    </div>
                    <Container disableGutters fixed style={{ margin: '0px', maxWidth: '100%', touchAction: 'none' }}>
                        <div style={{ display: 'inline-flex', width: '100%' }}>
                            <div id='verticalSlider' style={{ display: 'inline-block' }}>
                                <VerticalSlider updateValue={this.moveVert} />
                            </div>
                            <div style={eraserOverlay} id='eraserOverlay' />
                            <div id='multiBoardContainer' style={multiBoardContainer}>
                                <MultiBoardComponent parent={this} />
                                <ImageBox
                                    ref={this.multiImageBox}
                                    handleImageUpdate={this.handleImageUpdate}
                                    addStickyImage={this.addStickyImage}
                                />
                            </div>

                            <div style={canvasContainer} id='canvasContainer'>
                                <ProtractorComponent
                                    ref={this.protractorElement}
                                    updateState={(state) => {
                                        if (this.rulerElement.current && this.compassElement.current) {
                                            let geometry = {
                                                protractor: state,
                                                ruler: this.rulerElement.current.getState(),
                                                compass: this.compassElement.current.getState(),
                                            };
                                            socketUtils.updateMainGeometry(geometry);
                                            if (
                                                boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD &&
                                                this.stickyComponent.current &&
                                                this.allowDrawing
                                            ) {
                                                this.stickyComponent.current.updateGeometryState({
                                                    protractor: state,
                                                    ruler: this.rulerElement.current.getState(),
                                                    compass: this.compassElement.current.getState(),
                                                });
                                            }
                                        }
                                    }}
                                />
                                <RulerComponent
                                    ref={this.rulerElement}
                                    updateState={(state) => {
                                        if (this.protractorElement.current && this.compassElement.current) {
                                            let geometry = {
                                                ruler: state,
                                                protractor: this.protractorElement.current.getState(),
                                                compass: this.compassElement.current.getState(),
                                            };
                                            socketUtils.updateMainGeometry(geometry);
                                            if (
                                                boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD &&
                                                this.stickyComponent.current &&
                                                this.allowDrawing
                                            ) {
                                                this.stickyComponent.current.updateGeometryState({
                                                    ruler: state,
                                                    protractor: this.protractorElement.current.getState(),
                                                    compass: this.compassElement.current.getState(),
                                                });
                                            }
                                        }
                                    }}
                                />

                                <CompassComponent
                                    ref={this.compassElement}
                                    updateState={(state) => {
                                        if (this.protractorElement.current && this.rulerElement.current) {
                                            let geometry = {
                                                compass: state,
                                                protractor: this.protractorElement.current.getState(),
                                                ruler: this.rulerElement.current.getState(),
                                            };
                                            socketUtils.updateMainGeometry(geometry);
                                            if (
                                                boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD &&
                                                this.stickyComponent.current &&
                                                this.allowDrawing
                                            ) {
                                                this.stickyComponent.current.updateGeometryState({
                                                    compass: state,
                                                    protractor: this.protractorElement.current.getState(),
                                                    ruler: this.rulerElement.current.getState(),
                                                });
                                            }
                                        }
                                    }}
                                    moveArch={(x, y, width, direction, start, end) => {
                                        moveUtils.moveArc(x, y, width, direction, start, end, this);
                                    }}
                                    addArch={(position, width, direction, start, end) => {
                                        boardManager.clearAuxDrawing();
                                        this.drawArc(position.x, position.y, width, direction, start, end);
                                    }}
                                />

                                <canvas
                                    id='canvas'
                                    draggable={false}
                                    width={drawUtils.CANVAS_ACTUAL_WIDTH}
                                    height={drawUtils.CANVAS_ACTUAL_HEIGHT}
                                    style={{
                                        position: 'absolute',
                                        width: '100%',
                                        // height: '100%',
                                        transform: 'translate3d(0px, 0px, 0px)',
                                    }}
                                />
                                <div
                                    id='main-svg-container'
                                    style={{
                                        position: 'absolute',
                                        // height: '100%',
                                        width: '100%',
                                        transform: 'translate3d(0px, 0px, 0px)',
                                        zIndex: '-1',
                                    }}
                                >
                                    <svg
                                        // style={{ height: '100%', width: '100%' }}
                                        id='main-writing-svg'
                                        viewBox={`0 0 ${drawUtils.CANVAS_ACTUAL_WIDTH} ${drawUtils.CANVAS_ACTUAL_HEIGHT}`}
                                    >
                                        <path id='currentWritingMain' />
                                    </svg>
                                </div>
                                <ImageBox
                                    ref={this.singleImageBox}
                                    handleImageUpdate={this.handleImageUpdate}
                                    addStickyImage={this.addStickyImage}
                                />
                                <StickyElements
                                    ref={this.stickyComponent}
                                    updateStickyImagePosition={boardManager.updateLocalStickyImagePosition}
                                    removeStickyElement={boardManager.removeLocalStickyElement}
                                    updateStickyTextInfo={boardManager.updateLocalStickyTextInfo}
                                    updateLatexElement={boardManager.updateLocalStickyLatexInfo}
                                    setWriting={this.setWriting}
                                />
                            </div>
                            <ToolPickerFloat switchLineage={this.switchLineage} />
                        </div>
                        <a style={{ display: 'none' }} id='downloadImage' download='myCanvas.png' />

                        <div id='remoteVideoList' style={remoteVideoList}>
                            <video
                                id='localVideo'
                                autoPlay
                                muted
                                playsInline
                                style={localVideo}
                                poster='/noVideo.jpg'
                            />
                        </div>
                        <div
                            id='rectangle'
                            style={rectangleStyle}
                            onClick={(e) => {
                                e.preventDefault();
                            }}
                            onMouseDown={(e) => {
                                e.preventDefault();
                            }}
                            onTouchEnd={this.windowMouseUp}
                            onTouchMove={this.handleTouchMove}
                            onMouseMove={this.moveZoomer}
                            onMouseUp={this.windowMouseUp}
                            draggable='false'
                        />
                        <div
                            id='circle'
                            style={circleStyle}
                            onClick={(e) => {
                                e.preventDefault();
                            }}
                            onMouseDown={(e) => {
                                e.preventDefault();
                            }}
                            onTouchEnd={this.windowMouseUp}
                            onTouchMove={this.handleTouchMove}
                            onMouseMove={this.moveZoomer}
                            onMouseUp={this.windowMouseUp}
                            draggable='false'
                        />
                        <canvas
                            id='line'
                            style={singleLineStyle}
                            onClick={(e) => {
                                e.preventDefault();
                            }}
                            onMouseDown={(e) => {
                                e.preventDefault();
                            }}
                            onTouchEnd={this.windowMouseUp}
                            onTouchMove={this.handleTouchMove}
                            onPointerMove={this.moveZoomer}
                            onPointerUp={this.windowMouseUp}
                            draggable='false'
                        />
                    </Container>
                </div>
                <div
                    id='resizeMessage'
                    style={{
                        animation: 'wiggle 2s linear',
                        display: 'none',
                        textAlign: 'center',
                        flexDirection: 'column',
                        justifyContent: 'end',
                        verticalAlign: 'middle',
                        position: 'absolute',
                        bottom: '4rem',
                        zIndex: '-1',
                    }}
                >
                    <ScreenRotationIcon style={{ fontSize: '8rem', width: '100%' }} />
                    <Typography variant='h2'>
                        <FormattedMessage id='rotateForExperience' />
                    </Typography>
                </div>
                <UsersButton />
                {/*<div*/}
                {/*    id="imgLog"*/}
                {/*    style={{*/}
                {/*        position: 'fixed',*/}
                {/*        left: '10px',*/}
                {/*        top: '300px',*/}
                {/*        border: 'solid',*/}
                {/*        borderColor: 'red',*/}
                {/*        padding: '5px',*/}
                {/*        height: '200px',*/}
                {/*    }}*/}
                {/*/>*/}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const { profile, drawing, lessonState } = state;
    return { profile, drawing, lessonState };
};

export default withRouter(injectIntl(connect(mapStateToProps)(LessonPage)));
