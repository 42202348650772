import React from 'react';
import { injectIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { ONBOARDING_STEPS } from '../../shared/OnboardingOptions';
import { SUBSCRIPTION_PLANS } from '../../shared/Capabilities';
import StartStage from './StartStage';
import { parseQuery } from '../../common/utils';
import CancelStage from './Stages/CancelStage';
import Profile from '../Profile/Profile';
import { PROFILE_LINK } from '../../common/urlLinks';
import { GA_EVENT_TREE } from '../../gaConstants';
import Subscriptions from '../Subscriptions/Subscriptions';

function buildState(queryData) {
    let state = {
        billAnnual: false,
        billEur: true,
        step: ONBOARDING_STEPS.START,
        subscription: SUBSCRIPTION_PLANS.FREE,
    };

    if (!queryData) return state;

    if (queryData.step && ONBOARDING_STEPS[queryData.step]) {
        state.step = queryData.step;
    }
    if (queryData.subscription && SUBSCRIPTION_PLANS[queryData.subscription]) {
        state.subscription = queryData.subscription;
    }
    if (queryData.billAnnual && queryData.billAnnual.toLowerCase() === 'true') {
        queryData.billAnnual = true;
    } else {
        queryData.billAnnual = false;
    }
    if (queryData.billEur && queryData.billEur.toLowerCase() === 'false') {
        queryData.billEur = false;
    } else {
        queryData.billEur = true;
    }

    return queryData;
}

const Onboarding = ({ profile, language, handleOpen, open, handleLogin }) => {
    const location = useLocation();
    const history = useHistory();

    let queryObj = parseQuery(location.search);

    const state = buildState(queryObj);

    if (!profile.loggedIn) {
        if (state.step === ONBOARDING_STEPS.START) {
            return <StartStage state={state} profile={profile} language={language} />;
        } else {
            return <Subscriptions language={language} handleOpen={handleOpen} open={open} handleLogin={handleLogin} />;
        }
    }

    if (state.subscription === SUBSCRIPTION_PLANS.FREE) {
        window.logAppActivity(GA_EVENT_TREE.mainPage.onboarding.startFree);
        history.push(PROFILE_LINK);
    }

    if (state.step === ONBOARDING_STEPS.START) {
        return <StartStage state={state} profile={profile} language={language} />;
    }

    if (state.step === ONBOARDING_STEPS.CANCEL) {
        return <CancelStage />;
    }

    return (
        <React.Fragment>
            <Profile />
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    const { profile, mainAppState } = state;
    return { profile, subscriptions: mainAppState.subscriptions, pricing: mainAppState.pricing };
};

export default injectIntl(connect(mapStateToProps)(Onboarding));
