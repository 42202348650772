import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function LoginSmallIcon(props) {
    return (
        <SvgIcon {...props} style={{ verticalAlign: 'bottom' }}>
            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="31" height="31" rx="7.5" stroke="#065156" strokeOpacity="0.24" />
                <g clipPath="url(#clip0_108_315)">
                    <path
                        d="M18.5 12V12.5C18.5 13.8812 17.3813 15 16 15C14.6188 15 13.5 13.8812 13.5 12.5V12C13.5 11.8281 13.5188 11.6625 13.55 11.5H14.5C15.4313 11.5 16.2469 10.9906 16.675 10.2344C17.0406 10.7 17.6094 11 18.25 11H18.2906C18.425 11.3063 18.5 11.6437 18.5 12ZM12 12V12.5C12 14.7094 13.7906 16.5 16 16.5C18.2094 16.5 20 14.7094 20 12.5V12C20 9.79063 18.2094 8 16 8C13.7906 8 12 9.79063 12 12ZM15.5906 20.6719L13.7125 18.0094C11.0813 18.1781 9 20.3656 9 23.0406C9 23.5719 9.43125 24 9.95938 24H22.0406C22.5719 24 23 23.5687 23 23.0406C23 20.3656 20.9188 18.1781 18.2875 18.0125L16.4094 20.675C16.2094 20.9563 15.7906 20.9563 15.5938 20.675L15.5906 20.6719Z"
                        fill="#08AAB4"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_108_315">
                        <rect width="14" height="16" fill="white" transform="translate(9 8)" />
                    </clipPath>
                </defs>
            </svg>
        </SvgIcon>
    );
}
