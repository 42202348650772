import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function DescriptionListIconBasicSubscription(props) {
    return (
        <SvgIcon {...props} style={{ verticalAlign: 'bottom' }}>
            <svg viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.00024 1.99951C5.00024 0.896387 5.89712 -0.000488281 7.00024 -0.000488281H18.0002C19.1034 -0.000488281 20.0002 0.896387 20.0002 1.99951V10.9995C20.0002 12.1026 19.1034 12.9995 18.0002 12.9995H10.5252C10.1565 12.2026 9.59087 11.5151 8.88774 10.9995H12.0002V9.99951C12.0002 9.44639 12.4471 8.99951 13.0002 8.99951H15.0002C15.5534 8.99951 16.0002 9.44639 16.0002 9.99951V10.9995H18.0002V1.99951H7.00024V3.53389C6.41274 3.19326 5.72837 2.99951 5.00024 2.99951V1.99951ZM5.00024 9.99951C3.34399 9.99951 2.00024 8.65576 2.00024 6.99951C2.00024 5.34326 3.34399 3.99951 5.00024 3.99951C6.65649 3.99951 8.00024 5.34326 8.00024 6.99951C8.00024 8.65576 6.65649 9.99951 5.00024 9.99951ZM4.16587 10.9995H5.83149C8.13462 10.9995 10.0002 12.8651 10.0002 15.1651C10.0002 15.6245 9.62837 15.9995 9.16587 15.9995H0.834619C0.372119 15.9995 0.000244141 15.6276 0.000244141 15.1651C0.000244141 12.8651 1.86587 10.9995 4.16587 10.9995Z" fill="rgba(111, 111, 111, 0.5)"/>
            </svg>
        </SvgIcon>
    );
}

