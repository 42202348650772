import {
    UPDATE_PROFILE,
    UPDATE_EXTENDED_PROFILE,
    UPDATE_USER_PREFERENCES,
    UPDATE_USER_SUBSCRIPTION,
    UPDATE_ALLOWED_GROUPS,
    UPDATE_ALLOWED_BOARDS,
    SHOW_CONSENT,
    UPDATE_USER_CONSENT,
} from '../actionTypes';
import { USER_TYPE_MEMBER } from '../../constants';

const initialState = {
    name: 'Guest',
    email: 'noemail@email.com',
    type: USER_TYPE_MEMBER,
    avatar: null,
    loggedIn: false,
    extended: {},
    preferences: {},
    subscription: {},
    allowedGroups: null,
    allowedBoards: null,
    openConsent: false,
    consent: null,
};

const profile = function (state = initialState, action) {
    switch (action.type) {
        case UPDATE_PROFILE: {
            const { name, email, picture } = action.payload;
            return {
                ...state,
                name: name,
                email: email,
                type: USER_TYPE_MEMBER,
                avatar: picture,
                loggedIn: true,
            };
        }
        case UPDATE_EXTENDED_PROFILE: {
            return {
                ...state,
                extended: action.payload,
            };
        }
        case UPDATE_USER_PREFERENCES: {
            return {
                ...state,
                preferences: action.payload,
            };
        }
        case UPDATE_USER_SUBSCRIPTION: {
            return {
                ...state,
                subscription: action.payload,
            };
        }
        case UPDATE_USER_CONSENT: {
            return {
                ...state,
                consent: action.payload,
            };
        }
        case UPDATE_ALLOWED_GROUPS: {
            return {
                ...state,
                allowedGroups: action.payload,
            };
        }
        case UPDATE_ALLOWED_BOARDS: {
            return {
                ...state,
                allowedBoards: action.payload,
            };
        }
        case SHOW_CONSENT: {
            return {
                ...state,
                openConsent: action.payload,
            };
        }
        default:
            return state;
    }
};

export default profile;
