import apiUtil from './apiUtil';
import lessonList from '../pages/LessonPage/lessonList';
import store from '../redux/store';

let classApi = {};

classApi.createGroup = function (data) {
    let myData = {
        url: '/api/private/class',
        method: 'POST',
        data,
    };
    return apiUtil.runRequest(myData);
};

classApi.updateClass = function (id, data) {
    let myData = {
        url: '/api/private/class/' + id,
        method: 'PUT',
        data,
    };
    return apiUtil.runRequest(myData);
};

classApi.deleteClass = function (id) {
    let myData = {
        url: '/api/private/class/' + id,
        method: 'DELETE',
    };
    return apiUtil.runRequest(myData).then((res) => {
        lessonList.cleanLesson(id, store.getState().profile.email);
        return res;
    });
};

classApi.getMyClasses = function () {
    let myData = {
        url: '/api/private/class',
    };

    return apiUtil.runRequest(myData).then((res) => {
        lessonList.cleanLessons(
            res.data.map((el) => el.id),
            store.getState().profile.email
        );
        return res;
    });
};

classApi.getMyClassesCount = function () {
    let myData = {
        url: '/api/private/classcount',
    };
    return apiUtil.runRequest(myData);
};

classApi.getClass = function (classId) {
    let myData = {
        url: '/api/private/class/' + classId,
    };
    return apiUtil.runRequest(myData);
};

classApi.getClassMembers = function (classId) {
    let myData = {
        url: '/api/private/classmembers/' + classId,
    };
    return apiUtil.runRequest(myData);
};

classApi.addClassMember = function (classId, data) {
    let myData = {
        url: '/api/private/classmembers/' + classId,
        method: 'POST',
        data,
    };
    return apiUtil.runRequest(myData);
};

classApi.removeClassMember = function (classId, memberId) {
    let myData = {
        url: '/api/private/classmembers/' + classId + '/' + memberId,
        method: 'DELETE',
    };
    return apiUtil.runRequest(myData);
};

classApi.getClasses = function () {
    let myData = {
        url: '/api/private/joinedclasses',
    };
    return apiUtil.runRequest(myData);
};

classApi.getClassesCount = function () {
    let myData = {
        url: '/api/private/joinedclassescount',
    };
    return apiUtil.runRequest(myData);
};

classApi.canIJoin = function (classId) {
    let myData = {
        url: '/api/private/canIJoin/' + classId,
    };
    return apiUtil.runRequest(myData);
};

classApi.migrateClass = function (id, data) {
    let myData = {
        url: '/api/private/migrateClass/' + id,
        data,
        method: 'POST',
    };
    return apiUtil.runRequest(myData);
};

classApi.getClassPages = function (id) {
    let myData = {
        url: '/api/private/cloud-boards/main-board/' + id,
    };
    return apiUtil.runRequest(myData);
};

classApi.updateClassPage = function (classId, pageId, data) {
    let myData = {
        url: `/api/private/cloud-boards/main-board/${classId}/${pageId}`,
        data,
        method: 'PUT',
    };
    return apiUtil.runRequest(myData);
};

classApi.removeClassPage = function (classId, pageId) {
    let myData = {
        url: `/api/private/cloud-boards/main-board/${classId}/${pageId}`,
        method: 'DELETE',
    };
    return apiUtil.runRequest(myData);
};

classApi.getMultiBoardUserPages = function (classId, multiBoardId, target) {
    let myData = {
        url: `/api/private/cloud-boards/multiboard/${classId}/${multiBoardId}`,
        data: { email: target },
        method: 'POST',
    };
    return apiUtil.runRequest(myData);
};

classApi.getMultiBoards = function (classId) {
    let myData = {
        url: `/api/private/cloud-boards/multiboard/${classId}`,
        method: 'GET',
    };
    return apiUtil.runRequest(myData);
};

classApi.deleteMultiBoards = function (classId, multiboardId) {
    let myData = {
        url: `/api/private/cloud-boards/multiboard/${classId}/${multiboardId}`,
        method: 'DELETE',
    };
    return apiUtil.runRequest(myData);
};

classApi.updateMultiBoardUserPageByUser = function (classId, multiBoardId, pageId, data) {
    let myData = {
        url: `/api/private/cloud-boards/multiboard/user/${classId}/${multiBoardId}/${pageId}`,
        data,
        method: 'PUT',
    };
    return apiUtil.runRequest(myData);
};
classApi.updateMultiBoardUserPageByAdmin = function (classId, multiBoardId, pageId, data) {
    let myData = {
        url: `/api/private/cloud-boards/multiboard/admin/${classId}/${multiBoardId}/${pageId}`,
        data,
        method: 'PUT',
    };
    return apiUtil.runRequest(myData);
};

export default classApi;
