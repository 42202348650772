import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function HomeLine2(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 226 132" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ width: '100%', height: '90%', marginTop: '-1rem', marginBottom: '-1rem' }}>
            <svg fill="none" xmlns="http://www.w3.org/2000/svg">
                <line x1="1.36603" y1="1.13397" x2="224.801" y2="130.134" stroke="#F9A237" strokeWidth="2" strokeLinecap="round" strokeDasharray="4 8"/>
            </svg>
        </SvgIcon>
    );
}
