import lessonList, { getItem, removeItem } from '../pages/LessonPage/lessonList';
import classApi from '../Api/classApi';
import storageApi from '../Api/storageApi';

export const migrateLesson = async function (email, classId, updateUploads) {
    let migrationInfo = lessonList.getLessonForEmail(email, classId);
    if (migrationInfo && migrationInfo.pages.length > 0) {
        // store.dispatch(
        //     showSpinner({
        //         showCloudMigration: {
        //             uploads: -1,
        //         },
        //     })
        // );
        let migrationPayload = { pages: [] };
        migrationInfo.pages.forEach((id) => {
            migrationPayload.pages.push({
                id,
                meta: {
                    stickyElements: lessonList.getStickyLessonForEmail(email, classId, id).stickyElements,
                    gridType: localStorage.getItem(id + '-type'),
                },
            });
        });
        migrationPayload.multiboardSessions = [];
        delete migrationInfo.pages;
        Object.keys(migrationInfo).forEach((key) => {
            let id = key.split('_')[0];
            let ts = +key.split('_')[1];
            let data = migrationInfo[key];
            let imageId = data.imageId;
            let multiBoardElements = lessonList.getMultiBoardElements(email, classId, key);
            let el = {
                id: id,
                ts,
                meta: {
                    multiBoardId: key,
                    imageId,
                    gridType: localStorage.getItem(imageId + '-type'),
                    users: { ...data.users },
                    stickyElements: multiBoardElements.originalStickyElements,
                },
                userPages: multiBoardElements.users,
            };

            migrationPayload.multiboardSessions.push(el);
        });
        let migrationResult = await classApi.migrateClass(classId, migrationPayload);
        migrationResult = migrationResult.data;
        let migrationUploads = migrationResult;
        let uploadPromises = [];
        let uploadsComplete = 0;
        let idsToRemove = [];
        for (let i = 0; i < migrationUploads?.pages?.length; i++) {
            uploadPromises.push(
                getItem(migrationUploads.pages[i].id)
                    .then((data) => {
                        if (data) {
                            storageApi.uploadToUrl(
                                migrationUploads.pages[i].upload,
                                data,
                                migrationUploads.pages[i].id
                            );
                        }
                    })
                    .then(() => {
                        idsToRemove.push(migrationUploads.pages[i].id);
                        uploadsComplete++;
                        updateUploads(uploadPromises.length, uploadsComplete);
                        // store.dispatch(
                        //     showSpinner({
                        //         showCloudMigration: {
                        //             uploads: uploadPromises.length,
                        //             uploadsComplete: uploadsComplete,
                        //         },
                        //     })
                        // );
                    })
            );
        }
        for (let i = 0; i < migrationUploads?.multiBoards?.length; i++) {
            let keys = Object.keys(migrationUploads.multiBoards[i].users);
            for (let j = 0; j < keys.length; j++) {
                let userPages = migrationUploads.multiBoards[i].users[keys[j]].pages;
                for (let k = 0; k < userPages.length; k++) {
                    uploadPromises.push(
                        getItem(userPages[k].id)
                            .then((data) => {
                                if (data) {
                                    return storageApi.uploadToUrl(userPages[k].upload, data, userPages[k].id);
                                }
                            })
                            .then(() => {
                                idsToRemove.push(userPages[k].id);
                                uploadsComplete++;
                                updateUploads(uploadPromises.length, uploadsComplete);

                                // store.dispatch(
                                //     showSpinner({
                                //         showCloudMigration: {
                                //             uploads: uploadPromises.length,
                                //             uploadsComplete: uploadsComplete,
                                //         },
                                //     })
                                // );
                            })
                    );
                }
            }
        }
        updateUploads(uploadPromises.length, uploadsComplete);
        // incrementUploads();

        // store.dispatch(
        //     showSpinner({
        //         showCloudMigration: {
        //             uploads: uploadPromises.length,
        //             uploadsComplete: uploadsComplete,
        //         },
        //     })
        // );
        await Promise.all(uploadPromises)
            .then(() => {
                console.log('=== ids', idsToRemove);
                let promiseIdsToRemove = [];
                idsToRemove.forEach((id) => {
                    promiseIdsToRemove.push(removeItem(id));
                });
                return Promise.all(promiseIdsToRemove);
            })
            .then(() => {
                lessonList.cleanLesson(classId, email);
            });
    }
};
