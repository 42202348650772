import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import MultiBoardElement from './MultiBoardElement';
import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import boardManager from './boardManager';
import { BOARD_TYPE } from '../../constants';
import drawUtils from './drawUtils';

const MultiBoardComponent = (props) => {
    let [selectedBoard, setSelectedBoard] = useState(null);

    const adminEmail =
        props.lessonState.lessonInfo && props.lessonState.lessonInfo.classData
            ? props.lessonState.lessonInfo.classData.adminEmail
            : null;
    const isAdmin = adminEmail === props.profile.email;
    const currentUsers = props.lessonState.members;
    const stickyRefs = useRef({});

    const countActiveUsers = () => {
        // let activeUserCount = 0;
        if (!adminEmail) {
            return 0;
        }
        if (isAdmin) {
            // currentUsers.forEach((el) => {
            //     if (el.online) {
            //         activeUserCount++;
            //     }
            // });
            return currentUsers.length;
        } else {
            return 1;
            // currentUsers.forEach((el) => {
            //     if (el.online && el.email === adminEmail) {
            //         activeUserCount++;
            //     }
            // });
        }
        // return activeUserCount;
    };

    // useEffect(() => {
    //     console.log('=== effect in play3 ');
    //     if (props.lessonState.boardType === BOARD_TYPE.MULTI_BOARD) {
    //         if (!props.lessonState.isAdmin && countActiveUsers() === 1 && !selectedBoard) {
    //             openBoard(adminEmail);
    //         }
    //     }
    // }, [props]);

    const refreshMultiboardState = () => {
        // console.log('=== refreshing mb');
        if (!boardManager.lessonState) return;
        let mainContainer = document.getElementById('canvasContainer');
        let multiBoardContainer = document.getElementById('multiBoardContainer');
        let toolPickerContainer = document.getElementById('toolpickerContainer');
        if (!mainContainer || !multiBoardContainer || !toolPickerContainer) {
            // console.log('=== no containers');
            return;
        }

        if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
            mainContainer.style.display = 'inline-block';
            multiBoardContainer.style.display = 'none';
            toolPickerContainer.style.display = 'inline-block';
            if (selectedBoard) {
                setSelectedBoard(null);
            }
            window.updateBoardNavigation();
            // console.log('=== board type is single');
            return;
        }

        if (boardManager.lessonState.boardType === BOARD_TYPE.MULTI_BOARD) {
            // console.log('=== board type is multiple');
            mainContainer.style.display = 'none';
            multiBoardContainer.style.display = 'flex';
            if ((isAdmin && currentUsers.length === 1) || selectedBoard) {
                toolPickerContainer.style.display = 'inline-block';
                window.updateBoardNavigation();
            } else {
                toolPickerContainer.style.display = 'none';
            }
        }
        // console.log(
        //     '=== boardManager.multiBoardRefs',
        //     JSON.stringify(boardManager.multiBoardRefs),
        //     selectedBoard,
        //     isAdmin,
        //     currentUsers.length
        // );

        boardManager.multiBoardRefs = stickyRefs.current;
        if (isAdmin && currentUsers.length === 1 && !selectedBoard) {
            openBoard(currentUsers[0].email);
            boardManager.currentBoardActive = currentUsers[0].email;
            return;
        }
        // console.log('==== profile', props.profile);
        if (!isAdmin && !selectedBoard && adminEmail) {
            openBoard(adminEmail);
            boardManager.currentBoardActive = adminEmail;
            return;
        }
        boardManager.currentBoardActive = selectedBoard;
    };

    window.refreshMultiboardState = refreshMultiboardState;

    useEffect(() => {
        refreshMultiboardState();
    });

    // useEffect(() => {
    //     if (!boardManager.lessonState) return;
    //     if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD && props.lessonState.isAdmin) {
    //         document.getElementById('toolpickerContainer').style.display = 'inline-block';
    //         return;
    //     }
    //     boardManager.multiBoardRefs = stickyRefs.current;
    //     // drawUtils.computeCanvas();
    //     // drawUtils.computeSliders();
    //     let shouldCall = true;
    //     if (countActiveUsers() === 1) {
    //         if (!isAdmin && !selectedBoard) {
    //             openBoard(adminEmail);
    //             shouldCall = false;
    //         } else {
    //             if (isAdmin) {
    //                 let i = currentUsers.findIndex((el) => el.online);
    //                 if (!selectedBoard || selectedBoard !== currentUsers[i].email) {
    //                     openBoard(currentUsers[i].email);
    //                     shouldCall = false;
    //                 }
    //             }
    //         }
    //     }
    //     if (selectedBoard) {
    //         document.getElementById('toolpickerContainer').style.display = 'inline-block';
    //     } else {
    //         if (boardManager.lessonState?.boardType === BOARD_TYPE.MULTI_BOARD) {
    //             document.getElementById('toolpickerContainer').style.display = 'none';
    //         }
    //     }
    //
    //     if (shouldCall) {
    //         boardManager.updateBoards(currentUsers, selectedBoard, countActiveUsers()).then(() => {
    //             setTimeout(() => {
    //                 boardManager.updateBoards(currentUsers, selectedBoard, countActiveUsers());
    //                 currentUsers.forEach((el) => {
    //                     drawUtils.computeMultiboardCanvas(false, el.email);
    //                 });
    //             }, 200);
    //         });
    //     }
    // });

    const openBoard = (id) => {
        // console.log('====== triggered opene board with', id);
        // console.log('==== showing', id, currentUsers);
        document.getElementById('toolpickerContainer').style.display = 'inline-block';
        boardManager.setCurrentBoardActive(id);
        setSelectedBoard(id);
        boardManager.stopDrawing();
        window.checkIfStickyElementsToolPicker();
        if (window.onresize) {
            window.onresize();
        }

        // boardManager.updateBoards(currentUsers, id, countActiveUsers()).then(() => {
        //     setTimeout(() => {
        //         boardManager.updateBoards(currentUsers, selectedBoard, countActiveUsers());
        //         currentUsers.forEach((el) => {
        //             drawUtils.computeMultiboardCanvas(false, el.email);
        //         });
        //     }, 200);
        // });
    };

    window.clearMultiBoardBoards = () => {
        let ids = currentUsers.map((el) => {
            return `multiBoardChildCanvas-${el.email}`;
        });
        for (let i = 0; i < ids.length; i++) {
            let itemCanvas = document.getElementById(ids[i]);
            if (itemCanvas) {
                let ctx = itemCanvas.getContext('2d');
                ctx.globalCompositeOperation = 'source-over';
                ctx.clearRect(0, 0, drawUtils.CANVAS_ACTUAL_WIDTH, drawUtils.CANVAS_ACTUAL_HEIGHT);
            }
        }
    };

    const closeBoard = () => {
        props.parent.handleTextClose();
        window.zoomState.zoomLevel = 0;
        if (window.forceZoomBox) {
            window.forceZoomBox();
            drawUtils.triggerWindowCallbacks();
        }
        // setSelectedBoard(null);
        boardManager.setCurrentBoardActive(null);
        // console.log('=== closing here');
        // document.getElementById('toolpickerContainer').style.display = 'none';
        let el = document.getElementById('multiBoardContainer');
        if (el) {
            el.style.width = '100%';
        }
        setSelectedBoard(null);
        if (window.onresize) {
            window.onresize();
        }
        // boardManager
        //     .updateBoards(currentUsers, null, countActiveUsers())
        //     .then(() => {
        //         setTimeout(() => {
        //             setSelectedBoard(null);
        //         }, 0);
        //     })
        //     .then(() => {
        //         setTimeout(() => {
        //             boardManager.updateBoards(currentUsers, null, countActiveUsers());
        //             currentUsers.forEach((el) => {
        //                 drawUtils.computeMultiboardCanvas(false, el.email);
        //             });
        //         }, 200);
        //     });
    };

    let selectedItem = null;
    if (selectedBoard !== null) {
        currentUsers.forEach((el) => {
            if (el.email === selectedBoard) {
                selectedItem = el;
            }
        });
    }

    const buildAllUsers = () => {
        let activeUserCount = countActiveUsers();
        // if (activeUserCount === 0) {
        //     return (
        //         <Typography variant="h1">
        //             <FormattedMessage id="generic.label.noUsersPresent" />
        //         </Typography>
        //     );
        // }
        if (isAdmin) {
            if (activeUserCount === 0) {
                return (
                    <Typography variant="h1">
                        <FormattedMessage id="generic.label.noUsersPresent" />
                    </Typography>
                );
            }
            let sortedArr = [...currentUsers];
            try {
                sortedArr.sort((a, b) => {
                    return a.name.localeCompare(b.name);
                });
            } catch (ex) {
                console.error('Unable to compare', sortedArr);
            }

            return sortedArr.map((el) => {
                if (!stickyRefs.current[el.email]) {
                    stickyRefs.current[el.email] = React.createRef();
                }
                let isHidden = selectedBoard && el.email !== selectedBoard;
                return (
                    <MultiBoardElement
                        stickyRef={stickyRefs.current[el.email]}
                        item={el}
                        key={`multiBoardElement-${el.email}`}
                        onSelect={openBoard}
                        isSelected={el.email === selectedBoard}
                        isAdmin={props.lessonState.isAdmin}
                        userCount={activeUserCount}
                        isHidden={isHidden}
                        onClose={closeBoard}
                    />
                );
            });
        } else {
            let index = currentUsers.findIndex((el) => el.email === adminEmail);
            let el;
            if (index < 0) {
                el = { email: adminEmail };
            } else {
                el = currentUsers[index];
            }

            if (!stickyRefs.current[adminEmail] && adminEmail) {
                stickyRefs.current[adminEmail] = React.createRef();
            }

            return (
                <MultiBoardElement
                    stickyRef={stickyRefs.current[adminEmail]}
                    item={el}
                    key={`multiBoardElement-${adminEmail}`}
                    onSelect={openBoard}
                    isSelected={true}
                    isAdmin={false}
                    userCount={activeUserCount}
                    isHidden={false}
                    onClose={closeBoard}
                    setWriting={props.parent.setWriting}
                />
            );
        }
    };

    return <>{buildAllUsers()}</>;
};

const mapStateToProps = (state) => {
    const { profile, lessonState, appState } = state;
    return { profile, lessonState, appState };
};

export default connect(mapStateToProps)(MultiBoardComponent);
