import socketUtils from './socketUtils';
import { changeCallState, mute, showError, toggleVideo } from '../../redux/actions';
import store from '../../redux/store';
import { CALL_STATE } from '../../constants';
import mediaUtil from './mediaUtil';
import { promiseTimeout } from '../../common/utils';
import { GA_EVENT_TREE } from '../../gaConstants';
import boardManager from './boardManager';

const videoPrefs = {
    frameRate: { ideal: 10, max: 15 },
    facingMode: 'user',
    width: { max: 320 },
};

const ACCEPT_MEDIA_TIMEOUT = 30000;
// const videoPrefs = true;

const servers = {
    // "lifetimeDuration": "86400s",
    iceServers: [
        {
            urls: ['stun:stun1.vboard.ro:3478'],
        },
        {
            urls: ['turn:stun1.vboard.ro:3478'],
            username: 'brucewayne',
            credential: '12345',
            // "maxRateKbps": "8000"
        },
    ],
    // "blockStatus": "NOT_BLOCKED",
    // "iceTransportPolicy": "all"
};

let offerOptions = {
    offerToReceiveAudio: 1,
    offerToReceiveVideo: 1,
};

let lessonPage = null;
let myCurrentConnections = {};
let myConnectionSettings = {};
let localStream = null;

let letCurrentStateInterval = null;

let audioCallUtils = {};

// const buildArrayString = (arr) => {
//     let str = '';
//     arr.map((e) => {
//         str += e.email + ',';
//         return str;
//     });
//     return str;
// };

const logCurrentState = () => {
    if (window.logLevel === 'debug') {
        console.log('=============');
        console.log('current connections', Object.keys(myCurrentConnections).join(','));
        // if (myIncomingCalls.length > 0) console.log('incomming calls', buildArrayString(myIncomingCalls));
        // if (myOutgoingCalls.length > 0) console.log('outgoing calls', buildArrayString(myOutgoingCalls));
        console.log('=============');
    }
};

audioCallUtils.init = async (myLessonComponent) => {
    if (!letCurrentStateInterval) {
        letCurrentStateInterval = setInterval(() => {
            logCurrentState();
        }, 5000);
    }

    lessonPage = myLessonComponent;
    // lessonPage.hideVideoList();
    myConnectionSettings = {
        iceServers: [],
        outgoingConnectionInProgress: false,
        incomingConnectionInProgress: false,
        incomingConnectionProcessingIndex: 0,
        myPC: null,
        myInitState: false,
    };
    myCurrentConnections = {};
};

audioCallUtils.startCall = async () => {
    window.logAppActivity(GA_EVENT_TREE.lessonPage.actions.startCall);
    await audioCallUtils.initLocalForCalls();
    if (boardManager.isAdmin) {
        socketUtils.sendStartAudioCall(audioCallUtils.getCurrentMicState());
    }
    socketUtils.sendJoinAudioCall(audioCallUtils.getCurrentMicState());
};

audioCallUtils.joinCall = async () => {
    window.logAppActivity(GA_EVENT_TREE.lessonPage.actions.joinCall);
    await audioCallUtils.initLocalForCalls();
    socketUtils.sendJoinAudioCall(audioCallUtils.getCurrentMicState());
};

audioCallUtils.endCall = async () => {
    window.logAppActivity(GA_EVENT_TREE.lessonPage.actions.endCall);
    audioCallUtils.hangUp();
    socketUtils.sendEndAudioCall();
};

audioCallUtils.leaveCall = async () => {
    window.logAppActivity(GA_EVENT_TREE.lessonPage.actions.leaveCall);
    audioCallUtils.hangUp();
    socketUtils.sendLeaveAudioCall();
};

audioCallUtils.getCurrentMicState = () => {
    if (!audioCallUtils.hasLocalStream()) {
        return null;
    } else {
        return store.getState().lessonState.isMuted;
    }
};

audioCallUtils.initLocalForCalls = async () => {
    store.dispatch(changeCallState(CALL_STATE.CALL_STARTING));
    if (!navigator.mediaDevices) {
        myConnectionSettings.myInitState = true;
        store.dispatch(showError('NO_ACCESS_TO_MEDIA'));
        console.log('==== no media devices');
        return;
    }
    let stream;
    let success = false;
    try {
        stream = await promiseTimeout(
            ACCEPT_MEDIA_TIMEOUT,
            navigator.mediaDevices.getUserMedia({ video: videoPrefs, audio: true })
        );
        success = true;
    } catch (e) {
        if (e === 'TIMEOUT') {
            store.dispatch(changeCallState(CALL_STATE.CAN_JOIN_CALL));
            store.dispatch(showError('NO_ACCESS_TO_MEDIA'));
            return;
        }
    }

    if (!success) {
        try {
            stream = await promiseTimeout(ACCEPT_MEDIA_TIMEOUT, navigator.mediaDevices.getUserMedia({ audio: true }));
            success = true;
        } catch (e) {
            if (e === 'TIMEOUT') {
                store.dispatch(changeCallState(CALL_STATE.CAN_JOIN_CALL));
                store.dispatch(showError('NO_ACCESS_TO_MEDIA'));
                return;
            }
        }
    }

    if (success && stream) {
        audioCallUtils.handleStream(stream);
        myConnectionSettings.myInitState = true;
        store.dispatch(changeCallState(CALL_STATE.CALL_STARTED));
    } else {
        store.dispatch(changeCallState(CALL_STATE.CAN_JOIN_CALL));
        store.dispatch(showError('NO_ACCESS_TO_MEDIA'));
    }

    // try {
    //     let stream = await promiseTimeout(
    //         ACCEPT_MEDIA_TIMEOUT,
    //         navigator.mediaDevices.getUserMedia({ video: videoPrefs, audio: true })
    //     );
    //     console.log('got user media');
    //     audioCallUtils.handleStream(stream);
    //     // let audioTracks = localStream.getAudioTracks();
    //     // if (audioTracks.length > 0) {
    //     //     console.log('Using audio device: ' + audioTracks[0].label);
    //     // }
    //
    //     myConnectionSettings.myInitState = true;
    //
    //     store.dispatch(changeCallState(CALL_STATE.CALL_STARTED));
    //
    //     console.log('init state is ok', localStream.getTracks());
    // } catch (e) {
    //     if (e === 'TIMEOUT') {
    //         store.dispatch(changeCallState(CALL_STATE.CAN_JOIN_CALL));
    //         store.dispatch(showError('NO_ACCESS_TO_MEDIA'));
    //     } else {
    //         console.error('Error starting local setup', e);
    //         myConnectionSettings.myInitState = true;
    //     }
    // }
};

audioCallUtils.handleStream = function (stream) {
    console.log('Received local stream');
    document.getElementById('localVideo').style.display = 'block';
    document.getElementById('localVideo').srcObject = stream;
    // for (let i = 1; i <= 10; i++) {
    //     document.getElementById('localVideo' + i).style.display = 'block';
    //     document.getElementById('localVideo' + i).srcObject = stream;
    // }
    localStream = stream;
    let remoteVideoList = document.getElementById('remoteVideoList');
    remoteVideoList.style.display = 'inline-flex';
};

audioCallUtils.addMyIceCandidate = (candidate) => {
    console.log('ice candidate', candidate);
};

audioCallUtils.startCallWithNewEntry = (email) => {
    if (store.getState().lessonState.callState !== CALL_STATE.CALL_STARTED) {
        console.log('should not have reached startCallWithNewEntry');
        return;
    }
    let remoteItem = audioCallUtils.initRemoteItem(email);
    if (remoteItem) {
        audioCallUtils.createOffer(email);
    } else {
        // console.log('tried to processNextOutgoingConnection but could not get remote item = cleaning up');
        audioCallUtils.cleanEmailState(email);
    }
};

audioCallUtils.initRemoteItem = (email) => {
    try {
        if (myCurrentConnections[email]) {
            // connection already exists = needs to be cleared first
            // console.log('initing but item is already present - triggering cleanup');
            // audioCallUtils.cleanEmailState(email);
            return myCurrentConnections[email];
        }
        myCurrentConnections[email] = {
            pc: null,
        };

        let remoteItem = myCurrentConnections[email];

        audioCallUtils.createHtmlVideoElement(email);
        remoteItem.pc = new RTCPeerConnection(servers);
        remoteItem.pc.onicecandidate = function (e) {
            if (window.logLevel === 'debug') {
                console.log('========================== should send ice candidate', e.candidate);
            }

            if (e.candidate) {
                socketUtils.sendMediaIceCandidate(lessonPage.props.profile.email, email, e.candidate);
            } else {
                // console.log('remote ice candidates complete for', email);
                // audioCallUtils.completeConnection(email);
            }
        };

        remoteItem.pc.oniceconnectionstatechange = function (e) {
            // console.log('remote ice conn status change', e);
        };

        remoteItem.pc.onsignalingstatechange = () => {
            // console.log('remote signaling state change', e);
        };

        remoteItem.pc.ontrack = (t) => {
            // console.log('=============================== added remote track', t, 'video_' + email);
            t.onmute = () => {
                console.log('got muted for remote');
            };
            t.onmute = () => {
                console.log('got unmuted for remote');
            };
            let item = document.getElementById('video_' + email);
            if (item) {
                // console.log('updating remote stream');
                item.srcObject = t.streams[0];
                // let stream = t.streams[0];
                // stream.getTracks().forEach(track => remoteItem.pc.addTrack(track, stream));
            } else {
                console.log('dome element not found at stream update');
            }
        };
        if (localStream) {
            localStream.getTracks().forEach((track) => remoteItem.pc.addTrack(track, localStream));
        }
        // newVideoNode.srcObject = localStream;
        myCurrentConnections[email] = remoteItem;
        // console.log('==== init completed for ', email);
        remoteItem.email = email;
        return remoteItem;
    } catch (ex) {
        console.error('Unable to create remote description for', email, ex);
        audioCallUtils.cleanEmailState(email);
        return null;
    }
};

audioCallUtils.createOffer = async (emailTo) => {
    try {
        let remotePc = myCurrentConnections[emailTo].pc;
        let desc = await remotePc.createOffer(offerOptions);
        await remotePc.setLocalDescription(desc);
        socketUtils.sendOfferSDPToRemote(emailTo, lessonPage.props.profile.email, desc);
    } catch (ex) {
        console.error('Failed to create offer for ', emailTo, ex);
        audioCallUtils.cleanEmailState(emailTo);
    }
};

audioCallUtils.createHtmlVideoElement = (email) => {
    let newVideoNode = document.createElement('video');
    newVideoNode.setAttribute('id', 'video_' + email);
    newVideoNode.setAttribute('autoplay', '');
    newVideoNode.setAttribute('playsinline', '');
    newVideoNode.setAttribute('poster', '/noVideo.jpg');
    newVideoNode.style.height = '100%';
    newVideoNode.style['border-radius'] = '1rem';
    // newVideoNode.setAttribute('width', '100%');
    // newVideoNode.setAttribute('height', '100%');
    // newVideoNode.setAttribute("style", "border:solid");

    let videoNodeWrapper = document.createElement('div');
    videoNodeWrapper.setAttribute('id', 'wrapper_video_' + email);
    videoNodeWrapper.style.position = 'relative';
    videoNodeWrapper.style['margin-left'] = '0.5rem';
    // videoNodeWrapper.style['max-width'] = '10vw';

    let nameSpan = document.createElement('span');
    nameSpan.style.position = 'absolute';
    nameSpan.style['padding-left'] = '8px';
    nameSpan.style['font-weight'] = 'bold';
    nameSpan.style.bottom = '4px';
    nameSpan.style.left = '4px';
    nameSpan.style.color = 'white';
    let lessonState = store.getState().lessonState;
    // console.log(lessonState);
    let name = '';
    if (lessonState.members && lessonState.members.length > 0) {
        lessonState.members.forEach((el) => {
            if (el.email === email) {
                name = el.name;
            }
        });
    }
    nameSpan.appendChild(document.createTextNode(name));
    videoNodeWrapper.appendChild(newVideoNode);
    videoNodeWrapper.appendChild(nameSpan);
    document.getElementById('remoteVideoList').appendChild(videoNodeWrapper);
};

audioCallUtils.processSdpAndCreateAnswer = async (remoteItem, description) => {
    try {
        console.log('processing sdp to create answer');
        await remoteItem.pc.setRemoteDescription(description);
        if (remoteItem.unprocessedIceCandidates) {
            console.log('reprocessing missing ice candidates ', remoteItem.unprocessedIceCandidates.length, remoteItem);
            for (let i = 0; i < remoteItem.unprocessedIceCandidates.length; i++) {
                if (remoteItem.unprocessedIceCandidates[i]) {
                    await audioCallUtils.addRemoteIceCandidate(
                        remoteItem.email,
                        remoteItem.unprocessedIceCandidates[i],
                        false
                    );
                }
            }
            remoteItem.unprocessedIceCandidates = [];
        }
        let answer = await remoteItem.pc.createAnswer();
        if (answer) {
            console.log('sending answer to ', remoteItem.email);
            answer.sdp = mediaUtil.forceChosenAudioCodec(answer.sdp);
            await remoteItem.pc.setLocalDescription(answer);
            socketUtils.sendAnswerSDPToRemote(remoteItem.email, lessonPage.props.profile.email, answer);
        } else {
            console.log('===== NO ANSWER');
        }
    } catch (ex) {
        console.log('Unable to processSdpAndCreateAnswer', ex, remoteItem.email);
        audioCallUtils.cleanEmailState(remoteItem.email);
    }
};

audioCallUtils.updateAnswerMediaSdp = async (email, desc) => {
    try {
        if (!myCurrentConnections[email] || !myCurrentConnections[email].pc) {
            console.log('got updateAnswerMediaSdp without init');
            audioCallUtils.initRemoteItem(email);

            // console.error('Unable to set remote description for ', email);
            // audioCallUtils.cleanEmailState(email);
        }
        await myCurrentConnections[email].pc.setRemoteDescription(desc);
        if (myCurrentConnections[email].unprocessedIceCandidates) {
            for (let i = 0; i < myCurrentConnections[email].unprocessedIceCandidates.length; i++) {
                if (myCurrentConnections[email].unprocessedIceCandidates[i]) {
                    await audioCallUtils.addRemoteIceCandidate(
                        email,
                        myCurrentConnections[email].unprocessedIceCandidates[i],
                        false
                    );
                }
            }
            myCurrentConnections[email].unprocessedIceCandidates = [];
        }
        // audioCallUtils.completeConnection(email);
    } catch (ex) {
        console.log('Unable to updateAnswerMediaSdp', ex, email);
        store.dispatch(showError('ERROR_JOINING_CALL'));
        // audioCallUtils.hangUp();
        store.dispatch(changeCallState(CALL_STATE.CAN_JOIN_CALL));
        // audioCallUtils.forceEmailCompletion(email);
        // audioCallUtils.cleanEmailState(email);
    }
};

audioCallUtils.hasLocalStream = () => {
    return !!localStream;
};

audioCallUtils.processIncomingSDPOffer = (fromEmail, description) => {
    let newRemoteItem = audioCallUtils.initRemoteItem(fromEmail);
    audioCallUtils.processSdpAndCreateAnswer(newRemoteItem, description);
};

audioCallUtils.addJoinedUser = (email) => {
    if (myCurrentConnections[email] && myCurrentConnections[email].complete) {
        return;
    }
    if (store.getState().lessonState.callState !== CALL_STATE.CALL_STARTED) {
        return;
    }
    audioCallUtils.startCallWithNewEntry(email);
};

audioCallUtils.addRemoteIceCandidate = async function (email, iceCandidate, addIfFailed = true) {
    if (!myCurrentConnections[email] || !myCurrentConnections[email].pc) {
        audioCallUtils.initRemoteItem(email);
    }
    try {
        await myCurrentConnections[email].pc.addIceCandidate(iceCandidate);
        if (window.logLevel === 'debug') {
            console.log('remote ice candidate for added for ', email);
        }
    } catch (err) {
        // TODO: figure out what to do here
        console.log('unable to add ice candidate for ', email, iceCandidate, err);
        if (addIfFailed) {
            console.log('added for reprocessing');
            if (!myCurrentConnections[email].unprocessedIceCandidates) {
                myCurrentConnections[email].unprocessedIceCandidates = [];
            }
            myCurrentConnections[email].unprocessedIceCandidates.push(iceCandidate);
        }
    }
};

audioCallUtils.hangUp = () => {
    if (localStream) {
        localStream.getTracks().forEach((track) => track.stop());
    }
    localStream = null;
    let keys = Object.keys(myCurrentConnections);
    for (let i = 0; i < keys.length; i++) {
        audioCallUtils.cleanEmailState(keys[i]);
    }
    myCurrentConnections = {};
    let localVid = document.getElementById('localVideo');
    if (localVid) {
        localVid.style.display = 'none';
        localVid.srcObject = null;
    }
};

audioCallUtils.cleanEmailState = (email) => {
    // console.log('Cleanup was triggered for ', email);
    if (myCurrentConnections[email]) {
        if (myCurrentConnections[email].pc) {
            myCurrentConnections[email].pc.close();
        }
        if (document.getElementById('video_' + email)) {
            document.getElementById('video_' + email).remove();
        }
        if (document.getElementById('wrapper_video_' + email)) {
            document.getElementById('wrapper_video_' + email).remove();
        }
        delete myCurrentConnections[email];
    }
};

audioCallUtils.mute = () => {
    // console.log('should mute');
    if (localStream) {
        // console.log('muting for localstream');
        localStream.getTracks().forEach((track) => {
            if (track.kind === 'audio') {
                track.enabled = false;
            }
        });
        store.dispatch(mute());
        socketUtils.sendMutedValue(true);
        window.logAppActivity(GA_EVENT_TREE.lessonPage.actions.disableAudio);
    }
};

audioCallUtils.unMute = () => {
    if (localStream) {
        localStream.getTracks().forEach((track) => {
            if (track.kind === 'audio') {
                track.enabled = true;
            }
        });
        store.dispatch(mute());
        socketUtils.sendMutedValue(false);
        window.logAppActivity(GA_EVENT_TREE.lessonPage.actions.enableAudio);
    }
};

audioCallUtils.disableVideo = () => {
    if (localStream) {
        localStream.getTracks().forEach((track) => {
            if (track.kind === 'video') {
                track.enabled = false;
            }
        });
        store.dispatch(toggleVideo(false));
        // socketUtils.sendMutedValue(true);
        window.logAppActivity(GA_EVENT_TREE.lessonPage.actions.disableVideo);
    }
};

audioCallUtils.enableVideo = () => {
    if (localStream) {
        localStream.getTracks().forEach((track) => {
            if (track.kind === 'video') {
                track.enabled = true;
            }
        });
        store.dispatch(toggleVideo(true));
        window.logAppActivity(GA_EVENT_TREE.lessonPage.actions.enableVideo);
    }
};

// function updateBandwidthRestriction(sdp, bandwidth) {
//     let modifier = 'AS';
//     if (adapter.browserDetails.browser === 'firefox') {
//         bandwidth = (bandwidth >>> 0) * 1000;
//         modifier = 'TIAS';
//     }
//     if (sdp.indexOf('b=' + modifier + ':') === -1) {
//         // insert b= after c= line.
//         sdp = sdp.replace(/c=IN (.*)\r\n/, 'c=IN $1\r\nb=' + modifier + ':' + bandwidth + '\r\n');
//     } else {
//         sdp = sdp.replace(new RegExp('b=' + modifier + ':.*\r\n'), 'b=' + modifier + ':' + bandwidth + '\r\n');
//     }
//     return sdp;
// }

export default audioCallUtils;
