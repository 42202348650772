import React, { useEffect, useState } from 'react';
import { Button, Checkbox, FormControlLabel, FormGroup, Grid } from '@material-ui/core';
import userApi from '../../Api/userApi';
import { FormattedMessage } from 'react-intl';
import store from '../../redux/store';
import { changePreferences, updateUserConsent } from '../../redux/actions';
import { connect } from 'react-redux';

const ChangePreferences = ({ profile }) => {
    const [marketing, setMarketing] = useState(false);
    const [specialOffers, setSpecialOffers] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleChangeMarketing = (event) => {
        setMarketing(event.target.checked);
    };

    const handleChangeOffers = (event) => {
        setSpecialOffers(event.target.checked);
    };

    useEffect(() => {
        if (!profile.consent) {
            return;
        }
        setMarketing(profile.consent.allowMarketing);
        setSpecialOffers(profile.consent.allowSpecialOffers);
    }, [profile.consent]);

    const updateConsent = () => {
        setLoading(true);
        userApi
            .updateConsent({
                allowMarketing: marketing,
                allowSpecialOffers: specialOffers,
                terms: JSON.stringify('terms-20220504'),
                policy: JSON.stringify('policy-20220402'),
            })
            .then((response) => {
                store.dispatch(updateUserConsent(response.data));
                store.dispatch(changePreferences('CHANGE_PREFERENCES'));
                setLoading(false);
            })
            .catch((err) => {
                console.error('unable to update consent', err);
            });
    };

    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{
                marginTop: '3rem',
            }}
        >
            <Grid item xs={12} sm={6} style={{ marginBottom: '2rem', paddingLeft: '1rem' }}>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox />}
                        label={<FormattedMessage id="consent.dialog.checkbox.allowMarketing" />}
                        onChange={handleChangeMarketing}
                        checked={marketing}
                    />
                    <FormControlLabel
                        control={<Checkbox />}
                        label={<FormattedMessage id="consent.dialog.checkbox.allowSpecialOffers" />}
                        onChange={handleChangeOffers}
                        checked={specialOffers}
                    />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} style={{ paddingLeft: '1rem', paddingBottom: '2rem' }}>
                <Button
                    onClick={updateConsent}
                    onTouchStart={updateConsent}
                    color="primary"
                    variant={'contained'}
                    disabled={loading}
                >
                    <FormattedMessage id="consent.dialog.button.confirm" />
                </Button>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => {
    const { profile } = state;
    return { profile };
};
export default connect(mapStateToProps)(ChangePreferences);
