import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useEffect, useRef } from 'react';
import { Divider, Grid, Modal, Paper, Slide, TextField, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import VerticalEllipsisIcon from '../../../MyComponents/icons/VerticalEllipsisIcon';
import Button from '@material-ui/core/Button';
import { FormattedMessage, injectIntl } from 'react-intl';
import OutlinedOrangeSelectedFolderIcon from '../../../MyComponents/icons/OutlinedOrangeSelectedFolderIcon';
import collectionsApi from '../../../Api/collectionsApi';
import { COLORS2, LIBRARY_MANAGER_STATE } from '../../../constants';
import drawUtils from '../../LessonPage/drawUtils';
import GenericState from '../../../MyComponents/GenericLoadingErrorState/GenericState';
import SingleBoardIconRedesign from '../../../MyComponents/icons/SingleBoardIconRedesign';
import ModalContext from '../ModalContext/ModalContext';
import Hidden from '@material-ui/core/Hidden';
import SlideShow from '../../../MyComponents/SlideShow/SlideShow';
import MobileModalContent from './MobileModalContent';
import { determineDefaultLanguage } from '../../../common/utils';
import { Autocomplete } from '@material-ui/lab';
import { AddBoxOutlined } from '@material-ui/icons';
import { difference } from 'lodash';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import BoardSet from '../../../MyComponents/icons/BoardSet';
import SelectedSharedFolderIcon from '../../../MyComponents/icons/SelectedSharedFolderIcon';
import OutlinedSelectedSharedFolderIcon from '../../../MyComponents/icons/OutlinedSelectedSharedFolderIcon';

const filter = createFilterOptions();

const useStyles = makeStyles(() => ({
    modalStyle: {
        background: 'linear-gradient(180deg, rgba(2, 35, 37, 0) 0%, #022325 95.56%)',
    },
    boxModalStyle: {
        position: 'absolute',
        background: '#FFFFFF',
        boxShadow: '1.5rem -1.5rem 3rem rgba(0, 0, 0, 0.08)',
        borderRadius: '2rem 2rem 0rem 0rem',
        bottom: '0',
        height: '55%',
    },
    menuText: {
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '1rem',
        letterSpacing: '-0.01rem',
        textTransform: 'none',
        color: COLORS2.darkGreen,
    },
    withoutHover: {
        '&:hover': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
        },
    },
    deleteMenuText: {
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '1rem',
        letterSpacing: '-0.01rem',
        textTransform: 'none',
        color: COLORS2.virtualRed,
    },
    itemButton: {
        fontWeight: 400,
        fontSize: '1rem',
        textAlign: 'start',
        textTransform: 'none',
        letterSpacing: '-0.01rem',
        color: COLORS2.darkGreen,
    },
    itemButtonDelete: {
        fontWeight: 400,
        fontSize: '1rem',
        textAlign: 'start',
        textTransform: 'none',
        letterSpacing: '-0.01rem',
        color: COLORS2.virtualRed,
    },
    customPaperSlideShow: {
        borderRadius: '1rem',
        boxShadow: 'none',
        padding: '0rem !important',
    },
    verticalEllipsis: {
        filter: 'invert(46%) sepia(95%) saturate(601%) hue-rotate(140deg) brightness(93%) contrast(94%)',
    },
    nameStyle: {
        display: 'flex',
        alignItems: 'center',
        padding: '1.5rem 2.5rem',
        justifyContent: 'space-between',
    },
    nameStyleSmall: {
        display: 'flex',
        alignItems: 'center',
        padding: '1.5rem 1.5rem',
        justifyContent: 'space-between',
    },
    alignCenter: {
        display: 'flex',
        alignItems: 'center',
    },
    customH5: {
        fontWeight: 700,
        fontSize: '1.125rem',
        letterSpacing: '-0.02rem',
        marginLeft: '1rem',
    },
    customDivider: {
        backgroundColor: 'rgba(210, 104, 40, 0.1)',
        height: '0.063rem',
    },
    noNameText: {
        padding: '1.5rem 2.5rem',
    },
    dayText: {
        fontSize: '1rem',
        fontWeight: 400,
        color: COLORS2.virtualText,
        marginRight: '1rem',
    },
    hourText: {
        color: COLORS2.virtualAmber,
        fontWeight: 500,
        fontSize: '1rem',
        background: COLORS2.virtualBackground,
        borderRadius: '0.25rem',
    },
}));

const MobileCollectionEditor = ({
    deleteBoard,
    cancelFolderDeletion, 
    editBoard,
    selectedNode,
    addToCollection,
    attachTag,
    createAndAttachTag,
    removeTag,
    tags,
    intl,
    loadCollections, 
    create = false,
    state = LIBRARY_MANAGER_STATE.MANAGE,
}) => {
    const classes = useStyles();
    const modalStateContext = useContext(ModalContext);
    const [name, setName] = React.useState('');
    const [boards, setBoards] = React.useState([]);
    const [isError, setIsError] = React.useState(false);

    const dataSets = useRef({
        boardsToRender: {},
    });

    useEffect(() => {
        if (selectedNode && !create) {
            loadCollection(selectedNode);
        }
        if (create) {
            setName('');
        }
    }, [selectedNode, create]);

    const loadCollection = (selectedNode) => {
        if (selectedNode.isCollection) {
            if(selectedNode.isUserCollection) {
                collectionsApi
                    .getSharedCollection(selectedNode.id)
                    .then((res) => {
                        setName(res.data.name ? res.data.name : '');
                        if (res.data.boards && res.data.boards.length > 0) {
                            let promises = [];
                            for (let i = 0; i < res.data.boards.length; i++) {
                                let b = res.data.boards[i];
                                if (b.meta && !dataSets.current.boardsToRender[b.id]) {
                                    promises.push(
                                        drawUtils
                                            .buildComplexComposedImage(
                                                b.meta.gridType,
                                                null,
                                                null,
                                                b.meta.stickyElements,
                                                b.mainUrl,
                                                null
                                            )
                                            .then((data) => {
                                                if (data) {
                                                    dataSets.current.boardsToRender[b.id] = data;
                                                } else {
                                                    dataSets.current.boardsToRender[b.id] = null;
                                                }
                                            })
                                    );
                                }
                            }
                            return Promise.all(promises).then(() => {
                                setBoards(res.data.boards);
                            });
                        } else {
                            setBoards([]);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        setIsError(true);
                    });
            } else {
                collectionsApi
                    .getCollection(selectedNode.id)
                    .then((res) => {
                        setName(res.data.name ? res.data.name : '');
                        if (res.data.boards && res.data.boards.length > 0) {
                            let promises = [];
                            for (let i = 0; i < res.data.boards.length; i++) {
                                let b = res.data.boards[i];
                                if (b.meta && !dataSets.current.boardsToRender[b.id]) {
                                    promises.push(
                                        drawUtils
                                            .buildComplexComposedImage(
                                                b.meta.gridType,
                                                null,
                                                null,
                                                b.meta.stickyElements,
                                                b.mainUrl,
                                                null
                                            )
                                            .then((data) => {
                                                if (data) {
                                                    dataSets.current.boardsToRender[b.id] = data;
                                                } else {
                                                    dataSets.current.boardsToRender[b.id] = null;
                                                }
                                            })
                                    );
                                }
                            }
                            return Promise.all(promises).then(() => {
                                setBoards(res.data.boards);
                            });
                        } else {
                            setBoards([]);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        setIsError(true);
                    });
            }
        } else if(selectedNode.isUserBoard) {
            collectionsApi
                .getSharedBoard(selectedNode.id)
                .then((res) => {
                    setName(res.data.name ? res.data.name : '');
                    if (!dataSets.current.boardsToRender[selectedNode.id]) {
                        drawUtils
                            .buildComplexComposedImage(
                                res.data.meta.gridType,
                                null,
                                null,
                                res.data.meta.stickyElements,
                                res.data.mainUrl,
                                null
                            )
                            .then((data) => {
                                if (data) {
                                    dataSets.current.boardsToRender[selectedNode.id] = data;
                                } else {
                                    dataSets.current.boardsToRender[selectedNode.id] = null;
                                }
                                setBoards([res.data]);
                            });
                    } else {
                        setBoards([res.data]);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setIsError(true);
                });
        } else {
            collectionsApi
                .getBoard(selectedNode.id)
                .then((res) => {
                    setName(res.data.name ? res.data.name : '');
                    if (!dataSets.current.boardsToRender[selectedNode.id]) {
                        drawUtils
                            .buildComplexComposedImage(
                                res.data.meta.gridType,
                                null,
                                null,
                                res.data.meta.stickyElements,
                                res.data.mainUrl,
                                null
                            )
                            .then((data) => {
                                if (data) {
                                    dataSets.current.boardsToRender[selectedNode.id] = data;
                                } else {
                                    dataSets.current.boardsToRender[selectedNode.id] = null;
                                }
                                setBoards([res.data]);
                            });
                    } else {
                        setBoards([res.data]);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setIsError(true);
                });
        }
    };

    if (isError) {
        return <GenericState isError={true} inline />;
    }
    if (!selectedNode && !create) {
        return null;
    }

    let slideElements = [];
    if (boards.length > 0) {
        boards.forEach((el) => {
            slideElements.push({ img: dataSets.current.boardsToRender[el.id] });
        });
    }

    let localesOptions = 'ro-RO';
    let currentLanguage = determineDefaultLanguage();
    if (currentLanguage === 'en') {
        localesOptions = 'en-EN';
    } else if (currentLanguage === 'fr') {
        localesOptions = 'fr-FR';
    } else if (currentLanguage === 'ro') {
        localesOptions = 'ro-RO';
    }

    let setTags = [];
    if (tags && selectedNode && selectedNode.tags) {
        setTags = tags.filter((tag) => selectedNode.tags.includes(tag.id));
    }

    const tagsChanged = (newTags) => {
        if (tags && selectedNode && selectedNode.tags) {
            let newTagIds = newTags.map((el) => el.id);
            if (newTagIds.length > selectedNode.tags.length) {
                let diff = difference(newTagIds, selectedNode.tags);
                diff.forEach((el) => {
                    if (el !== -1) {
                        attachTag(selectedNode, { id: el });
                    }
                });
                let newlyCreatedTags = newTags.filter((el) => el.id === -1);
                newlyCreatedTags.forEach((el) => {
                    createAndAttachTag(el.inputValue, selectedNode);
                });
            } else if (newTagIds.length < selectedNode.tags.length) {
                let diff = difference(selectedNode.tags, newTagIds);
                diff.forEach((el) => removeTag(selectedNode, { id: el }));
            }
        }
    };

    return (
        <>
            <Hidden only={'sm'}>
                {state === LIBRARY_MANAGER_STATE.MANAGE ? (
                    (selectedNode && selectedNode.isUserCollection && !selectedNode.isBoardSet) ||
                    selectedNode.isUser ? null : (
                        <Button onClick={modalStateContext.handleOpen}>
                            <VerticalEllipsisIcon className={classes.verticalEllipsis} />
                        </Button>
                    )
                ) : null}
                <Modal
                    open={modalStateContext.open}
                    onClose={modalStateContext.handleClose}
                    className={classes.modalStyle}
                    BackdropProps={{ style: { backgroundColor: 'transparent' } }}
                >
                    <Slide
                        direction="up"
                        in={modalStateContext.open}
                        timeout={{ enter: 500, exit: 400 }}
                        easing={'ease-out'}
                    >
                        <Box className={classes.boxModalStyle} style={{ width: '100%', margin: '0rem' }}>
                            {(selectedNode.name && selectedNode.isUserBoard) || selectedNode.isBoardSet ? (
                                <div className={classes.nameStyleSmall}>
                                    <span className={classes.alignCenter}>
                                        {selectedNode && !selectedNode.isUserCollection && !selectedNode.isBoardSet ? (
                                            <SingleBoardIconRedesign />
                                        ) : null}
                                        {selectedNode && selectedNode.isBoardSet ? <BoardSet /> : null}
                                        <Typography variant="h5" className={classes.customH5}>
                                            {selectedNode.name}
                                        </Typography>
                                    </span>
                                </div>
                            ) : name ? (
                                <div className={classes.nameStyleSmall}>
                                    <span className={classes.alignCenter}>
                                        {selectedNode && selectedNode.isCollection && !selectedNode.isBoardSet ? (
                                            selectedNode.count > 0 ? (
                                                <OutlinedSelectedSharedFolderIcon />
                                            ) : (
                                                <OutlinedOrangeSelectedFolderIcon />
                                            )
                                        ) : null}
                                        {selectedNode && !selectedNode.isCollection && !selectedNode.isBoardSet ? (
                                            <SingleBoardIconRedesign />
                                        ) : null}
                                        {selectedNode && selectedNode.isBoardSet ? <BoardSet /> : null}
                                        <Typography variant="h5" className={classes.customH5}>
                                            {name}
                                        </Typography>
                                    </span>
                                    {selectedNode && !selectedNode.isCollection ? (
                                        <span style={{ display: 'flex', paddingTop: '0rem' }}>
                                            <Typography className={classes.dayText}>
                                                {new Date(selectedNode.createdAt).toLocaleString(localesOptions, {
                                                    year: 'numeric',
                                                    month: 'long',
                                                    day: 'numeric',
                                                })}
                                            </Typography>
                                            <Typography className={classes.hourText}>
                                                {new Date(selectedNode.createdAt).toLocaleString(localesOptions, {
                                                    hour: 'numeric',
                                                    minute: 'numeric',
                                                })}
                                            </Typography>
                                        </span>
                                    ) : null}
                                </div>
                            ) : (
                                <div className={classes.noNameText}>
                                    <Typography variant="h5">
                                        <FormattedMessage id="redesign.collections.noNameFolder" />
                                    </Typography>
                                </div>
                            )}
                            <Divider className={classes.customDivider} />
                            {boards.length > 0 && !create ? (
                                <>
                                    <Grid item>
                                        <Paper className={classes.customPaperSlideShow}>
                                            <SlideShow slideElements={slideElements} />
                                        </Paper>
                                    </Grid>
                                    <Divider className={classes.customDivider} />
                                </>
                            ) : null}
                            {(selectedNode && selectedNode.isCollection) || create ? null : selectedNode &&
                              !selectedNode.isUserBoard ? (
                                <div style={{ padding: '2rem 2.5rem 0rem 2.5rem' }}>
                                    <Autocomplete
                                        fullWidth
                                        multiple
                                        id="selected-tags-outlined"
                                        size="small"
                                        options={tags}
                                        getOptionLabel={(option) => {
                                            if (option.inputValue) {
                                                return (
                                                    <div style={{ width: '100%', display: 'inline-flex' }}>
                                                        <AddBoxOutlined />
                                                        <FormattedMessage id={'collections.createTag'} /> :{' '}
                                                        {option.inputValue}
                                                    </div>
                                                );
                                            }
                                            return option.name;
                                        }}
                                        filterSelectedOptions
                                        value={setTags}
                                        filterOptions={(options, params) => {
                                            const filtered = filter(options, params);
                                            if (params.inputValue !== '') {
                                                filtered.push({
                                                    inputValue: params.inputValue,
                                                    id: -1,
                                                });
                                            }
                                            return filtered;
                                        }}
                                        onChange={(event, v, reason) => tagsChanged(v, reason)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label={intl.formatMessage({ id: 'collections.filterTags' })}
                                                placeholder={intl.formatMessage({ id: 'collections.tags' })}
                                            />
                                        )}
                                    />
                                </div>
                            ) : null}
                            <MobileModalContent
                                intl={intl}
                                selectedNode={selectedNode}
                                editBoard={editBoard}
                                deleteBoard={deleteBoard}
                                cancelFolderDeletion={cancelFolderDeletion}
                                create={create}
                                addToCollection={addToCollection}
                                state={state}
                                loadCollections={loadCollections}
                            />
                        </Box>
                    </Slide>
                </Modal>
            </Hidden>
            <Hidden only={'xs'}>
                {state === LIBRARY_MANAGER_STATE.MANAGE ? (
                    (selectedNode && selectedNode.isUserCollection && !selectedNode.isBoardSet) ||
                    selectedNode.isUser ? null : (
                        <Button onClick={modalStateContext.handleOpen}>
                            <VerticalEllipsisIcon className={classes.verticalEllipsis} />
                        </Button>
                    )
                ) : null}
                <Modal
                    open={modalStateContext.open}
                    onClose={modalStateContext.handleClose}
                    className={classes.modalStyle}
                    BackdropProps={{ style: { backgroundColor: 'transparent' } }}
                >
                    <Slide
                        direction="up"
                        in={modalStateContext.open}
                        timeout={{ enter: 500, exit: 400 }}
                        easing={'ease-out'}
                    >
                        <Box className={classes.boxModalStyle} style={{ width: '75%', margin: '0rem 6rem' }}>
                            {(selectedNode.name && selectedNode.isUserBoard) || selectedNode.isBoardSet ? (
                                <div className={classes.nameStyle}>
                                    <span className={classes.alignCenter}>
                                        {selectedNode && !selectedNode.isUserCollection && !selectedNode.isBoardSet ? (
                                            <SingleBoardIconRedesign />
                                        ) : null}
                                        {selectedNode && selectedNode.isBoardSet ? <BoardSet /> : null}
                                        <Typography variant="h5" className={classes.customH5}>
                                            {selectedNode.name}
                                        </Typography>
                                    </span>
                                </div>
                            ) : name ? (
                                <div className={classes.nameStyle}>
                                    <span className={classes.alignCenter}>
                                        {selectedNode && selectedNode.isCollection && !selectedNode.isBoardSet ? (
                                            selectedNode.count > 0 ? (
                                                <SelectedSharedFolderIcon />
                                            ) : (
                                                <OutlinedOrangeSelectedFolderIcon />
                                            )
                                        ) : null}
                                        {selectedNode && !selectedNode.isCollection && !selectedNode.isBoardSet ? (
                                            <SingleBoardIconRedesign />
                                        ) : null}
                                        {selectedNode && selectedNode.isBoardSet ? <BoardSet /> : null}
                                        <Typography variant="h5" className={classes.customH5}>
                                            {name}
                                        </Typography>
                                    </span>
                                    {selectedNode && !selectedNode.isCollection ? (
                                        <span style={{ display: 'flex', paddingTop: '0rem' }}>
                                            <Typography className={classes.dayText}>
                                                {new Date(selectedNode.createdAt).toLocaleString(localesOptions, {
                                                    year: 'numeric',
                                                    month: 'long',
                                                    day: 'numeric',
                                                })}
                                            </Typography>
                                            <Typography className={classes.hourText}>
                                                {new Date(selectedNode.createdAt).toLocaleString(localesOptions, {
                                                    hour: 'numeric',
                                                    minute: 'numeric',
                                                })}
                                            </Typography>
                                        </span>
                                    ) : null}
                                </div>
                            ) : (
                                <div className={classes.noNameText}>
                                    <Typography variant="h5">
                                        <FormattedMessage id="redesign.collections.noNameFolder" />
                                    </Typography>
                                </div>
                            )}
                            <Divider className={classes.customDivider} />
                            {boards.length > 0 ? (
                                <>
                                    <Grid item>
                                        <Paper className={classes.customPaperSlideShow}>
                                            <SlideShow slideElements={slideElements} />
                                        </Paper>
                                    </Grid>
                                    <Divider className={classes.customDivider} />
                                </>
                            ) : null}
                            {(selectedNode && selectedNode.isCollection) || create ? null : selectedNode &&
                              !selectedNode.isUserBoard ? (
                                <div style={{ padding: '2rem 2.5rem 0rem 2.5rem' }}>
                                    <Autocomplete
                                        fullWidth
                                        multiple
                                        id="selected-tags-outlined"
                                        size="small"
                                        options={tags}
                                        getOptionLabel={(option) => {
                                            if (option.inputValue) {
                                                return (
                                                    <div style={{ width: '100%', display: 'inline-flex' }}>
                                                        <AddBoxOutlined />
                                                        <FormattedMessage id={'collections.createTag'} /> :{' '}
                                                        {option.inputValue}
                                                    </div>
                                                );
                                            }
                                            return option.name;
                                        }}
                                        filterSelectedOptions
                                        value={setTags}
                                        filterOptions={(options, params) => {
                                            const filtered = filter(options, params);
                                            if (params.inputValue !== '') {
                                                filtered.push({
                                                    inputValue: params.inputValue,
                                                    id: -1,
                                                });
                                            }
                                            return filtered;
                                        }}
                                        onChange={(event, v, reason) => tagsChanged(v, reason)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label={intl.formatMessage({ id: 'collections.filterTags' })}
                                                placeholder={intl.formatMessage({ id: 'collections.tags' })}
                                            />
                                        )}
                                    />
                                </div>
                            ) : null}
                            <MobileModalContent
                                intl={intl}
                                selectedNode={selectedNode}
                                editBoard={editBoard}
                                deleteBoard={deleteBoard}
                                cancelFolderDeletion={cancelFolderDeletion}
                                create={create}
                                addToCollection={addToCollection}
                                state={state}
                                loadCollections={loadCollections}
                            />
                        </Box>
                    </Slide>
                </Modal>
            </Hidden>
        </>
    );
};

export default injectIntl(MobileCollectionEditor);
