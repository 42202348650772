import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <React.Fragment>
            <CssBaseline />
            <Container maxWidth="sm">
                <Typography variant="h4" gutterBottom>
                    <FormattedMessage id="notFound" />
                </Typography>
                <Typography variant="h5" gutterBottom>
                    <Link to="/">
                        <FormattedMessage id="mainPage" />
                    </Link>
                </Typography>
            </Container>
        </React.Fragment>
    );
};

export default injectIntl(NotFound);
