import { Fab, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ShareIcon from '@material-ui/icons/Share';
import DraftsIcon from '@material-ui/icons/Drafts';
import MultiBoardIcon from '../../../../MyComponents/icons/MultiBoardIcon';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import HandRaisedIcon from '../../../../MyComponents/icons/HandRaisedIcon';
import SectionChapter from '../../SectionChapter';
import { COLORS } from '../../../../constants';

const MainMenuManagement = ({ refs, classes }) => {
    let mainMenu = [
        {
            id: 'tutorial.manageLesson.mainMenu.explanation1',
        },
        {
            id: 'tutorial.manageLesson.mainMenu.explanation2',
            children: (
                <Fab
                    size="small"
                    className={classes.sampleFab}
                    style={{ backgroundColor: COLORS.primary, color: '#ffffff' }}
                >
                    <MenuIcon style={{ padding: '0px' }} />
                </Fab>
            ),
        },
        {
            id: 'tutorial.manageLesson.title',
            children: (
                <ul>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.manageLesson.mainMenu.term1"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                            <ArrowBackIosIcon className={classes.sampleFab} />
                        </Typography>

                        <Typography>
                            <FormattedMessage
                                id="tutorial.manageLesson.mainMenu.term1.explanation"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.manageLesson.mainMenu.term2"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                            <ShareIcon className={classes.sampleFab} />
                        </Typography>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.manageLesson.mainMenu.term2.explanation"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.manageLesson.mainMenu.term3"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                            <DraftsIcon className={classes.sampleFab} />
                        </Typography>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.manageLesson.mainMenu.term3.explanation"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>

                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.manageLesson.mainMenu.term9"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                            <MultiBoardIcon className={classes.sampleFab} />
                        </Typography>

                        <Typography>
                            <FormattedMessage
                                id="tutorial.manageLesson.mainMenu.term9.explanation"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>

                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.manageLesson.mainMenu.term5"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                            <PeopleAltIcon className={classes.sampleFab} />
                        </Typography>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.manageLesson.mainMenu.term5.explanation"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                        <ul>
                            <li>
                                <Typography>
                                    <FormattedMessage
                                        id="tutorial.manageLesson.mainMenu.term5.explanation.list.term1"
                                        values={{
                                            b: (chunks) => <b>{chunks}</b>,
                                        }}
                                    />
                                </Typography>
                            </li>
                            <li>
                                <Typography>
                                    <FormattedMessage
                                        id="tutorial.manageLesson.mainMenu.term5.explanation.list.term2"
                                        values={{
                                            b: (chunks) => <b>{chunks}</b>,
                                        }}
                                    />
                                </Typography>
                            </li>
                            <li>
                                <Typography>
                                    <FormattedMessage
                                        id="tutorial.manageLesson.mainMenu.term5.explanation.list.term3"
                                        values={{
                                            b: (chunks) => <b>{chunks}</b>,
                                        }}
                                    />
                                </Typography>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.manageLesson.mainMenu.term4"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.manageLesson.mainMenu.term4.explanation"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.manageLesson.mainMenu.term6"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                            <HandRaisedIcon className={classes.sampleFab} />
                        </Typography>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.manageLesson.mainMenu.term6.explanation"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>
                </ul>
            ),
        },
    ];

    return (
        <SectionChapter
            refs={refs}
            id={'section-manageLesson-mainMenu'}
            title={'tutorial.manageLesson.mainMenu.title'}
            explanations={mainMenu}
            imageTop={false}
        />
    );
};

export default MainMenuManagement;
