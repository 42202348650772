import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function MoveIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                zoomAndPan="magnify"
                viewBox="0 0 112.5 112.499997"
                preserveAspectRatio="xMidYMid meet"
                version="1.0"
            >
                <path
                    d="M 52.433594 1.980469 C 54.40625 0.0117188 57.597656 0.0117188 59.570312 1.980469 L 74.703125 17.117188 L 67.570312 24.253906 L 56 12.683594 L 44.433594 24.253906 L 37.296875 17.117188 Z M 52.433594 1.980469 "
                    fillOpacity="1"
                    fillRule="evenodd"
                />
                <path
                    d="M 52.433594 110.019531 C 54.40625 111.988281 57.597656 111.988281 59.570312 110.019531 L 74.703125 94.882812 L 67.570312 87.75 L 56 99.316406 L 44.433594 87.75 L 37.296875 94.882812 Z M 52.433594 110.019531 "
                    fillOpacity="1"
                    fillRule="evenodd"
                />
                <path
                    d="M 12.683594 56 L 24.253906 44.433594 L 17.117188 37.296875 L 1.980469 52.433594 C 0.0117188 54.402344 0.0117188 57.597656 1.980469 59.570312 L 17.117188 74.703125 L 24.253906 67.570312 Z M 12.683594 56 "
                    fillOpacity="1"
                    fillRule="evenodd"
                />
                <path
                    d="M 99.316406 56 L 87.75 44.433594 L 94.882812 37.296875 L 110.019531 52.433594 C 111.988281 54.402344 111.988281 57.597656 110.019531 59.570312 L 94.882812 74.703125 L 87.75 67.570312 Z M 99.316406 56 "
                    fillOpacity="1"
                    fillRule="evenodd"
                />
                <path
                    d="M 50.957031 5.550781 L 61.046875 5.550781 L 61.046875 106.453125 L 50.957031 106.453125 Z M 50.957031 5.550781 "
                    fillOpacity="1"
                    fillRule="evenodd"
                />
                <path
                    d="M 5.550781 50.957031 L 106.453125 50.957031 L 106.453125 61.046875 L 5.550781 61.046875 Z M 5.550781 50.957031 "
                    fillOpacity="1"
                    fillRule="evenodd"
                />
            </svg>
        </SvgIcon>
    );
}
