import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function EraserIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 98 98">
                <g data-name="&lt;Group&gt;">
                    <g data-name="&lt;Group&gt;">
                        <path
                            d="M85.3,33,70.2,17.9a6.4,6.4,0,0,0-8.9,0L29.8,49.4l24,24L85.3,41.9a6.4,6.4,0,0,0,1.9-4.5A6.1,6.1,0,0,0,85.3,33Z"
                            data-name="&lt;Path&gt;"
                        />
                    </g>
                    <g data-name="&lt;Group&gt;">
                        <path
                            d="M66.4,82h-21l6.3-6.4-24-24.1-9.4,9.4a6.5,6.5,0,0,0,0,9L30.3,82H9.3a1.5,1.5,0,0,0,0,3H66.4a1.5,1.5,0,0,0,0-3Z"
                            data-name="&lt;Path&gt;"
                        />
                    </g>
                </g>
            </svg>
        </SvgIcon>
    );
}
