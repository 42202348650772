import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import AccountCircle from '@material-ui/icons/AccountCircle';
import {
    Button,
    ClickAwayListener,
    Grow,
    makeStyles,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Typography,
} from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';
import { COLORS, COLORS2 } from '../../constants';
import RomaniaIcon from '../icons/Romania';
import UKIcon from '../icons/UK';
import FranceIcon from '../icons/France';
import {
    ABOUT_US_LINK,
    ABOUT_US_MEDIA_LINK,
    ABOUT_US_PARTNERS_LINK,
    ABOUT_US_TEAM_LINK,
    FEATURES_LINK,
    FOR_SCHOOLS_LINK,
    FOR_TUTORING_LINK,
    SUBSCRIPTIONS_LINK,
    TUTORIAL_LINK,
} from '../../common/urlLinks';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import LoggedOutMenuIcon from '../icons/LoggedOutMenuIcon';
import SchoolIcon from '@material-ui/icons/SchoolOutlined';
import TutoringIcon from '../icons/ZoomIcon';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import ArrowLeftIcon from '../icons/ArrowLeftIcon';
import PricingIcon from '../icons/PricingIcon';
import TutorialIcon from '../icons/TutorialIcon';
import LearnIcon from '../icons/LearnIcon';
import MediaIcon from '../icons/MediaIcon';
import PartnersIcon from '../icons/PartnersIcon';
import { InfoOutlined, PeopleOutline } from '@material-ui/icons';
import MissionIcon from '../icons/MissionIcon';

const useStyles = makeStyles((theme) => ({
    small: {
        width: theme.spacing(2),
        height: theme.spacing(2),
    },
    large: {
        width: theme.spacing(6),
        height: theme.spacing(6),
        cursor: 'pointer',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: COLORS.primary,
    },
    img: {
        marginRight: '0.5rem',
        display: 'inline',
    },
    selectLanguageItem: {
        textTransform: 'uppercase',
        fontWeight: '600',
        color: COLORS2.darkGreen,
        fontStyle: 'normal',
        letterSpacing: '0.1rem',
        fontSize: '0.875rem',
        lineHeight: '1.063rem',
        textAlign: 'right',
    },
    menuText: {
        fontWeight: 400,
        fontSize: '1rem',
        lineHeight: '1.25rem',
        letterSpacing: '-0.01rem',
        color: COLORS2.darkGreen,
    },
}));

const LOCATION_MAP = {
    HOME: {
        url: '/',
    },
    SUBSCRIPTIONS: {
        url: SUBSCRIPTIONS_LINK,
    },
    FEATURES: {
        url: FEATURES_LINK,
    },
    TUTORIAL: {
        url: TUTORIAL_LINK,
    },
    FOR_TUTORING: {
        url: FOR_TUTORING_LINK,
    },
    FOR_SCHOOLS: {
        url: FOR_SCHOOLS_LINK,
    },
};

const LoggedOutMobileMenu = ({ profile, intl, handleRo, handleEn, handleFr }) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [openSubmenu, setOpenSubMenu] = React.useState(null);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
        setOpenSubMenu(null);
    };

    const handleOpenSubMenu = (val) => {
        setOpenSubMenu(val);
    };

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    if (!profile) {
        return <AccountCircle />;
    }

    let languageMenu = (
        <div
            style={{
                display: 'block',
                paddingLeft: '1rem',
                paddingRight: '1rem',
                paddingBottom: '0.5rem',
                paddingTop: '0.5rem',
            }}
        >
            <Button size="small" onClick={handleRo}>
                <RomaniaIcon className={classes.img} />
                <Typography className={classes.selectLanguageItem}>RO</Typography>
            </Button>
            <Button size="small" onClick={handleEn}>
                <UKIcon className={classes.img} />
                <Typography className={classes.selectLanguageItem}>EN</Typography>
            </Button>
            <Button size="small" onClick={handleFr}>
                <FranceIcon className={classes.img} />
                <Typography className={classes.selectLanguageItem}>FR</Typography>
            </Button>
        </div>
    );

    let usageMenu = (
        <>
            <Link to={LOCATION_MAP.FEATURES.url} onClick={handleClose}>
                <MenuItem>
                    <ListItemIcon>
                        <LearnIcon />
                    </ListItemIcon>
                    <ListItemText
                        disableTypography
                        primary={intl.formatMessage({ id: 'topMenu.features.name' })}
                        className={classes.menuText}
                    />
                </MenuItem>
            </Link>
            <Link to={LOCATION_MAP.TUTORIAL.url} onClick={handleClose}>
                <MenuItem>
                    <ListItemIcon>
                        <TutorialIcon style={{ color: 'rgba(8, 170, 180, 1)' }} />
                    </ListItemIcon>
                    <ListItemText
                        disableTypography
                        primary={intl.formatMessage({ id: 'topMenu.tutorial.name' })}
                        className={classes.menuText}
                    />
                </MenuItem>
            </Link>
            <MenuItem
                onClick={() => {
                    handleOpenSubMenu(null);
                }}
            >
                <ListItemIcon>
                    <ArrowLeftIcon />
                </ListItemIcon>
                <ListItemText
                    disableTypography
                    primary={intl.formatMessage({ id: 'redesign.subMenu.mobile.back' })}
                    className={classes.menuText}
                />
            </MenuItem>
        </>
    );

    let aboutUsMenu = (
        <>
            <Link to={ABOUT_US_LINK} onClick={handleClose}>
                <MenuItem>
                    <ListItemIcon>
                        <MissionIcon style={{ color: 'rgba(8, 170, 180, 1)' }} />
                    </ListItemIcon>
                    <ListItemText
                        disableTypography
                        primary={intl.formatMessage({ id: 'aboutUs.ourMission.button' })}
                        className={classes.menuText}
                    />
                </MenuItem>
            </Link>
            <Link to={ABOUT_US_PARTNERS_LINK} onClick={handleClose}>
                <MenuItem>
                    <ListItemIcon>
                        <PartnersIcon style={{ color: 'rgba(8, 170, 180, 1)' }} />
                    </ListItemIcon>
                    <ListItemText
                        disableTypography
                        primary={intl.formatMessage({ id: 'aboutUs.partners.button' })}
                        className={classes.menuText}
                    />
                </MenuItem>
            </Link>
            <Link to={ABOUT_US_TEAM_LINK} onClick={handleClose}>
                <MenuItem>
                    <ListItemIcon>
                        <PeopleOutline style={{ color: 'rgba(8, 170, 180, 1)' }} />
                    </ListItemIcon>
                    <ListItemText
                        disableTypography
                        primary={intl.formatMessage({ id: 'aboutUs.team.button' })}
                        className={classes.menuText}
                    />
                </MenuItem>
            </Link>

            <Link to={ABOUT_US_MEDIA_LINK} onClick={handleClose}>
                <MenuItem>
                    <ListItemIcon>
                        <MediaIcon style={{ color: 'rgba(8, 170, 180, 1)' }} />
                    </ListItemIcon>
                    <ListItemText
                        disableTypography
                        primary={intl.formatMessage({ id: 'aboutUs.media.button' })}
                        className={classes.menuText}
                    />
                </MenuItem>
            </Link>

            <MenuItem
                onClick={() => {
                    handleOpenSubMenu(null);
                }}
            >
                <ListItemIcon>
                    <ArrowLeftIcon />
                </ListItemIcon>
                <ListItemText
                    disableTypography
                    primary={intl.formatMessage({ id: 'redesign.subMenu.mobile.back' })}
                    className={classes.menuText}
                />
            </MenuItem>
        </>
    );

    let mainMenu = (
        <>
            <Link to={LOCATION_MAP.HOME.url} onClick={handleClose}>
                <MenuItem>
                    <ListItemIcon>
                        <HomeOutlinedIcon style={{ color: 'rgba(8, 170, 180, 1)' }} />
                    </ListItemIcon>
                    <ListItemText
                        disableTypography
                        primary={intl.formatMessage({ id: 'home' })}
                        className={classes.menuText}
                    />
                </MenuItem>
            </Link>
            <Link to={LOCATION_MAP.FOR_TUTORING.url} onClick={handleClose}>
                <MenuItem>
                    <ListItemIcon>
                        <TutoringIcon style={{ color: 'rgba(8, 170, 180, 1)' }} />
                    </ListItemIcon>
                    <ListItemText
                        disableTypography
                        primary={intl.formatMessage({ id: 'topMenu.forTutoring.name' })}
                        className={classes.menuText}
                    />
                </MenuItem>
            </Link>
            <Link to={LOCATION_MAP.FOR_SCHOOLS.url} onClick={handleClose}>
                <MenuItem>
                    <ListItemIcon>
                        <SchoolIcon style={{ color: 'rgba(8, 170, 180, 1)' }} />
                    </ListItemIcon>
                    <ListItemText
                        disableTypography
                        primary={intl.formatMessage({ id: 'topMenu.forSchools.name' })}
                        className={classes.menuText}
                    />
                </MenuItem>
            </Link>
            <Link to={LOCATION_MAP.SUBSCRIPTIONS.url} onClick={handleClose}>
                <MenuItem>
                    <ListItemIcon>
                        <PricingIcon />
                    </ListItemIcon>
                    <ListItemText
                        disableTypography
                        primary={intl.formatMessage({ id: 'topMenu.subscriptions.name' })}
                        className={classes.menuText}
                    />
                </MenuItem>
            </Link>
            <MenuItem
                onClick={() => {
                    handleOpenSubMenu('USAGE');
                }}
            >
                <ListItemIcon>
                    <MenuOpenIcon style={{ color: 'rgba(8, 170, 180, 1)' }} />
                </ListItemIcon>
                <ListItemText
                    disableTypography
                    primary={intl.formatMessage({ id: 'redesign.topMenu.usage.name' })}
                    className={classes.menuText}
                />
            </MenuItem>
            <MenuItem
                onClick={() => {
                    handleOpenSubMenu('ABOUT');
                }}
            >
                <ListItemIcon>
                    <InfoOutlined style={{ color: 'rgba(8, 170, 180, 1)' }} />
                </ListItemIcon>
                <ListItemText
                    disableTypography
                    primary={intl.formatMessage({ id: 'aboutUs' })}
                    className={classes.menuText}
                />
            </MenuItem>
        </>
    );

    return (
        <div>
            <div style={{ position: 'relative' }}>
                <Button
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    startIcon={<LoggedOutMenuIcon />}
                />
            </div>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                placement={'bottom-end'}
                style={{ zIndex: 2 }}
            >
                {({ TransitionProps }) => (
                    <Grow {...TransitionProps} style={{ transformOrigin: 'left bottom' }}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                {openSubmenu ? (
                                    <MenuList autoFocusItem={open} id="menu-list-grow">
                                        {openSubmenu === 'ABOUT' ? aboutUsMenu : usageMenu}
                                        <Divider />
                                        {languageMenu}
                                    </MenuList>
                                ) : (
                                    <MenuList autoFocusItem={open} id="menu-list-grow">
                                        {mainMenu}
                                        <Divider />
                                        {languageMenu}
                                    </MenuList>
                                )}
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
};

const mapStateToProps = (state) => {
    const { profile, userMessages, mainAppState } = state;
    return { profile, userMessages, subscriptions: mainAppState.subscriptions };
};

export default injectIntl(connect(mapStateToProps)(LoggedOutMobileMenu));
