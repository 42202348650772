import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function LibrarySideMenuIcon(props) {
    return (
        <SvgIcon {...props} style={{ verticalAlign: 'bottom' }}>
            <svg viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_46_253)">
                    <path d="M2.98828 0.513281L7.07266 4.59766L4.59766 7.07266L0.513281 2.98828C-0.171094 2.30391 -0.171094 1.19766 0.513281 0.513281C1.19766 -0.171094 2.30391 -0.171094 2.98828 0.513281ZM12.5008 16.0008C10.8258 16.0008 9.42266 14.8227 9.08203 13.2477L13.2477 9.08203C14.8195 9.42578 16.0008 10.8258 16.0008 12.5008C16.0008 12.6227 15.9945 12.7445 15.982 12.8633C15.9258 13.4102 16.3008 14.0008 16.8508 14.0008H17.0008C17.5539 14.0008 18.0008 14.4477 18.0008 15.0008C18.0008 15.5539 17.5539 16.0008 17.0008 16.0008H12.5008ZM15.6664 0.603906L16.3977 1.33516C17.1789 2.11641 17.1789 3.38203 16.3977 4.16328L14.6352 5.92578L11.0727 2.36641L12.8352 0.603906C13.6164 -0.177344 14.882 -0.177344 15.6633 0.603906H15.6664ZM2.40391 11.0383L10.3664 3.07266L13.9289 6.63516L5.96328 14.5977C5.75391 14.807 5.49141 14.9602 5.20703 15.0414L1.95703 15.9695C1.69453 16.0445 1.41328 15.9727 1.21953 15.7789C1.02578 15.5852 0.953906 15.3039 1.02891 15.0414L1.95703 11.7914C2.03828 11.5039 2.19141 11.2445 2.40078 11.0352L2.40391 11.0383Z" fill="#08AAB4"/>
                </g>
                <defs>
                    <clipPath id="clip0_46_253">
                        <rect width="18" height="16" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        </SvgIcon>
    );
}