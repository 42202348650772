import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import store from '../../redux/store';
import { updateLatexText } from '../../redux/actions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { DialogTitle, Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

const LatexEditDialog = ({ lessonState }) => {
    let [dialogEditLatex, setDialogEditLatex] = useState(null);
    let [text, setText] = useState('');

    useEffect(() => {
        setDialogEditLatex(lessonState.dialogEditLatex);
        if (lessonState.dialogEditLatex?.el?.latex) {
            setText(lessonState.dialogEditLatex?.el?.latex);
        }
    }, [lessonState.dialogEditLatex]);

    const close = () => {
        store.dispatch(updateLatexText(null));
    };

    const updateLatex = () => {
        if (dialogEditLatex?.el && text.trim().length > 0 && dialogEditLatex.callback) {
            dialogEditLatex.callback(dialogEditLatex.el.id, text.trim());
        }
        setDialogEditLatex(null);
    };

    return (
        <Dialog open={!!dialogEditLatex} onClose={close}>
            <DialogTitle>
                <FormattedMessage id="generic.label.update" />
            </DialogTitle>
            <DialogContent>
                <Typography>
                    <FormattedMessage id="lessonPage.latexEdit" />
                </Typography>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ padding: '2rem' }}>
                        <FormattedMessage id="subscriptions.subscription.findOutMore" />
                    </span>

                    <a href="https://www.latex-project.org/help/documentation/" target="_blank" rel="noreferrer">
                        <img src="/latex-project-logo.svg" alt="LaTeX" style={{ height: '3rem' }} />
                    </a>
                </div>
                {dialogEditLatex ? (
                    <TextField
                        value={text}
                        onChange={(event) => {
                            setText(event.target.value);
                        }}
                        required
                        fullWidth
                        variant="outlined"
                        autoFocus={true}
                        multiline
                        rows={5}
                    />
                ) : null}
            </DialogContent>
            <DialogActions style={{ marginBottom: '0.5rem', marginRight: '1rem' }}>
                <Button onClick={updateLatex} onTouchStart={updateLatex} color="primary">
                    <FormattedMessage id="ok" />
                </Button>
                <Button onClick={close} onTouchStart={close} color="primary">
                    <FormattedMessage id="close" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    const { lessonState } = state;
    return { lessonState };
};

export default connect(mapStateToProps)(LatexEditDialog);
