import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import SectionChapter from '../../SectionChapter';

const BoardManagement = ({ language, refs }) => {
    let boardMenu = [
        {
            id: 'tutorial.manageLesson.boardMenu.explanation',
            children: (
                <ul>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.boardMenu.navigation"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.boardMenu.navigation.explanation"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>
                </ul>
            ),
        },
        {
            children: (
                <ul>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.boardMenu.manageBoard"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.boardMenu.manageBoard.explanation"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>
                </ul>
            ),
            img: [
                `/static/asset/img/tutorial/withText/${language.toUpperCase()}/lessonPage_navigateBoards.jpeg`,
                `/static/asset/img/tutorial/withText/${language.toUpperCase()}/lessonPage_manageBoards.jpeg`,
            ],
        },
    ];
    return (
        <SectionChapter
            refs={refs}
            id={'section-manageLesson-boardMenu'}
            title={'tutorial.manageLesson.boardMenu.title'}
            explanations={boardMenu}
            imageTop={false}
        />
    );
};

export default BoardManagement;
