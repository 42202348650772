import React, { useEffect, useState } from 'react';
import { Button, Container, Grid, Paper } from '@material-ui/core';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import {
    getSubscriptionStateAction,
    SUBSCRIPTION_STATE_ACTION,
    SUBSCRIPTION_PLANS,
    TRIAL_DAYS,
} from '../../../shared/Capabilities.js';

import Typography from '@material-ui/core/Typography';
import { featureList, replaceValues } from '../../../common/features';
import { cloneDeep } from 'lodash';
import { Link } from 'react-router-dom';
import { SPECIAL_QUOTA_LINK } from '../../../common/urlLinks';
import { GA_EVENT_TREE } from '../../../gaConstants';
import SubscriptionDescriptionListRenderer from '../SubscriptionDescriptionListRenderer';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS2, VIDEO_URLS } from '../../../constants';
import DesktopSubscriptionFeatures from './DesktopSubscriptionFeatures';
import { ButtonGroupCurrency } from '../../../MyComponents/ButtonGroupSubscriptions/ButtonGroupCurrency';
import { ButtonGroupMonthly } from '../../../MyComponents/ButtonGroupSubscriptions/ButtonGroupMonthly';
import DesktopLanding from '../../../MyComponents/Landing/DesktopLanding';
import { CustomButtonGroupCurr } from '../../../MyComponents/ButtonGroupSubscriptions/CustomButtonGroupCurr';
import { CustomButtonGroupMonthly } from '../../../MyComponents/ButtonGroupSubscriptions/CustomButtonGroupMonthly';
import { StartGroupButton } from '../../../MyComponents/StartGroupButton/StartGroupButton';
import { DesktopLoginButtons } from '../../Home/HomeModals/DesktopLoginButtons';
import { ONBOARDING_STEPS } from '../../../shared/OnboardingOptions';

const SUBSCRIPTIONS_AVAILABLE = [SUBSCRIPTION_PLANS.FREE, SUBSCRIPTION_PLANS.BASIC, SUBSCRIPTION_PLANS.PREMIUM];

const useStyles = makeStyles(() => ({
    wrap: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: COLORS2.dawnBackground,
    },
    buttonGroup: {
        backgroundColor: 'rgba(255,255,255,0.9)',
        borderRadius: '4.75rem',
        padding: '0.25rem',
    },
    teamsPaper: {
        background: 'rgba(255, 205, 52, 0.06)',
        padding: '2rem',
    },
    whiteBackground: {
        display: 'flex',
        position: 'absolute',
        backgroundColor: 'white',
        borderRadius: '2rem',
        padding: '2rem',
        width: '45rem',
        height: '30rem',
        left: '25%',
    },
    startButtonContainer: {
        background: 'transparent',
        paddingBottom: '1rem',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '1.5rem',
    },
    subscriptionsContainer: {
        marginTop: '-5rem',
        padding: '5rem',
        paddingTop: '16rem',
        backgroundColor: COLORS2.darkGreen,
    },
    subscriptionsContainerLoggedIn: {
        paddingTop: '3rem',
        backgroundColor: COLORS2.dawnBackground,
    },
    subscriptionsPaper: {
        background: COLORS2.darkGreen,
        padding: '2rem',
    },
    subscriptionsPaperLoggedIn: {
        background: COLORS2.dawnBackground,
        padding: '2rem',
    },
    description: {
        width: '100%',
        marginTop: '0.5rem',
        textAlign: 'center',
    },
    customH2Header: {
        lineHeight: '2rem',
        fontSize: '2rem',
        color: 'white',
        fontWeight: 400,
    },
    topButtons: {
        border: '1px solid rgba(6, 81, 86, 0.24)',
        borderRadius: '1rem',
        height: '4.5rem',
        backgroundColor: 'rgba(6, 81, 86, 0.08)',
        boxShadow: 'none',
        color: COLORS2.darkGreen,
        width: '23rem',
    },
    customH2HeaderLoggedIn: {
        lineHeight: '2rem',
        fontSize: '2rem',
        color: COLORS2.darkGreen,
        fontWeight: 400,
    },
    customH2: {
        color: 'white',
        fontWeight: 700,
    },
    customH2LoggedIn: {
        color: COLORS2.darkGreen,
        fontWeight: 700,
    },
    alignWidth: {
        width: '100%',
        textAlign: 'center',
        marginTop: '1rem',
    },
    monthlyPosition: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginRight: '2rem',
    },
    currencyPosition: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    switchesContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '4rem',
    },
    switchesContainerLoggedIn: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '2rem',
        marginTop: '6rem',
    },
    listPaper: {
        width: '100%',
        background: 'rgba(6, 81, 86, 1)',
    },
    listPaperLoggedIn: {
        width: '101.7%',
        background: COLORS2.dawnBackground,
    },
    featuresText: {
        fontWeight: 400,
        letterSpacing: '0.24rem',
        lineHeight: '1.63rem',
        color: 'rgba(255,255,255,0.66)',
        marginTop: '9.35rem',
        marginBottom: '4rem',
    },
    featuresTextLoggedIn: {
        fontWeight: 400,
        letterSpacing: '0.24rem',
        lineHeight: '1.63rem',
        color: 'rgba(6, 81, 86, 0.66)',
        marginTop: '4.35rem',
        marginBottom: '4rem',
    },
    homeImg: {
        display: 'flex',
        position: 'absolute',
        backgroundColor: '#FFFFFF',
        borderRadius: '2rem',
        width: '45rem',
        height: '30rem',
        left: '22%',
        zIndex: '0',
    },
    centerImg: {
        zIndex: '0',
        display: 'flex',
        alignItems: 'center',
    },
}));

const SubscriptionsDesktop = ({ profile, subscriptions, pricing, language, handleOpen, handleLogin, open }) => {
    const classes = useStyles();
    const [billAnnual, setBillAnnual] = useState(true);
    const [billEur, setBillEur] = useState(language !== 'ro');

    useEffect(() => {
        setBillEur(language !== 'ro');
    }, [language]);

    if (!profile || !subscriptions || !profile.subscription) {
        return null;
    }

    const handleCurrencySelect = () => {
        setBillEur(!billEur);
        if (billEur) {
            window.logAppActivity(GA_EVENT_TREE.mainPage.onboarding.checkEur);
        } else {
            window.logAppActivity(GA_EVENT_TREE.mainPage.onboarding.checkRon);
        }
    };

    const handleChange = () => {
        setBillAnnual(!billAnnual);
        if (billAnnual) {
            window.logAppActivity(GA_EVENT_TREE.mainPage.onboarding.checkAnnual);
        } else {
            window.logAppActivity(GA_EVENT_TREE.mainPage.onboarding.checkMonthly);
        }
    };

    let subscriptionsToRender = [];
    let availableSubs = [];
    SUBSCRIPTIONS_AVAILABLE.forEach((subscriptionName) => {
        if (window.isTeams && subscriptionName === SUBSCRIPTION_PLANS.PREMIUM) {
            return;
        }
        let subscription = subscriptions[subscriptionName];
        if (subscription) {
            let myFeatureList = cloneDeep(featureList);
            replaceValues(subscription, myFeatureList);
            subscriptionsToRender.push(myFeatureList);
            availableSubs.push(subscriptionName);
        }
    });

    let canUpgrade = true;
    if (!profile.subscription.canceledAt) {
        subscriptionsToRender.forEach((el, index) => {
            if (
                getSubscriptionStateAction(profile, subscriptions, SUBSCRIPTIONS_AVAILABLE[index]) ===
                SUBSCRIPTION_STATE_ACTION.CANCEL
            ) {
                canUpgrade = false;
            }
        });
    }

    let canStartTrial = profile?.subscription?.trialStarted === false;

    if (!profile || !profile.loggedIn) {
        canStartTrial = true;
    }

    if (window.isTeams) {
        return (
            <Grid
                container
                direction="row"
                justify="center"
                style={{ paddingTop: '2rem', paddingBottom: '2rem' }}
                spacing={2}
            >
                <Grid item xs={12}>
                    <Paper elevation={0} className={classes.teamsPaper}>
                        <Grid container spacing={2} justify={'center'}>
                            <Grid item xs={12}>
                                <div style={{ width: '100%', marginTop: '0.5rem', textAlign: 'center' }}>
                                    <Typography variant="h2">
                                        <FormattedMessage id="topMenu.teams.subscriptions.name" />
                                    </Typography>
                                    <Typography variant="h5">
                                        <FormattedMessage id="subscriptions.teams.subscription.description1" />
                                    </Typography>
                                    <Typography variant="h5">
                                        <FormattedMessage id="subscriptions.teams.subscription.description2" />
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={3} />
                            <Grid item xs={12} sm={3}>
                                <div style={{ padding: '0.5rem' }}></div>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <div style={{ padding: '0.5rem' }}>
                                    <Link to={SPECIAL_QUOTA_LINK}>
                                        <Button variant={'contained'} color={'secondary'} size={'large'} fullWidth>
                                            <FormattedMessage id={'subscriptions.subscription.requestSpecialQuote'} />
                                        </Button>
                                    </Link>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={3} />
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        );
    }

    const setCreateGroupPath = () => {
        window.localStorage.setItem('pathBeforeLogin', '/creategroup');
    };

    return (
        <>
            {!profile.loggedIn ? (
                <>
                    <Container maxWidth="lg">
                        <DesktopLanding videoUrl={VIDEO_URLS['pricing'][language.toUpperCase()]} needsBackground={false}>
                            <div style={{ zIndex: '1', paddingBottom: '1rem' }}>
                                <Typography variant="h1">
                                    <FormattedMessage
                                        id={'redesign.subscriptions.name'}
                                        values={{
                                            b: (chunks) => (
                                                <b>
                                                    {chunks}
                                                    <br></br>
                                                </b>
                                            ),
                                        }}
                                    />
                                </Typography>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'bottom',
                                        paddingTop: '1rem',
                                    }}
                                >
                                    <StartGroupButton
                                        handleOpen={() => {
                                            handleOpen(false);
                                            setCreateGroupPath();
                                        }}
                                    />
                                    {!open ? (
                                        <DesktopLoginButtons handleLogin={handleLogin} />
                                    ) : (
                                        <div style={{ width: '18rem' }} />
                                    )}
                                </div>
                            </div>
                        </DesktopLanding>
                    </Container>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        className={classes.subscriptionsContainer}
                        spacing={2}
                    >
                        <Grid item xs={12}>
                            <Paper elevation={0} className={classes.subscriptionsPaper}>
                                <Grid container spacing={2} justify={'center'}>
                                    <Grid item xs={12}>
                                        <div className={classes.description}>
                                            <Typography variant="h2" className={classes.customH2Header}>
                                                <FormattedMessage id="redesign.subscriptions.subscription.description1" />
                                            </Typography>
                                            <Typography variant="h2" className={classes.customH2Header}>
                                                <FormattedMessage id="redesign.subscriptions.subscription.description2" />
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={3} />
                                </Grid>
                            </Paper>
                        </Grid>

                        <Grid item xs={12} className={classes.switchesContainer}>
                            <Grid item xs={12} sm={6}>
                                <div className={classes.alignWidth}>
                                    <div className={classes.monthlyPosition}>
                                        <ButtonGroupMonthly billAnnual={billAnnual} handleChange={handleChange} />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div className={classes.alignWidth}>
                                    <div className={classes.currencyPosition}>
                                        <ButtonGroupCurrency
                                            billEur={billEur}
                                            handleCurrencySelect={handleCurrencySelect}
                                        />
                                    </div>
                                </div>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={5}>
                            <Grid container spacing={10}>
                                <Grid item xs={12} md={12} />
                                <Grid item xs={12} md={12}>
                                    <Typography variant="subtitle1" className={classes.featuresText}>
                                        <FormattedMessage id="topMenu.features.name" />
                                    </Typography>
                                    <Paper elevation={0} className={classes.listPaper}>
                                        <SubscriptionDescriptionListRenderer featureList={featureList} />
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={7} style={{ display: 'flex' }}>
                            {subscriptionsToRender.map((el, index) => {
                                let isActive =
                                    getSubscriptionStateAction(
                                        profile,
                                        subscriptions,
                                        SUBSCRIPTIONS_AVAILABLE[index]
                                    ) === SUBSCRIPTION_STATE_ACTION.ACTIVE ||
                                    getSubscriptionStateAction(
                                        profile,
                                        subscriptions,
                                        SUBSCRIPTIONS_AVAILABLE[index]
                                    ) === SUBSCRIPTION_STATE_ACTION.CANCEL ||
                                    (!profile.loggedIn && SUBSCRIPTIONS_AVAILABLE[index] === SUBSCRIPTION_PLANS.BASIC);
                                return (
                                    <Grid
                                        item
                                        xs={12}
                                        md={4}
                                        key={`subscriptionType-${availableSubs[index]}`}
                                        style={{ marginRight: '0.5rem' }}
                                    >
                                        <Paper
                                            style={{
                                                height: '100%',
                                                width: '100%',
                                                background:
                                                    getSubscriptionStateAction(
                                                        profile,
                                                        subscriptions,
                                                        SUBSCRIPTIONS_AVAILABLE[index]
                                                    ) === SUBSCRIPTION_STATE_ACTION.ACTIVE ||
                                                    getSubscriptionStateAction(
                                                        profile,
                                                        subscriptions,
                                                        SUBSCRIPTIONS_AVAILABLE[index]
                                                    ) === SUBSCRIPTION_STATE_ACTION.CANCEL ||
                                                    (!profile.loggedIn &&
                                                        SUBSCRIPTIONS_AVAILABLE[index] === SUBSCRIPTION_PLANS.BASIC)
                                                        ? 'rgba(250, 250, 250, 1)'
                                                        : 'rgba(6, 81, 86, 1)',
                                                boxShadow:
                                                    getSubscriptionStateAction(
                                                        profile,
                                                        subscriptions,
                                                        SUBSCRIPTIONS_AVAILABLE[index]
                                                    ) === SUBSCRIPTION_STATE_ACTION.ACTIVE ||
                                                    getSubscriptionStateAction(
                                                        profile,
                                                        subscriptions,
                                                        SUBSCRIPTIONS_AVAILABLE[index]
                                                    ) === SUBSCRIPTION_STATE_ACTION.CANCEL ||
                                                    (!profile.loggedIn &&
                                                        SUBSCRIPTIONS_AVAILABLE[index] === SUBSCRIPTION_PLANS.BASIC)
                                                        ? '0rem 0rem 0rem 0.5rem rgba(8, 170, 180, 0.16), 0rem 2rem 6rem rgba(2, 35, 37, 0.32)'
                                                        : '',
                                                borderRadius:
                                                    getSubscriptionStateAction(
                                                        profile,
                                                        subscriptions,
                                                        SUBSCRIPTIONS_AVAILABLE[index]
                                                    ) === SUBSCRIPTION_STATE_ACTION.ACTIVE ||
                                                    getSubscriptionStateAction(
                                                        profile,
                                                        subscriptions,
                                                        SUBSCRIPTIONS_AVAILABLE[index]
                                                    ) === SUBSCRIPTION_STATE_ACTION.CANCEL ||
                                                    (!profile.loggedIn &&
                                                        SUBSCRIPTIONS_AVAILABLE[index] === SUBSCRIPTION_PLANS.BASIC)
                                                        ? '2rem'
                                                        : '',
                                            }}
                                            elevation={0}
                                        >
                                            <DesktopSubscriptionFeatures
                                                featureList={el}
                                                subscriptionType={availableSubs[index]}
                                                pricing={pricing}
                                                billAnnual={billAnnual}
                                                billEur={billEur}
                                                subscriptionState={getSubscriptionStateAction(
                                                    profile,
                                                    subscriptions,
                                                    SUBSCRIPTIONS_AVAILABLE[index]
                                                )}
                                                canUpgrade={canUpgrade}
                                                isActive={isActive}
                                                handleOpen={handleOpen}
                                            />
                                        </Paper>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                </>
            ) : (
                <Grid
                    container
                    direction="row"
                    justify="center"
                    className={classes.subscriptionsContainerLoggedIn}
                    spacing={2}
                >
                    <Grid item xs={12}>
                        <Paper elevation={0} className={classes.subscriptionsPaperLoggedIn}>
                            <Grid container spacing={2} justify={'center'}>
                                <Grid item xs={12}>
                                    <div className={classes.description}>
                                        <Typography variant="h2" className={classes.customH2HeaderLoggedIn}>
                                            <FormattedMessage id="redesign.subscriptions.subscription.description1" />
                                        </Typography>
                                        <Typography variant="h2" className={classes.customH2HeaderLoggedIn}>
                                            <FormattedMessage id="redesign.subscriptions.subscription.description2" />
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={3} />
                            </Grid>
                        </Paper>
                    </Grid>

                    {canStartTrial ? (
                        <Grid item xs={12} style={{ display: 'flex' }}>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                style={{ display: 'flex', justifyContent: 'end', marginRight: '1rem' }}
                            >
                                <Link
                                    to={`/onboarding?step=${ONBOARDING_STEPS.START}&subscription=${SUBSCRIPTION_PLANS.TRIAL}&billAnnual=${billAnnual}&billEur=${billEur}`}
                                >
                                    <Button className={classes.topButtons}>
                                        <FormattedMessage
                                            id={'subscriptions.subscription.startTrial'}
                                            values={{ days: TRIAL_DAYS }}
                                        />
                                    </Button>
                                </Link>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Link to={SPECIAL_QUOTA_LINK}>
                                    <Button className={classes.topButtons}>
                                        <FormattedMessage id={'subscriptions.subscription.requestSpecialQuote'} />
                                    </Button>
                                </Link>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                            <Link to={SPECIAL_QUOTA_LINK}>
                                <Button className={classes.topButtons}>
                                    <FormattedMessage id={'subscriptions.subscription.requestSpecialQuote'} />
                                </Button>
                            </Link>
                        </Grid>
                    )}

                    <Grid item xs={12} style={{ display: 'flex' }}>
                        <Grid item xs={6} />
                        <Grid item xs={6} className={classes.switchesContainerLoggedIn}>
                            <div className={classes.monthlyPosition}>
                                <CustomButtonGroupMonthly billAnnual={billAnnual} handleChange={handleChange} />
                            </div>
                            <div className={classes.currencyPosition}>
                                <CustomButtonGroupCurr billEur={billEur} handleCurrencySelect={handleCurrencySelect} />
                            </div>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={5}>
                        <Grid container spacing={10}>
                            <Grid item xs={12} md={12} />
                            <Grid item xs={12} md={12}>
                                <Typography variant="subtitle1" className={classes.featuresTextLoggedIn}>
                                    <FormattedMessage id="topMenu.features.name" />
                                </Typography>
                                <Paper elevation={0} className={classes.listPaperLoggedIn}>
                                    <SubscriptionDescriptionListRenderer featureList={featureList} profile={profile} />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={7} style={{ display: 'flex' }}>
                        {subscriptionsToRender.map((el, index) => {
                            let isActive =
                                getSubscriptionStateAction(profile, subscriptions, SUBSCRIPTIONS_AVAILABLE[index]) ===
                                    SUBSCRIPTION_STATE_ACTION.ACTIVE ||
                                getSubscriptionStateAction(profile, subscriptions, SUBSCRIPTIONS_AVAILABLE[index]) ===
                                    SUBSCRIPTION_STATE_ACTION.CANCEL ||
                                (!profile.loggedIn && SUBSCRIPTIONS_AVAILABLE[index] === SUBSCRIPTION_PLANS.BASIC);
                            return (
                                <Grid
                                    item
                                    xs={12}
                                    md={4}
                                    key={`subscriptionType-${availableSubs[index]}`}
                                    style={{ marginRight: '0.5rem' }}
                                >
                                    <Paper
                                        style={{
                                            height: '100%',
                                            width: isActive ? '100%' : '104%',
                                            background:
                                                getSubscriptionStateAction(
                                                    profile,
                                                    subscriptions,
                                                    SUBSCRIPTIONS_AVAILABLE[index]
                                                ) === SUBSCRIPTION_STATE_ACTION.ACTIVE ||
                                                getSubscriptionStateAction(
                                                    profile,
                                                    subscriptions,
                                                    SUBSCRIPTIONS_AVAILABLE[index]
                                                ) === SUBSCRIPTION_STATE_ACTION.CANCEL ||
                                                (!profile.loggedIn &&
                                                    SUBSCRIPTIONS_AVAILABLE[index] === SUBSCRIPTION_PLANS.BASIC)
                                                    ? 'rgba(250, 250, 250, 1)'
                                                    : COLORS2.dawnBackground,
                                            boxShadow:
                                                getSubscriptionStateAction(
                                                    profile,
                                                    subscriptions,
                                                    SUBSCRIPTIONS_AVAILABLE[index]
                                                ) === SUBSCRIPTION_STATE_ACTION.ACTIVE ||
                                                getSubscriptionStateAction(
                                                    profile,
                                                    subscriptions,
                                                    SUBSCRIPTIONS_AVAILABLE[index]
                                                ) === SUBSCRIPTION_STATE_ACTION.CANCEL ||
                                                (!profile.loggedIn &&
                                                    SUBSCRIPTIONS_AVAILABLE[index] === SUBSCRIPTION_PLANS.BASIC)
                                                    ? '0rem 0rem 0rem 0.5rem rgba(8, 170, 180, 0.16), 0rem 2rem 6rem rgba(255, 255, 255, 0)'
                                                    : '',
                                            borderRadius:
                                                getSubscriptionStateAction(
                                                    profile,
                                                    subscriptions,
                                                    SUBSCRIPTIONS_AVAILABLE[index]
                                                ) === SUBSCRIPTION_STATE_ACTION.ACTIVE ||
                                                getSubscriptionStateAction(
                                                    profile,
                                                    subscriptions,
                                                    SUBSCRIPTIONS_AVAILABLE[index]
                                                ) === SUBSCRIPTION_STATE_ACTION.CANCEL ||
                                                (!profile.loggedIn &&
                                                    SUBSCRIPTIONS_AVAILABLE[index] === SUBSCRIPTION_PLANS.BASIC)
                                                    ? '2rem'
                                                    : '',
                                        }}
                                        elevation={0}
                                    >
                                        <DesktopSubscriptionFeatures
                                            featureList={el}
                                            subscriptionType={availableSubs[index]}
                                            pricing={pricing}
                                            billAnnual={billAnnual}
                                            billEur={billEur}
                                            subscriptionState={getSubscriptionStateAction(
                                                profile,
                                                subscriptions,
                                                SUBSCRIPTIONS_AVAILABLE[index]
                                            )}
                                            canUpgrade={canUpgrade}
                                            isActive={isActive}
                                            isDesktop={true}
                                            handleOpen={handleOpen}
                                        />
                                    </Paper>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    const { profile, mainAppState } = state;
    return { profile, subscriptions: mainAppState.subscriptions, pricing: mainAppState.pricing };
};

export default injectIntl(connect(mapStateToProps)(SubscriptionsDesktop));
