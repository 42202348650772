import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { ItemTypes } from './dragDropConstants';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import { FormattedMessage, useIntl } from 'react-intl';
import { LIBRARY_MANAGER_STATE } from '../../constants';
import { updateStorageExpandedCollection } from '../../common/utils';
import BoardSet from '../../MyComponents/icons/BoardSet';
import CreateDirectoryOutlinedIcon from '../../MyComponents/icons/CreateDirectoryOutlinedIcon';
import OrangeFolderIcon from '../../MyComponents/icons/OrangeFolderIcon';
import DeleteIcon from '../../MyComponents/icons/DeleteIcon';
import SelectedOrangeFolderIcon from '../../MyComponents/icons/SelectedOrangeFolderIcon';
import SingleBoardIconRedesign from '../../MyComponents/icons/SingleBoardIconRedesign';
import { InputBase } from '@material-ui/core';
import CreateNewEmptyFolder from '../../MyComponents/icons/CreateNewEmptyFolder';
import ConfirmIcon from '../../MyComponents/icons/ConfirmIcon';
import DeclineIcon from '../../MyComponents/icons/DeclineIcon';
import Hidden from '@material-ui/core/Hidden';
import MobileCollectionEditor from './CollectionEditor/MobileCollectionEditor';
import Avatar from '@material-ui/core/Avatar';
import SharedFolderIcon from '../../MyComponents/icons/SharedFolderIcon';
import SelectedSharedFolderIcon from '../../MyComponents/icons/SelectedSharedFolderIcon';
import PersonShareIcon from '../../MyComponents/icons/PersonShareIcon';

const StyledTreeItemForLabel = withStyles({
    label: {
        paddingLeft: '0rem',
    },
})(TreeItem);

function StyledTreeItem({
    nodeData,
    addToCollection,
    children,
    move,
    selected,
    tags,
    state,
    deleteBoard,
    openConfirmFolderDeletion,
    editBoard,
    updateCollection,
    stopEditing,
    editing,
    create,
    attachTag,
    createAndAttachTag,
    removeTag,
    handleOpen,
    loadCollections,
}) {
    const [name, setName] = useState(nodeData.name);
    const inputRef = useRef(null);
    const intl = useIntl();

    useEffect(() => {
        setName(nodeData.name);
    }, [nodeData.name]);

    const handleNameChange = (event) => {
        setName(event.target.value);
    };
    useEffect(() => {
        if (editing && selected === nodeData.id && inputRef.current) {
            inputRef.current.children[0].focus();
        }
    }, [selected, editing]);

    let nodeType = ItemTypes.BOARD;
    if (nodeData.id === -1) {
        nodeType = ItemTypes.ROOT;
    }
    if (nodeData.isCollection) {
        if (nodeData.isBoardSet) {
            nodeType = ItemTypes.BOARD_SET;
        } else {
            nodeType = ItemTypes.COLLECTION;
        }
    }
    if (nodeData.isUser) {
        nodeType = ItemTypes.USER;
    }
    const [{ isDragging }, drag] = useDrag(() => ({
        type: nodeType,
        item: nodeData,
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }));

    const [{ isOverCurrent }, drop] = useDrop(
        () => ({
            accept: [ItemTypes.COLLECTION, ItemTypes.BOARD, ItemTypes.BOARD_SET],
            drop: (item) => {
                if (!item.isCollection || (item.isCollection && !item.fullChildSet.has(nodeData.id))) {
                    move(item, nodeData);
                } else {
                    console.log('failed check', item);
                }
                // move(item, nodeData);
            },
            collect: (monitor) => ({
                isOver: !!monitor.isOver(),
                isOverCurrent: monitor.isOver({ shallow: true }),
            }),
        }),
        []
    );

    const classes = useTreeItemStyles();

    if (
        (state === LIBRARY_MANAGER_STATE.SAVE_SET && !nodeData.isCollection) ||
        (state === LIBRARY_MANAGER_STATE.SAVE_SET && nodeData.isCollection && nodeData.isBoardSet)
    ) {
        return null;
    }

    if (state === LIBRARY_MANAGER_STATE.SAVE && nodeData.isCollection && nodeData.isBoardSet) {
        return null;
    }

    let icon;

    if (nodeData.isCollection) {
        if (nodeData.isBoardSet) {
            icon = <BoardSet color="primary" className={classes.labelIcon} />;
        } else {
            if (selected === nodeData.id)
                if (editing) icon = <CreateNewEmptyFolder className={classes.labelIcon} />;
                else icon = <SelectedOrangeFolderIcon className={classes.labelIcon} />;
            else icon = <OrangeFolderIcon className={classes.labelIcon} />;
        }
    } else {
        icon = <SingleBoardIconRedesign className={classes.labelIcon} />;
    }

    if (nodeData.count > 0) {
        if (selected === nodeData.id) {
            icon = <SelectedSharedFolderIcon className={classes.labelIcon} />;
        } else {
            icon = <SharedFolderIcon className={classes.labelIcon} />;
        }
    }

    if (nodeData.isUser) {
        icon = (
            <Avatar
                src={nodeData.meta.avatar}
                size="small"
                style={{ width: '1.5rem', height: '1.5rem', marginRight: '0.5rem' }}
            />
        );
    }

    if (!nodeData.shouldShowOnFilter) {
        return null;
    }

    let setTags = [];
    if (tags && nodeData.tags) {
        setTags = tags.filter((tag) => nodeData.tags.includes(tag.id));
    }

    let selectedButtonCollectionState = null;
    let selectedButtonBoardState = null;
    let selectedButtonMobileCollectionState = null;
    let selectedButtonMobileBoardState = null;
    let editingButtonBoardState = null;
    let unSelectedButtonCollectionState = null;
    let unSelectedButtonBoardState = null;
    let editingButtonCollectionState = (
        <>
            <IconButton
                onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    updateCollection(name);
                }}
                size="small"
                className={clsx(classes.labelIcon, classes.addBtn, classes.marginConfirm)}
            >
                <ConfirmIcon />
            </IconButton>
            <IconButton onClick={stopEditing} size="small" className={classes.marginDecline}>
                <DeclineIcon />
            </IconButton>
        </>
    );

    if (nodeData.isCollection && state !== LIBRARY_MANAGER_STATE.LOAD && !nodeData.isBoardSet) {
        if (!nodeData.isUserCollection && !nodeData.isUser) {
            selectedButtonCollectionState = (
                <>
                    <IconButton
                        onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            addToCollection(nodeData);
                        }}
                        size="small"
                        className={clsx(classes.labelIcon, classes.addBtn)}
                        style={{ marginRight: '2rem' }}
                    >
                        <CreateDirectoryOutlinedIcon />
                    </IconButton>
                    {state !== LIBRARY_MANAGER_STATE.SAVE && state !== LIBRARY_MANAGER_STATE.SAVE_SET ? (
                        <IconButton size="small" onClick={handleOpen}>
                            <PersonShareIcon />
                        </IconButton>
                    ) : null}
                    <IconButton
                        size="small"
                        onClick={openConfirmFolderDeletion}
                        style={{
                            marginLeft:
                                state !== LIBRARY_MANAGER_STATE.SAVE && state !== LIBRARY_MANAGER_STATE.SAVE_SET
                                    ? '2rem'
                                    : '0rem',
                            marginRight: '1 rem',
                        }}
                    >
                        <DeleteIcon />
                    </IconButton>
                </>
            );
        } else {
            selectedButtonCollectionState = <></>;
        }
        selectedButtonMobileCollectionState = (
            <MobileCollectionEditor
                deleteBoard={deleteBoard}
                editBoard={editBoard}
                addToCollection={addToCollection}
                selectedNode={nodeData}
                create={create}
                state={state}
                tags={tags}
                attachTag={attachTag}
                createAndAttachTag={createAndAttachTag}
                removeTag={removeTag}
                loadCollections={loadCollections}
            />
        );
        unSelectedButtonCollectionState = <></>;
    } else if ((!nodeData.isCollection || nodeData.isBoardSet) && state !== LIBRARY_MANAGER_STATE.LOAD) {
        selectedButtonMobileCollectionState = (
            <MobileCollectionEditor
                deleteBoard={deleteBoard}
                editBoard={editBoard}
                addToCollection={addToCollection}
                selectedNode={nodeData}
                create={create}
                state={state}
                tags={tags}
                attachTag={attachTag}
                createAndAttachTag={createAndAttachTag}
                removeTag={removeTag}
                loadCollections={loadCollections}
            />
        );
    }

    let bg = 'transparent';
    if (nodeData.canUse && !nodeData.canUse.value) {
        bg = 'rgba(210, 104, 40, 0.5)';
    }
    if (nodeData.isBoardSet) {
        children.forEach((e) => {
            if (e.props?.nodeData?.canUse && !e.props.nodeData.canUse.value) {
                bg = 'rgba(210, 104, 40, 0.5)';
            }
        });
    }

    return (
        <StyledTreeItemForLabel
            label={
                <>
                    {editing && selected === nodeData.id ? (
                        <div
                            ref={!nodeData.isUserCollection && !nodeData.isUser && !nodeData.isUserBoard ? drop : null}
                            style={{ background: '#FFFFFF' }}
                        >
                            <div
                                ref={
                                    !nodeData.isUserCollection && !nodeData.isUser && !nodeData.isUserBoard
                                        ? drag
                                        : null
                                }
                                style={{
                                    opacity: isDragging ? 0.5 : 1,
                                    cursor:
                                        !nodeData.isUserCollection && !nodeData.isUser && !nodeData.isUserBoard
                                            ? 'move'
                                            : 'pointer',
                                }}
                            >
                                <div className={classes.labelRootSelectedForEditing}>
                                    {icon}
                                    <div className={classes.labelRootText}>
                                        <InputBase
                                            value={name}
                                            onChange={handleNameChange}
                                            placeholder={intl.formatMessage({ id: 'redesign.collections.folderName' })}
                                            ref={inputRef}
                                        />
                                    </div>

                                    {selected === nodeData.id ? (
                                        <>
                                            {editingButtonCollectionState}
                                            {editingButtonBoardState}
                                        </>
                                    ) : (
                                        <>
                                            {unSelectedButtonBoardState}
                                            {unSelectedButtonCollectionState}
                                        </>
                                    )}
                                    {/*<Typography variant="caption" color="inherit">*/}
                                    {/*    {nodeData.count}*/}
                                    {/*</Typography>*/}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            <Hidden only={['xs', 'sm']}>
                                <div
                                    ref={
                                        !nodeData.isUserCollection && !nodeData.isUser && !nodeData.isUserBoard
                                            ? drop
                                            : null
                                    }
                                    style={{ background: '#FFFFFF' }}
                                >
                                    <div
                                        ref={
                                            !nodeData.isUserCollection && !nodeData.isUser && !nodeData.isUserBoard
                                                ? drag
                                                : null
                                        }
                                        style={{
                                            opacity: isDragging ? 0.5 : 1,
                                            cursor:
                                                !nodeData.isUserCollection && !nodeData.isUser && !nodeData.isUserBoard
                                                    ? 'move'
                                                    : 'pointer',
                                        }}
                                    >
                                        <div
                                            className={
                                                selected === nodeData.id ? classes.labelRootSelected : classes.labelRoot
                                            }
                                        >
                                            {icon}
                                            <div className={classes.labelRootText}>
                                                <Typography
                                                    variant="body2"
                                                    title={nodeData.name}
                                                    className={classes.labelText}
                                                >
                                                    {nodeData.name}
                                                    {nodeData.isBoardSet ? (
                                                        <FormattedMessage
                                                            id="collections.boardCount"
                                                            values={{ count: children.length }}
                                                        />
                                                    ) : null}
                                                </Typography>
                                                {setTags && setTags.length ? (
                                                    <div className={classes.tagsStyle}>
                                                        {setTags.map(
                                                            (tag) => (
                                                                <Chip
                                                                    variant="outlined"
                                                                    key={`attached-tag-${tag.id}`}
                                                                    label={tag.name}
                                                                />
                                                            )
                                                            //   )
                                                        )}
                                                    </div>
                                                ) : null}
                                            </div>

                                            {selected === nodeData.id ? (
                                                <>
                                                    {selectedButtonCollectionState}
                                                    {selectedButtonBoardState}
                                                </>
                                            ) : (
                                                <>
                                                    {unSelectedButtonBoardState}
                                                    {unSelectedButtonCollectionState}
                                                </>
                                            )}
                                            {/*<Typography variant="caption" color="inherit">*/}
                                            {/*    {nodeData.count}*/}
                                            {/*</Typography>*/}
                                        </div>
                                    </div>
                                </div>
                            </Hidden>
                            <Hidden only={['md', 'lg', 'xl']}>
                                <div
                                    ref={
                                        !nodeData.isUserCollection && !nodeData.isUser && !nodeData.isUserBoard
                                            ? drop
                                            : null
                                    }
                                    style={{ background: '#FFFFFF' }}
                                >
                                    <div
                                        ref={
                                            !nodeData.isUserCollection && !nodeData.isUser && !nodeData.isUserBoard
                                                ? drag
                                                : null
                                        }
                                        style={{
                                            opacity: isDragging ? 0.5 : 1,
                                            cursor:
                                                !nodeData.isUserCollection && !nodeData.isUser && !nodeData.isUserBoard
                                                    ? 'move'
                                                    : 'pointer',
                                        }}
                                    >
                                        <div
                                            className={
                                                selected === nodeData.id ? classes.labelRootSelected : classes.labelRoot
                                            }
                                        >
                                            {icon}
                                            <div className={classes.labelRootText}>
                                                <Typography
                                                    variant="body2"
                                                    title={nodeData.name}
                                                    className={classes.labelText}
                                                >
                                                    {nodeData.name}
                                                    {nodeData.isBoardSet ? (
                                                        <FormattedMessage
                                                            id="collections.boardCount"
                                                            values={{ count: children.length }}
                                                        />
                                                    ) : null}
                                                </Typography>
                                                {setTags && setTags.length ? (
                                                    <div className={classes.tagsStyle}>
                                                        {setTags.map((tag) => (
                                                            <Chip
                                                                variant="outlined"
                                                                key={`attached-tag-${tag.id}`}
                                                                label={tag.name}
                                                            />
                                                        ))}
                                                    </div>
                                                ) : null}
                                            </div>

                                            {selected === nodeData.id ? (
                                                <>
                                                    {selectedButtonMobileCollectionState}
                                                    {selectedButtonMobileBoardState}
                                                </>
                                            ) : (
                                                <>
                                                    {unSelectedButtonBoardState}
                                                    {unSelectedButtonCollectionState}
                                                </>
                                            )}

                                            {/*<Typography variant="caption" color="inherit">*/}
                                            {/*    {nodeData.count}*/}
                                            {/*</Typography>*/}
                                        </div>
                                    </div>
                                </div>
                            </Hidden>
                        </>
                    )}
                </>
            }
            style={{
                '--tree-view-color': '#1a73e8',
                '--tree-view-bg-color': '#fcefe3',
            }}
            nodeId={nodeData.id}
            children={nodeData.isBoardSet ? [] : children}
            onKeyDown={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
        />
    );
}

const useTreeItemStyles = makeStyles((theme) => ({
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5, 0),
    },
    labelRootSelected: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1, 1),
        backgroundColor: '#FDF9F6',
        borderRadius: '1rem',
        width: '100%',
    },
    labelRootText: {
        flexGrow: '1',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '100%',
        display: 'flex',
    },
    labelRootSelectedForEditing: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1, 1),
        backgroundColor: '#FFFFFF',
        borderRadius: '1rem',
        border: '0.063rem solid #FBF0E9',
        width: '100%',
    },
    labelIcon: {
        marginRight: theme.spacing(1),
    },
    labelText: {
        fontWeight: 'inherit',
        marginRight: '1rem',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    tagsStyle: {
        maxWidth: '200px',
        overflow: 'auto',
        flexShrink: 0,
        marginLeft: 'auto',
    },
    selected: {
        backgroundColor: 'blue',
    },
    nonSelectedIcon: {
        backgroundColor: '#FFFFFF',
        boxShadow: 'none',
        marginRight: '8px',
        '& svg': {
            fontSize: '1.2em',
        },
    },
    addBtn: {
        flexShrink: 0,
    },
    createDirectoryButton: {
        fontWeight: 500,
        fontSize: '1.125rem',
        textAlign: 'start',
        textTransform: 'none',
        letterSpacing: '-0.01rem',
    },
    marginConfirm: {
        [theme.breakpoints.down('md')]: {
            marginLeft: '2rem',
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: '0.5rem',
        },
    },
    marginDecline: {
        [theme.breakpoints.down('md')]: {
            marginLeft: '1rem',
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: '0.3rem',
        },
    },
}));

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        width: '100%',
        minHeight: '35vh',
    },
});

const CollectionTree = ({
    collections,
    addToCollection,
    updateCollection,
    addToCollectionNotExpanded,
    move,
    expandedList,
    onNodeSelect,
    tags,
    attachTag,
    createAndAttachTag,
    removeTag,
    state,
    deleteBoard,
    openConfirmFolderDeletion,
    editBoard,
    editing,
    stopEditing,
    selectedNode,
    onUpdate,
    create,
    handleOpen,
    loadCollections,
}) => {
    const classes = useStyles();

    const [expanded, setExpanded] = React.useState([]);
    const [selected, setSelected] = React.useState([]);

    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds);
        updateStorageExpandedCollection(nodeIds);
    };

    const handleSelect = (event, nodeIds) => {
        if (!nodeIds) return;
        setSelected(nodeIds);
        onNodeSelect(nodeIds);
    };
    const handleAdd = (nodeData) => {
        addToCollection(nodeData, expanded);
    };
    const handleMove = (currentNode, newNode) => {
        move(currentNode, newNode, expanded);
    };

    useEffect(() => {
        if (!selectedNode) return;
        if (selectedNode?.id === selected) return;
        setSelected(selectedNode.id);
    }, [selectedNode]);

    useEffect(() => {
        setExpanded(expandedList ? expandedList : []);
    }, [expandedList]);

    const renderTree = (node) => (
        <StyledTreeItem
            key={node.id}
            nodeData={node}
            addToCollection={handleAdd}
            updateCollection={updateCollection}
            addToCollectionNotExpanded={addToCollectionNotExpanded}
            openConfirmFolderDeletion={openConfirmFolderDeletion}
            move={handleMove}
            selected={selected}
            tags={tags}
            attachTag={attachTag}
            createAndAttachTag={createAndAttachTag}
            removeTag={removeTag}
            state={state}
            editing={editing}
            stopEditing={stopEditing}
            deleteBoard={deleteBoard}
            editBoard={editBoard}
            onUpdate={onUpdate}
            create={create}
            handleOpen={handleOpen}
            loadCollections={loadCollections}
        >
            {Array.isArray(node.children) ? node.children.map((node) => renderTree(node)) : null}
        </StyledTreeItem>
    );

    return (
        <DndProvider backend={HTML5Backend}>
            <TreeView
                className={classes.root}
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpanded={expanded}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expanded}
                selected={selected}
                onNodeToggle={handleToggle}
                onNodeSelect={handleSelect}
            >
                {collections ? collections.map((el) => renderTree(el)) : null}
            </TreeView>
        </DndProvider>
    );
};
export default CollectionTree;
