import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import SectionChapter from '../../Tutorial/SectionChapter';
import SectionHeader from '../../Tutorial/SectionHeader';
import { FindMoreButton } from '../../Home/HomeFeatures/FindMoreButton';
import { tutorialLinks } from '../../../common/urlLinks';

const MobileFeature = ({ refs }) => {
    const mobile = [
        {
            children: (
                <>
                    <ul>
                        <li>
                            <Typography variant="h6">
                                <FormattedMessage id="page.home.feature.mobile.description.1" />
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="h6">
                                <FormattedMessage id="page.home.feature.mobile.description.2" />
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="h6">
                                <FormattedMessage id="page.home.feature.mobile.description.3" />
                            </Typography>
                        </li>
                    </ul>
                    <a href={tutorialLinks.phoneOptimization}>
                        <FindMoreButton />
                    </a>
                </>
            ),
        },
    ];
    return (
        <>
            <SectionHeader
                refs={refs}
                title={'page.home.feature.mobile.tag'}
                id={'section-mobile'}
                img={`/static/asset/img/features/functionare_mobil.png`}
            />
            <SectionChapter
                refs={refs}
                id={'section-mobile-section'}
                title={'page.home.feature.mobile.description'}
                explanations={mobile}
            />
        </>
    );
};

export default MobileFeature;
