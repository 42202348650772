import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function RomaniaIcon(props) {
    return (
        <SvgIcon {...props} style={{ verticalAlign: 'bottom' }}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                viewBox="0 0 48 48"
                xmlSpace="preserve"
            >
                <path fill="#FFCA28" d="M16 9h16v30H16z" />
                <path fill="#D50000" d="M32 9h14v30H32z" />
                <path fill="#1A237E" d="M2 9h14v30H2z" />
            </svg>
        </SvgIcon>
    );
}
