import lessonList from '../pages/LessonPage/lessonList';
import store from '../redux/store';
import { showMultiBoardLessonState, updateMultiBoards } from '../redux/actions';
import storageApi from '../Api/storageApi';
import { COLLECTION_PATH_STORAGE_KEY, STICKY_ELEMENTS_TYPE } from '../constants';
import { GA_EVENT_TREE } from '../gaConstants';
import { find } from 'lodash';
import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import React from 'react';

export const loadListingFromStorageInStore = function(email, lessonId) {
    let userData = lessonList.getLessonForEmail(email, lessonId);
    if (!userData) {
        store.dispatch(updateMultiBoards(null));
    }
    let listingArr = [];
    Object.keys(userData).forEach((key) => {
        if (!lessonList.isSpecialAttribute(key)) {
            listingArr.push({ id: key, data: userData[key] });
        }
    });
    if (listingArr.length > 0) {
        store.dispatch(updateMultiBoards(listingArr));
    } else {
        store.dispatch(updateMultiBoards(null));
    }
};

export const removeUserMultiBoardEntry = function(email, lessonId, multiBoardId, targetEmail) {
    lessonList.removeMultiBoardLessonForUser(email, lessonId, multiBoardId, targetEmail);

    let multiBoardElements = lessonList.getMultiBoardElements(email, lessonId, multiBoardId);

    if (multiBoardElements && multiBoardElements.users && multiBoardElements.users[targetEmail]) {
        if (multiBoardElements.users[targetEmail].pages) {
            multiBoardElements.users[targetEmail].pages.forEach((page) => {
                if (page.stickyElements) {
                    page.stickyElements.forEach((el) => {
                        if (el.stickyType === STICKY_ELEMENTS_TYPE.IMAGE) {
                            storageApi
                                .deleteMultiBoardStickyImage(lessonId, multiBoardId, el.id, targetEmail)
                                .catch((err) => {
                                    console.error('unable to delete');
                                });
                        }
                    });
                }
                lessonList.removeItem(page.id);
            });
        }
        delete multiBoardElements.users[targetEmail];
        lessonList.updateMultiBoardElements(email, lessonId, multiBoardId, multiBoardElements);
    }

    let appState = store.getState().appState;
    if (appState.multiBoardLessonState && appState.multiBoardLessonState.id === multiBoardId) {
        let data = lessonList.getMultiBoardLesson(email, lessonId, multiBoardId);
        // console.log(
        //     '==== should be reloading the current loaded stuff after delete',
        //     data,
        //     email,
        //     lessonId,
        //     multiBoardId,
        //     targetEmail
        // );
        store.dispatch(showMultiBoardLessonState({ id: multiBoardId, ...data }));
    }
    loadListingFromStorageInStore(email, lessonId);
};

export const removeUserMultiBoard = function(email, lessonId, multiBoardId) {
    // console.log('trying to remove', email, lessonId, multiBoardId);
    window.logAppActivity(GA_EVENT_TREE.individualSessionBox.actions.removeEntireIndividualSession);
    lessonList.removeMultiBoard(email, lessonId, multiBoardId);
    store.dispatch(showMultiBoardLessonState(null));
    loadListingFromStorageInStore(email, lessonId);
};

export const promiseTimeout = function(ms, promise) {
    let timeout = new Promise((resolve, reject) => {
        let id = setTimeout(() => {
            clearTimeout(id);
            reject('TIMEOUT');
        }, ms);
    });

    // Returns a race between our timeout and the passed in promise
    return Promise.race([promise, timeout]);
};

export const generateFormData = function(urlEncoded, imageId, data) {
    let formData = new FormData();
    if (urlEncoded) {
        formData.append('file', dataURLtoFile(urlEncoded, imageId));
    }
    if (data) {
        Object.keys(data).forEach((key) => {
            formData.append(key, data[key]);
        });
    }
    return formData;
};

export const generateFormDataForMultiBoard = function(urlEncoded, imageId, stickyElements, meta, originalId) {
    let formData = new FormData();
    formData.append('file', dataURLtoFile(urlEncoded, imageId));
    formData.append('stickyElements', JSON.stringify(stickyElements));
    formData.append('meta', JSON.stringify(meta));
    formData.append('originalId', originalId);
    return formData;
};

export const generateFormDataCollectionBoard = function(
    urlEncoded,
    imageId,
    meta,
    boardId,
    classId,
    name,
    description,
    collectionId,
) {
    let formData = new FormData();
    formData.append('file', dataURLtoFile(urlEncoded, imageId));
    formData.append('meta', JSON.stringify(meta));
    formData.append('boardId', boardId);
    formData.append('classId', classId);
    if (description) {
        formData.append('description', description);
    }
    if (name) {
        formData.append('name', name);
    }

    if (collectionId) {
        formData.append('collectionId', collectionId);
    }
    return formData;
};
export const generateFormDataForUpdateCollectionBoard = function(
    urlEncoded,
    imageId,
    meta,
    boardId,
    classId,
    name,
    description,
    collectionId,
) {
    let formData = new FormData();
    formData.append('file', dataURLtoFile(urlEncoded, imageId));
    formData.append('meta', JSON.stringify(meta));
    formData.append('boardId', boardId);
    formData.append('classId', classId);
    if (description) {
        formData.append('description', description);
    }
    if (name) {
        formData.append('name', name);
    }

    if (collectionId) {
        formData.append('collectionId', collectionId);
    }
    return formData;
};

export const dataURLtoFile = function(urlEncoded, filename) {
    let arr = urlEncoded.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
};

function windowCachePrePopulateCheck(email, classId, pageId, imageId) {
    let cache = window.vBoardWindowCache;
    if (!cache) {
        cache = {};
        window.vBoardWindowCache = cache;
    }
    if (!cache[email]) {
        cache[email] = {};
    }

    if (!cache[email][classId]) {
        cache[email][classId] = {};
    }

    if (!cache[email][classId][pageId]) {
        cache[email][classId][pageId] = {};
    }

    if (!cache[email][classId][pageId][imageId]) {
        cache[email][classId][pageId][imageId] = {};
    }
}

export function getUrlFromWindowCache(email, classId, pageId, imageId) {
    windowCachePrePopulateCheck(email, classId, pageId, imageId);
    let cache = window.vBoardWindowCache;
    return cache[email][classId][pageId][imageId].url;
}

export function getUploadUrlFromWindowCache(email, classId, pageId, imageId) {
    windowCachePrePopulateCheck(email, classId, pageId, imageId);
    let cache = window.vBoardWindowCache;
    return cache[email][classId][pageId][imageId].upload;
}

export function addToWindowCache(email, classId, pageId, imageId, url, upload, skipOverwrite = false) {
    windowCachePrePopulateCheck(email, classId, pageId, imageId);
    let cache = window.vBoardWindowCache;
    if (skipOverwrite && cache[email][classId][pageId][imageId]) {
        return;
    }
    cache[email][classId][pageId][imageId] = { url, upload };
}

export function addImageToWindowCache(url, img) {
    if (!window.vBoardWindowImageCache) {
        window.vBoardWindowImageCache = {};
    }
    let cache = window.vBoardWindowImageCache;
    cache[url] = img;
}

export function addLatexToWindowCache(id, svg) {
    if (!window.vBoardWindowLatexCache) {
        window.vBoardWindowLatexCache = {};
    }
    let cache = window.vBoardWindowLatexCache;
    cache[id] = svg;
}

export function removeImageFromWindowCache(url) {
    if (!window.vBoardWindowImageCache) {
        window.vBoardWindowImageCache = {};
    }
    let cache = window.vBoardWindowImageCache;
    if (cache[url]) {
        delete cache[url];
    }
}

export function removeLatexFromWindowCache(id) {
    if (!window.vBoardWindowLatexCache) {
        window.vBoardWindowLatexCache = {};
    }
    let cache = window.vBoardWindowLatexCache;
    if (cache[id]) {
        delete cache[id];
    }
}

export function getImageFromWindowCache(url) {
    if (!window.vBoardWindowImageCache) {
        window.vBoardWindowImageCache = {};
    }
    let cache = window.vBoardWindowImageCache;
    return cache[url];
}

export function getLatexFromWindowCache(id) {
    if (!window.vBoardWindowLatexCache) {
        window.vBoardWindowLatexCache = {};
    }
    let cache = window.vBoardWindowLatexCache;
    return cache[id];
}

export function removeFromWindowCache(email, classId, pageId, imageId) {
    windowCachePrePopulateCheck(email, classId, pageId, imageId);
    let cache = window.vBoardWindowCache;
    cache[email][classId][pageId][imageId] = { url: null, upload: null };
}

export function getStickyImage(email, lessonId, boardId, id) {
    return getUrlFromWindowCache(email, lessonId, boardId, id);
}

export async function getLessonSignedURLs(email, lessonId) {
    let payload = { pages: [] };
    let lessonData = lessonList.getLessonForEmail(email, lessonId);
    lessonData.pages.forEach((pageId) => {
        let myPage = { id: pageId };
        myPage.stickyElements = lessonList
            .getStickyLessonForEmail(email, lessonId, pageId)
            .stickyElements.filter((el) => el.stickyType === STICKY_ELEMENTS_TYPE.IMAGE)
            .map((el) => {
                return { id: el.id };
            });
        payload.pages.push(myPage);
    });
    let urls = await storageApi.signSingleBoardSession(lessonId, payload);
    urls.data.pages.forEach((page) => {
        page.stickyElements.forEach((el) => {
            addToWindowCache(email, lessonId, page.id, el.id, el.url);
        });
    });
}

export async function buildMultiBoardLessonWithURLs(email, classId, multiBoardId) {
    let myData = lessonList.getMultiBoardElements(email, classId, multiBoardId);
    let myLessonData = lessonList.getLessonForEmail(email, classId);
    if (!myLessonData[multiBoardId]) {
        console.error('horrible error trying to recover this multiboard session', multiBoardId);
        return null;
    }
    let originalMultiBoardState = {
        boardId: multiBoardId,
        originalDownload: null,
        originalSticky: myData.originalStickyElements,
        originalLineage: myLessonData[multiBoardId].gridType,
    };

    let currentBoards = {};

    // let tmpUsr = [];
    // Object.keys(myLessonData[multiBoardId].users).forEach((key) => {
    //     tmpUsr.push({ email: key, name: myLessonData[multiBoardId].users[key].name, online: false });
    // });

    let multiBoardSignedAssets;
    if (myData.originalStickyElements) {
        multiBoardSignedAssets = {
            id: multiBoardId,
            originalStickyElements: myData.originalStickyElements
                .filter((el) => el.stickyType === STICKY_ELEMENTS_TYPE.IMAGE)
                .map((el) => {
                    return { id: el.id };
                }),
            users: {},
        };
    } else {
        multiBoardSignedAssets = {
            id: multiBoardId,
            originalStickyElements: [],
            users: {},
        };
    }
    Object.keys(myData.users).forEach((key) => {
        let idArray = [];
        if (myData.users[key].pages) {
            myData.users[key].pages.forEach((page) => {
                if (page.stickyElements) {
                    idArray.push(
                        ...page.stickyElements
                            .filter((el) => el.stickyType === STICKY_ELEMENTS_TYPE.IMAGE)
                            .map((el) => {
                                return { id: el.id };
                            }),
                    );
                }
            });
        }
        multiBoardSignedAssets.users[key] = {
            stickyElements: idArray,
        };
    });
    let signedRes = await storageApi.signMultiBoardSession(classId, multiBoardId, multiBoardSignedAssets);
    let newSignedAssets = signedRes.data;
    if (newSignedAssets.id && newSignedAssets.url) {
        addToWindowCache(email, classId, multiBoardId, newSignedAssets.id, newSignedAssets.url);
        originalMultiBoardState.originalDownload = newSignedAssets.url;
    }
    if (newSignedAssets.originalStickyElements) {
        for (let i = 0; i < newSignedAssets.originalStickyElements.length; i++) {
            originalMultiBoardState.originalSticky.forEach((el, index) => {
                if (el.id === newSignedAssets.originalStickyElements[i].id) {
                    originalMultiBoardState.originalSticky[index].url = newSignedAssets.originalStickyElements[i].url;
                    addToWindowCache(
                        email,
                        classId,
                        multiBoardId,
                        el.id,
                        newSignedAssets.originalStickyElements[i].url,
                    );
                }
            });
        }
    }

    if (newSignedAssets.users) {
        let userKeys = Object.keys(newSignedAssets.users);
        let multiBoardLessonDataUsers = lessonList.getMultiBoardLesson(email, classId, multiBoardId).users;
        userKeys.forEach((key) => {
            if (multiBoardLessonDataUsers[key]) {
                currentBoards[key] = multiBoardLessonDataUsers[key];
                currentBoards[key].pages = lessonList.getMultiBoardElementsForUser(email, classId, multiBoardId, key);
                currentBoards[key].pages.forEach((page) => {
                    if (page.stickyElements) {
                        page.stickyElements.forEach((el) => {
                            newSignedAssets.users[key].stickyElements.forEach((userSticky) => {
                                if (el.id === userSticky.id) {
                                    el.url = userSticky.url;
                                    addToWindowCache(email, classId, multiBoardId, el.id, userSticky.url);
                                }
                            });
                        });
                    }
                });
            }
        });
    }
    originalMultiBoardState.users = currentBoards;

    return originalMultiBoardState;
}

export const processCollections = (collections, boards = [], foreignUsers = []) => {
    collections.forEach((el) => {
        el.isCollection = true;
        el.children = [];
        el.processed = false;
        el.shouldShowOnFilter = true;
    });
    boards.forEach((el) => {
        el.isCollection = false;
        el.children = [];
        el.processed = false;
        el.shouldShowOnFilter = true;
    });
    boards.forEach((board) => {
        if (board.isUserBoard) {
            if (board.parentId !== null) {
                let el = collections.find((collection) => collection.id === board.parentId);
                if (el) {
                    el.children.push(board);
                }
            }
        } else {
            if (board.collectionId !== null) {
                let el = collections.find((collection) => collection.id === board.collectionId);
                if (el) {
                    el.children.push(board);
                }
            }
        }
    });

    let brokenItems = [];

    let topLayer = collections
        .filter((el) => !el.parentId)
        .map((el) => {
            el.processed = true;
            return el;
        });
    foreignUsers.map((e) => {
        let name = e.first_name + ' ' + e.last_name;
        if ((e.first_name && e.first_name.trim !== '') || (e.last_name && e.last_name.trim !== '')) {
            name = e.first_name + ' ' + e.last_name;
        }
        topLayer.push({
            id: e.userId,
            name: name,
            parentId: null,
            isBoardSet: false,
            isPublic: false,
            isShared: false,
            isCollection: false,
            isUser: true,
            meta: e,
            children: [],
            processed: true,
            shouldShowOnFilter: false,
            fullChildSet: {},
            pathToElement: [e.userId],
        });
    });
    topLayer.forEach((el) => {
        recursiveProcessor(brokenItems, collections, el);
    });
    boards.forEach((board) => {
        if (board.collectionId === null) {
            topLayer.push(board);
        }
    });

    let unProcessed = collections.filter((el) => !el.processed);

    return { topLayer, unProcessed };
};

export const findIdInProcessedCollection = (id, processedCollection) => {
    if (!id) {
        return null;
    }
    for (let i = 0; i < processedCollection.length; i++) {
        if (processedCollection[i].id === id) {
            return processedCollection[i];
        } else {
            let myId = findIdInProcessedCollection(id, processedCollection[i].children);
            if (myId) {
                return myId;
            }
        }
    }
    return null;
};

export const recursiveProcessor = (brokenItems, originalCollections, elementToProcess, pathToElement = []) => {
    let children = originalCollections.filter((el) => elementToProcess.id === el.parentId);
    let arr = children.filter((el) => el.processed);
    if (arr.length > 0) {
        console.log('=== found processed', arr);
    }
    brokenItems.push(...children.filter((el) => el.processed));
    children = children.filter((el) => !el.processed);
    let localFullChildSet = new Set();
    let localPathToElement = [...pathToElement, elementToProcess.id];
    localFullChildSet.add(elementToProcess.id);
    elementToProcess.children = [...children, ...elementToProcess.children];
    children.forEach((el) => {
        el.processed = true;
        let fullChildSet = recursiveProcessor(brokenItems, originalCollections, el, localPathToElement);
        fullChildSet.forEach((el) => {
            localFullChildSet.add(el);
        });
    });
    elementToProcess.fullChildSet = localFullChildSet;
    elementToProcess.pathToElement = localPathToElement;
    elementToProcess.isCollection = true;
    return localFullChildSet;
};

export const applyCollectionFilter = (collection, filter, searchLabelIds) => {
    collection.forEach((element) => {
        applyCollectionFilterOnItem(element, filter, searchLabelIds);
    });
    return [...collection];
};
export const applyCollectionFilterOnItem = (currentElement, filter, searchLabelIds) => {
    let foundByTag = false;
    let foundByName = false;
    let nameFilterOn = false;
    let tagFilterOn = false;
    if (searchLabelIds && searchLabelIds.length > 0) {
        tagFilterOn = true;
    }
    if (filter !== '') {
        nameFilterOn = true;
    }
    if (nameFilterOn && currentElement.name.toLowerCase().includes(filter.toLowerCase())) {
        foundByName = true;
    }

    if (currentElement.tags) {
        if (
            tagFilterOn &&
            find(currentElement.tags, (o) => {
                return searchLabelIds.includes(o);
            })
        ) {
            foundByTag = true;
        }
        // console.log('=== logic', currentElement.name, nameFilterOn, foundByName, tagFilterOn, foundByTag);
    }

    if (nameFilterOn && foundByName && tagFilterOn && foundByTag) {
        currentElement.shouldShowOnFilter = true;
    } else if (!nameFilterOn && tagFilterOn && foundByTag) {
        currentElement.shouldShowOnFilter = true;
    } else if (!tagFilterOn && nameFilterOn && foundByName) {
        currentElement.shouldShowOnFilter = true;
    } else {
        currentElement.shouldShowOnFilter = false;
    }
    currentElement.children.forEach((child) => {
        let found = applyCollectionFilterOnItem(child, filter, searchLabelIds);
        if (found) {
            currentElement.shouldShowOnFilter = true;
        }
    });
    return currentElement.shouldShowOnFilter;
};

export const resetCollectionFilter = (collection) => {
    collection.forEach((element) => {
        resetCollectionFilterOnItem(element);
    });
    return [...collection];
};
export const resetCollectionFilterOnItem = (currentElement) => {
    currentElement.shouldShowOnFilter = true;
    currentElement.children.forEach(resetCollectionFilterOnItem);
};

export const getStorageExpandedCollection = () => {
    let val = window.sessionStorage.getItem(COLLECTION_PATH_STORAGE_KEY);
    if (!val) {
        return [];
    }
    return JSON.parse(val);
};

export const updateStorageExpandedCollection = (path) => {
    if (!path) {
        path = [];
    }
    window.sessionStorage.setItem(COLLECTION_PATH_STORAGE_KEY, JSON.stringify(path));
};

export const isBoardSetUsable = (node) => {
    let isUsable = true;
    if (!node.isBoardSet || !node.children || node.children.length === 0) {
        isUsable = false;
    } else {
        node.children.forEach((n) => {
            if (!n.canUse.value) {
                isUsable = false;
            }
        });
    }
    if (isUsable) return null;
    return (
        <Typography color={'secondary'}>
            <FormattedMessage id='subscription.upsell.library.unavailable' />
        </Typography>
    );
};

export const determineDefaultLanguage = () => {
    let currentLanguage = 'en';
    try {
        let lang = navigator.language;
        if (!lang) {
            lang = currentLanguage;
        }
        lang = lang.toLowerCase();
        if (lang === 'en-us' || lang === 'en' || lang === 'en-uk' || lang === 'en-ca' || lang === 'en-gb') {
            currentLanguage = 'en';
        }
        if (
            lang === 'fr' ||
            lang === 'fr-be' ||
            lang === 'fr-ca' ||
            lang === 'fr-fr' ||
            lang === 'fr-lu' ||
            lang === 'fr-mc' ||
            lang === 'fr-ch'
        ) {
            currentLanguage = 'fr';
        }
        if (lang === 'ro' || lang === 'ro-mo') {
            currentLanguage = 'ro';
        }

        let storageLang = localStorage?.getItem('language');
        if (storageLang) {
            if (storageLang === 'ro') {
                currentLanguage = 'ro';
            }
            if (storageLang === 'fr') {
                currentLanguage = 'fr';
            }
            if (storageLang === 'en') {
                currentLanguage = 'en';
            }
        }

        if (window.location.pathname.startsWith('/ro')) {
            currentLanguage = 'ro';
            localStorage.setItem('language', 'ro');
        }
        if (window.location.pathname.startsWith('/fr')) {
            currentLanguage = 'fr';
            localStorage.setItem('language', 'fr');
        }
        if (window.location.pathname.startsWith('/en')) {
            currentLanguage = 'en';
            localStorage.setItem('language', 'en');
        }
    } catch (ex) {
        console.log('Unable to load language', ex);
    }
    return currentLanguage;
};

export const parseQuery = (queryString) => {
    let query = {};
    let pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (let i = 0; i < pairs.length; i++) {
        let pair = pairs[i].split('=');
        if (decodeURIComponent(pair[0]) && decodeURIComponent(pair[0]) !== '') {
            query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
        }
    }
    return query;
};

export const handleLogout = (complete) => {
    window.logAppActivity(GA_EVENT_TREE.mainPage.menuClick.logout);
    let concat = complete === true ? '?complete=true' : '';
    if (window.location.host === 'localhost:3000') {
        window.location.assign('http://localhost:4000/logout' + concat);
    } else if (window.location.host === '192.168.2.112:3000') {
        window.location.assign('http://192.168.2.112:4000/logout' + concat);
    } else {
        window.location.assign(window.location.protocol + '//' + window.location.host + '/logout' + concat);
    }
};

export const sleep = function(time) {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, time);
    });
};

export const scrollSection = (sectionRef) => {
    if (sectionRef) {
        window.scrollTo({
            behavior: 'smooth',
            top: sectionRef.offsetTop - 35,
        });
    } else {
        window.scrollTo({
            behavior: 'smooth',
            top: 0,
        });
    }
};

export const transitionToTop = () => {
    setTimeout(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }, 200);
};

export const hexToRgb = (hex) => {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
        return r + r + g + g + b + b;
    });

    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
    } : null;
};


export const SUBSCRIPTION_PLANS = {
    FREE: 'FREE',
    TRIAL: 'TRIAL',
    SPECIAL: 'SPECIAL',
    BASIC: 'BASIC',
    PREMIUM: 'PREMIUM',
};

export const SUBSCRIPTION_ORDER = {};
SUBSCRIPTION_ORDER[SUBSCRIPTION_PLANS.FREE] = 0;
SUBSCRIPTION_ORDER[SUBSCRIPTION_PLANS.TRIAL] = 0;
SUBSCRIPTION_ORDER[SUBSCRIPTION_PLANS.BASIC] = 1;
SUBSCRIPTION_ORDER[SUBSCRIPTION_PLANS.PREMIUM] = 2;
