import React, { useState, useCallback, useRef, useEffect } from 'react';
import ReactCrop from 'react-image-crop';
import { makeStyles } from '@material-ui/core/styles';
import 'react-image-crop/dist/ReactCrop.css';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';

// Setting a high pixel ratio avoids blurriness in the canvas crop preview.
const pixelRatio = 8;

// We resize the canvas down when saving on retina devices otherwise the image
// will be double or triple the preview size.
function getResizedCanvas(canvas, newWidth, newHeight) {
    const tmpCanvas = document.createElement('canvas');
    tmpCanvas.width = newWidth;
    tmpCanvas.height = newHeight;

    const ctx = tmpCanvas.getContext('2d');
    ctx.imageSmoothingEnabled = true;
    ctx.imageSmoothingQuality = 'high';
    ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, newWidth, newHeight);

    return tmpCanvas;
}

const useStyles = makeStyles(() => ({
    root: {
        textAlign: 'center',
        '& img': {
            maxWidth: '100%',
            maxHeight: '80vh',
        },
    },
}));

function generateDownload(previewCanvas, crop, handleClose, imgRef) {
    if (!crop || !previewCanvas) {
        handleClose();
        return;
    }

    // console.log('getting resized canvas', crop.width, crop.height);
    const image = imgRef.current;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    let width = crop.width * scaleX;
    let height = crop.height * scaleY;

    // console.log('width/height after scale', width, height);

    const canvas = getResizedCanvas(previewCanvas, width, height);
    // const canvas = getResizedCanvas(previewCanvas, drawUtils.CANVAS_ACTUAL_WIDTH, drawUtils.CANVAS_ACTUAL_HEIGHT);

    if (window.handleCroppedImage) {
        window.handleCroppedImage(canvas.toDataURL(), width, height);
        handleClose();
        // canvas.toBlob((blob) => {
        //     window.handleCroppedImage(blob, crop.width, crop.height);
        //     handleClose();
        // });
    }

    // canvas.toBlob(
    //     (blob) => {
    //         const previewUrl = window.URL.createObjectURL(blob);
    //
    //         const anchor = document.createElement('a');
    //         anchor.download = 'cropPreview.png';
    //         anchor.href = URL.createObjectURL(blob);
    //         anchor.click();
    //
    //         window.URL.revokeObjectURL(previewUrl);
    //     },
    //     'image/png',
    //     1
    // );
}

export default function ImageCropper(props) {
    const classes = useStyles();
    const fileRef = useRef(null);
    const [upImg, setUpImg] = useState();
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [crop, setCrop] = useState({
        unit: '%',
        width: '50',
        height: '50',
    });
    const [completedCrop, setCompletedCrop] = useState(null);

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => setUpImg(reader.result));
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');

        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingEnabled = false;

        let width = crop.width * scaleX;
        let height = crop.height * scaleY;

        if (width === 0) {
            width = 100;
        }
        if (height === 0) {
            height = 100;
        }

        // console.log('before load', crop.x * scaleX, crop.y * scaleY, width, height);

        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = 'high';
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            // crop.width * scaleX,
            // crop.height * scaleY,
            width,
            height,
            0,
            0,
            crop.width,
            crop.height
        );
    }, [completedCrop]);

    useEffect(() => {
        if (props.dialogImgUploadOpen) {
            setTimeout(() => {
                fileRef.current.click();
            }, 100);
            // fileRef.click();
        }
    }, [props.dialogImgUploadOpen]);

    // useCallback(async () => {
    //     if (props.dialogImgUploadOpen) {
    //         if (fileRef.current) {
    //             console.log('not null');
    //         } else {
    //             console.log('null');
    //         }
    //         console.log(fileRef.current);
    //         // fileRef.click();
    //     }
    // }, [props.dialogImgUploadOpen]);

    return (
        <Dialog open={props.dialogImgUploadOpen} onClose={props.handleClose} fullScreen>
            <DialogContent>
                <div className={classes.root}>
                    <div style={{ display: 'none' }}>
                        <input ref={fileRef} type="file" accept="image/*" onChange={onSelectFile} />
                    </div>
                    <ReactCrop
                        src={upImg}
                        onImageLoaded={onLoad}
                        crop={crop}
                        onChange={(c) => setCrop(c)}
                        onComplete={(c) => setCompletedCrop(c)}
                    />
                    <div style={{ display: 'none' }}>
                        <canvas
                            ref={previewCanvasRef}
                            // style={{
                            //     width: completedCrop?.width ?? 0,
                            //     height: completedCrop?.height ?? 0,
                            // }}
                        />
                    </div>
                </div>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button onClick={props.handleClose} size="large" color="primary" variant="outlined">
                    <FormattedMessage id="close" />
                </Button>
                <Button
                    onClick={() => generateDownload(previewCanvasRef.current, completedCrop, props.handleClose, imgRef)}
                    color="primary"
                    size="large"
                    disabled={!completedCrop?.width || !completedCrop?.height}
                    variant="contained"
                    style={{marginLeft: '1rem'}}
                >
                    <FormattedMessage id="ok" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}
