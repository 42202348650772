import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ViewActivityIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 0C2.52917 0 1.33333 1.19583 1.33333 2.66667V14.6667H4V2.66667H20V14.6667H22.6667V2.66667C22.6667 1.19583 21.4708 0 20 0H4ZM9.33333 14.6667V16H1.33333C0.595833 16 0 16.5958 0 17.3333C0 18.0708 0.595833 18.6667 1.33333 18.6667H22.6667C23.4042 18.6667 24 18.0708 24 17.3333C24 16.5958 23.4042 16 22.6667 16H17.3333V14.6667C17.3333 13.9292 16.7375 13.3333 16 13.3333H10.6667C9.92917 13.3333 9.33333 13.9292 9.33333 14.6667Z" fill="#08AAB4"/>
            </svg>
        </SvgIcon>
    );
}
