import React, { useEffect, useState } from 'react';
import { Container, Grid } from '@material-ui/core';
import PinDrop from '@material-ui/icons/PinDrop';

import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import CardType1 from '../../MyComponents/Card/CardType1';
import BusinessCenter from '@material-ui/icons/BusinessCenter';
import { FormattedMessage, injectIntl } from 'react-intl';
import store from '../../redux/store';
import { showError, showSuccess } from '../../redux/actions';
import userApi from '../../Api/userApi';
import { useHistory } from 'react-router-dom';
import { Send } from '@material-ui/icons';
import { GA_EVENT_TREE } from '../../gaConstants';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            marginBottom: theme.spacing(2),
        },
    },
    spacing: {
        margin: theme.spacing(3),
    },
    container: {
        margin: theme.spacing(3),
        borderRadius: '2rem',
    },
}));

const SpecialQuotaPage = (props) => {
    const classes = useStyles();
    let history = useHistory();

    let [name, setName] = useState('');
    let [email, setEmail] = useState('');
    let [phone, setPhone] = useState('');
    let [message, setMessage] = useState('');
    let [error, setError] = useState(false);

    const handleSend = () => {
        if (message.trim() === '' || !message) {
            store.dispatch(showError('NO_FEEDBACK_PRESENT'));
            setError(true);
        } else {
            userApi.sendSpecialQuotaEmail({ name, email, phone, message }).then(() => {
                store.dispatch(showSuccess('FEEDBACK_SENT'));
                history.push('/');
            });
        }
    };

    useEffect(() => {
        window.logAppActivity(GA_EVENT_TREE.mainPage.onboarding.wantOffer);
    }, []);

    return (
        <Container maxWidth='lg'>
            <div className={classes.root}>
                <Paper variant='outlined' className={classes.container}>
                    <Container>
                        <Typography variant='h2' gutterBottom>
                            <FormattedMessage id='specialQuota.sendMessage' />
                        </Typography>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={12} md={9}>
                                <p>
                                    <FormattedMessage id='specialQuota.sendMessageInfo' />
                                    <br />
                                    <br />
                                </p>
                                <form>
                                    <TextField
                                        label={props.intl.formatMessage({ id: 'contactUs.name' })}
                                        fullWidth
                                        variant='outlined'
                                        value={name}
                                        onChange={(event) => {
                                            setName(event.target.value);
                                        }}
                                    />
                                    <TextField
                                        label={props.intl.formatMessage({ id: 'contactUs.email' })}
                                        fullWidth
                                        variant='outlined'
                                        value={email}
                                        onChange={(event) => {
                                            setEmail(event.target.value);
                                        }}
                                    />
                                    <TextField
                                        label={props.intl.formatMessage({ id: 'contactUs.phone' })}
                                        fullWidth
                                        variant='outlined'
                                        value={phone}
                                        onChange={(event) => {
                                            setPhone(event.target.value);
                                        }}
                                    />
                                    <TextField
                                        label={props.intl.formatMessage({ id: 'contactUs.message' })}
                                        fullWidth
                                        multiline
                                        variant='outlined'
                                        rows={4}
                                        value={message}
                                        required
                                        error={error}
                                        onChange={(event) => {
                                            setMessage(event.target.value);
                                        }}
                                    />
                                    <Button
                                        variant='contained'
                                        className='u-mb-full'
                                        color='primary'
                                        startIcon={<Send />}
                                        onClick={handleSend}
                                    >
                                        <FormattedMessage id='contactUs.sendMessageButton' />
                                    </Button>
                                </form>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <Paper variant='outlined' className='u-p-full u-mb-full'>
                                    <CardType1
                                        icon={<PinDrop color='primary' fontSize='large' />}
                                        title={props.intl.formatMessage({ id: 'contactUs.devCenter' })}
                                        content={
                                            <Typography variant='body2'>
                                                Str. Primaverii <br /> Dumbravita, Timis, <br /> Romania
                                            </Typography>
                                        }
                                    />
                                    <CardType1
                                        icon={<BusinessCenter color='primary' fontSize='large' />}
                                        title={props.intl.formatMessage({ id: 'contactUs.legalInformation' })}
                                        content={
                                            <Typography variant='body2'>
                                                VIRTUAL BOARD SRL <br /> Dumbravita, Timis, <br /> Romania
                                            </Typography>
                                        }
                                    />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                </Paper>
            </div>
        </Container>
    );
};

export default injectIntl(SpecialQuotaPage);
