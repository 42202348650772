export const getSummaryActiveItem = (sections) => {
    for (let i = 0; i < sections.length; i++) {
        if (elementInViewport(sections[i].id)) {
            return sections[i];
        }
        if (sections[i].children && sections[i].children.length > 0) {
            let tmpItem = getSummaryActiveItem(sections[i].children);
            if (tmpItem) return tmpItem;
        }
    }
    return null;
};

function elementInViewport(id) {
    let myElement = document.getElementById(id);
    if (!myElement) {
        return false;
    }
    let bounding = myElement.getBoundingClientRect();
    let myElementHeight = myElement.offsetHeight;
    let myElementWidth = myElement.offsetWidth;

    if (
        bounding.top >= -myElementHeight &&
        bounding.left >= -myElementWidth &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth) + myElementWidth &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) + myElementHeight
    ) {
        return true;
    } else {
        return false;
    }
}
