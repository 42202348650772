import React from 'react';

import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import Hidden from '@material-ui/core/Hidden';
import SubscriptionsDesktop from './Desktop/SubscriptionsDesktop';
import SubscriptionsMobile from './SubscriptionsMobile';

const Subscriptions = ({ profile, subscriptions, pricing, language, handleOpen, handleLogin, open }) => {
    return (
        <>
            <Hidden only={['xs', 'sm']}>
                <SubscriptionsDesktop
                    profile={profile}
                    subscriptions={subscriptions}
                    pricing={pricing}
                    language={language}
                    handleOpen={handleOpen}
                    handleLogin={handleLogin}
                    open={open}
                />
            </Hidden>
            <Hidden only={['md', 'lg', 'xl']}>
                <SubscriptionsMobile
                    profile={profile}
                    subscriptions={subscriptions}
                    pricing={pricing}
                    language={language}
                    handleOpen={handleOpen}
                />
            </Hidden>
        </>
    );
};

const mapStateToProps = (state) => {
    const { profile, mainAppState } = state;
    return { profile, subscriptions: mainAppState.subscriptions, pricing: mainAppState.pricing };
};

export default injectIntl(connect(mapStateToProps)(Subscriptions));
