import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function EllipsisIcon(props) {
    return (
        <SvgIcon {...props} style={{ ...props.style, verticalAlign: 'bottom' }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                <path d="M8 14c-4.411 0-8-2.691-8-6s3.589-6 8-6 8 2.691 8 6-3.589 6-8 6zM8 3C4.141 3 1 5.244 1 8s3.141 5 7 5 7-2.243 7-5-3.141-5-7-5z" />
            </svg>
        </SvgIcon>
    );
}
