import React from 'react';
import Fab from '@material-ui/core/Fab';

import { makeStyles } from '@material-ui/core/styles';
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import VideocamIcon from '@material-ui/icons/Videocam';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import VBToolTip from '../../Tooltip/VBToolTip';
import MicNoneIcon from '@material-ui/icons/MicNone';
import audioCallUtils from '../../../pages/LessonPage/audioCallUtilsNoQueue';
import { CALL_STATE } from '../../../constants';
import { Button, ListItemText } from '@material-ui/core';
import { changeCallState, mute, toggleUsersVideo, upgradeSubscriptionDialog } from '../../../redux/actions';
import store from '../../../redux/store';
import { GA_EVENT_TREE } from '../../../gaConstants';
import boardManager from '../../../pages/LessonPage/boardManager';
import StyledMenu from '../StyledMenu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import BottomList from '../../icons/BottomList';
import GridList from '../../icons/GridList';
import BlockOutlinedIcon from '@material-ui/icons/BlockOutlined';
import { CAPABILITIES, hasBooleanPermission } from '../../../shared/Capabilities';
import ChatBox from './ChatBox';

const useStyles = makeStyles((theme) => ({
    margin: {
        marginLeft: theme.spacing(1),
    },
}));
const buttonMargin = { margin: '0.2rem 0.5rem' };
const MicBox = (props) => {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleDisplayTypeClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleDisplayTypeClose = () => {
        setAnchorEl(null);
    };

    const setVideoDisplay = (type) => {
        if (!type) {
            window.logAppActivity(GA_EVENT_TREE.lessonPage.actions.hideParticipants);
            let remoteVideoList = document.getElementById('remoteVideoList');
            remoteVideoList.style.display = 'none';
            store.dispatch(toggleUsersVideo(0));
        } else if (type === 1) {
            window.logAppActivity(GA_EVENT_TREE.lessonPage.actions.showParticipantsBottom);
            let remoteVideoList = document.getElementById('remoteVideoList');
            remoteVideoList.style.display = 'inline-flex';
            store.dispatch(toggleUsersVideo(1));
        } else {
            window.logAppActivity(GA_EVENT_TREE.lessonPage.actions.showParticipantsGrid);
            let remoteVideoList = document.getElementById('remoteVideoList');
            remoteVideoList.style.display = 'inline-flex';
            store.dispatch(toggleUsersVideo(2));
        }
        handleDisplayTypeClose();
    };

    const handleMicClick = () => {
        if (!props.lessonState.isMuted) {
            audioCallUtils.mute();
        } else {
            audioCallUtils.unMute();
        }
    };

    const handleVidClick = () => {
        if (props.lessonState.isVideoEnabled) {
            audioCallUtils.disableVideo();
        } else {
            audioCallUtils.enableVideo();
        }
    };

    // const handleUserVideosClick = () => {
    //     if (!props.lessonState.isUsersVidEnabled) {
    //         let remoteVideoList = document.getElementById('remoteVideoList');
    //         remoteVideoList.style.display = 'inline-flex';
    //         store.dispatch(toggleUsersVideo(true));
    //     } else {
    //         window.logAppActivity(GA_EVENT_TREE.lessonPage.actions.hideParticipants);
    //         let remoteVideoList = document.getElementById('remoteVideoList');
    //         remoteVideoList.style.display = 'none';
    //         store.dispatch(toggleUsersVideo(false));
    //     }
    // };

    if (window.isTeams) {
        return null;
    }
    let topButton = null;

    if (props.lessonState.callState === CALL_STATE.CAN_START_CALL) {
        topButton = (
            <VBToolTip content={<FormattedMessage id="call.startCall.tooltip" placement="bottom" arrow />}>
                <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    startIcon={<VideocamIcon />}
                    style={buttonMargin}
                    onClick={() => {
                        let canAudioVideo = hasBooleanPermission(
                            props.subscriptions,
                            props.profile.subscription,
                            CAPABILITIES.AUDIO_VIDEO
                        );

                        if (!canAudioVideo.value) {
                            store.dispatch(
                                upgradeSubscriptionDialog({
                                    key: 'subscription.upsell.audioVideo.unavailable',
                                    subscriptions: canAudioVideo.availableSubscriptions,
                                })
                            );
                            return;
                        }
                        audioCallUtils.startCall();
                    }}
                >
                    <FormattedMessage id="call.startCall" />
                </Button>
            </VBToolTip>
        );
    }
    if (props.lessonState.callState === CALL_STATE.CAN_JOIN_CALL && !topButton) {
        topButton = (
            <VBToolTip content={<FormattedMessage id="call.joinCall.tooltip" placement="bottom" arrow />}>
                <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    className="u-m-half"
                    startIcon={<VideocamIcon />}
                    style={buttonMargin}
                    onClick={() => {
                        audioCallUtils.joinCall();
                    }}
                >
                    <FormattedMessage id="call.joinCall" />
                </Button>
            </VBToolTip>
        );
    }

    if (props.lessonState.callState === CALL_STATE.CALL_STARTING && !topButton) {
        topButton = (
            <Button
                size="small"
                variant="outlined"
                className="u-m-half"
                style={buttonMargin}
                disabled
                startIcon={<VideocamIcon />}
            >
                <FormattedMessage id="call.starting" />
            </Button>
        );
    }
    if (props.lessonState.callState === CALL_STATE.CALL_STARTED && !topButton) {
        topButton = (
            <VBToolTip content={<FormattedMessage id="call.endCall.tooltip" placement="bottom" arrow />}>
                <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    className="u-m-half"
                    startIcon={<VideocamOffIcon />}
                    style={buttonMargin}
                    onClick={() => {
                        if (boardManager.isAdmin) {
                            audioCallUtils.endCall();
                            store.dispatch(changeCallState(CALL_STATE.CAN_START_CALL));
                        } else {
                            audioCallUtils.leaveCall();
                            store.dispatch(changeCallState(CALL_STATE.CAN_JOIN_CALL));
                        }
                        store.dispatch(mute(false));
                    }}
                >
                    <FormattedMessage id="call.endCall" />
                </Button>
            </VBToolTip>
        );
    }

    // if (!audioCallUtils.hasLocalStream() && !topButton && !(props.lessonState.callState === CALL_STATE.CALL_DISABLED)) {
    //     topButton = (
    //         <Fab id="muteMic" size="small" color="default" aria-label="mic" className={classes.margin} disabled>
    //             <VBToolTip content={<FormattedMessage id="tooltip.lessonPage.noMic" placement="bottom" arrow />}>
    //                 <MicNoneIcon style={{ verticalAlign: 'middle' }} />
    //             </VBToolTip>
    //         </Fab>
    //     );
    // }

    let micState;
    let videoState;
    let userVidState;

    if (!props.lessonState.isMuted) {
        micState = (
            <VBToolTip content={<FormattedMessage id="tooltip.lessonPage.activeMic" />}>
                <Fab
                    id="muteMic"
                    size="small"
                    aria-label="mic"
                    onClick={handleMicClick}
                    className={classes.margin}
                    style={{ background: '#4da34d', color: 'white' }}
                >
                    <MicIcon style={{ verticalAlign: 'middle' }} />
                </Fab>
            </VBToolTip>
        );
    } else {
        micState = (
            <VBToolTip content={<FormattedMessage id="tooltip.lessonPage.mutedMic" />}>
                <Fab
                    size="small"
                    style={{ background: '#e34646', color: 'white' }}
                    aria-label="mic"
                    onClick={handleMicClick}
                    className={classes.margin}
                >
                    <MicOffIcon style={{ verticalAlign: 'middle' }} />
                </Fab>
            </VBToolTip>
        );
    }

    if (props.lessonState.isVideoEnabled) {
        videoState = (
            <VBToolTip content={<FormattedMessage id="tooltip.lessonPage.videoOn" />}>
                <Fab
                    id="cameraOn"
                    size="small"
                    aria-label="camera"
                    onClick={handleVidClick}
                    className={classes.margin}
                    style={{ background: '#4da34d', color: 'white' }}
                >
                    <VideocamIcon style={{ verticalAlign: 'middle' }} />
                </Fab>
            </VBToolTip>
        );
    } else {
        videoState = (
            <VBToolTip content={<FormattedMessage id="tooltip.lessonPage.videoOff" />}>
                <Fab
                    size="small"
                    aria-label="camera"
                    onClick={handleVidClick}
                    className={classes.margin}
                    style={{ background: '#e34646', color: 'white' }}
                >
                    <VideocamOffIcon style={{ verticalAlign: 'middle' }} />
                </Fab>
            </VBToolTip>
        );
    }

    if (!props.lessonState.isUsersVidEnabled) {
        userVidState = (
            <VBToolTip content={<FormattedMessage id="tooltip.lessonPage.viewVideoNone" />}>
                <Fab size="small" color="secondary" onClick={handleDisplayTypeClick} className={classes.margin}>
                    <BlockOutlinedIcon style={{ verticalAlign: 'middle' }} />
                </Fab>
            </VBToolTip>
        );
    }

    if (props.lessonState.isUsersVidEnabled === 1) {
        userVidState = (
            <VBToolTip content={<FormattedMessage id="tooltip.lessonPage.viewVideoBottom" />}>
                <Fab size="small" color="secondary" onClick={handleDisplayTypeClick} className={classes.margin}>
                    <BottomList style={{ verticalAlign: 'middle' }} />
                </Fab>
            </VBToolTip>
        );
    }
    if (props.lessonState.isUsersVidEnabled === 2) {
        userVidState = (
            <VBToolTip content={<FormattedMessage id="tooltip.lessonPage.viewVideoGrid" />}>
                <Fab size="small" color="secondary" onClick={handleDisplayTypeClick} className={classes.margin}>
                    <GridList style={{ verticalAlign: 'middle' }} />
                </Fab>
            </VBToolTip>
        );
    }

    return (
        <div>
            {topButton}
            <div style={{ position: 'fixed', bottom: '8px', left: '8px', zIndex: '1' }}>
                <ChatBox />
                {props.lessonState.callState === CALL_STATE.CALL_STARTED && userVidState}
                {props.lessonState.callState === CALL_STATE.CALL_STARTED && micState}
                {props.lessonState.callState === CALL_STATE.CALL_STARTED && videoState}
                <StyledMenu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleDisplayTypeClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                >
                    <List>
                        <ListItem
                            button
                            onClick={() => {
                                setVideoDisplay(1);
                            }}
                            dense
                            selected={props.lessonState.isUsersVidEnabled === 1}
                        >
                            <ListItemIcon>
                                <BottomList color="primary" />
                            </ListItemIcon>
                            <ListItemText>
                                <FormattedMessage id={'tooltip.lessonPage.viewVideoBottom'} />
                            </ListItemText>
                        </ListItem>
                        {/*<ListItem*/}
                        {/*    button*/}
                        {/*    onClick={() => {*/}
                        {/*        setVideoDisplay(2);*/}
                        {/*    }}*/}
                        {/*    dense*/}
                        {/*>*/}
                        {/*    <ListItemIcon>*/}
                        {/*        <GridList color="primary" />*/}
                        {/*    </ListItemIcon>*/}
                        {/*    <ListItemText>*/}
                        {/*        <FormattedMessage id={'tooltip.lessonPage.viewVideoGrid'} />*/}
                        {/*    </ListItemText>*/}
                        {/*</ListItem>*/}
                        <ListItem
                            button
                            onClick={() => {
                                setVideoDisplay(0);
                            }}
                            dense
                            selected={props.lessonState.isUsersVidEnabled === 0}
                        >
                            <ListItemIcon>
                                <BlockOutlinedIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText>
                                <FormattedMessage id={'tooltip.lessonPage.viewVideoNone'} />
                            </ListItemText>
                        </ListItem>
                    </List>
                </StyledMenu>
                {/*<Popover*/}
                {/*    id={id}*/}
                {/*    open={open}*/}
                {/*    anchorEl={anchorEl}*/}
                {/*    onClose={handleDisplayTypeClose}*/}
                {/*    anchorOrigin={{*/}
                {/*        vertical: 'top',*/}
                {/*        horizontal: 'center',*/}
                {/*    }}*/}
                {/*    transformOrigin={{*/}
                {/*        vertical: 'bottom',*/}
                {/*        horizontal: 'center',*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <Fab size="small">*/}
                {/*        <MicIcon />*/}
                {/*    </Fab>*/}
                {/*    <Fab size="small">*/}
                {/*        <MicIcon />*/}
                {/*    </Fab>*/}
                {/*</Popover>*/}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    const { lessonState, profile, mainAppState } = state;
    return { lessonState, profile, subscriptions: mainAppState.subscriptions };
};
export default connect(mapStateToProps)(MicBox);
