export const USER_MESSAGE_TYPES = {
    PREDEFINED_TYPE: 0,
};

export const USER_MESSAGES = {
    UPGRADED_TO_BONUS_TRIAL: 0,
    STARTED_FREE: 1,
    UPDATED_SUBSCRIPTION: 2,
    CANCELED_SUBSCRIPTION: 3,
    INVOICE_GENERATED: 4,
    PAYMENT_FAILED: 5,
};
export const USER_MESSAGES_REVERTED = {
    0: 'UPGRADED_TO_BONUS_TRIAL',
    1: 'STARTED_FREE',
    2: 'UPDATED_SUBSCRIPTION',
    3: 'CANCELED_SUBSCRIPTION',
    4: 'INVOICE_GENERATED',
    5: 'PAYMENT_FAILED',
};

export const USER_EMAILS_RO = {
    UPGRADED_TO_BONUS_TRIAL: 'd-9fed9c5d588a4ba7a31f3f829ff0cdce',
    STARTED_FREE: 1,
    // TRIAL_ENDING_IN_5: 2,
    // TRIAL_ENDING_IN_1: 3,
    // TRIAL_ENDED: 4,
    // VALIDATE_SUBSCRIPTION_EMAIL: 5,
};
export const USER_EMAILS_EN = {
    UPGRADED_TO_BONUS_TRIAL: 'd-a04fd0c03c0a4b2fb3171de211097c18',
    STARTED_FREE: 1,
    // TRIAL_ENDING_IN_5: 2,
    // TRIAL_ENDING_IN_1: 3,
    // TRIAL_ENDED: 4,
    // VALIDATE_SUBSCRIPTION_EMAIL: 5,
};

export const EMAIL_GROUPS = {
    ALERT: 16598,
    NEWS: 16599,
    INVOICE: 16600,
};
