import { UPDATE_FEATURES_ACTIVE_SECTION } from '../actionTypes';

const initialState = {
    sectionId: 'section-featureStart',
};

const tutorial = function (state = initialState, action) {
    switch (action.type) {
        case UPDATE_FEATURES_ACTIVE_SECTION: {
            const { sectionId } = action.payload;
            if (sectionId === 'section-featureStart') console.trace();
            return {
                ...state,
                sectionId,
            };
        }

        default:
            return state;
    }
};
export default tutorial;
