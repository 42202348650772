import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import { FindMoreButton } from '../Home/HomeFeatures/FindMoreButton';

const useStyles = makeStyles(() => ({
    sectionImage: { textAlign: 'center', padding: '2rem' },
    image: {
        maxWidth: '300px',
        width: '80%',
        borderRadius: '16px',
    },

    imageVSP: {
        maxWidth: '400px',
        width: '80%',
    },

    title: {
        fontWeight: '700',
        textAlign: 'left',
        paddingTop: '2rem',
        paddingBottom: '1rem',
    },
}));
const AboutPartners = () => {
    const classes = useStyles();

    return (
        <Grid container spacing={3} alignItems={'center'} alignContent={'center'} justify={'center'}>
            <Grid item xs={12}>
                <Typography variant='h2' className={classes.title}>
                    <FormattedMessage id={'aboutUs.partners.button'} />
                </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
                <Typography variant='h3'>
                    <FormattedMessage id={'aboutUs.partners.samsung'} />
                </Typography>
                <Typography variant='h6'>
                    <FormattedMessage id={'aboutUs.partners.samsung.description'} />
                </Typography>
                <Typography variant='h6'>
                    <FormattedMessage id={'aboutUs.partners.samsung.description1'} />
                </Typography>
                <Typography variant='h6'>
                    <FormattedMessage id={'aboutUs.partners.samsung.description2'} />
                </Typography>
                <br />
                <a
                    href={
                        'https://www.edupedu.ro/video-profesorul-controleaza-interactivitatea-cu-elevul-ghideaza-clasa-spune-marius-mazilu-fondatorul-vboard-aplicatia-nativa-pe-tablele-interactive-nu-ne-permitem-ca-procesul-educational-sa-ias/'
                    }
                    target={'_blank'}
                >
                    <FindMoreButton textId={'aboutUs.partners.samsung.announcement'} />
                </a>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.sectionImage}>
                <img src={'/static/asset/img/samsung.png'} className={classes.image} alt='Samsung' />
            </Grid>
            <Divider variant={'middle'} style={{ width: '100%', marginTop: '2rem', marginBottom: '2rem' }} />

            <Grid item xs={12} sm={6}>
                <Typography variant='h3'>
                    <FormattedMessage id={'aboutUs.partners.microsoft'} />
                </Typography>
                <Typography variant='h6'>
                    <FormattedMessage id={'aboutUs.partners.microsoft.description'} />
                </Typography>
                <br />
                <a
                    href={'https://appsource.microsoft.com/en-us/product/office/WA200004360?tab=Overview'}
                    target={'_blank'}
                >
                    <FindMoreButton textId={'aboutUs.partners.microsoft.teamsStore'} />
                </a>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.sectionImage}>
                <img src={'/static/asset/img/MicrosoftLogo.png'} className={classes.image} alt='Microsoft' />
                <img src={'/static/asset/img/MicrosoftTeamsLogo.png'} className={classes.image} alt='Microsoft Teams' />
            </Grid>
            <Divider variant={'middle'} style={{ width: '100%', marginTop: '2rem', marginBottom: '2rem' }} />

            <Grid item xs={12} sm={6}>
                <Typography variant='h3'>
                    <FormattedMessage id={'aboutUs.partners.orange'} />
                </Typography>
                <Typography variant='h6'>
                    <FormattedMessage id={'aboutUs.partners.orange.description'} />
                </Typography>
                <br />

                <a
                    href={'https://www.orangefab.ro/vboard-orange-fab-startups-that-supports-online-education/'}
                    target={'_blank'}
                >
                    <FindMoreButton textId={'aboutUs.partners.orangeFab'} />
                </a>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.sectionImage}>
                <img src={'/static/asset/img/OrangeFab.png'} className={classes.image} alt='OrangeFab' />
            </Grid>
            <Divider variant={'middle'} style={{ width: '100%', marginTop: '2rem', marginBottom: '2rem' }} />

            <Grid item xs={12} sm={6}>
                <Typography variant='h3'>
                    <FormattedMessage id={'aboutUs.partners.innolabs'} />
                </Typography>
                <Typography variant='h6'>
                    <FormattedMessage id={'aboutUs.partners.innolabs.description'} />
                </Typography>
                <br />

                <a
                    href={'https://www.facebook.com/watch/live/?ref=watch_permalink&v=329145398984969&t=14836'}
                    target={'_blank'}
                >
                    <FindMoreButton textId={'aboutUs.partners.innolabs.awards'} />
                </a>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.sectionImage}>
                <img src={'/static/asset/img/InnovationLabs.png'} className={classes.image} alt='Innovation Labs' />
            </Grid>
        </Grid>
    );
};

export default AboutPartners;
