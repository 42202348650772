import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function CloseSideMenuIcon(props) {
    return (
        <SvgIcon {...props} style={{ width: '2rem', height: '2rem', marginRight: '1.25rem' }}>
            <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect
                    x="16.293"
                    y="13.4645"
                    width="14.9"
                    height="3.99997"
                    rx="1.99998"
                    transform="rotate(45 16.293 13.4645)"
                    fill="#08AAB4"
                />

                <rect
                    x="13.463"
                    y="31.7056"
                    width="14.9"
                    height="4.00428"
                    rx="2.00214"
                    transform="rotate(-45 13.463 31.7056)"
                    fill="#FFCD34"
                />

                <rect
                    x="34.5371"
                    y="16.2944"
                    width="14.9"
                    height="4.00453"
                    rx="2.00226"
                    transform="rotate(135 34.5371 16.2944)"
                    fill="#D26828"
                />

                <rect
                    x="31.708"
                    y="34.5347"
                    width="14.9"
                    height="3.9978"
                    rx="1.9989"
                    transform="rotate(-135 31.708 34.5347)"
                    fill="#F9A237"
                />
            </svg>
        </SvgIcon>
    );
}
