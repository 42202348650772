import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function CompassPenIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                zoomAndPan="magnify"
                viewBox="0 0 150 150"
                preserveAspectRatio="xMidYMid meet"
                version="1.0"
            >
                <path
                    d="M 60.96875 136.097656 C 60.96875 143.703125 67.179688 149.886719 74.8125 149.886719 C 82.445312 149.886719 88.65625 143.703125 88.65625 136.097656 L 88.65625 125.765625 L 60.96875 125.765625 Z M 60.96875 136.097656 "
                    fillOpacity="1"
                    fillRule="nonzero"
                />
                <g>
                    <path
                        d="M 40.667969 87.949219 C 48.359375 100.71875 56.878906 114.859375 56.878906 114.859375 C 57.675781 116.179688 59.109375 116.988281 60.65625 116.988281 L 88.96875 116.988281 C 90.515625 116.988281 91.949219 116.179688 92.746094 114.859375 C 92.746094 114.859375 101.265625 100.71875 108.957031 87.949219 C 116.539062 75.359375 120.867188 61.109375 121.582031 46.488281 L 28.042969 46.488281 C 28.757812 61.109375 33.085938 75.359375 40.667969 87.949219 Z M 40.667969 87.949219 "
                        fillOpacity="1"
                        fillRule="nonzero"
                    />
                </g>
                <g>
                    <path
                        d="M 121.6875 37.710938 L 121.6875 4.5 C 121.6875 2.078125 119.714844 0.113281 117.28125 0.113281 L 32.34375 0.113281 C 29.910156 0.113281 27.9375 2.078125 27.9375 4.5 L 27.9375 37.710938 Z M 121.6875 37.710938 "
                        fillOpacity="1"
                        fillRule="nonzero"
                    />
                </g>
            </svg>
        </SvgIcon>
    );
}
