import React from 'react';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import MultiScreenIcon from '../../icons/MultiScreenIcon';
import { hideSpinner, pickLessonPage, showSpinner, showStaticPageNavigator } from '../../../redux/actions';
import store from '../../../redux/store';
import VBToolTip from '../../Tooltip/VBToolTip';
import { GA_EVENT_TREE } from '../../../gaConstants';
import boardManager from '../../../pages/LessonPage/boardManager';
import { BOARD_TYPE, MAX_MULTI_PAGES, MAX_MAIN_PAGES } from '../../../constants';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import cloudBoardManager from '../../../pages/LessonPage/cloudBoardManager';

const useStyles = makeStyles((theme) => ({
    multiScreen: {
        position: 'absolute',
        width: '100%',
    },
    count: {
        position: 'absolute',
        width: '100%',
        lineHeight: '4rem',
        textAlign: 'center',
        marginTop: '0.2rem',
        marginLeft: '-0.3rem',
    },
    svg: {
        height: '4rem',
        width: '100%',
        marginTop: '0.2rem',
    },
    multiScreenButton: {
        position: 'absolute',
        height: '4rem',
        width: '100%',
        '& button': {
            height: '100%',
        },
    },
}));

const ToolNavLesson = (props) => {
    const classes = useStyles();
    const [stateCurrentPage, setStateCurrentPage] = React.useState(null);
    const [forceRefresh, setForceRefresh] = React.useState(null);

    let canNavigate = true;

    if (!props.lessonState.isStarted) return null;
    if (!boardManager.lessonState) return null;
    if (!props.lessonState.isAdmin && boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) return null;

    if (props.lessonState.isAdmin && boardManager.lessonState.boardType === BOARD_TYPE.MULTI_BOARD) {
        canNavigate = false;
    }

    let prevEnabled = false;
    let nextEnabled = false;
    if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD && props.lessonState.currentLessonPage > 0) {
        prevEnabled = true;
    }
    if (
        boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD &&
        props.lessonState.currentLessonPage + 1 < MAX_MAIN_PAGES
    ) {
        nextEnabled = true;
    }

    window.updateBoardNavigation = () => {
        // console.log('=== should updateBoardNavigation', boardManager.lessonState.boardType, boardManager.isAdmin);
        if (
            boardManager.lessonState.boardType === BOARD_TYPE.MULTI_BOARD &&
            !boardManager.isAdmin &&
            boardManager.lessonState.individualBoards[boardManager.adminEmail] &&
            boardManager.lessonState.individualBoards[boardManager.adminEmail].pages
        ) {
            // pageCount = boardManager.lessonState.individualBoards[boardManager.adminEmail].pages.length;
            // let pageNo = 0;
            // boardManager.lessonState.individualBoards[boardManager.adminEmail].pages.forEach((page, index) => {
            //     if (page.id === boardManager.mainBoard.boardId) {
            //         pageNo = index;
            //     }
            // });
            setStateCurrentPage(boardManager.lessonState.individualBoards[boardManager.adminEmail].currentPageNo);
        }
        if (
            boardManager.lessonState.boardType === BOARD_TYPE.MULTI_BOARD &&
            boardManager.isAdmin &&
            boardManager.currentBoardActive &&
            boardManager.lessonState.individualBoards[boardManager.currentBoardActive]
        ) {
            setStateCurrentPage(
                boardManager.lessonState.individualBoards[boardManager.currentBoardActive].currentPageNo
            );
        }
        if (
            boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD &&
            boardManager.isAdmin &&
            boardManager.lessonState.mainBoardPages &&
            boardManager.lessonState.mainBoardId
        ) {
            let idx = boardManager.lessonState.mainBoardPages.findIndex(
                (el) => el.id === boardManager.lessonState.mainBoardId
            );
            if (idx >= 0) {
                setStateCurrentPage(idx);
            }
        }
        setForceRefresh(!forceRefresh);
    };

    let currPage = 0;
    let pageCount = 0;

    if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
        currPage = props.lessonState.currentLessonPage + 1;
        pageCount = props.lessonState.lessonPages.length;
    } else {
        // console.log('tbd pick multi board user');
        if (
            !boardManager.isAdmin &&
            boardManager.lessonState?.individualBoards &&
            boardManager.lessonState.individualBoards[boardManager.adminEmail] &&
            boardManager.lessonState.individualBoards[boardManager.adminEmail].pages
        ) {
            pageCount = boardManager.lessonState.individualBoards[boardManager.adminEmail].pages.length;
            if (stateCurrentPage !== null) {
                currPage = stateCurrentPage + 1;
            } else {
                let pageNo = 0;
                boardManager.lessonState.individualBoards[boardManager.adminEmail].pages.forEach((page, index) => {
                    if (page.id === boardManager.mainBoard.boardId) {
                        pageNo = index;
                    }
                });
                currPage = pageNo + 1;
            }
            if (currPage > 1) {
                prevEnabled = true;
            }
            if (currPage <= MAX_MULTI_PAGES && boardManager.lessonState.canMultiple) {
                nextEnabled = true;
            }
        } else {
            if (
                boardManager?.lessonState?.individualBoards &&
                boardManager.lessonState.individualBoards[boardManager.currentBoardActive] &&
                boardManager.lessonState.individualBoards[boardManager.currentBoardActive].pages
            ) {
                pageCount = boardManager.lessonState.individualBoards[boardManager.currentBoardActive].pages.length;
                currPage = boardManager.lessonState.individualBoards[boardManager.currentBoardActive].currentPageNo + 1;
            }
        }
    }

    const onNext = () => {
        window.logAppActivity(GA_EVENT_TREE.lessonPage.toolSelection.nextBoard);
        if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
            if (props.lessonState.lessonPages.length - 1 === props.lessonState.currentLessonPage) {
                if (window.newPage) {
                    window.newPage();
                }
            } else {
                if (window.switchPage) {
                    window.switchPage(props.lessonState.currentLessonPage + 1);
                }
            }
        } else {
            // console.log('tbd next multi board user');
            boardManager.switchMultiBoardPageForUser(stateCurrentPage + 1);
        }
    };

    const onPrev = () => {
        window.logAppActivity(GA_EVENT_TREE.lessonPage.toolSelection.previousBoard);
        if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
            if (props.lessonState.currentLessonPage > 0) {
                if (window.switchPage) {
                    window.switchPage(props.lessonState.currentLessonPage - 1);
                }
            }
        } else {
            if (stateCurrentPage > 0) {
                boardManager.switchMultiBoardPageForUser(stateCurrentPage - 1);
            }
            // console.log('tbd prev multi board user');
        }
    };

    const openList = (currPage) => {
        window.logAppActivity(GA_EVENT_TREE.lessonPage.toolSelection.openBoards);
        if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
            store.dispatch(showSpinner());
            cloudBoardManager.saveCurrentMainPage().then(() => {
                store.dispatch(pickLessonPage(props.lessonState.lessonInfo.classData.id));
                store.dispatch(hideSpinner());
            });
        } else {
            // console.log('tbd pick multi board user', boardManager);
            if (boardManager.isAdmin) {
                if (
                    boardManager.lessonState.individualBoards[boardManager.currentBoardActive] &&
                    boardManager.lessonState.individualBoards[boardManager.currentBoardActive].pages &&
                    boardManager.lessonState.individualBoards[boardManager.currentBoardActive].pages.length > 1
                ) {
                    // console.log('==== board manager', boardManager);
                    let data = {
                        originalUrl: boardManager.lessonState.originalMultiBoardState.originalDownload,
                        originalSticky: boardManager.lessonState.originalMultiBoardState.originalSticky,
                        userState: boardManager.lessonState.individualBoards[boardManager.currentBoardActive],
                        currentPage: currPage,
                    };
                    // console.log(data);
                    store.dispatch(showStaticPageNavigator(data));
                }
            } else {
                // console.log('==== board manager', boardManager);
                let data = {
                    originalUrl: boardManager.lessonState.originalMultiBoardState.originalDownload,
                    originalSticky: boardManager.lessonState.originalMultiBoardState.originalSticky,
                    userState: boardManager.lessonState.individualBoards[boardManager.adminEmail],
                    currentPage: currPage,
                };
                store.dispatch(showStaticPageNavigator(data));
                // console.log('==== board manager', boardManager.lessonState.individualBoards, boardManager);
            }
        }
    };

    if (currPage === 0) {
        currPage = 1;
    }
    if (pageCount === 0) {
        pageCount = 1;
    }
    return (
        <div>
            <VBToolTip content={<FormattedMessage id="tooltip.lessonPage.boards" />}>
                <div
                    style={{ height: '3rem' }}
                    onClick={() => {
                        openList(currPage - 1);
                    }}
                >
                    <div style={{ position: 'relative' }}>
                        <div className={classes.multiScreen}>
                            <MultiScreenIcon className={classes.svg} />
                        </div>
                        <div className={classes.count}>
                            {currPage}/{pageCount}
                        </div>
                        <div className={classes.multiScreenButton}>
                            <Button size="large" fullWidth />
                        </div>
                    </div>
                </div>
            </VBToolTip>

            {canNavigate ? (
                <div>
                    <VBToolTip content={<FormattedMessage id="tooltip.lessonPage.previous" />}>
                        <IconButton
                            color="default"
                            aria-label="previous"
                            component="span"
                            disabled={!prevEnabled}
                            onClick={onPrev}
                        >
                            <NavigateBeforeIcon />
                        </IconButton>
                    </VBToolTip>
                    <VBToolTip content={<FormattedMessage id="tooltip.lessonPage.next" />}>
                        <IconButton
                            color="default"
                            aria-label="next"
                            component="span"
                            onClick={onNext}
                            disabled={!nextEnabled}
                        >
                            <NavigateNextIcon />
                        </IconButton>
                    </VBToolTip>
                </div>
            ) : null}
        </div>
    );
};

const mapStateToProps = (state) => {
    const { lessonState } = state;
    return { lessonState };
};

export default injectIntl(connect(mapStateToProps)(ToolNavLesson));
