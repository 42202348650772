import React, { useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Container } from '@material-ui/core';
import GenericState from '../../MyComponents/GenericLoadingErrorState/GenericState';
import managementApi from '../../Api/managementApi';
import PaginatedTable from '../../MyComponents/PaginatedTable/PaginatedTable';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
import AddEditSubscription from './AddEditSubscription';
import FindUser from './FindUser';
import AccountStatus from './AccountStatus';

const useStyles = makeStyles((theme) => ({
    toolbar: theme.mixins.toolbar,
}));

const subscriptionTypesColumns = [
    { id: 'subscriptionStripeId', label: 'StripeId' },
    { id: 'subscriptionName', label: 'Name' },
    { id: 'groupCount', label: 'Max Groups' },
    { id: 'collectionCount', label: 'Max Library' },
    {
        id: 'multipleIndividualBoards',
        label: 'Multiple Individual Boards',
        render: (val) => {
            return val ? 'Yes' : 'No';
        },
    },
    {
        id: 'pdfDownload',
        label: 'Download PDF',
        render: (val) => {
            return val ? 'Yes' : 'No';
        },
    },
    {
        id: 'audioVideo',
        label: 'Audio Video Calls',
        render: (val) => {
            return val ? 'Yes' : 'No';
        },
    },
    {
        id: 'id',
        label: 'Edit',
        render: function (val, row, action) {
            return (
                <Fab
                    size="small"
                    onClick={() => {
                        if (action) {
                            action('edit-subscription', row);
                        }
                    }}
                >
                    <EditIcon />
                </Fab>
            );
        },
    },
];

const Management = () => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = React.useState(true);
    const [isError] = React.useState(false);
    const [rows, setRows] = React.useState([]);
    const [count, setCount] = React.useState(0);
    const [pricing, setPricing] = React.useState([]);

    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('latestlogin');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    const [type, setType] = React.useState('accountStatus');

    const [columns, setColumns] = React.useState(subscriptionTypesColumns);
    const [editSubscription, setEditSubscription] = React.useState(null);

    const handleAnalyticsType = (event, newType) => {
        setType(newType);
        if (newType === 'subscriptionTypes') {
            setColumns([...subscriptionTypesColumns]);
            setPage(0);
        }
    };

    useEffect(() => {
        if (type === 'subscriptionTypes') {
            setIsLoading(true);
            managementApi.getSubscriptionTypes().then((res) => {
                setIsLoading(false);
                let subscriptionList = [];
                Object.keys(res.data.subscriptions).forEach((key) => {
                    subscriptionList.push(res.data.subscriptions[key]);
                });
                setRows(subscriptionList);
                setCount(subscriptionList.length);
                setPricing(res.data.pricing);
            });
        }
        if (type === 'accountStatus') {
            setIsLoading(false);
        }
    }, [order, orderBy, page, rowsPerPage, type, editSubscription]);

    if (isLoading) {
        return <GenericState isLoading={true} />;
    }
    if (isError) {
        return <GenericState isError={true} />;
    }

    const setOrderFlags = (order, orderBy) => {
        setOrder(order);
        setOrderBy(orderBy);
    };

    const handleAction = (action, data) => {
        console.log('=== handling action', action, data);
        setEditSubscription(data);
    };

    return (
        <React.Fragment>
            <CssBaseline />
            <div className={classes.toolbar} />
            <Container>
                <ToggleButtonGroup value={type} exclusive onChange={handleAnalyticsType}>
                    <ToggleButton value="accountStatus">
                        <Typography variant={'h6'}>Account status</Typography>
                    </ToggleButton>
                    <ToggleButton value="subscriptionTypes">
                        <Typography variant={'h6'}>Subscription Types</Typography>
                    </ToggleButton>
                    <ToggleButton value="alterUser">
                        <Typography variant={'h6'}>Alter User</Typography>
                    </ToggleButton>
                </ToggleButtonGroup>
                {type === 'subscriptionTypes' && !editSubscription ? (
                    <PaginatedTable
                        headCells={columns}
                        rows={rows}
                        rowCount={count}
                        order={order}
                        orderBy={orderBy}
                        setOrder={setOrderFlags}
                        page={page}
                        setPage={setPage}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        handleAction={handleAction}
                    />
                ) : null}
                {type === 'subscriptionTypes' && editSubscription ? (
                    <AddEditSubscription
                        subscription={editSubscription}
                        pricing={pricing}
                        handleClose={() => {
                            setEditSubscription(null);
                        }}
                    />
                ) : null}
                {type === 'alterUser' ? <FindUser /> : null}
                {type === 'accountStatus' ? <AccountStatus /> : null}
            </Container>
        </React.Fragment>
    );
};

export default Management;
