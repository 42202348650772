import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Route, Switch, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Backdrop from '@material-ui/core/Backdrop';
import store from '../../redux/store';
import { hideNotification } from '../../redux/actions';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { injectIntl } from 'react-intl';

import Hidden from '@material-ui/core/Hidden';
import Profile from '../../pages/Profile/Profile';
import CreateClass from '../../pages/CreateClass/CreateClass';
import EditClass from '../../pages/EditClass/EditClass';
import ManageClass from '../../pages/ManageClass/ManageClass';
import NotFound from '../../pages/NotFound/NotFound';
import Fab from '@material-ui/core/Fab';
import JoinRequest from '../../pages/JoinRequest/JoinRequest';
import Contact from '../../pages/Compliancy/Contact';
import PrivacyPolicy from '../../pages/Compliancy/PrivacyPolicy';
import TermsOfUse from '../../pages/Compliancy/TermsOfUse';
import Header from '../../pages/Home/Header';
import Tutorial from '../../pages/Tutorial/Tutorial';
import SpinnerContentRenderer from './SpinnerContentRenderer';
import Analytics from '../../pages/Analytics/Analytics';
import ManageCollections from '../../pages/ManageCollections/ManageCollections';
import Management from '../../pages/Analytics/Management';
import Subscriptions from '../../pages/Subscriptions/Subscriptions';
import Onboarding from '../../pages/Onboarding/Onboarding';
import VerifyEmailPage from '../../pages/Onboarding/VerifyEmailPage';
import StripePayment from '../../pages/StripePayment/StripePayment';
import UserMessages from '../../pages/UserMessages/UserMessages';
import {
    ALLOW_WHITEBOARD,
    CONTACT_US_LINK,
    MANAGE_CLASSES_LINK,
    PROFILE_LINK,
    SPECIAL_QUOTA_LINK,
} from '../../common/urlLinks';
import SpecialQuota from '../../pages/Compliancy/SpecialQuota';
import HomeLoggedIn from '../../pages/Home/HomeLoggedIn';
import { Container, Grid } from '@material-ui/core';
import Features from '../../pages/Features/Features';
import TeamsConfig from '../../pages/TeamsConfig/TeamsConfig';
import LeftMenuIcon from '../icons/LeftMenuIcon';
import { SideNavigation } from '../index';
import { COLORS2 } from '../../constants';
import { Unsubscribe } from '../../pages/Unsubscribe/Unsubscribe';
import ForTutoring from '../../pages/ForTutoring/ForTutoring';
import ForSchools from '../../pages/ForSchools/ForSchools';
import Welcome from '../../pages/Welcome/Welcome';
import About from '../../pages/About/About';
import ApproveLinkWhiteboard from '../../pages/LinkWhiteboard/ApproveLinkWhiteboard.js';

function Alert(props) {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const drawerWidth = 272;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        background: COLORS2.virtualBackground,
        height: '100%',
    },
    drawer: {
        [theme.breakpoints.up('lg')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    menuButton: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(7),
        position: 'absolute',
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        height: 'calc(100vh - 48px)',
        width: drawerWidth,
        backgroundColor: 'transparent',
        borderRight: 'none',
        margin: '24px 0px',
        borderRadius: '0px 32px 32px 0px',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(0),
        overscrollBehaviorY: 'contain',
        width: '100%',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    listItem: {
        width: '100%',
        height: '3rem',

        '&:hover': {
            width: '100%',
            height: '3rem',
            background: COLORS2.virtualBackground,
            borderRadius: '0.5rem',
        },
    },
    entireSideMenu: {
        // minHeight: '100%',
        height: 'calc(100vh - 48px)',
        background: '#FFFFFF',
        boxShadow: '0rem 0rem 2.5rem rgba(210, 104, 40, 0.04)',
        borderRadius: '0rem 1rem 1rem 0rem',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
}));

const PersistentDrawerLeft = (props) => {
    const classes = useStyles();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    let history = useHistory();

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        store.dispatch(hideNotification());
    };

    let notifMessage = '';
    if (props.appState.notificationMessage) {
        if (typeof props.appState.notificationMessage === 'string') {
            notifMessage = props.intl.formatMessage({
                id: 'notification.code.' + props.appState.notificationMessage,
            });
        } else {
            notifMessage = props.intl.formatMessage(
                {
                    id: 'notification.code.' + props.appState.notificationMessage.messageKey,
                },
                props.appState.notificationMessage.data,
            );
        }
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <nav className={classes.drawer}>
                <Hidden lgUp implementation='css'>
                    <SideNavigation open={mobileOpen} handleDrawerToggle={handleDrawerToggle} variant='temporary' />
                </Hidden>
                <Hidden mdDown implementation='css'>
                    <SideNavigation open={true} handleDrawerToggle={handleClose} variant='permanent' />
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.menuButton}>
                    <Fab
                        size='large'
                        aria-label='menu'
                        onClick={handleDrawerToggle}
                        aria-controls='pen-menu'
                        aria-haspopup='true'
                        style={{
                            boxShadow: 'none',
                            backgroundColor: COLORS2.floralWhite,
                            width: '4.5rem',
                            height: '4.5rem',
                        }}
                    >
                        <LeftMenuIcon style={{ width: '4rem', height: '4rem' }} />
                    </Fab>
                </div>

                <Container style={{ paddingBottom: '5rem' }} maxWidth='lg'>
                    <Grid container direction='row' spacing={2}>
                        <Header updateLanguage={props.updateLanguage} language={props.language} />

                        <Switch>
                            <Route path={PROFILE_LINK} component={Profile} />
                            <Route path={`${ALLOW_WHITEBOARD}/:id`} component={ApproveLinkWhiteboard} />
                            <Route path='/creategroup' component={CreateClass} />
                            <Route path='/class/:classId' component={EditClass} />
                            <Route path='/joinrequest/:classId' component={JoinRequest} />
                            <Route path='/features' component={Features} />
                            <Route path='/ro/features' component={Features} />
                            <Route path='/fr/features' component={Features} />
                            <Route path='/en/features' component={Features} />
                            <Route path='/manageclasses' component={ManageClass} />
                            <Route path='/teamsConfig' component={TeamsConfig} />
                            <Route path='/library' component={ManageCollections} />
                            <Route path={CONTACT_US_LINK} component={Contact} />
                            <Route path={SPECIAL_QUOTA_LINK} component={SpecialQuota} />
                            <Route path='/analytics' component={Analytics} />
                            <Route path='/management' component={Management} />
                            <Route path='/privacypolicy' component={PrivacyPolicy} />
                            <Route path='/terms' component={TermsOfUse} />
                            <Route
                                exact={false}
                                path={[
                                    '/ro/about/:aboutValue',
                                    '/fr/about/:aboutValue',
                                    '/en/about/:aboutValue',
                                    '/about/:aboutValue',
                                    '/ro/about',
                                    '/fr/about',
                                    '/en/about',
                                    '/about',
                                ]}
                            >
                                <About />
                            </Route>


                            <Route exact={true} path={['/ro/tutoring', '/fr/tutoring', '/en/tutoring', '/tutoring']}>
                                <ForTutoring
                                    language={props.language}
                                    handleOpen={() => {
                                        history.push(MANAGE_CLASSES_LINK);
                                    }}
                                />
                            </Route>
                            <Route exact={true} path={['/ro/schools', '/fr/schools', '/en/schools', '/schools']}>
                                <ForSchools
                                    language={props.language}
                                    handleOpen={() => {
                                        history.push(MANAGE_CLASSES_LINK);
                                    }}
                                />
                            </Route>

                            <Route path='/tutorial'>
                                <Tutorial language={props.language} />
                            </Route>
                            <Route path='/ro/tutorial'>
                                <Tutorial language={props.language} />
                            </Route>
                            <Route path='/en/tutorial'>
                                <Tutorial language={props.language} />
                            </Route>
                            <Route path='/fr/tutorial'>
                                <Tutorial language={props.language} />
                            </Route>
                            <Route path='/subscriptions'>
                                <Subscriptions language={props.language} />
                            </Route>
                            <Route path='/ro/subscriptions'>
                                <Subscriptions language={props.language} />
                            </Route>
                            <Route path='/fr/subscriptions'>
                                <Subscriptions language={props.language} />
                            </Route>
                            <Route path='/en/subscriptions'>
                                <Subscriptions language={props.language} />
                            </Route>
                            <Route path='/stripe-payment'>
                                <StripePayment language={props.language} />
                            </Route>
                            <Route path='/onboarding'>
                                <Onboarding language={props.language} />
                            </Route>
                            <Route path='/verifyemail'>
                                <VerifyEmailPage language={props.language} />
                            </Route>
                            <Route path='/messages'>
                                <UserMessages language={props.language} />
                            </Route>
                            <Route path='/welcome'>
                                <Welcome language={props.language} />
                            </Route>
                            <Route exact={true} path='/'>
                                <HomeLoggedIn />
                            </Route>
                            <Route exact={true} path='/en'>
                                <HomeLoggedIn />
                            </Route>
                            <Route exact={true} path='/ro'>
                                <HomeLoggedIn />
                            </Route>
                            <Route exact={true} path='/fr'>
                                <HomeLoggedIn />
                            </Route>
                            <Route exact={true} path='/signup_consent'>
                                <HomeLoggedIn />
                            </Route>
                            <Route exact={true} path='/unsubscribe/:token'>
                                <Unsubscribe />
                            </Route>
                            <Route path='*'>
                                <NotFound />
                            </Route>
                        </Switch>
                    </Grid>
                </Container>

                {/*<Switch>*/}
                {/*    <Route path={PROFILE_LINK}>*/}
                {/*        <Header updateLanguage={props.updateLanguage} />*/}
                {/*    </Route>*/}
                {/*    <Route path="/creategroup">*/}
                {/*        <Header updateLanguage={props.updateLanguage} />*/}
                {/*    </Route>*/}
                {/*    <Route path="/class/:classId">*/}
                {/*        <Header updateLanguage={props.updateLanguage} />*/}
                {/*    </Route>*/}
                {/*    <Route path="/joinrequest/:classId">*/}
                {/*        <Header updateLanguage={props.updateLanguage} />*/}
                {/*    </Route>*/}
                {/*    <Route path="/manageclasses">*/}
                {/*        <Header updateLanguage={props.updateLanguage} />*/}
                {/*    </Route>*/}
                {/*    <Route path="/library">*/}
                {/*        <Header updateLanguage={props.updateLanguage} />*/}
                {/*    </Route>*/}
                {/*    <Route path="/joinclass">*/}
                {/*        <Header updateLanguage={props.updateLanguage} />*/}
                {/*    </Route>*/}
                {/*    <Route path={CONTACT_US_LINK}>*/}
                {/*        <Header updateLanguage={props.updateLanguage} />*/}
                {/*    </Route>*/}
                {/*    <Route path={SPECIAL_QUOTA_LINK}>*/}
                {/*        <Header updateLanguage={props.updateLanguage} />*/}
                {/*    </Route>*/}
                {/*    <Route path="/privacypolicy">*/}
                {/*        <Header updateLanguage={props.updateLanguage} />*/}
                {/*    </Route>*/}
                {/*    <Route path="/tutorial">*/}
                {/*        <Header updateLanguage={props.updateLanguage} />*/}
                {/*    </Route>*/}
                {/*    <Route path="/subscriptions">*/}
                {/*        <Header updateLanguage={props.updateLanguage} />*/}
                {/*    </Route>*/}
                {/*    <Route path="/stripe-payment">*/}
                {/*        <Header updateLanguage={props.updateLanguage} />*/}
                {/*    </Route>*/}
                {/*    <Route path="/onboarding">*/}
                {/*        <Header updateLanguage={props.updateLanguage} />*/}
                {/*    </Route>*/}
                {/*    <Route path="/messages">*/}
                {/*        <Header updateLanguage={props.updateLanguage} />*/}
                {/*    </Route>*/}
                {/*</Switch>*/}

                <Backdrop className={classes.backdrop} open={props.appState.loading}>
                    <SpinnerContentRenderer content={props.appState.loadingData} />
                </Backdrop>
                {props.appState.isNotificationOpen ? (
                    <Snackbar
                        open={props.appState.isNotificationOpen}
                        autoHideDuration={6000}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Alert onClose={handleClose} severity={props.appState.notificationType}>
                            {notifMessage}
                        </Alert>
                    </Snackbar>
                ) : null}
            </main>
        </div>
    );
};

const mapStateToProps = (state) => {
    const { profile, appState, lessonState } = state;
    return { profile, appState, lessonState };
};

export default injectIntl(connect(mapStateToProps)(PersistentDrawerLeft));
