import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function RonIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                zoomAndPan="magnify"
                viewBox="0 0 150 150"
                preserveAspectRatio="xMidYMid meet"
                version="1.0"
            >
                <path
                    fill="#fcd20f"
                    d="M 99.988281 4.261719 L 99.988281 145.71875 C 92.175781 148.484375 83.753906 150 75 150 C 66.246094 150 57.824219 148.503906 49.996094 145.738281 L 49.996094 4.261719 C 57.824219 1.496094 66.230469 0 75 0 C 83.769531 0 92.175781 1.496094 99.988281 4.261719 Z M 99.988281 4.261719 "
                    fillOpacity="1"
                    fillRule="nonzero"
                />
                <path
                    fill="#cf0921"
                    d="M 150 75 C 150 80.132812 149.476562 85.144531 148.503906 90 C 147.808594 93.429688 146.886719 96.769531 145.738281 100.003906 C 138.203125 121.324219 121.324219 138.203125 100.003906 145.738281 L 100.003906 4.261719 C 121.324219 11.796875 138.203125 28.675781 145.738281 49.996094 C 146.886719 53.230469 147.808594 56.570312 148.503906 60 C 149.476562 64.835938 150 69.867188 150 75 Z M 150 75 "
                    fillOpacity="1"
                    fillRule="nonzero"
                />
                <path
                    fill="#002780"
                    d="M 49.996094 4.261719 L 49.996094 145.71875 C 28.695312 138.203125 11.796875 121.304688 4.261719 99.988281 C 3.132812 96.75 2.191406 93.410156 1.496094 90 C 0.523438 85.144531 0 80.132812 0 75 C 0 69.867188 0.523438 64.855469 1.496094 60 C 2.191406 56.570312 3.113281 53.230469 4.261719 49.996094 C 11.796875 28.695312 28.695312 11.796875 49.996094 4.261719 Z M 49.996094 4.261719 "
                    fillOpacity="1"
                    fillRule="nonzero"
                />
            </svg>
        </SvgIcon>
    );
}
