import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function GreenFolderIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 22.5H21C22.6547 22.5 24 21.1547 24 19.5V7.5C24 5.84531 22.6547 4.5 21 4.5H13.9922C13.1953 4.5 12.4312 4.18594 11.8687 3.62344L10.6266 2.37656C10.0641 1.81406 9.3 1.5 8.50312 1.5H3C1.34531 1.5 0 2.84531 0 4.5V19.5C0 21.1547 1.34531 22.5 3 22.5Z" fill="#065156"/>
            </svg>
        </SvgIcon>
    );
}
