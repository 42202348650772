import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import drawUtils from '../../pages/LessonPage/drawUtils';
import Grid from '@material-ui/core/Grid';
import SpinnerContentRenderer from '../../MyComponents/Drawer/SpinnerContentRenderer';
import Fab from '@material-ui/core/Fab';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import MultiScreenIcon from '../../MyComponents/icons/MultiScreenIcon';

const centeredDiv = {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
};
const verticalCenteredDiv = {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
};

const messageForCurrentPage = {
    maxWidth: '50vw',
    position: 'absolute',
    bottom: '0rem',
    right: '0rem',
    padding: '0.2rem',
    borderRadius: '8px',
    backgroundColor: 'rgba(0,0,0,0.2)',
};

const PageNavigator = React.forwardRef((props, ref) => {
    const data = props.data;
    const [value, setValue] = useState(null);
    const dataItems = useRef({ images: [] });
    const canceled = useRef({ val: false });
    useImperativeHandle(ref, () => ({
        getCurrentItem: () => {
            return value;
        },
        getCurrentComposedImage: () => {
            if (value !== null) {
                return dataItems.current.images[value];
            }
            return null;
        },
        getCurrentComposedImages: () => {
            if (value !== null) {
                return dataItems.current.images;
            }
            return null;
        },
        getBoardData: () => {
            return props.data;
        },
    }));

    const buildDataItems = async (buildInfo) => {
        if (canceled.current.val) {
            console.log('canceled - stopping shit');
        }
        // console.log('==== should load shit', buildInfo);
        for (let i = 0; i < data.userState.pages.length; i++) {
            let dataImg;
            // console.log('=== loading ', i, buildInfo.userState.pages[i].url, buildInfo.userState.pages[i].id);
            let url = buildInfo.userState.pages[i].download;
            // if (!boardManager.isAdmin) {
            //     url = buildInfo.userState.pages[i].download;
            // }
            if (i === 0) {
                dataImg = await drawUtils.buildComplexComposedImage(
                    buildInfo.userState.pages[i].meta.gridType,
                    buildInfo.originalUrl,
                    buildInfo.originalSticky,
                    buildInfo.userState.pages[i].meta.stickyElements,
                    url,
                    buildInfo.userState.pages[i].id
                );
            } else {
                dataImg = await drawUtils.buildComplexComposedImage(
                    buildInfo.userState.pages[i].meta.gridType,
                    null,
                    null,
                    buildInfo.userState.pages[i].meta.stickyElements,
                    url,
                    buildInfo.userState.pages[i].id
                );
            }
            if (canceled.current.val) {
                console.log('canceled - stopping shit');
                break;
            }
            dataItems.current.images[i] = dataImg;
        }
        setValue(0);
    };

    useEffect(() => {
        if (!data) {
            dataItems.current = { images: [] };
        }
        if (!data.userState.pages) {
            dataItems.current = { images: [] };
        }
        buildDataItems(data);
        return () => {
            canceled.current.val = true;
        };
    }, [data]);

    const onNext = () => {
        setValue(value + 1);
    };
    const onPrev = () => {
        setValue(value - 1);
    };

    if (value === null) {
        return (
            <div style={centeredDiv}>
                <SpinnerContentRenderer />
            </div>
        );
    }

    if (dataItems.current.images.length > 1) {
        return (
            <Grid container direction="row" justify="center">
                <Grid item xs={12} style={{ display: 'inline-flex', position: 'relative' }}>
                    <Chip
                        icon={<MultiScreenIcon />}
                        variant="outlined"
                        className="u-mb-half"
                        label={`${value + 1} / ${dataItems.current.images.length}`}
                        style={{ position: 'absolute', left: '45%' }}
                    />
                    <div style={{ ...verticalCenteredDiv, position: 'absolute', left: '12px' }}>
                        <Fab color="primary" size="small" disabled={value === 0} onClick={onPrev}>
                            <NavigateBefore />
                        </Fab>
                    </div>
                    <div style={{ ...verticalCenteredDiv, width: '100%', textAlign: 'center' }}>
                        <img
                            src={dataItems.current.images[value]}
                            alt="preview"
                            style={{ maxHeight: '75vh', maxWidth: '90vw', width: '100%' }}
                        />

                        {/*{value !== data.currentPage ? (*/}
                        {/*) : (*/}
                        {/*    <div style={{ marginTop: '3rem' }}>*/}
                        {/*        <Typography variant="h5">*/}
                        {/*            <FormattedMessage id="multiBoardNavigator.pageIsInEdit" />*/}
                        {/*        </Typography>*/}
                        {/*        <img*/}
                        {/*            src={`/static/asset/img/ChildWriting.png`}*/}
                        {/*            alt="VBoard"*/}
                        {/*            style={{ height: '15rem' }}*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*)}*/}
                    </div>
                    {value === data.currentPage ? (
                        <>
                            <div style={{ height: '2rem', position: 'absolute', top: '1rem', right: '1rem' }}>
                                <img
                                    src={`/static/asset/img/ChildWriting.png`}
                                    alt="VBoard"
                                    style={{ height: '4rem' }}
                                />
                            </div>

                            <div style={messageForCurrentPage}>
                                <Typography variant="h6">
                                    <FormattedMessage id="multiBoardNavigator.pageIsInEdit" />
                                </Typography>
                            </div>
                        </>
                    ) : null}

                    <div style={{ ...verticalCenteredDiv, position: 'absolute', right: '12px' }}>
                        <Fab
                            color="primary"
                            size="small"
                            disabled={value === dataItems.current.images.length - 1}
                            onClick={onNext}
                        >
                            <NavigateNext />
                        </Fab>
                    </div>
                </Grid>
            </Grid>
        );
    } else {
        return (
            <Grid container direction={'row'} justify={'center'} style={{ height: '100%' }}>
                <Grid item xs={12} style={{ textAlign: 'center', height: '100%' }}>
                    <div style={verticalCenteredDiv}>
                        <img
                            src={dataItems.current.images[value]}
                            alt="preview"
                            style={{ maxHeight: '75vh', maxWidth: '90vw', width: '100%' }}
                        />
                    </div>
                </Grid>
            </Grid>
        );
    }
});

export default PageNavigator;
