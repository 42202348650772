import BlueGroupIcon from '../../MyComponents/icons/BlueGroupIcon';
import OrangeGroupIcon from '../../MyComponents/icons/OrangeGroupIcon';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import store from '../../redux/store';
import { showMultiBoardLessonState } from '../../redux/actions';
import { FormattedMessage } from 'react-intl';
import EllipsisMenu from '../../MyComponents/EllipsisMenu/EllipsisMenu';
import React from 'react';
import { StyledBadge } from '../../MyComponents/StyledBadge/StyledBadge';
import { COLORS2 } from '../../constants';

export const buildDesktopMeta = (
    classes,
    lessonStart,
    profile,
    email,
    localesOptions,
    setModalItem,
    isHome = false
) => {
    let meta = [
        {
            header: 'redesign.generic.label.name',
            align: 'start',
            build: function (row) {
                return (
                    <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                        {row.isMine ? (
                            <StyledBadge badgeContent={row.requests != '0' ? row.requests : null}>
                                <span
                                    style={{
                                        boxSizing: 'border-box',
                                        width: '3rem',
                                        height: '3rem',
                                        background: 'rgba(8, 170, 180, 0.08)',
                                        borderRadius: '1rem',
                                    }}
                                >
                                    <BlueGroupIcon style={{ position: 'absolute', left: '0.75rem', top: '0.75rem' }} />
                                </span>
                            </StyledBadge>
                        ) : (
                            <StyledBadge badgeContent={row.requests != '0' ? row.requests : null}>
                                <span
                                    style={{
                                        boxSizing: 'border-box',
                                        width: '3rem',
                                        height: '3rem',
                                        border: '0.063rem solid #FBF0E9',
                                        borderRadius: '1rem',
                                    }}
                                >
                                    <OrangeGroupIcon
                                        style={{ position: 'absolute', left: '0.725rem', top: '0.7rem' }}
                                    />
                                </span>
                            </StyledBadge>
                        )}
                        <span
                            style={{
                                paddingLeft: '1rem',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                fontSize: '1.125rem',
                                lineHeight: '1.5 rem',
                                letterSpacing: '-0.01rem',
                                textTransform: 'none',
                                color: COLORS2.darkGreen,
                            }}
                        >
                            {row.name}
                        </span>
                    </span>
                );
            },
        },
        {
            header: 'redesign.generic.label.lastAccessed',
            align: 'middle',
            build: function (row) {
                return (
                    <span
                        style={{
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '1rem',
                            lineHeight: '1.5 rem',
                            letterSpacing: '-0.01rem',
                            textTransform: 'none',
                            color: COLORS2.virtualText,
                        }}
                    >
                        {new Date(row.lastaccess ? row.lastaccess : row.updatedAt).toLocaleDateString(localesOptions, {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                        })}
                    </span>
                );
            },
        },
        {
            header: 'redesign.generic.label.owner',
            align: 'middle',
            build: function (row) {
                let name = '';
                if (row.first_name) {
                    name += row.first_name + ' ';
                }
                if (row.last_name) {
                    name += row.last_name;
                }
                if (row.isMine) {
                    return (
                        <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                            <Avatar url={profile.avatar} />
                            <span
                                style={{
                                    paddingLeft: '1rem',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    fontSize: '1rem',
                                    lineHeight: '1.5 rem',
                                    letterSpacing: '-0.01rem',
                                    textTransform: 'none',
                                    color: COLORS2.darkGreen,
                                }}
                            >
                                {profile.name}
                            </span>
                        </div>
                    );
                } else {
                    return (
                        <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                            <Avatar url={row.avatar} />
                            <span
                                style={{
                                    paddingLeft: '1rem',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '1rem',
                                    lineHeight: '1.5 rem',
                                    letterSpacing: '-0.01rem',
                                    textTransform: 'none',
                                    color: COLORS2.virtualText,
                                }}
                            >
                                {name}
                            </span>
                        </div>
                    );
                }
            },
        },
        {
            header: 'redesign.table.header.empty',
            align: 'end',
            build: function (row) {
                if (!row.isMine) {
                    return (
                        <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                            {!isHome ? (
                                <Button
                                    color="default"
                                    size="large"
                                    onClick={() => {
                                        console.log('==== dispatch', {
                                            id: null,
                                            lessonId: row.classId,
                                            email: email,
                                            isOwner: false,
                                        });
                                        store.dispatch(
                                            showMultiBoardLessonState({
                                                id: null,
                                                lessonId: row.classId,
                                                email: email,
                                                isOwner: false,
                                            })
                                        );
                                    }}
                                >
                                    <FormattedMessage id="redesign.generic.label.view" />
                                </Button>
                            ) : null}
                            <Button
                                variant="contained"
                                color="default"
                                size="large"
                                onClick={() => {
                                    lessonStart(row.classId);
                                }}
                            >
                                <FormattedMessage id="generic.label.join" />
                            </Button>
                        </div>
                    );
                } else {
                    return (
                        <Button
                            variant="contained"
                            color="default"
                            size="large"
                            onClick={() => {
                                lessonStart(row.id);
                            }}
                        >
                            <FormattedMessage id={'redesign.generic.label.start'} />
                        </Button>
                    );
                }
            },
        },
    ];

    if (!isHome) {
        meta.push({
            header: 'redesign.table.header.empty',
            align: 'end',
            build: function (row) {
                if (row.isMine) {
                    return <EllipsisMenu row={row} setModalItem={setModalItem} />;
                }
            },
        });
    }
    return meta;
};
