import { Link } from 'react-router-dom';
import { SPECIAL_QUOTA_LINK } from '../../common/urlLinks';
import Button from '@material-ui/core/Button';
import { makeStyles, Typography } from '@material-ui/core';
import { COLORS2 } from '../../constants';
import { FormattedMessage } from 'react-intl';
import { ONBOARDING_STEPS } from '../../shared/OnboardingOptions';
import { SUBSCRIPTION_PLANS } from '../../common/utils';
import Grid from '@material-ui/core/Grid';
import React from 'react';

const useStyles = makeStyles(() => ({
    writeUs: {
        background: COLORS2.virtualBackground,
        boxShadow: '0rem 0rem 0rem 0.5rem rgba(8, 170, 180, 0.08)',
        borderRadius: '1rem',
        width: '23.188rem',
        height: '4.5rem',
    },
    tryPremium: {
        background: COLORS2.virtualGreen,
        boxShadow: '0rem 0rem 0rem 0.5rem rgba(8, 170, 180, 0.08)',
        borderRadius: '1rem',
        width: '23.188rem',
        height: '4.5rem',
        position: 'initial',
    },
    orText: {
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '0.875rem',
        lineHeight: '1rem',
        letterSpacing: '0.16rem',
        textTransform: 'uppercase',
        color: COLORS2.pattensBlue,
        textAlign: 'center',
        padding: '0rem 2rem',
    },
}));

export const LogOutSectionFooter = ({ handleOpen }) => {
    const classes = useStyles();

    const transitionToTop = () => {
        setTimeout(() => {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }, 200);
    };

    const setOnboardingPath = (path) => {
        window.localStorage.setItem('pathBeforeLogin', path);
    };

    return (
        <Grid
            item
            xs={12}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: '3.5rem',
            }}
        >
            <Link to={SPECIAL_QUOTA_LINK} onClick={transitionToTop}>
                <Button className={classes.writeUs}>
                    <Typography
                        style={{
                            fontStyle: 'normal',
                            fontWeight: '700',
                            fontSize: '0.875rem',
                            lineHeight: '1.063rem',
                            letterSpacing: '0.16rem',
                            textTransform: 'uppercase',
                            color: COLORS2.virtualGreen,
                            textAlign: 'center',
                        }}
                    >
                        <FormattedMessage id="specialQuota.buttonText" />
                    </Typography>
                </Button>
            </Link>
            <Typography className={classes.orText}>
                <FormattedMessage id="redesign.page.home.footer.or" />
            </Typography>
            <Button
                className={classes.tryPremium}
                onClick={() => {
                    transitionToTop();
                    handleOpen();
                    setOnboardingPath(
                        `/onboarding?step=${ONBOARDING_STEPS.START}&subscription=${SUBSCRIPTION_PLANS.TRIAL}`
                    );
                }}
            >
                <Typography
                    style={{
                        fontStyle: 'normal',
                        fontWeight: '700',
                        fontSize: '0.875rem',
                        lineHeight: '1.063rem',
                        letterSpacing: '0.16rem',
                        textTransform: 'uppercase',
                        color: COLORS2.third,
                        textAlign: 'center',
                    }}
                >
                    <FormattedMessage id="redesign.page.home.footer.tryPremium" />
                </Typography>
            </Button>
        </Grid>
    );
};
