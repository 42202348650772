import classApi from '../../Api/classApi';

import { v4 as uuidv4 } from 'uuid';
import { LINEAGE_NONE } from './drawConstants';
import drawUtils from './drawUtils';
import storageApi from '../../Api/storageApi';
import {
    addToWindowCache,
    getUploadUrlFromWindowCache,
    getUrlFromWindowCache,
    removeImageFromWindowCache,
} from '../../common/utils';
import boardManager from './boardManager';
import { STICKY_ELEMENTS_TYPE } from '../../constants';
import lessonList, { removeItem } from './lessonList';

let cloudBoardManager = {};

cloudBoardManager.getLessonPages = async function(email, classId) {
    let res = await classApi.getClassPages(classId);
    let pages = res.data;
    if (pages.length === 0) {
        let newID = uuidv4();
        await classApi.updateClassPage(classId, newID, {
            meta: { stickyElements: [], gridType: LINEAGE_NONE },
        });
        res = await classApi.getClassPages(classId);
        pages = res.data;
        if (drawUtils.getCanvas() && pages.length === 1) {
            let data = drawUtils.getCanvas().toDataURL('image/png', 1);
            await storageApi.uploadToUrl(pages[0].upload, data, newID);
        }
    }
    cloudBoardManager.addPagesToCache(pages, email, classId);
    return pages;
};

cloudBoardManager.addPagesToCache = (pages, email, classId) => {
    pages.forEach((page) => {
        syncPageMetaWithCache(email, classId, page);
        addToWindowCache(email, classId, page.id, page.id, page.download, page.upload);
    });
};

cloudBoardManager.saveCurrentMainPage = async function() {
    try {
        if (boardManager.mainBoard.isBusy) {
            console.error('Attempting to save while load is in progress');
            return;
        }
        if (!boardManager.mainBoard.isCurrentPageDirty) {
            return;
        }
        if (!boardManager.lessonState) return;
        let url = getUploadUrlFromWindowCache(
            boardManager.email,
            boardManager.classId,
            boardManager.lessonState.mainBoardId,
            boardManager.lessonState.mainBoardId,
        );
        removeImageFromWindowCache(
            getUrlFromWindowCache(
                boardManager.email,
                boardManager.classId,
                boardManager.lessonState.mainBoardId,
                boardManager.lessonState.mainBoardId,
            ),
        );
        if (drawUtils.getCanvas() && url) {
            let data = drawUtils.getCanvas().toDataURL('image/png', 1);
            if (!data) {
                console.error('Unable to get data for image to save');
                return;
            }
            await storageApi.uploadToUrl(url, data, boardManager.lessonState.mainBoardId);
        }
    } catch (err) {
        console.error('Error saving main page', boardManager.email, err);
    }
    boardManager.mainBoard.isCurrentPageDirty = false;
};

cloudBoardManager.saveCurrentMultiBoardUserPage = async function() {
    try {
        if (boardManager.lessonState.individualBoards[boardManager.adminEmail].isBusy) {
            console.error('Attempting to save while load is in progress in multiboard');
            return false;
        }
        if (!boardManager.lessonState.individualBoards[boardManager.adminEmail].isCurrentPageDirty) {
            // do not save if not needed
            return true;
        }

        if (
            boardManager.lessonState.individualBoards[boardManager.adminEmail]?.pages &&
            boardManager.lessonState.individualBoards[boardManager.adminEmail]?.pages.length > 0
        ) {
            let url =
                boardManager.lessonState.individualBoards[boardManager.adminEmail]?.pages[
                    boardManager.lessonState.individualBoards[boardManager.adminEmail].currentPageNo
                    ].upload;
            let cnv = drawUtils.getCanvas(boardManager.adminEmail);
            if (cnv && url) {
                let data = cnv.toDataURL('image/png', 1);
                if (!data) {
                    console.error('Unable to get data for image to save');
                    return false;
                }
                await storageApi.uploadToUrl(
                    url,
                    data,
                    boardManager.lessonState.individualBoards[boardManager.adminEmail]?.pages[
                        boardManager.lessonState.individualBoards[boardManager.adminEmail].currentPageNo
                        ].id,
                );
                boardManager.lessonState.individualBoards[boardManager.adminEmail].isCurrentPageDirty = false;
                return true;
            }
        }
        return false;
    } catch (err) {
        console.error('Error saving multiboard page', boardManager.email, err);
        return false;
    }
};

cloudBoardManager.newPage = async function(email, classId) {
    let newID = uuidv4();
    let res = await classApi.updateClassPage(classId, newID, {
        meta: { stickyElements: [], gridType: LINEAGE_NONE },
    });
    let pages = res.data;
    if (drawUtils.getCanvas() && pages.length === 1) {
        let data = drawUtils.getCanvas().toDataURL('image/png', 1);
        await storageApi.uploadToUrl(pages[0].upload, data, newID);
    }
    pages.forEach((page) => {
        syncPageMetaWithCache(email, classId, page);
        addToWindowCache(email, classId, page.id, page.id, page.download, page.upload);
    });
    return pages;
};

cloudBoardManager.updatePage = async function(email, classId, pageId, meta) {
    //TODO - resync in case of error
    let res = await classApi.updateClassPage(classId, pageId, { meta });
    let pages = res.data;
    pages.forEach((page) => {
        syncPageMetaWithCache(email, classId, page);
        addToWindowCache(email, classId, page.id, page.id, page.download, page.upload);
    });
    return pages;
};

cloudBoardManager.removePage = async function(email, classId, pageId) {
    let res = await classApi.removeClassPage(classId, pageId);
    lessonList.removeItem(pageId);
    let pages = res.data;
    pages.forEach((page) => {
        syncPageMetaWithCache(email, classId, page);
        addToWindowCache(email, classId, page.id, page.id, page.download, page.upload, true);
    });
    return pages;
};

cloudBoardManager.getMultiBoardUserPages = async function(email, classId, multiBoardId, adminEmail) {
    let res = await classApi.getMultiBoardUserPages(classId, multiBoardId, email);
    let pages = res.data.pages;
    pages.forEach((page) => {
        addToWindowCache(adminEmail, classId, page.id, page.id, page.download, page.upload);
    });
    return res.data;
};

cloudBoardManager.newMultiBoardPage = async function(
    email,
    classId,
    multiBoardId,
    adminEmail,
    lineage = LINEAGE_NONE,
) {
    let newID = uuidv4();
    let res = await classApi.updateMultiBoardUserPageByUser(classId, multiBoardId, newID, {
        email,
        meta: { gridType: lineage, stickyElements: [] },
    });
    let pages = res.data;
    pages.forEach((page) => {
        addToWindowCache(adminEmail, classId, page.id, page.id, page.download, page.upload);
    });
    return pages;
};

cloudBoardManager.updateMultiBoardPage = async function(email, classId, multiBoardId, adminEmail, pageId, meta) {
    let res = await classApi.updateMultiBoardUserPageByUser(classId, multiBoardId, pageId, {
        email,
        meta,
    });
    let pages = res.data;
    pages.forEach((page) => {
        addToWindowCache(adminEmail, classId, page.id, page.id, page.download, page.upload);
    });
    return pages;
};

function syncPageMetaWithCache(email, classId, page) {
    if (page.meta && page.meta.stickyElements) {
        page.meta.stickyElements.forEach((stickyElem) => {
            if (stickyElem.stickyType === STICKY_ELEMENTS_TYPE.IMAGE) {
                let url = getUrlFromWindowCache(email, classId, page.id, stickyElem.id);
                if (url) {
                    stickyElem.url = url;
                } else {
                    addToWindowCache(email, classId, page.id, stickyElem.id, stickyElem.url, stickyElem.url);
                }
            }
        });
    }
}

export default cloudBoardManager;
