import React from 'react';
import { YOUTUBE_CLIPS } from '../constants';
import Divider from '@material-ui/core/Divider';
import SectionHeader from '../SectionHeader';
import AudioVideoManagement from './LessonManagementSections/AudioVideoManagement';
import MainMenuManagement from './LessonManagementSections/MainMenuManagement';
import InstrumentManagement from './LessonManagementSections/InstrumentManagement';
import ImageMenuManagement from './LessonManagementSections/ImageMenuManagement';
import LineageManagement from './LessonManagementSections/LineageManagement';
import BoardManagement from './LessonManagementSections/BoardManagement';
import ZoomManagement from './LessonManagementSections/ZoomManagement';
import GeometryManagement from './LessonManagementSections/GeometryManagement';
import MathConvertorManagement from './LessonManagementSections/MathConvertorManagement';

const LessonManagement = ({ language, classes, refs }) => {
    return (
        <>
            <SectionHeader
                refs={refs}
                title={'tutorial.manageLesson.title'}
                description={'tutorial.manageLesson.description'}
                id={'section-manageLesson'}
                youtubeSrc={YOUTUBE_CLIPS.AUDIO_VIDEO[language.toUpperCase()]}
                imageTop={false}
            />
            <Divider variant="middle" />
            <AudioVideoManagement language={language} classes={classes} refs={refs} />
            <Divider variant="middle" />
            <MainMenuManagement language={language} classes={classes} refs={refs} />
            <Divider variant="middle" />
            <InstrumentManagement language={language} classes={classes} refs={refs} />
            <Divider variant="middle" />
            <GeometryManagement language={language} classes={classes} refs={refs} />
            <Divider variant="middle" />
            <MathConvertorManagement language={language} classes={classes} refs={refs} />
            <Divider variant="middle" />
            <ImageMenuManagement language={language} classes={classes} refs={refs} />
            <Divider variant="middle" />
            <LineageManagement language={language} classes={classes} refs={refs} />
            <Divider variant="middle" />
            <BoardManagement language={language} classes={classes} refs={refs} />
            <Divider variant="middle" />
            <ZoomManagement language={language} classes={classes} refs={refs} />
        </>
    );
};

export default LessonManagement;
