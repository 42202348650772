import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function IndividualBoardActivityIcon(props) {
    return (
        <SvgIcon {...props} style={{ verticalAlign: 'bottom' }}>
            <svg viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5 15C9.98531 15 12 12.9853 12 10.5C12 8.01469 9.98531 6 7.5 6C4.97344 6 3 7.97344 3 10.5C3 12.9844 4.97344 15 7.5 15ZM7.5 7.5C9.15422 7.5 10.5 8.84578 10.5 10.5C10.5 12.1542 9.15469 13.5 7.5 13.5C5.84531 13.5 4.5 12.1547 4.5 10.5C4.5 8.84531 5.84531 7.5 7.5 7.5ZM9 16.5H6C2.68641 16.5 0 19.1864 0 22.5C0 23.3283 0.671719 24 1.5 24H13.5C14.3283 24 15 23.3283 15 22.5C15 19.1859 12.3141 16.5 9 16.5ZM1.5 22.5C1.5 20.0184 3.51891 18 6 18H9C11.4816 18 13.5 20.0184 13.5 22.5H1.5ZM27.75 0H9.75C8.50781 0 7.5 1.00922 7.5 2.25V3.75C7.5 4.16437 7.8375 4.5 8.25 4.5C8.6625 4.5 9 4.16437 9 3.75V2.25C9 1.83703 9.3375 1.5 9.75 1.5H27.75C28.1625 1.5 28.5 1.83703 28.5 2.25V17.25C28.5 17.6631 28.1638 18 27.75 18H27V15.75C27 14.5078 25.9922 13.5 24.75 13.5H20.25C19.0078 13.5 18 14.5078 18 15.75V18H16.5C16.0854 18 15.75 18.3354 15.75 18.75C15.75 19.1646 16.0875 19.5 16.5 19.5H27.75C28.9922 19.5 30 18.4922 30 17.25V2.25C30 1.00922 28.9922 0 27.75 0ZM25.5 18H19.5V15.75C19.5 15.3369 19.8362 15 20.25 15H24.75C25.1638 15 25.5 15.3369 25.5 15.75V18Z" fill="#08AAB4"/>
            </svg>
        </SvgIcon>
    );
}
