import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import { COLORS2 } from '../../constants';

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: COLORS2.virtualGreen,
        color: 'rgba(255, 255, 255, 0.95)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(16),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

export default function VBToolTip(props) {
    return (
        <HtmlTooltip
            title={
                <Typography variant="h5" style={{ margin: '0rem 0rem 0.2rem 0rem' }}>
                    {props.content}
                </Typography>
            }
            {...props}
        >
            <span>{props.children}</span>
        </HtmlTooltip>
    );
}
