import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Grid, Paper, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import { composePriceText, featureList, replaceValues } from '../../../common/features';
import { COLORS } from '../../../constants';
import { Link } from 'react-router-dom';
import { SUBSCRIPTIONS_LINK } from '../../../common/urlLinks';
import { GA_EVENT_TREE } from '../../../gaConstants';
import SubscriptionListRenderer from '../../Subscriptions/SubscriptionListRenderer';
import { CustomButtonGroupCurr } from '../../../MyComponents/ButtonGroupSubscriptions/CustomButtonGroupCurr';
import { CustomButtonGroupMonthly } from '../../../MyComponents/ButtonGroupSubscriptions/CustomButtonGroupMonthly';
import Hidden from '@material-ui/core/Hidden';

const PaidStage = ({ intl, subscriptions, state, pricing }) => {
    const [billAnnual, setBillAnnual] = useState(true);
    const [billEur, setBillEur] = useState(true);

    useEffect(() => {
        setBillAnnual(state.billAnnual);
        setBillEur(state.billEur);
    }, [state]);

    useEffect(() => {
        if (state.subscription === 'BASIC') {
            window.logAppActivity(GA_EVENT_TREE.mainPage.onboarding.startBasic);
        }
        if (state.subscription === 'PREMIUM') {
            window.logAppActivity(GA_EVENT_TREE.mainPage.onboarding.startPremium);
        }
    }, []);

    const handleChange = () => {
        setBillAnnual(!billAnnual);
        if (billAnnual) {
            window.logAppActivity(GA_EVENT_TREE.mainPage.onboarding.checkAnnual);
        } else {
            window.logAppActivity(GA_EVENT_TREE.mainPage.onboarding.checkMonthly);
        }
    };

    const handleCurrencySelect = () => {
        setBillEur(!billEur);
        if (billEur) {
            window.logAppActivity(GA_EVENT_TREE.mainPage.onboarding.checkEur);
        } else {
            window.logAppActivity(GA_EVENT_TREE.mainPage.onboarding.checkRon);
        }
    };

    if (!subscriptions || !state || !subscriptions[state.subscription]) {
        return null;
    }

    let myFeatureList = cloneDeep(featureList);
    replaceValues(subscriptions[state.subscription], myFeatureList);

    let priceText = composePriceText(intl, pricing, state.subscription, billAnnual, billEur);
    if (!priceText) {
        return null;
    }

    return (
        <Grid container spacing={10} style={{ padding: '1.5rem' }}>
            <Hidden only={['xs']}>
                <Grid item xs={12} sm={12} style={{ textAlign: 'center', marginTop: '5rem', backgroundColor: COLORS.primaryL2, display: 'flex', flexDirection: 'row' }} >
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h2">
                            <FormattedMessage id={`subscriptions.subscription.${state.subscription}.name`} />
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ display: 'flex', alignItems: 'center' }}>
                        <form
                            action={`/api/private/profile/checkout?subscriptionType=${state.subscription}&currency=${
                                billEur ? 'eur' : 'ron'
                            }&interval=${billAnnual ? 'year' : 'month'}`}
                            method="POST"
                        >
                            <Button type="submit" variant={'contained'} color={'primary'}>
                                <FormattedMessage id="redesign.paidStage.pay" />
                            </Button>
                        </form>
                        <Link to={SUBSCRIPTIONS_LINK} style={{ marginLeft: '2rem' }}>
                            <Button variant={'contained'} color={'primary'}>
                                <FormattedMessage id="onboarding.chooseAnother" />
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
            </Hidden>
            <Hidden only={['sm','md','lg','xl']}>
                <Grid item xs={12} sm={12} style={{ textAlign: 'center', marginTop: '5rem', backgroundColor: COLORS.primaryL2, flexDirection: 'row' }} >
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h2">
                            <FormattedMessage id={`subscriptions.subscription.${state.subscription}.name`} />
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '1rem' }}>
                        <form
                            action={`/api/private/profile/checkout?subscriptionType=${state.subscription}&currency=${
                                billEur ? 'eur' : 'ron'
                            }&interval=${billAnnual ? 'year' : 'month'}`}
                            method="POST"
                        >
                            <Button type="submit" variant={'contained'} color={'primary'}>
                                <FormattedMessage id="redesign.paidStage.pay" />
                            </Button>
                        </form>
                        <Link to={SUBSCRIPTIONS_LINK} style={{ marginLeft: '2rem' }}>
                            <Button variant={'contained'} color={'primary'}>
                                <FormattedMessage id="onboarding.chooseAnother" />
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
            </Hidden>
            <Grid
                item
                xs={12}
                md={4}
                style={{
                    width: '100%',
                    marginTop: '1rem',
                    textAlign: 'center',
                }}
            >
                <Grid item xs={12} sm={12}>
                    <CustomButtonGroupMonthly billAnnual={billAnnual} handleChange={handleChange} />
                </Grid>
                <Grid item xs={12} sm={12} style={{ marginTop : '2rem' }}>
                    <CustomButtonGroupCurr billEur={billEur} handleCurrencySelect={handleCurrencySelect} />
                </Grid>
            </Grid>
            <Grid item xs={12} md={8} style={{ textAlign: 'center' }}>
                <Paper
                    style={{
                        background: 'rgb(6, 81, 86)',
                        boxShadow:
                            '0rem 0rem 0rem 0.5rem rgba(8, 170, 180, 0.16), 0rem 0.5rem 0.5rem rgba(2, 35, 37, 0.32)',
                        borderRadius: '2rem',
                        paddingTop: '1rem',
                    }}
                    elevation={0}
                >
                    <Typography variant="h4">{priceText}</Typography>
                    <SubscriptionListRenderer featureList={myFeatureList} isActive={false} />
                </Paper>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => {
    const { mainAppState } = state;
    return { subscriptions: mainAppState.subscriptions, pricing: mainAppState.pricing };
};

export default injectIntl(connect(mapStateToProps)(PaidStage));
