import {
    UPDATE_PROFILE,
    CHANGE_TOOL,
    CHANGE_COLOR,
    CHANGE_TOOL_WEIGHT,
    SHOW_LOADING,
    HIDE_LOADING,
    SHOW_INFO,
    SHOW_ERROR,
    SHOW_SUCCESS,
    SHOW_WARNING,
    HIDE_NOTIFICATION,
    START_LESSON,
    JOIN_LESSON,
    USER_JOIN,
    USER_LEAVE,
    END_LESSON,
    USER_TOGGLE_DRAW,
    SHARE_LESSON,
    USER_JOIN_REQUEST,
    CURRENT_REQUEST_COUNT,
    UPDATE_EXTENDED_PROFILE,
    CHANGE_TEXT_WEIGHT,
    CHANGE_ERASER_WEIGHT,
    LESSON_INFO,
    RAISE_HAND,
    USER_RAISE_HAND,
    MUTE,
    USER_MUTE,
    LOAD_LESSON_PAGES,
    SWITCH_LESSON_PAGE,
    CREATE_LESSON_PAGE,
    PICK_LESSON_PAGE,
    REQUEST_FULL_SCREEN,
    ALLOW_DRAWING,
    CHANGE_CALL_STATE,
    UPDATE_SOCKET_ID,
    SWITCH_BOARD_TYPE,
    UPDATE_MULTI_BOARDS,
    MULTI_BOARD_LESSON_STATE,
    TOGGLE_VIDEO,
    TOGGLE_USERS_VIDEO,
    CALL_IN_PROGRESS,
    OFFLINE_USERS,
    NEW_FEATURE,
    SHOW_STATIC_PAGE_NAVIGATOR,
    UPDATE_USER_PREFERENCES,
    UPDATE_USER_SUBSCRIPTION,
    UPDATE_APP_SUBSCRIPTION,
    UPDATE_ALLOWED_GROUPS,
    UPDATE_ALLOWED_BOARDS,
    UPGRADE_SUBSCRIPTION,
    UPDATE_USER_MESSAGES,
    UPDATE_LESSON_OPEN_JOIN_REQUESTS,
    UPDATE_LATEX_TEXT,
    LIBRARY_ALREADY_SHARED,
    ENTER_VALID_EMAIL,
    ENTER_OWN_EMAIL,
    SHOW_CONSENT,
    UPDATE_USER_CONSENT,
    CHANGE_PREFERENCES,
    USER_CAN_DRAW,
} from './actionTypes';

export const updateProfile = (content) => ({
    type: UPDATE_PROFILE,
    payload: content,
});

export const updateExtendedProfile = (content) => ({
    type: UPDATE_EXTENDED_PROFILE,
    payload: content,
});

export const updateUserPreferences = (content) => ({
    type: UPDATE_USER_PREFERENCES,
    payload: content,
});

export const updateUserSubscription = (content) => ({
    type: UPDATE_USER_SUBSCRIPTION,
    payload: content,
});

export const updateUserConsent = (content) => ({
    type: UPDATE_USER_CONSENT,
    payload: content,
});

export const updateAppSubscription = (content) => ({
    type: UPDATE_APP_SUBSCRIPTION,
    payload: content,
});

export const updateAllowedGroups = (content) => ({
    type: UPDATE_ALLOWED_GROUPS,
    payload: content,
});

export const updateAllowedBoards = (content) => ({
    type: UPDATE_ALLOWED_BOARDS,
    payload: content,
});

export const changeColor = (content) => ({
    type: CHANGE_COLOR,
    payload: content,
});

export const changeTool = (content) => ({
    type: CHANGE_TOOL,
    payload: content,
});

export const changeToolWeight = (content) => ({
    type: CHANGE_TOOL_WEIGHT,
    payload: content,
});

export const changeTextWeight = (content) => ({
    type: CHANGE_TEXT_WEIGHT,
    payload: content,
});

export const changeEraserWeight = (content) => ({
    type: CHANGE_ERASER_WEIGHT,
    payload: content,
});

export const showSpinner = (data) => ({
    type: SHOW_LOADING,
    payload: data,
});

export const hideSpinner = () => ({
    type: HIDE_LOADING,
});

export const showError = (messageKey) => ({
    type: SHOW_ERROR,
    payload: messageKey,
});

export const showInfo = (messageKey, isKey = true, data) => {
    if (isKey) {
        return {
            type: SHOW_INFO,
            payload: messageKey,
        };
    } else {
        return {
            type: SHOW_INFO,
            payload: {
                messageKey,
                data,
            },
        };
    }
};

export const showWarn = (messageKey, isKey = true, data) => {
    if (isKey) {
        return {
            type: SHOW_WARNING,
            payload: messageKey,
        };
    } else {
        return {
            type: SHOW_WARNING,
            payload: {
                messageKey,
                data,
            },
        };
    }
};

export const showSuccess = (messageKey) => ({
    type: SHOW_SUCCESS,
    payload: messageKey,
});

export const hideNotification = () => ({
    type: HIDE_NOTIFICATION,
});

export const startLesson = (classId, members) => ({
    type: START_LESSON,
    payload: { classId, members },
});

export const joinLesson = (classId, members) => ({
    type: JOIN_LESSON,
    payload: { classId, members },
});

export const userLeave = (email) => ({
    type: USER_LEAVE,
    payload: email,
});

export const userToggleDraw = (email) => ({
    type: USER_TOGGLE_DRAW,
    payload: email,
});

export const userCanDraw = (email, canDraw) => ({
    type: USER_CAN_DRAW,
    payload: { email, canDraw },
});

export const userJoin = (data) => ({
    type: USER_JOIN,
    payload: data,
});

export const offlineUsers = (data) => ({
    type: OFFLINE_USERS,
    payload: data,
});

export const endLesson = () => ({
    type: END_LESSON,
});

export const shareLesson = (data) => ({
    type: SHARE_LESSON,
    payload: data,
});

export const upgradeSubscriptionDialog = (data) => ({
    type: UPGRADE_SUBSCRIPTION,
    payload: data,
});

export const userJoinRequest = (data) => ({
    type: USER_JOIN_REQUEST,
    payload: data,
});

export const currentRequestCount = (data) => ({
    type: CURRENT_REQUEST_COUNT,
    payload: data,
});

export const lessonInfo = (data) => ({
    type: LESSON_INFO,
    payload: data,
});

export const raiseHand = () => ({
    type: RAISE_HAND,
});

export const userRaiseHand = (data) => ({
    type: USER_RAISE_HAND,
    payload: data,
});

export const mute = (data) => ({
    type: MUTE,
    payload: data,
});

export const updateUserOpenJoinRequests = (data) => ({
    type: UPDATE_LESSON_OPEN_JOIN_REQUESTS,
    payload: data,
});

export const toggleVideo = (data) => ({
    type: TOGGLE_VIDEO,
    payload: data,
});

export const toggleUsersVideo = (data) => ({
    type: TOGGLE_USERS_VIDEO,
    payload: data,
});

export const callInProgress = (data) => ({
    type: CALL_IN_PROGRESS,
    payload: data,
});

export const changeCallState = (data) => ({
    type: CHANGE_CALL_STATE,
    payload: data,
});

export const userMute = (data) => ({
    type: USER_MUTE,
    payload: data,
});

export const loadLessonPages = (data) => ({
    type: LOAD_LESSON_PAGES,
    payload: data,
});

export const switchLessonPage = (data) => ({
    type: SWITCH_LESSON_PAGE,
    payload: data,
});

export const updateSocketId = (data) => ({
    type: UPDATE_SOCKET_ID,
    payload: data,
});

export const switchBoardType = (data) => ({
    type: SWITCH_BOARD_TYPE,
    payload: data,
});

export const createLessonPage = (data) => ({
    type: CREATE_LESSON_PAGE,
    payload: data,
});

export const pickLessonPage = (data) => ({
    type: PICK_LESSON_PAGE,
    payload: data,
});

export const requestFullScreen = (data) => ({
    type: REQUEST_FULL_SCREEN,
    payload: data,
});

export const updateMultiBoards = (data) => ({
    type: UPDATE_MULTI_BOARDS,
    payload: data,
});

export const showMultiBoardLessonState = (data) => ({
    type: MULTI_BOARD_LESSON_STATE,
    payload: data,
});

export const allowDrawing = (data) => ({
    type: ALLOW_DRAWING,
    payload: data,
});

export const showNewFeature = (data) => ({
    type: NEW_FEATURE,
    payload: data,
});

export const showStaticPageNavigator = (data) => ({
    type: SHOW_STATIC_PAGE_NAVIGATOR,
    payload: data,
});

export const updateUserMessages = (data) => ({
    type: UPDATE_USER_MESSAGES,
    payload: data,
});

export const updateLatexText = (data) => ({
    type: UPDATE_LATEX_TEXT,
    payload: data,
});

export const infoAlreadyShared = (value) => ({
    type: LIBRARY_ALREADY_SHARED,
    payload: value,
});

export const enterValidEmail = (value) => ({
    type: ENTER_VALID_EMAIL,
    payload: value,
});

export const enterOwnEmail = (value) => ({
    type: ENTER_OWN_EMAIL,
    payload: value,
});

export const showConsent = (value) => ({
    type: SHOW_CONSENT,
    payload: value,
});

export const changePreferences = (value) => ({
    type: CHANGE_PREFERENCES,
    payload: value,
});
