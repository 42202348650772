import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import PriceRender from '../PriceRender';
import { ONBOARDING_STEPS } from '../../../shared/OnboardingOptions';
import { SUBSCRIPTION_PLANS, SUBSCRIPTION_STATE_ACTION } from '../../../shared/Capabilities';
import SubscriptionListRendererDesktop from './SubscriptionListRendererDesktop';
import { makeStyles } from '@material-ui/core';
import { COLORS2 } from '../../../constants';

const useStyles = makeStyles({
    subscriptionButtonUpdate: {
        border: '1px solid rgba(255, 255, 255, 0.24)',
        borderRadius: '1rem',
        height: '4.5rem',
        backgroundColor: 'rgba(255, 255, 255, 0.08)',
        boxShadow: 'none',
        color: '#FFFFFF',
    },
    subscriptionButtonUpdateLoggedIn: {
        border: '1px solid rgba(6, 81, 86, 0.24)',
        borderRadius: '1rem',
        height: '4.5rem',
        backgroundColor: 'rgba(6, 81, 86, 0.08)',
        boxShadow: 'none',
        color: COLORS2.darkGreen,
    },
    subscriptionButton: {
        border: '1px solid rgba(255, 255, 255, 0.24)',
        borderRadius: '1rem',
        height: '4.5rem',
        backgroundColor: 'rgba(255, 255, 255, 0.08)',
        boxShadow: 'none',
    },
    subscriptionButtonLoggedIn: {
        border: '1px solid rgba(6, 81, 86, 0.24)',
        borderRadius: '1rem',
        height: '4.5rem',
        backgroundColor: 'rgba(6, 81, 86, 0.08)',
        boxShadow: 'none',
        color: 'white',
    },
    subscriptionButtonIsActive: {
        border: '1px solid rgba(255, 255, 255, 0.24)',
        borderRadius: '1rem',
        height: '4.5rem',
        backgroundColor: COLORS2.blueStone,
        boxShadow: 'none',
    },
    priceContainer: {
        width: '100%',
        textAlign: 'center',
        paddingTop: '0.5rem',
        borderRadius: '2rem',
    },
});

const DesktopSubscriptionFeatures = ({
    profile,
    featureList,
    pricing,
    subscriptionType,
    billAnnual,
    billEur,
    subscriptionState,
    canUpgrade,
    isActive,
    isDesktop,
    handleOpen,
}) => {
    const classes = useStyles();

    if (!profile || !featureList) {
        return null;
    }

    let buttonMessageSubscription = 'redesign.subscriptions.subscription.start';
    switch (subscriptionType) {
        case SUBSCRIPTION_PLANS.FREE:
            buttonMessageSubscription = 'redesign.subscriptions.subscription.startForFree';
            break;
        case SUBSCRIPTION_PLANS.BASIC:
            buttonMessageSubscription = 'redesign.subscriptions.subscription.start';
            break;
        case SUBSCRIPTION_PLANS.PREMIUM:
            buttonMessageSubscription = 'redesign.subscriptions.subscription.start';
            break;
        default:
    }

    const transitionToTop = () => {
        setTimeout(() => {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }, 200);
    };

    const setOnboardingPath = (path) => {
        window.localStorage.setItem('pathBeforeLogin', path);
    };

    const buildButton = () => {
        if (
            subscriptionState === SUBSCRIPTION_STATE_ACTION.ACTIVE ||
            subscriptionState === SUBSCRIPTION_STATE_ACTION.NONE
        ) {
            return null;
        }
        if (subscriptionState === SUBSCRIPTION_STATE_ACTION.START) {
            return (
                <Link
                    to={`/onboarding?step=${ONBOARDING_STEPS.START}&subscription=${subscriptionType}&billAnnual=${billAnnual}&billEur=${billEur}`}
                >
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        className={
                            profile.loggedIn
                                ? isActive
                                    ? classes.subscriptionButtonIsActive
                                    : classes.subscriptionButtonLoggedIn
                                : isActive
                                ? classes.subscriptionButtonIsActive
                                : classes.subscriptionButton
                        }
                        onClick={() => {
                            transitionToTop();
                            if (handleOpen) {
                                handleOpen();
                            }
                            setOnboardingPath(
                                `/onboarding?step=${ONBOARDING_STEPS.START}&subscription=${subscriptionType}&billAnnual=${billAnnual}&billEur=${billEur}`
                            );
                        }}
                    >
                        <FormattedMessage id={buttonMessageSubscription} />
                    </Button>
                </Link>
            );
        }
        if (subscriptionState === SUBSCRIPTION_STATE_ACTION.CANCEL) {
            if (!profile.subscription.canceledAt) {
                return (
                    <Link
                        to={`/onboarding?step=${ONBOARDING_STEPS.CANCEL}&subscription=${subscriptionType}&billAnnual=${billAnnual}&billEur=${billEur}`}
                    >
                        <Button
                            variant={'contained'}
                            color={'secondary'}
                            onClick={() => {
                                transitionToTop();
                                if (handleOpen) {
                                    handleOpen();
                                }
                                setOnboardingPath(
                                    `/onboarding?step=${ONBOARDING_STEPS.CANCEL}&subscription=${subscriptionType}&billAnnual=${billAnnual}&billEur=${billEur}`
                                );
                            }}
                        >
                            <FormattedMessage id={'subscriptions.subscription.cancel'} />
                        </Button>
                    </Link>
                );
            } else {
                return (
                    <Link
                        to={`/onboarding?step=${ONBOARDING_STEPS.START}&subscription=${subscriptionType}&billAnnual=${billAnnual}&billEur=${billEur}`}
                    >
                        <Button
                            variant={'contained'}
                            className={
                                profile.loggedIn
                                    ? classes.subscriptionButtonUpdateLoggedIn
                                    : classes.subscriptionButtonUpdate
                            }
                            onClick={() => {
                                transitionToTop();
                                if (handleOpen) {
                                    handleOpen();
                                }
                                setOnboardingPath(
                                    `/onboarding?step=${ONBOARDING_STEPS.START}&subscription=${subscriptionType}&billAnnual=${billAnnual}&billEur=${billEur}`
                                );
                            }}
                        >
                            <FormattedMessage id={'subscriptions.subscription.upgrade'} />
                        </Button>
                    </Link>
                );
            }
        }
        if (subscriptionState === SUBSCRIPTION_STATE_ACTION.UPGRADE) {
            if (canUpgrade) {
                return (
                    <Link
                        to={`/onboarding?step=${ONBOARDING_STEPS.START}&subscription=${subscriptionType}&billAnnual=${billAnnual}&billEur=${billEur}`}
                    >
                        <Button
                            variant={'contained'}
                            className={
                                profile.loggedIn
                                    ? classes.subscriptionButtonUpdateLoggedIn
                                    : classes.subscriptionButtonUpdate
                            }
                            onClick={() => {
                                transitionToTop();
                                if (handleOpen) {
                                    handleOpen();
                                }
                                setOnboardingPath(
                                    `/onboarding?step=${ONBOARDING_STEPS.START}&subscription=${subscriptionType}&billAnnual=${billAnnual}&billEur=${billEur}`
                                );
                            }}
                        >
                            <FormattedMessage id={'subscriptions.subscription.upgrade'} />
                        </Button>
                    </Link>
                );
            } else {
                return (
                    <Typography style={{ fontSize: '0.8rem', paddingLeft: '1rem', paddingRight: '1rem' }}>
                        <FormattedMessage id="subscriptions.subscription.mustCancel" />
                    </Typography>
                );
            }
        }
        return null;
    };

    return (
        <>
            <div style={{ height: profile.loggedIn ? '15rem' : '20rem' }}>
                <div className={classes.priceContainer}>
                    <div style={{ borderRadius: '10px 10px 0px 0px' }}>
                        <Typography
                            variant={'h3'}
                            style={{
                                height: '4rem',
                                paddingTop: '1rem',
                                color: !profile.loggedIn
                                    ? isActive
                                        ? COLORS2.darkGreen
                                        : '#FFFFFF'
                                    : COLORS2.darkGreen,
                                fontWeight: isActive ? 'bold' : 'normal',
                            }}
                        >
                            <FormattedMessage id={`subscriptions.subscription.${subscriptionType}.name`} />
                        </Typography>
                    </div>
                    <PriceRender
                        pricing={pricing}
                        subscriptionType={subscriptionType}
                        billAnnual={billAnnual}
                        billEur={billEur}
                        isActive={isActive}
                        isDesktop={isDesktop}
                    />
                </div>
                <div style={{ width: '100%', textAlign: 'center' }}>{buildButton()}</div>
            </div>
            <SubscriptionListRendererDesktop featureList={featureList} isActive={isActive} />{' '}
        </>
    );
};

const mapStateToProps = (state) => {
    const { profile } = state;
    return { profile };
};

export default connect(mapStateToProps)(DesktopSubscriptionFeatures);
