import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function CheckSimpleIcon(props) {
    return (
        <SvgIcon {...props} style={{ verticalAlign: 'bottom' }}>
            <svg viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M23.7797 0.720315C24.075 1.01094 24.075 1.48906 23.7797 1.77969L8.02969 17.5297C7.73906 17.825 7.26094 17.825 6.97031 17.5297L0.219656 10.7797C-0.0732187 10.4891 -0.0732187 10.0109 0.219656 9.72031C0.512344 9.425 0.987188 9.425 1.28016 9.72031L7.5 15.9406L22.7203 0.720315C23.0109 0.426877 23.4891 0.426877 23.7797 0.720315Z" fill="#08AAB4"/>
            </svg>
        </SvgIcon>
    );
}
