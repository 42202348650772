import React from 'react';
import ModalContext from './ModalContext';

const ModalProvider = (props) => {

    const [openProvider, setOpenProvider] = React.useState(false);

    const handleOpenProvider = () => {
        setOpenProvider(true);
    };

    const handleCloseProvider = () => {
        setOpenProvider(false);
    };

    const modalContext = {
        open: openProvider,
        handleOpen: handleOpenProvider,
        handleClose: handleCloseProvider,
    };

    return (
        <ModalContext.Provider value={modalContext}>
            {props.children}
        </ModalContext.Provider>
    );
};

export default ModalProvider;