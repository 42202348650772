import { makeStyles } from '@material-ui/core';

const styles = makeStyles({
    modal: {
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'center',
        borderRadius: '2rem',
        boxShadow: 'rgba(8, 170, 180, 0.16) 0rem 0rem 0rem 0.5rem, rgba(255, 255, 255, 0) 0rem 2rem 6rem',
        backgroundColor: '#FFFFFF',
        padding: '1rem',
        minHeight: '35vh',
    },
    mobileBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1rem',
    },
    modalStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    dividerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    outlinedInput: {
        borderColor: 'red',
        borderRadius: '2rem',
        borderWidth: '2rem',
    },
    chipContainer: {
        alignContent: 'flex-start',
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
        height: '100%',
        overflowY: 'auto',
    },
    chipContainerEmpty: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
        height: '100%',
        overflowY: 'auto',
        alignItems: 'center',
        justifyContent: 'center',
    },
    chipContainerMobile: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
        justifyContent: 'flex-start',
        maxHeight: 'calc(30vh - 80px)',
        overflowY: 'auto',
    },
    chip: {
        backgroundColor: '#E0E0E0',
        color: 'rgba(0, 0, 0, 0.87)',
        display: 'flex',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    buttonMobile: {
        borderRadius: '0rem 1.75rem 1.75rem 0rem',
        height: '3.5rem',
        marginRight: '-0.9rem',
        boxShadow: 'none',
    },
    iconSm: {
        fontSize: '2rem',
        fontWeight: '400',
        marginRight: '0.5rem',
    },
});

export default styles;
