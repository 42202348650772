import axios from 'axios';
import apiUtil from './apiUtil';
import { determineDefaultLanguage } from '../common/utils';

let userApi = {};

userApi.getProfile = function() {
    return axios.get(`/api/private/profile`);
};

userApi.login = function(path, query) {
    return axios.get('/api' + path + query);
};

userApi.loginApi = function(url) {
    return axios.get(url);
};

userApi.getSocketToken = function() {
    return apiUtil.runRequest('/api/private/sockettoken');
};

userApi.getExtendedProfile = function() {
    let currentLanguage = determineDefaultLanguage();
    return apiUtil.runRequest(`/api/private/profile/extended?lang=${currentLanguage}`);
};

userApi.getUserMessages = function() {
    return apiUtil.runRequest(`/api/private/profile/messages`);
};

userApi.deleteUserMessage = function(id) {
    let myData = {
        url: `/api/private/profile/messages/${id}`,
        method: 'DELETE',
    };
    return apiUtil.runRequest(myData);
};

userApi.markReadUserMessage = function(id, value) {
    let myData = {
        url: `/api/private/profile/messages/${id}`,
        method: 'PUT',
        data: {
            value,
        },
    };
    return apiUtil.runRequest(myData);
};

userApi.updateLanguage = function(lang) {
    let myData = {
        url: `/api/private/profile/language`,
        method: 'PUT',
        data: {
            lang,
        },
    };
    return apiUtil.runRequest(myData);
};

userApi.getAllowedGroups = function() {
    return apiUtil.runRequest(`/api/private/profile/allowedGroups`);
};

userApi.getAllowedBoards = function() {
    return apiUtil.runRequest(`/api/private/profile/allowedBoards`);
};

userApi.startTrial = function() {
    return apiUtil.runRequest(`/api/private/profile/startTrial`);
};

userApi.cancelSubscription = function() {
    return apiUtil.runRequest(`/api/private/profile/cancelSubscription`);
};

userApi.updateEmail = function(data) {
    let myData = {
        url: `/api/private/profile/changeEmail`,
        method: 'POST',
        data,
    };
    return apiUtil.runRequest(myData);
};

userApi.updateConsent = function(data) {
    let myData = {
        url: `/api/private/profile/consent`,
        method: 'PUT',
        data,
    };
    return apiUtil.runRequest(myData);
};

userApi.verifyEmail = function(data) {
    let myData = {
        url: `/api/private/profile/verifyEmail`,
        method: 'POST',
        data,
    };
    return apiUtil.runRequest(myData);
};

userApi.sendEmail = function(data) {
    let myData = {
        url: '/api/public/sendfeedback',
        method: 'POST',
        data,
    };
    return axios(myData);
};

userApi.sendSpecialQuotaEmail = function(data) {
    let myData = {
        url: '/api/public/sendSpecialQuota',
        method: 'POST',
        data,
    };
    return axios(myData);
};

userApi.verifyUserEmailforLessonShare = function(email) {
    let myData = {
        url: `/api/private/profile/verifyUserEmailforLessonShare/` + email,
        method: 'GET',
        email,
    };
    return apiUtil.runRequest(myData);
};

userApi.linkWhiteboard = function(id) {
    return axios.post(`/api/private/linkwhiteboard/${id}`);
};

userApi.getLinkConnection = function(id) {
    return axios.get(`/api/auth/linkwhiteboard/${id}`);
};

userApi.unsubscribe = function(data) {
    let myData = {
        url: '/api/unsubscribe',
        method: 'POST',
        data,
    };
    return axios(myData);
};


export default userApi;
