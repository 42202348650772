import React from 'react';
import { Divider, IconButton, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import List from '@material-ui/core/List';
import {
    featureLinks,
    getIntlCount,
    getSupportIcon,
    getSupportPrimaryText,
    getSupportPrimaryTextForDescription,
} from '../../common/features';
import DescriptionListIcon from '../../MyComponents/icons/DescriptionListIcon';
import DescriptionListIconBasicSubscription from '../../MyComponents/icons/DescriptionListIconBasicSubscription';
import Hidden from '@material-ui/core/Hidden';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    listItem: {
        display: 'flex',
        flexDirection: 'row',
        height: '3.5rem',
    },
    listItemText: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginRight: '-2rem',
    },
    listItemIcon: {
        display: 'flex',
        justifyContent: 'center',
    },
    customDivider: {
        border: '0.063rem',
        height: '0.063em',
        width: '100%',
    },
}));

const SubscriptionListItem = ({ intl, el, formatObj, subscriptionType, isActive, isLight = true }) => {
    const classes = useStyles();

    if (!isActive) {
        return (
            <ListItem className={classes.listItem}>
                <Hidden only={['xs']}>
                    <ListItemIcon>
                        <Link to={featureLinks[el.name]}>
                            <IconButton style={{ color: isLight ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.5)' }}>
                                {<DescriptionListIcon />}
                            </IconButton>
                        </Link>
                    </ListItemIcon>
                </Hidden>
                <ListItemText
                    primary={getSupportPrimaryTextForDescription(
                        el.defaultValue,
                        el.name,
                        formatObj,
                        intl,
                        subscriptionType,
                        isActive,
                        isLight
                    )}
                />
                <ListItemText
                    className={classes.listItemText}
                    primary={getSupportPrimaryText(el.defaultValue, el.name, formatObj, intl)}
                />
                <ListItemIcon className={classes.listItemIcon}>{getSupportIcon(el.defaultValue)}</ListItemIcon>
            </ListItem>
        );
    } else {
        return (
            <ListItem className={classes.listItem}>
                <Hidden only={['xs']}>
                    <ListItemIcon>
                        <Link to={featureLinks[el.name]}>
                            <IconButton>{<DescriptionListIconBasicSubscription />}</IconButton>
                        </Link>
                    </ListItemIcon>
                </Hidden>
                <ListItemText
                    primary={getSupportPrimaryTextForDescription(
                        el.defaultValue,
                        el.name,
                        formatObj,
                        intl,
                        subscriptionType,
                        isActive,
                        isLight
                    )}
                />
                <ListItemText
                    className={classes.listItemText}
                    primary={getSupportPrimaryText(el.defaultValue, el.name, formatObj, intl)}
                />
                <ListItemIcon className={classes.listItemIcon}>{getSupportIcon(el.defaultValue)}</ListItemIcon>
            </ListItem>
        );
    }
};

const SubscriptionListRenderer = ({ intl, featureList, highLightItem, subscriptionType, isActive, isLight = true }) => {
    const classes = useStyles();

    return (
        <>
            <List dense>
                {featureList.map((el) => {
                    let formatObj = {};
                    formatObj[el.name] = getIntlCount(intl, el.defaultValue);
                    if (highLightItem) {
                        if (el.name === highLightItem) {
                            return (
                                <SubscriptionListItem
                                    el={el}
                                    formatObj={formatObj}
                                    intl={intl}
                                    key={'feature-' + el.name}
                                    subscriptionType={subscriptionType}
                                    isActive={isActive}
                                    isLight={isLight}
                                />
                            );
                        } else {
                            return null;
                        }
                    }

                    return (
                        <div key={'feature-' + el.name}>
                            <Divider className={classes.customDivider} />
                            <SubscriptionListItem
                                el={el}
                                formatObj={formatObj}
                                intl={intl}
                                key={'feature-' + el.name}
                                subscriptionType={subscriptionType}
                                isActive={isActive}
                                isLight={isLight}
                            />
                        </div>
                    );
                })}
            </List>
        </>
    );
};

export default injectIntl(SubscriptionListRenderer);
