import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function MultiBoardIcon(props) {
    return (
        <SvgIcon {...props} style={{ ...props.style, verticalAlign: 'bottom' }}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                version="1.1"
                x="0px"
                y="0px"
                viewBox="0 0 24 24"
                xmlSpace="preserve"
            >
                <path d="M21.5 0h-6A2.503 2.503 0 0 0 13 2.5v6c0 1.378 1.121 2.5 2.5 2.5h6c1.379 0 2.5-1.122 2.5-2.5v-6C24 1.122 22.879 0 21.5 0zm-6 2a.5.5 0 1 1 0 1 .5.5 0 0 1 0-1zM23 8.5c0 .827-.673 1.5-1.5 1.5h-6c-.827 0-1.5-.673-1.5-1.5V5h9v3.5zM8.5 0h-6A2.503 2.503 0 0 0 0 2.5v6C0 9.878 1.121 11 2.5 11h6C9.879 11 11 9.878 11 8.5v-6C11 1.122 9.879 0 8.5 0zm-6 2a.5.5 0 1 1 0 1 .5.5 0 0 1 0-1zM10 8.5c0 .827-.673 1.5-1.5 1.5h-6C1.673 10 1 9.327 1 8.5V5h9v3.5zM21.5 13h-6a2.503 2.503 0 0 0-2.5 2.5v6c0 1.378 1.121 2.5 2.5 2.5h6c1.379 0 2.5-1.122 2.5-2.5v-6c0-1.378-1.121-2.5-2.5-2.5zm-6 2a.5.5 0 1 1 0 1 .5.5 0 0 1 0-1zm7.5 6.5c0 .827-.673 1.5-1.5 1.5h-6c-.827 0-1.5-.673-1.5-1.5V18h9v3.5zM8.5 13h-6A2.503 2.503 0 0 0 0 15.5v6C0 22.878 1.121 24 2.5 24h6c1.379 0 2.5-1.122 2.5-2.5v-6c0-1.378-1.121-2.5-2.5-2.5zm-6 2a.5.5 0 1 1 0 1 .5.5 0 0 1 0-1zm7.5 6.5c0 .827-.673 1.5-1.5 1.5h-6c-.827 0-1.5-.673-1.5-1.5V18h9v3.5z" />
            </svg>
        </SvgIcon>
    );
}
