import React, { useImperativeHandle, useRef, useState } from 'react';
import { Rnd } from 'react-rnd';
import { Fab, Grid } from '@material-ui/core';
import { CheckCircle, DeleteForever } from '@material-ui/icons';

const imagePasteBox = {
    border: 'solid',
    width: '100%',
    height: '100%',
    position: 'relative',
    borderWidth: '1px',
};

const imagePasteImg = {
    width: '100%',
    height: '100%',
    position: 'relative',
};

const buttonComponent = {
    position: 'absolute',
    left: '0px',
    bottom: '0px',
    cursor: 'pointer',
    backgroundColor: 'rgba(0,0,0,0.3)',
};

const buttonRemoveComponent = {
    position: 'absolute',
    right: '-24px',
    top: '-24px',
    cursor: 'pointer',
    // backgroundColor: 'rgba(0,0,0,0.3)',
};

const ImageBox = React.forwardRef((props, ref) => {
    let [visible, setVisible] = useState(false);
    let [xy, setXY] = useState({ x: 0, y: 0 });
    let [wh, setWH] = useState({ width: 0, height: 0 });
    let [maxWH, setMaxWH] = useState({ width: 200, height: 200 });
    const image = useRef(null);

    useImperativeHandle(ref, () => ({
        addImage(img, width, height, maxWidth, maxHeight) {
            // console.log('==== adding image');
            image.current = img;
            setVisible(true);
            setXY({ x: 0, y: 0 });
            setWH({ width, height });
            setMaxWH({ width: maxWidth, height: maxHeight });
        },
    }));
    if (!visible) {
        return null;
    }
    // const updateRemoteData = (e) => {
    //     e.preventDefault();
    //     if (props.handleImageUpdate) {
    //         props.handleImageUpdate(image.current, xy.x, xy.y, wh.width, wh.height);
    //     }
    //     setVisible(false);
    // };

    const addSticky = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (props.addStickyImage) {
            props.addStickyImage(image.current, xy.x, xy.y, wh.width, wh.height);
        }
        setVisible(false);
    };

    const cancelUpdateRemoteData = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (props.handleImageUpdate) {
            props.handleImageUpdate(null);
        }
        setVisible(false);
    };

    return (
        <Rnd
            position={{ ...xy }}
            size={{ ...wh }}
            bounds="parent"
            onDragStop={(e, d) => {
                setXY({ x: d.x, y: d.y });
            }}
            onResizeStop={(e, direction, ref, delta, position) => {
                let rect = ref.getBoundingClientRect();
                setWH({ width: rect.width, height: rect.height });
                setXY({ x: position.x, y: position.y });
            }}
            maxWidth={maxWH.width}
            maxHeight={maxWH.height}
        >
            <div style={imagePasteBox}>
                <DeleteForever
                    color="secondary"
                    style={buttonRemoveComponent}
                    size={'small'}
                    onClick={cancelUpdateRemoteData}
                    onTouchStart={cancelUpdateRemoteData}
                />

                <img src={image.current.src} alt="" style={imagePasteImg} />
                <Grid container style={buttonComponent}>
                    <Grid item xs={12} sm={12} style={{ textAlign: 'center' }}>
                        <Fab onClick={addSticky} onTouchStart={addSticky} size={'small'} color={'primary'}>
                            <CheckCircle fontSize={'small'} />
                        </Fab>
                    </Grid>
                    {/*<Grid item xs={12} sm={6} style={{ textAlign: 'center' }}>*/}
                    {/*    <Fab*/}
                    {/*        onClick={updateRemoteData}*/}
                    {/*        onTouchStart={updateRemoteData}*/}
                    {/*        size={'small'}*/}
                    {/*        color={'secondary'}*/}
                    {/*    >*/}
                    {/*        <Lock />*/}
                    {/*    </Fab>*/}
                    {/*</Grid>*/}
                </Grid>
            </div>
        </Rnd>
    );
});

export default ImageBox;
