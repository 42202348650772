import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function PricingIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                <path
                    fill="#08aab4"
                    d="M22.854 6.008c-2.69.074-4.775.804-6.805 1.51c-2.171.754-4.22 1.465-7.059 1.494c-1.897.03-3.797-.298-5.664-.944L2 7.61v17.082l.666.237c1.739.615 3.517.97 5.287 1.054c.281.013.556.02.826.02c3.013 0 5.32-.8 7.557-1.572c2.358-.816 4.582-1.587 7.617-1.444a16.48 16.48 0 0 1 4.713.944l1.334.472V7.314l-.658-.24a17.78 17.78 0 0 0-5.297-1.056a16.56 16.56 0 0 0-1.191-.01zm.054 1.986c.34-.01.688-.01 1.049.004c.374.016.748.05 1.121.094A2.495 2.495 0 0 0 28 9.949v9.102a2.495 2.495 0 0 0-2.957 2.025a17.643 17.643 0 0 0-.996-.074c-3.415-.15-5.933.709-8.367 1.553c-2.361.818-4.598 1.591-7.631 1.447a15.746 15.746 0 0 1-1.13-.1A2.495 2.495 0 0 0 4 22.051v-9.102a2.493 2.493 0 0 0 2.959-2.05c.685.071 1.37.112 2.053.101c3.165-.032 5.466-.833 7.693-1.607c1.961-.683 3.83-1.325 6.203-1.399zM16 11c-2.206 0-4 2.243-4 5s1.794 5 4 5s4-2.243 4-5s-1.794-5-4-5zm0 2c1.084 0 2 1.374 2 3s-.916 3-2 3s-2-1.374-2-3s.916-3 2-3zm7.5 0a1.5 1.5 0 0 0 0 3a1.5 1.5 0 0 0 0-3zm-15 3a1.5 1.5 0 0 0 0 3a1.5 1.5 0 0 0 0-3z"
                />
            </svg>
        </SvgIcon>
    );
}
