import { BOARD_TYPE, ELEMENT_OPERATIONS, STICKY_ELEMENTS_TYPE, TARGETED_REQUESTS, TOOL_PEN } from '../../constants';
import store from '../../redux/store';
import drawUtils from './drawUtils';
import {
    LINEAGE_DICATANDO,
    LINEAGE_MATH,
    LINEAGE_MM,
    LINEAGE_MUSIC,
    LINEAGE_NONE,
    LINEAGE_TYPE_1,
    LINEAGE_TYPE_2,
} from './drawConstants';
import lessonList from './lessonList';
import socketUtils from './socketUtils';
import { v4 as uuidv4 } from 'uuid';
import {
    addLatexToWindowCache,
    addToWindowCache,
    getUrlFromWindowCache,
    removeLatexFromWindowCache,
} from '../../common/utils';
import {
    changeTool,
    hideSpinner,
    showError,
    showMultiBoardLessonState,
    showSpinner,
    showStaticPageNavigator,
    showSuccess,
    switchBoardType,
    switchLessonPage,
    userCanDraw,
    userJoin,
} from '../../redux/actions';
import storageApi from '../../Api/storageApi';
import cloudBoardManager from './cloudBoardManager';
import { CAPABILITIES, hasBooleanPermission } from '../../shared/Capabilities';

const PAGE_SAVE_INTERVAL_SECS = 10;
// const LINEAGE_DEBOUNCE_INTERVAL_MS = 250;
// const SEND_IMAGE_DATA_DEBOUNCE_MS = 500;
const LINE_SNAP_PIXEL_RANGE = 20;

export const DEFAULT_BOARD = {
    currentLineage: LINEAGE_NONE,
    isCurrentPageDirty: false,
    sendImageDataDebouncer: null,
    online: false,
    geometry: {
        ruler: null,
        protractor: null,
        compass: null,
    },
};

// console.log('=================== going through ... interesting');

let sleep = function(time) {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, time);
    });
};

let boardManager = {};

boardManager.mainBoard = { ...DEFAULT_BOARD };
boardManager.mainBoard.stickyElements = [];

boardManager.lessonState = {};

boardManager.currentBoardActive = null;

boardManager.pageSaveInterval = null;
boardManager.lessonState.multiBoardId = null;
boardManager.isAdmin = false;
boardManager.classId = null;
boardManager.linePoints = {};
boardManager.delayedJoins = [];
boardManager.pageSavingInProgress = false;

let lessonComponent = null;

boardManager.initDefault = () => {
    saveFunctionForInterval();
};

boardManager.initFromSocket = (isAdmin, email, classId, lessonState) => {
    boardManager.isAdmin = isAdmin;
    boardManager.email = email;
    boardManager.classId = classId;
    boardManager.lessonState = lessonState;
    if (boardManager.isAdmin && boardManager.lessonState.userStates) {
        Object.values(boardManager.lessonState.userStates).forEach((data) => {
            store.dispatch(userJoin(data));
        });
        Object.values(boardManager.lessonState.userStates).forEach((data) => {
            store.dispatch(userCanDraw(data.email, data.enableDraw));
        });
    }
};

boardManager.updateLessonComponent = (lessonPage) => {
    lessonComponent = lessonPage;
};

boardManager.resumeMultiBoardForAdmin = async () => {
    store.dispatch(showSpinner());
    await cloudBoardManager.saveCurrentMainPage();
    boardManager.lessonState.boardType = BOARD_TYPE.MULTI_BOARD;
    await boardManager.switchToExistingMultiBoardForAdmin();
    store.dispatch(switchBoardType(BOARD_TYPE.MULTI_BOARD));
    store.dispatch(hideSpinner());
    lessonComponent.setPointerForTool(store.getState()?.drawing?.tool);
};

boardManager.switchToNewMultiBoardForAdmin = async () => {
    let multiBoardId = boardManager.generateMultiBoardId();
    // console.log('==== starting multiboard ', multiBoardId);
    boardManager.lessonState.multiBoardId = multiBoardId;
    boardManager.multiBoardIsStarting = true;
    store.dispatch(showSpinner());
    await cloudBoardManager.saveCurrentMainPage();
    let mainBoard = boardManager.getCurrentMainBoard();
    if (!mainBoard) {
        return;
    }
    let myStickyElements = mainBoard.meta.stickyElements
        .filter((el) => el.stickyType === STICKY_ELEMENTS_TYPE.IMAGE)
        .map((el) => el.id);
    let meta = {
        gridType: mainBoard.meta.gridType,
        stickyElements: mainBoard.meta.stickyElements,
    };
    let s = store.getState();
    let canMultiple = hasBooleanPermission(
        s.mainAppState.subscriptions,
        s.profile.subscription,
        CAPABILITIES.MULTIPLE_INDIVIDUAL_BOARDS,
    ).value;
    boardManager.lessonState.canMultiple = canMultiple;

    let urlForMulti;
    try {
        urlForMulti = await storageApi.startMultiBoardSession(
            boardManager.classId,
            multiBoardId,
            myStickyElements,
            meta,
            mainBoard.id,
            boardManager.getCurrentCanvas().toDataURL('image/png', 1),
        );
    } catch (e) {
        console.error('Error creating multiboard', e);
        store.dispatch(
            showMultiBoardLessonState({ id: null, lessonId: boardManager.classId, email: boardManager.adminEmail }),
        );
        store.dispatch(hideSpinner());
        return;
    }
    boardManager.lessonState.originalMultiBoardState = {
        boardId: multiBoardId,
        originalDownload: urlForMulti.data.download,
        originalSticky: [],
        originalLineage: mainBoard.meta.gridType,
    };
    mainBoard.meta.stickyElements.forEach((el) => {
        let newEl = { ...el };
        if (el.stickyType === STICKY_ELEMENTS_TYPE.IMAGE) {
            newEl.url = urlForMulti.data.sticky[el.id];
        }
        boardManager.lessonState.originalMultiBoardState.originalSticky.push(newEl);
    });
    boardManager.lessonState.boardType = BOARD_TYPE.MULTI_BOARD;
    if (lessonComponent) {
        // if (window.clearMultiBoardBoards) {
        //     window.clearMultiBoardBoards();
        // }
        window.zoomState.zoomLevel = 0;
        if (window.forceZoomBox) {
            window.forceZoomBox();
            drawUtils.triggerWindowCallbacks();
        }
        lessonComponent.displayMainGeometryHandling(false);
    }
    boardManager.lessonState.individualBoards = {};
    if (window.refreshMultiboardState) {
        window.refreshMultiboardState();
    }

    socketUtils.switchBoardType(
        BOARD_TYPE.MULTI_BOARD,
        boardManager.lessonState.multiBoardId,
        boardManager.lessonState.originalMultiBoardState,
        null,
        canMultiple,
    );
    store.dispatch(switchBoardType(BOARD_TYPE.MULTI_BOARD));
    lessonComponent.setPointerForTool(store.getState()?.drawing?.tool);
    store.dispatch(hideSpinner());
};

boardManager.updateMainGeometry = (data) => {
    if (lessonComponent) {
        lessonComponent.updateMainGeometry(data);
        boardManager.lessonState.mainGeometry = data;
    }
};

boardManager.resumeMultiBoardForUser = async () => {
    store.dispatch(showSpinner());
    // await boardManager.clearCanvasesForMulti();

    boardManager.currentBoardActive = boardManager.adminEmail;
    if (lessonComponent) {
        window.zoomState.zoomLevel = 0;
        if (window.forceZoomBox) {
            window.forceZoomBox();
            drawUtils.triggerWindowCallbacks();
        }
        lessonComponent.displayMainGeometryHandling(false);
    }

    store.dispatch(switchBoardType(BOARD_TYPE.MULTI_BOARD));
    if (window.refreshMultiboardState) {
        window.refreshMultiboardState();
    }
    if (window[`targetMultiBoardStatusRefresh${boardManager.adminEmail}`]) {
        window[`targetMultiBoardStatusRefresh${boardManager.adminEmail}`](
            boardManager.lessonState.individualBoards[boardManager.adminEmail],
        );
    }
    store.dispatch(changeTool(TOOL_PEN));
    if (!boardManager.lessonState.individualBoards[boardManager.adminEmail]) {
        await boardManager.switchToNewMultiBoardForUser();
    }
    try {
        await boardManager.loadImageInTargetCanvas(
            boardManager.adminEmail,
            boardManager.lessonState.individualBoards[boardManager.adminEmail].pages[
                boardManager.lessonState.individualBoards[boardManager.adminEmail].currentPageNo
                ].download,
        );
    } catch (err) {
        console.error('Unable to load image in target', boardManager.adminEmail, err);
    }

    store.dispatch(hideSpinner());
    if (window.updateBoardNavigation) {
        window.updateBoardNavigation();
    }
    lessonComponent.shallowStickyElementSync();
    lessonComponent.setPointerForTool(store.getState()?.drawing?.tool);
};

boardManager.switchToNewMultiBoardForUser = async () => {
    boardManager.lessonState.individualBoards = {};
    boardManager.lessonState.individualBoards[boardManager.adminEmail] = {
        pages: [],
        currentPageNo: 0,
    };

    store.dispatch(showSpinner());
    // await boardManager.clearCanvasesForMulti();

    let pages = await cloudBoardManager.updateMultiBoardPage(
        boardManager.adminEmail,
        boardManager.classId,
        boardManager.lessonState.multiBoardId.split('_')[0],
        boardManager.email,
        uuidv4(),
        {
            gridType: boardManager.lessonState.originalMultiBoardState.originalLineage,
            stickyElements: [],
        },
    );
    boardManager.currentBoardActive = boardManager.adminEmail;
    boardManager.lessonState.individualBoards[boardManager.adminEmail].pages = pages;
    socketUtils.changeIndividualBoardStateByUser(boardManager.lessonState.individualBoards[boardManager.adminEmail]);

    if (lessonComponent) {
        window.zoomState.zoomLevel = 0;
        if (window.forceZoomBox) {
            window.forceZoomBox();
            drawUtils.triggerWindowCallbacks();
        }
        lessonComponent.displayMainGeometryHandling(false);
    }

    store.dispatch(switchBoardType(BOARD_TYPE.MULTI_BOARD));
    if (window.refreshMultiboardState) {
        window.refreshMultiboardState();
    }
    if (window[`targetMultiBoardStatusRefresh${boardManager.adminEmail}`]) {
        window[`targetMultiBoardStatusRefresh${boardManager.adminEmail}`](
            boardManager.lessonState.individualBoards[boardManager.adminEmail],
        );
    }
    store.dispatch(changeTool(TOOL_PEN));
    await boardManager.switchMultiBoardPageForUser(0);
    lessonComponent.setPointerForTool(store.getState()?.drawing?.tool);
};

boardManager.switchToSingleBoardForAdmin = async (pause = false) => {
    store.dispatch(showSpinner());
    boardManager.lessonState.boardType = BOARD_TYPE.SINGLE_BOARD;
    window.zoomState.zoomLevel = 0;
    if (window.forceZoomBox) {
        window.forceZoomBox();
        drawUtils.triggerWindowCallbacks();
    }

    store.dispatch(showSuccess('CLOSED_INDIVIDUAL_SESSION'));
    if (window.refreshMultiboardState) {
        window.refreshMultiboardState();
    }
    let mainBoard = boardManager.getCurrentMainBoard();
    if (!mainBoard) {
        return;
    }
    let idx = boardManager.getCurrentMainBoardIndex();
    if (idx < 0) idx = boardManager.lessonState.mainBoardPages.length - 1;

    let s = store.getState();
    let canMultiple = hasBooleanPermission(
        s.mainAppState.subscriptions,
        s.profile.subscription,
        CAPABILITIES.MULTIPLE_INDIVIDUAL_BOARDS,
    ).value;
    boardManager.canMultiple = canMultiple;

    if (pause) {
        socketUtils.switchBoardType(
            BOARD_TYPE.SINGLE_BOARD,
            mainBoard.id,
            mainBoard,
            boardManager.lessonState.multiBoardId,
            canMultiple,
        );
        boardManager.lessonState.pausedBoard = boardManager.lessonState.multiBoardId;
    } else {
        socketUtils.switchBoardType(BOARD_TYPE.SINGLE_BOARD, mainBoard.id, mainBoard);
        boardManager.lessonState.pausedBoard = null;
    }
    store.dispatch(switchBoardType(BOARD_TYPE.SINGLE_BOARD));
    let loadSuccess = await lessonComponent.loadLessonPage(idx);
    if (!loadSuccess) {
        // store.dispatch(showError('GENERIC_ERROR'));
        // return;
    }
    store.dispatch(switchLessonPage(idx));
};

boardManager.switchToSingleBoardForUser = async (data) => {
    // console.log('=== should be switching for user', data);
    store.dispatch(showSpinner());
    // await boardManager.clearCanvasesForMulti();
    //TODO should bring back and save before clearing the state
    // await cloudBoardManager.saveCurrentMultiBoardUserPage();
    // boardManager.originalMultiBoardState = {};
    boardManager.lessonState.boardType = BOARD_TYPE.SINGLE_BOARD;

    window.zoomState.zoomLevel = 0;
    if (window.forceZoomBox) {
        window.forceZoomBox();
        drawUtils.triggerWindowCallbacks();
    }

    store.dispatch(switchBoardType(BOARD_TYPE.SINGLE_BOARD));
    store.dispatch(showSuccess('CLOSED_INDIVIDUAL_SESSION'));
    if (window.refreshMultiboardState) {
        window.refreshMultiboardState();
    }
    store.dispatch(hideSpinner());
    lessonComponent.shallowStickyElementSync();
    store.dispatch(changeTool(TOOL_PEN));
    let board = boardManager.getCurrentMainBoard();
    if (board) {
        await lessonComponent.drawImageFromUrlInCanvas(board.download, drawUtils.getCanvas());
    }

    lessonComponent.setPointerForTool(store.getState()?.drawing?.tool);
    lessonComponent.displayMainGeometryHandling(false);
};

boardManager.clearElements = async (target) => {
    if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
        if (boardManager.isAdmin) {
            let board = boardManager.getCurrentMainBoard();
            if (!board) {
                console.error('board is not present', boardManager.lessonState.mainBoardId);
                return;
            }
            board.meta.stickyElements = [];
            await cloudBoardManager.updatePage(
                boardManager.email,
                boardManager.classId,
                boardManager.lessonState.mainBoardId,
                {
                    stickyElements: board.meta.stickyElements,
                    gridType: board.meta.gridType,
                },
            );
        } else {
            let ids = boardManager.mainBoard.stickyElements
                .filter((el) => el.creator !== boardManager.adminEmail)
                .map((el) => el.id);
            for (let i = 0; i < ids.length; i++) {
                await boardManager.removeLocalStickyElement(ids[i]);
            }
        }
    } else {
        if (boardManager.isAdmin) {
            let currentBoard = boardManager.lessonState.individualBoards[target];
            if (currentBoard && currentBoard.pages && currentBoard.pages.length >= 1) {
                if (currentBoard.pages[currentBoard.currentPageNo].meta.stickyElements) {
                    currentBoard.pages[currentBoard.currentPageNo].meta.stickyElements.forEach((element) => {
                        if (element.stickyType === STICKY_ELEMENTS_TYPE.IMAGE) {
                            storageApi
                                .deleteMultiBoardStickyImage(
                                    boardManager.classId,
                                    boardManager.lessonState.multiBoardId,
                                    element.id,
                                    target,
                                )
                                .catch(() => {
                                    console.log('unable to remove - probably already done');
                                });
                        }
                    });
                }
                currentBoard.pages[currentBoard.currentPageNo].meta.stickyElements = [];
            }
        } else {
            let currentBoard = boardManager.lessonState.individualBoards[boardManager.adminEmail];
            if (currentBoard.pages[currentBoard.currentPageNo].meta.stickyElements) {
                currentBoard.pages[currentBoard.currentPageNo].meta.stickyElements.forEach((element) => {
                    if (element.stickyType === STICKY_ELEMENTS_TYPE.IMAGE) {
                        storageApi
                            .deleteMultiBoardStickyImage(
                                boardManager.classId,
                                boardManager.lessonState.multiBoardId,
                                element.id,
                                boardManager.email,
                            )
                            .catch(() => {
                                console.log('unable to remove - probably already done');
                            });
                    }
                });
            }
            currentBoard.pages[currentBoard.currentPageNo].meta.stickyElements = [];
        }
    }
    lessonComponent.shallowStickyElementSync();
};

boardManager.clearCanvasesForMulti = async () => {
    if (boardManager.isAdmin) {
        let proms = Object.keys(boardManager.lessonState.userStates).map((target) => {
            lessonComponent.asyncClearImage(true, target);
        });
        await Promise.all(proms);
    } else {
        await lessonComponent.asyncClearImage(true, boardManager.lessonState.adminEmail);
    }
};

boardManager.getPointerContext = (target) => {
    if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
        return lessonComponent.stickyComponent.current.getPointerContext();
    } else {
        if (target) {
            if (boardManager.multiBoardRefs[target]) {
                return boardManager.multiBoardRefs[target].current.getPointerContext();
            }
        } else {
            if (boardManager.isAdmin) {
                return boardManager.multiBoardRefs[boardManager.currentBoardActive].current.getPointerContext();
            } else {
                return boardManager.multiBoardRefs[boardManager.adminEmail].current.getPointerContext();
            }
        }
    }
    return null;
};

boardManager.drawArc = (x, y, width, direction, start, end, weight, color) => {
    if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
        return lessonComponent.stickyComponent.current.drawArc(x, y, width, direction, start, end, weight, color);
    }
    return null;
};

boardManager.clearAuxDrawing = () => {
    if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
        return lessonComponent.stickyComponent.current.clearAuxDrawing();
    }
    return null;
};

boardManager.drawShape = (tool, x1, y1, x2, y2, weight, color) => {
    if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
        return lessonComponent.stickyComponent.current.drawShape(tool, x1, y1, x2, y2, weight, color);
    } else {
        if (boardManager.isAdmin) {
            if (boardManager.currentBoardActive) {
                if (boardManager.multiBoardRefs[boardManager.currentBoardActive]) {
                    boardManager.multiBoardRefs[boardManager.currentBoardActive].current.drawShape(
                        tool,
                        x1,
                        y1,
                        x2,
                        y2,
                        weight,
                        color,
                    );
                }
            }
        } else {
            if (boardManager.multiBoardRefs[boardManager.adminEmail]) {
                boardManager.multiBoardRefs[boardManager.adminEmail].current.drawShape(
                    tool,
                    x1,
                    y1,
                    x2,
                    y2,
                    weight,
                    color,
                );
            }
        }
    }
    return null;
};

boardManager.triggerPointerDraw = (target) => {
    if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
        lessonComponent.stickyComponent.current.drawPointerEvent();
    } else {
        if (target) {
            if (boardManager.multiBoardRefs[target]) {
                boardManager.multiBoardRefs[target].current.drawPointerEvent();
            }
        } else {
            if (boardManager.isAdmin) {
                boardManager.multiBoardRefs[boardManager.currentBoardActive].current.drawPointerEvent();
            } else {
                boardManager.multiBoardRefs[boardManager.adminEmail].current.drawPointerEvent();
            }
        }
    }
};

boardManager.buildMultiBoardSignatures = async function(classId, multiBoardId, myData) {
    let multiBoardSignedAssets = {
        id: multiBoardId,
        originalStickyElements: myData.originalStickyElements
            .filter((el) => el.stickyType === STICKY_ELEMENTS_TYPE.IMAGE)
            .map((el) => {
                return { id: el.id };
            }),
        users: {},
    };
    Object.keys(myData.users).forEach((key) => {
        let idArray = [];
        if (myData.users[key] && myData.users[key].pages && myData.users[key].pages.length > 0) {
            myData.users[key].pages.forEach((page) => {
                if (page.stickyElements) {
                    idArray.push(
                        ...page.stickyElements
                            .filter((el) => el.stickyType === STICKY_ELEMENTS_TYPE.IMAGE)
                            .map((el) => {
                                return { id: el.id };
                            }),
                    );
                }
            });
        }
        if (idArray.length > 0) {
            multiBoardSignedAssets.users[key] = {
                stickyElements: idArray,
            };
        }
    });
    let signedRes = await storageApi.signMultiBoardSession(classId, multiBoardId, multiBoardSignedAssets);
    let newSignedAssets = signedRes.data;
    // boardManager.originalMultiBoardState.originalDownload = newSignedAssets.url;
    syncMultiBoardURLs(newSignedAssets);
};

boardManager.shallowStickyElementSync = () => {
    lessonComponent.shallowStickyElementSync();
};

boardManager.switchToExistingMultiBoardForAdmin = async () => {
    if (boardManager.lessonState.pausedBoard) {
        socketUtils.switchBoardType(
            BOARD_TYPE.MULTI_BOARD,
            boardManager.lessonState.pausedBoard,
            boardManager.lessonState.originalMultiBoardState,
            null,
            boardManager.lessonState.canMultiple,
        );
        boardManager.lessonState.pausedBoard = null;
    } else {
        socketUtils.switchBoardType(
            BOARD_TYPE.MULTI_BOARD,
            boardManager.lessonState.multiBoardId,
            boardManager.lessonState.originalMultiBoardState,
            boardManager.lessonState.multiBoardId,
            boardManager.lessonState.canMultiple,
        );
    }
    boardManager.lessonState.boardType = BOARD_TYPE.MULTI_BOARD;

    if (window.refreshMultiboardState) {
        window.refreshMultiboardState();
    }
    // if (window.clearMultiBoardBoards) {
    //     window.clearMultiBoardBoards();
    // }

    store.dispatch(switchBoardType(BOARD_TYPE.MULTI_BOARD));
    lessonComponent.shallowStickyElementSync();
    let users = Object.keys(boardManager.lessonState.individualBoards);
    let proms = users.map((target) => boardManager.loadIndividualBoardForUser(target));
    await Promise.all(proms);
};

boardManager.loadIndividualBoardForUser = async (target) => {
    if (!boardManager.lessonState.individualBoards[target]) {
        console.error('No target available', target);
        return;
    }
    if (window[`targetMultiBoardStatusRefresh${target}`]) {
        window[`targetMultiBoardStatusRefresh${target}`](
            boardManager.lessonState.individualBoards[target],
        );
    }
    await boardManager.loadImageInTargetCanvas(
        target,
        boardManager.lessonState.individualBoards[target].pages[
            boardManager.lessonState.individualBoards[target].currentPageNo
            ].download,
    );
};

const syncMultiBoardURLs = (data) => {
    if (data.id && data.url) {
        addToWindowCache(
            boardManager.email,
            boardManager.classId,
            boardManager.lessonState.multiBoardId,
            data.id,
            data.url,
        );
        boardManager.lessonState.originalMultiBoardState.originalDownload = data.url;
    }
    if (data.originalStickyElements) {
        for (let i = 0; i < data.originalStickyElements.length; i++) {
            boardManager.lessonState.originalMultiBoardState.originalSticky.forEach((el, index) => {
                if (el.id === data.originalStickyElements[i].id) {
                    boardManager.lessonState.originalMultiBoardState.originalSticky[index].url =
                        data.originalStickyElements[i].url;
                    addToWindowCache(
                        boardManager.email,
                        boardManager.classId,
                        boardManager.lessonState.multiBoardId,
                        el.id,
                        data.originalStickyElements[i].url,
                    );
                }
            });
        }
    }
};

boardManager.switchToExistingMultiBoardForUser = async () => {
    store.dispatch(switchBoardType(BOARD_TYPE.MULTI_BOARD));
    if (window.refreshMultiboardState) {
        window.refreshMultiboardState();
    }

    await boardManager.loadIndividualBoardForUser(boardManager.adminEmail);
    store.dispatch(hideSpinner());
};

boardManager.addStickyElementFromRemote = async (el, target) => {
    // console.log('got add from remote');
    if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
        let board = boardManager.getCurrentMainBoard();
        if (!board) {
            console.error('board is not present', boardManager.lessonState.mainBoardId);
            return;
        }
        board.meta.stickyElements.push(el);
        if (boardManager.isAdmin) {
            if (el.stickyType === STICKY_ELEMENTS_TYPE.IMAGE) {
                addToWindowCache(
                    boardManager.email,
                    boardManager.classId,
                    boardManager.lessonState.mainBoardId,
                    el.id,
                    el.url,
                );
            }

            cloudBoardManager.updatePage(
                boardManager.email,
                boardManager.classId,
                boardManager.lessonState.mainBoardId,
                {
                    stickyElements: board.meta.stickyElements,
                    gridType: board.meta.gridType,
                },
            );
        }
        lessonComponent.stickyComponent.current.shallowSyncPageElements(board.meta.stickyElements);
    } else {
        if (el.stickyType === STICKY_ELEMENTS_TYPE.IMAGE) {
            addToWindowCache(
                boardManager.email,
                boardManager.classId,
                boardManager.lessonState.multiBoardId,
                el.id,
                el.url,
            );
        }
        let userState = boardManager.lessonState.individualBoards[target];
        if (userState) {
            if (userState.pages[userState.currentPageNo].meta.stickyElements) {
                userState.pages[userState.currentPageNo].meta.stickyElements.push(el);
            } else {
                userState.pages[userState.currentPageNo].meta.stickyElements = [el];
            }
        } else {
            console.error('Unable to add sticky element from remote');
            return;
        }

        if (!boardManager.isAdmin) {
            boardManager.lessonState.individualBoards[target].pages = await cloudBoardManager.updateMultiBoardPage(
                target,
                boardManager.classId,
                boardManager.lessonState.multiBoardId.split('_')[0],
                boardManager.adminEmail,
                userState.pages[userState.currentPageNo].id,
                {
                    gridType: userState.pages[userState.currentPageNo].meta.gridType,
                    stickyElements: userState.pages[userState.currentPageNo].meta.stickyElements,
                },
            );
        }

        try {
            // console.log('==== calling shallow sync on', target);
            boardManager.multiBoardRefs[target].current.shallowSyncPageElements(
                userState.pages[userState.currentPageNo].meta.stickyElements,
            );
        } catch (e) {
            console.error('unable to refresh', e);
        }
    }
};

boardManager.updateStickyElementFromRemote = async (el, target) => {
    if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
        let board = boardManager.getCurrentMainBoard();
        if (!board) {
            console.error('board is not present', boardManager.lessonState.mainBoardId);
            return;
        }

        let idx = board.meta.stickyElements.findIndex((elem) => elem.id === el.id);
        if (idx >= 0) {
            board.meta.stickyElements[idx] = el;
            if (boardManager.isAdmin) {
                cloudBoardManager.updatePage(
                    boardManager.email,
                    boardManager.classId,
                    boardManager.lessonState.mainBoardId,
                    {
                        stickyElements: board.meta.stickyElements,
                        gridType: board.meta.gridType,
                    },
                );
            } else {
                if (el.stickyType === STICKY_ELEMENTS_TYPE.LATEX) {
                    removeLatexFromWindowCache(el.id);
                }
            }
        }
        lessonComponent.stickyComponent.current.shallowSyncPageElements(board.meta.stickyElements);
    } else {
        let boardData = boardManager.lessonState.individualBoards[target];
        let stickyElements = boardData.pages[boardData.currentPageNo].meta.stickyElements;
        if (!stickyElements) {
            return;
        }
        let idx = stickyElements.findIndex((ell) => ell.id === el.id);
        if (idx < 0) {
            return;
        }
        if (el.stickyType === STICKY_ELEMENTS_TYPE.LATEX) {
            removeLatexFromWindowCache(el.id);
        }
        stickyElements[idx] = el;
        // boardManager.lessonState.individualBoards[target].stickyElements = stickyElements;

        if (!boardManager.isAdmin) {
            cloudBoardManager.updateMultiBoardPage(
                boardManager.currentBoardActive,
                boardManager.classId,
                boardManager.lessonState.multiBoardId.split('_')[0],
                boardManager.adminEmail,
                boardManager.lessonState.individualBoards[target].pages[
                    boardManager.lessonState.individualBoards[target].currentPageNo
                    ].id,
                {
                    gridType:
                    boardManager.lessonState.individualBoards[target].pages[
                        boardManager.lessonState.individualBoards[target].currentPageNo
                        ].meta.gridType,
                    stickyElements: stickyElements,
                },
            );
        }

        // console.log('calling shallow synk on', target);
        boardManager.multiBoardRefs[target].current.shallowSyncPageElements(stickyElements);
    }
};

boardManager.removeStickyElementFromRemote = async (id, target) => {
    if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
        let board = boardManager.getCurrentMainBoard();
        if (!board) {
            console.error('board is not present', boardManager.lessonState.mainBoardId);
            return;
        }
        if (boardManager.isAdmin) {
            boardManager.removeStickyElement(
                board.meta.stickyElements,
                boardManager.email,
                boardManager.classId,
                boardManager.lessonState.mainBoardId,
                id,
            );
            cloudBoardManager.updatePage(
                boardManager.email,
                boardManager.classId,
                boardManager.lessonState.mainBoardId,
                {
                    stickyElements: board.meta.stickyElements,
                    gridType: board.meta.gridType,
                },
            );
        } else {
            board.meta.stickyElements = board.meta.stickyElements.filter((el) => el.id !== id);
        }
        lessonComponent.shallowStickyElementSync();
        if (window.checkIfStickyElementsToolPicker) {
            window.checkIfStickyElementsToolPicker();
        }
    } else {
        let element;
        let boardData = boardManager.lessonState.individualBoards[target];
        let stickyElements = boardData.pages[boardData.currentPageNo].meta.stickyElements;
        element = stickyElements.find((el) => el.id === id);
        if (!element) {
            return;
        }
        if (element.stickyType === STICKY_ELEMENTS_TYPE.IMAGE) {
            storageApi
                .deleteMultiBoardStickyImage(
                    boardManager.classId,
                    boardManager.lessonState.multiBoardId,
                    id,
                    boardManager.isAdmin ? target : boardManager.email,
                )
                .catch(() => {
                    console.log('unable to remove - probably already done');
                });
        }

        stickyElements = stickyElements.filter((el) => el.id !== id);

        boardData.pages[boardData.currentPageNo].meta.stickyElements = stickyElements;

        // boardManager.lessonState.individualBoards[target].stickyElements = stickyElements;

        if (!boardManager.isAdmin) {
            cloudBoardManager.updateMultiBoardPage(
                boardManager.currentBoardActive,
                boardManager.classId,
                boardManager.lessonState.multiBoardId.split('_')[0],
                boardManager.adminEmail,
                boardManager.lessonState.individualBoards[target].pages[
                    boardManager.lessonState.individualBoards[target].currentPageNo
                    ].id,
                {
                    gridType:
                    boardManager.lessonState.individualBoards[target].pages[
                        boardManager.lessonState.individualBoards[target].currentPageNo
                        ].meta.gridType,
                    stickyElements: stickyElements,
                },
            );
        }
        lessonComponent.shallowStickyElementSync();
        if (window.checkIfStickyElementsToolPicker) {
            window.checkIfStickyElementsToolPicker();
        }
    }
};

boardManager.addLocalStickyImage = async (image, x, y, width, height) => {
    let scaledPoint = drawUtils.scalePoint(lessonComponent.startPoint, x, y);
    let scaledWHPoint = drawUtils.scalePoint(lessonComponent.startPoint, x + width, y + height);
    if (!scaledPoint || !scaledWHPoint) {
        return;
    }

    if (image) {
        let newID = uuidv4();
        if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
            let board = boardManager.getCurrentMainBoard();
            if (!board) {
                console.error('board is not present', boardManager.lessonState.mainBoardId);
                return;
            }
            store.dispatch(showSpinner());
            //TODO this can fail
            let res = await storageApi.uploadStickyImage(
                boardManager.classId,
                boardManager.lessonState.mainBoardId,
                newID,
                image.src,
            );
            if (res.data.download) {
                let newEl = lessonList.buildImageElement(
                    newID,
                    scaledPoint.x,
                    scaledPoint.y,
                    scaledWHPoint.x - scaledPoint.x,
                    scaledWHPoint.y - scaledPoint.y,
                    boardManager.email,
                    res.data.download,
                );
                board.meta.stickyElements.push(newEl);
                if (boardManager.isAdmin) {
                    cloudBoardManager.updatePage(
                        boardManager.email,
                        boardManager.classId,
                        boardManager.lessonState.mainBoardId,
                        {
                            stickyElements: board.meta.stickyElements,
                            gridType: board.meta.gridType,
                        },
                    );
                }
                lessonComponent.shallowStickyElementSync();
                boardManager.sendElementOp(newEl, ELEMENT_OPERATIONS.ADD_STICKY_ELEMENT);
                store.dispatch(hideSpinner());
            }
        } else {
            let userId;
            if (boardManager.isAdmin) {
                userId = boardManager.currentBoardActive;
            } else {
                userId = boardManager.email;
            }

            let res = await storageApi.uploadMultiBoardStickyImage(
                boardManager.classId,
                boardManager.lessonState.multiBoardId,
                newID,
                userId,
                image.src,
            );
            if (res.data.download) {
                let newEl = lessonList.buildImageElement(
                    newID,
                    scaledPoint.x,
                    scaledPoint.y,
                    scaledWHPoint.x - scaledPoint.x,
                    scaledWHPoint.y - scaledPoint.y,
                    boardManager.email,
                    res.data.download,
                );
                addToWindowCache(
                    boardManager.email,
                    boardManager.classId,
                    boardManager.lessonState.multiBoardId,
                    newID,
                    res.data.download,
                );
                // boardManager.lessonState.individualBoards[boardManager.currentBoardActive].stickyElements.push(newEl);
                let boardState = boardManager.lessonState.individualBoards[boardManager.currentBoardActive];
                if (
                    boardState.pages[boardState.currentPageNo] &&
                    boardState.pages[boardState.currentPageNo].meta &&
                    !boardState.pages[boardState.currentPageNo].meta.stickyElements
                ) {
                    boardState.pages[boardState.currentPageNo].meta.stickyElements = [];
                }
                boardState.pages[boardState.currentPageNo].meta.stickyElements.push(newEl);
                // boardManager.lessonState.individualBoards[boardManager.currentBoardActive].stickyElements;
                lessonComponent.shallowStickyElementSync();
                if (!boardManager.isAdmin) {
                    let b = boardManager.lessonState.individualBoards[boardManager.currentBoardActive];
                    await cloudBoardManager.updateMultiBoardPage(
                        boardManager.currentBoardActive,
                        boardManager.classId,
                        boardManager.lessonState.multiBoardId.split('_')[0],
                        boardManager.adminEmail,
                        b.pages[b.currentPageNo].id,
                        {
                            gridType: b.pages[b.currentPageNo].meta.gridType,
                            stickyElements: b.pages[b.currentPageNo].meta.stickyElements,
                        },
                    );
                }
                boardManager.sendElementOp(newEl, ELEMENT_OPERATIONS.ADD_STICKY_ELEMENT, [
                    boardManager.currentBoardActive,
                ]);
            }
        }
    }
    if (window.checkIfStickyElementsToolPicker) {
        window.checkIfStickyElementsToolPicker();
    }
};

boardManager.addLocalStickyText = async (textState, reverseScaledPoint, wh, textWeight, color) => {
    let el = lessonList.buildTextElement(
        textState.text,
        reverseScaledPoint.x,
        reverseScaledPoint.y,
        wh.width,
        wh.height,
        textWeight,
        color,
        boardManager.email,
    );

    if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
        let board = boardManager.getCurrentMainBoard();
        if (!board) {
            console.error('board is not present', boardManager.lessonState.mainBoardId);
            return;
        }

        board.meta.stickyElements.push(el);
        if (boardManager.isAdmin) {
            cloudBoardManager.updatePage(
                boardManager.email,
                boardManager.classId,
                boardManager.lessonState.mainBoardId,
                {
                    stickyElements: board.meta.stickyElements,
                    gridType: board.meta.gridType,
                },
            );
        }
        boardManager.sendElementOp(el, ELEMENT_OPERATIONS.ADD_STICKY_ELEMENT);
        lessonComponent.shallowStickyElementSync();
    } else {
        let boardState = boardManager.lessonState.individualBoards[boardManager.currentBoardActive];
        boardState.pages[boardState.currentPageNo].meta.stickyElements.push(el);

        if (!boardManager.isAdmin) {
            await cloudBoardManager.updateMultiBoardPage(
                boardManager.currentBoardActive,
                boardManager.classId,
                boardManager.lessonState.multiBoardId.split('_')[0],
                boardManager.adminEmail,
                boardState.pages[boardState.currentPageNo].id,
                {
                    gridType: boardState.pages[boardState.currentPageNo].meta.gridType,
                    stickyElements: boardState.pages[boardState.currentPageNo].meta.stickyElements,
                },
            );
        }

        boardManager.sendElementOp(el, ELEMENT_OPERATIONS.ADD_STICKY_ELEMENT, [boardManager.currentBoardActive]);
        lessonComponent.shallowStickyElementSync();
    }
    if (window.checkIfStickyElementsToolPicker) {
        window.checkIfStickyElementsToolPicker();
    }
    return el;
};

boardManager.addLocalStickyLatex = async (latex, x, y, width, height, textWeight, color) => {
    let el = lessonList.buildLatexElement(latex, x, y, width, height, textWeight, color, boardManager.email);
    addLatexToWindowCache(el.id, window.latexToImg(latex));

    if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
        let board = boardManager.getCurrentMainBoard();
        if (!board) {
            console.error('board is not present', boardManager.lessonState.mainBoardId);
            return;
        }

        board.meta.stickyElements.push(el);
        if (boardManager.isAdmin) {
            cloudBoardManager.updatePage(
                boardManager.email,
                boardManager.classId,
                boardManager.lessonState.mainBoardId,
                {
                    stickyElements: board.meta.stickyElements,
                    gridType: board.meta.gridType,
                },
            );
        }
        boardManager.sendElementOp(el, ELEMENT_OPERATIONS.ADD_STICKY_ELEMENT);
        lessonComponent.shallowStickyElementSync();
    } else {
        // if (!boardManager.lessonState.individualBoards[boardManager.currentBoardActive].stickyElements) {
        //     boardManager.lessonState.individualBoards[boardManager.currentBoardActive].stickyElements = [];
        // }
        // boardManager.lessonState.individualBoards[boardManager.currentBoardActive].stickyElements.push(el);
        let b = boardManager.lessonState.individualBoards[boardManager.currentBoardActive];
        b.pages[b.currentPageNo].meta.stickyElements.push(el);

        if (!boardManager.isAdmin) {
            await cloudBoardManager.updateMultiBoardPage(
                boardManager.currentBoardActive,
                boardManager.classId,
                boardManager.lessonState.multiBoardId.split('_')[0],
                boardManager.adminEmail,
                b.pages[b.currentPageNo].id,
                {
                    gridType: b.pages[b.currentPageNo].meta.gridType,
                    stickyElements: b.pages[b.currentPageNo].meta.stickyElements,
                },
            );
        }

        boardManager.sendElementOp(el, ELEMENT_OPERATIONS.ADD_STICKY_ELEMENT, [boardManager.currentBoardActive]);
        lessonComponent.shallowStickyElementSync();
    }
    if (window.checkIfStickyElementsToolPicker) {
        window.checkIfStickyElementsToolPicker();
    }
};

boardManager.updateLocalStickyImagePosition = async (id, x, y, w, h) => {
    if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
        let board = boardManager.getCurrentMainBoard();
        if (!board) {
            console.error('board is not present', boardManager.lessonState.mainBoardId);
            return;
        }

        let idx = board.meta.stickyElements.findIndex((el) => el.id === id);
        if (idx >= 0) {
            board.meta.stickyElements[idx] = updateImageElementData(board.meta.stickyElements[idx], x, y, w, h);
            boardManager.sendElementOp(board.meta.stickyElements[idx], ELEMENT_OPERATIONS.UPDATE_STICKY_ELEMENT);

            if (boardManager.isAdmin) {
                cloudBoardManager.updatePage(
                    boardManager.email,
                    boardManager.classId,
                    boardManager.lessonState.mainBoardId,
                    {
                        stickyElements: board.meta.stickyElements,
                        gridType: board.meta.gridType,
                    },
                );
            }
        }
    } else {
        let element;
        let currentTarget;
        if (boardManager.isAdmin) {
            currentTarget = boardManager.currentBoardActive;
        } else {
            currentTarget = boardManager.adminEmail;
        }
        let boardData = boardManager.lessonState.individualBoards[currentTarget];
        let stickyElements = boardData.pages[boardData.currentPageNo].meta.stickyElements;
        element = stickyElements.find((el) => el.id === id);
        if (!element) {
            return;
        }
        element = updateImageElementData(element, x, y, w, h);
        // boardManager.lessonState.individualBoards[currentTarget].stickyElements = stickyElements;
        lessonComponent.shallowStickyElementSync();

        if (!boardManager.isAdmin) {
            let b = boardManager.lessonState.individualBoards[boardManager.currentBoardActive];
            await cloudBoardManager.updateMultiBoardPage(
                boardManager.currentBoardActive,
                boardManager.classId,
                boardManager.lessonState.multiBoardId.split('_')[0],
                boardManager.adminEmail,
                b.pages[b.currentPageNo].id,
                {
                    gridType: b.pages[b.currentPageNo].meta.gridType,
                    stickyElements: stickyElements,
                },
            );
        }
        boardManager.sendElementOp(element, ELEMENT_OPERATIONS.UPDATE_STICKY_ELEMENT, [currentTarget]);
    }
    lessonComponent.shallowStickyElementSync();
};

boardManager.removeLocalStickyElement = async (id) => {
    if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
        let board = boardManager.getCurrentMainBoard();
        if (!board) {
            console.error('board is not present', boardManager.lessonState.mainBoardId);
            return;
        }

        if (boardManager.isAdmin) {
            let elems = board.meta.stickyElements;
            boardManager.removeStickyElement(
                elems,
                boardManager.email,
                boardManager.classId,
                boardManager.lessonState.mainBoardId,
                id,
            );

            cloudBoardManager.updatePage(
                boardManager.email,
                boardManager.classId,
                boardManager.lessonState.mainBoardId,
                {
                    stickyElements: board.meta.stickyElements,
                    gridType: board.meta.gridType,
                },
            );

            boardManager.sendElementOp({ id }, ELEMENT_OPERATIONS.REMOVE_STICKY_ELEMENT);
            lessonComponent.shallowStickyElementSync();
            if (window.checkIfStickyElementsToolPicker) {
                window.checkIfStickyElementsToolPicker();
            }
        } else {
            let board = boardManager.getCurrentMainBoard();
            let elems = board.meta.stickyElements;
            boardManager.removeStickyElement(
                elems,
                boardManager.email,
                boardManager.classId,
                boardManager.lessonState.mainBoardId,
                id,
            );

            boardManager.sendElementOp({ id }, ELEMENT_OPERATIONS.REMOVE_STICKY_ELEMENT);
            lessonComponent.shallowStickyElementSync();
            if (window.checkIfStickyElementsToolPicker) {
                window.checkIfStickyElementsToolPicker();
            }
        }
    } else {
        let element;
        let currentTarget;
        if (boardManager.isAdmin) {
            currentTarget = boardManager.currentBoardActive;
        } else {
            currentTarget = boardManager.adminEmail;
        }
        let boardData = boardManager.lessonState.individualBoards[currentTarget];
        let stickyElements = boardData.pages[boardData.currentPageNo].meta.stickyElements;
        element = stickyElements.find((el) => el.id === id);
        if (!element) {
            return;
        }
        if (element.stickyType === STICKY_ELEMENTS_TYPE.IMAGE) {
            storageApi
                .deleteMultiBoardStickyImage(
                    boardManager.classId,
                    boardManager.lessonState.multiBoardId,
                    id,
                    boardManager.isAdmin ? currentTarget : boardManager.email,
                )
                .catch(() => {
                    console.log('unable to remove - probably already done');
                });
        }
        stickyElements = stickyElements.filter((el) => el.id !== id);

        boardData.pages[boardData.currentPageNo].meta.stickyElements = stickyElements;

        // boardManager.lessonState.individualBoards[currentTarget].stickyElements = stickyElements;

        if (!boardManager.isAdmin) {
            await cloudBoardManager.updateMultiBoardPage(
                boardManager.currentBoardActive,
                boardManager.classId,
                boardManager.lessonState.multiBoardId.split('_')[0],
                boardManager.adminEmail,
                boardManager.lessonState.individualBoards[boardManager.currentBoardActive].pages[
                    boardManager.lessonState.individualBoards[boardManager.currentBoardActive].currentPageNo
                    ].id,
                {
                    gridType:
                    boardManager.lessonState.individualBoards[boardManager.currentBoardActive].pages[
                        boardManager.lessonState.individualBoards[boardManager.currentBoardActive].currentPageNo
                        ].meta.gridType,
                    stickyElements: stickyElements,
                },
            );
        }
        boardManager.sendElementOp(element, ELEMENT_OPERATIONS.REMOVE_STICKY_ELEMENT, [currentTarget]);

        lessonComponent.shallowStickyElementSync();
        if (window.checkIfStickyElementsToolPicker) {
            window.checkIfStickyElementsToolPicker();
        }
    }
};

boardManager.updateLocalStickyTextInfo = async (id, text, x, y, w, h, color, weight) => {
    if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
        let board = boardManager.getCurrentMainBoard();
        if (!board) {
            console.error('board is not present', boardManager.lessonState.mainBoardId);
            return;
        }

        let boardElements = board.meta;
        let idx = boardElements.stickyElements.findIndex((el) => el.id === id);
        if (idx >= 0) {
            boardElements.stickyElements[idx] = updateTextElementData(
                boardElements.stickyElements[idx],
                text,
                x,
                y,
                w,
                h,
                weight,
                color,
            );
            boardManager.sendElementOp(boardElements.stickyElements[idx], ELEMENT_OPERATIONS.UPDATE_STICKY_ELEMENT);
            if (boardManager.isAdmin) {
                cloudBoardManager.updatePage(
                    boardManager.email,
                    boardManager.classId,
                    boardManager.lessonState.mainBoardId,
                    {
                        stickyElements: board.meta.stickyElements,
                        gridType: board.meta.gridType,
                    },
                );
            }
        }
    } else {
        let element;
        let currentTarget;
        if (boardManager.isAdmin) {
            currentTarget = boardManager.currentBoardActive;
        } else {
            currentTarget = boardManager.adminEmail;
        }
        if (!currentTarget) return;
        let boardData = boardManager.lessonState.individualBoards[currentTarget];
        let stickyElements = boardData.pages[boardData.currentPageNo].meta.stickyElements;
        element = stickyElements.find((el) => el.id === id);
        if (!element) {
            return;
        }
        element = updateTextElementData(element, text, x, y, w, h, weight, color);
        // boardManager.lessonState.individualBoards[currentTarget].stickyElements = stickyElements;

        if (!boardManager.isAdmin) {
            let b = boardManager.lessonState.individualBoards[boardManager.currentBoardActive];
            cloudBoardManager.updateMultiBoardPage(
                boardManager.currentBoardActive,
                boardManager.classId,
                boardManager.lessonState.multiBoardId.split('_')[0],
                boardManager.adminEmail,
                b.pages[b.currentPageNo].id,
                {
                    gridType: b.pages[b.currentPageNo].meta.gridType,
                    stickyElements: stickyElements,
                },
            );
        }
        boardManager.sendElementOp(element, ELEMENT_OPERATIONS.UPDATE_STICKY_ELEMENT, [currentTarget]);
    }
    lessonComponent.shallowStickyElementSync();
};

boardManager.updateLocalStickyLatexInfo = async (id, latex, x, y, w, h, color, weight) => {
    if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
        let board = boardManager.getCurrentMainBoard();
        if (!board) {
            console.error('board is not present', boardManager.lessonState.mainBoardId);
            return;
        }

        let boardElements = board.meta;
        let idx = boardElements.stickyElements.findIndex((el) => el.id === id);
        if (idx >= 0) {
            boardElements.stickyElements[idx] = updateLatexElementData(
                boardElements.stickyElements[idx],
                latex,
                x,
                y,
                w,
                h,
                weight,
                color,
            );
            removeLatexFromWindowCache(id);
            boardManager.sendElementOp(boardElements.stickyElements[idx], ELEMENT_OPERATIONS.UPDATE_STICKY_ELEMENT);
            if (boardManager.isAdmin) {
                cloudBoardManager.updatePage(
                    boardManager.email,
                    boardManager.classId,
                    boardManager.lessonState.mainBoardId,
                    {
                        stickyElements: board.meta.stickyElements,
                        gridType: board.meta.gridType,
                    },
                );
            }
        }
    } else {
        let element;
        let currentTarget;
        if (boardManager.isAdmin) {
            currentTarget = boardManager.currentBoardActive;
        } else {
            currentTarget = boardManager.adminEmail;
        }
        if (!currentTarget) return;
        let boardData = boardManager.lessonState.individualBoards[currentTarget];
        let stickyElements = boardData.pages[boardData.currentPageNo].meta.stickyElements;
        element = stickyElements.find((el) => el.id === id);
        if (!element) {
            return;
        }
        element = updateLatexElementData(element, latex, x, y, w, h, weight, color);
        removeLatexFromWindowCache(id);

        if (!boardManager.isAdmin) {
            let b = boardManager.lessonState.individualBoards[boardManager.currentBoardActive];
            cloudBoardManager.updateMultiBoardPage(
                boardManager.currentBoardActive,
                boardManager.classId,
                boardManager.lessonState.multiBoardId.split('_')[0],
                boardManager.adminEmail,
                b.pages[b.currentPageNo].id,
                {
                    gridType: b.pages[b.currentPageNo].meta.gridType,
                    stickyElements: stickyElements,
                },
            );
        }
        boardManager.sendElementOp(element, ELEMENT_OPERATIONS.UPDATE_STICKY_ELEMENT, [currentTarget]);
    }
    lessonComponent.shallowStickyElementSync();
};

boardManager.switchMultiBoardPageForUser = async (pageNo) => {
    if (boardManager.isAdmin) return;
    if (boardManager.lessonState.boardType !== BOARD_TYPE.MULTI_BOARD) return;
    store.dispatch(showSpinner());
    // console.log('=== saving at switch');
    let success = await cloudBoardManager.saveCurrentMultiBoardUserPage();
    if (!success) {
        store.dispatch(showError('GENERIC_ERROR'));
        return;
    }
    // if (drawUtils.getCanvas(boardManager.adminEmail)) {
    //     let ctx = drawUtils.getCanvas(boardManager.adminEmail).getContext('2d');
    //     ctx.clearRect(0, 0, drawUtils.CANVAS_ACTUAL_WIDTH, drawUtils.CANVAS_ACTUAL_HEIGHT);
    // }
    // if (boardManager.lessonState.individualBoards[])
    let isNewPage = false;
    if (boardManager.lessonState.individualBoards[boardManager.adminEmail].pages.length <= pageNo) {
        boardManager.lessonState.individualBoards[
            boardManager.adminEmail
            ].pages = await cloudBoardManager.newMultiBoardPage(
            boardManager.email,
            boardManager.classId,
            boardManager.lessonState.multiBoardId.split('_')[0],
            boardManager.adminEmail,
        );
        // if (drawUtils.getCanvas(boardManager.adminEmail)) {
        //     let ctx = drawUtils.getCanvas(boardManager.adminEmail).getContext('2d');
        //     ctx.clearRect(0, 0, drawUtils.CANVAS_ACTUAL_WIDTH, drawUtils.CANVAS_ACTUAL_HEIGHT);
        // }
        isNewPage = true;
    }
    boardManager.lessonState.individualBoards[boardManager.adminEmail].currentPageNo = pageNo;

    socketUtils.sendTargetedRequest(
        [boardManager.adminEmail],
        TARGETED_REQUESTS.SWITCH_USER_MULTIBOARD_PAGE,
        boardManager.lessonState.individualBoards[boardManager.adminEmail],
    );

    try {
        if (!isNewPage) {
            let success = await boardManager.loadImageInTargetCanvas(
                boardManager.adminEmail,
                boardManager.lessonState.individualBoards[boardManager.adminEmail].pages[pageNo].download,
            );
            if (!success) {
                if (window[`targetMultiBoardStatusRefresh${boardManager.adminEmail}`]) {
                    window[`targetMultiBoardStatusRefresh${boardManager.adminEmail}`](
                        boardManager.lessonState.individualBoards[boardManager.adminEmail],
                    );
                }
                return boardManager.switchMultiBoardPageForUser(pageNo);
            }
        }
    } catch (err) {
        console.error('Unable to load image in target', boardManager.adminEmail, err);
    }

    store.dispatch(hideSpinner());
    if (window.updateBoardNavigation) {
        window.updateBoardNavigation();
    }
    lessonComponent.shallowStickyElementSync();
    if (window[`targetMultiBoardStatusRefresh${boardManager.adminEmail}`]) {
        window[`targetMultiBoardStatusRefresh${boardManager.adminEmail}`](
            boardManager.lessonState.individualBoards[boardManager.adminEmail],
        );
    }
    // console.log('switching multiboard page', pageNo);
};

boardManager.clearMultiBoardPageForUser = async () => {
    await lessonComponent.asyncClearImage(true);
    await boardManager.clearElements(boardManager.adminEmail);
    let currentBoard = boardManager.lessonState.individualBoards[boardManager.adminEmail];
    currentBoard.pages[currentBoard.currentPageNo].meta.stickyElements = [];
    currentBoard.pages[currentBoard.currentPageNo].meta.gridType = LINEAGE_NONE;

    await cloudBoardManager.updateMultiBoardPage(
        boardManager.email,
        boardManager.classId,
        boardManager.lessonState.multiBoardId.split('_')[0],
        boardManager.adminEmail,
        currentBoard.pages[currentBoard.currentPageNo].id,
        {
            gridType: LINEAGE_NONE,
            stickyElements: [],
        },
    );

    await cloudBoardManager.saveCurrentMultiBoardUserPage();
    lessonComponent.shallowStickyElementSync();
    store.dispatch(hideSpinner());
};

boardManager.switchMultiBoardPageForAdmin = async (target, data) => {
    //TODO
    // console.log('=== got switch from user', target, pageNo, boardManager.isAdmin, boardManager.lessonState.individualBoards[target]);
    // console.log('=== original multiboard state', boardManager.originalMultiBoardState);
    // console.log('=== current boards', boardManager.lessonState.individualBoards);
    // console.log('=== current page', boardManager.currentLessonPage);
    // console.log('=== main board', boardManager.mainBoard);
    // console.log('=== current lineage', boardManager.currentLineage);
    // console.log('=== root sticky', boardManager.stickyElements);
    if (!boardManager.isAdmin) return;
    if (boardManager.lessonState.boardType !== BOARD_TYPE.MULTI_BOARD) return;
    if (!target) return;
    boardManager.lessonState.individualBoards[target] = { pages: data.pages, currentPageNo: data.currentPageNo };
    let pageNo = boardManager.lessonState.individualBoards[target].currentPageNo;
    // console.log('====', target, boardManager.lessonState.individualBoards[target]);
    // let canvas = drawUtils.getCanvas(target);
    // if (canvas) {
    //     let ctx = canvas.getContext('2d');
    //     await drawUtils.asyncClearCanvas(ctx);
    // }
    await boardManager.loadImageInTargetCanvas(
        target,
        boardManager.lessonState.individualBoards[target].pages[pageNo].download,
    );
    if (
        boardManager.multiBoardRefs &&
        boardManager.multiBoardRefs[target] &&
        boardManager.multiBoardRefs[target].current
    ) {
        // console.log('===', target, pageNo);
        if (pageNo > 0) {
            boardManager.multiBoardRefs[target].current.setFixedStickyElements([]);
            boardManager.multiBoardRefs[target].current.setFixedWritingUrl(null);
        } else {
            boardManager.multiBoardRefs[target].current.setFixedStickyElements(
                boardManager.lessonState.originalMultiBoardState.originalSticky,
            );
            boardManager.multiBoardRefs[target].current.setFixedWritingUrl(
                boardManager.lessonState.originalMultiBoardState.originalDownload,
            );
        }
        boardManager.multiBoardRefs[target].current.setLineage(
            boardManager.lessonState.individualBoards[target].pages[pageNo].meta.gridType,
        );
    }
    if (window.updateBoardNavigation) {
        window.updateBoardNavigation();
    }
    if (window.checkIfStickyElementsToolPicker) {
        window.checkIfStickyElementsToolPicker();
    }
    if (window[`targetMultiBoardPageCount${target}`]) {
        window[`targetMultiBoardPageCount${target}`]();
    }

    if (window[`targetMultiBoardStatusRefresh${target}`]) {
        window[`targetMultiBoardStatusRefresh${target}`](
            boardManager.lessonState.individualBoards[target],
        );
    }


    store.dispatch(showStaticPageNavigator(null));
    boardManager.refreshStickyElements();
};

boardManager.clearMultiBoardPageForAdmin = async (target) => {
    await lessonComponent.asyncClearImage(true, target);
    await boardManager.clearElements(target);
    boardManager.clearLinePoints(null, null, null);
    let currentBoard = boardManager.lessonState.individualBoards[target];
    currentBoard.pages[currentBoard.currentPageNo].meta.stickyElements = [];
    currentBoard.pages[currentBoard.currentPageNo].meta.gridType = LINEAGE_NONE;

    if (window[`targetMultiBoardStatusRefresh${target}`]) {
        window[`targetMultiBoardStatusRefresh${target}`](boardManager.lessonState.individualBoards[target]);
    }
};
boardManager.switchMultiBoardLineageForAdmin = async (target, lineage) => {
    if (boardManager.lessonState.individualBoards[target]) {
        let tg = boardManager.lessonState.individualBoards[target];
        tg.pages[tg.currentPageNo].meta.gridType = lineage;
        // lessonComponent.shallowStickyElementSync();
        if (!boardManager.isAdmin) {
            await cloudBoardManager.updateMultiBoardPage(
                boardManager.currentBoardActive,
                boardManager.classId,
                boardManager.lessonState.multiBoardId.split('_')[0],
                boardManager.adminEmail,
                tg.pages[tg.currentPageNo].id,
                {
                    gridType: tg.pages[tg.currentPageNo].meta.gridType,
                    stickyElements: tg.pages[tg.currentPageNo].meta.stickyElements,
                },
            );
        }

        if (boardManager.multiBoardRefs[target]) {
            boardManager.multiBoardRefs[target].current.setLineage(lineage);
        }
    }
};

boardManager.decoratePages = async (target) => {
    if (
        boardManager.lessonState.individualBoards[target].pages &&
        boardManager.lessonState.individualBoards[target].pages.length > 0
    ) {
        for (let i = 0; i < boardManager.lessonState.individualBoards[target].pages.length; i++) {
            let el = boardManager.lessonState.individualBoards[target].pages[i];
            let url = getUrlFromWindowCache(
                boardManager.email,
                boardManager.classId,
                boardManager.lessonState.multiBoardId,
                el.id,
            );
            if (url) {
                boardManager.lessonState.individualBoards[target].pages[i].url = url;
            } else {
                // let userBoardData = await lessonList.getMultiBoardLessonDataForUser(
                //     boardManager.email,
                //     boardManager.classId,
                //     boardManager.lessonState.multiBoardId,
                //     target,
                //     i
                // );
                // let uploadRes;
                // if (userBoardData) {
                //     // console.log('=== updating for', target, i, userBoardData.id);
                //     uploadRes = await storageApi.uploadTempImage(boardManager.classId, userBoardData.data);
                //     addToWindowCache(
                //         boardManager.email,
                //         boardManager.classId,
                //         boardManager.lessonState.multiBoardId,
                //         userBoardData.id,
                //         uploadRes.data.download
                //     );
                //     boardManager.lessonState.individualBoards[target].pages[i].url = uploadRes.data.download;
                // }
            }
        }
    }
};

boardManager.populateMultiBoardUserPages = async (target) => {
    // console.log('=== trying to populate for', target, boardManager.isAdmin, boardManager.lessonState.individualBoards);
    if (!boardManager.isAdmin) return;
    if (boardManager.lessonState.boardType !== BOARD_TYPE.MULTI_BOARD) return;
    if (!target || !boardManager.lessonState.individualBoards[target]) return false;
    // if (boardManager.lessonState.individualBoards[target].pages) return;

    let multiboardData = await cloudBoardManager.getMultiBoardUserPages(
        target,
        boardManager.classId,
        boardManager.lessonState.multiBoardId.split('_')[0],
        boardManager.email,
    );
    let multiboardPages = multiboardData.pages;

    // let multiboardPages = lessonList.getMultiBoardElementsForUser(
    //     boardManager.email,
    //     boardManager.classId,
    //     boardManager.lessonState.multiBoardId,
    //     target
    // );
    if (!multiboardPages) return false;
    // console.log('=== about trying to populate for', target);
    for (let i = 0; i < multiboardPages.length; i++) {
        let url = getUrlFromWindowCache(
            boardManager.email,
            boardManager.classId,
            boardManager.lessonState.multiBoardId,
            multiboardPages[i].id,
        );
        if (!url) {
            // let userBoardData = await lessonList.getMultiBoardLessonDataForUser(
            //     boardManager.email,
            //     boardManager.classId,
            //     boardManager.lessonState.multiBoardId,
            //     target,
            //     i
            // );
            // if (!userBoardData) {
            //     console.log('==== bad juju, no board data for', target);
            //     return false;
            // }
            // let uploadRes = await storageApi.uploadTempImage(boardManager.classId, userBoardData.data);
            // addToWindowCache(
            //     boardManager.email,
            //     boardManager.classId,
            //     boardManager.lessonState.multiBoardId,
            //     multiboardPages[i].id,
            //     uploadRes.data.download
            // );
            // url = uploadRes.data.download;
        }
        multiboardPages[i].url = url;
    }
    boardManager.lessonState.individualBoards[target].pages = multiboardPages;
    return true;
    // console.log('populating multiBoardPages', target, multiboardPages);
};

function updateTextElementData(element, text, x, y, w, h, weight, color) {
    if (text) {
        element.text = text;
    }
    if (x) {
        element.x = x;
    }
    if (y) {
        element.y = y;
    }
    if (w) {
        element.width = w;
    }
    if (h) {
        element.height = h;
    }
    if (color) {
        element.color = color;
    }
    if (weight) {
        element.weight = weight;
    }
    return element;
}

function updateLatexElementData(element, latex, x, y, w, h, weight, color) {
    if (latex) {
        element.latex = latex;
    }
    if (x) {
        element.x = x;
    }
    if (y) {
        element.y = y;
    }
    if (w) {
        element.width = w;
    }
    if (h) {
        element.height = h;
    }
    if (color) {
        element.color = color;
    }
    if (weight) {
        element.weight = weight;
    }
    return element;
}

function updateImageElementData(element, x, y, w, h) {
    if (x !== null && x !== undefined) {
        element.x = x;
    }
    if (y !== null && y !== undefined) {
        element.y = y;
    }
    if (w) {
        element.width = w;
    }
    if (h) {
        element.height = h;
    }
    return element;
}

boardManager.loadImageInTargetCanvas = async (targetEmail, url, retried = false) => {
    if (targetEmail && url) {
        let canvas = boardManager.getUserCanvas(targetEmail);
        if (!canvas) {
            console.error('==== canvas not found for', targetEmail);
            if (!retried) {
                return new Promise((resolve) => {
                    setTimeout(() => {
                        resolve(boardManager.loadImageInTargetCanvas(targetEmail, url, true));
                    }, 200);
                });
            } else {
                return false;
            }
        }
        if (!window.targetCanvasLoadCache) {
            window.targetCanvasLoadCache = {};
        }
        if (window.targetCanvasLoadCache[canvas.id]) {
            return true;
        }
        let img = await drawUtils.buildImageFromUrlNoCache(url);
        let ctx = canvas.getContext('2d');
        if (img) {
            boardManager.lessonState.individualBoards[targetEmail].isBusy = true;
            // ctx.clearRect(0, 0, drawUtils.CANVAS_ACTUAL_WIDTH, drawUtils.CANVAS_ACTUAL_HEIGHT);
            // await sleep(200);
            ctx.imageSmoothingEnabled = true;
            ctx.imageSmoothingQuality = 'high';
            ctx.drawImage(img, 0, 0, drawUtils.CANVAS_ACTUAL_WIDTH, drawUtils.CANVAS_ACTUAL_HEIGHT);
            window.targetCanvasLoadCache[canvas.id] = true;
            await sleep(200);
            boardManager.lessonState.individualBoards[targetEmail].isBusy = false;
            return true;
        } else {
            window.targetCanvasLoadCache[canvas.id] = true;
            return false;
        }
    } else {
        console.error('===== no target or url');
        return Promise.reject();
    }
};

boardManager.getBoardType = () => {
    return boardManager.lessonState.boardType;
};

boardManager.getCurrentMainBoard = () => {
    if (!boardManager.lessonState) return null;
    let idx = boardManager.lessonState.mainBoardPages.findIndex((b) => b.id === boardManager.lessonState.mainBoardId);
    if (idx >= 0) {
        return boardManager.lessonState.mainBoardPages[idx];
    } else {
        return null;
    }
};

boardManager.getCurrentMainBoardIndex = () => {
    return boardManager.lessonState.mainBoardPages.findIndex((b) => b.id === boardManager.lessonState.mainBoardId);
};

const saveFunctionForInterval = async () => {
    // let storeState = store.getState();
    // console.log(boardManager.mainBoard);

    // let shouldStillBeSaving = true;
    // console.log('=== starting saving loop');
    if (boardManager.pageSavingInProgress) return;
    boardManager.pageSavingInProgress = true;

    while (true) {
        await sleep(PAGE_SAVE_INTERVAL_SECS * 1000);
        // if (!boardManager.isAdmin) {
        //     continue;
        // }
        if (!boardManager.lessonState) continue;

        if (boardManager.isAdmin && boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
            if (boardManager.mainBoard.isCurrentPageDirty && boardManager.getCurrentMainBoard()) {
                if (drawUtils.getCanvas()) {
                    lessonList.saveStageForUndo(
                        boardManager.lessonState.mainBoardId,
                        drawUtils.getCanvas().toDataURL('image/png', 1),
                    );
                }

                await cloudBoardManager.saveCurrentMainPage();
            }
        }

        if (!boardManager.isAdmin && boardManager.lessonState.boardType === BOARD_TYPE.MULTI_BOARD) {
            if (boardManager.lessonState.individualBoards[boardManager.adminEmail].isCurrentPageDirty) {
                if (drawUtils.getCanvas(boardManager.adminEmail)) {
                    await cloudBoardManager.saveCurrentMultiBoardUserPage();
                } else {
                    boardManager.lessonState.individualBoards[boardManager.adminEmail].isCurrentPageDirty = false;
                }
            }
        }
    }
};

// const saveAllMultiBoards = async () => {
//     let arr = Object.keys(boardManager.lessonState.individualBoards);
//     for (let i = 0; i < arr.length; i++) {
//         await boardManager.saveTargetedMultiBoard(arr[i]);
//     }
//     // console.log('completed save');
// };

// boardManager.saveTargetedMultiBoard = async (target) => {
//     let pageNo = boardManager.lessonState.individualBoards[target].currentPageNo;
//     if (pageNo === null) {
//         pageNo = 0;
//     }
//     await lessonList.updateMultiBoardLessonForUser(
//         boardManager.email,
//         boardManager.classId,
//         boardManager.lessonState.multiBoardId,
//         { email: target, name: boardManager.lessonState.individualBoards[target].name },
//         pageNo
//     );
//     removeFromWindowCache(
//         boardManager.email,
//         boardManager.classId,
//         boardManager.lessonState.multiBoardId,
//         boardManager.lessonState.individualBoards[target].boardId
//     );
//
//     boardManager.lessonState.individualBoards[target].isCurrentPageDirty = false;
//     // boardManager.lessonState.individualBoards[target].isCurrentPageDirty = false;
// };

boardManager.getUserCanvasData = (email) => {
    return boardManager.getUserCanvas(email).toDataURL('image/png', 1);
};

boardManager.getUserCanvas = (email) => {
    return boardManager.getCurrentCanvas(email);
    // return document.getElementById('multiBoardChildCanvas-' + email);
};

boardManager.createCanvasElement = (email) => {
    let canvasElement = document.createElement('canvas');
    canvasElement.setAttribute('id', 'multiBoardChildCanvas-' + email);
    canvasElement.setAttribute('width', drawUtils.CANVAS_ACTUAL_WIDTH);
    canvasElement.setAttribute('height', drawUtils.CANVAS_ACTUAL_HEIGHT);
    canvasElement.style.width = '100%';
    // canvasElement.style.height = '100%';
    // canvasElement.style.position = 'absolute';
    // canvasElement.style['box-shadow'] =
    //     '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)';
    return canvasElement;
};

boardManager.getCurrentCanvas = (target) => {
    if (!boardManager.lessonState) return null;
    if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
        return document.getElementById('canvas');
    } else {
        let storeState = store.getState();
        if (storeState.lessonState.isAdmin) {

            let t = target;
            if (!t) {
                t = boardManager.currentBoardActive;
            }
            if (!t) {
                return null;
            }

            let p = null;
            if (boardManager?.lessonState?.individualBoards[t]?.pages &&
                boardManager.lessonState.individualBoards[t].pages[boardManager.lessonState.individualBoards[t].currentPageNo]
            ) {
                p = boardManager.lessonState.individualBoards[t].pages[boardManager.lessonState.individualBoards[t].currentPageNo].id;
            }
            if (!p) return null;

            return document.getElementById('multiBoardChildCanvas-' + t + '-' + p);
        } else {
            if (
                storeState.lessonState &&
                storeState.lessonState.lessonInfo &&
                storeState.lessonState.lessonInfo.classData
            ) {
                let admin = storeState.lessonState.lessonInfo.classData.adminEmail;
                if (boardManager?.lessonState?.individualBoards[admin]?.pages &&
                    boardManager.lessonState.individualBoards[admin].pages[boardManager.lessonState.individualBoards[admin].currentPageNo]
                ) {
                    return document.getElementById(
                        'multiBoardChildCanvas-' + storeState.lessonState.lessonInfo.classData.adminEmail + '-' + boardManager.lessonState.individualBoards[admin].pages[boardManager.lessonState.individualBoards[admin].currentPageNo].id,
                    );
                } else {
                    return null;
                }
            } else {
                return null;
            }
        }
    }
};

boardManager.getCurrentWritingSvgPath = (target) => {
    if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
        return document.getElementById('currentWritingMain');
    } else {
        let storeState = store.getState();
        if (storeState.lessonState.isAdmin) {
            if (target) {
                return document.getElementById('currentWritingMulti-' + target);
            }
            if (boardManager.currentBoardActive) {
                return document.getElementById('currentWritingMulti-' + boardManager.currentBoardActive);
            } else {
                return null;
            }
        } else {
            if (
                storeState.lessonState &&
                storeState.lessonState.lessonInfo &&
                storeState.lessonState.lessonInfo.classData
            ) {
                return document.getElementById(
                    'currentWritingMulti-' + storeState.lessonState.lessonInfo.classData.adminEmail,
                );
            } else {
                return null;
            }
        }
    }
};

boardManager.getCurrentWritingSvgContainer = (target) => {
    if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
        return document.getElementById('main-svg-container');
    } else {
        let storeState = store.getState();
        if (storeState.lessonState.isAdmin) {
            if (target) {
                return document.getElementById('multi-svg-container-' + target);
            }
            if (boardManager.currentBoardActive) {
                return document.getElementById('multi-svg-container-' + boardManager.currentBoardActive);
            } else {
                return null;
            }
        } else {
            if (
                storeState.lessonState &&
                storeState.lessonState.lessonInfo &&
                storeState.lessonState.lessonInfo.classData
            ) {
                return document.getElementById(
                    'multi-svg-container-' + storeState.lessonState.lessonInfo.classData.adminEmail,
                );
            } else {
                return null;
            }
        }
    }
};

boardManager.getCurrentCanvasContainer = (email) => {
    if (!boardManager.lessonState) return null;
    if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
        return document.getElementById('canvasContainer');
    } else {
        if (boardManager.isAdmin) {
            if (email) {
                let element = document.getElementById('multiBoardElement-' + email);
                if (element) {
                    return element;
                } else {
                    return null;
                }
            } else if (boardManager.currentBoardActive) {
                return document.getElementById('multiBoardElement-' + boardManager.currentBoardActive);
            } else {
                return null;
            }
        } else {
            let element = document.getElementById('multiBoardElement-' + boardManager.adminEmail);
            if (element) {
                return element;
            } else {
                return null;
            }
        }
    }
};

boardManager.getCurrentEraserOverlay = () => {
    return document.getElementById('eraserOverlay');
};

boardManager.shouldHandleEventsForMulti = () => {
    if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
        return false;
    } else {
        return !!boardManager.currentBoardActive;
    }
};

boardManager.getCurrentCanvasContext = () => {
    let canvas = boardManager.getCurrentCanvas();
    if (!canvas) return null;
    return canvas.getContext('2d');
};

boardManager.setCurrentBoardActive = (email) => {
    boardManager.currentBoardActive = email;
    lessonComponent.setPointerForTool(store.getState()?.drawing?.tool);
    drawUtils.validateHorizontalPosition();
    if (window.setChatTarget) {
        window.setChatTarget(email);
    }
};

boardManager.refreshStickyElements = () => {
    // console.log('==== should be shallow synking elements', JSON.stringify(boardManager.multiBoardRefs));
    let somethingFailed = false;
    if (!boardManager.multiBoardRefs) return;

    Object.keys(boardManager.multiBoardRefs).forEach((el) => {
        if (!boardManager.multiBoardRefs[el].current) {
            console.error(
                'Failed sticky elements refresh for ',
                el,
                boardManager.multiBoardRefs[el],
                boardManager.lessonState,
            );
            somethingFailed = true;
            return;
        }
        let boardState = boardManager.lessonState.individualBoards[el];
        if (!boardState) {
            boardManager.multiBoardRefs[el].current.setFixedStickyElements(
                boardManager.lessonState.originalMultiBoardState.originalSticky,
            );
            boardManager.multiBoardRefs[el].current.setFixedWritingUrl(
                boardManager.lessonState.originalMultiBoardState.originalDownload,
            );
            return;
        }
        let currentPageNo = boardManager.lessonState.individualBoards[el]?.currentPageNo;

        if (boardState.currentPageNo === 0) {
            // console.log('==== synking ', el, boardManager.lessonState.originalMultiBoardState);
            boardManager.multiBoardRefs[el].current.setFixedStickyElements(
                boardManager.lessonState.originalMultiBoardState.originalSticky,
            );
            boardManager.multiBoardRefs[el].current.setFixedWritingUrl(
                boardManager.lessonState.originalMultiBoardState.originalDownload,
            );
        } else {
            boardManager.multiBoardRefs[el].current.setFixedStickyElements([]);
            boardManager.multiBoardRefs[el].current.setFixedWritingUrl(null);
            // console.log('=== calliong shallow synk on', el, boardManager.lessonState.individualBoards);
        }
        // console.log('=== smt', boardState, currentPageNo);
        if (
            boardState &&
            boardState.pages &&
            boardState.pages.length > 0 &&
            boardState.pages[currentPageNo] &&
            boardState.pages[currentPageNo].meta
        ) {
            boardManager.multiBoardRefs[el].current.setLineage(boardState.pages[currentPageNo].meta.gridType);
            boardManager.multiBoardRefs[el].current.shallowSyncPageElements(
                boardState.pages[currentPageNo].meta.stickyElements,
            );
        }
        // console.log(
        //     '==== should be updating',
        //     el,
        //     boardManager.lessonState.individualBoards[el].currentPageNo,
        //     boardManager.lessonState.individualBoards[el].pages[boardManager.lessonState.individualBoards[el].currentPageNo].stickyElements
        // );
    });
    return somethingFailed;
};

boardManager.stopDrawing = () => {
    if (lessonComponent) {
        lessonComponent.isDrawing = false;
    }
};

boardManager.getLineageForTarget = (target, pageNo) => {
    let tg = boardManager.lessonState.individualBoards[target];
    if (tg) {
        return tg.pages[tg.currentPageNo].meta.gridType;
    } else {
        return LINEAGE_NONE;
    }
};

boardManager.isCurrentPageDirty = (email) => {
    if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
        return boardManager.mainBoard.isCurrentPageDirty;
    } else {
        if (boardManager.lessonState.individualBoards[email]) {
            return boardManager.lessonState.individualBoards[email].isCurrentPageDirty;
        } else {
            if (
                boardManager.currentBoardActive &&
                boardManager.lessonState.individualBoards[boardManager.currentBoardActive]
            ) {
                return boardManager.lessonState.individualBoards[boardManager.currentBoardActive].isCurrentPageDirty;
            }
        }
    }
    return false;
};

boardManager.setDirtyFlag = (email) => {
    if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
        boardManager.mainBoard.isCurrentPageDirty = true;
    } else {
        if (boardManager.lessonState.individualBoards[email]) {
            boardManager.lessonState.individualBoards[email].isCurrentPageDirty = true;
        } else {
            if (
                boardManager.currentBoardActive &&
                boardManager.lessonState.individualBoards[boardManager.currentBoardActive]
            ) {
                boardManager.lessonState.individualBoards[boardManager.currentBoardActive].isCurrentPageDirty = true;
            }
        }
    }
};

boardManager.setDirtyFlagForRemote = (email) => {
    if (boardManager.lessonState.individualBoards[email]) {
        boardManager.lessonState.individualBoards[email].isCurrentPageDirty = true;
    }
};

boardManager.closeAndClean = () => {
    if (boardManager.pageSaveInterval) {
        clearInterval(boardManager.pageSaveInterval);
    }

    // if (boardManager.lessonState?.individualBoards) {
    //     Object.keys(boardManager.lessonState.individualBoards).forEach((el) => {
    //         if (boardManager.lessonState.individualBoards[el] && boardManager.lessonState.individualBoards[el].pageSaveInterval) {
    //             clearInterval(boardManager.lessonState.individualBoards[el].pageSaveInterval);
    //         }
    //     });
    // }
};

boardManager.buildTip1 = (clear = true, email) => {
    buildGenericTip(clear, email, drawUtils.drawTip1, LINEAGE_TYPE_1);
};
boardManager.buildTip2 = (clear = true, email) => {
    buildGenericTip(clear, email, drawUtils.drawTip2, LINEAGE_TYPE_2);
};
boardManager.buildDictando = (clear = true, email) => {
    buildGenericTip(clear, email, drawUtils.drawDictando, LINEAGE_DICATANDO);
};
boardManager.buildMath = (clear = true, email) => {
    buildGenericTip(clear, email, drawUtils.drawMathGrid, LINEAGE_MATH);
};
boardManager.buildMusic = (clear = true, email) => {
    buildGenericTip(clear, email, drawUtils.drawMusic, LINEAGE_MUSIC);
};
boardManager.buildMM = (clear = true, email) => {
    buildGenericTip(clear, email, drawUtils.drawMM, LINEAGE_MM);
};

const buildGenericTip = (clear, email, tipFunc, lineageType) => {
    if (clear) boardManager.clearImage(false, email);

    boardManager.setLineage(lineageType, email);

    let canvas;
    if (email) {
        canvas = boardManager.getUserCanvas(email);
    } else {
        canvas = drawUtils.getCanvas();
    }
    if (!canvas) {
        console.log('=== unable to draw lineage no canvas present', email);
        return;
    }

    let ctx = canvas.getContext('2d');

    tipFunc(ctx);
    tipFunc(ctx);

    if (clear) boardManager.sendImageData(true);
};

boardManager.clearImage = (force = false, email) => {
    let storeState = store.getState();
    if (!storeState.lessonState.allowDrawing && !force) return;

    if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
        let ctx = boardManager.getCurrentCanvasContext();
        if (ctx) {
            ctx.clearRect(0, 0, drawUtils.getCanvas().width, drawUtils.getCanvas().height);
        }
    } else {
        // let em = email ? email : boardManager.currentBoardActive;
        // if (em && boardManager.lessonState.individualBoards[em]) {
        //     let ctx = boardManager.getUserCanvas(em).getContext('2d');
        //     ctx.clearRect(0, 0, drawUtils.getCanvas().width, drawUtils.getCanvas().height);
        // }
    }
};

boardManager.setStickyElements = () => {
};

boardManager.sendElementOp = (element, op, emails = null) => {
    if (!emails) {
        socketUtils.sendElementOp(element, op);
    } else {
        socketUtils.sendTargetedElementOp(emails, element, op);
    }
};

boardManager.setLineage = (lineage = LINEAGE_NONE, email = null) => {
    if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
        let mainBoard = boardManager.getCurrentMainBoard();
        if (mainBoard) {
            mainBoard.meta.gridType = lineage;
        }
    } else {
        let targetEmail = email ? email : boardManager.currentBoardActive;
        // console.log(
        //     '==== setting lineage to target email',
        //     targetEmail,
        //     JSON.stringify(boardManager.lessonState.individualBoards[targetEmail])
        // );
        if (targetEmail && boardManager.lessonState.individualBoards[targetEmail]) {
            let b = boardManager.lessonState.individualBoards[targetEmail];
            b.pages[b.currentPageNo].meta.gridType = lineage;
        }
        // console.log(
        //     '==== after setting lineage to target email',
        //     targetEmail,
        //     JSON.stringify(boardManager.lessonState.individualBoards[targetEmail])
        // );
    }
    // console.log('mainboard is now', boardManager.mainBoard);
};

boardManager.getLineage = (email) => {
    if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
        // console.log('==== getting current lineage for main board', boardManager.mainBoard.currentLineage);
        return boardManager.mainBoard.currentLineage;
    } else {
        let targetEmail = email ? email : boardManager.currentBoardActive;
        // console.log('==== target email for lineage', targetEmail, boardManager.lessonState.individualBoards[targetEmail]);
        if (targetEmail && boardManager.lessonState.individualBoards[targetEmail]) {
            let b = boardManager.lessonState.individualBoards[targetEmail];
            return b.pages[b.currentPageNo].meta.gridType;
        } else {
            // console.log('==== getting current lineage fallback');
            return LINEAGE_NONE;
        }
    }
};

boardManager.getFromLinePoints = (x, y) => {
    let returnedLP = { x, y };
    let linePointId;
    if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
        linePointId = boardManager.mainBoard.boardId;
    } else {
        if (boardManager.isAdmin) {
            linePointId = `${boardManager.lessonState.multiBoardId}_${boardManager.currentBoardActive}_${0}`;
        } else {
            linePointId = `${boardManager.lessonState.multiBoardId}_${boardManager.adminEmail}_${0}`;
        }
    }
    if (boardManager.linePoints[linePointId]) {
        boardManager.linePoints[linePointId].forEach((el) => {
            let range = LINE_SNAP_PIXEL_RANGE / Math.ceil((window.zoomState.zoomLevel + 1) / 3);
            if (Math.abs(el.x - x) < range && Math.abs(el.y - y) < range) {
                returnedLP.x = el.x;
                returnedLP.y = el.y;
            }
        });
    }
    return returnedLP;
};

function generateLinePointId(target) {
    if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
        return boardManager.mainBoard.boardId;
    } else {
        if (boardManager.isAdmin) {
            if (!target) {
                return `${boardManager.lessonState.multiBoardId}_${boardManager.currentBoardActive}_${0}`;
            } else {
                return `${boardManager.lessonState.multiBoardId}_${target}_${0}`;
            }
        } else {
            return `${boardManager.lessonState.multiBoardId}_${boardManager.adminEmail}_${0}`;
        }
    }
}

boardManager.pushLinePoints = (x, y, target) => {
    let linePointId = generateLinePointId(target);

    if (boardManager.linePoints[linePointId]) {
        if (boardManager.linePoints[linePointId].findIndex((el) => el.x === x && el.y === y) < 0) {
            boardManager.linePoints[linePointId].push({ x, y });
        }
    } else {
        boardManager.linePoints[linePointId] = [{ x, y }];
    }
};

boardManager.clearLinePoints = (x, y, width, target) => {
    let linePointId = generateLinePointId(target);
    if (!x || !y || !width) {
        boardManager.linePoints[linePointId] = [];
        return;
    }

    if (boardManager.linePoints[linePointId] && boardManager.linePoints[linePointId].length > 0) {
        if (
            boardManager.linePoints[linePointId].findIndex(
                (el) => el.x <= x + width / 2 && el.x >= x - width / 2 && el.y <= y + width / 2 && el.y >= y - width / 2,
            ) >= 0
        ) {
            boardManager.linePoints[linePointId] = boardManager.linePoints[linePointId].filter(
                (el) =>
                    !(el.x <= x + width / 2 && el.x >= x - width / 2 && el.y <= y + width / 2 && el.y >= y - width / 2),
            );
        }
    }
};

boardManager.removeStickyElement = (boardElements, email, lessonId, boardId, id) => {
    if (!boardElements) {
        return;
    }
    boardElements.forEach((el, index) => {
        if (el.id === id) {
            // console.log('=== should be removing', boardId, id, boardElements.stickyElements);
            if (boardElements[index].stickyType === 'image') {
                storageApi.removeStickyImage(lessonId, boardId, boardElements[index].id).catch((el) => {
                    console.log('==== error removing the image');
                });
            }
            boardElements.splice(index, 1);
        }
    });
};

boardManager.removeStickyElementForMultiBoard = (boardElements, email, lessonId, boardId, id) => {
    if (!boardElements) {
        return;
    }
    boardElements.forEach((el, index) => {
        if (el.id === id) {
            // console.log('=== should be removing', boardId, id, boardElements.stickyElements);
            if (boardElements[index].stickyType === 'image') {
                storageApi
                    .deleteMultiBoardStickyImage(lessonId, boardId, boardElements[index].id, email)
                    .catch((el) => {
                        console.log('==== error removing the image');
                    });
            }
            boardElements.splice(index, 1);
        }
    });
};

boardManager.generateMultiBoardId = () => {
    let id = uuidv4();
    let ts = new Date().getTime();
    return id + '_' + ts;
};

boardManager.getCurrentTarget = () => {
    return boardManager.currentBoardActive;
};

export default boardManager;
