import React from 'react';
import { COLORS2 } from '../../../constants';
import { makeStyles } from '@material-ui/core/styles';
import { GoogleLoginButton } from './Buttons/GoogleLoginButton';
import { GoogleClassroomLoginButton } from './Buttons/GoogleClassroomLoginButton';
import { FacebookLoginButton } from './Buttons/FacebookLoginButton';
import { MicrosoftLoginButton } from './Buttons/MicrosoftLoginButton';
import Hidden from '@material-ui/core/Hidden';
import { ConnectAccountButton } from './Buttons/ConnectAccountButton.js';

const useStyles = makeStyles(() => ({
    loginButtonLarge: {
        width: '7rem',
        height: '5rem',
        marginRight: '1rem',
        backgroundColor: COLORS2.third,
        borderRadius: '16px',
        boxShadow: '0rem 0rem 0rem 0.5rem rgba(8, 170, 180, 0.08),' + '0rem 2rem 2rem -1rem rgba(6, 81, 86, 0.16)',
    },
    loginButtonSmall: {
        width: '4.5rem',
        height: '4.5rem',
        backgroundColor: COLORS2.third,
        color: 'white',
        borderRadius: '1rem',
        boxShadow: '0rem 0rem 0rem 0.5rem rgba(249, 162, 55, 0.15), 0rem 2rem 2rem -1rem rgba(210, 104, 40, 0.16)',
        boxSizing: 'borderBox',
        '&:hover': {
            boxShadow: 'none',
        },
        border: 'none',
    },
    linkWhiteboard: {
        width: '9rem',
        height: '4.5rem',
        backgroundColor: COLORS2.third,
        color: 'white',
        borderRadius: '1rem',
        boxShadow: '0rem 0rem 0rem 0.5rem rgba(249, 162, 55, 0.15), 0rem 2rem 2rem -1rem rgba(210, 104, 40, 0.16)',
        boxSizing: 'borderBox',
        '&:hover': {
            boxShadow: 'none',
        },
        border: 'none',
    },
    customIcon: {
        position: 'absolute',
        width: '2rem',
        height: '2rem',
        left: '1.25rem',
        top: '1.188rem',
    },
}));

export const DesktopLoginButtons = ({ handleLogin }) => {
    const classes = useStyles();

    return (
        <>
            <Hidden lgDown>
                <div>
                    <div style={{ display: 'flex' }}>
                        <GoogleLoginButton classes={classes} handleLogin={handleLogin} />
                        <GoogleClassroomLoginButton classes={classes} handleLogin={handleLogin} />
                        <FacebookLoginButton classes={classes} handleLogin={handleLogin} />
                        <MicrosoftLoginButton classes={classes} handleLogin={handleLogin} />
                    </div>
                    <div style={{ display: 'flex', textAlign: 'center', position: 'relative' }}>
                        <div style={{ paddingLeft: '0.75rem', width: '100%', position: 'absolute', top: '1.5rem' }}>
                            <ConnectAccountButton classes={classes} handleLogin={handleLogin} />
                        </div>
                    </div>
                </div>
            </Hidden>
            <Hidden xlUp>
                <div style={{ marginLeft: '4rem' }}>
                    <div style={{ display: 'flex' }}>
                        <GoogleLoginButton classes={classes} handleLogin={handleLogin} />
                        <GoogleClassroomLoginButton classes={classes} handleLogin={handleLogin} />
                    </div>
                    <div style={{ display: 'flex', marginTop: '1.5rem' }}>
                        <FacebookLoginButton classes={classes} handleLogin={handleLogin} />
                        <MicrosoftLoginButton classes={classes} handleLogin={handleLogin} />
                    </div>
                    <div style={{ display: 'flex', marginTop: '1.5rem' }}>
                        <div style={{ paddingLeft: '1.5rem', width: '100%', textAlign: 'center' }}>
                            <ConnectAccountButton classes={classes} handleLogin={handleLogin} />
                        </div>
                    </div>
                </div>
            </Hidden>
        </>
    );
};
