import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Container, Typography } from '@material-ui/core';

import CreateClassAction from '../ManageClass/CreateClassAction';

const CreateClass = () => {

    return (
        <Container maxWidth="md">
            <div className="u-fx u-fx-align-center u-mb-full">
                <Typography variant="h2">
                    <FormattedMessage id="createGroup.submit" />
                </Typography>
            </div>
            <CreateClassAction />
        </Container>
    );
};

export default injectIntl(CreateClass);
