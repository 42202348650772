import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function VerticalEllipsisIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 12C2.55219 12 3 12.4469 3 13C3 13.5531 2.55219 14 2 14C1.44781 14 1 13.5531 1 13C1 12.4469 1.44781 12 2 12ZM2 7C2.55219 7 3 7.44688 3 8C3 8.55312 2.55219 9 2 9C1.44781 9 1 8.55312 1 8C1 7.44688 1.44781 7 2 7ZM2 4C1.44781 4 1 3.55313 1 3C1 2.44781 1.44781 2 2 2C2.55219 2 3 2.44781 3 3C3 3.55313 2.55219 4 2 4Z" fill="#DAE0E5"/>
            </svg>
        </SvgIcon>
    );
}
