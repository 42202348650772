import apiUtil from './apiUtil';
let sendEmailsApi = {};

sendEmailsApi.sendInvitationToNewUserEmail = function (data) {
    let myData = {
        url: '/api/private/sendInvitationToNewUserEmail',
        method: 'POST',
        data,
    };
    return apiUtil.runRequest(myData); 
};

export default sendEmailsApi;