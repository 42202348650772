import {
    SHOW_LOADING,
    HIDE_LOADING,
    SHOW_SUCCESS,
    SHOW_WARNING,
    SHOW_INFO,
    SHOW_ERROR,
    HIDE_NOTIFICATION,
    USER_JOIN_REQUEST,
    SHARE_LESSON,
    CURRENT_REQUEST_COUNT,
    PICK_LESSON_PAGE,
    REQUEST_FULL_SCREEN,
    UPDATE_MULTI_BOARDS,
    MULTI_BOARD_LESSON_STATE,
    CALL_IN_PROGRESS,
    NEW_FEATURE,
    SHOW_STATIC_PAGE_NAVIGATOR,
    UPGRADE_SUBSCRIPTION, LIBRARY_ALREADY_SHARED, ENTER_VALID_EMAIL, ENTER_OWN_EMAIL,
    CHANGE_PREFERENCES,
} from '../actionTypes';

const initialState = {
    loading: false,
    isNotificationOpen: false,
    notificationType: 'success',
    notificationMessage: 'N',
    sharedLesson: null,
    userJoinRequest: null,
    currentRequestCount: 0,
    pickLessonPage: null,
    requestFullscreen: null,
    multiBoardState: null,
    multiBoardLessonState: null,
    callInProgress: null,
    loadingData: undefined,
    newFeature: null,
    staticPageNavigator: null,
    upgradeSubscription: null,
};

const appState = function (state = initialState, action) {
    switch (action.type) {
        case SHOW_LOADING: {
            return {
                ...state,
                loading: true,
                loadingData: action.payload,
            };
        }
        case HIDE_LOADING: {
            return {
                ...state,
                loading: false,
                loadingData: undefined,
            };
        }
        case SHOW_SUCCESS: {
            return {
                ...state,
                isNotificationOpen: true,
                notificationType: 'success',
                notificationMessage: action.payload,
            };
        }
        case SHOW_ERROR: {
            return {
                ...state,
                isNotificationOpen: true,
                notificationType: 'error',
                notificationMessage: action.payload,
            };
        }
        case SHOW_INFO: {
            return {
                ...state,
                isNotificationOpen: true,
                notificationType: 'info',
                notificationMessage: action.payload,
            };
        }
        case SHOW_WARNING: {
            return {
                ...state,
                isNotificationOpen: true,
                notificationType: 'warning',
                notificationMessage: action.payload,
            };
        }
        case HIDE_NOTIFICATION: {
            return {
                ...state,
                isNotificationOpen: false,
                notificationType: 'success',
                notificationMessage: 'N',
            };
        }
        case SHARE_LESSON: {
            return {
                ...state,
                isNotificationOpen: false,
                notificationType: 'success',
                notificationMessage: 'N',
                sharedLesson: action.payload,
            };
        }
        case UPGRADE_SUBSCRIPTION: {
            return {
                ...state,
                isNotificationOpen: false,
                notificationType: 'success',
                notificationMessage: 'N',
                upgradeSubscription: action.payload,
            };
        }
        case USER_JOIN_REQUEST: {
            return {
                ...state,
                isNotificationOpen: false,
                notificationType: 'success',
                notificationMessage: 'N',
                userJoinRequest: action.payload,
            };
        }
        case CURRENT_REQUEST_COUNT: {
            return {
                ...state,
                currentRequestCount: action.payload,
            };
        }
        case PICK_LESSON_PAGE: {
            return {
                ...state,
                isNotificationOpen: false,
                notificationType: 'success',
                notificationMessage: 'N',
                pickLessonPage: action.payload,
            };
        }
        case REQUEST_FULL_SCREEN: {
            return {
                ...state,
                requestFullscreen: action.payload,
            };
        }
        case UPDATE_MULTI_BOARDS: {
            return {
                ...state,
                multiBoardState: action.payload,
            };
        }
        case MULTI_BOARD_LESSON_STATE: {
            return {
                ...state,
                multiBoardLessonState: action.payload,
            };
        }
        case CALL_IN_PROGRESS: {
            return {
                ...state,
                callInProgress: action.payload,
            };
        }
        case NEW_FEATURE: {
            return {
                ...state,
                newFeature: action.payload,
            };
        }
        case SHOW_STATIC_PAGE_NAVIGATOR: {
            return {
                ...state,
                staticPageNavigator: action.payload,
            };
        }
        case CHANGE_PREFERENCES: {
            return {
                ...state,
                isNotificationOpen: true,
                notificationType: 'success',
                notificationMessage: action.payload,
            };
        }

        case LIBRARY_ALREADY_SHARED: {
            return {
                ...state,
                isNotificationOpen: true,
                notificationType: 'info',
                notificationMessage: action.payload,
            };
        }

        case ENTER_VALID_EMAIL: {
            return {
                ...state,
                isNotificationOpen: true,
                notificationType: 'warning',
                notificationMessage: action.payload,
            };
        }

        case ENTER_OWN_EMAIL: {
            return {
                ...state,
                isNotificationOpen: true,
                notificationType: 'warning',
                notificationMessage: action.payload,
            };
        }

        default:
            return state;
    }
};

export default appState;
