import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import BlueGroupIcon from '../../MyComponents/icons/BlueGroupIcon';
import OrangeGroupIcon from '../../MyComponents/icons/OrangeGroupIcon';
import Avatar from '@material-ui/core/Avatar';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import SortedIcon from '../../MyComponents/icons/SortedIcon';
import React from 'react';
import { StyledBadge } from '../../MyComponents/StyledBadge/StyledBadge';
import { MobilePopUpMyGroups } from '../../MyComponents/MobilePopUps/MobilePopUpMyGroups';
import { MobilePopUpGuestGroups } from '../../MyComponents/MobilePopUps/MobilePopUpGuestGroups';
import { COLORS2 } from '../../constants';

export const buildMobileMeta = (profile, email, lessonStart, localesOptions, setModalItem, isHome = false) => {
    const isMobilePopUp = true;

    return [
        {
            title: function () {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography
                                style={{
                                    color: COLORS2.virtualText,
                                    textTransform: 'uppercase',
                                    fontWeight: '500',
                                    fontSize: '0.625rem',
                                    margin: '1rem',
                                    letterSpacing: '0.16rem',
                                }}
                            >
                                <FormattedMessage id="redesign.group.label" />
                            </Typography>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                paddingRight: '1rem',
                            }}
                        >
                            <Typography
                                style={{
                                    color: COLORS2.virtualGreen,
                                    textTransform: 'uppercase',
                                    fontWeight: '500',
                                    fontSize: '0.625rem',
                                    margin: '1rem',
                                    letterSpacing: '0.16rem',
                                    textAlign: 'right',
                                }}
                            >
                                <FormattedMessage id="redesign.generic.label.lastAccessed" />
                            </Typography>
                            <SortedIcon style={{ width: '0.875rem', height: '1rem' }} />
                        </div>
                    </div>
                );
            },
            build: function (row) {
                const first_name = profile.extended.first_name;
                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            height: '5.5rem',
                            paddingLeft: '0.5rem',
                        }}
                    >
                        <div
                            onClick={() => {
                                lessonStart(row.isMine ? row.id : row.classId);
                            }}
                            style={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                cursor: 'pointer',
                            }}
                        >
                            {row.isMine ? (
                                <StyledBadge badgeContent={row.requests !== '0' ? row.requests : null}>
                                    <span
                                        style={{
                                            boxSizing: 'border-box',
                                            width: '3rem',
                                            height: '3rem',
                                            background: 'rgba(8, 170, 180, 0.08)',
                                            borderRadius: '1rem',
                                        }}
                                    >
                                        <BlueGroupIcon
                                            style={{ position: 'absolute', left: '0.75rem', top: '0.75rem' }}
                                        />
                                    </span>
                                </StyledBadge>
                            ) : (
                                <StyledBadge badgeContent={row.requests !== '0' ? row.requests : null}>
                                    <span
                                        style={{
                                            boxSizing: 'border-box',
                                            width: '3rem',
                                            height: '3rem',
                                            border: '0.063rem solid #FBF0E9',
                                            borderRadius: '1rem',
                                        }}
                                    >
                                        <OrangeGroupIcon
                                            style={{ position: 'absolute', left: '0.725rem', top: '0.7rem' }}
                                        />
                                    </span>
                                </StyledBadge>
                            )}
                            <div style={{ width: '100%' }}>
                                <div
                                    style={{
                                        paddingLeft: '1rem',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        fontSize: '1rem',
                                        letterSpacing: '-0.01rem',
                                        textTransform: 'none',
                                        color: COLORS2.darkGreen,
                                        width: '100%',
                                    }}
                                >
                                    {row.name}
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                    {row.isMine ? (
                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                            <Avatar
                                                url={profile.avatar}
                                                style={{ width: '1rem', height: '1rem', marginLeft: '1rem' }}
                                            />
                                            <span
                                                style={{
                                                    paddingLeft: '0.5rem',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    fontSize: '0.875rem',
                                                    letterSpacing: '-0.04rem',
                                                    textTransform: 'none',
                                                    color: COLORS2.darkGreen,
                                                }}
                                            >
                                                {first_name}
                                            </span>
                                        </span>
                                    ) : (
                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                            <Avatar
                                                url={row.avatar}
                                                style={{ width: '1rem', height: '1rem', marginLeft: '1rem' }}
                                            />
                                            <span
                                                style={{
                                                    paddingLeft: '0.5rem',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    fontSize: '0.875rem',
                                                    letterSpacing: '-0.01rem',
                                                    textTransform: 'none',
                                                    color: COLORS2.virtualText,
                                                }}
                                            >
                                                {row.first_name}
                                            </span>
                                        </span>
                                    )}
                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                        <FiberManualRecordIcon
                                            style={{ width: '0.325rem', height: '0.325rem', color: '#DAE0E5' }}
                                        />
                                    </span>
                                    <span
                                        style={{
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            fontSize: '0.875rem',
                                            letterSpacing: '-0.01rem',
                                            textTransform: 'none',
                                            color: COLORS2.virtualText,
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {new Date(row.lastaccess ? row.lastaccess : row.updatedAt).toLocaleDateString(
                                            localesOptions,
                                            {
                                                month: 'long',
                                                day: 'numeric',
                                                hour: 'numeric',
                                                minute: 'numeric',
                                            }
                                        )}
                                    </span>
                                </div>
                            </div>
                        </div>
                        {!isHome ? (
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                {row.isMine ? (
                                    <MobilePopUpMyGroups
                                        row={row}
                                        setModalItem={setModalItem}
                                        profile={profile}
                                        localesOptions={localesOptions}
                                        isMobilePopUp={isMobilePopUp}
                                    />
                                ) : (
                                    <MobilePopUpGuestGroups
                                        row={row}
                                        email={email}
                                        localesOptions={localesOptions}
                                        isMobilePopUp={isMobilePopUp}
                                    />
                                )}
                            </div>
                        ) : <div style={{marginRight: '0.75rem'}}/>}
                    </div>
                );
            },
        },
    ];
};
