import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { featureLinks } from '../../../common/features';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS2 } from '../../../constants';
import { FindMoreButton } from './FindMoreButton';
import { FEATURES_LINK, FOR_SCHOOLS_LINK, FOR_TUTORING_LINK } from '../../../common/urlLinks';

const useStyles = makeStyles(() => ({
    gridWrap: {
        height: '100%',
        background: 'linear-gradient(180deg, rgba(251, 248, 245, 0) 0%, #FFFFFF 93.57%)',
        borderRadius: '0rem 0rem 2rem 2rem',
        paddingTop: '3.5rem',
    },
    paperPadding: {
        padding: '1rem',
        paddingBottom: '3rem',
    },
    columnFlexCenter: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    displayFeature: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
    },
    mobileH2: {
        fontSize: '2.75rem',
        lineHeight: '3.25rem',
    },
    mobileH3: {
        fontSize: '2rem',
        lineHeight: '2.5rem',
    },
    mobileH4: {
        fontSize: '1.5rem',
        lineHeight: '2rem',
    },
    mobileH6: {
        color: COLORS2.virtualText,
    },
    findOutMoreText: {
        color: COLORS2.virtualAmber,
        fontStyle: 'normal',
        fontWeight: '700',
    },
}));

export const HomeFeaturesMobile = () => {
    const classes = useStyles();

    const transitionToTop = () => {
        setTimeout(() => {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }, 200);
    };

    return (
        <Grid container className={classes.gridWrap}>
            <Grid item xs={12} style={{ zIndex: 0 }}>
                <Typography variant="h2" className={classes.mobileH2} align="center">
                    <FormattedMessage
                        id={'redesign.home.subtitle1'}
                        values={{
                            b: (chunks) => (
                                <b>
                                    {chunks}
                                    <br></br>
                                </b>
                            ),
                        }}
                    />
                </Typography>
            </Grid>
            <Grid item xs={12} style={{paddingBottom: '2rem'}}>
                <Typography variant="h3" align="center">
                    <FormattedMessage id={'redesign.home.subtitle4'} />
                </Typography>
            </Grid>
            <Grid item xs={12} className={classes.columnFlexCenter}>
                <Grid item xs={12} className={classes.paperPadding}>
                    <div className={classes.displayFeature}>
                        <img src={`/static/asset/img/home/tabla_fizica.png`} alt="VBoard" width="90%" />
                        <div style={{ paddingTop: '2.5rem' }}>
                            <Typography variant="h3" className={classes.mobileH3}>
                                <FormattedMessage
                                    id="redesign.page.home.p1.t1"
                                    values={{
                                        b: (chunks) => <b>{chunks}</b>,
                                    }}
                                />
                            </Typography>
                            <Typography
                                variant="h6"
                                className={classes.mobileH6}
                                style={{ paddingBottom: '2rem', paddingTop: '1rem' }}
                            >
                                <FormattedMessage id="redesign.page.home.p1.t2" />
                            </Typography>
                            <Link to={FOR_SCHOOLS_LINK} onClick={transitionToTop}>
                                <FindMoreButton />
                            </Link>
                        </div>
                    </div>
                </Grid>

                <Grid item xs={12} className={classes.paperPadding}>
                    <div className={classes.displayFeature}>
                        <img src={`/static/asset/img/home/construit_cu_profesori.png`} alt="VBoard" width="90%" />
                        <div style={{ paddingTop: '2.5rem' }}>
                            <Typography variant="h3" className={classes.mobileH3}>
                                <FormattedMessage
                                    id="redesign.page.home.p2.t1"
                                    values={{
                                        b: (chunks) => <b>{chunks}</b>,
                                    }}
                                />
                            </Typography>
                            <Typography
                                variant="h6"
                                className={classes.mobileH6}
                                style={{ paddingBottom: '2rem', paddingTop: '1rem' }}
                            >
                                <FormattedMessage
                                    id="redesign.page.home.p2.t2"
                                    values={{
                                        a: (chunks) => (
                                            <a href={featureLinks.control} className={classes.findOutMoreText}>
                                                {chunks}
                                            </a>
                                        ),
                                        b: (chunks) => (
                                            <a href={featureLinks.individualBoards} className={classes.findOutMoreText}>
                                                {chunks}
                                            </a>
                                        ),
                                    }}
                                />
                            </Typography>
                            <Link to={FOR_TUTORING_LINK} onClick={transitionToTop}>
                                <FindMoreButton />
                            </Link>
                        </div>
                    </div>
                </Grid>

                <Grid item xs={12} className={classes.paperPadding}>
                    <div className={classes.displayFeature}>
                        <img src={`/static/asset/img/home/tabla_inteligenta.png`} alt="VBoard" width="90%" />
                        <div style={{ paddingTop: '2.5rem' }}>
                            <Typography variant="h3" className={classes.mobileH3}>
                                <FormattedMessage
                                    id="redesign.page.home.feature.paste.tag.v2"
                                    values={{
                                        b: (chunks) => <b>{chunks}</b>,
                                    }}
                                />
                            </Typography>
                            <Typography
                                variant="h6"
                                className={classes.mobileH6}
                                style={{ paddingBottom: '2rem', paddingTop: '1rem' }}
                            >
                                <FormattedMessage
                                    id="redesign.page.home.feature.paste.description.v2"
                                    values={{
                                        a: (chunks) => (
                                            <a href={featureLinks.paste} className={classes.findOutMoreText}>
                                                {chunks}
                                            </a>
                                        ),
                                        b: (chunks) => (
                                            <a href={featureLinks.geometry} className={classes.findOutMoreText}>
                                                {chunks}
                                            </a>
                                        ),
                                        c: (chunks) => (
                                            <a href={featureLinks.mathConvertor} className={classes.findOutMoreText}>
                                                {chunks}
                                            </a>
                                        ),
                                    }}
                                />
                            </Typography>
                            <Link to={FEATURES_LINK} onClick={transitionToTop}>
                                <FindMoreButton />
                            </Link>
                        </div>
                    </div>
                </Grid>

                <Grid item xs={12} className={classes.paperPadding}>
                    <div className={classes.displayFeature}>
                        <img src={`/static/asset/img/home/meditatii_online.png`} alt="VBoard" width="90%" />
                        <div style={{ paddingTop: '2.5rem' }}>
                            <Typography variant="h3" className={classes.mobileH3}>
                                <FormattedMessage
                                    id="redesign.page.home.feature.control.tag.v2"
                                    values={{
                                        b: (chunks) => <b>{chunks}</b>,
                                    }}
                                />
                            </Typography>
                            <Typography
                                variant="h6"
                                className={classes.mobileH6}
                                style={{ paddingBottom: '2rem', paddingTop: '1rem' }}
                            >
                                <FormattedMessage id="redesign.page.home.feature.control.description.v2" />
                            </Typography>
                            <Link to={FOR_TUTORING_LINK} onClick={transitionToTop}>
                                <FindMoreButton />
                            </Link>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Grid>
    );
};
