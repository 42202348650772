import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Container, Grid, makeStyles, Slide } from '@material-ui/core';

import { GA_EVENT_TREE } from '../../gaConstants';
import MainMenuNavigationWrapper from './MainMenu/menuWrapper';
import AvatarMenu from '../../MyComponents/AvatarMenu/AvatarMenu';
import Hidden from '@material-ui/core/Hidden';
import LoggedOutMobileMenu from '../../MyComponents/LoggedOutMobileMenu/LoggedOutMobileMenu';
import VBIcon from '../../MyComponents/icons/VBIcon';
import { LanguageMenu } from './LanguageMenu';
import LoginSmallIcon from '../../MyComponents/icons/LoginSmallIcon';
import { COLORS2 } from '../../constants';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
    languageLink: {
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        padding: theme.spacing(1),
        borderRadius: '2.188rem',

        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
    },
    imageLang: {
        width: 64,
        height: 64,
    },
    imageLogo: {
        cursor: 'pointer',
        [theme.breakpoints.down('md')]: {
            marginLeft: '2rem',
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '2rem',
            width: '10rem',
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: '2.5rem',
            width: '6rem',
        },
    },
    topLogo: {
        [theme.breakpoints.down('lg')]: {
            paddingTop: '2.5rem',
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: '3.5rem',
        },
    },
    img: {
        marginRight: '0.5rem',
        display: 'inline',
    },
    fb: {
        margin: '0.5rem',
        fontSize: '2rem',
        display: 'inline',
    },
    yt: {
        margin: '0.5rem',
        fontSize: '2.2rem',
        display: 'inline',
    },
    selectLanguageItem: {
        textTransform: 'uppercase',
        fontWeight: '500',
        color: COLORS2.darkGreen,
        font: 'normal Gilroy',
    },
    loginSmallIcon: {
        margin: 'auto 0',
        verticalAlign: 'center',
        cursor: 'pointer',
    },
    vbIcon: {
        width: '3.5rem',
        height: '3.5rem',
    },
    loggedOutWrapper: {
        textAlign: 'right',
        paddingTop: '4.5rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    loggedInWrapper: {
        textAlign: 'right',
        paddingTop: '4.5rem',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        position: 'relative',
    },
    menuNav: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '4.5rem',
    },
    autoMargin: {
        [theme.breakpoints.down('xs')]: {
            marginLeft: '0rem',
            marginRight: '0rem',
        },
    },
    topMenuNav: {
        textTransform: 'uppercase',
        color: COLORS2.darkGreen,
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '0.875rem',
        lineHeight: '1.063rem',
        letterSpacing: '0.16rem',
        borderBottom: '0.125rem solid ' + COLORS2.darkGreen,
        paddingTop: '0.5rem',
    },
    aboutMenu: {
        position: 'absolute',
        bottom: '-2.5rem',
        right: '0px',
    },
}));

const Header = (props) => {
    const classes = useStyles();
    const history = useHistory();

    const handleRo = (e) => {
        e.preventDefault();
        props.updateLanguage('ro');
        window.logAppActivity(GA_EVENT_TREE.mainPage.links.ro);
    };

    const handleFr = (e) => {
        e.preventDefault();
        props.updateLanguage('fr');
        window.logAppActivity(GA_EVENT_TREE.mainPage.links.fr);
    };

    const handleEn = (e) => {
        e.preventDefault();
        window.logAppActivity(GA_EVENT_TREE.mainPage.links.en);
        props.updateLanguage('en');
    };

    // const handleIt = () => {
    //     props.updateLanguage('it');
    // };

    const handleRedirectHome = () => {
        history.push('/');
    };
    window.openAuthentication = (e) => {
        props.handleOpen(e);
    };

    return (
        <Container maxWidth="lg" className={classes.autoMargin}>
            <Grid
                container
                direction="row"
                alignItems="center"
                style={{ justifyContent: props.profile.loggedIn ? 'flex-end' : 'space-between' }}
            >
                {!props.profile.loggedIn ? (
                    <>
                        <Grid item xs={6} sm={3} className={classes.topLogo}>
                            <Slide direction="right" in={true} timeout={1500}>
                                <div>
                                    <img
                                        src="/VBlogo.svg"
                                        alt="logo"
                                        className={classes.imageLogo}
                                        style={{ visibility: window.isTeams ? 'hidden' : 'visible' }}
                                        onClick={handleRedirectHome}
                                    />
                                </div>
                            </Slide>
                        </Grid>
                        <Hidden only={['xs', 'sm']}>
                            <Grid item xs={12} sm={6} className={classes.menuNav}>
                                <MainMenuNavigationWrapper />
                            </Grid>
                            <Grid item xs={6} sm={3} className={classes.loggedInWrapper}>
                                <Button variant="contained" color="default" onClick={props.handleOpen}>
                                    <FormattedMessage id={'home.login'} />
                                </Button>
                                <LanguageMenu
                                    handleEn={handleEn}
                                    handleRo={handleRo}
                                    handleFr={handleFr}
                                    language={props.language}
                                />
                            </Grid>
                        </Hidden>
                        <Hidden only={['md', 'lg', 'xl']}>
                            <Grid item xs={6} sm={3} className={classes.loggedInWrapper}>
                                <LoginSmallIcon className={classes.loginSmallIcon} onClick={props.handleOpen} />
                                <LoggedOutMobileMenu handleEn={handleEn} handleRo={handleRo} handleFr={handleFr} />
                            </Grid>
                        </Hidden>
                    </>
                ) : (
                    <Grid item xs={7} sm={7} className={classes.loggedOutWrapper}>
                        <div>
                            <Hidden only={['md', 'lg', 'xl']}>
                                <VBIcon className={classes.vbIcon} onClick={handleRedirectHome} />
                            </Hidden>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <AvatarMenu handleEn={handleEn} handleRo={handleRo} handleFr={handleFr} />
                            <Hidden only={['xs', 'sm']}>
                                <LanguageMenu
                                    handleEn={handleEn}
                                    handleRo={handleRo}
                                    handleFr={handleFr}
                                    language={props.language}
                                />
                            </Hidden>
                        </div>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
};

const mapStateToProps = (state) => {
    const { profile } = state;
    return { profile };
};

export default connect(mapStateToProps)(Header);
