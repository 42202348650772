import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function MobileMenuScrollIcon(props) {
    return (
        <SvgIcon {...props} style={{ verticalAlign: 'bottom' }}>
            <svg viewBox="0 0 32 138" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_73_6)">
                <path d="M16 51.5769C6.3409 51.5769 -1.5 59.3698 -1.5 69C-1.5 78.6302 6.3409 86.4231 16 86.4231C25.6591 86.4231 33.5 78.6302 33.5 69C33.5 59.383 25.6599 51.5769 16 51.5769ZM16 75.4615C12.4116 75.4615 9.5 72.5536 9.5 69C9.5 65.4464 12.4116 62.5385 16 62.5385C19.5884 62.5385 22.5 65.4464 22.5 69C22.5 72.5536 19.5884 75.4615 16 75.4615Z" fill="black" stroke="white" strokeWidth="3"/>
                <path d="M21.3334 6.46874V41.3005C21.3334 41.9414 18.9447 39.8077 16 39.8077C13.0554 39.8077 10.6667 41.9414 10.6667 41.3005V6.46874C10.6667 5.82769 13.0567 2.65384 16 2.65384C18.9434 2.65384 21.3334 5.82769 21.3334 6.46874Z" fill="black"/>
                <path d="M21.3334 96.6998V131.532C21.3334 132.172 18.9447 135.346 16 135.346C13.0554 135.346 10.6667 132.172 10.6667 131.532V96.6998C10.6667 96.0587 13.0567 98.1923 16 98.1923C18.9434 98.1923 21.3334 96.0587 21.3334 96.6998Z" fill="black"/>
                </g>
                <defs>
                <clipPath id="clip0_73_6">
                <rect width="32" height="138" fill="white"/>
                </clipPath>
                </defs>
            </svg>
        </SvgIcon>
    );
}