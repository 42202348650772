import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function PasteIcon(props) {
    return (
        <SvgIcon {...props} style={{ ...props.style, verticalAlign: 'bottom' }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 375 375" version="1.0">
                <path d="M 201.972656 216.347656 L 201.972656 245.191406 L 302.960938 245.191406 L 302.960938 216.347656 Z M 201.972656 302.882812 L 302.960938 302.882812 L 302.960938 274.039062 L 201.972656 274.039062 Z M 201.972656 302.882812" />
                <g>
                    <path d="M 302.960938 158.652344 C 318.832031 158.652344 331.816406 171.632812 331.816406 187.5 L 331.816406 317.308594 C 331.816406 333.171875 318.832031 346.152344 302.960938 346.152344 L 201.972656 346.152344 C 186.105469 346.152344 173.121094 333.171875 173.121094 317.308594 L 173.121094 187.5 C 173.121094 171.632812 186.105469 158.652344 201.972656 158.652344 L 302.960938 158.652344 M 302.960938 129.808594 L 201.972656 129.808594 C 170.234375 129.808594 144.265625 155.769531 144.265625 187.5 L 144.265625 317.308594 C 144.265625 349.039062 170.234375 375 201.972656 375 L 302.960938 375 C 334.703125 375 360.671875 349.039062 360.671875 317.308594 L 360.671875 187.5 C 360.671875 155.769531 334.703125 129.808594 302.960938 129.808594 Z M 302.960938 129.808594" />
                </g>
                <g>
                    <path d="M 122.625 317.308594 L 122.625 302.882812 L 57.703125 302.882812 L 57.703125 86.539062 L 90.886719 86.539062 C 95.214844 95.191406 105.3125 100.960938 115.410156 100.960938 L 187.546875 100.960938 C 197.644531 100.960938 207.746094 95.191406 212.074219 86.539062 L 245.253906 86.539062 L 245.253906 108.171875 L 288.535156 108.171875 L 288.535156 86.539062 C 288.535156 62.019531 269.78125 43.269531 245.253906 43.269531 L 216.402344 43.269531 C 216.402344 27.402344 203.417969 14.421875 187.546875 14.421875 L 171.675781 14.421875 C 168.792969 5.769531 160.136719 0 151.480469 0 C 142.824219 0 134.167969 5.769531 131.28125 14.421875 L 115.410156 14.421875 C 99.542969 14.421875 86.558594 27.402344 86.558594 43.269531 L 57.703125 43.269531 C 33.179688 43.269531 14.421875 62.019531 14.421875 86.539062 L 14.421875 302.882812 C 14.421875 327.402344 33.179688 346.152344 57.703125 346.152344 L 128.394531 346.152344 C 124.066406 337.5 122.625 327.402344 122.625 317.308594 Z M 115.410156 43.269531 L 187.546875 43.269531 L 187.546875 72.117188 L 115.410156 72.117188 Z M 115.410156 43.269531" />
                </g>
            </svg>
        </SvgIcon>
    );
}
