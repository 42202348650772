import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Typography, makeStyles, Divider } from '@material-ui/core';

import YouTubeIcon from '../../MyComponents/icons/YouTubeIcon';
import FacebookMainPageIcon from '../../MyComponents/icons/FacebookMainPageIcon';
import { CONTACT_US_LINK, SPECIAL_QUOTA_LINK } from '../../common/urlLinks';
import Grid from '@material-ui/core/Grid';
import VBoardFooterIcon from '../icons/VboardFooterIcon';
import { COLORS2 } from '../../constants';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { ONBOARDING_STEPS } from '../../shared/OnboardingOptions';
import { SUBSCRIPTION_PLANS } from '../../common/utils';
import Hidden from '@material-ui/core/Hidden';
import { LogOutSectionFooter } from './LogOutSectionFooter';

const useStyles = makeStyles((theme) => ({
    footerIn: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        marginTop: 'auto',
        position: 'fixed',
        bottom: 0,
        width: '100%',
    },
    footer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        marginTop: 'auto',
    },
    footerLoggedIn: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        padding: theme.spacing(2, 1),
        marginTop: 'auto',
        backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
    },
    lastLinkLoggedOut: {
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '0.875rem',
        lineHeight: '1rem',
        letterSpacing: '0.14em',
        textTransform: 'uppercase',
        paddingLeft: '1rem',
        paddingRight: '1rem',
    },
    lastLinkLoggedIn: {
        marginLeft: '0.5rem',
        paddingLeft: '0.5rem',
        marginRight: '0.5rem',
        paddingRight: '0.5rem',
        borderRight: `1px solid ${theme.palette.text.secondary}`,
    },
    footerLogo: {
        width: '11.688rem',
        height: '7.375rem',
    },
    footerLogoMobile: {
        width: '11.688rem',
        height: '5.375rem',
    },
    helpers: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        // width: '34.688rem',
        height: '1rem',
        marginTop: '0.325rem',
    },
    imgFacebookIcon: {
        width: '1.5rem',
        height: '1.5rem',
        left: '94.175rem',
        marginTop: '0.325rem',
        marginRight: '0.5rem',
        top: '268.25rem',
    },
    imgYoutubeIcon: {
        width: '2.125',
        height: '2.2rem',
        left: '102.875rem',
        top: '268.25rem',
    },
    socials: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        height: '3rem',
    },
    writeUs: {
        background: COLORS2.virtualBackground,
        boxShadow: '0rem 0rem 0rem 0.5rem rgba(8, 170, 180, 0.08)',
        borderRadius: '1rem',
        width: '20rem',
        height: '4.5rem',
    },
    tryPremium: {
        background: COLORS2.virtualGreen,
        boxShadow: '0rem 0rem 0rem 0.5rem rgba(8, 170, 180, 0.08)',
        borderRadius: '1rem',
        width: '20rem',
        height: '4.5rem',
        position: 'initial',
    },
    customDivider: {
        '&.MuiDivider-root': {
            backgroundColor: 'rgb(255, 255, 255, 0.3)',
            marginRight: '4.5rem',
            marginLeft: '4.5rem',
        },
    },
    footerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: COLORS2.darkGreen,
    },
    orCustom: {
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '0.875rem',
        lineHeight: '1rem',
        letterSpacing: '0.16rem',
        textTransform: 'uppercase',
        color: 'rgba(255,255,255,0.5)',
        textAlign: 'center',
        padding: '1.25rem 0rem',
    },
    premiumText: {
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '0.875rem',
        lineHeight: '1.063rem',
        letterSpacing: '0.16rem',
        textTransform: 'uppercase',
        color: COLORS2.third,
        textAlign: 'center',
    },
    displayCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    mobileContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '3.5rem',
        paddingBottom: '4rem',
        flexDirection: 'column',
    },
    mobileH5: {
        textAlign: 'center',
        color: COLORS2.third,
        fontWeight: 500,
        margin: '0rem 1rem',
        fontSize: '1rem',
        lineHeight: '1.5rem',
    },
    customH4: {
        textAlign: 'center',
        color: COLORS2.third,
        fontWeight: 500,
        margin: '0rem',
    },
    writeUsText: {
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '0.875rem',
        lineHeight: '1.063rem',
        letterSpacing: '0.16rem',
        textTransform: 'uppercase',
        color: COLORS2.virtualGreen,
        textAlign: 'center',
    },
}));

function Footer({ profile, handleOpen }) {
    const classes = useStyles();

    if (window.isTeams) {
        return null;
    }

    const transitionToTop = () => {
        setTimeout(() => {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }, 200);
    };

    const setOnboardingPath = (path) => {
        window.localStorage.setItem('pathBeforeLogin', path);
    };

    return (
        <>
            {profile.loggedIn ? (
                <footer className={classes.footer}>
                    <Grid item xs={12} className={classes.footerContainer}>
                        <Hidden only={['lg', 'xl']}>
                            <Grid item xs={6} sm={2}>
                                <VBoardFooterIcon className={classes.footerLogoMobile} />
                            </Grid>
                        </Hidden>
                        <Hidden only={['xs', 'sm', 'md']}>
                            <Grid item xs={6} sm={2}>
                                <VBoardFooterIcon className={classes.footerLogo} />
                            </Grid>
                            <Grid item xs={8} className={classes.displayCenter}>
                                <div className={classes.helpers}>
                                    <Typography className={classes.lastLinkLoggedOut} onClick={transitionToTop}>
                                        <Link
                                            to={CONTACT_US_LINK}
                                            style={{
                                                color: COLORS2.third,
                                            }}
                                        >
                                            <FormattedMessage id='contactus' />
                                        </Link>
                                    </Typography>
                                    <Typography className={classes.lastLinkLoggedOut} onClick={transitionToTop}>
                                        <Link
                                            to='/terms'
                                            style={{
                                                color: COLORS2.third,
                                            }}
                                        >
                                            <FormattedMessage id='terms' />
                                        </Link>
                                    </Typography>
                                    <Typography className={classes.lastLinkLoggedOut} onClick={transitionToTop}>
                                        <Link
                                            to='/privacypolicy'
                                            style={{
                                                color: COLORS2.third,
                                            }}
                                        >
                                            <FormattedMessage id='privacy' />
                                        </Link>
                                    </Typography>
                                </div>
                            </Grid>
                        </Hidden>
                        <Grid item xs={6} sm={2} className={classes.displayCenter}>
                            <div className={classes.socials}>
                                <a
                                    href='https://www.facebook.com/Virtual-Board-104068774768862'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    <FacebookMainPageIcon className={classes.imgFacebookIcon} />
                                </a>
                                <a
                                    className='u-ml-half'
                                    href='https://www.youtube.com/c/VirtualBoard_vboard'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    style={{ paddingTop: '0.5rem' }}
                                >
                                    <YouTubeIcon className={classes.imgYoutubeIcon} />
                                </a>
                            </div>
                        </Grid>
                    </Grid>
                </footer>
            ) : (
                <footer className={classes.footer}>
                    <Grid container style={{ backgroundColor: COLORS2.darkGreen, paddingTop: '14rem' }}>
                        <Hidden only={['xs', 'sm']}>
                            <Grid item xs={12}>
                                <Typography
                                    variant='h2'
                                    style={{
                                        textAlign: 'center',
                                        color: COLORS2.third,
                                    }}
                                >
                                    <FormattedMessage
                                        id='redesign.page.home.footer.notDecided.tag1'
                                        values={{
                                            b: (chunks) => <b>{chunks}</b>,
                                        }}
                                    />
                                </Typography>
                                <Typography variant='h4' className={classes.customH4}>
                                    <FormattedMessage id='redesign.page.home.footer.notDecided.tag2' />
                                </Typography>
                                <Typography variant='h4' className={classes.customH4}>
                                    <FormattedMessage id='redesign.page.home.footer.notDecided.tag3' />
                                </Typography>
                            </Grid>
                            <LogOutSectionFooter handleOpen={handleOpen} />
                            <Grid item xs={12} style={{ paddingTop: '10rem' }}>
                                <Divider className={classes.customDivider} variant='middle' />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    paddingTop: '4rem',
                                }}
                            >
                                <Grid item xs={6} sm={2} style={{ paddingLeft: '2rem' }}>
                                    <VBoardFooterIcon className={classes.footerLogo} />
                                </Grid>
                                <Grid
                                    item
                                    xs={8}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <div className={classes.helpers}>
                                        <Typography className={classes.lastLinkLoggedOut} onClick={transitionToTop}>
                                            <Link
                                                to={CONTACT_US_LINK}
                                                style={{
                                                    color: COLORS2.third,
                                                }}
                                            >
                                                <FormattedMessage id='contactus' />
                                            </Link>
                                        </Typography>
                                        <Typography className={classes.lastLinkLoggedOut} onClick={transitionToTop}>
                                            <Link
                                                to='/terms'
                                                style={{
                                                    color: COLORS2.third,
                                                }}
                                            >
                                                <FormattedMessage id='terms' />
                                            </Link>
                                        </Typography>
                                        <Typography className={classes.lastLinkLoggedOut} onClick={transitionToTop}>
                                            <Link
                                                to='/privacypolicy'
                                                style={{
                                                    color: COLORS2.third,
                                                }}
                                            >
                                                <FormattedMessage id='privacy' />
                                            </Link>
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    sm={2}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <div className={classes.socials}>
                                        <a
                                            href='https://www.facebook.com/Virtual-Board-104068774768862'
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            <FacebookMainPageIcon className={classes.imgFacebookIcon} />
                                        </a>
                                        <a
                                            className='u-ml-half'
                                            href='https://www.youtube.com/c/VirtualBoard_vboard'
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            style={{ paddingTop: '0.5rem' }}
                                        >
                                            <YouTubeIcon className={classes.imgYoutubeIcon} />
                                        </a>
                                    </div>
                                </Grid>
                            </Grid>
                        </Hidden>
                        <Hidden only={['md', 'lg', 'xl']}>
                            <Grid item xs={12}>
                                <Typography
                                    variant='h4'
                                    style={{
                                        textAlign: 'center',
                                        color: COLORS2.third,
                                        fontSize: '2rem',
                                        lineHeight: '2.5rem',
                                    }}
                                >
                                    <FormattedMessage
                                        id='redesign.page.home.footer.notDecided.tag1'
                                        values={{
                                            b: (chunks) => <b>{chunks}</b>,
                                        }}
                                    />
                                </Typography>
                                <Typography variant='h5' className={classes.mobileH5}>
                                    <FormattedMessage id='redesign.page.home.footer.notDecided.tag2' />
                                </Typography>
                                <Typography variant='h5' className={classes.mobileH5}>
                                    <FormattedMessage id='redesign.page.home.footer.notDecided.tag3' />
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.mobileContainer}>
                                <Link to={SPECIAL_QUOTA_LINK}>
                                    <Button className={classes.writeUs} onClick={transitionToTop}>
                                        <Typography className={classes.writeUsText}>
                                            <FormattedMessage id='specialQuota.buttonText' />
                                        </Typography>
                                    </Button>
                                </Link>
                                <Typography className={classes.orCustom}>
                                    <FormattedMessage id='redesign.page.home.footer.or' />
                                </Typography>
                                <Button
                                    className={classes.tryPremium}
                                    onClick={() => {
                                        transitionToTop();
                                        handleOpen();
                                        setOnboardingPath(
                                            `/onboarding?step=${ONBOARDING_STEPS.START}&subscription=${SUBSCRIPTION_PLANS.TRIAL}`,
                                        );
                                    }}
                                >
                                    <Typography className={classes.premiumText}>
                                        <FormattedMessage id='redesign.page.home.footer.tryPremium' />
                                    </Typography>
                                </Button>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    backgroundColor: '#022325',
                                }}
                            >
                                <Grid item xs={6} sm={2} style={{ paddingLeft: '2rem' }}>
                                    <VBoardFooterIcon className={classes.footerLogoMobile} />
                                </Grid>
                                <Grid item xs={6} sm={2} className={classes.displayCenter}>
                                    <div className={classes.socials}>
                                        <a
                                            href='https://www.facebook.com/Virtual-Board-104068774768862'
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            <FacebookMainPageIcon className={classes.imgFacebookIcon} />
                                        </a>
                                        <a
                                            className='u-ml-half'
                                            href='https://www.youtube.com/c/VirtualBoard_vboard'
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            style={{ paddingTop: '0.5rem' }}
                                        >
                                            <YouTubeIcon className={classes.imgYoutubeIcon} />
                                        </a>
                                    </div>
                                </Grid>
                            </Grid>
                        </Hidden>
                    </Grid>
                </footer>
            )}
        </>
    );
}

const mapStateToProps = (state) => {
    const { profile } = state;
    return { profile };
};

export default connect(mapStateToProps)(Footer);
