import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { featureLinks } from '../../../common/features';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FindMoreButton } from './FindMoreButton';
import { COLORS2 } from '../../../constants';
import { FEATURES_LINK, FOR_SCHOOLS_LINK, FOR_TUTORING_LINK } from '../../../common/urlLinks';

const useStyles = makeStyles((theme) => ({
    gridWrap: {
        height: '100%',
        background: 'linear-gradient(180deg, rgba(251, 248, 245, 0) 0%, #FFFFFF 93.57%)',
        borderRadius: '0rem 0rem 2rem 2rem',
        paddingTop: '8rem',
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        minHeight: '13rem',
        background: 'transparent',
    },
    mobileH3: {
        textAlign: 'center',
        fontSize: '2rem',
        lineHeight: '2.5rem',
    },
    columnFlexCenter: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    displayFeature: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
    },
    findOutMoreText: {
        color: COLORS2.virtualAmber,
        fontStyle: 'normal',
        fontWeight: '700',
    },
    paperPadding: {
        paddingTop: '5rem',
        paddingBottom: '2rem',
    },
}));

export const HomeFeaturesTablet = () => {
    const classes = useStyles();

    const transitionToTop = () => {
        setTimeout(() => {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }, 200);
    };

    return (
        <Grid container className={classes.gridWrap}>
            <Grid item xs={12} style={{ zIndex: 0 }}>
                <Typography variant="h2" style={{ textAlign: 'center' }}>
                    <FormattedMessage
                        id={'redesign.home.subtitle1'}
                        values={{
                            b: (chunks) => (
                                <b>
                                    {chunks}
                                    <br></br>
                                </b>
                            ),
                        }}
                    />
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h3" className={classes.mobileH3}>
                    <FormattedMessage id={'redesign.home.subtitle3'} />
                </Typography>
                <Typography variant="h3" className={classes.mobileH3}>
                    <FormattedMessage id={'redesign.home.subtitle4'} />
                </Typography>
            </Grid>
            <Grid item xs={12} className={classes.columnFlexCenter}>
                <Grid item xs={12} className={classes.paperPadding}>
                    <div className={classes.displayFeature}>
                        <img src={`/static/asset/img/home/tabla_fizica.png`} alt="VBoard" width="80%" />
                        <Typography variant="h3">
                            <FormattedMessage
                                id="redesign.page.home.p1.t1"
                                values={{
                                    b: (chunks) => (
                                        <b>
                                            <br></br>
                                            {chunks}
                                        </b>
                                    ),
                                }}
                            />
                        </Typography>
                        <Typography
                            variant="h5"
                            style={{ color: COLORS2.virtualText, paddingBottom: '3rem', paddingTop: '1rem' }}
                        >
                            <FormattedMessage id="redesign.page.home.p1.t2" />
                        </Typography>
                        <Link to={FOR_SCHOOLS_LINK} onClick={transitionToTop}>
                            <FindMoreButton />
                        </Link>
                    </div>
                </Grid>
                <Grid item xs={12} className={classes.paperPadding}>
                    <div className={classes.displayFeature}>
                        <img src={`/static/asset/img/home/construit_cu_profesori.png`} alt="VBoard" width="80%" style={{paddingBottom: '1rem'}}/>
                        <Typography variant="h3">
                            <FormattedMessage
                                id="redesign.page.home.p2.t1"
                                values={{
                                    b: (chunks) => (
                                        <b>
                                            <br></br>
                                            {chunks}
                                        </b>
                                    ),
                                }}
                            />
                        </Typography>
                        <Typography
                            variant="h5"
                            style={{ color: COLORS2.virtualText, paddingBottom: '3rem', paddingTop: '1rem' }}
                        >
                            <FormattedMessage
                                id="redesign.page.home.p2.t2"
                                values={{
                                    a: (chunks) => (
                                        <a href={featureLinks.control} className={classes.findOutMoreText}>
                                            {chunks}
                                        </a>
                                    ),
                                    b: (chunks) => (
                                        <a href={featureLinks.individualBoards} className={classes.findOutMoreText}>
                                            {chunks}
                                        </a>
                                    ),
                                }}
                            />
                        </Typography>
                        <Link to={FOR_TUTORING_LINK} onClick={transitionToTop}>
                            <FindMoreButton />
                        </Link>
                    </div>
                </Grid>
                <Grid item xs={12} className={classes.paperPadding}>
                    <div className={classes.displayFeature}>
                        <img src={`/static/asset/img/home/tabla_inteligenta.png`} alt="VBoard" width="80%" />
                        <Typography variant="h3">
                            <FormattedMessage
                                id="redesign.page.home.feature.paste.tag.v2"
                                values={{
                                    b: (chunks) => (
                                        <b>
                                            <br></br>
                                            {chunks}
                                        </b>
                                    ),
                                }}
                            />
                        </Typography>
                        <Typography
                            variant="h5"
                            style={{ color: COLORS2.virtualText, paddingBottom: '3rem', paddingTop: '1rem' }}
                        >
                            <FormattedMessage
                                id="redesign.page.home.feature.paste.description.v2"
                                values={{
                                    a: (chunks) => (
                                        <a href={featureLinks.paste} className={classes.findOutMoreText}>
                                            {chunks}
                                        </a>
                                    ),
                                    b: (chunks) => (
                                        <a href={featureLinks.geometry} className={classes.findOutMoreText}>
                                            {chunks}
                                        </a>
                                    ),
                                    c: (chunks) => (
                                        <a href={featureLinks.mathConvertor} className={classes.findOutMoreText}>
                                            {chunks}
                                        </a>
                                    ),
                                }}
                            />
                        </Typography>
                        <Link to={FEATURES_LINK} onClick={transitionToTop}>
                            <FindMoreButton />
                        </Link>
                    </div>
                </Grid>
                <Grid item xs={12} className={classes.paperPadding}>
                    <div className={classes.displayFeature}>
                        <img src={`/static/asset/img/home/meditatii_online.png`} alt="VBoard" width="80%" />
                        <Typography variant="h3">
                            <FormattedMessage
                                id="redesign.page.home.feature.control.tag.v2"
                                values={{
                                    b: (chunks) => (
                                        <b>
                                            <br></br>
                                            {chunks}
                                        </b>
                                    ),
                                }}
                            />
                        </Typography>
                        <Typography
                            variant="h5"
                            style={{ color: COLORS2.virtualText, paddingBottom: '3rem', paddingTop: '1rem' }}
                        >
                            <FormattedMessage id="redesign.page.home.feature.control.description.v2" />
                        </Typography>
                        <Link to={FOR_TUTORING_LINK} onClick={transitionToTop} style={{paddingBottom: '2rem'}}>
                            <FindMoreButton />
                        </Link>
                    </div>
                </Grid>
            </Grid>
        </Grid>
    );
};
