import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function SaveAllToLib(props) {
    return (
        <SvgIcon {...props}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                zoomAndPan="magnify"
                viewBox="0 0 150 150"
                preserveAspectRatio="xMidYMid meet"
                version="1.0"
            >
                <defs>
                    <clipPath>
                        <path d="M 0 51 L 130 51 L 130 138.832031 L 0 138.832031 Z M 0 51 " clipRule="nonzero" />
                    </clipPath>
                    <clipPath>
                        <path
                            d="M 5.394531 62.710938 L 72.894531 62.710938 L 72.894531 130.210938 L 5.394531 130.210938 Z M 5.394531 62.710938 "
                            clipRule="nonzero"
                        />
                    </clipPath>
                </defs>
                <g>
                    <path
                        d="M 0.636719 133.421875 C 0.636719 135.199219 0.636719 138.5625 5.800781 138.5625 L 124.503906 138.5625 C 129.664062 138.5625 129.664062 135.199219 129.664062 133.421875 L 129.664062 51.199219 L 0.636719 51.199219 Z M 0.636719 133.421875 "
                        fillOpacity="0.2"
                        fillRule="nonzero"
                    />
                </g>
                <path
                    d="M 134.824219 20.367188 L 16.121094 20.367188 C 10.960938 20.367188 10.960938 23.726562 10.960938 25.507812 L 134.824219 25.507812 L 134.824219 128.285156 C 136.609375 128.285156 139.984375 128.285156 139.984375 123.144531 L 139.984375 25.507812 C 139.984375 20.367188 139.984375 20.367188 134.824219 20.367188 Z M 134.824219 20.367188 "
                    fillOpacity="1"
                    fillRule="nonzero"
                />
                <path
                    d="M 145.148438 10.089844 L 26.441406 10.089844 C 21.28125 10.089844 21.28125 13.449219 21.28125 15.230469 L 145.148438 15.230469 L 145.148438 118.007812 C 146.933594 118.007812 150.308594 118.007812 150.308594 112.867188 L 150.308594 15.230469 C 150.308594 10.089844 150.308594 10.089844 145.148438 10.089844 Z M 145.148438 10.089844 "
                    fillOpacity="1"
                    fillRule="nonzero"
                />
                <path
                    d="M 124.503906 30.644531 L 5.800781 30.644531 C 0.636719 30.644531 0.636719 34.007812 0.636719 35.785156 L 0.636719 46.0625 L 129.664062 46.0625 L 129.664062 35.785156 C 129.664062 30.644531 129.664062 30.644531 124.503906 30.644531 Z M 16.765625 40.921875 C 14.980469 40.921875 13.539062 39.488281 13.539062 37.710938 C 13.539062 35.933594 14.980469 34.5 16.765625 34.5 C 18.550781 34.5 19.992188 35.933594 19.992188 37.710938 C 19.992188 39.488281 18.550781 40.921875 16.765625 40.921875 Z M 29.667969 40.921875 C 27.882812 40.921875 26.441406 39.488281 26.441406 37.710938 C 26.441406 35.933594 27.882812 34.5 29.667969 34.5 C 31.453125 34.5 32.894531 35.933594 32.894531 37.710938 C 32.894531 39.488281 31.453125 40.921875 29.667969 40.921875 Z M 42.570312 40.921875 C 40.785156 40.921875 39.34375 39.488281 39.34375 37.710938 C 39.34375 35.933594 40.785156 34.5 42.570312 34.5 C 44.355469 34.5 45.796875 35.933594 45.796875 37.710938 C 45.796875 39.488281 44.355469 40.921875 42.570312 40.921875 Z M 42.570312 40.921875 "
                    fillOpacity="1"
                    fillRule="nonzero"
                />
                <g>
                    <path
                        fill="#f9a334"
                        d="M 57.894531 62.710938 L 12.894531 62.710938 C 8.734375 62.710938 5.394531 66.085938 5.394531 70.210938 L 5.394531 122.710938 C 5.394531 126.835938 8.734375 130.210938 12.894531 130.210938 L 65.394531 130.210938 C 69.519531 130.210938 72.894531 126.835938 72.894531 122.710938 L 72.894531 77.710938 Z M 39.144531 122.710938 C 32.921875 122.710938 27.894531 117.6875 27.894531 111.460938 C 27.894531 105.234375 32.921875 100.210938 39.144531 100.210938 C 45.371094 100.210938 50.394531 105.234375 50.394531 111.460938 C 50.394531 117.6875 45.371094 122.710938 39.144531 122.710938 Z M 50.394531 85.210938 L 12.894531 85.210938 L 12.894531 70.210938 L 50.394531 70.210938 Z M 50.394531 85.210938 "
                        fillOpacity="1"
                        fillRule="nonzero"
                    />
                </g>
            </svg>
        </SvgIcon>
    );
}
