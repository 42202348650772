import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function YouTubeForSubscriptionsIcon(props) {
    return (
        <SvgIcon {...props} style={{ verticalAlign: 'bottom' }}>
            <svg viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M33.2891 3.74789C32.8973 2.27188 31.7469 1.11195 30.2831 0.716839C27.6327 2.14577e-07 16.9999 0 16.9999 0C16.9999 0 6.3673 2.14577e-07 3.71682 0.716839C2.25305 1.11195 1.10273 2.27188 0.710899 3.74789C2.12798e-07 6.4205 0 12 0 12C0 12 2.12798e-07 17.5795 0.710899 20.2522C1.10273 21.7282 2.25305 22.8881 3.71682 23.2831C6.3673 24 16.9999 24 16.9999 24C16.9999 24 27.6327 24 30.2831 23.2831C31.7469 22.8881 32.8973 21.7282 33.2891 20.2522C34 17.5795 34 12 34 12C34 12 33.9971 6.4205 33.2891 3.74789Z" fill="#FF0000"/>
                <path d="M13.5977 17.1415L22.4307 11.9995L13.5977 6.85742V17.1415Z" fill="white"/>
            </svg>
        </SvgIcon>
    );
}
