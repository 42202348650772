import apiUtil from './apiUtil';

let managementApi = {};

managementApi.getSubscriptionTypes = function () {
    let myData = {
        url: `/api/private/management/subscriptionTypes`,
        method: 'GET',
    };
    return apiUtil.runRequest(myData);
};

managementApi.getSpecialOffers = function () {
    let myData = {
        url: `/api/public/management/specialOffers`,
        method: 'GET',
    };
    return apiUtil.runRequest(myData);
};

managementApi.updateSubscriptionTypes = function (id, data) {
    let myData = {
        url: `/api/private/management/subscriptionTypes/${id}`,
        method: 'PUT',
        data,
    };
    return apiUtil.runRequest(myData);
};

managementApi.getSubscriptionTypesForPublic = function () {
    let myData = {
        url: `/api/public/subscriptionTypes`,
        method: 'GET',
    };
    return apiUtil.runRequest(myData);
};

managementApi.findUser = function (email, name) {
    let myData = {
        url: `/api/private/management/findUser`,
        method: 'POST',
        data: { email, name },
    };
    return apiUtil.runRequest(myData);
};

managementApi.findUserById = function (id) {
    let myData = {
        url: `/api/private/management/findUser/${id}`,
        method: 'GET',
    };
    return apiUtil.runRequest(myData);
};

managementApi.makeUserSpecial = function (id) {
    let myData = {
        url: `/api/private/management/makeSpecial/${id}`,
        method: 'GET',
    };
    return apiUtil.runRequest(myData);
};

managementApi.makeUserFree = function (id) {
    let myData = {
        url: `/api/private/management/makeFree/${id}`,
        method: 'GET',
    };
    return apiUtil.runRequest(myData);
};

managementApi.makeUserBasic = function (id) {
    let myData = {
        url: `/api/private/management/makeBasic/${id}`,
        method: 'GET',
    };
    return apiUtil.runRequest(myData);
};

managementApi.makeUserPremium = function (id) {
    let myData = {
        url: `/api/private/management/makePremium/${id}`,
        method: 'GET',
    };
    return apiUtil.runRequest(myData);
};

managementApi.updateUserEndDate = function (id, endDate) {
    let myData = {
        url: `/api/private/management/updateEndDate/${id}`,
        method: 'POST',
        data: {
            endDate,
        },
    };
    return apiUtil.runRequest(myData);
};

export default managementApi;
