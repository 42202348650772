import React from 'react';
import { Divider, IconButton, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import List from '@material-ui/core/List';
import { featureLinks, getSupportPrimaryTextForDescription } from '../../common/features';
import DescriptionListIcon from '../../MyComponents/icons/DescriptionListIcon';
import { Link } from 'react-router-dom';
import DescriptionListIconLoggedIn from '../../MyComponents/icons/DescriptionListIconLoggedIn';
import { connect } from 'react-redux';

const useStyles = makeStyles(() => ({
    listItem: {
        borderBottomWidth: '0.5rem',
        borderBottomColor: 'rgba(255,255,255,0.66)',
        height: '6.4375rem',
    },
    customDivider: {
        border: '0.063rem',
        height: '0.063em',
        width: '100%',
        paddingLeft: '1rem',
    },
}));

const SubscriptionDescriptionListItem = ({ intl, el, formatObj, isActive, isLoggedIn, isLight = true }) => {
    const classes = useStyles();

    return (
        <ListItem className={classes.listItem}>
            <ListItemIcon>
                <Link to={featureLinks[el.name]}>
                    <IconButton style={{ color: isLight ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.5)' }}>
                        {isLoggedIn ? <DescriptionListIconLoggedIn /> : <DescriptionListIcon />}
                    </IconButton>
                </Link>
            </ListItemIcon>
            <ListItemText
                primary={getSupportPrimaryTextForDescription(
                    el.defaultValue,
                    el.name,
                    formatObj,
                    intl,
                    isActive,
                    isLoggedIn,
                    isLight
                )}
                secondary={
                    <font style={{ color: isLoggedIn ? 'rgba(6,81,86,0.66)' : 'rgba(255,255,255,0.66)' }}>
                        {intl.formatMessage(
                            { id: `subscriptions.feature.${el.name}.shortSubscriptionDescription` },
                            formatObj
                        )}
                    </font>
                }
            />
        </ListItem>
    );
};

const SubscriptionDescriptionListRenderer = ({ intl, featureList, isActive, profile }) => {
    const classes = useStyles();
    let isLoggedIn = profile.loggedIn;

    return (
        <>
            <List dense>
                {featureList.map((el) => {
                    return (
                        <div key={'feature-' + el.name}>
                            <Divider className={classes.customDivider} />
                            <SubscriptionDescriptionListItem
                                el={el}
                                intl={intl}
                                isActive={isActive}
                                isLoggedIn={isLoggedIn}
                            />
                        </div>
                    );
                })}
            </List>
        </>
    );
};

const mapStateToProps = (state) => {
    const { profile } = state;
    return { profile };
};

export default injectIntl(connect(mapStateToProps)(SubscriptionDescriptionListRenderer));
