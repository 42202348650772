import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function DeclineIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_914_665)">
                    <path d="M9.85277 12.8537C9.65736 13.0491 9.3412 13.0491 9.14558 12.8537L4.99964 8.70687L0.852766 12.8537C0.657359 13.0491 0.341203 13.0491 0.145578 12.8537C-0.0498281 12.6583 -0.0498281 12.3422 0.145578 12.1466L4.29339 8.00062L0.146203 3.85374C-0.0492031 3.65834 -0.0492031 3.34218 0.146203 3.14655C0.341609 2.95115 0.657766 2.95115 0.853391 3.14655L4.99964 7.29437L9.14652 3.14749C9.34192 2.95209 9.65808 2.95209 9.8537 3.14749C10.0491 3.3429 10.0491 3.65905 9.8537 3.85468L5.70589 8.00062L9.85277 12.1475C10.0496 12.3412 10.0496 12.66 9.85277 12.8537Z" fill="#F35325"/>
                </g>
                <defs>
                    <clipPath id="clip0_914_665">
                        <rect width="10" height="16" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        </SvgIcon>
    );
}
