import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function FindMoreIcon(props) {
    return (
        <SvgIcon {...props} style={{ ...props.style, verticalAlign: 'bottom' }}>
            <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 16C12.4187 16 16 12.4187 16 8C16 3.58125 12.4187 0 8 0C3.58125 0 0 3.58125 0 8C0 12.4187 3.58125 16 8 16ZM5.30625 5.16563C5.55312 4.46875 6.21563 4 6.95625 4H8.77812C9.86875 4 10.75 4.88438 10.75 5.97188C10.75 6.67813 10.3719 7.33125 9.75937 7.68437L8.75 8.2625C8.74375 8.66875 8.40938 9 8 9C7.58437 9 7.25 8.66562 7.25 8.25V7.82812C7.25 7.55937 7.39375 7.3125 7.62813 7.17812L9.0125 6.38438C9.15938 6.3 9.25 6.14375 9.25 5.975C9.25 5.7125 9.0375 5.50313 8.77812 5.50313H6.95625C6.85 5.50313 6.75625 5.56875 6.72188 5.66875L6.70937 5.70625C6.57188 6.09688 6.14062 6.3 5.75313 6.1625C5.36563 6.025 5.15938 5.59375 5.29688 5.20625L5.30937 5.16875L5.30625 5.16563ZM9 11C9 11.5531 8.55313 12 8 12C7.44688 12 7 11.5531 7 11C7 10.4469 7.44688 10 8 10C8.55313 10 9 10.4469 9 11Z" fill="#08AAB4"/>
            </svg>
        </SvgIcon>
    );
}

