import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function SortedIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.1967 5.275L12.7248 0.275C12.5549 -0.0628125 12.0001 -0.0628125 11.8304 0.275L9.35855 5.275C9.23502 5.52206 9.33512 5.82188 9.58218 5.94594C9.82924 6.07094 10.1297 5.9684 10.2531 5.72231L10.5592 5H13.9404L14.3023 5.72375C14.3991 5.91613 14.6798 6.095 14.9732 5.94737C15.2217 5.82187 15.3217 5.525 15.1967 5.275ZM11.0592 4L12.2501 1.61812L13.4404 4H11.0592ZM14.2217 13H11.2623L14.6123 8.8125C14.7324 8.66212 14.7554 8.45687 14.6724 8.28312C14.6154 8.10938 14.4436 8 14.2217 8H10.2217C9.9453 8 9.72168 8.22363 9.72168 8.5C9.72168 8.77637 9.9453 9 10.2217 9H13.1811L9.83105 13.1875C9.71093 13.3379 9.68799 13.5431 9.77099 13.7169C9.88105 13.8906 10.0561 14 10.2217 14H14.1936C14.4699 14 14.6654 13.7764 14.6654 13.5C14.6654 13.2236 14.5248 13 14.2217 13ZM7.2498 10C7.12187 10 6.99393 10.0488 6.89637 10.1465L4.7498 12.2937V0.5C4.7498 0.223625 4.52618 0 4.2498 0C3.97343 0 3.7498 0.22375 3.7498 0.5V12.2937L1.60324 10.1472C1.40793 9.95187 1.09137 9.95187 0.896367 10.1472C0.701367 10.3425 0.701055 10.6591 0.896367 10.8541L3.89637 13.8541C4.09168 14.0494 4.40824 14.0494 4.60324 13.8541L7.60324 10.8541C7.79855 10.6587 7.79855 10.3422 7.60324 10.1472C7.50605 10.05 7.37793 10 7.2498 10Z" fill="#08AAB4"/>
            </svg>
        </SvgIcon>
    );
}
