import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import store from '../../redux/store';
import { callInProgress } from '../../redux/actions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import audioCallUtils from '../../pages/LessonPage/audioCallUtilsNoQueue';
import Typography from '@material-ui/core/Typography';

const JoinCallDialog = (props) => {
    return (
        <Dialog
            open={!!props.appState.callInProgress}
            onClose={() => {
                store.dispatch(callInProgress(null));
            }}
        >
            <DialogContent>
                <Typography>
                    <FormattedMessage id="call.inProgress" />
                </Typography>
            </DialogContent>
            <DialogActions style={{ marginBottom: '0.5rem', marginRight: '1rem' }}>
                <Button
                    onClick={() => {
                        audioCallUtils.startCall();
                        store.dispatch(callInProgress(null));
                    }}
                    color="primary"
                >
                    <FormattedMessage id="call.joinCall" />
                </Button>

                <Button
                    onClick={() => {
                        store.dispatch(callInProgress(null));
                    }}
                    color="secondary"
                >
                    <FormattedMessage id="close" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    const { appState } = state;
    return { appState };
};

export default injectIntl(connect(mapStateToProps)(JoinCallDialog));
