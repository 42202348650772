import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import {
    ABOUT_US_LINK,
    FEATURES_LINK,
    FOR_SCHOOLS_LINK,
    FOR_TUTORING_LINK,
    SUBSCRIPTIONS_LINK,
    TUTORIAL_LINK,
} from '../../../common/urlLinks';
import { COLORS2 } from '../../../constants';
import SubMenu from '../../../MyComponents/Submenu/SubMenu';

const useStyles = makeStyles(() => ({
    topMenuNav: {
        fontWeight: 600,
        fontSize: '0.875rem',
        lineHeight: '1.063rem',
        textAlign: 'right',
        letterSpacing: '0.16rem',
        color: COLORS2.darkGreen,
    },
    topMenuNavActive: {
        fontWeight: 700,
        fontSize: '0.875rem',
        lineHeight: '1.063rem',
        textAlign: 'right',
        letterSpacing: '0.16rem',
        color: COLORS2.darkGreen,
    },
}));

const LOCATION_MAP = {
    HOME: {
        url: '/',
    },
    SUBSCRIPTIONS: {
        url: SUBSCRIPTIONS_LINK,
    },
    FEATURES: {
        url: FEATURES_LINK,
    },
    FOR_TUTORING: {
        url: FOR_TUTORING_LINK,
    },
    FOR_SCHOOLS: {
        url: FOR_SCHOOLS_LINK,
    },
    TUTORIAL: {
        url: TUTORIAL_LINK,
    },
    ABOUT_US: {
        url: ABOUT_US_LINK,
    },
};

export default function MainMenuNavigationWrapper() {
    const location = useLocation();
    const classes = useStyles();

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <Link to={LOCATION_MAP.ABOUT_US.url}>
                <Button
                    color={location.pathname.startsWith(LOCATION_MAP.ABOUT_US.url) ? 'default' : 'primary'}
                    className={
                        location.pathname.startsWith(LOCATION_MAP.ABOUT_US.url)
                            ? classes.topMenuNavActive
                            : classes.topMenuNav
                    }
                >
                    <FormattedMessage id="aboutUs" />
                </Button>
            </Link>

            <Link to={LOCATION_MAP.FOR_TUTORING.url}>
                <Button
                    color={location.pathname === LOCATION_MAP.FOR_TUTORING.url ? 'default' : 'primary'}
                    className={
                        location.pathname === LOCATION_MAP.FOR_TUTORING.url
                            ? classes.topMenuNavActive
                            : classes.topMenuNav
                    }
                >
                    <FormattedMessage id="topMenu.forTutoring.name" />
                </Button>
            </Link>
            <Link to={LOCATION_MAP.FOR_SCHOOLS.url}>
                <Button
                    color={location.pathname === LOCATION_MAP.FOR_SCHOOLS.url ? 'default' : 'primary'}
                    className={
                        location.pathname === LOCATION_MAP.FOR_SCHOOLS.url
                            ? classes.topMenuNavActive
                            : classes.topMenuNav
                    }
                >
                    <FormattedMessage id="topMenu.forSchools.name" />
                </Button>
            </Link>
            {!window.isTeams ? (
                <Link to={LOCATION_MAP.SUBSCRIPTIONS.url}>
                    <Button
                        color={location.pathname.includes(LOCATION_MAP.SUBSCRIPTIONS.url) ? 'default' : 'primary'}
                        className={
                            location.pathname.includes(LOCATION_MAP.SUBSCRIPTIONS.url)
                                ? classes.topMenuNavActive
                                : classes.topMenuNav
                        }
                    >
                        <FormattedMessage id="topMenu.subscriptions.name" />
                    </Button>
                </Link>
            ) : null}
            <SubMenu />
        </div>
    );
}
