import React, {
    useEffect,
    useRef,
    // useRef,
    useState,
} from 'react';
import { Avatar, Grid } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import Fab from '@material-ui/core/Fab';
import StickyElements from './StickyElements';
import boardManager from './boardManager';
import { BOARD_TYPE } from '../../constants';
import Chip from '@material-ui/core/Chip';
import { FormattedMessage } from 'react-intl';
import drawUtils from './drawUtils';

const multiBoardElementStyle = {
    // transition: 'width 0.3s, height 0.3s, transform 0.3s',
    width: '100%',
    // textAlign: 'center',
    position: 'relative',
    overflow: 'hidden',
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
};

const MultiBoardElement = ({
                               item,
                               onSelect,
                               isSelected,
                               isAdmin,
                               userCount,
                               isHidden,
                               onClose,
                               stickyRef,
                               setWriting,
                           }) => {
    // console.log('==== rendering elem', item.email);

    // const debounceReRender = useRef({ debouncer: null });
    const [pageCount, setPageCount] = useState(null);
    const updatedListeners = useRef(false);
    const [userState, setUserState] = useState(null);

    if (item && item.email) {
        window[`targetMultiBoardPageCount${item.email}`] = () => {
            setPageCount(pageCountFunc());
        };
    }

    if (item && item.email) {
        window[`targetMultiBoardStatusRefresh${item.email}`] = (data) => {
            setUserState({ ...data });
            // updateDataBasedOnChanges(data);
        };
    }
    const pageCountFunc = () => {
        let pageCount = null;
        if (boardManager.isAdmin && boardManager.lessonState.individualBoards[item.email]) {
            if (
                boardManager.lessonState.individualBoards[item.email].pages &&
                boardManager.lessonState.individualBoards[item.email].pages.length > 1
            ) {
                pageCount = `${boardManager.lessonState.individualBoards[item.email].currentPageNo + 1} / ${
                    boardManager.lessonState.individualBoards[item.email].pages.length
                }`;
            }
        }
        return pageCount;
    };

    const updateDataBasedOnChanges = () => {
        let el;
        if (userState &&
            userState.pages &&
            userState.pages.length > 0 &&
            userState.pages[userState.currentPageNo]) {
            userState.pages.forEach((p, index) => {
                let e = document.getElementById(`multiBoardChildCanvas-${item.email}-${p.id}`);
                if (!e) {
                    console.error('No canvas found for ' + item.email);
                    return;
                }
                if (index === userState.currentPageNo) {
                    el = e;
                    e.style.display = 'block';
                    window.addEventListenersForIndividualBoard(`multiBoardChildCanvas-${item.email}-${p.id}`);
                } else {
                    e.style.display = 'none';
                }
            });
        }
        // let el = document.getElementById(`multiBoardChildCanvas-${item.email}`);
        if (el) {
            if (!isSelected) {
                // console.log('=== updating style to auto', item.email);
                el.style.height = 'auto';
                let el1 = document.getElementById(`multiBoardElement-${item.email}`);
                if (el1) {
                    el1.style.height = 'auto';
                }
            }
            if (boardManager.lessonState.boardType === BOARD_TYPE.MULTI_BOARD) {
                if (stickyRef && stickyRef.current) {
                    if (
                        boardManager.lessonState.originalMultiBoardState &&
                        userState &&
                        userState.pages &&
                        userState.pages[0]
                    ) {
                        if (!userState.pages || userState.currentPageNo === 0) {
                            stickyRef.current.setFixedStickyElements(
                                boardManager.lessonState.originalMultiBoardState.originalSticky,
                            );
                            stickyRef.current.setFixedWritingUrl(
                                boardManager.lessonState.originalMultiBoardState.originalDownload,
                            );
                        } else {
                            stickyRef.current.setFixedStickyElements([]);
                            stickyRef.current.setFixedWritingUrl(null);
                        }
                        if (
                            userState &&
                            userState.pages &&
                            userState.pages.length > 0 &&
                            userState.pages[userState.currentPageNo] &&
                            userState.pages[userState.currentPageNo].meta
                        ) {
                            stickyRef.current.shallowSyncPageElements(
                                userState.pages[userState.currentPageNo].meta.stickyElements,
                            );
                            stickyRef.current.setLineage(userState.pages[userState.currentPageNo].meta.gridType);
                        }
                    } else if (boardManager.lessonState.originalMultiBoardState && !userState) {
                        stickyRef.current.setFixedStickyElements(
                            boardManager.lessonState.originalMultiBoardState.originalSticky,
                        );
                        stickyRef.current.setFixedWritingUrl(
                            boardManager.lessonState.originalMultiBoardState.originalDownload,
                        );
                        stickyRef.current.setLineage(boardManager.lessonState.originalMultiBoardState.originalLineage);
                    }
                    setPageCount(pageCountFunc());
                }
            }
        }
        return el;
    };
    useEffect(() => {
        if (boardManager?.lessonState?.boardType === BOARD_TYPE.MULTI_BOARD) {
            updateDataBasedOnChanges();
            // if (el) {
            // window.addEventListenersForIndividualBoard(`multiBoardElement-${item.email}`);
            // updatedListeners.current = true;
            // }
        }
        // if (boardManager?.lessonState?.boardType === BOARD_TYPE.SINGLE_BOARD && updatedListeners.current === true) {
        //     updatedListeners.current = false;
        // }
    });
    useEffect(() => {
        if (
            item &&
            boardManager.lessonState &&
            boardManager.lessonState.individualBoards &&
            boardManager.lessonState.individualBoards[item.email]
        ) {
            setUserState({ ...boardManager.lessonState.individualBoards[item.email] });
        }
    }, [isSelected, isHidden]);

    if (isSelected) {
        return (
            <div id={`multiBoardElementWrapper-${item.email}`} style={{ width: '100%' }}>
                <div id={`multiBoardElement-${item.email}`} style={multiBoardElementStyle}>
                    {/*<canvas*/}
                    {/*    id={`multiBoardChildCanvas-${item.email}`}*/}
                    {/*    width={drawUtils.CANVAS_ACTUAL_WIDTH}*/}
                    {/*    height={drawUtils.CANVAS_ACTUAL_HEIGHT}*/}
                    {/*    style={{ width: '100%' }}*/}
                    {/*/>*/}
                    {!!boardManager?.lessonState?.individualBoards && boardManager.lessonState.individualBoards[item.email] ?
                        boardManager.lessonState.individualBoards[item.email].pages.map((p) => {
                            return (
                                <canvas
                                    id={`multiBoardChildCanvas-${item.email}-${p.id}`}
                                    key={`key-multiBoardChildCanvas-${item.email}-${p.id}`}
                                    width={drawUtils.CANVAS_ACTUAL_WIDTH}
                                    height={drawUtils.CANVAS_ACTUAL_HEIGHT}
                                    style={{ width: '100%' }}
                                />);
                        }) : ((
                            <canvas
                                id={`multiBoardChildCanvas-${item.email}`}
                                width={drawUtils.CANVAS_ACTUAL_WIDTH}
                                height={drawUtils.CANVAS_ACTUAL_HEIGHT}
                                style={{ width: '100%' }}
                            />))}

                    <div
                        id={`multi-svg-container-${item.email}`}
                        style={{
                            position: 'absolute',
                            width: '100%',
                            top: '0px',
                            left: '0px',
                            transform: 'translate3d(0px, 0px, 0px)',
                            zIndex: '-1',
                        }}
                    >
                        <svg viewBox={`0 0 ${drawUtils.CANVAS_ACTUAL_WIDTH} ${drawUtils.CANVAS_ACTUAL_HEIGHT}`}>
                            <path id={`currentWritingMulti-${item.email}`} />
                        </svg>
                    </div>

                    <StickyElements
                        ref={stickyRef}
                        updateStickyImagePosition={boardManager.updateLocalStickyImagePosition}
                        removeStickyElement={boardManager.removeLocalStickyElement}
                        updateStickyTextInfo={boardManager.updateLocalStickyTextInfo}
                        updateLatexElement={boardManager.updateLocalStickyLatexInfo}
                        email={item.email}
                        setWriting={setWriting}
                    />

                    {isAdmin ? (
                        <Chip
                            label={item.name}
                            avatar={<Avatar src={item.avatar} />}
                            style={{
                                backgroundColor: 'rgba(0,0,0,0.5)',
                                color: '#fff',
                                position: 'absolute',
                                bottom: '0.5rem',
                                left: '0.5rem',
                                zIndex: -1,
                            }}
                        />
                    ) : (
                        <Chip
                            label={<FormattedMessage id='multiBoardBoard.myBoard' />}
                            color='primary'
                            style={{
                                backgroundColor: 'rgba(0,0,0,0.5)',
                                color: '#fff',
                                position: 'absolute',
                                bottom: '0.5rem',
                                left: '0.5rem',
                                zIndex: -1,
                            }}
                        />
                    )}

                    {isAdmin && userCount > 1 ? (
                        <Fab
                            className={'bounce-top'}
                            id={`multiBoardCloseButton`}
                            color='secondary'
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                // console.log('should close');
                                onClose();
                            }}
                            style={{ zIndex: '1000', position: 'absolute', bottom: '0.5rem', right: '0.5rem' }}
                        >
                            <CancelIcon style={{ verticalAlign: 'middle' }} />
                        </Fab>
                    ) : null}
                </div>
            </div>
        );
    } else {
        let displayValue = isHidden ? 'none' : 'block';
        let perc = 100;
        if (userCount >= 2 && userCount <= 4) {
            perc = 50;
        } else if (userCount > 4 && userCount <= 9) {
            perc = 30;
        } else if (userCount > 9) {
            perc = 25;
        }

        return (
            <div
                id={`multiBoardElementWrapper-${item.email}`}
                style={{ padding: '1rem', display: displayValue, width: perc + '%' }}
            >
                <div
                    id={`multiBoardElement-${item.email}`}
                    style={{
                        // transition: 'width 0.3s, height 0.3s, transform 0.3s',
                        width: '100%',
                        position: 'relative',
                        boxShadow: '0 0 0 2px #07767D33',
                        border: '2px',
                        borderStyle: 'solid',
                        overflow: 'hidden',
                        borderRadius: '1rem',
                        borderColor: '#07767D',
                        height: 'auto',
                        background: item.online === false ? 'rgba(100,0,0,0.2)' : 'none',
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        // console.log('should close');
                        if (!isSelected) {
                            onSelect(item.email);
                        }
                    }}
                >
                    {/*<canvas*/}
                    {/*    id={`multiBoardChildCanvas-${item.email}`}*/}
                    {/*    width={drawUtils.CANVAS_ACTUAL_WIDTH}*/}
                    {/*    height={drawUtils.CANVAS_ACTUAL_HEIGHT}*/}
                    {/*    style={{ width: '100%' }}*/}
                    {/*/>*/}

                    {!!boardManager?.lessonState?.individualBoards && boardManager.lessonState.individualBoards[item.email] ?
                        boardManager.lessonState.individualBoards[item.email].pages.map((p) => {
                            return (
                                <canvas
                                    id={`multiBoardChildCanvas-${item.email}-${p.id}`}
                                    key={`key-multiBoardChildCanvas-${item.email}-${p.id}`}
                                    width={drawUtils.CANVAS_ACTUAL_WIDTH}
                                    height={drawUtils.CANVAS_ACTUAL_HEIGHT}
                                    style={{ width: '100%' }}
                                />);
                        }) : (
                            <canvas
                                id={`multiBoardChildCanvas-${item.email}`}
                                width={drawUtils.CANVAS_ACTUAL_WIDTH}
                                height={drawUtils.CANVAS_ACTUAL_HEIGHT}
                                style={{ width: '100%' }}
                            />)
                    }


                    <StickyElements
                        ref={stickyRef}
                        updateStickyImagePosition={boardManager.updateLocalStickyImagePosition}
                        removeStickyElement={boardManager.removeLocalStickyElement}
                        updateStickyTextInfo={boardManager.updateLocalStickyTextInfo}
                        email={item.email}
                    />

                    <Grid
                        container
                        style={{
                            left: '0px',
                            bottom: '0px',
                            position: 'absolute',
                        }}
                    >
                        <Grid item xs={12} sm={6} style={{ textAlign: 'left' }}>
                            <Chip
                                label={item.name}
                                avatar={<Avatar src={item.avatar} />}
                                style={{ backgroundColor: 'rgba(0,0,0,0.5)', color: '#fff' }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
                            {pageCount ? (
                                <Chip label={pageCount} style={{ backgroundColor: 'rgba(0,0,0,0.5)', color: '#fff' }} />
                            ) : null}
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }
};

export default MultiBoardElement;
