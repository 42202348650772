import MenuList from '@material-ui/core/MenuList';
import { MenuItem } from '@material-ui/core';
import EditIcon from '../../../MyComponents/icons/EditIcon';
import { FormattedMessage } from 'react-intl';
import DeleteIcon from '../../../MyComponents/icons/DeleteIcon';
import { COLORS2, LIBRARY_MANAGER_STATE } from '../../../constants';
import AddNewSubFolderIcon from '../../../MyComponents/icons/AddNewSubFolderIcon';
import Box from '@material-ui/core/Box';
import { Button, Grid, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ModalContext from '../ModalContext/ModalContext';
import ShareLessonIcon from '../../../MyComponents/icons/ShareLessonIcon';
import ShareCollectionsMobile from '../ShareCollections/ShareCollectionsMobile';
import GenericDeletionConfirmation from '../../../MyComponents/GenericDeletionConfirmation/GenericDeletionConfirmation';

const useStyles = makeStyles(() => ({
    itemButton: {
        fontWeight: 400,
        fontSize: '1rem',
        textAlign: 'start',
        textTransform: 'none',
        letterSpacing: '-0.01rem',
        color: COLORS2.darkGreen,
    },
    itemButtonDelete: {
        fontWeight: 400,
        fontSize: '1rem',
        textAlign: 'start',
        textTransform: 'none',
        letterSpacing: '-0.01rem',
        color: COLORS2.virtualRed,
    },
}));

const MobileModalContent = ({
    intl,
    selectedNode,
    editBoard,
    deleteBoard,
    cancelFolderDeletion,
    create,
    addToCollection,
    loadCollections,
    state = LIBRARY_MANAGER_STATE.MANAGE,
}) => {
    const classes = useStyles();
    const modalStateContext = useContext(ModalContext);
    const [changeContent, setChangeContent] = React.useState(false);
    const [deleteConfirmation, setDeleteConfirmation] = React.useState(false);

    const handleChangeContent = () => {
        setChangeContent(true);
    };

    const deleteBoardMobile = () => {
        setDeleteConfirmation(true);
    };

    return (
        <>
            {changeContent ? (
                <>
                    <ShareCollectionsMobile
                        intl={intl}
                        selectedNode={selectedNode}
                        changeContent={changeContent}
                        nodeId={selectedNode.id}
                        loadCollections={loadCollections}
                    />
                </>
            ) : (
                <>
                    {selectedNode && !selectedNode.isCollection && !selectedNode.isUserBoard ? (
                        <MenuList autoFocusItem={modalStateContext.open} style={{ padding: '1.5rem' }}>
                            <MenuItem onClick={editBoard}>
                                <Button size="small" startIcon={<EditIcon />} className={classes.itemButton}>
                                    <FormattedMessage id="redesign.collections.renameSingleBoard.title" />
                                </Button>
                            </MenuItem>
                            <MenuItem onClick={deleteBoard}>
                                <Button size="small" startIcon={<DeleteIcon />} className={classes.itemButtonDelete}>
                                    <FormattedMessage id="generic.label.delete" />
                                </Button>
                            </MenuItem>
                        </MenuList>
                    ) : null}
                    {selectedNode &&
                    selectedNode.isBoardSet &&
                    !selectedNode.isUserBoard &&
                    !selectedNode.isUserCollection ? (
                        <MenuList autoFocusItem={modalStateContext.open} style={{ padding: '1.5rem' }}>
                            <MenuItem onClick={deleteBoard}>
                                <Button size="small" startIcon={<DeleteIcon />} className={classes.itemButtonDelete}>
                                    <FormattedMessage id="generic.label.delete" />
                                </Button>
                            </MenuItem>
                        </MenuList>
                    ) : null}
                    {selectedNode &&
                    selectedNode.isCollection &&
                    !selectedNode.isBoardSet &&
                    state === LIBRARY_MANAGER_STATE.MANAGE &&
                    !create ? (
                        <MenuList autoFocusItem={modalStateContext.open} style={{ padding: '1.5rem' }}>
                            <MenuItem
                                onClick={() => {
                                    addToCollection(selectedNode);
                                }}
                            >
                                <Button size="small" startIcon={<AddNewSubFolderIcon />} className={classes.itemButton}>
                                    <FormattedMessage id="redesign.collections.createSubFolder.title" />
                                </Button>
                            </MenuItem>
                            <MenuItem onClick={editBoard}>
                                <Button size="small" startIcon={<EditIcon />} className={classes.itemButton}>
                                    <FormattedMessage id="redesign.collections.editCollection.title" />
                                </Button>
                            </MenuItem>
                            <MenuItem>
                                <Button
                                    size="small"
                                    startIcon={<ShareLessonIcon />}
                                    onClick={handleChangeContent}
                                    className={classes.itemButton}
                                >
                                    <FormattedMessage id="redesign.collections.shareCollection.title" />
                                </Button>
                            </MenuItem>
                            <MenuItem onClick={deleteBoardMobile}>
                                <Button size="small" startIcon={<DeleteIcon />} className={classes.itemButtonDelete}>
                                    <FormattedMessage id="redesign.collections.removeCollection.title" />
                                </Button>
                            </MenuItem>
                            {deleteConfirmation ? (
                                <Box style={{ padding: '1rem', width: '90vw' }}>
                                    <Typography variant="body1" style={{ color: COLORS2.darkGreen }}>
                                        <FormattedMessage id="redesign.collections.removeCollection.confirmation" />
                                    </Typography>
                                    <Grid
                                        container
                                        spacing={2}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            marginTop: '1rem',
                                        }}
                                    >
                                        <Button onClick={deleteBoard}>
                                            <FormattedMessage id="redesign.collections.removeCollection.confirmation.yes" />
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                setDeleteConfirmation(false);
                                            }}
                                        >
                                            <FormattedMessage id="redesign.collections.removeCollection.confirmation.no" />
                                        </Button>
                                    </Grid>
                                </Box>
                            ) : null}
                            {/* <GenericDeletionConfirmation
                                open={deleteConfirmation}
                                deleteFunction={() => {
                                    console.log('gigi')
                                }}
                                onClose={setDeleteConfirmation}
                                value={''}
                            /> */}
                        </MenuList>
                    ) : null}
                </>
            )}
        </>
    );
};

export default MobileModalContent;
