import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function OpenFolder(props) {
    return (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 375 375" version="1.0">
                <g>
                    <path
                        d="M 340.910156 119.238281 C 359.738281 119.238281 375 134.488281 375 153.300781 L 374.59375 156.992188 L 340.839844 308.746094 C 339.714844 326.542969 324.914062 340.628906 306.816406 340.628906 L 34.089844 340.628906 C 15.261719 340.628906 0 325.378906 0 306.566406 L 0 68.152344 C 0 49.339844 15.261719 34.089844 34.089844 34.089844 L 136.363281 34.089844 C 155.453125 34.089844 167.605469 42.1875 179.8125 57.574219 C 180.625 58.597656 184.261719 63.3125 185.15625 64.417969 C 187.835938 67.738281 188.382812 68.144531 187.53125 68.152344 L 306.816406 68.152344 C 325.644531 68.152344 340.910156 83.398438 340.910156 102.210938 Z M 306.816406 119.238281 L 306.816406 102.210938 L 187.410156 102.210938 C 175.167969 102.144531 167.195312 96.417969 158.621094 85.800781 C 157.4375 84.335938 153.679688 79.464844 153.09375 78.730469 C 146.710938 70.679688 142.914062 68.152344 136.363281 68.152344 L 34.089844 68.152344 L 34.089844 151.453125 L 34.585938 149.21875 C 39.378906 129.824219 48.296875 119.238281 68.183594 119.238281 Z M 34.507812 306.566406 L 306.816406 306.566406 L 307.222656 302.875 L 340.492188 153.300781 L 68.800781 153.300781 C 68.535156 154.144531 68.179688 155.367188 67.777344 156.992188 Z M 34.507812 306.566406"
                        fillRule="evenodd"
                    />
                </g>
            </svg>
        </SvgIcon>
    );
}
