import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { COLORS2 } from '../../constants';

const useStyles = makeStyles(() => ({
    buttonGroup: {
        backgroundColor: 'rgba(255,255,255,0.9)',
        borderRadius: '4.75rem',
        padding: '0.25rem',
    },
}));

export const ButtonGroupMonthly = ({ billAnnual, handleChange }) => {
    const classes = useStyles();

    return (
        <ToggleButtonGroup className={classes.buttonGroup} onChange={handleChange}>
            <ToggleButton
                style={{
                    color: billAnnual ? COLORS2.darkGreen : '#FFFFFF',
                    backgroundColor: billAnnual ? 'rgba(255,255,255,0.1)' : COLORS2.darkGreen,
                    width: '6.375rem',
                    height: '2.5rem',
                    borderRadius: '4.75rem',
                    border: 'none',
                    marginRight: '0.1rem',
                }}
                checked={billAnnual}
                value="monthly"
            >
                <FormattedMessage id="subscriptions.subscription.monthly" />
            </ToggleButton>
            <ToggleButton
                style={{
                    color: billAnnual ? '#FFFFFF' : COLORS2.darkGreen,
                    backgroundColor: billAnnual ? COLORS2.darkGreen : 'rgba(255,255,255,0.1)',
                    width: '6.375rem',
                    height: '2.5rem',
                    borderRadius: '4.75rem',
                    border: 'none',
                }}
                checked={billAnnual}
                value="annual"
            >
                <FormattedMessage id="subscriptions.subscription.yearly" />
            </ToggleButton>
        </ToggleButtonGroup>
    );
};
