import { Grid, IconButton, Modal, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import FindMoreIcon from '../../MyComponents/icons/FindMoreIcon';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FindMoreModalPicture from '../../MyComponents/icons/FindMoreModalPicture';
import { COLORS2 } from '../../constants';
import { Close } from '@material-ui/icons';
import { tutorialLinks } from '../../common/urlLinks';
import { FindMoreButton } from '../Home/HomeFeatures/FindMoreButton';

const useStyles = makeStyles((theme) => ({
    modalStyle: {
        background: 'linear-gradient(180deg, rgba(2, 35, 37, 0) 0%, #022325 100%)',
        //opacity: '0.5',
        transitionTimingFunction: 'ease-out',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    findMoreText: {
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '1rem',
        lineHeight: '1rem',
        letterSpacing: '-0.01em',
        textTransform: 'none',
        color: COLORS2.virtualGreen,
    },
    boxModalStyle: {
        position: 'absolute',
        background: '#FFFFFF',
        width: 'fit-content',
        height: 'fit-content',
        boxShadow: '1.5rem 1.5rem 3rem rgba(0, 0, 0, 0.16)',
        borderRadius: '2rem',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '2rem 0rem 2rem 2rem',
    },
    boxModalStyleMobile: {
        position: 'absolute',
        background: '#FFFFFF',
        width: '90%',
        height: 'fit-content',
        boxShadow: '1.5rem 1.5rem 3rem rgba(0, 0, 0, 0.16)',
        borderRadius: '2rem',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingBottom: '1.25rem',
    },
    descriptionModalWithoutBold: {
        fontStyle: 'normal',
        fontWeight: '300',
        fontSize: '2.5rem',
        lineHeight: '3rem',
        letterSpacing: '-0.01em',
        textTransform: 'none',
    },
    descriptionModalWithoutBoldMobile: {
        fontStyle: 'normal',
        fontWeight: '300',
        fontSize: '2rem',
        lineHeight: '2.5rem',
        letterSpacing: '-0.01em',
        textTransform: 'none',
    },
    descriptionModalWithBold: {
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '2.5rem',
        lineHeight: '3rem',
        letterSpacing: '-0.01em',
        textTransform: 'none',
    },
    descriptionModalWithBoldMobile: {
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '2rem',
        lineHeight: '2.5rem',
        letterSpacing: '-0.01em',
        textTransform: 'none',
    },
    itemModal: {
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '1rem',
        lineHeight: '1.625rem',
        letterSpacing: '-0.01em',
        textTransform: 'none',
        color: COLORS2.virtualText,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));
export const FindMoreAboutClasses = ({ isMobile }) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <Button startIcon={<FindMoreIcon />} onClick={handleOpen}>
                {!isMobile ? (
                    <Typography className={classes.findMoreText}>
                        <FormattedMessage id="redesign.menu.manageClass.findOutMore" />
                    </Typography>
                ) : null}
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                className={classes.modalStyle}
                BackdropProps={{ style: { backgroundColor: 'transparent' } }}
            >
                <Box className={isMobile ? classes.boxModalStyleMobile : classes.boxModalStyle}>
                    <Grid container style={{ paddingLeft: '1rem' }}>
                        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                            <Close />
                        </IconButton>
                        <Grid item xs={10}>
                            <div style={{ paddingTop: '1rem', paddingLeft: '1rem' }}>
                                <Typography variant="h4" className={isMobile ? classes.descriptionModalWithoutBoldMobile : classes.descriptionModalWithoutBold}>
                                    <FormattedMessage id="redesign.page.home.feature.groups.descriptionWithoutBold" />
                                </Typography>
                                <Typography variant="h4" className={isMobile ? classes.descriptionModalWithBoldMobile : classes.descriptionModalWithBold}>
                                    <FormattedMessage id="redesign.page.home.feature.groups.descriptionWihBold" />
                                </Typography>
                                <ul>
                                    <li style={{ color: COLORS2.virtualText }}>
                                        <Typography variant="h6" className={classes.itemModal}>
                                            <FormattedMessage id="page.home.feature.groups.description.1" />
                                        </Typography>
                                    </li>
                                    <li style={{ color: COLORS2.virtualText }}>
                                        <Typography variant="h6" className={classes.itemModal}>
                                            <FormattedMessage id="page.home.feature.groups.description.2" />
                                        </Typography>
                                    </li>
                                    <li style={{ color: COLORS2.virtualText }}>
                                        <Typography variant="h6" className={classes.itemModal}>
                                            <FormattedMessage id="page.home.feature.groups.description.3" />
                                        </Typography>
                                    </li>
                                </ul>
                            </div>
                        </Grid>
                        {!isMobile ? (
                            <Grid item xs={2} style={{ display: 'flex' }}>
                                <FindMoreModalPicture />
                            </Grid>
                        ) : null}
                    </Grid>
                    <a href={tutorialLinks.groups}>
                        <FindMoreButton />
                    </a>
                </Box>
            </Modal>
        </>
    );
};
