import React from 'react';
import Divider from '@material-ui/core/Divider';
import { Container, Grid, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import Sticky from 'react-stickynode';
import styles from './styles';
import { useDispatch } from 'react-redux';
import DesktopSummaryMenu from '../../../MyComponents/SummaryMenu/DesktopSummaryMenu/DesktopSummaryMenu';
import { sections } from '../constants';
import { UPDATE_FEATURES_ACTIVE_SECTION } from '../../../redux/actionTypes';
import IndividualBoards from '../Sections/IndividualBoards';
import PasteFeature from '../Sections/PasteFeature';
import MobileFeature from '../Sections/MobileFeature';
import ControlFeature from '../Sections/ControlFeature';
import SpecialLineageFeature from '../Sections/SpecialLineageFeature';
import GroupsFeature from '../Sections/GroupsFeature';
import LibraryFeature from '../Sections/LibraryFeature';
import PdfFeature from '../Sections/PdfFeature';
import AudioVideoFeature from '../Sections/AudioVideoFeature';
import Geometry from '../Sections/Geometry';
import MathConvertor from '../Sections/MathConvertor';

const FeaturesDesktop = ({ language, refs }) => {
    const classes = styles();
    const dispatch = useDispatch();

    return (
        <>
            <Grid container style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                <Grid item xs={10} sm={10} md={10} xl={10} style={{ paddingRight: '3rem' }}>
                    <Typography variant="h1">
                        <FormattedMessage
                            id={'redesign.page.home.feature.main'}
                            values={{
                                b: (chunks) => (
                                    <b>
                                        <br></br>
                                        {chunks}
                                        <br></br>
                                    </b>
                                ),
                            }}
                        />
                    </Typography>
                </Grid>
                <Grid item xs={2} sm={2} md={2} xl={2} style={{ paddingRight: '3rem' }} />
            </Grid>
            <Divider variant="middle" />

            <Container
                id="section-featuresStart"
                maxWidth="xl"
                className={classes.tutorialRoot}
                ref={(el) => {
                    if (el && el.id) {
                        refs.current[el.id.toLowerCase()] = el;
                    }
                }}
            >
                <Grid container>
                    <Grid item xs={8} sm={8} md={8} xl={8} style={{ paddingRight: '3rem' }}>
                        <IndividualBoards language={language} classes={classes} refs={refs} />
                        <Divider variant="middle" />
                        <PasteFeature language={language} classes={classes} refs={refs} />
                        <Divider variant="middle" />
                        <MobileFeature language={language} classes={classes} refs={refs} />
                        <Divider variant="middle" />
                        <ControlFeature language={language} classes={classes} refs={refs} />
                        <Divider variant="middle" />
                        <SpecialLineageFeature language={language} classes={classes} refs={refs} />
                        <Divider variant="middle" />
                        <GroupsFeature language={language} classes={classes} refs={refs} />
                        <Divider variant="middle" />
                        <LibraryFeature language={language} classes={classes} refs={refs} />
                        <Divider variant="middle" />
                        <PdfFeature language={language} classes={classes} refs={refs} />
                        <Divider variant="middle" />
                        <AudioVideoFeature language={language} classes={classes} refs={refs} />
                        <Divider variant="middle" />
                        <Geometry language={language} classes={classes} refs={refs} />
                        <Divider variant="middle" />
                        <MathConvertor language={language} classes={classes} refs={refs} />
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} xl={4}>
                        <Sticky enabled={true} top={8}>
                            <DesktopSummaryMenu
                                sections={sections}
                                path={'/features'}
                                updateFunction={(id) => {
                                    dispatch({ type: UPDATE_FEATURES_ACTIVE_SECTION, payload: { sectionId: id } });
                                }}
                                selectorFunc={(state) => state.features.sectionId}
                            />
                        </Sticky>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default FeaturesDesktop;
