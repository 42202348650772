import { Rnd } from 'react-rnd';
import MoveIcon from '../../MyComponents/icons/MoveIcon';
import React from 'react';
import drawUtils from './drawUtils';

const moverActive = {
    cursor: 'move',
    zIndex: 0,
};

const DraggerControl = ({
    point,
    width = 32,
    height = 32,
    el,
    startPoint,
    icon = <MoveIcon />,
    adjustTop = 0,
    adjustLeft = 0,
    onStart,
    onDrag,
    onStop,
}) => {
    let reverseScaledPoint = drawUtils.reverseScalePoint(startPoint, point.x, point.y);
    if (!reverseScaledPoint) return null;
    return (
        <Rnd
            key={'stickyControl-' + el.id}
            position={{
                x: reverseScaledPoint.x + adjustLeft,
                y: reverseScaledPoint.y + adjustTop,
            }}
            size={{
                width,
                height,
            }}
            enableResizing="false"
            bounds="parent"
            dragAxis="both"
            onDragStop={(e, d) => {
                // let scaledPoint = drawUtils.scalePoint(startPoint, d.x, d.y);
                // updateStickyImagePosition(el.id, scaledPoint.x, scaledPoint.y);
                // this.setState({ x: d.x, y: d.y });
                // console.log('=== drag stop', e, d);
                if (onStop) {
                    onStop(e, d);
                }
            }}
            onDragStart={(e, d) => {
                // let scaledPoint = drawUtils.scalePoint(startPoint, d.x, d.y);
                // updateStickyImagePosition(el.id, scaledPoint.x, scaledPoint.y);
                // this.setState({ x: d.x, y: d.y });
                // console.log('=== drag start', e, d);
                if (onStart) {
                    onStart(e, d);
                }
            }}
            onDrag={(e, d) => {
                // let scaledPoint = drawUtils.scalePoint(startPoint, d.x, d.y);
                // updateStickyImagePosition(el.id, scaledPoint.x, scaledPoint.y);
                // this.setState({ x: d.x, y: d.y });
                // console.log('=== drag', e, d);
                if (onDrag) {
                    onDrag(e, d);
                }
            }}
            style={moverActive}
            onTouchStart={(e) => {
                e.stopPropagation();
            }}
            onPointerDown={(e) => {
                e.stopPropagation();
            }}
        >
            {icon}
        </Rnd>
    );
};

export default DraggerControl;
