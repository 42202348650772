import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function YouTubeIcon(props) {
    return (
        <SvgIcon {...props} style={{ verticalAlign: 'bottom' }}>
            <svg viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.66">
                    <path fillRule="evenodd" clipRule="evenodd" d="M30.2831 0.716839C31.7469 1.11195 32.8973 2.27188 33.2891 3.74789C33.9971 6.4205 34 12 34 12C34 12 34 17.5795 33.2891 20.2522C32.8973 21.7282 31.7469 22.8881 30.2831 23.2831C27.6327 24 16.9999 24 16.9999 24C16.9999 24 6.3673 24 3.71682 23.2831C2.25305 22.8881 1.10273 21.7282 0.710899 20.2522C0 17.5795 0 12 0 12C0 12 0 6.4205 0.710899 3.74789C1.10273 2.27188 2.25305 1.11195 3.71682 0.716839C6.3673 0 16.9999 0 16.9999 0C16.9999 0 27.6327 0 30.2831 0.716839ZM22.4307 11.9995L13.5977 17.1415V6.85742L22.4307 11.9995Z" fill="white"/>
                </g>
            </svg>
        </SvgIcon>
    );
}
