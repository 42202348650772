import axios from 'axios';

let apiUtil = {};

function handleLogin() {
    if (window.location.host === 'localhost:3000') {
        window.location.assign('http://localhost:4000/login');
    } else if (window.location.host === '192.168.2.112:3000') {
        window.location.assign('http://192.168.2.112:4000/login');
    } else {
        if (window.location.pathname === '/login/google') {
            window.location.assign(window.location.protocol + '//' + window.location.host + '/login/google');
        } else if (window.location.pathname === '/login/facebook') {
            window.location.assign(window.location.protocol + '//' + window.location.host + '/login/facebook');
        } else if (window.location.pathname === '/login/microsoft') {
            window.location.assign(window.location.protocol + '//' + window.location.host + '/login/microsoft');
        } else if (window.location.pathname === '/login/microsoft-teams') {
            window.location.assign(window.location.protocol + '//' + window.location.host + '/login/microsoft-teams');
        } else {
            if (window.isTeams) {
                window.location.assign(
                    window.location.protocol + '//' + window.location.host + '/login/microsoft-teams'
                );
                return;
            }
            window.location.assign(window.location.protocol + '//' + window.location.host + '/login');
        }
    }
}

apiUtil.runRequest = function (data) {
    if (typeof data === 'string') {
        return axios.get(data).catch((err) => {
            if (err.request) {
                if (err.request.status === 401) {
                    console.log('should store location', window.location.pathname);
                    window.localStorage.setItem('pathBeforeLogin', window.location.pathname + window.location.search);
                    handleLogin();
                    return;
                }
            }
            console.error('error on call ', err.request?.status, data, err);
            return Promise.reject(err);
        });
    } else {
        return axios(data).catch((err) => {
            if (err.request) {
                if (err.request.status === 401) {
                    console.log('should store location', window.location.pathname);
                    window.localStorage.setItem('pathBeforeLogin', window.location.pathname + window.location.search);
                    handleLogin();
                    return;
                }
            }
            console.error('error on call ', err.request?.status, data, err);
            return Promise.reject(err);
        });
    }
};

apiUtil.runMyScript = (obj) => {
    return axios({
        url: '/api/private/management/convertSvg',
        method: 'POST',
        data: obj,
    });
};

export default apiUtil;
