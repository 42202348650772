import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    compass: {
        position: 'relative',
        width: '100%',
        borderTop: '2px dashed #06515633',
        height: '2px',
    },
    pin: { position: 'absolute', top: '-24px', left: '-12px' },
    pen: { position: 'absolute', top: '-24px', right: '-12px' },
}));

export default function CompassDIV() {
    const classes = useStyles();
    return <div className={classes.compass}></div>;
}
