import React, { useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    InputBase,
    makeStyles,
    Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import storageApi from '../../../Api/storageApi.js';
import NavigateNextIcon from '@material-ui/icons/NavigateNext.js';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore.js';
import drawUtils from '../../../pages/LessonPage/drawUtils.js';
import { sleep } from '../../../common/utils.js';
import { hideSpinner, showError, showSpinner } from '../../../redux/actions.js';
import store from '../../../redux/store.js';


const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: 'absolute', right: theme.spacing(1), top: theme.spacing(1), color: theme.palette.grey[500],
    },
    dialogContent: {
        padding: '0.5rem 0',
    },
    labelRootSelectedForEditing: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#FFFFFF',
        borderRadius: '1rem',
        border: '0.063rem solid #cc5c15',
        width: '100%',
        margin: '0rem 0.5rem',
    },
    centerContainer: {
        width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',
    },
    mainImageContainer: { display: 'flex', flexWrap: 'wrap', justifyContent: 'center', height: '60vh' },
    singleImageContainer: {
        width: '25%', height: '28vh', display: 'flex', alignItems: 'center', cursor: 'pointer',
    },
    imageStyle: { width: '100%', height: '100%', objectFit: 'contain', padding: '8px' },
    button: {
        whiteSpace: 'nowrap', margin: '1rem', minWidth: 'auto',
    },
    iconPosition: {
        width: '1.875rem', height: '1.375rem', marginTop: '0.563rem', marginLeft: '0.563rem',
    },
}));

function getThumbAndImage(data) {
    let res = null;
    if (data?.image && data.image.thumbnailLink && data.link) {
        res = {
            thumb: data.image.thumbnailLink, img: data.link,
        };
    }
    return res;
}

const GoogleSearchDialog = ({ intl, close, open }) => {
    const classes = useStyles();
    const [name, setName] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const [isError, setIsError] = React.useState(false);
    const [offset, setOffset] = React.useState(0);
    const [items, setItems] = React.useState([]);

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleSearch = () => {
        if (name.trim() === '') return;
        setIsLoading(true);
        setIsError(false);
        setItems([]);
        storageApi.searchGoogle(name, 8, offset).then(res => {
            setItems(res.data);
            setIsLoading(false);
        }).catch(err => {
            console.error('Unable to google search', err);
            setIsLoading(false);
            setIsError(true);
        });
    };
    useEffect(() => {
        handleSearch();
    }, [offset]);

    const loadImage = async (url) => {
        if (window.handleCroppedImage) {
            closeDialog();
            store.dispatch(showSpinner());
            try {
                let img = await drawUtils.buildImageFromUrlNoCache(`/api/private/proxy-images?url=${encodeURIComponent(decodeURIComponent(url))}`);
                let canvas = document.getElementById('googleSearchCanvasId');
                if (img && canvas) {
                    canvas.width = img.naturalWidth;
                    canvas.height = img.naturalHeight;
                    await sleep(100);
                    let ctx = canvas.getContext('2d');
                    ctx.imageSmoothingEnabled = true;
                    ctx.imageSmoothingQuality = 'high';
                    ctx.drawImage(img, 0, 0);
                    await sleep(200);
                    window.handleCroppedImage(canvas.toDataURL());
                } else {
                    store.dispatch(showError('ERROR_ADDING_GOOGLE_IMAGE'));
                }
            } catch (err) {
                store.dispatch(showError('ERROR_ADDING_GOOGLE_IMAGE'));
            }
            store.dispatch(hideSpinner());
        }
    };

    const displayLoadingState = () => {
        if (isLoading) {
            return <div className={classes.centerContainer}><CircularProgress /></div>;
        }
        if (isError) {
            return (<div className={classes.centerContainer}>
                <Typography variant='subtitle2' style={{ margin: '2rem' }}>
                    <FormattedMessage id='notification.code.GENERIC_ERROR' />
                </Typography>
            </div>);
        }

        if (items.length > 0 && items.length <= 8) {
            return null;
        }
        return (<div className={classes.centerContainer}><Typography variant='subtitle2' style={{ margin: '2rem' }}>
            <FormattedMessage id='generic.label.searchGoogleImage' />
        </Typography></div>);
    };

    const displayButtonState = () => {
        let disabledAll = false;
        if (isLoading) {
            disabledAll = true;
        }
        if (isError) {
            disabledAll = true;
        }


        return (<>
            <IconButton
                color='primary'
                aria-label='next'
                component='span'
                onClick={() => {
                    setOffset(offset - 8);
                }}
                disabled={offset === 0 || disabledAll}
                size={'medium'}
            >
                <NavigateBeforeIcon fontSize='large' />
            </IconButton>
            <IconButton
                color='primary'
                aria-label='next'
                component='span'
                onClick={() => {
                    setOffset(offset + 8);
                }}
                disabled={items.length < 8 || disabledAll}
                size={'medium'}
            >
                <NavigateNextIcon fontSize='large' />
            </IconButton></>);
    };

    let content = (<Grid container spacing={2} className={classes.dialogContent}>
        <Grid item xs={12} sm={6} className={classes.labelRootSelectedForEditing}>
            <InputBase
                id='outlined-basic'
                placeholder={intl.formatMessage({ id: 'collections.createCollection.name' })}
                value={name}
                onChange={handleNameChange}
                onKeyUp={(event) => {
                    if (event.keyCode === 13) {
                        if (name.trim().length > 0) {
                            handleSearch();
                        }
                    }
                }}
                required
                fullWidth
                endAdornment={name || name.trim() !== '' ? (
                    <IconButton
                        color='primary'
                        aria-label='search'
                        component='span'
                        variant='contained'
                        onClick={handleSearch}
                        size={'small'}
                    >
                        <SearchIcon fontSize='small' />
                    </IconButton>) : null}
            />
        </Grid>
        <Grid item xs={12} className={classes.mainImageContainer}>
            {displayLoadingState()}
            {items.length > 0 ? items.map((el, index) => {
                let d = getThumbAndImage(el);
                if (d) {
                    return (<div key={`google-img-${index}`}
                                 className={classes.singleImageContainer} onClick={() => {
                        loadImage(d.img);
                    }}>
                        <img
                            src={d.thumb}
                            className={classes.imageStyle}
                            alt={''}
                        />
                    </div>);
                } else {
                    return null;
                }
            }) : null}
        </Grid>
    </Grid>);

    const closeDialog = () => {
        close();
    };


    return (<Dialog
        open={open}
        onClose={closeDialog}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth
        maxWidth='lg'
    >
        <DialogTitle id='alert-dialog-title' disableTypography>
            <Typography variant='subtitle2'>
                <FormattedMessage id='generic.label.googleSearch' />
            </Typography>
            <IconButton aria-label='close' className={classes.closeButton} onClick={closeDialog}>
                <Close />
            </IconButton>
        </DialogTitle>
        <DialogContent>
            {content}</DialogContent>
        <DialogActions style={{ marginBottom: '0.5rem', marginRight: '1rem' }}>
            <div style={{ width: '100%', textAlign: 'center' }}>{displayButtonState()}</div>
            <Button onClick={closeDialog} size='large' variant='contained' color='default'>
                <FormattedMessage id='close' />
            </Button>
        </DialogActions>
    </Dialog>);
};

export default injectIntl(GoogleSearchDialog);
