import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Main from './Main/Main';
import { IntlProvider } from 'react-intl';
import adapter from 'webrtc-adapter';
import { determineDefaultLanguage } from './common/utils';
import managementApi from './Api/managementApi';
import store from './redux/store';
import { updateAppSubscription } from './redux/actions';
let en = require('./Translations/en.json');
let ro = require('./Translations/ro.json');
let it = require('./Translations/it.json');
let fr = require('./Translations/fr.json');

let translationsForUsersLocale = {
    en: en,
    ro: ro,
    it: it,
    fr: fr,
};

class App extends React.Component {
    constructor(props) {
        super(props);
        let currentLanguage = determineDefaultLanguage();
        this.state = { currentLanguage: currentLanguage };
    }

    updateLanguage = (lang) => {
        this.setState({ currentLanguage: lang });
        localStorage.setItem('language', lang);
    };

    componentDidMount() {
        managementApi
            .getSubscriptionTypesForPublic()
            .then((res) => {
                store.dispatch(updateAppSubscription(res.data));
            })
            .catch((err) => {
                console.error('Unable to get the subscriptions', err);
            });
    }

    render() {
        return (
            <IntlProvider
                locale={this.state.currentLanguage}
                messages={translationsForUsersLocale[this.state.currentLanguage]}
            >
                <BrowserRouter>
                    <Main
                        updateLanguage={this.updateLanguage}
                        style={{ 'max-width': '100%' }}
                        language={this.state.currentLanguage}
                    />
                </BrowserRouter>
            </IntlProvider>
        );
    }
}

export default App;
