import { makeStyles } from '@material-ui/core';
import { COLORS } from '../../../constants';

const styles = makeStyles({
    tutorialRoot: {
        '& hr': {
            width: '100%',
            margin: '1rem 0 5rem 0',
        },

        '& img': {
            width: '100%',
            border: '1px solid lightgray',
        },
        '& b': {
            padding: '0px',
        },
        paddingTop: '2rem',
        paddingLeft: '0px',
        paddingRight: '0px',
    },

    sampleFab: {
        '& span': {
            padding: '0px',
        },

        '& svg': {
            padding: '0px',
            verticalAlign: 'middle',
        },
        verticalAlign: 'middle',
        width: '36px',
        height: '36px',
        // padding: '4px',
        color: COLORS.primary,
        padding: '0px',
        marginLeft: '1rem',
    },
});

export default styles;
