import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import store from '../../redux/store';
import { showMultiBoardLessonState } from '../../redux/actions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import MultiBoardBox from '../../common/components/MultiBoardBox';
import { DialogTitle, IconButton, makeStyles, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import MultiBoardLessonListTable from '../../pages/EditClass/MultiBoardLessonListTable';

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

const MultiBoardDialog = (props) => {
    const classes = useStyles();

    const onClose = () => {
        store.dispatch(showMultiBoardLessonState(null));
    };

    const getDisplayableContent = () => {
        if (!props.appState.multiBoardLessonState) {
            return null;
        }
        if (
            !props.appState.multiBoardLessonState.id &&
            props.appState.multiBoardLessonState.lessonId &&
            props.appState.multiBoardLessonState.email
        ) {
            return (
                <MultiBoardLessonListTable
                    lessonId={props.appState.multiBoardLessonState.lessonId}
                    profileEmail={props.appState.multiBoardLessonState.email}
                    displayTitle={false}
                    isOwner={props.appState.multiBoardLessonState.isOwner}
                />
            );
        }

        return <MultiBoardBox />;
    };

    return (
        <Dialog open={!!props.appState.multiBoardLessonState} onClose={onClose} fullWidth maxWidth="lg">
            <DialogTitle disableTypography>
                <Typography variant="h4" style={{ margin: 0 }}>
                    <FormattedMessage id="menu.pastLessons" />
                </Typography>
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent style={{ height: '100vh' }}>{getDisplayableContent()}</DialogContent>
            <DialogActions style={{ marginBottom: '0.5rem', marginRight: '1rem' }}>
                {props.appState.multiBoardLessonState?.lessonId &&
                props.appState.multiBoardLessonState?.email &&
                props.appState.multiBoardLessonState.id ? (
                    <Button
                        onClick={() => {
                            store.dispatch(
                                showMultiBoardLessonState({
                                    id: null,
                                    lessonId: props.appState.multiBoardLessonState.lessonId,
                                    email: props.appState.multiBoardLessonState.email,
                                    isOwner: props.appState.multiBoardLessonState.isOwner,
                                })
                            );
                        }}
                        size="large"
                        variant="contained"
                        color="default"
                    >
                        <FormattedMessage id="home.firstGroup.back" />
                    </Button>
                ) : null}
                <Button
                    onClick={() => {
                        store.dispatch(showMultiBoardLessonState(null));
                    }}
                    size="large"
                    variant="contained"
                    color="default"
                >
                    <FormattedMessage id="close" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    const { appState } = state;
    return { appState };
};

export default injectIntl(connect(mapStateToProps)(MultiBoardDialog));
