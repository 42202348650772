import { withStyles } from '@material-ui/core/styles';
import { Badge } from '@material-ui/core';
import { COLORS2 } from '../../constants';

export const StyledBadge = withStyles(() => ({
    badge: {
        right: 2,
        top: 6,
        border: `0.14rem solid #FFFFFF`,
        color: '#FFFFFF',
        backgroundColor: COLORS2.virtualRed,
        width: '1.25rem',
        height: '1.25rem',
    },
}))(Badge);