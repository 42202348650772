import { Fab, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import GridIcon from '../../../../MyComponents/icons/GridIcon';
import SectionChapter from '../../SectionChapter';
import { COLORS } from '../../../../constants';

const LineageManagement = ({ language, classes, refs }) => {
    let lineageMenu = [
        {
            id: 'tutorial.manageLesson.rulingMenu.explanation',
            children: (
                <>
                    <Fab
                        size="small"
                        className={classes.sampleFab}
                        style={{ color: '#fff', backgroundColor: COLORS.primary }}
                    >
                        <GridIcon />
                    </Fab>
                    <ul>
                        <li>
                            <Typography>
                                <FormattedMessage
                                    id="tutorial.rulingMenu.tipe1"
                                    values={{
                                        b: (chunks) => <b>{chunks}</b>,
                                    }}
                                />
                            </Typography>
                        </li>
                        <li>
                            <Typography>
                                <FormattedMessage
                                    id="tutorial.rulingMenu.tipe2"
                                    values={{
                                        b: (chunks) => <b>{chunks}</b>,
                                    }}
                                />
                            </Typography>
                        </li>
                        <li>
                            <Typography>
                                <FormattedMessage
                                    id="tutorial.rulingMenu.dictando"
                                    values={{
                                        b: (chunks) => <b>{chunks}</b>,
                                    }}
                                />
                            </Typography>
                        </li>
                        <li>
                            <Typography>
                                <FormattedMessage
                                    id="tutorial.rulingMenu.math"
                                    values={{
                                        b: (chunks) => <b>{chunks}</b>,
                                    }}
                                />
                            </Typography>
                        </li>

                        <li>
                            <Typography>
                                <FormattedMessage
                                    id="tutorial.rulingMenu.music"
                                    values={{
                                        b: (chunks) => <b>{chunks}</b>,
                                    }}
                                />
                            </Typography>
                        </li>
                        <li>
                            <Typography>
                                <FormattedMessage
                                    id="tutorial.rulingMenu.mm"
                                    values={{
                                        b: (chunks) => <b>{chunks}</b>,
                                    }}
                                />
                            </Typography>
                        </li>
                    </ul>
                </>
            ),
            img: [
                `/static/asset/img/tutorial/withText/${language.toUpperCase()}/Lineage.jpg`,
                `/static/asset/img/home/SpecialLineage.png`,
            ],
        },
    ];
    return (
        <SectionChapter
            refs={refs}
            id={'section-manageLesson-rulingMenu'}
            title={'tutorial.manageLesson.rulingMenu.title'}
            explanations={lineageMenu}
            imageTop={false}
        />
    );
};

export default LineageManagement;
