import React from 'react';
import { ClickAwayListener, MenuItem, MenuList, Popper, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { FormattedMessage, injectIntl } from 'react-intl';
import EditIcon from '../icons/EditIcon';
import ShareLessonIcon from '../icons/ShareLessonIcon';
import DeleteIcon from '../icons/DeleteIcon';
import { makeStyles } from '@material-ui/core/styles';
import store from '../../redux/store';
import { shareLesson, userJoinRequest } from '../../redux/actions';
import { GA_EVENT_TREE } from '../../gaConstants';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import VerticalEllipsisIcon from '../icons/VerticalEllipsisIcon';
import { COLORS2 } from '../../constants';

const useStyles = makeStyles(() => ({
    ellipsisPopper: {
        zIndex: '1',
        boxSizing: 'border-box',
        width: '16rem',
        background: '#FFFFFF',
        border: '0.063rem solid rgba(6, 81, 86, 0.08)',
        boxShadow: '0rem 0.5rem 0.5rem rgba(0, 0, 0, 0.08)',
        borderRadius: '0.5rem',
    },
    menuText: {
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '1rem',
        lineHeight: '1 rem',
        letterSpacing: '-0.01rem',
        textTransform: 'none',
        color: COLORS2.darkGreen,
    },
    withoutHover: {
        '&:hover': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
        },
    },
    deleteMenuText: {
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '1rem',
        lineHeight: '1 rem',
        letterSpacing: '-0.01rem',
        textTransform: 'none',
        color: COLORS2.virtualRed,
    },
}));

const EllipsisMenu = ({ row, setModalItem }) => {
    let history = useHistory();
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const showModal = (item) => {
        setModalItem(item);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const share = (classId) => {
        window.logAppActivity(GA_EVENT_TREE.editGroup.actions.share);
        store.dispatch(shareLesson({ id: classId, name: classId.name }));
    };

    const join = (classId) => {
        window.logAppActivity(GA_EVENT_TREE.joinGroups.actions.join);
        store.dispatch(userJoinRequest({ classId: classId, userId: null }));
    };

    let editClass = function (classId) {
        window.logAppActivity(GA_EVENT_TREE.manageGroups.actions.edit);
        history.push('/class/' + classId);
    };

    return (
        <>
            <Button
                ref={anchorRef}
                onClick={handleToggle}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
            >
                {open ? (
                    <VerticalEllipsisIcon />
                ) : (
                    <VerticalEllipsisIcon
                        style={{
                            filter:
                                'invert(46%) sepia(95%) saturate(601%) hue-rotate(140deg) brightness(93%) contrast(94%)',
                        }}
                    />
                )}
            </Button>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                disablePortal
                className={classes.ellipsisPopper}
                style={{ height: row.requests > 0 ? '13rem' : '10rem' }}
            >
                <ClickAwayListener onClickAway={handleClose}>
                    <MenuList autoFocusItem={open} id="menu-list-grow">
                        {row.requests && row.requests > 0 ? (
                            <MenuItem
                                onClick={() => {
                                    join(row.id);
                                }}
                            >
                                <Button
                                    startIcon={
                                        <div
                                            style={{
                                                background: COLORS2.virtualRed,
                                                borderRadius: '1rem',
                                                width: '1.25rem',
                                                height: '1.25rem',
                                                boxShadow: '0 0 0rem 0.5rem rgba(243, 83, 37, 0.1)',
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    marginTop: '0.1rem',
                                                    marginLeft: '0.2rem',
                                                    fontStyle: 'normal',
                                                    fontWeight: '700',
                                                    fontSize: '0.8rem',
                                                    lineHeight: '1rem',
                                                    textAlign: 'center',
                                                    letterSpacing: '0.24rem',
                                                    textTransform: 'uppercase',
                                                    color: '#FFFFFF',
                                                }}
                                            >
                                                {row.requests}
                                            </Typography>
                                        </div>
                                    }
                                    className={classes.withoutHover}
                                >
                                    <Typography className={classes.menuText}>
                                        <FormattedMessage id={'redesign.generic.label.newRequests'} />
                                    </Typography>
                                </Button>
                            </MenuItem>
                        ) : null}
                        <MenuItem
                            onClick={() => {
                                editClass(row.id);
                            }}
                        >
                            <Button startIcon={<EditIcon />} className={classes.withoutHover}>
                                <Typography className={classes.menuText}>
                                    <FormattedMessage id={'redesign.generic.label.edit'} />
                                </Typography>
                            </Button>
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                share(row.id);
                            }}
                        >
                            <Button startIcon={<ShareLessonIcon />} className={classes.withoutHover}>
                                <Typography className={classes.menuText}>
                                    <FormattedMessage id={'generic.label.shareLesson'} />
                                </Typography>
                            </Button>
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                showModal(row);
                            }}
                        >
                            <Button startIcon={<DeleteIcon />} className={classes.withoutHover}>
                                <Typography className={classes.deleteMenuText}>
                                    <FormattedMessage id={'redesign.generic.label.delete'} />
                                </Typography>
                            </Button>
                        </MenuItem>
                    </MenuList>
                </ClickAwayListener>
            </Popper>
        </>
    );
};

const mapStateToProps = (state) => {
    const { profile, mainAppState } = state;
    return {
        allowedGroups: profile.allowedGroups,
        profile,
        subscriptions: mainAppState.subscriptions,
    };
};

export default injectIntl(connect(mapStateToProps)(EllipsisMenu));
