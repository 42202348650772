export const sections = [
    {
        id: 'section-individualBoards',
        nameId: 'page.home.feature.individualBoards.tag',
        childrenVisible: true,
        children: [],
    },
    {
        id: 'section-paste',
        nameId: 'page.home.feature.paste.tag',
        childrenVisible: true,
        children: [],
    }, 
    {
        id: 'section-mobile',
        nameId: 'page.home.feature.mobile.tag',
        childrenVisible: true,
        children: [],
    },
    {
        id: 'section-control',
        nameId: 'page.home.feature.control.tag',
        childrenVisible: true,
        children: [],
    },
    {
        id: 'section-specialLineage',
        nameId: 'page.home.feature.specialLineage.tag',
        childrenVisible: true,
        children: [],
    },
    {
        id: 'section-groups',
        nameId: 'page.home.feature.groups.tag',
        childrenVisible: true,
        children: [],
    },
    {
        id: 'section-library',
        nameId: 'page.home.feature.library.tag',
        childrenVisible: true,
        children: [],
    },
    {
        id: 'section-pdfDownload',
        nameId: 'page.home.feature.pdfDownload.tag',
        childrenVisible: true,
        children: [],
    },
    {
        id: 'section-audioVideo',
        nameId: 'page.home.feature.audioVideo.tag',
        childrenVisible: true,
        children: [],
    },
    {
        id: 'section-geometry',
        nameId: 'redesign.page.home.feature.geometry.tag',
        childrenVisible: true,
        children: [],
    },
    {
        id: 'section-mathConvertor',
        nameId: 'redesign.page.home.feature.mathConvertor.tag',
        childrenVisible: true,
        children: [],
    },
];
