import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function WhiteboardLink(props) {
    return (
        <SvgIcon {...props}>
            <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'
                 zoomAndPan='magnify' viewBox='0 0 150 150' preserveAspectRatio='xMidYMid meet'
                 version='1.0'>
                <path fill='#f9a334'
                      d='M 24.667969 10.828125 L 37.308594 10.828125 L 37.308594 134.996094 L 24.667969 134.996094 Z M 24.667969 10.828125 '
                      fillOpacity='1' fillRule='nonzero' />
                <path fill='#d36823'
                      d='M 37.308594 134.996094 L 30.984375 134.996094 C 32.75 132.683594 33.445312 122.378906 33.640625 116.734375 L 24.667969 116.734375 L 24.667969 114.976562 L 33.6875 114.976562 C 33.691406 114.699219 33.695312 114.445312 33.699219 114.21875 L 37.308594 114.21875 Z M 37.308594 134.996094 '
                      fillOpacity='1' fillRule='nonzero' />
                <path fill='#d36823'
                      d='M 37.265625 12.457031 L 37.265625 33.929688 L 30.945312 33.929688 C 30.945312 16.09375 30.945312 12.976562 27.785156 12.457031 Z M 37.265625 12.457031 '
                      fillOpacity='1' fillRule='nonzero' />
                <path fill='#f9a334'
                      d='M 106.347656 10.828125 L 118.988281 10.828125 L 118.988281 134.996094 L 106.347656 134.996094 Z M 106.347656 10.828125 '
                      fillOpacity='1' fillRule='nonzero' />
                <path fill='#d36823'
                      d='M 118.945312 10.828125 L 118.945312 32.300781 L 112.625 32.300781 C 112.625 14.464844 112.625 11.347656 109.464844 10.828125 Z M 118.945312 10.828125 '
                      fillOpacity='1' fillRule='nonzero' />
                <path fill='#d36823'
                      d='M 118.988281 134.996094 L 112.667969 134.996094 C 114.46875 132.632812 115.15625 122.292969 115.332031 116.734375 L 106.347656 116.734375 L 106.347656 115.328125 L 115.359375 115.328125 C 115.371094 114.910156 115.375 114.535156 115.378906 114.21875 L 118.988281 114.21875 Z M 118.988281 134.996094 '
                      fillOpacity='1' fillRule='nonzero' />
                <g>
                    <path fill='#f9a334'
                          d='M 0.0742188 9.273438 L 149.925781 9.273438 L 149.925781 115.601562 L 0.0742188 115.601562 Z M 0.0742188 9.273438 '
                          fillOpacity='1' fillRule='nonzero' />
                </g>
                <g>
                    <path fill='#d36823'
                          d='M 5.90625 14.445312 L 5.152344 15.183594 L 0.0742188 10.023438 L 0.0742188 9.273438 L 0.816406 9.273438 Z M 5.90625 14.445312 '
                          fillOpacity='1' fillRule='nonzero' />
                </g>
                <g>
                    <path fill='#d36823'
                          d='M 144.84375 15.183594 L 144.09375 14.445312 L 149.183594 9.273438 L 149.925781 9.273438 L 149.925781 10.023438 Z M 144.84375 15.183594 '
                          fillOpacity='1' fillRule='nonzero' />
                </g>
                <path fill='#d36823'
                      d='M 5.152344 109.6875 L 5.90625 110.429688 L 0.816406 115.601562 L 0.0742188 115.601562 L 0.0742188 114.847656 Z M 5.152344 109.6875 '
                      fillOpacity='1' fillRule='nonzero' />
                <path fill='#d36823'
                      d='M 144.09375 110.429688 L 144.84375 109.6875 L 149.925781 114.847656 L 149.925781 115.601562 L 149.183594 115.597656 Z M 144.09375 110.429688 '
                      fillOpacity='1' fillRule='nonzero' />
                <path fill='#ffffff'
                      d='M 5.269531 14.121094 L 141.902344 14.121094 L 141.902344 110.75 L 5.269531 110.75 Z M 5.269531 14.121094 '
                      fillOpacity='1' fillRule='nonzero' />
                <path fill='#ffce31'
                      d='M 5.269531 14.121094 L 145.074219 14.121094 L 145.074219 110.75 L 5.269531 110.75 Z M 5.269531 14.121094 '
                      fillOpacity='1' fillRule='nonzero' />
                <path fill='#ffffff'
                      d='M 7.523438 16.371094 L 144.410156 16.371094 L 144.410156 108.5 L 7.523438 108.5 Z M 7.523438 16.371094 '
                      fillOpacity='1' fillRule='nonzero' />
                <path fill='#d36823'
                      d='M 5.269531 14.121094 L 6.65625 14.121094 L 6.65625 110.75 L 5.269531 110.75 Z M 5.269531 14.121094 '
                      fillOpacity='1' fillRule='nonzero' />
                <path fill='#d36823'
                      d='M 143.691406 14.121094 L 145.074219 14.121094 L 145.074219 110.75 L 143.691406 110.75 Z M 143.691406 14.121094 '
                      fillOpacity='1' fillRule='nonzero' />
                <path fill='#d36823'
                      d='M 5.964844 14.121094 L 144.558594 14.121094 L 144.558594 15.507812 L 5.964844 15.507812 Z M 5.964844 14.121094 '
                      fillOpacity='1' fillRule='nonzero' />
                <path fill='#d36823'
                      d='M 5.097656 109.367188 L 143.691406 109.367188 L 143.691406 110.75 L 5.097656 110.75 Z M 5.097656 109.367188 '
                      fillOpacity='1' fillRule='nonzero' />
                <path fill='#d36823'
                      d='M 146.386719 45.542969 L 146.386719 14.933594 C 146.863281 18.183594 146.785156 44.671875 146.863281 56.011719 C 146.925781 65.082031 146.574219 81.253906 146.386719 88.207031 Z M 146.386719 45.542969 '
                      fillOpacity='1' fillRule='nonzero' />
                <path fill='#d36823'
                      d='M 148.054688 78.058594 L 148.054688 112 C 148.6875 93.839844 148.769531 89.316406 148.054688 78.058594 Z M 148.054688 78.058594 '
                      fillOpacity='1' fillRule='nonzero' />
                <path fill='#d36823'
                      d='M 147.578125 18.105469 C 148.453125 29.128906 148.054688 45.921875 148.054688 49.347656 C 148.769531 29.128906 148.53125 24.289062 147.578125 18.105469 Z M 147.578125 18.105469 '
                      fillOpacity='1' fillRule='nonzero' />
                <path fill='#d36823'
                      d='M 1.738281 81.386719 L 1.738281 112 C 2.214844 108.746094 2.136719 82.261719 2.214844 70.921875 C 2.277344 61.847656 1.925781 45.675781 1.738281 38.722656 Z M 1.738281 81.386719 '
                      fillOpacity='1' fillRule='nonzero' />
                <path fill='#d36823'
                      d='M 3.40625 48.875 L 3.40625 14.933594 C 4.039062 33.09375 4.117188 37.613281 3.40625 48.875 Z M 3.40625 48.875 '
                      fillOpacity='1' fillRule='nonzero' />
                <path fill='#d36823'
                      d='M 2.929688 108.828125 C 3.804688 97.804688 3.40625 81.007812 3.40625 77.582031 C 4.117188 97.804688 3.882812 102.640625 2.929688 108.828125 Z M 2.929688 108.828125 '
                      fillOpacity='1' fillRule='nonzero' />
                <path fill='#d36823'
                      d='M 92.542969 112.554688 C 95.660156 112.554688 118.421875 112.816406 129.417969 113.109375 C 125.847656 113.585938 107.453125 112.714844 92.9375 112.949219 C 85.011719 113.078125 77.226562 112.945312 71.746094 112.78125 C 69.617188 112.75 67.601562 112.679688 65.816406 112.554688 C 67.152344 112.621094 69.199219 112.703125 71.746094 112.78125 C 78.902344 112.882812 87.347656 112.554688 92.542969 112.554688 Z M 92.542969 112.554688 '
                      fillOpacity='1' fillRule='nonzero' />
                <path fill='#d36823'
                      d='M 7.054688 112.554688 C 18.5 112.632812 18.949219 112.554688 38.21875 112.554688 C 48.71875 112.554688 61.542969 113.675781 66.160156 113.871094 C 66.539062 113.882812 66.902344 113.894531 67.246094 113.902344 C 66.964844 113.902344 66.597656 113.890625 66.160156 113.871094 C 58.785156 113.675781 44.433594 113.308594 38.21875 113.1875 C 30.050781 113.03125 9.273438 113.1875 7.054688 112.554688 Z M 7.054688 112.554688 '
                      fillOpacity='1' fillRule='nonzero' />
                <path fill='#d36823'
                      d='M 100.472656 12.316406 C 103.589844 12.316406 126.351562 12.578125 137.347656 12.871094 C 133.78125 13.347656 115.382812 12.476562 100.867188 12.710938 C 92.941406 12.839844 85.15625 12.707031 79.675781 12.542969 C 77.546875 12.511719 75.53125 12.441406 73.746094 12.316406 C 75.082031 12.382812 77.128906 12.464844 79.675781 12.542969 C 86.835938 12.644531 95.277344 12.316406 100.472656 12.316406 Z M 100.472656 12.316406 '
                      fillOpacity='1' fillRule='nonzero' />
                <path fill='#d36823'
                      d='M 6.816406 11.046875 C 18.261719 11.125 18.949219 11.046875 38.21875 11.046875 C 48.691406 11.046875 61.476562 12.164062 66.121094 12.363281 C 66.515625 12.375 66.890625 12.386719 67.246094 12.394531 C 66.957031 12.394531 66.578125 12.382812 66.121094 12.363281 C 58.738281 12.160156 44.421875 11.722656 38.21875 11.601562 C 30.050781 11.441406 9.035156 11.679688 6.816406 11.046875 Z M 6.816406 11.046875 '
                      fillOpacity='1' fillRule='nonzero' />
                <path fill='#d36823'
                      d='M 86.277344 11.046875 C 78.792969 11.046875 63.226562 10.675781 56.378906 10.492188 C 61.21875 9.9375 82.3125 11.023438 85.960938 10.808594 C 88.878906 10.636719 105.574219 10.894531 113.558594 11.046875 Z M 86.277344 11.046875 '
                      fillOpacity='1' fillRule='nonzero' />
                <g>
                    <path fill='#00acb5'
                          d='M 142.789062 79.769531 L 64.1875 79.769531 L 64.1875 64.5 L 142.789062 64.5 Z M 142.789062 79.769531 '
                          fillOpacity='1' fillRule='nonzero' />
                </g>
                <g>
                    <path fill='#00acb5'
                          d='M 64.605469 52.316406 L 64.457031 91.953125 L 30.183594 72.007812 Z M 64.605469 52.316406 '
                          fillOpacity='1' fillRule='evenodd' />
                </g>
            </svg>
        </SvgIcon>
    );
}
