import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(() => ({
    sectionImage: { textAlign: 'center', padding: '2rem' },
    image: {
        maxWidth: '300px',
        width: '80%',
        borderRadius: '16px',
    },

    imageVSP: {
        maxWidth: '400px',
        width: '80%',
    },

    title: {
        fontWeight: '700',
        textAlign: 'left',
        paddingTop: '2rem',
        paddingBottom: '1rem',
    },
}));

const AboutTeam = () => {
    const classes = useStyles();

    return (
        <Grid container spacing={3} alignItems={'center'} alignContent={'center'} justify={'center'}>
            <Grid item xs={12}>
                <Typography variant='h2' className={classes.title}>
                    <FormattedMessage id={'aboutUs.team.button'} />
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Typography variant='h3'>
                    <FormattedMessage id={'aboutUs.team.founder'} />
                </Typography>
                <Typography variant='h4' style={{ fontWeight: '700' }}>
                    <FormattedMessage id={'aboutUs.team.founder.name'} />
                </Typography>
                <Typography variant='h6'>
                    <FormattedMessage id={'aboutUs.team.founder.description'} />
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.sectionImage}>
                <img src={'/static/asset/img/MariusMazilu.png'} className={classes.image} alt='Marius Mazilu' />
            </Grid>
            <Divider variant={'middle'} style={{ width: '100%', marginTop: '2rem', marginBottom: '2rem' }} />

            <Grid item xs={12} sm={6}>
                <Typography variant='h3'>
                    <FormattedMessage id={'aboutUs.team.colaborator1'} />
                </Typography>
                <Typography variant='h4' style={{ fontWeight: '700' }}>
                    <FormattedMessage id={'aboutUs.team.colaborator1.name'} />
                </Typography>
                <Typography variant='h6'>
                    <FormattedMessage id={'aboutUs.team.colaborator1.description'} />
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.sectionImage}>
                <img src={'/static/asset/img/MariusLobaza.png'} className={classes.image} alt='Marius Lobaza' />
            </Grid>
            <Divider variant={'middle'} style={{ width: '100%', marginTop: '2rem', marginBottom: '2rem' }} />

            <Grid item xs={12} sm={6}>
                <Typography variant='h3'>
                    <FormattedMessage id={'aboutUs.team.colaborator2'} />
                </Typography>
                <Typography variant='h4' style={{ fontWeight: '700' }}>
                    <FormattedMessage id={'aboutUs.team.colaborator2.name'} />
                </Typography>
                <Typography variant='h6'>
                    <FormattedMessage id={'aboutUs.team.colaborator2.description'} />
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.sectionImage}>
                <img src={'/static/asset/img/DanielaRezmive.png'} className={classes.image} alt='Daniela Rezmive' />
            </Grid>
            <Divider variant={'middle'} style={{ width: '100%', marginTop: '2rem', marginBottom: '2rem' }} />

            <Grid item xs={12} sm={6}>
                <Typography variant='h3'>
                    <FormattedMessage id={'aboutUs.team.colaborator3'} />
                </Typography>
                <Typography variant='h4' style={{ fontWeight: '700' }}>
                    <FormattedMessage id={'aboutUs.team.colaborator3.name'} />
                </Typography>
                <Typography variant='h6'>
                    <FormattedMessage id={'aboutUs.team.colaborator3.description'} />
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.sectionImage}>
                <img
                    src={'https://victorysquarepartners.com/wp-content/uploads/2022/06/VSP-Logo-SVG.svg'}
                    className={classes.imageVSP}
                    alt='VSP'
                />
            </Grid>
        </Grid>
    );
};

export default AboutTeam;
