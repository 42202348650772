import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Divider, MenuItem, MenuList, Modal, Slide, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import VerticalEllipsisIcon from '../icons/VerticalEllipsisIcon';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import EditIcon from '../icons/EditIcon';
import ShareLessonIcon from '../icons/ShareLessonIcon';
import DeleteIcon from '../icons/DeleteIcon';
import { GA_EVENT_TREE } from '../../gaConstants';
import store from '../../redux/store';
import { shareLesson, userJoinRequest } from '../../redux/actions';
import { useHistory } from 'react-router-dom';
import StartLessonIcon from '../icons/StartLessonIcon';
import BlueGroupIcon from '../icons/BlueGroupIcon';
import Avatar from '@material-ui/core/Avatar';
import { COLORS2 } from '../../constants';

const useStyles = makeStyles(() => ({
    modalStyle: {
        background: 'linear-gradient(180deg, rgba(2, 35, 37, 0) 0%, #022325 95.56%)',
    },
    boxModalStyle: {
        position: 'absolute',
        background: '#FFFFFF',
        boxShadow: '1.5rem -1.5rem 3rem rgba(0, 0, 0, 0.08)',
        borderRadius: '2rem 2rem 0rem 0rem',
        bottom: '0',
    },
    menuText: {
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '1rem',
        letterSpacing: '-0.01rem',
        textTransform: 'none',
        color: COLORS2.darkGreen,
    },
    withoutHover: {
        '&:hover': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
        },
    },
    deleteMenuText: {
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '1rem',
        letterSpacing: '-0.01rem',
        textTransform: 'none',
        color: COLORS2.virtualRed,
    },
}));

export const MobilePopUpMyGroups = ({ row, setModalItem, profile, localesOptions, isMobilePopUp }) => {
    let history = useHistory();
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const join = (classId) => {
        window.logAppActivity(GA_EVENT_TREE.joinGroups.actions.join);
        store.dispatch(userJoinRequest({ classId: classId, userId: null }));
    };

    let editClass = function (classId) {
        window.logAppActivity(GA_EVENT_TREE.manageGroups.actions.edit);
        history.push('/class/' + classId);
    };

    const share = (classId) => {
        window.logAppActivity(GA_EVENT_TREE.editGroup.actions.share);
        store.dispatch(shareLesson({ id: classId, name: classId.name }));
    };

    let lessonStart = function (classId) {
        window.logAppActivity(GA_EVENT_TREE.manageGroups.actions.start);
        history.push('/lesson/' + classId);
    };

    const showModal = (item) => {
        setModalItem(item);
    };

    return (
        <>
            <Button onClick={handleOpen}>
                <VerticalEllipsisIcon
                    style={{
                        filter:
                            'invert(46%) sepia(95%) saturate(601%) hue-rotate(140deg) brightness(93%) contrast(94%)',
                    }}
                />
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                className={classes.modalStyle}
                BackdropProps={{ style: { backgroundColor: 'transparent' } }}
            >
                <Slide direction="up" in={open} timeout={{ enter: 500, exit: 400 }} easing={'ease-out'}>
                    <Box
                        className={classes.boxModalStyle}
                        style={{ width: isMobilePopUp ? '100%' : '75%', margin: isMobilePopUp ? '0rem' : '0rem 6rem' }}
                    >
                        <div style={{ display: 'flex', padding: '1.5rem', alignItems: 'center' }}>
                            <span
                                style={{
                                    boxSizing: 'border-box',
                                    width: '3rem',
                                    height: '3rem',
                                    background: 'rgba(8, 170, 180, 0.08)',
                                    borderRadius: '1rem',
                                    textAlign: 'center',
                                }}
                            >
                                <BlueGroupIcon style={{ top: '2.25rem', left: '2.275rem', position: 'absolute' }} />
                            </span>
                            <div style={{ paddingLeft: '1rem' }}>
                                <Typography
                                    style={{
                                        fontStyle: 'normal',
                                        fontWeight: '700',
                                        fontSize: '1.125rem',
                                        letterSpacing: '-0.02rem',
                                        textTransform: 'none',
                                        color: COLORS2.darkGreen,
                                    }}
                                >
                                    {row.name}
                                </Typography>
                            </div>
                        </div>
                        <div style={{ background: 'rgba(210, 104, 40, 0.04)', width: '100%' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    padding: '2rem 2rem 0rem 5rem',
                                }}
                            >
                                <div>
                                    <Typography
                                        style={{
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            fontSize: '0.625rem',
                                            lineHeight: '1rem',
                                            letterSpacing: '0.08rem',
                                            textTransform: 'uppercase',
                                            color: COLORS2.virtualText,
                                        }}
                                    >
                                        <FormattedMessage id="redesign.generic.label.initiatedBy" />
                                    </Typography>
                                </div>
                                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                                    <Avatar url={profile.avatar} style={{ width: '1rem', height: '1rem' }} />
                                    <span
                                        style={{
                                            paddingLeft: '0.375rem',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            fontSize: '0.625rem',
                                            lineHeight: '1rem',
                                            letterSpacing: '-0.01rem',
                                            textTransform: 'none',
                                            textAlign: 'right',
                                            color: '#5F646B',
                                        }}
                                    >
                                        {profile.name}
                                    </span>
                                </div>
                            </div>
                            <Divider
                                style={{
                                    backgroundColor: 'rgba(210, 104, 40, 0.1)',
                                    height: '0.063rem',
                                    margin: '1rem 0rem 1rem 5rem',
                                }}
                            />
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    padding: '0rem 2rem 0rem 5rem',
                                }}
                            >
                                <div>
                                    <Typography
                                        style={{
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            fontSize: '0.625rem',
                                            lineHeight: '1rem',
                                            letterSpacing: '0.08rem',
                                            textTransform: 'uppercase',
                                            color: COLORS2.virtualText,
                                        }}
                                    >
                                        <FormattedMessage id="redesign.generic.label.createdAtMobile" />
                                    </Typography>
                                </div>
                                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                                    <span
                                        style={{
                                            paddingLeft: '0.375rem',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            fontSize: '0.625rem',
                                            lineHeight: '1rem',
                                            letterSpacing: '-0.01rem',
                                            textTransform: 'none',
                                            textAlign: 'right',
                                            color: '#5F646B',
                                        }}
                                    >
                                        {new Date(row.createdAt).toLocaleDateString(localesOptions, {
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric',
                                        })}
                                    </span>
                                </div>
                            </div>
                            <Divider
                                style={{
                                    backgroundColor: 'rgba(210, 104, 40, 0.1)',
                                    height: '0.063rem',
                                    margin: '1rem 0rem 1rem 5rem',
                                }}
                            />
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    padding: '0rem 2rem 2rem 5rem',
                                }}
                            >
                                <div>
                                    <Typography
                                        style={{
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            fontSize: '0.625rem',
                                            lineHeight: '1rem',
                                            letterSpacing: '0.08rem',
                                            textTransform: 'uppercase',
                                            color: COLORS2.virtualText,
                                        }}
                                    >
                                        <FormattedMessage id="redesign.generic.label.lastAccessedMobile" />
                                    </Typography>
                                </div>
                                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                                    <span
                                        style={{
                                            paddingLeft: '0.375rem',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            fontSize: '0.625rem',
                                            lineHeight: '1rem',
                                            letterSpacing: '-0.01rem',
                                            textTransform: 'none',
                                            textAlign: 'right',
                                            color: '#5F646B',
                                        }}
                                    >
                                        {new Date(row.lastaccess).toLocaleDateString(localesOptions, {
                                            month: 'long',
                                            day: 'numeric',
                                            hour: 'numeric',
                                            minute: 'numeric',
                                        })}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <MenuList autoFocusItem={open} style={{ padding: '1.5rem' }}>
                            {row.requests && row.requests > 0 ? (
                                <MenuItem
                                    onClick={() => {
                                        join(row.id);
                                    }}
                                >
                                    <Button
                                        startIcon={
                                            <div
                                                style={{
                                                    background: COLORS2.virtualRed,
                                                    borderRadius: '1rem',
                                                    width: '1.25rem',
                                                    height: '1.25rem',
                                                    boxShadow: '0 0 0rem 0.5rem rgba(243, 83, 37, 0.1)',
                                                }}
                                            >
                                                <Typography
                                                    style={{
                                                        marginTop: '0.1rem',
                                                        marginLeft: '0.2rem',
                                                        fontStyle: 'normal',
                                                        fontWeight: '700',
                                                        fontSize: '0.8rem',
                                                        lineHeight: '1rem',
                                                        textAlign: 'center',
                                                        letterSpacing: '0.24rem',
                                                        textTransform: 'uppercase',
                                                        color: '#FFFFFF',
                                                    }}
                                                >
                                                    {row.requests}
                                                </Typography>
                                            </div>
                                        }
                                        className={classes.withoutHover}
                                    >
                                        <Typography className={classes.menuText}>
                                            <FormattedMessage id={'redesign.generic.label.newRequests'} />
                                        </Typography>
                                    </Button>
                                </MenuItem>
                            ) : null}
                            <MenuItem
                                onClick={() => {
                                    lessonStart(row.id);
                                }}
                            >
                                <Button startIcon={<StartLessonIcon />} className={classes.withoutHover}>
                                    <Typography className={classes.menuText}>
                                        <FormattedMessage id={'redesign.generic.label.start'} />
                                    </Typography>
                                </Button>
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    editClass(row.id);
                                }}
                            >
                                <Button startIcon={<EditIcon />} className={classes.withoutHover}>
                                    <Typography className={classes.menuText}>
                                        <FormattedMessage id={'redesign.generic.label.edit'} />
                                    </Typography>
                                </Button>
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    share(row.id);
                                }}
                            >
                                <Button startIcon={<ShareLessonIcon />} className={classes.withoutHover}>
                                    <Typography className={classes.menuText}>
                                        <FormattedMessage id={'generic.label.shareLesson'} />
                                    </Typography>
                                </Button>
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    showModal(row);
                                }}
                            >
                                <Button startIcon={<DeleteIcon />} className={classes.withoutHover}>
                                    <Typography className={classes.deleteMenuText}>
                                        <FormattedMessage id={'redesign.generic.label.delete'} />
                                    </Typography>
                                </Button>
                            </MenuItem>
                        </MenuList>
                    </Box>
                </Slide>
            </Modal>
        </>
    );
};
