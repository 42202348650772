import { makeStyles } from "@material-ui/core";
import { COLORS2 } from '../../../constants';

const styles = makeStyles({
    // nodeStyle : {
    //     cursor: 'pointer',
    //     paddingTop: '1rem',
    //     alignItems: 'center',
    // },
    // selectedNodeStyle : {
    //     fontWeight: '700',
    // },
    
    // selectedCursorStyle : {
    //     height: '2rem',
    //     position: 'absolute',
    //     width: '4px',
    //     backgroundColor: COLORS.fontDefault,
    //     borderRadius: '32px 0px 0px 32px',
    //     left: '0px',
    //     transform: 'matrix(-1, 0, 0, 1, 0, 0)',
    // },
    simpleMenuSelection : {
        paddingTop: '1rem',
        paddingBottom: '1rem',
        //paddingLeft: '2rem',
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 40px 160px rgba(210, 104, 40, 0.08)',
        width: '100%',
        boxSizing: 'border-box',
        borderBottom: '1px solid #D2682825',
        borderRadius: '0px',
    },
    simpleTutorialMenuExpander : {
        cursor: 'pointer',
    },
    containerSimpleMenuItems : {
        display: 'flex',
        justifyContent : 'space-between',
        marginLeft: '-2.5rem',
        columnGap: '0.5rem',
        alignItems: 'center',
        height: '1.5rem',
    },
    menuItemSelected: {
        width: '15rem',
        color: COLORS2.darkGreen,
        fontWeight: '400',
        fontSize: '1.5rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    summary : {
        color: COLORS2.virtualGreen,
        fontWeight : '700',
        fontSize : '0.6rem',
        lineHeight: '2rem',
        textAlign: 'center',
        letterSpacing: '0.2rem', 
    },
    mobileMenuIconContainer :{
        width: '1.5rem',
    },
    mobileMenuIcon: {
        width: '100%' 
    },
})

export default styles; 