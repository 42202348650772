import React from 'react';
import { Divider, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import List from '@material-ui/core/List';
import { getIntlCount, getSupportIcon, getSupportPrimaryText } from '../../../common/features';

const useStyles = makeStyles(() => ({
    listItem: {
        display: 'flex',
        flexDirection: 'column',
        height: '5rem',
        marginBottom: '1.5rem',
        padding: '0rem',
    },
    customDivider: {
        border: '0.063rem',
        height: '0.063em',
        width: '100%',
    },
    listItemIcon: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '2.5rem',
    },
}));

const SubscriptionListItem = ({ intl, el, formatObj }) => {

    const classes = useStyles();

    return (
        <ListItem className={classes.listItem}>
            <Divider className={classes.customDivider} />
            <ListItemIcon className={classes.listItemIcon}>{getSupportIcon(el.defaultValue)}</ListItemIcon>
            <ListItemText primary={getSupportPrimaryText(el.defaultValue, el.name, formatObj, intl)} />
        </ListItem>
    );
};

const SubscriptionListRendererDesktop = ({ intl, featureList, highLightItem }) => {
    return (
        <>
            <List dense>
                {featureList.map((el) => {
                    let formatObj = {};
                    formatObj[el.name] = getIntlCount(intl, el.defaultValue);
                    if (highLightItem) {
                        if (el.name === highLightItem) {
                            return (
                                <SubscriptionListItem
                                    el={el}
                                    formatObj={formatObj}
                                    intl={intl}
                                    key={'feature-' + el.name}
                                />
                            );
                        } else {
                            return null;
                        }
                    }
                    return (
                        <SubscriptionListItem el={el} formatObj={formatObj} intl={intl} key={'feature-' + el.name} />
                    );
                })}
            </List>
        </>
    );
};

export default injectIntl(SubscriptionListRendererDesktop);
