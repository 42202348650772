import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS2 } from '../../../constants';

const useStyles = makeStyles(() => ({
    findOutMoreText: {
        textTransform: 'uppercase',
        color: COLORS2.virtualAmber,
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '0.875rem',
        lineHeight: '1.063rem',
        letterSpacing: '0.16rem',
        borderBottom: '0.125rem solid #D26828',
    },
}));

export const FindMoreButton = (props) => {
    const classes = useStyles();

    return (
        <Button variant={'text'}>
            <Typography className={classes.findOutMoreText}>
                <FormattedMessage id={props.textId ? props.textId : 'subscriptions.subscription.findOutMore'} />
            </Typography>
        </Button>
    );
};
