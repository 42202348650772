import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    InputBase,
    makeStyles,
    Typography,
} from '@material-ui/core';
import GenericState from '../../GenericLoadingErrorState/GenericState';
import CollectionManager from '../../../pages/ManageCollections/CollectionManager';
import { LIBRARY_MANAGER_STATE } from '../../../constants';
import { Close } from '@material-ui/icons';

const getChildIds = (node) => {
    let localList = [];
    let foundUnusable = false;
    if (node.children) {
        node.children.forEach((child) => {
            if (!child.isCollection) {
                if (child.canUse && !child.canUse.value) {
                    foundUnusable = true;
                }
                localList.push(child.id);
            } else {
                localList.push(...getChildIds(child));
            }
        });
    }
    if (foundUnusable) {
        return [];
    }
    return localList;
};

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    dialogContent: {
        padding: '0.5rem 0',
    },
    labelRootSelectedForEditing: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#FFFFFF',
        borderRadius: '1rem',
        border: '0.063rem solid #cc5c15',
        width: '100%',
        margin: '0rem 0.5rem',
    },
}));

const BoardCollectionDialog = ({ intl, close, state }) => {
    const classes = useStyles();
    const [name, setName] = React.useState('');
    const [collectionId, setCollectionId] = React.useState('');
    const [selectedBoard, setSelectedBoard] = React.useState(null);
    const [selectedBoardChildCount, setSelectedBoardChildCount] = React.useState(null);
    const [childrenToLoad, setChildrenToLoad] = React.useState([]);
    const [selectedUserBoard, setSelectedUserBoard] = React.useState(null);
    const [selectedUserBoardChildCount, setSelectedUserBoardChildCount] = React.useState(null);
    const [userChildrenToLoad, setUserChildrenToLoad] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleSelection = (value) => {
        if (value.isUserCollection || value.isCollection) {
            setSelectedUserBoard(null);
            setSelectedBoard(null);
            setCollectionId(value.id);
            let childIds = getChildIds(value);
            if (value.isUserCollection) {
                setSelectedUserBoardChildCount(childIds.length);
                setUserChildrenToLoad(childIds);
                setSelectedBoardChildCount(null);
                setChildrenToLoad([]);
            } else {
                setSelectedUserBoardChildCount(null);
                setUserChildrenToLoad([]);
                setSelectedBoardChildCount(childIds.length);
                setChildrenToLoad(childIds);
            }
        } else {
            setCollectionId(null);
            if (value.isUserBoard) {
                setSelectedUserBoard(value.id);
                setSelectedBoard(null);
            } else {
                setSelectedUserBoard(null);
                if (value.canUse && value.canUse.value) {
                    setSelectedBoard(value.id);
                } else {
                    setSelectedBoard(null);
                }
            }
        }
    };

    let content = (
        <Grid container spacing={2} className={classes.dialogContent}>
            <Grid item xs={12} sm={6} className={classes.labelRootSelectedForEditing}>
                <InputBase
                    id="outlined-basic"
                    placeholder={intl.formatMessage({ id: 'collections.createCollection.name' })}
                    value={name}
                    onChange={handleNameChange}
                    required
                    fullWidth
                />
            </Grid>
        </Grid>
    );

    if (isLoading) {
        content = <GenericState isLoading={true} />;
    }
    if (isError) {
        content = <GenericState isError={true} />;
    }

    if (!isError && !isLoading) {
        content = (
            <Grid container spacing={2} className={classes.dialogContent}>
                {state === LIBRARY_MANAGER_STATE.SAVE || state === LIBRARY_MANAGER_STATE.SAVE_SET ? (
                    <>
                        <Grid item xs={12} className={classes.labelRootSelectedForEditing}>
                            <InputBase
                                id="outlined-basic"
                                placeholder={intl.formatMessage({ id: 'collections.createCollection.name' })}
                                value={name}
                                onChange={handleNameChange}
                                required
                                fullWidth
                                variant="outlined"
                                size="small"
                                disabled={!!selectedBoard}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h4">
                                <FormattedMessage id="collections.selectFolder" />
                            </Typography>
                        </Grid>
                    </>
                ) : null}
                <CollectionManager onSelect={handleSelection} state={state} />
            </Grid>
        );
    }

    const closeDialog = () => {
        close();
    };

    const performAction = (selectedBoard) => {
        if (!selectedBoard) {
            if (state === LIBRARY_MANAGER_STATE.SAVE_SET) {
                if (window.addAllToCollection) {
                    setIsLoading(true);
                    window
                        .addAllToCollection({ name, collectionId, description: '' })
                        .then(() => {
                            close();
                        })
                        .catch((err) => {
                            console.error(err);
                            setIsError(true);
                        });
                }
            } else if (window.addToCollection) {
                setIsLoading(true);
                window
                    .addToCollection({ name, collectionId, description: '' })
                    .then(() => {
                        close();
                    })
                    .catch((err) => {
                        console.error(err);
                        setIsError(true);
                    });
            }
        } else {
            if (window.updateToCollection) {
                setIsLoading(true);
                window
                    .updateToCollection(selectedBoard)
                    .then(() => {
                        close();
                    })
                    .catch((err) => {
                        console.error(err);
                        setIsError(true);
                    });
            }
        }
    };

    const loadAction = () => {
        if (window.loadCollectionInLesson) {
            if (selectedBoard || selectedUserBoard) {
                window.loadCollectionInLesson([selectedBoard], [selectedUserBoard]);
            } else {
                if (childrenToLoad.length > 0 || userChildrenToLoad.length > 0) {
                    window.loadCollectionInLesson(childrenToLoad, userChildrenToLoad);
                }
            }
        }
        close();
    };

    return (
        <Dialog
            open={true}
            onClose={closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="md"
        >
            <DialogTitle id="alert-dialog-title" disableTypography>
                <Typography variant="subtitle2">
                    {state === LIBRARY_MANAGER_STATE.LOAD ? (
                        <FormattedMessage id="collections.loadCollection.title" />
                    ) : (
                        <FormattedMessage id="collections.addToCollection.title" />
                    )}
                </Typography>
                <IconButton aria-label="close" className={classes.closeButton} onClick={closeDialog}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>{content}</DialogContent>
            <DialogActions style={{ marginBottom: '0.5rem', marginRight: '1rem' }}>
                <Button onClick={closeDialog} size="large" variant="contained" color="default">
                    <FormattedMessage id="close" />
                </Button>
                {state === LIBRARY_MANAGER_STATE.LOAD ? (
                    <Button
                        onClick={() => {
                            loadAction();
                        }}
                        color="default"
                        size="large"
                        autoFocus
                        variant="contained"
                        disabled={
                            !(
                                selectedBoard ||
                                (collectionId && selectedBoardChildCount > 0 && selectedBoardChildCount < 25) ||
                                selectedUserBoard ||
                                (collectionId && selectedUserBoardChildCount > 0 && selectedUserBoardChildCount < 25)
                            )
                        }
                    >
                        {collectionId && selectedBoardChildCount > 0 ? (
                            <>
                                <FormattedMessage id={'collections.loadCollection.buttonMultiple'} /> :
                                {selectedBoardChildCount}
                            </>
                        ) : collectionId && selectedUserBoardChildCount > 0 ? (
                            <>
                                <FormattedMessage id={'collections.loadCollection.buttonMultiple'} /> :
                                {selectedUserBoardChildCount}
                            </>
                        ) : (
                            <FormattedMessage id={'collections.loadCollection.buttonSingle'} />
                        )}
                    </Button>
                ) : null}
                {state === LIBRARY_MANAGER_STATE.SAVE && selectedBoard ? (
                    <Button
                        onClick={() => {
                            performAction(selectedBoard);
                        }}
                        color="default"
                        autoFocus
                        variant="contained"
                    >
                        <FormattedMessage id={'collections.updateBoardButton'} />
                    </Button>
                ) : null}
                {state === LIBRARY_MANAGER_STATE.SAVE && !selectedBoard ? (
                    <Button
                        onClick={() => {
                            performAction(selectedBoard);
                        }}
                        color="default"
                        size="large"
                        autoFocus
                        variant="contained"
                        disabled={name.trim() === ''}
                    >
                        <FormattedMessage id="collections.addToCollection.title" />
                    </Button>
                ) : null}
                {state === LIBRARY_MANAGER_STATE.SAVE_SET ? (
                    <Button
                        onClick={() => {
                            performAction(selectedBoard);
                            // console.log('=== should save all');
                        }}
                        color="default"
                        size="large"
                        autoFocus
                        variant="contained"
                        disabled={name.trim() === ''}
                    >
                        <FormattedMessage id="collections.addToCollection.title" />
                    </Button>
                ) : null}
            </DialogActions>
        </Dialog>
    );
};

export default injectIntl(BoardCollectionDialog);
