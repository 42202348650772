import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Container, Typography } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';

import CollectionManager from './CollectionManager';
import { FindMoreAboutCollections } from './FindMoreAboutCollections';
import Hidden from '@material-ui/core/Hidden';
import ModalProvider from './ModalContext/ModalProvider';

const ManageCollections = () => {

    return (
        <ModalProvider>
            <CssBaseline />
            <Container>
                <div className="u-mb-full" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                    <Hidden only={['xs']}>
                        <div style={{ display:'flex', alignItems: 'center', justifyContent: 'flex-start' }} >
                            <Typography variant="h4" align={'center'} style={{fontSize: '1.625rem', marginRight: '1.5rem'}}>
                                <FormattedMessage id="page.home.feature.library.tag" />
                            </Typography>
                            <FindMoreAboutCollections isMobile={false}/>
                        </div>
                    </Hidden> 
                    <Hidden only={['sm','md','lg','xl']}>
                        <div style={{ display:'flex', alignItems: 'center', justifyContent: 'space-between' }} >
                            <Typography variant="h4" align={'center'} style={{fontSize: '1.625rem', marginRight: '1.5rem'}}>
                                <FormattedMessage id="page.home.feature.library.tag" />
                            </Typography>
                            <FindMoreAboutCollections isMobile={true}/>
                        </div>
                    </Hidden>
                    <div style={{ paddingTop: '3rem' }} />
                    <CollectionManager />
                </div>
            </Container>
        </ModalProvider>
    );
};

export default injectIntl(ManageCollections);
