import React from 'react';

import { Grid, Typography } from '@material-ui/core';

export default function CardType1({ icon, title, content }) {
    return (
        <Grid container spacing={2}>
            <Grid item>{icon}</Grid>
            <Grid item>
                <Grid container spacing={2}>
                    <Grid item>
                        <Typography variant="subtitle2">{title}</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item>
                        <Typography variant="subtitle1" color="textSecondary">
                            {content}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
