import React from 'react';
import { TOOL_PEN, TOOL_CIRCLE, TOOL_RECTANGLE, TOOL_LINE, BOARD_TYPE, TOOL_MATH_CONVERTER } from '../../../constants';
import Fab from '@material-ui/core/Fab';

import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import store from '../../../redux/store';
import { changeTool } from '../../../redux/actions';
import EllipsisIcon from '../../icons/EllipsisIcon';
import LineIcon from '../../icons/LineIcon';
import RectangleIcon from '../../icons/RectangleIcon';
import VBToolTip from '../../Tooltip/VBToolTip';
import { GA_EVENT_TREE } from '../../../gaConstants';
import CreateIcon from '@material-ui/icons/Create';

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
}));

const ToolBox = (props) => {
    const classes = useStyles();

    let selectPen = () => {
        window.logAppActivity(GA_EVENT_TREE.lessonPage.toolSelection.pen);
        store.dispatch(changeTool(TOOL_PEN));
        handleClose(TOOL_PEN);
    };
    let selectLine = () => {
        window.logAppActivity(GA_EVENT_TREE.lessonPage.toolSelection.line);
        store.dispatch(changeTool(TOOL_LINE));
        // console.log('=== changing to line from menu');

        handleClose(TOOL_LINE);
    };
    let selectRectangle = () => {
        window.logAppActivity(GA_EVENT_TREE.lessonPage.toolSelection.rectangle);
        store.dispatch(changeTool(TOOL_RECTANGLE));
        handleClose(TOOL_RECTANGLE);
    };
    let selectCircle = () => {
        window.logAppActivity(GA_EVENT_TREE.lessonPage.toolSelection.ellipse);
        store.dispatch(changeTool(TOOL_CIRCLE));
        handleClose(TOOL_CIRCLE);
    };

    const handleClose = (tool) => {
        // setAnchorTool(null);
        if (window.toolSelectionCallback) window.toolSelectionCallback(tool);
    };

    let allowDrawing = props.lessonState.allowDrawing || props.lessonState.boardType === BOARD_TYPE.MULTI_BOARD;

    let toolMenu = (
        <div>
            <div>
                <Fab
                    size="small"
                    color={props.drawing.tool === TOOL_PEN ? 'primary' : 'default'}
                    className={classes.margin}
                    onClick={selectPen}
                    style={{ display: 'inline-block' }}
                    disabled={!allowDrawing}
                >
                    <VBToolTip content={<FormattedMessage id="tooltip.lessonPage.pen" />}>
                        <CreateIcon style={{ verticalAlign: 'middle' }} />
                    </VBToolTip>
                </Fab>
                <Fab
                    size="small"
                    color={props.drawing.tool === TOOL_LINE ? 'primary' : 'default'}
                    className={classes.margin}
                    onClick={selectLine}
                    style={{ display: 'inline-block' }}
                    disabled={!allowDrawing}
                >
                    <VBToolTip content={<FormattedMessage id="tooltip.lessonPage.line" />}>
                        <LineIcon style={{ verticalAlign: 'middle' }} />
                    </VBToolTip>
                </Fab>
            </div>
            <div>
                <Fab
                    size="small"
                    color={props.drawing.tool === TOOL_RECTANGLE ? 'primary' : 'default'}
                    className={classes.margin}
                    onClick={selectRectangle}
                    style={{ display: 'inline-block' }}
                    disabled={!allowDrawing}
                >
                    <VBToolTip content={<FormattedMessage id="tooltip.lessonPage.rectangle" />}>
                        <RectangleIcon style={{ verticalAlign: 'middle' }} />
                    </VBToolTip>
                </Fab>
                <Fab
                    size="small"
                    color={props.drawing.tool === TOOL_CIRCLE ? 'primary' : 'default'}
                    className={classes.margin}
                    onClick={selectCircle}
                    style={{ display: 'inline-block' }}
                    disabled={!allowDrawing}
                >
                    <VBToolTip content={<FormattedMessage id="tooltip.lessonPage.ellipsis" />}>
                        <EllipsisIcon />
                    </VBToolTip>
                </Fab>
            </div>
        </div>
    );

    return <>{toolMenu}</>;
};

const mapStateToProps = (state) => {
    const { drawing, lessonState } = state;
    return { drawing, lessonState };
};

export default injectIntl(connect(mapStateToProps)(ToolBox));
