import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function PinIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                zoomAndPan="magnify"
                viewBox="0 0 150 150"
                preserveAspectRatio="xMidYMid meet"
            >
                <g>
                    <g>
                        <g>
                            <g>
                                <path
                                    d="M 102.246094 13.433594 C 111.308594 26.136719 109.761719 43.542969 98.585938 54.347656 C 96.894531 55.984375 95.03125 57.441406 92.964844 58.574219 C 91.839844 59.195312 91.417969 60.03125 91.203125 61.261719 C 86.191406 89.871094 81.148438 118.476562 76.070312 147.078125 C 75.910156 147.96875 76.242188 149.539062 75.058594 149.535156 C 74.226562 149.53125 74.433594 147.957031 74.316406 147.070312 C 70.542969 118.808594 66.78125 90.542969 63.070312 62.269531 C 62.851562 60.605469 62.300781 59.585938 60.765625 58.683594 C 42.851562 48.15625 39.863281 23.363281 54.707031 8.992188 C 68.550781 -4.410156 91.019531 -2.3125 102.246094 13.433594 Z M 102.246094 13.433594 "
                                    fillOpacity="1"
                                    fillRule="nonzero"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </SvgIcon>
    );
}
