import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { featureLinks } from '../../../common/features';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { StartGroupButton } from '../../../MyComponents/StartGroupButton/StartGroupButton';
import { FindMoreButton } from '../../Home/HomeFeatures/FindMoreButton';
import { COLORS2 } from '../../../constants';
import { tutorialLinks } from '../../../common/urlLinks';
import { ArrowForwardIos } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    gridWrap: {
        height: '100%',
        background: 'linear-gradient(180deg, rgba(251, 248, 245, 0) 0%, #FFFFFF 93.57%)',
        borderRadius: '0rem 0rem 2rem 2rem',
        paddingTop: '5rem',
        paddingBottom: '2rem',
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        minHeight: '13rem',
        background: 'transparent',
    },
    lines1: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '2rem',
    },
    lines2: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '-1rem',
        marginBottom: '-1rem',
    },
    lines3: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: '2.25rem',
    },
    columnFlexCenter: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        paddingTop: '3rem',
    },
    paperPadding: {
        padding: '1rem',
        paddingBottom: '3rem',
    },
    findOutMoreText: {
        color: COLORS2.virtualAmber,
        fontStyle: 'normal',
        fontWeight: '700',
        // borderBottom: '0.125rem solid #D26828',
    },
    imgContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    sectionTitle: {
        padding: '2rem',
    },
    sectionStep: {
        paddingLeft: '5%',
        textAlign: 'left',
        paddingRight: '1rem',
    },
    stepStart: {
        fontWeight: '500',
        fontSize: '1.5rem',
    },
    subHeading: { fontWeight: '700', fontSize: '2.5rem', lineHeight: '3rem' },
    sectionContent: {
        textAlign: 'center',
    },
    stepContent: {
        paddingLeft: '5%',
        display: 'flex',
        alignItems: 'center',
        fontSize: '1.25rem',
        lineHeight: '1.75rem',
    },
    mobileH3: {
        fontSize: '2rem',
        lineHeight: '2.5rem',
        textAlign: 'center',
    },
    mobileH6: {
        color: COLORS2.virtualText,
        paddingBottom: '2rem',
        paddingTop: '1rem',
        textAlign: 'center',
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    mobileH2: {
        fontSize: '2.75rem',
        lineHeight: '3.25rem',
        textAlign: 'center',
    },
}));

export const ForSchoolsFeaturesMobile = ({ handleOpen }) => {
    const classes = useStyles();

    const transitionToTop = () => {
        setTimeout(() => {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }, 200);
    };

    return (
        <>
            <Grid container className={classes.gridWrap}>
                <Grid item xs={12}>
                    <Typography variant="h2" className={classes.mobileH2}>
                        <FormattedMessage
                            id={'redesign.forTutoring.title'}
                            values={{
                                b: (chunks) => (
                                    <b>
                                        <br></br>
                                        {chunks}
                                    </b>
                                ),
                            }}
                        />
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h3" align="center">
                        <FormattedMessage id={'redesign.forTutoring.subtitle3'} />
                    </Typography>
                </Grid>

                <Grid item xs={12} className={classes.columnFlexCenter}>
                    <Grid item xs={12} className={classes.paperPadding}>
                        <Grid item xs={12} className={classes.imgContainer}>
                            <img src={`/static/asset/img/schools/orice_smartboard.png`} alt="VBoard" width="90%" />
                        </Grid>
                        <Grid item xs={12} className={classes.textContainer}>
                            <Typography variant="h3" align="left" className={classes.mobileH3}>
                                <FormattedMessage
                                    id="redesign.page.forSchools.smartBoard.tag"
                                    values={{
                                        b: (chunks) => <b>{chunks}</b>,
                                    }}
                                />
                            </Typography>
                            <Typography variant="h6" className={classes.mobileH6}>
                                <FormattedMessage id="redesign.page.forSchools.smartBoard.description" />
                            </Typography>
                            <Link to={tutorialLinks.groups}>
                                <FindMoreButton />
                            </Link>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.paperPadding}>
                        <Grid item xs={12} className={classes.imgContainer}>
                            <img src={`/static/asset/img/schools/lectii_in_avans.png`} alt="VBoard" width="90%" />
                        </Grid>
                        <Grid item xs={12} className={classes.textContainer}>
                            <Typography variant="h3" align="left" className={classes.mobileH3}>
                                <FormattedMessage
                                    id="redesign.page.forSchools.prepareLesson.tag"
                                    values={{
                                        b: (chunks) => (
                                            <b>
                                                <br></br>
                                                {chunks}
                                            </b>
                                        ),
                                    }}
                                />
                            </Typography>
                            <Typography variant="h6" className={classes.mobileH6}>
                                <FormattedMessage
                                    id="redesign.page.forSchools.prepareLesson.description"
                                    values={{
                                        a: (chunks) => (
                                            <a href={featureLinks.availableLibrary} className={classes.findOutMoreText}>
                                                {chunks}
                                            </a>
                                        ),
                                        b: (chunks) => (
                                            <a href={featureLinks.paste} className={classes.findOutMoreText}>
                                                {chunks}
                                            </a>
                                        ),
                                    }}
                                />
                            </Typography>
                            <Link to={tutorialLinks.library}>
                                <FindMoreButton />
                            </Link>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.paperPadding}>
                        <Grid item xs={12} className={classes.imgContainer}>
                            <img src={`/static/asset/img/schools/instrumente_speciale.png`} alt="VBoard" width="90%" />
                        </Grid>
                        <Grid item xs={12} className={classes.textContainer}>
                            <Typography variant="h3" align="left" className={classes.mobileH3}>
                                <FormattedMessage
                                    id="redesign.page.forSchools.specialTools.tag"
                                    values={{
                                        b: (chunks) => (
                                            <b>
                                                <br></br>
                                                {chunks}
                                            </b>
                                        ),
                                    }}
                                />
                            </Typography>
                            <Typography variant="h6" className={classes.mobileH6}>
                                <FormattedMessage
                                    id="redesign.page.forSchools.specialTools.description"
                                    values={{
                                        a: (chunks) => (
                                            <a href={tutorialLinks.mathConvertor} className={classes.findOutMoreText}>
                                                {chunks}
                                            </a>
                                        ),
                                        b: (chunks) => (
                                            <a href={tutorialLinks.geometry} className={classes.findOutMoreText}>
                                                {chunks}
                                            </a>
                                        ),
                                    }}
                                />
                            </Typography>
                            <Link to={tutorialLinks.generalTools}>
                                <FindMoreButton />
                            </Link>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.paperPadding}>
                        <Grid item xs={12} className={classes.imgContainer}>
                            <img src={`/static/asset/img/schools/istoric_activitati.png`} alt="VBoard" width="90%" />
                        </Grid>
                        <Grid item xs={12} className={classes.textContainer}>
                            <Typography variant="h3" align="left" className={classes.mobileH3}>
                                <FormattedMessage
                                    id="redesign.page.forSchools.saveLesson.tag"
                                    values={{
                                        b: (chunks) => <b>{chunks}</b>,
                                    }}
                                />
                            </Typography>
                            <Typography variant="h6" className={classes.mobileH6}>
                                <FormattedMessage
                                    id="redesign.page.forSchools.saveLesson.description"
                                    values={{
                                        a: (chunks) => (
                                            <a
                                                href={featureLinks.pdfDownload}
                                                className={classes.findOutMoreText}
                                            >
                                                {chunks}
                                            </a>
                                        ),
                                    }}
                                />
                            </Typography>
                            <Link to={tutorialLinks.imageMenu}>
                                <FindMoreButton />
                            </Link>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.paperPadding}>
                        <Grid item xs={12} className={classes.imgContainer}>
                            <img src={`/static/asset/img/schools/elevi_bolnavi.png`} alt="VBoard" width="90%" />
                        </Grid>
                        <Grid item xs={12} className={classes.textContainer}>
                            <Typography variant="h3" align="left" className={classes.mobileH3}>
                                <FormattedMessage
                                    id="redesign.page.forSchools.distanceTeaching.tag"
                                    values={{
                                        b: (chunks) => (
                                            <b>
                                                {chunks}
                                                <br></br>
                                            </b>
                                        ),
                                    }}
                                />
                            </Typography>
                            <Typography variant="h6" className={classes.mobileH6}>
                                <FormattedMessage id="redesign.page.forSchools.distanceTeaching.description" />
                            </Typography>
                            <Link to={featureLinks.control}>
                                <FindMoreButton />
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    item
                    xs={12}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingBottom: '2rem',
                        paddingTop: '3rem',
                    }}
                    onClick={transitionToTop}
                >
                    <StartGroupButton
                        handleOpen={() => {
                            handleOpen(false);
                        }}
                    />
                </Grid>

                <Grid item className={classes.sectionTitle} xs={12}>
                    <Typography variant={'h2'} className={classes.subHeading}>
                        <FormattedMessage id={'redesign.forSchools.howTo.v2'} />
                    </Typography>
                </Grid>

                <Grid item xs={12} className={classes.sectionContent}>
                    <div className={classes.sectionStep}>
                        <Typography variant={'h3'} className={classes.stepStart}>
                            <FormattedMessage id={'redesign.forTutoring.step'} values={{ step: 1 }} />
                        </Typography>

                        <Typography variant={'h4'} className={classes.stepContent}>
                            <ArrowForwardIos style={{ marginRight: '1rem' }} />
                            <FormattedMessage id={'redesign.forSchools.application'} />
                        </Typography>
                    </div>
                    <Link to={tutorialLinks.groups}>
                        <FindMoreButton />
                    </Link>
                </Grid>

                <Grid item xs={12} className={classes.sectionContent}>
                    <div className={classes.sectionStep}>
                        <Typography variant={'h3'} className={classes.stepStart}>
                            <FormattedMessage id={'redesign.forTutoring.step'} values={{ step: 2 }} />
                        </Typography>
                        <Typography variant={'h4'} className={classes.stepContent}>
                            <ArrowForwardIos style={{ marginRight: '1rem' }} />
                            <FormattedMessage id={'redesign.forSchools.createClass'} />
                        </Typography>
                    </div>
                    <Link to={tutorialLinks.groups}>
                        <FindMoreButton />
                    </Link>
                </Grid>

                <Grid item xs={12} className={classes.sectionContent}>
                    <div className={classes.sectionStep}>
                        <Typography variant={'h3'} className={classes.stepStart}>
                            <FormattedMessage id={'redesign.forTutoring.step'} values={{ step: 3 }} />
                        </Typography>
                        <Typography variant={'h4'} className={classes.stepContent}>
                            <ArrowForwardIos style={{ marginRight: '1rem' }} />
                            <FormattedMessage id={'redesign.forSchools.teachingTools'} />
                        </Typography>
                    </div>
                    <Link to={tutorialLinks.generalTools}>
                        <FindMoreButton />
                    </Link>
                </Grid>

                <Grid item xs={12} className={classes.sectionContent}>
                    <div className={classes.sectionStep}>
                        <Typography variant={'h3'} className={classes.stepStart}>
                            <FormattedMessage id={'redesign.forTutoring.step'} values={{ step: 4 }} />
                        </Typography>
                        <Typography variant={'h4'} className={classes.stepContent}>
                            <ArrowForwardIos style={{ marginRight: '1rem' }} />
                            <FormattedMessage id={'redesign.forSchools.buildBoards'} />
                        </Typography>
                    </div>
                    <Link to={tutorialLinks.library}>
                        <FindMoreButton />
                    </Link>
                </Grid>

                <Grid item xs={12} className={classes.sectionContent} style={{ paddingBottom: '2rem' }}>
                    <div className={classes.sectionStep}>
                        <Typography variant={'h3'} className={classes.stepStart}>
                            <FormattedMessage id={'redesign.forTutoring.step'} values={{ step: 5 }} />
                        </Typography>
                        <Typography variant={'h4'} className={classes.stepContent}>
                            <ArrowForwardIos style={{ marginRight: '1rem' }} />
                            <FormattedMessage id={'redesign.forSchools.downloadLesson'} />
                        </Typography>
                    </div>
                    <Link to={featureLinks.pdfDownload}>
                        <FindMoreButton />
                    </Link>
                </Grid>
            </Grid>
        </>
    );
};
