import React from 'react';
import { Typography } from '@material-ui/core';
import CheckIcon from '../MyComponents/icons/CheckIcon';
import RemoveIcon from '../MyComponents/icons/RemoveIcon';
import { COLORS2 } from '../constants';

export function getSupportIcon(value) {
    if (typeof value === 'boolean') {
        if (value) {
            return <CheckIcon />;
        } else {
            return <RemoveIcon />;
        }
    } else {
        if (value === 0) {
            return <RemoveIcon />;
        }
    }
}

export function getSupportPrimaryText(value, name, formatObj, intl) {
    if (typeof value !== 'boolean') {
        if (value === -1) {
            return (
                <b style={{ color: 'rgba(8, 170, 180, 1)' }}>
                    {intl.formatMessage({ id: `subscriptions.feature.${name}.name` }, formatObj)}
                </b>
            );
        } else {
            return (
                <b style={{ color: 'rgba(8, 170, 180, 1)' }}>
                    {intl.formatMessage({ id: `subscriptions.feature.${name}.name` }, formatObj)}
                </b>
            );
        }
    }
}

function primaryTextRendererHelper(value, intl, formatObj, name, color) {
    if (typeof value === 'boolean') {
        return (
            <b style={{ color: color }}>
                {intl.formatMessage({ id: `subscriptions.feature.${name}.name` }, formatObj)}
            </b>
        );
    } else {
        return (
            <b style={{ color: color }}>
                {intl.formatMessage({ id: `redesign.subscriptions.feature.${name}.name` }, formatObj)}
            </b>
        );
    }
}
export function getSupportPrimaryTextForDescription(
    value,
    name,
    formatObj,
    intl,
    subscriptionType,
    isActive,
    isLight = true
) {
    if (isActive === true) {
        return primaryTextRendererHelper(value, intl, formatObj, name, COLORS2.darkGreen);
    } else if (isActive === false) {
        return primaryTextRendererHelper(value, intl, formatObj, name, 'rgba(255,255,255,1)');
    } else if (isLight === true) {
        return primaryTextRendererHelper(value, intl, formatObj, name, 'rgba(255,255,255,1)');
    } else {
        return primaryTextRendererHelper(value, intl, formatObj, name, COLORS2.darkGreen);
    }
}

export const featureLinks = {
    // availableBoards: '/manageClasses',
    individualBoards: '/features?feature=individualboards',
    lineage: '/features?feature=specialLineage',
    availableGroups: '/features?feature=groups',
    availableLibrary: '/features?feature=library',
    multipleBoarsInIndividualSessions: '/features?feature=individualboards',
    savedMultiBoardSessionCount: '/features?feature=individualboards',
    pdfDownload: '/features?feature=pdfDownload',
    audioVideo: '/features?feature=audiovideo',
    control: '/features?feature=control',
    paste: '/features?feature=paste',
    mobile: '/features?feature=mobile',
    geometry: '/features?feature=geometry',
    mathConvertor: '/features?feature=mathConvertor',
};

export const featureList = [
    // { name: 'availableBoards', defaultValue: -1 },
    { name: 'individualBoards', defaultValue: true },
    { name: 'lineage', defaultValue: true },
    { name: 'availableGroups', defaultValue: 0 },
    { name: 'availableLibrary', defaultValue: 0 },
    { name: 'savedMultiBoardSessionCount', defaultValue: 3 },
    { name: 'multipleBoarsInIndividualSessions', defaultValue: false },
    { name: 'pdfDownload', defaultValue: false },
    { name: 'audioVideo', defaultValue: false },
];

export const replaceValues = function (subscriptionData, featureList) {
    featureList.forEach((el) => {
        if (el.name === 'audioVideo') {
            el.defaultValue = subscriptionData['audioVideo'];
        }
        if (el.name === 'availableGroups') {
            el.defaultValue = subscriptionData['groupCount'];
        }
        if (el.name === 'availableLibrary') {
            el.defaultValue = subscriptionData['collectionCount'];
        }
        if (el.name === 'multipleBoarsInIndividualSessions') {
            el.defaultValue = subscriptionData['multipleIndividualBoards'];
        }
        if (el.name === 'savedMultiBoardSessionCount') {
            el.defaultValue = subscriptionData['savedMultiBoardSessionCount'];
        }
        if (el.name === 'pdfDownload') {
            el.defaultValue = subscriptionData['pdfDownload'];
        }
    });
};

export const getIntlCount = (intl, val) => {
    if (val === -1) {
        return intl.formatMessage({ id: 'subscriptions.subscription.unlimited' });
    } else {
        return val;
    }
};

export const composePriceText = (
    intl,
    prices,
    subscriptionType,
    billAnnual,
    billEur,
    isActive,
    isLoggedIn,
    isDesktop
) => {
    let price = prices[subscriptionType];

    if (!price) {
        return null;
    }

    let curr = billEur ? 'eur' : 'ron';
    let interval = billAnnual ? 'year' : 'month';

    let p = price.find((el) => el.currency === curr && el.interval === interval);

    if (!p) {
        return null;
    }

    let nr = p['unit_amount'] / 100;
    let nrMon;
    let nrYear;
    if (billAnnual) {
        nrYear = nr;
        nrMon = nrYear / 12;
    } else {
        nrMon = nr;
        nrYear = nrMon * 12;
    }
    let tax =
        p['tax_behavior'] === 'exclusive'
            ? intl.formatMessage({ id: `subscriptions.subscription.includeVat` })
            : intl.formatMessage({ id: `subscriptions.subscription.excludeVat` });

    curr = curr.toUpperCase();

    if (isActive) {
        if (billAnnual) {
            return (
                <div>
                    <span style={{ display: 'flex', justifyContent: 'center' }}>
                        <span
                            style={{
                                fontSize: '1.5rem',
                                color: COLORS2.darkGreen,
                                fontWeight: 700,
                                fontStyle: 'normal',
                                letterSpacing: '-0.01rem',
                                marginRight: '0.5rem',
                            }}
                        >
                            {nrMon}
                        </span>
                        <span
                            style={{
                                fontSize: '1.375rem',
                                color: 'rgba(6, 81, 86, 0.66)',
                                fontWeight: 700,
                                fontStyle: 'normal',
                                letterSpacing: '-0.01rem',
                            }}
                        >
                            {curr}
                        </span>
                        <span>
                            <Typography
                                variant={'body2'}
                                style={{
                                    fontSize: '0.6875rem',
                                    color: 'rgba(6, 81, 86, 0.66)',
                                    fontWeight: 'bold',
                                }}
                            >
                                / {intl.formatMessage({ id: `subscriptions.subscription.perMonth` })} {tax}
                            </Typography>
                        </span>
                    </span>
                    <span style={{ display: 'flex', justifyContent: 'center' }}>
                        <span
                            style={{
                                fontSize: '1rem',
                                color: 'rgba(37, 37, 37, 0.66)',
                                fontWeight: 700,
                                fontStyle: 'normal',
                                letterSpacing: '-0.01rem',
                            }}
                        >
                            {nrYear}
                        </span>
                        <span style={{ marginBottom: '0.5rem' }}>
                            <Typography
                                variant={'body2'}
                                style={{ fontSize: '0.5rem', color: 'rgba(37, 37, 37, 0.66)', fontWeight: 'bold' }}
                            >
                                {curr} / {intl.formatMessage({ id: `redesign.subscriptions.subscription.perYear` })}{' '}
                                {tax}
                            </Typography>
                        </span>
                    </span>
                </div>
            );
        } else {
            return (
                <div>
                    <span style={{ display: 'flex', justifyContent: 'center' }}>
                        <span
                            style={{
                                fontSize: '1.5rem',
                                color: COLORS2.darkGreen,
                                fontWeight: 700,
                                fontStyle: 'normal',
                                letterSpacing: '-0.01rem',
                                marginRight: '0.5rem',
                            }}
                        >
                            {nrMon}
                        </span>
                        <span
                            style={{
                                fontSize: '1.375rem',
                                color: 'rgba(6, 81, 86, 0.66)',
                                fontWeight: 700,
                                fontStyle: 'normal',
                                letterSpacing: '-0.01rem',
                            }}
                        >
                            {curr}
                        </span>
                        <span>
                            <Typography
                                variant={'body2'}
                                style={{
                                    fontSize: '0.6875rem',
                                    color: 'rgba(6, 81, 86, 0.66)',
                                    fontWeight: 'bold',
                                }}
                            >
                                / {intl.formatMessage({ id: `subscriptions.subscription.perMonth` })} {tax}
                            </Typography>
                        </span>
                    </span>
                    <Typography variant={'body2'} style={{ color: 'rgba(37, 37, 37, 0.66)', fontWeight: 'bold' }}>
                        {intl.formatMessage({ id: `subscriptions.subscription.whenMonthly` })}
                    </Typography>
                </div>
            );
        }
    } else {
        if (billAnnual) {
            return (
                <div>
                    <span style={{ display: 'flex', justifyContent: 'center' }}>
                        <span
                            style={{
                                fontSize: '1.5rem',
                                color: isDesktop ? (isLoggedIn ? COLORS2.darkGreen : '#FFFFFF') : '#FFFFFF',
                                fontWeight: 700,
                                fontStyle: 'normal',
                                letterSpacing: '-0.01rem',
                                marginRight: '0.5rem',
                            }}
                        >
                            {nrMon}
                        </span>
                        <span
                            style={{
                                fontSize: '1.375rem',
                                color: isDesktop
                                    ? isLoggedIn
                                        ? 'rgba(6,81,86,0.66)'
                                        : 'rgba(255, 255, 255, 0.66)'
                                    : 'rgba(255, 255, 255, 0.66)',
                                fontWeight: 700,
                                fontStyle: 'normal',
                                letterSpacing: '-0.01rem',
                            }}
                        >
                            {curr}
                        </span>
                        <span>
                            <Typography
                                variant={'body2'}
                                style={{
                                    fontSize: '0.6875rem',
                                    color: isDesktop
                                        ? isLoggedIn
                                            ? 'rgba(6,81,86,0.66)'
                                            : 'rgba(255, 255, 255, 0.66)'
                                        : 'rgba(255, 255, 255, 0.66)',
                                    fontWeight: 'bold',
                                }}
                            >
                                / {intl.formatMessage({ id: `subscriptions.subscription.perMonth` })} {tax}
                            </Typography>
                        </span>
                    </span>
                    <span style={{ display: 'flex', justifyContent: 'center' }}>
                        <span
                            style={{
                                fontSize: '1rem',
                                color: isDesktop
                                    ? isLoggedIn
                                        ? 'rgba(6,81,86,0.66)'
                                        : 'rgba(255, 255, 255, 0.66)'
                                    : 'rgba(255, 255, 255, 0.66)',
                                fontWeight: 700,
                                fontStyle: 'normal',
                                letterSpacing: '-0.01rem',
                            }}
                        >
                            {nrYear}
                        </span>
                        <span style={{ marginBottom: '0.5rem' }}>
                            <Typography
                                variant={'body2'}
                                style={{
                                    fontSize: '0.5rem',
                                    color: isDesktop
                                        ? isLoggedIn
                                            ? 'rgba(6,81,86,0.66)'
                                            : 'rgba(255, 255, 255, 0.66)'
                                        : 'rgba(255, 255, 255, 0.66)',
                                    fontWeight: 'bold',
                                }}
                            >
                                {curr} / {intl.formatMessage({ id: `redesign.subscriptions.subscription.perYear` })}{' '}
                                {tax}
                            </Typography>
                        </span>
                    </span>
                </div>
            );
        } else {
            return (
                <div>
                    <span style={{ display: 'flex', justifyContent: 'center' }}>
                        <span
                            style={{
                                fontSize: '1.5rem',
                                color: isDesktop ? (isLoggedIn ? COLORS2.darkGreen : '#FFFFFF') : '#FFFFFF',
                                fontWeight: 700,
                                fontStyle: 'normal',
                                letterSpacing: '-0.01rem',
                                marginRight: '0.5rem',
                            }}
                        >
                            {nrMon}
                        </span>
                        <span
                            style={{
                                fontSize: '1.375rem',
                                color: isDesktop
                                    ? isLoggedIn
                                        ? 'rgba(6,81,86,0.66)'
                                        : 'rgba(255, 255, 255, 0.66)'
                                    : 'rgba(255, 255, 255, 0.66)',
                                fontWeight: 700,
                                fontStyle: 'normal',
                                letterSpacing: '-0.01rem',
                            }}
                        >
                            {curr}
                        </span>
                        <span>
                            <Typography
                                variant={'body2'}
                                style={{
                                    fontSize: '0.6875rem',
                                    color: isDesktop
                                        ? isLoggedIn
                                            ? 'rgba(6,81,86,0.66)'
                                            : 'rgba(255, 255, 255, 0.66)'
                                        : 'rgba(255, 255, 255, 0.66)',
                                    fontWeight: 'bold',
                                }}
                            >
                                / {intl.formatMessage({ id: `subscriptions.subscription.perMonth` })} {tax}
                            </Typography>
                        </span>
                    </span>
                    <Typography
                        variant={'body2'}
                        style={{
                            color: isDesktop
                                ? isLoggedIn
                                    ? 'rgba(6,81,86,0.66)'
                                    : 'rgba(255, 255, 255, 0.66)'
                                : 'rgba(255, 255, 255, 0.66)',
                            fontWeight: 'bold',
                        }}
                    >
                        {intl.formatMessage({ id: `subscriptions.subscription.whenMonthly` })}
                    </Typography>
                </div>
            );
        }
    }
};

export const composePriceTextForFree = (intl, billEur, isActive, isLoggedIn, isDesktop) => {
    let curr = billEur ? 'eur' : 'ron';

    let tax = intl.formatMessage({ id: `subscriptions.subscription.includeVat` });

    curr = curr.toUpperCase();
    if (isActive) {
        return (
            <div>
                <span style={{ display: 'flex', justifyContent: 'center' }}>
                    <span
                        style={{
                            fontSize: '1.5rem',
                            color: COLORS2.darkGreen,
                            fontWeight: 700,
                            fontStyle: 'normal',
                            letterSpacing: '-0.01rem',
                            marginRight: '0.5rem',
                        }}
                    >
                        0
                    </span>
                    <span
                        style={{
                            fontSize: '1.375rem',
                            color: 'rgba(6, 81, 86, 0.66)',
                            fontWeight: 700,
                            fontStyle: 'normal',
                            letterSpacing: '-0.01rem',
                        }}
                    >
                        {curr}
                    </span>
                    <span>
                        <Typography
                            variant={'body2'}
                            style={{
                                fontSize: '0.6875rem',
                                color: 'rgba(6,81,86,0.66)',
                                fontWeight: 'bold',
                            }}
                        >
                            / {intl.formatMessage({ id: `subscriptions.subscription.perMonth` })} {tax}
                        </Typography>
                    </span>
                </span>
                <Typography
                    variant={'body2'}
                    style={{
                        fontSize: '1rem',
                        color: COLORS2.darkGreen,
                        fontWeight: 'bold',
                        padding: '0.5rem',
                    }}
                >
                    -
                </Typography>
            </div>
        );
    } else {
        return (
            <div>
                <span style={{ display: 'flex', justifyContent: 'center' }}>
                    <span
                        style={{
                            fontSize: '1.5rem',
                            color: isDesktop ? (isLoggedIn ? COLORS2.darkGreen : '#FFFFFF') : '#FFFFFF',
                            fontWeight: 700,
                            fontStyle: 'normal',
                            letterSpacing: '-0.01rem',
                            marginRight: '0.5rem',
                        }}
                    >
                        0
                    </span>
                    <span
                        style={{
                            fontSize: '1.375rem',
                            color: isDesktop
                                ? isLoggedIn
                                    ? 'rgba(6,81,86,0.66)'
                                    : 'rgba(255, 255, 255, 0.66)'
                                : 'rgba(255, 255, 255, 0.66)',
                            fontWeight: 700,
                            fontStyle: 'normal',
                            letterSpacing: '-0.01rem',
                        }}
                    >
                        {curr}
                    </span>
                    <span>
                        <Typography
                            variant={'body2'}
                            style={{
                                fontSize: '0.6875rem',
                                color: isDesktop
                                    ? isLoggedIn
                                        ? 'rgba(6,81,86,0.66)'
                                        : 'rgba(255, 255, 255, 0.66)'
                                    : 'rgba(255, 255, 255, 0.66)',
                                fontWeight: 'bold',
                            }}
                        >
                            / {intl.formatMessage({ id: `subscriptions.subscription.perMonth` })} {tax}
                        </Typography>
                    </span>
                </span>
                <Typography
                    variant={'body2'}
                    style={{
                        fontSize: '1rem',
                        color: isDesktop ? (isLoggedIn ? COLORS2.darkGreen : '#FFFFFF') : '#FFFFFF',
                        fontWeight: 'bold',
                        padding: '0.5rem',
                    }}
                >
                    -
                </Typography>
            </div>
        );
    }
};
