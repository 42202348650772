import React from 'react';
import { List, ListItem, MenuItem } from '@material-ui/core';
import drawUtils from '../../../pages/LessonPage/drawUtils';
import { TOOL_TEXT } from '../../../constants';

const TextSizeSelectorMenu = React.forwardRef(({ selectText, textWeight, tool }, ref) => {
    return (
        <List>
            <MenuItem dense disableGutters>
                <ListItem
                    button
                    key={'1'}
                    selected={textWeight === 1 && tool === TOOL_TEXT}
                    onClick={(event) => {
                        event.preventDefault();
                        selectText(1);
                    }}
                >
                    <span style={{ fontSize: drawUtils.computeFontPixelForDisplay(1) + 'px' }}>Text</span>
                </ListItem>
            </MenuItem>
            <MenuItem dense disableGutters>
                <ListItem
                    button
                    key={'2'}
                    selected={textWeight === 2 && tool === TOOL_TEXT}
                    onClick={(event) => {
                        event.preventDefault();
                        selectText(2);
                    }}
                >
                    <span style={{ fontSize: drawUtils.computeFontPixelForDisplay(2) + 'px' }}>Text</span>
                </ListItem>
            </MenuItem>
            <MenuItem dense disableGutters>
                <ListItem
                    button
                    key={'3'}
                    selected={textWeight === 3 && tool === TOOL_TEXT}
                    onClick={(event) => {
                        event.preventDefault();
                        selectText(3);
                    }}
                >
                    <span style={{ fontSize: drawUtils.computeFontPixelForDisplay(3) + 'px' }}>Text</span>
                </ListItem>
            </MenuItem>
            <MenuItem dense disableGutters>
                <ListItem
                    button
                    key={'4'}
                    selected={textWeight === 4 && tool === TOOL_TEXT}
                    onClick={(event) => {
                        event.preventDefault();
                        selectText(4);
                    }}
                >
                    <span style={{ fontSize: drawUtils.computeFontPixelForDisplay(4) + 'px' }}>Text</span>
                </ListItem>
            </MenuItem>
            <MenuItem dense disableGutters>
                <ListItem
                    button
                    key={'5'}
                    selected={textWeight === 5 && tool === TOOL_TEXT}
                    onClick={(event) => {
                        event.preventDefault();
                        selectText(5);
                    }}
                >
                    <span style={{ fontSize: drawUtils.computeFontPixelForDisplay(5) + 'px' }}>Text</span>
                </ListItem>
            </MenuItem>
        </List>
    );
});

export default TextSizeSelectorMenu;
