import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import CreateClassAction from '../ManageClass/CreateClassAction';
import MainActivityBlock from './MainActivityBlock';

const Welcome = (props) => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h2">
                    <FormattedMessage
                        id={'redesign.home.loggedIn.welcomeProfileGivenName'}
                        values={{ givenName: props.profile.extended.first_name }}
                    />
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6">
                    <FormattedMessage id={'welcome.text.groupsDesc'} />
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <img src={`/static/asset/img/home/construit_cu_profesori.png`} alt="VBoard" width="100%" />
            </Grid>
            <Grid item xs={12} sm={12}>
                <Typography variant="h5">
                    <FormattedMessage id={'welcome.text.startWithGroups'} />
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
                <CreateClassAction />
            </Grid>
            <MainActivityBlock />
        </Grid>
    );
};

const mapStateToProps = (state) => {
    const { profile } = state;
    return { profile };
};

export default connect(mapStateToProps)(Welcome);
