import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function LeftMenuIcon(props) {
    return (
        <SvgIcon {...props} style={{ verticalAlign: 'bottom', width: '4rem', height: '4rem' }}>
            <svg viewBox="0 0 80 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="17" y="12.7574" width="6" height="6" rx="3" transform="rotate(45 17 12.7574)" fill="#08AAB4"/>
                <rect x="12.7573" y="31" width="6" height="6" rx="3" transform="rotate(-45 12.7573 31)" fill="#FFCD34"/>
                <rect x="35.2426" y="17" width="6" height="6" rx="3" transform="rotate(135 35.2426 17)" fill="#D26828"/>
                <rect x="31" y="35.2427" width="6" height="6" rx="3" transform="rotate(-135 31 35.2427)" fill="#F9A237"/>
                <path d="M55.5 20V27H54.13V22.46L52.16 25.71H52L50.03 22.47V27H48.65V20H50.07L52.08 23.31L54.08 20H55.5ZM59.7843 25.68H62.7343V27H58.4043V20H62.6843V21.32H59.7843V22.81H62.4343V24.11H59.7843V25.68ZM69.357 20H70.737V27H69.687L66.687 22.72V27H65.307V20H66.357L69.357 24.27V20ZM76.2455 27.14C75.4655 27.14 74.8255 26.92 74.3255 26.48C73.8255 26.04 73.5755 25.45 73.5755 24.71V20H74.9455V24.6C74.9455 24.9667 75.0489 25.2567 75.2555 25.47C75.4689 25.6833 75.7989 25.79 76.2455 25.79C76.6922 25.79 77.0189 25.6833 77.2255 25.47C77.4389 25.2567 77.5455 24.9667 77.5455 24.6V20H78.9255V24.71C78.9255 25.45 78.6755 26.04 78.1755 26.48C77.6755 26.92 77.0322 27.14 76.2455 27.14Z" fill="#065156"/>
            </svg>

        </SvgIcon>
    );
}
