import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import SectionHeader from '../SectionHeader';
import SectionChapter from '../SectionChapter';

const MobileManagement = ({ classes, refs, language }) => {
    let mobileData = [
        {
            children: (
                <ul>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.optimization.step1"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.optimization.step2"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.optimization.step3"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.optimization.step4"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.optimization.step5"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>
                </ul>
            ),
            img: [
                `/static/asset/img/tutorial/withText/${language.toUpperCase()}/IPhone_1.jpg`,
                `/static/asset/img/tutorial/withText/${language.toUpperCase()}/Android_1.jpg`,
                `/static/asset/img/tutorial/withText/${language.toUpperCase()}/IPhone_2.jpg`,
                `/static/asset/img/tutorial/withText/${language.toUpperCase()}/Android_2.jpg`,
                `/static/asset/img/tutorial/withText/${language.toUpperCase()}/IPhone_3.jpg`,
                `/static/asset/img/tutorial/withText/${language.toUpperCase()}/Android_3.jpg`,
                `/static/asset/img/tutorial/withText/${language.toUpperCase()}/Iphone_4.jpg`,
            ],
        },
    ];
    return (
        <SectionHeader
            refs={refs}
            classes={classes}
            title={'tutorial.optimization.title'}
            description={'tutorial.optimization.description'}
            id={'section-optimization'}
            imageTop={false}
        >
            <SectionChapter
                refs={refs}
                id={'section-optimization-chapter'}
                title={'page.home.feature.mobile.tag'}
                explanations={mobileData}
                imageTop={false}
            />
        </SectionHeader>
    );
};

export default MobileManagement;
