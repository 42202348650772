import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function LogOutIcon(props) {
    return (
        <SvgIcon {...props} style={{ verticalAlign: 'bottom' }}>
            <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_46_255)">
                    <path d="M2 8C2 4.6875 4.6875 2 8 2C8.55313 2 9 1.55313 9 1C9 0.446875 8.55313 0 8 0C3.58125 0 0 3.58125 0 8C0 12.4187 3.58125 16 8 16C8.55313 16 9 15.5531 9 15C9 14.4469 8.55313 14 8 14C4.6875 14 2 11.3125 2 8ZM12.2063 3.79375C11.8156 3.40313 11.1812 3.40313 10.7906 3.79375C10.4 4.18437 10.4 4.81875 10.7906 5.20937L12.5844 7H6C5.44688 7 5 7.44688 5 8C5 8.55313 5.44688 9 6 9H12.5844L10.7906 10.7937C10.4 11.1844 10.4 11.8188 10.7906 12.2094C11.1812 12.6 11.8156 12.6 12.2063 12.2094L15.7063 8.70938C16.0969 8.31875 16.0969 7.68437 15.7063 7.29375L12.2063 3.79375Z" fill="#08AAB4"/>
                </g>
                <defs>
                    <clipPath id="clip0_46_255">
                        <rect width="16" height="16" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        </SvgIcon>
    );
}