import { io } from 'socket.io-client';
import store from '../../redux/store';
import {
    allowDrawing,
    callInProgress,
    changeCallState,
    hideSpinner,
    joinLesson,
    showError,
    showInfo,
    showSuccess,
    showWarn,
    startLesson,
    switchBoardType,
    updateSocketId,
    updateUserOpenJoinRequests,
    userJoin,
    userJoinRequest,
    userLeave,
    userMute,
    userRaiseHand,
} from '../../redux/actions';
import { LINEAGE_NONE } from './drawConstants';
import audioCallUtils from './audioCallUtilsNoQueue';
import { BOARD_TYPE, CALL_STATE, TARGETED_REQUESTS } from '../../constants';
import boardManager from './boardManager';
import cloudBoardManager from './cloudBoardManager';
import joinRequestApi from '../../Api/joinRequestApi';
import { JOIN_REQUEST_STATUS } from '../../shared/JoinRequestStatus';

let socket = null;
let classId = null;
let lessonComponent = null;

let socketUtils = {};

let onAuthenticateCallback = null;

socketUtils.setAuthenticateCallback = function(cb) {
    onAuthenticateCallback = cb;
};

socketUtils.getAuthenticateCallback = function() {
    return onAuthenticateCallback;
};

socketUtils.socketStateHandling = function(key, classIdProp, myLesson) {
    if (socket != null) {
        return;
    }

    if (!key) {
        return;
    }
    let protocol = window.location.protocol;
    let host = window.location.host;
    // console.log('===== window socket protocol', protocol, host);
    if (protocol === 'http:') {
        protocol = 'ws:';
    }
    if (protocol === 'https:') {
        protocol = 'wss:';
    }
    if (host === 'localhost:3000') {
        host = 'localhost:4000';
    }
    if (host === '192.168.2.112:3000') {
        host = '192.168.2.112:4000';
    }
    socket = io(protocol + '//' + host, { reconnection: false, transports: ['websocket'] });
    classId = classIdProp;
    lessonComponent = myLesson;

    socket.on('connect', () => {
        // console.log('connected');
        socket.emit(
            'authenticate',
            {
                key,
                profile: lessonComponent.props.profile,
            },
            () => {
                // console.log('init completed', data);
                socket.emit(
                    'joinLesson',
                    {
                        classId: classId,
                        key,
                        profile: lessonComponent.props.profile,
                    },
                    (data) => {
                        if (!data.connected) {
                            lessonComponent.props.history.push('/');
                            store.dispatch(hideSpinner());
                            store.dispatch(showError('NOT_ALLOWED_IN_CLASS'));
                            return;
                        }
                        if (data.isOwner) {
                            boardManager.isAdmin = true;
                            // store.dispatch(showSuccess('CONNECT_AS_ADMIN'));
                            store.dispatch(startLesson(classId, Object.values(data.lessonState.userStates)));
                        } else {
                            store.dispatch(joinLesson(classId, Object.values(data.lessonState.userStates)));
                            lessonComponent.allowDrawing = data.userData.enableDraw;
                            store.dispatch(allowDrawing(data.userData.enableDraw));
                        }
                        if (!audioCallUtils.hasLocalStream()) {
                            socketUtils.sendMutedValue(null);
                        } else {
                            socketUtils.sendMutedValue(store.getState().lessonState.isMuted);
                        }

                        // store.dispatch(hideSpinner());
                        store.dispatch(updateSocketId(data.socketId));

                        if (data.lessonState) {
                            boardManager.initFromSocket(
                                data.isOwner,
                                lessonComponent.props.profile.email,
                                classId,
                                data.lessonState,
                                // data.lessonState.boardType,
                                // data.lessonState.boardId,
                                // data.lessonState.pausedBoard,
                                // data.lessonState.canMultiple,
                                // data.lessonState.boardData,
                                // data.lessonState.geometry
                            );
                            // store.dispatch(switchBoardType(data.lessonState.boardType));
                            // boardManager.switchBoardType(data.lessonState.boardType, data.lessonState.boardId);
                            if (data.lessonState.audioConf) {
                                store.dispatch(callInProgress(true));
                                store.dispatch(changeCallState(CALL_STATE.CAN_JOIN_CALL));
                            }
                        } else {
                            lessonComponent.props.history.push('/');
                            store.dispatch(hideSpinner());
                            store.dispatch(showError('ERROR_CONNECTING_SOCKET'));
                        }

                        if (!data.isOwner) {
                            lessonComponent.allowMainGeometryHandling(allowDrawing(data.userData.enableDraw));
                        }

                        if (onAuthenticateCallback) {
                            onAuthenticateCallback();
                            onAuthenticateCallback = null;
                        }
                    },
                );
            },
        );
    });
    socket.on('myPing', function() {
        socket.emit('myPong');
    });

    socket.on('draw', (newData) => {
        // let newData = JSON.parse(data);
        lessonComponent.drawLineFromMessage(newData.drawing);
    });

    socket.on('targetedDraw', (newData) => {
        // let newData = JSON.parse(data);
        // console.log('=== got targetedDraw', newData);
        lessonComponent.drawLineFromMessage(newData.drawing, newData.source);
    });

    socket.on('mediaChannel', (newData) => {
        // let newData = JSON.parse(data);
        if (store.getState().lessonState.callState !== CALL_STATE.CALL_STARTED) {
            return;
        }

        if (newData.messageType === 'SEND_MEDIA_ICE_CANDIDATE') {
            if (window.logLevel === 'debug') {
                console.log('adding ice candidate', newData.messageData);
            }
            audioCallUtils.addRemoteIceCandidate(newData.messageData.fromEmail, newData.messageData.ice);
            return;
        }

        if (newData.messageType === 'SEND_OFFER_MEDIA_SDP') {
            // console.log('got media offer sdp', newData.messageData);
            if (newData.messageData.toEmail !== lessonComponent.props.profile.email) {
                // console.log('media offer not for us');
                return;
            }

            audioCallUtils.processIncomingSDPOffer(newData.messageData.fromEmail, newData.messageData.description);

            return;
        }
        if (newData.messageType === 'SEND_ANSWER_MEDIA_SDP') {
            // console.log('got media answer sdp', newData.messageData);
            if (newData.messageData.toEmail !== lessonComponent.props.profile.email) {
                // console.log('media answer not for us');
                return;
            }
            audioCallUtils.updateAnswerMediaSdp(newData.messageData.fromEmail, newData.messageData.description);
        }
    });

    socket.on('adminAction', (newData) => {
        // let newData = JSON.parse(data);
        if (newData.messageType === 'UPDATE_USER_DRAWING') {
            if (lessonComponent.props.profile.email === newData.messageData.email) {
                lessonComponent.allowDrawing = newData.messageData.drawingEnabled;
                if (lessonComponent.allowDrawing) {
                    store.dispatch(showSuccess('ENABLED_DRAWING'));
                    store.dispatch(allowDrawing(true));
                    lessonComponent.allowMainGeometryHandling(true);
                } else {
                    if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
                        store.dispatch(showWarn('DISABLED_DRAWING'));
                    }
                    store.dispatch(allowDrawing(false));
                    lessonComponent.allowMainGeometryHandling(false);
                }
            }
        }
        if (newData.messageType === 'UPDATE_USER_MIC') {
            if (lessonComponent.props.profile.email === newData.messageData.email) {
                if (newData.messageData.isMuted) {
                    audioCallUtils.mute();
                } else {
                    audioCallUtils.unMute();
                }
            }
        }
        if (newData.messageType === 'SWITCH_BOARD_TYPE') {
            if (!newData.messageData) return;
            if (!boardManager.isAdmin) {
                // this message should never be received by the admin
                if (newData.messageData.boardType === BOARD_TYPE.MULTI_BOARD) {
                    if (!newData.messageData.individualBoards[boardManager.adminEmail]) {
                        let currentIndividualBoards = JSON.stringify(boardManager.lessonState.individualBoards);
                        boardManager.lessonState = newData.messageData;
                        boardManager.lessonState.individualBoards = JSON.parse(currentIndividualBoards);
                    } else {
                        boardManager.lessonState = newData.messageData;
                    }
                    if (newData.messageData.pausedBoard) {
                        boardManager.resumeMultiBoardForUser();
                    } else {
                        boardManager.switchToNewMultiBoardForUser(newData.messageData);
                    }
                } else {
                    cloudBoardManager.saveCurrentMultiBoardUserPage().then(() => {
                        if (!newData.messageData.individualBoards[boardManager.adminEmail]) {
                            let currentIndividualBoards = JSON.stringify(boardManager.lessonState.individualBoards);
                            boardManager.lessonState = newData.messageData;
                            boardManager.lessonState.individualBoards = JSON.parse(currentIndividualBoards);
                        } else {
                            boardManager.lessonState = newData.messageData;
                        }

                        boardManager.switchToSingleBoardForUser(newData.messageData.boardData);
                    });
                }
            } else {
                boardManager.lessonState = newData.messageData;
            }
        }
        if (newData.messageType === 'UPDATE_MAIN_GEOMETRY') {
            boardManager.updateMainGeometry(newData.messageData);
        }
    });

    socket.on('userJoin', (data) => {
        // console.log('===== user join', data);
        if (socketUtils.shouldNotContinueProcessing(data.email, data.socketId)) {
            // console.log('==== skipping rest of processing for user join');
            return;
        }
        // data.enableDraw = true;
        store.dispatch(userJoin(data));
        boardManager.lessonState.userStates[data.email] = data;
        // store.dispatch(showInfo('USER_JOIN', false, { name: data.name }));
        if (lessonComponent.classInfo.owner) {
            // console.log('==== preparing to send join image data', data.email);
            if (boardManager.lessonState.boardType === BOARD_TYPE.MULTI_BOARD) {
                // boardManager.processCanvasLoad(data.email);
                // boardManager.sendMultiBoardJoinData(data.email);
            } else {
                lessonComponent.sendJoinImageData(data.email);
            }
        }
        socketUtils.sendJoinFeedback(data.email);
        if (window.getMainMessages) {
            socketUtils.sendTargetedRequest(
                [data.email],
                TARGETED_REQUESTS.CHAT_MSG,
                window.getMainMessages(data.email),
            );
        }

        audioCallUtils.cleanEmailState(data.email);
        // callUtils.clearRemoteState(data.email);
        // callUtils.initRemoteState(data.email).then(() => callUtils.createOffer(data.email));
        if (!audioCallUtils.hasLocalStream()) {
            socketUtils.sendMutedValue(null);
        } else {
            socketUtils.sendMutedValue(store.getState().lessonState.isMuted);
        }

        // if (lessonComponent.classInfo.owner && window.updateUserDrawingCallback) {
        //     // window.updateUserDrawingCallback(
        //     //     data.email,
        //     //     !lessonComponent.classInfo.classData.disableMembersDrawDefault
        //     // );
        //     if (lessonComponent.classInfo.classData.disableMembersDrawDefault) {
        //         store.dispatch(userToggleDraw(data.email));
        //     }
        // }
        let callS = store.getState().lessonState.callState;
        if (
            lessonComponent.classInfo.owner &&
            (callS === CALL_STATE.CALL_STARTED || callS === CALL_STATE.CALL_STARTING)
        ) {
            socketUtils.sendStartAudioCall(audioCallUtils.getCurrentMicState());
        }
        if (
            !boardManager.isAdmin &&
            boardManager.lessonState.boardType === BOARD_TYPE.MULTI_BOARD &&
            data.email === boardManager.adminEmail
        ) {
            cloudBoardManager.saveCurrentMultiBoardUserPage().then(() => {
                socketUtils.sendTargetedRequest(
                    [boardManager.adminEmail],
                    TARGETED_REQUESTS.SWITCH_USER_MULTIBOARD_PAGE,
                    {
                        currentPageNo: boardManager.lessonState.individualBoards[boardManager.adminEmail].currentPageNo,
                        pages: boardManager.lessonState.individualBoards[boardManager.adminEmail].pages,
                    },
                );
            });
        }
    });

    socket.on('userJoinRequest', (data) => {
        // console.log(data.messageData.request);

        // console.log('==== userJoinRequest', data);

        if (lessonComponent.classInfo.owner) {
            let callC = store.getState().lessonState?.openJoinRequests?.length;
            let alreadyOpen = store.getState().appState.userJoinRequest;
            if (!callC || alreadyOpen) {
                store.dispatch(
                    userJoinRequest({
                        classId: data.messageData.request.toClass,
                        userId: data.messageData.request.fromUser,
                    }),
                );
            } else {
                joinRequestApi.getJoinRequestsForClass(classId, JOIN_REQUEST_STATUS.OPEN).then((res) => {
                    store.dispatch(updateUserOpenJoinRequests(res.data));
                });
            }
        }
    });

    socket.on('sendTargetedImage', (newData) => {
        // let newData = JSON.parse(data);
        lessonComponent.drawLineFromMessage(newData.drawing, newData.fromEmail);
    });

    socket.on('sendJoinData', (newData) => {
        // let newData = JSON.parse(data);
        lessonComponent.remoteMainPageChange(newData.lessonState.mainBoardId, newData.lessonState.mainBoardPages);
    });

    socket.on('changeMainBoardState', (newData) => {
        lessonComponent.remoteMainPageChange(newData.mainBoardId, newData.mainBoardPages);
    });

    socket.on('sendTargetedRequest', (newData) => {
        // let newData = JSON.parse(data);
        // console.log('got targeted request', newData);
        if (newData.request === TARGETED_REQUESTS.SWITCH_USER_MULTIBOARD_PAGE) {
            // let s = store.getState();
            // let canMultiple = hasBooleanPermission(
            //     s.mainAppState.subscriptions,
            //     s.profile.subscription,
            //     CAPABILITIES.MULTIPLE_INDIVIDUAL_BOARDS
            // );
            // if (!canMultiple.value && newData.data.pageNo !== 0) {
            //     store.dispatch(showWarn('MULTIBOARD_UNAVAILABLE'));
            //     socketUtils.sendTargetedRequest([newData.fromEmail], TARGETED_REQUESTS.UNABLE_TO_CHANGE_PAGE, {});
            //     return;
            // }
            boardManager.switchMultiBoardPageForAdmin(newData.fromEmail, newData.data);
        }
        if (newData.request === TARGETED_REQUESTS.CLEAR_USER_MULTIBOARD_PAGE) {
            if (boardManager.isAdmin) {
                boardManager.clearMultiBoardPageForAdmin(newData.fromEmail);
            } else {
                boardManager.clearMultiBoardPageForUser();
            }
        }
        if (newData.request === TARGETED_REQUESTS.SWITCH_USER_MULTIBOARD_LINEAGE) {
            boardManager.switchMultiBoardLineageForAdmin(newData.fromEmail, newData.data.lineage);
        }
        if (newData.request === TARGETED_REQUESTS.UNABLE_TO_CHANGE_PAGE) {
            store.dispatch(showWarn('MULTIBOARD_UNAVAILABLE'));
            store.dispatch(hideSpinner());
        }
        if (newData.request === TARGETED_REQUESTS.CHAT_MSG) {
            if (window.pushMainMessages) {
                window.pushMainMessages(newData.data, newData.fromEmail);
            }
        }
        if (newData.request === TARGETED_REQUESTS.UPDATE_MULTIBOARD_SET) {
            store.dispatch(showInfo('NEW_BOARDS_ADDED'));
            boardManager.lessonState.individualBoards[newData.fromEmail].pages = newData.data.pages;
            if (window.updateBoardNavigation) {
                window.updateBoardNavigation();
            }
        }
    });

    // socket.on('getLocalImage', (data) => {
    //     let newData = JSON.parse(data);
    //     // console.log('got image = local', newData);
    //     if (!newData.drawing.data) {
    //         console.log('==== ERROR getting image to load');
    //     } else {
    //         lessonComponent.drawLineFromMessage(newData.drawing, newData.fromEmail);
    //     }
    // });

    socket.on('discoverChannel', (newData) => {
        // console.log('got discoverChannel', newData);
        if (socketUtils.shouldNotContinueProcessing(newData.messageData.profile.email)) {
            // console.log('==== skipping rest of processing for discover');
            return;
        }
        if (newData.messageData.profile.email === lessonComponent.props.profile.email) {
            // ignore my own discovery message
            return;
        }
        if (newData.messageData.profile.enableDraw === undefined) {
            newData.messageData.profile.enableDraw = true;
        }
        store.dispatch(userJoin(newData.messageData.profile));
        // store.dispatch(showInfo('USER_JOIN', false, { name: newData.messageData.profile.name }));
        setTimeout(lessonComponent.shallowStickyElementSync, 100);
        if (window.pushMainMessages && newData.messageData.chatMessages) {
            window.pushMainMessages(newData.messageData.chatMessages);
        }
        // audioCallUtils.addDiscoveredUser(newData.messageData.profile.email);
        // if (lessonComponent.classInfo.owner) {
        //     // window.updateUserDrawingCallback(
        //     //     newData.messageData.profile.email,
        //     //     !lessonComponent.classInfo.classData.disableMembersDrawDefault
        //     // );
        //     if (lessonComponent.classInfo.classData.disableMembersDrawDefault) {
        //         store.dispatch(userCanDraw(newData.messageData.profile.email, newData.messageData.profile.canDraw));
        //     }
        // }
        if (lessonComponent.classInfo.owner) {
            // console.log('==== preparing to send discover image data', data.email);
            if (boardManager.lessonState.boardType === BOARD_TYPE.MULTI_BOARD) {
                // boardManager.processCanvasLoad(data.email);
                // boardManager.sendMultiBoardJoinData(
                //     newData.messageData.profile.email,
                //     newData.messageData.currentPageNo
                // );
            } else {
                // console.log('=== discovered', newData.messageData.profile.email);
                lessonComponent.sendJoinImageData(newData.messageData.profile.email);
            }
        }
    });

    socket.on('sendImageData', (drawData) => {
        // let drawData = JSON.parse(data);
        // console.log('=== got sendImageData', drawData);
        lessonComponent.drawLineFromMessage(drawData.drawing);
        // store.dispatch(hideSpinner());
        // let drawData = JSON.parse(data);
        // if (!lessonComponent.didImageLoad || drawData.drawing.force) {
        //     lessonComponent.didImageLoad = true;
        //     console.log('got image = sent');
        //     lessonComponent.drawLineFromMessage(drawData.drawing);
        //     store.dispatch(hideSpinner());
        //     console.log('got image data and not ignoring');
        // } else {
        //     console.log('got image data and ignoring');
        // }
    });

    socket.on('sendElementOp', (drawData) => {
        lessonComponent.drawLineFromMessage(drawData.drawing);
    });

    socket.on('changeIndividualBoardState', (drawData) => {
        // let drawData = JSON.parse(data);
        if (boardManager.isAdmin) {
            // console.log('==== got individual board state', drawData);
        }
    });

    socket.on('socketManagement', (data) => {
        // console.log('got message on management', data);
        if (data.messageType === 'DISCONNECT') {
            lessonComponent.props.history.push('/');
            // store.dispatch(hideSpinner());
            store.dispatch(showError(data.messageKey));
        }
        if (data.messageType === 'CONNECT') {
            // if (data.messageData.connectedClients === 0) {
            //     lessonComponent.didImageLoad = true;
            // }
            if (data.messageKey === 'CONNECT_AS_ADMIN') {
                // store.dispatch(hideSpinner());
                // store.dispatch(showSuccess(data.messageKey));
                store.dispatch(startLesson(classId));
            } else if (data.messageKey === 'CONNECT_AS_MEMBER') {
                // store.dispatch(hideSpinner());
                store.dispatch(showSuccess(data.messageKey));
                store.dispatch(joinLesson(classId));
            }
            store.dispatch(updateSocketId(data.messageData.socketId));
            if (data.messageData.lessonState) {
                store.dispatch(switchBoardType(data.messageData.lessonState.boardType));
                boardManager.switchBoardType(
                    data.messageData.lessonState.boardType,
                    data.messageData.lessonState.boardId,
                );
            }
        }
        if (data.messageType === 'USER_DISCONNECT') {
            if (
                socketUtils.shouldNotContinueProcessingForDisconnect(data.messageData.email, data.messageData.socketId)
            ) {
                console.log('==== skipping rest of processing for disconnect');
                return;
            }
            // store.dispatch(
            //     showWarn(data.messageKey, false, {
            //         name: data.messageData.last_name + ' ' + data.messageData.first_name,
            //     })
            // );
            if (data.messageData.email === lessonComponent.props.profile.email) {
                // something happened and the system reported me as disconnected - reloading the page
                // window.location.reload();
            } else {
                store.dispatch(userLeave(data.messageData.email));
                // store.dispatch(hideSpinner());
                audioCallUtils.cleanEmailState(data.messageData.email);
            }
        }
    });

    socket.on('classroomActions', (classData) => {
        // let classData = JSON.parse(data);
        // console.log('==== classroom action', classData);
        if (classData.messageType === 'RAISE_HAND') {
            store.dispatch(userRaiseHand(classData.messageData));
            if (classData.messageData.raisedHand) {
                store.dispatch(showInfo('RAISE_HAND', false, { name: classData.messageData.name }));
            } else {
                store.dispatch(showWarn('LOWER_HAND', false, { name: classData.messageData.name }));
            }
        }
        if (classData.messageType === 'MUTE_ACTION') {
            // console.log('=== mute action', classData.messageData);
            store.dispatch(userMute(classData.messageData));
        }
        if (classData.messageType === 'START_AUDIO_CALL') {
            // console.log('=== mute action', classData.messageData);
            if (store.getState().lessonState.callState !== CALL_STATE.CALL_STARTED) {
                store.dispatch(changeCallState(CALL_STATE.CAN_JOIN_CALL));
                store.dispatch(callInProgress(true));
            }
        }
        if (classData.messageType === 'LINEAGE_CHANGE') {
            lessonComponent.switchLineageFromRemote(classData.messageData.gridType);
        }
        if (classData.messageType === 'LEAVE_AUDIO_CALL') {
            // console.log('=== mute action', classData.messageData);
            if (store.getState().lessonState.callState === CALL_STATE.CALL_STARTED) {
                audioCallUtils.cleanEmailState(classData.messageData.email);
            }
        }
        if (classData.messageType === 'CHAT_MESSAGE') {
            if (window.pushMainMessages) {
                window.pushMainMessages(classData.messageData.chatMessages);
            }
        }
        if (classData.messageType === 'JOIN_AUDIO_CALL') {
            // console.log('=== join audion call', store.getState().lessonState.callState);
            if (store.getState().lessonState.callState === CALL_STATE.CALL_STARTED) {
                audioCallUtils.addJoinedUser(classData.messageData.email);
                socketUtils.sendJoinReponseAudioCall(audioCallUtils.getCurrentMicState(), classData.messageData.email);
                store.dispatch(userMute(classData.messageData));
            }
        }
        if (classData.messageType === 'END_AUDIO_CALL') {
            // console.log('=== join audion call', store.getState().lessonState.callState);
            audioCallUtils.hangUp();
            store.dispatch(userMute(false));
            if (boardManager.isAdmin) {
                store.dispatch(changeCallState(CALL_STATE.CAN_START_CALL));
            } else {
                store.dispatch(changeCallState(CALL_STATE.CALL_DISABLED));
            }
        }
        if (classData.messageType === 'JOIN_RESPONSE_AUDIO_CALL') {
            // console.log('=== mute action', classData.messageData);
            if (
                store.getState().lessonState.callState === CALL_STATE.CALL_STARTED &&
                classData.messageData.toEmail === lessonComponent.props.profile.email
            ) {
                // should be added if we revert fix
                // audioCallUtils.addDiscoveredUser(classData.messageData.email);
                store.dispatch(userMute(classData.messageData));
            }
        }
    });

    socket.on('connect_error', (error) => {
        console.error('connect-error ', error);
        store.dispatch(hideSpinner());
        store.dispatch(showError('ERROR_CONNECTING_SOCKET'));
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    });

    socket.on('connect_timeout', (timeout) => {
        console.error('connect_timeout ', timeout);
        store.dispatch(hideSpinner());
        store.dispatch(showError('ERROR_CONNECTING_SOCKET'));
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    });

    socket.on('error', (error) => {
        console.log('error ', error);
        store.dispatch(hideSpinner());
        store.dispatch(showError('ERROR_CONNECTING_SOCKET'));
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    });

    socket.on('disconnect', (disconnect) => {
        console.error('disconnect ', disconnect);
        if (disconnect !== 'io client disconnect') {
            store.dispatch(hideSpinner());
            store.dispatch(showError('ERROR_CONNECTING_SOCKET'));
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }
    });

    socket.on('reconnect', (reconnect) => {
        console.error('reconnect ', reconnect);
    });

    socket.on('reconnect_attempt', (reconnect_attempt) => {
        console.error('reconnect_attempt ', reconnect_attempt);
        // socket.io.opts.transports = ['polling', 'websocket'];
    });

    socket.on('reconnecting', (reconnecting) => {
        console.error('reconnecting ', reconnecting);
    });
    socket.on('reconnect_error', (reconnect_error) => {
        console.error('reconnect_error ', reconnect_error);
    });

    // socket.on('ping', () => {
    //     // console.log('ping ');
    // });
};

socketUtils.updateUserDrawing = function(email, drawingEnabled) {
    socket.emit('adminAction', {
        lesson: classId,
        messageType: 'UPDATE_USER_DRAWING',
        messageData: {
            email,
            drawingEnabled,
        },
    });
};

socketUtils.updateUserMic = function(email, isMuted) {
    socket.emit('adminAction', {
        lesson: classId,
        messageType: 'UPDATE_USER_MIC',
        messageData: {
            email,
            isMuted,
        },
    });
};
socketUtils.updateMainGeometry = function(geometry) {
    socket.emit('adminAction', {
        lesson: classId,
        messageType: 'UPDATE_MAIN_GEOMETRY',
        messageData: geometry,
    });
};

socketUtils.sendMediaCandidate = function(candidate, email, desc) {
    socket.emit('mediaChannel', {
        lesson: classId,
        messageType: 'SEND_MEDIA_CANDIDATE',
        messageData: {
            candidate,
            desc: desc,
            email: email,
        },
    });
};

socketUtils.sendMediaDescription = function(lessonComponent, description, email) {
    socket.emit('mediaChannel', {
        lesson: classId,
        messageType: 'SEND_DESCRIPTION',
        messageData: {
            desc: description,
            email: email,
        },
    });
};

socketUtils.sendMediaIceCandidate = function(fromEmail, toEmail, ice) {
    socket.emit('mediaChannel', {
        lesson: classId,
        messageType: 'SEND_MEDIA_ICE_CANDIDATE',
        messageData: {
            ice: ice,
            fromEmail: fromEmail,
            toEmail: toEmail,
        },
    });
};

socketUtils.sendSDPToRemote = function(toEmail, fromEmail, description) {
    socket.emit('mediaChannel', {
        lesson: classId,
        messageType: 'SEND_MEDIA_SDP',
        messageData: {
            description: description,
            fromEmail: fromEmail,
            toEmail: toEmail,
        },
    });
};

socketUtils.sendOfferSDPToRemote = function(toEmail, fromEmail, description) {
    socket.emit('mediaChannel', {
        lesson: classId,
        messageType: 'SEND_OFFER_MEDIA_SDP',
        messageData: {
            description: description,
            fromEmail: fromEmail,
            toEmail: toEmail,
        },
    });
};

socketUtils.sendAnswerSDPToRemote = function(toEmail, fromEmail, description) {
    socket.emit('mediaChannel', {
        lesson: classId,
        messageType: 'SEND_ANSWER_MEDIA_SDP',
        messageData: {
            description: description,
            fromEmail: fromEmail,
            toEmail: toEmail,
        },
    });
};

socketUtils.sendImageData = function(width, height, data, force, lineage = LINEAGE_NONE, sticky, boardId) {
    if (!socket) {
        return;
    }
    socket.emit('sendImageData', {
        lesson: classId,
        drawing: {
            tool: 'IMAGE_PUSH',
            width: width,
            height: height,
            data: data,
            boardId,
            force,
            lineage,
            stickyElements: sticky,
        },
    });
};

socketUtils.changeMainBoardState = function(mainBoardId, mainBoardPages) {
    if (!socket) {
        return;
    }
    socket.emit('changeMainBoardState', { mainBoardId, mainBoardPages });
};

socketUtils.changeIndividualBoardStateByUser = function(individualBoardState) {
    if (!socket) {
        return;
    }
    socket.emit('changeIndividualBoardState', individualBoardState);
};

socketUtils.sendJoinImageData = function(mainBoardId, mainBoardPages, emails) {
    // console.log(
    //     '==== sending join image data for ',
    //     data,
    //     emails,
    //     (lineage = LINEAGE_NONE),
    //     stickyElements,
    //     boardId,
    //     multiBoardOriginalData,
    //     pages
    // );
    // console.trace();
    if (emails.length === 0) {
        return;
    }
    if (!socket) return;
    socket.emit('sendJoinData', {
        lesson: classId,
        lessonState: {
            mainBoardId,
            mainBoardPages,
        },
        emails,
        fromEmail: lessonComponent.props.profile.email,
    });
};

socketUtils.sendElementOp = function(element, op) {
    if (!socket) {
        return;
    }
    socket.emit('sendElementOp', {
        lesson: classId,
        drawing: {
            tool: 'ELEMENT_OP',
            operation: op,
            element,
        },
    });
};

socketUtils.sendTargetedElementOp = function(emails, element, op) {
    // console.log('==== sending join image data for ', emails);
    if (emails.length === 0) {
        return;
    }
    socket.emit('sendTargetedImage', {
        lesson: classId,
        drawing: {
            tool: 'ELEMENT_OP',
            operation: op,
            element,
        },
        emails,
        fromEmail: lessonComponent.props.profile.email,
    });
};

socketUtils.sendTargetedRequest = function(emails, request, data) {
    if (emails.length === 0) {
        return;
    }
    if (!socket) {
        return;
    }
    socket.emit('sendTargetedRequest', {
        lesson: classId,
        request,
        data,
        emails,
        fromEmail: lessonComponent.props.profile.email,
    });
};

socketUtils.sendGetLocalImage = function(payload) {
    socket.emit('getLocalImage', payload);
};

socketUtils.sendDraw = function(drawing) {
    // console.log('=== should send draw', boardManager.lessonState.boardType);
    if (!socket) {
        return;
    }
    if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
        socket.emit('draw', {
            lesson: classId,
            drawing: drawing,
        });
    } else {
        let lessonState = store.getState().lessonState;
        const adminEmail = lessonState.lessonInfo ? lessonState.lessonInfo.classData.adminEmail : null;
        const isAdmin = lessonState.isAdmin;

        if (!isAdmin && adminEmail) {
            socket.emit('targetedDraw', {
                lesson: classId,
                drawing: drawing,
                target: adminEmail,
                source: lessonComponent.props.profile.email,
            });
        } else if (boardManager.getCurrentTarget()) {
            // console.log('==== should be sending targeted to', boardManager.getCurrentTarget());
            socket.emit('targetedDraw', {
                lesson: classId,
                drawing: drawing,
                target: boardManager.getCurrentTarget(),
                source: lessonComponent.props.profile.email,
            });
        }
    }
};

socketUtils.sendJoinFeedback = function(target) {
    let currentPageNo = -1;
    if (boardManager.lessonState.boardType === BOARD_TYPE.MULTI_BOARD && !boardManager.isAdmin) {
        currentPageNo = boardManager.lessonState.individualBoards[boardManager.adminEmail].currentPageNo;
    }
    socket.emit('discoverChannel', {
        lesson: classId,
        messageType: 'USER_ONLINE',
        messageData: {
            profile: { ...lessonComponent.props.profile, socketId: socket.id, online: true },
            isAdmin: boardManager.isAdmin,
            currentPageNo,
            chatMessages: window.getMainMessages ? window.getMainMessages(lessonComponent.props.profile.email) : [],
        },
        target,
    });
};

socketUtils.sendRaisedHandValue = function(value) {
    socket.emit('classroomActions', {
        lesson: classId,
        messageType: 'RAISE_HAND',
        messageData: {
            email: lessonComponent.props.profile.email,
            raisedHand: value,
            name: lessonComponent.props.profile.name,
        },
    });
};

socketUtils.sendMutedValue = function(value) {
    socket.emit('classroomActions', {
        lesson: classId,
        messageType: 'MUTE_ACTION',
        messageData: {
            email: lessonComponent.props.profile.email,
            isMuted: value,
            name: lessonComponent.props.profile.name,
        },
    });
};

socketUtils.sendStartAudioCall = function(value) {
    // console.log('=== sending start call');
    socket.emit('classroomActions', {
        lesson: classId,
        messageType: 'START_AUDIO_CALL',
        messageData: {
            email: lessonComponent.props.profile.email,
            isMuted: value,
            name: lessonComponent.props.profile.name,
        },
    });
};

socketUtils.sendLineageChange = function(value) {
    // console.log('=== sending start call');
    socket.emit('classroomActions', {
        lesson: classId,
        messageType: 'LINEAGE_CHANGE',
        messageData: {
            email: lessonComponent.props.profile.email,
            gridType: value,
        },
    });
};

socketUtils.sendEndAudioCall = function() {
    socket.emit('classroomActions', {
        lesson: classId,
        messageType: 'END_AUDIO_CALL',
        messageData: {
            email: lessonComponent.props.profile.email,
        },
    });
};

socketUtils.sendLeaveAudioCall = function() {
    socket.emit('classroomActions', {
        lesson: classId,
        messageType: 'LEAVE_AUDIO_CALL',
        messageData: {
            email: lessonComponent.props.profile.email,
        },
    });
};

socketUtils.sendChatMessages = function(value) {
    socket.emit('classroomActions', {
        lesson: classId,
        messageType: 'CHAT_MESSAGE',
        messageData: {
            email: lessonComponent.props.profile.email,
            chatMessages: value,
        },
    });
};

socketUtils.sendUserJoin = function(profile) {
    socket.emit('userJoin', profile);
};

socketUtils.switchBoardType = function(value, id, boardData, pausedBoard = null, canMultiple = false) {
    socket.emit('adminAction', {
        lesson: classId,
        messageType: 'SWITCH_BOARD_TYPE',
        messageData: {
            email: lessonComponent.props.profile.email,
            boardType: value,
            boardId: id,
            boardData,
            pausedBoard,
            canMultiple,
        },
    });
};

socketUtils.sendJoinAudioCall = function(value) {
    // console.log('should be joining audio call');
    socket.emit('classroomActions', {
        lesson: classId,
        messageType: 'JOIN_AUDIO_CALL',
        messageData: {
            email: lessonComponent.props.profile.email,
            isMuted: value,
            name: lessonComponent.props.profile.name,
        },
    });
};

socketUtils.sendJoinReponseAudioCall = function(value, toEmail) {
    socket.emit('classroomActions', {
        lesson: classId,
        messageType: 'JOIN_RESPONSE_AUDIO_CALL',
        messageData: {
            email: lessonComponent.props.profile.email,
            isMuted: value,
            name: lessonComponent.props.profile.name,
            toEmail,
        },
    });
};

socketUtils.socketClose = function() {
    if (socket) {
        console.log('closing socket');
        socket.close();
    } else {
        console.log('socket not initiated');
    }
    socket = null;
};

socketUtils.shouldNotContinueProcessing = function(email, socketId) {
    let currentState = store.getState();
    return currentState.profile.email === email;
    // if (!currentState.lessonState.isStarted) {
    //     return true;
    // }
    // if (currentState.profile.email === email) {
    //     if (currentState.lessonState.mySocketId === socketId) {
    //         return true;
    //     } else {
    //         // TODO = something went wrong and I need to leave the lesson
    //         console.error('========== should exit', currentState.lessonState);
    //         lessonComponent.props.history.push('/');
    //         store.dispatch(hideSpinner());
    //         store.dispatch(showError('LOGGED_IN_FROM_DIFFERENT_DEVICE'));
    //         return true;
    //     }
    // }
    // let members = currentState.lessonState.members;
    // // console.log('===== members', members);
    // for (let i = 0; i < members.length; i++) {
    //     if (members[i].email === email && members[i].socketId === socketId && members[i].online) {
    //         // console.log('found member', members[i]);
    //         return true;
    //     }
    // }
    // return false;
};

socketUtils.shouldNotContinueProcessingForDisconnect = function(email, socketId) {
    let currentState = store.getState();
    if (currentState.profile.email === email) {
        // console.log('=== same? ', currentState.profile.email, email);
        return true;
    }
    let members = currentState.lessonState.members;
    // console.log('===== members', JSON.stringify(members), email, socketId);
    for (let i = 0; i < members.length; i++) {
        if (members[i].email === email && (members[i].socketId === socketId || !members[i].socketId)) {
            // console.log('found member', members[i]);
            return false;
        }
    }
    return true;
};

export default socketUtils;
