import React from 'react';
import Container from '@material-ui/core/Container';
import { Grid } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { composePriceText, composePriceTextForFree } from '../../common/features';
import { COLORS2 } from '../../constants';

const PriceRender = ({ intl, pricing, subscriptionType, billAnnual, billEur, isActive, profile, isDesktop }) => {
    if (!pricing || !subscriptionType) {
        return null;
    }

    let isLoggedIn = profile.loggedIn;

    let priceTextForFree = composePriceTextForFree(intl, billEur, isActive, isLoggedIn, isDesktop);
    if (!pricing[subscriptionType]) {
        return (
            <Grid container direction="row" justify="center" alignItems="center" style={{ minHeight: '4rem' }}>
                {priceTextForFree}
            </Grid>
        );
    }

    let priceText = composePriceText(intl, pricing, subscriptionType, billAnnual, billEur, isActive, isLoggedIn, isDesktop);
    if (!priceText) {
        return null;
    }

    return (
        <Grid container direction="row" justify="center" alignItems="center" style={{ minHeight: '4rem' }}>
            <Container fixed style={{ fontSize: '1.375rem', color: COLORS2.blackRussian, fontWeight: 'bold' }}>
                {priceText}
            </Container>
        </Grid>
    );
};

const mapStateToProps = (state) => {
    const { profile } = state;
    return { profile };
};

export default injectIntl(connect(mapStateToProps)(PriceRender));
