import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function RectangleIcon(props) {
    return (
        <SvgIcon {...props} style={{ ...props.style, verticalAlign: 'bottom' }}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                viewBox="0 0 24 24"
                xmlSpace="preserve"
            >
                <path d="M23.5 4.5H.5A.5.5 0 0 0 0 5v14a.5.5 0 0 0 .5.5h23a.5.5 0 0 0 .5-.5V5a.5.5 0 0 0-.5-.5zm-.5 14H1v-13h22v13z" />
            </svg>{' '}
        </SvgIcon>
    );
}
