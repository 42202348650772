import React, { useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage, injectIntl } from 'react-intl';
import classApi from '../../Api/classApi';
import joinRequestApi from '../../Api/joinRequestApi';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import GenericState from '../../MyComponents/GenericLoadingErrorState/GenericState';
import { JOIN_REQUEST_STATUS } from '../../shared/JoinRequestStatus';

const useStyles = makeStyles((theme) => ({
    toolbar: theme.mixins.toolbar,
}));

const JoinRequest = (props) => {
    let history = useHistory();
    const classes = useStyles();
    const [canJoin] = React.useState(false);
    const [joinInfo, setJoinInfo] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);
    const [isError, setIsError] = React.useState(false);

    useEffect(() => {
        classApi
            .canIJoin(props.match.params.classId)
            .then((res) => {
                if (res.data.canIJoin) {
                    lessonJoin();
                    return;
                }
                if (res.data.joinRequest) {
                    console.log(res.data);
                    setJoinInfo(res.data.joinRequest);
                } else {
                    sendJoin();
                }
                setIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setIsLoading(false);
                setIsError(true);
            });
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            classApi
                .canIJoin(props.match.params.classId)
                .then((res) => {
                    if (res.data.canIJoin) {
                        lessonJoin();
                        return;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }, 5000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    let lessonJoin = function () {
        history.push('/lesson/' + props.match.params.classId);
    };

    let sendJoin = function () {
        setIsLoading(true);
        joinRequestApi
            .joinClass(props.match.params.classId)
            .then((res) => {
                setIsLoading(false);
                if (res.data) {
                    setJoinInfo(res.data);
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
                setIsError(true);
            });
    };

    let sendCancel = function () {
        setIsLoading(true);
        joinRequestApi
            .cancelJoinClass(props.match.params.classId)
            .then((res) => {
                setIsLoading(false);
                history.push('/');
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
                setIsError(true);
            });
    };

    const renderState = () => {
        if (canJoin) {
            return (
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <Typography variant="h4" gutterBottom>
                            <FormattedMessage id={'joinRequest.alreadyJoined'} />
                        </Typography>
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: 'center' }}>
                        <Button size="large" variant="contained" color="primary" onClick={lessonJoin}>
                            <FormattedMessage id={'joinRequest.joinLesson'} />
                        </Button>
                    </Grid>
                </Grid>
            );
        }
        if (joinInfo) {
            switch (joinInfo.status) {
                case JOIN_REQUEST_STATUS.OPEN:
                    return (
                        <Grid container spacing={3}>
                            <Grid item xs={8}>
                                <Typography variant="h4" gutterBottom>
                                    <FormattedMessage id={'joinRequest.requestPending'} />
                                </Typography>
                            </Grid>
                            <Grid item xs={4} style={{ textAlign: 'center' }}>
                                <Button size="large" variant="contained" color="primary" onClick={sendCancel}>
                                    <FormattedMessage id={'joinRequest.cancelRequest'} />
                                </Button>
                            </Grid>
                        </Grid>
                    );
                case JOIN_REQUEST_STATUS.BLOCKED:
                    return (
                        <Grid container spacing={3}>
                            <Grid item xs={10}>
                                <Typography variant="h4" gutterBottom>
                                    <FormattedMessage id={'joinRequest.requestPending'} />
                                </Typography>
                            </Grid>
                        </Grid>
                    );
                case JOIN_REQUEST_STATUS.ACCEPTED:
                    return (
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <Typography variant="h4" gutterBottom>
                                    <FormattedMessage id={'joinRequest.alreadyJoined'} />
                                </Typography>
                            </Grid>
                            <Grid item xs={4} style={{ textAlign: 'center' }}>
                                <Button size="large" variant="contained" color="primary" onClick={lessonJoin}>
                                    <FormattedMessage id={'joinRequest.joinLesson'} />
                                </Button>
                            </Grid>
                        </Grid>
                    );
                case JOIN_REQUEST_STATUS.REJECTED:
                    return (
                        <Grid container spacing={3}>
                            <Grid item xs={8}>
                                <Typography variant="h4" gutterBottom>
                                    <FormattedMessage id={'joinRequest.requestRejected'} />
                                </Typography>
                            </Grid>
                            <Grid item xs={4} style={{ textAlign: 'center' }}>
                                <Button size="large" variant="contained" color="primary" onClick={lessonJoin}>
                                    <FormattedMessage id={'joinRequest.joinLesson'} />
                                </Button>
                            </Grid>
                        </Grid>
                    );
            }
        }
        return (
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    <Typography variant="h4" gutterBottom>
                        <FormattedMessage id={'joinRequest.notPartOfGroup'} />
                    </Typography>
                </Grid>
                <Grid item xs={4} style={{ textAlign: 'center' }}>
                    <Button size="large" variant="contained" color="primary" onClick={sendJoin}>
                        <FormattedMessage id={'joinRequest.requestJoin'} />
                    </Button>
                </Grid>
            </Grid>
        );
    };

    if (isLoading) {
        return <GenericState isLoading={true} />;
    }
    if (isError) {
        return <GenericState isError={true} />;
    }

    return (
        <React.Fragment>
            <CssBaseline />
            <div className={classes.toolbar} />
            <Container style={{paddingTop: '2rem'}}>{renderState()}</Container>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    const { profile } = state;
    return { profile };
};

export default injectIntl(connect(mapStateToProps)(JoinRequest));
