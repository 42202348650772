import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function UKIcon(props) {
    return (
        <SvgIcon {...props} style={{ verticalAlign: 'bottom' }}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 48 48"
                version="1.1"
            >
                <g id="surface1">
                    <path d="M2 10h44v28H2z" fill="#3F51B5" />
                    <path d="M2 14.215l20.809 12.719 3.128-5.118L6.61 10H2z" fill="#FFF" />
                    <path d="M46 10h-3.61L23.063 21.813l3.125 5.12L46 14.825z" fill="#FFF" />
                    <path d="M26.188 20.27l-3.125 5.12L43.69 38H46v-5.621z" fill="#FFF" />
                    <path d="M2 32.992V38h3.309l20.629-12.61-3.125-5.12z" fill="#FFF" />
                    <path d="M2 20h44v8H2z" fill="#FFF" />
                    <path d="M20 10h8v28h-8z" fill="#FFF" />
                    <path d="M17.219 20L2 10.7v2.343L13.383 20z" fill="#E53935" />
                    <path d="M44.309 10L27.945 20h3.836L46 11.312V10z" fill="#E53935" />
                    <path d="M33.082 28L46 35.895V33.55L36.918 28z" fill="#E53935" />
                    <path d="M15.918 28L2 36.508V38h1.39l16.364-10z" fill="#E53935" />
                    <path d="M2 22h44v4H2z" fill="#E53935" />
                    <path d="M22 10h4v28h-4z" fill="#E53935" />
                </g>
            </svg>
        </SvgIcon>
    );
}
