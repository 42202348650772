import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function MathConvertIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 150 150"
                preserveAspectRatio="xMidYMid meet"
                version="1.0"
            >
                <path
                    d="M 149.9375 71.015625 C 150 70.917969 150.007812 70.792969 149.949219 70.691406 C 149.894531 70.585938 149.785156 70.523438 149.667969 70.523438 L 141.140625 70.535156 C 141.027344 70.539062 140.917969 70.597656 140.863281 70.699219 L 129.914062 90.035156 C 129.855469 90.136719 129.75 90.199219 129.632812 90.199219 L 129.441406 90.199219 C 129.320312 90.199219 129.210938 90.132812 129.15625 90.023438 C 128.476562 88.695312 127.777344 87.390625 127.078125 86.152344 L 118.910156 70.675781 C 118.851562 70.566406 118.769531 70.515625 118.625 70.503906 L 109.601562 70.539062 C 109.484375 70.539062 109.378906 70.601562 109.320312 70.703125 C 109.265625 70.804688 109.269531 70.929688 109.328125 71.027344 L 123.976562 94.953125 C 124.246094 95.378906 124.230469 95.925781 123.933594 96.355469 L 108.683594 119.289062 C 108.617188 119.386719 108.613281 119.515625 108.667969 119.621094 C 108.722656 119.722656 108.832031 119.789062 108.949219 119.789062 L 108.953125 119.789062 L 117.847656 119.773438 C 117.964844 119.773438 118.070312 119.710938 118.125 119.609375 L 126.574219 104.429688 C 127.984375 102.105469 128.714844 100.890625 128.867188 100.609375 C 128.925781 100.507812 129.03125 100.445312 129.148438 100.445312 L 129.34375 100.445312 C 129.457031 100.445312 129.566406 100.507812 129.621094 100.613281 C 130.464844 102.140625 131.195312 103.410156 131.785156 104.371094 L 140.113281 119.65625 C 140.167969 119.757812 140.277344 119.824219 140.394531 119.824219 L 149.402344 119.773438 C 149.519531 119.773438 149.625 119.707031 149.679688 119.605469 C 149.738281 119.5 149.730469 119.375 149.667969 119.277344 L 134.867188 96.132812 C 134.585938 95.71875 134.59375 95.171875 134.882812 94.734375 L 149.9375 71.015625 "
                    fillOpacity="1"
                    fillRule="nonzero"
                />
                <g clipPath="url(#e6e4c56412)">
                    <path
                        d="M 59.5 81.976562 L 72.296875 119.449219 C 73.09375 121.777344 75.34375 123.3125 77.789062 123.21875 C 80.230469 123.125 82.359375 121.398438 82.964844 119.027344 L 103.640625 44.257812 C 103.8125 43.585938 104.429688 43.105469 105.136719 43.105469 L 146.453125 43.105469 L 146.453125 33.222656 L 99.613281 33.222656 C 97.070312 33.222656 94.851562 34.949219 94.21875 37.417969 L 78.085938 96.996094 C 77.914062 97.683594 77.308594 98.152344 76.589844 98.152344 C 75.917969 98.152344 75.34375 97.738281 75.125 97.101562 L 67.574219 71.773438 C 67.253906 70.84375 66.800781 70.066406 66.234375 69.496094 C 65.15625 68.414062 63.738281 67.816406 62.242188 67.816406 C 61.445312 67.816406 60.679688 67.980469 59.957031 68.308594 L 42.785156 76.050781 L 47.339844 85.601562 L 57.402344 81.0625 C 57.601562 80.972656 57.816406 80.925781 58.035156 80.925781 C 58.699219 80.925781 59.289062 81.347656 59.5 81.976562 "
                        fillOpacity="1"
                        fillRule="nonzero"
                    />
                </g>
                <g clipPath="url(#c8cca65ec9)">
                    <path
                        d="M 4.335938 141.542969 C 4.417969 141.660156 4.472656 141.730469 4.484375 141.753906 C 4.558594 141.859375 4.636719 141.96875 4.71875 142.070312 C 8.535156 147.035156 14.445312 150 20.53125 150 C 22.65625 150 24.753906 149.644531 26.757812 148.949219 C 33.691406 146.539062 38.453125 140.714844 39.824219 132.972656 C 41.089844 125.8125 39.648438 103.511719 37.128906 67.628906 C 35.789062 48.496094 34.523438 30.492188 34.351562 20.46875 C 34.328125 19.125 34.605469 17.890625 35.15625 16.894531 C 37.03125 13.503906 39.769531 13 41.234375 13 C 41.363281 13 41.480469 13.003906 41.589844 13.007812 C 44.699219 13.167969 47.488281 15.433594 49.046875 19.078125 C 50.070312 21.472656 52.414062 23.019531 55.011719 23.019531 C 55.890625 23.019531 56.75 22.84375 57.5625 22.492188 C 60.851562 21.082031 62.378906 17.253906 60.972656 13.957031 C 57.46875 5.773438 50.292969 0.433594 42.242188 0.0273438 C 34.585938 -0.371094 27.644531 3.65625 23.808594 10.59375 C 22.15625 13.582031 21.316406 17.074219 21.378906 20.691406 C 21.558594 31.089844 22.835938 49.28125 24.1875 68.539062 C 25.851562 92.199219 28.128906 124.605469 27.046875 130.707031 C 26.367188 134.566406 24.203125 136.078125 22.507812 136.667969 C 21.890625 136.882812 21.238281 136.992188 20.566406 136.992188 C 18.539062 136.992188 16.511719 135.996094 15.140625 134.324219 L 15.066406 134.222656 C 14.703125 133.6875 12.863281 130.9375 11.933594 129.367188 C 10.769531 127.402344 8.628906 126.183594 6.347656 126.183594 C 5.191406 126.183594 4.046875 126.496094 3.042969 127.09375 C 0.0898438 128.851562 -0.84375 132.640625 0.832031 136.101562 C 1.285156 137.035156 2.257812 138.742188 4.335938 141.542969 "
                        fillOpacity="1"
                        fillRule="nonzero"
                    />
                </g>
            </svg>
        </SvgIcon>
    );
}
