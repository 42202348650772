import boardManager from './boardManager';
import { BOARD_TYPE, STICKY_ELEMENTS_TYPE } from '../../constants';
import {
    LINEAGE_DICATANDO,
    LINEAGE_MATH,
    LINEAGE_MUSIC,
    LINEAGE_NONE,
    LINEAGE_TYPE_1,
    LINEAGE_TYPE_2,
    LINEAGE_MM,
} from './drawConstants';
import { getItem } from './lessonList';
import { addImageToWindowCache, getImageFromWindowCache, getUrlFromWindowCache, sleep } from '../../common/utils';
import axios from 'axios';
// import fitCurve from 'fit-curve';
import { Canvg } from 'canvg';
import { Buffer } from 'buffer';
import moveUtils from './moveUtils';

let drawUtils = {};

const CANVAS_WIDTH = 18;
const CANVAS_HEIGHT = 9;

const CANVAS_ACTUAL_WIDTH = 1440;
const CANVAS_ACTUAL_HEIGHT = 720;

// const CANVAS_ACTUAL_WIDTH = 1920;
// const CANVAS_ACTUAL_HEIGHT = 960;

const GRID_COLOR = '#ccc';
const GRID_WIDTH = 1;
const BEZIER_INTERVAL_DIVIDER = 2;

drawUtils.CANVAS_ACTUAL_WIDTH = CANVAS_ACTUAL_WIDTH;
drawUtils.CANVAS_ACTUAL_HEIGHT = CANVAS_ACTUAL_HEIGHT;

const IMAGE_LOAD_CACHING_SLEEP_MILIS = 100;

let inputExpanderDebouncer = null;
let inputMoverDebouncer = null;

// const ASPECT_RATIO = CANVAS_WIDTH / CANVAS_HEIGHT;

let resizeDebouncer = null;
// let fullQualityImageSnapshot = null;

// let computedValues = null;
let computedToolPicker = null;

let resizeChecker = {
    height: 0,
    width: 0,
    interval: null,
    lessonComponent: null,
};

drawUtils.computeHeightWidth = function() {
    // console.log("==== canvas cont ", document.getElementById("canvasContainer").getBoundingClientRect());
    // let containerRect = document.getElementById('canvasContainer').getBoundingClientRect();
    // if (computedValues && !force) {
    //     console.log('==== computed values', computedValues);
    //     return computedValues;
    // }

    if (!document.getElementById('verticalSlider')) return null;

    let verticalSliderRect = document.getElementById('verticalSlider').getBoundingClientRect();
    let toolpickerContainerRect = computedToolPicker
        ? computedToolPicker
        : document.getElementById('toolpickerContainer').getBoundingClientRect();
    let horizontalSliderRect = document.getElementById('horizontalSlider').getBoundingClientRect();

    // console.log(
    //     '==== computation values width:',
    //     window.innerWidth,
    //     verticalSliderRect.width,
    //     toolpickerContainerRect.width
    // );
    // console.log('==== computation values height:', window.innerHeight, horizontalSliderRect.height);

    let availableWidth = window.innerWidth - verticalSliderRect.width - toolpickerContainerRect.width;
    let availableHeight = (window.innerHeight - horizontalSliderRect.height) * 0.95;

    let height;
    let width;

    if (availableWidth / CANVAS_WIDTH > availableHeight / CANVAS_HEIGHT) {
        // wider
        height = availableHeight;
        width = (height * CANVAS_WIDTH) / CANVAS_HEIGHT;
    } else {
        // higher
        width = availableWidth;
        height = (width * CANVAS_HEIGHT) / CANVAS_WIDTH;
    }

    // console.log('computed', width, height);
    if (toolpickerContainerRect.width > 0) {
        // computedValues = { height, width };
        computedToolPicker = toolpickerContainerRect;
    }
    // console.log('==== computed values', { height, width });

    return { height, width };
};
// drawUtils.computeHeightWidth = function () {
//     // console.log("==== canvas cont ", document.getElementById("canvasContainer").getBoundingClientRect());
//     let containerRect = document.getElementById("canvasContainer").getBoundingClientRect();
//
//     let availableWidth = containerRect.width * 0.80;
//     let availableHeight = window.innerHeight * 0.70;
//
//     let height;
//     let width;
//     // aspectRatio : 16/9
//
//     if (availableWidth/16 > availableHeight/9) {
//         // wider
//         height = availableHeight;
//         width = height * 16 / 9
//     } else {
//         // higher
//         width = availableWidth;
//         height = width * 9 /16;
//     }
//
//     // let width = containerRect.width * 0.83;
//     // let height = width * 0.6;
//     //
//     // if (height > window.innerHeight) {
//     //     height = window.innerHeight * 0.83;
//     //     width = height * 1.4;
//     // }
//
//     return {height, width};
// };

drawUtils.copyTouch = function({ identifier, pageX, pageY }) {
    return { identifier, pageX, pageY };
};

drawUtils.computeX = function(x, remoteWidth) {
    let localData = drawUtils.getCanvas();
    return (x * localData.width) / remoteWidth;
};

drawUtils.computeY = function(y, remoteHeight) {
    let localData = drawUtils.getCanvas();
    return (y * localData.height) / remoteHeight;
};

// drawUtils.scaleDown = function (startPoint, x1, y1, x2, y2) {
//     if (!drawUtils.isZoomed()) {
//         return { x1: Math.round(x1), y1: Math.round(y1), x2: Math.round(x2), y2: Math.round(y2) };
//     } else {
//         return {
//             x1: Math.round(x1),
//             y1: Math.round(y1),
//             x2: Math.round(startPoint.x + x2 / (window.zoomState.zoomLevel + 1)),
//             y2: Math.round(startPoint.y + y2 / (window.zoomState.zoomLevel + 1)),
//         };
//     }
// };

drawUtils.scalePoint = function(startPoint, x1, y1) {
    // let canvas = document.getElementById('canvas');
    // let canvasRect = drawUtils.getCurrentCanvasRect();
    // if (!drawUtils.isZoomed()) {
    //     let xx1, yy1;
    //     xx1 = Math.round((x1 * canvas.width) / canvasRect.width);
    //     yy1 = Math.round((y1 * canvas.height) / canvasRect.height);
    //     return { x: xx1, y: yy1 };
    // } else {
    //     let zoom = window.zoomState.zoomLevel + 1;
    //     let xx1, yy1;
    //     xx1 = startPoint.x + Math.round((x1 * canvas.width) / zoom / canvasRect.width);
    //     yy1 = startPoint.y + Math.round((y1 * canvas.height) / zoom / canvasRect.height);
    //     return { x: xx1, y: yy1 };
    // }
    // let canvas = drawUtils.getCanvas();
    if (!drawUtils.getCanvasContainer()) return;
    let canvasRect = drawUtils.getCanvasContainer().getBoundingClientRect();
    let xx1, yy1;
    let decremental = (window.zoomState.zoomLevel * 50 + 100) / 100;
    xx1 = startPoint.x + Math.round((x1 * CANVAS_ACTUAL_WIDTH) / (canvasRect.width * decremental));
    yy1 = startPoint.y + Math.round((y1 * CANVAS_ACTUAL_HEIGHT) / (canvasRect.height * decremental));
    return { x: xx1, y: yy1 };
};

drawUtils.reverseScalePoint = function(startPoint, x1, y1, email) {
    if (!drawUtils.getCanvasContainer(email)) return;
    let canvasRect = drawUtils.getCanvasContainer(email).getBoundingClientRect();
    // console.log('==== rect for canvas container is', canvasRect);
    let xx1, yy1;
    let decremental = (window.zoomState.zoomLevel * 50 + 100) / 100;
    xx1 = Math.round(((x1 - startPoint.x) * (canvasRect.width * decremental)) / CANVAS_ACTUAL_WIDTH);
    yy1 = Math.round(((y1 - startPoint.y) * (canvasRect.height * decremental)) / CANVAS_ACTUAL_HEIGHT);
    return { x: xx1, y: yy1 };
};

drawUtils.computeFontPixel = function(toolWidth) {
    let canvas = drawUtils.getCanvas();
    let newHeight;
    if (canvas && canvas.height) {
        newHeight = canvas.height / 40;
    } else {
        newHeight = drawUtils.CANVAS_ACTUAL_HEIGHT / 40;
    }
    switch (toolWidth) {
        case 1:
            newHeight = newHeight * 1.4;
            break;
        case 2:
            newHeight = newHeight * 1.6;
            break;
        case 3:
            newHeight = newHeight * 1.8;
            break;
        case 4:
            newHeight = newHeight * 2.4;
            break;
        case 5:
            newHeight = newHeight * 3;
            break;
        default:
            break;
    }
    return Math.round(newHeight);
};

drawUtils.computeFontPixelForDisplay = function(toolWidth) {
    let canvas = drawUtils.getCurrentCanvasRect();
    let newHeight = 8;
    if (canvas && canvas.height) {
        newHeight = canvas.height / 40;
    }
    switch (toolWidth) {
        case 1:
            newHeight = newHeight * 1.4;
            break;
        case 2:
            newHeight = newHeight * 1.6;
            break;
        case 3:
            newHeight = newHeight * 1.8;
            break;
        case 4:
            newHeight = newHeight * 2.4;
            break;
        case 5:
            newHeight = newHeight * 3;
            break;
        default:
            break;
    }
    return Math.round(newHeight);
};

drawUtils.computeLineWidth = function(toolWidth) {
    // let canvas = drawUtils.getCanvas();
    let newWidth = 3;

    switch (toolWidth) {
        case 1:
            // newWidth = newWidth;
            break;
        case 2:
            newWidth = newWidth * 2;
            break;
        case 3:
            newWidth = newWidth * 3;
            break;
        case 4:
            newWidth = newWidth * 4;
            break;
        case 5:
            newWidth = newWidth * 6;
            break;
        default:
            break;
    }
    // console.log(newWidth);
    return Math.round(newWidth);
};

drawUtils.computeEraserWidth = function(toolWidth) {
    let canvas = drawUtils.getCanvas();
    let newWidth;
    if (canvas && canvas.height) {
        newWidth = canvas.height / 50;
    } else {
        newWidth = drawUtils.CANVAS_ACTUAL_HEIGHT / 50;
    }
    switch (toolWidth) {
        case 1:
            // newWidth = newWidth;
            break;
        case 2:
            newWidth = newWidth * 2;
            break;
        case 3:
            newWidth = newWidth * 3;
            break;
        case 4:
            newWidth = newWidth * 4;
            break;
        case 5:
            newWidth = newWidth * 5;
            break;
        default:
            break;
    }
    // console.log(newWidth);
    return Math.round(newWidth);
};

drawUtils.computeEraserWidthForDisplay = function(toolWidth) {
    let canvas = drawUtils.getCurrentCanvasRect();

    let newWidth = 1;
    if (canvas && canvas.height) {
        newWidth = canvas.height / 50;
    }
    switch (toolWidth) {
        case 1:
            // newWidth = newWidth;
            break;
        case 2:
            newWidth = newWidth * 2;
            break;
        case 3:
            newWidth = newWidth * 3;
            break;
        case 4:
            newWidth = newWidth * 4;
            break;
        case 5:
            newWidth = newWidth * 5;
            break;
        default:
            break;
    }
    // console.log(newWidth);
    let decremental = (window.zoomState.zoomLevel * 50 + 100) / 100;
    return Math.round(newWidth * decremental);
    // let zoom = window.zoomState.zoomLevel + 1;
    // if (!drawUtils.isZoomed()) {
    //     return Math.round(newWidth);
    // } else {
    //     return Math.round(newWidth) * zoom;
    // }
};

// drawUtils.scaleDown = function(startPoint, x1, y1, x2, y2) {
//     let scaleResult = {x1, y1};
//
//     if (x2 > startPoint.x) {
//         scaleResult.x2 = (startPoint.x + (x2-startPoint.x)/SCALE_FACTOR);
//     } else {
//         scaleResult.x2 = (startPoint.x - (startPoint.x-x2)/SCALE_FACTOR);
//     }
//     if (y2 > startPoint.y) {
//         scaleResult.y2 = (startPoint.y + (y2- startPoint.y)/SCALE_FACTOR);
//     } else {
//         scaleResult.y2 = (startPoint.y - (startPoint.y-y2)/SCALE_FACTOR);
//     }
//     return scaleResult;
// };

drawUtils.isInElement = function(event, element) {
    if (!element) return false;
    let myComponent = element.getBoundingClientRect();
    return (
        event.clientX > myComponent.left &&
        event.clientX < myComponent.right &&
        event.clientY < myComponent.bottom &&
        event.clientY > myComponent.top
    );
};

drawUtils.isOverCurrentCanvas = function(event, rect) {
    let myComponent = rect;
    if (!myComponent) {
        myComponent = drawUtils.getCurrentCanvasRect();
    }
    return (
        event.clientX > myComponent.left &&
        event.clientX < myComponent.right &&
        event.clientY < myComponent.bottom &&
        event.clientY > myComponent.top
    );
};

drawUtils.isOverElement = function(event, element) {
    if (!element || !element.getBoundingClientRect) {
        return false;
    }
    let myComponent = element.getBoundingClientRect();
    return (
        event.clientX > myComponent.left &&
        event.clientX < myComponent.right &&
        event.clientY < myComponent.bottom &&
        event.clientY > myComponent.top
    );
};

drawUtils.getCurrentCanvasRect = function(email) {
    let element;
    if (email) {
        element = drawUtils.getCanvasContainer(email);
    } else {
        element = drawUtils.getCanvasContainer();
    }

    // console.log('==== current canvas rect', element);
    if (!element) {
        return { width: 0, height: 0, top: 0, left: 0 };
    }
    return element.getBoundingClientRect();
};

drawUtils.showElementToMove = function(x, y, element) {
    element.style.position = 'absolute';
    element.style.display = 'block';
    element.style.left = x - element.width / 2 + 'px';
    element.style.top = y - element.height / 2 + 'px';
};

drawUtils.hideElement = function(element) {
    element.style.display = 'none';
};

drawUtils.triggerWindowCallbacks = function() {
    for (const key of Object.keys(window.zoomState.zoomCallbacks)) {
        window.zoomState.zoomCallbacks[key]();
    }
};

drawUtils.computePinchZoom = (touch11, touch12, touch21, touch22) => {
    // console.log('pinch computing', touch11, touch12, touch21, touch22);
    let xInitDiff = Math.abs(touch11.clientX - touch12.clientX);
    let yInitDiff = Math.abs(touch11.clientY - touch12.clientY);
    let xCurrDiff = Math.abs(touch21.clientX - touch22.clientX);
    let yCurrDiff = Math.abs(touch21.clientY - touch22.clientY);
    let maxXDiff = xCurrDiff - xInitDiff;
    let maxYDiff = yCurrDiff - yInitDiff;
    let totalDiff = (maxXDiff + maxYDiff) / 2;
    let zoomedCanvas = drawUtils.getCanvasContainer().getBoundingClientRect();
    if (zoomedCanvas.height === 0) {
        console.log('got no sizes', zoomedCanvas);
        return null;
    }

    let w;
    let h;
    w = zoomedCanvas.width;
    h = zoomedCanvas.height;
    let totalCanvasDiff = (w + h) / 2;
    let percentage = (totalDiff * 100) / totalCanvasDiff;
    // console.log('=== diff', totalDiff, totalCanvasDiff, percentage);

    return {
        percentage: percentage,
        initialMid: {
            x: Math.round((touch11.clientX + touch12.clientX) / 2),
            y: Math.round((touch11.clientY + touch12.clientY) / 2),
        },
        currentMid: {
            x: Math.round((touch21.clientX + touch22.clientX) / 2),
            y: Math.round((touch21.clientY + touch22.clientY) / 2),
        },
    };
};

drawUtils.isZoomed = function() {
    return window.zoomState.zoomLevel > 0;
};

drawUtils.getCanvas = function(target) {
    return boardManager.getCurrentCanvas(target);
};

drawUtils.logImage = (data) => {
    let element = document.createElement('img');
    element.src = data;
    element.style.height = '100%';
    element.style.border = 'solid';
    element.style.overflow = 'scroll';
    document.getElementById('imgLog').appendChild(element);
};

drawUtils.getCanvasContainer = function(email) {
    return boardManager.getCurrentCanvasContainer(email);
    // if (boardManager.getBoardType() === BOARD_TYPE.SINGLE_BOARD) {
    //     return document.getElementById('canvasContainer');
    // } else {
    //     if (boardManager.getCurrentCanvas)
    //     return document.getElementById('multiBoardContainer');
    // }
};

drawUtils.getZoomedCanvas = function() {
    return document.getElementById('canvasZoom');
};

drawUtils.getVerticalSlider = function() {
    return document.getElementById('verticalSlider');
};
drawUtils.getVerticalSliderHeight = function() {
    return document.getElementById('verticalSliderHeight');
};
drawUtils.getToolPicker = function() {
    return document.getElementById('toolpickerContainer');
};

drawUtils.getHorizontalSlider = function() {
    return document.getElementById('horizontalSlider');
};

drawUtils.hideSliders = function() {
    if (drawUtils.getVerticalSlider()) {
        drawUtils.getVerticalSlider().style.visibility = 'hidden';
        drawUtils.getHorizontalSlider().style.visibility = 'hidden';
    }
};

drawUtils.showSliders = function() {
    if (drawUtils.getVerticalSlider()) {
        drawUtils.getVerticalSlider().style.visibility = 'visible';
        drawUtils.getHorizontalSlider().style.visibility = 'visible';
    }
};

drawUtils.computeSliders = function() {
    let computed = drawUtils.computeHeightWidth();
    // drawUtils.getHorizontalSlider().style.width = computed.width + 'px';
    if (drawUtils.getVerticalSliderHeight()) {
        drawUtils.getVerticalSliderHeight().style.height = computed.height - 72 + 'px';
    }
    if (drawUtils.getToolPicker()) {
        drawUtils.getToolPicker().style['max-height'] = computed.height + 'px';
    }
    // drawUtils.getToolPicker().style.width = "100%";
};

drawUtils.resetSlidersAndCanvases = function() {
    // drawUtils.getHorizontalSlider().style.width = '0px';
    // drawUtils.getVerticalSliderHeight().style.height = '0px';
    // drawUtils.getToolPicker().style['max-height'] = "0px";
    // drawUtils.getToolPicker().style.width = "0px";
    // drawUtils.getCanvasContainer().style.width = '0px';
    // drawUtils.getCanvasContainer().style.height = '0px';
    // drawUtils.getZoomedCanvas().style.width = '0px';
    // drawUtils.getZoomedCanvas().style.height = '0px';
};

drawUtils.computeCanvas = function(force = false) {
    let computed = drawUtils.computeHeightWidth(force);
    let canvasContainer = drawUtils.getCanvasContainer();
    if (!canvasContainer || !computed) {
        // console.log('no canvas to compute');
        return;
    }
    canvasContainer.style.width = '100%';
    // canvasContainer.style.height = 'auto';
    // canvasContainer.style.width = computed.width + 'px';
    // canvasContainer.style.height = computed.height + 'px';
    let rect = canvasContainer.getBoundingClientRect();
    let height = rect.width / 2 + 'px';
    canvasContainer.style.height = height;
    // console.log('=== computation', rect.width, height);
    rect = canvasContainer.getBoundingClientRect();
    // let mainCanv = document.getElementById('canvas');
    // if (mainCanv) {
    //     mainCanv.style.height = height;
    // }
    // let mainSvgCont = document.getElementById('main-svg-container');
    // if (mainSvgCont) {
    //     mainSvgCont.style.height = height;
    // }
    if (rect.width > computed.width) {
        height = computed.width / 2 + 'px';
        canvasContainer.style.width = computed.width + 'px';
        canvasContainer.style.height = height;
        // let mainCanv = document.getElementById('canvas');
        // if (mainCanv) {
        //     mainCanv.style.height = height;
        // }
        // let mainSvgCont = document.getElementById('main-svg-container');
        // if (mainSvgCont) {
        //     mainSvgCont.style.height = height;
        // }
    }
};
setInterval(() => {
    let canvasContainer = drawUtils.getCanvasContainer();
    if (canvasContainer) {
        let rect = canvasContainer.getBoundingClientRect();
        if (rect.width / 2 !== rect.height) {
            drawUtils.computeCanvas();
        }
    }
}, 2000);

drawUtils.computeMultiboardCanvas = function(force = false, email) {
    let computed = drawUtils.computeHeightWidth(force);
    let canvasContainer = drawUtils.getCanvasContainer(email);
    if (!canvasContainer || !computed) {
        // console.log('no canvas to compute');
        return;
    }
    canvasContainer.style.width = '100%';
    // canvasContainer.style.height = 'auto';
    // canvasContainer.style.width = computed.width + 'px';
    // canvasContainer.style.height = computed.height + 'px';
    let rect = canvasContainer.getBoundingClientRect();
    // canvasContainer.style.height = rect.width / 2 + 'px';
    // rect = canvasContainer.getBoundingClientRect();
    // console.log("=== shoud put height to ", )
    if (rect.width > computed.width && boardManager.currentBoardActive) {
        canvasContainer.style.width = computed.width + 'px';
        // canvasContainer.style.height = computed.width / 2 + 'px';
    }
};

drawUtils.buildLineageImage = function(lineage) {
    let canvas = document.createElement('canvas');
    canvas.width = drawUtils.CANVAS_ACTUAL_WIDTH;
    canvas.height = drawUtils.CANVAS_ACTUAL_HEIGHT;
    let ctx = canvas.getContext('2d');
    if (lineage === LINEAGE_MATH) {
        drawUtils.drawMathGrid(ctx);
    } else if (lineage === LINEAGE_TYPE_1) {
        drawUtils.drawTip1(ctx);
    }
    if (lineage === LINEAGE_TYPE_2) {
        drawUtils.drawTip2(ctx);
    }
    if (lineage === LINEAGE_DICATANDO) {
        drawUtils.drawDictando(ctx);
    }
    if (lineage === LINEAGE_MUSIC) {
        drawUtils.drawMusic(ctx);
    }
    if (lineage === LINEAGE_MM) {
        drawUtils.drawMM(ctx);
    }
    return canvas.toDataURL('png', 1.0);
};

drawUtils.buildComposedImageForMainPage = async function(email, classId, pageId, useCaching = false) {
    // let data = await getItem(pageId);
    // if (!data) return null;
    // let img = await drawUtils.buildImage(data);
    let url = getUrlFromWindowCache(email, classId, pageId, pageId);
    if (!url) return null;
    let img;
    if (useCaching) {
        img = getImageFromWindowCache(url);
        if (!img) {
            img = await drawUtils.buildImageFromUrl(url);
        }
    } else {
        img = await drawUtils.buildImageFromUrlNoCache(url);
    }
    if (!img) return null;

    let lineage = LINEAGE_NONE;

    let canvas = document.createElement('canvas');
    canvas.width = drawUtils.CANVAS_ACTUAL_WIDTH;
    canvas.height = drawUtils.CANVAS_ACTUAL_HEIGHT;

    let ctx = canvas.getContext('2d');

    let meta;
    boardManager.lessonState.mainBoardPages.forEach((p) => {
        if (p.id === pageId) {
            meta = p.meta;
        }
    });

    let stickyElements = [];
    if (meta) {
        stickyElements = meta.stickyElements;
        lineage = meta.gridType;
    }
    // console.log('=== meta', meta);

    if (stickyElements.length > 0) {
        for (let i = 0; i < stickyElements.length; i++) {
            if (stickyElements[i].stickyType === STICKY_ELEMENTS_TYPE.IMAGE) {
                // let stickyData = await lessonList.getStickyImage(email, classId, pageId, stickyElements[i].id);
                if (!stickyElements[i].url) continue;

                let stickyImg;
                stickyImg = getImageFromWindowCache(stickyElements[i].url);
                if (!stickyImg) {
                    stickyImg = await drawUtils.buildImageFromUrl(stickyElements[i].url);
                }
                // let stickyImg = await drawUtils.buildImageFromUrl(stickyElements[i].url);
                if (!stickyImg) continue;
                ctx.imageSmoothingEnabled = true;
                ctx.imageSmoothingQuality = 'high';
                ctx.drawImage(
                    stickyImg,
                    stickyElements[i].x,
                    stickyElements[i].y,
                    stickyElements[i].width,
                    stickyElements[i].height,
                );
            }
            if (stickyElements[i].stickyType === STICKY_ELEMENTS_TYPE.TEXT) {
                ctx.font = 'Arial';
                ctx.fillStyle = stickyElements[i].color;
                ctx.font = drawUtils.computeFontPixel(stickyElements[i].weight) + 'px Arial';
                // ctx.fillText(stickyElements[i].text, stickyElements[i].x, stickyElements[i].y, stickyElements[i].width);
                drawUtils.writeTextToDom(
                    ctx,
                    stickyElements[i].text,
                    stickyElements[i].x,
                    stickyElements[i].y,
                    stickyElements[i].width,
                    drawUtils.computeFontPixel(stickyElements[i].weight),
                );
            }
            if (stickyElements[i].stickyType === STICKY_ELEMENTS_TYPE.LATEX) {
                let svg = window.latexToImg(stickyElements[i].latex);
                svg.style.color = stickyElements[i].color;
                let img = await drawUtils.buildImgFromSvg(svg, stickyElements[i].width, stickyElements[i].height);
                if (!img) continue;
                let stickyImg = await drawUtils.buildImage(img);
                if (!stickyImg) continue;
                ctx.imageSmoothingEnabled = true;
                ctx.imageSmoothingQuality = 'high';
                ctx.drawImage(
                    stickyImg,
                    stickyElements[i].x,
                    stickyElements[i].y,
                    stickyElements[i].width,
                    stickyElements[i].height,
                );
            }
        }
    }

    ctx.imageSmoothingEnabled = true;
    ctx.imageSmoothingQuality = 'high';
    ctx.drawImage(img, 0, 0, CANVAS_ACTUAL_WIDTH, CANVAS_ACTUAL_HEIGHT);
    if (lineage === LINEAGE_MATH) {
        drawUtils.drawMathGrid(ctx);
    } else if (lineage === LINEAGE_TYPE_1) {
        drawUtils.drawTip1(ctx);
    }
    if (lineage === LINEAGE_TYPE_2) {
        drawUtils.drawTip2(ctx);
    }
    if (lineage === LINEAGE_DICATANDO) {
        drawUtils.drawDictando(ctx);
    }
    if (lineage === LINEAGE_MUSIC) {
        drawUtils.drawMusic(ctx);
    }
    if (lineage === LINEAGE_MM) {
        drawUtils.drawMM(ctx);
    }
    return canvas.toDataURL('png', 1.0);
};

drawUtils.buildComposedImageForStudentMainPage = async function(data, stickyElements, lineage) {
    if (!data) return null;
    let img = await drawUtils.buildImage(data);

    let canvas = document.createElement('canvas');
    canvas.width = drawUtils.CANVAS_ACTUAL_WIDTH;
    canvas.height = drawUtils.CANVAS_ACTUAL_HEIGHT;

    let ctx = canvas.getContext('2d');

    if (stickyElements.length > 0) {
        for (let i = 0; i < stickyElements.length; i++) {
            if (stickyElements[i].stickyType === STICKY_ELEMENTS_TYPE.IMAGE) {
                // let stickyData = await lessonList.getStickyImage(email, classId, pageId, stickyElements[i].id);
                if (!stickyElements[i].url) continue;
                let stickyImg = await drawUtils.buildImageFromUrl(stickyElements[i].url);
                if (!stickyImg) continue;
                ctx.imageSmoothingEnabled = true;
                ctx.imageSmoothingQuality = 'high';
                ctx.drawImage(
                    stickyImg,
                    stickyElements[i].x,
                    stickyElements[i].y,
                    stickyElements[i].width,
                    stickyElements[i].height,
                );
            }
            if (stickyElements[i].stickyType === STICKY_ELEMENTS_TYPE.TEXT) {
                ctx.font = 'Arial';
                ctx.fillStyle = stickyElements[i].color;
                ctx.font = drawUtils.computeFontPixel(stickyElements[i].weight) + 'px Arial';
                // ctx.fillText(stickyElements[i].text, stickyElements[i].x, stickyElements[i].y, stickyElements[i].width);
                drawUtils.writeTextToDom(
                    ctx,
                    stickyElements[i].text,
                    stickyElements[i].x,
                    stickyElements[i].y,
                    stickyElements[i].width,
                    drawUtils.computeFontPixel(stickyElements[i].weight),
                );
            }
            if (stickyElements[i].stickyType === STICKY_ELEMENTS_TYPE.LATEX) {
                let svg = window.latexToImg(stickyElements[i].latex);
                svg.style.color = stickyElements[i].color;
                let img = await drawUtils.buildImgFromSvg(svg, stickyElements[i].width, stickyElements[i].height);
                if (!img) continue;
                let stickyImg = await drawUtils.buildImage(img);
                if (!stickyImg) continue;
                ctx.imageSmoothingEnabled = true;
                ctx.imageSmoothingQuality = 'high';
                ctx.drawImage(
                    stickyImg,
                    stickyElements[i].x,
                    stickyElements[i].y,
                    stickyElements[i].width,
                    stickyElements[i].height,
                );
            }
        }
    }

    ctx.imageSmoothingEnabled = true;
    ctx.imageSmoothingQuality = 'high';
    ctx.drawImage(img, 0, 0, CANVAS_ACTUAL_WIDTH, CANVAS_ACTUAL_HEIGHT);
    if (lineage === LINEAGE_MATH) {
        drawUtils.drawMathGrid(ctx);
    } else if (lineage === LINEAGE_TYPE_1) {
        drawUtils.drawTip1(ctx);
    }
    if (lineage === LINEAGE_TYPE_2) {
        drawUtils.drawTip2(ctx);
    }
    if (lineage === LINEAGE_DICATANDO) {
        drawUtils.drawDictando(ctx);
    }
    if (lineage === LINEAGE_MUSIC) {
        drawUtils.drawMusic(ctx);
    }
    if (lineage === LINEAGE_MM) {
        drawUtils.drawMM(ctx);
    }
    return canvas.toDataURL('png', 1.0);
};

drawUtils.buildComposedImageFromData = async function(data, url) {
    if (!data) return null;
    let img = await drawUtils.buildImage(data);
    let urlImg;
    if (url) {
        urlImg = await drawUtils.buildImageFromUrlNoCache(url);
    }

    let canvas = document.createElement('canvas');
    canvas.width = drawUtils.CANVAS_ACTUAL_WIDTH;
    canvas.height = drawUtils.CANVAS_ACTUAL_HEIGHT;

    let ctx = canvas.getContext('2d');

    if (urlImg) {
        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = 'high';
        ctx.drawImage(urlImg, 0, 0, drawUtils.CANVAS_ACTUAL_WIDTH, drawUtils.CANVAS_ACTUAL_HEIGHT);
    }

    if (img) {
        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = 'high';
        ctx.drawImage(img, 0, 0, drawUtils.CANVAS_ACTUAL_WIDTH, drawUtils.CANVAS_ACTUAL_HEIGHT);
    }
    return canvas.toDataURL('png', 1.0);
};

async function addStickyElements(stickyElements, ctx) {
    if (stickyElements && stickyElements.length > 0) {
        for (let i = 0; i < stickyElements.length; i++) {
            if (stickyElements[i].stickyType === STICKY_ELEMENTS_TYPE.IMAGE) {
                // let stickyData = await lessonList.getStickyImage(email, classId, pageId, stickyElements[i].id);
                if (!stickyElements[i].url) continue;
                let stickyImg = await drawUtils.buildImageFromUrl(stickyElements[i].url);
                if (!stickyImg) continue;
                ctx.imageSmoothingEnabled = true;
                ctx.imageSmoothingQuality = 'high';

                ctx.drawImage(
                    stickyImg,
                    stickyElements[i].x,
                    stickyElements[i].y,
                    stickyElements[i].width,
                    stickyElements[i].height,
                );
            }
            if (stickyElements[i].stickyType === STICKY_ELEMENTS_TYPE.TEXT) {
                ctx.font = 'Arial';
                ctx.fillStyle = stickyElements[i].color;
                ctx.font = drawUtils.computeFontPixel(stickyElements[i].weight) + 'px Arial';
                // ctx.fillText(stickyElements[i].text, stickyElements[i].x, stickyElements[i].y, stickyElements[i].width);
                drawUtils.writeTextToDom(
                    ctx,
                    stickyElements[i].text,
                    stickyElements[i].x,
                    stickyElements[i].y,
                    stickyElements[i].width,
                    drawUtils.computeFontPixel(stickyElements[i].weight),
                );
            }
            if (stickyElements[i].stickyType === STICKY_ELEMENTS_TYPE.LATEX) {
                let svg = window.latexToImg(stickyElements[i].latex);
                svg.style.color = stickyElements[i].color;
                let img = await drawUtils.buildImgFromSvg(svg, stickyElements[i].width, stickyElements[i].height);
                if (!img) continue;
                let stickyImg = await drawUtils.buildImage(img);
                if (!stickyImg) continue;
                ctx.imageSmoothingEnabled = true;
                ctx.imageSmoothingQuality = 'high';
                ctx.drawImage(
                    stickyImg,
                    stickyElements[i].x,
                    stickyElements[i].y,
                    stickyElements[i].width,
                    stickyElements[i].height,
                );
            }
        }
    }
}

drawUtils.buildImgFromSvg = async function(svg, width, height) {
    const canvasElement = document.createElement('canvas');
    canvasElement.width = width;
    canvasElement.height = height;
    const divElem = document.createElement('div');
    const ctx = canvasElement.getContext('2d');
    divElem.appendChild(svg);
    let v = await Canvg.fromString(ctx, divElem.innerHTML);
    await v.render();

    return canvasElement.toDataURL('image/png', 1);
};

drawUtils.buildComposedImageForMultiBoard = async function(
    originalLineage,
    originalUrl,
    originalSticky,
    userSticky,
    elementId,
    url,
    canvasId,
) {
    let img;

    if (elementId) {
        let data;
        data = await getItem(elementId);
        if (!data) {
            console.log('==== failed to retrieve element', elementId);
            return null;
        }
        img = await drawUtils.buildImage(data);
    } else if (url) {
        img = await drawUtils.buildImageFromUrl(url);
    } else {
        if (!canvasId) {
            console.log('==== failed to retrieve element', canvasId);
            return null;
        }
        let tmpCnvs = document.getElementById(canvasId);
        if (!tmpCnvs) {
            console.log('==== failed to retrieve element', canvasId);
            return null;
        }
        let data = tmpCnvs.toDataURL('png', 1.0);
        img = await drawUtils.buildImage(data);
    }

    let canvas = document.createElement('canvas');
    canvas.width = drawUtils.CANVAS_ACTUAL_WIDTH;
    canvas.height = drawUtils.CANVAS_ACTUAL_HEIGHT;

    let ctx = canvas.getContext('2d');

    if (originalUrl) {
        let originalImg = await drawUtils.buildImageFromUrlNoCache(originalUrl);
        if (originalImg) {
            ctx.imageSmoothingEnabled = true;
            ctx.imageSmoothingQuality = 'high';

            ctx.drawImage(originalImg, 0, 0, drawUtils.CANVAS_ACTUAL_WIDTH, drawUtils.CANVAS_ACTUAL_HEIGHT);
        }
    }

    await addStickyElements(originalSticky, ctx);
    await addStickyElements(userSticky, ctx);

    ctx.imageSmoothingEnabled = true;
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(img, 0, 0, CANVAS_ACTUAL_WIDTH, CANVAS_ACTUAL_HEIGHT);
    if (originalLineage === LINEAGE_MATH) {
        drawUtils.drawMathGrid(ctx);
    } else if (originalLineage === LINEAGE_TYPE_1) {
        drawUtils.drawTip1(ctx);
    }
    if (originalLineage === LINEAGE_TYPE_2) {
        drawUtils.drawTip2(ctx);
    }
    if (originalLineage === LINEAGE_DICATANDO) {
        drawUtils.drawDictando(ctx);
    }
    if (originalLineage === LINEAGE_MUSIC) {
        drawUtils.drawMusic(ctx);
    }
    if (originalLineage === LINEAGE_MM) {
        drawUtils.drawMM(ctx);
    }
    return canvas.toDataURL('png', 1.0);
};

drawUtils.buildComplexComposedImage = async function(
    lineage,
    originalUrl,
    originalSticky,
    userSticky,
    elementUrl,
    elementId,
    useCaching = false,
) {
    let canvas = document.createElement('canvas');
    canvas.width = drawUtils.CANVAS_ACTUAL_WIDTH;
    canvas.height = drawUtils.CANVAS_ACTUAL_HEIGHT;

    let ctx = canvas.getContext('2d');

    if (lineage === LINEAGE_MATH) {
        drawUtils.drawMathGrid(ctx);
    } else if (lineage === LINEAGE_TYPE_1) {
        drawUtils.drawTip1(ctx);
    }
    if (lineage === LINEAGE_TYPE_2) {
        drawUtils.drawTip2(ctx);
    }
    if (lineage === LINEAGE_DICATANDO) {
        drawUtils.drawDictando(ctx);
    }
    if (lineage === LINEAGE_MUSIC) {
        drawUtils.drawMusic(ctx);
    }
    if (lineage === LINEAGE_MM) {
        drawUtils.drawMM(ctx);
    }
    ctx.globalCompositeOperation = 'source-over';

    if (originalSticky) {
        await addStickyElements(originalSticky, ctx);
    }
    if (userSticky) {
        await addStickyElements(userSticky, ctx);
    }

    if (originalUrl) {
        let originalImg;
        if (useCaching) {
            originalImg = await drawUtils.buildImageFromUrl(originalUrl);
        } else {
            originalImg = await drawUtils.buildImageFromUrlNoCache(originalUrl);
        }

        if (originalImg) {
            ctx.imageSmoothingEnabled = true;
            ctx.imageSmoothingQuality = 'high';

            ctx.drawImage(originalImg, 0, 0, drawUtils.CANVAS_ACTUAL_WIDTH, drawUtils.CANVAS_ACTUAL_HEIGHT);
        }
    }

    if (elementUrl) {
        let elementImg;
        if (useCaching) {
            elementImg = await drawUtils.buildImageFromUrl(elementUrl);
        } else {
            elementImg = await drawUtils.buildImageFromUrlNoCache(elementUrl);
        }
        if (elementImg) {
            ctx.imageSmoothingEnabled = true;
            ctx.imageSmoothingQuality = 'high';

            ctx.drawImage(elementImg, 0, 0, drawUtils.CANVAS_ACTUAL_WIDTH, drawUtils.CANVAS_ACTUAL_HEIGHT);
        }
    } else if (elementId) {
        let data = await getItem(elementId);
        if (!data) {
            console.log('==== failed to retrieve element', elementId);
            return null;
        }
        let img = await drawUtils.buildImage(data);
        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(img, 0, 0, CANVAS_ACTUAL_WIDTH, CANVAS_ACTUAL_HEIGHT);
    }

    return canvas.toDataURL('png', 1.0);
};

drawUtils.clearCanvas = function() {
    let canvas = drawUtils.getCanvas();
    if (!canvas) return;
    canvas.width = CANVAS_ACTUAL_WIDTH;
    // canvas.height = CANVAS_ACTUAL_HEIGHT;
    let ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, drawUtils.getCanvas().width, drawUtils.getCanvas().height);
    // ctx.fillStyle = '#FFF';
    // ctx.fillRect(0, 0, canvas.width, canvas.height);
};

drawUtils.drawMathGrid = function(ctx) {
    ctx.globalCompositeOperation = 'destination-over';

    ctx.lineCap = 'round';
    ctx.lineJoin = 'miter';
    ctx.miterLimit = 1;

    // line horiz light
    ctx.beginPath();
    ctx.lineWidth = GRID_WIDTH;
    ctx.strokeStyle = GRID_COLOR;
    ctx.setLineDash([]);
    for (let i = 25; i < drawUtils.CANVAS_ACTUAL_HEIGHT; i += 25) {
        ctx.moveTo(0, i);
        ctx.lineTo(drawUtils.CANVAS_ACTUAL_WIDTH, i);
        ctx.moveTo(0, i);
        ctx.lineTo(drawUtils.CANVAS_ACTUAL_WIDTH, i);
    }
    ctx.stroke();
    ctx.closePath();

    ctx.beginPath();
    ctx.lineWidth = GRID_WIDTH;
    ctx.strokeStyle = GRID_COLOR;
    ctx.setLineDash([]);
    for (let i = 25; i < drawUtils.CANVAS_ACTUAL_WIDTH; i += 25) {
        ctx.moveTo(i, 0);
        ctx.lineTo(i, drawUtils.CANVAS_ACTUAL_HEIGHT);
        ctx.moveTo(i, 0);
        ctx.lineTo(i, drawUtils.CANVAS_ACTUAL_HEIGHT);
    }
    ctx.stroke();
    ctx.closePath();
};

drawUtils.drawMM = function(ctx) {
    ctx.globalCompositeOperation = 'destination-over';

    ctx.lineCap = 'square';
    ctx.lineJoin = 'miter';
    ctx.miterLimit = 0;

    // line horiz light
    ctx.beginPath();
    ctx.lineWidth = 0.5;
    ctx.strokeStyle = '#0000ff55';
    ctx.setLineDash([]);
    for (let i = 5; i < drawUtils.CANVAS_ACTUAL_HEIGHT; i += 5) {
        ctx.moveTo(0, i);
        ctx.lineTo(drawUtils.CANVAS_ACTUAL_WIDTH, i);
    }
    ctx.stroke();
    ctx.closePath();

    ctx.beginPath();
    ctx.lineWidth = 0.5;
    ctx.strokeStyle = '#0000ff55';
    ctx.setLineDash([]);
    for (let i = 5; i < drawUtils.CANVAS_ACTUAL_WIDTH; i += 5) {
        ctx.moveTo(i, 0);
        ctx.lineTo(i, drawUtils.CANVAS_ACTUAL_HEIGHT);
    }
    ctx.stroke();
    ctx.closePath();

    ctx.beginPath();
    ctx.lineWidth = 1;
    ctx.strokeStyle = '#0000ff88';
    ctx.setLineDash([]);
    for (let i = 5; i < drawUtils.CANVAS_ACTUAL_HEIGHT; i += 50) {
        ctx.moveTo(0, i);
        ctx.lineTo(drawUtils.CANVAS_ACTUAL_WIDTH, i);
    }
    ctx.stroke();
    ctx.closePath();

    ctx.beginPath();
    ctx.lineWidth = 1;
    ctx.strokeStyle = '#0000ff88';
    ctx.setLineDash([]);
    for (let i = 5; i < drawUtils.CANVAS_ACTUAL_WIDTH; i += 50) {
        ctx.moveTo(i, 0);
        ctx.lineTo(i, drawUtils.CANVAS_ACTUAL_HEIGHT);
    }
    ctx.stroke();
    ctx.closePath();
};

drawUtils.drawTip1 = function(ctx) {
    ctx.globalCompositeOperation = 'destination-over';

    ctx.lineCap = 'round';
    ctx.lineJoin = 'miter';
    ctx.miterLimit = 1;

    // line vert heavy
    ctx.beginPath();
    ctx.lineWidth = GRID_WIDTH;
    ctx.strokeStyle = GRID_COLOR;
    for (let i = 0; i < drawUtils.CANVAS_ACTUAL_WIDTH + 180; i += 30) {
        ctx.moveTo(i, 0);
        ctx.lineTo(i - 180, drawUtils.CANVAS_ACTUAL_HEIGHT);
    }
    ctx.stroke();
    ctx.closePath();

    // line vert light
    ctx.beginPath();
    ctx.lineWidth = GRID_WIDTH;
    ctx.strokeStyle = GRID_COLOR;
    for (let i = 15; i < drawUtils.CANVAS_ACTUAL_WIDTH + 180; i += 30) {
        ctx.moveTo(i, 0);
        ctx.lineTo(i - 180, drawUtils.CANVAS_ACTUAL_HEIGHT);
    }
    ctx.stroke();
    ctx.closePath();

    // line horiz doted
    ctx.beginPath();
    ctx.lineWidth = GRID_WIDTH;
    ctx.strokeStyle = GRID_COLOR;
    ctx.setLineDash([5, 15]);
    for (let i = 28; i < drawUtils.CANVAS_ACTUAL_HEIGHT; i += 78) {
        ctx.moveTo(0, i);
        ctx.lineTo(drawUtils.CANVAS_ACTUAL_WIDTH, i);
    }
    ctx.stroke();
    ctx.closePath();

    // line horiz light
    ctx.beginPath();
    ctx.lineWidth = GRID_WIDTH;
    ctx.strokeStyle = GRID_COLOR;
    ctx.setLineDash([]);
    for (let i = 53; i < drawUtils.CANVAS_ACTUAL_HEIGHT; i += 78) {
        ctx.moveTo(0, i);
        ctx.lineTo(drawUtils.CANVAS_ACTUAL_WIDTH, i);
    }
    ctx.stroke();
    ctx.closePath();

    // line horiz light
    ctx.beginPath();
    ctx.lineWidth = GRID_WIDTH;
    ctx.strokeStyle = GRID_COLOR;
    ctx.setLineDash([]);
    for (let i = 78; i < drawUtils.CANVAS_ACTUAL_HEIGHT; i += 78) {
        ctx.moveTo(0, i);
        ctx.lineTo(drawUtils.CANVAS_ACTUAL_WIDTH, i);
    }
    ctx.stroke();
    ctx.closePath();
};

drawUtils.drawTip2 = function(ctx) {
    ctx.globalCompositeOperation = 'destination-over';
    ctx.lineCap = 'round';
    ctx.lineJoin = 'miter';
    ctx.miterLimit = 1;

    // line horiz doted
    ctx.beginPath();
    ctx.lineWidth = GRID_WIDTH;
    ctx.strokeStyle = GRID_COLOR;
    ctx.setLineDash([10, 15]);
    for (let i = 15; i < drawUtils.CANVAS_ACTUAL_HEIGHT; i += 45) {
        ctx.moveTo(0, i);
        ctx.lineTo(drawUtils.CANVAS_ACTUAL_WIDTH, i);
    }
    ctx.stroke();
    ctx.closePath();

    // line horiz light
    ctx.beginPath();
    ctx.lineWidth = GRID_WIDTH;
    ctx.strokeStyle = GRID_COLOR;
    ctx.setLineDash([]);
    for (let i = 30; i < drawUtils.CANVAS_ACTUAL_HEIGHT; i += 45) {
        ctx.moveTo(0, i);
        ctx.lineTo(drawUtils.CANVAS_ACTUAL_WIDTH, i);
    }
    ctx.stroke();
    ctx.closePath();

    // line horiz light
    ctx.beginPath();
    ctx.lineWidth = GRID_WIDTH;
    ctx.strokeStyle = GRID_COLOR;
    ctx.setLineDash([]);
    for (let i = 45; i < drawUtils.CANVAS_ACTUAL_HEIGHT; i += 45) {
        ctx.moveTo(0, i);
        ctx.lineTo(drawUtils.CANVAS_ACTUAL_WIDTH, i);
    }
    ctx.stroke();
    ctx.closePath();
};

drawUtils.drawDictando = function(ctx) {
    ctx.globalCompositeOperation = 'destination-over';
    ctx.lineCap = 'round';
    ctx.lineJoin = 'miter';
    ctx.miterLimit = 1;

    // line horiz light
    ctx.beginPath();
    ctx.lineWidth = GRID_WIDTH;
    ctx.strokeStyle = GRID_COLOR;
    ctx.setLineDash([]);
    for (let i = 20; i < drawUtils.CANVAS_ACTUAL_HEIGHT; i += 20) {
        ctx.moveTo(0, i);
        ctx.lineTo(drawUtils.CANVAS_ACTUAL_WIDTH, i);
    }
    ctx.stroke();
    ctx.closePath();
};

drawUtils.drawMusic = function(ctx) {
    ctx.globalCompositeOperation = 'destination-over';
    ctx.lineCap = 'round';
    ctx.lineJoin = 'miter';
    ctx.miterLimit = 1;

    // line horiz light
    ctx.beginPath();
    ctx.lineWidth = GRID_WIDTH;
    ctx.strokeStyle = GRID_COLOR;
    ctx.setLineDash([]);
    for (let i = 80; i < drawUtils.CANVAS_ACTUAL_HEIGHT; i += 80) {
        ctx.moveTo(0, i);
        ctx.lineTo(drawUtils.CANVAS_ACTUAL_WIDTH, i);
        ctx.moveTo(0, i - 10);
        ctx.lineTo(drawUtils.CANVAS_ACTUAL_WIDTH, i - 10);
        ctx.moveTo(0, i - 20);
        ctx.lineTo(drawUtils.CANVAS_ACTUAL_WIDTH, i - 20);
        ctx.moveTo(0, i - 30);
        ctx.lineTo(drawUtils.CANVAS_ACTUAL_WIDTH, i - 30);
        ctx.moveTo(0, i - 40);
        ctx.lineTo(drawUtils.CANVAS_ACTUAL_WIDTH, i - 40);
    }
    ctx.stroke();
    ctx.closePath();
};

drawUtils.validateHorizontalPosition = function() {
    if (window.isTeams) {
        return true;
    }

    if (!boardManager.lessonState) {
        setTimeout(() => {
            drawUtils.validateHorizontalPosition();
        }, 1000);
        return true;
    }

    if (boardManager.lessonState.boardType === BOARD_TYPE.MULTI_BOARD && !boardManager.currentBoardActive) {
        if (!document.getElementById('resizeMessage') || !document.getElementById('lessonPage')) {
            setTimeout(() => {
                drawUtils.validateHorizontalPosition();
            }, 1000);
            return;
        }
        document.getElementById('resizeMessage').style.display = 'none';
        document.getElementById('lessonPage').style.display = 'block';
        return true;
    }

    if (window.innerHeight > window.innerWidth) {
        // document.getElementById('lessonPage').style.display = 'none';
        if (document.getElementById('lessonPage') && document.getElementById('resizeMessage')) {
            document.getElementById('resizeMessage').style.display = 'flex';
            document.getElementById('lessonPage').style.display = 'block';
            return true;
        }
        // document.getElementById('resizeMessage').style.height = window.innerHeight / 2 + 'px';
        // return false;
    } else {
        if (document.getElementById('lessonPage') && document.getElementById('resizeMessage')) {
            document.getElementById('lessonPage').style.display = 'block';
            document.getElementById('resizeMessage').style.display = 'none';
            return true;
        }
    }
    return true;
};

drawUtils.imageResize = async (data) => {
    return new Promise((resolve) => {
        let image = new Image();
        image.onload = function(imageEvent) {
            if (image.width < CANVAS_ACTUAL_WIDTH && image.height < CANVAS_ACTUAL_HEIGHT) {
                resolve({ data, w: image.width, h: image.height });
                return;
            }

            // Resize the image
            let canvas = document.createElement('canvas'),
                width = image.width,
                height = image.height;

            let newHW = drawUtils.computeWithHeightWithAspectRatio(
                width,
                height,
                CANVAS_ACTUAL_WIDTH,
                CANVAS_ACTUAL_HEIGHT,
            );

            canvas.width = newHW.width;
            canvas.height = newHW.height;
            let ctx = canvas.getContext('2d');
            ctx.imageSmoothingEnabled = true;
            ctx.imageSmoothingQuality = 'high';
            ctx.drawImage(image, 0, 0, newHW.width, newHW.height);
            resolve({ data: canvas.toDataURL('image/png', 1), w: newHW.width, h: newHW.height });
        };
        image.src = data;
    });
};

drawUtils.computeWithHeightWithAspectRatio = (width, height, fitWidth, fitHeight) => {
    if (height < fitHeight && width < fitWidth) {
        return {
            width: width,
            height: height,
        };
    }
    const heightPercNecessary = (fitHeight * 100) / height;
    const widthPercNecessary = (fitWidth * 100) / width;

    let newHeight, newWidth;

    if (widthPercNecessary < heightPercNecessary) {
        // width is the max
        newWidth = fitWidth;
        newHeight = (height * fitWidth) / width;
    } else {
        // height is the max
        newHeight = fitHeight;
        newWidth = (width * fitHeight) / height;
    }

    return {
        width: newWidth,
        height: newHeight,
    };
};

drawUtils.imageDataFromBlob = async (blob) => {
    return new Promise((resolve) => {
        let image = new Image();
        image.onload = function(imageEvent) {
            let canvas = document.createElement('canvas'),
                width = image.width,
                height = image.height;

            canvas.width = width;
            canvas.height = height;
            let ctx = canvas.getContext('2d');
            ctx.imageSmoothingEnabled = true;
            ctx.imageSmoothingQuality = 'high';
            ctx.drawImage(image, 0, 0, width, height);
            resolve({ data: canvas.toDataURL('image/png', 1), width, height });
        };
        image.src = URL.createObjectURL(blob);
    });
};

drawUtils.handleResize = (lessonComponent) => {
    // console.log('=== handle resize', resizeChecker);
    resizeChecker.lessonComponent = lessonComponent;
    if (!lessonComponent) return;
    if (lessonComponent.isWriting) {
        return;
    }

    let allGood = drawUtils.validateHorizontalPosition();
    if (allGood && !lessonComponent.isInitialised) {
        // lessonComponent.startPageFlow();
    } else {
        if (!resizeDebouncer) {
            resizeDebouncer = setTimeout(() => {
                // console.log('====== resize logic');
                drawUtils.computeCanvas(true);
                drawUtils.computeSliders();
                if (boardManager.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
                    lessonComponent.shallowStickyElementSync();
                    lessonComponent.rulerElement?.current?.updateScale(lessonComponent.startPoint);
                    lessonComponent.protractorElement?.current?.updateScale(lessonComponent.startPoint);
                    lessonComponent.compassElement?.current?.updateScale(lessonComponent.startPoint);
                    window.repositionUserBar();
                    moveUtils.zoomFunc(lessonComponent);
                    resizeDebouncer = null;
                } else {
                    Object.keys(boardManager.lessonState.individualBoards).forEach((k) => {
                        drawUtils.computeMultiboardCanvas(true, k);
                    });
                    lessonComponent.moveVert(lessonComponent.lastVertValue);
                    setTimeout(() => {
                        lessonComponent.shallowStickyElementSync();
                        resizeDebouncer = null;
                    }, 250);
                }
            }, 250);
        }

        // let computed = drawUtils.computeHeightWidth();
        // if (drawUtils.getCanvasContainer()) {
        //     drawUtils.getCanvasContainer().style.width = computed.width + 'px';
        //     drawUtils.getCanvasContainer().style.height = computed.height + 'px';
        //     drawUtils.computeSliders();
        // }
    }

    //
    // if (resizeDebouncer) {
    //     clearTimeout(resizeDebouncer);
    // } else {
    //     fullQualityImageSnapshot = drawUtils.getCanvas().toDataURL('image/png', 1.0);
    //     // console.log("getting image", drawUtils.getCanvas().getBoundingClientRect());
    // }
    //
    // resizeDebouncer = setTimeout(() => {
    //     let img = new Image();
    //
    //     img.onload = () => {
    //         drawUtils.resetSlidersAndCanvases();
    //         drawUtils.computeSliders();
    //         drawUtils.computeCanvas();
    //         let targetContext = drawUtils.getCanvas().getContext('2d');
    //         targetContext.imageSmoothingEnabled = true;
    //         targetContext.imageSmoothingQuality = 'high';
    //         let rect = drawUtils.getCurrentCanvasRect();
    //
    //         targetContext.drawImage(img, 0, 0, rect.width, rect.height);
    //         // console.log("drew image", rect);
    //         moveUtils.zoomFunc(lessonComponent);
    //         resizeDebouncer = null;
    //         fullQualityImageSnapshot = null;
    //     };
    //     img.src = fullQualityImageSnapshot;
    // }, 1000);
};

drawUtils.getProjectionPoint = (startPosition, endPosition, point) => {
    let CF =
        ((endPosition.x - startPosition.x) * (point.x - startPosition.x) +
            (endPosition.y - startPosition.y) * (point.y - startPosition.y)) /
        ((endPosition.x - startPosition.x) * (endPosition.x - startPosition.x) +
            (endPosition.y - startPosition.y) * (endPosition.y - startPosition.y));
    let D = {
        x: 0,
        y: 0,
    };
    D.x = startPosition.x + (endPosition.x - startPosition.x) * CF;
    D.y = startPosition.y + (endPosition.y - startPosition.y) * CF;
    return D;
};

drawUtils.writeTextToDom = (context, text, x, y, maxWidth, fontHeight) => {
    let multiLines = text.split('\n');
    let myY = y + fontHeight;
    // console.log('wrtiting at ', x, y, maxWidth, fontHeight);

    let textLineWriter = (textLine) => {
        let words = textLine.split(' ');
        let line = '';

        for (let n = 0; n < words.length; n++) {
            let testLine = line + words[n] + ' ';
            let metrics = context.measureText(testLine);
            let testWidth = metrics.width;
            if (testWidth > maxWidth && n > 0) {
                context.fillText(line, x, myY);
                line = words[n] + ' ';
                myY += fontHeight;
            } else {
                line = testLine;
            }
        }
        context.fillText(line, x, myY);
        myY += fontHeight;
    };

    if (multiLines.length > 0) {
        multiLines.forEach((lineText) => {
            textLineWriter(lineText);
        });
    } else {
        textLineWriter(text);
    }
};

drawUtils.repositionTextInputExpander = () => {
    if (window['updateTextInputExpand']) {
        window['updateTextInputExpand']({
            visible: false,
            axis: 'x',
            defaultPosition: { x: 0, y: 0 },
            bounds: {
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
            },
        });
        if (inputExpanderDebouncer) {
            clearTimeout(inputExpanderDebouncer);
        }
        inputExpanderDebouncer = setTimeout(() => {
            let input = document.getElementById('textInput');
            let inputRect = input.getBoundingClientRect();
            let rect = drawUtils.getCurrentCanvasRect();
            window['updateTextInputExpand']({
                visible: true,
                axis: 'both',
                defaultPosition: { x: inputRect.right, y: inputRect.bottom },
                bounds: {
                    left: inputRect.left,
                    top: inputRect.top,
                    right: rect.right - 24,
                    bottom: rect.bottom - 24,
                },
            });
            if (inputRect.right > rect.right) {
                input.style.width = inputRect.width - (inputRect.right - rect.right) + 'px';
            }
            input.style.maxWidth = inputRect.width - (inputRect.right - rect.right) + 'px';
            if (inputRect.bottom > rect.bottom) {
                input.style.height = inputRect.height - (inputRect.bottom - rect.bottom) + 'px';
            }
            input.style.maxHeight = inputRect.height - (inputRect.bottom - rect.bottom) + 'px';

            inputExpanderDebouncer = null;
        }, 100);
    }
};

drawUtils.repositionTextInputMover = () => {
    if (window['updateTextInputMove']) {
        window['updateTextInputMove']({
            visible: false,
            axis: 'both',
            defaultPosition: { x: 0, y: 0 },
            bounds: {
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
            },
        });
        if (inputMoverDebouncer) {
            clearTimeout(inputMoverDebouncer);
        }
        inputMoverDebouncer = setTimeout(() => {
            let input = document.getElementById('textInput');
            let inputRect = input.getBoundingClientRect();
            let rect = drawUtils.getCurrentCanvasRect();
            window['updateTextInputMove']({
                visible: true,
                axis: 'both',
                defaultPosition: { x: inputRect.left - 12 + inputRect.width / 2, y: inputRect.top - 28 },
                bounds: {
                    left: rect.left + inputRect.width / 2,
                    top: rect.top,
                    right: rect.right - 24 - inputRect.width / 2,
                    bottom: rect.bottom - 24 - inputRect.height,
                },
            });
            inputMoverDebouncer = null;
        }, 100);
    }
};

// ================== utils

drawUtils.buildImage = function(data) {
    return new Promise((resolve) => {
        const image = new Image();
        image.addEventListener('load', () => {
            resolve(image);
        });
        image.src = data;
    });
};

drawUtils.buildImageFromUrl = function(imageUrl) {
    return new Promise((resolve) => {
        const image = new Image();
        image.addEventListener('load', () => {
            addImageToWindowCache(imageUrl, image);
            sleep(IMAGE_LOAD_CACHING_SLEEP_MILIS).then(() => {
                resolve(image);
            });
        });
        image.addEventListener('error', () => {
            resolve(null);
        });
        image.crossOrigin = 'anonymous';
        image.src = imageUrl;
    });
};

drawUtils.buildImageFromUrlNoCache = function(imageUrl) {
    return axios
        .get(imageUrl, {
            responseType: 'arraybuffer',
        })
        .then((res) => {
            let buf = Buffer.from(res.data, 'binary').toString('base64');
            if (buf.length < 300) {
                console.error('Something went wrong at image retrieval', buf);
                return null;
            }
            const image = new Image();
            image.crossOrigin = 'anonymous';
            image.src = 'data:image/png;base64,' + buf;
            addImageToWindowCache(imageUrl, image);
            return sleep(IMAGE_LOAD_CACHING_SLEEP_MILIS).then(() => {
                return image;
            });
        })
        .catch((err) => {
            console.error('No image', imageUrl, err);
            return null;
        });
};

drawUtils.asyncClearCanvas = (ctx) => {
    if (!ctx) return;
    ctx.clearRect(0, 0, drawUtils.CANVAS_ACTUAL_WIDTH, drawUtils.CANVAS_ACTUAL_HEIGHT);
    // return new Promise((resolve, reject) => {
    //     setTimeout(() => {
    //         ctx.clearRect(0, 0, drawUtils.CANVAS_ACTUAL_WIDTH, drawUtils.CANVAS_ACTUAL_HEIGHT);
    //         // console.log('should have cleared rect');
    //         resolve();
    //     }, 0);
    // });
};

drawUtils.buildCatmullRomSplineSvgPath = (lineArray) => {
    let fitArr = [];
    let str = 'M ';
    lineArray.forEach((el, index) => {
        if (index === 0) {
            fitArr.push({ x: el.x1, y: el.y1 });
            fitArr.push({ x: el.x2, y: el.y2 });
        } else {
            fitArr.push({ x: el.x2, y: el.y2 });
        }
    });
    if (fitArr.length === 0) return '';

    str = window.SVGCatmullRomSpline.toPath(
        fitArr.map((point) => [point.x, point.y]),
        1,
        true,
    );
    let s = '';
    let arr = str.split('C');
    if (arr.length > 2) {
        arr.splice(arr.length - 1, 1);
        arr.forEach((el, index) => {
            let el1 = el;
            if (index === 0) {
                s = s + el1;
            } else {
                try {
                    el1 = el1.split(' ').map(e => {
                        return e.split(',').map(item => Math.round(item)).join(',');
                    }).join(' ');
                } catch (er) {
                    el1 = el;
                }
                s = s + 'C' + el1;
            }
        });
    } else {
        s = str;
    }
    return s;
};
drawUtils.buildSvgPath = (lineArray) => {
    let fitArr = [];
    let s = 'M ';
    lineArray.forEach((el, index) => {
        if (index === 0) {
            fitArr.push([el.x1, el.y1]);
            fitArr.push([el.x2, el.y2]);
        } else {
            fitArr.push([el.x2, el.y2]);
        }
    });
    if (fitArr.length === 0) return '';

    if (fitArr.length <= 4) {
        for (let i = 0; i < fitArr.length; i++) {
            if (i === 0) {
                s = s + ` ${fitArr[i][0]} ${fitArr[i][1]}`;
                s = s + ` l 0 0`;
            } else {
                s = s + ` l ${fitArr[i][0] - fitArr[i - 1][0]} ${fitArr[i][1] - fitArr[i - 1][1]}`;
            }
        }
    } else {
        s = s + ` ${fitArr[0][0]} ${fitArr[0][1]}`;
        s = s + ` C ${fitArr[1][0]} ${fitArr[1][1]}`;
        s = s + `, ${fitArr[2][0]} ${fitArr[2][1]}`;
        s = s + `, ${fitArr[3][0]} ${fitArr[3][1]}`;
        for (let i = 4; i < fitArr.length - 2; i += 2) {
            s = s + ` S ${fitArr[i][0]} ${fitArr[i][1]}, ${fitArr[i + 1][0]} ${fitArr[i + 1][1]}`;
        }
    }
    return s;
};

/*
*         if (stroke.length < 2) return; // Need at least 2 points

        ctx.beginPath();
        ctx.moveTo(stroke[0].x, stroke[0].y);

        // Use a "looping midpoint" technique to draw curves
        for (let i = 1; i < stroke.length - 2; i++) {
            const cpx = (stroke[i].x + stroke[i + 1].x) / 2; // midpoint X
            const cpy = (stroke[i].y + stroke[i + 1].y) / 2; // midpoint Y
            ctx.quadraticCurveTo(stroke[i].x, stroke[i].y, cpx, cpy);
        }

        // Draw the last segment from the second-to-last point to the last point
        const len = stroke.length;
        ctx.quadraticCurveTo(
            stroke[len - 2].x,
            stroke[len - 2].y,
            stroke[len - 1].x,
            stroke[len - 1].y,
        );

        ctx.stroke();
* */


drawUtils.buildQuadraticPath = (lineArray) => {
    let fitArr = [];
    let s = 'M ';
    lineArray.forEach((el, index) => {
        if (index === 0) {
            fitArr.push([el.x1, el.y1]);
            fitArr.push([el.x2, el.y2]);
            s += ` ${el.x1} ${el.y1}`;
        } else {
            fitArr.push([el.x2, el.y2]);
        }
    });
    if (fitArr.length === 0) return '';
    if (fitArr.length <= 2) {
        if (fitArr.length === 1 || (fitArr[0][0] === fitArr[1][0] && fitArr[0][1] === fitArr[1][1])) {
            s = `M ${fitArr[0][0] - 0.1} ${fitArr[0][1] - 0.1} L ${fitArr[0][0] + 0.1} ${fitArr[0][1] + 0.1} Z`;
        } else {
            for (let i = 1; i < fitArr.length - 2; i++) {
                s += ` L ${fitArr[i][0]} ${fitArr[i][1]} `;
            }
            const len = fitArr.length;
            s += ` L ${fitArr[len - 1][0]} ${fitArr[len - 1][1]}`;
        }
    } else {
        for (let i = 1; i < fitArr.length - 2; i++) {
            const cpx = (fitArr[i][0] + fitArr[i + 1][0]) / 2; // midpoint X
            const cpy = (fitArr[i][1] + fitArr[i + 1][1]) / 2; // midpoint Y
            s += ` Q ${fitArr[i][0]} ${fitArr[i][1]} ${cpx} ${cpy}`;
        }
        const len = fitArr.length;

        s += ` Q ${fitArr[len - 2][0]} ${fitArr[len - 2][1]} ${fitArr[len - 1][0]} ${fitArr[len - 1][1]}`;
    }

    return s;
};


// drawUtils.buildSmoothSvgPath = (lineArray) => {
//     let fitArr = [];
//     lineArray.forEach((el, index) => {
//         if (index === 0) {
//             fitArr.push([el.x1, el.y1]);
//             fitArr.push([el.x2, el.y2]);
//         } else {
//             fitArr.push([el.x2, el.y2]);
//         }
//     });
//     if (fitArr.length === 0) return '';
//     let s = 'M ';
//     // console.log('=== fitArr', fitArr);
//
//     let bessierArr = fitCurve(fitArr, 1);
//     if (bessierArr.length === 0) {
//         for (let i = 0; i < fitArr.length; i++) {
//             if (i === 0) {
//                 s = s + ` ${fitArr[i][0]} ${fitArr[i][1]}`;
//                 s = s + ` l 0 0`;
//             } else {
//                 s = s + ` l ${fitArr[i][0] - fitArr[i - 1][0]} ${fitArr[i][1] - fitArr[i - 1][1]}`;
//             }
//         }
//         return s;
//     }
//
//     for (let i = 0; i < bessierArr.length; i++) {
//         let pSet = bessierArr[i];
//         if (i === 0) {
//             s = s + ` ${parseInt(pSet[0][0])} ${parseInt(pSet[0][1])}`;
//             s = s + ` C ${parseInt(pSet[1][0])} ${parseInt(pSet[1][1])}`;
//             s = s + `, ${parseInt(pSet[2][0])} ${parseInt(pSet[2][1])}`;
//             s = s + `, ${parseInt(pSet[3][0])} ${parseInt(pSet[3][1])}`;
//         } else {
//             s =
//                 s +
//                 ` S ${parseInt(pSet[2][0])} ${parseInt(pSet[2][1])}, ${parseInt(pSet[3][0])} ${parseInt(pSet[3][1])}`;
//         }
//     }
//
//     // if (bessierArr.length >= 4) {
//     //     s = s + ` ${bessierArr[0][0]} ${bessierArr[0][1]}`;
//     //     s = s + ` C ${bessierArr[1][0]} ${bessierArr[1][1]}`;
//     //     s = s + `, ${bessierArr[2][0]} ${bessierArr[2][1]}`;
//     //     s = s + `, ${bessierArr[3][0]} ${bessierArr[3][1]}`;
//     //     for (let i = 4; i < bessierArr.length; i += 4) {
//     //         if (bessierArr[i + 2] && bessierArr[i + 3]) {
//     //             s =
//     //                 s +
//     //                 ` S ${bessierArr[i + 2][0]} ${bessierArr[i + 2][1]}, ${bessierArr[i + 3][0]} ${
//     //                     bessierArr[i + 3][1]
//     //                 }`;
//     //         }
//     //     }
//     // }
//
//     // for (let i = lineArray.length - 1; i > 0; i--) {
//     //     s = s + 'l ' + (lineArray[i].x1 - lineArray[i].x2) + ',' + (lineArray[i].y1 - lineArray[i].y2);
//     // }
//     // console.log('===', s);
//     return s;
// };

drawUtils.buildSmoothSvgPathCurrent = (lineArray) => {
    let s = 'M ';
    if (lineArray.length === 0) {
        return '';
    }

    // console.log('=== line', JSON.stringify(lineArray));

    if (lineArray.length > 2) {
        // ctx.moveTo(lineArray[0].x1, lineArray[0].y1);
        s = s + ` ${lineArray[0].x1} ${lineArray[0].y1}`;
        let cp1x, cp1y, cp2x, cp2y, x, y;
        if (lineArray.length < BEZIER_INTERVAL_DIVIDER * 2) {
            let interval = Math.floor(lineArray.length / 2);
            x = lineArray[lineArray.length - 1].x2;
            y = lineArray[lineArray.length - 1].y2;
            cp1x = lineArray[interval].x2;
            cp1y = lineArray[interval].y2;
            if (lineArray[2 * interval]) {
                cp2x = lineArray[2 * interval].x2;
                cp2y = lineArray[2 * interval].y2;
            } else {
                cp2x = lineArray[2 * interval - 1].x2;
                cp2y = lineArray[2 * interval - 1].y2;
            }
            s = s + ` C ${parseInt(cp1x)} ${parseInt(cp1y)}`;
            s = s + `, ${parseInt(cp2x)} ${parseInt(cp2y)}`;
            s = s + `, ${parseInt(x)} ${parseInt(y)}`;
            // console.log('=== line', JSON.stringify(lineArray));
            // console.log('==== small count');

            // ctx.bezierCurveTo(cp1x, cp1y, cp2x, cp2y, x, y);
        } else {
            let i;
            for (i = 0; i < Math.floor(lineArray.length / BEZIER_INTERVAL_DIVIDER) - 2; i++) {
                x = lineArray[(i + 1) * BEZIER_INTERVAL_DIVIDER].x2;
                y = lineArray[(i + 1) * BEZIER_INTERVAL_DIVIDER].y2;
                cp1x = lineArray[i * BEZIER_INTERVAL_DIVIDER + BEZIER_INTERVAL_DIVIDER / 2].x2;
                cp1y = lineArray[i * BEZIER_INTERVAL_DIVIDER + BEZIER_INTERVAL_DIVIDER / 2].y2;
                cp2x = lineArray[i * BEZIER_INTERVAL_DIVIDER + 2 * (BEZIER_INTERVAL_DIVIDER / 2)].x2;
                cp2y = lineArray[i * BEZIER_INTERVAL_DIVIDER + 2 * (BEZIER_INTERVAL_DIVIDER / 2)].y2;
                if (i === 0) {
                    s = s + ` C ${parseInt(cp1x)} ${parseInt(cp1y)}`;
                    s = s + `, ${parseInt(cp2x)} ${parseInt(cp2y)}`;
                    s = s + `, ${parseInt(x)} ${parseInt(y)}`;
                } else {
                    s = s + ` S ${parseInt(cp2x)} ${parseInt(cp2y)}`;
                    s = s + `, ${parseInt(x)} ${parseInt(y)}`;
                }

                // ctx.bezierCurveTo(cp1x, cp1y, cp2x, cp2y, x, y);
            }
            let diff = lineArray.length - BEZIER_INTERVAL_DIVIDER * (i + 1);
            // console.log(diff);
            if (diff !== 0) {
                let interval = Math.floor(diff / 3);
                x = lineArray[lineArray.length - 1].x2;
                y = lineArray[lineArray.length - 1].y2;
                cp1x = lineArray[i * BEZIER_INTERVAL_DIVIDER + interval].x2;
                cp1y = lineArray[i * BEZIER_INTERVAL_DIVIDER + interval].y2;
                cp2x = lineArray[i * BEZIER_INTERVAL_DIVIDER + 2 * interval].x2;
                cp2y = lineArray[i * BEZIER_INTERVAL_DIVIDER + 2 * interval].y2;
                s = s + ` C ${cp1x} ${cp1y}`;
                s = s + `, ${cp2x} ${cp2y}`;
                s = s + `, ${x} ${y}`;
                // ctx.bezierCurveTo(cp1x, cp1y, cp2x, cp2y, x, y);
            } else {
                s = s + ` l ${lineArray[lineArray.length - 1].x2} ${lineArray[lineArray.length - 1].y2}`;
                // ctx.lineTo(lineArray[lineArray.length - 1].x2, lineArray[lineArray.length - 1].y2);
            }
        }
    } else {
        s = s + `${lineArray[0].x1} ${lineArray[0].y1}`;
        for (let i = 0; i < lineArray.length; i++) {
            // s = s + ` l ${lineArray[i].x1} ${lineArray[i].y1}`;
            s = s + ` l ${lineArray[i].x2 - lineArray[i].x1} ${lineArray[i].y2 - lineArray[i].y1}`;
            // ctx.moveTo(lineArray[i].x1, lineArray[i].y1);
            // ctx.lineTo(lineArray[i].x2, lineArray[i].y2);
        }
    }

    // console.log('===', s);
    return s;
};

if (!resizeChecker.interval) {
    resizeChecker.interval = setInterval(() => {
        if (window.onresize && resizeChecker.lessonComponent) {
            if (resizeChecker.height !== window.innerHeight || resizeChecker.width !== window.innerWidth) {
                // console.log(
                //     '=== checking size',
                //     resizeChecker.height,
                //     window.innerHeight,
                //     resizeChecker.width,
                //     window.innerWidth
                // );
                resizeChecker.height = window.innerHeight;
                resizeChecker.width = window.innerWidth;
                drawUtils.handleResize(resizeChecker.lessonComponent);
            }
        }
    }, 1000);
}

export default drawUtils;
