import { Fab, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import ImageIcon from '@material-ui/icons/Image';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import PasteIcon from '../../../../MyComponents/icons/PasteIcon';
import SectionChapter from '../../SectionChapter';
import { COLORS } from '../../../../constants';

const ImageMenuManagement = ({ language, classes, refs }) => {
    let imageMenu = [
        {
            id: 'tutorial.manageLesson.imageMenu.explanation',
            children: (
                <Fab
                    size="small"
                    style={{ color: '#FFF', backgroundColor: COLORS.primary }}
                    className={classes.sampleFab}
                >
                    <ImageIcon />
                </Fab>
            ),
        },
        {
            children: (
                <ul>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.imageMenu.upload"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                            <AddPhotoAlternateIcon className={classes.sampleFab} />
                        </Typography>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.imageMenu.upload.explanation"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.imageMenu.saveBoard"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                            <SaveAltIcon className={classes.sampleFab} />
                        </Typography>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.imageMenu.saveBoard.explanation"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.imageMenu.downloadPDF"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                            <PictureAsPdfIcon className={classes.sampleFab} />
                        </Typography>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.imageMenu.downloadPDF.explanation"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.imageMenu.pasteImage"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                            <PasteIcon className={classes.sampleFab} />
                        </Typography>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.imageMenu.pasteImage.explanation"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>
                </ul>
            ),
            img: [`/static/asset/img/tutorial/withText/${language.toUpperCase()}/ImageMenu.jpg`],
        },
        {
            id: 'tutorial.imageMenu.upload',
            children: (
                <ul>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.imageMenu.upload.explanation2"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="tutorial.imageMenu.upload.explanation3"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>
                </ul>
            ),
            img: [`/static/asset/img/tutorial/noText/ImageSelection.jpeg`],
        },
    ];
    return (
        <SectionChapter
            refs={refs}
            id={'section-manageLesson-imageMenu'}
            title={'tutorial.manageLesson.imageMenu.title'}
            explanations={imageMenu}
            imageTop={false}
        />
    );
};

export default ImageMenuManagement;
