import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function SelectArrowIcon(props) {
    return (
        <SvgIcon {...props} style={{ width: '10%' }}>
            <svg viewBox="0 0 6 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.83528 1.1375L3.44719 3.80417C3.31378 3.93542 3.15971 4 3.00564 4C2.85157 4 2.69788 3.9349 2.58044 3.80469L0.192351 1.13802C0.00360284 0.947917 -0.0478868 0.660417 0.0451944 0.4125C0.138276 0.164583 0.357695 0 0.600638 0H5.39374C5.63687 0 5.85632 0.162104 5.94952 0.411458C6.04271 0.660812 6.00814 0.947917 5.83528 1.1375Z" fill="#065156"/>
            </svg>
        </SvgIcon>
    );
}
