import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { SketchPicker } from 'react-color';
import { Button, Dialog, DialogActions, Fab, List, ListItem, MenuItem, makeStyles } from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import TextFieldsIcon from '@material-ui/icons/TextFields';

import StyledMenu from '../StyledMenu';
import store from '../../../redux/store';
import { changeColor, changeEraserWeight, changeTextWeight, changeToolWeight } from '../../../redux/actions';
import drawUtils from '../../../pages/LessonPage/drawUtils';
import LineWidthIcon from '../../icons/LineWidthIcon';
import VBToolTip from '../../Tooltip/VBToolTip';
import { BOARD_TYPE, TOOL_BRUSH, TOOL_ERASER, TOOL_SELECT, TOOL_TEXT } from '../../../constants';
import { GA_EVENT_TREE } from '../../../gaConstants';
import TextSizeSelectorMenu from './TextSizeSelectorMenu';

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    toolBorder: {
        borderColor: 'rgba(0,0,0,0.3)',
        borderWidth: 'thin',
        borderTopStyle: 'solid',
        borderBottomStyle: 'solid',
    },
    sketchPicker: {
        boxShadow: 'none !important',
    },
}));

const ToolWeightColor = (props) => {
    const classes = useStyles();

    const [anchorTool, setAnchorTool] = React.useState(null);
    const [dialogOpen, setDialogOpen] = React.useState(false);

    let selectWeight = (weight) => {
        window.logAppActivity(GA_EVENT_TREE.lessonPage.toolSelection.toolWeight, weight);
        store.dispatch(changeToolWeight(weight));
        if (window.window.toolSelectionCallback) window.toolSelectionCallback(props.drawing.tool);
        handleClose();
    };

    const selectEraser = (weight) => {
        window.logAppActivity(GA_EVENT_TREE.lessonPage.toolSelection.eraserWeight, weight);
        store.dispatch(changeEraserWeight(weight));
        if (window.window.toolSelectionCallback) window.toolSelectionCallback(TOOL_ERASER, weight);
        handleClose();
    };
    const selectText = (weight) => {
        window.logAppActivity(GA_EVENT_TREE.lessonPage.toolSelection.textWeight, weight);
        store.dispatch(changeTextWeight(weight));
        if (window.window.toolSelectionCallback) window.toolSelectionCallback(TOOL_TEXT);
        handleClose();
    };

    const handleClose = () => {
        setAnchorTool(null);
        setDialogOpen(false);
    };

    const handleClick = (event) => {
        if (!event) {
            handleClose();
            return;
        }
        setAnchorTool(event.currentTarget);
    };

    const handleColorChange = (color) => {
        window.logAppActivity(GA_EVENT_TREE.lessonPage.toolSelection.color);
        store.dispatch(changeColor(color.hex));
        if (window.window.toolSelectionCallback) {
            window.toolSelectionCallback(props.drawing.tool);
        }
    };

    let allowDrawing = props.lessonState.allowDrawing || props.lessonState.boardType === BOARD_TYPE.MULTI_BOARD;

    let toolMenu = (
        <List>
            <MenuItem dense disableGutters>
                <ListItem
                    button
                    key={'1'}
                    selected={props.drawing.toolWeight === 1}
                    onClick={(event) => {
                        event.preventDefault();
                        selectWeight(1);
                    }}
                >
                    <div style={{ width: '50px', borderBottom: drawUtils.computeLineWidth(1) + 'px solid black' }} />
                </ListItem>
            </MenuItem>
            <MenuItem dense disableGutters>
                <ListItem
                    button
                    key={'2'}
                    selected={props.drawing.toolWeight === 2}
                    onClick={(event) => {
                        event.preventDefault();
                        selectWeight(2);
                    }}
                >
                    <div style={{ width: '50px', borderBottom: drawUtils.computeLineWidth(2) + 'px solid black' }} />
                </ListItem>
            </MenuItem>
            <MenuItem dense disableGutters>
                <ListItem
                    button
                    key={'3'}
                    selected={props.drawing.toolWeight === 3}
                    onClick={(event) => {
                        event.preventDefault();
                        selectWeight(3);
                    }}
                >
                    <div style={{ width: '50px', borderBottom: drawUtils.computeLineWidth(3) + 'px solid black' }} />
                </ListItem>
            </MenuItem>
            <MenuItem dense disableGutters>
                <ListItem
                    button
                    key={'4'}
                    selected={props.drawing.toolWeight === 4}
                    onClick={(event) => {
                        event.preventDefault();
                        selectWeight(4);
                    }}
                >
                    <div style={{ width: '50px', borderBottom: drawUtils.computeLineWidth(4) + 'px solid black' }} />
                </ListItem>
            </MenuItem>
            <MenuItem dense disableGutters>
                <ListItem
                    button
                    key={'5'}
                    selected={props.drawing.toolWeight === 5}
                    onClick={(event) => {
                        event.preventDefault();
                        selectWeight(5);
                    }}
                >
                    <div style={{ width: '50px', borderBottom: drawUtils.computeLineWidth(5) + 'px solid black' }} />
                </ListItem>
            </MenuItem>
        </List>
    );

    let eraserMenu = (
        <List>
            <MenuItem dense disableGutters>
                <ListItem
                    button
                    key={'1'}
                    selected={
                        props.drawing.eraserWeight === 1 &&
                        (props.drawing.tool === TOOL_ERASER || props.drawing.tool === TOOL_BRUSH)
                    }
                    onClick={(event) => {
                        event.preventDefault();
                        selectEraser(1);
                    }}
                >
                    <div
                        style={{
                            borderRadius: '50%',
                            width: drawUtils.computeEraserWidthForDisplay(1) + 'px',
                            height: drawUtils.computeEraserWidthForDisplay(1) + 'px',
                            background: 'white',
                            border: '1px solid black',
                        }}
                    />
                </ListItem>
            </MenuItem>
            <MenuItem dense disableGutters>
                <ListItem
                    button
                    key={'2'}
                    selected={
                        props.drawing.eraserWeight === 2 &&
                        (props.drawing.tool === TOOL_ERASER || props.drawing.tool === TOOL_BRUSH)
                    }
                    onClick={(event) => {
                        event.preventDefault();
                        selectEraser(2);
                    }}
                >
                    <div
                        style={{
                            borderRadius: '50%',
                            width: drawUtils.computeEraserWidthForDisplay(2) + 'px',
                            height: drawUtils.computeEraserWidthForDisplay(2) + 'px',
                            background: 'white',
                            border: '1px solid black',
                        }}
                    />
                </ListItem>
            </MenuItem>
            <MenuItem dense disableGutters>
                <ListItem
                    button
                    key={'3'}
                    selected={
                        props.drawing.eraserWeight === 3 &&
                        (props.drawing.tool === TOOL_ERASER || props.drawing.tool === TOOL_BRUSH)
                    }
                    onClick={(event) => {
                        event.preventDefault();
                        selectEraser(3);
                    }}
                >
                    <div
                        style={{
                            borderRadius: '50%',
                            width: drawUtils.computeEraserWidthForDisplay(3) + 'px',
                            height: drawUtils.computeEraserWidthForDisplay(3) + 'px',
                            border: '1px solid black',
                        }}
                    />
                </ListItem>
            </MenuItem>
            <MenuItem dense disableGutters>
                <ListItem
                    button
                    key={'4'}
                    selected={
                        props.drawing.eraserWeight === 4 &&
                        (props.drawing.tool === TOOL_ERASER || props.drawing.tool === TOOL_BRUSH)
                    }
                    onClick={(event) => {
                        event.preventDefault();
                        selectEraser(4);
                    }}
                >
                    <div
                        style={{
                            borderRadius: '50%',
                            width: drawUtils.computeEraserWidthForDisplay(4) + 'px',
                            height: drawUtils.computeEraserWidthForDisplay(4) + 'px',
                            background: 'white',
                            border: '1px solid black',
                        }}
                    />
                </ListItem>
            </MenuItem>
            <MenuItem dense disableGutters>
                <ListItem
                    button
                    key={'5'}
                    selected={
                        props.drawing.eraserWeight === 5 &&
                        (props.drawing.tool === TOOL_ERASER || props.drawing.tool === TOOL_BRUSH)
                    }
                    onClick={(event) => {
                        event.preventDefault();
                        selectEraser(5);
                    }}
                >
                    <div
                        style={{
                            borderRadius: '50%',
                            width: drawUtils.computeEraserWidthForDisplay(5) + 'px',
                            height: drawUtils.computeEraserWidthForDisplay(5) + 'px',
                            border: '1px solid black',
                        }}
                    />
                </ListItem>
            </MenuItem>
        </List>
    );

    let currentMenu = toolMenu;
    let currentSelectorImage = (
        <VBToolTip content={<FormattedMessage id="tooltip.lessonPage.lineWidth" />}>
            <LineWidthIcon className={'jello-horizontal'} />
        </VBToolTip>
    );
    if (props.drawing.tool === TOOL_ERASER || props.drawing.tool === TOOL_BRUSH) {
        currentMenu = eraserMenu;
        currentSelectorImage = (
            <VBToolTip content={<FormattedMessage id="tooltip.lessonPage.eraser" />}>
                <RadioButtonUncheckedIcon style={{ verticalAlign: 'middle' }} className={'jello-horizontal'} />
            </VBToolTip>
        );
    } else if (props.drawing.tool === TOOL_TEXT) {
        currentMenu = (
            <TextSizeSelectorMenu
                selectText={selectText}
                tool={props.drawing.tool}
                textWeight={props.drawing.textWeight}
            />
        );
        currentSelectorImage = (
            <VBToolTip content={<FormattedMessage id="tooltip.lessonPage.text" />}>
                <TextFieldsIcon style={{ verticalAlign: 'middle' }} className={'jello-horizontal'} />
            </VBToolTip>
        );
    }

    return (
        <div className={classes.toolBorder}>
            <Fab
                size="small"
                aria-label="pen"
                className={classes.margin}
                onClick={handleClick}
                aria-controls="tool-menu"
                aria-haspopup="true"
                style={{ display: 'inline-block', backgroundColor: 'rgba(8, 170, 180, 0.6)', color: 'white' }}
                disabled={!allowDrawing || props.drawing.tool === TOOL_SELECT}
            >
                {currentSelectorImage}
            </Fab>
            <StyledMenu
                id="tool-menu"
                anchorEl={anchorTool}
                keepMounted
                open={Boolean(anchorTool)}
                onClose={handleClose}
            >
                {currentMenu}
            </StyledMenu>
            <VBToolTip content={<FormattedMessage id="tooltip.lessonPage.color" />}>
                <Fab
                    size="small"
                    color="default"
                    aria-label="color"
                    className={classes.margin}
                    aria-controls="color-menu"
                    onClick={() => setDialogOpen(true)}
                    aria-haspopup="true"
                    style={{ display: 'inline-block', border: '5px solid white', background: props.drawing.color }}
                    disabled={!allowDrawing || props.drawing.tool === TOOL_SELECT}
                >
                    <span />
                </Fab>
            </VBToolTip>
            <div
                style={{
                    width: '100%',
                    textAlign: 'center',
                    display: 'inline-flex',
                    fontSize: '0.9rem',
                    fontWeight: '600',
                }}
            >
                <div style={{ width: '50%' }}>
                    <FormattedMessage id="tooltip.lessonPage.size" />
                </div>
                <div style={{ width: '50%' }}>
                    <FormattedMessage id="tooltip.lessonPage.color" />
                </div>
            </div>
            <Dialog open={dialogOpen} onClose={handleClose}>
                <SketchPicker
                    className={classes.sketchPicker}
                    disableAlpha
                    color={props.drawing.color}
                    onChangeComplete={handleColorChange}
                />
                <DialogActions>
                    <Button onClick={handleClose} color="primary" variant="contained" size="small">
                        <FormattedMessage id="ok" />
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

const mapStateToProps = (state) => {
    const { drawing, lessonState } = state;
    return { drawing, lessonState };
};

export default injectIntl(connect(mapStateToProps)(ToolWeightColor));
