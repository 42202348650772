import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import store from '../../redux/store';
import { requestFullScreen, shareLesson } from '../../redux/actions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';

function openFullscreen() {
    let elem = document.documentElement;

    if (elem.requestFullscreen) {
        try {
            elem.requestFullscreen();
        } catch (ex) {
            console.log('Unable to open fullscreen', ex);
        }
    } else if (elem.mozRequestFullScreen) {
        /* Firefox */
        try {
            elem.mozRequestFullScreen();
        } catch (ex) {
            console.log('Unable to open fullscreen', ex);
        }
    } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        try {
            elem.webkitRequestFullscreen();
        } catch (ex) {
            console.log('Unable to open fullscreen', ex);
        }
    } else if (elem.msRequestFullscreen) {
        /* IE/Edge */
        try {
            elem.msRequestFullscreen();
        } catch (ex) {
            console.log('Unable to open fullscreen', ex);
        }
    } else {
        console.log('fullscreen not available');
    }
}
const RequestFullScreen = (props) => {
    return (
        <Dialog
            open={!!props.appState.requestFullscreen}
            onClose={() => {
                store.dispatch(shareLesson(null));
            }}
        >
            <DialogContent>
                <Typography variant="h4">
                    <FormattedMessage id="fullScreenDialog.text" />
                </Typography>
                <Button
                    onClick={() => {
                        openFullscreen();
                        store.dispatch(requestFullScreen(null));
                    }}
                    variant="outlined"
                    fullWidth
                    color="primary"
                >
                    <FormattedMessage id="fullScreenDialog.button" />
                </Button>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        store.dispatch(requestFullScreen(null));
                    }}
                    color="primary"
                >
                    <FormattedMessage id="close" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    const { appState } = state;
    return { appState };
};

export default injectIntl(connect(mapStateToProps)(RequestFullScreen));
