export const USER_TYPE_ADMIN = 'USER_TYPE_ADMIN';
export const USER_TYPE_MEMBER = 'USER_TYPE_MEMBER';

export const TOOL_PEN = 'TOOL_PEN';
export const TOOL_BRUSH = 'TOOL_BRUSH';
export const TOOL_LINE = 'TOOL_LINE';
export const TOOL_RECTANGLE = 'TOOL_RECTANGLE';
export const TOOL_CIRCLE = 'TOOL_CIRCLE';
export const TOOL_TRIANGLE = 'TOOL_TRIANGLE';
export const TOOL_ERASER = 'TOOL_ERASER';
export const TOOL_TEXT = 'TOOL_TEXT';
export const TOOL_IMAGE_LOAD = 'TOOL_IMAGE_LOAD';
export const TOOL_IMAGE_PASTE = 'TOOL_IMAGE_PASTE';
export const TOOL_SELECT = 'TOOL_SELECT';
export const TOOL_POINTER = 'TOOL_POINTER';
export const TOOL_MATH_CONVERTER = 'TOOL_MATH_CONVERTER';
export const TOOL_ARC = 'TOOL_ARC';

export const TOOL_WEIGHT = 'TOOL_WEIGHT';

export const MAX_MAIN_PAGES = 25;
export const MAX_MULTI_PAGES = 20;

export const CALL_STATE = {
    CALL_DISABLED: 0,
    CAN_START_CALL: 1,
    CAN_JOIN_CALL: 2,
    CALL_STARTED: 3,
    CALL_STARTING: 4,
};

export const BOARD_TYPE = {
    SINGLE_BOARD: 0,
    MULTI_BOARD: 1,
};

export const STICKY_ELEMENTS_TYPE = {
    IMAGE: 'image',
    TEXT: 'text',
    LATEX: 'latex',
};

export const ELEMENT_OPERATIONS = {
    ADD_STICKY_ELEMENT: 'ADD_STICKY_ELEMENT',
    UPDATE_STICKY_ELEMENT: 'UPDATE_STICKY_ELEMENT',
    REMOVE_STICKY_ELEMENT: 'REMOVE_STICKY_ELEMENT',
};

export const TARGETED_REQUESTS = {
    SWITCH_USER_MULTIBOARD_PAGE: 'SWITCH_USER_MULTIBOARD_PAGE',
    CLEAR_USER_MULTIBOARD_PAGE: 'CLEAR_USER_MULTIBOARD_PAGE',
    SWITCH_USER_MULTIBOARD_LINEAGE: 'SWITCH_USER_MULTIBOARD_LINEAGE',
    UNABLE_TO_CHANGE_PAGE: 'UNABLE_TO_CHANGE_PAGE',
    CHAT_MSG: 'CHAT_MSG',
    UPDATE_MULTIBOARD_SET: 'UPDATE_MULTIBOARD_SET',
};

export const LIBRARY_MANAGER_STATE = {
    MANAGE: 'MANAGE',
    LOAD: 'LOAD',
    SAVE: 'SAVE',
    SAVE_SET: 'SAVE_SET',
};

export const COLLECTION_PATH_STORAGE_KEY = 'expandedTreePath';

export const COLORS = {
    fontDefault: '#065156',
    fontDefaultFaded: '#6D6D6D',
    fontPrimary: '#08AAB4',

    neutral: '#525560',
    primary: '#08AAB4',
    secondary: '#D26828',
    third: '#F9A237',
    fourth: '#FFCD34',

    primaryD1: '#11474B',
    secondaryD1: '#55311C',
    thirdD1: '#442E13',
    fourthD1: '#574E33',

    primaryD2: '#0D797F',
    secondaryD2: '#934D22',
    thirdD2: '#AD732C',
    fourthD2: '#B1902A',

    primaryL1: '#5AC6CD',
    secondaryL1: '#E19A70',
    thirdL1: '#FBC17A',
    fourthL1: '#FFDE78',

    primaryL2: '#ADE3E6',
    secondaryL2: '#F0CDB7',
    thirdL2: '#FDE0BC',
    fourthL2: '#FFEEBB',
};

export const COLORS2 = {
    fontDefault: '#1D2130',
    fontPrimary: '#08AAB4',

    darkGreen: '#065156',
    virtualGreen: '#08AAB4',
    iceGreen: '#EBF8F9',
    virtualText: '#6D6D6D',
    virtualRed: '#F35325',
    virtualAmber: '#F9A237',
    dawnBackground: '#FBF8F4',
    darkCyan: '#022325',
    pattensBlue: '#DAE0E5',
    taxBreak: '#496365',
    blackRussian: '#1D2130',
    blueStone: '#1A5F63',
    floralWhite: '#FAF8F5',
    nero: '#252525',
    dimGray: '#6F6F6F',

    unauthenticatedBackground: '#FBF8F4',
    virtualBackground: '#FBF8F5',
    findMore: '#F9A237',
    orTextFooter: '#02232552',

    neutral: '#525560',
    primary: '#08AAB4',
    secondary: '#D26828',
    third: '#FFFFFF',
    fourth: '#FFCD34',
    fifth: '#1A5F63',
    sixth: '#065156',

    primaryD1: '#11474B',
    secondaryD1: '#55311C',
    thirdD1: '#442E13',
    fourthD1: '#574E33',

    primaryD2: '#0D797F',
    secondaryD2: '#934D22',
    thirdD2: '#AD732C',
    fourthD2: '#B1902A',

    primaryL1: '#5AC6CD',
    secondaryL1: '#E19A70',
    thirdL1: '#FBC17A',
    fourthL1: '#FFDE78',

    primaryL2: '#ADE3E6',
    secondaryL2: '#F0CDB7',
    thirdL2: '#FDE0BC',
    fourthL2: '#FFEEBB',
};

export const COLORS_SEMANTICAL = {
    PRIMARY_BORDER: '#07767D',
    PRIMARY_BORDER_TRANSPARENT: '#07767D33',
};

export const VIDEO_URLS = {
    main: {
        EN: 'https://www.youtube.com/embed/bPrX3IuU6vI?playlist=bPrX3IuU6vI&autoplay=1&mute=1&loop=1',
        RO: 'https://www.youtube.com/embed/7u5-ftAU0mM?playlist=7u5-ftAU0mM&autoplay=1&mute=1&loop=1',
        FR: 'https://www.youtube.com/embed/bPrX3IuU6vI?playlist=bPrX3IuU6vI&autoplay=1&mute=1&loop=1',
    },
    tutoring: {
        EN: 'https://www.youtube.com/embed/7TpjWK6Myss?playlist=7TpjWK6Myss&autoplay=1&mute=1&loop=1',
        RO: 'https://www.youtube.com/embed/Vtiey4kcdBo?playlist=Vtiey4kcdBo&autoplay=1&mute=1&loop=1',
        FR: 'https://www.youtube.com/embed/7TpjWK6Myss?playlist=7TpjWK6Myss&autoplay=1&mute=1&loop=1',
    },
    schools: {
        EN: 'https://www.youtube.com/embed/Tr7UHnUu1E8?playlist=Tr7UHnUu1E8&autoplay=1&mute=1&loop=1',
        RO: 'https://www.youtube.com/embed/CeSne9cajNg?playlist=CeSne9cajNg&autoplay=1&mute=1&loop=1',
        FR: 'https://www.youtube.com/embed/Tr7UHnUu1E8?playlist=Tr7UHnUu1E8&autoplay=1&mute=1&loop=1',
    },
    pricing: {
        EN: 'https://www.youtube.com/embed/bPrX3IuU6vI?playlist=bPrX3IuU6vI&autoplay=1&mute=1&loop=1',
        RO: 'https://www.youtube.com/embed/7u5-ftAU0mM?playlist=7u5-ftAU0mM&autoplay=1&mute=1&loop=1',
        FR: 'https://www.youtube.com/embed/bPrX3IuU6vI?playlist=bPrX3IuU6vI&autoplay=1&mute=1&loop=1',
    },
};

export const TERMS_VERSION = 'terms-20220504';
export const POLICY_VERSION = 'policy-20220402';

export const LOGIN_TYPES = {
    SESSIONS_US: 'SESSIONS_US',
    SAMSUNG: 'SAMSUNG',
    EQUALIFAI: 'EQUALIFAI',
    DIGITALIADA: 'DIGITALIADA',
    TOP_DASKAL: 'TOP_DASKAL',
};
