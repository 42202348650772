import React, { useEffect } from 'react';
import userApi from '../../Api/userApi';
import store from '../../redux/store';
import { updateAllowedGroups } from '../../redux/actions';
import classApi from '../../Api/classApi';
import { GA_EVENT_TREE } from '../../gaConstants';

const useClassesHook = (allowedGroups) => {
    let dataAll = [];
    const [data, setData] = React.useState([]);
    const [otherClasses, setOtherClasses] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [isError, setIsError] = React.useState(false);

    const sortDateFunction = (a, b) => {
        let dateA = a.lastaccess ? new Date(a.lastaccess).getTime() : new Date(a.updatedAt).getTime();
        let dateB = b.lastaccess ? new Date(b.lastaccess).getTime() : new Date(b.updatedAt).getTime();
        return dateA > dateB ? -1 : 1;
    };

    data.forEach((el) => {
        el.enabled = !allowedGroups || (allowedGroups && allowedGroups.includes(el.id));
    });

    data.forEach((el) => {
        el.isMine = true;
    });

    otherClasses.forEach((el) => {
        el.isMine = false;
    });

    dataAll = dataAll.concat(data, otherClasses).sort(sortDateFunction);

    const loadClasses = () => {
        setIsLoading(true);
        userApi
            .getAllowedGroups()
            .then((response) => {
                store.dispatch(updateAllowedGroups(response.data.groupIds));
            })
            .catch((err) => {
                console.error('unable to get allowed groups', err);
            });
        classApi
            .getMyClasses()
            .then((res) => {
                setData(res.data);
                window.logAppActivity(GA_EVENT_TREE.manageGroups.actions.load, res.data.length);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
                setIsError(true);
            });
        classApi
            .getClasses()
            .then((res) => {
                res.data.forEach((el) => {
                    el.enabled = true;
                });
                setOtherClasses(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        loadClasses();
    }, []);

    return { dataAll, isLoading, setIsError, isError, loadClasses, setIsLoading };
};

export default useClassesHook;
