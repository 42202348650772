import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ClearSingleIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 75 75">
                <defs>
                    <clipPath>
                        <path
                            d="M 3.136719 0 L 72.136719 0 L 72.136719 75 L 3.136719 75 Z M 3.136719 0 "
                            clipRule="nonzero"
                        />
                    </clipPath>
                </defs>
                <g>
                    <path
                        fill="#d36823"
                        d="M 72.136719 12.511719 L 52.691406 12.511719 L 49.554688 0.015625 L 25.71875 0.015625 L 22.582031 12.511719 L 3.136719 12.511719 L 3.136719 18.757812 L 9.566406 18.757812 L 12.703125 74.984375 L 62.570312 74.984375 L 65.707031 18.757812 L 72.136719 18.757812 Z M 30.738281 6.265625 L 44.691406 6.265625 L 46.261719 12.511719 L 29.167969 12.511719 Z M 56.613281 68.734375 L 18.660156 68.734375 L 15.839844 18.757812 L 59.433594 18.757812 Z M 56.613281 68.734375 "
                        fillOpacity="1"
                        fillRule="nonzero"
                    />
                </g>
                <path
                    fill="#d36823"
                    d="M 25.089844 28.128906 L 31.363281 28.128906 L 31.363281 59.363281 L 25.089844 59.363281 Z M 25.089844 28.128906 "
                    fillOpacity="1"
                    fillRule="nonzero"
                />
                <path
                    fill="#d36823"
                    d="M 43.910156 28.128906 L 50.183594 28.128906 L 50.183594 59.363281 L 43.910156 59.363281 Z M 43.910156 28.128906 "
                    fillOpacity="1"
                    fillRule="nonzero"
                />
            </svg>
        </SvgIcon>
    );
}
