import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { HomeFeaturesTablet } from '../HomeFeatures/HomeFeaturesTablet';
import TabletLanding from '../../../MyComponents/Landing/TabletLanding';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import { StartGroupButton } from '../../../MyComponents/StartGroupButton/StartGroupButton';
import { VIDEO_URLS } from '../../../constants';

const useStyles = makeStyles(() => ({
    featuresContainer: {
        position: 'relative',
        marginBottom: '-10rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    bottomGreenPaper: {
        background: 'rgba(8, 170, 180, 0.16)',
        borderRadius: '0rem 0rem 2rem 2rem',
        height: '1.5rem',
        width: '80%',
    },
    loginContainer: {
        background: 'transparent',
        padding: '3rem 1rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

const HomeLandingTablet = ({ handleOpen, language }) => {
    const classes = useStyles();

    const setCreateGroupPath = () => {
        window.localStorage.setItem('pathBeforeLogin', '/creategroup');
    };

    let needsBackground = true;

    return (
        <>
            <TabletLanding videoUrl={VIDEO_URLS['main'][language.toUpperCase()]} needsBackground={needsBackground}>
                <Grid item xs={12} md={7} style={{ zIndex: 1 }}>
                    <Grid
                        item
                        xs={12}
                        md={12}
                        style={{ textAlign: 'center', paddingTop: needsBackground ? '2rem' : '0rem' }}
                    >
                        <Typography variant="h1">
                            <FormattedMessage
                                id={'redesign.page.home.vBoard.tagline.v2'}
                                values={{
                                    b: (chunks) => (
                                        <b>
                                            {chunks}
                                            <br></br>
                                        </b>
                                    ),
                                }}
                            />
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} className={classes.loginContainer}>
                        <StartGroupButton
                            handleOpen={() => {
                                handleOpen(false);
                                setCreateGroupPath();
                            }}
                        />
                    </Grid>
                </Grid>
            </TabletLanding>
            <Grid item xs={12} className={classes.featuresContainer}>
                <HomeFeaturesTablet />
                <Grid container className={classes.bottomGreenPaper} />
            </Grid>
        </>
    );
};

export default HomeLandingTablet;
