import React, { useImperativeHandle, useRef, useState } from 'react';
import { COLORS } from '../../constants';
import drawUtils from '../../pages/LessonPage/drawUtils';
import CompassDIV from '../icons/CompassDIV';
import { DeleteForever } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import VBToolTip from '../Tooltip/VBToolTip';
import { FormattedMessage } from 'react-intl';
import MoveIcon from '../icons/MoveIcon';
import PinIcon from '../icons/PinIcon';
import CompassPenIcon from '../icons/CompassPenIcon';

const COMPASS_WIDTH = 300;
const COMPASS_HEIGHT = 240;

const useStyles = makeStyles(() => ({
    moveButton: {
        position: 'absolute',
        color: COLORS.primaryD2,
        top: '-32px',
        width: '32px',
        height: '32px',
        left: '-16px',
        // borderRadius: '50%',
        // backgroundColor: COLORS.primaryD2 + 33,
    },
    deleteButton: {
        position: 'absolute',
        color: COLORS.secondaryD2,
        right: '50%',
        top: '-32px',
        width: '24px',
        height: '24px',
        borderRadius: '50%',
        backgroundColor: COLORS.secondaryD2 + 33,
    },
    rotateButton: {
        position: 'absolute',
        color: COLORS.primaryD2,
        top: '-32px',
        width: '32px',
        height: '32px',
        right: '-16px',
        // borderRadius: '50%',
        // backgroundColor: COLORS.primaryD2 + 33,
    },
    expandButton: {
        position: 'absolute',
        color: COLORS.primaryD2,
        top: '0px',
        width: '24px',
        height: '24px',
        right: '-24px',
        borderRadius: '50%',
        backgroundColor: COLORS.primaryD2 + 33,
    },

    hiddenActionButton: { width: '32px', height: '32px', backgroundColor: 'none' },
}));
function extractDeg(rotation) {
    let rotateVal = Number.parseFloat(rotation).toFixed(1);
    if (360 == rotateVal || rotateVal == 0) {
        return 0;
    } else {
        return Number.parseFloat(360 - rotateVal).toFixed(1);
    }
}

const CompassComponent = React.forwardRef((props, ref) => {
    let [visible, setVisible] = useState(false);
    let [startPoint, setStartPoint] = useState({ x: 0, y: 0 });
    let [position, setPosition] = useState({
        x: drawUtils.CANVAS_ACTUAL_WIDTH / 3,
        y: drawUtils.CANVAS_ACTUAL_HEIGHT / 3,
    });
    let [rotation, setRotation] = useState(0);
    let [width, setWidth] = useState(COMPASS_WIDTH);
    let [canHandle, setCanHandle] = useState(false);

    const classes = useStyles();

    let refUpdateDebouncer = useRef({
        position: { x: drawUtils.CANVAS_ACTUAL_WIDTH / 3, y: drawUtils.CANVAS_ACTUAL_HEIGHT / 3 },
        debounceFunc: null,
        rotation: 0,
        prevRotation: null,
        // shouldReposition: false,
        width: COMPASS_WIDTH,
        moveX: 0,
        moveY: 0,
        rotationA: null,
        rotationB: null,
        rotationDirection: null,
        crossedMer: false,
        visible: false,
        startPoint: { x: 0, y: 0 },
    });

    useImperativeHandle(ref, () => ({
        showElement(el) {
            let newState = !visible;
            if (el !== undefined && el !== null) {
                newState = el;
                setVisible(el);
                if (refUpdateDebouncer && refUpdateDebouncer.current) {
                    refUpdateDebouncer.current.visible = el;
                }
            } else {
                setVisible(!visible);
                if (refUpdateDebouncer && refUpdateDebouncer.current) {
                    refUpdateDebouncer.current.visible = !visible;
                }
            }
            // if (newState && pos.x === -1 && pos.y === -1) {
            //     refUpdateDebouncer.current.shouldReposition = true;
            //     pos = {
            //         x: drawUtils.CANVAS_ACTUAL_WIDTH / 3,
            //         y: drawUtils.CANVAS_ACTUAL_HEIGHT / 3,
            //     };
            //     setPosition(pos);
            //     refUpdateDebouncer.current.position = pos;
            // }
            setTimeout(() => {
                updatePositions(true);
            }, 200);

            // if (!skipStateUpdate) {
            //     if (props.updateState) {
            //         let state = {
            //             visible: newState,
            //             rotation,
            //             position: pos,
            //             width,
            //         };
            //         props.updateState(state);
            //     }
            // }
        },
        updateScale(startPoint) {
            setStartPoint({ x: startPoint.x, y: startPoint.y });
            refUpdateDebouncer.current.startPoint = { x: startPoint.x, y: startPoint.y };
            // refUpdateDebouncer.current.shouldReposition = true;
            setTimeout(updatePositions, 200);
            // updatePositions();
        },
        getOverlapPoint(x, y, ignoreToleranceForProjection = false) {
            if (!visible) return null;
            let endPoint = document.getElementById('compass-end-point').getBoundingClientRect();
            let startPoint = document.getElementById('compass-start-point').getBoundingClientRect();
            let rect = drawUtils.getCurrentCanvasRect();
            let endPosition = {
                x: endPoint.left - rect.left,
                y: endPoint.top - rect.top,
            };
            let startPosition = {
                x: startPoint.left - rect.left,
                y: startPoint.top - rect.top,
            };

            const TOLERANCE = 10;
            // console.log('=== wtf', position, endPosition, { x, y });

            if (Math.abs(startPosition.x - x) < TOLERANCE && Math.abs(startPosition.y - y) < TOLERANCE)
                return startPosition;
            if (Math.abs(endPosition.x - x) < TOLERANCE && Math.abs(endPosition.y - y) < TOLERANCE) return endPosition;
            let pp = drawUtils.getProjectionPoint(startPosition, endPosition, { x, y });
            if (ignoreToleranceForProjection) {
                return pp;
            }
            if (Math.abs(pp.x - x) < TOLERANCE && Math.abs(pp.y - y) < TOLERANCE) return pp;

            return null;
        },
        getPosition() {
            return position;
        },

        getEndPosition() {
            let endPoint = document.getElementById('compass-end-point').getBoundingClientRect();
            let rect = drawUtils.getCurrentCanvasRect();
            return {
                x: endPoint.left - rect.left,
                y: endPoint.top - rect.top,
            };
        },

        getState() {
            return {
                visible: refUpdateDebouncer.current.visible,
                rotation: refUpdateDebouncer.current.rotation,
                position: refUpdateDebouncer.current.position,
                width: refUpdateDebouncer.current.width,
            };
        },
        setAllowWriting(canWrite) {
            setCanHandle(canWrite);
            setTimeout(() => {
                updatePositions();
            }, 100);
        },

        updateState(data) {
            if (data) {
                setVisible(data.visible);
                if (data.position) {
                    setPosition(data.position);
                    refUpdateDebouncer.current.position = data.position;
                }
                if (data.rotation) {
                    setRotation(data.rotation);
                    refUpdateDebouncer.current.rotation = data.rotation;
                }

                setWidth(data.width);
                // refUpdateDebouncer.current.shouldReposition = true;
                refUpdateDebouncer.current.visible = data.visible;
                refUpdateDebouncer.current.width = data.width;

                // setTimeout(() => {
                //     updatePositions();
                // }, 100);
            }
        },
    }));

    const updatePositions = (shouldCallProps = false) => {
        if (props.updateState) {
            let state = {
                visible: refUpdateDebouncer.current.visible,
                rotation: refUpdateDebouncer.current.rotation,
                position: refUpdateDebouncer.current.position,
                width: refUpdateDebouncer.current.width,
            };
            if (shouldCallProps) {
                props.updateState(state);
            }
        }
    };

    // useEffect(() => {
    //     if (refUpdateDebouncer.current.shouldReposition) {
    //         updatePositions();
    //         refUpdateDebouncer.current.shouldReposition = false;
    //     }
    // });

    window.compassMove = (event, data) => {
        let pos = {
            x: data.x + refUpdateDebouncer.current.moveX,
            y: data.y + refUpdateDebouncer.current.moveY,
        };
        if (pos.x === position.x && pos.y === position.y) return;
        if (!refUpdateDebouncer.current.debounceFunc) {
            refUpdateDebouncer.current.debounceFunc = setTimeout(() => {
                if (refUpdateDebouncer?.current?.position) {
                    setPosition(
                        drawUtils.scalePoint(
                            refUpdateDebouncer.current.startPoint,
                            refUpdateDebouncer.current.position.x,
                            refUpdateDebouncer.current.position.y
                        )
                    );
                }
                refUpdateDebouncer.current.debounceFunc = null;
            }, 30);
        } else {
            refUpdateDebouncer.current.position = pos;
        }
    };

    window.expandCompass = (event, data) => {
        let pos = {
            x: data.x + refUpdateDebouncer.current.moveX,
            y: data.y + refUpdateDebouncer.current.moveY,
        };
        if (pos.x === position.x && pos.y === position.y) return;
        if (!refUpdateDebouncer.current.debounceFunc) {
            refUpdateDebouncer.current.debounceFunc = setTimeout(() => {
                let el = document.getElementById('compass-start-point')?.getBoundingClientRect();
                let rect = drawUtils.getCurrentCanvasRect();

                let x1, x2, y1, y2;
                x1 = data.x + rect.x + refUpdateDebouncer.current.moveX;
                x2 = el.x;
                y1 = data.y + rect.y + refUpdateDebouncer.current.moveY;
                y2 = el.y;
                refUpdateDebouncer.current.rotation = (Math.atan2(y2 - y1, x2 - x1) * 180) / Math.PI + 180;
                setRotation(refUpdateDebouncer.current.rotation);
                refUpdateDebouncer.current.prevRotation = refUpdateDebouncer.current.rotation;

                let p1S = drawUtils.scalePoint(refUpdateDebouncer.current.startPoint, x1, y1);
                let p2S = drawUtils.scalePoint(refUpdateDebouncer.current.startPoint, x2, y2);
                refUpdateDebouncer.current.width = Math.round(
                    Math.sqrt((p2S.x - p1S.x) * (p2S.x - p1S.x) + (p2S.y - p1S.y) * (p2S.y - p1S.y))
                );
                setWidth(refUpdateDebouncer.current.width);

                if (refUpdateDebouncer?.current?.position) {
                    // setPosition(
                    //     drawUtils.scalePoint(
                    //         startPoint,
                    //         refUpdateDebouncer.current.position.x,
                    //         refUpdateDebouncer.current.position.y
                    //     )
                    // );
                }
                refUpdateDebouncer.current.debounceFunc = null;
            }, 30);
        }
    };

    window.compassStopMove = (event, data) => {
        let position = {
            x: data.x + refUpdateDebouncer.current.moveX,
            y: data.y + refUpdateDebouncer.current.moveY,
        };

        if (refUpdateDebouncer.current.debounceFunc) {
            clearTimeout(refUpdateDebouncer.current.debounceFunc);
            refUpdateDebouncer.current.debounceFunc = null;
        }
        let pos = drawUtils.scalePoint(refUpdateDebouncer.current.startPoint, position.x, position.y);
        setPosition(pos);
        refUpdateDebouncer.current.position = pos;
        if (props.updateState) {
            let state = {
                visible: refUpdateDebouncer.current.visible,
                rotation: refUpdateDebouncer.current.rotation,
                position: pos,
                width: refUpdateDebouncer.current.width,
            };
            props.updateState(state);
        }

        // refUpdateDebouncer.current.shouldReposition = true;
    };

    window.stopCompassExpand = () => {
        // return;
        // let position = {
        //     x: data.x + refUpdateDebouncer.current.moveX,
        //     y: data.y + refUpdateDebouncer.current.moveY,
        // };

        if (refUpdateDebouncer.current.debounceFunc) {
            clearTimeout(refUpdateDebouncer.current.debounceFunc);
            refUpdateDebouncer.current.debounceFunc = null;
        }
        // let pos = drawUtils.scalePoint(startPoint, position.x, position.y);
        // setPosition(pos);
        setWidth(refUpdateDebouncer.current.width);
        setRotation(refUpdateDebouncer.current.rotation);
        if (props.updateState) {
            let state = {
                visible: refUpdateDebouncer.current.visible,
                rotation: refUpdateDebouncer.current.rotation,
                position: refUpdateDebouncer.current.position,
                width: refUpdateDebouncer.current.width,
            };
            props.updateState(state);
        }
        // updatePositions();

        // refUpdateDebouncer.current.shouldReposition = true;
    };

    window.rotateCompass = (event, data) => {
        if (!refUpdateDebouncer.current.debounceFunc) {
            refUpdateDebouncer.current.debounceFunc = setTimeout(() => {
                setRotation(refUpdateDebouncer.current.rotation);
                refUpdateDebouncer.current.prevRotation = refUpdateDebouncer.current.rotation;
                refUpdateDebouncer.current.debounceFunc = null;
            }, 30);
        } else {
            let el = document.getElementById('compass-start-point')?.getBoundingClientRect();
            let rect = drawUtils.getCurrentCanvasRect();

            let x1, x2, y1, y2;
            x1 = data.x + rect.x + refUpdateDebouncer.current.moveX;
            x2 = el.x;
            y1 = data.y + rect.y + refUpdateDebouncer.current.moveY;
            y2 = el.y;
            refUpdateDebouncer.current.rotation = (Math.atan2(y2 - y1, x2 - x1) * 180) / Math.PI + 180;

            let currentDeg = parseInt(refUpdateDebouncer.current.rotation);
            let prevDeg = parseInt(refUpdateDebouncer.current.prevRotation);
            if (currentDeg === prevDeg) {
                return;
            }

            let jumpingBorder = false;
            if (Math.abs(currentDeg - prevDeg) > 300) {
                jumpingBorder = true;
            }

            let currentRotation;

            if (!jumpingBorder) {
                if (prevDeg < currentDeg) {
                    currentRotation = 1;
                } else {
                    currentRotation = -1;
                }
            } else {
                if (prevDeg > currentDeg) {
                    currentRotation = 1;
                } else {
                    currentRotation = -1;
                }
            }

            if (!refUpdateDebouncer.current.rotationDirection) {
                refUpdateDebouncer.current.rotationDirection = currentRotation;
            }

            if (refUpdateDebouncer.current.rotationDirection === 1) {
                if (currentRotation === 1) {
                    if (!jumpingBorder) {
                        if (!refUpdateDebouncer.current.crossedMer) {
                            if (refUpdateDebouncer.current.rotationB < currentDeg) {
                                refUpdateDebouncer.current.rotationB = currentDeg;
                            }
                        } else {
                            if (
                                refUpdateDebouncer.current.rotationB < currentDeg &&
                                currentDeg <= refUpdateDebouncer.current.rotationA
                            ) {
                                refUpdateDebouncer.current.rotationB = currentDeg;
                            }
                        }
                    } else {
                        if (!refUpdateDebouncer.current.crossedMer) {
                            refUpdateDebouncer.current.rotationB = currentDeg;
                            refUpdateDebouncer.current.crossedMer = true;
                        }
                    }
                    // console.log('=== should move B 1', refUpdateDebouncer.current.rotationB);
                } else {
                    if (!refUpdateDebouncer.current.crossedMer) {
                        if (refUpdateDebouncer.current.rotationA > currentDeg) {
                            refUpdateDebouncer.current.rotationA = currentDeg;
                        }
                    } else {
                        if (
                            refUpdateDebouncer.current.rotationA > currentDeg &&
                            currentDeg >= refUpdateDebouncer.current.rotationB
                        ) {
                            refUpdateDebouncer.current.rotationA = currentDeg;
                        }
                    }
                    // console.log('=== should move A 1', refUpdateDebouncer.current.rotationA);
                }
            } else {
                if (currentRotation === 1) {
                    if (!jumpingBorder) {
                        if (!refUpdateDebouncer.current.crossedMer) {
                            if (refUpdateDebouncer.current.rotationA < currentDeg) {
                                refUpdateDebouncer.current.rotationA = currentDeg;
                            }
                        } else {
                            if (
                                refUpdateDebouncer.current.rotationA < currentDeg &&
                                currentDeg <= refUpdateDebouncer.current.rotationB
                            ) {
                                refUpdateDebouncer.current.rotationA = currentDeg;
                            }
                        }
                    } else {
                        if (!refUpdateDebouncer.current.crossedMer) {
                            refUpdateDebouncer.current.rotationA = currentDeg;
                            refUpdateDebouncer.current.crossedMer = true;
                        }
                    }
                    // console.log('=== should move A 2', refUpdateDebouncer.current.rotationA);
                } else {
                    if (!jumpingBorder) {
                        if (!refUpdateDebouncer.current.crossedMer) {
                            if (refUpdateDebouncer.current.rotationB > currentDeg) {
                                refUpdateDebouncer.current.rotationB = currentDeg;
                            }
                        } else {
                            if (
                                refUpdateDebouncer.current.rotationB > currentDeg &&
                                currentDeg >= refUpdateDebouncer.current.rotationA
                            ) {
                                refUpdateDebouncer.current.rotationB = currentDeg;
                            }
                        }
                    } else {
                        if (!refUpdateDebouncer.current.crossedMer) {
                            refUpdateDebouncer.current.rotationB = currentDeg;
                            refUpdateDebouncer.current.crossedMer = true;
                        }
                    }
                    // console.log('=== should move B 2', refUpdateDebouncer.current.rotationB);
                }
            }
            if (props.moveArch) {
                props.moveArch(
                    position.x,
                    position.y,
                    width,
                    refUpdateDebouncer.current.rotationDirection,
                    refUpdateDebouncer.current.rotationA,
                    refUpdateDebouncer.current.rotationB
                );
            }
        }
    };

    window.stopCompassRotate = (event, data) => {
        if (refUpdateDebouncer.current.debounceFunc) {
            clearTimeout(refUpdateDebouncer.current.debounceFunc);
            refUpdateDebouncer.current.debounceFunc = null;
        }
        let el = document.getElementById('compass-start-point')?.getBoundingClientRect();
        let rect = drawUtils.getCurrentCanvasRect();

        let x1, x2, y1, y2;
        x1 = data.x + rect.x + refUpdateDebouncer.current.moveX;
        x2 = el.x;
        y1 = data.y + rect.y + refUpdateDebouncer.current.moveY;
        y2 = el.y;
        refUpdateDebouncer.current.rotation = (Math.atan2(y2 - y1, x2 - x1) * 180) / Math.PI + 180;

        setRotation(refUpdateDebouncer.current.rotation);
        refUpdateDebouncer.current.prevRotation = refUpdateDebouncer.current.rotation;
        if (props.updateState) {
            let state = {
                visible,
                rotation: refUpdateDebouncer.current.rotation,
                position,
                width,
            };
            props.updateState(state);
        }

        if (props.addArch) {
            props.addArch(
                position,
                width,
                refUpdateDebouncer.current.rotationDirection,
                refUpdateDebouncer.current.rotationA,
                refUpdateDebouncer.current.rotationB
            );
        }

        refUpdateDebouncer.current.rotationDirection = null;
        refUpdateDebouncer.current.rotationA = 0;
        refUpdateDebouncer.current.rotationB = 0;
        refUpdateDebouncer.current.crossedMer = null;

        // updatePositions();
    };

    window.compassStartMove = (event, data) => {
        let el = document.getElementById('compass-start-point')?.getBoundingClientRect();
        let rect = drawUtils.getCurrentCanvasRect();
        let w = 0;
        let h = 0;
        if (el && rect) {
            let xx = data.x + rect.left;
            let yy = data.y + rect.top;
            w = el.x - xx;
            h = el.y - yy;
        }
        refUpdateDebouncer.current.moveX = w;
        refUpdateDebouncer.current.moveY = h;
        refUpdateDebouncer.current.rotationA = parseInt(rotation);
        refUpdateDebouncer.current.rotationB = parseInt(rotation);
        refUpdateDebouncer.current.rotationDirection = null;
        refUpdateDebouncer.current.crossedMer = false;
        // el = document.getElementById('compass-move-icon-position')?.getBoundingClientRect();
        // console.log('=== start', el.x, el.y, data.x, data.y, w, h);
    };

    window.handleStartCompassRotate = (event, data) => {
        let el = document.getElementById('compass-end-point')?.getBoundingClientRect();
        let rect = drawUtils.getCurrentCanvasRect();
        let w = 0;
        let h = 0;
        if (el && rect) {
            let xx = data.x + rect.left;
            let yy = data.y + rect.top;
            w = el.x - xx;
            h = el.y - yy;
        }
        refUpdateDebouncer.current.moveX = w;
        refUpdateDebouncer.current.moveY = h;
        refUpdateDebouncer.current.rotationA = parseInt(rotation);
        refUpdateDebouncer.current.rotationB = parseInt(rotation);
        refUpdateDebouncer.current.rotationDirection = null;
        refUpdateDebouncer.current.crossedMer = false;
    };

    window.handleStartCompassExpand = (event, data) => {
        let el = document.getElementById('compass-end-point')?.getBoundingClientRect();
        let rect = drawUtils.getCurrentCanvasRect();
        let w = 0;
        let h = 0;
        if (el && rect) {
            let xx = data.x + rect.left;
            let yy = data.y + rect.top;
            w = el.x - xx;
            h = el.y - yy;
        }
        refUpdateDebouncer.current.moveX = w;
        refUpdateDebouncer.current.moveY = h;
        refUpdateDebouncer.current.rotationA = parseInt(rotation);
        refUpdateDebouncer.current.rotationB = parseInt(rotation);
        refUpdateDebouncer.current.rotationDirection = null;
        refUpdateDebouncer.current.crossedMer = false;
    };

    window.hideCompass = () => {
        setVisible(false);
        refUpdateDebouncer.current.visible = false;
        // if (hasShowElement()) {
        // moveRef.current.showElement(false);
        // rotateRef.current.showElement(false);
        // removeRef.current.showElement(false);
        // expandRef.current.showElement(false);
        // }

        if (props.updateState) {
            let state = {
                visible: false,
                rotation,
                position,
                width,
            };
            props.updateState(state);
        }
    };

    let t = 'rotate(' + rotation + 'deg)';

    let rotateVal = extractDeg(rotation);
    if (!position) {
        return null;
    }
    let rswhWH = drawUtils.reverseScalePoint({ x: 0, y: 0 }, width, COMPASS_HEIGHT);

    if (!rswhWH) return null;

    // if (!reverseScaledWHPoints) return;

    let reverseScaledPoint = drawUtils.reverseScalePoint(startPoint, position.x, position.y);
    if (!reverseScaledPoint) return null;
    let px = reverseScaledPoint.x;
    let py = reverseScaledPoint.y;
    let currentRotDif = 0;
    if (refUpdateDebouncer.current) {
        if (!refUpdateDebouncer.current.crossedMer) {
            currentRotDif = Number.parseFloat(
                Math.abs(refUpdateDebouncer.current.rotationA - refUpdateDebouncer.current.rotationB)
            ).toFixed(1);
        } else {
            if (refUpdateDebouncer.current.rotationA < refUpdateDebouncer.current.rotationB) {
                currentRotDif = Number.parseFloat(
                    Math.abs(360 + refUpdateDebouncer.current.rotationA - refUpdateDebouncer.current.rotationB)
                ).toFixed(1);
            } else {
                currentRotDif = Number.parseFloat(
                    Math.abs(refUpdateDebouncer.current.rotationA - refUpdateDebouncer.current.rotationB - 360)
                ).toFixed(1);
            }
        }
        if (
            refUpdateDebouncer.current.rotationA === refUpdateDebouncer.current.rotationB &&
            refUpdateDebouncer.current.rotationDirection
        ) {
            currentRotDif = 360;
        }
    }
    return (
        <div>
            {/*<DraggerImperative*/}
            {/*    ref={moveRef}*/}
            {/*    updateRemoteData={moveCompass}*/}
            {/*    handleStop={stopCompass}*/}
            {/*    handleStart={handleStartMove}*/}
            {/*    cursor={'grab'}*/}
            {/*>*/}
            {/*    <VBToolTip content={<FormattedMessage id="tooltip.lessonPage.move" />}>*/}
            {/*        <div className={classes.hiddenActionButton} />*/}
            {/*    </VBToolTip>*/}
            {/*</DraggerImperative>*/}

            {/*<DraggerImperative ref={removeRef} cursor={'grab'}>*/}
            {/*    <VBToolTip content={<FormattedMessage id="tooltip.lessonPage.remove" />}>*/}
            {/*        <div*/}
            {/*            className={classes.hiddenActionButton}*/}
            {/*            onClick={(event) => {*/}
            {/*                hideCompass();*/}
            {/*            }}*/}
            {/*            onTouchStart={(event) => {*/}
            {/*                hideCompass();*/}
            {/*            }}*/}
            {/*        />*/}
            {/*    </VBToolTip>*/}
            {/*</DraggerImperative>*/}

            {/*<DraggerImperative*/}
            {/*    ref={rotateRef}*/}
            {/*    updateRemoteData={rotateCompass}*/}
            {/*    handleStop={stopCompassRotate}*/}
            {/*    handleStart={handleStartRotate}*/}
            {/*    cursor={'grab'}*/}
            {/*>*/}
            {/*    <div className={classes.hiddenActionButton} />*/}
            {/*</DraggerImperative>*/}
            {/*<DraggerImperative*/}
            {/*    ref={expandRef}*/}
            {/*    updateRemoteData={expandCompass}*/}
            {/*    handleStop={stopCompassExpand}*/}
            {/*    handleStart={handleStartCompassExpand}*/}
            {/*    cursor={'grab'}*/}
            {/*>*/}
            {/*    <VBToolTip content={<FormattedMessage id="tooltip.lessonPage.resize" />}>*/}
            {/*        <div className={classes.hiddenActionButton} />*/}
            {/*    </VBToolTip>*/}
            {/*</DraggerImperative>*/}
            <div
                style={{
                    transform: t,
                    width: rswhWH.x + 'px',
                    height: 'auto',
                    position: 'absolute',
                    left: px + 'px',
                    top: py + 'px',
                    transformOrigin: '0% 0%',
                    display: visible ? 'block' : 'none',
                }}
            >
                <div
                    id="compass-start-point"
                    style={{
                        position: 'absolute',
                        left: '0px',
                        top: '0px',
                    }}
                />
                <div
                    id="compass-end-point"
                    style={{
                        position: 'absolute',
                        right: '0px',
                        top: '0px',
                    }}
                />
                <CompassDIV />
                <CompassPenIcon className={classes.rotateButton} id="compass-rotate-icon-position" />
                <MoveIcon
                    className={classes.expandButton}
                    id="compass-expand-icon-position"
                    style={{ visibility: canHandle ? 'visible' : 'hidden' }}
                />
                <DeleteForever className={classes.deleteButton} id="compass-delete-icon-position" />
                <PinIcon className={classes.moveButton} id="compass-move-icon-position" />
                {rotateVal ? (
                    <VBToolTip content={<FormattedMessage id="tooltip.lessonPage.angle" />}>
                        <div
                            style={{
                                position: 'absolute',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: '0.7rem',
                                transform: `rotate(${rotateVal}deg)`,
                                width: '36px',
                                height: '36px',
                                fontWeight: '700',
                                color: COLORS.primaryD1,
                                backgroundColor: COLORS.primary + '66',
                                borderRadius: '50%',
                                top: '8px',
                            }}
                        >
                            {rotateVal}
                        </div>
                    </VBToolTip>
                ) : null}
                {currentRotDif ? (
                    <VBToolTip content={<FormattedMessage id="tooltip.lessonPage.angle" />}>
                        <div
                            style={{
                                position: 'absolute',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: '0.7rem',
                                transform: `rotate(${rotateVal}deg)`,
                                width: '36px',
                                height: '36px',
                                fontWeight: '700',
                                color: COLORS.primaryD1,
                                backgroundColor: COLORS.primary + '66',
                                borderRadius: '50%',
                                top: '-72px',
                                right: '-16px',
                            }}
                        >
                            {currentRotDif}
                        </div>
                    </VBToolTip>
                ) : null}
            </div>
        </div>
    );
});

export default CompassComponent;
