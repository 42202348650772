import { UPDATE_USER_MESSAGES } from '../actionTypes';

const initialState = {
    userMessages: null,
    unreadCount: null,
};

const userMessages = function (state = initialState, action) {
    switch (action.type) {
        case UPDATE_USER_MESSAGES: {
            const userMessages = action.payload;
            let unreadMessages = [];
            if (userMessages) {
                unreadMessages = userMessages.filter((el) => el.isRead !== true);
            }
            return {
                ...state,
                userMessages,
                unreadCount: unreadMessages.length,
            };
        }
        default:
            return state;
    }
};
export default userMessages;
