import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ShareLessonIcon(props) {
    return (
        <SvgIcon {...props} style={{ verticalAlign: 'bottom' }}>
            <svg viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 3.5C14 4.88125 12.8813 6 11.5 6C10.7844 6 10.1375 5.7 9.68437 5.19063L4.96562 7.57812C4.9875 7.71562 5 7.85625 5 8C5 8.14375 4.9875 8.28438 4.96562 8.42188L9.68437 10.7812C10.1375 10.3 10.7844 10 11.5 10C12.8813 10 14 11.1187 14 12.5C14 13.8813 12.8813 15 11.5 15C10.1187 15 9 13.8813 9 12.5C9 12.1969 9.05312 11.8781 9.15312 11.6344L4.6 9.35938C4.15312 10.0469 3.38125 10.5 2.5 10.5C1.11937 10.5 0 9.38125 0 8C0 6.61875 1.11937 5.5 2.5 5.5C3.38125 5.5 4.15312 5.95312 4.6 6.64062L9.15312 4.36562C9.05312 4.09375 9 3.80312 9 3.5C9 2.11937 10.1187 1 11.5 1C12.8813 1 14 2.11937 14 3.5ZM2.47187 9.5C3.32812 9.5 3.97187 8.82812 3.97187 8C3.97187 7.17188 3.32812 6.5 2.47187 6.5C1.67156 6.5 0.971875 7.17188 0.971875 8C0.971875 8.82812 1.67156 9.5 2.47187 9.5ZM11.5 2C10.6719 2 10 2.67156 10 3.5C10 4.32812 10.6719 5 11.5 5C12.3281 5 13 4.32812 13 3.5C13 2.67156 12.3281 2 11.5 2ZM11.5 14C12.3281 14 13 13.3281 13 12.5C13 11.6719 12.3281 11 11.5 11C10.6719 11 10 11.6719 10 12.5C10 13.3281 10.6719 14 11.5 14Z" fill="#08AAB4"/>
            </svg>
        </SvgIcon>
    );
}
