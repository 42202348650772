import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function OrangeGroupIcon(props) {
    return (
        <SvgIcon {...props} style={{ ...props.style, verticalAlign: 'bottom' }}>
            <svg viewBox="0 0 28 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.9875 3C7.9875 1.34375 6.64375 0 4.9875 0C3.33125 0 1.9875 1.34375 1.9875 3C1.9875 4.65625 3.33125 6 4.9875 6C6.64375 6 7.9875 4.65625 7.9875 3ZM0 11.3312C0 12.25 0.743749 13 1.66875 13H3.48123C4.47349 13 5.30321 12.2944 5.77304 11.4204C6.25909 10.5163 6.95335 9.74101 7.7918 9.15866C8.31966 8.79203 8.31144 8 7.66875 8H3.33125C1.49375 8 0 9.49375 0 11.3312ZM22.227 11.4204C22.6968 12.2944 23.5265 13 24.5188 13H26.3312C27.25 13 28 12.2562 28 11.3312C28 9.4875 26.5063 8 24.6688 8H20.3312C19.6885 8 19.6804 8.79207 20.2083 9.15875C21.0467 9.74109 21.7409 10.5163 22.227 11.4204ZM17.25 10.0625C17.0063 10.0188 16.7563 10 16.5 10H11.5C11.2438 10 10.9938 10.0188 10.75 10.0625C9.125 10.3375 7.79375 11.4813 7.25625 13C7.0875 13.4688 7 13.975 7 14.5C7 15.3313 7.66875 16 8.5 16H19.5C20.3313 16 21 15.3313 21 14.5C21 13.975 20.9125 13.4688 20.7437 13C20.2062 11.4813 18.875 10.3375 17.25 10.0625ZM26 3C26 1.34375 24.6562 0 23 0C21.3438 0 20 1.34375 20 3C20 4.65625 21.3438 6 23 6C24.6562 6 26 4.65625 26 3ZM14 8C16.2063 8 18 6.20625 18 4C18 1.79375 16.2063 0 14 0C11.7938 0 10 1.79375 10 4C10 6.20625 11.7938 8 14 8Z" fill="#F9A237"/>
            </svg>
        </SvgIcon>
    );
}
