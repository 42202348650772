import { Grid, Typography } from '@material-ui/core';
import { FormattedMessage, injectIntl } from 'react-intl';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    sectionHeader: {
        paddingBottom: theme.spacing(1),
        fontSize: '2rem',
    },
    sectionImage: {
        padding: theme.spacing(2),
    },

    title: {
        fontWeight: '700',
        textAlign: 'left',
        paddingTop: '2rem',
        paddingBottom: '1rem',
    },
    description: {
        textAlign: 'left',
        paddingBottom: '1rem',
    },
}));

const SectionHeader = ({ refs, title, description, id, youtubeSrc, intl, children, img }) => {
    const classes = useStyles();
    return (
        <Grid item xs={12} sm={12}>
            <section
                ref={(el) => {
                    if (el && el.id && refs) {
                        refs.current[el.id.toLowerCase()] = el;
                    }
                }}
                className={classes.sectionHeader}
                id={id}
            >
                {img && (
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        key={`tutorial-section-chapter-img-${id}`}
                        className={classes.sectionImage}
                    >
                        <img src={img} alt={intl.formatMessage({ id: title })} style={{ border: 'none' }} />
                    </Grid>
                )}
                {title && (
                    <Typography variant="h2" className={classes.title}>
                        <FormattedMessage id={title} />
                    </Typography>
                )}
                {description && (
                    <Typography variant="h4" className={classes.description}>
                        <FormattedMessage id={description} />
                    </Typography>
                )}
                {youtubeSrc && (
                    <iframe
                        width="100%"
                        height="315"
                        src={youtubeSrc}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title={intl.formatMessage({ id: title })}
                    />
                )}
                {children}
            </section>
        </Grid>
    );
};

export default injectIntl(SectionHeader);
