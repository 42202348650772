import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import store from '../../redux/store';
import { showStaticPageNavigator } from '../../redux/actions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import PageNavigator from '../../common/components/PageNavigator';

const StaticPageNavigatorDialog = (props) => {
    return (
        <Dialog
            open={!!props.appState.staticPageNavigator}
            onClose={() => {
                store.dispatch(showStaticPageNavigator(null));
            }}
            fullScreen
        >
            <DialogContent>
                {props.appState.staticPageNavigator ? (
                    <PageNavigator data={props.appState.staticPageNavigator} />
                ) : null}
            </DialogContent>
            <DialogActions style={{ marginBottom: '0.5rem', marginRight: '1rem' }}>
                <Button
                    onClick={() => {
                        store.dispatch(showStaticPageNavigator(null));
                    }}
                    size="large"
                    variant="contained"
                    color="default"
                >
                    <FormattedMessage id="close" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    const { appState } = state;
    return { appState };
};

export default injectIntl(connect(mapStateToProps)(StaticPageNavigatorDialog));
