export const JOIN_REQUEST_STATUS = {
    OPEN: 0,
    ACCEPTED: 1,
    REJECTED: 2,
    BLOCKED: 3,
    CANCELED: 4,
};

export const JOIN_REQUESTER_STATUS = {
    OPEN: 0,
    HIDDEN: 1,
};

export const JOIN_TARGET_STATUS = {
    OPEN: 0,
    HIDDEN: 1,
};
