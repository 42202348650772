import Grid from '@material-ui/core/Grid';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import { COLORS2 } from '../../constants';

const useStyles = makeStyles(() => ({
    wrap: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: COLORS2.dawnBackground,
    },
    divContainer: {
        display: 'flex',
        position: 'absolute',
        backgroundColor: '#FFFFFF',
        borderRadius: '2rem',
        padding: '2rem',
        width: '45rem',
        height: '48rem',
        right: '20%',
    },
    tutorialHomeImg: {
        background: 'transparent',
        borderRadius: '2rem',
        zIndex: 1,
        paddingTop: '2rem',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '4rem',
    },
    landingVideo: {
        borderRadius: '2rem',
        border: '3px solid #065156',
    },
}));

const TabletLanding = (props) => {
    const classes = useStyles();

    return (
        <>
            <Grid item xs={12} style={{ paddingTop: props.needsBackground ? '4.4rem' : '3.8rem' }}>
                <Paper elevation={0} className={classes.wrap}>
                    <Grid container spacing={2} style={{ position: 'relative' }}>
                        {props.needsBackground ? (
                            <>
                                <div className={classes.divContainer} />
                                <Grid item xs={12} md={5} className={classes.tutorialHomeImg}>
                                    <iframe width="569" height="320" controls autoPlay src={props.videoUrl} className={classes.landingVideo}/>
                                </Grid>
                                {props.children}
                            </>
                        ) : (
                            <>
                                {props.children}
                                <Grid item xs={12} md={5} className={classes.tutorialHomeImg}>
                                    <iframe width="569" height="320" controls autoPlay src={props.videoUrl} className={classes.landingVideo}/>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Paper>
            </Grid>
        </>
    );
};

export default TabletLanding;
