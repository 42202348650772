import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function CheckIcon(props) {
    return (
        <SvgIcon {...props} style={{ verticalAlign: 'bottom' }}>
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 0C13.725 0 15.225 0.970312 15.9797 2.39531C17.5219 1.92187 19.2656 2.29688 20.4844 3.51562C21.7031 4.73438 22.0781 6.48281 21.6047 8.02031C23.0297 8.775 24 10.275 24 12C24 13.725 23.0297 15.225 21.6047 15.9797C22.0781 17.5219 21.7031 19.2656 20.4844 20.4844C19.2656 21.7031 17.5172 22.0781 15.9797 21.6047C15.225 23.0297 13.725 24 12 24C10.275 24 8.775 23.0297 8.02031 21.6047C6.47813 22.0781 4.73438 21.7031 3.51562 20.4844C2.29688 19.2656 1.92187 17.5172 2.39531 15.9797C0.970312 15.225 0 13.725 0 12C0 10.275 0.970312 8.775 2.39531 8.02031C1.92187 6.47813 2.29688 4.73438 3.51562 3.51562C4.73438 2.29688 6.48281 1.92187 8.02031 2.39531C8.775 0.970312 10.275 0 12 0ZM16.5469 10.5469C16.9875 10.1062 16.9875 9.39375 16.5469 8.95781C16.1063 8.52188 15.3938 8.51719 14.9578 8.95781L10.5047 13.4109L8.67656 11.5828C8.23594 11.1422 7.52344 11.1422 7.0875 11.5828C6.65156 12.0234 6.64687 12.7359 7.0875 13.1719L9.7125 15.7969C10.1531 16.2375 10.8656 16.2375 11.3016 15.7969L16.5469 10.5469Z" fill="#08AAB4"/>
            </svg>
        </SvgIcon>
    );
}
