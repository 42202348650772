import Button from '@material-ui/core/Button';
import { GA_EVENT_TREE } from '../../../../gaConstants';
import GoogleIcon from '../../../../MyComponents/icons/GoogleIcon';
import React from 'react';

export const GoogleLoginButton = ({ handleLogin, classes }) => {
    return (
        <div style={{ paddingLeft: '1.5rem' }}>
            {!window.isTeams ? (
                <Button
                    size="large"
                    variant="contained"
                    className={classes.loginButtonSmall}
                    onClick={() => {
                        window.logAppActivity(GA_EVENT_TREE.mainPage.links.googleLogin);
                        handleLogin('/google');
                    }}
                    startIcon={<GoogleIcon className={classes.customIcon} />}
                />
            ) : null}
        </div>
    );
};
