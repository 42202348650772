import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function PhoneQR(props) {
    return (
        <SvgIcon {...props}>

            <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'
                 zoomAndPan='magnify' viewBox='0 0 150 150' preserveAspectRatio='xMidYMid meet'
                 version='1.0'>
                <path
                    d='M 141.496094 20.632812 C 140.253906 16.730469 136.085938 14.578125 132.1875 15.820312 L 122.933594 18.777344 L 122.933594 34.347656 L 136.691406 29.953125 C 140.585938 28.707031 142.742188 24.535156 141.496094 20.632812 Z M 141.496094 20.632812 '
                    fillOpacity='1' fillRule='nonzero' />
                <path
                    d='M 60.675781 99.601562 L 60.675781 91.84375 L 60.652344 91.832031 C 60.652344 91.832031 60.667969 91.734375 60.675781 91.539062 L 60.675781 90.464844 C 60.582031 88.054688 59.867188 82.484375 55.828125 73.957031 C 53.777344 69.628906 49.9375 65.796875 48.035156 64.0625 L 59.695312 40.761719 C 61.882812 36.054688 59.855469 30.464844 55.164062 28.265625 C 50.476562 26.074219 48.898438 27.820312 46.050781 32.8125 L 27.09375 64.023438 L 26.996094 64.246094 L 23.746094 69.527344 L 30.507812 111.34375 L 7.425781 150 L 65.160156 150 L 76.480469 131.234375 C 81.519531 130.339844 92.679688 127.136719 104.777344 115.742188 C 105.96875 114.621094 106.8125 113.390625 107.835938 111.828125 L 72.414062 111.828125 C 65.792969 111.828125 60.675781 106.246094 60.675781 99.601562 Z M 60.675781 99.601562 '
                    fillOpacity='1' fillRule='nonzero' />
                <path
                    d='M 133.175781 41.125 L 122.933594 41.125 L 122.933594 55.648438 L 133.175781 55.648438 C 137.160156 55.648438 140.394531 52.394531 140.394531 48.386719 C 140.394531 44.375 137.164062 41.125 133.175781 41.125 Z M 133.175781 41.125 '
                    fillOpacity='1' fillRule='nonzero' />
                <path
                    d='M 129.074219 58.550781 L 122.933594 58.550781 L 122.933594 73.074219 L 129.074219 73.074219 C 133.179688 73.074219 136.515625 69.824219 136.515625 65.8125 C 136.515625 61.804688 133.179688 58.550781 129.074219 58.550781 Z M 129.074219 58.550781 '
                    fillOpacity='1' fillRule='nonzero' />
                <path
                    d='M 125.300781 75.980469 L 122.933594 75.980469 L 122.933594 90.503906 L 125.300781 90.503906 C 129.347656 90.503906 132.632812 87.25 132.632812 83.242188 C 132.632812 79.234375 129.347656 75.980469 125.300781 75.980469 Z M 125.300781 75.980469 '
                    fillOpacity='1' fillRule='nonzero' />
                <path
                    d='M 115.445312 93.011719 L 69.296875 93.011719 L 69.296875 8.207031 L 115.445312 8.207031 Z M 92.132812 103.667969 C 90.402344 103.667969 89 102.25 89 100.515625 C 89 98.777344 90.402344 97.367188 92.132812 97.367188 C 93.859375 97.367188 95.261719 98.777344 95.261719 100.515625 C 95.261719 102.253906 93.859375 103.667969 92.132812 103.667969 Z M 87.558594 2.640625 L 97.519531 2.640625 C 98.183594 2.640625 98.726562 3.179688 98.726562 3.847656 C 98.726562 4.515625 98.183594 5.058594 97.519531 5.058594 L 87.558594 5.058594 C 86.894531 5.058594 86.355469 4.515625 86.355469 3.847656 C 86.355469 3.179688 86.894531 2.640625 87.558594 2.640625 Z M 111.355469 -0.0234375 L 72.90625 -0.0234375 C 68.125 -0.0234375 64.246094 3.878906 64.246094 8.6875 L 64.246094 99.695312 C 64.246094 104.515625 68.125 108.417969 72.90625 108.417969 L 111.355469 108.417969 C 116.140625 108.417969 120.023438 104.515625 120.023438 99.695312 L 120.023438 8.6875 C 120.023438 3.878906 116.136719 -0.0234375 111.355469 -0.0234375 Z M 111.355469 -0.0234375 '
                    fillOpacity='1' fillRule='evenodd' />
                <path
                    d='M 84.984375 30.253906 L 76.535156 30.253906 L 76.535156 21.808594 L 84.984375 21.808594 Z M 86.320312 20.476562 L 75.199219 20.476562 L 75.199219 31.589844 L 86.320312 31.589844 L 86.320312 20.476562 '
                    fillOpacity='1' fillRule='nonzero' />
                <path
                    d='M 107.222656 30.253906 L 98.769531 30.253906 L 98.769531 21.808594 L 107.222656 21.808594 Z M 108.554688 20.476562 L 97.4375 20.476562 L 97.4375 31.589844 L 108.554688 31.589844 L 108.554688 20.476562 '
                    fillOpacity='1' fillRule='nonzero' />
                <path
                    d='M 105.378906 23.648438 L 100.613281 23.648438 L 100.613281 28.414062 L 105.378906 28.414062 L 105.378906 23.648438 '
                    fillOpacity='1' fillRule='nonzero' />
                <path
                    d='M 83.144531 23.648438 L 78.375 23.648438 L 78.375 28.414062 L 83.144531 28.414062 L 83.144531 23.648438 '
                    fillOpacity='1' fillRule='nonzero' />
                <path
                    d='M 78.375 50.640625 L 83.144531 50.640625 L 83.144531 45.875 L 78.375 45.875 L 78.375 50.640625 '
                    fillOpacity='1' fillRule='nonzero' />
                <path
                    d='M 76.535156 44.035156 L 84.984375 44.035156 L 84.984375 52.480469 L 76.535156 52.480469 Z M 75.199219 53.816406 L 86.320312 53.816406 L 86.320312 42.703125 L 75.199219 42.703125 L 75.199219 53.816406 '
                    fillOpacity='1' fillRule='nonzero' />
                <path
                    d='M 90.03125 20.480469 L 88.175781 20.480469 L 88.175781 22.335938 L 90.03125 22.335938 L 90.03125 20.480469 '
                    fillOpacity='1' fillRule='nonzero' />
                <path
                    d='M 91.890625 24.1875 L 93.742188 24.1875 L 93.742188 22.335938 L 95.597656 22.335938 L 95.597656 20.480469 L 91.890625 20.480469 L 91.890625 24.1875 L 90.039062 24.1875 L 90.039062 26.039062 L 91.890625 26.039062 L 91.890625 24.1875 '
                    fillOpacity='1' fillRule='nonzero' />
                <path
                    d='M 88.183594 27.894531 L 88.183594 29.746094 L 90.039062 29.746094 L 90.039062 26.039062 L 88.183594 26.039062 L 88.183594 27.894531 '
                    fillOpacity='1' fillRule='nonzero' />
                <path
                    d='M 93.742188 27.894531 L 91.890625 27.894531 L 91.890625 29.746094 L 93.742188 29.746094 L 93.742188 27.894531 '
                    fillOpacity='1' fillRule='nonzero' />
                <path
                    d='M 95.597656 26.039062 L 93.742188 26.039062 L 93.742188 27.894531 L 95.597656 27.894531 L 95.597656 26.039062 '
                    fillOpacity='1' fillRule='nonzero' />
                <path
                    d='M 90.039062 31.597656 L 91.890625 31.597656 L 91.890625 29.746094 L 90.039062 29.746094 L 90.039062 31.597656 '
                    fillOpacity='1' fillRule='nonzero' />
                <path
                    d='M 95.597656 29.746094 L 93.742188 29.746094 L 93.742188 31.597656 L 95.597656 31.597656 L 95.597656 29.746094 '
                    fillOpacity='1' fillRule='nonzero' />
                <path
                    d='M 77.054688 39.011719 L 75.199219 39.011719 L 75.199219 40.863281 L 77.054688 40.863281 L 77.054688 39.011719 '
                    fillOpacity='1' fillRule='nonzero' />
                <path
                    d='M 78.90625 35.296875 L 77.054688 35.296875 L 77.054688 33.445312 L 75.199219 33.445312 L 75.199219 37.152344 L 78.90625 37.152344 L 78.90625 35.296875 '
                    fillOpacity='1' fillRule='nonzero' />
                <path
                    d='M 80.761719 39.003906 L 80.761719 37.152344 L 78.90625 37.152344 L 78.90625 39.003906 L 80.761719 39.003906 '
                    fillOpacity='1' fillRule='nonzero' />
                <path
                    d='M 80.761719 39.003906 L 80.761719 40.855469 L 84.46875 40.855469 L 84.46875 39.003906 L 80.761719 39.003906 '
                    fillOpacity='1' fillRule='nonzero' />
                <path
                    d='M 84.46875 35.296875 L 82.613281 35.296875 L 82.613281 37.152344 L 84.46875 37.152344 L 84.46875 35.296875 '
                    fillOpacity='1' fillRule='nonzero' />
                <path
                    d='M 80.761719 33.445312 L 80.761719 35.296875 L 82.613281 35.296875 L 82.613281 33.445312 L 80.761719 33.445312 '
                    fillOpacity='1' fillRule='nonzero' />
                <path
                    d='M 84.46875 39.003906 L 86.320312 39.003906 L 86.320312 37.152344 L 84.46875 37.152344 L 84.46875 39.003906 '
                    fillOpacity='1' fillRule='nonzero' />
                <path
                    d='M 84.46875 35.296875 L 86.320312 35.296875 L 86.320312 33.445312 L 84.46875 33.445312 L 84.46875 35.296875 '
                    fillOpacity='1' fillRule='nonzero' />
                <path
                    d='M 93.746094 51.984375 L 95.597656 51.984375 L 95.597656 50.128906 L 93.746094 50.128906 L 93.746094 51.984375 '
                    fillOpacity='1' fillRule='nonzero' />
                <path
                    d='M 90.039062 51.984375 L 90.039062 53.835938 L 93.742188 53.835938 L 93.742188 51.984375 L 90.039062 51.984375 '
                    fillOpacity='1' fillRule='nonzero' />
                <path
                    d='M 90.039062 50.128906 L 91.890625 50.128906 L 91.890625 48.277344 L 90.039062 48.277344 L 90.039062 50.128906 '
                    fillOpacity='1' fillRule='nonzero' />
                <path
                    d='M 91.890625 48.277344 L 93.742188 48.277344 L 93.742188 46.425781 L 91.890625 46.425781 L 91.890625 48.277344 '
                    fillOpacity='1' fillRule='nonzero' />
                <path
                    d='M 88.183594 50.128906 L 88.183594 51.984375 L 90.039062 51.984375 L 90.039062 50.128906 L 88.183594 50.128906 '
                    fillOpacity='1' fillRule='nonzero' />
                <path
                    d='M 95.597656 37.164062 L 93.746094 37.164062 L 93.746094 39.015625 L 95.597656 39.015625 L 95.597656 37.164062 '
                    fillOpacity='1' fillRule='nonzero' />
                <path
                    d='M 91.890625 37.164062 L 91.890625 35.308594 L 90.039062 35.308594 L 90.039062 37.164062 L 91.890625 37.164062 '
                    fillOpacity='1' fillRule='nonzero' />
                <path
                    d='M 93.742188 33.457031 L 91.890625 33.457031 L 91.890625 35.308594 L 93.742188 35.308594 L 93.742188 33.457031 '
                    fillOpacity='1' fillRule='nonzero' />
                <path
                    d='M 88.183594 39.015625 L 90.039062 39.015625 L 90.039062 37.164062 L 88.183594 37.164062 L 88.183594 39.015625 '
                    fillOpacity='1' fillRule='nonzero' />
                <path
                    d='M 88.183594 33.457031 L 88.183594 35.308594 L 90.039062 35.308594 L 90.039062 33.457031 L 88.183594 33.457031 '
                    fillOpacity='1' fillRule='nonzero' />
                <path
                    d='M 106.703125 46.4375 L 108.558594 46.4375 L 108.558594 44.582031 L 106.703125 44.582031 L 106.703125 46.4375 '
                    fillOpacity='1' fillRule='nonzero' />
                <path
                    d='M 104.863281 40.871094 L 104.863281 42.722656 L 108.570312 42.722656 L 108.570312 40.871094 L 104.863281 40.871094 '
                    fillOpacity='1' fillRule='nonzero' />
                <path
                    d='M 103.007812 44.574219 L 104.863281 44.574219 L 104.863281 42.722656 L 103.007812 42.722656 L 103.007812 44.574219 '
                    fillOpacity='1' fillRule='nonzero' />
                <path
                    d='M 99.304688 40.871094 L 99.304688 42.722656 L 101.15625 42.722656 L 101.15625 40.871094 L 99.304688 40.871094 '
                    fillOpacity='1' fillRule='nonzero' />
                <path
                    d='M 102.996094 51.984375 L 97.453125 51.984375 L 97.453125 53.835938 L 103.007812 53.835938 L 103.007812 51.984375 L 106.691406 51.992188 L 106.691406 53.84375 L 108.558594 53.84375 L 108.558594 51.992188 L 106.703125 51.992188 L 106.703125 50.128906 L 108.558594 50.128906 L 108.558594 48.277344 L 102.996094 48.277344 L 102.996094 51.984375 '
                    fillOpacity='1' fillRule='nonzero' />
                <path
                    d='M 106.691406 39.019531 L 108.546875 39.019531 L 108.546875 37.167969 L 106.691406 37.167969 L 106.691406 39.019531 '
                    fillOpacity='1' fillRule='nonzero' />
                <path
                    d='M 90.039062 46.425781 L 91.878906 46.425781 L 91.878906 44.578125 L 95.203125 44.578125 L 95.203125 42.722656 L 97.4375 42.722656 L 97.4375 40.878906 L 99.292969 40.878906 L 99.292969 40.871094 L 99.304688 40.871094 L 99.304688 39.019531 L 104.851562 39.019531 L 104.851562 35.304688 L 108.558594 35.304688 L 108.558594 33.453125 L 104.851562 33.453125 L 104.851562 35.304688 L 102.996094 35.304688 L 102.996094 37.160156 L 99.304688 37.160156 L 99.304688 35.304688 L 101.144531 35.304688 L 101.144531 33.453125 L 97.449219 33.453125 L 97.449219 35.308594 L 95.597656 35.308594 L 95.597656 37.164062 L 97.449219 37.164062 L 97.449219 37.167969 L 97.4375 37.167969 L 97.4375 39.019531 L 97.449219 39.019531 L 97.449219 39.023438 L 97.4375 39.023438 L 97.4375 40.867188 L 93.742188 40.867188 L 93.742188 39.015625 L 90.039062 39.015625 L 90.039062 40.871094 L 91.890625 40.871094 L 91.878906 40.871094 L 91.878906 44.570312 L 88.171875 44.570312 L 88.171875 48.277344 L 90.039062 48.277344 L 90.039062 46.425781 '
                    fillOpacity='1' fillRule='nonzero' />
                <path
                    d='M 90.027344 40.867188 L 88.171875 40.867188 L 88.171875 42.71875 L 90.027344 42.71875 L 90.027344 40.867188 '
                    fillOpacity='1' fillRule='nonzero' />
                <path
                    d='M 103.007812 46.429688 L 103.007812 44.574219 L 99.304688 44.574219 L 99.304688 42.722656 L 97.449219 42.722656 L 97.449219 44.578125 L 95.574219 44.578125 L 95.574219 46.433594 L 97.425781 46.433594 L 97.425781 46.429688 L 97.4375 46.429688 L 97.4375 46.433594 L 97.449219 46.433594 L 97.449219 48.277344 L 95.597656 48.277344 L 95.597656 50.128906 L 101.144531 50.128906 L 101.144531 48.277344 L 99.304688 48.277344 L 99.304688 46.429688 L 103.007812 46.429688 '
                    fillOpacity='1' fillRule='nonzero' />
                <g>
                    <path fill='#00acb5'
                          d='M 69.722656 25.109375 L 71.179688 25.109375 L 71.179688 16.457031 L 79.835938 16.457031 L 79.835938 15 L 69.722656 15 L 69.722656 25.109375 '
                          fillOpacity='1' fillRule='nonzero' />
                </g>
                <g>
                    <path fill='#00acb5'
                          d='M 103.933594 15 L 103.933594 16.457031 L 112.589844 16.457031 L 112.589844 25.109375 L 114.042969 25.109375 L 114.042969 15 L 103.933594 15 '
                          fillOpacity='1' fillRule='nonzero' />
                </g>
                <g>
                    <path fill='#00acb5'
                          d='M 112.589844 57.863281 L 103.933594 57.863281 L 103.933594 59.320312 L 114.042969 59.320312 L 114.042969 49.210938 L 112.589844 49.210938 L 112.589844 57.863281 '
                          fillOpacity='1' fillRule='nonzero' />
                </g>
                <g>
                    <path fill='#00acb5'
                          d='M 71.179688 49.210938 L 69.722656 49.210938 L 69.722656 59.320312 L 79.835938 59.320312 L 79.835938 57.863281 L 71.179688 57.863281 L 71.179688 49.210938 '
                          fillOpacity='1' fillRule='nonzero' />
                </g>
            </svg>
        </SvgIcon>
    );
}
