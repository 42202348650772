import React from 'react';
import { connect } from 'react-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { FormattedMessage, injectIntl } from 'react-intl';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { useHistory } from 'react-router-dom';
import { raiseHand, shareLesson, showMultiBoardLessonState, userJoinRequest } from '../../redux/actions';
import store from '../../redux/store';
import DraftsIcon from '@material-ui/icons/Drafts';
import Fab from '@material-ui/core/Fab';
import socketUtils from '../../pages/LessonPage/socketUtils';
import HandRaisedIcon from '../icons/HandRaisedIcon';
import RaiseHandIcon from '../icons/RaiseHandIcon';
import { COLORS2 } from '../../constants';
import MultiBoardIcon from '../icons/MultiBoardIcon';
import { GA_EVENT_TREE } from '../../gaConstants';
import LibrarySideMenuIcon from '../icons/LibrarySideMenuIcon';
import MyGroupsIcon from '../icons/MyGroupsIcon';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

const MANAGE_COLLECTIONS = {
    text: 'page.home.feature.library.tag',
    link: '/library',
    icon: (
        <ListItemIcon>
            <LibrarySideMenuIcon style={{ width: '1rem', height: '1rem' }} />
        </ListItemIcon>
    ),
};

const LEAVE_CLASS_LINK = {
    text: 'menu.leaveClass',
    link: '/manageClasses',
    icon: (
        <ListItemIcon>
            <ArrowBackIosIcon style={{ color: COLORS2.virtualGreen }} />
        </ListItemIcon>
    ),
};

const SHARE_LESSON_LINK = {
    text: 'menu.shareLesson',
    link: null,
    icon: (
        <ListItemIcon>
            <PersonAddIcon style={{ color: COLORS2.virtualGreen }} />
        </ListItemIcon>
    ),
    handler: () => {
        window.logAppActivity(GA_EVENT_TREE.lessonPage.menuActions.share);
        store.dispatch(shareLesson({ id: window.location.pathname.split('/')[2] }));
    },
};

const JOIN_REQUEST_LINK = {
    text: 'menu.joinRequests',
    link: null,
    icon: (
        <ListItemIcon>
            <DraftsIcon style={{ color: COLORS2.virtualGreen }} />
        </ListItemIcon>
    ),
    handler: () => {
        window.logAppActivity(GA_EVENT_TREE.lessonPage.menuActions.requests);
        store.dispatch(userJoinRequest({ classId: window.location.pathname.split('/')[2], userId: null }));
    },
};

let ADMIN_LESSON_STATE = [LEAVE_CLASS_LINK, SHARE_LESSON_LINK, JOIN_REQUEST_LINK];

let MEMBER_LESSON_STATE = [
    {
        text: 'menu.leaveClass',
        link: '/manageClasses',
        icon: (
            <ListItemIcon>
                <ArrowBackIosIcon style={{ color: COLORS2.virtualGreen }} />
            </ListItemIcon>
        ),
    },
];

const useStyles = makeStyles(() => ({
    listItem: {
        width: '100%',
        height: '3.5rem',

        '&:hover': {
            width: '100%',
            height: '3.5rem',
            background: COLORS2.virtualBackground,
            borderRadius: '0.5rem',
        },
    },
}));

const SideMenu = (props) => {
    const classes = useStyles();

    const MULTI_BOARD_SESSIONS = {
        text: 'menu.pastLessons',
        link: null,
        icon: (
            <ListItemIcon>
                <MultiBoardIcon style={{ color: COLORS2.virtualGreen }} />
            </ListItemIcon>
        ),
        handler: () => {
            // socketUtils.switchBoardType(BOARD_TYPE.SINGLE_BOARD);
            // store.dispatch(switchBoardType(BOARD_TYPE.SINGLE_BOARD));
            window.logAppActivity(GA_EVENT_TREE.lessonPage.menuActions.seeIndividualSessions);
            store.dispatch(
                showMultiBoardLessonState({ id: null, lessonId: props.lessonState.classId, email: props.profile.email })
            );
        },
    };

    let NO_LESSON_STATE = [
        {
            text: 'menu.manageClass',
            link: '/manageClasses',
            icon: (
                <ListItemIcon>
                    <MyGroupsIcon style={{ color: COLORS2.virtualGreen }} />
                </ListItemIcon>
            ),
            afterComponent:
                props.appState.currentRequestCount && props.appState.currentRequestCount > 0 ? (
                    <span style={{ marginLeft: '0.625rem' }}>
                        <Fab size="small" color="secondary">
                            {props.appState.currentRequestCount}
                        </Fab>
                    </span>
                ) : null,
        },
        MANAGE_COLLECTIONS,
    ];

    let history = useHistory();

    function handleClick(url) {
        if (url) {
            switch (url) {
                case '/':
                    window.logAppActivity(GA_EVENT_TREE.mainPage.menuClick.home);
                    break;
                case '/manageClasses':
                    window.logAppActivity(GA_EVENT_TREE.mainPage.menuClick.myGroups);
                    break;
                default:
                    break;
            }
            history.push(url);
            if (props.closeDrawer) {
                props.closeDrawer();
            }
        }
    }

    let raisedHand = {
        text: 'menu.raiseHand',
        icon: (
            <ListItemIcon>
                <RaiseHandIcon style={{ color: COLORS2.virtualGreen }} />
            </ListItemIcon>
        ),
        handler: () => {
            window.logAppActivity(GA_EVENT_TREE.lessonPage.actions.raiseHand);
            socketUtils.sendRaisedHandValue(!props.lessonState.raisedHand);
            store.dispatch(raiseHand());
        },
        afterComponent: props.lessonState.raisedHand ? (
            <span style={{ marginLeft: '0.625rem' }}>
                <HandRaisedIcon />
            </span>
        ) : null,
    };

    let CURRENT_STATE = [...NO_LESSON_STATE];
    if (props.lessonState.isStarted && props.lessonState.isAdmin) {
        CURRENT_STATE = [...ADMIN_LESSON_STATE, MULTI_BOARD_SESSIONS];
        // if (props.lessonState.boardType === BOARD_TYPE.SINGLE_BOARD) {
        //     CURRENT_STATE.push(SWITCH_TO_SPLIT_BOARD);
        //     // CURRENT_STATE.splice(1, 0, SWITCH_TO_SPLIT_BOARD);
        // } else {
        //     CURRENT_STATE.push(SWITCH_TO_SINGLE_BOARD);
        //     // CURRENT_STATE.splice(1, 0, SWITCH_TO_SINGLE_BOARD);
        // }
    }

    if (props.lessonState.isStarted && !props.lessonState.isAdmin) {
        CURRENT_STATE = [...MEMBER_LESSON_STATE, raisedHand];
    }

    return (
        <List>
            {CURRENT_STATE.map((obj) => (
                <ListItem
                    button
                    className={classes.listItem}
                    key={obj['text']}
                    onClick={() => {
                        if (obj['link']) {
                            handleClick(obj['link']);
                        }
                        if (obj['handler']) {
                            obj['handler']();
                        }
                    }}
                >
                    {obj.icon}
                    <ListItemText>
                        <Typography variant="subtitle1">
                            <FormattedMessage id={obj['text']} />
                        </Typography>
                    </ListItemText>
                    {obj.afterComponent ? obj.afterComponent : null}
                </ListItem>
            ))}
        </List>
    );
};

const mapStateToProps = (state) => {
    const { profile, lessonState, appState } = state;
    return { profile, lessonState, appState };
};

export default injectIntl(connect(mapStateToProps)(SideMenu));
