import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import MathConvertIcon from '../../../../MyComponents/icons/MathConvertIcon';
import SectionChapter from '../../SectionChapter';

const MathConvertorManagement = ({ language, classes, refs }) => {
    let instruments = [
        {
            id: 'redesign.page.tutorial.manageLesson.mathConvertor.explanation1',
            children: (
                <ul>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="redesign.page.tutorial.manageLesson.mathConvertor.line0"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                            <MathConvertIcon className={classes.sampleFab} />
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="redesign.page.tutorial.manageLesson.mathConvertor.line1"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="redesign.page.tutorial.manageLesson.mathConvertor.line2"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            <FormattedMessage
                                id="redesign.page.tutorial.manageLesson.mathConvertor.line3"
                                values={{
                                    b: (chunks) => <b>{chunks}</b>,
                                }}
                            />
                        </Typography>
                    </li>
                </ul>
            ),
            img: [`/static/asset/img/tutorial/withText/${language.toUpperCase()}/MathConvertorBefore.jpg`,
                `/static/asset/img/tutorial/withText/${language.toUpperCase()}/MathConvertorAfter.jpg`,
                `/static/asset/img/tutorial/withText/${language.toUpperCase()}/MathConvertorUpdate.jpg`
            ],
        },
    ];
    return (
        <SectionChapter
            refs={refs}
            id={'section-manageLesson-mathConvertorManagement'}
            title={'redesign.page.tutorial.manageLesson.mathConvertorManagement.title'}
            explanations={instruments}
            imageTop={false}
        />
    );
};

export default MathConvertorManagement;
