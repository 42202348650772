import React, { useEffect, useState } from 'react';
import { Button, Container, Grid, Paper, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import { featureList, replaceValues } from '../../../common/features';
import { COLORS, COLORS2 } from '../../../constants';
import { Link } from 'react-router-dom';
import { SUBSCRIPTIONS_LINK } from '../../../common/urlLinks';
import SpinnerContentRenderer from '../../../MyComponents/Drawer/SpinnerContentRenderer';
import managementApi from '../../../Api/managementApi';
import { FormattedMessage, injectIntl } from 'react-intl';
import ExpandableSubscriptionListRenderer from '../../Subscriptions/ExpandableSubscriptionListRenderer';

// http://localhost:3000/onboarding?step=START&subscription=mariuslobaza
// http://localhost:3000/onboarding?step=START&subscription=ionelselea

const SpecialStage = ({ state, subscriptions, language, isLoggedIn }) => {
    const [specialOffers, setSpecialOffer] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        managementApi
            .getSpecialOffers()
            .then((res) => {
                setSpecialOffer(res.data);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                console.error('Unable to get special data', err);
            });
    }, []);

    if (loading) {
        return (
            <div style={{ width: '100%', textAlign: 'center' }}>
                <SpinnerContentRenderer />
            </div>
        );
    }

    if (!specialOffers || !state.subscription || !specialOffers[state.subscription.toLowerCase()]) {
        return (
            <Grid container spacing={10} style={{ padding: '1.5rem' }}>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    style={{ textAlign: 'center', marginTop: '5rem', backgroundColor: COLORS.secondaryL2 }}
                >
                    <Typography variant="h4">
                        <FormattedMessage id={'subscription.upsell.dialog.title'} />
                    </Typography>
                    <Typography variant="h4">
                        <Link to={SUBSCRIPTIONS_LINK}>
                            <FormattedMessage id={'topMenu.subscriptions.name'} />
                        </Link>
                    </Typography>
                </Grid>
            </Grid>
        );
    }
    let offer = specialOffers[state.subscription.toLowerCase()];

    offer.offers.forEach((el) => {
        let myFeatureList = cloneDeep(featureList);
        replaceValues(subscriptions[el.type], myFeatureList);
        el.myFeatureList = myFeatureList;
    });

    // let myFeatureList = cloneDeep(featureList);
    // console.log('=== offer type', subscriptions, offer.type, subscriptions[offer.type]);
    // replaceValues(subscriptions[offer.type], myFeatureList);

    return (
        <Container maxWidth="lg">
            <Grid container spacing={1} style={{ padding: '1.5rem' }}>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    style={{ textAlign: 'center', marginTop: '5rem', backgroundColor: COLORS.primaryL2 }}
                >
                    <Typography variant="h2">
                        <div dangerouslySetInnerHTML={{ __html: offer.text[language.toUpperCase()] }}></div>
                    </Typography>
                </Grid>
            </Grid>
            {offer.offers.map((el) => {
                return (
                    <Grid container spacing={10} style={{ padding: '1.5rem' }} key={`offers-price-${el.id}`}>
                        <Grid item xs={12} sm={6} style={{ textAlign: 'center' }}>
                            <Paper style={{ background: 'rgba(8, 170, 180, 0.1)' }}>
                                <div
                                    style={{
                                        borderRadius: '10px 10px 0px 0px',
                                        backgroundColor: el.type === 'BASIC' ? COLORS2.virtualAmber : COLORS.primary,
                                        marginTop: '1.5rem',
                                    }}
                                >
                                    <Typography
                                        variant={'h3'}
                                        style={{ height: '4rem', paddingTop: '1rem', color: 'white' }}
                                    >
                                        <FormattedMessage id={`subscriptions.subscription.${el.type}.name`} />
                                    </Typography>
                                </div>
                                <div>
                                    <Typography variant="h5">{el.text[language.toUpperCase()]}</Typography>
                                    <ExpandableSubscriptionListRenderer
                                        featureList={el.myFeatureList}
                                        isLight={false}
                                    />
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} style={{ textAlign: 'center', paddingTop: '4rem' }}>
                            <Typography variant="h4">{el.value}</Typography>
                            {isLoggedIn ? (
                                <form action={`/api/private/profile/specialCheckout?priceId=${el.id}`} method="POST">
                                    <Button type="submit" variant={'contained'} color={'primary'}>
                                        <FormattedMessage id="subscriptions.subscription.checkout" />
                                    </Button>
                                </form>
                            ) : (
                                <Button
                                    size="large"
                                    color="primary"
                                    variant="contained"
                                    onClick={() => {
                                        window.localStorage.setItem(
                                            'pathBeforeLogin',
                                            window.location.pathname + window.location.search
                                        );
                                        window.openAuthentication();
                                    }}
                                >
                                    <FormattedMessage id={'createAccount'} />
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                );
            })}
        </Container>
    );
};

const mapStateToProps = (state) => {
    const { mainAppState } = state;
    return { subscriptions: mainAppState.subscriptions };
};

// export default SpecialStage;
export default injectIntl(connect(mapStateToProps)(SpecialStage));
// export default connect(mapStateToProps)(SpecialStage);
