import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ArrowRightIcon(props) {
    return (
        <SvgIcon {...props} style={{ ...props.style, verticalAlign: 'bottom' }}>
            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.5031 11.9697C15.2101 11.6768 14.7356 11.6768 14.4424 11.9697C14.1493 12.2627 14.1495 12.7372 14.4424 13.0304L17.4406 16.001L14.4709 18.9707C14.1779 19.2636 14.1779 19.7382 14.4709 20.0313C14.7638 20.3244 15.2384 20.3243 15.5315 20.0313L19.0315 16.5313C19.1781 16.3854 19.2499 16.1947 19.2499 16.001C19.2499 15.8072 19.1767 15.6172 19.0302 15.4707L15.5031 11.9697Z" fill="#065156"/>
            </svg>
        </SvgIcon>
    );
}
