export const ItemTypes = {
    COLLECTION: 'COLLECTION',
    BOARD: 'BOARD',
    ROOT: 'ROOT',
    BOARD_SET: 'BOARD_SET',
    USER: 'USER',
    FOREIGN_COLLECTION: 'FOREIGN_COLLECTION',
    FOREIGN_BOARD: 'FOREIGN_BOARD',
    FOREIGN_BOARD_SET: 'FOREIGN_BOARD_SET',
};
