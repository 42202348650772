import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import joinRequestApi from '../../Api/joinRequestApi';
import GenericState from '../../MyComponents/GenericLoadingErrorState/GenericState';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import store from '../../redux/store';
import { currentRequestCount, updateUserOpenJoinRequests } from '../../redux/actions';
import { GA_EVENT_TREE } from '../../gaConstants';
import ResponsiveTable from '../../MyComponents/ResponsiveTable/ResponsiveTable';
import CheckSimpleIcon from '../../MyComponents/icons/CheckSimpleIcon';
import DenyIcon from '../../MyComponents/icons/DenyIcon';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { COLORS2 } from '../../constants';
import Hidden from '@material-ui/core/Hidden';

import { JOIN_REQUEST_STATUS } from '../../shared/JoinRequestStatus';

const useStyles = makeStyles((theme) => ({
    toolbar: theme.mixins.toolbar,
    buttonGroupRequests: {
        border: '0.063rem solid #F9EAE1',
        borderRadius: '0.25rem',
        height: '2rem',
        marginLeft: '1rem',
    },
    flexEnd: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    nameSpan: {
        display: 'inline-flex',
        alignItems: 'center',
        paddingLeft: '0.625rem',
    },
    customButton: {
        backgroundColor: '#FDF9F6',
        fontStyle: 'bold',
        width: '5.188rem',
        borderRadius: '0.125rem 0rem 0rem 0.125rem',
        border: 'none',
    },
    customButtonWhite: {
        backgroundColor: 'white',
        fontStyle: 'bold',
        width: '5.188rem',
        borderRadius: '0.125rem 0rem 0rem 0.125rem',
        border: 'none',
    },
    boldText: {
        color: COLORS2.darkGreen,
        fontWeight: 700,
        fontSize: '0.625rem',
        textTransform: 'uppercase',
        letterSpacing: '0.16rem',
        lineHeight: '2rem',
    },
    normalText: {
        color: COLORS2.darkGreen,
        fontWeight: 400,
        fontSize: '0.625rem',
        textTransform: 'uppercase',
        letterSpacing: '0.16rem',
        lineHeight: '2rem',
    },
    emptyStateH5: {
        fontSize: '1rem',
        margin: '1rem 0rem 0rem 0rem',
        fontWeight: '400',
        textAlign: 'left',
    },
}));

const RequestsTable = (props) => {
    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    const [data, setData] = useState([]);
    const [requestType, setRequestType] = useState(JOIN_REQUEST_STATUS.OPEN);

    useEffect(() => {
        loadJoinRequests(props.classId, requestType);
    }, [props.classId, requestType, props.requestId]);

    const [value, setValue] = useState(false);

    const loadJoinRequests = (classId, requestType) => {
        setIsLoading(true);
        joinRequestApi
            .getJoinRequestsForClass(classId, requestType)
            .then((res) => {
                setData(res.data);
                setIsLoading(false);
                if (requestType === JOIN_REQUEST_STATUS.OPEN) {
                    store.dispatch(updateUserOpenJoinRequests(res.data));
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
                setIsError(true);
            });
    };

    const acceptRequest = (id) => {
        setIsLoading(true);
        window.logAppActivity(GA_EVENT_TREE.joinBoxActions.actions.accept);
        joinRequestApi
            .acceptJoinRequest(id)
            .then(joinRequestApi.getCurrentRequestCount)
            .then((res) => {
                store.dispatch(currentRequestCount(res.data.count));
            })
            .then(() => {
                return loadJoinRequests(props.classId, requestType);
            })
            .then(props.onReload);
    };

    const rejectRequest = (id) => {
        window.logAppActivity(GA_EVENT_TREE.joinBoxActions.actions.reject);
        setIsLoading(true);
        joinRequestApi
            .rejectJoinRequest(id)
            .then(joinRequestApi.getCurrentRequestCount)
            .then((res) => {
                store.dispatch(currentRequestCount(res.data.count));
            })
            .then(() => {
                return loadJoinRequests(props.classId, requestType);
            });
    };

    function composeNameCell(row) {
        let name = row.first_name + ' ' + row.last_name;
        if (row.avatar) {
            return (
                <div style={{ display: 'flex' }}>
                    <span>
                        <Avatar src={row.avatar} />
                    </span>
                    <span className={classes.nameSpan}>{name}</span>
                </div>
            );
        } else {
            return <div>{name}</div>;
        }
    }

    const handleButtonChange = (requestValue) => {
        setRequestType(requestValue);
        setValue(!value);
    };

    const desktopMeta = [
        {
            header: 'redesign.generic.label.name.member',
            build: composeNameCell,
        },
        {
            header: 'redesign.headerEmpty',
            build: function (row) {
                return (
                    <Grid container spacing={1} justify={'flex-end'}>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="default"
                                size="large"
                                disabled={
                                    row.status === JOIN_REQUEST_STATUS.ACCEPTED ||
                                    row.status === JOIN_REQUEST_STATUS.CANCELED
                                }
                                onClick={() => {
                                    acceptRequest(row.id);
                                }}
                            >
                                <FormattedMessage id={'joinRequest.button.acceptRequest'} />
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="secondary"
                                size="large"
                                disabled={
                                    row.status === JOIN_REQUEST_STATUS.ACCEPTED ||
                                    row.status === JOIN_REQUEST_STATUS.REJECTED
                                }
                                onClick={() => {
                                    rejectRequest(row.id);
                                }}
                            >
                                <FormattedMessage id={'joinRequest.button.rejectRequest'} />
                            </Button>
                        </Grid>
                    </Grid>
                );
            },
        },
    ];

    const mobileMeta = [
        {
            header: 'redesign.generic.label.name.member',
            build: function (row) {
                let name = row.first_name + ' ' + row.last_name;
                if (row.avatar) {
                    return (
                        <div style={{ display: 'inline-flex', justifyContent: 'space-between', width: '100%' }}>
                            <div style={{ display: 'inline-flex', width: '100%', alignItems: 'center' }}>
                                <Avatar src={row.avatar} />
                                <span className={classes.nameSpan}>{name}</span>
                            </div>
                            <Grid container spacing={1} justify={'flex-end'}>
                                <Grid item>
                                    <Button
                                        disabled={
                                            row.status === JOIN_REQUEST_STATUS.ACCEPTED ||
                                            row.status === JOIN_REQUEST_STATUS.CANCELED
                                        }
                                        onClick={() => {
                                            acceptRequest(row.id);
                                        }}
                                        startIcon={<CheckSimpleIcon />}
                                        className={classes.flexEnd}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        disabled={
                                            row.status === JOIN_REQUEST_STATUS.ACCEPTED ||
                                            row.status === JOIN_REQUEST_STATUS.REJECTED
                                        }
                                        onClick={() => {
                                            rejectRequest(row.id);
                                        }}
                                        startIcon={<DenyIcon />}
                                        className={classes.flexEnd}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    );
                } else {
                    return (
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>{name}</div>
                            <Grid container spacing={1} justify={'flex-end'}>
                                <Grid item>
                                    <Button
                                        disabled={
                                            row.status === JOIN_REQUEST_STATUS.ACCEPTED ||
                                            row.status === JOIN_REQUEST_STATUS.CANCELED
                                        }
                                        onClick={() => {
                                            acceptRequest(row.id);
                                        }}
                                        startIcon={<CheckSimpleIcon />}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        disabled={
                                            row.status === JOIN_REQUEST_STATUS.ACCEPTED ||
                                            row.status === JOIN_REQUEST_STATUS.REJECTED
                                        }
                                        onClick={() => {
                                            rejectRequest(row.id);
                                        }}
                                        startIcon={<DenyIcon />}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    );
                }
            },
        },
    ];

    return (
        <>
            <Grid item xs={12} sm={12} style={{ display: 'inline-flex', alignItems: 'center' }}>
                <Typography variant="h5" gutterBottom>
                    <FormattedMessage id="redesign.updateClass.openRequests" />
                </Typography>
                <ToggleButtonGroup className={classes.buttonGroupRequests}>
                    <ToggleButton
                        className={value ? classes.customButtonWhite : classes.customButton}
                        onClick={() => {
                            handleButtonChange(JOIN_REQUEST_STATUS.OPEN);
                        }}
                        value="open"
                    >
                        <Typography className={value ? classes.normalText : classes.boldText}>
                            <FormattedMessage id={'joinRequest.select.opened'} />
                        </Typography>
                    </ToggleButton>
                    <ToggleButton
                        className={value ? classes.customButton : classes.customButtonWhite}
                        onClick={() => {
                            handleButtonChange(JOIN_REQUEST_STATUS.REJECTED);
                        }}
                        value="rejected"
                    >
                        <Typography className={value ? classes.boldText : classes.normalText}>
                            <FormattedMessage id={'joinRequest.select.rejected'} />
                        </Typography>
                    </ToggleButton>
                </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12} sm={12} style={{ minHeight: '5rem' }}>
                {isLoading || isError ? <GenericState isLoading={isLoading} isError={isError} inline={true} /> : null}
                {data.length === 0 ? (
                    <>
                        {!isLoading && !isError ? (
                            <>
                                <Hidden only={['xs']}>
                                    <Container fixed style={{ marginLeft: '-1.3rem' }}>
                                        <Typography variant="h5" gutterBottom className={classes.emptyStateH5}>
                                            <FormattedMessage id={'joinRequest.noOpenRequests'} />
                                        </Typography>
                                    </Container>
                                </Hidden>
                                <Hidden only={['sm','md','lg','xl']}>
                                    <Container fixed style={{ marginLeft: '-0.7rem' }}>
                                        <Typography variant="h5" gutterBottom className={classes.emptyStateH5}>
                                            <FormattedMessage id={'joinRequest.noOpenRequests'} />
                                        </Typography>
                                    </Container>
                                </Hidden>
                            </>
                        ) : null}
                    </>
                ) : (
                    <ResponsiveTable
                        data={data}
                        metaDesktop={desktopMeta}
                        metaMobile={mobileMeta}
                        metaTablet={mobileMeta}
                        styled={true}
                        editStyle={false}
                    />
                )}
            </Grid>
        </>
    );
};

const mapStateToProps = (state) => {
    const { profile } = state;
    return { profile };
};

export default injectIntl(connect(mapStateToProps)(RequestsTable));
