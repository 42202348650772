import React from 'react';
import { YOUTUBE_CLIPS } from '../constants';
import Divider from '@material-ui/core/Divider';
import SectionHeader from '../SectionHeader';
import AddBoards from './LibrarySections/AddBoards';
import UpdateBoards from './LibrarySections/UpdateBoards';
import LoadingBoards from './LibrarySections/LoadingBoards';
import LibraryManagement from './LibrarySections/LibraryManagement';
import ShareLibrary from './LibrarySections/ShareLibrary';

const TutorialLibrary = ({ language, classes, refs }) => {
    return (
        <>
            <Divider variant="middle" />

            <SectionHeader
                refs={refs}
                title={'tutorial.manageLibrary.title'}
                description={'tutorial.manageLibrary.description'}
                id={'section-manageLibrary'}
                youtubeSrc={YOUTUBE_CLIPS.LIBRARY[language.toUpperCase()]}
                imageTop={false}
            />

            <AddBoards refs={refs} language={language} classes={classes} />
            <Divider variant="middle" />
            <UpdateBoards refs={refs} language={language} classes={classes} />
            <Divider variant="middle" />
            <LoadingBoards refs={refs} language={language} classes={classes} />
            <Divider variant="middle" />
            <LibraryManagement refs={refs} language={language} classes={classes} />
            <ShareLibrary refs={refs} language={language} classes={classes} />
        </>
    );
};

export default TutorialLibrary;
