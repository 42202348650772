import React from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import Sticky from 'react-stickynode';
import styles from './styles';
import IndividualBoards from '../Sections/IndividualBoards';
import PasteFeature from '../Sections/PasteFeature';
import MobileFeature from '../Sections/MobileFeature';
import ControlFeature from '../Sections/ControlFeature';
import SpecialLineageFeature from '../Sections/SpecialLineageFeature';
import GroupsFeature from '../Sections/GroupsFeature';
import LibraryFeature from '../Sections/LibraryFeature';
import PdfFeature from '../Sections/PdfFeature';
import AudioVideoFeature from '../Sections/AudioVideoFeature';
import { sections } from '../constants';
import { UPDATE_FEATURES_ACTIVE_SECTION } from '../../../redux/actionTypes';
import { useDispatch } from 'react-redux';
import MobileSummaryMenu from '../../../MyComponents/SummaryMenu/MobileSummaryMenu/MobileSummaryMenu';
import Geometry from '../Sections/Geometry';
import MathConvertor from '../Sections/MathConvertor';
import Divider from '@material-ui/core/Divider';

const FeaturesMobile = ({ language, refs }) => {
    const classes = styles();
    const dispatch = useDispatch();

    // TODO check nesting here - grid container and item logic makes no sense
    return (
        <>
            <Grid item xs={10} sm={10} md={10} xl={10}>
                <Typography variant="h1" className={classes.tutorialMobileTitle}>
                    <FormattedMessage id="topMenu.features.name" />
                </Typography>
            </Grid>
            <Container
                id="section-featuresStart"
                maxWidth="xl"
                className={classes.tutorialRoot}
                ref={(el) => {
                    if (el && el.id) {
                        refs.current[el.id.toLowerCase()] = el;
                    }
                }}
            >
                <Grid container direction="row">
                    <Grid item xs={12} sm={12} md={12} xl={12}>
                        <Sticky enabled={true}>
                            <MobileSummaryMenu
                                sections={sections}
                                path={'/features'}
                                updateFunction={(id) => {
                                    dispatch({ type: UPDATE_FEATURES_ACTIVE_SECTION, payload: { sectionId: id } });
                                }}
                                selectorFunc={(state) => state.tutorial.sectionId}
                            />
                        </Sticky>
                        <IndividualBoards language={language} classes={classes} refs={refs} />
                        <Divider variant="middle" />
                        <PasteFeature language={language} classes={classes} refs={refs} />
                        <Divider variant="middle" />
                        <MobileFeature language={language} classes={classes} refs={refs} />
                        <Divider variant="middle" />
                        <ControlFeature language={language} classes={classes} refs={refs} />
                        <Divider variant="middle" />
                        <SpecialLineageFeature language={language} classes={classes} refs={refs} />
                        <Divider variant="middle" />
                        <GroupsFeature language={language} classes={classes} refs={refs} />
                        <Divider variant="middle" />
                        <LibraryFeature language={language} classes={classes} refs={refs} />
                        <Divider variant="middle" />
                        <PdfFeature language={language} classes={classes} refs={refs} />
                        <Divider variant="middle" />
                        <AudioVideoFeature language={language} classes={classes} refs={refs} />
                        <Divider variant="middle" />
                        <Geometry language={language} classes={classes} refs={refs} />
                        <Divider variant="middle" />
                        <MathConvertor language={language} classes={classes} refs={refs} />
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default FeaturesMobile;
