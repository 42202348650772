import React, { useEffect, useRef, useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import classApi from '../../Api/classApi';
import GenericState from '../../MyComponents/GenericLoadingErrorState/GenericState';
import MembersTable from './MembersTable';
import Typography from '@material-ui/core/Typography';
import RequestsTable from './RequestsTable';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import { lessonInfo, showSuccess } from '../../redux/actions';
import store from '../../redux/store';
import { Link, useHistory } from 'react-router-dom';
import { Divider, Paper, styled } from '@material-ui/core';
import MultiBoardLessonListTable from './MultiBoardLessonListTable';
import { loadListingFromStorageInStore } from '../../common/utils';
import { GA_EVENT_TREE } from '../../gaConstants';
import BlueGroupIcon from '../../MyComponents/icons/BlueGroupIcon';
import ArrowRightIcon from '../../MyComponents/icons/ArrowRightIcon';
import Hidden from '@material-ui/core/Hidden';
import { MANAGE_CLASSES_LINK } from '../../common/urlLinks';
import ArrowLeftIcon from '../../MyComponents/icons/ArrowLeftIcon';
import { COLORS2 } from '../../constants';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: theme.spacing(2),
        '& > *': {
            margin: theme.spacing(2),
            width: '25ch',
        },
    },
    toolbar: theme.mixins.toolbar,
    // container: { width: '100vw' },
    sectionSpacing: {
        margin: theme.spacing(2),
    },
    customDivider: {
        '&.MuiDivider-root': {
            backgroundColor: 'rgba(210, 104, 40, 0.2)',
        },
    },
    customText1: {
        fontWeight: 500,
        fontSize: '1.625rem',
        marginRight: '1rem',
    },
    customText2: {
        fontSize: '1.625rem',
        marginLeft: '1rem',
    },
    positionNavDesktop: {
        display: 'inline-flex',
        alignItems: 'center',
        marginTop: '-12rem',
    },
    positionNavMobile: {
        display: 'inline-flex',
        alignItems: 'center',
        marginBottom: '1rem',
    },
    positionStartButton: {
        textAlign: 'right',
        marginTop: '-7.25rem',
    },
    icon: {
        boxSizing: 'border-box',
        width: '3rem',
        height: '3rem',
        background: 'rgba(8, 170, 180, 0.08)',
        borderRadius: '1rem',
        marginRight: '1rem',
    },
}));

const CustomSwitch = styled(Switch)(({}) => ({
    '& .MuiSwitch-track': {
        marginTop: '0.225rem',
        height: '0.5rem',
        borderRadius: '0.25rem',
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: COLORS2.virtualGreen,
        border: '0.125rem solid #FFFFFF',
        borderRadius: '0.513rem',
        boxShadow: 'none',
    },
}));

const EditClass = (props) => {
    const classes = useStyles();
    let history = useHistory();

    const [name, setName] = useState('');
    const [descriptionName, setDescriptionName] = useState('');
    const [description, setDescription] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [reload, setReload] = useState(false);
    const [state, setState] = useState({
        disableMembersDrawDefault: false,
        muteMembersDefault: true,
        allowMembersToStart: false,
    });

    const serverVals = useRef({ name: null, description: null, disableMembersDrawDefault: false });

    const handleSwitchChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
        updateClass(null, { name, description, ...state, [event.target.name]: event.target.checked });
    };

    useEffect(() => {
        classApi
            .getClass(props.match.params.classId)
            .then((res) => {
                if (!res.data.id) {
                    console.log('no class available');
                    setIsLoading(false);
                    setIsError(true);
                }
                store.dispatch(lessonInfo(res.data));
                setDescription(res.data.description ? res.data.description : '');
                setName(res.data.name);
                setDescriptionName(res.data.name);
                serverVals.current = {
                    name: res.data.name,
                    description: res.data.description,
                    disableMembersDrawDefault: res.data.disableMembersDrawDefault,
                };
                setIsLoading(false);
                setState({
                    disableMembersDrawDefault: res.data.disableMembersDrawDefault,
                    muteMembersDefault: res.data.muteMembersDefault,
                    allowMembersToStart: res.data.allowMembersToStart,
                });

                loadListingFromStorageInStore(props.profile.email, props.match.params.classId);
            })
            .catch((err) => {
                setIsLoading(false);
                setIsError(true);
                console.log(err);
            });
    }, [props.match.params.classId, props.profile.email]);

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

    const updateClass = (event = null, dd = null) => {
        if (!dd) {
            dd = { name, description, ...state };
        }

        if (
            dd.name !== serverVals.current.name ||
            dd.description !== serverVals.current.description ||
            dd.disableMembersDrawDefault !== serverVals.current.disableMembersDrawDefault
        ) {
            setIsLoading(true);
            window.logAppActivity(GA_EVENT_TREE.editGroup.actions.updateMeta);

            classApi.updateClass(props.match.params.classId, dd).then(() => {
                setIsLoading(false);
                serverVals.current = dd;
                store.dispatch(showSuccess('SUCCESSFUL_UPDATE'));
            });
        }
    };

    if (isError) {
        return <GenericState isError={true} />;
    }

    const onReload = () => {
        setReload(!reload);
    };

    const start = () => {
        window.logAppActivity(GA_EVENT_TREE.editGroup.actions.start);
        history.push('/lesson/' + props.match.params.classId);
    };

    return (
        <>
            <Container fixed>
                <CssBaseline />
                <div className={classes.toolbar} />
                <a style={{ display: 'none' }} id="downloadImage" download="myCanvas.png" />
                <Grid container spacing={1}>
                    <Hidden only={['xs', 'sm', 'md']}>
                        <Grid item xs={12} sm={5} className={classes.positionNavDesktop}>
                            <Link to={MANAGE_CLASSES_LINK}>
                                <Typography variant="h4" gutterBottom className={classes.customText1}>
                                    <FormattedMessage id={'page.home.feature.groups.tag'} />
                                </Typography>
                            </Link>
                            <ArrowRightIcon />
                            <Typography variant="h4" gutterBottom className={classes.customText2}>
                                <FormattedMessage id={'redesign.updateClass.edit'} />
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={5} className={classes.positionStartButton}>
                            <Button
                                variant="outlined"
                                color="primary"
                                size="large"
                                onClick={start}
                                style={{ marginRight: '1rem' }}
                            >
                                <FormattedMessage id="generic.label.startLesson" />
                            </Button>
                        </Grid>
                    </Hidden>
                    <Hidden only={['lg', 'xl']}>
                        <Grid item xs={12} sm={5} className={classes.positionNavMobile}>
                            <Link to={MANAGE_CLASSES_LINK}>
                                <ArrowLeftIcon />
                            </Link>
                            <Typography variant="h4" gutterBottom className={classes.customText2}>
                                <FormattedMessage id={'redesign.updateClass.edit'} />
                            </Typography>
                        </Grid>
                    </Hidden>
                    <Grid item sm={2} />
                    <Grid item xs={12}>
                        <Paper
                            className="u-p-full"
                            style={{
                                boxShadow: '0rem 2.5rem 10rem rgba(210, 104, 40, 0.08)',
                                borderRadius: '2rem 2rem 0rem 0rem',
                            }}
                        >
                            <Grid container spacing={4}>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    style={{ display: 'inline-flex', alignItems: 'center', padding: '1.5rem 2rem' }}
                                >
                                    <span className={classes.icon}>
                                        <BlueGroupIcon style={{ marginLeft: '0.75rem', marginTop: '0.75rem' }} />
                                    </span>
                                    <Typography variant="h5" gutterBottom>
                                        {descriptionName}
                                    </Typography>
                                </Grid>
                                <Hidden only={['xs', 'sm']}>
                                    <Grid item xs={12} sm={12} style={{ padding: '0rem 2.5rem 2rem 2rem' }}>
                                        <Divider className={classes.customDivider} />
                                    </Grid>
                                </Hidden>
                                <Hidden only={['md', 'lg', 'xl']}>
                                    <Grid item xs={12} sm={12} style={{ padding: '0rem 0rem 2rem 0rem' }}>
                                        <Divider className={classes.customDivider} />
                                    </Grid>
                                </Hidden>
                                <Grid item xs={12} sm={12} style={{ padding: '0rem 2rem 2rem 2rem' }}>
                                    <Typography variant="h5" gutterBottom>
                                        <FormattedMessage id={'updateClass.classSettings'} />
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} style={{ padding: '0rem 1.5rem 2rem 2rem' }}>
                                    <TextField
                                        id="outlined-required"
                                        label={props.intl.formatMessage({ id: 'redesign.createGroup.className' })}
                                        value={name}
                                        onChange={handleNameChange}
                                        fullWidth
                                        variant="outlined"
                                        disabled={isLoading}
                                        onBlur={updateClass}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} style={{ padding: '0rem 1.5rem 2rem 2rem' }}>
                                    <TextField
                                        id="outlined-multiline-flexible"
                                        label={props.intl.formatMessage({ id: 'createGroup.description' })}
                                        value={description}
                                        onChange={handleDescriptionChange}
                                        fullWidth
                                        variant="outlined"
                                        disabled={isLoading}
                                        onBlur={updateClass}
                                        multiline
                                        rows="4"
                                        rowsMax="4"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} style={{ padding: '0rem 0.5rem 2rem 2rem' }}>
                                    <Hidden only={['xs', 'sm']}>
                                        <FormControlLabel
                                            control={
                                                <CustomSwitch
                                                    checked={state.disableMembersDrawDefault}
                                                    onChange={handleSwitchChange}
                                                    name="disableMembersDrawDefault"
                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                    disabled={isLoading}
                                                    color="default"
                                                />
                                            }
                                            label={
                                                state.disableMembersDrawDefault
                                                    ? props.intl.formatMessage({
                                                          id: 'redesign.updateClass.disableMembersDrawActive',
                                                      })
                                                    : props.intl.formatMessage({
                                                          id: 'redesign.updateClass.disableMembersDrawInactive',
                                                      })
                                            }
                                        />
                                    </Hidden>
                                    <Hidden only={['md', 'lg', 'xl']}>
                                        <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                                            <Typography>
                                                <FormattedMessage
                                                    id={
                                                        state.disableMembersDrawDefault
                                                            ? 'redesign.updateClass.disableMembersDrawActive'
                                                            : 'redesign.updateClass.disableMembersDrawInactive'
                                                    }
                                                />
                                            </Typography>
                                            <FormControlLabel
                                                control={
                                                    <CustomSwitch
                                                        checked={state.disableMembersDrawDefault}
                                                        onChange={handleSwitchChange}
                                                        name="disableMembersDrawDefault"
                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        disabled={isLoading}
                                                        color="default"
                                                    />
                                                }
                                                label={props.intl.formatMessage({ id: 'redesign.headerEmpty' })}
                                            />
                                        </div>
                                    </Hidden>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper
                            className="u-p-full"
                            style={{
                                boxShadow: '0rem 2.5rem 10rem rgba(210, 104, 40, 0.08)',
                            }}
                        >
                            <Hidden only={['xs', 'sm']}>
                                <div style={{ padding: '1.5rem 1rem' }}>
                                    <RequestsTable
                                        classId={props.match.params.classId}
                                        onReload={onReload}
                                        className={classes.sectionSpacing}
                                    />
                                </div>
                            </Hidden>
                            <Hidden only={['md', 'lg', 'xl']}>
                                <RequestsTable
                                    classId={props.match.params.classId}
                                    onReload={onReload}
                                    className={classes.sectionSpacing}
                                />
                            </Hidden>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper
                            className="u-p-full"
                            style={{
                                boxShadow: '0rem 2.5rem 10rem rgba(210, 104, 40, 0.08)',
                            }}
                        >
                            <Hidden only={['xs', 'sm']}>
                                <div style={{ padding: '1.5rem 1rem' }}>
                                    <MembersTable
                                        classId={props.match.params.classId}
                                        reload={reload}
                                        className={classes.sectionSpacing}
                                    />
                                </div>
                            </Hidden>
                            <Hidden only={['md', 'lg', 'xl']}>
                                <MembersTable
                                    classId={props.match.params.classId}
                                    reload={reload}
                                    className={classes.sectionSpacing}
                                />
                            </Hidden>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper
                            className="u-p-full"
                            style={{
                                boxShadow: '0rem 2.5rem 10rem rgba(210, 104, 40, 0.08)',
                                borderRadius: '0rem 0rem 2rem 2rem',
                            }}
                        >
                            <Hidden only={['xs', 'sm']}>
                                <div style={{ padding: '1.5rem 1rem' }}>
                                    <MultiBoardLessonListTable
                                        list={props.appState.multiBoardState}
                                        profileEmail={props.profile.email}
                                        lessonId={props.match.params.classId}
                                    />
                                </div>
                            </Hidden>
                            <Hidden only={['md', 'lg', 'xl']}>
                                <MultiBoardLessonListTable
                                    list={props.appState.multiBoardState}
                                    profileEmail={props.profile.email}
                                    lessonId={props.match.params.classId}
                                />
                            </Hidden>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} />
                </Grid>
            </Container>
        </>
    );
};

const mapStateToProps = (state) => {
    const { profile, appState } = state;
    return { profile, appState };
};

export default injectIntl(connect(mapStateToProps)(EditClass));
