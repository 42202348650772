import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Typography,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import React, { useState } from 'react';
import userApi from '../../Api/userApi';
import { termsHTML } from './terms-20220504';
import ArrowLeftIcon from '../../MyComponents/icons/ArrowLeftIcon';
import { policyHTML } from './policy-20220402';
import { COLORS2, POLICY_VERSION, TERMS_VERSION } from '../../constants';
import store from '../../redux/store';
import {
    currentRequestCount,
    showConsent,
    updateAllowedBoards,
    updateAllowedGroups,
    updateExtendedProfile,
    updateProfile,
    updateUserConsent,
    updateUserPreferences,
    updateUserSubscription,
} from '../../redux/actions';
import joinRequestApi from '../../Api/joinRequestApi';

const SignUpConsent = () => {
    const [termsAndConditions, setTermsAndConditions] = useState(false);
    const [marketing, setMarketing] = useState(false);
    const [specialOffers, setSpecialOffers] = useState(false);
    const [seeTerms, setSeeTerms] = useState(false);
    const [seePolicy, setSeePolicy] = useState(false);

    const handleChangeTerms = (event) => {
        setTermsAndConditions(event.target.checked);
    };

    const handleChangeMarketing = (event) => {
        setMarketing(event.target.checked);
    };

    const handleChangeOffers = (event) => {
        setSpecialOffers(event.target.checked);
    };

    const handleSeeTerms = () => {
        setSeeTerms(true);
    };

    const handleSeePolicy = () => {
        setSeePolicy(true);
    };

    const handleBack = () => {
        setSeeTerms(false);
        setSeePolicy(false);
    };

    const updateConsent = () => {
        userApi
            .updateConsent({
                acceptedTermsAndConditions: termsAndConditions,
                allowMarketing: marketing,
                allowSpecialOffers: specialOffers,
                terms: JSON.stringify(TERMS_VERSION),
                policy: JSON.stringify(POLICY_VERSION),
            })
            .then(userApi.getProfile)
            .then((res) => {
                window.isLoggedIn = true;
                store.dispatch(updateProfile(res.data));
                return userApi.getExtendedProfile().then((response) => {
                    store.dispatch(updateExtendedProfile(response.data.profile));
                    store.dispatch(updateUserPreferences(response.data.preferences));
                    store.dispatch(updateUserSubscription(response.data.subscription));
                    if (response?.data?.preferences?.language) {
                        let l = response?.data?.preferences?.language.toLowerCase();
                        if (l === 'ro' || l === 'en' || l === 'fr') {
                            localStorage.setItem('language', l);
                        }
                    }
                    if (!response.data.consentOption) {
                        store.dispatch(showConsent(true));
                    } else {
                        store.dispatch(updateUserConsent(response.data.consentOption));
                    }
                    userApi
                        .getAllowedGroups()
                        .then((response) => {
                            store.dispatch(updateAllowedGroups(response.data.groupIds));
                        })
                        .catch((err) => {
                            console.error('unable to get allowed groups', err);
                        });
                    userApi
                        .getAllowedBoards()
                        .then((response) => {
                            store.dispatch(updateAllowedBoards(response.data.boardIds));
                        })
                        .catch((err) => {
                            console.error('unable to get allowed boards', err);
                        });
                });
            })
            .then(joinRequestApi.getCurrentRequestCount)
            .then((res) => {
                store.dispatch(currentRequestCount(res.data.count));
            })
            .then(() => {
                let existingPath = window.localStorage.getItem('pathBeforeLogin');
                if (existingPath) {
                    window.localStorage.removeItem('pathBeforeLogin');
                    window.location.replace(existingPath);
                } else {
                    window.location.replace('/');
                }
            })
            // .then(renderDom)
            .catch((err) => {
                console.log('error on profile', err);
                // console.log('==== error on profile1', window.location.pathname);
                // if (err.request && err.request.status === 400) {
                //     window.location.replace('/signup_consent' + window.location.pathname + window.location.search);
                // } else {
                //     handleLogin();
                // }
            });
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '4rem', padding: '3rem' }}>
            {!seeTerms && !seePolicy ? (
                <>
                    <Typography variant="h3">
                        <FormattedMessage id="consent.dialog.title" />
                    </Typography>
                    <FormGroup style={{ marginTop: '2rem' }}>
                        <FormControlLabel
                            control={<Checkbox />}
                            label={<FormattedMessage id="consent.dialog.checkbox.allowMarketing" />}
                            onChange={handleChangeMarketing}
                        />
                        <FormControlLabel
                            control={<Checkbox />}
                            label={<FormattedMessage id="consent.dialog.checkbox.allowSpecialOffers" />}
                            onChange={handleChangeOffers}
                        />
                        <FormControl required={true}>
                            <FormControlLabel
                                control={<Checkbox />}
                                label={
                                    <>
                                        <span>
                                            <FormattedMessage id="consent.dialog.checkbox.termsAndConditions1" />
                                        </span>
                                        <span onClick={handleSeeTerms} style={{ color: COLORS2.virtualGreen }}>
                                            <FormattedMessage id="consent.dialog.checkbox.termsAndConditions2" />
                                        </span>
                                        <span>
                                            <FormattedMessage id="consent.dialog.checkbox.termsAndConditions3" />
                                        </span>
                                        <span onClick={handleSeePolicy} style={{ color: COLORS2.virtualGreen }}>
                                            <FormattedMessage id="consent.dialog.checkbox.termsAndConditions4" />
                                        </span>
                                    </>
                                }
                                onChange={handleChangeTerms}
                            />
                        </FormControl>
                    </FormGroup>
                    <FormHelperText>
                        <FormattedMessage id="consent.dialog.helperText" />
                    </FormHelperText>
                    <div style={{ padding: '1rem' }}>
                        {termsAndConditions ? (
                            <Button color="primary" variant={'contained'} onClick={updateConsent}>
                                <FormattedMessage id="consent.dialog.button.confirm" />
                            </Button>
                        ) : (
                            <Button
                                color="primary"
                                variant={'contained'}
                                disabled={!termsAndConditions}
                                onClick={updateConsent}
                            >
                                <FormattedMessage id="consent.dialog.button.confirm" />
                            </Button>
                        )}
                    </div>
                </>
            ) : seePolicy ? (
                <div style={{ padding: '2rem' }}>
                    <ArrowLeftIcon onClick={handleBack} />
                    <div dangerouslySetInnerHTML={{ __html: policyHTML }} />
                </div>
            ) : (
                <div style={{ padding: '2rem' }}>
                    <ArrowLeftIcon onClick={handleBack} />
                    <div dangerouslySetInnerHTML={{ __html: termsHTML }} />
                </div>
            )}
        </div>
    );
};

export default SignUpConsent;
