import apiUtil from './apiUtil';
import { generateFormDataCollectionBoard } from '../common/utils';

let collectionsApi = {};

collectionsApi.createCollection = function (data) {
    let myData = {
        url: '/api/private/collections',
        method: 'POST',
        data,
    };
    return apiUtil.runRequest(myData);
};

collectionsApi.updateCollection = function (id, data) {
    let myData = {
        url: '/api/private/collections/' + id,
        method: 'PUT',
        data,
    };
    return apiUtil.runRequest(myData);
};

collectionsApi.deleteCollection = function (id) {
    let myData = {
        url: '/api/private/collections/' + id,
        method: 'DELETE',
    };
    return apiUtil.runRequest(myData);
};

collectionsApi.updateBoard = function (id, data) {
    let myData = {
        url: '/api/private/boards/' + id,
        method: 'PUT',
        data,
    };
    return apiUtil.runRequest(myData);
};

collectionsApi.copyStickyImage = function (classId, pageId, imageId, boardId) {
    let myData = {
        url: `/api/private/copyStickyImageToBoard/${classId}/${pageId}/${imageId}/${boardId}`,
        method: 'POST',
    };
    return apiUtil.runRequest(myData);
};

collectionsApi.loadBoardInClass = function (classId, boardId) {
    let myData = {
        url: `/api/private/boards/loadBoardInClass/${classId}/${boardId}`,
        method: 'POST',
    };
    return apiUtil.runRequest(myData);
};

collectionsApi.loadSharedBoardInClass = function (classId, boardId) {
    let myData = {
        url: `/api/private/boards/loadSharedBoardInClass/${classId}/${boardId}`,
        method: 'POST',
    };
    return apiUtil.runRequest(myData);
};

collectionsApi.loadBoardInMultiBoardClass = function (classId, multiBoardId, collectionId, data) {
    let myData = {
        url: `/api/private/boards/loadBoardInMultiBoardClass/${classId}/${multiBoardId}/${collectionId}`,
        method: 'POST',
        data,
    };
    return apiUtil.runRequest(myData);
};

collectionsApi.loadUserBoardInMultiBoardClass = function (classId, multiBoardId, collectionId, data) {
    let myData = {
        url: `/api/private/boards/loadUserBoardInMultiBoardClass/${classId}/${multiBoardId}/${collectionId}`,
        method: 'POST',
        data,
    };
    return apiUtil.runRequest(myData);
};

collectionsApi.moveCollection = function (id, newId) {
    let myData = {
        url: `/api/private/collections/${id}`,
        method: 'POST',
        data: { newId },
    };
    return apiUtil.runRequest(myData);
};

collectionsApi.moveBoard = function (id, newId) {
    let myData = {
        url: `/api/private/boards/${id}`,
        method: 'POST',
        data: { newId },
    };
    return apiUtil.runRequest(myData);
};

collectionsApi.getCollections = function (data) {
    let myData = {
        url: '/api/private/collections',
        method: 'GET',
        data,
    };
    return apiUtil.runRequest(myData);
};

collectionsApi.getBoards = function () {
    let myData = {
        url: '/api/private/boards',
        method: 'GET',
    };
    return apiUtil.runRequest(myData);
};

collectionsApi.getBoardsCount = function () {
    let myData = {
        url: '/api/private/boardCount',
        method: 'GET',
    };
    return apiUtil.runRequest(myData);
};

collectionsApi.getCollection = function (id) {
    let myData = {
        url: '/api/private/collections/' + id,
        method: 'GET',
    };
    return apiUtil.runRequest(myData);
};

collectionsApi.getSharedCollection = function (id) {
    let myData = {
        url: '/api/private/sharedCollections/' + id,
        method: 'GET',
    };
    return apiUtil.runRequest(myData);
};

collectionsApi.getBoard = function (id) {
    let myData = {
        url: '/api/private/boards/' + id,
        method: 'GET',
    };
    return apiUtil.runRequest(myData);
};

collectionsApi.getSharedBoard = function (id) {
    let myData = {
        url: '/api/private/sharedBoards/' + id,
        method: 'GET',
    };
    return apiUtil.runRequest(myData);
};
collectionsApi.deleteBoard = function (id) {
    let myData = {
        url: '/api/private/boards/' + id,
        method: 'DELETE',
    };
    return apiUtil.runRequest(myData);
};
// collectionsApi.addBoardToCollection = function (data) {
//     let myData = {
//         url: '/api/private/boards',
//         method: 'POST',
//         data,
//     };
//     return apiUtil.runRequest(myData);
// };

collectionsApi.addBoardToCollection = function (
    fileEncoded,
    classId,
    imageId,
    meta,
    boardId,
    name,
    description,
    collectionId
) {
    return apiUtil.runRequest({
        url: `/api/private/boards`,
        method: 'post',
        data: generateFormDataCollectionBoard(
            fileEncoded,
            `${imageId}.png`,
            meta,
            boardId,
            classId,
            name,
            description,
            collectionId
        ),
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

collectionsApi.saveBoardSet = function (classId, name, description, collectionId) {
    return apiUtil.runRequest({
        url: `/api/private/boardSet/${classId}`,
        method: 'post',
        data: {
            name,
            description,
            collectionId,
        },
    });
};

collectionsApi.updateBoardData = function (fileEncoded, classId, collectionBoardId, meta, boardId) {
    return apiUtil.runRequest({
        url: `/api/private/boardData/${collectionBoardId}`,
        method: 'put',
        data: generateFormDataCollectionBoard(fileEncoded, `${collectionBoardId}.png`, meta, boardId, classId),
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

collectionsApi.getTags = function () {
    let myData = {
        url: '/api/private/tags',
        method: 'GET',
    };
    return apiUtil.runRequest(myData);
};

collectionsApi.createTag = function (data) {
    let myData = {
        url: '/api/private/tags',
        method: 'POST',
        data,
    };
    return apiUtil.runRequest(myData);
};

collectionsApi.attachTag = function (boardId, tagId) {
    let myData = {
        url: `/api/private/tags/${boardId}/${tagId}`,
        method: 'POST',
    };
    return apiUtil.runRequest(myData);
};

collectionsApi.removeTag = function (boardId, tagId) {
    let myData = {
        url: `/api/private/tags/${boardId}/${tagId}`,
        method: 'DELETE',
    };
    return apiUtil.runRequest(myData);
};

collectionsApi.shareLibrary = function (data) {
    let myData = {
        url: '/api/private/shareLibrary',
        method: 'POST',
        data,
    };
    return apiUtil.runRequest(myData);
};

collectionsApi.getUserForSharedBoards = function (boardCollectionId) {
    let myData = {
        url: '/api/private/emailsForSharedBoards/' + boardCollectionId,
        method: 'GET',
        boardCollectionId,
    };
    return apiUtil.runRequest(myData);
};

collectionsApi.removeShareForUser = function (userId, boardCollectionId) {
    let myData = {
        url: `/api/private/removeShareForUser/${userId}/${boardCollectionId}`,
        method: 'DELETE',
    };
    return apiUtil.runRequest(myData);
};

collectionsApi.getSharingAuthors = function () {
    let myData = {
        url: '/api/private/getSharingAuthors',
        method: 'GET',
    };
    return apiUtil.runRequest(myData);
};

collectionsApi.getCollectionTreeForSharingUser = function (userId) {
    let myData = {
        url: '/api/private/collectionTreeForSharingUser/' + userId,
        method: 'GET',
        userId,
    };
    return apiUtil.runRequest(myData);
};

collectionsApi.getBoardsTreeForSharingUser = function (userId) {
    let myData = {
        url: '/api/private/boardsTreeForSharingUser/' + userId,
        method: 'GET',
        userId,
    };
    return apiUtil.runRequest(myData);
};

export default collectionsApi;
