import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import store from '../../redux/store';
import { upgradeSubscriptionDialog } from '../../redux/actions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { DialogTitle, Grid, IconButton, makeStyles, Paper, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { cloneDeep } from 'lodash';
import { featureList, replaceValues } from '../../common/features';
import { Link } from 'react-router-dom';
import SubscriptionFeaturesSingle from '../../pages/Subscriptions/SubscriptionFeaturesSingle';

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

let upsellMapping = {
    'subscription.upsell.groups.exceeded': 'availableGroups',
    'subscription.upsell.groups.unavailable': 'availableGroups',
    'subscription.upsell.library.exceeded': 'availableLibrary',
    'subscription.upsell.library.unavailable': 'availableLibrary',
    'subscription.upsell.multiBoards.unavailable': 'multipleBoarsInIndividualSessions',
    'subscription.upsell.pdf.unavailable': 'pdfDownload',
    'subscription.upsell.audioVideo.unavailable': 'audioVideo',
    'subscription.upsell.multiBoard.exceeded': 'savedMultiBoardSessionCount',
};

const UpgradeSubscriptionDialog = ({ subscriptions, appState }) => {
    const classes = useStyles();
    const close = () => {
        store.dispatch(upgradeSubscriptionDialog(null));
    };

    let subscriptionsToRender = [];
    let availableSubscriptions = [];
    if (appState.upgradeSubscription && appState.upgradeSubscription.subscriptions && subscriptions)
        appState.upgradeSubscription.subscriptions.forEach((subscriptionName) => {
            let subscription = subscriptions[subscriptionName];
            if (subscription) {
                let myFeatureList = cloneDeep(featureList);
                replaceValues(subscription, myFeatureList);
                availableSubscriptions.push(subscriptionName);
                subscriptionsToRender.push(myFeatureList);
            }
        });

    return (
        <Dialog open={!!appState.upgradeSubscription} onClose={close} fullWidth={true} maxWidth={'xl'}>
            <DialogTitle disableTypography id="alert-dialog-title">
                <Typography variant="h4">
                    <FormattedMessage id="subscription.upsell.dialog.title" />
                </Typography>
                <IconButton aria-label="close" className={classes.closeButton} onClick={close}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent style={{ overflow: 'auto' }}>
                <Grid container spacing={2} justify={'center'} style={{ alignItems: 'center' }}>
                    <Grid item xs={12} sm={6}>
                        {appState?.upgradeSubscription?.key ? (
                            <Typography variant={'h5'} color={'secondary'}>
                                <FormattedMessage id={appState?.upgradeSubscription?.key} />
                            </Typography>
                        ) : null}
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ textAlign: 'center' }}>
                        <Link to={'/subscriptions'}>
                            <Button variant={'contained'} color={'primary'} onClick={close}>
                                <FormattedMessage id="subscriptions.subscription.findOutMore" />
                            </Button>
                        </Link>
                    </Grid>
                    {subscriptionsToRender.map((el, index) => {
                        let isActive = index === 0;
                        return (
                            <Grid item xs={12} md={6} key={`subscriptionType-${availableSubscriptions[index]}`}>
                                <Paper
                                    style={{
                                        background: isActive ? 'rgba(250, 250, 250, 1)' : 'rgb(6, 81, 86)',
                                        boxShadow:
                                            '0rem 0rem 0rem 0.5rem rgba(8, 170, 180, 0.16), 0rem 0.5rem 0.5rem rgba(2, 35, 37, 0.32)',
                                        borderRadius: '2rem',
                                        margin: '1rem 1rem',
                                    }}
                                    elevation={0}
                                >
                                    <SubscriptionFeaturesSingle
                                        featureList={el}
                                        highLightItem={upsellMapping[appState?.upgradeSubscription?.key]}
                                        isActive={isActive}
                                        subscriptionType={availableSubscriptions[index]}
                                        showExpandable={true}
                                        showUpsell={true}
                                    />
                                </Paper>
                            </Grid>
                        );
                    })}
                </Grid>
            </DialogContent>
            <DialogActions style={{ marginBottom: '0.5rem', marginRight: '1rem' }}>
                <Button onClick={close} size="large" variant="contained" color="default">
                    <FormattedMessage id="close" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    const { appState, mainAppState } = state;
    return { appState, subscriptions: mainAppState.subscriptions };
};

export default injectIntl(connect(mapStateToProps)(UpgradeSubscriptionDialog));
