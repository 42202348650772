import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function LoggedOutMenuIcon(props) {
    return (
        <SvgIcon {...props} style={{ ...props.style, verticalAlign: 'bottom' }}>
            <svg viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="6" width="24" height="2" rx="1" fill="#065156"/>
                <rect x="4" width="20" height="2" rx="1" fill="#08AAB4"/>
                <rect x="8" y="12" width="16" height="2" rx="1" fill="#F9A237"/>
            </svg>
        </SvgIcon>
    );
}