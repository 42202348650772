import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import BlueGroupIcon from '../../MyComponents/icons/BlueGroupIcon';
import OrangeGroupIcon from '../../MyComponents/icons/OrangeGroupIcon';
import Avatar from '@material-ui/core/Avatar';
import SortedIcon from '../../MyComponents/icons/SortedIcon';
import React from 'react';
import { StyledBadge } from '../../MyComponents/StyledBadge/StyledBadge';
import { MobilePopUpMyGroups } from '../../MyComponents/MobilePopUps/MobilePopUpMyGroups';
import { MobilePopUpGuestGroups } from '../../MyComponents/MobilePopUps/MobilePopUpGuestGroups';
import { COLORS2 } from '../../constants';

export const buildTabletMeta = (profile, email, lessonStart, localesOptions, setModalItem, isHome = false) => {
    const isMobilePopUp = false;

    return [
        {
            title: function () {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '1.5rem' }}>
                            <Typography
                                style={{
                                    color: COLORS2.virtualGreen,
                                    fontStyle: 'normal',
                                    textTransform: 'uppercase',
                                    fontWeight: 500,
                                    fontSize: '0.625rem',
                                    margin: '0.5rem',
                                    letterSpacing: '0.16rem',
                                }}
                            >
                                <FormattedMessage id="redesign.group.label" />
                            </Typography>
                            <SortedIcon style={{ width: '0.875rem', height: '1rem' }} />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                paddingRight: '4rem',
                            }}
                        >
                            <Typography
                                style={{
                                    color: COLORS2.virtualText,
                                    textTransform: 'uppercase',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    fontSize: '0.625rem',
                                    margin: '1rem',
                                    letterSpacing: '0.16rem',
                                    textAlign: 'right',
                                }}
                            >
                                <FormattedMessage id="redesign.generic.label.owner" />
                            </Typography>
                        </div>
                    </div>
                );
            },
            build: function (row) {
                const first_name = profile.extended.first_name;
                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            height: '5.5rem',
                            paddingLeft: '1.5rem',
                            paddingRight: '0.5rem',
                        }}
                    >
                        <div
                            onClick={() => {
                                lessonStart(row.isMine ? row.id : row.classId);
                            }}
                            style={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                cursor: 'pointer',
                            }}
                        >
                            {row.isMine ? (
                                <StyledBadge badgeContent={row.requests !== '0' ? row.requests : null}>
                                    <span
                                        style={{
                                            boxSizing: 'border-box',
                                            width: '3rem',
                                            height: '3rem',
                                            background: 'rgba(8, 170, 180, 0.08)',
                                            borderRadius: '1rem',
                                        }}
                                    >
                                        <BlueGroupIcon
                                            style={{ position: 'absolute', left: '0.75rem', top: '0.75rem' }}
                                        />
                                    </span>
                                </StyledBadge>
                            ) : (
                                <StyledBadge badgeContent={row.requests !== '0' ? row.requests : null}>
                                    <span
                                        style={{
                                            boxSizing: 'border-box',
                                            width: '3rem',
                                            height: '3rem',
                                            border: '0.063rem solid #FBF0E9',
                                            borderRadius: '1rem',
                                        }}
                                    >
                                        <OrangeGroupIcon
                                            style={{ position: 'absolute', left: '0.725rem', top: '0.7rem' }}
                                        />
                                    </span>
                                </StyledBadge>
                            )}
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    paddingLeft: '1rem',
                                }}
                            >
                                <div>
                                    <div
                                        style={{
                                            fontStyle: 'normal',
                                            fontWeight: '500',
                                            fontSize: '1rem',
                                            letterSpacing: '-0.01rem',
                                            textTransform: 'none',
                                            color: COLORS2.darkGreen,
                                            width: '100%',
                                        }}
                                    >
                                        {row.name}
                                    </div>
                                    <span
                                        style={{
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            fontSize: '0.875rem',
                                            letterSpacing: '-0.01rem',
                                            textTransform: 'none',
                                            color: COLORS2.virtualText,
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {new Date(row.lastaccess ? row.lastaccess : row.updatedAt).toLocaleDateString(
                                            localesOptions,
                                            {
                                                month: 'long',
                                                day: 'numeric',
                                                hour: 'numeric',
                                                minute: 'numeric',
                                            }
                                        )}
                                    </span>
                                </div>
                                {row.isMine ? (
                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                        <span
                                            style={{
                                                paddingLeft: '0.5rem',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                fontSize: '0.875rem',
                                                letterSpacing: '-0.04rem',
                                                textTransform: 'none',
                                                color: COLORS2.darkGreen,
                                            }}
                                        >
                                            {first_name}
                                        </span>
                                        <Avatar
                                            url={profile.avatar}
                                            style={{
                                                width: '1rem',
                                                height: '1rem',
                                                marginLeft: '1rem',
                                                marginRight: isHome ? '4rem' : '0rem',
                                            }}
                                        />
                                    </span>
                                ) : (
                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                        <span
                                            style={{
                                                paddingLeft: '0.5rem',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                fontSize: '0.875rem',
                                                letterSpacing: '-0.01rem',
                                                textTransform: 'none',
                                                color: COLORS2.virtualText,
                                            }}
                                        >
                                            {row.first_name}
                                        </span>
                                        <Avatar
                                            url={row.avatar}
                                            style={{
                                                width: '1rem',
                                                height: '1rem',
                                                marginLeft: '1rem',
                                                marginRight: isHome ? '4rem' : '0rem',
                                            }}
                                        />
                                    </span>
                                )}
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {row.isMine ? (
                                !isHome ? (
                                    <MobilePopUpMyGroups
                                        row={row}
                                        setModalItem={setModalItem}
                                        profile={profile}
                                        localesOptions={localesOptions}
                                        isMobilePopUp={isMobilePopUp}
                                    />
                                ) : null
                            ) : !isHome ? (
                                <MobilePopUpGuestGroups
                                    row={row}
                                    email={email}
                                    localesOptions={localesOptions}
                                    isMobilePopUp={isMobilePopUp}
                                />
                            ) : null}
                        </div>
                    </div>
                );
            },
        },
    ];
};
