import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS2 } from '../../../constants';
import { Backdrop } from '@material-ui/core';
import { GA_EVENT_TREE } from '../../../gaConstants';
import GoogleIcon from '../../../MyComponents/icons/GoogleIcon';
import FacebookIcon from '../../../MyComponents/icons/FacebookIcon';
import MicrosoftIcon from '../../../MyComponents/icons/MicrosoftIcon';
import Hidden from '@material-ui/core/Hidden';
import GoogleClassroomIcon from '../../../MyComponents/icons/GoogleClassroomIcon';

const useStyles = makeStyles((theme) => ({
    connectText: {
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '1rem',
        lineHeight: '1.188rem',
        textAlign: 'center',
        letterSpacing: '0.16em',
        textTransform: 'uppercase',
        color: '#FFFFFF',
        marginBottom: '2.75rem',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        backgroundColor: 'rgba(2, 35, 37, 0.85)',
    },
    animateInHeader: {
        position: 'absolute',
        top: '0px',
        transition: 'transform 0.4s',
        transform: 'scale(1.3) translate(0vw, 25vh)',
    },
    animateOutHeader: {
        position: 'absolute',
        top: '0px',
        transition: 'transform 0.4s',
        transform: 'scale(1) translate(0vw, 0vh)',
    },
    animateInMain: {
        position: 'absolute',
        bottom: '0px',
        transition: 'transform 0.4s',
        transform: 'scale(1.3) translate(0vw, -40vh)',
    },
    animateOutMain: {
        position: 'absolute',
        bottom: '0px',
        transition: 'transform 0.4s',
        transform: 'scale(1) translate(0vw, 0vh)',
    },
    mobileButton: {
        width: '4rem',
        height: '3.5rem',
        marginRight: '1rem',
        backgroundColor: COLORS2.third,
        borderRadius: '16px',
        boxShadow: '0rem 0rem 0rem 0.5rem rgba(8, 170, 180, 0.08),' + '0rem 2rem 2rem -1rem rgba(6, 81, 86, 0.16)',
        color: COLORS2.nero,
        textAlign: 'center',
        textTransform: 'uppercase',
        fontSize: '0.4rem',
        lineHeight: '0.6rem',
        letterSpacing: '0.1rem',
        fontWeight: 600,
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    tabletButton: {
        width: '5rem',
        height: '4rem',
        marginRight: '1rem',
        backgroundColor: COLORS2.third,
        borderRadius: '16px',
        boxShadow: '0rem 0rem 0rem 0.5rem rgba(8, 170, 180, 0.08),' + '0rem 2rem 2rem -1rem rgba(6, 81, 86, 0.16)',
        color: COLORS2.nero,
        textAlign: 'center',
        textTransform: 'uppercase',
        fontSize: '0.5rem',
        lineHeight: '0.875rem',
        letterSpacing: '0.1rem',
        fontWeight: 600,
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    desktopButton: {
        width: '7rem',
        height: '5rem',
        marginRight: '1rem',
        backgroundColor: COLORS2.third,
        borderRadius: '16px',
        boxShadow: '0rem 0rem 0rem 0.5rem rgba(8, 170, 180, 0.08),' + '0rem 2rem 2rem -1rem rgba(6, 81, 86, 0.16)',
        color: COLORS2.nero,
        textAlign: 'center',
        textTransform: 'uppercase',
        fontSize: '0.75rem',
        lineHeight: '0.875rem',
        letterSpacing: '0.1rem',
        fontWeight: 600,
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

const SocialButtonDesktop = ({ onClick, textId, icon, isMicrosoft }) => {
    let classes = useStyles();

    return (
        <>
            <Hidden only={['xs', 'sm', 'md']}>
                <div style={{ paddingLeft: '1.5rem' }}>
                    <div onClick={onClick} className={classes.desktopButton}>
                        <div>{icon}</div>
                        <div style={{ marginTop: '0.5rem' }}>
                            <FormattedMessage id={textId} />
                        </div>
                    </div>
                </div>
            </Hidden>
            <Hidden only={['xs', 'lg', 'xl']}>
                <div style={{ paddingLeft: '1.5rem' }}>
                    <div onClick={onClick} className={classes.tabletButton}>
                        <div>{icon}</div>
                        <div>
                            <FormattedMessage id={textId} />
                        </div>
                    </div>
                </div>
            </Hidden>
            <Hidden only={['sm', 'md', 'lg', 'xl']}>
                <div style={{ paddingLeft: '0.25rem' }}>
                    <div
                        onClick={onClick}
                        className={classes.mobileButton}
                        style={{ marginRight: isMicrosoft ? '0rem' : '1rem' }}
                    >
                        <div>{icon}</div>
                        <div>
                            <FormattedMessage id={textId} />
                        </div>
                    </div>
                </div>
            </Hidden>
        </>
    );
};

export const ResponsiveLoginModal = ({ handleLogin, handleClose, open, fromHeader = true }) => {
    const classes = useStyles();

    if (window.isTeams) return null;

    return (
        <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
            <div
                className={
                    open
                        ? fromHeader
                            ? classes.animateInHeader
                            : classes.animateInMain
                        : fromHeader
                        ? classes.animateOutHeader
                        : classes.animateOutMain
                }
            >
                <div>
                    <Typography className={classes.connectText}>
                        <FormattedMessage id="redesign.page.home.modal.connect" />
                    </Typography>
                </div>
                <div>
                    <div style={{ display: 'flex' }}>
                        <SocialButtonDesktop
                            onClick={(e) => {
                                window.logAppActivity(GA_EVENT_TREE.mainPage.links.googleLogin);
                                handleLogin('/google');
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                            icon={<GoogleIcon />}
                            textId={'home.loginGoogle'}
                            isMicrosoft={false}
                        />
                        <SocialButtonDesktop
                            onClick={(e) => {
                                window.logAppActivity(GA_EVENT_TREE.mainPage.links.googleLogin);
                                handleLogin('/google');
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                            icon={<GoogleClassroomIcon />}
                            textId={'home.loginGoogleClassroom'}
                            isMicrosoft={false}
                        />
                    </div>
                    <div style={{ display: 'flex', marginTop: '2rem' }}>
                        <SocialButtonDesktop
                            onClick={(e) => {
                                window.logAppActivity(GA_EVENT_TREE.mainPage.links.facebookLogin);
                                handleLogin('/facebook');
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                            icon={<FacebookIcon />}
                            textId={'home.loginFacebook'}
                            isMicrosoft={false}
                        />
                        <SocialButtonDesktop
                            onClick={(e) => {
                                window.logAppActivity(GA_EVENT_TREE.mainPage.links.microsoftLogin);
                                handleLogin('/microsoft');
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                            icon={<MicrosoftIcon />}
                            textId={'home.loginMicrosoft'}
                            isMicrosoft={true}
                        />
                    </div>
                </div>
            </div>
        </Backdrop>
    );
};
