import React from 'react';
import { Button, CircularProgress, Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

const GenericState = ({ isLoading, isError, inline = false, forceHeight }) => {
    let height = 'auto';
    if (!inline) {
        height = '100vh';
    }
    if (forceHeight) {
        height = forceHeight;
    }
    if (isLoading) {
        return (
            <div
                style={{
                    width: '100%',
                    height: height,
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'inline-flex',
                }}
            >
                <CircularProgress />
            </div>
        );
    }

    if (isError) {
        return (
            <Grid spacing={5} container direction='row' justify='center' alignItems='center'>
                <Grid item xs={6}>
                    <Typography variant={'h3'}>
                        <FormattedMessage id={'genericError'} />
                    </Typography>
                </Grid>

                <Grid item xs={2}>
                    <Link to={'/'}>
                        <Button variant='outlined' color='primary'>
                            <FormattedMessage id={'home'} />
                        </Button>
                    </Link>
                </Grid>
            </Grid>
        );
    }
    return null;
};

export default GenericState;
