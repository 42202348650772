import apiUtil from './apiUtil';
import { dataURLtoFile, generateFormData, generateFormDataForMultiBoard } from '../common/utils';
import { hideSpinner, showError } from '../redux/actions';
import store from '../redux/store';
import axios from 'axios';

let storageApi = {};

storageApi.getStickyImage = function(classId, pageId, imageId) {
    return apiUtil.runRequest(`/api/private/stickyimage/${classId}/${pageId}/${imageId}`);
};

const handleFileTooLarge = (err) => {
    if (err && err.response && err.response.data && err.response.data.messageKey === 'FILE_TOO_LARGE') {
        store.dispatch(hideSpinner());
        store.dispatch(showError('FILE_TOO_LARGE'));
    }
};

storageApi.uploadStickyImage = function(classId, pageId, imageId, fileEncoded) {
    let returnData;
    return apiUtil
        .runRequest({
            url: `/api/private/stickyimage/${classId}/${pageId}/${imageId}`,
            method: 'post',
            data: generateFormData(null, imageId),
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .then((res) => {
            returnData = res;
            let data = res.data;
            return axios.put(data.upload, dataURLtoFile(fileEncoded, imageId));
        })
        .then(() => {
            return returnData;
        })
        .catch((err) => {
            handleFileTooLarge(err);
            return Promise.reject(err);
        });
};

storageApi.removeStickyImage = function(classId, pageId, imageId) {
    return apiUtil.runRequest({
        url: `/api/private/stickyimage/${classId}/${pageId}/${imageId}`,
        method: 'delete',
    });
};

storageApi.uploadTempImage = function(classId, fileEncoded) {
    return apiUtil
        .runRequest({
            url: `/api/private/tempimage/${classId}`,
            method: 'post',
            data: generateFormData(fileEncoded, `${classId}-${new Date().getTime()}.png`),
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .catch((err) => {
            handleFileTooLarge(err);
            return Promise.reject(err);
        });
};

storageApi.startMultiBoardSession = function(classId, multiBoardId, stickyElements, meta, originalId, fileEncoded) {
    return apiUtil.runRequest({
        url: `/api/private/multiboard/${classId}/${multiBoardId}`,
        method: 'post',
        data: generateFormDataForMultiBoard(fileEncoded, `${multiBoardId}.png`, stickyElements, meta, originalId),
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

storageApi.uploadMultiBoardStickyImage = function(classId, multiBoardId, imageId, userId, fileEncoded) {
    let returnData;

    return apiUtil
        .runRequest({
            url: `/api/private/multiboard/stickyimage/${classId}/${multiBoardId}/${imageId}`,
            method: 'post',
            data: generateFormData(null, imageId, { userId }),
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .then((res) => {
            returnData = res;
            let data = res.data;
            return axios.put(data.upload, dataURLtoFile(fileEncoded, imageId));
        })
        .then(() => {
            return returnData;
        })
        .catch((err) => {
            handleFileTooLarge(err);
            return Promise.reject(err);
        });
};

storageApi.uploadToUrl = function(url, fileEncoded, imageId) {
    return axios.put(url, dataURLtoFile(fileEncoded, imageId), { headers: { 'Content-Type': 'image/png' } });
};

storageApi.deleteMultiBoardStickyImage = function(classId, multiBoardId, imageId, userId) {
    return apiUtil.runRequest({
        url: `/api/private/multiboard/stickyimage/${classId}/${multiBoardId}/${imageId}`,
        method: 'delete',
        data: { userId },
    });
};

storageApi.deleteMultiBoardFiles = function(classId, multiBoardId) {
    return apiUtil.runRequest({
        url: `/api/private/multiboard/${classId}/${multiBoardId}/`,
        method: 'delete',
    });
};

storageApi.signMultiBoardSession = function(classId, multiBoardId, data) {
    return apiUtil.runRequest({
        url: `/api/private/multiboard-sign-urls/${classId}/${multiBoardId}`,
        method: 'post',
        data: data,
    });
};

storageApi.signSingleBoardSession = function(classId, data) {
    return apiUtil.runRequest({
        url: `/api/private/singleboard-sign-urls/${classId}`,
        method: 'post',
        data: data,
    });
};

storageApi.loadMultiBoardSession = function(classId, multiBoardId, pageId, data) {
    return apiUtil.runRequest({
        url: `/api/private/load-multi-in-single/${classId}/${multiBoardId}/${pageId}`,
        method: 'post',
        data: data,
    });
};

storageApi.searchGoogle = function(query, size, offset) {
    return apiUtil.runRequest({
        url: `/api/private/searchGoogle?q=${query}&s=${size}&o=${offset}`,
        method: 'get',
    });
};

storageApi.proxyImageGet = function(url) {
    return apiUtil.runRequest({
        url: `/api/private/proxyImages?url=${url}`,
        method: 'get',
    });
};

export default storageApi;
