import Grid from '@material-ui/core/Grid';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    homeImg: {
        display: 'flex',
        position: 'absolute',
        backgroundColor: '#FFFFFF',
        borderRadius: '2rem',
        padding: '2rem',
        width: '45rem',
        height: '30rem',
        left: '20%',
        zIndex: '0',
    },
    centerVid: {
        zIndex: '0',
        display: 'flex',
        alignItems: 'center',
        marginLeft: '2rem',
        width: '100%',
        justifyContent: 'end',
        '& img': {
            maxWidth: '33rem',
        },
    },
    relativeWidth: {
        width: '100%',
        position: 'relative',
        display: 'flex',
    },
    children: {
        display: 'flex',
        paddingTop: '7rem',
        width: '100%',
    },
    childrenHome: {
        display: 'flex',
        paddingTop: '4rem',
        width: '100%',
    },
    landingVideo: {
        borderRadius: '2rem',
        border: '3px solid #065156',
    },
}));

const DesktopLanding = (props) => {
    const classes = useStyles();

    return (
        <>
            <Grid item xs={12} style={{ paddingTop: '4rem' }}>
                <div className={classes.relativeWidth}>
                    <img alt="mainHomeWelcome" src={'/MainHomeWelcome.png'} className={classes.homeImg} />
                    <div className={props.needsBackground ? classes.childrenHome : classes.children}>
                        {props.children}
                        <div className={classes.centerVid}>
                            <iframe width="569" height="320" controls autoPlay src={props.videoUrl} className={classes.landingVideo}/>
                        </div>
                    </div>
                </div>
            </Grid>
        </>
    );
};

export default DesktopLanding;
