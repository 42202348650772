import React from 'react';
import { Button, makeStyles, MenuItem, styled } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { COLORS2 } from '../../constants';
import {
    FEATURES_LINK,
    FOR_SCHOOLS_LINK,
    FOR_TUTORING_LINK,
    SUBSCRIPTIONS_LINK,
    TUTORIAL_LINK, USAGE_FOR_SCHOOLS_LINK, USAGE_FOR_TUTORING_LINK,
} from '../../common/urlLinks';
import { Link, useLocation } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const useStyles = makeStyles(() => ({
    topMenuNav: {
        fontWeight: 600,
        fontSize: '0.875rem',
        lineHeight: '1.063rem',
        textAlign: 'right',
        letterSpacing: '0.16rem',
        color: COLORS2.darkGreen,
    },
    activeTopMenu: {
        borderBottom: '2px solid #065156',
        borderRadius: '0px',
    },
}));

const LOCATION_MAP = {
    HOME: {
        url: '/',
    },
    SUBSCRIPTIONS: {
        url: SUBSCRIPTIONS_LINK,
    },
    FEATURES: {
        url: FEATURES_LINK,
    },
    FOR_TUTORING: {
        url: FOR_TUTORING_LINK,
    },
    USAGE_FOR_TUTORING: {
        url: USAGE_FOR_TUTORING_LINK,
    },
    FOR_SCHOOLS: {
        url: FOR_SCHOOLS_LINK,
    },
    USAGE_FOR_SCHOOLS: {
        url: USAGE_FOR_SCHOOLS_LINK,
    },
    TUTORIAL: {
        url: TUTORIAL_LINK,
    },
};

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: 45,
        minWidth: 180,
        backgroundColor: COLORS2.dawnBackground,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        '& .MuiMenu-list': {
            padding: '4px 0',
            display: 'flex',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: COLORS2.unauthenticatedBackground,
            },
        },
    },
}));

const SubMenu = () => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const location = useLocation();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <div className={open ? classes.activeTopMenu : null}>
                <Button
                    color={location.pathname.includes(LOCATION_MAP.FEATURES.url) ? 'default' : 'primary'}
                    className={classes.topMenuNav}
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                >
                    <FormattedMessage id="redesign.topMenu.usage.name" />
                </Button>
            </div>
            <StyledMenu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleClose}>
                    <Link to={LOCATION_MAP.FEATURES.url}>
                        <Button
                            color={location.pathname.includes(LOCATION_MAP.FEATURES.url) ? 'default' : 'primary'}
                            className={classes.topMenuNav}
                        >
                            <FormattedMessage id="topMenu.features.name" />
                        </Button>
                    </Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    {!window.isTeams ? (
                        <Link to={LOCATION_MAP.TUTORIAL.url}>
                            <Button
                                color={location.pathname.includes(LOCATION_MAP.TUTORIAL.url) ? 'default' : 'primary'}
                                className={classes.topMenuNav}
                            >
                                <FormattedMessage id="topMenu.tutorial.name" />
                            </Button>
                        </Link>
                    ) : null}
                </MenuItem>
            </StyledMenu>
        </div>
    );
};

export default SubMenu;
