import React, { useRef, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Hidden from '@material-ui/core/Hidden';
import FeaturesDesktop from './Desktop/FeaturesDesktop';
import { Container } from '@material-ui/core';
import FeaturesTablet from './Tablet/FeaturesTablet';
import FeaturesMobile from './Mobile/FeaturesMobile';
import { parseQuery, scrollSection } from '../../common/utils';

function getSectionUrl(location) {
    let queryObj = parseQuery(location.search);
    if (queryObj.feature) {
        return queryObj.feature;
    }
    return null;
}

const Features = ({ language }) => {
    const location = useLocation();
    const history = useHistory();
    const refs = useRef({});

    useEffect(() => {
        let sectionId;
        if (history.location.state && history.location.state.sectionId) {
            sectionId = history.location.state && history.location.state.sectionId;
        } else {
            sectionId = `section-${getSectionUrl(location)}`;
        }
        if (sectionId) {
            setTimeout(() => {
                const sectionRef = refs.current[sectionId.toLowerCase()];
                if (sectionRef) {
                    // used to give time for the images to load and the page to calculate correctly the scroll.
                    // necessary only the first time the user acceses the Tutorial.
                    scrollSection(sectionRef);
                }
            }, 800);
        }
    }, [history.location.state, location]);

    return (
        <Container maxWidth="lg">
            <div style={{ display: 'flex' }}>
                <main style={{ flexGrow: 1, padding: '0px', overscrollBehaviorY: 'contain', width: '100%' }}>
                    <Hidden only={['xs', 'sm', 'md']}>
                        <FeaturesDesktop language={language} refs={refs} />
                    </Hidden>
                    <Hidden only={['xs', 'lg', 'xl']}>
                        <FeaturesTablet language={language} refs={refs} />
                    </Hidden>
                    <Hidden only={['sm', 'md', 'lg', 'xl']}>
                        <FeaturesMobile language={language} refs={refs} />
                    </Hidden>
                </main>
            </div>
        </Container>
    );
};

const mapStateToProps = (state) => {
    const { profile } = state;
    return { profile };
};

export default connect(mapStateToProps)(Features);
