import React, { useEffect, useRef, useState, useImperativeHandle, createRef } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import store from '../../redux/store';
import { pickLessonPage } from '../../redux/actions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '../icons/DeleteIcon';
import lessonList, { getItem } from '../../pages/LessonPage/lessonList';
import ScrollContainer from 'react-indiana-drag-scroll';
import drawUtils from '../../pages/LessonPage/drawUtils';
import { CircularProgress, DialogTitle, IconButton, Typography } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { Close, KeyboardArrowRight } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    root: {
        // height: '22rem',
    },
    container: {
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '13rem',
        overflow: 'auto',
    },
    paperLarge: {
        margin: theme.spacing(1),
        width: '22rem',
        height: '11rem',
        transition: 'width 0.8s, height 0.8s',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

    },
    paperSmall: {
        margin: theme.spacing(1),
        height: '3rem',
        width: '6rem',
        transition: 'width 0.3s, height 0.3s',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    svg: {
        width: 100,
        height: 100,
    },
    polygon: {
        fill: theme.palette.common.white,
        stroke: theme.palette.divider,
        strokeWidth: 1,
    },
    imageLarge: {
        width: '22rem',
        height: '11rem',
        transition: 'width 0.8s, height 0.8s',
    },
    imageSmall: {
        height: '3rem',
        width: '6rem',
        transition: 'width 0.3s, height 0.3s',
    },
    buttonSelect: {
        position: 'absolute',
        left: '0px',
        bottom: '0px',
        marginBottom: '0.2rem',
        marginLeft: '0.2rem',
    },
    buttonRemove: {
        position: 'absolute',
        right: '0px',
        bottom: '0px',
        marginBottom: '0.2rem',
        marginRight: '0.2rem',
        backgroundColor: 'white',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

// const loadBoards = (email, classId, lessonPages, data) => {
//     let myItems = [];
//     for (let i = 0; i < lessonPages.length; i++) {
//         myItems.push(lessonPages[i]);
//         // let myData = await drawUtils.buildComposedImageForMainPage(email, classId, lessonPages[i], true);
//         // if (myData) {
//         //     data[lessonPages[i]] = myData;
//         // }
//     }
//     return myItems;
// };

const loadUndoBoards = (lessonPages, currentLessonPage, data) => {
    let myItems = [];
    let id = lessonPages[currentLessonPage];
    let undoKeys = lessonList.getUndoKeys(id);

    for (let i = 0; i < undoKeys.length; i++) {
        myItems.push(undoKeys[i]);
        data.push(createRef());
    }
    return myItems;
};

const LessonPagePickerDialog = (props) => {
    let [currentItem, setCurrentItem] = useState(-1);
    let [items, setItems] = useState(null);
    const undoData = useRef([]);

    const classes = useStyles();

    let myItem = props.lessonState.currentLessonPage;

    if (currentItem >= 0) {
        myItem = currentItem;
    } else if (props.appState.pickLessonPage === 'undoBoard') {
        if (items) {
            myItem = items.length - 1;
        }
    }

    const onSelect = (pageNo) => {
        if (props.appState.pickLessonPage !== 'undoBoard') {
            if (window.switchPage) {
                window.switchPage(pageNo);
            }
        } else {
            // console.log('=== should load', items[pageNo], data.current[items[pageNo]]);
            if (undoData.current[pageNo]) {
                undoData.current[pageNo].current.loadUndoImage();
            }
            // if (window.loadFullImage) {
            //     window.loadFullImage(undoData.current[pageNo]);
            // }
        }
        onClose();
    };

    const onDelete = (pageNo) => {
        if (window.removePage) {
            setItems(null);
            window.removePage(pageNo);
        }
    };
    // useEffect(() => {
    //     setCurrentItem(props.lessonState.currentLessonPage);
    // }, [props.lessonState.currentLessonPage]);

    useEffect(() => {
        if (!props.appState.pickLessonPage) {
            return;
        }
        if (props.appState.pickLessonPage !== 'undoBoard') {
            setItems(props.lessonState.lessonPages);
        }
    }, [props.appState.pickLessonPage, props.lessonState.classId, props.profile.email, props.lessonState.lessonPages]);

    useEffect(() => {
        if (!props.appState.pickLessonPage) {
            return;
        }
        if (props.appState.pickLessonPage === 'undoBoard') {
            undoData.current = [];
            setItems(loadUndoBoards(props.lessonState.lessonPages, props.lessonState.currentLessonPage, undoData.current));
        }
    }, [props.appState.pickLessonPage, props.lessonState.lessonPages, props.lessonState.currentLessonPage]);

    useEffect(() => {
        try {
            document.getElementById('tablePickerElementToScroll').scrollIntoView({ behavior: 'smooth' });
        } catch (ex) {
            // console.log('unable to scroll');
        }
    });

    let objArr = null;
    if (items) {
        objArr = [];
        if (items.length === 0) {
            objArr.push(
                <Paper variant='outlined' className={classes.paperLarge} key={`lesson_page${0}`}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                        <Typography variant={'h6'}>
                            <FormattedMessage id='multiBoard.noBoards' />
                        </Typography>
                    </div>
                </Paper>,
            );
        }
        for (let i = 0; i < items.length; i++) {
            if (i === myItem) {
                objArr.push(
                    <Paper
                        variant='outlined'
                        className={classes.paperLarge}
                        key={`lesson_page${i}`}
                        id='tablePickerElementToScroll'
                    >
                        {props.appState.pickLessonPage === 'undoBoard' ?
                            <UndoImageLoading imgId={items[i]} small={false} ref={undoData.current[i]} /> :
                            <SingleImageLoading email={props.profile.email} pageId={items[i]}
                                                classId={props.lessonState.classId} small={false} />}

                        <Button
                            variant='contained'
                            color='primary'
                            className={classes.buttonSelect}
                            startIcon={<KeyboardArrowRight />}
                            onClick={() => onSelect(i)}
                        >
                            <FormattedMessage id='generic.label.select' />
                        </Button>
                        {props.appState.pickLessonPage !== 'undoBoard' ? (
                            <Button
                                variant='contained'
                                color='secondary'
                                className={classes.buttonRemove}
                                startIcon={<DeleteIcon />}
                                onClick={() => {
                                    onDelete(i);
                                }}
                            >
                                <FormattedMessage id='generic.label.delete' />
                            </Button>
                        ) : null}
                    </Paper>,
                );
            } else {
                objArr.push(
                    <Paper variant='outlined' className={classes.paperSmall} key={`lesson_page${i}`} onClick={() => {
                        setCurrentItem(i);
                    }}>
                        {props.appState.pickLessonPage === 'undoBoard' ? <UndoImageLoading imgId={items[i]} /> :
                            <SingleImageLoading email={props.profile.email} pageId={items[i]}
                                                classId={props.lessonState.classId} />}

                        {/*<img*/}
                        {/*    src={data.current[items[i]]}*/}
                        {/*    className={classes.imageSmall}*/}
                        {/*    onClick={() => {*/}
                        {/*        setCurrentItem(i);*/}
                        {/*    }}*/}
                        {/*    alt=''*/}
                        {/*/>*/}
                    </Paper>,
                );
            }
        }
    }

    const onClose = () => {
        setCurrentItem(-1);
        store.dispatch(pickLessonPage(null));
    };

    return (
        <Dialog fullWidth maxWidth='xl' open={!!props.appState.pickLessonPage} onClose={onClose}>
            <DialogTitle disableTypography id='alert-dialog-title'>
                <Typography variant='h4'>
                    <FormattedMessage id='tooltip.lessonPage.boards' />
                </Typography>
                <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent style={{ background: '#f6f6f6' }}>
                <ScrollContainer vertical={false} hideScrollbars={false}>
                    <div className={classes.container}>
                        {objArr ? (
                            objArr
                        ) : (
                            <div style={{ paddingTop: '100px', paddingLeft: '150px' }}>
                                <CircularProgress />
                            </div>
                        )}
                    </div>
                </ScrollContainer>
            </DialogContent>
            <DialogActions style={{ marginBottom: '0.5rem', marginRight: '1rem' }}>
                <Button onClick={onClose} size='large' variant='contained' color='default'>
                    <FormattedMessage id='close' />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    const { appState, profile, lessonState } = state;
    return { appState, profile, lessonState };
};

export default injectIntl(connect(mapStateToProps)(LessonPagePickerDialog));

const SingleImageLoading = ({ email, classId, pageId, small = true }) => {
    const classes = useStyles();
    let [loadedState, setLoadedState] = useState(0);
    const data = useRef(null);
    useEffect(() => {
        drawUtils.buildComposedImageForMainPage(email, classId, pageId, true).then(myData => {
            if (myData) {
                data.current = myData;
                setLoadedState(1);
            }
        }).catch(err => {
            console.error(`Unable to load page preview ${email}, ${classId} ${err}`);
            setLoadedState(2);
        });
    }, []);


    if (loadedState === 0) return <CircularProgress />;
    if (loadedState === 1) return <img src={data.current} className={small ? classes.imageSmall : classes.imageLarge}
                                       alt='' />;
    if (loadedState === 2) return <ErrorIcon color={'secondary'} />;


};

const UndoImageLoading = React.forwardRef(({ imgId, small = true }, ref) => {
    const classes = useStyles();
    let [loadedState, setLoadedState] = useState(0);
    const data = useRef(null);

    useImperativeHandle(ref, () => ({
        loadUndoImage: () => {
            if (window.loadFullImage) {
                window.loadFullImage(data.current);
            }
        },
    }));

    useEffect(() => {
        getItem(imgId).then(myData => {
            if (myData) {
                data.current = myData;
                setLoadedState(1);
            }
        }).catch(err => {
            console.error(`Unable to load page undo ${imgId} ${err}`);
            setLoadedState(2);
        });
    }, []);

    if (loadedState === 0) return <CircularProgress />;
    if (loadedState === 1) return <img src={data.current} className={small ? classes.imageSmall : classes.imageLarge}
                                       alt='' />;
    if (loadedState === 2) return <ErrorIcon color={'secondary'} />;

});
