export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_EXTENDED_PROFILE = 'UPDATE_EXTENDED_PROFILE';
export const UPDATE_USER_PREFERENCES = 'UPDATE_USER_PREFERENCES';
export const UPDATE_USER_SUBSCRIPTION = 'UPDATE_USER_SUBSCRIPTION';
export const UPDATE_USER_CONSENT = 'UPDATE_USER_CONSENT';
export const UPDATE_ALLOWED_GROUPS = 'UPDATE_ALLOWED_GROUPS';
export const UPDATE_ALLOWED_BOARDS = 'UPDATE_ALLOWED_BOARDS';
export const SHOW_CONSENT = 'SHOW_CONSENT';
export const CHANGE_PREFERENCES = 'CHANGE_PREFERENCES';

export const UPDATE_APP_SUBSCRIPTION = 'UPDATE_APP_SUBSCRIPTION';

export const UPDATE_USER_MESSAGES = 'UPDATE_USER_MESSAGES';

export const CHANGE_COLOR = 'CHANGE_COLOR';
export const CHANGE_TOOL = 'CHANGE_TOOL';
export const CHANGE_TOOL_WEIGHT = 'CHANGE_TOOL_WEIGHT';
export const CHANGE_TEXT_WEIGHT = 'CHANGE_TEXT_WEIGHT';
export const CHANGE_ERASER_WEIGHT = 'CHANGE_ERASER_WEIGHT';

export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';

export const SHOW_ERROR = 'SHOW_ERROR';
export const SHOW_INFO = 'SHOW_INFO';
export const SHOW_SUCCESS = 'SHOW_SUCCESS';
export const SHOW_WARNING = 'SHOW_WARNING';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';
export const NEW_FEATURE = 'NEW_FEATURE';

export const START_LESSON = 'START_LESSON';
export const JOIN_LESSON = 'JOIN_LESSON';
export const USER_JOIN = 'USER_JOIN';
export const OFFLINE_USERS = 'OFFLINE_USERS';
export const USER_LEAVE = 'USER_LEAVE';
export const END_LESSON = 'END_LESSON';
export const USER_TOGGLE_DRAW = 'USER_TOGGLE_DRAW';
export const USER_CAN_DRAW = 'USER_CAN_DRAW';
export const LESSON_INFO = 'LESSON_INFO';
export const RAISE_HAND = 'RAISE_HAND';
export const MUTE = 'MUTE';
export const TOGGLE_VIDEO = 'TOGGLE_VIDEO';
export const TOGGLE_USERS_VIDEO = 'TOGGLE_USERS_VIDEO';
export const CALL_IN_PROGRESS = 'CALL_IN_PROGRESS';
export const SHOW_STATIC_PAGE_NAVIGATOR = 'SHOW_STATIC_PAGE_NAVIGATOR';
export const UPDATE_LESSON_OPEN_JOIN_REQUESTS = 'UPDATE_LESSON_OPEN_JOIN_REQUESTS';
export const UPDATE_LATEX_TEXT = 'UPDATE_LATEX_TEXT';

export const CHANGE_CALL_STATE = 'CHANGE_CALL_STATE';
export const UPDATE_SOCKET_ID = 'UPDATE_SOCKET_ID';
export const SWITCH_BOARD_TYPE = 'SWITCH_BOARD_TYPE';

export const UPGRADE_SUBSCRIPTION = 'UPGRADE_SUBSCRIPTION';
export const SHARE_LESSON = 'SHARE_LESSON';
export const USER_JOIN_REQUEST = 'USER_JOIN_REQUEST';
export const CURRENT_REQUEST_COUNT = 'CURRENT_REQUEST_COUNT';
export const USER_RAISE_HAND = 'USER_RAISE_HAND';
export const USER_MUTE = 'USER_MUTE';
export const ALLOW_DRAWING = 'ALLOW_DRAWING';

export const REQUEST_FULL_SCREEN = 'REQUEST_FULL_SCREEN';
export const UPDATE_MULTI_BOARDS = 'UPDATE_MULTI_BOARDS';
export const MULTI_BOARD_LESSON_STATE = 'MULTI_BOARD_LESSON_STATE';

export const CREATE_LESSON_PAGE = 'CREATE_LESSON_PAGE';
export const SWITCH_LESSON_PAGE = 'SWITCH_LESSON_PAGE';
export const LOAD_LESSON_PAGES = 'LOAD_LESSON_PAGES';
export const PICK_LESSON_PAGE = 'PICK_LESSON_PAGE';

export const UPDATE_TUTORIAL_ACTIVE_SECTION = 'UPDATE_TUTORIAL_ACTIVE_SECTION';
export const UPDATE_FEATURES_ACTIVE_SECTION = 'UPDATE_FEATURES_ACTIVE_SECTION';

export const LIBRARY_ALREADY_SHARED = 'LIBRARY_ALREADY_SHARED';
export const ENTER_VALID_EMAIL = 'ENTER_VALID_EMAIL';
export const ENTER_OWN_EMAIL = 'ENTER_OWN_EMAIL';
