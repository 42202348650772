import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import userApi from '../../Api/userApi';
import { updateUserMessages } from '../../redux/actions';
import store from '../../redux/store';
import Avatar from '@material-ui/core/Avatar';
import AccountCircle from '@material-ui/icons/AccountCircle';
import {
    Badge,
    Button,
    ClickAwayListener,
    Grow,
    makeStyles,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Typography,
} from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ListItemText from '@material-ui/core/ListItemText';
import EmailIcon from '@material-ui/icons/Email';
import SettingsIcon from '@material-ui/icons/Settings';
import Divider from '@material-ui/core/Divider';
import { handleLogout } from '../../common/utils';
import { Link } from 'react-router-dom';
import { getSubscriptionData } from '../../shared/Capabilities';
import { COLORS, COLORS2 } from '../../constants';
import RomaniaIcon from '../icons/Romania';
import UKIcon from '../icons/UK';
import FranceIcon from '../icons/France';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles((theme) => ({
    small: {
        width: theme.spacing(2),
        height: theme.spacing(2),
    },
    large: {
        width: theme.spacing(5),
        height: theme.spacing(5),
        cursor: 'pointer',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: COLORS.primary,
        borderRadius: '0.5rem',
    },
    img: {
        marginRight: '0.5rem',
        display: 'inline',
    },
    selectLanguageItem: {
        textTransform: 'uppercase',
        fontWeight: '600',
        color: COLORS2.darkGreen,
        fontStyle: 'normal',
        letterSpacing: '0.1rem',
        fontSize: '0.875rem',
        lineHeight: '1.063rem',
        textAlign: 'right',
    },
    menuText: {
        fontWeight: 400,
        fontSize: '1rem',
        lineHeight: '1.75rem',
        letterSpacing: '-0.01rem',
        color: COLORS2.darkGreen,
    },
}));

const AvatarMenu = ({ profile, userMessages, intl, subscriptions, handleRo, handleEn, handleFr }) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    useEffect(() => {
        userApi.getUserMessages().then((res) => {
            if (res && res.data) {
                store.dispatch(updateUserMessages(res.data));
            }
        });
    }, []);

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    if (!profile) {
        return <AccountCircle />;
    }

    let subscriptionData = null;

    if (subscriptions && profile.subscription) {
        subscriptionData = getSubscriptionData(subscriptions, profile.subscription);
    }

    return (
        <div>
            <div style={{ position: 'relative' }}>
                {/*{userMessages.unreadCount > 0 ? (*/}
                {/*    <NotificationsIcon*/}
                {/*        color={'secondary'}*/}
                {/*        style={{ position: 'absolute', top: '0px', right: '0px', zIndex: '1', height: '1.3rem' }}*/}
                {/*    />*/}
                {/*) : null}*/}

                <Badge badgeContent={userMessages.unreadCount} color='secondary'>
                    <Avatar
                        src={profile.avatar}
                        color={'default'}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup='true'
                        onClick={handleToggle}
                        className={classes.large}
                    />
                </Badge>
            </div>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                placement={'bottom-end'}
                style={{ zIndex: '5' }}
            >
                {({ TransitionProps }) => (
                    <Grow {...TransitionProps} style={{ transformOrigin: 'left bottom' }}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} id='menu-list-grow'>
                                    <div
                                        style={{
                                            display: 'block',
                                            paddingLeft: '1rem',
                                            paddingRight: '1rem',
                                            paddingBottom: '0.5rem',
                                        }}
                                    >
                                        <div style={{ fontWeight: '700' }}>{profile.name}</div>
                                        {subscriptionData ? (
                                            <div style={{ fontWeight: '400', fontSize: '0.8rem' }}>
                                                {subscriptionData.subscriptionName}
                                            </div>
                                        ) : null}
                                    </div>
                                    <Divider />
                                    <Link to={'/messages'} onClick={handleClose}>
                                        <MenuItem>
                                            <ListItemIcon>
                                                {userMessages.unreadCount > 0 ? (
                                                    <Badge badgeContent={userMessages.unreadCount} color='secondary'>
                                                        <EmailIcon color='secondary' />
                                                    </Badge>
                                                ) : (
                                                    <EmailIcon color='primary' />
                                                )}
                                            </ListItemIcon>
                                            <ListItemText disableTypography
                                                          primary={intl.formatMessage({ id: 'messages' })}
                                                          className={classes.menuText} />
                                        </MenuItem>
                                    </Link>
                                    <Link to={'/profile'} onClick={handleClose}>
                                        <MenuItem>
                                            <ListItemIcon>
                                                <SettingsIcon color='primary' />
                                            </ListItemIcon>
                                            <ListItemText disableTypography
                                                          primary={intl.formatMessage({ id: 'profile' })}
                                                          className={classes.menuText} />
                                        </MenuItem>
                                    </Link>
                                    <Hidden only={['md', 'lg', 'xl']}>
                                        <Divider />
                                        <div
                                            style={{
                                                display: 'block',
                                                paddingLeft: '1rem',
                                                paddingRight: '1rem',
                                                paddingBottom: '0.5rem',
                                                paddingTop: '0.5rem',
                                            }}
                                        >
                                            <Button size='small' onClick={handleRo}>
                                                <RomaniaIcon className={classes.img} />
                                                <Typography className={classes.selectLanguageItem}>RO</Typography>
                                            </Button>
                                            <Button size='small' onClick={handleEn}>
                                                <UKIcon className={classes.img} />
                                                <Typography className={classes.selectLanguageItem}>EN</Typography>
                                            </Button>
                                            <Button size='small' onClick={handleFr}>
                                                <FranceIcon className={classes.img} />
                                                <Typography className={classes.selectLanguageItem}>FR</Typography>
                                            </Button>
                                        </div>
                                    </Hidden>
                                    <Divider />
                                    <MenuItem onClick={handleLogout}>
                                        <ListItemIcon>
                                            <ExitToAppIcon color='primary' />
                                        </ListItemIcon>
                                        <ListItemText disableTypography primary={intl.formatMessage({ id: 'logout' })}
                                                      className={classes.menuText} />
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
};

const mapStateToProps = (state) => {
    const { profile, userMessages, mainAppState } = state;
    return { profile, userMessages, subscriptions: mainAppState.subscriptions };
};

export default injectIntl(connect(mapStateToProps)(AvatarMenu));
