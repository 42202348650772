import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { BOARD_TYPE } from '../../../constants';
import { Avatar, Badge, Fab } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import boardManager from '../../../pages/LessonPage/boardManager';
import PersonIcon from '@material-ui/icons/Person';
import store from '../../../redux/store';
import { userToggleDraw } from '../../../redux/actions';
import Chip from '@material-ui/core/Chip';
import PencilIcon from '../../icons/PencilIcon';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const UsersButton = (props) => {
    const [showUsers, setShowUsers] = useState(true);
    const [showAll, setShowAll] = useState(false);
    const [showMoreEnabled, setShowMoreEnabled] = useState(false);
    const [userElementWidth, setUserElementWidth] = useState('100%');
    const [positionRight, setPositionRight] = useState(0);
    const [positionBottom, setPositionBottom] = useState(0);

    const toggleUsers = () => {
        setShowUsers(!showUsers);
    };
    const toggleAll = () => {
        setShowAll(!showAll);
    };

    const positionElements = () => {
        let container = document.getElementById('userControlListData');
        let containerWrapper = document.getElementById('userControlListWrapper');
        let containerCanvas = document.getElementById('canvasContainer');
        let showAllUsers = document.getElementById('showAllUsersButton');
        if (!window.isTeams) {
            if (window.innerHeight > window.innerWidth) {
                return;
            }
            if (containerCanvas && containerCanvas.getBoundingClientRect().width !== 0) {
                let rect = containerCanvas.getBoundingClientRect();
                if (positionRight !== Math.floor(window.innerWidth - rect.right)) {
                    setPositionRight(Math.floor(window.innerWidth - rect.right));
                }

                // console.log(window.innerHeight, rect.bottom, window.innerHeight - rect.bottom - 50);
                if (window.innerHeight - rect.bottom - 50 < 8) {
                    if (positionBottom !== 12) {
                        setPositionBottom(12);
                    }
                } else {
                    if (Math.floor(window.innerHeight - rect.bottom - 50) !== positionBottom) {
                        setPositionBottom(Math.floor(window.innerHeight - rect.bottom - 50));
                    }
                }
            }

            if (container && containerWrapper && containerCanvas && showAllUsers) {
                if (containerCanvas.getBoundingClientRect().width === 0) {
                    return;
                }
                let diffLength = 50 + 120; //showAllUsers.getBoundingClientRect().width;
                if (Math.floor(containerCanvas.getBoundingClientRect().width - diffLength) !== userElementWidth) {
                    setUserElementWidth(containerCanvas.getBoundingClientRect().width - diffLength + 'px');
                }
                if (
                    containerCanvas.getBoundingClientRect().width <=
                    container.getBoundingClientRect().width + diffLength
                ) {
                    if (!showMoreEnabled) {
                        setShowMoreEnabled(true);
                    }
                } else {
                    if (showMoreEnabled) {
                        setShowMoreEnabled(false);
                    }
                }
            }
        } else {
            if (containerCanvas && containerCanvas.getBoundingClientRect().width !== 0) {
                let rect = containerCanvas.getBoundingClientRect();
                if (positionRight !== Math.floor(window.innerWidth - rect.right)) {
                    setPositionRight(Math.floor(window.innerWidth - rect.right));
                }

                // console.log(window.innerHeight, rect.bottom, window.innerHeight - rect.bottom - 50);
                if (window.innerHeight - rect.bottom - 50 < 8) {
                    if (positionBottom !== 12) {
                        setPositionBottom(12);
                    }
                } else {
                    if (Math.floor(window.innerHeight - rect.bottom - 50) !== positionBottom) {
                        setPositionBottom(Math.floor(window.innerHeight - rect.bottom - 50));
                    }
                }
            }

            if (container && containerWrapper && containerCanvas && showAllUsers) {
                if (containerCanvas.getBoundingClientRect().width === 0) {
                    return;
                }
                let diffLength = 50 + 120; //showAllUsers.getBoundingClientRect().width;
                if (Math.floor(containerCanvas.getBoundingClientRect().width) !== userElementWidth) {
                    setUserElementWidth(containerCanvas.getBoundingClientRect().width + 'px');
                }
            }
        }
    };

    useEffect(() => {
        setTimeout(positionElements, 100);
    });

    window.repositionUserBar = positionElements;

    if (
        !boardManager.isAdmin ||
        !boardManager.lessonState ||
        boardManager.lessonState.boardType !== BOARD_TYPE.SINGLE_BOARD
    ) {
        return null;
    }

    const toggleDrawState = (user) => {
        if (window.updateUserDrawingCallback) {
            window.updateUserDrawingCallback(user.email, !user.enableDraw);
        }
        store.dispatch(userToggleDraw(user.email));
    };

    let activeMemberCount = 0;
    let displayableMembers = [];
    if (props.lessonState && props.lessonState.members) {
        props.lessonState.members.forEach((member) => {
            if (member.online) {
                activeMemberCount++;
                displayableMembers.push(member);
            }
        });
    }

    try {
        displayableMembers.sort((a, b) => {
            return a.name.localeCompare(b.name);
        });
    } catch (ex) {
        console.error('Unable to compare', displayableMembers);
    }

    // console.log('=== members to display', displayableMembers);

    return (
        <div
            style={{
                position: 'absolute',
                right: positionRight + 'px',
                bottom: positionBottom + 'px',
                display: 'flex',
                flexDirection: 'row-reverse',
                alignItems: 'end',
                visibility: activeMemberCount > 0 ? 'visible' : 'hidden',
            }}
        >
            <div style={{ position: 'relative', alignSelf: 'end', marginTop: '5px' }}>
                <Badge
                    color="secondary"
                    badgeContent={activeMemberCount}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                >
                    <Fab size={'small'} color={showUsers ? 'primary' : 'default'} onClick={toggleUsers}>
                        <PersonIcon />
                    </Fab>
                </Badge>
            </div>
            <div
                style={{
                    // display: 'flex',
                    flexWrap: 'nowrap',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                    width: '100%',
                    display: showUsers ? 'flex' : 'none',
                }}
            >
                <div
                    style={{
                        maxWidth: userElementWidth,
                        overflow: 'hidden',
                        boxSizing: 'border-box',
                        display: 'block',
                        textAlign: 'end',
                    }}
                    id="userControlListWrapper"
                >
                    <div
                        style={{
                            display: showMoreEnabled && showAll ? 'block' : showMoreEnabled ? 'flex' : 'inline-block',
                            overflow: 'hidden',
                        }}
                        id="userControlListData"
                    >
                        {displayableMembers.map((member) => (
                            <Chip
                                key={'singleUserList-' + member.email}
                                label={member.name}
                                avatar={<Avatar alt={member.name} src={member.avatar} />}
                                color={member.enableDraw ? 'primary' : 'default'}
                                deleteIcon={
                                    <PencilIcon
                                        style={{
                                            borderRadius: '50%',
                                            padding: '3px',
                                            backgroundColor: 'rgba(0,0,0,0.1)',
                                        }}
                                    />
                                }
                                clickable
                                onClick={() => {
                                    toggleDrawState(member);
                                }}
                                onDelete={() => {
                                    toggleDrawState(member);
                                }}
                                style={{
                                    margin: '4px',
                                    borderStyle: member.raisedHand ? 'solid' : 'none',
                                    borderColor: 'orange',
                                    borderWidth: '2px',
                                }}
                            />
                        ))}
                        {/*<Chip label={'gigi1 ala bala portocala'} style={{ margin: '4px' }} />*/}
                        {/*<Chip label={'gigi2 ala bala portocala'} style={{ margin: '4px' }} />*/}
                        {/*<Chip label={'gigi3 ala bala portocala'} style={{ margin: '4px' }} />*/}
                        {/*<Chip label={'gigi4 ala bala portocala'} style={{ margin: '4px' }} />*/}
                        {/*<Chip label={'gigi5 ala bala portocala'} style={{ margin: '4px' }} />*/}
                        {/*<Chip label={'gigi6 ala bala portocala'} style={{ margin: '4px' }} />*/}
                        {/*<Chip label={'gigi7 ala bala portocala'} style={{ margin: '4px' }} />*/}
                        {/*<Chip label={'gigi8 ala bala portocala'} style={{ margin: '4px' }} />*/}
                        {/*<Chip label={'gigi9 ala bala portocala'} style={{ margin: '4px' }} />*/}
                        {/*<Chip label={'gigi10 ala bala portocala'} style={{ margin: '4px' }} />*/}
                        {/*<Chip label={'gigi11 ala bala portocala'} style={{ margin: '4px' }} />*/}
                    </div>
                </div>
                <div
                    style={{
                        position: 'relative',
                        justifyContent: 'flex-end',
                        alignSelf: 'end',
                        display: showAll || showMoreEnabled ? 'block' : 'none',
                    }}
                    id="showAllUsersButton"
                >
                    <Button
                        variant={'outlined'}
                        size={'small'}
                        endIcon={showAll ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                        style={{ margin: '4px' }}
                        onClick={toggleAll}
                    >
                        <FormattedMessage
                            id={showAll ? 'lessonPage.usersButton.less' : 'lessonPage.usersButton.more'}
                        />
                    </Button>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    const { drawing, lessonState, profile } = state;
    return { drawing, lessonState, profile };
};

export default injectIntl(connect(mapStateToProps)(UsersButton));
