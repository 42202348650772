import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import RonIcon from '../icons/RonIcon';
import EuIcon from '../icons/EuIcon';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS2 } from '../../constants';

const useStyles = makeStyles(() => ({
    buttonGroup: {
        backgroundColor: 'rgba(255,255,255,0.9)',
        borderRadius: '4.75rem',
        padding: '0.25rem',
    },
}));

export const ButtonGroupCurrency = ({billEur, handleCurrencySelect}) => {

    const classes = useStyles();

    return(
        <ToggleButtonGroup className={classes.buttonGroup} onChange={handleCurrencySelect}>
            <ToggleButton
                style={{
                    color: billEur ? COLORS2.darkGreen : '#FFFFFF',
                    backgroundColor: billEur ? 'rgba(255,255,255,0.1)' : COLORS2.darkGreen,
                    width: '6.375rem',
                    height: '2.5rem',
                    borderRadius: '4.75rem',
                    border: 'none',
                }}
                checked={billEur}
                value="ron"
            >
                <RonIcon
                    style={{ width: '2rem', height: '2rem', marginRight: '0.75rem' }}
                />
                <span style={{ marginRight: '1.25rem' }}>RON</span>
            </ToggleButton>
            <ToggleButton
                style={{
                    color: billEur ? '#FFFFFF' : COLORS2.darkGreen,
                    backgroundColor: billEur ? COLORS2.darkGreen : 'rgba(255,255,255,0.1)',
                    width: '6.375rem',
                    height: '2.5rem',
                    borderRadius: '4.75rem',
                    border: 'none',
                }}
                checked={billEur}
                value="eur"
            >
                <EuIcon style={{ width: '2rem', height: '2rem', marginRight: '0.75rem' }} />
                <span style={{ marginRight: '1.25rem' }}>EUR</span>
            </ToggleButton>
        </ToggleButtonGroup>
    );
};