import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function DenyIcon(props) {
    return (
        <SvgIcon {...props} style={{ ...props.style, verticalAlign: 'bottom' }}>
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.5001 11.2078C16.9126 11.2078 17.2501 11.5875 17.2501 11.9578C17.2501 12.4125 16.9126 12.7078 16.5001 12.7078H7.50005C7.08755 12.7078 6.75005 12.4125 6.75005 11.9578C6.75005 11.5875 7.08755 11.2078 7.50005 11.2078H16.5001ZM0.00318789 8.97656C0.00318789 8.08125 0.358739 7.22344 0.991927 6.59063L6.59068 0.991406C7.22349 0.3585 8.0813 0.00292969 8.97661 0.00292969H15.0235C15.9188 0.00292969 16.7766 0.3585 17.4094 0.991406L23.0063 6.59063C23.6391 7.22344 23.9954 8.08125 23.9954 8.97656V15.0234C23.9954 15.9187 23.6391 16.7766 23.0063 17.4094L17.4094 23.0062C16.7766 23.6391 15.9188 23.9953 15.0235 23.9953H8.97661C8.0813 23.9953 7.22349 23.6391 6.59068 23.0062L0.991458 17.4094C0.358739 16.7766 0.00317383 15.9187 0.00317383 15.0234L0.00318789 8.97656ZM2.05224 7.65C1.70068 8.00156 1.50333 8.47969 1.50333 8.97656V15.0234C1.50333 15.5203 1.70068 15.9984 2.05224 16.35L7.65005 21.9469C8.00161 22.2984 8.47974 22.4953 8.97661 22.4953H15.0235C15.5204 22.4953 15.9985 22.2984 16.3501 21.9469L21.9469 16.35C22.2985 15.9984 22.4954 15.5203 22.4954 15.0234V8.97656C22.4954 8.47969 22.2985 8.00156 21.9469 7.65L16.3501 2.05219C15.9985 1.70062 15.5204 1.50281 15.0235 1.50281H8.97661C8.47974 1.50281 8.00161 1.70062 7.65005 2.05219L2.05224 7.65ZM2.05224 16.35L0.991458 17.4094L2.05224 16.35Z" fill="#F35325"/>
            </svg>
        </SvgIcon>
    );
}
