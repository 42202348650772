import React, { useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { FormattedMessage } from 'react-intl';
import { Container, Typography } from '@material-ui/core';
import GenericState from '../../MyComponents/GenericLoadingErrorState/GenericState';
import { connect } from 'react-redux';
import userApi from '../../Api/userApi';
import store from '../../redux/store';
import { updateUserMessages } from '../../redux/actions';
import MessageAccordions from './MessageAccordions';

const UserMessages = ({ userMessages }) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [isError, setIsError] = React.useState(false);

    useEffect(() => {
        userApi
            .getUserMessages()
            .then((res) => {
                store.dispatch(updateUserMessages(res.data));
                setIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setIsError(true);
            });
    }, []);

    const deleteRow = (id) => {
        setIsLoading(true);
        userApi
            .deleteUserMessage(id)
            .then((res) => {
                store.dispatch(updateUserMessages(res.data));
                setIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setIsError(true);
            });
    };
    const markRead = (id, value) => {
        userApi
            .markReadUserMessage(id, value)
            .then((res) => {
                store.dispatch(updateUserMessages(res.data));
            })
            .catch((err) => {
                console.error(err);
                setIsError(true);
            });
    };

    if (isLoading) {
        return <GenericState isLoading={true} />;
    }

    if (isError) {
        return <GenericState isError={true} />;
    }

    let data = [];
    if (userMessages && userMessages.userMessages) {
        data = userMessages.userMessages;
    }

    return (
        <>
            <CssBaseline />
            <Container>
                <div className="u-fx u-fx-align-center u-mb-full">
                    <Typography variant="h2">
                        <FormattedMessage id="messages.title" />
                    </Typography>
                </div>
                <MessageAccordions messages={data} handleReadMark={markRead} handleDelete={deleteRow} />
            </Container>
        </>
    );
};

const mapStateToProps = (state) => {
    const { userMessages } = state;
    return {
        userMessages,
    };
};
export default connect(mapStateToProps)(UserMessages);
