import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ClearMultipleIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 75 75">
                <defs>
                    <clipPath>
                        <path
                            d="M 0 20.03125 L 39 20.03125 L 39 62.78125 L 0 62.78125 Z M 0 20.03125 "
                            clipRule="nonzero"
                        />
                    </clipPath>
                    <clipPath>
                        <path
                            d="M 36.035156 7.5 L 75 7.5 L 75 50.25 L 36.035156 50.25 Z M 36.035156 7.5 "
                            clipRule="nonzero"
                        />
                    </clipPath>
                </defs>
                <g>
                    <path
                        fill="#d36823"
                        d="M 39 27.164062 L 28.007812 27.164062 L 26.238281 20.042969 L 12.761719 20.042969 L 10.992188 27.164062 L 0 27.164062 L 0 30.722656 L 3.632812 30.722656 L 5.40625 62.769531 L 33.59375 62.769531 L 35.367188 30.722656 L 39 30.722656 Z M 15.601562 23.601562 L 23.488281 23.601562 L 24.375 27.164062 L 14.714844 27.164062 Z M 30.226562 59.210938 L 8.773438 59.210938 L 7.179688 30.722656 L 31.820312 30.722656 Z M 30.226562 59.210938 "
                        fillOpacity="1"
                        fillRule="nonzero"
                    />
                </g>
                <path
                    fill="#d36823"
                    d="M 12.410156 36.066406 L 15.953125 36.066406 L 15.953125 53.867188 L 12.410156 53.867188 Z M 12.410156 36.066406 "
                    fillOpacity="1"
                    fillRule="nonzero"
                />
                <path
                    fill="#d36823"
                    d="M 23.046875 36.066406 L 26.589844 36.066406 L 26.589844 53.867188 L 23.046875 53.867188 Z M 23.046875 36.066406 "
                    fillOpacity="1"
                    fillRule="nonzero"
                />
                <g>
                    <path
                        fill="#d36823"
                        d="M 75.035156 14.632812 L 64.046875 14.632812 L 62.273438 7.511719 L 48.800781 7.511719 L 47.027344 14.632812 L 36.035156 14.632812 L 36.035156 18.191406 L 39.671875 18.191406 L 41.445312 50.238281 L 69.628906 50.238281 L 71.402344 18.191406 L 75.035156 18.191406 Z M 51.636719 11.070312 L 59.527344 11.070312 L 60.410156 14.632812 L 50.75 14.632812 Z M 66.261719 46.679688 L 44.8125 46.679688 L 43.214844 18.191406 L 67.855469 18.191406 Z M 66.261719 46.679688 "
                        fillOpacity="1"
                        fillRule="nonzero"
                    />
                </g>
                <path
                    fill="#d36823"
                    d="M 48.445312 23.535156 L 51.992188 23.535156 L 51.992188 41.335938 L 48.445312 41.335938 Z M 48.445312 23.535156 "
                    fillOpacity="1"
                    fillRule="nonzero"
                />
                <path
                    fill="#d36823"
                    d="M 59.082031 23.535156 L 62.628906 23.535156 L 62.628906 41.335938 L 59.082031 41.335938 Z M 59.082031 23.535156 "
                    fillOpacity="1"
                    fillRule="nonzero"
                />
            </svg>
        </SvgIcon>
    );
}
