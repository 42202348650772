import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { featureLinks } from '../../../common/features';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import HomeLine1 from '../../../MyComponents/icons/HomeLine1';
import HomeLine2 from '../../../MyComponents/icons/HomeLine2';
import HomeLine3 from '../../../MyComponents/icons/HomeLine3';
import HomeLine4 from '../../../MyComponents/icons/HomeLine4';
import HomeLine5 from '../../../MyComponents/icons/HomeLine5';
import { StartGroupButton } from '../../../MyComponents/StartGroupButton/StartGroupButton';
import { FindMoreButton } from './FindMoreButton';
import { COLORS2 } from '../../../constants';
import { FEATURES_LINK, FOR_SCHOOLS_LINK, FOR_TUTORING_LINK } from '../../../common/urlLinks';

const useStyles = makeStyles(() => ({
    gridWrap: {
        height: '100%',
        background: 'linear-gradient(180deg, rgba(251, 248, 245, 0) 0%, #FFFFFF 93.57%)',
        borderRadius: '0rem 0rem 2rem 2rem',
        paddingTop: '8rem',
    },
    lines1: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '2rem',
    },
    lines2: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '-1rem',
        marginBottom: '-1rem',
    },
    lines3: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: '2.25rem',
    },
    columnFlexCenter: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    flexCenter: {
        background: 'transparent',
        padding: '2rem',
        display: 'flex',
        alignItems: 'center',
    },
    findOutMoreText: {
        color: COLORS2.virtualAmber,
        fontStyle: 'normal',
        fontWeight: '700',
        // borderBottom: '0.125rem solid #D26828',
    },
}));

export const HomeFeaturesDesktop = ({ handleOpen }) => {
    const classes = useStyles();

    const transitionToTop = () => {
        setTimeout(() => {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }, 200);
    };

    return (
        <>
            <Grid container className={classes.gridWrap}>
                <Grid item xs={12}>
                    <Typography variant="h2" align="center">
                        <FormattedMessage
                            id={'redesign.home.subtitle1'}
                            values={{
                                b: (chunks) => (
                                    <b>
                                        {chunks}
                                        <br></br>
                                    </b>
                                ),
                            }}
                        />
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.lines1}>
                    <HomeLine1 style={{ width: '100%' }} />
                </Grid>
                <Grid item xs={12} className={classes.columnFlexCenter}>
                    <Grid item xs={12} className={classes.flexCenter}>
                        <Grid item xs={12}>
                            <img src={`/static/asset/img/home/tabla_fizica.png`} alt="VBoard" width="100%" />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h3" align="left">
                                <FormattedMessage
                                    id="redesign.page.home.p1.t1"
                                    values={{
                                        b: (chunks) => (
                                            <b>
                                                <br></br>
                                                {chunks}
                                            </b>
                                        ),
                                    }}
                                />
                            </Typography>
                            <Typography
                                variant="h6"
                                align="left"
                                style={{ color: COLORS2.virtualText, paddingBottom: '4rem', paddingTop: '1rem' }}
                            >
                                <FormattedMessage id="redesign.page.home.p1.t2" />
                            </Typography>
                            <Link to={FOR_SCHOOLS_LINK} onClick={transitionToTop}>
                                <FindMoreButton />
                            </Link>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.lines2}>
                        <HomeLine2 />
                    </Grid>
                    <Grid item xs={12} className={classes.flexCenter}>
                        <Grid item xs={12}>
                            <Typography variant="h3" align="left">
                                <FormattedMessage
                                    id="redesign.page.home.p2.t1"
                                    values={{
                                        b: (chunks) => (
                                            <b>
                                                <br></br>
                                                {chunks}
                                            </b>
                                        ),
                                    }}
                                />
                            </Typography>
                            <Typography
                                variant="h6"
                                align="left"
                                style={{ color: COLORS2.virtualText, paddingBottom: '4rem', paddingTop: '1rem' }}
                            >
                                <FormattedMessage
                                    id="redesign.page.home.p2.t2"
                                    values={{
                                        a: (chunks) => (
                                            <a href={featureLinks.control} className={classes.findOutMoreText}>
                                                {chunks}
                                            </a>
                                        ),
                                        b: (chunks) => (
                                            <a href={featureLinks.individualBoards} className={classes.findOutMoreText}>
                                                {chunks}
                                            </a>
                                        ),
                                    }}
                                />
                            </Typography>
                            <Link to={FOR_TUTORING_LINK} onClick={transitionToTop}>
                                <FindMoreButton />
                            </Link>
                        </Grid>
                        <Grid item xs={12}>
                            <img src={`/static/asset/img/home/construit_cu_profesori.png`} alt="VBoard" width="100%" />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.lines2}>
                        <HomeLine3 />
                    </Grid>
                    <Grid item xs={12} className={classes.flexCenter}>
                        <Grid item xs={12}>
                            <img src={`/static/asset/img/home/tabla_inteligenta.png`} alt="VBoard" width="100%" />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h3" align="left">
                                <FormattedMessage
                                    id="redesign.page.home.feature.paste.tag.v2"
                                    values={{
                                        b: (chunks) => (
                                            <b>
                                                <br></br>
                                                {chunks}
                                            </b>
                                        ),
                                    }}
                                />
                            </Typography>
                            <Typography
                                variant="h6"
                                align="left"
                                style={{ color: COLORS2.virtualText, paddingBottom: '4rem', paddingTop: '1rem' }}
                            >
                                <FormattedMessage
                                    id="redesign.page.home.feature.paste.description.v2"
                                    values={{
                                        a: (chunks) => (
                                            <a href={featureLinks.paste} className={classes.findOutMoreText}>
                                                {chunks}
                                            </a>
                                        ),
                                        b: (chunks) => (
                                            <a href={featureLinks.geometry} className={classes.findOutMoreText}>
                                                {chunks}
                                            </a>
                                        ),
                                        c: (chunks) => (
                                            <a href={featureLinks.mathConvertor} className={classes.findOutMoreText}>
                                                {chunks}
                                            </a>
                                        ),
                                    }}
                                />
                            </Typography>
                            <Link to={FEATURES_LINK} onClick={transitionToTop}>
                                <FindMoreButton />
                            </Link>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.lines2}>
                        <HomeLine4 />
                    </Grid>
                    <Grid item xs={12} className={classes.flexCenter}>
                        <Grid item xs={12}>
                            <Typography variant="h3" align="left">
                                <FormattedMessage
                                    id="redesign.page.home.feature.control.tag.v2"
                                    values={{
                                        b: (chunks) => (
                                            <b>
                                                <br></br>
                                                {chunks}
                                            </b>
                                        ),
                                    }}
                                />
                            </Typography>
                            <Typography
                                variant="h6"
                                align="left"
                                style={{ color: COLORS2.virtualText, paddingBottom: '4rem', paddingTop: '1rem' }}
                            >
                                <FormattedMessage id="redesign.page.home.feature.control.description.v2" />
                            </Typography>
                            <Link to={FOR_TUTORING_LINK} onClick={transitionToTop}>
                                <FindMoreButton />
                            </Link>
                        </Grid>
                        <Grid item xs={12}>
                            <img src={`/static/asset/img/home/meditatii_online.png`} alt="VBoard" width="100%" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.lines3}>
                    <HomeLine5 />
                </Grid>
                <Grid
                    item
                    xs={12}
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: '8rem' }}
                    onClick={transitionToTop}
                >
                    <StartGroupButton
                        handleOpen={() => {
                            handleOpen(false);
                        }}
                    />
                </Grid>
            </Grid>
        </>
    );
};
