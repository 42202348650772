import React from 'react';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import CurrentSubscriptionShort from './CurrentSubscriptionShort';
import { getSubscriptionData } from '../../shared/Capabilities';
import { featureList, replaceValues } from '../../common/features';
import { cloneDeep } from 'lodash';
import SubscriptionListRenderer from '../Subscriptions/SubscriptionListRenderer';

const CurrentSubscription = ({ profile, subscriptions }) => {
    if (!profile || !subscriptions || !profile.subscription) {
        return null;
    }
    let subscriptionData = getSubscriptionData(subscriptions, profile.subscription);

    let myFeatureList = cloneDeep(featureList);
    replaceValues(subscriptionData, myFeatureList);

    return (
        <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={12}>
                <CurrentSubscriptionShort />
            </Grid>
            <Grid item xs={12}>
                <SubscriptionListRenderer featureList={myFeatureList} isActive={true} />
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => {
    const { profile, mainAppState } = state;
    return { profile, subscriptions: mainAppState.subscriptions };
};

export default connect(mapStateToProps)(CurrentSubscription);
