import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function MyGroupsIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_46_239)">
                    <path d="M11 4C11 6.20937 9.20938 8 7 8C4.79063 8 3 6.20937 3 4C3 1.79063 4.79063 0 7 0C9.20938 0 11 1.79063 11 4ZM0 15.0719C0 11.9937 2.49375 9.5 5.57188 9.5H8.42813C11.5063 9.5 14 11.9937 14 15.0719C14 15.5844 13.5844 16 13.0719 16H0.928125C0.415625 16 0 15.5844 0 15.0719ZM19.0406 16H14.7312C14.9 15.7063 15 15.3656 15 15V14.75C15 12.8531 14.1531 11.15 12.8188 10.0063C12.8938 10.0031 12.9656 10 13.0406 10H14.9594C17.7437 10 20 12.2562 20 15.0406C20 15.5719 19.5688 16 19.0406 16ZM13.5 8C12.5312 8 11.6562 7.60625 11.0219 6.97188C11.6375 6.14062 12 5.1125 12 4C12 3.1625 11.7938 2.37188 11.4281 1.67813C12.0094 1.25312 12.725 1 13.5 1C15.4344 1 17 2.56562 17 4.5C17 6.43437 15.4344 8 13.5 8Z" fill="#08AAB4"/>
                </g>
                <defs>
                    <clipPath id="clip0_46_239">
                        <rect width="20" height="16" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        </SvgIcon>
    );
}