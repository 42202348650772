import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
    Button,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputBase,
    Paper,
    TextField,
    Typography,
    Popover,
} from '@material-ui/core';
import GenericState from '../../MyComponents/GenericLoadingErrorState/GenericState';
import { GA_EVENT_TREE } from '../../gaConstants';
import collectionsApi from '../../Api/collectionsApi';
import userApi from '../../Api/userApi';
import CollectionTree from './CollectionTree';
import {
    applyCollectionFilter,
    determineDefaultLanguage,
    getStorageExpandedCollection,
    isBoardSetUsable,
    processCollections,
    resetCollectionFilter,
    updateStorageExpandedCollection,
} from '../../common/utils';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Autocomplete } from '@material-ui/lab';
import { showError } from '../../redux/actions';
import store from '../../redux/store';
import { COLORS2, LIBRARY_MANAGER_STATE } from '../../constants';
import DeleteIcon from '../../MyComponents/icons/DeleteIcon';
import EditIcon from '../../MyComponents/icons/EditIcon';
import { canActOnData, CAPABILITIES } from '../../shared/Capabilities';
import SearchIcon from '@material-ui/icons/Search';
import Box from '@material-ui/core/Box';
import AddNewSubFolderIcon from '../../MyComponents/icons/AddNewSubFolderIcon';
import CreateDirectoryIcon from '../../MyComponents/icons/CreateDirectoryIcon';
import Hidden from '@material-ui/core/Hidden';
import DesktopCollectionEditor from './CollectionEditor/DesktopCollectionEditor';
import ShareLessonIcon from '../../MyComponents/icons/ShareLessonIcon';
import ShareCollectionsDesktop from './ShareCollections/ShareCollectionsDesktop';
import Sticky from 'react-stickynode';

const useStyles = makeStyles((theme) => ({
    button: { marginBottom: theme.spacing(2) },
    margin: {
        margin: theme.spacing(1),
    },
    inputLabel: {
        backgroundColor: '#fff',
    },
    imageContainer: { display: 'flex', alignItems: 'center' },
    customDivider: {
        '&.MuiDivider-root': {
            backgroundColor: 'rgba(210, 104, 40, 0.1)',
            width: '97%',
            marginBottom: '1.5rem',
            marginTop: '0.5rem',
        },
    },
    customDividerMobile: {
        '&.MuiDivider-root': {
            backgroundColor: 'rgba(210, 104, 40, 0.1)',
            width: '100%',
            marginBottom: '1.5rem',
            marginTop: '0.5rem',
        },
    },
    customDividerEditor: {
        '&.MuiDivider-root': {
            backgroundColor: 'rgba(210, 104, 40, 0.1)',
            marginBottom: '1.5rem',
            marginTop: '0.5rem',
        },
    },
    createDirectoryButton: {
        fontWeight: 500,
        fontSize: '1.125rem',
        textAlign: 'start',
        textTransform: 'none',
        letterSpacing: '-0.01rem',
        paddingLeft: '2rem',
    },
    itemButton: {
        fontWeight: 400,
        fontSize: '1rem',
        textAlign: 'start',
        textTransform: 'none',
        letterSpacing: '-0.01rem',
        color: COLORS2.darkGreen,
    },
    itemButtonDelete: {
        fontWeight: 400,
        fontSize: '1rem',
        textAlign: 'start',
        textTransform: 'none',
        letterSpacing: '-0.01rem',
        color: COLORS2.virtualRed,
    },
    dayText: {
        fontSize: '1rem',
        fontWeight: 400,
        color: COLORS2.virtualText,
        marginRight: '1rem',
    },
    hourText: {
        color: COLORS2.virtualAmber,
        fontWeight: 500,
        fontSize: '1rem',
        background: COLORS2.virtualBackground,
        borderRadius: '0.25rem',
    },
    desktopPaper: {
        width: '100%',
        height: '100%',
        borderRadius: '0rem 2rem 2rem 0rem',
        boxShadow: 'none',
        // borderLeft: '0.063rem solid rgba(210, 104, 40, 0.1)',
        minWidth: '18.15rem',
    },
    mobilePaper: {
        width: '1rem',
        backgroundColor: '#FFFFFF',
        height: '100%',
        borderRadius: '2rem',
    },
}));

const CollectionManager = ({ onSelect, state = LIBRARY_MANAGER_STATE.MANAGE, intl }) => {
    const classes = useStyles();
    const [processedCollections, setProcessedCollections] = React.useState(null);
    const [tags, setTags] = React.useState([]);
    const [filteredProcessedCollection, setFilteredProcessedCollection] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);
    const [isError, setIsError] = React.useState(false);
    const [create, setCreate] = React.useState(false);
    const [defaultExpanded, setDefaultExpanded] = React.useState(getStorageExpandedCollection());
    const [searchText, setSearchText] = React.useState('');
    const [searchLabels, setSearchLabels] = React.useState([]);
    const [selectedNode, setSelectedNode] = React.useState(null);
    const [editing, setEditing] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [openPopUp, setOpenPopUp] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => setOpen(false);

    const handleOpenPopUp = () => {
        setOpenPopUp(true);
    };

    const handleClosePopUp = () => {
        setOpenPopUp(false);
    };

    const dataSets = useRef({
        boards: new Set(),
        collections: new Set(),
        boardsToRender: {},
        boardMap: {},
        processedCollections: null,
    });

    useEffect(() => {
        loadCollections();
    }, []);

    const onUpdate = (dataHasChanged) => {
        setCreate(false);
        setSelectedNode(null);
        if (dataHasChanged) {
            loadCollections();
        }
    };

    const createEmptyFolder = async (selectedNode) => {
        window.logAppActivity(GA_EVENT_TREE.manageCollections.actions.create);
        try {
            setIsLoading(true);
            let col = await collectionsApi.createCollection({
                name: '',
                description: '',
                parentId: selectedNode ? selectedNode.id : null,
            });
            await loadCollections();
            return col?.data?.id;
        } catch (err) {
            console.log(err);
            setIsLoading(false);
            setIsError(true);
        }
    };

    const deleteBoard = () => {
        handleOpenPopUp();
    };

    const editBoard = () => {
        setEditing(!editing);
    };

    const stopEditing = () => {
        setEditing(false);
    };

    useEffect(() => {
        if (!processedCollections) {
            setFilteredProcessedCollection(null);
            return;
        }
        let filtered;
        if ((searchText && searchText.trim().length > 0) || searchLabels.length > 0) {
            let searchLabelIds = searchLabels.map((el) => el.id);
            filtered = applyCollectionFilter(processedCollections, searchText.trim(), searchLabelIds);
            let s = new Set();
            filtered.forEach((el) => {
                if (el.fullChildSet) {
                    s = [...s, ...el.fullChildSet];
                } else {
                    s = [...s];
                }
            });
            setDefaultExpanded(Array.from(s));
        } else {
            filtered = resetCollectionFilter(processedCollections, searchText.trim());
            setDefaultExpanded(getStorageExpandedCollection());
        }

        setFilteredProcessedCollection(filtered);
    }, [processedCollections, searchText, searchLabels]);

    const addToCollection = (nodeData, expanded = null) => {
        createEmptyFolder(nodeData).then((id) => {
            if (id) {
                setSelectedNode({ ...dataSets.current.boardMap[id] });
                setEditing(true);
                if (expanded) {
                    expanded.push(id);
                    expanded.push(nodeData.id);
                    updateStorageExpandedCollection(expanded);
                    setDefaultExpanded(expanded);
                }
            }
        });

        let setIdForExpanded = (dataSets.current.processedCollections || []).reduce((acc, item) => {
            acc[item.id] = true;
            return acc;
        }, {});

        defaultExpanded.filter((v) => setIdForExpanded[v]);
    };

    const editCollection = (newName) => {
        setIsLoading(true);

        if (selectedNode.isCollection) {
            collectionsApi
                .updateCollection(selectedNode.id, { name: newName, description: '' })
                .then(() => {
                    return loadCollections();
                })
                .then(() => {
                    setEditing(false);
                    handleNodeSelect(selectedNode.id);
                })
                .catch((err) => {
                    console.error(err);
                    setIsError(true);
                });
        } else {
            collectionsApi
                .updateBoard(selectedNode.id, { name: newName, description: '' })
                .then(() => {
                    return loadCollections();
                })
                .then(() => {
                    setEditing(false);
                })
                .catch((err) => {
                    console.error(err);
                    setIsError(true);
                });
        }
    };

    //     let setIdForExpanded = (dataSets.current.processedCollections || []).reduce((acc, item) => {
    //         acc[item.id] = true;
    //         return acc;
    //     }, {});
    //
    //     defaultExpanded.filter((v) => setIdForExpanded[v]);
    // };

    const attachTag = (nodeData, tag) => {
        collectionsApi.attachTag(nodeData.id, tag.id).then(() => {
            nodeData.tags.push(tag.id);
            onUpdate(true);
        });
    };

    const removeTag = (nodeData, tag) => {
        collectionsApi.removeTag(nodeData.id, tag.id).then(() => {
            nodeData.tags = nodeData.tags.filter((t) => t !== tag.id);
            onUpdate(true);
        });
    };

    const moveCollection = (currentNode, newNode, expanded) => {
        setIsLoading(true);
        if (currentNode.isCollection && newNode.isCollection && !newNode.isBoardSet) {
            collectionsApi
                .moveCollection(currentNode.id, newNode.id)
                .then(() => {
                    return loadCollections();
                })
                .catch(() => {
                    store.dispatch(showError('ERROR_MOVING_COLLECTION'));
                    loadCollections();
                });
        } else if (!currentNode.isCollection && newNode.isCollection && !newNode.isBoardSet) {
            collectionsApi
                .moveBoard(currentNode.id, newNode.id)
                .then(() => {
                    return loadCollections();
                })
                .catch(() => {
                    store.dispatch(showError('ERROR_MOVING_BOARD'));
                    loadCollections();
                });
        } else {
            setIsLoading(false);
            return;
        }
        setDefaultExpanded(expanded);
    };

    const loadCollections = async (userId = null) => {
        try {
            let [collections, boards, tags, allowedBoards, foreignUsers] = await Promise.all([
                collectionsApi.getCollections(),
                collectionsApi.getBoards(),
                collectionsApi.getTags(),
                userApi.getAllowedBoards(),
                collectionsApi.getSharingAuthors(),
            ]);
            let expandedUserCollections, expandedUserBoards;
            if (state === LIBRARY_MANAGER_STATE.SAVE || state === LIBRARY_MANAGER_STATE.SAVE_SET) {
                foreignUsers = [];
            }

            if (userId && state !== LIBRARY_MANAGER_STATE.SAVE && state !== LIBRARY_MANAGER_STATE.SAVE_SET) {
                [expandedUserCollections, expandedUserBoards] = await Promise.all([
                    collectionsApi.getCollectionTreeForSharingUser(userId),
                    collectionsApi.getBoardsTreeForSharingUser(userId),
                ]);
                expandedUserCollections.data.forEach((el) => {
                    if (el.parentId === null) {
                        el.parentId = userId;
                    }
                    let findId = false;
                    expandedUserCollections.data.forEach((e) => {
                        if (e.id === el.parentId) {
                            findId = true;
                        }
                    });
                    if (!findId) {
                        el.parentId = userId;
                    }
                });
                expandedUserCollections.data.forEach((el) => {
                    el.isUserCollection = true;
                    collections.data.push(el);
                });
                expandedUserBoards.data.forEach((el) => {
                    el.isUserBoard = true;
                    boards.data.push(el);
                });
            }

            setTags(tags.data);
            dataSets.current.boards.clear();
            dataSets.current.collections.clear();
            dataSets.current.boardMap = {};

            collections.data.forEach((el) => {
                dataSets.current.collections.add(el.id);
                dataSets.current.boardMap[el.id] = el;
            });

            let storeState = store.getState();
            for (let i = 0; i < boards.data.length; i++) {
                let el = boards.data[i];
                el.canUse = await canActOnData(
                    storeState.mainAppState?.subscriptions,
                    storeState.profile.subscription,
                    CAPABILITIES.GROUP_COUNT,
                    () => {
                        return Promise.resolve(allowedBoards.data.boardIds);
                    },
                    el.id,
                );
                if (el.isUserBoard) {
                    el.canUse = { value: true };
                }
                dataSets.current.boards.add(el.id);
                dataSets.current.boardMap[el.id] = el;
            }

            window.logAppActivity(GA_EVENT_TREE.manageCollections.actions.load);
            let { topLayer, unProcessed } = processCollections(
                collections.data,
                boards.data,
                foreignUsers.data,
                userId,
                expandedUserCollections,
                expandedUserBoards,
            );
            if (unProcessed && unProcessed.length) {
                let promises = unProcessed
                    .filter((el) => el.isCollection)
                    .map((el) => {
                        return collectionsApi.moveCollection(el.id, null);
                    });
                await Promise.all(promises);
                await loadCollections();
                return;
            }
            setIsLoading(false);

            dataSets.current.processedCollections = topLayer;

            setProcessedCollections(topLayer);
        } catch (err) {
            console.log(err);
            // setIsLoading(false);
            setIsError(true);
        }
    };

    const handleSearchTextChange = (event) => {
        setSearchText(event.target.value);
    };

    const handleNodeSelect = (value) => {
        if (value !== selectedNode?.id) {
            setEditing(false);
        }
        if (value === '-1') {
            setCreate(true);
            setSelectedNode(null);
            return;
        }
        if (!dataSets.current.boardMap[value]) {
            loadCollections(value);
        } else {
            if (onSelect) {
                onSelect(dataSets.current.boardMap[value], dataSets.current.boardMap);
            }
            setSelectedNode({ ...dataSets.current.boardMap[value] });
            setCreate(false);
        }
    };

    if (isLoading) {
        return <GenericState isLoading={true} />;
    }
    if (isError) {
        return <GenericState isError={true} />;
    }

    const createAndAttachTag = (name, nodeData) => {
        return collectionsApi.createTag({ name }).then((res) => {
            if (res.data && res.data.id) {
                let newTags = [...tags];
                newTags.push({ id: res.data.id, name });
                newTags.sort((a, b) => {
                    return a.name.toUpperCase().localeCompare(b.name.toUpperCase());
                });
                setTags(newTags);
                return collectionsApi.attachTag(nodeData.id, res.data.id).then(() => {
                    nodeData.tags.push(res.data.id);
                    onUpdate(true);
                });
            }
        });
    };

    let localesOptions = 'ro-RO';
    let currentLanguage = determineDefaultLanguage();
    if (currentLanguage === 'en') {
        localesOptions = 'en-EN';
    } else if (currentLanguage === 'fr') {
        localesOptions = 'fr-FR';
    } else if (currentLanguage === 'ro') {
        localesOptions = 'ro-RO';
    }

    const confirmFolderDeletion = () => {
        setIsLoading(true);
        if (!selectedNode.isCollection) {
            collectionsApi.deleteBoard(selectedNode.id).then(() => {
                setSelectedNode(null);
                loadCollections();
            });
        } else {
            collectionsApi.deleteCollection(selectedNode.id).then(() => {
                setSelectedNode(null);
                loadCollections();
            });
        }
        handleClosePopUp();
    };

    const cancelFolderDeletion = () => {
        handleClosePopUp();
    };

    return (
        <Paper style={{ borderRadius: '2rem', boxShadow: 'none' }}>
            <Grid
                container
                style={{
                    boxShadow:
                        state === LIBRARY_MANAGER_STATE.MANAGE ? '0rem 2.5rem 10rem rgba(210, 104, 40, 0.08)' : 'none',
                    borderRadius: '2rem',
                }}
            >
                <Grid item xs={12} md={8} style={{ padding: '2rem', minHeight: '35rem' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={6} style={{ display: 'flex', alignItems: 'center', paddingLeft: '1.2rem' }}>
                            <FormControl variant='outlined' size='small' fullWidth>
                                <InputBase
                                    id='searchCollection'
                                    placeholder={intl.formatMessage({ id: 'redesign.collections.searchBox' })}
                                    value={searchText}
                                    onChange={handleSearchTextChange}
                                    margin='none'
                                    fullWidth
                                    endAdornment={
                                        searchText.trim() !== '' ? (
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    onClick={() => {
                                                        setSearchText('');
                                                    }}
                                                >
                                                    <HighlightOffIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ) : (
                                            <InputAdornment position='end'>
                                                <IconButton>
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <Autocomplete
                                fullWidth
                                multiple
                                id='tags-outlined'
                                size='small'
                                options={tags}
                                getOptionLabel={(option) => option.name}
                                filterSelectedOptions
                                onChange={(event, v) => setSearchLabels(v)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant='outlined'
                                        label={intl.formatMessage({ id: 'collections.filterTags' })}
                                        placeholder={intl.formatMessage({ id: 'collections.tags' })}
                                    />
                                )}
                            />
                        </Grid>
                        <Divider className={classes.customDivider} variant='middle' />
                        {state !== LIBRARY_MANAGER_STATE.LOAD ? (
                            <Button
                                onClick={() => {
                                    handleNodeSelect('-1');
                                    addToCollection();
                                }}
                                color='default'
                                startIcon={<CreateDirectoryIcon />}
                                className={classes.createDirectoryButton}
                            >
                                <FormattedMessage id='redesign.collectionTree.createNewDirectory' />
                            </Button>
                        ) : null}
                        <Grid item xs={12}>
                            <CollectionTree
                                collections={filteredProcessedCollection}
                                addToCollection={addToCollection}
                                expandedList={defaultExpanded}
                                updateCollection={editCollection}
                                move={moveCollection}
                                onNodeSelect={handleNodeSelect}
                                tags={tags}
                                attachTag={attachTag}
                                createAndAttachTag={createAndAttachTag}
                                removeTag={removeTag}
                                state={state}
                                deleteBoard={confirmFolderDeletion}
                                openConfirmFolderDeletion={handleOpenPopUp}
                                stopEditing={stopEditing}
                                editing={editing}
                                onUpdate={onUpdate}
                                selectedNode={selectedNode}
                                create={create}
                                editBoard={editBoard}
                                handleOpen={handleOpen}
                                loadCollections={loadCollections}
                            />
                        </Grid>
                        <Hidden only={['md', 'lg', 'xl']}>
                            <div className={classes.mobilePaper} />
                        </Hidden>
                    </Grid>
                </Grid>
                <Hidden only={['xs', 'sm']}>
                    <Grid item xs={12} sm={4} className={classes.imageContainer}
                          style={{ alignItems: 'start', borderLeft: '0.063rem solid rgba(210, 104, 40, 0.1)' }}>
                        <Sticky enabled={true}>
                            <Paper className={classes.desktopPaper}>
                                <DesktopCollectionEditor
                                    selectedNode={selectedNode}
                                    onUpdate={onUpdate}
                                    create={create}
                                    state={state}
                                    tags={tags}
                                    attachTag={attachTag}
                                    createAndAttachTag={createAndAttachTag}
                                    removeTag={removeTag}
                                />
                                {selectedNode && !selectedNode.isUserCollection && !selectedNode.isUserBoard ? (
                                    <Grid container spacing={2} direction='column'
                                          style={{ padding: '0.5rem 2rem 2rem' }}>
                                        {selectedNode && !selectedNode.isCollection ? (
                                            <>
                                                <Grid item>
                                                <span style={{ display: 'flex', paddingTop: '0rem' }}>
                                                    <Typography className={classes.dayText}>
                                                        {new Date(selectedNode.createdAt).toLocaleString(
                                                            localesOptions,
                                                            {
                                                                year: 'numeric',
                                                                month: 'long',
                                                                day: 'numeric',
                                                            },
                                                        )}
                                                    </Typography>
                                                    <Typography className={classes.hourText}>
                                                        {new Date(selectedNode.createdAt).toLocaleString(
                                                            localesOptions,
                                                            {
                                                                hour: 'numeric',
                                                                minute: 'numeric',
                                                            },
                                                        )}
                                                    </Typography>
                                                </span>
                                                    {!selectedNode.canUse.value ? (
                                                        <Typography color={'secondary'}>
                                                            <FormattedMessage
                                                                id='subscription.upsell.library.unavailable' />
                                                        </Typography>
                                                    ) : null}
                                                </Grid>
                                                {state === LIBRARY_MANAGER_STATE.MANAGE ? (
                                                    <>
                                                        <Divider className={classes.customDividerEditor}
                                                                 variant='middle' />
                                                        <Grid item>
                                                            <Button
                                                                size='small'
                                                                startIcon={<EditIcon />}
                                                                onClick={editBoard}
                                                                className={classes.itemButton}
                                                            >
                                                                <FormattedMessage
                                                                    id='redesign.collections.renameSingleBoard.title' />
                                                            </Button>
                                                        </Grid>
                                                        <Grid item>
                                                            <Button
                                                                size='small'
                                                                startIcon={<DeleteIcon />}
                                                                onClick={deleteBoard}
                                                                className={classes.itemButtonDelete}
                                                            >
                                                                <FormattedMessage id='generic.label.delete' />
                                                            </Button>
                                                        </Grid>
                                                    </>
                                                ) : null}
                                            </>
                                        ) : null}
                                        {selectedNode &&
                                        selectedNode.isCollection &&
                                        !selectedNode.isBoardSet &&
                                        state === LIBRARY_MANAGER_STATE.MANAGE &&
                                        !create ? (
                                            <>
                                                <Grid item>
                                                    <Button
                                                        size='small'
                                                        startIcon={<AddNewSubFolderIcon />}
                                                        onClick={() => {
                                                            addToCollection(selectedNode);
                                                        }}
                                                        className={classes.itemButton}
                                                    >
                                                        <FormattedMessage
                                                            id='redesign.collections.createSubFolder.title' />
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        size='small'
                                                        startIcon={<EditIcon />}
                                                        onClick={editBoard}
                                                        className={classes.itemButton}
                                                    >
                                                        <FormattedMessage
                                                            id='redesign.collections.editCollection.title' />
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        size='small'
                                                        startIcon={<ShareLessonIcon />}
                                                        onClick={handleOpen}
                                                        className={classes.itemButton}
                                                    >
                                                        <FormattedMessage
                                                            id='redesign.collections.shareCollection.title' />
                                                    </Button>
                                                    <ShareCollectionsDesktop
                                                        intl={intl}
                                                        selectedNode={selectedNode}
                                                        open={open}
                                                        handleClose={handleClose}
                                                        nodeId={selectedNode.id}
                                                        loadCollections={loadCollections}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        size='small'
                                                        startIcon={<DeleteIcon />}
                                                        onClick={deleteBoard}
                                                        className={classes.itemButtonDelete}
                                                    >
                                                        <FormattedMessage
                                                            id='redesign.collections.removeCollection.title' />
                                                    </Button>
                                                </Grid>
                                            </>
                                        ) : null}
                                        {selectedNode &&
                                        selectedNode.isBoardSet &&
                                        state === LIBRARY_MANAGER_STATE.MANAGE ? (
                                            <Grid item>
                                                {isBoardSetUsable(selectedNode)}
                                                <Button
                                                    size='small'
                                                    startIcon={<DeleteIcon />}
                                                    onClick={deleteBoard}
                                                    className={classes.itemButtonDelete}
                                                >
                                                    <FormattedMessage id='generic.label.delete' />
                                                </Button>
                                            </Grid>
                                        ) : null}
                                        {selectedNode && selectedNode.isBoardSet && state === LIBRARY_MANAGER_STATE.LOAD ? (
                                            <Grid item>{isBoardSetUsable(selectedNode)}</Grid>
                                        ) : null}
                                    </Grid>
                                ) : null}
                            </Paper>
                        </Sticky>
                    </Grid>
                    <Popover
                        open={openPopUp}
                        onClose={handleClosePopUp}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Box style={{ padding: '1rem', width: '40vw' }}>
                            <Typography variant='body1' style={{ color: COLORS2.darkGreen }}>
                                <FormattedMessage id='redesign.collections.removeCollection.confirmation' />
                            </Typography>
                            <Grid
                                container
                                spacing={2}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    marginTop: '1rem',
                                }}
                            >
                                <Button onClick={confirmFolderDeletion}>
                                    <FormattedMessage id='redesign.collections.removeCollection.confirmation.yes' />
                                </Button>
                                <Button onClick={cancelFolderDeletion}>
                                    <FormattedMessage id='redesign.collections.removeCollection.confirmation.no' />
                                </Button>
                            </Grid>
                        </Box>
                    </Popover>
                </Hidden>
            </Grid>
        </Paper>
    );
};

export default injectIntl(CollectionManager);
