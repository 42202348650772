import styles from './styles';
import React, { useEffect, useState } from 'react';
import { OutlinedInput, FormControl, Grid, Chip } from '@material-ui/core';
import userApi from '../../../Api/userApi';
import collectionsApi from '../../../Api/collectionsApi';
import { enterOwnEmail, enterValidEmail, infoAlreadyShared, showSuccess } from '../../../redux/actions';
import store from '../../../redux/store';
import GenericState from '../../../MyComponents/GenericLoadingErrorState/GenericState';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/Add';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import VBToolTip from '../../../MyComponents/Tooltip/VBToolTip';
import { FormattedMessage } from 'react-intl';
import { COLORS2 } from '../../../constants';
import { connect } from 'react-redux';
import sendEmailsApi from '../../../Api/sendEmailsApi';

const ShareCollectionsMobile = ({ intl, selectedNode, changeContent, profile, nodeId, loadCollections }) => {
    const classes = styles();
    const [searchEmail, setSearchEmail] = useState('');
    const [enteredItems, setEnteredItems] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [userNotFound, setUserNotFound] = useState(false);

    const handleSearchTextChange = (event) => {
        const newSearchText = event.target.value;
        setSearchEmail(newSearchText);
    };

    useEffect(() => {
        if (changeContent) {
            loadSharedList();
        }
    }, [selectedNode, changeContent]);

    const loadSharedList = () => {
        setIsLoading(true);
        collectionsApi
            .getUserForSharedBoards(selectedNode.id)
            .then((res) => {
                if (res) {
                    setEnteredItems(res.data);
                } else {
                    console.error('no response getUserForSharedBoards');
                }
                if (!res.data) {
                    console.error('no class available getUserForSharedBoards');
                }
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                console.log(err);
            });
    };

    const findEmail = (email) => {
        let foundItem = enteredItems.find(function (item) {
            return item.email === email;
        });

        return !!foundItem;
    };

    const handleShare = async () => {
        setIsLoading(true);
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (searchEmail.trim() !== '') {
            if (emailRegex.test(searchEmail)) {
                if (searchEmail === profile.email) {
                    store.dispatch(enterOwnEmail('ENTER_OWN_EMAIL'));
                    setSearchEmail('');
                } else {
                    const isEmailEntered = findEmail(searchEmail);
                    if (isEmailEntered) {
                        store.dispatch(infoAlreadyShared('LIBRARY_ALREADY_SHARED'));
                        setSearchEmail('');
                    } else {
                        try {
                            setIsLoading(true);
                            let searchUser = await userApi.verifyUserEmailforLessonShare(searchEmail);
                            if (searchUser.data !== '') {
                                setSearchEmail('');
                                await collectionsApi.shareLibrary({
                                    userId: searchUser.data.id,
                                    boardCollectionId: selectedNode.id,
                                });
                                loadSharedList();
                                loadCollections();
                                return;
                            } else {
                                setUserNotFound(true);
                                setIsLoading(false);
                            }
                        } catch (err) {
                            console.log(err);
                            setIsError(true);
                            setIsLoading(false);
                        }
                    }
                }
            } else {
                store.dispatch(enterValidEmail('ENTER_VALID_EMAIL'));
            }
        }
        setIsLoading(false);
    };

    const handleRemoveItem = (userId, boardCollectionId) => {
        setIsLoading(true);
        collectionsApi.removeShareForUser(userId, boardCollectionId).then(() => {
            loadSharedList();
            loadCollections();
        });
    };

    const sendInvitation = async () => {
        await sendEmailsApi.sendInvitationToNewUserEmail({
            email: searchEmail,
            language: 'ro',
            boardCollectionId: selectedNode.id,
        });
        setSearchEmail('');
        setUserNotFound(false);
        store.dispatch(showSuccess('USER_INVITED'));
    };
    const cancelSendInvitation = () => {
        setSearchEmail('');
        setUserNotFound(false);
    };

    const BuildProfessorData = ({ first_name, last_name, email }) => {
        let name = first_name + ' ' + last_name;
        return (
            <VBToolTip
                content={
                    <div className="u-fx u-fx-col">
                        <Typography variant="body1" style={{ color: '#FFFFFF' }}>
                            {email}
                        </Typography>
                    </div>
                }
            >
                <div className="u-fx u-fx-align-center">
                    <div className="u-fx u-fx-col">
                        <Typography variant="body1" style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                            {name}
                        </Typography>
                    </div>
                </div>
            </VBToolTip>
        );
    };

    return (
        <Grid container spacing={2} className={classes.mobileBox}>
            <Grid item xs={12}>
                {userNotFound ? (
                    <Box style={{ padding: '1rem', width: '100%' }}>
                        <Typography variant="body1" style={{ color: COLORS2.darkGreen }}>
                            <FormattedMessage id="redesign.collections.sendInvitation" />
                        </Typography>
                        <Grid
                            container
                            spacing={2}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                marginTop: '1rem',
                            }}
                        >
                            <Button onClick={sendInvitation}>
                                <FormattedMessage id="redesign.collections.sendInvitationButton" />
                            </Button>
                            <Button onClick={cancelSendInvitation}>
                                <FormattedMessage id="redesign.collections.cancelSendInvitationButton" />
                            </Button>
                        </Grid>
                    </Box>
                ) : (
                    <FormControl variant="outlined" size="small" fullWidth>
                        <OutlinedInput
                            id="searchCollection"
                            placeholder={intl.formatMessage({ id: 'redesign.collections.searchProfessorByEmail' })}
                            value={searchEmail}
                            onChange={handleSearchTextChange}
                            margin="none"
                            className={classes.outlinedInput}
                            fullWidth
                            endAdornment={
                                searchEmail.trim() !== '' ? (
                                    <Button
                                        size="large"
                                        color="primary"
                                        variant="contained"
                                        onClick={handleShare}
                                        className={classes.buttonMobile}
                                        endIcon={<AddIcon className={classes.iconSm} />}
                                    />
                                ) : null
                            }
                        />
                    </FormControl>
                )}
            </Grid>
            {isLoading ? (
                <GenericState isLoading={true} inline={true} />
            ) : (
                <Grid item xs={12} className={classes.chipContainerMobile}>
                    {enteredItems.length && !userNotFound
                        ? enteredItems.map((item, index) => (
                              <Chip
                                  key={index}
                                  label={
                                      <BuildProfessorData
                                          email={item.email}
                                          first_name={item.first_name}
                                          last_name={item.last_name}
                                      />
                                  }
                                  avatar={<Avatar alt={item.first_name} src={item.avatar} />}
                                  onDelete={() => {
                                      handleRemoveItem(item.userId, nodeId);
                                  }}
                                  className={classes.chip}
                              />
                          ))
                        : !userNotFound && (
                              <Typography variant="body1" style={{ color: COLORS2.darkGreen }}>
                                  <FormattedMessage id="redesign.collections.sharedToNobody" />
                              </Typography>
                          )}
                </Grid>
            )}
        </Grid>
    );
};

const mapStateToProps = (state) => {
    const { profile } = state;
    return { profile };
};

export default connect(mapStateToProps)(ShareCollectionsMobile);
