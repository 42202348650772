import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ArrowLeftIcon(props) {
    return (
        <SvgIcon {...props} style={{ ...props.style, verticalAlign: 'bottom' }}>
            <svg viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_657_4968)">
                    <path d="M21.5 12C21.5 12.4125 21.1531 12.75 20.7218 12.75H3.03637L9.63168 19.7485C9.92029 20.0546 9.89676 20.5294 9.57928 20.8074C9.42965 20.9344 9.24215 21 9.05465 21C8.84348 21 8.63235 20.918 8.47903 20.7539L0.702463 12.5039C0.432838 12.2183 0.432838 11.7816 0.702463 11.4961L8.47903 3.24612C8.76918 2.93853 9.26137 2.91799 9.57824 3.19484C9.89573 3.47318 9.91926 3.94765 9.63065 4.25375L3.03637 11.25H20.7223C21.1531 11.25 21.5 11.5875 21.5 12Z" fill="#08AAB4"/>
                </g>
                <defs>
                    <clipPath id="clip0_657_4968">
                        <rect width="21" height="24" fill="white" transform="translate(0.5)"/>
                    </clipPath>
                </defs>
            </svg>

        </SvgIcon>
    );
}
