export const termsHTML = `
<h1>Terms of Service</h1>
<p>
    Please read these terms of service ("terms", "terms of service") carefully before using the
    VBoard.ro website (the "service") operated by Virtual Board SRL Romania ("us", “we”, “our”, “the
    company”).
</p>
<h3>Conditions of use</h3>
<p>
    We will provide their services to you, which are subject to the conditions stated below in this
    document. Every time you visit this website, use its services or make a purchase, you accept the
    following conditions. This is why we urge you to read them carefully
</p>
<h3>Usage of VBoard.ro</h3>
<p>
    Teacher will create a new groups and invite students to it. All teachers and students are
    required to be logged in in order to use the service.
</p>
<h3>Privacy Policy</h3>
<p>
    Before you continue using our website we advise you to read our privacy policy, regarding our
    user data collection. It will help you better understand our practices.
</p>
<h3>Copyright</h3>
<p>
    Content available on this website (digital downloads, images, texts, graphics, logos) is the
    property of the service and/or its content creators and protected by international copyright
    laws. The entire compilation of the content found on this website is the exclusive property of
    the company. The ownership of the content published by the user is not transferred to the
    company, and the user is responsible for following applicable copyright laws regarding the
    published content.
</p>
<h3>Communications</h3>
<p>
    The entire communication with us is electronic. Every time you send us an email or visit our
    website, you are going to be communicating with us. You hereby consent to receive communications
    from us. If you subscribe to the news on our website, you are going to receive regular emails
    from us. We will continue to communicate with you by posting news and notices on our website and
    by sending you emails. You also agree that all notices, disclosures, agreements and other
    communications we provide to you electronically meet the legal requirements that such
    communications be in writing.
</p>
<h3>Parental Consent</h3>
<p>
    Students need an account to access the service. When the teacher creates a group, the teacher
    should verify that he/she, the school or the organisation has the appropriate parental consent,
    according to any local legislation and data protection acts, before the group link is shared
    with the students.
</p>
<h3>Applicable Law</h3>
<p>
    By visiting this website, you agree that the laws of Romania, without regard to principles of
    conflict laws, will govern these terms of service, or any dispute of any sort that might come
    between the company and you, or its business partners and associates.
</p>
<h3>Disputes</h3>
<p>
    Any dispute related in any way to your visit to this website or to products you purchase from us
    shall be arbitrated by the state or federal court of Finland and you consent to exclusive
    jurisdiction and venue of such courts.
</p>
<h3>Content</h3>
<p>
    Visitors may post content as long as it is not obscene, illegal, defamatory, threatening,
    infringing of intellectual property rights, invasive of privacy or injurious in any other way to
    third parties. Content has to be free of software viruses, political campaign, and commercial
    solicitation. We reserve all rights (but not the obligation) to remove and/or edit such content.
</p>
<h3>License and Site Access</h3>
<p>
    We grant you a limited license to access and make personal use of this website. You are not
    allowed to download or modify it. This may be done only with written consent from us.
</p>
<h3>User Account</h3>
<p>
    If you are an owner of an account on this website, you are solely responsible for maintaining
    the confidentiality of your private user details (account information and organized
    information). You are responsible for all activities that occur under your account or password.
    We reserve all rights to terminate accounts, edit or remove content and cancel orders in their
    sole discretion.
</p>
<h3>Cookies</h3>
<p>
    Like any other website, VBoard.ro uses 'cookies'. These cookies are used to store information
    including visitors' preferences, and the pages on the website that the visitor accessed or
    visited. The information is used to optimize the users' experience by customizing our web page
    content based on visitors' browser type and/or other information. For more general information
    on cookies, please read the "What Are Cookies" article on
    <a href='https://www.cookieconsent.com/what-are-cookies/'>Cookie Consent website</a>.
</p>
<h3>FERPA and California AB 1584</h3>
<p>
    Regarding FERPA and California AB 1584 (Buchanan) Privacy of Pupil Records: 3rd-Party Digital
    Storage &amp; Education Software (Education Code section 49073.1), VBoard.ro will abide to the
    following:
</p>
<p>
    1. Student records obtained by VBoard.ro from an educational institution continue to be the
    property of and under the control of the educational institution. The educational institution
    retains full ownership rights to the personal information and education records it provides to
    VBoard.ro.
</p>
<p>
    2. VBoard.ro users may retain possession and control of their own generated content by
    downloading it to their devices.
</p>
<p>
    3. VBoard.ro will not use any information in a student record for any purpose other than those
    required or specifically permitted by the VBoard.ro Terms of Use and Privacy Policy.
</p>
<p>
    4. Parents, legal guardians, or eligible students may review personally identifiable information
    in the student’s records and correct erroneous information by contacting their educational
    institution. Additionally, VBoard.ro users may access, correct, update, or delete personal
    information in their profile by signing into VBoard.ro, accessing their user account, and making
    the appropriate changes.
</p>
<p>
    5. VBoard.ro is committed to maintaining the security and confidentiality of student records.
    Towards this end, we take the following actions: (a) we limit employee access to student data to
    only those employees with a need to such access to fulfill their job responsibilities; (b) we
    conduct background checks on our employees that may have access to student data; (c) we conduct
    regular employee privacy and data security training and education; and (e) we protect personal
    information with technical, contractual, administrative, and physical security safeguards in
    order to protect against unauthorized access, release or use.
</p>
<p>
    6. In the event of an unauthorized disclosure of a student’s records, VBoard.ro will (1)
    promptly notify Users unless specifically directed not to provide such notification by law
    enforcement officials. Notification shall identify: (i) the date and nature of the unauthorized
    use or disclosure; (ii) the Private Data used or disclosed; (iii) general description of what
    occurred including who made the unauthorized use or received the unauthorized disclosure; (iv)
    what VBoard.ro has done or shall do to mitigate any effect of the unauthorized use or
    disclosure; (v) what corrective action VBoard.ro has taken or shall take to prevent future
    similar unauthorized use or disclosure; and (vi) who at VBoard.ro the User can contact.
    VBoard.ro will keep the User fully informed until the incident is resolved.
</p>
<p>
    7. VBoard.ro will delete or de-identify personal information when it is no longer needed, upon
    expiration or termination of our agreement with an educational institution with any deletion or
    de-identification to be completed according to the terms of our agreement with the educational
    institution, or at the direction or request of the educational institution.
</p>
<p>
    8. VBoard.ro agrees to work with educational institution to ensure compliance with FERPA and the
    Parties will ensure compliance by providing parents, legal guardians or eligible students with
    the ability to inspect and review student records and to correct any inaccuracies therein as
    described in statement (4) above.
</p>
<p>
    9. VBoard.ro prohibits using personally identifiable information in student records to engage in
    targeted advertising.
</p>
<h3>Disclaimer</h3>
<p>
    The company will attempt to provide the service in the best way possible without interruptions,
    the user understands and agrees that the service is provided “as is” and “as available”, without
    any express or implied warranty, condition or assurance of any kind. This means that the company
    does not represent or warrant to you that: the use of the service will meet your needs or
    requirements. the use of the service will operate in an uninterrupted, timely, secure, or
    error-free manner. the information provided by the service will be accurate or reliable. any
    imperfections in the functionality of the service will be repaired or corrected. You are using
    the service at your own risk and for your own account. Any resources downloaded or otherwise
    obtained through the use of the service is done at your own discretion and risk, and you are
    solely responsible for any damage to your devices for any loss of data that may result from the
    download of such resources. The company makes no representations and disclaim any warranties or
    conditions of satisfactory quality, merchantability, fitness for a particular purpose, or
    non-infringement. Neither the company, its licensors nor partners resources warrants that the
    service is free of malware or other harmful components. In addition, the company makes no
    representation nor does it warrant, endorse, guarantee, or assume responsibility for any third
    party applications (or the resources thereof), user content, or any other product or service
    offered by a third party on or through the service or any hyperlinked website, or featured in
    any banner or other advertising. You understand and agree that the service is not responsible or
    liable for any communication between users or any transaction between you and third parties
    through the service. No information or advice, whether expressed, implied, oral or written,
    obtained by you from the company shall create any warranty, guarantee, or conditions of any
    kind, except for those expressly outlined in these Terms of Service.
</p>
`;