import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import analyticsApi from '../../Api/analyticsApi';
import Divider from '@material-ui/core/Divider';
import VBToolTip from '../../MyComponents/Tooltip/VBToolTip';

const MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

function buildCountSlots(counts) {
    let monthSlots = [];
    let slotDayIndex = -1;
    let slotWeekIndex = -1;
    if (counts && counts.length > 0) {
        let countStart = counts[0].day;
        for (let i = countStart; i < counts[counts.length - 1].day + 1; i++) {
            let el = counts.find((e) => e.day === i);
            let date = new Date(i * 24 * 60 * 60 * 1000);
            if (!el) {
                el = { day: i, count: '0' };
            }
            let month = monthSlots.find((e) => e.month === MONTHS[date.getMonth()]);
            if (!month) {
                monthSlots.push({ month: MONTHS[date.getMonth()], weeks: [] });
                slotWeekIndex = -1;
                slotDayIndex = -1;
            }
            month = monthSlots.find((e) => e.month === MONTHS[date.getMonth()]);
            let weeks = month.weeks;
            if (slotWeekIndex === -1) {
                weeks.push({ days: [] });
                slotWeekIndex = 0;
            }
            let days = weeks[slotWeekIndex].days;
            let currentDay = date.getDay();
            if (slotDayIndex === -1) {
                for (let i = 0; i < currentDay; i++) {
                    days.push({ date, count: 0 });
                }
                slotDayIndex = currentDay;
            }

            days.push({ date, count: +el.count });
            slotDayIndex = currentDay;
            if (slotDayIndex >= 6) {
                slotDayIndex = -1;
                weeks.push({ days: [] });
                slotWeekIndex++;
            }
        }
    }
    return monthSlots;
}

function computeBackground(val) {
    if (val > 0 && val < 100) {
        return '#9CC069';
    }
    if (val > 100 && val < 200) {
        return '#669D45';
    }
    if (val > 200 && val < 300) {
        return '#637939';
    }
    if (val > 300 && val < 800) {
        return '#3A6427';
    }
    if (val > 800) {
        return '#12230b';
    }
    return '#EDEDED';
}

const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

const AccountStatus = () => {
    const [totalUserCount, setTotalUserCount] = React.useState(null);
    const [subscriptionBreakdown, setSubscriptionBreakdown] = React.useState(null);
    const [loginGroups, setLoginGroups] = React.useState(null);

    useEffect(() => {
        analyticsApi.getAccountStatus().then((res) => {
            setTotalUserCount(res?.data?.totalUserCount[0]?.count);
            setSubscriptionBreakdown(res?.data?.subscriptionCount);
            setLoginGroups(res?.data?.loginCounts);
            // console.log(buildCountSlots(res?.data?.loginCounts));
        });
    }, []);

    const renderSlots = () => {
        let counts = buildCountSlots(loginGroups);
        if (!counts || counts.length === 0) {
            return null;
        }

        return counts.map((month) => {
            return (
                <Grid item xs={4} key={`day-render-month-${month.month}`}>
                    <Typography>{month.month}</Typography>
                    <div style={{ display: 'inline-flex' }}>
                        {month.weeks.map((week, index) => {
                            return (
                                <div key={`day-render-month-day-${month.month}-week-${index}`}>
                                    {week.days.map((day, index1) => {
                                        return (
                                            <VBToolTip
                                                content={
                                                    <Typography>
                                                        {`${day?.count ? day?.count : 0}`}
                                                        {' users : '}
                                                        {day.date.toLocaleDateString('en-us', dateOptions)}
                                                    </Typography>
                                                }
                                                key={`day-render-month-day-${month.month}-week-${index}-day${index1}`}
                                            >
                                                <div
                                                    style={{
                                                        width: '3rem',
                                                        height: '2rem',
                                                        textAlign: 'center',
                                                        background: computeBackground(day?.count),
                                                    }}
                                                >
                                                    {day?.count}
                                                </div>
                                            </VBToolTip>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </div>
                </Grid>
            );
        });
    };

    return (
        <Grid container direction="row" spacing={2} style={{ paddingTop: '20px' }}>
            <Grid item xs={12}>
                <Typography variant="h5">Total user count: {totalUserCount}</Typography>
            </Grid>
            {subscriptionBreakdown
                ? subscriptionBreakdown.map((el) => {
                      return (
                          <Grid item xs={4} key={`subscription-element${el.subscriptionName}`}>
                              <Typography variant={'h5'}>
                                  {el.subscriptionName}: {el.count}
                              </Typography>
                          </Grid>
                      );
                  })
                : null}
            <Grid item xs={12}>
                <Divider />
            </Grid>
            {renderSlots()}
        </Grid>
    );
};

export default AccountStatus;
