import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function HomeLine1(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 78 116" style={{ width: '8%', height: '100%' }}>
            <svg fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.10208 114.002L1.10299 44.6369L76.8395 1.13686" stroke="#F9A237" strokeWidth="2" strokeLinecap="round" strokeDasharray="4 8"/>
            </svg>
        </SvgIcon>
    );
}
