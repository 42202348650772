import Grid from '@material-ui/core/Grid';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import { COLORS2 } from '../../constants';

const useStyles = makeStyles(() => ({
    wrap: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: COLORS2.dawnBackground,
    },
    divContainer: {
        display: 'flex',
        position: 'absolute',
        backgroundColor: '#FFFFFF',
        borderRadius: '2rem',
        padding: '2rem',
        width: '45rem',
        height: '39rem',
        right: '20%',
    },
    tutorialImg: {
        background: 'transparent',
        borderRadius: '2rem',
        zIndex: 1,
        marginTop: '3rem',
        textAlign: 'center',
        display: 'block',
        margin: '0 auto',
    },
    tutorialImgNoBackground: {
        background: 'transparent',
        borderRadius: '2rem',
        zIndex: 1,
        textAlign: 'center',
        display: 'block',
        margin: '0 auto',
    },
    mobileH1: {
        fontSize: '3.25rem',
        lineHeight: '3.75rem',
    },
    loginContainer: {
        background: 'transparent',
        padding: '2rem 1rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    featuresContainer: {
        position: 'relative',
        marginBottom: '-10rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    bottomGreenPaper: {
        background: 'rgba(8, 170, 180, 0.16)',
        borderRadius: '0rem 0rem 2rem 2rem',
        height: '1.5rem',
        width: '80%',
    },
    landingVideo: {
        borderRadius: '2rem',
        border: '3px solid #065156',
    },
}));

const MobileLanding = (props) => {
    const classes = useStyles();

    return (
        <>
            <Grid item xs={12} style={{ paddingTop: props.needsBackground ? '4rem' : '3rem' }}>
                <Paper elevation={0} className={classes.wrap}>
                    <Grid container spacing={2} style={{ position: 'relative' }}>
                        {props.needsBackground ? (
                            <>
                                <div className={classes.divContainer} />
                                <Grid item xs={12} md={5} className={classes.tutorialImg}>
                                    <iframe width="320" height="180" controls autoPlay src={props.videoUrl} className={classes.landingVideo} />
                                </Grid>
                                {props.children}
                            </>
                        ) : (
                            <>
                                {props.children}
                                <div className={classes.tutorialImgNoBackground}>
                                    <iframe width="100%" height="100%" controls autoPlay src={props.videoUrl} />
                                </div>
                            </>
                        )}
                    </Grid>
                </Paper>
            </Grid>
        </>
    );
};

export default MobileLanding;
