import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import VBToolTip from '../../Tooltip/VBToolTip';
import { FormattedMessage, injectIntl } from 'react-intl';
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import { BOARD_TYPE, COLORS, COLORS_SEMANTICAL, TARGETED_REQUESTS } from '../../../constants';
import TextField from '@material-ui/core/TextField';
import { InputAdornment } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';
import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import socketUtils from '../../../pages/LessonPage/socketUtils';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
    margin: {
        marginLeft: theme.spacing(1),
    },
    chatBox: {
        width: '400px',
        maxWidth: '90vw',
        height: '40vh',
        border: '1px solid',
        marginBottom: '1rem',
        borderColor: COLORS_SEMANTICAL.PRIMARY_BORDER,
        boxShadow: `0 0 0 2px ${COLORS_SEMANTICAL.PRIMARY_BORDER_TRANSPARENT}`,
        borderRadius: '8px',
        overflow: 'auto',
        padding: '0.5rem',
        display: 'flex',
        flexFlow: 'column',
        backgroundColor: '#FFF',
    },
    chatText: {
        width: '100%',
    },
    chatMessages: {
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    myMessage: {
        display: 'flex',
        width: '100%',
        flexFlow: 'row-reverse',
        margin: '4px',
    },
    myMessageLines: {
        backgroundColor: COLORS.primary + '66',
        padding: '2px 1rem',
        borderRadius: '4px',
        display: 'flex',
        flexFlow: 'column',
    },
    otherMessage: {
        display: 'flex',
        margin: '4px',
        width: '100%',
    },
    otherMessageLines: {
        backgroundColor: COLORS.secondary + '66',
        padding: '2px 1rem',
        borderRadius: '4px',
        display: 'flex',
        flexFlow: 'column',
    },
    otherAvatar: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        marginRight: '0.5rem',
    },
    myAvatar: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        marginLeft: '0.5rem',
    },
    otherName: {
        fontWeight: '700',
        fontSize: '12px',
    },
}));

const ChatBox = ({ lessonState, intl, profile }) => {
    const classes = useStyles();
    const [messages, setMessages] = useState({});
    const [target, setTarget] = useState(null);
    const [popInMessages, setPopInMessages] = useState([]);
    const [messageBoxActive, setMessageBoxActive] = useState(false);
    const [currentText, setCurrentText] = useState('');
    const messageBoxRef = useRef(null);
    const popupState = useRef({
        timeoutFunc: null,
    });
    const isMultiBoard = lessonState?.boardType === BOARD_TYPE.MULTI_BOARD;

    useEffect(() => {
        if (currentText === '\n') {
            setCurrentText('');
        }
    });

    const handleTextChange = (event) => {
        setCurrentText(event.target.value);
    };

    const showMessage = (m) => {
        let texts = m.msg.split('\n');
        if (m.src === profile.email) {
            return (
                <div key={`messageBox-${m.id}`} className={classes.myMessage}>
                    <Avatar src={profile.avatar} className={classes.otherAvatar} />
                    <div className={classes.myMessageLines}>
                        <span className={classes.otherName}>{profile.name}</span>
                        {texts.map((t, index) => (
                            <span key={`msgLine-${m.id}-${index}`}>{t}</span>
                        ))}
                    </div>
                </div>
            );
        } else {
            let member = lessonState?.members?.find((mrb) => mrb.email === m.src);
            if (!member) {
                member = { avatar: null, name: '' };
            }
            return (
                <div key={`messageBox-${m.id}`} className={classes.otherMessage}>
                    <Avatar src={member.avatar} className={classes.otherAvatar} />
                    <div className={classes.otherMessageLines}>
                        <span className={classes.otherName}>{member.name}</span>
                        {texts.map((t, index) => (
                            <span key={`msgLine-${m.id}-${index}`}>{t}</span>
                        ))}
                    </div>
                </div>
            );
        }
    };

    const showMessages = (messages) => {
        let showMsg = messages.sort((a, b) => {
            return a.ts < b.ts;
        });
        return (
            <div style={{ width: '100%', paddingBottom: '1rem' }} ref={messageBoxRef}>
                {showMsg.map((m) => showMessage(m))}
            </div>
        );
    };

    const pushMessage = () => {
        let msg = {
            id: uuidv4(),
            ts: new Date().getTime(),
            msg: currentText.trim(),
            src: profile.email,
        };
        if (isMultiBoard && target) {
            msg.dst = target;
            pushMainMessages([msg]);
            socketUtils.sendTargetedRequest([target], TARGETED_REQUESTS.CHAT_MSG, [msg]);
        } else if (!isMultiBoard) {
            pushMainMessages([msg]);
            socketUtils.sendChatMessages([msg]);
        }

        setCurrentText('');
    };

    const buildMsgArray = () => {
        let msgArr = [];
        if (!isMultiBoard) {
            msgArr = Object.values(messages).filter((m) => !m.dst);
        } else if (target) {
            msgArr = Object.values(messages).filter(
                (m) => (m.src === profile.email && m.dst === target) || (m.dst === profile.email && m.src === target)
            );
        }
        return msgArr;
    };

    const showMessageBox = () => {
        return (
            <div className={classes.chatBox}>
                <div className={classes.chatMessages}>{showMessages(buildMsgArray())}</div>
                <div className={classes.chatText}>
                    <TextField
                        variant="outlined"
                        label={intl.formatMessage({ id: 'tooltip.lessonPage.chat' })}
                        value={currentText}
                        onChange={handleTextChange}
                        fullWidth
                        multiline
                        onKeyDown={(e) => {
                            // console.log('===key', e.ctrlKey, e.keyCode, e.key, e.code);
                            if (!e.ctrlKey && e.key === 'Enter' && !isEmpty(currentText)) {
                                pushMessage();
                                e.stopPropagation();
                            } else if (e.ctrlKey && e.key === 'Enter') {
                                setCurrentText(currentText + '\n');
                                e.stopPropagation();
                            }
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        color={'primary'}
                                        disabled={isEmpty(currentText)}
                                        onClick={() => {
                                            pushMessage();
                                        }}
                                    >
                                        <SendIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
            </div>
        );
    };

    const pushMainMessages = (newMessages) => {
        if (!newMessages || newMessages.length === 0) {
            return;
        }
        newMessages.forEach((m) => {
            messages[m.id] = m;
        });
        setMessages({ ...messages });

        scrollToBottom();
        if (
            newMessages.length === 1 &&
            (!messageBoxActive ||
                (messageBoxActive &&
                    isMultiBoard &&
                    target &&
                    newMessages[0].src !== target &&
                    newMessages[0].src !== profile.email) ||
                (isMultiBoard && !target))
        ) {
            setPopInMessages((prevMessages) => {
                return [...prevMessages, ...newMessages];
            });
            if (popupState.current.timeoutFunc) {
                clearTimeout(popupState.current.timeoutFunc);
            }
            popupState.current.timeoutFunc = setTimeout(() => {
                setPopInMessages([]);
            }, 2000);
        }
    };

    const scrollToBottom = () => {
        setTimeout(() => {
            if (messageBoxRef.current) {
                messageBoxRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
            }
        }, 200);
    };

    const getMainMessages = (requestedTarget) => {
        let msg;
        if (!requestedTarget) {
            msg = Object.values(messages).filter((m) => !m.dst);
        } else {
            msg = Object.values(messages).filter(
                (m) =>
                    (m.src === profile.email && m.dst === requestedTarget) ||
                    (m.dst === profile.email && m.src === requestedTarget) ||
                    !m.dst
            );
        }
        return msg;
    };

    window.pushMainMessages = pushMainMessages;
    window.getMainMessages = getMainMessages;
    window.setChatTarget = setTarget;

    let showChat = true;
    if (isMultiBoard && !target) {
        showChat = false;
    }
    if (lessonState?.members && lessonState?.members.length === 0) {
        showChat = false;
    }

    return (
        <div style={{ display: 'inline' }}>
            {popInMessages.length > 0 ? showMessages(popInMessages) : null}
            {messageBoxActive && showChat ? showMessageBox() : null}
            {showChat ? (
                <VBToolTip content={<FormattedMessage id="tooltip.lessonPage.chat" />}>
                    <Fab
                        id="muteMic"
                        size="small"
                        aria-label="mic"
                        onClick={() => {
                            setMessageBoxActive(!messageBoxActive);
                            if (!messageBoxActive) {
                                scrollToBottom();
                            }
                        }}
                        className={classes.margin}
                        color={'primary'}
                    >
                        <SpeakerNotesIcon />
                    </Fab>
                </VBToolTip>
            ) : null}
        </div>
    );
};

const mapStateToProps = (state) => {
    const { lessonState, profile } = state;
    return { lessonState, profile };
};
export default injectIntl(connect(mapStateToProps)(ChatBox));
