import apiUtil from './apiUtil';

let analyticsApi = {};

analyticsApi.getLogins = function (rowsPerPage, page, sortField, sortDir) {
    let myData = {
        url: `/api/private/analytics/logins?limit=${rowsPerPage}&offset=${
            page * rowsPerPage
        }&sortField=${sortField}&sortDir=${sortDir}`,
        method: 'GET',
    };
    return apiUtil.runRequest(myData);
};

analyticsApi.getLessonsWithUserCount = function (rowsPerPage, page, sortField, sortDir) {
    let myData = {
        url: `/api/private/analytics/lessonsWithUserCount?limit=${rowsPerPage}&offset=${
            page * rowsPerPage
        }&sortField=${sortField}&sortDir=${sortDir}`,
        method: 'GET',
    };
    return apiUtil.runRequest(myData);
};

analyticsApi.getLessonsByAdmins = function (rowsPerPage, page, sortField, sortDir) {
    let myData = {
        url: `/api/private/analytics/lessonsByAdmins?limit=${rowsPerPage}&offset=${
            page * rowsPerPage
        }&sortField=${sortField}&sortDir=${sortDir}`,
        method: 'GET',
    };
    return apiUtil.runRequest(myData);
};

analyticsApi.getAccountStatus = function () {
    let myData = {
        url: `/api/private/analytics/status`,
        method: 'GET',
    };
    return apiUtil.runRequest(myData);
};

analyticsApi.getSamsungAccounts = function (rowsPerPage, page, sortField, sortDir) {
    let myData = {
        url: `/api/private/analytics/samsung?limit=${rowsPerPage}&offset=${
            page * rowsPerPage
        }&sortField=${sortField}&sortDir=${sortDir}`,
        method: 'GET',
    };
    return apiUtil.runRequest(myData);
};

analyticsApi.addSamsungSN = function (data) {
    let myData = {
        url: `/api/private/analytics/samsung`,
        method: 'POST',
        data,
    };
    return apiUtil.runRequest(myData);
};

export default analyticsApi;
