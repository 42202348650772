import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function CompassIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                zoomAndPan="magnify"
                viewBox="0 0 150 150"
                preserveAspectRatio="xMidYMid meet"
                version="1.0"
            >
                <path
                    d="M 69.800781 48.589844 C 69.800781 49.96875 71.9375 51.507812 75.007812 51.507812 C 78.078125 51.507812 80.214844 49.96875 80.214844 48.589844 C 80.214844 47.210938 78.078125 45.675781 75.007812 45.675781 C 71.9375 45.675781 69.800781 47.210938 69.800781 48.589844 Z M 69.800781 48.589844 "
                    fillOpacity="1"
                    fillRule="nonzero"
                />
                <path
                    d="M 36.980469 75.433594 C 35.082031 75.433594 33.472656 76.351562 33.160156 77.613281 L 33.039062 78.089844 L 33.171875 78.5625 C 33.511719 79.785156 35.113281 80.679688 36.984375 80.679688 L 42.160156 80.679688 L 43.628906 75.433594 Z M 36.980469 75.433594 "
                    fillOpacity="1"
                    fillRule="nonzero"
                />
                <path
                    d="M 61.175781 75.433594 L 60.148438 77.25 L 59.109375 80.675781 L 68.390625 80.675781 L 68.390625 75.433594 Z M 61.175781 75.433594 "
                    fillOpacity="1"
                    fillRule="nonzero"
                />
                <path
                    d="M 116.851562 77.546875 C 116.507812 76.320312 114.902344 75.429688 113.035156 75.429688 L 106.382812 75.429688 C 106.382812 75.429688 107.851562 80.675781 107.851562 80.675781 L 113.035156 80.675781 C 114.933594 80.675781 116.542969 79.757812 116.859375 78.496094 L 116.976562 78.019531 L 116.847656 77.546875 Z M 116.851562 77.546875 "
                    fillOpacity="1"
                    fillRule="nonzero"
                />
                <path
                    d="M 62.800781 39.640625 L 64.457031 36.65625 C 64.78125 36.070312 65.402344 35.703125 66.074219 35.703125 L 71.488281 35.703125 C 72.445312 35.703125 73.246094 36.433594 73.332031 37.386719 L 73.761719 42.0625 C 74.171875 42.019531 74.582031 41.96875 75.007812 41.96875 C 75.429688 41.96875 75.835938 42.019531 76.242188 42.058594 L 76.667969 37.386719 C 76.753906 36.433594 77.554688 35.703125 78.511719 35.703125 L 83.957031 35.703125 C 84.628906 35.703125 85.25 36.070312 85.574219 36.660156 L 87.21875 39.628906 C 89.503906 37.835938 90.75 35.652344 90.75 33.367188 C 90.75 27.925781 83.542969 23.328125 75.007812 23.328125 C 66.476562 23.328125 59.265625 27.925781 59.265625 33.367188 C 59.265625 35.652344 60.511719 37.84375 62.800781 39.640625 Z M 62.800781 39.640625 "
                    fillOpacity="1"
                    fillRule="nonzero"
                />
                <path
                    d="M 78.296875 19.839844 L 78.296875 1.644531 C 78.296875 1 76.988281 0.0234375 75.015625 0.0234375 C 73.023438 0.0234375 71.703125 1 71.703125 1.644531 L 71.703125 19.839844 C 72.78125 19.710938 73.878906 19.625 75.007812 19.625 C 76.132812 19.625 77.226562 19.707031 78.296875 19.839844 Z M 78.296875 19.839844 "
                    fillOpacity="1"
                    fillRule="nonzero"
                />
                <g>
                    <path
                        d="M 66.097656 48.589844 C 66.097656 46.230469 67.6875 44.203125 70.128906 43.03125 L 69.796875 39.40625 L 67.164062 39.40625 L 47.332031 75.152344 L 47.125 75.894531 C 47.121094 75.90625 47.128906 75.917969 47.125 75.929688 L 45.660156 81.175781 C 45.65625 81.183594 45.648438 81.195312 45.644531 81.207031 L 31.664062 131.28125 L 29.996094 148.808594 L 32.109375 149.398438 C 32.109375 149.398438 32.121094 149.398438 32.128906 149.398438 C 32.136719 149.398438 32.140625 149.402344 32.144531 149.40625 L 34.285156 150.003906 L 56.527344 76.703125 C 56.566406 76.570312 56.621094 76.449219 56.6875 76.328125 L 69.417969 53.742188 C 67.398438 52.535156 66.101562 50.703125 66.101562 48.589844 Z M 50.671875 80.675781 C 49.222656 80.675781 48.050781 79.5 48.050781 78.054688 C 48.050781 76.609375 49.222656 75.433594 50.671875 75.433594 C 52.117188 75.433594 53.289062 76.609375 53.289062 78.054688 C 53.289062 79.5 52.117188 80.675781 50.671875 80.675781 Z M 50.671875 80.675781 "
                        fillOpacity="1"
                        fillRule="nonzero"
                    />
                </g>
                <path
                    d="M 89.921875 77.25 L 88.898438 75.433594 L 81.628906 75.433594 L 81.628906 80.675781 L 90.960938 80.675781 Z M 89.921875 77.25 "
                    fillOpacity="1"
                    fillRule="nonzero"
                />
                <path
                    d="M 75.855469 70.355469 L 74.160156 70.355469 C 73.019531 70.355469 72.09375 71.28125 72.09375 72.421875 L 72.09375 83.6875 C 72.09375 84.828125 73.019531 85.757812 74.160156 85.757812 L 75.855469 85.757812 C 77 85.757812 77.925781 84.828125 77.925781 83.6875 L 77.925781 72.421875 C 77.925781 71.28125 77 70.355469 75.855469 70.355469 Z M 75.855469 70.355469 "
                    fillOpacity="1"
                    fillRule="nonzero"
                />
                <g>
                    <path
                        d="M 102.667969 75.152344 L 82.863281 39.40625 L 80.203125 39.40625 L 79.871094 43.023438 C 82.320312 44.191406 83.917969 46.222656 83.917969 48.589844 C 83.917969 50.703125 82.621094 52.535156 80.605469 53.738281 L 93.339844 76.328125 C 93.40625 76.449219 93.460938 76.570312 93.5 76.703125 L 115.746094 150.003906 L 117.878906 149.40625 C 117.878906 149.40625 117.886719 149.402344 117.890625 149.398438 C 117.890625 149.398438 117.894531 149.398438 117.902344 149.398438 L 120.007812 148.808594 L 118.359375 131.28125 L 102.664062 75.152344 Z M 99.328125 80.675781 C 97.878906 80.675781 96.707031 79.5 96.707031 78.054688 C 96.707031 76.605469 97.882812 75.433594 99.328125 75.433594 C 100.773438 75.433594 101.949219 76.605469 101.949219 78.054688 C 101.949219 79.5 100.773438 80.675781 99.328125 80.675781 Z M 99.328125 80.675781 "
                        fillOpacity="1"
                        fillRule="nonzero"
                    />
                </g>
            </svg>
        </SvgIcon>
    );
}
