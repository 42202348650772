import React, { useImperativeHandle, useRef, useState } from 'react';
import { COLORS } from '../../constants';
import drawUtils from '../../pages/LessonPage/drawUtils';
import RulerDIV from '../icons/RulerDIV';
import LoopIcon from '@material-ui/icons/Loop';
import { DeleteForever } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import VBToolTip from '../Tooltip/VBToolTip';
import { FormattedMessage } from 'react-intl';
import MoveIcon from '../icons/MoveIcon';
import RotateIconNew from '../icons/RotateIconNew';

const RULER_WIDTH = 500;
const RULER_HEIGHT = 240;

const useStyles = makeStyles(() => ({
    moveButton: {
        position: 'absolute',
        color: COLORS.primaryD2,
        top: '32px',
        width: '32px',
        height: '32px',
        left: '40px',
        borderRadius: '50%',
        backgroundColor: COLORS.primaryD2 + 33,
    },
    deleteButton: {
        position: 'absolute',
        color: COLORS.secondaryD2,
        right: '50%',
        top: '32px',
        width: '24px',
        height: '24px',
        borderRadius: '50%',
        backgroundColor: COLORS.secondaryD2 + 33,
    },
    rotateButton: {
        position: 'absolute',
        color: COLORS.primaryD2,
        top: '32px',
        width: '32px',
        height: '32px',
        right: '4px',
        borderRadius: '50%',
        backgroundColor: COLORS.primaryD2 + 33,
    },
    hiddenActionButton: { width: '32px', height: '32px', backgroundColor: 'none' },
}));

const RulerComponent = React.forwardRef((props, ref) => {
    let [visible, setVisible] = useState(false);
    let [startPoint, setStartPoint] = useState({ x: 0, y: 0 });
    let [position, setPosition] = useState({
        x: drawUtils.CANVAS_ACTUAL_WIDTH / 3,
        y: drawUtils.CANVAS_ACTUAL_HEIGHT / 3,
    });
    let [rotation, setRotation] = useState(0);
    let [canHandle, setCanHandle] = useState(false);
    const classes = useStyles();

    let refUpdateDebouncer = useRef({
        position: {
            x: drawUtils.CANVAS_ACTUAL_WIDTH / 3,
            y: drawUtils.CANVAS_ACTUAL_HEIGHT / 3,
        },
        startPoint: { x: 0, y: 0 },
        visible: false,
        debounceFunc: null,
        rotation: 0,
        moveX: 0,
        moveY: 0,
    });

    useImperativeHandle(ref, () => ({
        showElement(el) {
            let newState = !visible;
            if (el !== undefined && el !== null) {
                newState = el;
                setVisible(el);
                if (refUpdateDebouncer && refUpdateDebouncer.current) {
                    refUpdateDebouncer.current.visible = el;
                }
            } else {
                setVisible(!visible);
                if (refUpdateDebouncer && refUpdateDebouncer.current) {
                    refUpdateDebouncer.current.visible = !visible;
                }
            }

            setTimeout(() => {
                updatePositions(true);
            }, 200);
        },
        updateScale(startPoint) {
            setStartPoint({ x: startPoint.x, y: startPoint.y });
            refUpdateDebouncer.current.startPoint = { x: startPoint.x, y: startPoint.y };
            setTimeout(updatePositions, 200);
        },
        getOverlapPoint(x, y, ignoreToleranceForProjection = false) {
            if (!visible) return null;
            let endPoint = document.getElementById('ruler-end-point').getBoundingClientRect();
            let startPoint = document.getElementById('ruler-start-point').getBoundingClientRect();
            let rect = drawUtils.getCurrentCanvasRect();
            let endPosition = {
                x: endPoint.left - rect.left,
                y: endPoint.top - rect.top,
            };
            let startPosition = {
                x: startPoint.left - rect.left,
                y: startPoint.top - rect.top,
            };

            const TOLERANCE = 10;
            // console.log('=== wtf', position, endPosition, { x, y });

            if (Math.abs(startPosition.x - x) < TOLERANCE && Math.abs(startPosition.y - y) < TOLERANCE)
                return startPosition;
            if (Math.abs(endPosition.x - x) < TOLERANCE && Math.abs(endPosition.y - y) < TOLERANCE) return endPosition;
            let pp = drawUtils.getProjectionPoint(startPosition, endPosition, { x, y });
            if (ignoreToleranceForProjection) {
                return pp;
            }
            if (Math.abs(pp.x - x) < TOLERANCE && Math.abs(pp.y - y) < TOLERANCE) return pp;

            return null;
        },
        getPosition() {
            return position;
        },

        getEndPosition() {
            let endPoint = document.getElementById('ruler-end-point').getBoundingClientRect();
            let rect = drawUtils.getCurrentCanvasRect();
            return {
                x: endPoint.left - rect.left,
                y: endPoint.top - rect.top,
            };
        },

        getState() {
            return {
                visible: refUpdateDebouncer.current.visible,
                rotation: refUpdateDebouncer.current.rotation,
                position: refUpdateDebouncer.current.position,
            };
        },
        setAllowWriting(canWrite) {
            setCanHandle(canWrite);
            setTimeout(() => {
                updatePositions();
            }, 100);
        },

        updateState(data) {
            if (data) {
                setVisible(data.visible);
                refUpdateDebouncer.current.visible = data.visible;
                if (data.position) {
                    setPosition(data.position);
                    refUpdateDebouncer.current.position = data.position;
                }
                if (data.rotation) {
                    setRotation(data.rotation);
                    refUpdateDebouncer.current.rotation = data.rotation;
                }
            }
        },
    }));

    const updatePositions = (shouldCallProps = false) => {
        if (props.updateState) {
            let state = {
                visible: refUpdateDebouncer.current.visible,
                rotation: refUpdateDebouncer.current.rotation,
                position: refUpdateDebouncer.current.position,
            };
            if (shouldCallProps) {
                props.updateState(state);
            }
        }
    };

    window.moveRuler = (event, data) => {
        let pos = {
            x: data.x + refUpdateDebouncer.current.moveX,
            y: data.y + refUpdateDebouncer.current.moveY,
        };
        if (pos.x === position.x && pos.y === position.y) return;
        if (!refUpdateDebouncer.current.debounceFunc) {
            refUpdateDebouncer.current.debounceFunc = setTimeout(() => {
                if (refUpdateDebouncer?.current?.position) {
                    setPosition(
                        drawUtils.scalePoint(
                            refUpdateDebouncer.current.startPoint,
                            refUpdateDebouncer.current.position.x,
                            refUpdateDebouncer.current.position.y
                        )
                    );
                }
                refUpdateDebouncer.current.debounceFunc = null;
            }, 30);
        } else {
            refUpdateDebouncer.current.position = pos;
        }
    };

    window.stopRuler = (event, data) => {
        let position = {
            x: data.x + refUpdateDebouncer.current.moveX,
            y: data.y + refUpdateDebouncer.current.moveY,
        };

        if (refUpdateDebouncer.current.debounceFunc) {
            clearTimeout(refUpdateDebouncer.current.debounceFunc);
            refUpdateDebouncer.current.debounceFunc = null;
        }
        let pos = drawUtils.scalePoint(refUpdateDebouncer.current.startPoint, position.x, position.y);
        setPosition(pos);
        refUpdateDebouncer.current.position = pos;
        if (props.updateState) {
            let state = {
                visible: refUpdateDebouncer.current.visible,
                rotation: refUpdateDebouncer.current.rotation,
                position: refUpdateDebouncer.current.position,
            };
            props.updateState(state);
        }

        refUpdateDebouncer.current.shouldReposition = true;
    };

    window.rotateRuler = (event, data) => {
        if (!refUpdateDebouncer.current.debounceFunc) {
            refUpdateDebouncer.current.debounceFunc = setTimeout(() => {
                setRotation(refUpdateDebouncer.current.rotation);
                refUpdateDebouncer.current.debounceFunc = null;
            }, 30);
        } else {
            let el = document.getElementById('ruler-start-point')?.getBoundingClientRect();
            let rect = drawUtils.getCurrentCanvasRect();

            let x1, x2, y1, y2;
            x1 = data.x + rect.x + refUpdateDebouncer.current.moveX;
            x2 = el.x;
            y1 = data.y + rect.y + refUpdateDebouncer.current.moveY;
            y2 = el.y;
            refUpdateDebouncer.current.rotation = (Math.atan2(y2 - y1, x2 - x1) * 180) / Math.PI + 180;
        }
    };

    window.stopRulerRotate = (event, data) => {
        if (refUpdateDebouncer.current.debounceFunc) {
            clearTimeout(refUpdateDebouncer.current.debounceFunc);
            refUpdateDebouncer.current.debounceFunc = null;
        }
        let el = document.getElementById('ruler-start-point')?.getBoundingClientRect();
        let rect = drawUtils.getCurrentCanvasRect();

        let x1, x2, y1, y2;
        x1 = data.x + rect.x + refUpdateDebouncer.current.moveX;
        x2 = el.x;
        y1 = data.y + rect.y + refUpdateDebouncer.current.moveY;
        y2 = el.y;
        refUpdateDebouncer.current.rotation = (Math.atan2(y2 - y1, x2 - x1) * 180) / Math.PI + 180;

        setRotation(refUpdateDebouncer.current.rotation);
        if (props.updateState) {
            let state = {
                visible: refUpdateDebouncer.current.visible,
                rotation: refUpdateDebouncer.current.rotation,
                position: refUpdateDebouncer.current.position,
            };
            props.updateState(state);
        }

        updatePositions();
    };

    window.handleStartRulerMove = (event, data) => {
        let el = document.getElementById('ruler-start-point')?.getBoundingClientRect();
        let rect = drawUtils.getCurrentCanvasRect();
        let w = 0;
        let h = 0;
        if (el && rect) {
            let xx = data.x + rect.left;
            let yy = data.y + rect.top;
            w = el.x - xx;
            h = el.y - yy;
        }
        refUpdateDebouncer.current.moveX = w;
        refUpdateDebouncer.current.moveY = h;
        // el = document.getElementById('ruler-move-icon-position')?.getBoundingClientRect();
        // console.log('=== start', el.x, el.y, data.x, data.y, w, h);
    };

    window.handleStartRulerRotate = (event, data) => {
        let el = document.getElementById('ruler-end-point')?.getBoundingClientRect();
        let rect = drawUtils.getCurrentCanvasRect();
        let w = 0;
        let h = 0;
        if (el && rect) {
            let xx = data.x + rect.left;
            let yy = data.y + rect.top;
            w = el.x - xx;
            h = el.y - yy;
        }
        refUpdateDebouncer.current.moveX = w;
        refUpdateDebouncer.current.moveY = h;
    };

    window.hideRuler = () => {
        setVisible(false);
        refUpdateDebouncer.current.visible = false;

        if (props.updateState) {
            let state = {
                visible: refUpdateDebouncer.current.visible,
                rotation: refUpdateDebouncer.current.rotation,
                position: refUpdateDebouncer.current.position,
            };

            props.updateState(state);
        }
    };

    let t = 'rotate(' + rotation + 'deg)';
    let rotateVal = Number.parseFloat(rotation).toFixed(1);
    if (360 == rotateVal || rotateVal == 0) {
        rotateVal = 0;
    } else {
        rotateVal = Number.parseFloat(360 - rotateVal).toFixed(1);
    }
    if (!position) {
        return null;
    }
    let rswhWH = drawUtils.reverseScalePoint({ x: 0, y: 0 }, RULER_WIDTH, RULER_HEIGHT);

    if (!rswhWH) return null;

    // if (!reverseScaledWHPoints) return;

    let reverseScaledPoint = drawUtils.reverseScalePoint(startPoint, position.x, position.y);
    if (!reverseScaledPoint) return null;
    let px = reverseScaledPoint.x;
    let py = reverseScaledPoint.y;
    return (
        <div>
            <div
                style={{
                    transform: t,
                    width: rswhWH.x + 'px',
                    height: 'auto',
                    position: 'absolute',
                    left: px + 'px',
                    top: py + 'px',
                    transformOrigin: '0% 0%',
                    display: visible ? 'block' : 'none',
                }}
            >
                <div
                    id="ruler-start-point"
                    style={{
                        position: 'absolute',
                        left: '0px',
                        top: '0px',
                    }}
                />
                <div
                    id="ruler-end-point"
                    style={{
                        position: 'absolute',
                        right: '0px',
                        top: '0px',
                    }}
                />
                <RulerDIV />
                <RotateIconNew
                    className={classes.rotateButton}
                    id="ruler-rotate-icon-position"
                    style={{ visibility: canHandle ? 'visible' : 'hidden' }}
                />
                <DeleteForever
                    className={classes.deleteButton}
                    id="ruler-delete-icon-position"
                    onClick={() => {
                        setVisible(false);
                    }}
                    onTouchStart={() => {
                        setVisible(false);
                    }}
                    style={{ visibility: canHandle ? 'visible' : 'hidden' }}
                />
                <MoveIcon
                    className={classes.moveButton}
                    id="ruler-move-icon-position"
                    style={{ visibility: canHandle ? 'visible' : 'hidden' }}
                />
                {rotateVal ? (
                    <VBToolTip content={<FormattedMessage id="tooltip.lessonPage.angle" />}>
                        <div
                            style={{
                                position: 'absolute',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: '0.7rem',
                                transform: `rotate(${rotateVal}deg)`,
                                width: '36px',
                                height: '36px',
                                fontWeight: '700',
                                color: COLORS.primaryD1,
                                backgroundColor: COLORS.primary + '66',
                                borderRadius: '50%',
                                top: '32px',
                            }}
                        >
                            {rotateVal}
                        </div>
                    </VBToolTip>
                ) : null}
            </div>
        </div>
    );
});

export default RulerComponent;
