import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function PricesSideMenuIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 3.51518V13.1964C0 13.7589 0.315625 14.2902 0.84375 14.4871C3.5625 15.5027 6.28125 14.8089 9 14.1152C11.4937 13.4808 13.9875 12.8433 16.4781 13.5246C17.1969 13.7214 18 13.2277 18 12.4808V2.80268C18 2.24018 17.6844 1.70893 17.1562 1.51205C14.4375 0.496426 11.7188 1.19018 9 1.88393C6.50625 2.5183 4.0125 3.15268 1.52188 2.47143C0.8 2.27455 0 2.7683 0 3.51518ZM9 10.9996C7.61875 10.9996 6.5 9.6558 6.5 7.99955C6.5 6.3433 7.61875 4.99955 9 4.99955C10.3813 4.99955 11.5 6.3433 11.5 7.99955C11.5 9.6558 10.3813 10.9996 9 10.9996Z" fill="#08AAB4"/>
            </svg>
        </SvgIcon>
    );
}