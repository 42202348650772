import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function PencilIcon(props) {
    let style = { verticalAlign: 'bottom' };
    if (props.style) {
        style = Object.assign({}, style, props.style);
    }
    return (
        <SvgIcon {...props} style={style}>
            <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 32 32" viewBox="0 0 32 32">
                <path d="M8.992 27.306l-.008.008-6.807 2.677c-.106.042-.21-.063-.169-.169l2.677-6.807.008-.008L8.992 27.306zM25.592 10.706L10.406 25.892l-4.298-4.298L21.294 6.408 25.592 10.706zM29.414 6.884l-2.408 2.408-4.298-4.298 2.408-2.408c.781-.781 2.047-.781 2.828 0l1.47 1.47C30.195 4.837 30.195 6.103 29.414 6.884z" />
            </svg>
        </SvgIcon>
    );
}
