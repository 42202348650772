import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function EditIcon(props) {
    return (
        <SvgIcon {...props} style={{ ...props.style, verticalAlign: 'bottom' }}>
            <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.0844 0.706914C12.8656 -0.0741797 14.1344 -0.0741797 14.9156 0.706914L15.2937 1.08535C16.075 1.8666 16.075 3.1341 15.2937 3.91535L8.40625 10.7997C8.14375 11.0654 7.80937 11.2216 7.44375 11.3341L4.6125 11.9872C4.44375 12.0247 4.26875 11.9747 4.14687 11.8529C4.025 11.731 3.975 11.556 4.0125 11.3872L4.66563 8.55598C4.75 8.19035 4.93437 7.85598 5.2 7.56535L12.0844 0.706914ZM14.2063 1.4141C13.8156 1.02348 13.1844 1.02348 12.7937 1.4141L11.9563 2.24973L13.75 4.04348L14.5844 3.20598C14.975 2.81629 14.975 2.18316 14.5844 1.79254L14.2063 1.4141ZM5.64062 8.78098L5.16563 10.8341L7.19063 10.3591C7.4 10.3185 7.56875 10.2247 7.7 10.0654L13.0437 4.74973L11.25 2.95691L5.90625 8.29973C5.775 8.43098 5.68125 8.59973 5.64062 8.78098ZM6.5 1.99973C6.775 1.99973 7 2.22348 7 2.49973C7 2.77598 6.775 2.99973 6.5 2.99973H2.5C1.67156 2.99973 1 3.6716 1 4.49973V13.4997C1 14.3279 1.67156 14.9997 2.5 14.9997H11.5C12.3281 14.9997 13 14.3279 13 13.4997V9.49973C13 9.22473 13.225 8.99973 13.5 8.99973C13.775 8.99973 14 9.22473 14 9.49973V13.4997C14 14.881 12.8813 15.9997 11.5 15.9997H2.5C1.11937 15.9997 0 14.881 0 13.4997V4.49973C0 3.1191 1.11937 1.99973 2.5 1.99973H6.5Z" fill="#08AAB4"/>
            </svg>
        </SvgIcon>
    );
}
