import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Grid, Paper, Typography } from '@material-ui/core';
import GenericState from '../../../MyComponents/GenericLoadingErrorState/GenericState';
import userApi from '../../../Api/userApi';
import { useHistory } from 'react-router-dom';
import store from '../../../redux/store';
import { updateUserSubscription } from '../../../redux/actions';
import { PROFILE_LINK } from '../../../common/urlLinks';
import { TRIAL_DAYS } from '../../../shared/Capabilities';
import { GA_EVENT_TREE } from '../../../gaConstants';

const TrialStage = ({ profile }) => {
    const [isStarting, setIsStarting] = useState(false);

    let history = useHistory();

    const startTrial = () => {
        setIsStarting(true);
        userApi.startTrial().then((res) => {
            window.logAppActivity(GA_EVENT_TREE.mainPage.onboarding.startTrial);
            store.dispatch(updateUserSubscription(res.data.subscription));
            history.push(PROFILE_LINK);
        });
    };

    if (profile?.subscription?.trialStarted === true) {
        return (
            <Grid container spacing={10}>
                <Grid item xs={12} sm={12} style={{ textAlign: 'center', marginTop: '5rem' }}>
                    <Typography variant="h2">
                        <FormattedMessage id={'onboarding.start.trialAlreadyDone'} />
                    </Typography>
                </Grid>
            </Grid>
        );
    }
    return (
        <Grid container spacing={10}>
            <Grid item xs={12}>
                <Paper elevation={0} style={{ background: 'rgba(255, 205, 52, 0.06)', padding: '2rem' }}>
                    <Grid container spacing={2} justify={'center'}>
                        <Grid item xs={12}>
                            <div
                                style={{
                                    width: '100%',
                                    marginTop: '0.5rem',
                                    textAlign: 'center',
                                    paddingBottom: '5rem',
                                }}
                            >
                                <Typography variant="h2">
                                    <FormattedMessage id="subscriptions.subscription.TRIAL.name" />
                                </Typography>
                                <Typography variant="h5">
                                    <FormattedMessage id="onboarding.start.startTrialDescription" />
                                </Typography>
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={12} style={{ textAlign: 'center', marginTop: '5rem' }}>
                            {isStarting ? (
                                <GenericState isLoading={true} inline={true} />
                            ) : (
                                <Button variant={'contained'} onClick={startTrial} color={'primary'} size={'large'}>
                                    <FormattedMessage
                                        id={'subscriptions.subscription.startTrial'}
                                        values={{ days: TRIAL_DAYS }}
                                    />
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default TrialStage;
