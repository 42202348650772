import React from 'react';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Divider, Grid, Paper } from '@material-ui/core';
import CurrentSubscription from './CurrentSubscription';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import { a11yProps } from '../../common/generic/TabPanel';
import ChangePreferences from './ChangePreferences';
import { handleLogout } from '../../common/utils';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
        margin: theme.spacing(5),
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    large: {
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    container: {
        margin: `${theme.spacing(3)}px 0`,
        padding: theme.spacing(3),
        borderRadius: '2rem',
        // width: '100%',
    },
    text: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '220px',
    },
    hoverEllipsis: {
        '&:hover': {
            whiteSpace: 'normal',
            maxWidth: 'none',
            overflow: 'visible',
        },
    },
}));

// function handleLogout() {
//     if (window.location.host === "localhost:3000") {
//         window.location.assign("http://localhost:4000/logout");
//     } else {
//         window.location.assign(window.location.protocol + "//" + window.location.host + "/logout");
//     }
// }

const Profile = (props) => {
    const classes = useStyles();
    const theme = useTheme();

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    return (
        <Paper className={classes.container}>
            <Grid container direction="row" justify="center" alignItems="center">
                <Grid item xs={12}>
                    <Typography variant="h4">
                        <FormattedMessage id="profile.title" />
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <div className="u-fx u-fx-align-center" style={{ margin: '1rem 0' }}>
                        <Avatar
                            src={props.profile.avatar}
                            className={classes.large}
                            size="large"
                            style={{ border: '1px solid #08AAB4' }}
                        />
                        <div className="u-fx u-fx-col u-ml-full">
                            <Typography variant="h5" style={{ margin: 0 }}>
                                <div className={classes.text}>{props.profile.name}</div>
                            </Typography>
                            <Typography variant="h6" style={{ margin: 0 }} alt={props.profile.email}>
                                <div className={`${classes.text} ${classes.hoverEllipsis}`}>{props.profile.email}</div>
                            </Typography>
                        </div>
                        <div style={{ width: '100%', textAlign: 'end' }}>
                            <Button
                                onClick={() => {
                                    handleLogout(true);
                                }}
                                onTouchStart={() => {
                                    handleLogout(true);
                                }}
                                color="secondary"
                                variant={'contained'}
                            >
                                <FormattedMessage id="logoutComplete" />
                            </Button>
                        </div>
                    </div>
                    <Divider />
                </Grid>
                {!window.isTeams ? (
                    <Grid item xs={12}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                        >
                            <Tab
                                label={props.intl.formatMessage({
                                    id: 'subscriptions.subscription.currentSubscription',
                                })}
                                {...a11yProps(0)}
                            />
                            <Tab
                                label={props.intl.formatMessage({
                                    id: 'profile.tab.updatePreferences',
                                })}
                                {...a11yProps(1)}
                            />
                        </Tabs>
                        <SwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={value}
                            onChangeIndex={handleChangeIndex}
                        >
                            <CurrentSubscription />
                            <ChangePreferences />
                        </SwipeableViews>
                    </Grid>
                ) : null}
            </Grid>
        </Paper>
    );
};

const mapStateToProps = (state) => {
    const { profile } = state;
    return { profile };
};

export default injectIntl(connect(mapStateToProps)(Profile));
