import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function MultiScreenIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46 36">
                <g fill="none">
                    <rect width="38" height="22" x="1" y="7" stroke="#3C4043" strokeWidth="2" />
                    <path fill="#3C4043" d="M8,4 L6,4 L6,0 L46,0 L46,24 L42,24 L42,22 L44,22 L44,2 L8,2 L8,4 Z" />
                </g>
            </svg>
        </SvgIcon>
    );
}
