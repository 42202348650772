import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function OrangeFolderIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_796_407)">
                    <path d="M3.99805 22.5H21.998C23.6527 22.5 24.998 21.1547 24.998 19.5V7.5C24.998 5.84531 23.6527 4.5 21.998 4.5H14.9902C14.1934 4.5 13.4293 4.18594 12.8668 3.62344L11.6246 2.37656C11.0621 1.81406 10.298 1.5 9.50117 1.5H3.99805C2.34336 1.5 0.998047 2.84531 0.998047 4.5V19.5C0.998047 21.1547 2.34336 22.5 3.99805 22.5Z" fill="#FCD09B"/>
                </g>
                <defs>
                    <clipPath id="clip0_796_407">
                        <rect width="24" height="24" fill="white" transform="translate(0.998047)"/>
                    </clipPath>
                </defs>
            </svg>

        </SvgIcon>
    );
}
