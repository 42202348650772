import React from 'react';
import { injectIntl } from 'react-intl';
import { Button, Grid } from '@material-ui/core';

import UKIcon from '../icons/UK';
import RomaniaIcon from '../icons/Romania';
import FranceIcon from '../icons/France';

const LanguageSelector = (props) => {
    const handleRo = () => {
        props.updateLanguage('ro');
        props.closeDrawer();
    };

    const handleEn = () => {
        props.updateLanguage('en');
        props.closeDrawer();
    };

    const handleFr = () => {
        props.updateLanguage('fr');
        props.closeDrawer();
    };

    return (
        <Grid container style={{ display: 'flex', justifyContent: 'center', margin: '0.75rem 0rem' }}>
            <Grid item>
                <Button onClick={handleRo}>
                    <RomaniaIcon />
                </Button>
            </Grid>
            <Grid item>
                <Button onClick={handleEn}>
                    <UKIcon />
                </Button>
            </Grid>
            <Grid item>
                <Button onClick={handleFr}>
                    <FranceIcon />
                </Button>
            </Grid>
        </Grid>
    );
};

export default injectIntl(LanguageSelector);
